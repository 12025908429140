import React from "react";

interface Props {
  width?: number;
  height?: number;
}

const CloseIcon = (props: Props) => {
  const { width, height } = props;

  return (
    <>
      <svg
        style={{ display: "block" }}
        width={width || 20}
        height={height || 20}
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.3566 19.9999L27.0716 15.2866C27.2264 15.1317 27.3493 14.9479 27.4331 14.7456C27.5169 14.5433 27.56 14.3264 27.56 14.1074C27.56 13.8884 27.5169 13.6716 27.4331 13.4693C27.3493 13.2669 27.2264 13.0831 27.0716 12.9283C26.9167 12.7734 26.7329 12.6506 26.5306 12.5668C26.3283 12.483 26.1114 12.4398 25.8924 12.4398C25.6734 12.4398 25.4566 12.483 25.2543 12.5668C25.0519 12.6506 24.8681 12.7734 24.7133 12.9283L19.9999 17.6433L15.2866 12.9283C14.9739 12.6155 14.5497 12.4398 14.1074 12.4398C13.6651 12.4398 13.241 12.6155 12.9283 12.9283C12.6155 13.241 12.4398 13.6651 12.4398 14.1074C12.4398 14.3264 12.483 14.5433 12.5668 14.7456C12.6506 14.9479 12.7734 15.1317 12.9283 15.2866L17.6433 19.9999L12.9283 24.7133C12.6155 25.026 12.4398 25.4501 12.4398 25.8924C12.4398 26.3347 12.6155 26.7589 12.9283 27.0716C13.241 27.3843 13.6651 27.56 14.1074 27.56C14.5497 27.56 14.9739 27.3843 15.2866 27.0716L19.9999 22.3566L24.7133 27.0716C25.026 27.3843 25.4501 27.56 25.8924 27.56C26.3347 27.56 26.7589 27.3843 27.0716 27.0716C27.3843 26.7589 27.56 26.3347 27.56 25.8924C27.56 25.4501 27.3843 25.026 27.0716 24.7133L22.3566 19.9999ZM19.9999 36.6666C10.7949 36.6666 3.33325 29.2049 3.33325 19.9999C3.33325 10.7949 10.7949 3.33325 19.9999 3.33325C29.2049 3.33325 36.6666 10.7949 36.6666 19.9999C36.6666 29.2049 29.2049 36.6666 19.9999 36.6666Z"
          fill="#C2C3CD"
        />
      </svg>
    </>
  );
};

export default CloseIcon;
