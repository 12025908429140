import { gql } from "apollo-boost";

export const workoutFragment = gql`
  fragment WorkoutProperty on Workout {
    id
    instructions
    sortOrder
    title
  }
`;

export const commonMasterTrainingPlanFields = `
  id
  gender
  repRange
  duration
  type
  program
  frequency
  planVersion
`;

export const commonExerciseFields = `
exercise {
  id
  targetBase
  name
  equipment
  isStrengthScoreKeyExercise
  isCustomExercise
  media {
    thumbnailUrl {
      id
      url
    }
    videoUrl {
      id
      url 
    }
  }
}`;

export const commonGymStepFields = `
  id
  target
  supersetTag
  targetRepsLowerBound
  targetRepsUpperBound
  targetSets
  targetWeight
  targetTime
  hideWeightForExerciseSets
  generateSmartWarmupSets
  smartWarmupSetsCount
  restPeriod
  ${commonExerciseFields}
`;

export const commonSupersetChildFields = `
  id
  target
  targetTime
  hideWeightForExerciseSets
  targetRepsLowerBound
  targetRepsUpperBound
`;

export const commonSupersetFields = `
  targetSets
  restPeriod  
`;

export const masterGymStepsFields = gql`
  fragment MasterSupersetGymStepProperty on MasterSupersetGymStep {
    ${commonSupersetFields}
    gymSteps {
      ${commonSupersetChildFields}
      ${commonExerciseFields}
      supersetGymStep {
        id
      }
    }
  }
  fragment MasterGymStepProperty on MasterGymStep {
    ${commonGymStepFields}
  }  
`;

export const masterGymStepsSelectors = `
  id
  sortOrder
  ...MasterSupersetGymStepProperty
  ...MasterGymStepProperty
`;

export const clientGymStepTemplatesFields = gql`
  ${workoutFragment}
  fragment ClientSupersetGymStepTemplateProperty on ClientSupersetGymStepTemplate {
    ${commonSupersetFields}
    gymSteps {
      ${commonSupersetChildFields}
      ${commonExerciseFields}
      supersetGymStep {
        id
      }
    }
  }
  fragment ClientGymStepTemplateProperty on ClientGymStepTemplate {
    ${commonGymStepFields}
    workout {
      ...WorkoutProperty
    }
  }  
`;

export const clientGymStepTemplatesSelectors = `
  id
  sortOrder
  ...ClientSupersetGymStepTemplateProperty
  ...ClientGymStepTemplateProperty
`;
