import React from "react";

interface Props {}

const CheckIcon = (props: Props) => {
  const {} = props;

  return (
    <>
      <svg
        style={{ display: "block" }}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="12" r="12" fill="#8FE3B2" />
        <path
          d="M18.6654 7.83325L9.4987 16.9999L5.33203 12.8333"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default CheckIcon;
