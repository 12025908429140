import React from "react";
import colors from "../../assets/colors";

interface Props {
  text: string;
  height?: number;
  isSelected?: boolean;
  textColor?: string;
  selectedTextColor?: string;
  bgColor?: string;
  selectedBgColor?: string;
  disabled?: boolean;
  name?: string;
  textDescription?: string;
  margin?: string | number;
  padding?: string | number;
  minWidth?: number;
  nofocus?: boolean;
  className?: string;
  noPointer?: boolean;
  variant?: "filter-button" | "tag-button";
  style?: React.CSSProperties;
  onClick?: (props: any) => void;
  onMouseEnter?: (textDescription: string) => void;
  onMouseLeave?: () => void;
}

const ButtonTag = (props: Props) => {
  const {
    className,
    isSelected,
    height,
    name,
    text,
    padding,
    margin,
    nofocus,
    disabled,
    minWidth,
    textColor,
    noPointer,
    selectedTextColor,
    bgColor,
    selectedBgColor,
    textDescription,
    variant,
    onClick,
    style,
    onMouseEnter,
    onMouseLeave,
  } = props;

  let buttonStyle: React.CSSProperties = {
    cursor: "pointer",
    opacity: 1,
    minWidth: minWidth || 0,
    borderRadius: 4,
    padding: padding || 5,
    height: height || 28,
    margin: margin || 0,
    color: isSelected ? colors.caliber_secondary : colors.caliber_gray_text,
    backgroundColor: isSelected ? colors.caliber_gray_11 : colors.caliber_white,
  };

  if (variant === "tag-button") {
    buttonStyle.borderRadius = 6;
    buttonStyle.margin = 3;
    buttonStyle.color = colors.caliber_secondary;
    buttonStyle.backgroundColor = isSelected
      ? colors.caliber_dark_gray
      : colors.caliber_white;
  }
  if (variant === "filter-button") {
    buttonStyle.backgroundColor = isSelected
      ? colors.caliber_secondary_gray_11
      : colors.caliber_secondary_gray_5;
    buttonStyle.color = isSelected
      ? colors.caliber_secondary
      : colors.caliber_gray_text;
  }
  const classes = [
    className,
    "heading-xsmall",
    "d-flex",
    "flex-column",
    "justify-content-center",
    "align-items-center",
    "align-self-center",
  ];
  if (textColor && !isSelected) {
    buttonStyle.color = textColor;
  }
  if (bgColor && !isSelected) {
    buttonStyle.backgroundColor = bgColor;
  }
  if (isSelected && selectedBgColor) {
    buttonStyle.backgroundColor = selectedBgColor;
  }
  if (isSelected && selectedTextColor) {
    buttonStyle.color = selectedTextColor;
  }

  if (nofocus || disabled) {
    classes.push("nofocus");
  }
  if (disabled || noPointer) {
    delete buttonStyle.cursor;
  }
  if (disabled) {
    buttonStyle.opacity = 0.5;
  }
  if (style) {
    buttonStyle = {
      ...buttonStyle,
      ...style,
    };
  }
  return (
    <div
      role="button"
      className={classes.join(" ")}
      style={buttonStyle}
      tabIndex={0}
      onClick={(event) => {
        if (onClick && !disabled) {
          onClick({ name, text, isSelected, event });
        }
      }}
      onKeyDown={(event) => {
        if (event.key === "Enter" && onClick && !disabled) {
          onClick({ name, text, isSelected, event });
        }
      }}
      onMouseEnter={() => onMouseEnter && onMouseEnter(textDescription)}
      onMouseLeave={() => onMouseLeave && onMouseLeave()}
    >
      {text}
    </div>
  );
};
export default ButtonTag;
