import React from "react";
import colors from "../../assets/colors";
import ArrowDownIcon from "../../assets/images/ArrowDownIcon.svg";

interface Props {
  client: {
    location: string;
    clientAge: string;
    clientHeight: string;
    clientWeight: string;
  };
  expand: () => void;
}

const CollapsedProfile = (props: Props) => {
  const {
    client: { clientAge, clientHeight, clientWeight, location },
    expand,
  } = props;

  const badgeStyle = {
    padding: "1px 10px",
    borderRadius: 4,
    backgroundColor: colors.caliber_gray_5,
    fontSize: "12px",
    lineHeight: "16px",
  };
  return (
    <div>
      <div className="flex-center medium-bold" style={{ marginTop: 18 }}>
        {location && (
          <div
            style={{
              ...badgeStyle,
              margin: 2,
              maxWidth: 200,
              wordBreak: "break-all",
            }}
          >
            {location}
          </div>
        )}
      </div>
      <div className="flex-center medium-bold">
        {[clientAge, clientHeight, clientWeight].map((badge) => {
          return (
            <div
              key={badge}
              style={{
                ...badgeStyle,
                margin: 2,
              }}
            >
              {badge}
            </div>
          );
        })}
      </div>
      <div className="flex-center medium-bold">
        <div
          role="button"
          className="pointer"
          tabIndex={0}
          onClick={expand}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              expand();
            }
          }}
          style={{
            color: colors.caliber_gray_border,
            backgroundColor: colors.caliber_white,
            border: `1px solid ${colors.caliber_gray_bg}`,
            borderRadius: 24,
            padding: "1px 7px 1px 14px",
            marginTop: 15,
            marginBottom: 20,
          }}
        >
          More Info
          <img alt="Expand" src={ArrowDownIcon} />
        </div>
      </div>
    </div>
  );
};
export default CollapsedProfile;
