import React from "react";
import { Modal } from "react-bootstrap";
import colors from "../../assets/colors";
import { useKeyPress } from "../../utils/customHooks";
import ButtonTag from "../ButtonTag";

interface Props {
  show?: boolean;
  text: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  confirmButtonColor?: string;
  cancelButtonColor?: string;
  closeOnDimmerClick?: boolean;
  onConfirm: () => void;
  onCancel?: () => void;
}

const ConfirmationDialog = (props: Props) => {
  const {
    show,
    text,
    confirmButtonText,
    cancelButtonText,
    confirmButtonColor,
    cancelButtonColor,
    closeOnDimmerClick,
    onConfirm,
    onCancel,
  } = props;

  useKeyPress("Enter", () => {
    onConfirm?.();
  });
  useKeyPress("Escape", () => {
    onCancel?.();
  });

  return (
    <Modal
      centered
      show={show}
      onHide={closeOnDimmerClick ? onCancel : () => {}}
    >
      <div
        className="d-flex flex-column"
        style={{ padding: "25px 15px 15px 15px", minHeight: 100 }}
      >
        {show &&
          text &&
          text.split("\\n").map((piece) => (
            <div
              key={piece}
              className="d-flex flex-column justify-content-center align-items-center paragraph-large"
              style={{
                flex: 1,
                marginTop: 3,
                marginBottom: 3,
                textAlign: "center",
                whiteSpace: "pre-line",
                color: colors.caliber_secondary,
                lineHeight: "24px",
                fontSize: "18px",
              }}
            >
              {piece}
            </div>
          ))}
        <div
          className="d-flex justify-content-center"
          style={{
            height: 62,
          }}
        >
          {onCancel && (
            <ButtonTag
              margin={6}
              text={cancelButtonText || "Leave"}
              onClick={({ event }) => {
                event.stopPropagation();
                onCancel();
              }}
              height={24}
              textColor={colors.caliber_white}
              bgColor={cancelButtonColor || colors.caliber_gray_text}
              minWidth={91}
            />
          )}
          <ButtonTag
            margin={6}
            text={confirmButtonText || "Delete"}
            onClick={({ event }) => {
              event.stopPropagation();
              onConfirm();
            }}
            height={24}
            textColor={colors.caliber_white}
            bgColor={confirmButtonColor || colors.caliber_green_200}
            minWidth={91}
          />
        </div>
      </div>
    </Modal>
  );
};
export default ConfirmationDialog;
