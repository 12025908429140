import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import { Modal } from "react-bootstrap";
import { ContentLessonSet } from "../../types/gqlTypes";
import PrevButton from "../../assets/images/PrevButton.svg";
import NextButton from "../../assets/images/NextButton.svg";
import colors from "../../assets/colors";
import "./index.css";
import { useRerenderOnResize } from "../../utils/customHooks";

interface Props {
  weeklyItem: ContentLessonSet;
  onClose: () => void;
}

const LessonContentModal = (props: Props) => {
  const { weeklyItem, onClose } = props;
  useRerenderOnResize();

  const [current, setCurrent] = useState(0);
  const modalWidth = 414;

  const pageLeft = () => {
    if (current === 0) {
      onClose();
    } else {
      setCurrent(Math.max(current - 1, 0));
      document.getElementById("weekly-content").scrollTo(0, 0);
    }
  };
  const pageRight = () => {
    if (current === weeklyItem?.lessonPages?.length - 1) {
      onClose();
    } else {
      setCurrent(Math.min(current + 1, weeklyItem?.lessonPages?.length - 1));
      document.getElementById("weekly-content").scrollTo(0, 0);
    }
  };
  const closeButton = (
    <div
      role="button"
      tabIndex={0}
      onClick={onClose}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          onClose();
        }
      }}
      className="circular-medium-font d-flex align-items-center justify-content-center"
      style={{
        width: 79,
        height: 32,
        color: colors.caliber_secondary,
        backgroundColor: colors.caliber_light_gray,
        lineHeight: "16px",
        fontSize: "14px",
        borderRadius: 6,
      }}
    >
      Close
    </div>
  );
  const nextButton = (
    <div
      role="button"
      tabIndex={0}
      onClick={pageRight}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          pageRight();
        }
      }}
      className=""
      style={{
        marginRight: 17,
      }}
    >
      <img src={NextButton} />
    </div>
  );
  const prevButton = (
    <div
      role="button"
      tabIndex={0}
      onClick={pageLeft}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          pageLeft();
        }
      }}
      className=""
      style={{}}
    >
      <img src={PrevButton} />
    </div>
  );

  return (
    <Modal
      style={{
        overflow: "hidden !important",
        width: 446,
        maxWidth: "414px !important",
        marginLeft: (window.innerWidth - modalWidth) / 2,
      }}
      show
      centered
      onHide={onClose}
    >
      <Modal.Body>
        <div
          className="d-flex flex-column"
          style={{
            height: window.innerHeight * 0.8,
            maxHeight: 730,
            overflowY: "auto",
            overflowX: "hidden",
            width: modalWidth,
          }}
        >
          <div
            style={{
              height: 232,
              width: modalWidth,
            }}
          >
            <img
              style={{
                height: 232,
                width: modalWidth,
              }}
              src={
                weeklyItem?.lessonPages &&
                weeklyItem?.lessonPages?.[current]?.backgroundImage?.url
              }
            />
          </div>
          <div
            id="weekly-content"
            className="circular-font"
            style={{
              flex: 1,
              margin: 8,
              marginTop: 10,
              paddingRight: 20,
              maxHeight: window.innerHeight * 0.4,
              minHeight: 100,
              whiteSpace: "pre-line",
              overflowY: "auto",
            }}
          >
            <ReactMarkdown
              source={
                weeklyItem?.lessonPages &&
                weeklyItem?.lessonPages?.[current]?.body
              }
            />
          </div>
          <div
            style={{
              marginTop: 20,
              marginBottom: 15,
            }}
            className="d-flex justify-content-between"
          >
            {current === 0 ? closeButton : prevButton}
            <div
              className="d-flex justify-content-between align-items-center"
              style={{
                flexGrow: 1,
                height: 32,
                paddingRight: weeklyItem?.lessonPages?.length > 6 ? 40 : 70,
                paddingLeft: weeklyItem?.lessonPages?.length > 6 ? 40 : 70,
              }}
            >
              {weeklyItem?.lessonPages?.map((_, index) => {
                return (
                  <div
                    style={{
                      width: 8,
                      height: 8,
                      borderRadius: 4,
                      backgroundColor:
                        index === current
                          ? colors.caliber_gray_text
                          : colors.caliber_gray_border,
                    }}
                  >
                    {/* little dot */}
                  </div>
                );
              })}
            </div>
            {current !== weeklyItem?.lessonPages?.length - 1
              ? nextButton
              : closeButton}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LessonContentModal;
