import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import colors from "../../../assets/colors";
import { getStrengthScoresMuscleGroup } from "../../../redux/actions/ClientStrengthDashboard";
import { StoreState } from "../../../redux/reducers";
import {
  StrengthScore,
  StrengthScoreDashboard,
  StrengthScoreMuscleGroupType,
} from "../../../types/gqlTypes";
import MuscleDetailsChart from "./MuscleDetailsChart";
import MuscleDetailsContent from "./MuscleDetailsContent";
import MuscleDetailsHeader from "./MuscleDetailsHeader";
import MuscleDetailsSkeleton from "./MuscleDetailsSkeleton";
import Loader from "../../Loader";

interface OwnProps {
  muscleType: StrengthScoreMuscleGroupType;
  onClose: () => void;
}
interface Props extends OwnProps {
  strengthScoreDashboardChanges: StrengthScoreDashboard[];
  isLoading: boolean;
  getStrengthScoresMuscleGroup: (
    clientId: string,
    type: StrengthScoreMuscleGroupType
  ) => void;
}

const StrengthScoreMuscleDetails = (props: Props) => {
  const {
    muscleType,
    onClose,
    strengthScoreDashboardChanges,
    isLoading,
    getStrengthScoresMuscleGroup,
  } = props;
  const { id: clientId } = useParams();

  useEffect(() => {
    if (muscleType != null) {
      getStrengthScoresMuscleGroup(clientId, muscleType);
    }
  }, [muscleType]);

  const [thisWeekMuscleGroups, lastWeekMuscleGroups] = useMemo(() => {
    const thisWeekMuscleGroups: { [key: string]: StrengthScore } = {};
    const lastWeekMuscleGroups: { [key: string]: StrengthScore } = {};
    strengthScoreDashboardChanges?.[0]?.muscleGroupScores?.forEach(
      (muscleGroup) => {
        thisWeekMuscleGroups[muscleGroup.type] = muscleGroup;
      }
    );
    strengthScoreDashboardChanges?.[1]?.muscleGroupScores?.forEach(
      (muscleGroup) => {
        lastWeekMuscleGroups[muscleGroup.type] = muscleGroup;
      }
    );
    return [thisWeekMuscleGroups, lastWeekMuscleGroups];
  }, [strengthScoreDashboardChanges]);

  if (muscleType == null) {
    return (
      <div
        className="d-flex flex-column"
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
        }}
      >
        <div
          style={{
            position: "absolute",
            width: 130,
            height: 130,
            borderRadius: 65,
            zIndex: 2,
            top: "calc(50% - 65px)",
            left: "calc(50% - 65px)",
            backgroundColor: colors.caliber_secondary_gray_11,
          }}
        >
          {/* circle */}
        </div>
        <div
          style={{
            position: "absolute",
            width: 48,
            height: 48,
            borderRadius: 24,
            zIndex: 3,
            top: "calc(50% - 24px)",
            left: "calc(50% - 24px)",
            backgroundColor: colors.caliber_secondary_gray_29,
          }}
        >
          {/* circle */}
        </div>
        <div
          className="d-flex justify-content-center align-items-center medium-bold"
          style={{
            position: "absolute",
            fontSize: "20px",
            lineHeight: "24px",
            letterSpacing: "-0.011em",
            width: "100%",
            left: 0,
            top: "calc(50% + 88px)",
            color: colors.caliber_secondary_gray_47,
          }}
        >
          Select a muscle group to inspect
        </div>
      </div>
    );
  }

  return (
    <div
      className="d-flex flex-column"
      style={{
        backgroundColor: colors.caliber_white,
        minHeight: 789,
        borderRadius: 16,
        width: 762,
        padding: "32px 24px",
        marginLeft: 14,
        marginBottom: 70,
        position: "relative",
      }}
    >
      {isLoading ? (
        <div
          style={{
            position: "absolute",
            top: "40%",
            left: "calc(50% - 24px)",
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          <MuscleDetailsHeader muscleType={muscleType} onClose={onClose} />
          <div className="d-flex">
            <div className="d-flex flex-column">
              <MuscleDetailsContent
                lastMuscleGroup={lastWeekMuscleGroups[muscleType]}
                thisMuscleGroup={thisWeekMuscleGroups[muscleType]}
              />
              <MuscleDetailsChart muscleType={muscleType} />
            </div>
            <div>
              <MuscleDetailsSkeleton
                muscleGroup={thisWeekMuscleGroups[muscleType]}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getStrengthScoresMuscleGroup: (
    clientId: string,
    type: StrengthScoreMuscleGroupType
  ) => {
    dispatch(getStrengthScoresMuscleGroup(clientId, type));
  },
});

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  strengthScoreDashboard:
    state.clientStrengthDashboardState.strengthScoreDashboard,
  strengthScoreDashboardChanges:
    state.clientStrengthDashboardState.strengthScoreDashboardChanges,
  isLoading: state.clientStrengthDashboardState.isLoadingMuscleScores,
  muscleType: ownProps.muscleType,
  onClose: ownProps.onClose,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StrengthScoreMuscleDetails);
