import { CaliberRule } from "../../../types/gqlTypes";

export enum RulesActionType {
  GET_RULES_SUCCESS,
  GET_RULES_FAIL,
  GET_RULES_LOADING,
}

export interface RulesState {
  isLoading: boolean;
  rules: CaliberRule[];
}

export interface RulesAction {
  type: string;
  ruleList: CaliberRule[];
}
