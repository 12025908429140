import { ProgramType } from "../../../components/TrainingProgram";
import {
  ClientGymStepTemplate,
  ClientTrainingPlan,
  ClientWorkoutGroupTemplate,
  ClientWorkoutTemplate,
  CustomTrainingPlan,
  LibraryTrainingPlan,
  MasterGymStep,
  MasterTrainingPlan,
  MasterWorkout,
  Workout,
} from "../../../types/gqlTypes";
import {
  ADD_GYM_STEP_FAIL,
  ADD_GYM_STEP_LOADING,
  ADD_GYM_STEP_SUCCESS,
  ADD_NEW_CLIENT_WORKOUT_GROUP_SUCCESS,
  ADD_WORKOUT_TO_TRAINING_PLAN_FAIL,
  ADD_WORKOUT_TO_TRAINING_PLAN_LOADING,
  ADD_WORKOUT_TO_TRAINING_PLAN_SUCCESS,
  ASSIGN_TRAINING_PLAN_SUCCESS,
  BROWSE_MASTER_TRAINING_PLANS,
  CHANGE_PROGRAM_TYPE_TRAINING_PLAN,
  CHANGE_TRAINING_PLAN_COMPONENT_LOCATION,
  CLONE_MASTER_TRAINING_PLAN_FAIL,
  CLONE_MASTER_TRAINING_PLAN_LOADING,
  CLONE_MASTER_TRAINING_PLAN_SUCCESS,
  COPY_MASTER_WORKOUT_GROUP_TO_CLIENT_SUCCESS,
  COPY_TRAINING_PLAN_TO_CLIENTS_FAIL,
  COPY_TRAINING_PLAN_TO_CLIENTS_SAVING,
  COPY_TRAINING_PLAN_TO_CLIENTS_SUCCESS,
  COPY_TRAINING_PLAN_TO_TRAINER_FAIL,
  COPY_TRAINING_PLAN_TO_TRAINER_SAVING,
  COPY_TRAINING_PLAN_TO_TRAINER_SUCCESS,
  CREATE_CUSTOM_PLAN_IN_PROGRAM_LIBRARY,
  CREATE_TRAINING_PLAN_FAIL,
  CREATE_TRAINING_PLAN_SUCCESS,
  DELETE_CLIENT_WORKOUT_GROUP_FAIL,
  DELETE_CLIENT_WORKOUT_GROUP_LOADING,
  DELETE_CLIENT_WORKOUT_GROUP_SUCCESS,
  DELETE_GYM_STEP_FAIL,
  DELETE_GYM_STEP_LOADING,
  DELETE_GYM_STEP_SUCCESS,
  DELETE_TRAINING_PLAN_FAIL,
  DELETE_TRAINING_PLAN_LOADING,
  DELETE_TRAINING_PLAN_SUCCESS,
  DELETE_WORKOUT_FAIL,
  DELETE_WORKOUT_LOADING,
  DELETE_WORKOUT_SUCCESS,
  GET_TRAINING_PLAN_LAST_UPDATED_SUCCESS,
  GET_TRAINING_PLAN_LOADING,
  GET_TRAINING_PLAN_SUCCESS,
  GET_TRAINING_PLANS_FAIL,
  GET_TRAINING_PLANS_LOADING,
  GET_TRAINING_PLANS_SUCCESS,
  ON_CHANGE_FILTER_STATE_TRAINING_PLAN,
  ON_CLICK_NEW_OR_EDIT_MASTER_PROGRAM,
  ON_CLOSE_EDITING_MASTER_PLAN,
  ON_CLOSE_NEW_OR_EDIT_MASTER_PROGRAM,
  ON_STOP_CREATE_CUSTOM_PLAN_IN_PROGRAM_LIBRARY,
  OPEN_EXERCISE_LIBRARY_TRAINING_PLAN,
  REORDER_GYM_STEPS_IN_TRAINING_PLAN_FAIL,
  REORDER_GYM_STEPS_IN_TRAINING_PLAN_SAVING,
  REORDER_GYM_STEPS_IN_TRAINING_PLAN_SUCCESS,
  REORDER_WORKOUT_ACROSS_GROUPS_FAIL,
  REORDER_WORKOUT_ACROSS_GROUPS_SAVING,
  REORDER_WORKOUT_ACROSS_GROUPS_SUCCESS,
  REORDER_WORKOUT_FAIL,
  REORDER_WORKOUT_GROUP_FAIL,
  REORDER_WORKOUT_GROUP_SAVING,
  REORDER_WORKOUT_GROUP_SUCCESS,
  REORDER_WORKOUT_SAVING,
  REORDER_WORKOUT_SUCCESS,
  SELECT_CLIENT_TRAINING_PLAN,
  SELECT_TRAINING_PLAN,
  SELECT_WORKOUT_GROUP_TRAINING_PLAN,
  SELECT_WORKOUT_TRAINING_PLAN,
  SET_DEFAULT_WORKOUT_GROUP_FAIL,
  SET_DEFAULT_WORKOUT_GROUP_LOADING,
  SET_DEFAULT_WORKOUT_GROUP_SUCCESS,
  START_EDIT_WORKOUT_TRAINING_PLAN,
  STOP_EDIT_WORKOUT_TRAINING_PLAN,
  TOGGLE_COPY_TRAINING_PLAN_TO_CLIENTS,
  TOGGLE_COPY_TRAINING_PLAN_TO_TRAINER,
  TOGGLE_CREATE_CUSTOM_TRAINING_PLAN,
  TOGGLE_EDIT_TRAINING_PLAN,
  TOGGLE_EDIT_WORKOUT_GROUP,
  TOGGLE_FILTER_TRAINING_PLAN,
  TrainingProgramsAction,
  TrainingProgramsState,
  UPDATE_GYM_STEP_SUCCESS,
  UPDATE_TRAINING_PLAN_SUCCESS,
  UPDATE_WORKOUT_GROUP_FAIL,
  UPDATE_WORKOUT_GROUP_SUCCESS,
  UPDATE_WORKOUT_SUCCESS,
  VIEW_NEWLY_CREATED_CUSTOM_PLAN,
  COPY_CLIENT_WORKOUT_TEMPLATE_FAIL,
  COPY_CLIENT_WORKOUT_TEMPLATE_SAVING,
  COPY_CLIENT_WORKOUT_TEMPLATE_SUCCESS,
  OPEN_SUPERSET_EDITOR,
  OPEN_WORKOUTS,
  OPEN_WORKOUT_GROUPS,
  CLOSE_EXERCISE_SEARCH_LIBRARY,
  CLOSE_EXERCISE_LIST,
  OPEN_EXERCISE_LIST,
  CLOSE_SUPERSET_EDITOR,
  CREATE_SUPERSET_LOADING,
  CREATE_SUPERSET_SUCCESS,
  CREATE_SUPERSET_FAIL,
  LOAD_TRAINING_PLAN_FROM_ROUTE_SUCCESS,
  CHANGE_CUSTOM_TRAINING_PLAN_TYPE,
  CLONE_CUSTOM_TRAINING_PLAN_SUCCESS,
  CLONE_CUSTOM_TRAINING_PLAN_LOADING,
  CLONE_CUSTOM_TRAINING_PLAN_FAIL,
  CREATE_LIBRARY_PLAN_IN_PROGRAM_LIBRARY,
} from "../../actions/TrainingPrograms/types";
import addReducer from "./add";
import copyReducer from "./copy";
import createAssignReducer from "./createAssign";
import deleteReducer from "./delete";
import getReducer from "./get";
import {
  changeTrainingPlanComponentLocation,
  onClickClientTrainingPlan,
  onClickTrainingPlan,
  onClickWorkout,
  onClickWorkoutGroup,
  toggleEditTrainingPlan,
  toggleEditWorkoutGroup,
} from "./helperFunctions";
import reorderReducer from "./reorder";
import updateReducer from "./update";

const initialState: TrainingProgramsState = {
  isLoading: false,
  isDeleting: false,
  isSaving: false,
  isLoadingPlan: false,
  isLoadingPlans: false,

  componentLocation: null,
  programType: null,

  lastUpdatedPlanDateById: new Map<string, Date>(),

  customPlansContent: null,
  masterPlansContent: null,
  clientPlans: null,
  cachedPlans: {},

  clientPlanIndex: null,
  planIndex: null,
  workoutIndex: null,

  filterState: {
    page: 0,
  },

  isEditPlan: null,
  isEditWorkout: null,

  showPlans: false,
  showCreateNewPlans: null,
  showFilter: null,
  showWorkouts: false,
  showExercises: null,
  showExerciseLibrary: null,
  showSupersetEditor: false,
  showWorkoutExerciseList: false,

  showCopyPlanToClients: null,
  showCopyPlanToTrainer: null,
  copyPlanToClients: null,
  copyPlanToTrainer: null,
  isCopying: null,
  failedToCreateCustomPlan: null,
  successToCreateCustomPlan: null,
};

export const initialListsState = {
  isDeleting: false,
  isSaving: false,
  isLoading: false,
  showExercises: false,
  showExerciseLibrary: false,
  showWorkoutExerciseList: false,
  showWorkouts: false,
  showPlans: false,
  showFilter: false,
  clientPlanIndex: null,
  planIndex: null,
  workoutGroupIndex: null,
  workoutIndex: null,
  isEditPlan: false,
  isEditWorkout: false,
  showCreateNewPlans: false,
  showSupersetEditor: false,
};

const trainingProgramsState = (
  state = initialState,
  action: TrainingProgramsAction
) => {
  switch (action.type) {
    case ON_CLOSE_EDITING_MASTER_PLAN:
      return {
        ...state,
        showExerciseLibrary: false,
        showExercises: false,
        showWorkouts: false,
        showPlans: true,
        showFilter: true,
      } as TrainingProgramsState;
    case ON_CLICK_NEW_OR_EDIT_MASTER_PROGRAM: {
      if (typeof action.index === "number") {
        return {
          ...state,
          editingMasterProgram: selectTrainingPlans(state)?.[action.index],
          showNewOrEditMasterProgramSection: true,
          showPlans: false,
          showFilter: false,
          showWorkouts: false,
          showExercises: false,
          showExerciseLibrary: false,
        } as TrainingProgramsState;
      }
      return {
        ...state,
        editingMasterProgram: null,
        showNewOrEditMasterProgramSection: true,
        showPlans: false,
        showFilter: false,
        showWorkouts: false,
        showExercises: false,
        showExerciseLibrary: false,
      } as TrainingProgramsState;
    }
    case ON_CLOSE_NEW_OR_EDIT_MASTER_PROGRAM: {
      return {
        ...state,
        editingMasterProgram: null,
        showNewOrEditMasterProgramSection: false,
        planIndex: null,
        showWorkouts: false,
        showPlans: true,
        showFilter: true,
      } as TrainingProgramsState;
    }
    case CHANGE_TRAINING_PLAN_COMPONENT_LOCATION:
      return changeTrainingPlanComponentLocation(state, initialState, action);
    case TOGGLE_EDIT_TRAINING_PLAN:
      return toggleEditTrainingPlan(state, action);
    case START_EDIT_WORKOUT_TRAINING_PLAN:
      return {
        ...state,
        showExercises: true,
        showWorkoutExerciseList: true,
        workoutIndex: action.index,
        isEditWorkout: true,
        showPlans: false,
      };
    case STOP_EDIT_WORKOUT_TRAINING_PLAN:
      return {
        ...state,
        isEditWorkout: false,
      };
    case BROWSE_MASTER_TRAINING_PLANS:
      return {
        ...state,
        ...initialListsState,
        clientPlanIndex: state.clientPlanIndex,
        programType: ProgramType.Master,
        showPlans: true,
      } as TrainingProgramsState;
    case CHANGE_PROGRAM_TYPE_TRAINING_PLAN:
      if (action.programType === state.programType) {
        return state;
      }
      return {
        ...state,
        ...initialListsState,
        clientPlanIndex: state.clientPlanIndex,
        programType: action.programType,
        plans: null,
        showPlans: true,
      } as TrainingProgramsState;
    case CHANGE_CUSTOM_TRAINING_PLAN_TYPE:
      if (action.customTrainingPlanType == state.customTrainingPlanType) {
        return state;
      }
      return {
        ...state,
        customTrainingPlanType: action.customTrainingPlanType,
      } as TrainingProgramsState;
    case TOGGLE_FILTER_TRAINING_PLAN:
      return {
        ...state,
        showWorkouts: false,
        showFilter: !state.showFilter,
        showPlans: true,
        showExercises: false,
        showExerciseLibrary: false,
      };
    case ON_CHANGE_FILTER_STATE_TRAINING_PLAN:
      return {
        ...state,
        filterState: action.filterState,
      };
    case OPEN_EXERCISE_LIBRARY_TRAINING_PLAN:
      return {
        ...state,
        showPlans: false,
        showWorkouts: false,
        showExercises: true,
        showWorkoutExerciseList: true,
        showExerciseLibrary: true,
      };
    case OPEN_SUPERSET_EDITOR:
      return {
        ...state,
        showPlans: false,
        showWorkouts: false,
        showExercises: true,
        showWorkoutExerciseList: true,
        showExerciseLibrary: false,
        showSupersetEditor: true,
        supersetId: action.superset.id,
      } as TrainingProgramsState;
    case CLOSE_SUPERSET_EDITOR:
      return {
        ...state,
        showWorkouts: true,
        showExercises: true,
        showWorkoutExerciseList: true,
        showExerciseLibrary: false,
        showSupersetEditor: false,
        supersetId: null,
      } as TrainingProgramsState;
    case OPEN_WORKOUTS:
      return {
        ...state,
        showPlans: false,
        showWorkouts: true,
        showExercises: true,
        showWorkoutExerciseList: true,
        showExerciseLibrary: false,
        showSupersetEditor: false,
        supersetId: null,
      } as TrainingProgramsState;
    case OPEN_WORKOUT_GROUPS:
      return {
        ...state,
        showPlans: false,
        showWorkouts: true,
        showExercises: false,
        showExerciseLibrary: false,
        showSupersetEditor: false,
        supersetId: null,
      } as TrainingProgramsState;
    case CLOSE_EXERCISE_LIST:
      return {
        ...state,
        showWorkoutExerciseList: false,
      } as TrainingProgramsState;
    case OPEN_EXERCISE_LIST:
      return {
        ...state,
        showWorkoutExerciseList: true,
      } as TrainingProgramsState;
    case CLOSE_EXERCISE_SEARCH_LIBRARY:
      return {
        ...state,
        showExerciseLibrary: false,
      };
    case SELECT_TRAINING_PLAN:
      return onClickTrainingPlan(state, action);
    case SELECT_CLIENT_TRAINING_PLAN:
      return onClickClientTrainingPlan(state, action);
    case SELECT_WORKOUT_GROUP_TRAINING_PLAN:
      return onClickWorkoutGroup(state, action);
    case SELECT_WORKOUT_TRAINING_PLAN:
      return onClickWorkout(state, action);

    case ASSIGN_TRAINING_PLAN_SUCCESS:
    case ADD_NEW_CLIENT_WORKOUT_GROUP_SUCCESS:
    case COPY_MASTER_WORKOUT_GROUP_TO_CLIENT_SUCCESS:
    case CREATE_TRAINING_PLAN_SUCCESS:
    case CREATE_TRAINING_PLAN_FAIL:
    case CREATE_CUSTOM_PLAN_IN_PROGRAM_LIBRARY:
    case CREATE_LIBRARY_PLAN_IN_PROGRAM_LIBRARY:
    case CLONE_MASTER_TRAINING_PLAN_SUCCESS:
    case CLONE_MASTER_TRAINING_PLAN_LOADING:
    case CLONE_MASTER_TRAINING_PLAN_FAIL:
    case CLONE_CUSTOM_TRAINING_PLAN_SUCCESS:
    case CLONE_CUSTOM_TRAINING_PLAN_LOADING:
    case CLONE_CUSTOM_TRAINING_PLAN_FAIL:
      return createAssignReducer(state, action);

    case UPDATE_GYM_STEP_SUCCESS:
    case UPDATE_WORKOUT_SUCCESS:
    case UPDATE_WORKOUT_GROUP_SUCCESS:
    case UPDATE_WORKOUT_GROUP_FAIL:
    case UPDATE_TRAINING_PLAN_SUCCESS:
    case SET_DEFAULT_WORKOUT_GROUP_SUCCESS:
    case SET_DEFAULT_WORKOUT_GROUP_FAIL:
    case SET_DEFAULT_WORKOUT_GROUP_LOADING:
    case CREATE_SUPERSET_LOADING:
    case CREATE_SUPERSET_SUCCESS:
    case CREATE_SUPERSET_FAIL:
      return updateReducer(state, action);

    case ADD_WORKOUT_TO_TRAINING_PLAN_FAIL:
    case ADD_WORKOUT_TO_TRAINING_PLAN_LOADING:
    case ADD_WORKOUT_TO_TRAINING_PLAN_SUCCESS:
    case ADD_GYM_STEP_FAIL:
    case ADD_GYM_STEP_LOADING:
    case ADD_GYM_STEP_SUCCESS:
      return addReducer(state, action);

    case GET_TRAINING_PLANS_LOADING:
    case GET_TRAINING_PLAN_LOADING:
    case GET_TRAINING_PLANS_SUCCESS:
    case GET_TRAINING_PLAN_LAST_UPDATED_SUCCESS:
    case GET_TRAINING_PLANS_FAIL:
    case GET_TRAINING_PLAN_SUCCESS:
    case LOAD_TRAINING_PLAN_FROM_ROUTE_SUCCESS:
      return getReducer(state, action);

    case REORDER_WORKOUT_FAIL:
    case REORDER_WORKOUT_SAVING:
    case REORDER_WORKOUT_SUCCESS:
    case REORDER_WORKOUT_ACROSS_GROUPS_FAIL:
    case REORDER_WORKOUT_ACROSS_GROUPS_SAVING:
    case REORDER_WORKOUT_ACROSS_GROUPS_SUCCESS:
    case REORDER_WORKOUT_GROUP_FAIL:
    case REORDER_WORKOUT_GROUP_SAVING:
    case REORDER_WORKOUT_GROUP_SUCCESS:
    case REORDER_GYM_STEPS_IN_TRAINING_PLAN_FAIL:
    case REORDER_GYM_STEPS_IN_TRAINING_PLAN_SAVING:
    case REORDER_GYM_STEPS_IN_TRAINING_PLAN_SUCCESS:
      return reorderReducer(state, action);

    case DELETE_TRAINING_PLAN_SUCCESS:
    case DELETE_TRAINING_PLAN_FAIL:
    case DELETE_TRAINING_PLAN_LOADING:
    case DELETE_WORKOUT_LOADING:
    case DELETE_WORKOUT_SUCCESS:
    case DELETE_WORKOUT_FAIL:
    case DELETE_GYM_STEP_LOADING:
    case DELETE_GYM_STEP_FAIL:
    case DELETE_GYM_STEP_SUCCESS:
    case DELETE_CLIENT_WORKOUT_GROUP_LOADING:
    case DELETE_CLIENT_WORKOUT_GROUP_SUCCESS:
    case DELETE_CLIENT_WORKOUT_GROUP_FAIL:
      return deleteReducer(state, action);

    case ON_STOP_CREATE_CUSTOM_PLAN_IN_PROGRAM_LIBRARY:
    case TOGGLE_COPY_TRAINING_PLAN_TO_CLIENTS:
    case TOGGLE_COPY_TRAINING_PLAN_TO_TRAINER:
    case COPY_TRAINING_PLAN_TO_CLIENTS_SAVING:
    case COPY_TRAINING_PLAN_TO_CLIENTS_SUCCESS:
    case COPY_TRAINING_PLAN_TO_CLIENTS_FAIL:
    case COPY_TRAINING_PLAN_TO_TRAINER_SAVING:
    case COPY_TRAINING_PLAN_TO_TRAINER_SUCCESS:
    case COPY_TRAINING_PLAN_TO_TRAINER_FAIL:
    case VIEW_NEWLY_CREATED_CUSTOM_PLAN:
    case TOGGLE_CREATE_CUSTOM_TRAINING_PLAN:
    case COPY_CLIENT_WORKOUT_TEMPLATE_FAIL:
    case COPY_CLIENT_WORKOUT_TEMPLATE_SAVING:
    case COPY_CLIENT_WORKOUT_TEMPLATE_SUCCESS:
      return copyReducer(state, action);

    case TOGGLE_EDIT_WORKOUT_GROUP:
      return toggleEditWorkoutGroup(state, action);

    default:
      return state;
  }
};

export default trainingProgramsState;

export const selectTrainingPlans = (
  state: TrainingProgramsState
): (ClientTrainingPlan | MasterTrainingPlan | CustomTrainingPlan)[] => {
  const { programType, clientPlans, customPlansContent, masterPlansContent } =
    state;
  if (programType === ProgramType.Client) {
    return clientPlans;
  }
  if (programType === ProgramType.Custom) {
    return customPlansContent?.content;
  }
  if (programType === ProgramType.Master) {
    return masterPlansContent?.content;
  }
  return null;
};
export const selectTrainingPlan = (
  state: TrainingProgramsState
):
  | ClientTrainingPlan
  | MasterTrainingPlan
  | CustomTrainingPlan
  | LibraryTrainingPlan => {
  const {
    programType,
    clientPlans,
    planIndex,
    clientPlanIndex,
    customPlansContent,
    libraryPlansContent,
    masterPlansContent,
    cachedPlans,
  } = state;
  if (programType === ProgramType.Client) {
    const planId = clientPlans?.[clientPlanIndex]?.id;
    return cachedPlans?.[planId] as ClientTrainingPlan;
  }
  if (programType === ProgramType.Custom) {
    const planId = customPlansContent?.content?.[planIndex]?.id;
    return cachedPlans?.[planId] as CustomTrainingPlan;
  }
  if (programType === ProgramType.Library) {
    const planId = libraryPlansContent?.content?.[planIndex]?.id;
    return cachedPlans?.[planId] as LibraryTrainingPlan;
  }
  if (programType === ProgramType.Master) {
    const planId = masterPlansContent?.content?.[planIndex]?.id;
    return cachedPlans?.[planId] as MasterTrainingPlan;
  }
  return null;
};
export const selectWorkoutGroups = (
  state: TrainingProgramsState
): ClientWorkoutGroupTemplate[] => {
  return (selectTrainingPlan(state) as ClientTrainingPlan)
    ?.clientWorkoutGroupTemplates;
};
export const selectWorkouts = (
  state: TrainingProgramsState
): (MasterWorkout | ClientWorkoutTemplate)[] => {
  const { programType, workoutGroupIndex } = state;
  if (programType === ProgramType.Client) {
    return (selectTrainingPlan(state) as ClientTrainingPlan)
      ?.clientWorkoutGroupTemplates[workoutGroupIndex].clientWorkoutTemplates;
  }
  if (programType === ProgramType.Custom) {
    return (selectTrainingPlan(state) as CustomTrainingPlan)
      ?.masterWorkoutGroups[0].masterWorkouts;
  }
  if (programType === ProgramType.Library) {
    return (selectTrainingPlan(state) as LibraryTrainingPlan)
      ?.masterWorkoutGroups[0].masterWorkouts;
  }
  if (programType === ProgramType.Master) {
    return (selectTrainingPlan(state) as MasterTrainingPlan)
      ?.masterWorkoutGroups[0].masterWorkouts;
  }
  return null;
};
export const selectGymSteps = (
  state: TrainingProgramsState
): (MasterGymStep | ClientGymStepTemplate)[] => {
  const { workoutIndex } = state;
  const workouts = selectWorkouts(state);
  return (workouts?.[workoutIndex] as Workout)?.gymSteps;
};
