import React from "react";
import { connect } from "react-redux";
import { StoreState } from "../../redux/reducers";
import colors from "../../assets/colors";
import StrengthTable from "../StrengthTable";
import StrengthArc from "../StrengthArc/index";
import {
  DashboardTypes,
  StrengthBalanceInfos,
  StrengthScoreInfos,
} from "../../models/strengthscore";
import StrengthChart from "../StrengthChart/index";
import {
  StrengthScoreDashboard,
  StrengthScoreLight,
  User,
} from "../../types/gqlTypes";
import StrengthSkeleton from "./StrengthSkeleton";
import StrengthDashboardHeader from "./StrengthDashboardHeader";

interface OwnProps {
  dashboardType: DashboardTypes;
}
interface Props extends OwnProps {
  client: User;
  strengthScoreDashboard: StrengthScoreDashboard;
  historicalStrengthScore?: StrengthScoreLight[];
  historicalStrengthBalance?: StrengthScoreLight[];
}

const StrengthDashboard = (props: Props) => {
  const {
    dashboardType,
    client,
    strengthScoreDashboard,
    historicalStrengthScore,
    historicalStrengthBalance,
  } = props;
  const width = 840;
  const isStrengthScore = dashboardType === DashboardTypes.score;

  const strengthValue = isStrengthScore
    ? strengthScoreDashboard?.overallStrengthScore?.score
    : Math.round(strengthScoreDashboard?.overallStrengthScore?.balance * 100) /
      100;
  const strengthInfo = isStrengthScore
    ? StrengthScoreInfos
    : StrengthBalanceInfos;
  const strengthLevelProperty = strengthInfo.find((info) => {
    return strengthValue >= info.startNumber && strengthValue < info.endNumber;
  }) || { ...strengthInfo[0] };

  return (
    <div
      className="d-flex flex-column"
      style={{
        backgroundColor: colors.caliber_white,
        borderRadius: 8,
        width,
        height: 464,
        padding: "32px 24px",
      }}
    >
      <StrengthDashboardHeader dashboardType={dashboardType} />

      <div className="d-flex">
        <StrengthArc
          strengthValue={strengthValue}
          strengthLevelProperty={strengthLevelProperty}
          dashboardType={dashboardType}
        />

        {dashboardType === DashboardTypes.score && (
          <StrengthTable tableType={dashboardType} />
        )}

        {dashboardType === DashboardTypes.balance && (
          <StrengthSkeleton
            muscleGroups={strengthScoreDashboard?.muscleGroupScores}
            gender={client?.gender}
          />
        )}

        <StrengthChart
          areaColor={strengthLevelProperty.areaColor}
          lineColor={strengthLevelProperty.lineColor}
          strengthType={dashboardType}
          historicalStrengthScore={historicalStrengthScore}
          historicalStrengthBalance={historicalStrengthBalance}
        />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({});
const mapStateToProps = (state: StoreState) => ({
  client: state.clientDetailState.user,
  strengthScoreDashboard:
    state.clientStrengthDashboardState.strengthScoreDashboard,
  historicalStrengthScore:
    state.clientStrengthDashboardState.historicalStrengthScore,
  historicalStrengthBalance:
    state.clientStrengthDashboardState.historicalStrengthBalance,
});

export default connect(mapStateToProps, mapDispatchToProps)(StrengthDashboard);
