import React from "react";
import colors from "../../assets/colors";

interface Props {
  title: string;
  list: string[];
}

const ListSection = (props: Props) => {
  const { title, list } = props;

  return (
    <div
      className="d-flex flex-column"
      style={{
        minHeight: 100,
        backgroundColor: colors.caliber_white,
        borderRadius: 8,
        padding: 24,
        flex: 3,
        marginBottom: 32,
        marginRight: 24,
      }}
    >
      <div
        className="medium-bold"
        style={{
          fontSize: "16px",
          lineHeight: "16px",
          color: colors.caliber_secondary_gray_29,
          marginLeft: 12,
          marginBottom: 24,
        }}
      >
        {title}
      </div>

      <div className="d-flex flex-column">
        {list.map((item, index) => {
          return (
            <div
              className="medium-bold"
              style={{
                fontSize: "16px",
                lineHeight: "16px",
                border: `1px solid ${colors.caliber_gray_border}`,
                color: "#000632",
                padding: 16,
                height: 56,
                borderRadius: 8,
                marginTop: index > 0 && 12,
              }}
            >
              {item}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ListSection;
