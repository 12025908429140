import React from "react";
import colors from "../../assets/colors";

interface Props {
  value: string;
  title: string;
}

const Bio = (props: Props) => {
  const { value, title } = props;

  return (
    <div
      className="d-flex flex-column"
      style={{
        minHeight: 200,
        padding: 24,
        backgroundColor: colors.caliber_white,
        borderRadius: 8,
        flex: 3,
        marginBottom: 32,
        marginRight: 24,
      }}
    >
      <div
        className="medium-bold"
        style={{
          fontSize: "16px",
          lineHeight: "16px",
          color: colors.caliber_secondary_gray_29,
          marginLeft: 12,
          marginBottom: 24,
        }}
      >
        {title}
      </div>

      <div className="d-flex">
        <textarea
          disabled
          style={{
            border: `1px solid ${colors.caliber_gray_border}`,
            minHeight: 110,
            padding: 16,
            fontSize: "16px",
            lineHeight: "24px",
            color: "#000632",
            flex: 1,
            borderRadius: 8,
            backgroundColor: colors.caliber_white,
          }}
          value={value}
        />
      </div>
    </div>
  );
};

export default Bio;
