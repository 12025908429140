import React from "react";
import colors from "../../assets/colors";
import SimpleButton from "../SimpleButton";

interface Props {
  onSave: () => void;
  onCancel: () => void;
}

const Header = (props: Props) => {
  const { onSave, onCancel } = props;

  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-center">
        <SimpleButton
          className="d-flex justify-content-center align-items-center medium-bold"
          onClick={onCancel}
          style={{
            fontSize: "12px",
            lineHeight: "16px",
            color: colors.caliber_white,
            width: 134,
            height: 32,
            marginTop: 16,
            borderRadius: 4,
            backgroundColor: colors.caliber_gray_text,
          }}
        >
          Cancel
        </SimpleButton>
        <SimpleButton
          className="d-flex justify-content-center align-items-center medium-bold"
          onClick={onSave}
          style={{
            fontSize: "12px",
            lineHeight: "16px",
            color: colors.caliber_white,
            width: 134,
            height: 32,
            marginTop: 16,
            marginLeft: 16,
            borderRadius: 4,
            backgroundColor: colors.caliber_green_200,
          }}
        >
          Save
        </SimpleButton>
      </div>

      <div
        className="d-flex"
        style={{
          fontSize: "16px",
          lineHeight: "32px",
          color: colors.caliber_gray_text,
          marginTop: 57,
        }}
      >
        Edit Profile
      </div>
    </div>
  );
};

export default Header;
