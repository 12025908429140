import _ from "lodash";
import {
  Exercise,
  ExerciseInput,
  MediaUrl,
  MediaUrlInput,
  VideoLesson,
  VideoLessonInput,
  ExerciseTargetBaseType,
} from "../../../types/gqlTypes";

export enum MediaContext {
  Thumbnail,
  HeroImage,
  VideoLesson,
  VideoLoop,
  VideoLessonThumbnail,
}

export const mediaUrlToMediaUrlInput = (mediaUrl: MediaUrl): MediaUrlInput => {
  if (mediaUrl == null) {
    return null;
  }
  const mediaUrlInput = _.cloneDeep(mediaUrl);
  // eslint-disable-next-line no-underscore-dangle
  delete mediaUrlInput.__typename;
  return mediaUrlInput;
};

export const vidLessonToVidLessonInput = (
  videoLesson: VideoLesson
): VideoLessonInput => {
  const vidLessonInput = _.cloneDeep(videoLesson);
  if (vidLessonInput == null) {
    return null;
  }
  // eslint-disable-next-line no-underscore-dangle
  delete vidLessonInput.__typename;
  vidLessonInput.videoLessonUrl = mediaUrlToMediaUrlInput(
    vidLessonInput.videoLessonUrl
  );

  vidLessonInput.thumbnailUrl = mediaUrlToMediaUrlInput(
    vidLessonInput.thumbnailUrl
  );

  vidLessonInput.chapters.forEach((chapter) => {
    // eslint-disable-next-line no-param-reassign,no-underscore-dangle
    delete chapter.__typename;
  });

  return vidLessonInput;
};

export const exerciseInputFromExercise = (
  exercise?: Exercise
): ExerciseInput => {
  if (exercise == null) {
    return {
      media: {},
      primaryMuscleGroups: [],
      secondaryMuscleGroups: [],
      takeaways: [],
    };
  }
  const exerciseInput: ExerciseInput = {
    equipment: exercise.equipment,
    id: exercise.id,
    instructions: exercise.instructions,
    level: exercise.level,
    name: exercise.name,
    primaryMuscleGroups: [...exercise.primaryMuscleGroups],
    secondaryMuscleGroups: [...exercise.secondaryMuscleGroups],
    takeaways: [...exercise.takeaways],
    type: exercise.type,
    targetBase: exercise.targetBase || ExerciseTargetBaseType.Reps,
    includeBodyWeightInCalculations: exercise.includeBodyWeightInCalculations,
  };
  if (exercise.media) {
    exerciseInput.media = {
      heroImageUrl: mediaUrlToMediaUrlInput(exercise.media.heroImageUrl),
      id: exercise.media.id,
      thumbnailUrl: mediaUrlToMediaUrlInput(exercise.media.thumbnailUrl),
      videoLesson: vidLessonToVidLessonInput(exercise.media.videoLesson),
      videoUrl: mediaUrlToMediaUrlInput(exercise.media.videoUrl),
    };
  }

  return exerciseInput;
};

export const createVideoLessonObject = (): VideoLessonInput => {
  return {
    videoLessonUrl: {},
    thumbnailUrl: {},
    chapters: [
      {
        marker: 0,
        number: 1,
      },
      {
        marker: 0,
        number: 2,
      },
      {
        marker: 0,
        number: 3,
      },
      {
        marker: 0,
        number: 4,
      },
      {
        marker: 0,
        number: 5,
      },
    ],
  };
};

export const updateChapter = (
  exerciseInput: ExerciseInput,
  chapter: number,
  marker: number
): ExerciseInput => {
  const updatedInput = { ...exerciseInput };
  if (updatedInput.media == null) {
    updatedInput.media = {};
  }
  if (updatedInput.media.videoLesson == null) {
    updatedInput.media.videoLesson = createVideoLessonObject();
  }
  updatedInput.media.videoLesson.chapters[chapter].marker = marker;
  return updatedInput;
};
