import React, { useMemo } from "react";
import { connect } from "react-redux";
import { StoreState } from "../../redux/reducers";
import {
  Manager,
  UserType,
  Trainer,
  UserStatusType,
} from "../../types/gqlTypes";
import Input, { InputTypes } from "../Input";
import SearchDropdown, { DropdownItem } from "../SearchDropdown";
import { searchManagers } from "../../redux/actions/AdminTeamManagers";
import colors from "../../assets/colors";
import Dropdown from "../Dropdown";
import { toTitleCase } from "../../utils";

interface OwnProps {
  inputs: Item[];
  values: {
    role: UserType;
    firstName: string;
    lastName: string;
    email: string;
    status: UserStatusType;
    password: string;
    manager: string;
    assignedManagerId: string;
    isAvailable: string;
  };
  errorValues: {
    role: boolean;
    firstName: boolean;
    lastName: boolean;
    status: boolean;
    email: boolean;
    password: boolean;
    manager: boolean;
    isAvailable: boolean;
  };
  editMode?: boolean;
  viewMode?: boolean;
  userToEdit?: Manager & Trainer;
  onChange: (key: string, value: string) => void;
}

interface Props extends OwnProps {
  searchManagers: (page: number, searchCriteria: string) => void;
  foundManagers: Manager[];
  page: number;
  totalPages: number;
}

interface Item {
  key: string;
  type: InputTypes;
  label?: string;
  placeholder?: string;
  errorMessage?: string;
}
const roleItems = [
  { text: "Coach", value: UserType.Trainer },
  { text: "Manager", value: UserType.Manager },
];

const statusItems = [
  { text: "Active", value: UserStatusType.Active },
  { text: "Inactive", value: UserStatusType.Inactive },
];

const PersonalInfo = (props: Props) => {
  const {
    values,
    editMode,
    viewMode,
    userToEdit,
    errorValues,
    onChange,
    inputs,
    searchManagers,
    foundManagers,
    page,
    totalPages,
  } = props;

  const dropdown = inputs[inputs.length - 1];

  const searchItems: DropdownItem[] = useMemo(() => {
    return foundManagers.map((manager) => ({
      text: `${manager.firstName} ${manager.lastName}`,
      value: manager.id,
    }));
  }, [foundManagers]);

  return (
    <div className="d-flex flex-column">
      <div
        className="heading-small"
        style={{
          color: colors.caliber_secondary,
          marginTop: 30,
          marginBottom: 18,
        }}
      >
        Role
      </div>
      {viewMode || editMode ? (
        <div
          style={{
            height: 48,
            paddingLeft: 20,
          }}
        >
          {
            roleItems.filter((t) => {
              return t.value === values.role;
            })[0]?.text
          }
        </div>
      ) : (
        <Dropdown
          error={errorValues.role}
          errorMessage="Choose a role"
          placeholder="Choose role"
          value={values.role}
          items={roleItems}
          onSelect={(role) => onChange("role", role)}
        />
      )}
      <div
        className="heading-small"
        style={{
          color: colors.caliber_secondary,
          marginBottom: 10,
          marginTop: viewMode ? 10 : 30,
        }}
      >
        Personal Information
      </div>
      {viewMode && (
        <Input
          readOnly
          label="Id"
          bgColor={colors.caliber_gray_bg}
          borderBgColor={viewMode && colors.caliber_gray_bg}
          value={userToEdit.id}
          onChange={() => {}}
        />
      )}
      {inputs
        .filter(
          (input) =>
            !((viewMode || editMode) && input.type === InputTypes.Password)
        )
        .map(({ key, placeholder, type, errorMessage, label }) => {
          // TODO: remove readOnly on email input when user will be able to change email
          const readOnly = viewMode || (key === "email" && editMode);
          return (
            type !== "dropdown" && (
              <div key={key} className="d-flex">
                <Input
                  readOnly={readOnly}
                  bgColor={readOnly && colors.caliber_gray_bg}
                  borderBgColor={readOnly && colors.caliber_gray_bg}
                  label={label}
                  placeholder={placeholder}
                  value={values[key]}
                  onChange={(value) => onChange(key, value)}
                  type={type}
                  error={errorValues[key]}
                  errorMessage={errorMessage}
                />
              </div>
            )
          );
        })}

      {viewMode && (
        <Input
          readOnly
          label="Status"
          bgColor={colors.caliber_gray_bg}
          borderBgColor={viewMode && colors.caliber_gray_bg}
          value={toTitleCase(userToEdit.status)}
          onChange={() => {}}
        />
      )}

      {editMode && (
        <div
          style={{
            marginTop: 8,
          }}
        >
          <div className="d-flex flex-column">
            <div
              className="heading-xsmall"
              style={{
                marginLeft: 5,
              }}
            >
              Status
            </div>
            <div
              className="d-flex flex-column justify-content-center paragraph-large"
              style={{
                marginLeft: 20,
                height: 48,
              }}
            >
              <Dropdown
                error={errorValues.status}
                errorMessage="Choose a status"
                placeholder="Choose status"
                value={values.status}
                items={statusItems}
                onSelect={(status) => onChange("status", status)}
              />
            </div>
          </div>
        </div>
      )}

      {values?.role === UserType.Trainer && (
        <div
          style={{
            marginTop: 8,
          }}
        >
          {/* TODO: add dropdown when API to reassign manager will be ready */}
          {viewMode && userToEdit?.type === UserType.Trainer ? (
            <div className="d-flex flex-column">
              <div
                className="heading-xsmall"
                style={{
                  marginLeft: 5,
                }}
              >
                Manager
              </div>
              <div
                className="d-flex flex-column justify-content-center paragraph-large"
                style={{
                  marginLeft: 20,
                  height: 48,
                }}
              >
                {`${userToEdit?.assignedManager?.firstName} ${userToEdit?.assignedManager?.lastName}`}
              </div>
            </div>
          ) : (
            <SearchDropdown
              label="Manager"
              error={errorValues[dropdown.key]}
              errorMessage={dropdown.errorMessage}
              placeholder={dropdown.placeholder}
              value={values[dropdown.key]}
              items={searchItems}
              search={searchManagers}
              page={page}
              totalPages={totalPages}
              onSelect={(value) => onChange(dropdown.key, value)}
            />
          )}
        </div>
      )}
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  searchManagers: (page: number, searchCriteria: string) => {
    dispatch(
      searchManagers(
        page,
        searchCriteria,
        UserStatusType[UserStatusType.Active]
      )
    );
  },
});
const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  foundManagers: state.adminSearchManagersState.foundManagers,
  page: state.adminSearchManagersState.page,
  totalPages: state.adminSearchManagersState.totalPages,

  inputs: ownProps.inputs,
  values: ownProps.values,
  errorValues: ownProps.errorValues,
  onChange: ownProps.onChange,
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo);
