import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import rootReducer from './reducers';

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["userState", "masqueradeState", "recentEmojis"],
  // Note: Only use this for dev purposes, we should not persist authState in prod.
  // whitelist: ['userState', 'authState'],
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoreActions: true,
        ignoreState: true,
      },
      // Because old messenger breaks the immutability rule, should remove when migrated to new messenger
      immutableCheck: false,
    }),
  devTools: process.env.NODE_ENV === "development",
});

// Infer the `RootState` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export default () => {
  const persistor = persistStore(store);
  return { store, persistor };
};
