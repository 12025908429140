import React, { useEffect, useRef } from "react";
import { Image } from "react-bootstrap";
import Hls from "hls.js";
import {
  Exercise,
  ExerciseEquipmentType,
  ExerciseLevelType,
  ExerciseType,
  QuerySearchExercisesArgs,
  ExerciseTargetBaseType,
} from "../../types/gqlTypes";
import colors from "../../assets/colors";
import ExerciseLibraryTagSection from "../ExerciseLibraryTagSection";
import {
  exerciseEquipment,
  exerciseLevel,
  exerciseTypeText,
  exerciseBaseType,
} from "../../models/exercises";
import ExerciseLibraryMuscleGroup from "../ExerciseLibraryMuscleGroups";
import VideoLessonRow from "./VideoLessonRow";
import { FilterTypes } from "../../screens/AdminExerciseLibraryScreen";

interface Props {
  viewExerciseMode?: boolean;
  exercise?: Exercise;
  filterState?: QuerySearchExercisesArgs;
  onFilterTagClick?: (tag: any, type: FilterTypes) => void;
  asFilterComponent?: boolean;
}

const ExerciseLibraryDetailsView = (props: Props) => {
  const {
    viewExerciseMode,
    exercise,
    asFilterComponent,
    filterState,
    onFilterTagClick,
  } = props;

  const videoLessonRef = useRef();
  const videoLoopRef = useRef();

  const sectionSpacing = asFilterComponent ? 12 : 48;

  let selectedTagEquipment;
  let selectedTagLevel;
  let selectedTagType;
  let selectedTagsMuscle;
  if (asFilterComponent) {
    selectedTagEquipment = filterState.exerciseEquipmentCriteria
      ? [exerciseEquipment(filterState.exerciseEquipmentCriteria)]
      : [];
    selectedTagLevel = filterState.exerciseLevelCriteria
      ? [exerciseLevel(filterState.exerciseLevelCriteria)]
      : [];
    selectedTagType = filterState.exerciseTypeCriteria
      ? [exerciseTypeText(filterState.exerciseTypeCriteria)]
      : [];
    selectedTagsMuscle = filterState.muscleGroupCriteria
      ? filterState.muscleGroupCriteria
      : [];
  } else {
    selectedTagEquipment = [exerciseEquipment(exercise.equipment)];
    selectedTagLevel = [exerciseLevel(exercise.level)];
    selectedTagType = [exerciseTypeText(exercise.type)];
    selectedTagsMuscle = exercise.primaryMuscleGroups;
  }

  useEffect(() => {
    if (
      videoLessonRef &&
      exercise?.media?.videoLesson?.videoLessonUrl?.url != null
    ) {
      const hls = new Hls();
      hls.loadSource(exercise.media.videoLesson.videoLessonUrl.url);
      hls.attachMedia(videoLessonRef.current);
    }
  }, [videoLessonRef]);

  useEffect(() => {
    if (videoLoopRef && exercise?.media?.videoUrl?.url != null) {
      const hls = new Hls();
      hls.loadSource(exercise.media.videoUrl.url);
      hls.attachMedia(videoLoopRef.current);
    }
  }, [videoLoopRef]);

  return (
    <div
      className="d-flex flex-column"
      style={{
        marginLeft: 16,
        marginRight: 45,
        flex: 1,
      }}
    >
      <div
        className="heading-small"
        style={{
          color: colors.caliber_gray_text,
        }}
      >
        {!asFilterComponent && "View Exercise"}
      </div>
      {!asFilterComponent && (
        <div className="d-flex flex-column">
          <div
            className="heading-large"
            style={{
              marginTop: 32,
              marginLeft: 16,
            }}
          >
            {exercise.name}
          </div>
          <div
            className="paragraph-normal"
            style={{
              marginTop: 32,
              marginLeft: 16,
              marginBottom: 50,
            }}
          >
            {exercise.instructions}
          </div>
        </div>
      )}
      {asFilterComponent && (
        <div className="d-flex">
          <ExerciseLibraryTagSection
            isFilter={asFilterComponent}
            viewExerciseMode={viewExerciseMode}
            onTagSelected={() => {
              onFilterTagClick(null, FilterTypes.Warmups);
            }}
            title="Exercise Type"
            tags={["Exercise", "Warmup"]}
            selectedTags={filterState.showWarmups ? ["Warmup"] : ["Exercise"]}
          />
        </div>
      )}
      <div className="d-flex">
        <ExerciseLibraryTagSection
          isFilter={asFilterComponent}
          viewExerciseMode={viewExerciseMode}
          onTagSelected={
            asFilterComponent
              ? (tag) => {
                  if (asFilterComponent) {
                    let actualTag;
                    switch (tag) {
                      case exerciseLevel(ExerciseLevelType.Beginner):
                        actualTag = ExerciseLevelType.Beginner;
                        break;
                      case exerciseLevel(ExerciseLevelType.Advanced):
                        actualTag = ExerciseLevelType.Advanced;
                        break;
                    }
                    onFilterTagClick(actualTag, FilterTypes.Level);
                  }
                }
              : null
          }
          title="Level"
          tags={[
            exerciseLevel(ExerciseLevelType.Beginner),
            exerciseLevel(ExerciseLevelType.Advanced),
          ]}
          selectedTags={selectedTagLevel}
        />
        {exercise?.type !== ExerciseType.Warmup && (
          <ExerciseLibraryTagSection
            isFilter={asFilterComponent}
            viewExerciseMode={viewExerciseMode}
            onTagSelected={
              asFilterComponent
                ? (tag) => {
                    if (asFilterComponent) {
                      let actualTag;
                      switch (tag) {
                        case exerciseTypeText(ExerciseType.Strength):
                          actualTag = ExerciseType.Strength;
                          break;
                        case exerciseTypeText(ExerciseType.Cardio):
                          actualTag = ExerciseType.Cardio;
                          break;
                        case exerciseTypeText(ExerciseType.Mobility):
                          actualTag = ExerciseType.Mobility;
                          break;
                      }
                      onFilterTagClick(actualTag, FilterTypes.Type);
                    }
                  }
                : null
            }
            title="Type"
            tags={[
              exerciseTypeText(ExerciseType.Strength),
              exerciseTypeText(ExerciseType.Cardio),
              exerciseTypeText(ExerciseType.Mobility),
            ]}
            selectedTags={selectedTagType}
          />
        )}
      </div>
      {!asFilterComponent && (
        <div className="d-flex">
          <ExerciseLibraryTagSection
            viewExerciseMode={viewExerciseMode}
            title="Measurement"
            tags={[
              exerciseBaseType(ExerciseTargetBaseType.Reps),
              exerciseBaseType(ExerciseTargetBaseType.Time),
            ]}
            selectedTags={[exerciseBaseType(exercise?.targetBase)]}
          />
        </div>
      )}
      {exercise?.includeBodyWeightInCalculations && (
        <div
          style={{
            marginTop: 20,
            fontSize: 14,
            color: colors.caliber_secondary,
          }}
        >
          * Including body weight in achievement and Strength Score
          calculations.
        </div>
      )}
      <div
        className="d-flex flex-column"
        style={{
          marginTop: sectionSpacing,
        }}
      >
        <div
          className={`${!asFilterComponent && "medium-bold"}`}
          style={{
            marginBottom: asFilterComponent ? 8 : 16,
            fontSize: asFilterComponent ? "12px" : "14px",
            lineHeight: "32px",
            color: asFilterComponent
              ? colors.caliber_gray_border
              : colors.caliber_secondary,
          }}
        >
          Primary Muscle Groups
        </div>
        <ExerciseLibraryMuscleGroup
          isFilter={asFilterComponent}
          onMuscleSelected={
            asFilterComponent
              ? (tag) => {
                  if (asFilterComponent) {
                    onFilterTagClick(tag, FilterTypes.MuscleGroup);
                  }
                }
              : null
          }
          title="Primary Muscle Groups"
          selectedMuscles={selectedTagsMuscle}
        />
        {!asFilterComponent && (
          <ExerciseLibraryMuscleGroup
            title="Secondary Muscle Groups"
            selectedMuscles={exercise.secondaryMuscleGroups}
          />
        )}
      </div>
      <div
        className="d-flex"
        style={{
          marginTop: sectionSpacing,
        }}
      >
        <ExerciseLibraryTagSection
          isFilter={asFilterComponent}
          viewExerciseMode={viewExerciseMode}
          onTagSelected={
            asFilterComponent
              ? (tag) => {
                  if (asFilterComponent) {
                    const actualTag = Object.values(ExerciseEquipmentType).find(
                      (key) => tag === exerciseEquipment(key)
                    );
                    onFilterTagClick(actualTag, FilterTypes.Equipment);
                  }
                }
              : null
          }
          title="Equipment"
          tags={[
            exerciseEquipment(ExerciseEquipmentType.Assisted),
            exerciseEquipment(ExerciseEquipmentType.Barbell),
            exerciseEquipment(ExerciseEquipmentType.Bodyweight),
            exerciseEquipment(ExerciseEquipmentType.Cable),
            exerciseEquipment(ExerciseEquipmentType.Dumbbell),
            exerciseEquipment(ExerciseEquipmentType.EzBar),
            exerciseEquipment(ExerciseEquipmentType.FoamRoller),
            exerciseEquipment(ExerciseEquipmentType.HammerStrength),
            exerciseEquipment(ExerciseEquipmentType.Kettlebell),
            exerciseEquipment(ExerciseEquipmentType.Landmine),
            exerciseEquipment(ExerciseEquipmentType.Machine),
            exerciseEquipment(ExerciseEquipmentType.ResistanceBand),
            exerciseEquipment(ExerciseEquipmentType.SmithMachine),
            exerciseEquipment(ExerciseEquipmentType.TrapBar),
            exerciseEquipment(ExerciseEquipmentType.Trx),
            exerciseEquipment(ExerciseEquipmentType.Weighted),
          ]}
          selectedTags={selectedTagEquipment}
        />
      </div>
      {!asFilterComponent && (
        <div
          className="d-flex flex-wrap"
          style={{
            marginTop: sectionSpacing,
          }}
        >
          <div
            className="medium-bold"
            style={{
              marginRight: 42,
              fontSize: "14px",
              lineHeight: "32px",
              color: colors.caliber_secondary,
            }}
          >
            Thumbnail Image
            <div
              style={{
                width: 94,
                height: 94,
                backgroundColor: "white",
                borderRadius: 8,
                marginTop: 16,
              }}
            >
              {exercise?.media?.thumbnailUrl?.url && (
                <Image
                  style={{
                    height: 94,
                    width: 94,
                    objectFit: "contain",
                  }}
                  src={exercise?.media?.thumbnailUrl?.url}
                />
              )}
            </div>
          </div>

          <div
            className="medium-bold"
            style={{
              marginRight: 30,
              fontSize: "14px",
              lineHeight: "32px",
              color: colors.caliber_secondary,
            }}
          >
            Hero Image
            <div
              style={{
                height: 94,
                marginTop: 16,
              }}
            >
              {exercise?.media?.heroImageUrl?.url && (
                <Image
                  style={{
                    height: 94,
                    width: 166,
                    objectFit: "contain",
                  }}
                  src={exercise?.media?.heroImageUrl?.url}
                />
              )}
            </div>
          </div>

          {exercise?.media?.videoUrl && (
            <div
              className="medium-bold"
              style={{
                fontSize: "14px",
                lineHeight: "32px",
                color: colors.caliber_secondary,
              }}
            >
              Thumbnail Video
              <div
                style={{
                  height: 94,
                  width: 166,
                  marginTop: 16,
                }}
              >
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <video height={94} width={166} ref={videoLoopRef} controls />
              </div>
            </div>
          )}
        </div>
      )}
      {!asFilterComponent &&
        exercise?.media?.videoLesson?.thumbnailUrl?.url && (
          <div
            className="d-flex flex-column"
            style={{
              marginTop: sectionSpacing,
            }}
          >
            <div
              className="d-flex flex-column medium-bold"
              style={{
                fontSize: "14px",
                lineHeight: "32px",
                marginBottom: 16,
                color: colors.caliber_secondary,
              }}
            >
              201 video hero image
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              <div className="d-flex">
                <Image
                  style={{
                    height: 94,
                    width: 166,
                    objectFit: "contain",
                  }}
                  src={exercise?.media?.videoLesson?.thumbnailUrl?.url}
                />
              </div>
            </div>
          </div>
        )}
      {!asFilterComponent &&
        exercise?.media?.videoLesson?.videoLessonUrl?.url && (
          <div
            className="d-flex flex-column"
            style={{
              marginTop: sectionSpacing,
            }}
          >
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video
              style={{
                marginBottom: 4,
                borderRadius: 8,
              }}
              ref={videoLessonRef}
              controls
              width="100%"
            />
            <VideoLessonRow
              label="Intro"
              timeStamp={exercise.media.videoLesson.chapters[0].marker}
            />
            <VideoLessonRow
              label="Setup"
              timeStamp={exercise.media.videoLesson.chapters[1].marker}
            />
            <VideoLessonRow
              label="Movement"
              timeStamp={exercise.media.videoLesson.chapters[2].marker}
            />
            <VideoLessonRow
              label="Common Mistakes"
              timeStamp={exercise.media.videoLesson.chapters[3].marker}
            />
            <VideoLessonRow
              label="Takeaways"
              timeStamp={exercise.media.videoLesson.chapters[4].marker}
            />
          </div>
        )}
      {!asFilterComponent && (
        <div
          className="d-flex flex-column"
          style={{
            marginTop: sectionSpacing,
          }}
        >
          <div
            className="medium-bold"
            style={{
              marginBottom: 16,
              fontSize: "14px",
              lineHeight: "32px",
              color: colors.caliber_secondary,
            }}
          >
            Takeaways
            {exercise.takeaways.map((takeaway, index) => (
              <div
                key={`takeaway_${takeaway}`}
                className="d-flex flex-column paragraph-large"
                style={{
                  marginLeft: 16,
                  marginTop: sectionSpacing,
                }}
              >
                {`Takeaway ${index + 1}`}
                <div
                  className="paragraph-normal"
                  style={{
                    marginTop: 8,
                  }}
                >
                  {takeaway}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ExerciseLibraryDetailsView;
