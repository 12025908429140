import React, { useState } from "react";
import colors from "../../assets/colors";
import CloseIcon from "../Icons/CloseIcons";
import SimpleButton from "../SimpleButton";

interface Props {
  title: string;
  index: number;
  zIndex: number;
  children: React.ReactNode;
  onClose: () => void;
  onFocus: () => void;
}

const FloatingBox = (props: Props) => {
  const { title, index, zIndex, children, onClose, onFocus } = props;

  const [coord, setCoord] = useState({
    x: Math.round(Math.random() * 400),
    y: -Math.round(Math.random() * 600),
  });
  const [deltas, setDeltas] = useState({ deltaX: null, deltaY: null });
  const [size, setSize] = useState({ width: 400, height: 800 });
  const [rectCoord, setRectCoord] = useState({ x: 0, y: 0 });

  return (
    <div
      id={`relatedBox-${index}`}
      className="d-flex"
      style={{
        position: "relative",
      }}
    >
      <div
        role="button"
        tabIndex={0}
        onFocus={onFocus}
        className="d-flex flex-column"
        style={{
          position: "absolute",

          backgroundColor: colors.caliber_white,
          borderRadius: 8,
          boxShadow: "0px 2px 16px #808297",
          zIndex,

          top: coord.y,
          left: coord.x,
        }}
      >
        <div
          id={`dragBox-${index}`}
          draggable
          onDragStart={(e) => {
            const rect = (
              document.getElementById(`dragBox-${index}`) as HTMLElement
            )?.getBoundingClientRect?.();
            const relatedBox = (
              document.getElementById(`relatedBox-${index}`) as HTMLElement
            )?.getBoundingClientRect?.();
            const resizableBox = (
              document.getElementById(`resizableBox-${index}`) as HTMLElement
            )?.getBoundingClientRect?.();
            const deltaX = e.clientX - rect.left;
            const deltaY = e.clientY - rect.top;
            setDeltas({ deltaX, deltaY });
            setRectCoord({ x: relatedBox.left, y: relatedBox.top });
            setSize({ width: resizableBox.width, height: resizableBox.height });
          }}
          onDragEnd={(e) => {
            setDeltas({ deltaX: null, deltaY: null });
          }}
          onDragOver={(e) => {
            e?.target?.dispatchEvent(new MouseEvent("drag", e));
            e.preventDefault();
          }}
          onDrag={(e) => {
            if (
              typeof deltas.deltaX === "number" &&
              e.clientX + e.clientY > 0
            ) {
              setCoord({
                x: Math.max(0, e.clientX - rectCoord.x - deltas.deltaX),
                y: e.clientY - rectCoord.y - deltas.deltaY,
              });
            }
          }}
          className="d-flex justify-content-between"
          style={{
            background: colors.caliber_gray_bg,
            height: 25,
            fontSize: 16,
            cursor: "move",
          }}
        >
          {title}
          <SimpleButton onClick={onClose}>
            <CloseIcon width={20} height={20} />
          </SimpleButton>
        </div>
        <div
          id={`resizableBox-${index}`}
          style={{
            whiteSpace: "pre-line",
            padding: 16,
            overflow: "auto",
            height: size.height,
            width: size.width,
            resize: "both",
            minWidth: 100,
            minHeight: 100,
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default FloatingBox;
