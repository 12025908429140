import React, { useMemo } from "react";
import { format } from "date-fns";
import {
  CardioActivityCalendarItem,
  ImportedCardioActivityCalendarItem,
  CalendarItemType,
  CardioActivityType,
  CalendarDay,
  WorkoutSyncSourceType,
} from "../../types/gqlTypes";
import colors from "../../assets/colors";
import Modal from "../Modal";
import Loader from "../Loader";
import CalendarIcons, { CalendarIconType } from "../Icons/CalendarIcons";

interface Props {
  onClose: () => void;
  isLoading: boolean;
  day: Date;
  itemId: string;
  calendarDay: CalendarDay;
}

const CardioCalendarItem = (props: Props) => {
  const { isLoading, calendarDay, itemId, day, onClose } = props;

  const cardioTypesMap = {
    [CardioActivityType.OutdoorRun]: [
      "time",
      "calories",
      "averageHeartRate",
      "distance",
      "averagePace",
    ],
    [CardioActivityType.OutdoorWalk]: [
      "time",
      "calories",
      "averageHeartRate",
      "distance",
      "averagePace",
    ],
    [CardioActivityType.IndoorWalk]: [
      "time",
      "calories",
      "averageHeartRate",
      "distance",
      "averagePace",
    ],
    [CardioActivityType.IndoorRun]: [
      "time",
      "calories",
      "averageHeartRate",
      "distance",
      "averagePace",
    ],
    [CardioActivityType.Hiit]: [
      "time",
      "calories",
      "averageHeartRate",
      "distance",
      "averagePace",
    ],
    [CardioActivityType.PoolSwim]: [
      "time",
      "calories",
      "averageHeartRate",
      "distance",
      "averagePace",
    ],
    [CardioActivityType.Swimming]: [
      "time",
      "calories",
      "averageHeartRate",
      "distance",
      "averagePace",
    ],
    [CardioActivityType.OpenWaterSwim]: [
      "time",
      "calories",
      "averageHeartRate",
      "distance",
      "averagePace",
    ],
    [CardioActivityType.Hiking]: [
      "time",
      "calories",
      "averageHeartRate",
      "distance",
      "averagePace",
    ],
    [CardioActivityType.Rower]: [
      "time",
      "calories",
      "averageHeartRate",
      "distance",
      "averagePace",
      "level",
    ],
    [CardioActivityType.StairStepper]: [
      "time",
      "calories",
      "averageHeartRate",
      "distance",
      "averagePace",
      "level",
    ],
    [CardioActivityType.IndoorCycle]: [
      "time",
      "calories",
      "averageHeartRate",
      "distance",
      "averagePace",
      "speed",
    ],
    [CardioActivityType.Elliptical]: [
      "time",
      "calories",
      "averageHeartRate",
      "distance",
      "averagePace",
    ],
    [CardioActivityType.Yoga]: ["time", "calories", "averageHeartRate"],
    [CardioActivityType.OutdoorCycle]: [
      "time",
      "calories",
      "averageHeartRate",
      "distance",
      "averagePace",
      "elevationGain",
      "speed",
    ],

    [CardioActivityType.Running]: [
      "time",
      "calories",
      "distance",
      "averagePace",
      "averageHeartRate",
    ],
    [CardioActivityType.Walking]: [
      "time",
      "calories",
      "distance",
      "averagePace",
    ],
    [CardioActivityType.Elliptical]: [
      "time",
      "calories",
      "distance",
      "level",
      "averagePace",
    ],
    [CardioActivityType.Stairclimber]: [
      "time",
      "calories",
      "distance",
      "level",
      "averagePace",
    ],
    [CardioActivityType.Cycling]: [
      "time",
      "calories",
      "averageHeartRate",
      "distance",
      "averagePace",
      "elevationGain",
      "speed",
    ],
    [CardioActivityType.Rower]: [
      "time",
      "calories",
      "distance",
      "level",
      "averagePace",
    ],

    other: ["time", "calories", "distance", "averagePace", "level"],
  };
  const cardioTitleMap = {
    [CardioActivityType.Cycling]: "Cycling",
    [CardioActivityType.Elliptical]: "Elliptical",
    [CardioActivityType.General]: "General",
    [CardioActivityType.Hiit]: "HIIT",
    [CardioActivityType.Hiking]: "Hiking",
    [CardioActivityType.IndoorCycle]: "Indoor Cycle",
    [CardioActivityType.IndoorRun]: "Indoor Run",
    [CardioActivityType.IndoorWalk]: "Indoor Walk",
    [CardioActivityType.OpenWaterSwim]: "Open Water Swim",
    [CardioActivityType.OutdoorCycle]: "Outdoor Cycle",
    [CardioActivityType.OutdoorRun]: "Outdoor Run",
    [CardioActivityType.OutdoorWalk]: "Outdoor Walk",
    [CardioActivityType.PoolSwim]: "Pool Swim",
    [CardioActivityType.Rower]: "Rower",
    [CardioActivityType.Running]: "Running",
    [CardioActivityType.Stairclimber]: "Stairclimber",
    [CardioActivityType.StairStepper]: "Stair Stepper",
    [CardioActivityType.Swimming]: "Swimming",
    [CardioActivityType.Walking]: "Walking",
    [CardioActivityType.Yoga]: "Yoga",
  };
  const cardioTitles = {
    time: "Time",
    distance: "Distance",
    speed: "Avg Speed",
    calories: "Active Calories",
    averageHeartRate: "Avg Heart Rate",
    elevationGain: "Elevation Gain",
    averagePace: "Avg Pace",
    level: "Level",
  };

  const [cardioCalendarItem, items] = useMemo(() => {
    const calItem = calendarDay?.items?.find(
      (item) => item?.id === itemId
    ) as CardioActivityCalendarItem & ImportedCardioActivityCalendarItem;
    const titles =
      calItem?.cardioActivityType in cardioTypesMap
        ? cardioTypesMap?.[calItem?.cardioActivityType].map((value) => ({
            value,
            title: cardioTitles?.[value],
          }))
        : cardioTypesMap?.other.map((value) => ({
            value,
            title: cardioTitles?.[value],
          }));
    return [calItem, titles];
  }, [isLoading, itemId]);

  const dataObject = useMemo(() => {
    const object: any = { isImported: false };
    if (
      cardioCalendarItem?.time &&
      typeof cardioCalendarItem?.time === "number"
    ) {
      const h = Math.floor(cardioCalendarItem?.time / 3600);
      const m = Math.floor((cardioCalendarItem?.time - h * 3600) / 60);
      const s = cardioCalendarItem?.time - h * 3600 - m * 60;
      object.time = `${h > 0 ? `${h} hr ` : ""}${m} min`;
    }
    if (cardioCalendarItem.enumType === CalendarItemType.CardioActivity) {
      object.speed =
        cardioCalendarItem?.speed &&
        `${Number(cardioCalendarItem?.speed).toFixed(1)} MPH`;
      object.title = `Cardio: ${cardioCalendarItem?.title || "Other"}`;
      object.calories =
        cardioCalendarItem?.calories && `${cardioCalendarItem?.calories} cal`;
      object.distance =
        cardioCalendarItem?.distance &&
        `${Number(cardioCalendarItem?.distance).toFixed(1)} mi`;
      object.level = cardioCalendarItem?.level;
      object.isImported = false;
    } else if (
      cardioCalendarItem.enumType === CalendarItemType.ImportedCardioActivity
    ) {
      object.speed =
        cardioCalendarItem?.speed &&
        `${Number(cardioCalendarItem?.speed).toFixed(1)} MPH`;
      object.title = `Cardio: ${
        cardioTitleMap[cardioCalendarItem?.cardioActivityType] || "Other"
      }`;
      object.calories =
        cardioCalendarItem?.calories && `${cardioCalendarItem?.calories} cal`;
      object.distance =
        cardioCalendarItem?.distance &&
        `${Number(cardioCalendarItem?.distance).toFixed(1)} mi`;
      object.averageHeartRate =
        cardioCalendarItem?.averageHeartRate &&
        `${cardioCalendarItem?.averageHeartRate} BPM`;
      const pace =
        cardioCalendarItem?.distance &&
        Math.round(
          (cardioCalendarItem?.time || 0) / (cardioCalendarItem?.distance || 1)
        );
      const minutes = Math.floor(pace / 60);
      const seconds = Math.round(pace - minutes * 60);
      object.averagePace =
        pace &&
        `${minutes < 10 ? "0" : ""}${minutes}'${
          seconds < 10 ? "0" : ""
        }${seconds}" / mi`;
      object.elevationGain =
        cardioCalendarItem?.elevationGain &&
        `${Number(cardioCalendarItem?.elevationGain).toFixed(1)} FT`;
      object.level = cardioCalendarItem?.level;
      object.isImported = true;
      if (
        cardioCalendarItem.cardioActivityType === CardioActivityType.Swimming
      ) {
        const pace =
          cardioCalendarItem?.distance &&
          Math.round(
            (cardioCalendarItem?.time || 0) /
              Number(cardioCalendarItem?.distance * 17.6)
          );
        const minutes = Math.floor(pace / 60);
        const seconds = Math.round(pace - minutes * 60);
        object.distance =
          cardioCalendarItem?.distance &&
          `${Number(cardioCalendarItem?.distance * 1760).toFixed(0)} YD`;
        object.averagePace =
          pace &&
          `${minutes < 10 ? "0" : ""}${minutes}'${
            seconds < 10 ? "0" : ""
          }${seconds}" / 100YD`;
      }
    }
    return object;
  }, [cardioCalendarItem]);

  const subTitleStyle: React.CSSProperties = {
    color: colors.caliber_gray_text,
    fontSize: "14px",
    lineHeight: "14px",
    height: 16,
  };
  const tagStyle: React.CSSProperties = {
    color: colors.caliber_secondary,
    fontSize: "12px",
    lineHeight: "20px",
    letterSpacing: "-0.2px",
    backgroundColor: colors.caliber_secondary_gray_5,
    borderRadius: 8,
    height: 20,
    padding: "1px 8px",
  };
  const valueTagStyle: React.CSSProperties = {
    fontSize: "16px",
    lineHeight: "18px",
    color: colors.caliber_secondary,
    marginLeft: 8,
    marginTop: 2,
  };

  return (
    <Modal onClose={onClose} closeOnDimmerClick>
      <div
        className="d-flex flex-column"
        style={{
          padding: 24,
          width: 456,
          position: "relative",
        }}
      >
        {isLoading && (
          <div
            style={{
              position: "absolute",
              top: "48%",
              left: "48%",
            }}
          >
            <Loader />
          </div>
        )}
        <div className="d-flex justify-content-between">
          <div
            className="medium-bold"
            style={{
              color: colors.caliber_secondary,
              fontSize: "12px",
              lineHeight: "24px",
            }}
          >
            {`${format(day, "EEEE")}・${format(day, "MMM dd yyyy")}`}
          </div>
        </div>
        <div
          className="d-flex align-items-center medium-bold"
          style={{
            color: colors.caliber_secondary,
            fontSize: "20px",
            lineHeight: "24px",
            marginTop: 5,
          }}
        >
          <div style={{ width: 24, height: 24, marginRight: 10 }}>
            {dataObject.isImported ? (
              <CalendarIcons type={CalendarIconType.IMPORTED_CARDIO_RECT} />
            ) : (
              <CalendarIcons type={CalendarIconType.CARDIO_RECT} />
            )}
          </div>
          {dataObject.title}
        </div>
        <div
          className="d-flex flex-column"
          style={{
            height: 320,
          }}
        >
          <div
            style={{ margin: "20px 0px", width: 408 }}
            className="d-flex justify-content-between"
          >
            {dataObject.isImported && (
              <div className="d-flex">
                <div
                  className="medium-bold"
                  style={{
                    ...subTitleStyle,
                    color: colors.caliber_gray_text,
                  }}
                >
                  Imported from
                </div>
                <div
                  className="bold"
                  style={{
                    ...subTitleStyle,
                    marginLeft: 5,
                    color: colors.caliber_secondary,
                  }}
                >
                  {(cardioCalendarItem as ImportedCardioActivityCalendarItem)
                    ?.source === WorkoutSyncSourceType.GoogleFit
                    ? "Google Fit"
                    : ""}
                  {(cardioCalendarItem as ImportedCardioActivityCalendarItem)
                    ?.source === WorkoutSyncSourceType.Healthkit
                    ? "Apple Health"
                    : ""}
                </div>
              </div>
            )}
          </div>

          <div
            className="d-flex flex-wrap"
            style={{
              width: 408,
            }}
          >
            {items.map(({ title, value }) => {
              return (
                !!dataObject[value] && (
                  <div
                    key={value}
                    className="d-flex flex-column"
                    style={{
                      width: 204,
                      height: 68,
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <div
                        className="d-flex bold justify-content-center align-items-center"
                        style={tagStyle}
                      >
                        {title}
                      </div>
                    </div>
                    <div
                      className="d-flex bold align-items-center"
                      style={valueTagStyle}
                    >
                      {dataObject[value]}
                    </div>
                  </div>
                )
              );
            })}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CardioCalendarItem;
