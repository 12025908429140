import _ from "lodash";
import {
  SEARCH_TIMEZONES_FAIL,
  SEARCH_TIMEZONES_LOADING,
  SEARCH_TIMEZONES_SUCCESS,
  SearchTimezonesAction,
  SearchTimezonesState,
} from "../../actions/SearchTimezones/types";

const initialState: SearchTimezonesState = {
  isLoading: false,
  isLastPage: false,
  page: null,
  totalPages: null,
  timezones: null,
};

const searchTimezonesState = (
  state = initialState,
  action: SearchTimezonesAction
) => {
  switch (action.type) {
    case SEARCH_TIMEZONES_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case SEARCH_TIMEZONES_SUCCESS: {
      const isFirstPage = action.data.number === 0;
      let mergedData;
      if (!isFirstPage) {
        const collections = [...state.timezones, ...action.data.content];
        mergedData = _.uniqBy(collections, (item) => item.id);
      }
      return {
        ...state,
        isLoading: false,
        timezones: isFirstPage ? action.data.content : mergedData,
        page: action.data.number,
        isLastPage: action.data.last,
        totalPages: action.data.totalPages,
      };
    }
    case SEARCH_TIMEZONES_FAIL:
      return {
        ...state,
        isLoading: false,
        timezones: null,
        page: null,
        isLastPage: null,
        totalPages: null,
      };
    default:
      return state;
  }
};

export default searchTimezonesState;
