import React from "react";
import colors from "../../assets/colors";

interface Props {
  count: number;
}

const UnreadMessageCount = (props: Props) => {
  const { count } = props;

  if (count === 0) {
    return null;
  }

  if (count < 10) {
    return (
      <div
        className="d-flex align-items-center justify-content-center bold"
        style={{
          backgroundColor: colors.caliber_white,
          width: 14,
          height: 14,
          borderRadius: 7,
          position: "absolute",
          bottom: 11,
          right: 19,
        }}
      >
        <div
          className="d-flex align-items-center justify-content-center bold"
          style={{
            backgroundColor: "#F73843",
            color: colors.caliber_white,
            width: 12,
            height: 12,
            borderRadius: 6,
            fontSize: "10px",
            lineHeight: "10px",
          }}
        >
          {count}
        </div>
      </div>
    );
  }
  if (count < 100) {
    return (
      <div
        className="d-flex align-items-center justify-content-center bold"
        style={{
          backgroundColor: colors.caliber_white,
          width: 16,
          height: 14,
          borderRadius: 7,
          position: "absolute",
          bottom: 11,
          right: 19,
        }}
      >
        <div
          className="d-flex align-items-center justify-content-center bold"
          style={{
            backgroundColor: "#F73843",
            color: colors.caliber_white,
            width: 14,
            height: 12,
            borderRadius: 6,
            fontSize: "10px",
            lineHeight: "10px",
            letterSpacing: "-0.02em",
          }}
        >
          {count}
        </div>
      </div>
    );
  }

  return (
    <div
      className="d-flex align-items-center justify-content-center bold"
      style={{
        backgroundColor: colors.caliber_white,
        width: 20,
        height: 14,
        borderRadius: 7,
        position: "absolute",
        bottom: 11,
        right: 19,
      }}
    >
      <div
        className="d-flex align-items-center justify-content-center bold"
        style={{
          backgroundColor: "#F73843",
          color: colors.caliber_white,
          width: 18,
          height: 12,
          borderRadius: 6,
          fontSize: "10px",
          lineHeight: "10px",
          letterSpacing: "-0.02em",
        }}
      >
        {count}
      </div>
    </div>
  );
};

export default UnreadMessageCount;
