import React, { useEffect, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { connect } from "react-redux";
import { ResizableBox } from "react-resizable";
import colors from "../../../assets/colors";
import {
  changeSortingInClientsTab,
  closeColumnFilterInClientsTab,
  onHoverEnterResizingInClientsTab,
  onHoverLeaveResizingInClientsTab,
  openColumnFilterInClientsTab,
  startDraggingColumnInClientsTab,
  stopDraggingColumnInClientsTab,
} from "../../../redux/actions/ClientsTab";
import { ClientsTabColumnsType } from "../../../redux/actions/ClientsTab/types";
import {
  getIsAdminOrManager,
  getIsMasquerade,
  StoreState,
} from "../../../redux/reducers";
import {
  ClientPackageType,
  ClientSearchResultSortCriteria,
  SortOrder,
  UserStatusType,
} from "../../../types/gqlTypes";
import ResizeIcon from "../../Icons/ResizeIcon";
import SortButton from "../../Icons/SortButton";
import Filter from "./Filter";

interface Item {
  type: string;
  text: string;
  originalIndex: string;
}
interface OwnProps {
  text: ClientsTabColumnsType;
  isLastColumn?: boolean;
  width?: number;
  moveCard?: (text?: string, to?: number) => void;
  findCard?: (text?: string) => { index?: number };
  onResize?: (newWidth: number) => void;
  onResizeEnd: () => void;
}
interface Props extends OwnProps {
  sortOrder?: SortOrder;
  sortField?: ClientSearchResultSortCriteria;
  isSortingApplied?: boolean;
  statusCriteria?: UserStatusType[];
  clientPackageTypeCriteria?: ClientPackageType[];
  isFilterOpen?: boolean;
  whichColumnDragging?: ClientsTabColumnsType;
  isResizingHovered?: boolean;
  isAdminOrManager: boolean;
  isMasquerade: boolean;

  onDraggingStart?: (column: ClientsTabColumnsType) => void;
  onSort?: (
    sortOrder: SortOrder,
    sortField: ClientSearchResultSortCriteria
  ) => void;
  openColumnFilter?: (column: "status" | "packageType") => void;
  closeColumnFilter?: () => void;
  onDraggingStop: () => void;
  onHoverEnterResizing: () => void;
  onHoverLeaveResizing: () => void;
}

const HeaderCell = (props: Props) => {
  const {
    text,
    width,
    sortField,
    sortOrder,
    statusCriteria,
    clientPackageTypeCriteria,
    isFilterOpen,
    whichColumnDragging,
    isResizingHovered,
    isAdminOrManager,
    isMasquerade,
    onSort,
    moveCard,
    findCard,
    onDraggingStart,
    onResize,
    onResizeEnd,
    openColumnFilter,
    closeColumnFilter,
    onDraggingStop,
    onHoverEnterResizing,
    onHoverLeaveResizing,
  } = props;

  let isSortingApplied = sortField === (text as any);
  const borderColor = colors.table_gray;

  const card = findCard && findCard(text);
  const originalIndex = card ? card.index : 0;
  const [hoveredResizeIcon] = useState(false);
  const [hoveredDiv, setHoveredDiv] = useState(false);

  const [{ isDragging }, drag] = useDrag({
    item: { type: "columns_table", text, originalIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: !isResizingHovered,
    end: (dropResult, monitor) => {
      const { text: droppedText, originalIndex } = monitor.getItem();
      const didDrop = monitor.didDrop();
      if (moveCard && !didDrop) {
        moveCard(droppedText, originalIndex);
      }
    },
  });

  const [, drop] = useDrop({
    accept: "columns_table",
    canDrop: () => false,
    hover({ text: droppedText }: Item, monitor) {
      if (findCard && droppedText !== text) {
        const card = findCard(text);
        if (moveCard && card && card.index > -1) {
          moveCard(droppedText, card.index);
        }
      }
    },
    collect: (monitor) => ({
      didDrop: monitor.didDrop(),
    }),
  });

  useEffect(() => {
    if (isDragging) {
      onDraggingStart(text);
    }
  }, [isDragging]);

  useEffect(() => {
    if (!isDragging && text === whichColumnDragging) {
      onDraggingStop();
    }
  }, [isDragging, whichColumnDragging]);

  let column;
  let isAsc = sortOrder === SortOrder.Asc;
  let actualSortField = text as any;
  let onAscClick = () => onSort(SortOrder.Asc, actualSortField);
  let onDescClick = () => onSort(SortOrder.Desc, actualSortField);
  if (text === ClientsTabColumnsType.age) {
    isSortingApplied = sortField === ClientSearchResultSortCriteria.BirthDate;
    actualSortField = ClientSearchResultSortCriteria.BirthDate;
  }
  if (text === ClientsTabColumnsType.trainerFullName) {
    isSortingApplied =
      sortField === ClientSearchResultSortCriteria.TrainerLastName;
    actualSortField = ClientSearchResultSortCriteria.TrainerLastName;
  }
  if (text === ClientsTabColumnsType.clientPackageType) {
    column = "packageType";
  } else if (text === ClientsTabColumnsType.status) {
    column = "status";
  }
  if (
    text === ClientsTabColumnsType.lastActiveDate ||
    text === ClientsTabColumnsType.lastDateClientSentMessage ||
    text === ClientsTabColumnsType.lastDateClientReceivedMessage ||
    text === ClientsTabColumnsType.mostRecentlyCompletedWorkoutDate
  ) {
    isAsc = sortOrder === SortOrder.Desc;
    onAscClick = () => onSort(SortOrder.Desc, text as any);
    onDescClick = () => onSort(SortOrder.Asc, text as any);
  }

  const headerTitles = {
    [ClientsTabColumnsType.age]: "Age",
    [ClientsTabColumnsType.strengthScore]: "Strength score",
    [ClientsTabColumnsType.strengthBalance]: "Strength balance",
    [ClientsTabColumnsType.armsMuscleGroupScore]: "Arms score",
    [ClientsTabColumnsType.backMuscleGroupScore]: "Back score",
    [ClientsTabColumnsType.chestMuscleGroupScore]: "Chest score",
    [ClientsTabColumnsType.legsMuscleGroupScore]: "Legs score",
    [ClientsTabColumnsType.shouldersMuscleGroupScore]: "Shoulders score",
    [ClientsTabColumnsType.callsLeft]: "Calls left",
    [ClientsTabColumnsType.packageEndDate]: "Package End Date",
    [ClientsTabColumnsType.calories7DayAverage]: "Calories (7-day)",
    [ClientsTabColumnsType.carbs7DayAverage]: "Carbs (7-day)",
    [ClientsTabColumnsType.fat7DayAverage]: "Fat (7-day)",
    [ClientsTabColumnsType.protein7DayAverage]: "Protein (7-day)",
    [ClientsTabColumnsType.clientPackageType]: "Package type",
    [ClientsTabColumnsType.email]: "Email",
    [ClientsTabColumnsType.gender]: "Gender",
    [ClientsTabColumnsType.height]: "Height",
    [ClientsTabColumnsType.weight]: "Weight",
    [ClientsTabColumnsType.joinedDate]: "Member since",
    [ClientsTabColumnsType.lastActiveDate]: "Active",
    [ClientsTabColumnsType.lastDateClientReceivedMessage]: "Message sent",
    [ClientsTabColumnsType.lastDateClientSentMessage]: "Message received",
    [ClientsTabColumnsType.mostRecentlyCompletedWorkoutDate]:
      "Workout completed",
    [ClientsTabColumnsType.status]: "Status",
    [ClientsTabColumnsType.trainerFullName]: "Coach",
  };

  const resizeHandleDiv = (
    <div
      role="button"
      tabIndex={0}
      onClick={(e) => e.preventDefault()}
      className="nofocus"
      onKeyDown={() => {}}
      style={{
        position: "absolute",
        top: 0,
        right: -2,
        width: 12,
        height: 56,
        cursor: "ew-resize",
        zIndex: 3,
      }}
      onMouseEnter={() => onHoverEnterResizing()}
      onMouseLeave={() => onHoverLeaveResizing()}
    >
      {hoveredResizeIcon && <ResizeIcon />}
    </div>
  );

  const cellDiv = (
    <div
      id={`filter_cell_${column}`}
      className="d-flex align-items-center"
      style={{
        flex: 1,
        height: 56,
        backgroundColor: colors.caliber_white,
        borderRight: `1px solid ${borderColor}`,
        whiteSpace: "nowrap",
        overflow: "hidden",
        position: "relative",
        marginBottom: 8,
        paddingLeft: 10,
        paddingRight: 10,
      }}
    >
      <div
        ref={(node) => drag(drop(node))}
        role="button"
        tabIndex={0}
        onClick={(e) => {
          e.preventDefault();
          if (isSortingApplied) {
            onDescClick();
          } else {
            onAscClick();
          }
        }}
        onKeyDown={() => {}}
        onMouseEnter={() => setHoveredDiv(true)}
        onMouseLeave={() => setHoveredDiv(false)}
        onDragStart={(event) => {
          if (isResizingHovered) {
            event?.preventDefault();
            event?.stopPropagation();
          }
        }}
        className="d-flex align-items-center justify-content-between medium-bold nofocus pointer"
        id={text}
        style={{
          flex: 1,
          transform: "translate(0, 0)",
          backgroundColor: hoveredDiv ? "#E8EAEE" : colors.caliber_white,
          height: 28,
          color: colors.caliber_secondary,
          fontSize: "14px",
          lineHeight: "20px",
          border: "1px solid #F2F2F2",
          borderRadius: 8,
          paddingLeft: 8,
          paddingRight: 8,
        }}
      >
        <div>{headerTitles[text]}</div>

        <div
          className="d-flex"
          style={{
            marginLeft: 10,
          }}
        >
          <Filter
            showCancelled={isAdminOrManager && !isMasquerade}
            showFreemium={isAdminOrManager && !isMasquerade}
            column={column}
            text={text}
            isFilterOpen={isFilterOpen}
            statusCriteria={statusCriteria}
            clientPackageTypeCriteria={clientPackageTypeCriteria}
            onClose={closeColumnFilter}
            onOpenFilter={openColumnFilter}
          />
          <div
            style={{
              marginLeft: 4,
            }}
          >
            <SortButton
              isDefault={!isSortingApplied}
              isAsc={isAsc}
              onAscClick={onAscClick}
              onDescClick={onDescClick}
            />
          </div>
        </div>
      </div>
    </div>
  );

  const cellStyle: React.CSSProperties = {
    position: "sticky",
    top: 0,
    left: "auto",
    height: 64,
    zIndex: 2,
    opacity: isDragging ? 0 : 1,
    width,
    whiteSpace: "nowrap",
    backgroundColor: "#F2F3F5",
  };

  return (
    <th className="d-flex" style={cellStyle}>
      {whichColumnDragging ? (
        cellDiv
      ) : (
        <ResizableBox
          axis="x"
          width={width}
          height={56}
          minConstraints={[40, 56]}
          handle={resizeHandleDiv}
          handleSize={[18, 18]}
          onResize={(mouseEvent, data) => onResize(data?.size?.width)}
          onResizeStop={onResizeEnd}
        >
          {cellDiv}
        </ResizableBox>
      )}
    </th>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onSort: (sortOrder: SortOrder, sortField: ClientSearchResultSortCriteria) => {
    dispatch(changeSortingInClientsTab(sortOrder, sortField));
  },
  closeColumnFilter: () => {
    dispatch(closeColumnFilterInClientsTab());
  },
  openColumnFilter: (column: "status" | "packageType") => {
    dispatch(openColumnFilterInClientsTab(column));
  },
  onDraggingStart: (column: ClientsTabColumnsType) => {
    dispatch(startDraggingColumnInClientsTab(column));
  },
  onDraggingStop: () => {
    dispatch(stopDraggingColumnInClientsTab());
  },
  onHoverEnterResizing: () => {
    dispatch(onHoverEnterResizingInClientsTab());
  },
  onHoverLeaveResizing: () => {
    dispatch(onHoverLeaveResizingInClientsTab());
  },
});

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  isSortingApplied: state.clientsTabState.isSortingApplied,
  sortOrder: state.clientsTabState.sortOrder,
  sortField: state.clientsTabState.sortField,
  statusCriteria: state.clientsTabState.statusCriteria,
  clientPackageTypeCriteria: state.clientsTabState.clientPackageTypeCriteria,
  isFilterOpen: state.clientsTabState.isFilterOpen,
  whichColumnDragging: state.clientsTabState.whichColumnDragging,
  isResizingHovered: state.clientsTabState.isResizingHovered,
  isAdminOrManager: getIsAdminOrManager(state),
  isMasquerade: getIsMasquerade(state),

  text: ownProps.text,
  width: ownProps.width,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(HeaderCell);
