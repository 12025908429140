import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import colors from "../../assets/colors";
import SimpleButton from "../../components/SimpleButton";
import UserTable from "../../components/UserTable";
import ClientDetails from "../../components/UserTable/ClientDetails";
import SearchTrainerDropdown from "../../components/UserTable/SearchTrainerDropdown";
import UserTableSettings from "../../components/UserTable/UserTableSettings";
import {
  openNewClientInClientsTab,
  searchClientsTab,
  setTrainerFilter,
} from "../../redux/actions/ClientsTab";
import {
  getIsAdmin,
  getIsManager,
  getIsMasquerade,
  getIsTrainer,
  getTrainerId,
  StoreState,
} from "../../redux/reducers";
import { User } from "../../types/gqlTypes";
import { useDebounce } from "../../utils/customHooks";

interface Props {
  user: User;
  trainerId: string;
  isTrainer: boolean;
  isAdmin: boolean;
  isManager: boolean;
  isMasquerade: boolean;
  searchCriteria: string;
  trainerFilter: string | User;
  searchClientsTab: (args: {
    managerIdFilter?: string;
    trainerIdFilter?: string;
    searchCriteria: string;
    pageNumber: number;
  }) => void;
  openNewClientInClientsTab: () => void;
  setTrainerFilter: (trainerFilter: string | User) => void;
}

const TrainerClientsScreen = (props: Props) => {
  const {
    user,
    trainerId,
    isTrainer,
    isAdmin,
    isManager,
    isMasquerade,
    searchCriteria,
    trainerFilter,
    searchClientsTab,
    openNewClientInClientsTab,
    setTrainerFilter,
  } = props;
  const inputFocus = useRef(null);
  const [searchString, setSearchString] = useState(searchCriteria);
  const debouncedSearch = useDebounce(searchString, 400);

  useEffect(() => {
    const obj: {
      managerIdFilter?: string;
      trainerIdFilter?: string;
      searchCriteria: string;
      pageNumber: number;
    } = {
      searchCriteria: "",
      pageNumber: 0,
    };
    if (isManager && !isMasquerade) {
      obj.managerIdFilter = user?.id;
    }
    if (trainerFilter !== "all") {
      obj.trainerIdFilter = (trainerFilter as User)?.id;
    } else {
      obj.trainerIdFilter = undefined;
    }
    if (isTrainer || isMasquerade) {
      obj.trainerIdFilter = trainerId;
    }
    obj.searchCriteria = debouncedSearch;
    searchClientsTab(obj);
  }, [isMasquerade, trainerId, debouncedSearch, trainerFilter]);

  useEffect(() => {
    inputFocus?.current?.focus?.();
  }, []);
  useEffect(() => {
    const table = document.getElementById("clients_tab_table");
    table.scrollTop = 0;
  }, [trainerFilter]);

  const currentText =
    trainerFilter === "all"
      ? "All Coaches"
      : `${(trainerFilter as User)?.firstName} ${
          (trainerFilter as User)?.lastName
        }`;
  const currentValue =
    trainerFilter === "all" ? "all" : (trainerFilter as User)?.id;

  return (
    <div className="d-flex  ">
      <div
        className="d-flex flex-column"
        style={{
          flex: 1,
        }}
      >
        <div
          className="d-flex"
          style={{
            margin: 24,
            height: 48,
          }}
        >
          <input
            className="bold"
            ref={inputFocus}
            style={{
              flex: 1,
              fontSize: "16px",
              lineHeight: "24px",
              padding: "13px 0px 11px 16px",
              backgroundColor: colors.caliber_secondary_gray_5,
              border: `1px solid ${colors.caliber_secondary_gray_5}`,
              borderRadius: 8,
              height: 48,
              marginRight: 24,
            }}
            value={searchString}
            onChange={(event) => setSearchString(event.target.value)}
            placeholder="Search Clients"
          />
          {(isAdmin || isManager) && !isMasquerade && (
            <SearchTrainerDropdown
              currentText={currentText}
              currentValue={currentValue}
              onSelect={(value) => setTrainerFilter(value)}
            />
          )}

          {isAdmin && !isMasquerade && (
            <SimpleButton
              className="d-flex align-items-center justify-content-center medium-bold"
              onClick={openNewClientInClientsTab}
              style={{
                fontSize: "12px",
                lineHeight: "16px",
                color: colors.caliber_white,
                borderRadius: 4,
                backgroundColor: colors.caliber_green_200,
                width: 134,
                height: 32,
                marginTop: 8,
                marginLeft: 44,
              }}
            >
              Add New
            </SimpleButton>
          )}
        </div>

        <UserTable />
      </div>

      <UserTableSettings />
      {/* @ts-ignore */}
      <ClientDetails />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  searchClientsTab: (args: {
    managerIdFilter?: string;
    trainerIdFilter?: string;
    searchCriteria: string;
    pageNumber: number;
  }) => {
    dispatch(searchClientsTab(args));
  },
  openNewClientInClientsTab: () => {
    dispatch(openNewClientInClientsTab());
  },
  setTrainerFilter: (trainerFilter: string | User) => {
    dispatch(setTrainerFilter(trainerFilter));
  },
});

const mapStateToProps = (state: StoreState) => ({
  user: state.authenticatedUserState.user,
  trainerId: getTrainerId(state),
  isTrainer: getIsTrainer(state),
  isAdmin: getIsAdmin(state),
  isManager: getIsManager(state),
  isMasquerade: getIsMasquerade(state),

  searchCriteria: state.clientsTabState.searchCriteria,
  trainerFilter: state.clientsTabState.trainerFilter,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrainerClientsScreen);
