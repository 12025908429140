import React from "react";

interface Props {
  width?: number;
}

const DefaultUserImage = (props: Props) => {
  const { width } = props;

  return (
    <svg
      width={width || 48}
      height={width || 48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="24" fill="#DADCE3" />
      <path
        d="M23.9995 23.0999C21.2755 23.0999 20.7235 18.9719 20.7235 18.9719C20.3995 16.8239 21.3835 14.3999 23.9635 14.3999C26.5555 14.3999 27.5395 16.8239 27.2155 18.9719C27.2155 18.9719 26.7235 23.0999 23.9995 23.0999ZM23.9995 26.1839L27.2635 23.9999C30.1315 23.9999 32.6875 26.7959 32.6875 29.4359V32.4239C32.6875 32.4239 28.3075 33.7799 23.9995 33.7799C19.6195 33.7799 15.3115 32.4239 15.3115 32.4239V29.4359C15.3115 26.7359 17.6395 24.0599 20.6755 24.0599L23.9995 26.1839Z"
        fill="#989BAD"
      />
    </svg>
  );
};

export default DefaultUserImage;
