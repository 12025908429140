import React from "react";
import { Image } from "react-bootstrap";
import { ClientPackageType } from "../../../types/gqlTypes";
import ClientPackageBadge from "../../ClientPackageBadge.tsx";
import DefaultUserImage from "../../Icons/DefaultUserImage";

interface Props {
  imageUrl?: string;
  firstName?: string;
  lastName?: string;
  packageType?: ClientPackageType;
}

const Profile = (props: Props) => {
  const { imageUrl, firstName, lastName, packageType } = props;

  return (
    <>
      <div
        style={{
          width: 96,
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        {imageUrl ? (
          <Image
            roundedCircle
            src={imageUrl}
            style={{
              width: 96,
              height: 96,
              objectFit: "cover",
            }}
          />
        ) : (
          <DefaultUserImage width={96} />
        )}
      </div>

      <div
        className="bold"
        style={{
          top: 200,
          left: 0,
          fontSize: "24px",
          lineHeight: "24px",
          letterSpacing: "-0.033em",
          color: "#0D0E10",
          width: 400,
          textAlign: "center",
        }}
      >
        {`${firstName} ${lastName}`}
        <div
          className="d-flex justify-content-center"
          style={{
            marginTop: 8,
          }}
        >
          {packageType && (
            <ClientPackageBadge clientPackageType={packageType} />
          )}
        </div>
      </div>
    </>
  );
};

export default Profile;
