import { gql } from "apollo-boost";

export const SEND_REMINDERS_TO_SEGMENT = gql`
  query SendRemindersToSegment($clientId: UUID!, $targetDate: LocalDate!) {
    sendRemindersToSegment(clientId: $clientId, targetDate: $targetDate)
  }
`;
export const GET_CALIBER_ERRORS_QUERY = gql`
  query GetCaliberErrors {
    caliberErrors {
      code
      defaultMessage
      name
    }
  }
`;
export const THROW_CALIBER_ERROR = gql`
  query ThrowCaliberError($caliberErrorNumber: Int) {
    throwCaliberError(caliberErrorNumber: $caliberErrorNumber)
  }
`;
export const THROW_HTTP_ERROR = gql`
  query ThrowHttpError {
    throwHttpError
  }
`;
export const GET_CLIENT_FOR_DEBUG_NUTRITION_COLUMNS = gql`
  query SearchClientsTab(
    $pageNumber: Int!
    $pageSize: Int!
    $searchCriteria: String
    $sortOrder: SortOrder!
    $sortField: ClientSearchResultSortCriteria!
    $statusCriteria: [UserStatusType]
    $clientPackageTypeCriteria: [ClientPackageType]
    $trainerIdFilter: UUID
  ) {
    searchClientsTab(
      pageNumber: $pageNumber
      pageSize: $pageSize
      searchCriteria: $searchCriteria
      sortOrder: $sortOrder
      sortField: $sortField
      statusCriteria: $statusCriteria
      clientPackageTypeCriteria: $clientPackageTypeCriteria
      trainerIdFilter: $trainerIdFilter
    ) {
      content {
        id
        calories7DayAverage
        protein7DayAverage
        fat7DayAverage
        carbs7DayAverage
        caloriesTarget
        proteinTarget
        fatTarget
        carbsTarget
      }
      last
      totalPages
      totalElements
      number
    }
  }
`;
export const FETCH_NUTRITION_CALENDAR_ITEMS_FOR_DEBUG = gql`
  query Calendar($clientId: UUID!, $startDate: LocalDate, $endDate: LocalDate) {
    calendarDateRange(
      clientId: $clientId
      startDate: $startDate
      endDate: $endDate
    ) {
      date
      items {
        id
        ... on ImportedNutritionCalendarItem {
          daySortOrder
          enumType
          id
          macronutrientEvents {
            date
            id
            value
            unit
            macronutrient
            nutritionSyncSourceType
          }
          sort
          status
          title
          type
        }
      }
    }
  }
`;
export const GET_ACL_DATA_FOR_CLIENT = gql`
  query GetAclDataForClient($className: String!, $id: UUID!) {
    acl(className: $className, id: $id)
  }
`;
