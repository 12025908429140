import * as SockJS from "sockjs-client";
import Stomp from "stompjs";
import { getAppConfig } from "../../../config";

const deleteUserFromPlatformWebSocket = (() => {
  const state = { stompClient: null };
  return {
    subscribe: (token: string, userId: string, cb: (info: any) => void) => {
      if (!state.stompClient) {
        const socket = new SockJS(`${getAppConfig().apiUrl}/websocket`);
        state.stompClient = Stomp.over(socket);
      }
      state.stompClient.connect({ Authorization: `Bearer ${token}` }, () => {
        state.stompClient.subscribe(`/topic/message/${userId}`, (info) => {
          cb(info);
        });
      });
    },

    unsubscribe: () => {
      if (state.stompClient && state.stompClient.connected) {
        state.stompClient.disconnect();
        delete state.stompClient;
      }
    },
  };
})();
Object.freeze(deleteUserFromPlatformWebSocket);
export default deleteUserFromPlatformWebSocket;
