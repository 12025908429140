import _ from "lodash";
import {
  ADMIN_TRAINERS_SEARCH_FAIL,
  ADMIN_TRAINERS_SEARCH_LOADING,
  ADMIN_TRAINERS_SEARCH_SUCCESS,
  ADMIN_TRAINER_UPDATING_SUCCESS,
  UPDATE_TRAINER_PROFILE_ICON,
  AdminSearchTrainersAction,
  AdminSearchTrainersState,
} from "../../actions/AdminTeamTrainers/types";

const initialState: AdminSearchTrainersState = {
  isLoading: false,
  foundTrainers: [],
  page: 0,
  totalPages: 0,
};

const adminSearchTrainersState = (
  state = initialState,
  action: AdminSearchTrainersAction
) => {
  switch (action.type) {
    case UPDATE_TRAINER_PROFILE_ICON: {
      const newTrainers = [...state.foundTrainers];
      const index = newTrainers.findIndex(
        (trainer) => trainer.id === action.id
      );
      newTrainers[index] = {
        ...newTrainers[index],
        profileIconMediaUrl: action.profileIconMediaUrl,
      };
      return {
        ...state,
        foundTrainers: newTrainers,
      };
    }
    case ADMIN_TRAINER_UPDATING_SUCCESS: {
      const newTrainers = [...state.foundTrainers];
      const index = newTrainers.findIndex(
        (trainer) => trainer.id === action.trainer.id
      );
      newTrainers[index] = {
        ...newTrainers[index],
        ...action.trainer,
      };
      return {
        ...state,
        foundTrainers: newTrainers,
      };
    }
    case ADMIN_TRAINERS_SEARCH_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case ADMIN_TRAINERS_SEARCH_SUCCESS:
      const isFirstPage = action.data.number === 0;
      let mergedData;
      if (!isFirstPage) {
        const collections = [...state.foundTrainers, ...action.data.content];
        mergedData = _.uniqBy(collections, (item) => item.id);
      }
      return {
        ...state,
        isLoading: false,
        foundTrainers: isFirstPage ? action.data.content : mergedData,
        page: action.data.number,
        totalPages: action.data.totalPages,
      };
    case ADMIN_TRAINERS_SEARCH_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default adminSearchTrainersState;
