import React from "react";

interface Props {
  error?: boolean;
}

const VideoMessageUploadIcon = (props: Props) => {
  const { error } = props;

  return (
    <svg
      style={{ display: "block" }}
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="48" cy="48" r="48" fill={error ? "#85131D" : "#272E5F"} />
      <path
        d="M70.1538 25.8461H68.3077V29.5384H62.7692V25.8461H33.2308V29.5384H27.6923V25.8461H25.8462C24.8289 25.8461 24 26.675 24 27.6922V68.3076C24 69.3248 24.8289 70.1538 25.8462 70.1538H27.6923V68.3076H33.2308V70.1538H62.7692V68.3076H68.3077V70.1538H70.1538C71.1711 70.1538 72 69.3248 72 68.3076V27.6922C72 26.675 71.1711 25.8461 70.1538 25.8461ZM33.2308 62.7691H27.6923V57.2307H33.2308V62.7691ZM33.2308 51.6922H27.6923V46.1538H33.2308V51.6922ZM33.2308 40.6153H27.6923V35.0768H33.2308V40.6153ZM56.4702 49.211L44.4646 56.2135C44.2689 56.3113 44.0548 56.3575 43.8425 56.3575C43.5877 56.3575 43.3403 56.2891 43.1151 56.1562C42.7089 55.9107 42.4615 55.4713 42.4615 55.0024V40.9993C42.4615 40.5285 42.7089 40.0891 43.1151 39.8436C43.5249 39.5962 44.0363 39.5741 44.4628 39.7882L56.4683 46.7888C56.9354 47.0196 57.2308 47.4867 57.2308 47.9999C57.2308 48.5131 56.9354 48.9802 56.4702 49.211ZM68.3077 62.7691H62.7692V57.2307H68.3077V62.7691ZM68.3077 51.6922H62.7692V46.1538H68.3077V51.6922ZM68.3077 40.6153H62.7692V35.0768H68.3077V40.6153Z"
        fill="#0C113A"
      />
    </svg>
  );
};

export default VideoMessageUploadIcon;
