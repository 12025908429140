import { gql } from "apollo-boost";
import { medialUrlFragment } from "../../calendar";
import { userFragment } from "..";

export const trainerFragment = gql`
  ${userFragment}
  ${medialUrlFragment}
  fragment TrainerProperty on Trainer {
    ...UserData
    proGroups {
      id
      name
    }
    assignedManager {
      ...UserData
    }
  }
`;

export const SEARCH_TRAINERS = gql`
  ${trainerFragment}
  query SearchTrainers(
    $page: Int!
    $searchCriteria: String
    $statusCriteria: [UserStatusType]
  ) {
    searchTrainers(
      page: $page
      searchCriteria: $searchCriteria
      statusCriteria: $statusCriteria
    ) {
      content {
        ...TrainerProperty
      }
      last
      number
      numberOfElements
      totalElements
      totalPages
    }
  }
`;

export const CREATE_TRAINER = gql`
  ${trainerFragment}
  mutation CreateTrainer(
    $firstName: String
    $lastName: String
    $email: String!
    $defaultPassword: String
    $assignedManagerId: UUID
    $profileIconMediaUrlId: UUID
    $type: UserType!
  ) {
    createUserProfile(
      firstName: $firstName
      lastName: $lastName
      email: $email
      defaultPassword: $defaultPassword
      assignedManagerId: $assignedManagerId
      profileIconMediaUrlId: $profileIconMediaUrlId
      type: $type
    ) {
      ...TrainerProperty
    }
  }
`;
export const UPDATE_TRAINER = gql`
  ${trainerFragment}
  mutation UpdateTrainer(
    $birthDate: String
    $city: String
    $country: String
    $dateMask: String
    $email: String
    $firstName: String
    $gender: GenderType
    $isConsultationCompleted: Boolean
    $isProfileCompleted: Boolean
    $lastName: String
    $phone: String
    $state: String
    $unitHeight: UnitType!
    $newAssignedManagerId: UUID
    $userId: UUID!
    $status: UserStatusType
  ) {
    updateUserProfile(
      birthDate: $birthDate
      city: $city
      country: $country
      dateMask: $dateMask
      email: $email
      firstName: $firstName
      gender: $gender
      isConsultationCompleted: $isConsultationCompleted
      isProfileCompleted: $isProfileCompleted
      lastName: $lastName
      phone: $phone
      state: $state
      unitHeight: $unitHeight
      newAssignedManagerId: $newAssignedManagerId
      userId: $userId
      status: $status
    ) {
      ...TrainerProperty
    }
  }
`;

export const REASSIGN_CLIENT_TRAINER = gql`
  ${userFragment}
  mutation ReassignClient($clientId: UUID, $trainerId: UUID) {
    reassignClient(clientId: $clientId, trainerId: $trainerId) {
      ...UserData
    }
  }
`;

export const GET_COACH_PROFILE = gql`
  query GetCoachProfile($trainerId: UUID) {
    coachProfile(trainerId: $trainerId) {
      bio
      certifications
      education
      funFacts
      id
      photos {
        id
        sortOrder
        mediaUrl {
          id
          url
        }
      }
    }
  }
`;

export const CREATE_UPDATE_COACH_PROFILE = gql`
  mutation CreateUpdateCoachProfile(
    $bio: String
    $certifications: String
    $education: String
    $funFacts: String
    $photos: [UUID]
    $trainerId: UUID
  ) {
    createUpdateCoachProfile(
      bio: $bio
      certifications: $certifications
      education: $education
      funFacts: $funFacts
      photos: $photos
      trainerId: $trainerId
    ) {
      id
    }
  }
`;

export const UPDATE_COACH_PROFILE_PIC_GQL = gql`
  mutation UpdateCoachProfilePic($trainerId: UUID!, $mediaUrlId: UUID!) {
    updateCoachProfileIconUrl(trainerId: $trainerId, mediaUrlId: $mediaUrlId) {
      id
      profileIconUrl
      type
      profileIconMediaUrl {
        url
        id
        type
      }
    }
  }
`;
