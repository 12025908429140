import {
  MediaAction,
  MediaState,
  MEDIA_IS_UPLOADING,
  MEDIA_UPLOAD_FAIL,
  MEDIA_UPLOAD_SUCCESS,
  RESET_MEDIA_STATE,
} from "../../actions/Media/types";

const initialState = (): MediaState => {
  return {
    isUploading: false,
    fileInfo: null,
    mediaUrl: null,
  };
};

const mediaState = (
  state = initialState(),
  action: MediaAction
): MediaState => {
  switch (action.type) {
    case MEDIA_IS_UPLOADING: {
      return {
        ...state,
        isUploading: true,
        fileInfo: null,
        mediaUrl: null,
      };
    }
    case MEDIA_UPLOAD_SUCCESS: {
      return {
        ...state,
        isUploading: false,
        fileInfo: action.response,
        mediaUrl: action.mediaUrl,
      };
    }
    case MEDIA_UPLOAD_FAIL:
      return {
        ...state,
        isUploading: false,
        fileInfo: null,
        mediaUrl: null,
      };
    case RESET_MEDIA_STATE:
      return {
        ...state,
        isUploading: false,
        fileInfo: null,
        mediaUrl: null,
      };
    default:
      return state;
  }
};

export default mediaState;
