import React from "react";
import { connect } from "react-redux";
import colors from "../../assets/colors";
import {
  changePageInClientsTab,
  changePageSizeInClientsTab,
} from "../../redux/actions/ClientsTab";
import { StoreState } from "../../redux/reducers";
import Dropdown from "../Dropdown";
import ArrowIcons from "../Icons/ArrowIcons";
import SimpleButton from "../SimpleButton";

interface Props {
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  totalElements: number;
  onPageSizeChange: (pageSize: number) => void;
  onPageChange: (page: number) => void;
}

const Pagination = (props: Props) => {
  const {
    pageNumber,
    pageSize,
    totalPages,
    totalElements,
    onPageSizeChange,
    onPageChange,
  } = props;

  const rowTextStyle: React.CSSProperties = {
    fontSize: "14px",
    lineHeight: "20px",
    color: colors.caliber_gray_text,
    marginLeft: 12,
    marginRight: 16,
  };
  const textStyle: React.CSSProperties = {
    fontSize: "14px",
    lineHeight: "20px",
    color: colors.caliber_gray_text,
    marginLeft: 12,
    marginRight: 16,
    width: 60,
  };

  const dropdownOptions = [10, 25, 50, 100, 250].map((value) => ({
    value,
    text: value.toString(),
  }));
  const page = totalPages === 0 ? 0 : pageNumber + 1;

  return (
    <div
      className="d-flex align-items-center justify-content-end"
      style={{
        height: 56,
        borderRadius: 8,
        backgroundColor: colors.caliber_white,
        marginTop: 8,
        marginRight: 33,
        marginLeft: 23,
      }}
    >
      <div
        className="d-flex align-items-center justify-content-center"
        style={{
          backgroundColor: colors.caliber_gray_bg,
          height: 32,
          borderRadius: 4,
          marginRight: 12,
        }}
      >
        <div style={rowTextStyle} className="medium-bold">
          Rows per page
        </div>

        <div
          style={{
            width: 50,
            height: 24,
            marginRight: 4,
          }}
        >
          <Dropdown
            dropup
            width={60}
            height={24}
            items={dropdownOptions}
            value={pageSize}
            onSelect={onPageSizeChange}
            customPadding="0px 0px 0px 8px"
            bgColor={colors.caliber_white}
          />
        </div>

        <div
          style={textStyle}
          className="d-flex justify-content-center medium-bold"
        >
          {page} of {totalPages || 0}
        </div>

        <SimpleButton
          nofocus
          disabled={pageNumber === 0}
          className="d-flex align-items-center justify-content-center"
          onClick={() => onPageChange(Math.max(pageNumber - 1, 0))}
          style={{
            width: 24,
            height: 24,
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
            backgroundColor: colors.caliber_white,
          }}
        >
          <ArrowIcons
            isLeft
            height={22}
            width={18}
            color={pageNumber !== 0 && "#808297"}
          />
        </SimpleButton>
        <SimpleButton
          nofocus
          disabled={pageNumber >= totalPages - 1}
          className="d-flex align-items-center justify-content-center"
          onClick={() => onPageChange(pageNumber + 1)}
          style={{
            width: 24,
            height: 24,
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
            backgroundColor: colors.caliber_white,
            marginLeft: 4,
            marginRight: 8,
          }}
        >
          <ArrowIcons
            isLeft={false}
            height={22}
            width={18}
            color={pageNumber < totalPages - 1 && "#808297"}
          />
        </SimpleButton>

        <div
          style={textStyle}
          className="d-flex justify-content-center medium-bold"
        >
          {totalElements} rows
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onPageSizeChange: (pageSize: number) => {
    dispatch(changePageSizeInClientsTab(pageSize));
  },
  onPageChange: (pageNumber: number) => {
    dispatch(changePageInClientsTab(pageNumber));
  },
});

const mapStateToProps = (state: StoreState) => ({
  pageNumber: state.clientsTabState.pageNumber,
  pageSize: state.clientsTabState.pageSize,
  totalPages: state.clientsTabState.totalPages,
  totalElements: state.clientsTabState.totalElements,
});

export default connect(mapStateToProps, mapDispatchToProps)(Pagination);
