import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useDrop } from "react-dnd";
import {
  Client,
  ClientTrainingPlan,
  ClientWorkoutGroupTemplate,
  WorkoutGroupType,
} from "../../types/gqlTypes";
import WorkoutGroupItem from "./WorkoutGroupItem";
import Loader from "../Loader";
import { StoreState } from "../../redux/reducers";
import {
  selectCurrentWorkoutGroups,
  selectFullClientPlan,
} from "../../redux/reducers/TrainingPrograms/selectors";
import AddTrainingPlanButton from "../AddTrainingPlanButton";
import MagnifyGlassIcon from "../../assets/images/MagnifyGlassIcon.svg";
import PlusIconDarker from "../../assets/images/PlusIconDarker.svg";
import { browseMasterTrainingPlans } from "../../redux/actions/TrainingPrograms";
import { addNewClientWorkoutGroup } from "../../redux/actions/TrainingPrograms/createAssign";
import { reorderClientWorkoutGroups } from "../../redux/actions/TrainingPrograms/reorder";

interface Props {
  workoutGroups: ClientWorkoutGroupTemplate[];
  isLoading?: boolean;
  selectedClient: Client;
  clientPlan: ClientTrainingPlan;
  browseMasterTrainingPlans: () => void;
  addNewClientWorkoutGroup: (clientId: string, trainingPlanId: string) => void;
  reorderClientWorkoutGroups: (clientId: string, groupIds: []) => void;
}

const WorkoutGroupList = (props: Props) => {
  const {
    workoutGroups,
    isLoading,
    selectedClient,
    clientPlan,
    browseMasterTrainingPlans,
    addNewClientWorkoutGroup,
    reorderClientWorkoutGroups,
  } = props;

  const [localItems, setLocalItems] = useState([]);

  const workoutGroupObjects = useMemo(() => {
    const object = {};
    workoutGroups &&
      workoutGroups.forEach((workoutGroup) => {
        object[workoutGroup.id] = workoutGroup;
      });
    return object;
  }, [workoutGroups]);

  useEffect(() => {
    if (workoutGroups) {
      setLocalItems(workoutGroups.map((workoutGroup) => workoutGroup.id));
    }
  }, [workoutGroups]);

  const moveWorkoutGroupCard = (id: string, toIndex: number) => {
    const { index } = findCardIndex(id);
    const items = [...localItems];
    if (index > -1) {
      const temp = items[index];
      items[index] = items[toIndex];
      items[toIndex] = temp;
      setLocalItems(items);
    } else {
      items.splice(toIndex, 0, id);
      setLocalItems(items);
    }
  };

  const findCardIndex = (id: string) => {
    return {
      index: localItems.indexOf(id),
    };
  };

  const onDropGroup = useCallback(() => {
    // @ts-ignore
    reorderClientWorkoutGroups(selectedClient.id, localItems);
  }, [localItems]);

  const [, dropGroupRef] = useDrop({
    accept: "workout_group",
    drop: onDropGroup,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      didDrop: monitor.didDrop(),
    }),
  });

  return (
    <div
      className="d-flex flex-column"
      style={{
        flex: 1,
      }}
    >
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: 30,
            left: "45%",
          }}
        >
          <Loader />
        </div>
      )}
      <div
        ref={dropGroupRef}
        style={{
          flex: 1,
          padding: 0,
        }}
      >
        {localItems &&
          localItems.map((workoutGroupId, index) => {
            const workoutGroup = workoutGroupObjects?.[workoutGroupId];
            return (
              workoutGroup && (
                <WorkoutGroupItem
                  // @ts-ignore
                  index={index.toString()}
                  workoutGroup={workoutGroup}
                  key={workoutGroup.id}
                  moveWorkoutGroupCard={moveWorkoutGroupCard}
                  findCardIndex={findCardIndex}
                />
              )
            );
          })}
      </div>
      {localItems.length === 0 && (
        <div
          className="d-flex"
          style={{
            justifyContent: "center",
            marginTop: 100,
            marginBottom: 76,
          }}
        >
          <div
            className="d-flex"
            style={{
              width: 130,
              height: 130,
              backgroundColor: "#E8EAEE",
              borderRadius: 65,
            }}
          />
        </div>
      )}
      <div
        className="d-flex flex-column"
        style={{
          alignItems: "center",
          marginTop: 24,
          marginBottom: 12,
        }}
      >
        <AddTrainingPlanButton
          icon={<img alt="Add workout group" src={PlusIconDarker} />}
          title="Add workout group"
          width={181}
          onClick={() => {
            addNewClientWorkoutGroup(selectedClient.id, clientPlan.id);
          }}
        />
      </div>
      <div
        className="d-flex flex-column"
        style={{
          alignItems: "center",
          marginBottom: 12,
        }}
      >
        <AddTrainingPlanButton
          icon={<img alt="Browse workout groups" src={MagnifyGlassIcon} />}
          title="Browse workout groups"
          width={209}
          onClick={() => {
            browseMasterTrainingPlans();
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  workoutGroups: selectCurrentWorkoutGroups(state),
  selectedClient: state.clientDetailState.user,
  clientPlan: selectFullClientPlan(state),
});

const mapDispatchToProps = (dispatch) => ({
  browseMasterTrainingPlans: () => {
    dispatch(browseMasterTrainingPlans());
  },
  addNewClientWorkoutGroup: (clientId: string, trainingPlanId: string) => {
    dispatch(
      addNewClientWorkoutGroup(
        clientId,
        trainingPlanId,
        "",
        WorkoutGroupType.Gym
      )
    );
  },
  reorderClientWorkoutGroups: (clientId: string, groupIds: []) => {
    dispatch(reorderClientWorkoutGroups(clientId, groupIds));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkoutGroupList);
