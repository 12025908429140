import {
  MASQUERADE_SAVE_AUTO_MESSAGES_SUCCESS,
  MASQUERADE_USER_TIMEZONE_SAVE_SUCCESS,
} from "../../actions/Masquerade/types";
import {
  GET_TRAINER_PREMIUM_CLIENTS,
  RESET_USER_DATA,
  SAVE_AUTO_MESSAGES_FAIL,
  SAVE_AUTO_MESSAGES_LOADING,
  SAVE_AUTO_MESSAGES_SUCCESS,
  UserAction,
  UserState,
  USER_LOADING,
  USER_LOAD_FAIL,
  USER_LOAD_SUCCESS,
  USER_TIMEZONE_SAVE_FAIL,
  USER_TIMEZONE_SAVE_SUCCESS,
  USER_TIMEZONE_SAVING,
} from "../../actions/User/types";

const initialState: UserState = {
  isLoading: false,
  isSavingTimezone: false,
  isSavingAutoMessages: false,
  doneLoading: false,
  user: null,
  error: null,
};

const authenticatedUserState = (
  state = initialState,
  action: UserAction
): UserState => {
  switch (action.type) {
    case RESET_USER_DATA:
      return {
        ...state,
        user: null,
        error: null,
      };
    case GET_TRAINER_PREMIUM_CLIENTS:
      return {
        ...state,
        premiumClients: action.premiumClients,
      };
    case USER_LOADING:
      return {
        ...state,
        isLoading: true,
        isSavingTimezone: false,
        doneLoading: false,
        error: null,
      };
    case USER_LOAD_FAIL:
      return {
        ...state,
        isLoading: false,
        isSavingTimezone: false,
        doneLoading: true,
        error: action.error,
      };
    case USER_LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSavingTimezone: false,
        doneLoading: true,
        user: action.fetchedUser,
        error: null,
      };
    case USER_TIMEZONE_SAVING:
      return {
        ...state,
        isSavingTimezone: true,
      };
    case SAVE_AUTO_MESSAGES_LOADING:
      return {
        ...state,
        isSavingAutoMessages: true,
      };
    case USER_TIMEZONE_SAVE_SUCCESS: {
      const newUser = { ...state.user };
      if (action?.fetchedUser) {
        newUser.timezone = action.fetchedUser.timezone;
      }
      return {
        ...state,
        isSavingTimezone: false,
        user: newUser,
      };
    }
    case SAVE_AUTO_MESSAGES_SUCCESS: {
      return {
        ...state,
        isSavingAutoMessages: false,
        user: {
          ...state.user,
          settings: {
            ...state.user.settings,
            ...action.trainerSettings,
          },
        },
      };
    }
    case MASQUERADE_USER_TIMEZONE_SAVE_SUCCESS:
    case USER_TIMEZONE_SAVE_FAIL:
      return {
        ...state,
        isSavingTimezone: false,
      };
    case MASQUERADE_SAVE_AUTO_MESSAGES_SUCCESS:
    case SAVE_AUTO_MESSAGES_FAIL:
      return {
        ...state,
        isSavingAutoMessages: false,
      };
    default:
      return state;
  }
};

export default authenticatedUserState;
