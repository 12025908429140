import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getAclData } from "../../redux/actions/DebugTools";
import { StoreState } from "../../redux/reducers";
import { User } from "../../types/gqlTypes";

interface Props {
  aclData: string[];
  client: User;
  getAclData: (clientId: string) => void;
}

const DebugSecurity = (props: Props) => {
  const { aclData, client, getAclData } = props;

  const clientId = client.id;

  useEffect(() => {
    getAclData(clientId);
  }, [clientId]);

  return (
    <div
      style={{
        whiteSpace: "pre-line",
        margin: 16,
      }}
    >
      {JSON.stringify(aclData, null, 2)}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getAclData: (clientId: string) => {
    dispatch(getAclData(clientId));
  },
});

const mapStateToProps = (state: StoreState) => ({
  client: state.clientDetailState.user,
  aclData: state.debugToolsState.aclData,
});

export default connect(mapStateToProps, mapDispatchToProps)(DebugSecurity);
