import React from "react";
import RadioButtonSelected from "../../assets/images/RadioButtonSelected.svg";
import RadioButtonUnselected from "../../assets/images/RadioButtonUnselected.svg";

interface Props {
  icon: any;
  title: string;
  isSelected: boolean;
  onSelected: () => void;
}

const AddWorkoutGroupItem = (props: Props) => {
  const { icon, title, isSelected, onSelected } = props;

  return (
    <div
      role="button"
      className="d-flex heading-small"
      style={{
        width: 404,
        height: 48,
        marginTop: 12,
        paddingLeft: 8,
        paddingRight: 16,
        backgroundColor: "#F1F1F4",
        borderRadius: 12,
        alignItems: "center",
        justifyContent: "space-between",
        cursor: "pointer",
      }}
      tabIndex={0}
      onClick={(event) => {
        onSelected();
      }}
      onKeyDown={(event) => {
        onSelected();
      }}
    >
      <div
        className="d-flex"
        style={{
          alignItems: "center",
        }}
      >
        {icon}
        <div
          style={{
            marginLeft: 12,
          }}
        />
        {title}
      </div>
      <div>
        {isSelected ? (
          <img
            style={{
              width: 16,
              height: 16,
            }}
            src={RadioButtonSelected}
            alt="Selected"
          />
        ) : (
          <img
            style={{
              width: 16,
              height: 16,
            }}
            src={RadioButtonUnselected}
            alt="Unselected"
          />
        )}
      </div>
    </div>
  );
};

export default AddWorkoutGroupItem;
