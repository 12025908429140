import React from "react";
import colors from "../../../assets/colors";

const StrengthScoreDetailsHeader = () => {
  return (
    <div
      className="d-flex bold align-items-center"
      style={{
        backgroundColor: colors.caliber_white,
        borderRadius: 8,
        padding: "16px 23px 15px 19px",
        width: 366,
        height: 48,
        marginBottom: 24,
      }}
    >
      <div
        className="d-flex"
        style={{
          flex: 2,
          color: colors.caliber_gray_text,
          fontSize: "14px",
          lineHeight: "17px",
          letterSpacing: "-0.29px",
        }}
      >
        Muscle Group
      </div>
      <div
        className="d-flex justify-content-center"
        style={{
          flex: 1,
          color: colors.caliber_gray_text,
          fontSize: "14px",
          lineHeight: "17px",
          letterSpacing: "-0.29px",
        }}
      >
        Change
      </div>
      <div
        className="d-flex justify-content-end"
        style={{
          flex: 1,
          color: colors.caliber_gray_text,
          fontSize: "14px",
          lineHeight: "17px",
          letterSpacing: "-0.29px",
        }}
      >
        Score
      </div>
    </div>
  );
};

export default StrengthScoreDetailsHeader;
