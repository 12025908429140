import { CoachProfile, MediaUrl } from "../../../types/gqlTypes";
import { FileUploadResponse } from "../Media/types";

export const SAVE_COACH_PROFILE_SUCCESS = "SAVE_COACH_PROFILE_SUCCESS";
export const SAVE_COACH_PROFILE_FAIL = "SAVE_COACH_PROFILE_FAIL";
export const SAVE_COACH_PROFILE_SAVING = "SAVE_COACH_PROFILE_SAVING";

export const GET_COACH_PROFILE_SUCCESS = "GET_COACH_PROFILE_SUCCESS";
export const GET_COACH_PROFILE_FAIL = "GET_COACH_PROFILE_FAIL";
export const GET_COACH_PROFILE_LOADING = "GET_COACH_PROFILE_LOADING";

export const COACH_PROFILE_IMAGE_UPLOADING = "COACH_PROFILE_IMAGE_UPLOADING";
export const COACH_PROFILE_IMAGE_UPLOAD_FAIL =
  "COACH_PROFILE_IMAGE_UPLOAD_FAIL";
export const COACH_PROFILE_IMAGE_UPLOAD_FAIL_SIZE_EXCEEDED =
  "COACH_PROFILE_IMAGE_UPLOAD_FAIL_SIZE_EXCEEDED";
export const COACH_PROFILE_IMAGE_UPLOAD_SUCCESS =
  "COACH_PROFILE_IMAGE_UPLOAD_SUCCESS";

export const COACH_PROFILE_CROPPED_IMAGE_UPLOAD_SUCCESS =
  "COACH_PROFILE_CROPPED_IMAGE_UPLOAD_SUCCESS";
export const CANCEL_UPLOAD_COACH_PROFILE_IMAGE =
  "CANCEL_UPLOAD_COACH_PROFILE_IMAGE";

export const CHANGE_COACH_PROFILE_STATE = "CHANGE_COACH_PROFILE_STATE";
export const RESET_COACH_PROFILE_STATE = "RESET_COACH_PROFILE_STATE";

export interface CoachProfileState {
  isSaving: boolean;
  isLoading: boolean;
  isUploading: boolean;
  coachProfile: CoachProfile;
}

export interface CoachProfileAction {
  type: string;
  mediaUrl?: MediaUrl;
  coachProfile?: CoachProfile;
  response?: FileUploadResponse;
}
