import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/css/clbr-bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./assets/css/style.css";
import "./index.css";
import { PersistGate } from "redux-persist/integration/react";
import RootContainer from "./screens/RootContainer";
import configureStore from "./redux/configureStore";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const { store, persistor } = configureStore();

const App = (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <RootContainer />
    </PersistGate>
  </Provider>
);

Sentry.init({
  dsn: "https://4a8260b8b9cd487b956ac2338eb5762b@o4504045951909888.ingest.sentry.io/4504211770507264",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0,
  environment: window.location.host.includes("localhost:")
    ? "local"
    : process.env.NODE_ENV,
});

ReactDOM.render(App, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
