import React from "react";
import { connect } from "react-redux";
import ClientNavItem from "../ClientNavItem";
import colors from "../../assets/colors";
import { RoutesInfo } from "../../navigation";
import { StoreState } from "../../redux/reducers";
import ClientHomeIcon from "../../assets/images/ClientHomeIcon.svg";
import { UserState } from "../../redux/actions/User/types";
import { UserType } from "../../types/gqlTypes";

interface OwnProps {
  routes: Array<RoutesInfo>;
  id: string;
}
interface Props extends OwnProps {
  userState: UserState;
}

const ClientNavigationBar = (props: Props) => {
  const { routes, id, userState } = props;
  const isTrainer = userState?.user?.type === UserType.Trainer;

  return (
    <div
      className="d-inline-flex flex-grow-0 align-items-center bordered-item"
      style={{
        backgroundColor: colors.caliber_white,
        height: 48,
        minWidth: 922,
        justifyContent: "space-between",
        margin: "4px 12px 11px 12px",
      }}
    >
      <div className="d-inline-flex flex-grow-0 align-items-center bordered-item">
        <ClientNavItem
          to="/clients"
          paths="/clients"
          icon={ClientHomeIcon}
          title="Client"
          iconOnly
          id={id}
        />
        <div
          style={{
            width: 1,
            height: 24,
            marginLeft: -6,
            marginRight: 10,
            backgroundColor: colors.caliber_gray_bg,
          }}
        >
          {/* vertical line */}
        </div>
        {routes
          .filter((route) => !isTrainer || route?.title !== "Debug")
          .map((route, index) => {
            const hasMultiplePaths = Array.isArray(route.path);
            const toPath = hasMultiplePaths
              ? route.path[0]
              : (route.path as string);
            return (
              <ClientNavItem
                key={toPath}
                to={toPath}
                paths={route.path}
                title={route.title}
                id={id}
              />
            );
          })}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  userState: state.authenticatedUserState,
  id: ownProps.id,
  routes: ownProps.routes,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientNavigationBar);
