import React from "react";

export enum ArrowIconType {
  DEFAULT = "DEFAULT",
  ARROW_BACK = "ARROW_BACK",
  DROPDOWN = "DROPDOWN",
}
interface Props {
  width?: number;
  height?: number;
  isLeft?: boolean;
  color?: string;
  iconType?: ArrowIconType;
  direction?: "up" | "down" | "left" | "right";
}

const ArrowIcons = (props: Props) => {
  const { width, height, color, isLeft, iconType, direction } = props;
  const defaultColor = "#DADCE3";

  const collection = {
    [ArrowIconType.DEFAULT]: {
      left: (
        <svg
          style={{ display: "block" }}
          width={width || 20}
          height={height || 22}
          viewBox="0 0 22 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13 4L9 10L13 16"
            stroke={color || defaultColor}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      right: (
        <svg
          style={{ display: "block" }}
          width={width || 20}
          height={height || 22}
          viewBox="0 0 22 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 16L13 10L9 4"
            stroke={color || defaultColor}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      up: (
        <svg
          width={width || "32"}
          height={height || "32"}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z"
            fill="white"
          />
          <path
            d="M10.5266 20.2524C10.3184 20.4586 10.0372 20.5742 9.74422 20.5742C9.45121 20.5742 9.17003 20.4586 8.96182 20.2524C8.85902 20.1512 8.77738 20.0305 8.72166 19.8974C8.66593 19.7643 8.63724 19.6215 8.63724 19.4772C8.63724 19.3329 8.66593 19.1901 8.72166 19.057C8.77738 18.9239 8.85902 18.8032 8.96182 18.702L15.2146 12.574C15.4233 12.3681 15.7047 12.2527 15.9978 12.2527C16.291 12.2527 16.5723 12.3681 16.781 12.574L23.0338 18.702C23.1366 18.8032 23.2183 18.9239 23.274 19.057C23.3297 19.1901 23.3584 19.3329 23.3584 19.4772C23.3584 19.6215 23.3297 19.7643 23.274 19.8974C23.2183 20.0305 23.1366 20.1512 23.0338 20.2524C22.8256 20.4586 22.5444 20.5742 22.2514 20.5742C21.9584 20.5742 21.6772 20.4586 21.469 20.2524L15.9954 15.2268L10.5266 20.2524Z"
            fill="#C2C3CD"
          />
        </svg>
      ),
      down: (
        <svg
          width={width || "32"}
          height={height || "32"}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z"
            fill="white"
          />
          <path
            d="M21.4685 12.5747C21.6767 12.3686 21.9579 12.2529 22.2509 12.2529C22.5439 12.2529 22.8251 12.3686 23.0333 12.5747C23.1361 12.676 23.2177 12.7967 23.2735 12.9297C23.3292 13.0628 23.3579 13.2057 23.3579 13.3499C23.3579 13.4942 23.3292 13.6371 23.2735 13.7701C23.2177 13.9032 23.1361 14.0239 23.0333 14.1251L16.7805 20.2531C16.5718 20.459 16.2904 20.5745 15.9973 20.5745C15.7041 20.5745 15.4228 20.459 15.2141 20.2531L8.9613 14.1251C8.8585 14.0239 8.77686 13.9032 8.72114 13.7701C8.66542 13.6371 8.63672 13.4942 8.63672 13.3499C8.63672 13.2057 8.66542 13.0628 8.72114 12.9297C8.77686 12.7967 8.8585 12.676 8.9613 12.5747C9.16951 12.3686 9.45069 12.2529 9.7437 12.2529C10.0367 12.2529 10.3179 12.3686 10.5261 12.5747L15.9997 17.6003L21.4685 12.5747Z"
            fill="#C2C3CD"
          />
        </svg>
      ),
    },
    [ArrowIconType.ARROW_BACK]: {
      left: (
        <svg
          style={{ display: "block" }}
          width={width}
          height={height}
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="10" cy="10" r="10" fill="#C2C3CD" />
          <path
            d="M15.6667 10H5M5 10L9 14M5 10L9 6"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      right: null,
    },
    [ArrowIconType.DROPDOWN]: {
      up: (
        <svg
          width={width || "7"}
          height={height || "6"}
          viewBox="0 0 7 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.09852 1.04085C3.29843 0.771544 3.70157 0.771544 3.90148 1.04085L6.80474 4.95198C7.04964 5.2819 6.81415 5.75 6.40326 5.75H0.596739C0.185853 5.75 -0.0496422 5.2819 0.195262 4.95198L3.09852 1.04085Z"
            fill="#808297"
          />
        </svg>
      ),
      down: (
        <svg
          width={width || "7"}
          height={height || "6"}
          viewBox="0 0 7 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.90148 4.95915C3.70157 5.22846 3.29843 5.22846 3.09852 4.95915L0.195262 1.04802C-0.0496416 0.718098 0.185854 0.25 0.59674 0.25L6.40326 0.25C6.81415 0.25 7.04964 0.718097 6.80474 1.04802L3.90148 4.95915Z"
            fill="#808297"
          />
        </svg>
      ),
      left: null,
      right: null,
    },
  };

  if (direction && iconType) {
    return collection?.[iconType]?.[direction];
  }

  return (
    <>
      {isLeft
        ? collection[iconType || ArrowIconType.DEFAULT]?.left
        : collection[iconType || ArrowIconType.DEFAULT]?.right}
    </>
  );
};

export default ArrowIcons;
