import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";
import { ComponentLocationType, ProgramType } from ".";
import colors from "../../assets/colors";
import {
  onClickNewOrEditMasterProgram,
  selectTrainingPlan,
} from "../../redux/actions/TrainingPrograms";
import { cloneMasterTrainingPlan } from "../../redux/actions/TrainingPrograms/createAssign";
import { deleteTrainingPlan } from "../../redux/actions/TrainingPrograms/delete";
import {
  getTrainingPlans,
  loadTrainingPlanFromRoute,
} from "../../redux/actions/TrainingPrograms/get";
import {
  getIsAdmin,
  getIsMasquerade,
  getTrainerId,
  StoreState,
} from "../../redux/reducers";
import { selectShallowCurrentPlan } from "../../redux/reducers/TrainingPrograms/selectors";
import {
  CaliberPageImpl_CustomTrainingPlan,
  CaliberPageImpl_LibraryTrainingPlan,
  CaliberPageImpl_MasterTrainingPlan,
  CustomTrainingPlan,
  CustomTrainingPlanType,
  LibraryTrainingPlan,
  MasterTrainingPlan,
  QueryClientTrainingPlansArgs,
  QuerySearchCustomTrainingPlansArgs,
  QuerySearchLibraryTrainingPlansArgs,
  QuerySearchMasterTrainingPlansArgs,
  QueryTrainingPlanArgs,
} from "../../types/gqlTypes";
import { useDebounce } from "../../utils/customHooks";
import Loader from "../Loader";
import PlanCard from "../PlanCard";
import CustomPlanCard from "./CustomPlanCard";

interface OwnProps {}
interface Props extends OwnProps {
  customPlansContent?: CaliberPageImpl_CustomTrainingPlan;
  libraryPlansContent?: CaliberPageImpl_LibraryTrainingPlan;
  masterPlansContent?: CaliberPageImpl_MasterTrainingPlan;
  plans: (MasterTrainingPlan | CustomTrainingPlan | LibraryTrainingPlan)[];
  componentLocation: ComponentLocationType;
  programType: ProgramType;
  customTrainingPlanType: CustomTrainingPlanType;
  planIndex: number;
  isLoading: boolean;
  isAdmin: boolean;
  isMasquerade: boolean;
  trainerId: string;
  filterState:
    | QuerySearchCustomTrainingPlansArgs
    | QuerySearchLibraryTrainingPlansArgs
    | QuerySearchMasterTrainingPlansArgs
    | QueryClientTrainingPlansArgs;
  getTrainingPlans: (
    args:
      | QuerySearchCustomTrainingPlansArgs
      | QuerySearchLibraryTrainingPlansArgs
      | QuerySearchMasterTrainingPlansArgs
      | QueryClientTrainingPlansArgs
  ) => void;
  onClickNewOrEditMasterProgram: (index: number) => void;
  selectTrainingPlan: (index?: number) => void;
  deleteTrainingPlan: (args: { id?: string }) => void;
  cloneMasterTrainingPlan: (trainingPlanId: string) => void;
  loadTrainingPlanFromRoute: (args: QueryTrainingPlanArgs) => void;
}

const PlansList = (props: Props) => {
  const {
    customPlansContent,
    libraryPlansContent,
    masterPlansContent,
    plans,
    componentLocation,
    programType,
    customTrainingPlanType,
    planIndex,
    isAdmin,
    isMasquerade,
    trainerId,
    isLoading,
    filterState,
    getTrainingPlans,
    onClickNewOrEditMasterProgram,
    selectTrainingPlan,
    deleteTrainingPlan,
    cloneMasterTrainingPlan,
    loadTrainingPlanFromRoute,
  } = props;

  const { trainingPlanId } = useParams();
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const isCustomPlanList = programType === ProgramType.Custom;
  const isLibraryPlanList = programType === ProgramType.Library;
  let canBeChanged = true;
  let searchObject;
  if (isCustomPlanList) {
    searchObject = customPlansContent;
  } else if (isLibraryPlanList) {
    searchObject = libraryPlansContent;
  } else if (programType === ProgramType.Master) {
    searchObject = masterPlansContent;
    canBeChanged =
      isAdmin &&
      !isMasquerade &&
      componentLocation === ComponentLocationType.AdminLibrary;
  }
  const { number: page, totalPages } = searchObject || {};

  const loadMore = () => {
    getTrainingPlans({
      ...filterState,
      page: (page || 0) + 1,
      ...((isCustomPlanList || isLibraryPlanList) && { trainerId }),
      ...(isCustomPlanList && { type: customTrainingPlanType }),
    });
  };

  useEffect(() => {
    if (trainingPlanId) {
      loadTrainingPlanFromRoute({ trainingPlanId });
      selectTrainingPlan(0);
    } else if (debouncedSearchValue === searchValue) {
      getTrainingPlans({
        ...filterState,
        page: 0,
        name: debouncedSearchValue,
        ...((isCustomPlanList || isLibraryPlanList) && { trainerId }),
        ...(isCustomPlanList && { type: customTrainingPlanType }),
      });
    }
  }, [debouncedSearchValue]);

  return (
    <div
      className="d-flex flex-column"
      style={{
        flex: 1,
        marginBottom:
          componentLocation === ComponentLocationType.ClientTab && 70,
      }}
    >
      {(isCustomPlanList || isLibraryPlanList) && (
        <input
          className="heading-small"
          style={{
            border: `1px solid ${colors.caliber_secondary_gray_5}`,
            backgroundColor: colors.caliber_secondary_gray_5,
            color: colors.caliber_gray_text,
            borderRadius: 8,
            height: 48,
            paddingLeft: 20,
            margin: "0px 12px 22px 0px",
          }}
          value={searchValue}
          onChange={(event) => setSearchValue(event.target.value)}
          placeholder="Search workout groups"
        />
      )}
      <div
        className="d-flex flex-column overflow-auto"
        style={{
          flex: 1,
          position: "relative",
        }}
      >
        {isLoading && (
          <div
            style={{
              position: "absolute",
              left: "48%",
              top: "5%",
            }}
          >
            <Loader />
          </div>
        )}

        <InfiniteScroll
          style={{
            maxHeight: 0,
            position: "relative",
            flex: 1,
          }}
          initialLoad={false}
          className="d-flex flex-column"
          pageStart={0}
          useWindow={false}
          loadMore={loadMore}
          hasMore={page < totalPages - 1}
          loader={null}
        >
          {plans?.map((plan, index) => {
            const isSelected = planIndex === index;
            if (isCustomPlanList) {
              return (
                <CustomPlanCard
                  key={plan.id}
                  id={plan.id}
                  title={(plan as CustomTrainingPlan)?.name}
                  index={index}
                  isSelected={isSelected}
                  onClick={() => selectTrainingPlan(index)}
                  proGroupName={(plan as CustomTrainingPlan).proGroup?.name}
                  active={(plan as CustomTrainingPlan).active}
                />
              );
            } else if (isLibraryPlanList) {
              return (
                <CustomPlanCard
                  key={plan.id}
                  id={plan.id}
                  title={(plan as LibraryTrainingPlan)?.name}
                  index={index}
                  isSelected={isSelected}
                  onClick={() => selectTrainingPlan(index)}
                  active={(plan as LibraryTrainingPlan).active}
                />
              );
            }
            return (
              <PlanCard
                key={plan.id}
                showEditMenu={canBeChanged}
                filterState={filterState}
                onPlanClick={() => selectTrainingPlan(index)}
                onEdit={() => onClickNewOrEditMasterProgram(index)}
                onDelete={() => deleteTrainingPlan({ id: plan.id })}
                onClone={
                  isAdmin ? () => cloneMasterTrainingPlan?.(plan.id) : undefined
                }
                bgColor={
                  isSelected ? colors.caliber_gray_11 : colors.caliber_gray_5
                }
                index={index}
                plan={plan}
                componentLocation={componentLocation}
                margin={index === 0 ? "0px 5px 5px 5px" : 5}
              />
            );
          })}
        </InfiniteScroll>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getTrainingPlans: (
    args:
      | QuerySearchCustomTrainingPlansArgs
      | QuerySearchLibraryTrainingPlansArgs
      | QuerySearchMasterTrainingPlansArgs
      | QueryClientTrainingPlansArgs
  ) => {
    dispatch(getTrainingPlans(args));
  },
  loadTrainingPlanFromRoute: (args: QueryTrainingPlanArgs) => {
    dispatch(loadTrainingPlanFromRoute(args));
  },
  onClickNewOrEditMasterProgram: (index: number) => {
    dispatch(onClickNewOrEditMasterProgram(index));
  },
  selectTrainingPlan: (index?: number) => {
    dispatch(selectTrainingPlan(index));
  },
  deleteTrainingPlan: (args: { id: string }) => {
    dispatch(deleteTrainingPlan(args));
  },
  cloneMasterTrainingPlan: (trainingPlanId: string) => {
    dispatch(cloneMasterTrainingPlan(trainingPlanId));
  },
});

const mapStateToProps = (state: StoreState) => ({
  plans: selectShallowCurrentPlan(state),
  programType: state.trainingProgramsState.programType,
  customTrainingPlanType: state.trainingProgramsState.customTrainingPlanType,
  componentLocation: state.trainingProgramsState.componentLocation,
  planIndex: state.trainingProgramsState.planIndex,
  customPlansContent: state.trainingProgramsState.customPlansContent,
  libraryPlansContent: state.trainingProgramsState.libraryPlansContent,
  masterPlansContent: state.trainingProgramsState.masterPlansContent,
  filterState: state.trainingProgramsState.filterState,
  isLoading: state.trainingProgramsState.isLoadingPlans,
  isAdmin: getIsAdmin(state),
  isMasquerade: getIsMasquerade(state),
  trainerId: getTrainerId(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlansList);
