import React from "react";

interface Props {
  icon: any;
  title: string;
  width: number;
  onClick: () => void;
}

const AddTrainingPlanButton = (props: Props) => {
  const { icon, title, width, onClick } = props;

  return (
    <div
      role="button"
      className="d-flex paragraph-normal"
      style={{
        cursor: "pointer",
        borderRadius: 24,
        height: 38,
        width,
        paddingLeft: 12,
        paddingRight: 12,
        backgroundColor: "#E8EAEE",
        color: "#82869B",
        alignItems: "center",
        textAlign: "center",
      }}
      tabIndex={0}
      onClick={(event) => {
        onClick();
      }}
      onKeyDown={(event) => {
        onClick();
      }}
    >
      {icon}
      <div
        style={{
          marginRight: 10,
        }}
      />
      {title}
    </div>
  );
};

export default AddTrainingPlanButton;
