import { format, parse } from "date-fns";
import React from "react";
import colors from "../../assets/colors";
import {
  Client,
  Manager,
  Trainer,
  User,
  UserStatusType,
} from "../../types/gqlTypes";
import ClientStatusTag from "../ClientStatusTag";
import MeatballsMenu from "../MeatballsMenu";
import ProfilePhoto from "../ProfilePhoto";

interface Item {
  text: string;
  onClick: () => void;
}
interface Props {
  user?: User | Manager | Trainer | Client;
  showUserType?: boolean;
  showUserStatus?: boolean;
  cardColor?: string;
  cardWidth?: number;
  showMenu?: boolean;
  showMenuItems?: Item[];
  showStatusDate?: boolean;
}

const UserShortCard = (props: Props) => {
  const {
    user,
    showUserType,
    cardColor,
    showMenu,
    showMenuItems,
    showUserStatus,
    cardWidth,
    showStatusDate,
  } = props;

  const profilePic = user?.profileIconMediaUrl?.url;
  const clientStatus = user?.status as UserStatusType;
  let dateSince;
  let dateTextSince;
  if (showStatusDate) {
    if (clientStatus === UserStatusType.Active) {
      dateTextSince = "Active since";
      const date = (user as Client)?.joinedDate;
      const parsedDate = date
        ? parse(date.slice(0, 10), "yyyy-MM-dd", new Date())
        : null;
      dateSince = parsedDate ? format(parsedDate, "MMM d, yyyy") : "--";
    } else if (clientStatus === UserStatusType.TrialUser) {
      dateTextSince = "Trial ends";
      const date = (user as Client)?.trialEndDate;
      const parsedDate = date
        ? parse(date.slice(0, 10), "yyyy-MM-dd", new Date())
        : null;
      dateSince = parsedDate ? format(parsedDate, "MMM d, yyyy") : "--";
    } else if (clientStatus === UserStatusType.PendingCancellation) {
      dateTextSince = "Ends";
      const date = (user as Client)?.subscriptionEndDate;
      const parsedDate = date
        ? parse(date.slice(0, 10), "yyyy-MM-dd", new Date())
        : null;
      dateSince = parsedDate ? format(parsedDate, "MMM d, yyyy") : "--";
    }
  }
  const types = {
    TRAINER: "Coach",
    MANAGER: "Manager",
  };
  return (
    <div
      className="d-flex bordered-item pointer"
      style={{
        backgroundColor: cardColor || colors.caliber_white,
        paddingLeft: 8,
        width: cardWidth || 294,
        height: 64,
        position: "relative",
      }}
    >
      <div
        style={{
          margin: 8,
        }}
      >
        <ProfilePhoto
          imageUrl={profilePic}
          size={48}
          bgColor={cardColor || colors.caliber_white}
        />
      </div>
      <div
        className="d-flex flex-column"
        style={{
          flex: 1,
        }}
      >
        <div
          className="d-flex flex-column justify-content-end medium-bold"
          style={{
            marginLeft: 3,
            marginTop: 9,
            height: 32,
            width: (cardWidth || 294) - 155,
            fontSize: "12px",
            lineHeight: "24px",
            color: colors.caliber_secondary,
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {user.firstName} {user.lastName}
        </div>
        {showUserType && (
          <div
            className="d-flex flex-column paragraph-small"
            style={{
              marginLeft: 3,
              height: 32,
              color: colors.caliber_placeholder_color,
            }}
          >
            {types[user.type]}
          </div>
        )}
        {showUserStatus && clientStatus && (
          <div
            className="d-flex justify-content-start paragraph-small"
            style={{
              marginLeft: 3,
              height: 32,
            }}
          >
            <ClientStatusTag status={clientStatus} />
          </div>
        )}
      </div>
      {showStatusDate && (
        <div
          className="d-flex flex-column medium-bold"
          style={{
            flex: 1,
          }}
        >
          <div
            className="d-flex justify-content-end"
            style={{
              color: colors.caliber_gray_text,
              fontSize: "10px",
              lineHeight: "11px",
              letterSpacing: "0.011em",
              marginTop: showMenu ? 24 : 18,
              marginRight: 12,
            }}
          >
            {dateTextSince}
          </div>
          <div
            className="d-flex justify-content-end"
            style={{
              color: colors.caliber_gray_text,
              fontSize: "10px",
              lineHeight: "11px",
              letterSpacing: "0.011em",
              marginRight: 12,
            }}
          >
            {dateSince}
          </div>
        </div>
      )}
      {showMenu && (
        <div
          style={{
            position: "absolute",
            right: 0,
            top: showStatusDate ? 0 : 18,
          }}
        >
          <MeatballsMenu items={showMenuItems} />
        </div>
      )}
    </div>
  );
};

export default UserShortCard;
