import React from "react";
import colors from "../../../assets/colors";
import CalendarIcons, { CalendarIconType } from "../../Icons/CalendarIcons";

interface Props {}

const CategoryButton = (props: Props) => {
  const {} = props;

  const greyRectStyle: React.CSSProperties = {
    position: "absolute",
    width: 110,
    height: 36,
    backgroundColor: colors.caliber_secondary_gray_5,
    borderRadius: "8px 8px 0 0",
    top: 8,
    left: 8,
    zIndex: 1,
  };
  const tagStyle: React.CSSProperties = {
    fontSize: "14px",
    lineHeight: "20px",
    color: colors.caliber_secondary,
    height: 20,
    width: 126,
    zIndex: 2,
  };
  const imageStyle: React.CSSProperties = {
    height: 64,
    width: 126,
    paddingTop: 9,
    zIndex: 2,
  };

  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center"
      style={{
        position: "relative",
        height: 126,
        width: 126,
        borderRadius: 8,
        backgroundColor: colors.caliber_gray_bg,
      }}
    >
      <div style={greyRectStyle}>{/* grey rect */}</div>
      <div
        className="d-flex justify-content-center align-items-center"
        style={imageStyle}
      >
        <CalendarIcons type={CalendarIconType.PROGRESS_PHOTO} />
      </div>
      <div
        className="d-flex justify-content-center align-items-center"
        style={tagStyle}
      >
        Progress
      </div>
      <div
        className="d-flex justify-content-center align-items-center"
        style={tagStyle}
      >
        photo
      </div>
    </div>
  );
};

export default CategoryButton;
