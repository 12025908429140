import { gql } from "apollo-boost";

export const exerciseFragment = gql`
  fragment ExerciseProperty on Exercise {
    id
    instructions
    isStrengthScoreKeyExercise
    includeBodyWeightInCalculations
    isCustomExercise
    media {
      heroImageUrl {
        id
        url
      }
      thumbnailUrl {
        id
        url
      }
      videoUrl {
        id
        url
      }
      videoLesson {
        id
        videoLessonUrl {
          id
          url
        }
        thumbnailUrl {
          id
          url
        }
        chapters {
          id
          marker
          number
          title
        }
      }
    }
    name
    targetBase
    level
    takeaways
    type
    primaryMuscleGroups
    secondaryMuscleGroups
    equipment
  }
`;

export const caliberExerciseFragment = gql`
  fragment ExerciseProperty on CaliberExercise {
    id
    instructions
    isStrengthScoreKeyExercise
    includeBodyWeightInCalculations
    isCustomExercise
    media {
      heroImageUrl {
        id
        url
      }
      thumbnailUrl {
        id
        url
      }
      videoUrl {
        id
        url
      }
      videoLesson {
        id
        videoLessonUrl {
          id
          url
        }
        thumbnailUrl {
          id
          url
        }
        chapters {
          id
          marker
          number
          title
        }
      }
    }
    name
    targetBase
    level
    takeaways
    type
    primaryMuscleGroups
    secondaryMuscleGroups
    equipment
  }
`;

export const CREATE_CALIBER_EXERCISE_GQL = gql`
  ${caliberExerciseFragment}
  mutation CreateCaliberExercise($exercise: CaliberExerciseInput) {
    createCaliberExercise(exercise: $exercise) {
      ...ExerciseProperty
    }
  }
`;

export const UPDATE_EXERCISE_GQL = gql`
  ${exerciseFragment}
  mutation UpdateExercise($exercise: ExerciseInput!) {
    updateExercise(exercise: $exercise) {
      ...ExerciseProperty
    }
  }
`;

export const DELETE_EXERCISE = gql`
  mutation DeleteExercise($exerciseId: UUID, $force: Boolean) {
    deleteExercise(exerciseId: $exerciseId, force: $force)
  }
`;
export const SEARCH_EXERCISES = gql`
  ${exerciseFragment}
  query SearchExercises(
    $exerciseEquipmentCriteria: ExerciseEquipmentType
    $exerciseTypeCriteria: ExerciseType
    $exerciseLevelCriteria: ExerciseLevelType
    $muscleGroupCriteria: [MuscleGroupType]
    $page: Int!
    $sortDirection: Direction
    $sortField: ExerciseSortCriteria
    $textCriteria: String!
    $showWarmups: Boolean
  ) {
    searchExercises(
      exerciseEquipmentCriteria: $exerciseEquipmentCriteria
      exerciseTypeCriteria: $exerciseTypeCriteria
      exerciseLevelCriteria: $exerciseLevelCriteria
      muscleGroupCriteria: $muscleGroupCriteria
      page: $page
      sortDirection: $sortDirection
      sortField: $sortField
      textCriteria: $textCriteria
      showWarmups: $showWarmups
    ) {
      last
      number
      numberOfElements
      totalElements
      totalPages
      content {
        ...ExerciseProperty
      }
    }
  }
`;
