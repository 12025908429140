import React from "react";
import { connect } from "react-redux";
import { StoreState } from "../../redux/reducers";
import ExerciseList from "../ExerciseList";
import WorkoutGroupList from "../WorkoutGroupList";
import PlanHeader from "./PlanHeader";
import WorkoutList from "./WorkoutList";
import colors from "../../assets/colors";

interface OwnProps {}
interface Props extends OwnProps {
  showWorkouts?: boolean;
  showExercises?: boolean;
  showGroups: boolean;
}

const TrainingProgramWorkoutList = (props: Props) => {
  const { showWorkouts, showExercises, showGroups } = props;

  return (
    <div
      className="d-flex"
      style={{
        flex: 1,
        marginBottom: 70,
      }}
    >
      {showWorkouts && (
        <div
          className="d-flex"
          style={{
            flex: 1,
            marginRight: 12,
          }}
        >
          <div
            className="d-flex flex-column"
            style={{
              flex: "1 1 50%",
              position: "relative",
            }}
          >
            <div className="d-flex flex-column">
              {/* @ts-ignore */}
              <PlanHeader />
            </div>
            <div
              className="d-flex flex-column overflow-auto"
              style={{
                position: "relative",
              }}
            >
              {!showGroups && <WorkoutList />}

              {showGroups && <WorkoutGroupList />}
            </div>
          </div>
        </div>
      )}
      {showExercises && (
        <div
          className="d-flex flex-column"
          style={{
            flex: 1,
          }}
        >
          <ExerciseList />
        </div>
      )}
      {!showExercises && showGroups && (
        <div
          className="d-flex flex-column"
          style={{
            flex: 1,
            marginTop: 100,
          }}
        >
          <div
            className="d-flex"
            style={{
              justifyContent: "center",
              marginBottom: 32,
            }}
          >
            <div
              className="d-flex"
              style={{
                width: 96,
                height: 96,
                backgroundColor: "#DADCE3",
                borderRadius: 65,
              }}
            />
          </div>
          <div
            className="d-flex justify-content-center medium-bold"
            style={{
              fontSize: "18px",
              lineHeight: "20px",
              color: colors.caliber_gray_text,
            }}
          >
            No workout selected
          </div>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state: StoreState, props: Props) => ({
  showWorkouts: state.trainingProgramsState.showWorkouts,
  showExercises: state.trainingProgramsState.showExercises,
  showGroups: props.showGroups,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrainingProgramWorkoutList);
