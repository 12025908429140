import apolloClient from "../../../api/apolloClient";
import {
  GET_PRO_GROUPS_FAIL,
  GET_PRO_GROUPS_SUCCESS,
  PRO_GROUPS_LOADING,
} from "./types";
import { GET_ALL_PRO_GROUPS } from "../../../api/gql/proGroups";

export const getAllProGroups = () => async (dispatch, getState) => {
  if (getState().proGroupsState.isLoading) {
    return;
  }
  dispatch({
    type: PRO_GROUPS_LOADING,
  });
  try {
    const client = await apolloClient(getState().authState.authToken, dispatch);
    const result = (
      await client.query({
        query: GET_ALL_PRO_GROUPS,
      })
    ).data.proGroups;
    dispatch({
      type: GET_PRO_GROUPS_SUCCESS,
      data: result,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_PRO_GROUPS_FAIL,
      data: error,
    });
  }
};
