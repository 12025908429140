import React from "react";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import colors from "../../assets/colors";
import CloseIcon from "../Icons/CloseIcons";

const DashboardNavigation = () => {
  const { isExact: isStrengthScoreDetails } =
    useRouteMatch("/clients/:id/dashboard/strength-score-details") || {};
  const { id: clientId } = useParams();

  const selectedTextStyle: React.CSSProperties = {
    fontSize: "18px",
    lineHeight: "20px",
    letterSpacing: "-0.022em",
    color: colors.caliber_secondary,
    height: 67,
    marginRight: 9,
  };

  return (
    <div
      className="d-flex flex-column"
      style={{
        padding: "0px 12px",
      }}
    >
      <div
        className="d-flex justify-content-between align-items-center bold"
        style={{
          height: 67,
          marginBottom: 12,
          paddingRight: 5,
        }}
      >
        <div className="d-flex">
          <div
            className="d-flex align-items-center"
            style={{
              ...selectedTextStyle,
              color: colors.caliber_gray_text,
              marginLeft: 12,
            }}
          >
            Dashboard
          </div>
          {isStrengthScoreDetails && (
            <div
              className="d-flex align-items-center"
              style={selectedTextStyle}
            >
              /
            </div>
          )}
          {isStrengthScoreDetails && (
            <div
              className="d-flex align-items-center"
              style={selectedTextStyle}
            >
              Strength Score details
            </div>
          )}
        </div>
        <Link
          key={`link_client_dashboard_${clientId}`}
          to={`/clients/${clientId}/dashboard`}
          style={{ textDecoration: "none" }}
        >
          <CloseIcon width={30} height={30} />
        </Link>
      </div>

      <div
        style={{
          height: 1,
          backgroundColor: colors.caliber_gray_border,
          marginBottom: 24,
        }}
      >
        {/* line */}
      </div>
    </div>
  );
};

export default DashboardNavigation;
