import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { StoreState } from "../../redux/reducers";
import colors from "../../assets/colors";
import { SearchTimezonesState } from "../../redux/actions/SearchTimezones/types";
import { User } from "../../types/gqlTypes";
import SearchDropdown from "../SearchDropdown";
import PinIcon from "../../assets/images/PinRedIcon.svg";
import { searchTimezones } from "../../redux/actions/SearchTimezones";
import { updateUserTimezone } from "../../redux/actions/User";
import { MasqueradeState } from "../../redux/actions/Masquerade/types";

interface Props {
  user: User;
  searchTimezonesState: SearchTimezonesState;
  masqueradeState: MasqueradeState;
  searchTimezones: (page: number, searchCriteria: string) => void;
  updateUserTimezone: (timeZoneTypeId: string) => void;
}

const TimezoneSettings = (props: Props) => {
  const {
    user,
    searchTimezonesState,
    masqueradeState,
    searchTimezones,
    updateUserTimezone,
  } = props;

  const [timezone, setTimezone] = useState(user?.timezone);
  const isMasquerading = masqueradeState.masqueradeTrainer !== null;

  useEffect(() => {
    if (isMasquerading) {
      setTimezone(masqueradeState.masqueradeTrainer?.timezone);
    } else {
      setTimezone(user?.timezone);
    }
  }, [user, masqueradeState]);

  const dropdownOptions = useMemo(() => {
    return searchTimezonesState?.timezones?.map((timezone) => ({
      text: timezone?.description,
      value: timezone?.id,
    }));
  }, [searchTimezonesState, user, masqueradeState]);

  return (
    <div
      className="d-flex"
      style={{
        marginTop: 32,
        marginBottom: 23,
      }}
    >
      <div
        style={{
          flex: 2,
        }}
      >
        <div
          className="bold"
          style={{
            marginTop: 12,
            fontSize: "18px",
            lineHeight: "18px",
            color: colors.caliber_secondary,
            letterSpacing: "-0.033em",
          }}
        >
          Time zone
        </div>
        <div
          className=""
          style={{
            fontSize: "14px",
            lineHeight: "14px",
            color: colors.caliber_gray_text,
            letterSpacing: "-0.011em",
            width: 250,
            marginTop: 12,
          }}
        >
          Select your correct time zone from the dropdown menu on the right.
        </div>
      </div>
      <div
        style={{
          flex: 3,
          height: 112,
          backgroundColor: colors.caliber_white,
          borderRadius: 8,
          border: `1px solid ${colors.caliber_secondary_gray_5}`,
        }}
      >
        <div
          style={{
            width: 350,
            margin: "32px 24px",
          }}
        >
          <SearchDropdown
            customThumbnail={PinIcon}
            placeholder="Search for timezone..."
            height={48}
            search={searchTimezones}
            totalPages={searchTimezonesState?.totalPages}
            page={searchTimezonesState?.page}
            bgColor={colors.caliber_secondary_gray_11}
            items={dropdownOptions || []}
            value={timezone?.id}
            selectedText={timezone?.description}
            onSelect={updateUserTimezone}
          />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  searchTimezones: (page: number, searchCriteria: string) => {
    dispatch(searchTimezones(page, searchCriteria));
  },
  updateUserTimezone: (timeZoneTypeId: string) => {
    dispatch(updateUserTimezone(timeZoneTypeId));
  },
});

const mapStateToProps = (state: StoreState) => ({
  masqueradeState: state.masqueradeState,
  searchTimezonesState: state.searchTimezonesState,
  user: state.authenticatedUserState.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(TimezoneSettings);
