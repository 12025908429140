import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  subMonths,
  format,
  parse,
  eachDayOfInterval,
  isBefore,
} from "date-fns";
import { Line } from "react-chartjs-2";
import { StoreState } from "../../redux/reducers";
import {
  CalendarProgressItems,
  getCalendarProgressItems,
} from "../../redux/actions/Calendar";
import {
  BodyMeasurementType,
  CalendarDay,
  BodyStatCalendarItem,
  CalendarItemType,
  ImportedBodyStatCalendarItem,
} from "../../types/gqlTypes";
import { useRerenderOnResize } from "../../utils/customHooks";
import Dropdown from "../Dropdown";
import colors from "../../assets/colors";
import Loader from "../Loader";

interface Props {
  calendarData: CalendarDay[];
  isLoading: boolean;
  getBodyStatCalendarItems: (
    clientId: string,
    startDate: Date,
    endDate: Date
  ) => void;
}

const BodyStatsProgress = (props: Props) => {
  const { calendarData, isLoading, getBodyStatCalendarItems } = props;
  useRerenderOnResize();

  const { id: clientId } = useParams();
  const [period, setPeriod] = useState(1);
  const [chartType, setChartType] = useState(BodyMeasurementType.BodyWeight);

  const dropdownPeriodOptions = [
    { text: "1 month", value: 1 },
    { text: "3 months", value: 3 },
    { text: "6 months", value: 6 },
    { text: "12 months", value: 12 },
  ];
  const dropdownTypeOptions = [
    { text: "Body Weight", value: BodyMeasurementType.BodyWeight },
    { text: "Body Fat %", value: BodyMeasurementType.BodyFatPercent },
    { text: "Waist", value: BodyMeasurementType.WaistSize },
  ];
  const lineColor = "#3446FB";

  const datasetOptions = {
    fill: true,
    borderColor: lineColor,
    borderCapStyle: "butt",
    borderDash: [],
    borderDashOffset: 0.0,
    pointBorderColor: lineColor,
    pointBackgroundColor: lineColor,
    pointHoverRadius: 6,
    pointHoverBackgroundColor: lineColor,
    pointHoverBorderColor: lineColor,
    pointRadius: 4,
    pointHitRadius: 4,
  };

  const normalizedData = useMemo(() => {
    const data = {};
    dropdownTypeOptions.forEach(({ value }) => {
      data[value] = { x: [], y: [], maxValue: 0 };
    });
    calendarData &&
      calendarData.map((calendarDay) => {
        calendarDay.items.map(
          (item: BodyStatCalendarItem | ImportedBodyStatCalendarItem) => {
            if (
              (item.enumType === CalendarItemType.BodyStat ||
                item.enumType === CalendarItemType.ImportedBodyStat) &&
              item &&
              item.bodyMeasurementType in data &&
              item.bodyMeasurementValue != null
            ) {
              const date = parse(calendarDay.date, "yyyy-MM-dd", new Date());
              if (data[item.bodyMeasurementType].x.length > 0) {
                const prevDate = parse(
                  data[item.bodyMeasurementType].x[
                    data[item.bodyMeasurementType].x.length - 1
                  ],
                  "MMM dd",
                  new Date()
                );
                let range;
                if (isBefore(prevDate, date)) {
                  range = eachDayOfInterval({ start: prevDate, end: date });
                } else {
                  range = eachDayOfInterval({ start: date, end: prevDate });
                }
                if (range.length > 2) {
                  // if non consecutive days
                  range.forEach((rangeDate, index) => {
                    if (index > 0 && index !== range.length - 1) {
                      data[item.bodyMeasurementType].x.push(
                        format(rangeDate, "MMM dd")
                      );
                      data[item.bodyMeasurementType].y.push(null);
                    }
                  });
                }
              }
              data[item.bodyMeasurementType].x.push(format(date, "MMM dd"));
              data[item.bodyMeasurementType].y.push(item.bodyMeasurementValue);
              data[item.bodyMeasurementType].maxValue = Math.max(
                data[item.bodyMeasurementType].maxValue,
                item.bodyMeasurementValue
              );
            }
          }
        );
      });
    return data;
  }, [calendarData]);

  const chartOptions = useMemo(() => {
    let labelString;
    let label;
    let min;
    let max;
    switch (chartType) {
      case BodyMeasurementType.BodyFatPercent:
        labelString = "Body Fat, %";
        label = "%";
        min = 0;
        max = 45;
        break;
      case BodyMeasurementType.WaistSize:
        labelString = "Waist, inch";
        label = "inch";
        min = 20;
        max = 50;
        break;
      case BodyMeasurementType.BodyWeight:
        labelString = "Weight, lbs";
        label = "lbs";
        min = 0;
        max = 200;
        break;
    }
    const suggestedScale = { min: undefined, max: undefined };
    if (normalizedData[chartType]?.x?.length === 0) {
      suggestedScale.max = max;
      suggestedScale.min = min;
    }
    const options = {
      spanGaps: true,
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 70,
        },
      },
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            ticks: {
              minRotation: 0,
              maxRotation: 0,
              callback: (tick, ind) => {
                let divider = 1;
                if (normalizedData?.[chartType]?.x?.length > 28) {
                  divider = 7;
                } else if (normalizedData?.[chartType]?.x?.length > 14) {
                  divider = 3;
                } else if (normalizedData?.[chartType]?.x?.length > 6) {
                  divider = 2;
                }
                return ind % divider ? "" : tick;
              },
            },
            scaleLabel: {
              display: true,
              labelString: "Date",
              fontFamily: "Circular-Medium, serif",
              fontColor: "#AEB1BF",
            },
            gridLines: {
              display: false,
            },
          },
          {
            position: "top",
            ticks: {
              display: false,
            },
            gridLines: {
              display: false,
              drawTicks: false,
            },
          },
        ],
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString,
              fontFamily: "Circular-Medium, serif",
              fontColor: "#AEB1BF",
            },
            gridLines: {
              tickMarkLength: 0,
            },
            ticks: {
              ...suggestedScale,
              padding: 20,
              beginAtZero: false,
            },
          },
          {
            position: "right",
            ticks: { display: false },
            gridLines: {
              display: false,
              drawTicks: false,
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          labelColor: () => ({}),
          labelTextColor: () => colors.caliber_secondary,
          label: (item) => {
            return `${item.value} ${label}`;
          },
          displayColors: () => false,
          footer: (items) => items?.[0]?.label,
          title: () => null,
        },
        displayColors: false,
        footerFontColor: colors.caliber_gray_47,
        footerFontFamily: "Circular, serif",
        bodyFontFamily: "Circular-Medium, serif",
        backgroundColor: colors.caliber_white,
        caretPadding: 10,
        caretSize: 7,
        xPadding: 14,
        yPadding: 8,
        footerAlign: "center",
        bodyAlign: "center",
        yAlign: "bottom",
        xAlign: "center",
        bodyFontSize: 17,
        fotterFontSize: 13,
      },
    };
    return options;
  }, [chartType, period, normalizedData]);

  const chartData = useCallback(
    (canvas) => {
      const ctx = canvas.getContext("2d");
      const gradient = ctx.createLinearGradient(0, 0, 0, 400);
      gradient.addColorStop(0, "rgba(52, 70, 251, 0.4)");
      gradient.addColorStop(1, "rgba(52, 70, 251, 0)");
      const data = {
        labels: [],
        datasets: [
          {
            ...datasetOptions,
            backgroundColor: gradient,
            data: [],
          },
        ],
      };
      normalizedData[chartType].x.map((date, index) => {
        data.labels.push(date);
        data.datasets[0].data.push(normalizedData[chartType].y[index]);
      });
      return data;
    },
    [chartType, normalizedData]
  );

  const getChartData = useCallback(() => {
    const endDate = new Date();
    const startDate = subMonths(endDate, period);
    getBodyStatCalendarItems(clientId, startDate, endDate);
  }, [period]);

  useEffect(() => {
    getChartData();
  }, [period]);

  const chartHeight = Math.max(window.innerHeight - 400, 300);
  const chartWidth =
    window.innerWidth > 1100
      ? window.innerWidth - 300
      : Math.max(window.innerWidth - 150, 400);

  return (
    <div
      className="d-flex flex-column"
      style={{
        flex: 1,
        minWidth: 0,
        position: "relative",
      }}
    >
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: "40%",
            left: "calc(50% - 24px)",
          }}
        >
          <Loader />
        </div>
      )}
      <div className="d-flex justify-content-between">
        <div
          style={{
            width: 250,
          }}
        >
          <Dropdown
            value={chartType}
            items={dropdownTypeOptions}
            onSelect={(value) => setChartType(value)}
            height={36}
            textColor="#82869B"
          />
        </div>
        <div
          style={{
            width: 250,
          }}
        >
          <Dropdown
            value={period}
            items={dropdownPeriodOptions}
            onSelect={(value) => setPeriod(value)}
            height={36}
            textColor="#82869B"
          />
        </div>
      </div>
      <div
        className="d-flex flex-column"
        style={{
          flex: 1,
          maxWidth: chartWidth,
          maxHeight: chartHeight,
          width: chartWidth,
          height: chartHeight,
        }}
      >
        <Line
          width={chartWidth}
          height={chartHeight}
          options={chartOptions}
          data={chartData}
        />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getBodyStatCalendarItems: (
    clientId: string,
    startDate: Date,
    endDate: Date
  ) => {
    dispatch(
      getCalendarProgressItems(
        clientId,
        startDate,
        endDate,
        CalendarProgressItems.BODY_STAT
      )
    );
  },
});

const mapStateToProps = (state: StoreState) => ({
  calendarData: state.calendarDataState.calendarItemsByDay,
  isLoading: state.calendarDataState.isLoading,
});

export default connect(mapStateToProps, mapDispatchToProps)(BodyStatsProgress);
