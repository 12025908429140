import {
  TEXT_GOAL_LOADING,
  TEXT_GOAL_LOAD_FAIL,
  TEXT_GOAL_LOAD_SUCCESS,
} from "./types";
import apolloClient from "../../../../api/apolloClient";
import {
  CREATE_CLIENT_TEXT_GOAL,
  UPDATE_CLIENT_TEXT_GOAL,
} from "../../../../api/gql/goals";
import {
  TextGoal,
  MutationCreateTextGoalArgs,
  MutationUpdateTextGoalArgs,
} from "../../../../types/gqlTypes";
import { ClientDetailAction } from "../../ClientDetail/types";

export const createTextGoal =
  (clientId: string, text: string, targetDate: string) =>
  async (dispatch, getState) => {
    if (getState().authenticatedUserState.isLoading) {
      return;
    }
    dispatch({
      type: TEXT_GOAL_LOADING,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const variables = {
        clientId,
        text,
        targetDate,
      } as MutationCreateTextGoalArgs;

      const result = await client.mutate({
        mutation: CREATE_CLIENT_TEXT_GOAL,
        variables,
      });
      const textGoal = result.data.createTextGoal as TextGoal;

      dispatch({
        type: TEXT_GOAL_LOAD_SUCCESS,
        textGoal,
      } as ClientDetailAction);
    } catch (error) {
      dispatch({
        type: TEXT_GOAL_LOAD_FAIL,
        data: error,
      });
    }
  };

export const updateTextGoal =
  (goalId: string, text: string, targetDate: string, clientId: string) =>
  async (dispatch, getState) => {
    if (getState().clientDetailState.isLoading) {
      return;
    }
    dispatch({
      type: TEXT_GOAL_LOADING,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const variables: MutationUpdateTextGoalArgs = {
        goalId,
        text,
        targetDate,
        clientId,
        achieved: false,
      };

      const result = await client.mutate({
        mutation: UPDATE_CLIENT_TEXT_GOAL,
        variables,
      });
      const textGoal = result.data.updateTextGoal as TextGoal;

      dispatch({
        type: TEXT_GOAL_LOAD_SUCCESS,
        textGoal,
      } as ClientDetailAction);
    } catch (error) {
      dispatch({
        type: TEXT_GOAL_LOAD_FAIL,
        data: error,
      });
    }
  };
