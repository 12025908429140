import React from "react";
import colors from "../../../assets/colors";
import { ClientsTabColumnsType } from "../../../redux/actions/ClientsTab/types";
import { ClientSearchResult } from "../../../types/gqlTypes";

interface Props {
  isLastColumn?: boolean;
  isDragging?: boolean;
  width?: number;
  column: ClientsTabColumnsType;
  row: ClientSearchResult;
}

const NutritionCell = (props: Props) => {
  const { isLastColumn, isDragging, width, column, row } = props;

  const color = colors.black;
  const borderColor = colors.table_gray;
  const rowHeight = 64;

  const keys = {
    [ClientsTabColumnsType.fat7DayAverage]: "fatTarget",
    [ClientsTabColumnsType.protein7DayAverage]: "proteinTarget",
    [ClientsTabColumnsType.carbs7DayAverage]: "carbsTarget",
    [ClientsTabColumnsType.calories7DayAverage]: "caloriesTarget",
  };

  const value = row?.[column];
  const target = row?.[keys[column]];
  let percent = null;
  let valueText = null;
  let targetText = null;
  let displayText = null;
  const unit =
    column === ClientsTabColumnsType.calories7DayAverage ? "cal" : "g";

  valueText = value;
  targetText = target;

  if (value === null) {
    valueText = "Not enough data";
  }
  if (target === null) {
    targetText = "No target set";
  }

  if (value != null && target != null) {
    percent = Math.round((100 * value) / (target || 1));
    if (target === 0) {
      percent = 100;
    }
    displayText = `${valueText}  /  ${targetText} ${unit} (${percent}%)`;
  } else if (target != null) {
    displayText = `${valueText}  /  ${targetText} ${unit}`;
  } else if (value != null) {
    displayText = `${valueText} ${unit}  /  ${targetText}`;
  } else {
    displayText = `${valueText}  /  ${targetText}`;
  }

  return (
    <td
      className="d-flex"
      style={{
        width,
        minWidth: isLastColumn && width,
        backgroundColor: colors.caliber_gray_bg,
        position: "relative",
      }}
    >
      <div
        className="d-flex"
        style={{
          position: "absolute",
          top: isDragging ? -8 : 0,
          backgroundColor: isDragging ? "#E8EAEE" : "transparent",
          width: "100%",
          height: isDragging ? rowHeight + 16 : rowHeight,
          zIndex: isDragging ? 4 : 1,
          marginBottom: isDragging ? 0 : 8,
        }}
      >
        <div
          className="d-flex align-items-center"
          style={{
            flex: "1 1 100%",
            fontSize: "14px",
            lineHeight: "20px",
            color,
            zIndex: isDragging ? 3 : 2,
            backgroundColor: colors.caliber_white,
            height: rowHeight,
            borderRight: `1px solid ${borderColor}`,
            paddingLeft: 18,
            margin: isDragging ? 8 : 0,
            borderTopRightRadius: isLastColumn && 8,
            borderBottomRightRadius: isLastColumn && 8,

            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          <div>{displayText}</div>
        </div>
      </div>
    </td>
  );
};

export default NutritionCell;
