import React from "react";
import TrainerProfile from "../../components/TrainerProfile";

interface Props {}

const TrainerProfileScreen = (props: Props) => {
  const {} = props;

  return (
    <div className="d-flex" style={{ flex: 1 }}>
      <TrainerProfile />
    </div>
  );
};

export default TrainerProfileScreen;
