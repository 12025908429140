const CM_INCHES_FACTOR = 0.3937;

const MILE_KM_FACTOR = 1.60934;

const LB_KG_FACTOR = 0.453592;

export const convertCmToInches = (centimeters: number) =>
  centimeters * CM_INCHES_FACTOR;

export const convertInchesToCm = (inches: number) => inches / CM_INCHES_FACTOR;

export const convertMilesToKM = (miles: number) => miles * MILE_KM_FACTOR;

export const convertKMToMiles = (kilometers: number) =>
  kilometers / MILE_KM_FACTOR;

export const convertPoundsToKilos = (pounds: number) =>
  Math.round(pounds * LB_KG_FACTOR * 10) / 10;

export const convertKilosToPounds = (kilos: number) => kilos / LB_KG_FACTOR;

export const formatMeasurement = (v: any, unit?: string, space = true) => {
  const joiner = space ? " " : "";
  const value = v || 0;
  let formatted = "";
  if (unit === "CM") {
    formatted = [value, "cm"].join(joiner);
  } else if (unit === "IN") {
    const ft = Math.floor(value / 12);
    const inch = Math.round(value % 12);
    formatted = [`${ft}'`, `${inch}''`].join(joiner);
  } else if (unit === "KG") {
    formatted = [convertPoundsToKilos(value), "kg"].join(joiner);
  } else if (unit === "LB") {
    formatted = [value, "lbs"].join(joiner);
  } else if (unit === "location") {
    formatted = value.filter((x) => x).join(", ");
    if (formatted.length > 20) {
      formatted = formatted.split(" ").join("\n");
    }
  } else if (unit === "age") {
    formatted = [value, "years"].join(" ");
  }
  return formatted;
};

export const formatLocation = (values: string[]) => {
  return values.filter((x) => x).join(", ");
};
/**
 * Pad int with leading 0
 * @param num
 * @return string
 */
export const padInt = (num: number): string => {
  if (num >= 10) {
    return `${num}`;
  }
  return `0${num}`;
};
