import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import colors from "../../assets/colors";
import {
  getAppRequirements,
  updateMinimumAppBuildNumber,
} from "../../redux/actions/AdminMobileAppBuildNumber";
import { StoreState } from "../../redux/reducers";
import {
  AppRequirements,
  DeviceType,
  MutationUpdateMinimumAppBuildNumberArgs,
  QueryAppRequirementsArgs,
} from "../../types/gqlTypes";

interface OwnProps {
  appRequirementsList: AppRequirements[];
  success: boolean;
}

interface Props extends OwnProps {
  getMobileMinimumAppBuildNumber: (args: QueryAppRequirementsArgs) => void;
  updateMobileMinimumAppBuildNumber: (
    args: MutationUpdateMinimumAppBuildNumberArgs
  ) => void;
}

const MobileAppBuildNumber = (props: Props): JSX.Element => {
  const {
    appRequirementsList,
    success,
    getMobileMinimumAppBuildNumber,
    updateMobileMinimumAppBuildNumber,
  } = props;

  const [minIosBuildNumber, setMinIosBuildNumber] = useState<number>();
  const [minAndroidBuildNumber, setMinAndroidBuildNumber] = useState<number>();
  const [updated, setUpdated] = useState<DeviceType>();

  useEffect(() => {
    getMobileMinimumAppBuildNumber({ deviceType: DeviceType.Android });
    getMobileMinimumAppBuildNumber({ deviceType: DeviceType.Ios });
  }, []);

  useEffect(() => {
    if (appRequirementsList.length > 1) {
      setMinAndroidBuildNumber(
        appRequirementsList.find(
          (minVer) => minVer.deviceType === DeviceType.Android
        ).minimumAppBuildNumber
      );
      setMinIosBuildNumber(
        appRequirementsList.find(
          (minVer) => minVer.deviceType === DeviceType.Ios
        ).minimumAppBuildNumber
      );
      appRequirementsList.sort((a, b) => {
        if (a.deviceType < b.deviceType) {
          return -1;
        }
        if (a.deviceType > b.deviceType) {
          return 1;
        }

        return 0;
      });
    }
  }, [appRequirementsList]);

  return (
    <form>
      {appRequirementsList &&
        appRequirementsList.map((app) => {
          return (
            <div key={app.deviceType}>
              <div className="heading-normal nofocus">{app.deviceType}</div>
              <input
                name={app.deviceType}
                value={
                  app.deviceType === DeviceType.Android
                    ? minAndroidBuildNumber
                    : minIosBuildNumber
                }
                onChange={(event) => {
                  let value: number;
                  if (event.target.value === "") {
                    value = null;
                  } else {
                    value = Number.parseInt(event.target.value, 10);
                  }

                  switch (app.deviceType) {
                    case DeviceType.Android:
                      setMinAndroidBuildNumber(value);
                      break;
                    case DeviceType.Ios:
                      setMinIosBuildNumber(value);
                      break;
                  }
                }}
                style={{
                  width: 100,
                  borderRadius: 8,
                  border: `1px solid ${colors.caliber_gray_border}`,
                  padding: 5,
                  resize: "none",
                  lineHeight: "20px",
                }}
              />

              <button
                type="button"
                onClick={() => {
                  let buildNumber;
                  switch (app.deviceType) {
                    case DeviceType.Android:
                      buildNumber = minAndroidBuildNumber;
                      break;
                    case DeviceType.Ios:
                      buildNumber = minIosBuildNumber;
                      break;
                  }

                  updateMobileMinimumAppBuildNumber({
                    buildNumber,
                    deviceType: app.deviceType,
                  });

                  setUpdated(app.deviceType);
                }}
              >
                Update
              </button>
              {success && updated === app.deviceType && (
                <div>{app.deviceType} saved successfully</div>
              )}
              <p />
            </div>
          );
        })}
    </form>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getMobileMinimumAppBuildNumber: (args: QueryAppRequirementsArgs) => {
    dispatch(getAppRequirements(args));
  },
  updateMobileMinimumAppBuildNumber: (
    args: MutationUpdateMinimumAppBuildNumberArgs
  ) => {
    dispatch(updateMinimumAppBuildNumber(args));
  },
});

const mapStateToProps = (state: StoreState) => ({
  appRequirementsList: state.adminMobileAppBuildNumberState.appRequirementsList,
  success: state.adminMobileAppBuildNumberState.success,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileAppBuildNumber);
