import apolloClient from "../../../api/apolloClient";
import { CaliberRule } from "../../../types/gqlTypes";
import { RulesActionType, RulesAction } from "./types";
import { CALIBER_RULES_GQL } from "../../../api/gql/rules";

export const getRules = () => async (dispatch, getState) => {
  if (getState().tasksState.isLoading) {
    return;
  }

  dispatch({
    type: RulesActionType[RulesActionType.GET_RULES_LOADING],
  } as RulesAction);

  try {
    const client = await apolloClient(getState().authState.authToken, dispatch);

    const result: CaliberRule[] = (
      await client.query({
        query: CALIBER_RULES_GQL,
      })
    ).data.caliberRules as CaliberRule[];

    dispatch({
      type: RulesActionType[RulesActionType.GET_RULES_SUCCESS],
      ruleList: result,
    } as RulesAction);
  } catch (error) {
    console.error("Get Rules failed", error);
    dispatch({
      type: RulesActionType.GET_RULES_FAIL,
      data: error,
    });
  }
};
