export const SAVE_COMPARISON_IMAGE = "SAVE_COMPARISON_IMAGE";
export const DISCARD_COMPARISON_IMAGE = "DISCARD_COMPARISON_IMAGE";

export const UPLOAD_COMPARISON_IMAGE_UPLOADING =
  "UPLOAD_COMPARISON_IMAGE_UPLOADING";
export const UPLOAD_COMPARISON_IMAGE_SUCCESS =
  "UPLOAD_COMPARISON_IMAGE_SUCCESS";
export const UPLOAD_COMPARISON_IMAGE_FAIL = "UPLOAD_COMPARISON_IMAGE_FAIL";

export interface ComparisonToolState {
  library: { [key: string]: any };
  isUploading: false;
}

export interface ComparisonToolAction {
  type: string;
  img?: any;
  userId?: string;
}
