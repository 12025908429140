import React from "react";
import colors from "../../assets/colors";
import CheckedBox from "../../assets/images/CheckboxChecked.svg";
import UncheckedBox from "../../assets/images/CheckboxUnchecked.svg";
import { ExerciseEquipmentType, GymStep } from "../../types/gqlTypes";
import SimpleButton from "../SimpleButton";

interface Props {
  gymStep?: GymStep;
  hideWeight?: boolean;
  setHideWeight?: (value: boolean) => void;
}

const WeightField = (props: Props) => {
  const { gymStep, hideWeight, setHideWeight } = props;

  return (
    <div
      className="d-flex"
      style={{
        marginTop: 10,
        marginBottom: 10,
      }}
    >
      <div
        className="flex-grow-1 bold"
        style={{
          marginRight: 10,
          minWidth: 150,
          fontSize: "16px",
          lineHeight: "32px",
          letterSpacing: "-0.02em",
          color:
            gymStep?.exercise?.equipment !== ExerciseEquipmentType.Bodyweight
              ? colors.caliber_gray_text
              : colors.caliber_secondary,
        }}
      >
        Hide weight field
      </div>
      <SimpleButton
        nofocus
        disabled={
          gymStep?.exercise?.equipment !== ExerciseEquipmentType.Bodyweight
        }
        onClick={() => {
          setHideWeight(!hideWeight);
          (document.activeElement as HTMLElement)?.blur();
        }}
        className="align-items-center"
        style={{
          width: 24,
        }}
      >
        <img
          style={{
            width: 24,
            height: 24,
          }}
          src={hideWeight ? CheckedBox : UncheckedBox}
        />
      </SimpleButton>
    </div>
  );
};

export default WeightField;
