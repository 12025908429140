import React from "react";
import colors from "../../assets/colors";
import Modal from "../Modal";
import InsertClientFirstName from "../../assets/images/ClientFirstName.gif";
import DeleteIcon from "../../assets/images/DeleteSolidIcon.svg";
import SimpleButton from "../SimpleButton";

interface Props {
  onClose: () => void;
}

const InsertFirstNameModal = (props: Props) => {
  const { onClose } = props;

  const titleStyle: React.CSSProperties = {
    fontSize: "24px",
    lineHeight: "20px",
    color: colors.caliber_secondary,
    letterSpacing: "-0.033em",
  };
  const subTitleStyle: React.CSSProperties = {
    fontSize: "16px",
    lineHeight: "20px",
    color: colors.caliber_secondary,
    letterSpacing: "-0.011em",
    whiteSpace: "pre-line",
    marginTop: 16,
  };
  const textStyle: React.CSSProperties = {
    fontSize: "14px",
    lineHeight: "18px",
    marginTop: 16,
    color: colors.caliber_secondary,
  };
  const textWrapperStyle: React.CSSProperties = {
    width: 316,
  };
  const imageStyle: React.CSSProperties = {
    height: 352,
    borderRadius: 8,
    marginLeft: 35,
  };

  return (
    <Modal closeOnDimmerClick onClose={onClose}>
      <div
        className="d-flex flex-column"
        style={{
          padding: 32,
        }}
      >
        <div className="d-flex justify-content-end">
          <SimpleButton
            nofocus
            iconStyle={{ width: 20, height: 20 }}
            isIcon
            onClick={onClose}
          >
            {DeleteIcon}
          </SimpleButton>
        </div>
        <div
          className="d-flex"
          style={{
            maxWidth: 600,
            maxHeight: 350,
            marginTop: 25,
          }}
        >
          <div className="d-flex flex-column" style={textWrapperStyle}>
            <div className="bold" style={titleStyle}>
              Add your clients name
            </div>
            <div className="medium-bold" style={subTitleStyle}>
              This feature will let you insert your client’s first name
              automatically into these welcome message templates.
            </div>
            <div style={textStyle}>
              To use this feature, click on the ‘Insert Client’s First Name’
              button, with the text cursor positioned where you want to add
              their name in the message.
            </div>
            <div style={textStyle}>
              Once this has been added, it will display your client’s actual
              first name in the message that they receive.
            </div>
            <div style={textStyle}>
              You can use this as many times as needed as part of this welcome
              message sequence.
            </div>
          </div>
          <img style={imageStyle} src={InsertClientFirstName} />
        </div>
      </div>
    </Modal>
  );
};

export default InsertFirstNameModal;
