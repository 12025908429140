import React from "react";
import BSBadge from "react-bootstrap/Badge";
import "./badge.css";

export enum BadgeType {
  INFO = "info",
  ACTIVE = "active",
  INACTIVE = "inactive",
}

interface Props {
  text: string;
  type: BadgeType;
}

const Badge = (props: Props) => {
  const { text, type } = props;

  return <BSBadge className={`clbr-badge ${type}`}>{text}</BSBadge>;
};

export default Badge;
