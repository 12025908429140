import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";
import { StoreState } from "../../redux/reducers";
import colors from "../../assets/colors";
import SimpleButton from "../SimpleButton";
import "./index.css";
import { updateTrainerAutoMessages } from "../../redux/actions/User";
import { TrainerSettings, User, UserType } from "../../types/gqlTypes";
import { MasqueradeState } from "../../redux/actions/Masquerade/types";
import InsertFirstNameModal from "./InsertFirstNameModal";
import InfoIcon from "../../assets/images/InfoIcon.svg";

interface Props {
  updateTrainerAutoMessages: (
    trainerId: string,
    settingsId: string,
    firstAutoMessage: string,
    secondAutoMessage: string,
    thirdAutoMessage: string
  ) => void;
  user: User;
  masqueradeState: MasqueradeState;
}

const AutoMessage = (props: Props) => {
  const { updateTrainerAutoMessages, user, masqueradeState } = props;

  const varName = "{ Client First Name }";
  const [showModal, setShowModal] = useState(false);
  const [previewMode, setPreviewMode] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [value1, setValue1] = useState("");
  const [value2, setValue2] = useState("");
  const [value3, setValue3] = useState("");
  const textRef1 = useRef(null);
  const textRef2 = useRef(null);
  const textRef3 = useRef(null);
  const [currentInput, setCurrentInput] = useState(null);
  const [currentFocusPosition, setCurrentFocusPosition] = useState([
    null,
    null,
  ]);
  const isMasquerade = !!masqueradeState?.masqueradeTrainer;
  const isTrainer = user.type === UserType.Trainer;

  useEffect(() => {
    if (isMasquerade) {
      setValue1(
        (masqueradeState?.masqueradeTrainer?.settings as TrainerSettings)
          ?.firstAutoMessage || ""
      );
      setValue2(
        (masqueradeState?.masqueradeTrainer?.settings as TrainerSettings)
          ?.secondAutoMessage || ""
      );
      setValue3(
        (masqueradeState?.masqueradeTrainer?.settings as TrainerSettings)
          ?.thirdAutoMessage || ""
      );
    } else if (isTrainer) {
      setValue1((user?.settings as TrainerSettings)?.firstAutoMessage || "");
      setValue2((user?.settings as TrainerSettings)?.secondAutoMessage || "");
      setValue3((user?.settings as TrainerSettings)?.thirdAutoMessage || "");
    }
  }, [user, masqueradeState]);

  const findSelectionBorders = (
    text: string,
    cursorStart: number,
    cursorEnd: number,
    key: any
  ) => {
    const varLength = varName.length;
    let startIndex = 0;
    let foundIndex = text.indexOf(varName, startIndex);
    while (foundIndex !== -1) {
      if (
        (foundIndex <= cursorStart && foundIndex + varLength >= cursorStart) ||
        (foundIndex <= cursorEnd && foundIndex + varLength >= cursorEnd)
      ) {
        const selectionStart = Math.min(cursorStart, foundIndex);
        const selectionEnd = Math.max(cursorEnd, foundIndex + varLength);
        if (
          text.indexOf(varName, selectionEnd + 1) === selectionEnd + 1 &&
          key === "Delete"
        ) {
          return [selectionEnd + 1, selectionEnd + 1 + varLength];
        }
        if (
          key === "Delete" &&
          cursorStart === cursorEnd &&
          cursorEnd - 1 === foundIndex
        ) {
          return [foundIndex, foundIndex + varLength];
        }
        if (selectionStart === cursorEnd && key !== "Delete") {
          return [selectionStart, selectionStart];
        }
        if (selectionStart === cursorEnd && key === "Delete") {
          return [selectionStart, selectionEnd];
        }
        if (selectionEnd === cursorStart && key !== "Backspace") {
          return [selectionEnd, selectionEnd];
        }
        if (selectionEnd === cursorStart && key === "Backspace") {
          return [selectionStart, selectionEnd];
        }
        return [selectionStart, selectionEnd];
      }
      startIndex = foundIndex + 1;
      foundIndex = text.indexOf(varName, startIndex);
    }
    return [cursorStart, cursorEnd];
  };

  const moveFocus = (textRef, left, right) => {
    /* eslint-disable no-param-reassign */
    textRef.current.focus();
    textRef.current.selectionStart = left;
    textRef.current.selectionEnd = right;
    /* eslint-enable no-param-reassign */
  };

  const onAnyChangeTextarea = (event, textRef, value, whichTextarea) => {
    const cursorStart = (event.target as HTMLInputElement).selectionStart;
    const cursorEnd = (event.target as HTMLInputElement).selectionEnd;
    const [left, right] = findSelectionBorders(
      value,
      cursorStart,
      cursorEnd,
      event?.key
    );
    if (typeof left === "number" && textRef?.current) {
      moveFocus(textRef, left, right);
    }
    setCurrentFocusPosition([left, right]);
    setCurrentInput(whichTextarea);
  };

  const saveInput = (event, setValue) => {
    if (!previewMode && event.target.value.length <= 1000) {
      setValue(event.target.value);
      setHasChangesTrue();
    } else if (!previewMode) {
      setValue(event.target.value.slice(0, 1000));
      setHasChangesTrue();
    }
  };

  const insertVarName = useCallback(() => {
    const [left, right] = currentFocusPosition;
    if (currentInput === 1 && value1.length <= 1000 - varName.length) {
      setValue1(`${value1.slice(0, left)}${varName}${value1.slice(right)}`);
      moveFocus(textRef1, right + varName.length, right + varName.length);
    }
    if (currentInput === 2 && value2.length <= 1000 - varName.length) {
      setValue2(`${value2.slice(0, left)}${varName}${value2.slice(right)}`);
      moveFocus(textRef2, right + varName.length, right + varName.length);
    }
    if (currentInput === 3 && value3.length <= 1000 - varName.length) {
      setValue3(`${value3.slice(0, left)}${varName}${value3.slice(right)}`);
      moveFocus(textRef3, right + varName.length, right + varName.length);
    }
    setHasChangesTrue();
  }, [currentInput, currentFocusPosition, value1, value2, value3]);

  const setHasChangesTrue = useCallback(() => {
    if (!hasChanges) {
      setHasChanges(true);
    }
  }, [hasChanges]);

  const saveAutoMessages = () => {
    const masqueradeTrainer = masqueradeState?.masqueradeTrainer;

    const settingsId = isMasquerade
      ? masqueradeTrainer?.settings?.id
      : user?.settings?.id;
    const userId = isMasquerade ? masqueradeTrainer?.id : user?.id;
    updateTrainerAutoMessages(userId, settingsId, value1, value2, value3);

    setHasChanges(false);
  };

  const canBeSaved = useMemo(() => {
    if (!hasChanges) {
      return false;
    }
    if (value1.length > 0) {
      if (value2.length > 0) {
        return true;
      }
      if (value3.length > 0) {
        return false;
      }
      return true;
    }
    return true;
  }, [value1, value2, value3, hasChanges]);
  const isFirstTextareaErroneous = useMemo(() => {
    return value2.length > 0 && value1.length === 0;
  }, [value1, value2]);
  const isSecondTextareaErroneous = useMemo(() => {
    return value3.length > 0 && value2.length === 0;
  }, [value2, value3]);
  const isFirstTextareaDisabled = useMemo(() => {
    return previewMode;
  }, [previewMode]);
  const isSecondTextareaDisabled = useMemo(() => {
    return previewMode || value1.length === 0;
  }, [value1, previewMode]);
  const isThirdTextareaDisabled = useMemo(() => {
    return previewMode || value1.length === 0 || value2.length === 0;
  }, [value1, value2, previewMode]);

  const buttonStype: React.CSSProperties = {
    backgroundColor: colors.caliber_secondary_gray_5,
    borderRadius: 4,
    letterSpacing: "-0.011em",
    fontSize: "12px",
    lineHeight: "12px",
    width: 175,
    height: 23,
    marginRight: 14,
  };

  return (
    <div
      className="d-flex"
      style={{
        marginTop: 32,
        marginBottom: 23,
      }}
    >
      {showModal && (
        <InsertFirstNameModal onClose={() => setShowModal(false)} />
      )}
      <div style={{ flex: 2 }}>
        <div
          className="bold"
          style={{
            marginTop: 12,
            fontSize: "18px",
            lineHeight: "18px",
            color: colors.caliber_secondary,
            letterSpacing: "-0.033em",
          }}
        >
          New member auto message
        </div>
      </div>
      <div
        className="d-flex flex-column"
        style={{
          flex: 3,
          minHeight: 480,
          backgroundColor: colors.caliber_white,
          borderRadius: 8,
          border: `1px solid ${colors.caliber_secondary_gray_5}`,
        }}
      >
        <div style={{ margin: 24 }}>
          <div className="d-flex justify-content-between">
            <SimpleButton
              className="medium-bold d-flex align-items-center justify-content-center nofocus"
              onClick={() => setPreviewMode(!previewMode)}
              style={{
                ...buttonStype,
                width: 75,
                backgroundColor: previewMode
                  ? colors.caliber_green_200
                  : colors.caliber_secondary_gray_5,
              }}
            >
              PREVIEW
            </SimpleButton>
            <div
              className="d-flex justify-content-end align-items-center"
              style={{
                marginBottom: 17,
              }}
            >
              <SimpleButton
                className="bold d-flex align-items-center justify-content-center nofocus"
                style={buttonStype}
                disabled={typeof currentFocusPosition[0] !== "number"}
                onClick={insertVarName}
              >
                INSERT CLIENT FIRST NAME
              </SimpleButton>
              <SimpleButton
                nofocus
                iconStyle={{ width: 20, height: 20 }}
                isIcon
                onClick={() => setShowModal(true)}
              >
                {InfoIcon}
              </SimpleButton>
            </div>
          </div>
          <textarea
            disabled={isFirstTextareaDisabled}
            ref={textRef1}
            className={`medium-bold styledTextarea nofocus ${
              isFirstTextareaErroneous ? "error" : ""
            }`}
            value={
              previewMode
                ? (value1 || "").split(varName).join(user?.firstName)
                : value1
            }
            onKeyDown={(event) =>
              onAnyChangeTextarea(event, textRef1, value1, 1)
            }
            onKeyUp={(event) => onAnyChangeTextarea(event, textRef1, value1, 1)}
            onClick={(event) => onAnyChangeTextarea(event, textRef1, value1, 1)}
            onChange={(event) => {
              saveInput(event, setValue1);
              onAnyChangeTextarea(event, textRef1, value1, 1);
            }}
          />
          <div className="charNum medium-bold d-flex justify-content-end">
            {`${value1.length} / 1000 characters`}
          </div>
          <div>
            <textarea
              disabled={isSecondTextareaDisabled}
              ref={textRef2}
              className={`medium-bold styledTextarea nofocus ${
                isSecondTextareaErroneous ? "error" : ""
              }`}
              value={
                previewMode
                  ? (value2 || "").split(varName).join(user?.firstName)
                  : value2
              }
              onKeyDown={(event) =>
                onAnyChangeTextarea(event, textRef2, value2, 2)
              }
              onKeyUp={(event) =>
                onAnyChangeTextarea(event, textRef2, value2, 2)
              }
              onClick={(event) =>
                onAnyChangeTextarea(event, textRef2, value2, 2)
              }
              onChange={(event) => {
                saveInput(event, setValue2);
                onAnyChangeTextarea(event, textRef2, value2, 2);
              }}
            />
            <div className="charNum medium-bold d-flex justify-content-end">
              {`${value2.length} / 1000 characters`}
            </div>
            <textarea
              disabled={isThirdTextareaDisabled}
              ref={textRef3}
              className="medium-bold styledTextarea nofocus"
              value={
                previewMode
                  ? (value3 || "").split(varName).join(user?.firstName)
                  : value3
              }
              onKeyDown={(event) =>
                onAnyChangeTextarea(event, textRef3, value3, 3)
              }
              onKeyUp={(event) =>
                onAnyChangeTextarea(event, textRef3, value3, 3)
              }
              onClick={(event) =>
                onAnyChangeTextarea(event, textRef3, value3, 3)
              }
              onChange={(event) => {
                saveInput(event, setValue3);
                onAnyChangeTextarea(event, textRef3, value3, 3);
              }}
            />
            <div className="charNum medium-bold d-flex justify-content-end">
              {`${value3.length} / 1000 characters`}
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <SimpleButton
              onClick={saveAutoMessages}
              disabled={!canBeSaved}
              nofocus={!canBeSaved}
              className="d-flex align-items-center justify-content-center medium-bold"
              style={{
                fontSize: "14px",
                color: colors.caliber_green_200,
                backgroundColor: colors.caliber_green_100,
                width: 187,
                height: 32,
                borderRadius: 4,
                marginTop: 20,
                opacity: canBeSaved ? 1 : 0.5,
              }}
            >
              Save Auto Message
            </SimpleButton>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateTrainerAutoMessages: (
    trainerId: string,
    settingsId: string,
    firstAutoMessage: string,
    secondAutoMessage: string,
    thirdAutoMessage: string
  ) => {
    dispatch(
      updateTrainerAutoMessages(
        trainerId,
        settingsId,
        firstAutoMessage,
        secondAutoMessage,
        thirdAutoMessage
      )
    );
  },
});

const mapStateToProps = (state: StoreState) => ({
  masqueradeState: state.masqueradeState,
  user: state.authenticatedUserState.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoMessage);
