import React from "react";
import _ from "lodash";
import colors from "../../assets/colors";
import PlanFilter from "../PlanFilter";
import { QuerySearchMasterTrainingPlansArgs } from "../../types/gqlTypes";

interface Props {
  onChangeFilterState: (newState: QuerySearchMasterTrainingPlansArgs) => void;
  filterState: QuerySearchMasterTrainingPlansArgs;
}

const MasterPlansFilter = (props: Props) => {
  const { onChangeFilterState, filterState } = props;

  const onChange = (props) => {
    const { type, name, isSelected } = props;
    const newFilterState = _.cloneDeep(filterState);
    if (isSelected) {
      if (newFilterState[type].length > 1) {
        _.remove(newFilterState[type], (val) => val === name);
      } else {
        delete newFilterState[type];
      }
    } else {
      if (!(type in newFilterState)) {
        newFilterState[type] = [];
      }
      newFilterState[type].push(name);
    }
    onChangeFilterState(newFilterState);
  };

  return (
    <div
      className="d-flex flex-column"
      style={{
        flex: 1,
      }}
    >
      <div
        className="d-flex justify-content-between"
        style={{
          flex: "0 0 60px",
          padding: "2px 0px",
        }}
      >
        <div
          style={{
            lineHeight: "32px",
            color: colors.caliber_gray_text,
          }}
          className="paragraph-normal align-self-center"
        >
          Filter
        </div>
      </div>
      <PlanFilter
        noVersions
        onChangeFilterState={onChange}
        filterState={filterState}
      />
    </div>
  );
};
export default MasterPlansFilter;
