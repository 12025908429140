import React from "react";
import {
  durationDisplayText,
  frequencyDisplayText,
  fullGenderDisplayText,
  fullPlanTypeDisplayText,
  planTypeDisplayText,
  programTypeDisplayText,
  repRangeDisplayText,
  versionDisplayText,
} from "../../models/tags";
import {
  QuerySearchMasterTrainingPlansArgs,
  TrainingPlanDurationType,
  TrainingPlanFrequencyType,
  TrainingPlanProgramType,
  TrainingPlanRepRangeType,
  TrainingPlanType,
  TrainingPlanVersionType,
} from "../../types/gqlTypes";
import ButtonTagGroup from "../ButtonTagGroup";

interface FilterItemProps {
  title: string;
  tag: string;
  type: string;
  names: string[];
  displayTextFunction: (text: string) => string;
  hoverDisplayTextFunction?: (text: string) => string;
}
interface Props {
  onChangeFilterState: (props: object) => void;
  noVersions?: boolean;
  filterState: QuerySearchMasterTrainingPlansArgs;
}
export const filterItems: Array<FilterItemProps> = [
  {
    title: "Gender",
    tag: "gender",
    type: "genderType",
    names: ["FEMALE", "MALE"],
    displayTextFunction: fullGenderDisplayText,
  },
  {
    title: "Program",
    tag: "program",
    type: "programType",
    names: Object.values(TrainingPlanProgramType),
    displayTextFunction: programTypeDisplayText,
  },
  {
    title: "Type",
    tag: "type",
    type: "planType",
    names: Object.values(TrainingPlanType),
    displayTextFunction: planTypeDisplayText,
    hoverDisplayTextFunction: fullPlanTypeDisplayText,
  },
  {
    title: "Rep Range",
    tag: "repRange",
    type: "repRangeType",
    names: Object.values(TrainingPlanRepRangeType),
    displayTextFunction: repRangeDisplayText,
  },
  {
    title: "Duration",
    tag: "duration",
    type: "durationType",
    names: Object.values(TrainingPlanDurationType),
    displayTextFunction: durationDisplayText,
  },
  {
    title: "Frequency",
    tag: "frequency",
    type: "frequencyType",
    names: Object.values(TrainingPlanFrequencyType),
    displayTextFunction: frequencyDisplayText,
  },
  {
    title: "Version",
    tag: "planVersion",
    type: "versionType",
    names: Object.values(TrainingPlanVersionType),
    displayTextFunction: versionDisplayText,
  },
];

const PlanFilter = (props: Props) => {
  const { onChangeFilterState, noVersions, filterState } = props;

  const onClickTag = (props) => {
    onChangeFilterState(props);
  };
  return (
    <div
      className="d-flex flex-column overflow-auto"
      style={{
        flex: 1,
      }}
    >
      {filterItems
        .filter((item) => !noVersions || item.title !== "Version")
        .map(
          ({
            title,
            type,
            tag,
            names,
            displayTextFunction,
            hoverDisplayTextFunction,
          }) => {
            return (
              <ButtonTagGroup
                key={title}
                names={names}
                title={title}
                variant="filter-button"
                buttonMinWidth={96}
                displayTextFunction={displayTextFunction}
                hoverDisplayTextFunction={hoverDisplayTextFunction}
                selectedNames={filterState?.[type] || []}
                onClick={(props: object) => onClickTag({ tag, type, ...props })}
              />
            );
          }
        )}
    </div>
  );
};
export default PlanFilter;
