import * as SockJS from "sockjs-client";
import Stomp from "stompjs";
import { getAppConfig } from "../../../config";

const VideoUploadWebsocketInstance = (() => {
  const state = { stompClient: null };
  return {
    subscribe: (
      token: string,
      videoId?: string,
      cb?: (body: string) => void
    ) => {
      if (!state.stompClient) {
        const socket = new SockJS(`${getAppConfig().apiUrl}/websocket`);
        state.stompClient = Stomp.over(socket);
      }
      state.stompClient.connect({ Authorization: `Bearer ${token}` }, () => {
        state.stompClient.subscribe(`/topic/encoding/${videoId}`, (info) => {
          cb(info.body);
        });
      });
    },

    unsubscribe: () => {
      if (state.stompClient && state.stompClient.connected) {
        state.stompClient.disconnect();
        delete state.stompClient;
      }
    },
  };
})();
Object.freeze(VideoUploadWebsocketInstance);
export default VideoUploadWebsocketInstance;
