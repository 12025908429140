import { ClientWorkout, FormVideo } from "../../../types/gqlTypes";

export const FETCHING_CLIENT_WORKOUT_HISTORY_SUCCESS =
  "FETCHING_CLIENT_WORKOUT_HISTORY_SUCCESS";
export const FETCHING_CLIENT_WORKOUT_HISTORY_FAIL =
  "FETCHING_CLIENT_WORKOUT_HISTORY_FAIL";
export const FETCHING_CLIENT_WORKOUT_HISTORY_LOADING =
  "FETCHING_CLIENT_WORKOUT_HISTORY_LOADING";

export const FETCHING_FORM_VIDEOS_BY_DATE_RANGE_SUCCESS =
  "FETCHING_FORM_VIDEOS_BY_DATE_RANGE_SUCCESS";
export const FETCHING_FORM_VIDEOS_BY_DATE_RANGE_FAIL =
  "FETCHING_FORM_VIDEOS_BY_DATE_RANGE_FAIL";
export const FETCHING_FORM_VIDEOS_BY_DATE_RANGE_LOADING =
  "FETCHING_FORM_VIDEOS_BY_DATE_RANGE_LOADING";

export const RESET_CLIENT_WORKOUT_HISTORY_STATE =
  "RESET_CLIENT_WORKOUT_HISTORY_STATE";
export const SELECT_WORKOUT_PROGRESS_TABLE = "SELECT_WORKOUT_PROGRESS_TABLE";

export interface ClientWorkoutHistoryState {
  isLoading: boolean;
  isLoadingFormVideos: boolean;
  selectedWorkoutId: string;
  clientWorkoutHistory: ClientWorkout[];
  formVideos: FormVideo[];
  args: {
    clientWorkoutTemplateId: string;
    maxQueryResults: number;
  };
}

export interface ClientWorkoutHistoryAction {
  type: string;
  id?: string;
  formVideos?: FormVideo[];
  data?: ClientWorkout[];
  args?: {
    clientWorkoutTemplateId: string;
    maxQueryResults: number;
  };
}
