import apolloClient from "../../../api/apolloClient";
import {
  CREATE_CLIENT_QUERY,
  UPDATE_CLIENT_QUERY,
} from "../../../api/gql/users/clients";
import {
  MutationCreateClientFromWebArgs,
  MutationUpdateClientFromWebArgs,
} from "../../../types/gqlTypes";
import { searchClientsTab } from "../ClientsTab";
import {
  CREATE_CLIENT_FAIL,
  CREATE_CLIENT_LOADING,
  CREATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAIL,
  UPDATE_CLIENT_LOADING,
  UPDATE_CLIENT_SUCCESS,
} from "./types";

export const createClientFromWeb =
  (args: MutationCreateClientFromWebArgs) => async (dispatch, getState) => {
    dispatch({
      type: CREATE_CLIENT_LOADING,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const result = await client.mutate({
        mutation: CREATE_CLIENT_QUERY,
        variables: args,
      });
      dispatch({
        type: CREATE_CLIENT_SUCCESS,
        user: result.data.createClientFromWeb,
      });
      dispatch(searchClientsTab({}));
    } catch (error) {
      dispatch({
        type: CREATE_CLIENT_FAIL,
      });
      dispatch(searchClientsTab({}));
    }
  };

export const updateClientFromWeb =
  (args: MutationUpdateClientFromWebArgs) => async (dispatch, getState) => {
    dispatch({
      type: UPDATE_CLIENT_LOADING,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const result = await client.mutate({
        mutation: UPDATE_CLIENT_QUERY,
        variables: args,
      });

      dispatch({
        type: UPDATE_CLIENT_SUCCESS,
        data: result,
      });
      dispatch(searchClientsTab({}));
    } catch (error) {
      dispatch({
        type: UPDATE_CLIENT_FAIL,
      });
    }
  };
