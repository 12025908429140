import React from "react";
import Popup from "../Popover";
import colors from "../../assets/colors";
import NotebookIcon from "../../assets/images/NotebookIcon.svg";

interface Props {
  index: number;
  feedback: {
    rating: number;
    notes: string;
  };
}

const Feedback = (props: Props) => {
  const { feedback, index } = props;

  const ratingDescription = {
    1: "Effortless",
    2: "Very Easy",
    3: "Easy",
    4: "Comfortable",
    5: "Somewhat Difficult",
    6: "Difficult",
    7: "Hard",
    8: "Very Hard",
    9: "Extremely Hard",
    10: "Exhausting",
  };

  const ratingDiv = (rating, size: "small" | "large") => {
    const isLarge = size === "large";
    return (
      <div
        role="button"
        tabIndex={0}
        className="d-flex justify-content-center align-items-center medium-bold nofocus pointer"
        style={{
          width: isLarge ? 30 : 16,
          height: isLarge ? 30 : 16,
          borderRadius: isLarge ? 15 : 8,
          color: colors.caliber_white,
          backgroundColor: colors.caliber_primary,
          fontSize: isLarge ? "24px" : "12px",
          marginLeft: !isLarge && 10,
        }}
      >
        {rating}
      </div>
    );
  };
  const ratingPopup = (rating) => {
    if (typeof rating !== "number") {
      return null;
    }
    return (
      <Popup
        popupkey="ratingPopup"
        trigger={ratingDiv(rating, "small")}
        triggerEvents={["hover"]}
        placement="bottom-start"
      >
        <div
          className="d-flex align-items-center"
          style={{
            backgroundColor: colors.caliber_white,
            borderRadius: 9,
            boxShadow: "0px 2px 16px #E7E7EB",
            height: 56,
            width: 160,
            padding: 12,
          }}
        >
          {ratingDiv(rating, "large")}
          <div
            className="d-flex flex-column"
            style={{
              marginLeft: 12,
            }}
          >
            {ratingDescription?.[rating]?.split(" ").map((text) => {
              return (
                <div
                  key={`rating-key-${index}`}
                  className="bold"
                  style={{
                    fontSize: "14px",
                    lineHeight: "16px",
                    letterSpacing: "-0.022em",
                    color: colors.caliber_secondary,
                  }}
                >
                  {text}
                </div>
              );
            })}
          </div>
        </div>
      </Popup>
    );
  };
  const notesPopup = (notes, rating) => {
    if (!notes) {
      return null;
    }
    return (
      <Popup
        popupkey="notesPopup"
        trigger={
          <div
            role="button"
            tabIndex={0}
            className="nofocus pointer"
            style={{ marginLeft: typeof rating !== "number" ? 10 : 4 }}
          >
            <img src={NotebookIcon} />
          </div>
        }
        triggerEvents={["hover"]}
        placement="bottom-start"
      >
        <div
          className="d-flex align-items-center"
          style={{
            backgroundColor: colors.caliber_white,
            borderRadius: 9,
            boxShadow: "0px 2px 16px #E7E7EB",
            minHeight: 56,
            width: 190,
            padding: 16,
          }}
        >
          <div
            className="medium-bold"
            style={{
              fontSize: "14px",
              lineHeight: "18px",
              letterSpacing: "-0.011em",
              color: colors.caliber_gray_text,
            }}
          >
            {notes}
          </div>
        </div>
      </Popup>
    );
  };

  return (
    <div className="d-flex">
      {ratingPopup(feedback?.rating)}
      {notesPopup(feedback?.notes, feedback?.rating)}
    </div>
  );
};

export default Feedback;
