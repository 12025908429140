import { gql } from "apollo-boost";
import { mediaUrlFragment } from "../users";

export const CREATE_MUX_VIDEO_UPLOAD = gql`
  ${mediaUrlFragment}
  mutation CreateMuxVideoUpload(
    $corsOrigin: String!
    $fileUploadType: FileUploadType!
  ) {
    createMuxVideoUpload(
      corsOrigin: $corsOrigin
      fileUploadType: $fileUploadType
    ) {
      ...MediaUrl
    }
  }
`;

export const CREATE_MUX_VIDEO_MESSAGE_UPLOAD = gql`
  mutation CreateMuxVideoMessageUpload($corsOrigin: String!, $clientId: UUID!) {
    createMuxVideoMessageUpload(corsOrigin: $corsOrigin, clientId: $clientId) {
      id
      mimeType
      muxVideoUpload {
        assetId
        id
        muxJson
        muxUploadId
        playbackId
        status
        uploadUrl
        videoProcessingStatus
      }
      type
      url
      originUrl
    }
  }
`;

export const DELETE_VIDEO_MESSAGE_URL = gql`
  mutation DeleteVideoMessageUrl($clientId: UUID!, $mediaUrlId: UUID!) {
    deleteVideoMessageMediaUrl(clientId: $clientId, mediaUrlId: $mediaUrlId)
  }
`;

export const FETCH_MEDIA = gql`
  ${mediaUrlFragment}
  query MediaUrl($mediaUrlId: UUID) {
    mediaUrl(mediaUrlId: $mediaUrlId) {
      ...MediaUrl
    }
  }
`;
