import React from "react";
import ClientProfileInfoModule from "../../components/ClientProfileInfoModule";
import Habits from "../../components/Habits";

interface Props {}

const TrainerClientHabitTab = (props: Props) => {
  const {} = props;

  return (
    <div className="d-flex">
      <div className="flex-grow-0 margin-13-12 d-flex">
        <ClientProfileInfoModule fullView />
      </div>

      <div
        className="d-flex margin-13-12"
        style={{
          flex: 1,
        }}
      >
        <Habits />
      </div>
    </div>
  );
};

export default TrainerClientHabitTab;
