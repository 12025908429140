import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import { StoreState } from "../../redux/reducers";
import ButtonTag from "../ButtonTag";
import colors from "../../assets/colors";
import Input from "../Input";
import { useDebounce } from "../../utils/customHooks";
import { searchTrainers } from "../../redux/actions/AdminTeamTrainers";
import {
  Manager,
  Trainer,
  User,
  UserStatusType,
  UserType,
} from "../../types/gqlTypes";
import { searchManagers } from "../../redux/actions/AdminTeamManagers";
import UserShortCard from "../UserShortCard";
import Loader from "../Loader";
import TeamMemberStatusDropdown from "./TeamMemberStatusDropdown";

interface OwnProps {
  createNewUser: () => void;
  closeForm: () => void;
  startViewingUser: (user: Manager & Trainer) => void;
  startEditingUser: (user: Manager & Trainer) => void;
  onEditProfile: (user: Manager & Trainer) => void;
  createEditMode: boolean;
  selectedUserId?: string;
}
interface Props extends OwnProps {
  foundManagers: Manager[];
  pageOfManagers: number;
  totalPagesOfManagers: number;

  foundTrainers: Trainer[];
  pageOfTrainers: number;
  totalPagesOfTrainers: number;

  searchManagers: (
    page: number,
    nameCriteria: string,
    statusCriteria: UserStatusType[]
  ) => void;
  searchTrainers: (
    page: number,
    nameCriteria: string,
    statusCriteria: UserStatusType[]
  ) => void;

  userType: UserType;
}
enum Types {
  Trainers = "trainers",
  Managers = "managers",
}

const TeamList = (props: Props) => {
  const {
    foundManagers,
    pageOfManagers,
    totalPagesOfManagers,

    foundTrainers,
    pageOfTrainers,
    totalPagesOfTrainers,

    searchManagers,
    searchTrainers,

    createNewUser,
    closeForm,
    createEditMode,
    startViewingUser,
    startEditingUser,
    onEditProfile,
    selectedUserId,

    userType,
  } = props;

  const [current, setCurrent] = useState(Types.Trainers);

  const [searchNameTrainers, setSearchNameTrainers] = useState("");
  const [searchStatusTrainers, setSearchStatusTrainers] = useState<
    UserStatusType[]
  >([UserStatusType.Active]);
  const [searchNameManagers, setSearchNameManagers] = useState("");
  const [searchStatusManagers, setSearchStatusManagers] = useState<
    UserStatusType[]
  >([UserStatusType.Active]);
  const debouncedSearchTrainers = useDebounce(searchNameTrainers, 400);
  const debouncedSearchManagers = useDebounce(searchNameManagers, 400);

  const {
    nameString,
    statusCriteria,
    page,
    totalPages,
    userList,
    onChangeNameCriteria,
    onChangeStatusCriteria,
    placeholder,
  }: {
    nameString: string;
    statusCriteria: UserStatusType[];
    page: number;
    totalPages: number;
    userList: User[];
    onChangeNameCriteria: React.Dispatch<React.SetStateAction<string>>;
    onChangeStatusCriteria: React.Dispatch<
      React.SetStateAction<UserStatusType[]>
    >;
    placeholder: string;
  } = useMemo(() => {
    const params = {
      nameString: searchNameTrainers as string,
      statusCriteria: searchStatusTrainers as UserStatusType[],
      page: pageOfTrainers as number,
      totalPages: totalPagesOfTrainers as number,
      userList: foundTrainers as User[],
      placeholder: "Search Coaches" as string,
      onChangeNameCriteria: setSearchNameTrainers as React.Dispatch<
        React.SetStateAction<string>
      >,
      onChangeStatusCriteria: setSearchStatusTrainers as React.Dispatch<
        React.SetStateAction<UserStatusType[]>
      >,
    };
    if (current === Types.Managers) {
      params.nameString = searchNameManagers;
      params.statusCriteria = searchStatusManagers;
      params.page = pageOfManagers;
      params.totalPages = totalPagesOfManagers;
      params.userList = foundManagers;
      params.placeholder = "Search Coach Managers";
      params.onChangeNameCriteria = setSearchNameManagers;
      params.onChangeStatusCriteria = setSearchStatusManagers;
    }
    return params;
  }, [
    current,
    searchNameTrainers,
    searchNameManagers,
    searchStatusTrainers,
    searchStatusManagers,
    foundTrainers,
    foundManagers,
  ]);

  const loadMore = () => {
    if (current === Types.Trainers) {
      searchTrainers(page + 1, searchNameTrainers, searchStatusTrainers);
    } else if (current === Types.Managers) {
      searchManagers(page + 1, searchNameManagers, searchStatusManagers);
    }
  };

  useEffect(() => {
    if (current === Types.Trainers) {
      searchTrainers(0, debouncedSearchTrainers, searchStatusTrainers);
    } else if (current === Types.Managers) {
      searchManagers(0, debouncedSearchManagers, searchStatusManagers);
    }
  }, [
    createEditMode,
    current,
    debouncedSearchManagers,
    debouncedSearchTrainers,
    searchStatusManagers,
    searchStatusTrainers,
  ]);

  return (
    <div
      className="d-flex"
      style={{
        width: "100%",
      }}
    >
      <div
        className="d-flex flex-column"
        style={{
          flex: 1,
        }}
      >
        <div
          className="d-flex"
          style={{
            marginLeft: 8,
            marginRight: 8,
          }}
        >
          <Input
            placeholder={placeholder}
            value={nameString}
            onChange={onChangeNameCriteria}
          />
          {/* @ts-ignore */}
          <TeamMemberStatusDropdown
            value={statusCriteria}
            onChange={onChangeStatusCriteria}
          />
        </div>
        <div
          className="d-flex"
          style={{
            marginLeft: 8,
            marginRight: 8,
          }}
        >
          <ButtonTag
            text="Coaches"
            padding="16px 20px"
            textColor={
              current === Types.Trainers
                ? colors.caliber_secondary
                : colors.caliber_placeholder_color
            }
            bgColor={colors.caliber_gray_5}
            onClick={() => {
              setCurrent(Types.Trainers);
              closeForm();
            }}
          />
          {userType === UserType.Admin && (
            <ButtonTag
              text="Coach Managers"
              padding="16px 20px"
              margin={12}
              textColor={
                current === Types.Managers
                  ? colors.caliber_secondary
                  : colors.caliber_placeholder_color
              }
              bgColor={colors.caliber_gray_5}
              onClick={() => {
                setCurrent(Types.Managers);
                closeForm();
              }}
            />
          )}
          <div
            className="d-flex justify-content-end"
            style={{
              flex: 1,
            }}
          >
            {!createEditMode && !selectedUserId && (
              <ButtonTag
                text="Add New"
                onClick={createNewUser}
                height={32}
                textColor={colors.caliber_white}
                bgColor={colors.caliber_green_200}
                minWidth={134}
              />
            )}
          </div>
        </div>
        <div>
          <InfiniteScroll
            className="d-flex flex-wrap"
            pageStart={0}
            useWindow={false}
            loadMore={loadMore}
            hasMore={page < totalPages - 1}
            loader={
              <div
                style={{
                  position: "absolute",
                  flex: 1,
                  bottom: 16,
                  left: "50%",
                  right: "50%,",
                }}
              >
                <Loader />
              </div>
            }
          >
            {userList?.map((user, index) => {
              const meatballOptions = [
                {
                  text: "Edit info",
                  onClick: () => startEditingUser(user as User),
                },
              ];
              if (current === Types.Trainers) {
                meatballOptions.push({
                  text: "Edit profile",
                  onClick: () => {
                    onEditProfile(user as User);
                  },
                });
              }
              return (
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => startViewingUser(user as User)}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      startViewingUser(user as User);
                    }
                  }}
                  key={user.id}
                  style={{
                    outline: "none",
                    flex: 0,
                    margin: 8,
                  }}
                >
                  <UserShortCard
                    showUserType
                    showMenu
                    cardColor={
                      selectedUserId === user.id
                        ? colors.caliber_dark_gray
                        : colors.caliber_gray_5
                    }
                    key={user.id}
                    user={user}
                    showMenuItems={meatballOptions}
                    showUserStatus
                  />
                </div>
              );
            })}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  foundManagers: state.adminSearchManagersState.foundManagers,
  isLoadingManagers: state.adminSearchManagersState.isLoading,
  pageOfManagers: state.adminSearchManagersState.page,
  totalPagesOfManagers: state.adminSearchManagersState.totalPages,

  foundTrainers: state.adminSearchTrainersState.foundTrainers,
  isLoadingTrainers: state.adminSearchTrainersState.isLoading,
  pageOfTrainers: state.adminSearchTrainersState.page,
  totalPagesOfTrainers: state.adminSearchTrainersState.totalPages,

  createNewUser: ownProps.createNewUser,
  startViewingUser: ownProps.startViewingUser,
  startEditingUser: ownProps.startEditingUser,
  onEditProfile: ownProps.onEditProfile,
  closeForm: ownProps.closeForm,
  createEditMode: ownProps.createEditMode,

  userType: state.authenticatedUserState.user.type,
});
const mapDispatchToProps = (dispatch) => ({
  searchTrainers: (
    page: number,
    nameCriteria: string,
    statusCriteria: UserStatusType[]
  ) => {
    dispatch(searchTrainers(page, nameCriteria, statusCriteria));
  },
  searchManagers: (
    page: number,
    nameCriteria: string,
    statusCriteria: UserStatusType[]
  ) => {
    dispatch(searchManagers(page, nameCriteria, statusCriteria));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(TeamList);
