import React from "react";
import colors from "../../../assets/colors";
import { ClientPackageType, ClientSearchResult } from "../../../types/gqlTypes";

interface Props {
  width?: number;
  isLastColumn?: boolean;
  isDragging?: boolean;
  row: ClientSearchResult;
}

const PackageCell = (props: Props) => {
  const { width, isLastColumn, isDragging, row } = props;

  const { clientPackageType } = row;
  const borderColor = colors.table_gray;
  const rowHeight = 64;

  const properties = {
    [ClientPackageType.Premium]: {
      minWidth: 66,
      backgroundColor: "#FFCC32",
      color: "#A18329",
    },
    [ClientPackageType.Freemium]: {
      minWidth: 80,
      backgroundColor: "#0BA34B",
      color: "#FFFFFF",
    },
    [ClientPackageType.Pro]: {
      minWidth: 80,
      backgroundColor: "#9747FF",
      color: "#FFFFFF",
    },
    [ClientPackageType.Plus]: {
      minWidth: 80,
      backgroundColor: "#9747FF",
      color: "#FFFFFF",
    },
    [ClientPackageType.Legacy]: {
      minWidth: 64,
      backgroundColor: "#C2C8FE",
      color: "#3547FC",
    },
    [ClientPackageType.TestAccount]: {
      minWidth: 90,
      backgroundColor: "#C2C8FE",
      color: "#3547FC",
    },
  };
  let title;
  switch (clientPackageType) {
    case ClientPackageType.Premium:
      title = "Premium";
      break;
    case ClientPackageType.Freemium:
      title = "Freemium";
      break;
    case ClientPackageType.Pro:
      title = "Pro";
      break;
    case ClientPackageType.Plus:
      title = "Plus";
      break;
    case ClientPackageType.Legacy:
      title = "Legacy";
      break;
    case ClientPackageType.TestAccount:
      title = "Test Account";
      break;
    default:
      title = "n/a";
  }

  return (
    <td
      className="d-flex"
      style={{
        width,
        minWidth: isLastColumn && width,
        backgroundColor: colors.caliber_gray_bg,
        position: "relative",
      }}
    >
      <div
        className="d-flex"
        style={{
          position: "absolute",
          top: isDragging ? -8 : 0,
          backgroundColor: isDragging ? "#E8EAEE" : "transparent",
          width: "100%",
          height: isDragging ? rowHeight + 16 : rowHeight,
          zIndex: isDragging ? 4 : 1,
          marginBottom: isDragging ? 0 : 8,
        }}
      >
        <div
          className="d-flex align-items-center"
          style={{
            flex: "1 1 100%",
            zIndex: isDragging ? 3 : 2,
            backgroundColor: colors.caliber_white,
            height: rowHeight,
            borderRight: `1px solid ${borderColor}`,
            paddingLeft: 18,
            margin: isDragging ? 8 : 0,
            borderTopRightRadius: isLastColumn && 8,
            borderBottomRightRadius: isLastColumn && 8,

            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {clientPackageType ? (
            <div
              className="d-flex align-items-center justify-content-center medium-bold"
              style={{
                fontSize: "12px",
                lineHeight: "16px",
                height: 24,
                borderRadius: 4,
                ...(properties?.[clientPackageType] && {
                  ...properties?.[clientPackageType],
                }),
              }}
            >
              {title}
            </div>
          ) : (
            <div>{title}</div>
          )}
        </div>
      </div>
    </td>
  );
};

export default PackageCell;
