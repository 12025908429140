import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../redux/configureStore";
import SendbirdChat from "@sendbird/chat";
import { getAppConfig } from "../../../config";
import {
  GroupChannelCollectionEventHandler,
  GroupChannelFilter,
  GroupChannelListOrder,
  GroupChannelModule,
  MessageCollectionInitPolicy,
  MessageFilter,
} from "@sendbird/chat/groupChannel";
import { BaseMessage } from "@sendbird/chat/message";
import { setMessageCollection, setMessages } from "./messengerSlice";

const appConfig = getAppConfig();

const loadChannels = async (sendbird, channelHandlers) => {
  const groupChannelFilter = new GroupChannelFilter();
  groupChannelFilter.includeEmpty = true;

  const collection = sendbird.groupChannel.createGroupChannelCollection({
    filter: groupChannelFilter,
    order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
  });
  collection.setGroupChannelCollectionHandler(channelHandlers);

  const channels = await collection.loadMore();
  return channels;
};

export const connectToSendbird = createAsyncThunk(
  "messenger/connectToSendbird",
  async (channelHandlers: GroupChannelCollectionEventHandler, { getState }) => {
    const state = getState() as RootState;
    const sendbird = await SendbirdChat.init({
      appId: appConfig.sbAppId,
      localCacheEnabled: true,
      modules: [new GroupChannelModule()],
    });
    await sendbird.connect(
      state.authenticatedUserState.user.id,
      state.authenticatedUserState.user.sendbirdAccessToken
    );
    const channels = await loadChannels(sendbird, channelHandlers);
    return channels;
  }
);

export const createMessageCollection = createAsyncThunk(
  "messenger/createMessageCollection",
  async (args: any, { getState, dispatch }) => {
    const state = getState() as RootState;
    const activeChannel = state.messenger.activeChannel;
    if (
      activeChannel.messageCollection &&
      activeChannel.messageCollection.dispose
    ) {
      activeChannel.messageCollection.dispose();
    }

    if (activeChannel.channel.createMessageCollection) {
      // Set up new MessageCollection
      const messageFilter = new MessageFilter();
      const messageCollection = activeChannel.channel.createMessageCollection({
        filter: messageFilter,
        startingPoint: Date.now(),
        limit: 100,
      });
      messageCollection.setMessageCollectionHandler(args.messageHandlers);
      messageCollection
        .initialize(MessageCollectionInitPolicy.CACHE_AND_REPLACE_BY_API)
        .onCacheResult((error: Error, messages: BaseMessage[]) => {
          if (error) {
            console.log("Sendbird Error", error);
          }
          dispatch(setMessages(messages.reverse()));
          args.scrollToBottom();
        })
        .onApiResult((error: Error, messages: BaseMessage[]) => {
          if (error) {
            console.log("Sendbird Error", error);
          }
          dispatch(setMessages(messages.reverse()));
          args.scrollToBottom();
        });
      dispatch(setMessageCollection(messageCollection));
      return messageCollection;
    }
  }
);

export const fetchPreviousMessages = createAsyncThunk(
  "messenger/fetchPreviousMessages",
  async (args, { getState }) => {
    const state = getState() as RootState;
    const messageCollection = state.messenger.activeChannel.messageCollection;
    const prevMessages = await messageCollection.loadPrevious();
    return prevMessages;
  }
);
