import { gql } from "apollo-boost";

export const CoachTaskFragment = gql`
  fragment CoachTaskFragment on CoachTask {
    id
    assignee {
      id
      email
      firstName
      lastName
    }
    trainer {
      id
      email
      firstName
      lastName
    }
    client {
      id
      firstName
      lastName
      email
      profileIconUrl
      clientSalesPackage {
        id
        clientPackageType
      }
      assignedTrainer {
        id
        firstName
        lastName
      }
    }
    createdDate
    dueDate
    escalatedDate
    resolvedDate
    snoozeUntilDate
    sourceRule
    status
    summary
    taskAutocompletable
    webDeepLinkScreen
    impact
  }
`;

export const COACH_TASKS_GQL = gql`
  ${CoachTaskFragment}
  query CoachTasks($trainerId: UUID, $statusCriteria: [CoachTaskStatusType]) {
    coachTasksByStatus(trainerId: $trainerId, statusCriteria: $statusCriteria) {
      ...CoachTaskFragment
    }
  }
`;

export const COACH_TASKS_FOR_MANAGER_GQL = gql`
  ${CoachTaskFragment}
  query CoachTasksForManager(
    $managerId: UUID
    $statusCriteria: [CoachTaskStatusType]
  ) {
    coachTasksForManagerByStatus(
      managerId: $managerId
      statusCriteria: $statusCriteria
    ) {
      ...CoachTaskFragment
    }
  }
`;

export const COACH_TASKS_FOR_ADMIN_GQL = gql`
  ${CoachTaskFragment}
  query CoachTasksForAdmin($statusCriteria: [CoachTaskStatusType]) {
    coachTasksForAdminByStatus(statusCriteria: $statusCriteria) {
      ...CoachTaskFragment
    }
  }
`;

export const MARK_COACH_TASK_STATUS_GQL = gql`
  ${CoachTaskFragment}
  mutation MarkCoachTaskStatus(
    $trainerId: UUID
    $coachTaskId: UUID
    $status: CoachTaskStatusType
  ) {
    markCoachTaskStatus(
      trainerId: $trainerId
      coachTaskId: $coachTaskId
      status: $status
    ) {
      ...CoachTaskFragment
    }
  }
`;

export const DELETE_COACH_TASK_GQL = gql`
  mutation DeleteCoachTask($trainerId: UUID, $coachTaskId: UUID) {
    deleteCoachTask(trainerId: $trainerId, coachTaskId: $coachTaskId)
  }
`;

export const ADMIN_RUN_RULES_ENGINE_CRON_GQL = gql`
  query AdminRunRulesEngineCron {
    runRulesEngineCron
  }
`;
