import { matchPath, NavLink, useLocation } from "react-router-dom";
import React from "react";
import colors from "../../assets/colors";
import "./index.css";

export const NAV_WIDTH = 240;
export const NAV_COLLAPSED_WIDTH = 80;

interface Props {
  title: string;
  icon?: string;
  to: string;
  paths: string | string[];
  iconOnly?: boolean;
  id: string;
}

interface ActiveStyle {
  border?: string;
  backgroundColor: string;
  color?: string;
}

const ClientNavItem = (props: Props) => {
  const { title, to, icon, paths, iconOnly, id } = props;
  const location = useLocation();

  const linkPath = to.replace(":id", id);

  const match = matchPath(location.pathname, {
    path: paths,
    exact: true,
  });
  const isActive = match && match.isExact;
  const activeStyle: ActiveStyle = {
    backgroundColor: colors.caliber_white,
  };
  if (isActive) {
    activeStyle.border = "0.5px solid #EEEEF1";
    activeStyle.backgroundColor = "#F8F9FA";
    activeStyle.color = colors.caliber_secondary;
  }
  return (
    <NavLink className="d-flex" to={linkPath}>
      <div
        className={`d-flex ${isActive ? "" : "active_nav"}`}
        title={title}
        style={{
          height: 32,
          minWidth: 32,
          padding: 8,
          margin: 8,
          marginLeft: 0,
          borderRadius: 4,
          boxSizing: "border-box",
          fontSize: "14px",
          lineHeight: "24px",
          ...activeStyle,
        }}
      >
        <div
          className={`d-flex align-items-center justify-content-center ${
            isActive ? "medium-bold" : ""
          }`}
        >
          {iconOnly ? <img src={icon} alt="icon" /> : title}
        </div>
      </div>
    </NavLink>
  );
};

export default ClientNavItem;
