import Hls from "hls.js";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useDrag, useDrop } from "react-dnd";
import colors from "../../assets/colors";
import SSBadge from "../../assets/images/StrengthScoreBadgeIcon.svg";
import DefaultWorkoutImage from "../../assets/images/DefaultWorkoutImage.svg";
import CustomExerciseImage from "../../assets/images/CustomExerciseImage.svg";
import SmartWarmupIndicator from "../../assets/images/SmartWarmupIndicator.svg";
import {
  Exercise,
  GymStep,
  MutationUpdateClientGymStepTemplateArgs,
  MutationUpdateMasterGymStepArgs,
  MutationUpdateMasterGymStepForCustomTrainingPlanArgs,
  Superset,
  SupersetTagType,
} from "../../types/gqlTypes";
import ConfirmationDialog from "../ConfirmationDialog";
import EditExercise from "../EditExercise";
import MeatballsMenu, { MeatballItem } from "../MeatballsMenu";
import { StoreState } from "../../redux/reducers";
import { selectCurrentSuperset } from "../../redux/reducers/TrainingPrograms/selectors";
import SupersetIcon from "../../assets/images/SupersetIcon.svg";
import { exerciseThumbnailImageUrl } from "../../utils";

interface OwnProps {}

interface Props {
  exercise: Exercise;
  collapsedThumbnail?: boolean;
  showTarget?: boolean;
  showMenu?: boolean;
  index?: number;
  id?: string;
  gymStep?: GymStep;
  draggable?: boolean;
  transparent?: boolean;
  selectedEditMode?: number;
  showVideoOnHover?: boolean;
  moveCard?: (id?: string, to?: number) => void;
  findCard?: (id?: string) => { index?: number };
  onDelete?: () => void;
  onStartEdit?: () => void;
  onCancelEdit?: () => void;
  onSaveEdit?: (
    args:
      | MutationUpdateClientGymStepTemplateArgs
      | MutationUpdateMasterGymStepArgs
      | MutationUpdateMasterGymStepForCustomTrainingPlanArgs
  ) => void;
  onToggleDragging?: (value: boolean) => void;
  onStartEditSuperset?: (gymStep?: GymStep) => void;
  onConvertGymStepToSuperset?: () => void;
  selectedSuperset?: GymStep;
  showSupersetEditor?: boolean;
  showExerciseLibrary?: boolean;
}
export interface DraggableItem {
  type: string;
  id: string;
  originalIndex: number;
  isSupersetChild: boolean;
  isWorkoutChild: boolean;
  isSuperset: boolean;
}
const SupersetTagColors = {
  [SupersetTagType.SupersetOne]: "#6FA3E9",
  [SupersetTagType.SupersetTwo]: "#F2C94C",
  [SupersetTagType.SupersetThree]: "#F2994A",
  [SupersetTagType.SupersetFour]: "#EB5757",
  [SupersetTagType.SupersetFive]: "#5B64A0",
};
const SupersetTagTexts = {
  [SupersetTagType.SupersetOne]: "S1",
  [SupersetTagType.SupersetTwo]: "S2",
  [SupersetTagType.SupersetThree]: "S3",
  [SupersetTagType.SupersetFour]: "S4",
  [SupersetTagType.SupersetFive]: "S5",
};

const ExerciseItem = (props: Props) => {
  const {
    showTarget,
    showMenu,
    index,
    draggable,
    id,
    gymStep,
    transparent,
    collapsedThumbnail,
    exercise,
    selectedEditMode,
    showVideoOnHover,
    onDelete,
    onSaveEdit,
    onStartEdit,
    onCancelEdit,
    moveCard,
    findCard,
    onToggleDragging,
    onStartEditSuperset,
    onConvertGymStepToSuperset,
    selectedSuperset,
    showSupersetEditor,
    showExerciseLibrary,
  } = props;

  const [localItems, setLocalItems] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const imageDivRef = useRef(null);
  const videoLoopRef = useRef(null);
  const isSuperset: boolean = gymStep && gymStep.exercise == null;
  const isSelectedSuperset: boolean =
    selectedSuperset && gymStep?.id === selectedSuperset?.id;
  const isSupersetChild: boolean =
    gymStep && typeof gymStep.supersetGymStep !== "undefined";
  const isWorkoutChild: boolean = gymStep && !isSupersetChild;

  const name = exercise?.name;
  const media = exercise?.media;

  const overlay = (
    <Popover
      style={{
        zIndex: 3,
        width: 207,
        height: 123,
        padding: 8,
      }}
      id="popover-contained"
    >
      <Popover.Body>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        {media?.videoUrl?.url ? (
          <video
            loop
            muted
            autoPlay
            style={{
              borderRadius: 8,
            }}
            ref={videoLoopRef}
            width={191}
            height={107}
          />
        ) : (
          <div
            className="heading-normal"
            style={{
              width: 191,
              height: 50,
              marginTop: 25,
              textAlign: "center",
            }}
          >
            No video available
          </div>
        )}
      </Popover.Body>
    </Popover>
  );

  useEffect(() => {
    if (showVideo && media?.videoUrl?.url != null) {
      const hls = new Hls();
      hls.loadSource(media.videoUrl.url);
      hls.attachMedia(videoLoopRef.current);
    }
  }, [showVideo]);

  // For supersets, show the children on load
  useEffect(() => {
    if (isSuperset) {
      setLocalItems((gymStep as Superset).gymSteps);
    }
  }, [gymStep]);

  const card: { index?: number } = findCard && findCard(id);
  const originalIndex: number = card ? card.index : 0;

  // Allows this component to be dragged, defines the Item that is being dragged
  const [{ isDragging }, drag] = useDrag({
    item: {
      type: "exercise",
      id,
      originalIndex,
      isSupersetChild,
      isWorkoutChild,
      isSuperset,
    } as DraggableItem,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      const { id: droppedId, originalIndex } =
        monitor.getItem() as DraggableItem;
      const didDrop = monitor.didDrop();
      if (moveCard && !didDrop && draggable) {
        moveCard(droppedId, originalIndex);
      }
    },
  });

  // When hovering into a drop zone
  const [, drop] = useDrop({
    accept: "exercise",
    canDrop: () => false,
    hover(item: DraggableItem, monitor) {
      if (draggable && findCard && item.id !== id) {
        const card = findCard(id);
        if (moveCard && card && card.index > -1 && draggable) {
          moveCard(item.id, card.index);
        }
      }
    },
  });

  useEffect(() => {
    if ((exercise || isSuperset) && onToggleDragging) {
      onToggleDragging?.(isDragging);
    }
  }, [isDragging]);

  const menuItems: MeatballItem[] = isSupersetChild
    ? [
        { text: "Edit", onClick: onStartEdit },
        { text: "Delete", onClick: () => setConfirmDialog(true) },
      ]
    : [
        { text: "Edit", onClick: onStartEdit },
        { text: "Make Superset", onClick: onConvertGymStepToSuperset },
        { text: "Delete", onClick: () => setConfirmDialog(true) },
      ];

  const renderSupersetExerciseNames = (names: string[]) => {
    const maxChars: number =
      showExerciseLibrary && showSupersetEditor ? 75 : 175;
    let runningIndex = names.length;
    let returnString: string = "";

    names.map((name, index) => {
      if (returnString.length < maxChars) {
        returnString = index !== 0 ? `${returnString}, ${name}` : name;
        runningIndex -= 1;
      }
    });

    if (runningIndex !== 0) {
      returnString = `${returnString} (+ ${runningIndex} more)...`;
    }
    return returnString;
  };

  return (
    <div
      style={{
        transform:
          !showVideo &&
          typeof selectedEditMode !== "number" &&
          "translate(0, 0)",
        zIndex: typeof selectedEditMode === "number" ? 1 : 2,
        position: "relative",
      }}
      ref={imageDivRef}
    >
      {confirmDialog && (
        <ConfirmationDialog
          show={confirmDialog}
          cancelButtonText="Cancel"
          text={`Do you really want to delete ${
            isSuperset ? "this superset" : name
          }?`}
          onCancel={() => setConfirmDialog(false)}
          onConfirm={onDelete}
        />
      )}
      <div
        ref={(node) =>
          draggable && typeof selectedEditMode !== "number"
            ? drag(drop(node))
            : null
        }
        className="d-flex flex-nowrap nofocus"
        style={{
          flex: 1,
          cursor: draggable && "move",
          position: "relative",
          borderRadius: 8,
          minHeight: 80,
          minWidth: 292,
          backgroundColor: colors.caliber_gray_5,
          // Allows pre-dropped empty gymStep to be rendered in moveItem()
          border: isSuperset || exercise ? "none" : "5px solid gray",
          margin: "4px 0px",
          opacity: transparent || isDragging || isSelectedSuperset ? 0.35 : 1,
          paddingLeft: collapsedThumbnail ? 12 : 4,
          paddingRight: exercise ? 0 : 4,
        }}
      >
        {showVideoOnHover && exercise ? (
          <OverlayTrigger
            onEntering={() => setShowVideo(true)}
            onExiting={() => setShowVideo(false)}
            placement="bottom"
            trigger="hover"
            delay={1000}
            flip
            overlay={overlay}
          >
            <div
              style={{
                margin: 8,
                width: 64,
                height: 64,
                borderRadius: 8,
                position: "relative",
              }}
            >
              <img
                style={{
                  width: 64,
                  height: 64,
                  borderRadius: 8,
                  position: "relative",
                }}
                src={exerciseThumbnailImageUrl(exercise)}
                alt=""
              />
            </div>
          </OverlayTrigger>
        ) : (
          exercise && (
            <div
              style={{
                margin: 8,
                width: 64,
                height: 64,
                borderRadius: 8,
                position: "relative",
              }}
            >
              <img
                style={{
                  width: 64,
                  height: 64,
                  borderRadius: 8,
                  position: "relative",
                }}
                src={exerciseThumbnailImageUrl(exercise)}
                alt=""
              />
            </div>
          )
        )}
        {exercise && (
          <div
            className="d-flex flex-column"
            style={{
              flex: "1 1 0",
            }}
          >
            <div
              className="d-flex justify-content-between align-items-center"
              style={{
                flex: "1 1 0",
              }}
            >
              <div
                className="medium-bold"
                style={{
                  flex: 1,
                  width: 100,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  margin: 0,
                  fontSize: "16px",
                  lineHeight: "24px",
                  paddingTop: showTarget && gymStep?.target ? 8 : 0,
                }}
              >
                {name}
              </div>
            </div>

            <div className="d-flex justify-content-between">
              <div className="d-flex">
                {showTarget && gymStep?.target && (
                  <div
                    className="d-flex align-items-center medium-bold"
                    style={{
                      height: 24,
                      padding: "2px 8px",
                      marginBottom: 12,
                      borderRadius: 8,
                      backgroundColor: colors.caliber_white,
                      maxWidth: "200px",
                      overflow: "hidden",
                      fontSize: "14px",
                      lineHeight: "16px",
                    }}
                  >
                    {gymStep?.target &&
                      `${gymStep?.target?.slice(0, 25)}${
                        gymStep?.target?.length > 25 ? "..." : ""
                      }`}
                  </div>
                )}
                {gymStep?.generateSmartWarmupSets && (
                  <div
                    className="d-flex flex-column justify-content-center align-items-center"
                    style={{
                      marginBottom: 12,
                      marginLeft: 8,
                    }}
                  >
                    <img src={SmartWarmupIndicator} />
                  </div>
                )}
                {showTarget && gymStep?.supersetTag && (
                  <div
                    className="d-flex flex-column justify-content-center align-items-center medium-bold"
                    style={{
                      width: 33,
                      height: 24,
                      marginBottom: 12,
                      marginLeft: 8,
                      borderRadius: 8,
                      color: colors.caliber_white,
                      backgroundColor: SupersetTagColors[gymStep?.supersetTag],
                      lineHeight: "16px",
                      fontSize: "14px",
                    }}
                  >
                    {SupersetTagTexts?.[gymStep?.supersetTag]}
                  </div>
                )}
              </div>
              {exercise?.isStrengthScoreKeyExercise && (
                <div
                  style={{
                    position: "absolute",
                    height: 24,
                    bottom: 12,
                    right: 22,
                  }}
                >
                  <img src={SSBadge} />
                </div>
              )}
              {showMenu && (
                <div
                  style={{
                    position: "absolute",
                    height: 24,
                    top: 14,
                    right: 6,
                  }}
                >
                  <MeatballsMenu items={menuItems} />
                </div>
              )}
            </div>
          </div>
        )}
        {isSuperset && (
          <div
            style={{
              width: 64,
              height: 64,
              margin: 8,
              borderRadius: 8,
            }}
          >
            <img
              style={{
                width: 64,
                height: 64,
                borderRadius: 8,
              }}
              src={SupersetIcon}
              alt="Thumbnail"
            />
          </div>
        )}
        {isSuperset && (
          <div
            className="d-flex flex-column"
            style={{
              flex: "1 1 0",
              cursor: "pointer",
            }}
            role="button"
            tabIndex={0}
            onClick={() => onStartEditSuperset(gymStep)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                onStartEditSuperset(gymStep);
              }
            }}
          >
            {showMenu && !isSelectedSuperset && (
              <div
                style={{
                  position: "absolute",
                  height: 24,
                  top: 14,
                  right: 6,
                }}
              >
                <MeatballsMenu
                  items={[
                    {
                      text: "Edit",
                      onClick: () => onStartEditSuperset(gymStep),
                    },
                    { text: "Delete", onClick: () => setConfirmDialog(true) },
                  ]}
                />
              </div>
            )}

            <div
              className="d-flex justify-content-between align-items-center"
              style={{
                flex: "1 1 0",
              }}
            >
              <div
                className="medium-bold"
                style={{
                  flex: 1,
                  width: 100,
                  overflow: "hidden",
                  margin: "0",
                  marginRight: "32px",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                {localItems.length === 1 ? "⚠️" : ""} Superset of{" "}
                {gymStep.targetSets} sets of{" "}
                {renderSupersetExerciseNames(
                  localItems?.map((childGymStep) => {
                    return childGymStep.exercise.name;
                  })
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {!isDragging && selectedEditMode === index && (
        <EditExercise
          id={id}
          gymStep={gymStep}
          onSave={onSaveEdit}
          onCancelEdit={onCancelEdit}
          isSupersetChild={isSupersetChild}
        />
      )}
    </div>
  );
};
const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  selectedSuperset: selectCurrentSuperset(state),
  showSupersetEditor: state.trainingProgramsState.showSupersetEditor,
  showExerciseLibrary: state.trainingProgramsState.showExerciseLibrary,
});

export default connect(mapStateToProps)(ExerciseItem);
