import apolloClient from "../../../api/apolloClient";
import {
  COPY_CLIENT_WORKOUT_GROUP_TEMPLATE,
  COPY_CLIENT_WORKOUT_TEMPLATE,
  COPY_TRAINING_PLAN_TO_CLIENTS,
} from "../../../api/gql/trainingPrograms/clientTrainingPlan";
import { COPY_TRAINING_PLAN_TO_TRAINER } from "../../../api/gql/trainingPrograms/customTrainingPlan";
import {
  ClientTrainingPlan,
  CustomTrainingPlan,
  MutationCopyClientWorkoutGroupTemplateArgs,
  MutationCopyClientWorkoutTemplateArgs,
  MutationCopyTrainingPlanToClientsArgs,
  MutationCopyTrainingPlanToTrainerArgs,
} from "../../../types/gqlTypes";
import {
  COPY_TRAINING_PLAN_TO_CLIENTS_FAIL,
  COPY_TRAINING_PLAN_TO_CLIENTS_SAVING,
  COPY_TRAINING_PLAN_TO_CLIENTS_SUCCESS,
  COPY_TRAINING_PLAN_TO_TRAINER_FAIL,
  COPY_TRAINING_PLAN_TO_TRAINER_SAVING,
  COPY_TRAINING_PLAN_TO_TRAINER_SUCCESS,
  TOGGLE_COPY_TRAINING_PLAN_TO_CLIENTS,
  TOGGLE_COPY_TRAINING_PLAN_TO_TRAINER,
  TOGGLE_CREATE_CUSTOM_TRAINING_PLAN,
  VIEW_NEWLY_CREATED_CUSTOM_PLAN,
  COPY_CLIENT_WORKOUT_TEMPLATE_FAIL,
  COPY_CLIENT_WORKOUT_TEMPLATE_SAVING,
  COPY_CLIENT_WORKOUT_TEMPLATE_SUCCESS,
  TrainingProgramsAction,
} from "./types";

export const copyTrainingPlanToClients =
  (args: MutationCopyTrainingPlanToClientsArgs) =>
  async (dispatch, getState) => {
    dispatch({
      type: COPY_TRAINING_PLAN_TO_CLIENTS_SAVING,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      await client.mutate({
        mutation: COPY_TRAINING_PLAN_TO_CLIENTS,
        variables: args,
      });

      dispatch({ type: COPY_TRAINING_PLAN_TO_CLIENTS_SUCCESS });
    } catch (error) {
      console.error("Error copyTrainingPlanToClients:", error);
      dispatch({
        type: COPY_TRAINING_PLAN_TO_CLIENTS_FAIL,
        data: error,
      });
    }
  };

export const copyTrainingPlanToTrainer =
  (args: MutationCopyTrainingPlanToTrainerArgs) =>
  async (dispatch, getState) => {
    dispatch({
      type: COPY_TRAINING_PLAN_TO_TRAINER_SAVING,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const plan = (
        await client.mutate({
          mutation: COPY_TRAINING_PLAN_TO_TRAINER,
          variables: args,
        })
      ).data.copyTrainingPlanToTrainer as CustomTrainingPlan;

      dispatch({ type: COPY_TRAINING_PLAN_TO_TRAINER_SUCCESS, plan });
    } catch (error) {
      console.error("Error copyTrainingPlanToTrainer:", error);
      dispatch({
        type: COPY_TRAINING_PLAN_TO_TRAINER_FAIL,
        data: error,
      });
    }
  };

export const viewNewlyCreatedCustomPlan = () => async (dispatch, getState) => {
  dispatch({
    type: VIEW_NEWLY_CREATED_CUSTOM_PLAN,
  });
};

export const toggleCopyTrainingPlanToClients =
  (index?: number) => async (dispatch) => {
    dispatch({ type: TOGGLE_COPY_TRAINING_PLAN_TO_CLIENTS, index });
  };

export const toggleCopyTrainingPlanToTrainer =
  (index?: number) => async (dispatch) => {
    dispatch({ type: TOGGLE_COPY_TRAINING_PLAN_TO_TRAINER, index });
  };

export const toggleCreateCustomPlan = () => async (dispatch) => {
  dispatch({ type: TOGGLE_CREATE_CUSTOM_TRAINING_PLAN });
};

export const copyClientWorkoutTemplate =
  (args: MutationCopyClientWorkoutTemplateArgs) =>
  async (dispatch, getState) => {
    dispatch({
      type: COPY_CLIENT_WORKOUT_TEMPLATE_SAVING,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const result = (
        await client.mutate({
          mutation: COPY_CLIENT_WORKOUT_TEMPLATE,
          variables: args,
        })
      ).data.copyClientWorkoutTemplate as ClientTrainingPlan;

      dispatch({
        type: COPY_CLIENT_WORKOUT_TEMPLATE_SUCCESS,
        plan: result,
      } as TrainingProgramsAction);
    } catch (error) {
      console.error("Error copyClientWorkoutTemplate:", error);
      dispatch({
        type: COPY_CLIENT_WORKOUT_TEMPLATE_FAIL,
        data: error,
      });
    }
  };

export const copyClientWorkoutGroupTemplate =
  (args: MutationCopyClientWorkoutGroupTemplateArgs) =>
  async (dispatch, getState) => {
    dispatch({
      type: COPY_CLIENT_WORKOUT_TEMPLATE_SAVING,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const result = (
        await client.mutate({
          mutation: COPY_CLIENT_WORKOUT_GROUP_TEMPLATE,
          variables: args,
        })
      ).data.copyClientWorkoutGroupTemplate as ClientTrainingPlan;

      dispatch({
        type: COPY_CLIENT_WORKOUT_TEMPLATE_SUCCESS,
        plan: result,
      } as TrainingProgramsAction);
    } catch (error) {
      console.error("Error copyClientWorkoutTemplate:", error);
      dispatch({
        type: COPY_CLIENT_WORKOUT_TEMPLATE_FAIL,
        data: error,
      });
    }
  };
