import {
  CREATE_UPDATE_CLIENT_HABIT_TARGET_SUCCESS,
  CREATE_UPDATE_CLIENT_HABIT_TARGET_SAVING,
  CREATE_UPDATE_CLIENT_HABIT_TARGET_FAIL,
  GET_CLIENT_HABIT_TARGET_SUCCESS,
  GET_CLIENT_HABIT_TARGET_LOADING,
  GET_CLIENT_HABIT_TARGET_FAIL,
  DELETE_CLIENT_HABIT_TARGET_FAIL,
  DELETE_CLIENT_HABIT_TARGET_SUCCESS,
  DELETE_CLIENT_HABIT_TARGET_LOADING,
  ClientHabitsAction,
  ClientHabitsState,
  RESET_CLIENT_HABIT_TARGET_STATE,
} from "../../actions/Habits/types";

const initialState: ClientHabitsState = {
  isSaving: false,
  isLoading: false,
  habits: null,
};

const clientHabitsState = (
  state = initialState,
  action: ClientHabitsAction
) => {
  switch (action.type) {
    case RESET_CLIENT_HABIT_TARGET_STATE:
      return {
        ...initialState,
      };
    case CREATE_UPDATE_CLIENT_HABIT_TARGET_SUCCESS:
      return {
        ...state,
        isSaving: true,
        habits: action.data,
      };
    case DELETE_CLIENT_HABIT_TARGET_LOADING:
    case GET_CLIENT_HABIT_TARGET_SUCCESS:
      return {
        ...state,
        isLoading: true,
        habits: action.data,
      };
    case DELETE_CLIENT_HABIT_TARGET_SUCCESS:
      return {
        ...state,
        habits: state.habits.filter(
          (habit) => habit?.id !== action.habitTargetId
        ),
        isLoading: false,
      };
    case CREATE_UPDATE_CLIENT_HABIT_TARGET_SAVING:
      return {
        ...state,
        isSaving: true,
      };
    case GET_CLIENT_HABIT_TARGET_LOADING:
      return {
        ...state,
        habits: action.data,
        isLoading: false,
      };
    case CREATE_UPDATE_CLIENT_HABIT_TARGET_FAIL:
    case GET_CLIENT_HABIT_TARGET_FAIL:
    case DELETE_CLIENT_HABIT_TARGET_FAIL:
      return {
        ...state,
        isSaving: false,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default clientHabitsState;
