import React from "react";
import TagItem from "./TagItem";
import colors from "../../assets/colors";

interface Props {
  isFilter?: boolean;
  viewExerciseMode?: boolean;
  title: string;
  tags: string[];
  selectedTags: string[];
  onTagSelected?: (tag: string) => void;
}

const ExerciseLibraryTagSection = (props: Props) => {
  const {
    isFilter,
    viewExerciseMode,
    title,
    tags,
    selectedTags,
    onTagSelected,
  } = props;

  return (
    <div
      className="d-flex flex-column"
      style={{
        flex: 1,
      }}
    >
      <div
        className={`${(!isFilter || viewExerciseMode) && "medium-bold"}`}
        style={{
          marginBottom: isFilter ? 8 : 16,
          fontSize: isFilter ? "12px" : "14px",
          lineHeight: "32px",
          color: isFilter
            ? colors.caliber_gray_border
            : colors.caliber_secondary,
        }}
      >
        {title}
      </div>
      <div className="d-flex flex-wrap">
        {tags.map((tag) => {
          if (onTagSelected) {
            return (
              <TagItem
                key={tag}
                viewExerciseMode={viewExerciseMode}
                isHighlighted={selectedTags.includes(tag)}
                label={tag}
                onClick={() => {
                  onTagSelected(tag);
                }}
              />
            );
          }
          return (
            <TagItem
              key={tag}
              viewExerciseMode={viewExerciseMode}
              isHighlighted={selectedTags.includes(tag)}
              label={tag}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ExerciseLibraryTagSection;
