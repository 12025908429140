import React from "react";
import colors from "../../assets/colors";
import SimpleButton from "../SimpleButton";

interface Props {
  disabled?: boolean;
  onClick: () => void;
}

const AddNewHabitButton = (props: Props) => {
  const { disabled, onClick } = props;

  return (
    <SimpleButton
      disabled={disabled}
      className="d-flex justify-content-center align-items-center medium-bold"
      style={{
        fontSize: "14px",
        lineHeight: "17px",
        letterSpacing: "-0.-22em",
        color: colors.caliber_white,
        backgroundColor: colors.caliber_green_200,
        width: 146,
        height: 36,
        borderRadius: 8,
      }}
      onClick={onClick}
    >
      Add new habit
    </SimpleButton>
  );
};

export default AddNewHabitButton;
