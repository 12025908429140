import { eachDayOfInterval, format, subDays } from "date-fns";
import React from "react";
import {
  CalendarDay,
  ImportedNutritionCalendarItem,
  MacronutrientType,
} from "../../../types/gqlTypes";

interface Props {
  target?: number;
  weeklyNumber?: number;
  nutrient: MacronutrientType;
  data: CalendarDay[];
}

const WeeklyNutritionTable = (props: Props) => {
  const { target, weeklyNumber, nutrient, data } = props;

  const endDate = subDays(new Date(), 1);
  const startDate = subDays(endDate, 7);

  const table = [];

  eachDayOfInterval({ start: startDate, end: endDate }).forEach(
    (date, index) => {
      table.push({
        date,
        value: 0,
        target,
      });
    }
  );

  data?.forEach((day) => {
    day?.items?.forEach((item: ImportedNutritionCalendarItem) => {
      const dayValue = item?.macronutrientEvents?.find(
        (event) => event.macronutrient === nutrient
      ).value;
      const rowIndex = table.findIndex(
        (row) => format(row.date, "yyyy-MM-dd") === day.date
      );
      if (rowIndex > -1) {
        table[rowIndex].value += dayValue;
      }
    });
  });
  table.unshift({
    date: null,
    value: weeklyNumber,
    target,
  });

  const titles = [
    "Date",
    `${nutrient?.toLowerCase()} (7-day)`,
    `${nutrient?.toLowerCase()} (Target)`,
    `${nutrient?.toLowerCase()} (%)`,
  ];

  const colors = {
    [MacronutrientType.Calories]: "#CCCCCC",
    [MacronutrientType.Fat]: "#E9CECC",
    [MacronutrientType.Protein]: "#D5E2F0",
    [MacronutrientType.Carbs]: "#DEE8D6",
  };
  return (
    <div>
      <table style={{ borderCollapse: "collapse" }}>
        <thead>
          <tr
            style={{
              border: "1px solid #ddd",
            }}
          >
            {titles.map((text) => {
              return (
                <td
                  style={{
                    border: "1px solid #ddd",
                    backgroundColor: colors?.[nutrient],
                  }}
                >
                  <div
                    style={{
                      padding: "3px 15px",
                      minWidth: 150,
                    }}
                  >
                    {text}
                  </div>
                </td>
              );
            })}
          </tr>
        </thead>

        <tbody>
          {table.map(({ value, date, target }, index) => {
            return (
              <tr
                style={{
                  border: "1px solid #ddd",
                  backgroundColor: value === 0 && index > 0 && "gray",
                  height: index === 0 ? 50 : 25,
                }}
              >
                <td
                  style={{
                    border: "1px solid #ddd",
                    padding: "3px 5px",
                    textAlign: "center",
                  }}
                >
                  {date && format(date, "MM/dd/yyyy")}
                </td>
                <td
                  style={{
                    border: "1px solid #ddd",
                    padding: "3px 5px",
                    textAlign: "center",
                  }}
                >
                  {Math.round(value)}
                </td>
                <td
                  style={{
                    border: "1px solid #ddd",
                    padding: "3px 5px",
                    textAlign: "center",
                  }}
                >
                  {target}
                </td>
                <td
                  style={{
                    border: "1px solid #ddd",
                    padding: "3px 5px",
                    textAlign: "center",
                  }}
                >
                  {!value ? "100" : Math.round((value / (target || 1)) * 100)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default WeeklyNutritionTable;
