import React from "react";
import colors from "../../../assets/colors";

interface Props {
  value: number;
  unit: string;
}

const WeekDeltaBar = (props: Props) => {
  const { value, unit } = props;

  const isNegative = value < 0;
  const bgWidth = 159;
  const bgHeight = 120;
  const pieceWidth = 64;
  const pieceHeight = 43;

  const coordinates = {
    top: isNegative && 61,
    bottom: !isNegative && 60,
    left: (bgWidth - pieceWidth) / 2,
  };

  return (
    <div
      style={{
        backgroundColor: colors.caliber_secondary_gray_5,
        height: bgHeight,
        width: bgWidth,
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          backgroundColor: colors.caliber_secondary_gray_11,
          height: 1,
          width: 159,
          top: 60,
          left: 0,
        }}
      >
        {/* line */}
      </div>
      <div
        className="d-flex justify-content-center align-items-center bold"
        style={{
          backgroundColor: isNegative ? colors.caliber_primary : "#27AE60",
          color: colors.caliber_white,
          fontSize: "12px",
          lineHeight: "14px",

          width: pieceWidth,
          height: pieceHeight,

          position: "absolute",
          ...coordinates,

          borderTopLeftRadius: isNegative ? 1 : 4,
          borderTopRightRadius: isNegative ? 1 : 4,
          borderBottomRightRadius: isNegative ? 4 : 1,
          borderBottomLeftRadius: isNegative ? 4 : 1,
        }}
      >
        {`${isNegative ? "-" : "+"} ${Math.abs(value)} ${unit}`}
      </div>
    </div>
  );
};

export default WeekDeltaBar;
