import {
  AdminCreateClientAction,
  AdminCreateClientState,
  CREATE_CLIENT_FAIL,
  CREATE_CLIENT_LOADING,
  CREATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAIL,
  UPDATE_CLIENT_LOADING,
  UPDATE_CLIENT_SUCCESS,
} from "../../actions/AdminClients/types";

const initialState: AdminCreateClientState = {
  isLoading: false,
};

const adminCreateClientState = (
  state = initialState,
  action: AdminCreateClientAction
) => {
  switch (action.type) {
    case CREATE_CLIENT_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case CREATE_CLIENT_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_CLIENT_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_CLIENT_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default adminCreateClientState;
