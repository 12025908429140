import React, { useMemo } from "react";
import {
  GenderType,
  StrengthScore,
  StrengthScoreMuscleGroupType,
} from "../../types/gqlTypes";
import BackFemaleSkeleton from "./BackFemaleSkeleton";
import BackMaleSkeleton from "./BackMaleSkeleton";
import FrontFemaleSkeleton from "./FrontFemaleSkeleton";
import FrontMaleSkeleton from "./FrontMaleSkeleton";

interface Props {
  muscleGroups?: StrengthScore[];
  muscleColor?: { muscle: StrengthScoreMuscleGroupType; color: string }[];
  gender: GenderType;
  view: "front" | "back";
  height?: number;
  width?: number;
}

const Skeleton = (props: Props) => {
  const { muscleGroups, muscleColor, gender, view, height, width } = props;

  const defaultColor = "#EDEDED";
  const isFemale = gender === GenderType.Female;

  const FrontSkeleton = isFemale ? FrontFemaleSkeleton : FrontMaleSkeleton;
  const BackSkeleton = isFemale ? BackFemaleSkeleton : BackMaleSkeleton;

  const skeletonSize = useMemo(() => {
    const size = { height: 431, width: 218 };
    if (height) {
      size.width = Math.round((size.width * height) / size.height);
      size.height = height;
    } else if (width) {
      size.height = Math.round((size.height * width) / size.width);
      size.width = width;
    }
    return size;
  }, [height, width]);

  const muscleStandardDeviationContribution = {
    [StrengthScoreMuscleGroupType.Arms]: 0.35,
    [StrengthScoreMuscleGroupType.Shoulders]: 0.15,
    [StrengthScoreMuscleGroupType.Back]: 0.25,
    [StrengthScoreMuscleGroupType.Chest]: 0.1,
    [StrengthScoreMuscleGroupType.Legs]: 0.15,
  };
  const getColor = (
    muscleGroup: StrengthScore,
    overallStrengthScore: StrengthScore
  ) => {
    const SD =
      (muscleGroup?.score *
        muscleStandardDeviationContribution[muscleGroup?.type] || 0) /
      (overallStrengthScore?.score || 1);
    if (SD > 1 && muscleGroup?.score > overallStrengthScore?.score) {
      return "#272E5F";
    }
    if (SD > 0 && SD <= 1 && muscleGroup?.score > overallStrengthScore?.score) {
      return "#52587F";
    }
    if (SD > 0 && SD <= 1 && muscleGroup?.score < overallStrengthScore?.score) {
      return "#A9ABBF";
    }
    if (SD > 1 && muscleGroup?.score < overallStrengthScore?.score) {
      return "#D4D5DF";
    }
    if (SD === 0) {
      return "#52587F";
    }
    return "#EDEDED";
  };

  const muscleColors = useMemo(() => {
    const muscleColors = {
      [StrengthScoreMuscleGroupType.Arms]: defaultColor,
      [StrengthScoreMuscleGroupType.Shoulders]: defaultColor,
      [StrengthScoreMuscleGroupType.Back]: defaultColor,
      [StrengthScoreMuscleGroupType.Chest]: defaultColor,
      [StrengthScoreMuscleGroupType.Legs]: defaultColor,
    };
    const overallStrengthScore = muscleGroups?.find(
      ({ type }) => type === StrengthScoreMuscleGroupType.Overall
    );
    muscleGroups?.forEach((muscleGroup: StrengthScore) => {
      const { type } = muscleGroup || {};
      if (type in muscleColors) {
        muscleColors[type] = getColor(muscleGroup, overallStrengthScore);
      }
    });
    muscleColor?.forEach(({ muscle, color }) => {
      muscleColors[muscle] = color;
    });
    return muscleColors;
  }, [muscleGroups, muscleColor]);

  return (
    <div>
      {view === "front" ? (
        <FrontSkeleton
          skeletonSize={skeletonSize}
          defaultColor={defaultColor}
          legColor={muscleColors[StrengthScoreMuscleGroupType.Legs]}
          chestColor={muscleColors[StrengthScoreMuscleGroupType.Chest]}
          armColor={muscleColors[StrengthScoreMuscleGroupType.Arms]}
          shoulderColor={muscleColors[StrengthScoreMuscleGroupType.Shoulders]}
        />
      ) : (
        <BackSkeleton
          skeletonSize={skeletonSize}
          defaultColor={defaultColor}
          legColor={muscleColors[StrengthScoreMuscleGroupType.Legs]}
          backColor={muscleColors[StrengthScoreMuscleGroupType.Back]}
          armColor={muscleColors[StrengthScoreMuscleGroupType.Arms]}
          shoulderColor={muscleColors[StrengthScoreMuscleGroupType.Shoulders]}
        />
      )}
    </div>
  );
};

export default Skeleton;
