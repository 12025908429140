import React, { useState } from "react";
import { ResizableBox } from "react-resizable";
import colors from "../../../assets/colors";
import { SortOrder } from "../../../types/gqlTypes";
import ResizeIcon from "../../Icons/ResizeIcon";
import SortButton from "../../Icons/SortButton";
import SimpleButton from "../../SimpleButton";

interface Props {
  width: number;
  isSorted?: boolean;
  sortOrder: SortOrder;
  onSort?: (sortOrder: SortOrder) => void;
  onResize?: (newWidth: number) => void;
  onResizeEnd: () => void;
}

const HeaderCorner = (props: Props) => {
  const { width, isSorted, sortOrder, onSort, onResize, onResizeEnd } = props;
  const [hovered, setHovered] = useState(false);
  const borderColor = colors.table_gray;

  const handleDiv = (
    <div
      role="button"
      tabIndex={0}
      onClick={(e) => e.preventDefault()}
      className="nofocus"
      onKeyDown={() => {}}
      style={{
        position: "absolute",
        top: 0,
        right: -2,
        width: 12,
        height: 56,
        cursor: "ew-resize",
        zIndex: 3,
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {hovered && <ResizeIcon />}
    </div>
  );

  return (
    <th
      style={{
        position: "sticky",
        top: 0,
        left: 0,
        zIndex: 6,
        width,
        height: 64,
        whiteSpace: "nowrap",
        overflow: "hidden",
        backgroundColor: "#F2F3F5",
      }}
    >
      <ResizableBox
        axis="x"
        width={width}
        height={56}
        handle={handleDiv}
        handleSize={[18, 18]}
        onResize={(mouseEvent, data) => onResize(data?.size?.width)}
        onResizeStop={onResizeEnd}
      >
        <div
          className="d-flex align-items-center"
          style={{
            width,
            height: 56,
            borderBottomLeftRadius: 8,
            borderTopLeftRadius: 8,
            borderRight: `1px solid ${borderColor}`,
            backgroundColor: colors.caliber_white,
            whiteSpace: "nowrap",
            overflow: "hidden",
            marginBottom: 8,
          }}
        >
          <SimpleButton
            onClick={() => {
              if (isSorted) {
                onSort(SortOrder.Desc);
              } else {
                onSort(SortOrder.Asc);
              }
            }}
            nofocus
            className="d-flex align-items-center justify-content-between medium-bold"
            styleOnHover={{
              backgroundColor: "#E8EAEE",
            }}
            style={{
              flex: 1,
              height: 28,
              color: colors.caliber_secondary,
              fontSize: "14px",
              lineHeight: "20px",
              border: "1px solid #F2F2F2",
              borderRadius: 8,
              paddingLeft: 8,
              paddingRight: 8,
              marginLeft: 10,
              marginRight: 10,
            }}
          >
            <div
              style={{
                marginRight: 10,
              }}
            >
              Client name
            </div>

            <SortButton
              isDefault={!isSorted}
              isAsc={sortOrder === SortOrder.Asc}
              onAscClick={() => onSort(SortOrder.Asc)}
              onDescClick={() => onSort(SortOrder.Desc)}
            />
          </SimpleButton>
        </div>
      </ResizableBox>
    </th>
  );
};

export default HeaderCorner;
