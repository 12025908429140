import { Trainer, TrainerSettings } from "../../../types/gqlTypes";

export const MASQUERADE_ENABLED = "MASQUERADE_ENABLED";
export const MASQUERADE_DISABLED = "MASQUERADE_DISABLED";
export const MASQUERADE_USER_TIMEZONE_SAVE_SUCCESS =
  "MASQUERADE_USER_TIMEZONE_SAVE_SUCCESS";
export const MASQUERADE_SAVE_AUTO_MESSAGES_SUCCESS =
  "MASQUERADE_SAVE_AUTO_MESSAGES_SUCCESS";

export interface MasqueradeAction {
  type: string;
  trainer?: Trainer;
  trainerSettings?: TrainerSettings;
}

export interface MasqueradeState {
  masqueradeTrainer?: Trainer;
}
