import { Exercise, ExerciseInput } from "../../../types/gqlTypes";
import apolloClient from "../../../api/apolloClient";
import {
  CREATE_CALIBER_EXERCISE_GQL,
  DELETE_EXERCISE,
  UPDATE_EXERCISE_GQL,
} from "../../../api/gql/exerciseLibrary";
import {
  ADMIN_EXERCISE_DELETE,
  ADMIN_EXERCISE_DELETE_FAIL,
  ADMIN_EXERCISE_DELETE_SUCCESS,
  ADMIN_EXERCISE_LIB_SAVE_FAIL,
  ADMIN_EXERCISE_LIB_SAVE_SUCCESS,
  ADMIN_EXERCISE_LIB_SAVING,
  RESET_ADMIN_EXERCISE_LIBRARY_STATE,
} from "./types";

export const saveExercise =
  (exerciseInput: ExerciseInput) => async (dispatch, getState) => {
    if (getState().adminExerciseLibraryState.isSaving) {
      return;
    }
    dispatch({
      type: ADMIN_EXERCISE_LIB_SAVING,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const variables = {
        exercise: exerciseInput,
      };
      let result: Exercise;
      if (exerciseInput.id) {
        result = (
          await client.mutate({
            mutation: UPDATE_EXERCISE_GQL,
            variables,
          })
        ).data.updateExercise as Exercise;
      } else {
        result = (
          await client.mutate({
            mutation: CREATE_CALIBER_EXERCISE_GQL,
            variables,
          })
        ).data.createCaliberExercise as Exercise;
      }

      dispatch({
        type: ADMIN_EXERCISE_LIB_SAVE_SUCCESS,
        savedExercise: result,
      });
    } catch (error) {
      console.error("Save Exercise Failed", error);
      dispatch({
        type: ADMIN_EXERCISE_LIB_SAVE_FAIL,
      });
    }
  };

export const deleteExercise =
  (exerciseId: string, force?: boolean) => async (dispatch, getState) => {
    if (getState().adminExerciseLibraryState.isLoading) {
      return;
    }
    dispatch({
      type: ADMIN_EXERCISE_DELETE,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const variables = { exerciseId, force: true };
      if (!force) {
        delete variables.force;
      }
      await client.mutate({
        mutation: DELETE_EXERCISE,
        variables,
      });

      dispatch({
        type: ADMIN_EXERCISE_DELETE_SUCCESS,
        deletedExerciseId: exerciseId,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_EXERCISE_DELETE_FAIL,
        data: error,
      });
    }
  };

export const resetAdminExerciseLibraryState =
  () => async (dispatch, getState) => {
    dispatch({
      type: RESET_ADMIN_EXERCISE_LIBRARY_STATE,
    });
  };
