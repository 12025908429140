import React from "react";

interface Props {}

const ResizeIcon = (props: Props) => {
  const {} = props;

  return (
    <svg
      style={{ display: "block", zIndex: 9 }}
      width="12"
      height="56"
      viewBox="0 0 12 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="6"
        height="56"
        transform="translate(3)"
        fill="#3446FB"
        fillOpacity="0.08"
      />
      <rect width="16" height="56" fill="#3547FC" fillOpacity="0.05" />
      <rect x="5" width="2" height="56" fill="#3547FC" fillOpacity="0.5" />
      <rect
        x="2.25"
        y="10.25"
        width="7.5"
        height="35.5"
        rx="0.75"
        fill="url(#paint0_linear)"
        stroke="#8C95F7"
        strokeWidth="0.5"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="2"
          y1="9.5"
          x2="16.3443"
          y2="42.2869"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEFAFA" />
          <stop offset="0.53704" stopColor="white" />
          <stop offset="1" stopColor="#F3F3F3" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ResizeIcon;
