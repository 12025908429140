import {
  CalendarDay,
  CalendarItem,
  QueryCalendarDateRangeArgs,
} from "../../../types/gqlTypes";

export const CALENDAR_FETCH_DATA_SUCCESS = "CALENDAR_FETCH_DATA_SUCCESS";
export const CALENDAR_FETCH_NUTRITION_DATA_SUCCESS =
  "CALENDAR_FETCH_NUTRITION_DATA_SUCCESS";
export const CALENDAR_FETCH_DATA_FAILED = "CALENDAR_FETCH_DATA_FAILED";
export const CALENDAR_DATA_LOADING = "CALENDAR_DATA_LOADING";
export const CREATE_CALENDAR_ITEM_SAVING = "CREATE_CALENDAR_ITEM_SAVING";
export const CREATE_CALENDAR_ITEM = "CREATE_CALENDAR_ITEM";

export const CALENDAR_FETCH_ITEM_SUCCESS = "CALENDAR_FETCH_ITEM_SUCCESS";
export const CALENDAR_FETCH_ITEM_FAILED = "CALENDAR_FETCH_ITEM_FAILED";
export const CLOSE_CALENDAR_FETCH_ITEM_FAILED_DIALOG =
  "CLOSE_CALENDAR_FETCH_ITEM_FAILED_DIALOG";
export const CALENDAR_FETCH_ITEM_LOADING = "CALENDAR_FETCH_ITEM_LOADING";

export const DELETE_CALENDAR_ITEM_SUCCESS = "DELETE_CALENDAR_ITEM_SUCCESS";
export const DELETE_CALENDAR_ITEM_FAILED = "DELETE_CALENDAR_ITEM_FAILED";
export const DELETE_CALENDAR_ITEM_SAVING = "DELETE_CALENDAR_ITEM_SAVING";

export const DELETE_RECURRING_EVENT_SUCCESS = "DELETE_RECURRING_EVENT_SUCCESS";
export const DELETE_RECURRING_EVENT_FAILED = "DELETE_RECURRING_EVENT_FAILED";
export const DELETE_RECURRING_EVENT_SAVING = "DELETE_RECURRING_EVENT_SAVING";

export const DELETE_RECURRING_EVENT_DEFINITION_SUCCESS =
  "DELETE_RECURRING_EVENT_DEFINITION_SUCCESS";
export const DELETE_RECURRING_EVENT_DEFINITION_FAILED =
  "DELETE_RECURRING_EVENT_DEFINITION_FAILED";
export const DELETE_RECURRING_EVENT_DEFINITION_SAVING =
  "DELETE_RECURRING_EVENT_DEFINITION_SAVING";

export const MOVE_CALENDAR_ITEM_SUCCESS = "MOVE_CALENDAR_ITEM_SUCCESS";
export const MOVE_CALENDAR_ITEM_SAVING = "MOVE_CALENDAR_ITEM_SAVING";

export interface CalendarDataAction {
  type: string;
  data?: CalendarDay[];
  calendarItem?: CalendarItem;
  calendarItems?: CalendarItem[];
  date?: string;
  calendarItemId?: string;
  calendarDayId?: string;
  calendarDay?: CalendarDay;
  oldCalendarDayId?: string;
  args?: QueryCalendarDateRangeArgs;
}
export interface CalendarDataState {
  calendarItemsByDay?: CalendarDay[];
  args?: QueryCalendarDateRangeArgs;
  isLoading: boolean;
  isSavingEvent: boolean;
  isDeletingEvent: boolean;
  isLoadingItem: boolean;
  isMovingCalendarItem: boolean;
  calendarFetchItemFailed: boolean;
}
