import React from "react";
import { connect } from "react-redux";
import { openEditClientInClientsTab } from "../../../redux/actions/ClientsTab";
import { ClientsTabColumnsType } from "../../../redux/actions/ClientsTab/types";
import {
  getIsAdmin,
  getIsMasquerade,
  StoreState,
} from "../../../redux/reducers";
import { ClientSearchResult } from "../../../types/gqlTypes";
import BodyCell from "./BodyCell";
import EditCell from "./EditCell";

interface OwnProps {}
interface Props extends OwnProps {
  content?: ClientSearchResult[];
  columns?: ClientsTabColumnsType[];
  widths?: number[];
  clientIdToEdit?: string;
  openEditClient: (clientId: string) => void;
  whichColumnDragging?: ClientsTabColumnsType;
  isAdmin: boolean;
  isMasquerade: boolean;
}

const Body = (props: Props) => {
  const {
    content,
    columns,
    widths,
    whichColumnDragging,
    clientIdToEdit,
    openEditClient,
    isAdmin,
    isMasquerade,
  } = props;

  const showEditColumn = isAdmin && !isMasquerade;

  return (
    <tbody className="d-flex flex-column">
      {content?.map((row, rowIndex) => {
        return (
          <tr key={`row-${row?.id}`} className="d-flex">
            {columns.map((columnName, columnIndex) => {
              const isLastColumn =
                !showEditColumn && columnIndex === columns?.length - 1;
              const addWidth = !showEditColumn && isLastColumn ? 56 : 0;
              const width = widths[columnIndex] + addWidth;
              return (
                <BodyCell
                  row={row}
                  column={columnName}
                  key={`cell-${row?.id}-${columnName}`}
                  width={width}
                  columnName={columnName as ClientsTabColumnsType}
                  isDragging={columnName === whichColumnDragging}
                  // @ts-ignore
                  rowIndex={rowIndex}
                  isLastRow={rowIndex === content.length - 1}
                  isLastColumn={isLastColumn}
                />
              );
            })}

            {showEditColumn && (
              <EditCell
                isSelected={clientIdToEdit === row?.id}
                onClick={() => openEditClient(row?.id)}
              />
            )}
          </tr>
        );
      })}
    </tbody>
  );
};

const mapDispatchToProps = (dispatch) => ({
  openEditClient: (clientId: string) => {
    dispatch(openEditClientInClientsTab(clientId));
  },
});

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  columns: state.clientsTabState.columns,
  content: state.clientsTabState.content,
  whichColumnDragging: state.clientsTabState.whichColumnDragging,
  widths: state.clientsTabState.widths,
  clientIdToEdit: state.clientsTabState.clientIdToEdit,
  isAdmin: getIsAdmin(state),
  isMasquerade: getIsMasquerade(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(Body);
