import React, { useState, forwardRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { StoreState } from "../../redux/reducers";
import {
  resetDebugToolsState,
  sendReminderNotification,
} from "../../redux/actions/DebugTools";
import colors from "../../assets/colors";
import { User } from "../../types/gqlTypes";
import SimpleButton from "../SimpleButton";
import Loader from "../Loader";
import { DebugToolsState } from "../../redux/actions/DebugTools/types";

interface Props {
  client: User;
  debugToolsState: DebugToolsState;
  sendNotification: (clientId: string, targetDate: string) => void;
  resetDebugToolsState: () => void;
}
interface CustomInputProps {
  value?: string;
  onClick?: (date?: any) => void;
}

const DebugReminderNotification = (props: Props) => {
  const { client, debugToolsState, sendNotification, resetDebugToolsState } =
    props;
  const { isLoading, sentReminderResult } = debugToolsState;
  const { id: clientId } = useParams();
  const [date, setDate] = useState(null);

  useEffect(() => {
    if (debugToolsState?.sentReminderResult && !debugToolsState?.isLoading) {
      setTimeout(() => {
        resetDebugToolsState();
      }, 1000 * 5);
    }
  }, [debugToolsState]);

  useEffect(() => {
    return () => {
      resetDebugToolsState();
    };
  }, []);

  const CustomInput = forwardRef((props: CustomInputProps, _ref: any) => {
    return (
      <input
        ref={_ref}
        onClick={() => {
          if (props?.onClick) {
            props.onClick();
          }
        }}
        className="d-flex flex-column justify-content-center align-items-center medium-bold pointer"
        style={{
          backgroundColor: colors.caliber_gray_bg,
          borderRadius: 4,
          border: `1px solid ${colors.caliber_gray_border}`,
          width: 220,
          padding: "5px 12px",
          margin: 0,
          fontSize: "24px",
          lineHeight: "24px",
        }}
        value={date ? format(date, "MMM dd yyyy") : "Pick a date"}
        onChange={() => {}}
      />
    );
  });

  const canNotifBeSent = !isLoading && !!date;

  return (
    <div>
      <DatePicker
        onChange={(date) => {
          setDate(date);
        }}
        customInput={<CustomInput />}
      />

      {isLoading && <Loader />}

      <SimpleButton
        onClick={() => {
          if (canNotifBeSent) {
            sendNotification(clientId, format(date, "yyyy-MM-dd"));
          }
        }}
        nofocus
        nopointer={!canNotifBeSent}
        className="d-flex flex-column justify-content-center align-items-center medium-bold"
        style={{
          backgroundColor: !canNotifBeSent
            ? colors.caliber_gray_text
            : colors.caliber_green_200,
          borderRadius: 8,
          width: 300,
          height: 70,
          color: colors.caliber_white,
          padding: "5px 12px",
          marginTop: 20,
          fontSize: "18px",
          lineHeight: "18px",
        }}
      >
        <div>Send Notification to</div>
        <div>{`${client?.firstName} ${client?.lastName}`}</div>
      </SimpleButton>

      {sentReminderResult && (
        <div
          style={{
            fontSize: "20px",
            marginTop: 20,
          }}
        >
          {sentReminderResult}
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  sendNotification: (clientId: string, targetDate: string) => {
    dispatch(sendReminderNotification(clientId, targetDate));
  },
  resetDebugToolsState: () => {
    dispatch(resetDebugToolsState());
  },
});

const mapStateToProps = (state: StoreState) => ({
  client: state.clientDetailState.user,
  debugToolsState: state.debugToolsState,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DebugReminderNotification);
