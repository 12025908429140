import React from "react";
import colors from "../../../assets/colors";
import RightArrow from "../../../assets/images/RightArrowGray.svg";
import SimpleButton from "../../SimpleButton";

interface Props {
  onClick: () => void;
}

const ViewMore = (props: Props) => {
  const { onClick } = props;

  return (
    <div className="d-flex justify-content-end align-items-center bold">
      <SimpleButton
        nofocus
        onClick={onClick}
        style={{
          fontSize: "14px",
          lineHeight: "18px",
          letterSpacing: "-0.03em",
          color: colors.caliber_secondary_gray_29,
          paddingRight: 10,
        }}
      >
        View more
      </SimpleButton>
      <SimpleButton
        isIcon
        nofocus
        className="d-flex align-items-center"
        onClick={onClick}
        iconStyle={{
          height: 18,
        }}
      >
        {RightArrow}
      </SimpleButton>
    </div>
  );
};

export default ViewMore;
