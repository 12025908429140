import React from "react";
import colors from "../../../assets/colors";
import { StrengthScoreMuscleGroupType } from "../../../types/gqlTypes";
import WeekDeltaBar from "./WeekDeltaBar";

interface Props {
  strengthScoreType: StrengthScoreMuscleGroupType;
  lastWeekDelta: number;
  thisWeekDelta: number;
}

const DeltaGraphs = (props: Props) => {
  const { lastWeekDelta, thisWeekDelta, strengthScoreType } = props;
  const unit =
    strengthScoreType === StrengthScoreMuscleGroupType.Weight ? "lbs" : "pts";

  return (
    <div className="d-flex">
      <div
        className="d-flex flex-column"
        style={{
          width: 159,
          height: 144,
        }}
      >
        <WeekDeltaBar unit={unit} value={lastWeekDelta} />
        <div
          className="d-flex align-items-end justify-content-center bold"
          style={{
            height: 24,
            fontSize: "12px",
            letterSpacing: "-0.022em",
            color: colors.caliber_secondary_gray_47,
          }}
        >
          Last Week
        </div>
      </div>

      <div
        className="d-flex flex-column"
        style={{
          width: 159,
          height: 144,
          marginLeft: 16,
        }}
      >
        <WeekDeltaBar unit={unit} value={thisWeekDelta} />
        <div
          className="d-flex align-items-end justify-content-center bold"
          style={{
            height: 24,
            fontSize: "12px",
            letterSpacing: "-0.022em",
            color: colors.caliber_secondary_gray_47,
          }}
        >
          This Week
        </div>
      </div>
    </div>
  );
};

export default DeltaGraphs;
