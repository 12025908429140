import { gql } from "apollo-boost";

export const CREATE_CLIENT_TEXT_GOAL = gql`
  mutation CreateTextGoal($clientId: UUID, $targetDate: String, $text: String) {
    createTextGoal(clientId: $clientId, text: $text, targetDate: $targetDate) {
      id
      achieved
      text
      targetDate
    }
  }
`;
export const UPDATE_CLIENT_TEXT_GOAL = gql`
  mutation UpdateTextGoal(
    $clientId: UUID
    $goalId: UUID
    $text: String
    $achieved: Boolean!
    $targetDate: String
  ) {
    updateTextGoal(
      clientId: $clientId
      goalId: $goalId
      text: $text
      achieved: $achieved
      targetDate: $targetDate
    ) {
      id
      achieved
      text
      targetDate
    }
  }
`;
