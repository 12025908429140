import DataTable, { TableColumn } from "react-data-table-component";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { connect } from "react-redux";
import { format, parseISO } from "date-fns";
import ReactTooltip from "react-tooltip";
import { StoreState } from "../../redux/reducers";
import colors from "../../assets/colors";
import {
  ClientSearchResult,
  CoachTask,
  CoachTaskImpactType,
  CoachTaskStatusType,
  MutationDeleteCoachTaskArgs,
  User,
  UserType,
  WebDeepLinkScreenType,
} from "../../types/gqlTypes";
import { MasqueradeState } from "../../redux/actions/Masquerade/types";
import {
  adminRunRulesEngineCron,
  deleteCoachTask,
  getTasks,
  markCoachTaskStatus,
} from "../../redux/actions/Tasks";
import SimpleButton from "../SimpleButton";
import { toTitleCase } from "../../utils";
import CoachTaskAutoButton from "../../assets/images/CoachTaskAutoButton.svg";
import CoachTaskCheckedButton from "../../assets/images/CoachTaskCheckedButton.svg";
import CoachTaskHoverButton from "../../assets/images/CoachTaskHoverButton.svg";
import CoachTaskUncheckedButton from "../../assets/images/CoachTaskUncheckedButton.svg";
import UserNameCell from "../UserTable/Body/UserNameCell";
import RefreshButton from "../../assets/images/RefreshButton.svg";
import Loader from "../Loader";
import DeleteSolidIcon from "../../assets/images/DeleteSolidIcon.svg";
import ConfirmationDialog from "../ConfirmationDialog";
import CoachTaskLowImpactIcon from "../../assets/images/CoachTaskLowImpactIcon.svg";
import CoachTaskMediumImpactIcon from "../../assets/images/CoachTaskMediumImpactIcon.svg";
import CoachTaskHighImpactIcon from "../../assets/images/CoachTaskHighImpactIcon.svg";

interface Props {
  isLoading: boolean;
  isCronRunning: boolean;
  tasks: CoachTask[];
  user: User;
  masqueradeState: MasqueradeState;
  getTasks: (
    userType: UserType,
    statusCriteria: CoachTaskStatusType[],
    userId?: string
  ) => CoachTask[];
  markCoachTaskStatus: (
    trainerId: string,
    coachTaskId: string,
    status: CoachTaskStatusType
  ) => void;
  adminRunRulesEngineCron: (
    userType: UserType,
    statusCriteria: CoachTaskStatusType[],
    userId?: string
  ) => void;
  deleteCoachTask: (args: MutationDeleteCoachTaskArgs) => void;
  row: CoachTask;
}

const Tasks = (props: Props) => {
  const {
    isLoading,
    isCronRunning,
    tasks,
    user,
    masqueradeState,
    getTasks,
    markCoachTaskStatus,
    adminRunRulesEngineCron,
    deleteCoachTask,
  } = props;

  const isMasq = !!masqueradeState?.masqueradeTrainer?.id;
  const [openTaskCount, setOpenTaskCount] = useState(0);
  const [hideAction, setHideAction] = useState(false);
  const [hideFromCoach, setHideFromCoach] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle] = useState(false);
  const [activeFilter, setActiveFilter] = useState(CoachTaskStatusType.Open);
  const [deleteMode, setDeleteMode] = useState(false);
  const [selectedLocalCoachTask, setSelectedLocalCoachTask] = useState(
    {} as CoachTask
  );
  const isAdmin = user.type === UserType.Admin;

  const filteredItems =
    tasks != null &&
    tasks.filter(
      (item) =>
        (item.summary &&
          item.summary.toLowerCase().includes(filterText.toLowerCase())) ||
        item.trainer.firstName
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        item.trainer.lastName.toLowerCase().includes(filterText.toLowerCase())
    );
  const inputFocus = useRef(null);

  const subHeaderComponentMemo = React.useMemo(() => {
    const getFilterButtonColor = (buttonType: CoachTaskStatusType) => {
      return activeFilter === buttonType
        ? colors.caliber_blue
        : colors.caliber_gray_47;
    };

    return (
      <div
        className="d-flex flex-column"
        style={{
          flex: 0,
        }}
      >
        {tasks && (
          <div className="d-flex">
            {!isLoading && (
              <SimpleButton
                data-tip="Refresh"
                className="d-flex align-items-center justify-content-center medium-bold"
                isIcon
                onClick={() => {
                  if (activeFilter === CoachTaskStatusType.Open) {
                    loadTasks(
                      [CoachTaskStatusType.Open, CoachTaskStatusType.Escalated],
                      user
                    );
                  }
                  if (activeFilter === CoachTaskStatusType.Closed) {
                    loadTasks([CoachTaskStatusType.Closed], user);
                  }
                }}
                iconStyle={{ width: 43, height: 20 }}
                style={{
                  borderRadius: 4,
                  border: `1px solid ${colors.caliber_secondary_gray_5}`,
                  marginTop: "8px",
                  marginBottom: "6px",
                }}
              >
                {RefreshButton}
              </SimpleButton>
            )}

            {isLoading && <Loader />}

            {isAdmin && (
              <SimpleButton
                data-tip="Refresh"
                className="d-flex align-items-center justify-content-center medium-bold"
                onClick={() => {
                  if (activeFilter === CoachTaskStatusType.Open) {
                    loadCron(
                      [CoachTaskStatusType.Open, CoachTaskStatusType.Escalated],
                      user
                    );
                  }
                  if (activeFilter === CoachTaskStatusType.Closed) {
                    loadCron([CoachTaskStatusType.Closed], user);
                  }
                }}
                style={{
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: colors.caliber_white,
                  borderRadius: 4,
                  backgroundColor: colors.caliber_gold_200,
                  width: 134,
                  height: 32,
                  marginTop: 8,
                  marginLeft: 44,
                }}
              >
                {isCronRunning ? "Running Cron..." : "Run Cron Job"}
              </SimpleButton>
            )}

            <SimpleButton
              className="d-flex align-items-center justify-content-center medium-bold"
              onClick={() => {
                if (activeFilter != CoachTaskStatusType.Open) {
                  setHideAction(!hideAction);
                  loadTasks(
                    [CoachTaskStatusType.Open, CoachTaskStatusType.Escalated],
                    user
                  );
                  setActiveFilter(CoachTaskStatusType.Open);
                }
              }}
              style={{
                fontSize: "12px",
                lineHeight: "16px",
                color: colors.caliber_white,
                borderRadius: 4,
                backgroundColor: getFilterButtonColor(CoachTaskStatusType.Open),
                width: 134,
                height: 32,
                marginTop: 8,
                marginLeft: 44,
              }}
            >
              Open Tasks ({openTaskCount})
            </SimpleButton>

            <SimpleButton
              className="d-flex align-items-center justify-content-center medium-bold"
              onClick={() => {
                if (activeFilter != CoachTaskStatusType.Closed) {
                  setHideAction(!hideAction);
                  loadTasks([CoachTaskStatusType.Closed], user);
                  setActiveFilter(CoachTaskStatusType.Closed);
                }
              }}
              style={{
                fontSize: "12px",
                lineHeight: "16px",
                color: colors.caliber_white,
                borderRadius: 4,
                backgroundColor: getFilterButtonColor(
                  CoachTaskStatusType.Closed
                ),
                width: 134,
                height: 32,
                marginTop: 8,
                marginLeft: 44,
              }}
            >
              Closed Tasks
            </SimpleButton>

            <input
              className="bold"
              ref={inputFocus}
              style={{
                flex: 0.25,
                fontSize: "16px",
                lineHeight: "24px",
                padding: "13px 0px 11px 16px",
                backgroundColor: colors.caliber_secondary_gray_5,
                border: `1px solid ${colors.caliber_secondary_gray_5}`,
                borderRadius: 8,
                height: 48,
                marginRight: 24,
                marginLeft: 44,
              }}
              value={filterText}
              onChange={(event) => setFilterText(event.target.value)}
              placeholder="Filter Tasks"
            />
          </div>
        )}
      </div>
    );
  }, [
    filterText,
    resetPaginationToggle,
    tasks,
    activeFilter,
    openTaskCount,
    isLoading,
    isCronRunning,
  ]);

  // On page load
  useEffect(() => {
    const statusCriteria: CoachTaskStatusType[] = [
      CoachTaskStatusType.Open,
      CoachTaskStatusType.Escalated,
    ];

    loadTasks(statusCriteria, user);
    setHideFromCoach(!isMasq && user.type === UserType.Trainer);
  }, [user, masqueradeState]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [tasks]);

  // After page load
  useMemo(() => {
    if (tasks && activeFilter === CoachTaskStatusType.Open) {
      setOpenTaskCount(tasks.length);
    }
  }, [tasks]);

  const loadTasks = (statusCriteria: CoachTaskStatusType[], user: User) => {
    if (isMasq) {
      getTasks(
        UserType.Trainer,
        statusCriteria,
        masqueradeState?.masqueradeTrainer?.id
      );
    } else {
      getTasks(user.type, statusCriteria, user?.id);
    }
  };

  const loadCron = (statusCriteria: CoachTaskStatusType[], user: User) => {
    if (isMasq) {
      adminRunRulesEngineCron(
        UserType.Trainer,
        statusCriteria,
        masqueradeState?.masqueradeTrainer?.id
      );
    } else {
      adminRunRulesEngineCron(user.type, statusCriteria, user?.id);
    }
  };

  const friendlyDateTooltip = (dateString: string) => {
    return dateString ? format(parseISO(dateString), "Pp") : "";
  };

  const generateDeepLink = (row: CoachTask) => {
    if (row.webDeepLinkScreen) {
      let deepLinkLocation: string = "";
      switch (row.webDeepLinkScreen) {
        case WebDeepLinkScreenType.ClientDashboard:
          deepLinkLocation = `/clients/${row.client.id}/dashboard`;
          break;
        case WebDeepLinkScreenType.ClientProfile:
          deepLinkLocation = `/clients/${row.client.id}/profile`;
          break;
        case WebDeepLinkScreenType.ClientTrainingProgram:
          deepLinkLocation = `/clients/${row.client.id}/trainingProgram`;
          break;
        case WebDeepLinkScreenType.Messages:
          if (user.type === UserType.Trainer || isMasq) {
            deepLinkLocation = `/messages/${row.client.id}`;
            break;
          } else {
            return row.summary;
          }
        case WebDeepLinkScreenType.NutritionProgram:
          deepLinkLocation = `/clients/${row.client.id}/nutritionProgram`;
          break;
        case WebDeepLinkScreenType.Progress:
          deepLinkLocation = `/clients/${row.client.id}/progress`;
          break;
      }
      return (
        <Link to={deepLinkLocation} target="_blank" rel="noopener noreferrer">
          <div
            style={{
              color: "black",
            }}
          >
            {row.summary}
          </div>
        </Link>
      );
    }

    return row.summary;
  };

  const toggleDeleteMode = (coachTask: CoachTask) => {
    setSelectedLocalCoachTask(coachTask);
    setDeleteMode(!deleteMode);
  };

  const customImpactSort = (rowA: CoachTask, rowB: CoachTask) => {
    function convertImpactToNumber(rowA: CoachTask) {
      switch (rowA.impact) {
        case CoachTaskImpactType.Low:
          return 0;
        case CoachTaskImpactType.Medium:
          return 1;
        case CoachTaskImpactType.High:
          return 2;
        default:
          return 0;
      }
    }

    const intA = convertImpactToNumber(rowA);
    const intB = convertImpactToNumber(rowB);

    if (intA > intB) {
      return 1;
    }
    if (intB > intA) {
      return -1;
    }
    return 0;
  };

  const generateImpactIcon = (coachTask: CoachTask) => {
    switch (coachTask.impact) {
      case CoachTaskImpactType.Low:
        return <img src={CoachTaskLowImpactIcon} alt="Low Impact" />;
      case CoachTaskImpactType.Medium:
        return <img src={CoachTaskMediumImpactIcon} alt="Medium Impact" />;
      case CoachTaskImpactType.High:
        return <img src={CoachTaskHighImpactIcon} alt="High Impact" />;
      default:
        return 0;
    }
  };

  const columns: TableColumn<CoachTask>[] = React.useMemo(
    () => [
      {
        name: "Summary",
        selector: (row: CoachTask) => row.summary,
        format: (row: CoachTask) => generateDeepLink(row),
        sortable: true,
        wrap: true,
        grow: 5,
      },
      {
        name: "Impact",
        selector: (row) => row.impact,
        omit: hideFromCoach,
        format: (row) => generateImpactIcon(row),
        sortable: true,
        wrap: true,
        sortFunction: customImpactSort,
      },
      {
        name: "Created",
        selector: (row: CoachTask) => row.createdDate,
        sortable: true,
        format: (row: CoachTask) => (
          <Moment fromNow data-tip={friendlyDateTooltip(row.createdDate)}>
            {row.createdDate}
          </Moment>
        ),
      },
      {
        name: "Status",
        selector: (row: CoachTask) => toTitleCase(row.status),
        sortable: true,
        conditionalCellStyles: [
          {
            when: (row: CoachTask) => row.status === CoachTaskStatusType.Open,
            style: {
              color: colors.caliber_green_200,
            },
          },
          {
            when: (row) => row.status === CoachTaskStatusType.Closed,
            style: {
              color: colors.caliber_error_color,
            },
          },
        ],
      },
      {
        name: "Coach",
        omit: user.type === UserType.Trainer || isMasq,
        selector: (row: CoachTask) => row.trainer.firstName,
        sortable: true,
        cell: (row: CoachTask) =>
          `${row.trainer.firstName} ${row.trainer.lastName}`,
      },
      {
        name: "Client",
        selector: (row: CoachTask) => row.client.firstName,
        sortable: true,
        cell: (row: CoachTask) => (
          <UserNameCell
            row={
              {
                id: row.client.id,
                profileMediaIconUrl: row.client.profileIconUrl,
                clientPackageType:
                  row.client.clientSalesPackage.clientPackageType,
                fullName: `${row.client.firstName} ${row.client.lastName}`,
                armsMuscleGroupScore: 0,
                armsMuscleGroupScoreDelta: 0,
                backMuscleGroupScore: 0,
                backMuscleGroupScoreDelta: 0,
                chestMuscleGroupScore: 0,
                chestMuscleGroupScoreDelta: 0,
                legsMuscleGroupScore: 0,
                legsMuscleGroupScoreDelta: 0,
                shouldersMuscleGroupScore: 0,
                shouldersMuscleGroupScoreDelta: 0,
                strengthBalance: 0,
                strengthBalanceDelta: 0,
                strengthScore: 0,
                strengthScoreDelta: 0,
              } as ClientSearchResult
            }
            width={200}
            outerBoxStyle={{}}
            innerBoxStyle={{
              color: "black",
              marginTop: "10px",
            }}
          />
        ),
      },
      {
        name: "Due Date",
        omit: hideFromCoach,
        selector: (row: CoachTask) => row.dueDate,
        sortable: true,
        format: (row: CoachTask) => (
          <Moment fromNow data-tip={friendlyDateTooltip(row.dueDate)}>
            {row.dueDate}
          </Moment>
        ),
      },
      {
        name: "Resolved Date",
        omit: !hideAction,
        selector: (row: CoachTask) => row.resolvedDate,
        sortable: true,
        format: (row: CoachTask) => (
          <Moment fromNow data-tip={friendlyDateTooltip(row.resolvedDate)}>
            {row.resolvedDate}
          </Moment>
        ),
      },
      {
        name: "Snooze Until",
        omit: !hideAction,
        selector: (row: CoachTask) => row.snoozeUntilDate,
        sortable: true,
        format: (row: CoachTask) =>
          row.snoozeUntilDate && (
            <Moment fromNow data-tip={friendlyDateTooltip(row.snoozeUntilDate)}>
              {row.snoozeUntilDate}
            </Moment>
          ),
      },
      {
        name: "Action",
        omit: hideAction,
        cell: (row: CoachTask) => (
          <div className="d-flex flex-column">
            <div className="flex-center">
              {row.taskAutocompletable && (
                <div>
                  <img src={CoachTaskAutoButton} alt="Auto" />
                </div>
              )}

              {!row.taskAutocompletable &&
                row.status === CoachTaskStatusType.Open && (
                  <SimpleButton
                    className="pointer"
                    onClick={() => {
                      const trainerId = row.assignee.id;
                      const taskId = row.id;
                      markCoachTaskStatus(
                        trainerId,
                        taskId,
                        CoachTaskStatusType.Closed
                      );
                    }}
                    styleOnHover={{
                      backgroundImage: `url('${CoachTaskHoverButton}')`,
                    }}
                  >
                    <img
                      src={CoachTaskUncheckedButton}
                      alt="Unchecked Button"
                    />
                  </SimpleButton>
                )}

              {!row.taskAutocompletable &&
                row.status === CoachTaskStatusType.Closed && (
                  <div>
                    <img src={CoachTaskCheckedButton} alt="Closed" />
                  </div>
                )}
              {!hideFromCoach && (
                <SimpleButton
                  className="pointer"
                  onClick={() => toggleDeleteMode(row)}
                  styleOnHover={{
                    backgroundColor: "red",
                    borderRadius: "20px",
                    paddingRight: "10px",
                  }}
                >
                  <img
                    src={DeleteSolidIcon}
                    alt="Delete"
                    style={{
                      height: "36px",
                      paddingLeft: "10px",
                    }}
                  />
                </SimpleButton>
              )}
            </div>
          </div>
        ),
      },
    ],
    [hideAction, hideFromCoach]
  );

  return (
    <div
      className="d-flex flex-column"
      style={{
        flex: 1,
        padding: "48px 32px",
      }}
    >
      {deleteMode && (
        <ConfirmationDialog
          show
          text={`Are you sure you want to delete the task\n 
            "${selectedLocalCoachTask.summary}"\n
            for Coach ${selectedLocalCoachTask.assignee.firstName} ${selectedLocalCoachTask.assignee.lastName}?`}
          cancelButtonText="Cancel"
          onConfirm={() => {
            deleteCoachTask({
              trainerId: selectedLocalCoachTask.assignee.id,
              coachTaskId: selectedLocalCoachTask.id,
            });

            setDeleteMode(!deleteMode);
          }}
          onCancel={() => setDeleteMode(!deleteMode)}
        />
      )}

      {tasks && (
        <div
          style={{
            height: 1,
            backgroundColor: colors.caliber_gray_border,
          }}
        >
          <DataTable
            title="Tasks"
            columns={columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            highlightOnHover
            customStyles={{
              cells: {
                style: {
                  fontFamily: "Circular, serif",
                  fontSize: "16px",
                },
              },
              headCells: {
                style: {
                  fontFamily: "Circular-Medium, serif",
                  fontSize: "14px",
                  border: "1px solid rgb(242, 242, 242)",
                  borderRadius: "8px",
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  marginLeft: "10px",
                  marginRight: "10px",
                },
              },
              header: {
                style: {
                  fontFamily: "Circular-Bold, serif",
                  fontWeight: "bold",
                },
              },
              headRow: {
                style: {
                  minHeight: "30px",
                  borderBottomStyle: "none",
                },
              },
            }}
          />
        </div>
      )}
      <ReactTooltip />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getTasks: (
    userType: UserType,
    statusCriteria: CoachTaskStatusType[],
    userId: string
  ) => {
    dispatch(getTasks(userType, statusCriteria, userId));
  },
  markCoachTaskStatus: (
    trainerId: string,
    coachTaskId: string,
    status: CoachTaskStatusType
  ) => {
    dispatch(markCoachTaskStatus(trainerId, coachTaskId, status));
  },
  adminRunRulesEngineCron: (
    userType: UserType,
    statusCriteria: CoachTaskStatusType[],
    userId: string
  ) => {
    dispatch(adminRunRulesEngineCron(userType, statusCriteria, userId));
  },
  deleteCoachTask: (args: MutationDeleteCoachTaskArgs) => {
    dispatch(deleteCoachTask(args));
  },
});

const mapStateToProps = (state: StoreState) => ({
  tasks: state.tasksState.tasks,
  user: state.authenticatedUserState.user,
  masqueradeState: state.masqueradeState,
  isLoading: state.tasksState.isLoading,
  isCronRunning: state.tasksState.isCronRunning,
});

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
