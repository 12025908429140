import { gql } from "apollo-boost";

export const UPDATE_MINIMUM_APP_BUILD_NUMBER = gql`
  mutation UpdateMinimumAppBuildNumber(
    $buildNumber: Int!
    $deviceType: DeviceType!
  ) {
    updateMinimumAppBuildNumber(
      buildNumber: $buildNumber
      deviceType: $deviceType
    ) {
      deviceType
      id
      minimumAppBuildNumber
    }
  }
`;

export const GET_MINIMUM_APP_BUILD_NUMBER = gql`
  query AppRequirements($deviceType: DeviceType) {
    appRequirements(deviceType: $deviceType) {
      deviceType
      id
      minimumAppBuildNumber
    }
  }
`;
