/* eslint-disable react/no-array-index-key */
import { Link, useParams } from "react-router-dom";
import React, { useMemo } from "react";
import { connect } from "react-redux";
import { format } from "date-fns";
import { StoreState } from "../../redux/reducers";
import { selectWorkoutProgressTable } from "../../redux/actions/ClientWorkoutHistory";
import {
  CalendarDay,
  ClientGymStep,
  ClientGymStepStatusType,
  ClientSupersetGymStep,
  ClientWorkout,
  ExerciseTargetBaseType,
  WorkoutCalendarItem,
} from "../../types/gqlTypes";
import AchievementsIcon from "../../assets/images/AchievementsIcon.svg";
import colors from "../../assets/colors";
import Modal from "../Modal";
import Loader from "../Loader";
import CalendarIcons, { CalendarIconType } from "../Icons/CalendarIcons";
import { exerciseThumbnailImageUrl } from "../../utils";

interface OwnProps {
  onClose: () => void;
  isLoading: boolean;
  day: Date;
  itemId: string;
  calendarDay: CalendarDay;
}
interface Props extends OwnProps {
  selectWorkoutProgressTable: (id: string) => void;
}

const StrengthCalendarItem = (props: Props) => {
  const {
    isLoading,
    day,
    itemId,
    calendarDay,
    onClose,
    selectWorkoutProgressTable,
  } = props;
  const { id: clientId } = useParams();

  const [clientWorkout, achievObj] = useMemo(() => {
    const clientWorkout: ClientWorkout = (
      calendarDay?.items.find(
        (item) => item?.id === itemId
      ) as WorkoutCalendarItem
    )?.clientWorkout;
    const obj = {};
    clientWorkout?.achievements?.map((ach) => {
      obj[ach?.exercise?.id] = true;
    });
    return [clientWorkout, obj];
  }, [itemId, isLoading, calendarDay]);

  const titleStyle = (exerciseId) => {
    const style: React.CSSProperties = {
      fontSize: "18px",
      lineHeight: "22px",
      letterSpacing: "-0.03em",
      color: colors.caliber_secondary,
      height: 80,
      paddingTop: 5,
      marginRight: 8,
      textOverflow: "ellipsis",
      overflow: "hidden",
      width: achievObj?.[exerciseId] ? 264 : 330,
      maxWidth: achievObj?.[exerciseId] ? 264 : 330,
      whiteSpace: "nowrap",
    };
    return style;
  };

  const exerciseSetsAndReps = (clientGymStep: ClientGymStep) => {
    const { targetBase } = clientGymStep?.exercise || {};

    return clientGymStep?.exerciseSets?.map((exerciseSet, setIndex) => {
      const isLastSet = setIndex === clientGymStep?.exerciseSets?.length - 1;
      const { actualReps, actualWeight, actualTime } = exerciseSet;
      const setName = `SET ${setIndex + 1}`;
      const reps = [];
      if (targetBase === ExerciseTargetBaseType.Reps) {
        reps.push(actualReps);
      } else if (targetBase === ExerciseTargetBaseType.Time) {
        const min = Math.floor((actualTime || 0) / 60);
        const sec = (actualTime || 0) - min * 60;
        if (min > 0) {
          reps.push(`${min}`);
          sec > 0
            ? reps.push(`:${sec > 9 ? "" : "0"}${sec} mins`)
            : reps.push(`${min === 1 ? " min" : " mins"}`);
        } else {
          sec === 1 ? reps.push(`${sec} sec`) : reps.push(`${sec} secs`);
        }
      }
      return (
        <div
          key={setIndex}
          className="d-flex"
          style={{
            marginBottom: !isLastSet && 6,
          }}
        >
          <div
            className="d-flex align-items-center justify-content-center medium-bold"
            style={{
              width: 44,
              height: 22,
              padding: 4,
              fontSize: "12px",
              lineHeight: "14px",
              borderRadius: 6,
              backgroundColor: "#DADCE3",
              marginRight: 8,
            }}
          >
            {setName}
          </div>
          <div
            className="d-flex align-items-center justify-content-center medium-bold"
            style={{
              width: 122,
              height: 22,
              backgroundColor: colors.caliber_secondary_gray_5,
              color: colors.caliber_secondary,
              borderRadius: 4,
              fontSize: "12px",
              lineHeight: "14px",
            }}
          >
            {exerciseSet
              ? `${reps.join("")} x ${
                  Math.round((actualWeight || 0) * 2) / 2
                } lbs`
              : "---"}
          </div>
        </div>
      );
    });
  };

  const renderGymStep = (gymStep: ClientGymStep) => (
    <div
      className="d-flex"
      style={{ marginBottom: gymStep?.exerciseSets?.length > 3 && 8 }}
    >
      <div
        key={gymStep?.id}
        className="d-flex bold"
        style={{
          width: 418,
          height: 80,
          padding: "8px 12px",
          marginBottom: 8,
          marginRight: 10,
          backgroundColor: colors.caliber_secondary_gray_5,
          borderRadius: 8,
          position: "relative",
        }}
      >
        <div
          style={{
            width: 64,
            height: 64,
            borderRadius: 8,
            marginRight: 8,
          }}
        >
          <img
            style={{
              width: 64,
              height: 64,
              borderRadius: 8,
            }}
            src={exerciseThumbnailImageUrl(gymStep?.exercise)}
          />
        </div>
        <div
          className="d-flex flex-column"
          style={{
            width: achievObj?.[gymStep?.exercise?.id] ? 264 : 330,
            maxWidth: achievObj?.[gymStep?.exercise?.id] ? 264 : 330,
          }}
        >
          <div
            className="d-flex align-items-center"
            style={titleStyle(gymStep?.exercise?.id)}
          >
            {gymStep?.exercise?.name}
          </div>
        </div>
        {achievObj?.[gymStep?.exercise?.id] && (
          <div className="d-flex align-items-center">
            <img style={{ width: 58, height: 56 }} src={AchievementsIcon} />
          </div>
        )}
      </div>
      <div className="d-flex flex-column">{exerciseSetsAndReps(gymStep)}</div>
    </div>
  );

  const renderSuperset = (supersetGymStep: ClientSupersetGymStep) => {
    return supersetGymStep.clientGymSteps.map((supersetChild) => {
      return renderGymStep(supersetChild);
    });
  };

  return (
    <Modal onClose={onClose} closeOnDimmerClick>
      <div
        className="d-flex flex-column"
        style={{
          padding: 24,
          position: "relative",
          width: 665,
        }}
      >
        <Link
          to={`/clients/${clientId}/progress`}
          onClick={() => {
            if (clientWorkout?.id) {
              selectWorkoutProgressTable(clientWorkout?.sourceTemplate?.id);
            }
          }}
        >
          <div
            className="medium-bold"
            style={{
              position: "absolute",
              top: 26,
              right: 24,
              fontSize: "14px",
              lineHeight: "14px",
              color: colors.caliber_secondary,
            }}
          >
            View Progress
          </div>
        </Link>
        {isLoading && (
          <div
            style={{
              position: "absolute",
              top: "48%",
              left: "48%",
            }}
          >
            <Loader />
          </div>
        )}
        <div className="d-flex justify-content-between">
          <div
            className="medium-bold"
            style={{
              color: colors.caliber_secondary,
              fontSize: "12px",
              lineHeight: "24px",
            }}
          >
            {`${format(day, "EEEE")}・${format(day, "MMM dd yyyy")}`}
          </div>
        </div>
        <div
          className="d-flex flex-column"
          style={{
            height: 540,
            overflowY: "auto",
          }}
        >
          <div
            className="d-flex align-items-center medium-bold"
            style={{
              color: colors.caliber_secondary,
              fontSize: "20px",
              lineHeight: "24px",
              marginTop: 5,
              wordBreak: "break-word",
              width: 424,
            }}
          >
            <div style={{ width: 24, height: 24, marginRight: 10 }}>
              <CalendarIcons type={CalendarIconType.WORKOUT_RECT} />
            </div>
            {clientWorkout?.title}
          </div>
          <div
            className="d-flex flex-column"
            style={{
              height: 540,
              overflowY: "auto",
            }}
          >
            {clientWorkout?.gymSteps?.map((abstractGymStep) => {
              const isSuperset = abstractGymStep.exercise == null;
              const clientGymStep: ClientGymStep = abstractGymStep;
              console.log(clientGymStep);
              let supersetGymStep: ClientSupersetGymStep;
              if (isSuperset) {
                supersetGymStep = abstractGymStep;
              }
              return (
                clientGymStep?.status === ClientGymStepStatusType.Completed && (
                  <div key={abstractGymStep.id}>
                    <div
                      className="medium-bold"
                      style={{
                        color: colors.caliber_gray_text,
                        fontSize: "14px",
                        lineHeight: "24px",
                        margin: "16px 0px",
                      }}
                    >
                      {isSuperset ? "Completed superset" : "Completed exercise"}
                    </div>
                    {isSuperset && renderSuperset(supersetGymStep)}
                    {!isSuperset && renderGymStep(clientGymStep)}
                  </div>
                )
              );
            })}
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  selectWorkoutProgressTable: (id: string) => {
    dispatch(selectWorkoutProgressTable(id));
  },
});

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  onClose: ownProps.onClose,
  isLoading: ownProps.isLoading,
  day: ownProps.day,
  itemId: ownProps.itemId,
  calendarDay: ownProps.calendarDay,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StrengthCalendarItem);
