import React, { useState } from "react";
import { connect } from "react-redux";
import { getIsAdmin, StoreState } from "../../redux/reducers";
import { ClientSearchResult } from "../../types/gqlTypes";
import { useInterval, useRerenderOnResize } from "../../utils/customHooks";
import Body from "./Body";
import Filter from "./Filter";
import Header from "./Header";
import Pagination from "./Pagination";

interface Props {
  isSettingsOpen?: boolean;
  isNewClientDetailsOpen?: boolean;
  isEditClientDetailsOpen?: boolean;
  content?: ClientSearchResult[];
  isAdmin?: boolean;
}

const UserTable = (props: Props) => {
  const {
    isSettingsOpen,
    isNewClientDetailsOpen,
    isEditClientDetailsOpen,
    content,
    isAdmin,
  } = props;
  useRerenderOnResize();

  const scrollLeft = () => {
    const el = document.getElementById("clients_tab_table");
    el.scrollLeft -= 10;
  };
  const scrollRight = () => {
    const el = document.getElementById("clients_tab_table");
    el.scrollLeft += 10;
  };

  const [scrollLeftDelay, setScrollLeftDelay] = useState(0);
  const [scrollRightDelay, setScrollRightDelay] = useState(0);
  useInterval(scrollLeft, scrollLeftDelay);
  useInterval(scrollRight, scrollRightDelay);

  const tableHeight = Math.max(window.innerHeight - 250, 300);
  let tableWidth = Math.max(window.innerWidth - 136, 650);

  if (isSettingsOpen || isNewClientDetailsOpen || isEditClientDetailsOpen) {
    tableWidth -= 448;
  }

  const minWidth = isAdmin ? 751 : 600;

  tableWidth = Math.max(minWidth, tableWidth);

  return (
    <div
      className="d-flex flex-column"
      style={{
        flex: 1,
        position: "relative",
      }}
    >
      <Filter />

      {false && (
        <div className="d-flex justify-content-between">
          <div
            className="d-flex align-items-center justify-content-center"
            onDragEnter={() => setScrollLeftDelay(50)}
            onDragLeave={() => setScrollLeftDelay(0)}
            onFocus={() => {}}
            style={{
              flex: 1,
              height: 50,
              border: "1px solid black",
            }}
          >
            Left
          </div>
          <div
            className="d-flex align-items-center justify-content-center"
            onDragEnter={() => setScrollRightDelay(50)}
            onDragLeave={() => setScrollRightDelay(0)}
            onFocus={() => {}}
            style={{
              flex: 1,
              height: 50,
              border: "1px solid black",
            }}
          >
            Right
          </div>
        </div>
      )}

      <div className="d-flex">
        <div
          className="d-flex align-items-center justify-content-center"
          onDragEnter={() => setScrollLeftDelay(50)}
          onDragLeave={() => setScrollLeftDelay(0)}
          onFocus={() => {}}
          style={{
            flex: 1,
            width: 24,
          }}
        />

        <div
          id="clients_tab_table"
          className="d-flex"
          style={{
            height: tableHeight || 0,
            width: tableWidth || 0,
            position: "relative",
            overflow: "auto",
          }}
        >
          <table
            id="clients_tab_table"
            className="d-flex flex-column"
            style={{
              height: content ? 64 * (content?.length + 1) : 0,
              position: "relative",
              tableLayout: "fixed",
            }}
          >
            <Header />

            <Body />
          </table>
        </div>

        <div
          className="d-flex align-items-center justify-content-center"
          onDragEnter={() => setScrollRightDelay(50)}
          onDragLeave={() => setScrollRightDelay(0)}
          onFocus={() => {}}
          style={{
            flex: 1,
            width: 24,
          }}
        />
      </div>

      <Pagination />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state: StoreState) => ({
  isSettingsOpen: state.clientsTabState.isSettingsOpen,
  isNewClientDetailsOpen: state.clientsTabState.isNewClientDetailsOpen,
  isEditClientDetailsOpen: state.clientsTabState.isEditClientDetailsOpen,
  isLoading: state.clientsTabState.isLoading,
  page: state.clientsTabState.page,
  pageSize: state.clientsTabState.pageSize,
  content: state.clientsTabState.content,
  searchCriteria: state.clientsTabState.searchCriteria,

  isAdmin: getIsAdmin(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserTable);
