import React, { useState } from "react";
import { isAfter } from "date-fns";
import { useDrag, useDrop } from "react-dnd";
import colors from "../../assets/colors";
import {
  CalendarItem,
  CalendarItemType,
  BodyStatCalendarItem,
  BodyMeasurementType,
  CardioActivityCalendarItem,
  HabitCalendarItem,
  CardioActivityType,
  CalendarDay,
  CalendarItemStatusType,
} from "../../types/gqlTypes";
import CloseIcon from "../../assets/images/DeleteCalendarItemIcon.svg";
import SimpleButton from "../SimpleButton";
import CalendarItemCircle from "./CalendaritemCircle";

interface Props {
  item: CalendarItem;
  calendarDay: CalendarDay;
  date: Date;
  isDraggable: boolean;
  viewEvent: () => void;
  onDelete: (item: CalendarItem, date: Date) => void;
}

const DraggableCalendarItemComponent = (props: Props) => {
  const { item, date, viewEvent, onDelete, calendarDay, isDraggable } = props;
  const { enumType, title } = item;

  const [hovered, setHovered] = useState(false);

  const cardioTitles = {
    [CardioActivityType.Cycling]: "Cycling",
    [CardioActivityType.Elliptical]: "Elliptical",
    [CardioActivityType.General]: "General",
    [CardioActivityType.Hiit]: "Hiit",
    [CardioActivityType.Hiking]: "Hiking",
    [CardioActivityType.IndoorCycle]: "Indoor Cycle",
    [CardioActivityType.IndoorRun]: "Indoor Run",
    [CardioActivityType.IndoorWalk]: "Indoor Walk",
    [CardioActivityType.OpenWaterSwim]: "Open Water Swim",
    [CardioActivityType.OutdoorCycle]: "Outdoor Cycle",
    [CardioActivityType.OutdoorRun]: "Outdoor Run",
    [CardioActivityType.OutdoorWalk]: "Outdoor Walk",
    [CardioActivityType.PoolSwim]: "Pool Swim",
    [CardioActivityType.Rower]: "Rower",
    [CardioActivityType.Running]: "Running",
    [CardioActivityType.Stairclimber]: "Stairclimber",
    [CardioActivityType.StairStepper]: "Stair Stepper",
    [CardioActivityType.Swimming]: "Swimming",
    [CardioActivityType.Walking]: "Walking",
    [CardioActivityType.Yoga]: "Yoga",
  };
  let itemTitle = title;
  if (enumType === CalendarItemType.ImportedNutrition) {
    itemTitle = "Nutrition ";
  } else if (
    enumType === CalendarItemType.BodyStat ||
    enumType === CalendarItemType.ImportedBodyStat
  ) {
    const value = (item as BodyStatCalendarItem)?.bodyMeasurementValue;
    const type = (item as BodyStatCalendarItem)?.bodyMeasurementType;
    const stringArr = [];
    stringArr.push(value);
    if (type === BodyMeasurementType.BodyFatPercent) {
      stringArr.push("% body fat");
    } else if (type === BodyMeasurementType.BodyWeight) {
      stringArr.push(" lbs");
    } else if (type === BodyMeasurementType.WaistSize) {
      stringArr.push(" in waist");
    }
    itemTitle = stringArr.join("");
  } else if (
    enumType === CalendarItemType.ImportedSteps ||
    enumType === CalendarItemType.Steps
  ) {
    const isDateInFuture = isAfter(date, new Date());
    if (isDateInFuture) {
      itemTitle = `${(
        (item as HabitCalendarItem)?.target || 0
      ).toLocaleString()} Steps`;
    } else {
      itemTitle = `${(
        (item as HabitCalendarItem)?.quantity || 0
      ).toLocaleString()} Steps`;
    }
  } else if (enumType === CalendarItemType.ProgressPic) {
    itemTitle = "Progress Photos";
  } else if (
    enumType === CalendarItemType.CardioActivity ||
    enumType === CalendarItemType.ImportedCardioActivity
  ) {
    itemTitle = (item as CardioActivityCalendarItem)?.title || "Other";
  }
  const isClickable =
    enumType === CalendarItemType.ImportedNutrition ||
    enumType === CalendarItemType.ProgressPic ||
    (enumType === CalendarItemType.Workout &&
      item?.status === CalendarItemStatusType.Completed) ||
    enumType === CalendarItemType.CardioActivity ||
    enumType === CalendarItemType.ImportedCardioActivity;
  const isDeletable =
    enumType !== CalendarItemType.ImportedNutrition &&
    enumType !== CalendarItemType.ImportedSteps &&
    enumType !== CalendarItemType.ImportedCardioActivity &&
    enumType !== CalendarItemType.ImportedBodyStat;

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: "calendar",
      calendarItemId: item.id,
      oldCalendarDay: calendarDay,
    },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  });

  const [, drop] = useDrop({
    accept: "calendar",
    collect: (monitor) => monitor,
    canDrop: () => false,
  });

  let cursor = isDraggable ? "move" : "default";
  if (isClickable) {
    cursor = "pointer";
  }

  return (
    <div
      ref={(node) => (isDraggable ? drag(drop(node)) : null)}
      role="button"
      tabIndex={0}
      onKeyDown={(event) => {
        if (event.key === "Enter" && isClickable) {
          viewEvent();
        }
      }}
      onClick={() => {
        if (isClickable) {
          viewEvent();
        }
      }}
      className="d-flex align-items-center nofocus"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{
        flex: 1,
        transform: isDraggable && "translate(0, 0)",
        cursor,
        opacity: isDragging ? 0 : 1,
        position: "relative",
        margin: "1px 2px",
        padding: "4px 6px 4px 2px",
        paddingRight: hovered && 18,
        borderRadius: 4,
        backgroundColor:
          (hovered || isDragging) && colors.caliber_secondary_gray_5,
      }}
    >
      <div
        style={{
          width: 18,
        }}
      >
        <CalendarItemCircle calendarItem={item} />
      </div>
      <div
        title={title}
        style={{
          fontSize: "12px",
          lineHeight: "12px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          color: colors.caliber_gray_text,
        }}
      >
        {itemTitle}
      </div>
      {hovered && isDeletable && (
        <SimpleButton
          onClick={() => {
            if (isDeletable) {
              onDelete(item, date);
            }
          }}
          nofocus
          style={{
            flex: "0 0 13px",
            paddingLeft: 4,
            height: 14,
            position: "absolute",
            right: 2,
            top: -3,
          }}
        >
          <img alt="i" src={CloseIcon} />
        </SimpleButton>
      )}
    </div>
  );
};
export default DraggableCalendarItemComponent;
