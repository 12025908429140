import React from "react";
import colors from "../../../assets/colors";
import { Exercise } from "../../../types/gqlTypes";
import { exerciseThumbnailImageUrl } from "../../../utils";

interface Props {
  exercise: Exercise;
  setNumber: number;
  date: string;
}

const ExerciseCard = (props: Props) => {
  const { exercise, setNumber, date } = props;
  const { name } = exercise;

  const labelStyle: React.CSSProperties = {
    fontSize: "12px",
    lineHeight: "14px",
    color: colors.caliber_secondary,
    width: 55,
    height: 22,
    borderRadius: 4,
    backgroundColor: colors.caliber_gray_bg,
    margin: "10px 4px 0px 4px",
  };

  return (
    <div
      className="d-flex align-items-center"
      style={{
        width: 404,
        height: 82,
        backgroundColor: colors.caliber_secondary_gray_5,
        borderRadius: 8,
        padding: 4,
        marginTop: 30,
      }}
    >
      <img
        style={{
          width: 64,
          height: 64,
          marginLeft: 12,
          marginRight: 8,
          borderRadius: 8,
        }}
        src={exerciseThumbnailImageUrl(exercise)}
      />
      <div className="d-flex flex-column">
        <div
          className="circular-bold-font d-flex align-items-center"
          style={{
            width: 300,
            marginLeft: 4,
            marginTop: 10,
            fontSize: "16px",
            lineHeight: "18px",
            letterSpacing: "-0.03em",
            whiteSpace: "pre-wrap",
          }}
        >
          {name}
        </div>
        <div className="d-flex">
          <div
            style={labelStyle}
            className="d-flex justify-content-center align-items-center medium-bold"
          >
            {date}
          </div>
          <div
            style={labelStyle}
            className="d-flex justify-content-center align-items-center medium-bold"
          >
            {`Set ${setNumber + 1}`}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExerciseCard;
