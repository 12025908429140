import React, { useState, useEffect, useMemo } from "react";
import colors from "../../assets/colors";
import { GymStep } from "../../types/gqlTypes";
import { onKeyDownRestrict } from "../../utils";

interface Props {
  gymStep: GymStep;
  onChange: (time: number) => void;
}

const TimeSection = (props: Props) => {
  const { gymStep, onChange } = props;

  const getTime = useMemo(() => {
    const min = Math.floor(gymStep?.targetTime / 60);
    const sec = gymStep?.targetTime - min * 60;
    return [`${min || 0} min`, `${sec || 0} sec`];
  }, [gymStep]);

  const [min, setMin] = useState(getTime[0]);
  const [sec, setSec] = useState(getTime[1]);

  useEffect(() => {
    const time = Number(min.split(" ")[0]) * 60 + Number(sec.split(" ")[0]);
    if (time !== gymStep?.targetTime) {
      onChange(time);
    }
  }, [min, sec]);

  const inputStyle: React.CSSProperties = {
    width: 80,
    flex: "1 1 80px",
    height: 32,
    backgroundColor: colors.caliber_white,
    borderRadius: 4,
    fontSize: "14px",
    lineHeight: "32px",
    color: colors.caliber_gray_text,
    padding: "6px 12px",
    margin: "0px 10px",
    border: "none",
    resize: "none",
    outline: "none",
  };

  const onKeyDown = (event) => {
    if (onKeyDownRestrict(event.key)) {
      event.preventDefault();
    }
  };
  return (
    <div
      className="d-flex align-items-center"
      style={{
        backgroundColor: colors.caliber_light_blue_20,
        height: 50,
        margin: "10px 0px",
        borderRadius: 4,
      }}
    >
      <div
        className="d-flex heading-xsmall"
        style={{
          color: colors.caliber_light_blue,
          flex: "0 0 60px",
          width: 60,
          marginLeft: 10,
        }}
      >
        Time
      </div>

      <input
        value={min}
        onKeyDown={onKeyDown}
        onFocus={() => setMin(min.split(" ")[0])}
        onBlur={() => setMin(`${min} min`)}
        onChange={(event) => {
          if (Number(event.target.value) < 121) {
            setMin(Number(event.target.value).toString());
          }
        }}
        style={inputStyle}
      />

      <input
        value={sec}
        onKeyDown={onKeyDown}
        onFocus={() => setSec(sec.split(" ")[0])}
        onBlur={() => setSec(`${sec} sec`)}
        onChange={(event) => {
          if (Number(event.target.value) < 60) {
            setSec(Number(event.target.value).toString());
          }
        }}
        style={inputStyle}
      />
    </div>
  );
};

export default TimeSection;
