import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import colors from "../../../assets/colors";
import {
  createCardioActivityCalendarItem,
  createCardioActivityRecurringEventDefinition,
  updateRecurringEventDefinition,
} from "../../../redux/actions/Calendar";
import { StoreState } from "../../../redux/reducers";
import {
  CalendarItem,
  CardioActivityCalendarItem,
  CardioActivityType,
  MutationCreateCardioActivityCalendarItemArgs,
  MutationCreateCardioActivityRecurringEventDefinitionArgs,
  MutationUpdateRecurringEventDefinitionArgs,
  RecurringEventDefinition,
} from "../../../types/gqlTypes";
import RecurringMenu from "../../Calendar/RecurringMenu";
import Checkbox from "../../Icons/Checkbox";
import SimpleButton from "../../SimpleButton";
import Footer from "../Footer";
import Header, { HeaderType } from "../Header";
import CardioCard from "./CardioCard";
import CardioList from "./CardioList";

interface OwnProps {
  onClose: () => void;
  onBack: () => void;
  day: Date;
  calendarItem?: CalendarItem;
}
interface Props extends OwnProps {
  createCardioActivityCalendarItem: (
    args: MutationCreateCardioActivityCalendarItemArgs
  ) => void;
  createRecurringCardio: (
    args: MutationCreateCardioActivityRecurringEventDefinitionArgs
  ) => void;
  updateRecurringEventDefinition: (
    args: MutationUpdateRecurringEventDefinitionArgs
  ) => void;
}

const CardioEvent = (props: Props) => {
  const {
    day,
    calendarItem,
    onClose,
    onBack,
    createCardioActivityCalendarItem,
    createRecurringCardio,
    updateRecurringEventDefinition,
  } = props;
  const { id: clientId } = useParams();
  const [selectedWorkout, setSelectedWorkout] =
    useState<CardioActivityType>(null);
  const [repeat, setRepeat] = useState(false);
  const [recurringEvent, setRecurringEvent] =
    useState<RecurringEventDefinition>({
      RRule: "",
      endDate: format(day, "yyyy-MM-dd"),
      startDate: format(day, "yyyy-MM-dd"),
    });
  const isUpdating = !!calendarItem;

  useEffect(() => {
    if (calendarItem) {
      setRepeat(true);
      setSelectedWorkout(
        (calendarItem as CardioActivityCalendarItem)?.cardioActivityType
      );
      setRecurringEvent({
        RRule: calendarItem?.recurringEventDefinition?.RRule,
        endDate: calendarItem?.recurringEventDefinition?.endDate,
        startDate: calendarItem?.recurringEventDefinition?.startDate,
      });
    }
  }, [calendarItem]);

  const onBackClick = () => {
    if (calendarItem) {
      onClose();
    } else if (selectedWorkout) {
      setSelectedWorkout(null);
      setRepeat(false);
    } else {
      onBack();
    }
  };
  const onSuccess = () => {
    if (repeat && selectedWorkout) {
      const ind = recurringEvent.RRule.indexOf("RRULE:");
      if (isUpdating) {
        updateRecurringEventDefinition({
          clientId,
          rRule: recurringEvent.RRule.slice(ind + 6),
          recurringEventDefinitionId:
            calendarItem?.recurringEventDefinition?.id,
          endDate: recurringEvent.endDate,
        });
      } else {
        createRecurringCardio({
          clientId,
          cardioActivityType: selectedWorkout,
          rRule: recurringEvent.RRule.slice(ind + 6),
          startDate: recurringEvent.startDate,
          endDate: recurringEvent.endDate,
        });
      }
    } else if (selectedWorkout) {
      const [year, month, dayOfMonth] = format(day, "yyyy-MM-dd").split("-");
      createCardioActivityCalendarItem({
        clientId,
        cardioActivityType: selectedWorkout,
        dayOfMonth: Number(dayOfMonth),
        month: Number(month),
        year: Number(year),
      });
    }
    onClose();
  };

  const repeatStyle: React.CSSProperties = {
    fontSize: "16px",
    lineHeight: "16px",
    letterSpacing: "-0.022em",
    color: colors.caliber_gray_text,
    marginLeft: 8,
    marginBottom: 24,
  };
  const cardioCardStyle: React.CSSProperties = {
    marginTop: 42,
    marginBottom: 24,
  };

  return (
    <div className="d-flex flex-column">
      <Header day={day} type={HeaderType.CARDIO} onBackClick={onBackClick} />

      {!selectedWorkout && (
        <CardioList onClick={(value) => setSelectedWorkout(value)} />
      )}

      {selectedWorkout && (
        <div style={cardioCardStyle}>
          <CardioCard type={selectedWorkout} />
        </div>
      )}

      {selectedWorkout && (
        <div className="d-flex">
          <Checkbox
            disabled={isUpdating}
            checked={repeat}
            onClick={() => setRepeat(!repeat)}
          />
          <SimpleButton
            disabled={isUpdating}
            onClick={() => setRepeat(!repeat)}
            nofocus
            className="bold"
            style={repeatStyle}
          >
            Repeat
          </SimpleButton>
        </div>
      )}

      {repeat && (
        <RecurringMenu
          day={day}
          recurringEvent={recurringEvent}
          onChange={(recurringEvent) => setRecurringEvent(recurringEvent)}
        />
      )}

      {selectedWorkout && (
        <Footer
          successText={calendarItem ? "Update activity" : "Add activity"}
          onSuccess={onSuccess}
          onCancel={() => {
            if (calendarItem) {
              onClose();
            } else {
              onBack();
            }
          }}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  createCardioActivityCalendarItem: (
    args: MutationCreateCardioActivityCalendarItemArgs
  ) => {
    dispatch(createCardioActivityCalendarItem(args));
  },
  createRecurringCardio: (
    args: MutationCreateCardioActivityRecurringEventDefinitionArgs
  ) => {
    dispatch(createCardioActivityRecurringEventDefinition(args));
  },
  updateRecurringEventDefinition: (
    args: MutationUpdateRecurringEventDefinitionArgs
  ) => {
    dispatch(updateRecurringEventDefinition(args));
  },
});

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  onClose: ownProps.onClose,
  day: ownProps.day,
  calendarItem: ownProps.calendarItem,
});

export default connect(mapStateToProps, mapDispatchToProps)(CardioEvent);
