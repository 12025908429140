import React from "react";
import SimpleButton from "../SimpleButton";

interface Props {
  onClick: () => void;
  checked: boolean;
  id?: string;
}

const RadioButton = (props: Props) => {
  const { onClick, checked, id } = props;

  return (
    <SimpleButton id={id} nofocus onClick={onClick}>
      <svg
        style={{ display: "block" }}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="8" cy="8" r="7.5" fill="white" stroke="#01052F" />
        {checked && <circle cx="8" cy="8" r="5" fill="#01052F" />}
      </svg>
    </SimpleButton>
  );
};

export default RadioButton;
