import apolloClient from "../../../api/apolloClient";
import {
  GET_CALIBER_ERRORS_QUERY,
  THROW_CALIBER_ERROR,
  THROW_HTTP_ERROR,
} from "../../../api/gql/masqueradeDebug";
import { CaliberErrorObject } from "../../../types/gqlTypes";
import {
  CLOSE_ERROR_SUCCESS_DIALOG,
  GET_CALIBER_ERRORS,
  OPEN_ERROR_DIALOG,
  OPEN_SUCCESS_DIALOG,
  THROW_CALIBER_ERROR_FAILED,
  THROW_CALIBER_ERROR_LOADING,
  THROW_CALIBER_ERROR_SUCCESS,
} from "./types";

export const onErrorSuccessDialogClose = () => async (dispatch, getState) => {
  dispatch({
    type: CLOSE_ERROR_SUCCESS_DIALOG,
  });
};
export const onErrorDialogOpen =
  (errors: any[], message?: string) => async (dispatch, getState) => {
    dispatch({
      type: OPEN_ERROR_DIALOG,
      data: errors,
      message,
    });
  };
export const onSuccessDialogOpen =
  (message: string) => async (dispatch, getState) => {
    dispatch({
      type: OPEN_SUCCESS_DIALOG,
      message,
    });
  };
export const getCaliberErrors = () => async (dispatch, getState) => {
  try {
    const client = await apolloClient(getState().authState.authToken, dispatch);

    const result = await client.query({
      query: GET_CALIBER_ERRORS_QUERY,
    });
    const errors = result.data.caliberErrors as CaliberErrorObject[];

    dispatch({
      type: GET_CALIBER_ERRORS,
      errors,
    });
  } catch (error) {
    console.log("Can't fetch Caliber Errors: ", error);
  }
};
export const throwCaliberError =
  (caliberErrorNumber: number) => async (dispatch, getState) => {
    if (getState().errorSuccessDialogState.isLoading) {
      return;
    }
    dispatch({
      type: THROW_CALIBER_ERROR_LOADING,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );

      if (caliberErrorNumber === 500) {
        await client.mutate({
          mutation: THROW_HTTP_ERROR,
        });
      } else {
        await client.mutate({
          mutation: THROW_CALIBER_ERROR,
          variables: { caliberErrorNumber },
        });
      }
      dispatch({ type: THROW_CALIBER_ERROR_SUCCESS, caliberErrorNumber });
    } catch (error) {
      dispatch({
        type: THROW_CALIBER_ERROR_FAILED,
        data: error,
      });
    }
  };
