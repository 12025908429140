export const GET_CLIENT_CRONOMETER_USER_DIARY_SUCCESS =
  "GET_CLIENT_CRONOMETER_USER_DIARY_SUCCESS";
export const GET_CLIENT_CRONOMETER_USER_DIARY_FAIL =
  "GET_CLIENT_CRONOMETER_USER_DIARY_FAIL";
export const GET_CLIENT_CRONOMETER_USER_DIARY_LOADING =
  "GET_CLIENT_CRONOMETER_USER_DIARY_LOADING";

export interface ClientCronometerUserDiaryState {
  isLoading: boolean;
  userDiary?: string;
}

export interface ClientCronometerUserDiaryAction {
  type: string;
  userDiary?: string;
}
