import { ProgramType } from "../../../components/TrainingProgram";
import {
  GET_TRAINING_PLANS_FAIL,
  GET_TRAINING_PLANS_LOADING,
  GET_TRAINING_PLANS_SUCCESS,
  GET_TRAINING_PLAN_FAIL,
  GET_TRAINING_PLAN_LOADING,
  GET_TRAINING_PLAN_SUCCESS,
  TrainingProgramsAction,
  TrainingProgramsState,
  GET_TRAINING_PLAN_LAST_UPDATED_SUCCESS,
  LOAD_TRAINING_PLAN_FROM_ROUTE_SUCCESS,
} from "../../actions/TrainingPrograms/types";
import { getTrainingPlans } from "./helperFunctions";

const getReducer = (
  state: TrainingProgramsState,
  action: TrainingProgramsAction
) => {
  switch (action.type) {
    case GET_TRAINING_PLANS_LOADING:
      return {
        ...state,
        isLoadingPlans: true,
      };
    case GET_TRAINING_PLAN_LOADING:
      return {
        ...state,
        isLoadingPlan: true,
      };
    case GET_TRAINING_PLANS_SUCCESS: {
      return getTrainingPlans(state, action);
    }
    case LOAD_TRAINING_PLAN_FROM_ROUTE_SUCCESS: {
      return {
        ...state,
        isLoadingPlans: false,
        masterPlansContent: {
          content: [action.plan],
        },
      } as TrainingProgramsState;
    }
    case GET_TRAINING_PLANS_FAIL:
      return {
        ...state,
        isLoadingPlans: false,
      };
    case GET_TRAINING_PLAN_LAST_UPDATED_SUCCESS:
      state.lastUpdatedPlanDateById.set(action.id, action.timeUpdated);
      return {
        ...state,
        lastUpdatedPlanDateById: new Map(state.lastUpdatedPlanDateById),
      };
    case GET_TRAINING_PLAN_FAIL:
      return {
        ...state,
        isLoadingPlan: false,
      };
    case GET_TRAINING_PLAN_SUCCESS: {
      const { plan } = action;
      if (state.programType === ProgramType.Client) {
        return {
          ...state,
          isLoadingPlan: false,
          workoutGroupIndex: 0,
          cachedPlans: {
            ...state.cachedPlans,
            [plan.id]: plan,
          },
        };
      }
      return {
        ...state,
        isLoadingPlan: false,
        cachedPlans: {
          ...state.cachedPlans,
          [plan.id]: plan,
        },
      };
    }
    default:
      return state;
  }
};
export default getReducer;
