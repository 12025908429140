import apolloClient from "../../../api/apolloClient";
import { DELETE_USER_FROM_PLATFORM } from "../../../api/gql/users";
import deleteUserFromPlatformWebSocket from "./DeletingUserSocketInstance";
import {
  DELETE_USER_FROM_PLATFORM_FAIL,
  DELETE_USER_FROM_PLATFORM_LOADING,
  DELETE_USER_FROM_PLATFORM_SUCCESS,
} from "./types";

export const deleteUserFromPlatform =
  (userId: string, adminId: string, cb: (info: any) => void) =>
  async (dispatch, getState) => {
    dispatch({
      type: DELETE_USER_FROM_PLATFORM_LOADING,
    });
    try {
      deleteUserFromPlatformWebSocket.subscribe(
        getState().authState.authToken,
        adminId,
        (info) => {
          const parsedInfo = JSON.parse(info.body);
          if (parsedInfo?.operationComplete) {
            dispatch({
              type: DELETE_USER_FROM_PLATFORM_SUCCESS,
            });
          }
          cb(parsedInfo);
        }
      );
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      await client.mutate({
        mutation: DELETE_USER_FROM_PLATFORM,
        variables: { userId },
      });
    } catch (error) {
      console.error("Error deleteUserFromPlatform:", error);
      dispatch({
        type: DELETE_USER_FROM_PLATFORM_FAIL,
        data: error,
      });
    }
  };
