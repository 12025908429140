import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import colors from "../../../assets/colors";
import {
  createProgressPhotoCalendarItem,
  createProgressPhotoRecurringEventDefinition,
  updateRecurringEventDefinition,
} from "../../../redux/actions/Calendar";
import { StoreState } from "../../../redux/reducers";
import {
  CalendarItem,
  MutationCreateProgressPhotoCalendarItemArgs,
  MutationCreateProgressPhotoRecurringEventDefinitionArgs,
  MutationUpdateRecurringEventDefinitionArgs,
  RecurringEventDefinition,
} from "../../../types/gqlTypes";
import RecurringMenu from "../../Calendar/RecurringMenu";
import Checkbox from "../../Icons/Checkbox";
import SimpleButton from "../../SimpleButton";
import Footer from "../Footer";
import Header, { HeaderType } from "../Header";
import CategoryButton from "./CategoryButton";

interface OwnProps {
  onClose: () => void;
  onBack: () => void;
  day: Date;
  calendarItem?: CalendarItem;
}
interface Props extends OwnProps {
  createProgressPhotoCalendarItem: (
    args: MutationCreateProgressPhotoCalendarItemArgs
  ) => void;
  createRecurringProgressPhoto: (
    args: MutationCreateProgressPhotoRecurringEventDefinitionArgs
  ) => void;
  updateRecurringEventDefinition: (
    args: MutationUpdateRecurringEventDefinitionArgs
  ) => void;
}

const ProgressPhotoEvent = (props: Props) => {
  const {
    day,
    calendarItem,
    onClose,
    onBack,
    createProgressPhotoCalendarItem,
    createRecurringProgressPhoto,
    updateRecurringEventDefinition,
  } = props;
  const { id: clientId } = useParams();
  const [repeat, setRepeat] = useState(false);
  const [recurringEvent, setRecurringEvent] =
    useState<RecurringEventDefinition>({
      RRule: "",
      endDate: format(day, "yyyy-MM-dd"),
      startDate: format(day, "yyyy-MM-dd"),
    });
  const isUpdating = !!calendarItem;

  useEffect(() => {
    if (calendarItem) {
      setRepeat(true);
      setRecurringEvent({
        RRule: calendarItem?.recurringEventDefinition?.RRule,
        endDate: calendarItem?.recurringEventDefinition?.endDate,
        startDate: calendarItem?.recurringEventDefinition?.startDate,
      });
    }
  }, [calendarItem]);

  const onSuccess = () => {
    if (repeat) {
      const ind = recurringEvent.RRule.indexOf("RRULE:");
      if (isUpdating) {
        updateRecurringEventDefinition({
          clientId,
          rRule: recurringEvent.RRule.slice(ind + 6),
          recurringEventDefinitionId:
            calendarItem?.recurringEventDefinition?.id,
          endDate: recurringEvent.endDate,
        });
      } else {
        createRecurringProgressPhoto({
          clientId,
          rRule: recurringEvent.RRule.slice(ind + 6),
          startDate: recurringEvent.startDate,
          endDate: recurringEvent.endDate,
        });
      }
    } else {
      const [year, month, dayOfMonth] = format(day, "yyyy-MM-dd").split("-");
      createProgressPhotoCalendarItem({
        clientId,
        dayOfMonth: Number(dayOfMonth),
        month: Number(month),
        year: Number(year),
      });
    }
    onClose();
  };

  const repeatStyle: React.CSSProperties = {
    fontSize: "16px",
    lineHeight: "16px",
    letterSpacing: "-0.022em",
    color: colors.caliber_gray_text,
    marginLeft: 8,
    marginBottom: 24,
  };

  return (
    <div className="d-flex flex-column">
      <Header day={day} type={HeaderType.PROGRESS_PHOTO} onBackClick={onBack} />

      <div
        className="d-flex justify-content-center"
        style={{
          marginTop: 42,
          marginBottom: 24,
        }}
      >
        <CategoryButton />
      </div>

      <div className="d-flex">
        <Checkbox
          disabled={isUpdating}
          checked={repeat}
          onClick={() => setRepeat(!repeat)}
        />
        <SimpleButton
          disabled={isUpdating}
          onClick={() => setRepeat(!repeat)}
          nofocus
          className="bold"
          style={repeatStyle}
        >
          Repeat
        </SimpleButton>
      </div>

      {repeat && (
        <RecurringMenu
          day={day}
          recurringEvent={recurringEvent}
          onChange={(recurringEvent) => setRecurringEvent(recurringEvent)}
        />
      )}

      <Footer
        successText={calendarItem ? "Update activity" : "Add activity"}
        onSuccess={onSuccess}
        onCancel={() => {
          if (calendarItem) {
            onClose();
          } else {
            onBack();
          }
        }}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  createProgressPhotoCalendarItem: (
    args: MutationCreateProgressPhotoCalendarItemArgs
  ) => {
    dispatch(createProgressPhotoCalendarItem(args));
  },
  createRecurringProgressPhoto: (
    args: MutationCreateProgressPhotoRecurringEventDefinitionArgs
  ) => {
    dispatch(createProgressPhotoRecurringEventDefinition(args));
  },
  updateRecurringEventDefinition: (
    args: MutationUpdateRecurringEventDefinitionArgs
  ) => {
    dispatch(updateRecurringEventDefinition(args));
  },
});

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  onClose: ownProps.onClose,
  day: ownProps.day,
  calendarItem: ownProps.calendarItem,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgressPhotoEvent);
