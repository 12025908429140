import React from "react";
import { connect } from "react-redux";
import {
  changeCoachProfileState,
  createUpdateCoachProfile,
  resetCoachProfileState,
} from "../../redux/actions/CoachProfile";
import { CoachProfileState } from "../../redux/actions/CoachProfile/types";
import { StoreState } from "../../redux/reducers";
import {
  CoachProfile,
  MutationCreateUpdateCoachProfileArgs,
} from "../../types/gqlTypes";
import Bio from "./Bio";
import FunFacts from "./FunFacts";
import Header from "./Header";
import Images from "./Images";
import TraitSection, { TraitType } from "./TraitsSection";

interface OwnProps {
  editable?: boolean;
  trainerId: string;
  onClose: () => void;
}
interface Props extends OwnProps {
  coachProfileState: CoachProfileState;
  onClose: () => void;
  changeCoachProfileState: (coachProfile: CoachProfile) => void;
  createUpdateCoachProfile: (
    args: MutationCreateUpdateCoachProfileArgs
  ) => void;
  resetCoachProfileState: () => void;
}

const CoachProfileComponent = (props: Props) => {
  const {
    coachProfileState,
    editable,
    trainerId,
    onClose,
    changeCoachProfileState,
    createUpdateCoachProfile,
    resetCoachProfileState,
  } = props;
  const splitter = "~~~";

  return (
    <div
      className="d-flex flex-column"
      style={{
        width: 464,
        padding: "0px 24px 24px 12px",
      }}
    >
      <Header
        onSave={() => {
          createUpdateCoachProfile({
            trainerId,
            bio: coachProfileState.coachProfile?.bio || "",
            certifications:
              coachProfileState.coachProfile?.certifications || "",
            education: coachProfileState.coachProfile?.education || "",
            funFacts: coachProfileState.coachProfile?.funFacts || "",
            photos: coachProfileState.coachProfile.photos
              .map((photo) => photo?.mediaUrl?.id)
              .filter(Boolean),
          });
          onClose();
          resetCoachProfileState();
        }}
        onCancel={() => {
          onClose();
          resetCoachProfileState();
        }}
      />

      <Bio
        text={coachProfileState?.coachProfile?.bio}
        onChange={(value) => {
          changeCoachProfileState({
            ...coachProfileState.coachProfile,
            bio: value,
          });
        }}
      />

      <TraitSection
        traitType={TraitType.CERTIFICATION}
        title="Certifications"
        items={
          coachProfileState?.coachProfile?.certifications
            ?.split(splitter)
            .filter(Boolean) || []
        }
        onChange={(items) => {
          changeCoachProfileState({
            ...coachProfileState.coachProfile,
            certifications: items.join(splitter),
          });
        }}
      />

      <TraitSection
        traitType={TraitType.EDUCATION}
        title="Education"
        items={
          coachProfileState?.coachProfile?.education
            ?.split(splitter)
            .filter(Boolean) || []
        }
        onChange={(items) => {
          changeCoachProfileState({
            ...coachProfileState.coachProfile,
            education: items.join(splitter),
          });
        }}
      />

      <Images
        editable={editable}
        photos={coachProfileState?.coachProfile?.photos || []}
        onChange={(photos) => {
          changeCoachProfileState({
            ...coachProfileState.coachProfile,
            photos,
          });
        }}
      />

      <FunFacts
        text={coachProfileState?.coachProfile?.funFacts}
        onChange={(value) => {
          changeCoachProfileState({
            ...coachProfileState.coachProfile,
            funFacts: value,
          });
        }}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  changeCoachProfileState: (coachProfile: CoachProfile) => {
    dispatch(changeCoachProfileState(coachProfile));
  },
  createUpdateCoachProfile: (args: MutationCreateUpdateCoachProfileArgs) => {
    dispatch(createUpdateCoachProfile(args));
  },
  resetCoachProfileState: () => {
    dispatch(resetCoachProfileState());
  },
});

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  coachProfileState: state.coachProfileState,

  editable: ownProps.editable,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoachProfileComponent);
