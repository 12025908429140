import React, { useEffect, useRef, useState } from "react";
import colors from "../../assets/colors";
import { getIsVideoUploadingByChannelId } from "../../redux/actions/Messages";
import { User } from "../../types/gqlTypes";
import CloseIcon from "../Icons/CloseIcons";
import VideoMessageUploadIcon from "../Icons/VideoMessageUploadIcon";
import ProfilePhoto from "../ProfilePhoto";
import SimpleButton from "../SimpleButton";

interface Props {
  channelId: string;
  channelsVideoUploadProgress: { [key: string]: number };
  trainer: User;
  isChatWindow?: boolean;
  isFake?: boolean;
  removeError: () => void;
}

const VideoMessageUploadItem = (props: Props) => {
  const {
    channelId,
    channelsVideoUploadProgress,
    trainer,
    isChatWindow,
    isFake,
    removeError,
  } = props;

  const isVideoUploading =
    channelsVideoUploadProgress?.[channelId] >= 0 || isFake;
  const didVideoUploadFail = channelsVideoUploadProgress?.[channelId] === -1;
  const wasVideoUploading = getIsVideoUploadingByChannelId(channelId);
  const progress = channelsVideoUploadProgress?.[channelId];
  const isError =
    didVideoUploadFail || (wasVideoUploading && typeof progress !== "number");

  const [localProgress, setLocalProgress] = useState(null);
  const messageEnd = useRef(null);

  const barWidth = 150;

  useEffect(() => {
    if (isFake && localProgress < 100) {
      setTimeout(() => {
        if (typeof localProgress !== "number") {
          messageEnd.current?.scrollIntoView();
        }
        setLocalProgress(Math.min((localProgress || 0) + 20, 100));
      }, 300);
    } else if (isFake && localProgress >= 100) {
      removeError();
    }
  }, [isFake, localProgress]);

  useEffect(() => {
    if (isVideoUploading && !isFake) {
      setTimeout(() => {
        messageEnd.current?.scrollIntoView();
      }, 300);
    }
  }, [isVideoUploading]);

  if (!isVideoUploading && !isError) {
    return <div />;
  }

  const showProgressBar =
    !isError &&
    (typeof progress === "number" || typeof localProgress === "number");
  const actualProgress = isFake ? localProgress : progress;

  return (
    <div
      className="d-flex flex-column"
      style={{
        padding: "0px 24px",
        marginTop: 24,
      }}
    >
      <div
        className="d-flex align-items-center"
        style={{
          marginBottom: 7,
          position: "relative",
        }}
      >
        <div
          style={{
            marginRight: 12,
          }}
        >
          <ProfilePhoto
            imageUrl={trainer?.profileIconMediaUrl?.url}
            size={48}
            bgColor={
              isChatWindow
                ? colors.caliber_white
                : colors.caliber_secondary_gray_5
            }
          />
        </div>
        <div
          className="medium-bold"
          style={{
            marginRight: 14,
            fontSize: "12px",
            lineHeight: "24px",
            color: colors.caliber_secondary,
          }}
        >
          {`${trainer?.firstName} ${trainer?.lastName}`}
        </div>
      </div>
      <div
        className="d-flex flex-column"
        style={{
          width: 360,
          height: 200,
          borderRadius: 8,
          backgroundColor: "#0C113A",
          position: "relative",
        }}
      >
        <div
          className="d-flex align-items-end justify-content-center"
          style={{
            width: 360,
            height: 140,
          }}
        >
          <VideoMessageUploadIcon error={isError} />
          {isError && (
            <SimpleButton
              onClick={removeError}
              style={{
                position: "absolute",
                top: 10,
                right: 10,
              }}
            >
              <CloseIcon />
            </SimpleButton>
          )}
        </div>

        <div
          className="d-flex justify-content-center"
          style={{
            width: 360,
            height: 60,
            paddingTop: 10,
          }}
        >
          {isError && (
            <div
              style={{
                fontSize: "16px",
                lineHeight: "24px",
                letterSpacing: "-0.022em",
                color: colors.caliber_secondary_gray_29,
                textShadow: "0px 2px 32px #01052F",
              }}
            >
              Video failed to upload
            </div>
          )}
          {showProgressBar && (
            <div
              style={{
                position: "relative",
                width: barWidth,
              }}
            >
              <div
                style={{
                  position: "absolute",
                  width: barWidth,
                  height: 6,
                  top: 0,
                  left: 0,
                  backgroundColor: "#272E5F",
                  borderRadius: 6,
                }}
              />
              <div
                style={{
                  position: "absolute",
                  width: (actualProgress * barWidth) / 100,
                  height: 6,
                  top: 0,
                  left: 0,
                  backgroundColor: "#ffffff",
                  borderRadius: 6,
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div ref={messageEnd} />
    </div>
  );
};

export default VideoMessageUploadItem;
