import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

interface Props {
  children?: any;
  popupkey: string;
  trigger: any;
  triggerEvents?: ("focus" | "hover")[];
  placement:
    | "auto-start"
    | "auto"
    | "auto-end"
    | "top-start"
    | "top"
    | "top-end"
    | "right-start"
    | "right"
    | "right-end"
    | "bottom-end"
    | "bottom"
    | "bottom-start"
    | "left-end"
    | "left"
    | "left-start";
}

const Popup = (props: Props) => {
  const { children, popupkey, trigger, placement, triggerEvents } = props;
  const overlay = (
    <Popover
      style={{
        padding: 0,
      }}
      id="popover-basic"
    >
      <Popover.Body>{children}</Popover.Body>
    </Popover>
  );
  return (
    <OverlayTrigger
      key={popupkey}
      trigger={triggerEvents || "focus"}
      placement={placement}
      overlay={overlay}
    >
      {trigger}
    </OverlayTrigger>
  );
};

export default Popup;
