import apolloClient from "../../../api/apolloClient";
import {
  UPDATE_COACH_PROFILE_ICON_FAIL,
  UPDATE_COACH_PROFILE_ICON_SAVING as UPDATE_COACH_PROFILE_ICON_SAVING,
  UPDATE_COACH_PROFILE_ICON_SUCCESS,
} from "./types";
import { UPDATE_COACH_PROFILE_PIC_GQL } from "../../../api/gql/users/trainers";
import {
  MutationUpdateCoachProfileIconUrlArgs,
  UserType,
} from "../../../types/gqlTypes";
import { UPDATE_TRAINER_PROFILE_ICON } from "../AdminTeamTrainers/types";
import { UPDATE_MANAGER_PROFILE_ICON } from "../AdminTeamManagers/types";

export const updateCoachProfileIcon =
  (args: MutationUpdateCoachProfileIconUrlArgs) =>
  async (dispatch, getState) => {
    if (getState().updateCoachProfileIconState.isSaving) {
      return;
    }
    dispatch({
      type: UPDATE_COACH_PROFILE_ICON_SAVING,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const result = (
        await client.mutate({
          mutation: UPDATE_COACH_PROFILE_PIC_GQL,
          variables: args,
        })
      ).data.updateCoachProfileIconUrl;

      if (result.type === UserType.Trainer) {
        dispatch({
          type: UPDATE_TRAINER_PROFILE_ICON,
          id: result.id,
          profileIconMediaUrl: result.profileIconMediaUrl,
        });
      } else if (result.type === UserType.Manager) {
        dispatch({
          type: UPDATE_MANAGER_PROFILE_ICON,
          id: result.id,
          profileIconMediaUrl: result.profileIconMediaUrl,
        });
      }

      dispatch({
        type: UPDATE_COACH_PROFILE_ICON_SUCCESS,
      });
    } catch (error) {
      console.error("Error updateCoachProfileIcon:", error);
      dispatch({
        type: UPDATE_COACH_PROFILE_ICON_FAIL,
        data: error,
      });
    }
  };
