import _ from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";
import {
  FileMessage,
  GroupChannel,
  PreviousMessageListQuery,
  UserMessage,
} from "sendbird";
import colors from "../../assets/colors";
import { MasqueradeState } from "../../redux/actions/Masquerade/types";
import {
  addReaction,
  deactivateChannel,
  deleteMessage,
  getPrevMessageList,
  removeVideoUploadFail,
  sendFileMessage,
  sendMessage,
  sendVideoMessage,
  setActiveChannel,
} from "../../redux/actions/Messages";
import { getTrainer, StoreState } from "../../redux/reducers";
import { User, UserType } from "../../types/gqlTypes";
import { dateHeaderText } from "../../utils";
import Loader from "../Loader";
import MessageItem from "../MessageItem";
import VideoMessageUploadItem from "../MessageItem/VideoMessageUploadItem";
import MessagesInputField from "../MessagesInputField";

interface OwnProps {
  isChatWindow?: boolean;
  channel: GroupChannel;
  clientId: string;
  isClientPremium: boolean;
}

interface Props extends OwnProps {
  messageList: Map<string, any[]>;
  activeChannel: GroupChannel;
  messageListQueries: { [url: string]: PreviousMessageListQuery };
  user: User;
  isLoading: boolean;
  channelsVideoUploadProgress: { [key: string]: number };
  masqueradeState: MasqueradeState;
  isNotFirstRenderChat?: boolean;
  isMediaUploading?: boolean;
  isMediaFakeUploading?: boolean;
  trainer: User;
  setActiveChannel: (trainerId: string, clientId: string) => void;
  deactivateChannel: () => void;
  fetchMessages: () => void;
  sendMessage: (message: string) => void;
  addReaction: (message: UserMessage | FileMessage, emojiKey: string) => void;
  sendVideoMessage: (file: string) => void;
  sendFile: (file: any) => void;
  deleteMessage: (message: UserMessage | FileMessage) => void;
  onFirstRender?: () => void;
  removeVideoUploadFail: (channelId: string) => void;
}

const MessagesChatPane = (props: Props) => {
  const {
    isChatWindow,
    channel,
    clientId,
    messageList,
    activeChannel,
    messageListQueries,
    user,
    isLoading,
    channelsVideoUploadProgress,
    masqueradeState,
    isNotFirstRenderChat,
    isMediaUploading,
    isMediaFakeUploading,
    trainer,
    isClientPremium,
    onFirstRender,
    removeVideoUploadFail,
    setActiveChannel,
    deactivateChannel,
    fetchMessages,
    sendMessage,
    addReaction,
    sendVideoMessage,
    sendFile,
    deleteMessage,
  } = props;

  const messageEnd = useRef(null);
  const [trainerId, setTrainerId] = useState(null);
  const [draggingOver, setDraggingOver] = useState(false);

  const textArea: any = document.getElementById("message-textarea");

  const isMasquerade = !!masqueradeState?.masqueradeTrainer;
  const isAdminOrManager =
    user?.type === UserType.Admin || user?.type === UserType.Manager;
  const isTrainer = user?.type === UserType.Trainer;

  const dragOver = (e) => {
    if (!isMasquerade && !isAdminOrManager) {
      e.preventDefault();
    }
  };

  const dragEnter = (e) => {
    if (!isMasquerade && !isAdminOrManager) {
      e.preventDefault();
      setDraggingOver(true);
    }
  };

  const dragLeave = (e) => {
    if (!isMasquerade && !isAdminOrManager) {
      e.preventDefault();
      setDraggingOver(false);
    }
  };

  const fileDrop = (event) => {
    if (!isMasquerade && !isAdminOrManager) {
      event.preventDefault();
      const { files } = event?.dataTransfer || { files: {} };
      Object.values(files)?.forEach((file: any) => {
        if (file?.type?.startsWith("image") || file?.type?.includes("pdf")) {
          sendFile(file);
        } else if (file?.type?.startsWith("video")) {
          sendVideoMessage(file);
        } else {
          // TODO: Display error
          console.log("Invalid filetype", file.type);
        }
      });
      setDraggingOver(false);
    }
  };

  useEffect(() => {
    setTrainerId(trainer?.id);
    setActiveChannel(trainer?.id, clientId);

    return () => {
      deactivateChannel();
    };
  }, []);

  useEffect(() => {
    if (
      !isNotFirstRenderChat &&
      !isLoading &&
      activeChannel &&
      activeChannel.members.find((member) => member.userId === clientId)
    ) {
      fetchMessages();
      onFirstRender?.();
    }
  }, [activeChannel]);

  const messageItems = useMemo(() => {
    const messages = (
      messageList.get(channel?.url) == null ? [] : messageList.get(channel?.url)
    ).sort((a, b) => a.createdAt - b.createdAt);

    const groupedItems = _.groupBy(messages, (item) => {
      return dateHeaderText(new Date(item.createdAt));
    });

    return _.keys(groupedItems).map((value, index) => {
      return {
        title: value,
        data: groupedItems[value],
      };
    });
  }, [messageList, channel]);

  useEffect(() => {
    setTimeout(() => {
      messageEnd.current?.scrollIntoView();
    }, 0);
  }, [messageItems?.[messageItems?.length - 1]?.data?.length]);

  const isMasquerading = masqueradeState.masqueradeTrainer != null;

  const dragFilesOverStyle: React.CSSProperties = {
    position: "absolute",
    bottom: 0,
    left: 0,
    border: "4px dashed black",
    opacity: 0.8,
    backgroundColor: "#505050",
    color: "white",
    fontSize: "25px",
    width: "100%",
    height: "100%",
    zIndex: 999,
  };
  const dragFilesOverClassname =
    "d-flex justify-content-center align-items-center";

  return (
    <div
      onDragOver={dragOver}
      onDragEnter={dragEnter}
      className="d-flex flex-column"
      style={{
        flex: 1,
        minHeight: 0,
        minWidth: 490,
        position: "relative",
        justifyContent: "flex-end",
        backgroundColor: colors.caliber_secondary_gray_5,
        marginLeft: isChatWindow ? 0 : 6,
      }}
    >
      {isLoading && (
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{
            flex: 1,
          }}
        >
          <Loader />
        </div>
      )}
      {draggingOver && (
        <div
          onDragOver={dragOver}
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
          onDrop={fileDrop}
          className={dragFilesOverClassname}
          style={dragFilesOverStyle}
        >
          Drag Files Here
        </div>
      )}
      <div
        className="d-flex flex-column flex-grow-1"
        style={{
          minHeight: 0,
          position: "relative",
          overflow: "auto",
          zIndex: 998,
          paddingBottom: textArea?.style?.offsetHeight || 10,
        }}
      >
        <InfiniteScroll
          pageStart={0}
          initialLoad={false}
          isReverse
          hasMore={
            !isLoading && messageListQueries?.[activeChannel?.url]?.hasMore
          }
          useWindow={false}
          loadMore={() => {
            if (
              activeChannel &&
              activeChannel.members.find((member) => member.userId === clientId)
            ) {
              fetchMessages();
            }
          }}
          className="mt-auto"
        >
          {/* Leave extra space for emoji picker */}
          <div style={{ minHeight: 300 }} />
          {messageItems.map((item, msgGroupIndex) => (
            <div
              key={`${item?.title}-${item?.data?.[0]?.messageId}`}
              className="d-flex flex-column"
            >
              <div className="d-flex justify-content-center align-items-center">
                <div
                  className="d-flex justify-content-center align-items-center medium-bold"
                  style={{
                    padding: "4px 16px",
                    backgroundColor: colors.caliber_secondary_gray_5,
                    zIndex: 1,
                    color: "#615375",
                    borderRadius: 24,
                    minWidth: 140,
                    height: 32,
                    marginTop: 15,
                  }}
                >
                  {item.title}
                </div>
              </div>
              {item.data.map(
                (msg: UserMessage | FileMessage, index: number) => {
                  const isPrevSenderSame =
                    index === 0 ||
                    item?.data?.[index - 1]?.sender?.userId !==
                      msg?.sender?.userId;
                  const isLink =
                    !isChatWindow && msg.sender.userId !== trainerId;
                  const isLastMessage =
                    msgGroupIndex === messageItems.length - 1 &&
                    index === item.data.length - 1;
                  return (
                    <MessageItem
                      isChatWindow={isChatWindow}
                      isLastMessage={isLastMessage}
                      isLink={isLink}
                      isClient={clientId === msg.sender.userId}
                      isMasquerading={isMasquerading || isAdminOrManager}
                      key={`msg_item_${msg.messageId}`}
                      showProfilePic={isPrevSenderSame}
                      message={msg}
                      isClientPremium={isClientPremium}
                      isDeletable={msg.sender.userId === user.id}
                      addReaction={addReaction}
                      onDelete={() => {
                        deleteMessage(msg);
                      }}
                    />
                  );
                }
              )}
            </div>
          ))}
          <VideoMessageUploadItem
            isFake={isMediaFakeUploading}
            channelsVideoUploadProgress={channelsVideoUploadProgress}
            channelId={activeChannel?.url}
            trainer={trainer}
            removeError={() => removeVideoUploadFail(activeChannel.url)}
            isChatWindow={isChatWindow}
          />
          <div ref={messageEnd} />
        </InfiniteScroll>
      </div>
      {isTrainer && (
        <div
          className="d-flex"
          style={{
            justifySelf: "flex-end",
            flexGrow: 0,
          }}
        >
          <MessagesInputField
            isMediaUploading={isMediaUploading || isMediaFakeUploading}
            activeChannel={activeChannel}
            isChatWindow={isChatWindow}
            sendTextMessage={(text) => {
              sendMessage(text);
            }}
            sendFileMessage={(file) => {
              sendFile(file);
            }}
            sendVideoMessage={(file) => {
              sendVideoMessage(file);
              setTimeout(messageEnd.current?.scrollIntoView(), 500);
            }}
          />
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setActiveChannel: (clientId: string, trainerId: string) => {
    dispatch(setActiveChannel(clientId, trainerId));
  },
  deactivateChannel: () => {
    dispatch(deactivateChannel());
  },
  removeVideoUploadFail: (channelId: string) => {
    dispatch(removeVideoUploadFail(channelId));
  },
  fetchMessages: () => {
    dispatch(getPrevMessageList());
  },
  sendMessage: (message: string) => {
    dispatch(sendMessage(message));
  },
  addReaction: (message: UserMessage | FileMessage, emojiKey: string) => {
    dispatch(addReaction(message, emojiKey));
  },
  sendVideoMessage: (file: string) => {
    dispatch(sendVideoMessage(file));
  },
  sendFile: (file: string) => {
    dispatch(sendFileMessage(file));
  },
  deleteMessage: (message: UserMessage | FileMessage) => {
    dispatch(deleteMessage(message));
  },
});

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  messageList: state.messagesState.messageListByClient,
  isLoading: state.messagesState.isLoading,
  activeChannel: state.messagesState.activeChannel,
  messageListQueries: state.messagesState.messageListQueries,
  channelsVideoUploadProgress: state.messagesState.channelsVideoUploadProgress,
  isMediaUploading: state.messagesState.isMediaUploading,
  isMediaFakeUploading: state.messagesState.isMediaFakeUploading,
  user: state.authenticatedUserState.user,
  masqueradeState: state.masqueradeState,
  channel: ownProps.channel,
  clientId: ownProps.clientId,
  trainer: getTrainer(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(MessagesChatPane);
