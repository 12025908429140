import React, { useState } from "react";
import { isAfter, parse, format, subDays, isSameDay } from "date-fns";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { StoreState } from "../../../redux/reducers";
import colors from "../../../assets/colors";
import {
  CalendarItem,
  MutationDeleteRecurringEventArgs,
  MutationDeleteRecurringEventDefinitionArgs,
  MutationUpdateRecurringEventDefinitionArgs,
} from "../../../types/gqlTypes";
import CloseIcon from "../../Icons/CloseIcons";
import RadioButton from "../../Icons/RadioButton";
import Modal from "../../Modal";
import SimpleButton from "../../SimpleButton";
import {
  deleteRecurringEvent,
  deleteRecurringEventDefinition,
  updateRecurringEventDefinition,
} from "../../../redux/actions/Calendar";

interface OwnProps {
  onClose: () => void;
  calendarItem: CalendarItem;
  day: Date;
}
interface Props extends OwnProps {
  deleteRecurringEventDefinition: (
    args: MutationDeleteRecurringEventDefinitionArgs
  ) => void;
  deleteRecurringEvent: (args: MutationDeleteRecurringEventArgs) => void;
  updateRecurringEventDefinition: (
    args: MutationUpdateRecurringEventDefinitionArgs
  ) => void;
}

const DeleteRecurringEvent = (props: Props) => {
  const {
    onClose,
    calendarItem,
    day,
    deleteRecurringEvent,
    deleteRecurringEventDefinition,
    updateRecurringEventDefinition,
  } = props;
  const { id: clientId } = useParams();
  const recurrenceEndDate = parse(
    calendarItem?.recurringEventDefinition?.endDate,
    "yyyy-MM-dd",
    new Date()
  );
  const isOutOfRecurrenceDateRange =
    isAfter(day, recurrenceEndDate) || isSameDay(day, recurrenceEndDate);

  const [selected, setSelected] = useState(0);

  const textStyle: React.CSSProperties = {
    fontSize: "16px",
    lineHeight: "24px",
    color: colors.caliber_secondary,
    marginBottom: 8,
  };
  const titleStyle: React.CSSProperties = {
    fontSize: "16px",
    lineHeight: "24px",
    color: colors.caliber_secondary,
    marginLeft: 4,
    marginRight: 4,
  };
  const optionStyle: React.CSSProperties = {
    fontSize: "14px",
    lineHeight: "24px",
    color: colors.caliber_secondary,
  };
  const boxStyle: React.CSSProperties = {
    backgroundColor: colors.caliber_white,
    width: 312,
    height: 40,
    borderRadius: 8,
    border: `1px solid ${colors.caliber_gray_bg}`,
    marginTop: 12,
  };
  const selectedBoxStyle: React.CSSProperties = {
    backgroundColor: colors.caliber_gray_bg,
    width: 312,
    height: 40,
    borderRadius: 8,
    marginTop: 12,
  };
  const closeIconStyle: React.CSSProperties = {
    position: "absolute",
    top: 20,
    right: 20,
  };
  const radioStyle: React.CSSProperties = {
    marginLeft: 10,
    marginRight: 14,
  };

  const onDelete = () => {
    if (selected === 0) {
      deleteRecurringEvent({
        clientId,
        date: format(day, "yyyy-MM-dd"),
        recurringEventDefinitionId: calendarItem.recurringEventDefinition.id,
      });
    } else if (selected === 1) {
      const date = subDays(day, 1);
      const untilDate = format(date, "yyyyMMdd");
      const index =
        calendarItem.recurringEventDefinition.RRule.indexOf("UNTIL=");
      const oldRule = calendarItem.recurringEventDefinition.RRule;
      const newRule = `${oldRule.slice(
        0,
        index + 6
      )}${untilDate}${oldRule.slice(14)}`;
      updateRecurringEventDefinition({
        clientId,
        rRule: newRule,
        recurringEventDefinitionId: calendarItem.recurringEventDefinition.id,
        endDate: format(date, "yyyy-MM-dd"),
      });
    } else if (selected === 2) {
      deleteRecurringEventDefinition({
        clientId,
        recurringEventDefinitionId: calendarItem.recurringEventDefinition.id,
      });
    }
    onClose();
  };

  return (
    <Modal closeOnDimmerClick onClose={onClose}>
      <div
        className="d-flex flex-column align-items-center justify-content-center"
        style={{
          width: 440,
          height: 350,
          position: "relative",
          paddingTop: 40,
        }}
      >
        <div style={closeIconStyle} className="d-flex justify-content-end">
          <SimpleButton onClick={onClose}>
            <CloseIcon width={20} height={20} />
          </SimpleButton>
        </div>

        <div className="d-flex justify-content-center">
          <span style={textStyle}>Are you sure want to delete</span>
          <span className="bold" style={titleStyle}>
            {calendarItem?.title || "TITLE"} ?
          </span>
        </div>

        <SimpleButton
          onClick={() => setSelected(0)}
          nofocus
          className="d-flex align-items-center"
          style={selected === 0 ? selectedBoxStyle : boxStyle}
        >
          <div style={radioStyle}>
            <RadioButton
              checked={selected === 0}
              onClick={() => setSelected(0)}
            />
          </div>
          <div>This event only</div>
        </SimpleButton>

        {!isOutOfRecurrenceDateRange && (
          <SimpleButton
            onClick={() => setSelected(1)}
            nofocus
            className="d-flex align-items-center"
            style={selected === 1 ? selectedBoxStyle : boxStyle}
          >
            <div style={radioStyle}>
              <RadioButton
                checked={selected === 1}
                onClick={() => setSelected(1)}
              />
            </div>
            <div style={optionStyle}>This event </div>
            <div className="bold" style={titleStyle}>
              AND{" "}
            </div>
            <div style={optionStyle}>following events</div>
          </SimpleButton>
        )}

        <SimpleButton
          onClick={() => setSelected(2)}
          nofocus
          className="d-flex align-items-center"
          style={selected === 2 ? selectedBoxStyle : boxStyle}
        >
          <div style={radioStyle}>
            <RadioButton
              checked={selected === 2}
              onClick={() => setSelected(2)}
            />
          </div>
          <div>All events</div>
        </SimpleButton>

        <div className="d-flex justify-content-center">
          <SimpleButton
            onClick={onClose}
            className="d-flex justify-content-center align-items-center medium-bold"
            style={{
              backgroundColor: colors.caliber_gray_text,
              color: colors.caliber_white,
              borderRadius: 4,
              width: 94,
              height: 32,
              marginRight: 12,
              marginTop: 30,
              fontSize: 14,
            }}
          >
            Cancel
          </SimpleButton>
          <SimpleButton
            onClick={onDelete}
            className="d-flex justify-content-center align-items-center medium-bold"
            style={{
              backgroundColor: colors.caliber_green_200,
              color: colors.caliber_white,
              borderRadius: 4,
              width: 94,
              height: 32,
              marginRight: 12,
              marginTop: 30,
              fontSize: 14,
            }}
          >
            Delete
          </SimpleButton>
        </div>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  deleteRecurringEventDefinition: (
    args: MutationDeleteRecurringEventDefinitionArgs
  ) => {
    dispatch(deleteRecurringEventDefinition(args));
  },
  deleteRecurringEvent: (args: MutationDeleteRecurringEventArgs) => {
    dispatch(deleteRecurringEvent(args));
  },
  updateRecurringEventDefinition: (
    args: MutationUpdateRecurringEventDefinitionArgs
  ) => {
    dispatch(updateRecurringEventDefinition(args));
  },
});

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  onClose: ownProps.onClose,
  calendarItem: ownProps.calendarItem,
  day: ownProps.day,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteRecurringEvent);
