import React from "react";
import colors from "../../assets/colors";
import SimpleButton from "../SimpleButton";

interface Props {
  text: string;
  disabled?: boolean;
  onClick: () => void;
}

const AddTraitButton = (props: Props) => {
  const { text, disabled, onClick } = props;

  return (
    <div className="d-flex justify-content-end">
      <SimpleButton
        nofocus
        disabled={disabled}
        onClick={onClick}
        className="d-flex justify-content-center align-items-center medium-bold"
        style={{
          fontSize: "14px",
          lineHeight: "14px",
          color: disabled ? colors.caliber_gray_text : colors.caliber_secondary,
          backgroundColor: colors.caliber_secondary_gray_11,
          borderRadius: 4,
          width: 164,
          height: 32,
          marginTop: 16,
        }}
      >
        {text}
      </SimpleButton>
    </div>
  );
};

export default AddTraitButton;
