import React, { useEffect } from "react";
import { connect } from "react-redux";
import { StoreState } from "../../redux/reducers";
import { UserState } from "../../redux/actions/User/types";
import fetchUserData from "../../redux/actions/User";
import Loader from "../../components/Loader";
import { authenticateUser } from "../../redux/actions/Auth";
import { AuthState } from "../../redux/actions/Auth/types";
import { HEADER_HEIGHT } from "../../components/Header";
import BaseHomeScreen from "../BaseHomeScreen";
import {
  adminPrimaryRoutes,
  trainerPrimaryRoutes,
  managerPrimaryRoutes,
} from "../../navigation";
import { UserType } from "../../types/gqlTypes";
import { MasqueradeState } from "../../redux/actions/Masquerade/types";
import * as Sentry from "@sentry/react";

interface Props {
  masqueradeState: MasqueradeState;
  userState: UserState;
  authState: AuthState;
  fetchUserData: () => void;
  authenticate: () => void;
}

const RootContainer = (props: Props) => {
  const {
    masqueradeState,
    userState,
    authState,
    authenticate,
    //  login,
    fetchUserData,
  } = props;

  const hasCode = window.location.search.includes("code=");

  useEffect(() => {
    if (authState.isAuthenticated && !userState.isLoading) {
      fetchUserData();
    }
  }, [authState.isAuthenticated]);

  useEffect(() => {
    if (
      (!authState.isAuthStateLoaded && !authState.isAuthenticating) ||
      (!authState.isAuthenticating && hasCode)
    ) {
      authenticate();
    }
  }, [authState.isAuthStateLoaded, authState.isAuthenticating, authenticate]);

  if (
    hasCode ||
    !authState.isAuthStateLoaded ||
    (authState.isAuthenticated &&
      (!userState.doneLoading || userState.isLoading))
  ) {
    return (
      <div
        className="max-height-100 d-flex justify-content-center"
        style={{
          marginTop: HEADER_HEIGHT,
        }}
      >
        <Loader />
      </div>
    );
  }

  if (!authState.isAuthenticated && !hasCode) {
    return (
      <div
        className="max-height-100 d-flex justify-content-center"
        style={{
          marginTop: HEADER_HEIGHT,
        }}
      >
        Redirecting to log in...
      </div>
    );
  }

  if (userState?.user?.id) {
    Sentry.setUser({ id: userState?.user?.id, email: userState?.user?.email });
  }

  if (
    userState?.user?.type === UserType.Trainer ||
    masqueradeState?.masqueradeTrainer != null
  ) {
    return <BaseHomeScreen routeInfos={trainerPrimaryRoutes} />;
  }
  if (userState?.user?.type === UserType.Admin) {
    return <BaseHomeScreen routeInfos={adminPrimaryRoutes} />;
  }
  if (userState?.user?.type === UserType.Manager) {
    return <BaseHomeScreen routeInfos={managerPrimaryRoutes} />;
  }
  return <BaseHomeScreen routeInfos={[]} />;
};

const mapDispatchToProps = (dispatch) => ({
  fetchUserData: () => {
    dispatch(fetchUserData());
  },
  authenticate: () => {
    dispatch(authenticateUser());
  },
});

const mapStateToProps = (state: StoreState) => ({
  masqueradeState: state.masqueradeState,
  userState: state.authenticatedUserState,
  authState: state.authState,
});

export default connect(mapStateToProps, mapDispatchToProps)(RootContainer);
