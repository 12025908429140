import {
  AUTH_AUTHENTICATING,
  AUTH_AUTHENTICATION_FAIL,
  AUTH_AUTHENTICATION_SUCCESS,
  AUTH_LOGGED_OUT,
  AuthAction,
  AuthState,
} from "../../actions/Auth/types";

const initialState: AuthState = {
  isAuthStateLoaded: false,
  isAuthenticated: false,
  isAuthenticating: false,
  authToken: null,
  authUser: null,
};

const authState = (state = initialState, action: AuthAction): AuthState => {
  switch (action.type) {
    case AUTH_AUTHENTICATING:
      return {
        ...state,
        authToken: null,
        authUser: null,
        isAuthStateLoaded: false,
      };
    case AUTH_AUTHENTICATION_SUCCESS:
      return {
        ...state,
        authToken: action.authToken,
        authUser: action.authUser,
        isAuthenticated: action.authToken !== null,
        isAuthStateLoaded: true,
        isAuthenticating: false,
      };
    case AUTH_AUTHENTICATION_FAIL:
      return {
        ...state,
        authToken: null,
        authUser: null,
        isAuthenticating: false,
        isAuthenticated: false,
        isAuthStateLoaded: true,
      };
    case AUTH_LOGGED_OUT:
      return {
        ...state,
        authToken: null,
        authUser: null,
        isAuthenticating: false,
        isAuthenticated: false,
        isAuthStateLoaded: true,
      };
    default:
      return state;
  }
};

export default authState;
