import { CalendarDay, ClientSearchResult } from "../../../types/gqlTypes";

export const SEND_REMINDER_NOTIFICATION_SUCCESS =
  "SEND_REMINDER_NOTIFICATION_SUCCESS";
export const SEND_REMINDER_NOTIFICATION_FAIL =
  "SEND_REMINDER_NOTIFICATION_FAIL";
export const SEND_REMINDER_NOTIFICATION_LOADING =
  "SEND_REMINDER_NOTIFICATION_LOADING";

export const GET_CLIENT_NUTRITION_COLUMNS_FOR_DEBUG_SUCCESS =
  "GET_CLIENT_NUTRITION_COLUMNS_FOR_DEBUG_SUCCESS";
export const GET_CLIENT_NUTRITION_COLUMNS_FOR_DEBUG_FAIL =
  "GET_CLIENT_NUTRITION_COLUMNS_FOR_DEBUG_FAIL";
export const GET_CLIENT_NUTRITION_COLUMNS_FOR_DEBUG_LOADING =
  "GET_CLIENT_NUTRITION_COLUMNS_FOR_DEBUG_LOADING";

export const GET_CLIENT_WEEK_NUTRITION_DATA_FOR_DEBUG_SUCCESS =
  "GET_CLIENT_WEEK_NUTRITION_DATA_FOR_DEBUG_SUCCESS";
export const GET_CLIENT_WEEK_NUTRITION_DATA_FOR_DEBUG_FAIL =
  "GET_CLIENT_WEEK_NUTRITION_DATA_FOR_DEBUG_FAIL";
export const GET_CLIENT_WEEK_NUTRITION_DATA_FOR_DEBUG_LOADING =
  "GET_CLIENT_WEEK_NUTRITION_DATA_FOR_DEBUG_LOADING";

export const GET_ACL_DATA_FOR_CLIENT_FAIL = "GET_ACL_DATA_FOR_CLIENT_FAIL";
export const GET_ACL_DATA_FOR_CLIENT_LOADING =
  "GET_ACL_DATA_FOR_CLIENT_LOADING";
export const GET_ACL_DATA_FOR_CLIENT_SUCCESS =
  "GET_ACL_DATA_FOR_CLIENT_SUCCESS";

export const RESET_DEBUG_TOOLS_STATE = "RESET_DEBUG_TOOLS_STATE";

export interface DebugToolsState {
  isLoading: boolean;
  sentReminderResult: "success" | "fail";
  clientNutritionColumns: ClientSearchResult;
  nutritionCalendarData: CalendarDay[];
  aclData: string[];
}

export interface DebugToolsAction {
  type: string;
  sentReminderResult?: "success" | "fail";
  client?: ClientSearchResult;
  calendarData?: CalendarDay[];
  aclData: string[];
}
