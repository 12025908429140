import React from "react";

interface Props {
  up: boolean;
  width: number;
  height: number;
}

const Ticker = (props: Props) => {
  const { up, width, height } = props;
  return (
    <>
      {up ? (
        <svg
          style={{ display: "block" }}
          width={width}
          height={height}
          viewBox="0 0 12 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.366819 7.05987C-0.175953 7.71119 0.287204 8.70005 1.13504 8.70005H10.865C11.7128 8.70005 12.176 7.71119 11.6332 7.05986L6.76822 1.22191C6.36843 0.742159 5.63157 0.74216 5.23178 1.22191L0.366819 7.05987Z"
            fill="#1FC866"
          />
        </svg>
      ) : (
        <svg
          style={{ display: "block" }}
          width={width}
          height={height}
          viewBox="0 0 12 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.6332 1.94013C12.176 1.28881 11.7128 0.299952 10.865 0.299952L1.13504 0.299952C0.287205 0.299952 -0.175952 1.28881 0.36682 1.94014L5.23178 7.77809C5.63157 8.25784 6.36843 8.25784 6.76822 7.77809L11.6332 1.94013Z"
            fill="#FF1400"
          />
        </svg>
      )}
    </>
  );
};

export default Ticker;
