import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
  changeTrainingPlanComponentLocation,
  onChangeFilterStateTrainingPlan,
} from "../../redux/actions/TrainingPrograms";
import {
  getTrainingPlan,
  getTrainingPlans,
} from "../../redux/actions/TrainingPrograms/get";
import { getTrainerId, StoreState } from "../../redux/reducers";
import {
  selectShallowClientPlan,
  selectShallowMasterOrCustomPlan,
} from "../../redux/reducers/TrainingPrograms/selectors";
import {
  ClientTrainingPlan,
  CustomTrainingPlan,
  CustomTrainingPlanType,
  LibraryTrainingPlan,
  MasterTrainingPlan,
  QueryClientTrainingPlansArgs,
  QuerySearchCustomTrainingPlansArgs,
  QuerySearchMasterTrainingPlansArgs,
  QueryTrainingPlanArgs,
} from "../../types/gqlTypes";
import CopyTrainingPlanModal from "../CopyTrainingPlanModal";
import MasterPlansFilter from "../MasterPlansFilter";
import MenuBar from "./MenuBar";
import PlanList from "./PlanList";
import { addWorkoutToPlan } from "../../redux/actions/TrainingPrograms/add";
import TrainingProgramWorkoutList from "../TrainingProgramWorkoutList";
import { createNewClientTrainingPlan } from "../../redux/actions/TrainingPrograms/createAssign";
import AddTrainingPlanButton from "../AddTrainingPlanButton";
import PlusIconDarker from "../../assets/images/PlusIconDarker.svg";

export enum ProgramType {
  Master = "MASTER",
  Custom = "CUSTOM",
  Client = "CLIENT",
  Library = "LIBRARY",
}

export enum ComponentLocationType {
  AdminLibrary = "ADMIN_LIBRARY",
  ClientTab = "ClientTab",
}

interface OwnProps {
  componentLocation: ComponentLocationType;
}

interface Props extends OwnProps {
  plan?:
    | MasterTrainingPlan
    | ClientTrainingPlan
    | CustomTrainingPlan
    | LibraryTrainingPlan;
  clientPlan?: ClientTrainingPlan;
  clientPlans?: ClientTrainingPlan[];
  planIndex?: number;
  showPlans?: boolean;
  showFilter?: boolean;
  showWorkouts?: boolean;
  showExercises?: boolean;
  programType?: ProgramType;
  customTrainingPlanType?: CustomTrainingPlanType;
  filterState?: QuerySearchMasterTrainingPlansArgs;
  trainerId: string;
  selectedWorkoutGroupIndex: number;

  onChangeFilterState?: (newState: QuerySearchMasterTrainingPlansArgs) => void;
  getTrainingPlan: (
    args: QueryTrainingPlanArgs,
    forceRefresh?: boolean,
    programType?: ProgramType
  ) => void;
  getTrainingPlans: (
    args:
      | QuerySearchCustomTrainingPlansArgs
      | QuerySearchMasterTrainingPlansArgs
      | QueryClientTrainingPlansArgs,
    programType?: ProgramType
  ) => void;
  changeComponentLocation: (componentLocation: ComponentLocationType) => void;
  addTrainingPlan: (clientId: string) => void;
}

const TrainingProgram = (props: Props) => {
  const {
    componentLocation,
    plan,
    clientPlan,
    clientPlans,
    planIndex,
    showPlans,
    showFilter,
    showWorkouts,
    showExercises,
    programType,
    customTrainingPlanType,
    filterState,
    trainerId,

    onChangeFilterState,
    getTrainingPlan,
    getTrainingPlans,
    changeComponentLocation,
    addTrainingPlan,
  } = props;

  const { id: clientId } = useParams();

  useEffect(() => {
    changeComponentLocation(componentLocation);
  }, [componentLocation]);

  // Only used to update search results after filter is activated
  useMemo(() => {
    if (programType && programType !== ProgramType.Client) {
      getTrainingPlans({
        page: 0,
        ...(programType === ProgramType.Master && { ...filterState }),
        ...(programType === ProgramType.Custom && { trainerId }),
        ...(programType === ProgramType.Custom && {
          type: customTrainingPlanType,
        }),
      });
    }
  }, [filterState]);

  useEffect(() => {
    if (programType === ProgramType.Client && clientPlan?.id) {
      getTrainingPlan({ trainingPlanId: clientPlan?.id }, null, programType);
    } else if (programType && plan?.id) {
      getTrainingPlan({ trainingPlanId: plan?.id }, null, programType);
    }
  }, [planIndex, programType, plan, clientPlan]);

  const showGroups =
    componentLocation === ComponentLocationType.ClientTab &&
    programType === ProgramType.Client;

  const showEmptyState =
    componentLocation === ComponentLocationType.ClientTab &&
    clientPlans?.length === 0;

  return (
    <div
      className="d-flex"
      style={{
        width: "100%",
        marginLeft: 24,
      }}
    >
      {showEmptyState ? (
        <div
          className="d-flex flex-column"
          style={{
            flex: 1,
          }}
        >
          <div
            className="d-flex"
            style={{
              justifyContent: "center",
              marginTop: 100,
              marginBottom: 76,
            }}
          >
            <div
              className="d-flex"
              style={{
                width: 130,
                height: 130,
                backgroundColor: "#E8EAEE",
                borderRadius: 65,
              }}
            />
          </div>
          <div
            className="d-flex flex-column"
            style={{
              alignItems: "center",
              marginTop: 24,
              marginBottom: 12,
            }}
          >
            <AddTrainingPlanButton
              icon={<img alt="Add Training Plan" src={PlusIconDarker} />}
              title="Add training plan"
              width={167}
              onClick={() => {
                addTrainingPlan(clientId);
              }}
            />
          </div>
        </div>
      ) : (
        <div
          className="d-flex flex-column"
          style={{
            flex: 1,
          }}
        >
          <CopyTrainingPlanModal />

          <MenuBar />

          <div
            className="d-flex"
            style={{
              flex: 1,
            }}
          >
            {showPlans && (
              <div
                className="d-flex"
                style={{
                  flex: 1,
                  marginRight: 12,
                }}
              >
                <PlanList />
              </div>
            )}

            {showFilter && (
              <div
                style={{
                  flex: 1,
                }}
              >
                <MasterPlansFilter
                  filterState={filterState}
                  onChangeFilterState={onChangeFilterState}
                />
              </div>
            )}

            {(showWorkouts || showExercises) && (
              <div
                className="d-flex"
                style={{
                  flex: 1,
                }}
              >
                <TrainingProgramWorkoutList showGroups={showGroups} />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  changeComponentLocation: (componentLocation: ComponentLocationType) => {
    dispatch(changeTrainingPlanComponentLocation(componentLocation));
  },
  getTrainingPlan: (
    args: QueryTrainingPlanArgs,
    forceRefresh?: boolean,
    programType?: ProgramType
  ) => {
    dispatch(getTrainingPlan(args, null, programType));
  },
  onChangeFilterState: (newState: QuerySearchMasterTrainingPlansArgs) => {
    dispatch(onChangeFilterStateTrainingPlan(newState));
  },
  getTrainingPlans: (
    args:
      | QuerySearchCustomTrainingPlansArgs
      | QuerySearchMasterTrainingPlansArgs
      | QueryClientTrainingPlansArgs,
    programType?: ProgramType
  ) => {
    dispatch(getTrainingPlans(args, programType));
  },
  addNewWorkout: (selectedWorkoutGroupIndex: number) => {
    dispatch(addWorkoutToPlan(selectedWorkoutGroupIndex));
  },
  addTrainingPlan: (clientId: string) => {
    dispatch(createNewClientTrainingPlan(clientId, ""));
  },
});

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  componentLocation: ownProps.componentLocation,
  plan: selectShallowMasterOrCustomPlan(state),
  clientPlan: selectShallowClientPlan(state),
  clientPlans: state.trainingProgramsState.clientPlans,
  planIndex: state.trainingProgramsState.planIndex,
  showPlans: state.trainingProgramsState.showPlans,
  showFilter: state.trainingProgramsState.showFilter,
  programType: state.trainingProgramsState.programType,
  customTrainingPlanType: state.trainingProgramsState.customTrainingPlanType,
  showWorkouts: state.trainingProgramsState.showWorkouts,
  showExercises: state.trainingProgramsState.showExercises,
  filterState: state.trainingProgramsState.filterState,
  trainerId: getTrainerId(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(TrainingProgram);
