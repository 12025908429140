import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { GroupChannel, UserMessage } from "sendbird";
import {
  subscribeUnreadAsTrainer,
  unsubscribeUnreadAsTrainer,
} from "../../redux/actions/Masquerade";
import { MasqueradeState } from "../../redux/actions/Masquerade/types";
import {
  connectToSendbird,
  deactivateChannel,
  registerChannelHandler,
} from "../../redux/actions/Messages";
import { MessagesState } from "../../redux/actions/Messages/types";
import { UserState } from "../../redux/actions/User/types";
import { getIsClientPremium, StoreState } from "../../redux/reducers";
import { Client, User, UserType } from "../../types/gqlTypes";
import CollapsedChatWindow from "./CollapsedChatWindow";
import FullviewChatWindow from "./FullviewChatWindow";

interface OwnProps {}
interface Props extends OwnProps {
  masqueradeState: MasqueradeState;
  messagesState: MessagesState;
  userState: UserState;
  client: User;
  isPremium: boolean;
  connectToChat: () => void;
  registerChannel: (trainerId: string, clientId: string) => void;
  deactivateChannel: () => void;
  subscribeUnreadAsTrainer: (trainerId: string) => void;
  unsubscribeUnreadAsTrainer: () => void;
}

const ChatWindow = (props: Props) => {
  const {
    masqueradeState,
    messagesState,
    userState,
    client,
    isPremium,
    connectToChat,
    registerChannel,
    deactivateChannel,
    subscribeUnreadAsTrainer,
    unsubscribeUnreadAsTrainer,
  } = props;
  const { id: clientId } = useParams();

  const isMasquerade = !!masqueradeState?.masqueradeTrainer;
  const trainerId = (client as Client)?.assignedTrainer?.id;
  const isAdminOrManager =
    userState?.user?.type === UserType.Admin ||
    userState?.user?.type === UserType.Manager;

  useEffect(() => {
    if (isAdminOrManager && !isMasquerade && trainerId && clientId) {
      connectToChat();
      registerChannel(trainerId, clientId);
      subscribeUnreadAsTrainer(trainerId);
    }
    return () => {
      if (isAdminOrManager && !isMasquerade && trainerId && clientId) {
        deactivateChannel();
        unsubscribeUnreadAsTrainer();
      }
    };
  }, [clientId, trainerId, isAdminOrManager, isMasquerade]);

  const [fullView, setFullView] = useState(false);

  const clientChannel: GroupChannel = messagesState.availableChannels.find(
    (channel) => {
      return channel.members.some((member) => member.userId === clientId);
    }
  );
  const clientObject = clientChannel?.members?.find((member) => {
    const metaData = member.metaData as any;
    return metaData.role === "CLIENT";
  });

  const userMessage =
    clientChannel?.lastMessage &&
    clientChannel?.lastMessage?.messageType === "user"
      ? (clientChannel?.lastMessage as UserMessage)
      : null;
  const lastMessageSnippet = userMessage
    ? userMessage.message.replace(/\n/g, " ")
    : "";

  return (
    <div>
      {fullView ? (
        <FullviewChatWindow
          onClose={() => setFullView(false)}
          client={clientObject}
          clientChannel={clientChannel}
          isPremium={isPremium}
        />
      ) : (
        <CollapsedChatWindow
          onOpen={() => setFullView(true)}
          lastMessageSnippet={lastMessageSnippet}
          clientChannel={clientChannel}
          client={clientObject}
          isPremium={isPremium}
          isLoading={messagesState.isLoading}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  connectToChat: () => {
    dispatch(connectToSendbird());
  },
  registerChannel: (trainerId: string, clientId: string) => {
    dispatch(registerChannelHandler(trainerId, clientId));
  },
  deactivateChannel: () => {
    dispatch(deactivateChannel());
  },
  subscribeUnreadAsTrainer: (trainerId: string) => {
    dispatch(subscribeUnreadAsTrainer(trainerId));
  },
  unsubscribeUnreadAsTrainer: () => {
    dispatch(unsubscribeUnreadAsTrainer());
  },
});

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  masqueradeState: state.masqueradeState,
  messagesState: state.messagesState,
  unreadMessageCount: state.unreadMessageCountState.unreadMessageCount,
  userState: state.authenticatedUserState,
  client: state.clientDetailState.user,
  isPremium: getIsClientPremium(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatWindow);
