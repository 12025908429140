import { formatDistanceToNow } from "date-fns";
import React from "react";
import { AdminMessage, FileMessage, User, UserMessage } from "sendbird";
import colors from "../../assets/colors";
import ProfilePhoto from "../ProfilePhoto";

interface Props {
  userInfo: User;
  lastMessage?: UserMessage | FileMessage | AdminMessage;
  unreadCount: number;
  isSelected: boolean;
  isClientPremium?: boolean;
}

const MessageListPaneItem = (props: Props) => {
  const { userInfo, lastMessage, isSelected, unreadCount, isClientPremium } =
    props;
  const profilePic = userInfo.profileUrl;

  const userMessage =
    lastMessage && lastMessage.messageType === "user"
      ? (lastMessage as UserMessage)
      : null;

  const lastMessageSnippet = userMessage
    ? userMessage.message.replace(/\n/g, " ")
    : "";
  const lastMessageDate = lastMessage
    ? formatDistanceToNow(new Date(lastMessage.createdAt))
    : null;

  return (
    <div
      className="d-flex"
      style={{
        backgroundColor: isSelected
          ? colors.caliber_secondary_gray_11
          : colors.caliber_white,
        margin: "2px 0px",
        height: 80,
        width: 296,
        position: "relative",
      }}
    >
      {lastMessageDate ? (
        <div
          className="medium-bold"
          style={{
            position: "absolute",
            right: 10,
            marginTop: 5,
            color: colors.caliber_secondary_gray_29,
            letterSpacing: "0.011em",
            fontSize: "10px",
            lineHeight: "11px",
          }}
        >
          {lastMessageDate} ago
        </div>
      ) : null}

      {unreadCount > 0 && (
        <div
          className="paragraph-small"
          style={{
            position: "absolute",
            right: 24,
            height: 24,
            width: 24,
            paddingTop: 2,
            alignSelf: "center",
            borderRadius: 12,
            backgroundColor: colors.caliber_red,
            color: colors.caliber_white,
            textAlign: "center",
          }}
        >
          {unreadCount}
        </div>
      )}
      <div style={{ margin: 16 }}>
        <ProfilePhoto
          isPremium={isClientPremium}
          imageUrl={profilePic}
          size={48}
          bgColor={
            isSelected ? colors.caliber_secondary_gray_11 : colors.caliber_white
          }
        />
      </div>
      <div
        className="d-flex flex-column"
        style={{
          margin: 8,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          className="bold"
          style={{
            alignSelf: "flex-start",
            fontSize: "14px",
            lineHeight: "24px",
            color: colors.caliber_secondary,
          }}
        >
          {userInfo.nickname}
        </div>
        {userMessage ? (
          <div
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              width: 200,
              maxWidth: 200,
              whiteSpace: "nowrap",
              alignSelf: "flex-start",
              fontSize: "12px",
              lineHeight: "12px",
              letterSpacing: "0.011em",
              color: colors.caliber_gray_text,
            }}
          >
            {lastMessageSnippet}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default MessageListPaneItem;
