import apolloClient from "../../../api/apolloClient";
import {
  FETCH_CLIENT_PROFILE_FOR_CLIENTS_TAB,
  GET_SEARCH_CLIENTS_TAB_QUERY,
} from "../../../api/gql/users/clients";
import {
  UPDATE_ADMIN_SETTINGS,
  UPDATE_MANAGER_SETTINGS,
  UPDATE_TRAINER_SETTINGS,
} from "../../../api/gql/users";
import {
  CaliberPageImpl_ClientSearchResult,
  ClientPackageType,
  ClientSearchResultSortCriteria,
  SortOrder,
  TrainerSettings,
  User,
  UserStatusType,
  UserType,
} from "../../../types/gqlTypes";
import {
  getIsAdmin,
  getIsManager,
  getIsMasquerade,
  getIsTrainer,
} from "../../reducers";
import { MasqueradeState } from "../Masquerade/types";
import { UserState } from "../User/types";
import {
  CHANGE_COLUMNS_IN_CLIENTS_TAB,
  CHANGE_COLUMNS_ON_REORDER_IN_CLIENTS_TAB,
  CHANGE_COLUMN_WIDTH_IN_CLIENTS_TAB,
  CHANGE_SORTING_IN_CLIENTS_TAB,
  ClientsTabAction,
  ClientsTabColumnsType,
  CLOSE_COLUMN_FILTER_IN_CLIENTS_TAB,
  CLOSE_NEW_CLIENT_IN_CLIENTS_TAB,
  CLOSE_SETTINGS_IN_CLIENTS_TAB,
  FETCH_CLIENT_PROFILE_FOR_CLIENTS_TAB_FAIL,
  FETCH_CLIENT_PROFILE_FOR_CLIENTS_TAB_LOADING,
  FETCH_CLIENT_PROFILE_FOR_CLIENTS_TAB_SUCCESS,
  GET_TABLE_SETTINGS_FOR_CLIENTS_TAB,
  ON_HOVER_ENTER_RESIZING_IN_CLIENTS_TAB,
  ON_HOVER_LEAVE_RESIZING_IN_CLIENTS_TAB,
  OPEN_COLUMN_FILTER_IN_CLIENTS_TAB,
  OPEN_EDIT_CLIENT_IN_CLIENTS_TAB,
  OPEN_NEW_CLIENT_IN_CLIENTS_TAB,
  OPEN_SETTINGS_IN_CLIENTS_TAB,
  RESET_COLUMNS_VIEW_IN_CLIENTS_TAB,
  RESET_FETCHED_CLIENT_PROFILE_FOR_CLIENTS_TAB,
  RESET_SORTING_IN_CLIENTS_TAB,
  SEARCH_CLIENTS_TAB_FAIL,
  SEARCH_CLIENTS_TAB_LOADING,
  SEARCH_CLIENTS_TAB_SUCCESS,
  SELECT_CLIENT_IN_CLIENTS_TAB,
  START_DRAGGING_COLUMN_IN_CLIENTS_TAB,
  STOP_DRAGGING_COLUMN_IN_CLIENTS_TAB,
  TURN_ON_EDIT_CLIENT_MODE_IN_CLIENTS_TAB,
  SET_CLIENTS_TAB_TRAINER_FILTER,
  ClientsTabState,
  CLIENTS_TAB_PREFS_SAVING_FAIL,
  CLIENTS_TAB_PREFS_SAVING_SUCCESS,
  CLIENTS_TAB_PREFS_SAVING,
} from "./types";

export const searchClientsTab =
  (
    args: {
      pageNumber?: number;
      pageSize?: number;
      searchCriteria?: string;
      sortOrder?: SortOrder;
      sortField?: ClientSearchResultSortCriteria;
      statusCriteria?: UserStatusType[];
      clientPackageTypeCriteria?: ClientPackageType[];
      trainerIdFilter?: string;
      managerIdFilter?: string;
    },
    columns?: ClientsTabColumnsType[]
  ) =>
  async (dispatch, getState) => {
    dispatch({
      type: SEARCH_CLIENTS_TAB_LOADING,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const state = getState();
      const { clientsTabState } = state;

      let actualColumns = [];
      if (columns) {
        actualColumns = [...columns];
      } else {
        actualColumns = [...clientsTabState.columns];
      }
      const strengthScoreArray = [
        "strengthScore",
        "strengthBalance",
        "armsMuscleGroupScore",
        "chestMuscleGroupScore",
        "backMuscleGroupScore",
        "legsMuscleGroupScore",
        "shouldersMuscleGroupScore",
      ];
      strengthScoreArray.forEach((name) => {
        if (actualColumns.includes(name)) {
          actualColumns.push(`${name}Delta`);
        }
      });
      const nutritionArray = ["fat", "carbs", "calories", "protein"];
      nutritionArray.forEach((name) => {
        if (actualColumns.includes(`${name}7DayAverage`)) {
          actualColumns.push(`${name}Target`);
        }
      });

      if (actualColumns?.length === 0) {
        return;
      }
      const result = (
        await client.query({
          query: GET_SEARCH_CLIENTS_TAB_QUERY(actualColumns),
          variables: {
            sortOrder: clientsTabState.sortOrder,
            sortField: clientsTabState.sortField,
            pageNumber: clientsTabState.pageNumber,
            pageSize: clientsTabState.pageSize,
            searchCriteria: clientsTabState.searchCriteria,
            statusCriteria: clientsTabState.statusCriteria,
            clientPackageTypeCriteria:
              clientsTabState.clientPackageTypeCriteria,
            trainerIdFilter: clientsTabState.trainerIdFilter,
            managerIdFilter: clientsTabState.managerIdFilter,
            ...(args && { ...args }),
          },
        })
      ).data.searchClientsTab as CaliberPageImpl_ClientSearchResult;

      dispatch({
        type: SEARCH_CLIENTS_TAB_SUCCESS,
        args,
        result,
      });
    } catch (error) {
      console.error("Error searchClientsTab:", error);
      dispatch({
        type: SEARCH_CLIENTS_TAB_FAIL,
      });
    }
  };
export const saveUserSettings =
  (
    columns: ClientsTabColumnsType[],
    widths: number[],
    statusCriteria: UserStatusType[],
    clientPackageTypeCriteria: ClientPackageType[]
  ) =>
  async (dispatch, getState) => {
    dispatch({
      type: CLIENTS_TAB_PREFS_SAVING,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const state = getState();
      const isAdmin = getIsAdmin(state);
      const isManager = getIsManager(state);
      const isTrainer = getIsTrainer(state);
      const isMasquerade = getIsMasquerade(state);
      let settings = (state.authenticatedUserState as UserState)?.user
        ?.settings;
      let mutation;
      let settingsName;
      if (isAdmin && !isMasquerade) {
        mutation = UPDATE_ADMIN_SETTINGS;
        settingsName = "adminSettings";
      } else if (isManager && !isMasquerade) {
        mutation = UPDATE_MANAGER_SETTINGS;
        settingsName = "managerSettings";
      } else if (isTrainer) {
        settingsName = "trainerSettings";
        mutation = UPDATE_TRAINER_SETTINGS;
      } else if (isMasquerade) {
        settingsName = "trainerSettings";
        mutation = UPDATE_TRAINER_SETTINGS;
        settings = (getState().masqueradeState as MasqueradeState)
          ?.masqueradeTrainer?.settings;
      }
      const prefs = {
        columns: columns.map((column, index) => {
          const width = widths[index];
          return { column, width };
        }),
        statusCriteria,
        clientPackageTypeCriteria,
      };
      await client.mutate({
        mutation,
        variables: {
          [settingsName]: {
            clientsTabColumnPreferences: JSON.stringify(prefs),
            enableGeneralNotifications: true,
            enableMessageNotifications: true,
            hasNewNotification: settings.hasNewNotification,
            id: settings.id,
            unitBodystat: settings.unitBodystat,
            unitDistance: settings.unitDistance,
            unitWeight: settings.unitWeight,
            ...((isTrainer || isMasquerade) && {
              firstAutoMessage: (settings as TrainerSettings).firstAutoMessage,
              secondAutoMessage: (settings as TrainerSettings)
                .secondAutoMessage,
              thirdAutoMessage: (settings as TrainerSettings).thirdAutoMessage,
            }),
          },
        },
      });

      dispatch({
        type: CLIENTS_TAB_PREFS_SAVING_SUCCESS,
      });
    } catch (error) {
      console.error("Error searchClientsTab:", error);
      dispatch({
        type: CLIENTS_TAB_PREFS_SAVING_FAIL,
      });
    }
  };

export const setTrainerFilter =
  (trainerFilter: string | User) => (dispatch) => {
    dispatch({
      type: SET_CLIENTS_TAB_TRAINER_FILTER,
      trainerFilter,
    } as ClientsTabAction);
  };

export const startDraggingColumnInClientsTab =
  (column: ClientsTabColumnsType) => (dispatch) => {
    dispatch({
      type: START_DRAGGING_COLUMN_IN_CLIENTS_TAB,
      column,
    } as ClientsTabAction);
  };
export const stopDraggingColumnInClientsTab = () => (dispatch, getState) => {
  const state = getState().clientsTabState;
  dispatch({
    type: STOP_DRAGGING_COLUMN_IN_CLIENTS_TAB,
  } as ClientsTabAction);
  dispatch(
    saveUserSettings(
      state.columns,
      state.widths,
      state.statusCriteria,
      state.clientPackageTypeCriteria
    )
  );
};
export const changeColumnWidthInClientsTab =
  (widths: number[]) => (dispatch, getState) => {
    const state = getState().clientsTabState;
    dispatch({
      type: CHANGE_COLUMN_WIDTH_IN_CLIENTS_TAB,
      widths,
    } as ClientsTabAction);
    dispatch(
      saveUserSettings(
        state.columns,
        widths,
        state.statusCriteria,
        state.clientPackageTypeCriteria
      )
    );
  };
export const openColumnFilterInClientsTab =
  (column: "status" | "packageType") => (dispatch) => {
    dispatch({
      type: OPEN_COLUMN_FILTER_IN_CLIENTS_TAB,
      column,
    } as ClientsTabAction);
  };
export const closeColumnFilterInClientsTab = () => (dispatch) => {
  dispatch({
    type: CLOSE_COLUMN_FILTER_IN_CLIENTS_TAB,
  } as ClientsTabAction);
};
export const openSettingsInClientsTab = () => (dispatch) => {
  dispatch({
    type: OPEN_SETTINGS_IN_CLIENTS_TAB,
  } as ClientsTabAction);
};
export const closeSettingsInClientsTab = () => (dispatch) => {
  dispatch({
    type: CLOSE_SETTINGS_IN_CLIENTS_TAB,
  } as ClientsTabAction);
};
export const changeColumnsInClientsTab =
  (columns: ClientsTabColumnsType[]) => (dispatch, getState) => {
    const state = getState().clientsTabState as ClientsTabState;
    const oldColumns = [...state.columns];
    const newAddedColumns = columns.filter((col) => !oldColumns.includes(col));
    const existingColumns = columns.filter((col) => oldColumns.includes(col));
    newAddedColumns
      .sort((a, b) => getColumnOrderIndex(a) - getColumnOrderIndex(b))
      .forEach((newColumn) => {
        const orderIndex = getColumnOrderIndex(newColumn);
        // insert 'newColumn' at index 'orderIndex' + 1
        existingColumns.splice(orderIndex + 1, 0, newColumn);
      });
    dispatch({
      type: CHANGE_COLUMNS_IN_CLIENTS_TAB,
      columns: existingColumns,
    } as ClientsTabAction);

    dispatch(searchClientsTab({}, existingColumns));

    let newWidths: number[] = state.widths;
    if (state.widths.length > existingColumns.length) {
      newWidths = newWidths.slice(0, existingColumns.length);
    } else if (newWidths.length < existingColumns.length) {
      new Array(existingColumns.length - newWidths.length)
        .fill(1)
        .forEach((_) => {
          newWidths.push(200);
        });
    }

    dispatch(
      saveUserSettings(
        existingColumns,
        newWidths,
        state.statusCriteria,
        state.clientPackageTypeCriteria
      )
    );
  };
export const resetColumnsViewInClientsTab = () => (dispatch, getState) => {
  const state = getState();
  const isAdmin = getIsAdmin(state);
  const isManager = getIsAdmin(state);
  const isMasquerade = getIsMasquerade(state);

  const columns = [
    ClientsTabColumnsType.fullName,
    ClientsTabColumnsType.lastActiveDate,
    ClientsTabColumnsType.lastDateClientSentMessage,
    ClientsTabColumnsType.lastDateClientReceivedMessage,
    ClientsTabColumnsType.mostRecentlyCompletedWorkoutDate,
    ClientsTabColumnsType.callsLeft,
    ClientsTabColumnsType.packageEndDate,
    ClientsTabColumnsType.status,
    ClientsTabColumnsType.clientPackageType,
  ];
  const widths = [197, 124, 188, 199, 211, 139, 140, 140, 200];
  const statusCriteria = [
    UserStatusType.Active,
    UserStatusType.OnHold,
    UserStatusType.PausedSubscription,
    UserStatusType.PendingCancellation,
    UserStatusType.TrialUser,
  ];
  const clientPackageTypeCriteria = [
    ClientPackageType.Premium,
    ClientPackageType.Legacy,
    ClientPackageType.TestAccount,
  ];
  if ((isAdmin || isManager) && !isMasquerade) {
    statusCriteria.push(UserStatusType.Cancelled);
    clientPackageTypeCriteria.push(ClientPackageType.Freemium);
  }
  dispatch({
    type: RESET_COLUMNS_VIEW_IN_CLIENTS_TAB,
    columns,
    widths,
    statusCriteria,
    clientPackageTypeCriteria,
  } as ClientsTabAction);
  dispatch(searchClientsTab({}, columns));
  dispatch(
    saveUserSettings(columns, widths, statusCriteria, clientPackageTypeCriteria)
  );
};
export const changeColumnsOnReorderInClientsTab =
  (columns: ClientsTabColumnsType[]) => (dispatch, getState) => {
    dispatch({
      type: CHANGE_COLUMNS_ON_REORDER_IN_CLIENTS_TAB,
      columns,
    } as ClientsTabAction);
  };
export const changePageInClientsTab = (pageNumber: number) => (dispatch) => {
  dispatch(searchClientsTab({ pageNumber }));
};
export const changePageSizeInClientsTab = (pageSize: number) => (dispatch) => {
  dispatch(searchClientsTab({ pageSize, pageNumber: 0 }));
};
export const selectClientInClientsTab = (index: number) => (dispatch) => {
  dispatch({
    type: SELECT_CLIENT_IN_CLIENTS_TAB,
    index,
  } as ClientsTabAction);
};
export const changeSortingInClientsTab =
  (sortOrder: SortOrder, sortField: ClientSearchResultSortCriteria) =>
  (dispatch, getState) => {
    const state = getState().clientsTabState;
    const currentSortOrder = state.sortOrder;
    const currentSortField = state.sortField;
    if (
      currentSortField === sortField &&
      currentSortOrder === sortOrder &&
      state.isSortingApplied
    ) {
      dispatch(
        searchClientsTab({
          sortOrder: SortOrder.Asc,
          sortField: ClientSearchResultSortCriteria.LastName,
          pageNumber: 0,
        })
      );
      dispatch({
        type: RESET_SORTING_IN_CLIENTS_TAB,
      });
    } else {
      dispatch(searchClientsTab({ sortOrder, sortField, pageNumber: 0 }));
      dispatch({
        type: CHANGE_SORTING_IN_CLIENTS_TAB,
      });
    }
  };
export const changeFilterInClientsTab =
  (args: {
    statusCriteria?: UserStatusType[];
    clientPackageTypeCriteria?: ClientPackageType[];
  }) =>
  (dispatch, getState) => {
    const state = getState().clientsTabState;
    dispatch(searchClientsTab({ ...args, pageNumber: 0 }));
    dispatch(
      saveUserSettings(
        state.columns,
        state.widths,
        args.statusCriteria || state.statusCriteria,
        args.clientPackageTypeCriteria || state.clientPackageTypeCriteria
      )
    );
  };
export const openNewClientInClientsTab = () => (dispatch) => {
  dispatch({
    type: OPEN_NEW_CLIENT_IN_CLIENTS_TAB,
  });
};
export const openEditClientInClientsTab = (clientId: string) => (dispatch) => {
  dispatch({
    type: OPEN_EDIT_CLIENT_IN_CLIENTS_TAB,
    clientId,
  });
};
export const closeNewClientInClientsTab = () => (dispatch) => {
  dispatch({
    type: CLOSE_NEW_CLIENT_IN_CLIENTS_TAB,
  });
};
export const resetFetchedClientProfileForClientsTab = () => (dispatch) => {
  dispatch({
    type: RESET_FETCHED_CLIENT_PROFILE_FOR_CLIENTS_TAB,
  });
};
export const fetchClientProfileForClientsTab =
  (id: string) => async (dispatch, getState) => {
    if (getState().clientSearchState.isLoading) {
      return;
    }
    dispatch({
      type: FETCH_CLIENT_PROFILE_FOR_CLIENTS_TAB_LOADING,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const result = await client.query({
        query: FETCH_CLIENT_PROFILE_FOR_CLIENTS_TAB,
        variables: { id },
      });

      dispatch({
        type: FETCH_CLIENT_PROFILE_FOR_CLIENTS_TAB_SUCCESS,
        user: result.data.user,
        weight: result.data.mostRecentBodyMeasurement,
        clientPackageType: result.data.clientSalesPackage.clientPackageType,
      });
    } catch (error) {
      dispatch({
        type: FETCH_CLIENT_PROFILE_FOR_CLIENTS_TAB_FAIL,
        data: error,
      });
    }
  };
const getColumnOrderIndex = (column: ClientsTabColumnsType) => {
  const arr = [
    ClientsTabColumnsType.lastActiveDate,
    ClientsTabColumnsType.lastDateClientSentMessage,
    ClientsTabColumnsType.lastDateClientReceivedMessage,
    ClientsTabColumnsType.mostRecentlyCompletedWorkoutDate,
    ClientsTabColumnsType.callsLeft,
    ClientsTabColumnsType.packageEndDate,
    ClientsTabColumnsType.gender,
    ClientsTabColumnsType.weight,
    ClientsTabColumnsType.age,
    ClientsTabColumnsType.height,
    ClientsTabColumnsType.status,
    ClientsTabColumnsType.clientPackageType,
    ClientsTabColumnsType.joinedDate,
    ClientsTabColumnsType.trainerFullName,
    ClientsTabColumnsType.strengthScore,
    ClientsTabColumnsType.strengthBalance,
    ClientsTabColumnsType.legsMuscleGroupScore,
    ClientsTabColumnsType.chestMuscleGroupScore,
    ClientsTabColumnsType.backMuscleGroupScore,
    ClientsTabColumnsType.shouldersMuscleGroupScore,
    ClientsTabColumnsType.armsMuscleGroupScore,
    ClientsTabColumnsType.calories7DayAverage,
    ClientsTabColumnsType.protein7DayAverage,
    ClientsTabColumnsType.fat7DayAverage,
    ClientsTabColumnsType.carbs7DayAverage,
  ];
  return arr.indexOf(column);
};
export const onHoverEnterResizingInClientsTab = () => (dispatch, getState) => {
  dispatch({
    type: ON_HOVER_ENTER_RESIZING_IN_CLIENTS_TAB,
  } as ClientsTabAction);
};
export const onHoverLeaveResizingInClientsTab = () => (dispatch, getState) => {
  dispatch({
    type: ON_HOVER_LEAVE_RESIZING_IN_CLIENTS_TAB,
  } as ClientsTabAction);
};
export const turnOnEditClientModeInClientsTab = () => (dispatch, getState) => {
  dispatch({
    type: TURN_ON_EDIT_CLIENT_MODE_IN_CLIENTS_TAB,
  } as ClientsTabAction);
};
export const getTableSettingsForClientsTab =
  (user: User) => (dispatch, getState) => {
    const isAdmin = user?.type === UserType.Admin;
    const isManager = user?.type === UserType.Manager;
    const columns = [];
    let widths = [];
    let statusCriteria = [];
    let clientPackageTypeCriteria = [];
    let prefs;
    try {
      prefs = JSON.parse(user?.settings?.clientsTabColumnPreferences);
    } catch (err) {
      resetColumnsViewInClientsTab?.();
      return;
    }
    if (Array.isArray(prefs)) {
      prefs?.forEach((pref) => {
        columns.push(pref?.column);
        widths.push(pref?.width);
      });
      statusCriteria = [
        UserStatusType.Active,
        UserStatusType.OnHold,
        UserStatusType.PausedSubscription,
        UserStatusType.PendingCancellation,
        UserStatusType.TrialUser,
      ];
      clientPackageTypeCriteria = [
        ClientPackageType.Premium,
        ClientPackageType.Legacy,
      ];
      if (isAdmin || isManager) {
        statusCriteria.push(UserStatusType.Cancelled);
        clientPackageTypeCriteria.push(ClientPackageType.Freemium);
      }
    } else if (prefs && typeof prefs === "object") {
      prefs?.columns?.forEach((pref) => {
        columns.push(pref?.column);
        widths.push(pref?.width);
      });
      statusCriteria = prefs?.statusCriteria || [];
      clientPackageTypeCriteria = prefs?.clientPackageTypeCriteria || [];
    } else {
      const isTrainer = user?.type === UserType.Trainer;
      const defaultColumns = [
        ClientsTabColumnsType.fullName,
        ClientsTabColumnsType.lastActiveDate,
        ClientsTabColumnsType.lastDateClientReceivedMessage,
        ClientsTabColumnsType.lastDateClientSentMessage,
        ClientsTabColumnsType.mostRecentlyCompletedWorkoutDate,
        ClientsTabColumnsType.callsLeft,
        ClientsTabColumnsType.packageEndDate,
        ClientsTabColumnsType.status,
        ClientsTabColumnsType.clientPackageType,
      ];
      if (!isTrainer) {
        defaultColumns.push(ClientsTabColumnsType.trainerFullName);
      }
      defaultColumns.forEach((column) => {
        columns.push(column);
      });
      widths = [197, 124, 167, 199, 211, 139, 140, 140, 200];
      statusCriteria = [
        UserStatusType.Active,
        UserStatusType.OnHold,
        UserStatusType.PausedSubscription,
        UserStatusType.PendingCancellation,
        UserStatusType.TrialUser,
      ];
      clientPackageTypeCriteria = [
        ClientPackageType.Premium,
        ClientPackageType.Legacy,
      ];
      if (isAdmin || isManager) {
        statusCriteria.push(UserStatusType.Cancelled);
        clientPackageTypeCriteria.push(ClientPackageType.Freemium);
      }
    }

    dispatch({
      type: GET_TABLE_SETTINGS_FOR_CLIENTS_TAB,
      settings: {
        columns,
        widths,
        statusCriteria,
        clientPackageTypeCriteria,
      },
    } as ClientsTabAction);
  };
