import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { StoreState } from "../../redux/reducers";
import ClientProfileInfoModule from "../../components/ClientProfileInfoModule";
import DailyCaloriesCalculator from "../../components/DailyCaloriesCalculator";
import NutritionMacroTargets from "../../components/NutritionMacroTargets";
import NutritionDietTypes from "../../components/NutritionDietTypes";
import { QueryClientNutritionTargetArgs } from "../../types/gqlTypes";
import { getClientNutritionTarget } from "../../redux/actions/ClientNutritionTarget";
import NutritionMealPlan from "../../components/NutritionMealPlan";

interface Props {
  getClientNutritionTarget: (args: QueryClientNutritionTargetArgs) => void;
}
const TrainerClientNutritionProgramScreen = (props: Props) => {
  const { getClientNutritionTarget } = props;
  const { id: clientId } = useParams();

  useEffect(() => {
    getClientNutritionTarget({ clientId });
  }, [clientId]);

  return (
    <div
      className="d-flex"
      style={{
        paddingBottom: 50,
      }}
    >
      <div
        className="d-flex margin-13-12"
        style={{
          width: 272,
        }}
      >
        <ClientProfileInfoModule fullView notShowLessInfo />
      </div>
      <div className="margin-13-12" style={{}}>
        <DailyCaloriesCalculator />
        <NutritionMacroTargets />
      </div>
      <div className="margin-13-12" style={{}}>
        <NutritionDietTypes />
        <NutritionMealPlan />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getClientNutritionTarget: (args: QueryClientNutritionTargetArgs) => {
    dispatch(getClientNutritionTarget(args));
  },
});

const mapStateToProps = (state: StoreState) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrainerClientNutritionProgramScreen);
