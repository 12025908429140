import {
  ClientTrainingPlan,
  ClientWorkoutTemplate,
  ClientGymStepTemplate,
} from "../../../types/gqlTypes";

export const GET_CLIENT_PLANS_LOAD_SUCCESS = "GET_CLIENT_PLANS_LOAD_SUCCESS";
export const GET_CLIENT_PLANS_LOAD_FAIL = "GET_CLIENT_PLANS_LOAD_FAIL";
export const GET_CLIENT_PLANS_LOADING = "GET_CLIENT_PLANS_LOADING";

export const GET_CLIENT_ACTIVE_PLAN_SUCCESS = "GET_CLIENT_ACTIVE_PLAN_SUCCESS";
export const GET_CLIENT_ACTIVE_PLAN_FAIL = "GET_CLIENT_ACTIVE_PLAN_FAIL";
export const GET_CLIENT_ACTIVE_PLAN_LOADING = "GET_CLIENT_ACTIVE_PLAN_LOADING";

export const RESET_CLIENT_STATE = "RESET_CLIENT_STATE";

export interface ClientPlansState {
  isLoading: boolean;
  isActivePlanLoading: boolean;
  hasActivePlan: boolean;
  clientPlans: ClientTrainingPlan[];
  activePlan: ClientTrainingPlan;
}

export interface GetClientPlansAction {
  type: string;
  gymStepData?: GymStepData;
  workoutData?: WorkoutData;
  planData?: PlanData;
  id?: string;
}

export interface GymStepData {
  clientGymStepTemplates?: ClientGymStepTemplate[];
  clientGymStepTemplateId?: string;
  currentPlan?: string;
  currentWorkout?: string;
  workoutTemplate?: ClientWorkoutTemplate;
}

export interface WorkoutData {
  currentPlan?: string;
  plan?: ClientTrainingPlan;
  workoutId?: string;
}

export interface PlanData {
  data?: ClientTrainingPlan[];
  clientPlanId?: string;
  plan?: ClientTrainingPlan;
  name?: string;
  activePlan?: ClientTrainingPlan;
  deactivePlan?: ClientTrainingPlan;
}
