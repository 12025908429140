export const CREATE_CLIENT_SUCCESS = "CREATE_CLIENT_SUCCESS";
export const CREATE_CLIENT_FAIL = "CREATE_CLIENT_FAIL";
export const CREATE_CLIENT_LOADING = "CREATE_CLIENT_LOADING";

export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";
export const UPDATE_CLIENT_FAIL = "UPDATE_CLIENT_FAIL";
export const UPDATE_CLIENT_LOADING = "UPDATE_CLIENT_LOADING";

export interface AdminCreateClientState {
  isLoading: boolean;
}

export interface AdminCreateClientAction {
  type: string;
}
