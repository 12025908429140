import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDrop } from "react-dnd";
import { connect } from "react-redux";
import colors from "../../assets/colors";
import { addWorkoutToPlan } from "../../redux/actions/TrainingPrograms/add";
import { reorderWorkoutsInPlan } from "../../redux/actions/TrainingPrograms/reorder";
import { getIsAdmin, getIsMasquerade, StoreState } from "../../redux/reducers";
import { selectCurrentWorkouts } from "../../redux/reducers/TrainingPrograms/selectors";
import { ClientWorkoutTemplate, MasterWorkout } from "../../types/gqlTypes";
import SimpleButton from "../SimpleButton";
import { ComponentLocationType, ProgramType } from "../TrainingProgram";
import WorkoutItem, { WorkoutCardItem } from "./WorkoutItem";

interface Props {
  workouts: (MasterWorkout | ClientWorkoutTemplate)[];
  programType: ProgramType;
  componentLocation: ComponentLocationType;
  isLoading: boolean;
  workoutGroupId?: string;
  isAdmin: boolean;
  isMasquerade: boolean;
  addNewWorkout: (selectedWorkoutGroupIndex: number) => void;
  reorder: (args: { ids: string[] }) => void;
  selectedWorkoutGroupIndex: number;
  canAddNewWorkout?: boolean;
}

const WorkoutList = (props: Props) => {
  const {
    workouts,
    programType,
    componentLocation,
    isLoading,
    workoutGroupId,
    isAdmin,
    isMasquerade,
    addNewWorkout,
    reorder,
    selectedWorkoutGroupIndex,
  } = props;

  const [localItems, setLocalItems] = useState([]);

  let canAddWorkout = true;
  if (
    programType === ProgramType.Master &&
    componentLocation === ComponentLocationType.ClientTab
  ) {
    canAddWorkout = false;
  } else if (
    programType === ProgramType.Master &&
    componentLocation === ComponentLocationType.AdminLibrary
  ) {
    canAddWorkout = isAdmin && !isMasquerade;
  }

  const [draggableWorkouts, setDraggableWorkouts] = useState(workouts);

  const workoutObjects = useMemo(() => {
    const object = {};
    draggableWorkouts &&
      draggableWorkouts.forEach((workout) => {
        object[workout.id] = workout;
      });
    return object;
  }, [workouts, draggableWorkouts]);

  useEffect(() => {
    setDraggableWorkouts(workouts);
  }, [workouts]);

  const moveCard = (
    id: string,
    atIndex: number,
    workoutGroupSourceId: string,
    workoutItem: MasterWorkout | ClientWorkoutTemplate
  ) => {
    const { index } = findCard(id);
    const items = [...localItems];

    if (index > -1 && atIndex != null) {
      const temp = items[index];
      items[index] = items[atIndex];
      items[atIndex] = temp;
      setLocalItems(items);
    } else if (index > -1 && atIndex === null) {
      items.push(workoutItem.id);
      setLocalItems(items);
    } else {
      items.splice(atIndex, 0, id);
      setLocalItems(items);
    }
  };
  const findCard = (id: string) => {
    return {
      index: localItems.indexOf(id),
    };
  };

  const onDrop = useCallback(
    (workoutId?: string, workoutGroupSourceId?: string) => {
      if (workoutGroupSourceId === workoutGroupId) {
        reorder({ ids: localItems });
      }
    },
    [localItems]
  );

  const [{ isOver, didDrop }, drop] = useDrop({
    accept: "workout",
    drop: (item: WorkoutCardItem, monitor) => {
      onDrop(item.id, item.workoutGroupId);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      didDrop: monitor.didDrop(),
    }),
  });

  useEffect(() => {
    if (!isOver && !didDrop && workouts) {
      setLocalItems(workouts.map((workout) => workout.id));
    }
  }, [workouts, isOver]);

  return (
    <div
      style={{
        flex: "1",
        padding: 0,
      }}
    >
      <div
        className="d-flex justify-content-between"
        style={{
          flex: "0 0 65px",
          padding: "8px 8px",
        }}
      >
        <div
          style={{
            lineHeight: "32px",
            color: colors.caliber_gray_border,
          }}
          className="paragraph-normal align-self-center"
        >
          Workouts
        </div>

        {canAddWorkout && (
          <div className="d-flex flex-column align-items-center justify-content-center">
            <SimpleButton
              className="pointer nofocus circular-medium-font"
              style={{
                backgroundColor: colors.caliber_gray_11,
                color: colors.caliber_secondary,
                padding: "1px 12px",
                borderRadius: 8,
                fontSize: "14px",
                lineHeight: "24px",
              }}
              onClick={() => addNewWorkout(selectedWorkoutGroupIndex)}
            >
              Add workout
            </SimpleButton>
          </div>
        )}
      </div>
      {!isLoading && localItems?.length > 0 && (
        <div
          className="d-flex flex-column overflow-auto"
          style={{
            position: "relative",
            flex: "1 1 0",
            width: "100%",
          }}
        >
          <div
            ref={drop}
            style={{
              flex: 1,
              padding: 0,
            }}
          >
            {localItems?.map((workoutId, index) => {
              return (
                workoutObjects[workoutId] && (
                  <WorkoutItem
                    key={workoutId}
                    index={index}
                    workoutGroupId={workoutGroupId}
                    workout={workoutObjects[workoutId]}
                    moveCard={moveCard}
                    findCard={findCard}
                  />
                )
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  workouts: selectCurrentWorkouts(state),
  programType: state.trainingProgramsState.programType,
  componentLocation: state.trainingProgramsState.componentLocation,
  planIndex: state.trainingProgramsState.planIndex,
  isLoading:
    state.trainingProgramsState?.isLoadingPlan ||
    state.trainingProgramsState?.isLoadingPlans,

  isAdmin: getIsAdmin(state),
  isMasquerade: getIsMasquerade(state),
  selectedWorkoutGroupIndex: state.trainingProgramsState.workoutGroupIndex,
});
const mapDispatchToProps = (dispatch) => ({
  reorder: (args: { ids: string[] }) => {
    dispatch(reorderWorkoutsInPlan(args));
  },
  addNewWorkout: (selectedWorkoutGroupIndex: number) => {
    dispatch(addWorkoutToPlan(selectedWorkoutGroupIndex));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkoutList);
