import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import { StoreState } from "../../redux/reducers";
import { searchExercises } from "../../redux/actions/SearchExercises";
import {
  Direction,
  Exercise,
  ExerciseSortCriteria,
  ExerciseType,
  QuerySearchExercisesArgs,
} from "../../types/gqlTypes";
import { SearchExercisesState } from "../../redux/actions/SearchExercises/types";
import ExerciseLibraryItem from "../ExerciseLibraryItem";
import {
  exerciseEquipment,
  exerciseLevel,
  exerciseTypeText,
} from "../../models/exercises";
import {
  deleteExercise,
  resetAdminExerciseLibraryState,
} from "../../redux/actions/AdminExerciseLibrary";
import ConfirmationDialog from "../ConfirmationDialog";

interface OwnProps {
  searchText: string;
  viewOnly?: boolean;
  onExerciseSelected: (exercise: Exercise) => void;
  selectedExercise: Exercise;
  queryArgs: QuerySearchExercisesArgs;
  deleteExercise: (exerciseId: string, force?: boolean) => void;
}

interface Props extends OwnProps {
  wasDeletedExerciseActive: boolean;
  searchExercisesState: SearchExercisesState;
  loadExercises: (args: QuerySearchExercisesArgs) => void;
  resetAdminExerciseLibraryState: () => void;
}

const ExerciseLibraryList = (props: Props) => {
  const {
    loadExercises,
    wasDeletedExerciseActive,
    viewOnly,
    searchExercisesState,
    searchText,
    onExerciseSelected,
    deleteExercise,
    resetAdminExerciseLibraryState,
    selectedExercise,
    queryArgs,
  } = props;

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [confirmStrengtScore, setConfirmStrengthScore] = useState(false);
  const [confirmForce, setConfirmForce] = useState(false);
  const [deletedExercise, setDeletedExercise] = useState(null);

  const defaultQueryArgs = {
    page: 0,
    sortField: ExerciseSortCriteria.Name,
    sortDirection: Direction.Asc,
    ...queryArgs,
    textCriteria: searchText,
  };

  const firstPageLoaded = useRef(false);
  useEffect(() => {
    loadExercises(defaultQueryArgs);
    firstPageLoaded.current = true;
  }, [searchText, queryArgs]);

  const parentRef = useRef();

  useEffect(() => {
    if (wasDeletedExerciseActive) {
      setConfirmForce(true);
    }
  }, [wasDeletedExerciseActive]);

  return (
    <div
      ref={parentRef}
      className="d-flex flex-column overflow-auto"
      style={{
        flex: 1,
      }}
    >
      {confirmDialog && (
        <ConfirmationDialog
          show={confirmDialog}
          cancelButtonText="Cancel"
          text={`Do you really want to delete ${deletedExercise?.name}?`}
          onCancel={() => setConfirmDialog(false)}
          onConfirm={() => {
            deleteExercise(deletedExercise?.id);
            setConfirmDialog(false);
          }}
        />
      )}
      {confirmStrengtScore && (
        <ConfirmationDialog
          show
          confirmButtonText="OK"
          text="This exercise is used in calculating Strength Score and cannot be deleted."
          onConfirm={() => {
            setConfirmDialog(false);
            setConfirmStrengthScore(false);
          }}
        />
      )}
      {confirmForce && (
        <ConfirmationDialog
          show
          cancelButtonText="Cancel"
          text="This exercise is tied to a Master or Client Training Plan.\nAre you sure want to delete it?"
          onCancel={() => {
            setConfirmDialog(false);
            setConfirmForce(false);
            resetAdminExerciseLibraryState();
          }}
          onConfirm={() => {
            setConfirmDialog(false);
            setConfirmForce(false);
            resetAdminExerciseLibraryState();
            deleteExercise(deletedExercise?.id, true);
          }}
        />
      )}
      <InfiniteScroll
        pageStart={0}
        useWindow={false}
        getScrollParent={() => parentRef.current}
        hasMore={
          !searchExercisesState.isLastPage &&
          !searchExercisesState.isLoading &&
          firstPageLoaded.current
        }
        loadMore={() => {
          const args = searchExercisesState.currentArgs
            ? searchExercisesState.currentArgs
            : defaultQueryArgs;
          loadExercises({
            ...args,
            page: searchExercisesState.currentPage + 1,
          });
        }}
      >
        {searchExercisesState.exerciseList.map((exercise) => {
          const tags = [];

          if (exercise.type === ExerciseType.Warmup) {
            tags.push(exerciseTypeText(exercise.type));
          }

          exercise.level && tags.push(exerciseLevel(exercise.level));

          if (exercise.type && exercise.type !== ExerciseType.Warmup) {
            tags.push(exerciseTypeText(exercise.type));
          }

          exercise.equipment &&
            tags.push(exerciseEquipment(exercise.equipment));

          return (
            <ExerciseLibraryItem
              viewOnly={viewOnly}
              key={exercise.id}
              thumbnail={exercise.media?.thumbnailUrl?.url}
              exercise={exercise}
              title={exercise.name}
              isSelected={selectedExercise?.id === exercise.id}
              tags={tags}
              onSelect={() => {
                onExerciseSelected(exercise);
              }}
              onDelete={() => {
                if (exercise?.isStrengthScoreKeyExercise) {
                  setConfirmStrengthScore(true);
                } else {
                  setDeletedExercise(exercise);
                  setConfirmDialog(true);
                }
              }}
            />
          );
        })}
      </InfiniteScroll>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  loadExercises: (args: QuerySearchExercisesArgs) => {
    dispatch(searchExercises(args));
  },
  deleteExercise: (exerciseId: string, force?: boolean) => {
    dispatch(deleteExercise(exerciseId, force));
  },
  resetAdminExerciseLibraryState: () => {
    dispatch(resetAdminExerciseLibraryState());
  },
});

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  searchExercisesState: state.searchExercisesState,
  searchText: ownProps.searchText,
  onSelectedExercise: ownProps.onExerciseSelected,
  selectedExercise: ownProps.selectedExercise,
  queryArgs: ownProps.queryArgs,
  wasDeletedExerciseActive:
    state.adminExerciseLibraryState.wasDeletedExerciseActive,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExerciseLibraryList);
