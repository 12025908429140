import React, { useCallback, useEffect, useState } from "react";
import { useDrop } from "react-dnd";
import { connect } from "react-redux";
import {
  changeColumnsOnReorderInClientsTab,
  changeColumnWidthInClientsTab,
  changeSortingInClientsTab,
  closeColumnFilterInClientsTab,
  closeSettingsInClientsTab,
  openColumnFilterInClientsTab,
  openSettingsInClientsTab,
  stopDraggingColumnInClientsTab,
} from "../../../redux/actions/ClientsTab";
import { ClientsTabColumnsType } from "../../../redux/actions/ClientsTab/types";
import { StoreState } from "../../../redux/reducers";
import {
  ClientSearchResultSortCriteria,
  SortOrder,
} from "../../../types/gqlTypes";
import HeaderCell from "./HeaderCell";
import HeaderCorner from "./HeaderCorner";
import SettingsGear from "./SettingsGear";

interface OwnProps {}
interface Props extends OwnProps {
  columns: ClientsTabColumnsType[];
  widths?: number[];
  isSettingsOpen?: boolean;
  sortOrder?: SortOrder;
  sortField?: ClientSearchResultSortCriteria;
  isSortingApplied?: boolean;
  isDragging?: boolean;
  onDraggingStop: () => void;
  onResizing: (widths: number[]) => void;
  openSettings: () => void;
  closeSettings: () => void;
  openColumnFilter: (column: "status" | "packageType") => void;
  closeColumnFilter: () => void;
  changeColumns: (columns: ClientsTabColumnsType[]) => void;
  onSort: (
    sortOrder: SortOrder,
    sortField: ClientSearchResultSortCriteria
  ) => void;
}

const Header = (props: Props) => {
  const {
    columns,
    widths,
    isSettingsOpen,
    sortOrder,
    sortField,
    isSortingApplied,
    isDragging,
    changeColumns,
    onDraggingStop,
    onResizing,
    openSettings,
    closeSettings,
    onSort,
  } = props;

  const [localColumns, setLocalColumns] = useState([]);
  const [localWidths, setLocalWidths] = useState([]);
  const [canMoveItems, setCanMoveItems] = useState(true);

  const moveCard = (id: string, atIndex: number) => {
    const { index } = findCard(id);
    const localWidths = [...widths];
    const temp = localColumns[index];
    const tempWidth = localWidths[index];
    const items = [...localColumns];
    items.splice(index, 1);
    items.splice(atIndex, 0, temp);
    localWidths.splice(index, 1);
    localWidths.splice(atIndex, 0, tempWidth);
    if (canMoveItems) {
      setCanMoveItems(false);
      setTimeout(() => {
        setCanMoveItems(true);
        setLocalColumns(items);
        setLocalWidths(localWidths);
        changeColumns(items);
        onResizing(localWidths);
      }, 450);
    }
  };
  const findCard = (text: string) => {
    return {
      index: localColumns.findIndex((column) => column === text),
    };
  };

  const onDrop = useCallback(
    (item: any, monitor) => {
      onDraggingStop();
    },
    [localColumns]
  );

  const [, drop] = useDrop({
    accept: "columns_table",
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      didDrop: monitor.didDrop(),
    }),
  });

  useEffect(() => {
    setLocalColumns([...columns]);
  }, [columns]);
  useEffect(() => {
    setLocalWidths([...widths]);
  }, [widths]);

  const onResize = (columnIndex, newWidth) => {
    if (isDragging) {
      return;
    }
    const newWidths = [...widths];
    newWidths[columnIndex] = Math.max(newWidth, 40);
    setLocalWidths(newWidths);
  };
  const onResizeEnd = () => {
    onResizing(localWidths);
  };

  return (
    <thead
      className="d-flex flex-column"
      style={{
        position: "sticky",
        top: 0,
        zIndex: 10,
      }}
    >
      <tr className="d-flex" ref={drop}>
        <HeaderCorner
          width={localWidths[0] || 0}
          isSorted={
            sortField === ClientSearchResultSortCriteria.LastName &&
            isSortingApplied
          }
          sortOrder={sortOrder}
          onSort={(sortOrder) =>
            onSort(sortOrder, ClientSearchResultSortCriteria.LastName)
          }
          onResize={(newWidth) => onResize(0, newWidth)}
          onResizeEnd={onResizeEnd}
        />
        {localColumns.slice(1).map((column, columnIndex) => {
          return (
            <HeaderCell
              isLastColumn={columnIndex === localColumns?.length - 2}
              width={localWidths[columnIndex + 1] || 0}
              key={column}
              text={column}
              moveCard={moveCard}
              findCard={findCard}
              onResize={(newWidth) => onResize(columnIndex + 1, newWidth)}
              onResizeEnd={onResizeEnd}
            />
          );
        })}
        <SettingsGear
          isSelected={isSettingsOpen}
          onClick={isSettingsOpen ? closeSettings : openSettings}
        />
      </tr>
    </thead>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onDraggingStop: () => {
    dispatch(stopDraggingColumnInClientsTab());
  },
  onResizing: (widths: number[]) => {
    dispatch(changeColumnWidthInClientsTab(widths));
  },
  openSettings: () => {
    dispatch(openSettingsInClientsTab());
  },
  closeSettings: () => {
    dispatch(closeSettingsInClientsTab());
  },
  openColumnFilter: (column: "status" | "packageType") => {
    dispatch(openColumnFilterInClientsTab(column));
  },
  closeColumnFilter: () => {
    dispatch(closeColumnFilterInClientsTab());
  },
  changeColumns: (columns: ClientsTabColumnsType[]) => {
    dispatch(changeColumnsOnReorderInClientsTab(columns));
  },
  onSort: (sortOrder: SortOrder, sortField: ClientSearchResultSortCriteria) => {
    dispatch(changeSortingInClientsTab(sortOrder, sortField));
  },
});

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  isSettingsOpen: state.clientsTabState.isSettingsOpen,
  columns: state.clientsTabState.columns,
  widths: state.clientsTabState.widths,
  sortOrder: state.clientsTabState.sortOrder,
  sortField: state.clientsTabState.sortField,
  isSortingApplied: state.clientsTabState.isSortingApplied,
  isDragging: state.clientsTabState.isDragging,
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
