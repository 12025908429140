import React from "react";
import colors from "../../assets/colors";
import AddNewHabitButton from "./AddNewHabitButton";

interface Props {
  disabled?: boolean;
  onNewHabitClick: () => void;
}

const Header = (props: Props) => {
  const { disabled, onNewHabitClick } = props;

  return (
    <div
      className="d-flex justify-content-between align-items-center"
      style={{
        marginTop: 24,
        marginBottom: 46,
      }}
    >
      <div
        className="bold"
        style={{
          fontSize: "24px",
          lineHeight: "30px",
          letterSpacing: "-0.033em",
          color: colors.black,
        }}
      >
        Add new habit
      </div>

      <div>
        <AddNewHabitButton disabled={disabled} onClick={onNewHabitClick} />
      </div>
    </div>
  );
};

export default Header;
