import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import colors from "../../../assets/colors";
import { getAppConfig } from "../../../config";
import {
  createBodyStatCalendarItem,
  createBodyStatRecurringEventDefinition,
  updateRecurringEventDefinition,
} from "../../../redux/actions/Calendar";
import { StoreState } from "../../../redux/reducers";
import {
  BodyMeasurementType,
  BodyStatCalendarItem,
  CalendarDay,
  CalendarItem,
  CalendarItemStatusType,
  MutationCreateBodyStatCalendarItemArgs,
  MutationCreateBodyStatRecurringEventDefinitionArgs,
  MutationUpdateRecurringEventDefinitionArgs,
  RecurringEventDefinition,
} from "../../../types/gqlTypes";
import RecurringMenu from "../../Calendar/RecurringMenu";
import Checkbox from "../../Icons/Checkbox";
import SimpleButton from "../../SimpleButton";
import Footer from "../Footer";
import Header, { HeaderType } from "../Header";
import CategoryButton from "./CategoryButton";
import SelectCategory from "./SelectCategory";

interface OwnProps {
  onClose: () => void;
  onBack: () => void;
  day: Date;
  calendarDay: CalendarDay;
  calendarItem?: CalendarItem;
}
interface Props extends OwnProps {
  createBodyStatCalendarItem: (
    args: MutationCreateBodyStatCalendarItemArgs
  ) => void;
  createRecurringBodyStat: (
    args: MutationCreateBodyStatRecurringEventDefinitionArgs
  ) => void;
  updateRecurringEventDefinition: (
    args: MutationUpdateRecurringEventDefinitionArgs
  ) => void;
}

const BodyStatEvent = (props: Props) => {
  const {
    day,
    calendarDay,
    calendarItem,
    onClose,
    onBack,
    createBodyStatCalendarItem,
    createRecurringBodyStat,
    updateRecurringEventDefinition,
  } = props;
  const isProd = getAppConfig().isProduction;
  const { id: clientId } = useParams();
  const [selectedStat, setSelectedStat] = useState<BodyMeasurementType>(null);
  const [repeat, setRepeat] = useState(false);
  const [recurringEvent, setRecurringEvent] =
    useState<RecurringEventDefinition>({
      RRule: "",
      endDate: format(day, "yyyy-MM-dd"),
      startDate: format(day, "yyyy-MM-dd"),
    });
  const isUpdating = !!calendarItem;

  useEffect(() => {
    if (calendarItem) {
      setRepeat(true);
      setSelectedStat(
        (calendarItem as BodyStatCalendarItem)?.bodyMeasurementType
      );
      setRecurringEvent({
        RRule: calendarItem?.recurringEventDefinition?.RRule,
        endDate: calendarItem?.recurringEventDefinition?.endDate,
        startDate: calendarItem?.recurringEventDefinition?.startDate,
      });
    }
  }, [calendarItem]);

  const onBackClick = () => {
    if (calendarItem) {
      onClose();
    } else if (selectedStat) {
      setSelectedStat(null);
      setRepeat(false);
    } else {
      onBack();
    }
  };
  const onSuccess = () => {
    if (repeat && selectedStat) {
      const ind = recurringEvent.RRule.indexOf("RRULE:");
      if (isUpdating) {
        updateRecurringEventDefinition({
          clientId,
          rRule: recurringEvent.RRule.slice(ind + 6),
          recurringEventDefinitionId:
            calendarItem?.recurringEventDefinition?.id,
          endDate: recurringEvent.endDate,
        });
      } else {
        createRecurringBodyStat({
          clientId,
          bodyMeasurementType: selectedStat,
          rRule: recurringEvent.RRule.slice(ind + 6),
          startDate: recurringEvent.startDate,
          endDate: recurringEvent.endDate,
        });
      }
    } else if (selectedStat) {
      const [year, month, dayOfMonth] = format(day, "yyyy-MM-dd").split("-");
      createBodyStatCalendarItem({
        clientId,
        bodyMeasurementType: selectedStat,
        bodyMeasurementValue: null,
        dayOfMonth: Number(dayOfMonth),
        month: Number(month),
        year: Number(year),
      });
    }
    onClose();
  };

  const repeatStyle: React.CSSProperties = {
    fontSize: "16px",
    lineHeight: "16px",
    letterSpacing: "-0.022em",
    color: colors.caliber_gray_text,
    marginLeft: 8,
    marginBottom: 24,
  };

  const canBeRandomlyPopulated =
    !isProd &&
    ((calendarItem as BodyStatCalendarItem)?.bodyMeasurementType ===
      BodyMeasurementType.BodyFatPercent ||
      (calendarItem as BodyStatCalendarItem)?.bodyMeasurementType ===
        BodyMeasurementType.WaistSize);

  return (
    <div className="d-flex flex-column">
      <Header day={day} type={HeaderType.BODY_STAT} onBackClick={onBackClick} />
      {canBeRandomlyPopulated && (
        <div className="d-flex">
          <SimpleButton
            style={{
              border: "1px solid black",
              marginTop: 10,
            }}
            onClick={() => {
              const [year, month, dayOfMonth] = format(day, "yyyy-MM-dd").split(
                "-"
              );
              createBodyStatCalendarItem({
                clientId,
                recurringEventDefinitionId:
                  calendarItem?.recurringEventDefinition?.id,
                bodyMeasurementType: (calendarItem as BodyStatCalendarItem)
                  ?.bodyMeasurementType,
                bodyMeasurementValue: Math.floor(Math.random() * 50),
                dayOfMonth: Number(dayOfMonth),
                month: Number(month),
                year: Number(year),
                status: CalendarItemStatusType.Completed,
              });
              onClose();
            }}
          >
            Populate with random data
          </SimpleButton>
        </div>
      )}

      {!selectedStat && (
        <SelectCategory
          calendarDay={calendarDay}
          onClick={(value) => setSelectedStat(value)}
        />
      )}

      {selectedStat && (
        <div
          className="d-flex justify-content-center"
          style={{
            marginTop: 42,
            marginBottom: 24,
          }}
        >
          <CategoryButton bodyStat={selectedStat} />
        </div>
      )}

      {selectedStat && (
        <div className="d-flex" style={{}}>
          <Checkbox
            disabled={isUpdating}
            checked={repeat}
            onClick={() => setRepeat(!repeat)}
          />
          <SimpleButton
            disabled={isUpdating}
            onClick={() => setRepeat(!repeat)}
            nofocus
            className="bold"
            style={repeatStyle}
          >
            Repeat
          </SimpleButton>
        </div>
      )}

      {repeat && (
        <RecurringMenu
          day={day}
          recurringEvent={recurringEvent}
          onChange={(recurringEvent) => setRecurringEvent(recurringEvent)}
        />
      )}

      {selectedStat && (
        <Footer
          successText={calendarItem ? "Update activity" : "Add activity"}
          onSuccess={onSuccess}
          onCancel={() => {
            if (calendarItem) {
              onClose();
            } else {
              onBack();
            }
          }}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  createBodyStatCalendarItem: (
    args: MutationCreateBodyStatCalendarItemArgs
  ) => {
    dispatch(createBodyStatCalendarItem(args));
  },
  createRecurringBodyStat: (
    args: MutationCreateBodyStatRecurringEventDefinitionArgs
  ) => {
    dispatch(createBodyStatRecurringEventDefinition(args));
  },
  updateRecurringEventDefinition: (
    args: MutationUpdateRecurringEventDefinitionArgs
  ) => {
    dispatch(updateRecurringEventDefinition(args));
  },
});

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  onClose: ownProps.onClose,
  day: ownProps.day,
  calendarDay: ownProps.calendarDay,
  calendarItem: ownProps.calendarItem,
});

export default connect(mapStateToProps, mapDispatchToProps)(BodyStatEvent);
