import React, { useState } from "react";
import colors from "../../assets/colors";
import { CoachProfilePhoto } from "../../types/gqlTypes";
import SimpleButton from "../SimpleButton";
import ImageModal from "./ImageModal";

interface Props {
  photos: CoachProfilePhoto[];
}

const ImagesSection = (props: Props) => {
  const { photos } = props;

  const [openModal, setOpenModal] = useState(null);

  return (
    <div
      className="d-flex flex-column"
      style={{
        minHeight: 200,
        backgroundColor: colors.caliber_white,
        borderRadius: 8,
        padding: 24,
        paddingBottom: 32,
        flex: 3,
        marginBottom: 32,
        marginRight: 24,
      }}
    >
      {typeof openModal === "number" && (
        <ImageModal
          imgUrl={photos?.[openModal]?.mediaUrl?.url}
          index={openModal}
          onClose={() => setOpenModal(null)}
        />
      )}
      <div
        className="medium-bold"
        style={{
          fontSize: "16px",
          lineHeight: "16px",
          color: colors.caliber_secondary_gray_29,
          marginLeft: 12,
          marginBottom: 16,
        }}
      >
        Images
      </div>

      <div className="d-flex flex-wrap">
        {photos.map((photo, index) => {
          return (
            photo?.mediaUrl?.url && (
              <SimpleButton nofocus onClick={() => setOpenModal(index)}>
                <img
                  src={photo?.mediaUrl?.url}
                  style={{
                    width: 96,
                    height: 96,
                    borderRadius: 8,
                    marginRight: 12,
                    marginTop: 12,
                  }}
                />
              </SimpleButton>
            )
          );
        })}
      </div>
    </div>
  );
};

export default ImagesSection;
