import React from "react";
import { Image } from "react-bootstrap";
import colors from "../../assets/colors";
import DefaultUserImage from "../../assets/images/DefaultUserImage.svg";
import PremiumClientIcon from "../Icons/PremiumClientIcon";

interface Props {
  imageUrl: string;
  size: number;
  isPremium?: boolean;
  bgColor?: string;
}

const ProfilePhoto = (props: Props) => {
  const { imageUrl, size, isPremium, bgColor } = props;

  let starSize;
  if (size >= 96) {
    starSize = 22;
  } else if (size === 36) {
    starSize = 14;
  } else {
    starSize = 16;
  }

  return (
    <div
      style={{
        position: "relative",
        width: size,
        height: size,
      }}
    >
      <Image
        className="bordered-item"
        style={{
          width: size,
          height: size,
          backgroundColor: colors.caliber_gray_border,
          objectFit: "cover",
        }}
        src={imageUrl || DefaultUserImage}
        roundedCircle
      />
      <div
        style={{
          position: "absolute",
          bottom: 0,
          right: -2,
        }}
      >
        {isPremium && (
          <PremiumClientIcon
            width={starSize}
            height={starSize}
            bgColor={bgColor}
          />
        )}
      </div>
    </div>
  );
};

export default ProfilePhoto;
