import apolloClient from "../../../api/apolloClient";
import {
  GET_MINIMUM_APP_BUILD_NUMBER as GET_APP_REQUIREMENTS,
  UPDATE_MINIMUM_APP_BUILD_NUMBER,
} from "../../../api/gql/adminDebug";
import {
  MutationUpdateMinimumAppBuildNumberArgs,
  QueryAppRequirementsArgs,
} from "../../../types/gqlTypes";
import {
  GET_MINIMUM_APP_BUILD_NUMBER_FAIL,
  GET_MINIMUM_APP_BUILD_NUMBER_LOADING,
  GET_MINIMUM_APP_BUILD_NUMBER_SUCCESS,
  UPDATE_MINIMUM_APP_BUILD_NUMBER_FAIL,
  UPDATE_MINIMUM_APP_BUILD_NUMBER_LOADING,
  UPDATE_MINIMUM_APP_BUILD_NUMBER_SUCCESS,
} from "./types";

export const getAppRequirements =
  (args: QueryAppRequirementsArgs) => async (dispatch, getState) => {
    dispatch({
      type: GET_MINIMUM_APP_BUILD_NUMBER_LOADING,
    });

    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const result = (
        await client.query({
          query: GET_APP_REQUIREMENTS,
          variables: args,
        })
      ).data.appRequirements;

      dispatch({
        type: GET_MINIMUM_APP_BUILD_NUMBER_SUCCESS,
        data: result,
      });
    } catch (error) {
      dispatch({
        type: GET_MINIMUM_APP_BUILD_NUMBER_FAIL,
      });
    }
  };

export const updateMinimumAppBuildNumber =
  (args: MutationUpdateMinimumAppBuildNumberArgs) =>
  async (dispatch, getState) => {
    dispatch({
      type: UPDATE_MINIMUM_APP_BUILD_NUMBER_LOADING,
    });

    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const result = (
        await client.mutate({
          mutation: UPDATE_MINIMUM_APP_BUILD_NUMBER,
          variables: args,
        })
      ).data.updateMinimumAppBuildNumber;
      dispatch({
        type: UPDATE_MINIMUM_APP_BUILD_NUMBER_SUCCESS,
        data: result,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_MINIMUM_APP_BUILD_NUMBER_FAIL,
      });
    }
  };
