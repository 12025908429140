import React, { useEffect, useState } from "react";
import colors from "../../../assets/colors";
import { ExerciseSet } from "../../../types/gqlTypes";
import { onKeyDownRestrict } from "../../../utils";

interface Props {
  exerciseSet: ExerciseSet;
  isTimeBased: boolean;
  hideWeight: boolean;
  onTimeChange: (value: number) => void;
  onRepsChange: (value: number) => void;
  onWeightChange: (value: number) => void;
}

const EditWorkout = (props: Props) => {
  const {
    exerciseSet,
    isTimeBased,
    hideWeight,
    onRepsChange,
    onTimeChange,
    onWeightChange,
  } = props;
  const [sec, setSec] = useState(0);
  const [min, setMin] = useState(0);
  const [reps, setReps] = useState(0);
  const [weight, setWeight] = useState(0);

  const onKeyDown = (event) => {
    if (onKeyDownRestrict(event.key, ["."])) {
      event.preventDefault();
    }
  };
  const onChangeMin = (event) => {
    if (Number(event.target.value) < 60) {
      setMin(Number(event.target.value));
    }
  };
  const onChangeSec = (event) => {
    if (Number(event.target.value) < 60) {
      setSec(Number(event.target.value));
    }
  };

  useEffect(() => {
    const time = exerciseSet?.actualTime || 0;
    const min = Math.floor(time / 60);
    const sec = time - min * 60;
    setMin(min);
    setSec(sec);
    setReps(exerciseSet?.actualReps || 0);
    if (!hideWeight) {
      setWeight(exerciseSet?.actualWeight || 0);
    }
  }, [exerciseSet, isTimeBased]);

  useEffect(() => {
    onWeightChange(weight || 0);
    onRepsChange(reps || 0);
    onTimeChange((min || 0) * 60 + (sec || 0));
  }, [min, sec, reps, weight]);

  const timeInputStyle: React.CSSProperties = {
    width: 50,
    height: 32,
    backgroundColor: colors.caliber_secondary_gray_11,
    border: `1px solid ${colors.caliber_secondary_gray_11}`,
    borderRadius: 8,
    fontSize: "18px",
    letterSpacing: "-0.022em",
    color: "#000000",
    textAlign: "right",
    paddingRight: 12,
  };
  const repsInputStyle: React.CSSProperties = {
    width: 88,
    height: 32,
    backgroundColor: colors.caliber_secondary_gray_11,
    border: `1px solid ${colors.caliber_secondary_gray_11}`,
    borderRadius: 8,
    fontSize: "18px",
    letterSpacing: "-0.022em",
    color: "#000000",
    textAlign: "right",
    paddingRight: 12,
  };
  const labelStyle: React.CSSProperties = {
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "-0.022em",
    color: colors.caliber_gray_text,
    marginLeft: 14,
    marginRight: 14,
    width: 30,
  };
  const titleStyle: React.CSSProperties = {
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "-0.022em",
    color: "#000000",
  };

  return (
    <div className="d-flex flex-column">
      <div
        style={{
          height: 1,
          backgroundColor: colors.caliber_gray_bg,
          marginTop: 24,
          marginBottom: 24,
        }}
      >
        {/* line */}
      </div>

      {isTimeBased ? (
        <div
          className="d-flex justify-content-between align-items-center"
          style={{}}
        >
          <div className="bold" style={titleStyle}>
            Time
          </div>
          <div className="d-flex">
            <input
              className="medium-bold"
              value={min?.toString()}
              onChange={onChangeMin}
              onKeyDown={onKeyDown}
              style={timeInputStyle}
            />
            <div
              className="d-flex align-items-center medium-bold"
              style={labelStyle}
            >
              mins
            </div>
            <input
              className="medium-bold"
              value={sec?.toString()}
              onChange={onChangeSec}
              onKeyDown={onKeyDown}
              style={timeInputStyle}
            />
            <div
              className="d-flex align-items-center medium-bold"
              style={labelStyle}
            >
              secs
            </div>
          </div>
        </div>
      ) : (
        <div
          className="d-flex justify-content-between align-items-center"
          style={{}}
        >
          <div className="bold" style={titleStyle}>
            Reps
          </div>
          <div className="d-flex">
            <input
              className="medium-bold"
              value={reps?.toString()}
              onChange={(event) => setReps(Number(event.target.value))}
              onKeyDown={onKeyDown}
              style={repsInputStyle}
            />
            <div
              className="d-flex align-items-center medium-bold"
              style={labelStyle}
            >
              reps
            </div>
          </div>
        </div>
      )}

      <div
        style={{
          height: 1,
          backgroundColor: colors.caliber_gray_bg,
          marginTop: 24,
          marginBottom: 24,
        }}
      >
        {/* line */}
      </div>

      <div
        className="d-flex justify-content-between align-items-center"
        style={{
          opacity: hideWeight ? 0.3 : 1,
        }}
      >
        <div className="bold" style={titleStyle}>
          Weight
        </div>
        <div className="d-flex">
          <input
            className="medium-bold"
            disabled={hideWeight}
            value={weight?.toString()}
            onChange={(event) => setWeight(Number(event.target.value))}
            onKeyDown={onKeyDown}
            style={repsInputStyle}
          />
          <div
            className="d-flex align-items-center medium-bold"
            style={labelStyle}
          >
            lbs
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditWorkout;
