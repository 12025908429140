import {
  AdminMobileAppBuildNumberAction,
  AdminMobileAppBuildNumberState,
  GET_MINIMUM_APP_BUILD_NUMBER_FAIL,
  GET_MINIMUM_APP_BUILD_NUMBER_LOADING,
  GET_MINIMUM_APP_BUILD_NUMBER_SUCCESS,
  UPDATE_MINIMUM_APP_BUILD_NUMBER_FAIL,
  UPDATE_MINIMUM_APP_BUILD_NUMBER_LOADING,
  UPDATE_MINIMUM_APP_BUILD_NUMBER_SUCCESS,
} from "../../actions/AdminMobileAppBuildNumber/types";

const initialState: AdminMobileAppBuildNumberState = {
  isLoading: false,
  success: false,
  appRequirementsList: [],
};

const adminMobileAppBuildNumberState = (
  state = initialState,
  action: AdminMobileAppBuildNumberAction
) => {
  switch (action.type) {
    case GET_MINIMUM_APP_BUILD_NUMBER_LOADING:
      return {
        ...state,
        isLoading: true,
        success: false,
        appRequirementsList: [],
      };
    case GET_MINIMUM_APP_BUILD_NUMBER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        appRequirementsList: [...state.appRequirementsList, action.data],
      };
    case GET_MINIMUM_APP_BUILD_NUMBER_FAIL:
      return {
        ...state,
        isLoading: false,
        success: false,
      };
    case UPDATE_MINIMUM_APP_BUILD_NUMBER_LOADING:
      return {
        ...state,
        isLoading: true,
        success: false,
      };
    case UPDATE_MINIMUM_APP_BUILD_NUMBER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        appRequirementsList: state.appRequirementsList.map((appReq) => {
          if (appReq.deviceType === action.data.deviceType) {
            return action.data;
          }
          return appReq;
        }),
      };
    case UPDATE_MINIMUM_APP_BUILD_NUMBER_FAIL:
      return {
        ...state,
        isLoading: false,
        success: false,
      };
    default:
      return state;
  }
};

export default adminMobileAppBuildNumberState;
