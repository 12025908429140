import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FileMessage, UserMessage } from "sendbird";
import colors from "../../assets/colors";
import ProfilePhoto from "../ProfilePhoto";
import FileMessageItem from "./FileMessageItem";
import TextMessageItem from "./TextMessageItem";
import VideoMessageItem from "./VideoMessageItem";

interface Props {
  isLink?: boolean;
  isClient?: boolean;
  isChatWindow?: boolean;
  isLastMessage?: boolean;
  showProfilePic?: boolean;
  message: UserMessage | FileMessage;
  isDeletable: boolean;
  isMasquerading: boolean;
  isClientPremium?: boolean;
  onDelete: () => void;
  addReaction: (message: UserMessage | FileMessage, emojiKey: string) => void;
}

const MessageItem = (props: Props) => {
  const {
    isLastMessage,
    isChatWindow,
    isLink,
    isClient,
    message,
    isDeletable,
    isMasquerading,
    showProfilePic,
    isClientPremium,
    onDelete,
    addReaction,
  } = props;

  const [emojiMenuOpen, setEmojiMenuOpen] = useState(false);
  const textMessage = message.isUserMessage() ? (message as UserMessage) : null;
  const fileMessage = message.isFileMessage() ? (message as FileMessage) : null;
  const isVideoMessage = message.customType == "muxVideo";
  const isFormVideo = message.customType == "form_video";
  const isTextMessage = textMessage?.message?.length > 0 && !isVideoMessage;

  const whatTimeIsIt = (message: UserMessage | FileMessage) => {
    const createdDate = new Date(message.createdAt);
    return format(createdDate, "h:mm aa").toLowerCase();
  };

  useEffect(() => {
    if (isLastMessage) {
      setTimeout(() => {
        document
          .getElementById("last_client_message_list_item")
          ?.scrollIntoView?.();
      }, 1000);
    }
  }, [isLastMessage]);

  return (
    <div
      id={`${isLastMessage ? "last_" : ""}client_message_list_item`}
      className="d-flex flex-column"
      style={{
        padding: "0px 24px",
      }}
    >
      {showProfilePic && (
        <div
          className="d-flex align-items-center"
          style={{
            marginBottom: 7,
            marginTop: 24,
            position: "relative",
          }}
        >
          <div
            style={{
              marginRight: 12,
            }}
          >
            {isLink ? (
              <Link
                to={`/clients/${message?.sender?.userId}/dashboard`}
                style={{ textDecoration: "none" }}
              >
                <ProfilePhoto
                  isPremium={isClient && isClientPremium}
                  imageUrl={message?.sender?.profileUrl}
                  size={48}
                  bgColor={
                    isChatWindow
                      ? colors.caliber_white
                      : colors.caliber_secondary_gray_5
                  }
                />
              </Link>
            ) : (
              <ProfilePhoto
                isPremium={isClient && isClientPremium}
                imageUrl={message?.sender?.profileUrl}
                size={48}
                bgColor={
                  isChatWindow
                    ? colors.caliber_white
                    : colors.caliber_secondary_gray_5
                }
              />
            )}
          </div>
          {isLink ? (
            <Link
              to={`/clients/${message?.sender?.userId}/dashboard`}
              style={{ textDecoration: "none" }}
            >
              <div
                className="medium-bold"
                style={{
                  marginRight: 14,
                  fontSize: "12px",
                  lineHeight: "24px",
                  color: colors.caliber_secondary,
                }}
              >
                {message?.sender?.nickname}
              </div>
            </Link>
          ) : (
            <div
              className="medium-bold"
              style={{
                marginRight: 14,
                fontSize: "12px",
                lineHeight: "24px",
                color: colors.caliber_secondary,
              }}
            >
              {message?.sender?.nickname}
            </div>
          )}
          <div
            className="medium-bold"
            style={{
              color: colors.caliber_secondary_gray_47,
              fontSize: "12px",
              lineHeight: "12px",
            }}
          >
            {whatTimeIsIt(message)}
          </div>
        </div>
      )}

      {isTextMessage && (
        <TextMessageItem
          key={`text_msg_item_${message?.messageId}`}
          onDelete={onDelete}
          addReaction={addReaction}
          setEmojiMenuOpen={(val) => setEmojiMenuOpen(val)}
          message={message}
          showProfilePic={showProfilePic}
          isMasquerading={isMasquerading}
          isDeletable={isDeletable}
          emojiMenuOpen={emojiMenuOpen}
        />
      )}

      {(isVideoMessage || isFormVideo) && (
        <VideoMessageItem
          key={`video_msg_item_${message?.messageId}`}
          onDelete={onDelete}
          addReaction={addReaction}
          setEmojiMenuOpen={(val) => setEmojiMenuOpen(val)}
          message={message}
          showProfilePic={showProfilePic}
          isMasquerading={isMasquerading}
          isDeletable={isDeletable}
          emojiMenuOpen={emojiMenuOpen}
        />
      )}

      {fileMessage && (
        <FileMessageItem
          key={`file_msg_item_${message?.messageId}`}
          onDelete={onDelete}
          addReaction={addReaction}
          setEmojiMenuOpen={(val) => setEmojiMenuOpen(val)}
          message={message}
          showProfilePic={showProfilePic}
          isMasquerading={isMasquerading}
          isDeletable={isDeletable}
          emojiMenuOpen={emojiMenuOpen}
        />
      )}
    </div>
  );
};

export default MessageItem;
