import React from "react";
import { Image } from "react-bootstrap";
import colors from "../../assets/colors";
import MeatballsMenu from "../MeatballsMenu";
import { Exercise } from "../../types/gqlTypes";
import SSBadge from "../../assets/images/StrengthScoreBadgeIcon.svg";

interface Props {
  viewOnly?: boolean;
  title: string;
  thumbnail?: string;
  isSelected: boolean;
  tags?: string[];
  exercise: Exercise;
  onSelect: () => void;
  onDelete: () => void;
}

const ExerciseLibraryItem = (props: Props) => {
  const {
    title,
    thumbnail,
    isSelected,
    tags,
    viewOnly,
    exercise,
    onSelect,
    onDelete,
  } = props;

  const thumbnailStyle = {
    width: 64,
    height: 64,
    backgroundColor: "white",
    borderRadius: 8,
  };

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <div
        role="button"
        className="d-flex align-items-center nofocus pointer"
        tabIndex={0}
        onClick={onSelect}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            onSelect();
          }
        }}
        style={{
          minHeight: 80,
          borderRadius: 8,
          padding: "8px 20px",
          paddingRight: 50,
          margin: 5,
          backgroundColor: isSelected
            ? colors.caliber_gray_11
            : colors.caliber_gray_5,
        }}
      >
        <div className="d-flex flex-wrap">
          <div style={thumbnailStyle}>
            {thumbnail && <Image style={thumbnailStyle} src={thumbnail} />}
          </div>
        </div>
        <div
          className="d-flex heading-medium flex-wrap"
          style={{
            flex: 1,
            overflow: "hidden",
            marginLeft: 16,
          }}
        >
          <p style={{ margin: 0 }}>{title}</p>
        </div>
        {tags && (
          <div
            className="d-flex flex-wrap"
            style={{
              flex: 1,
            }}
          >
            {tags.map((text) => {
              return (
                <div
                  className="paragraph-normal"
                  key={`${text}`}
                  style={{
                    height: 24,
                    padding: 5,
                    margin: 2,
                    borderRadius: 6,
                    backgroundColor: colors.caliber_white,
                    lineHeight: "16px",
                  }}
                >
                  {text}
                </div>
              );
            })}
          </div>
        )}
      </div>
      {!viewOnly && (
        <div
          className="pointer"
          style={{
            position: "absolute",
            zIndex: 100,
            right: 12,
            top: 25,
          }}
        >
          <MeatballsMenu items={[{ text: "Delete", onClick: onDelete }]} />
        </div>
      )}
      {exercise?.isStrengthScoreKeyExercise && (
        <div
          style={{
            height: 24,
            position: "absolute",
            zIndex: 100,
            right: 22,
            bottom: 15,
          }}
        >
          <img src={SSBadge} />
        </div>
      )}
    </div>
  );
};

export default ExerciseLibraryItem;
