import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import SelectEventType from "./SelectEventType";
import CardioEvent from "./CardioEvent";
import {
  CalendarDay,
  CalendarItem,
  CalendarItemType,
  ClientHabitTarget,
} from "../../types/gqlTypes";
import WorkoutEvent from "./WorkoutEvent";
import BodyStatEvent from "./BodyStatEvent";
import ProgressPhotoEvent from "./ProgressPhotoEvent";
import Header, { HeaderType } from "./Header";
import HabitEvent from "./HabitEvent";

interface Props {
  day: Date;
  calendarDay: CalendarDay;
  calendarItem?: CalendarItem;
  habits: ClientHabitTarget[];
  onClose: () => void;
}

const ScheduleEventModal = (props: Props) => {
  const { day, calendarDay, calendarItem, habits, onClose } = props;
  const [currentEvent, setCurrentEvent] = useState(null);
  const hasProgressPhotos = calendarDay?.items.some(
    (item) => item?.enumType === CalendarItemType.ProgressPic
  );
  const noHabitEnabled = habits?.length === 0 || !habits;

  useEffect(() => {
    if (calendarItem && calendarItem?.enumType === CalendarItemType.Workout) {
      setCurrentEvent("0");
    } else if (
      calendarItem &&
      calendarItem?.enumType === CalendarItemType.CardioActivity
    ) {
      setCurrentEvent("1");
    } else if (
      calendarItem &&
      calendarItem?.enumType === CalendarItemType.BodyStat
    ) {
      setCurrentEvent("2");
    } else if (
      calendarItem &&
      calendarItem?.enumType === CalendarItemType.ProgressPic
    ) {
      setCurrentEvent("3");
    } else if (
      calendarItem &&
      calendarItem?.enumType === CalendarItemType.ImportedSteps
    ) {
      setCurrentEvent("4");
    } else if (
      calendarItem &&
      calendarItem?.enumType === CalendarItemType.Steps
    ) {
      setCurrentEvent("4");
    }
  }, [calendarItem]);

  return (
    <Modal onClose={onClose} closeOnDimmerClick>
      <div
        className="d-flex flex-column"
        style={{
          width: 456,
          minHeight: 258,
          padding: 24,
          paddingTop: 16,
          position: "relative",
        }}
      >
        <div>
          {!currentEvent && (
            <Header
              day={day}
              type={HeaderType.SCHEDULE_EVENT}
              onBackClick={onClose}
            />
          )}
          {!currentEvent && !calendarItem && (
            <SelectEventType
              disableProgressPhotos={hasProgressPhotos}
              noHabitEnabled={noHabitEnabled}
              onSelect={(index: string) => setCurrentEvent(index)}
            />
          )}
          {currentEvent === "0" && (
            <WorkoutEvent
              day={day}
              calendarItem={calendarItem}
              onClose={onClose}
              onBack={() => setCurrentEvent(null)}
            />
          )}
          {currentEvent === "1" && (
            <CardioEvent
              day={day}
              calendarItem={calendarItem}
              onClose={onClose}
              onBack={() => setCurrentEvent(null)}
            />
          )}
          {currentEvent === "2" && (
            <BodyStatEvent
              day={day}
              calendarItem={calendarItem}
              calendarDay={calendarDay}
              onClose={onClose}
              onBack={() => setCurrentEvent(null)}
            />
          )}
          {currentEvent === "3" && (
            <ProgressPhotoEvent
              day={day}
              calendarItem={calendarItem}
              onClose={onClose}
              onBack={() => setCurrentEvent(null)}
            />
          )}
          {currentEvent === "4" && (
            <HabitEvent
              day={day}
              calendarItem={calendarItem}
              calendarDay={calendarDay}
              onClose={onClose}
              onBack={() => setCurrentEvent(null)}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ScheduleEventModal;
