export const TEXT_GOAL_LOADING = "TEXT_GOAL_LOADING";
export const TEXT_GOAL_LOAD_SUCCESS = "TEXT_GOAL_LOAD_SUCCESS";
export const TEXT_GOAL_LOAD_FAIL = "TEXT_GOAL_LOAD_FAIL";

export interface CreateTextGoalAction {
  clientId: string;
  text?: string;
}
export interface UpdateTextGoalAction {
  type: string;
  goalId?: string;
  text?: string;
  achieved?: boolean;
  targetDate?: string;
}
