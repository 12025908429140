import {
  addMonths,
  addWeeks,
  differenceInMonths,
  differenceInWeeks,
  format,
  getDay,
  parse,
  subDays,
} from "date-fns";
import React, { useEffect, useState } from "react";
import { Frequency as RRuleFrequency, Options, RRule, Weekday } from "rrule";
import { RecurringEventDefinition } from "../../../types/gqlTypes";
import { WeekdayRecurrenceType } from "../../../utils";
import EveryDayFrequency, {
  DayOfMonthType,
  DaysOfWeekType,
} from "./EveryDayFrequency";
import Frequency, { RecurrenceFrequencyType } from "./Frequency";
import OnDayFrequency from "./OnDayFrequency";
import RepeatingFor from "./RepeatingFor";

interface Props {
  day?: Date;
  recurringEvent?: RecurringEventDefinition;
  onChange?: (recurringEvent: RecurringEventDefinition) => void;
}

const RecurringMenu = (props: Props) => {
  const { onChange, day, recurringEvent } = props;
  const { RRule: ruleString, endDate, startDate } = recurringEvent || {};
  const [firstRender, setFirstRender] = useState(true);
  const [frequencyType, setFrequencyType] = useState(
    RecurrenceFrequencyType.WEEKLY
  );
  const [OnOrEvery, setOnOrEvery] = useState<"ON" | "EVERY">("EVERY");
  const [onDayOfMonth, setOnDayOfMonth] = useState(1);
  const [everyDayOfMonth, setEveryDayOfMonth] = useState(DayOfMonthType.FIRST);
  const [everyDayOfWeek, setEveryDayOfWeek] = useState(DaysOfWeekType.ONE);
  const [weekdays, setWeekdays] = useState<WeekdayRecurrenceType[]>([]);
  const [weekDuration, setWeekDuration] = useState(1);
  const [monthDuration, setMonthDuration] = useState(1);

  const getUntilDateFromRule = (rule: string) => {
    const indexUntil = rule.indexOf("UNTIL=");
    if (indexUntil === -1) return null;
    const newEndDate = rule.slice(indexUntil + 6, indexUntil + 14);
    return `${newEndDate.slice(0, 4)}-${newEndDate.slice(
      4,
      6
    )}-${newEndDate.slice(6)}`;
  };

  useEffect(() => {
    const newRule = getNewRule();
    const newRuleString = newRule?.toString();
    const parsedStartDate = parse(startDate, "yyyy-MM-dd", new Date());
    const parsedEndDate = parse(endDate, "yyyy-MM-dd", new Date());
    if (firstRender && ruleString !== "") {
      setFirstRender(false);
      setByOrigOptions(
        RRule.fromString(ruleString)?.origOptions,
        parsedStartDate,
        parsedEndDate
      );
    } else if (firstRender && ruleString === "") {
      setFirstRender(false);
      onChange({
        startDate,
        endDate: getUntilDateFromRule(newRuleString),
        RRule: newRuleString,
      });
      const weekday = getDay(day);
      if (weekday === 0) {
        // Sunday
        setWeekdays([WeekdayRecurrenceType.SUNDAY]);
      } else {
        setWeekdays([translateWeekday(weekday - 1) as WeekdayRecurrenceType]);
      }
    } else if (ruleString && ruleString !== newRuleString) {
      onChange({
        startDate,
        endDate: getUntilDateFromRule(newRuleString),
        RRule: newRuleString,
      });
    }
  });
  useEffect(() => {
    const options = RRule.fromString(ruleString)?.origOptions;
    if (
      firstRender &&
      options.freq === RRuleFrequency.WEEKLY &&
      startDate &&
      endDate
    ) {
      const start = parse(startDate, "yyyy-MM-dd", new Date());
      const end = parse(endDate, "yyyy-MM-dd", new Date());
      const diff = Math.abs(differenceInWeeks(start, end)) + 1;
      if (diff !== weekDuration) {
        setWeekDuration(Math.max(diff, 1));
      }
    } else if (
      firstRender &&
      options.freq === RRuleFrequency.MONTHLY &&
      startDate &&
      endDate
    ) {
      const start = parse(startDate, "yyyy-MM-dd", new Date());
      const end = parse(endDate, "yyyy-MM-dd", new Date());
      const diff = Math.abs(differenceInMonths(start, end));
      if (diff !== monthDuration) {
        setMonthDuration(Math.max(diff, 1));
      }
    }
  }, [startDate, endDate, ruleString, firstRender]);
  const setByOrigOptions = (
    options: Partial<Options>,
    startDate: Date,
    endDate: Date
  ) => {
    if (!options) return;
    const isWeekly = options.freq === RRuleFrequency.WEEKLY;
    if (isWeekly) {
      const weekdays = (options.byweekday as Weekday[]).map(({ weekday }) =>
        translateWeekday(weekday)
      );
      setFrequencyType(RecurrenceFrequencyType.WEEKLY);
      const numOfWeeks = differenceInWeeks(startDate, endDate);
      setWeekDuration(Math.abs(numOfWeeks) + 1);
      setWeekdays(weekdays as WeekdayRecurrenceType[]);
      setOnOrEvery("EVERY");
      setEveryDayOfWeek(options.interval);
    } else {
      setFrequencyType(RecurrenceFrequencyType.MONTHLY);
      const numOfMonths = differenceInMonths(startDate, endDate);
      setMonthDuration(Math.abs(numOfMonths));
      const { weekday, n } = (options.byweekday as Weekday[])?.[0] || {
        weekday: null,
        n: null,
      };
      if (n) {
        setEveryDayOfMonth(
          n === -1 ? DayOfMonthType.LAST : DayOfMonthType.FIRST
        );
        setWeekdays([translateWeekday(weekday) as WeekdayRecurrenceType]);
        setOnOrEvery("EVERY");
      } else if (options?.bymonthday) {
        setOnDayOfMonth(options?.bymonthday as number);
        setOnOrEvery("ON");
      }
    }
  };
  const getCount = () => {
    const ruleObject: any = {};
    const [todayYear, todayMonth, todayDayOfMonth] = format(
      day,
      "yyyy-MM-dd"
    ).split("-");
    const timezoneOffset = new Date().getTimezoneOffset();
    ruleObject.dtstart = new Date(
      Date.UTC(
        Number(todayYear),
        Number(todayMonth) - 1,
        Number(todayDayOfMonth),
        0,
        timezoneOffset
      )
    );
    if (frequencyType === RecurrenceFrequencyType.WEEKLY) {
      const [year, month, dayOfMonth] = format(
        subDays(addWeeks(day, weekDuration), 1),
        "yyyy-MM-dd"
      ).split("-");
      ruleObject.until = new Date(
        Date.UTC(
          Number(year),
          Number(month) - 1,
          Number(dayOfMonth),
          0,
          timezoneOffset
        )
      );
    }
    if (frequencyType === RecurrenceFrequencyType.MONTHLY) {
      const [year, month, dayOfMonth] = format(
        addMonths(day, monthDuration),
        "yyyy-MM-dd"
      ).split("-");
      ruleObject.until = new Date(
        Date.UTC(
          Number(year),
          Number(month) - 1,
          Number(dayOfMonth),
          0,
          timezoneOffset
        )
      );
    }
    return ruleObject;
  };
  const getFreq = () => {
    const ruleObject: any = {};
    if (frequencyType === RecurrenceFrequencyType.WEEKLY) {
      ruleObject.freq = RRule.WEEKLY;
    }
    if (frequencyType === RecurrenceFrequencyType.MONTHLY) {
      ruleObject.freq = RRule.MONTHLY;
    }
    return ruleObject;
  };
  const getInterval = () => {
    const ruleObject: any = {};
    if (frequencyType === RecurrenceFrequencyType.WEEKLY) {
      ruleObject.interval = everyDayOfWeek;
    }
    if (frequencyType === RecurrenceFrequencyType.MONTHLY) {
      ruleObject.interval = 1;
    }
    return ruleObject;
  };
  const translateWeekday = (weekday) => {
    switch (weekday) {
      case WeekdayRecurrenceType.MONDAY:
        return RRule.MO;
      case WeekdayRecurrenceType.TUESDAY:
        return RRule.TU;
      case WeekdayRecurrenceType.WEDNESDAY:
        return RRule.WE;
      case WeekdayRecurrenceType.THURSDAY:
        return RRule.TH;
      case WeekdayRecurrenceType.FRIDAY:
        return RRule.FR;
      case WeekdayRecurrenceType.SATURDAY:
        return RRule.SA;
      case WeekdayRecurrenceType.SUNDAY:
        return RRule.SU;
      case 0:
        return WeekdayRecurrenceType.MONDAY;
      case 1:
        return WeekdayRecurrenceType.TUESDAY;
      case 2:
        return WeekdayRecurrenceType.WEDNESDAY;
      case 3:
        return WeekdayRecurrenceType.THURSDAY;
      case 4:
        return WeekdayRecurrenceType.FRIDAY;
      case 5:
        return WeekdayRecurrenceType.SATURDAY;
      case 6:
        return WeekdayRecurrenceType.SUNDAY;
    }
    return null;
  };
  const getBy_Day = () => {
    const ruleObject: any = {};
    if (frequencyType === RecurrenceFrequencyType.WEEKLY) {
      ruleObject.byweekday = weekdays.map(translateWeekday);
    }
    if (frequencyType === RecurrenceFrequencyType.MONTHLY) {
      if (OnOrEvery === "EVERY") {
        const sign = everyDayOfMonth === DayOfMonthType.FIRST ? +1 : -1;
        ruleObject.byweekday = (translateWeekday(weekdays[0]) as Weekday).nth(
          sign
        );
      }
      if (OnOrEvery === "ON") {
        ruleObject.bymonthday = onDayOfMonth;
      }
    }
    return ruleObject;
  };
  const getNewRule = () => {
    return new RRule({
      ...getCount(),
      ...getFreq(),
      ...getInterval(),
      ...getBy_Day(),
    });
  };

  return (
    <div
      className="d-flex flex-column"
      style={{
        border: "1px solid #C2C3CD",
        borderRadius: 8,
        padding: "12px 4px",
        marginBottom: 40,
      }}
    >
      <Frequency
        frequencyType={frequencyType}
        onSelect={(value) => {
          setFrequencyType(value);
          setOnOrEvery("EVERY");
        }}
      />
      <OnDayFrequency
        onDaySelect={setOnDayOfMonth}
        onCheck={() => setOnOrEvery("ON")}
        isSelected={OnOrEvery === "ON"}
        selectedDay={onDayOfMonth}
        frequencyType={frequencyType}
      />
      <EveryDayFrequency
        isSelected={OnOrEvery === "EVERY"}
        frequencyType={frequencyType}
        dayOfMonth={everyDayOfMonth}
        daysOfWeek={everyDayOfWeek}
        weekdays={weekdays}
        onCheck={() => setOnOrEvery("EVERY")}
        onSelectDayOfMonth={setEveryDayOfMonth}
        onSelectDaysOfWeek={setEveryDayOfWeek}
        onSelectWeekdays={setWeekdays}
      />
      <RepeatingFor
        frequencyType={frequencyType}
        weekDuration={weekDuration}
        monthDuration={monthDuration}
        onSelectWeekDuration={setWeekDuration}
        onSelectMonthDuration={setMonthDuration}
      />
    </div>
  );
};

export default RecurringMenu;
