import React, { useMemo } from "react";
import { connect } from "react-redux";
import { StoreState } from "../../redux/reducers";
import {
  StrengthScoreMuscleGroupType,
  StrengthScoreDashboard,
} from "../../types/gqlTypes";
import CaretDown from "../../assets/images/CaretRedDown.svg";
import CaretUp from "../../assets/images/CaretGreenUp.svg";
import colors from "../../assets/colors";
import { DashboardTypes } from "../../models/strengthscore";

interface Props {
  strengthScoreDashboard: StrengthScoreDashboard;
  tableType: DashboardTypes;
}

const StrengthTable = (props: Props) => {
  const { strengthScoreDashboard, tableType } = props;

  const showScoreChange = tableType === DashboardTypes.score;

  const tableItems = [
    { group: StrengthScoreMuscleGroupType.Legs, title: "Legs" },
    { group: StrengthScoreMuscleGroupType.Chest, title: "Chest" },
    { group: StrengthScoreMuscleGroupType.Back, title: "Back" },
    { group: StrengthScoreMuscleGroupType.Shoulders, title: "Shoulders" },
    { group: StrengthScoreMuscleGroupType.Arms, title: "Arms" },
  ];

  const muscleObject = useMemo(() => {
    const obj = {};
    strengthScoreDashboard &&
      strengthScoreDashboard.muscleGroupScores.forEach((muscleGroup) => {
        obj[muscleGroup.type] = muscleGroup;
      });
    return obj;
  }, [strengthScoreDashboard]);

  const headerStyle = {
    color: colors.caliber_gray_text,
    fontSize: "12px",
    lineHeight: "15px",
    letterSpacing: "-0.29px",
    height: 32,
    marginBottom: 8,
    padding: 10,
  };

  if (!strengthScoreDashboard) {
    return (
      <div
        style={{
          width: 290,
          height: 232,
        }}
      >
        {/* empty */}
      </div>
    );
  }

  return (
    <div
      className="d-flex flex-column justify-content-end"
      style={{
        flex: 1,
        width: 290,
        height: 232,
        backgroundColor: colors.caliber_white,
        borderRadius: 8,
        marginLeft: 22,
      }}
    >
      <div
        className="d-flex align-items-center"
        style={{
          height: 36,
        }}
      >
        <div className="bold" style={{ ...headerStyle, width: 115 }}>
          Muscle group
        </div>
        {showScoreChange && (
          <div className="bold" style={{ ...headerStyle, width: 100 }}>
            Change
          </div>
        )}
        <div
          className="bold"
          style={{
            ...headerStyle,
            width: showScoreChange ? 55 : 155,
            paddingLeft: showScoreChange ? 7 : 65,
          }}
        >
          {tableType === DashboardTypes.score ? "Score" : "Strength Index"}
        </div>
      </div>
      {tableItems.map((tableItem, index) => {
        let isTickerUp;
        let displayDelta;
        let displayScore = muscleObject[tableItem.group][tableType];
        if (showScoreChange) {
          isTickerUp = muscleObject[tableItem.group].strengthScoreDelta >= 0;
          displayDelta = `${Math.abs(
            Math.round(muscleObject[tableItem.group].strengthScoreDelta)
          )} pts`;
        }
        if (tableType === DashboardTypes.balance) {
          displayScore = Math.round(
            muscleObject[tableItem.group][tableType] * 100
          );
        }
        return (
          <div
            key={tableItem.group}
            className="d-flex"
            style={{
              backgroundColor: index % 2 === 0 ? "#F3F4F6" : "#FDFDFD",
              height: 32,
              borderRadius: 4,
              marginTop: index % 2 !== 0 && 8,
              marginBottom: index % 2 !== 0 && 8,
            }}
          >
            <div
              className="d-flex align-items-center bold"
              style={{
                width: 115,
                color: colors.caliber_secondary,
                fontWeight: 900,
                fontSize: "16px",
                letterSpacing: "-0.29px",
                lineHeight: "20px",
                paddingLeft: 10,
              }}
            >
              {tableItem.title}
            </div>
            {showScoreChange && (
              <div
                style={{
                  width: 100,
                }}
                className="d-flex justify-content-between"
              >
                <div
                  className="d-flex flex-column justify-content-center"
                  style={{
                    height: 36,
                    marginRight: 5,
                  }}
                >
                  <img
                    style={{
                      width: 14,
                      height: 8.5,
                    }}
                    src={isTickerUp ? CaretUp : CaretDown}
                  />
                </div>
                <div
                  className="d-flex flex-column justify-content-center bold"
                  style={{
                    color: colors.caliber_gray_text,
                    fontSize: "14px",
                    lineHeight: "18px",
                    letterSpacing: "-0.23px",
                    paddingRight: 30,
                  }}
                >
                  {displayDelta}
                </div>
              </div>
            )}
            <div
              className="d-flex justify-content-end align-items-center bold"
              style={{
                width: showScoreChange ? 55 : 155,
                fontSize: "22px",
                lineHeight: "28px",
                letterSpacing: "-0.34px",
                paddingRight: 10,
              }}
            >
              {displayScore}
            </div>
          </div>
        );
      })}
    </div>
  );
};
const mapStateToProps = (state: StoreState) => ({
  strengthScoreDashboard:
    state.clientStrengthDashboardState.strengthScoreDashboard,
  isLoading: state.clientStrengthDashboardState.isLoading,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(StrengthTable);
