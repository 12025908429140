import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import colors from "../../assets/colors";
import { getClientTrainingPlans } from "../../redux/actions/ClientPlans";
import { StoreState } from "../../redux/reducers";
import { ClientTrainingPlan } from "../../types/gqlTypes";
import ButtonTag from "../ButtonTag";
import RadioButton from "../Icons/RadioButton";
import Loader from "../Loader";

interface Props {
  clientId: string;
  title: string;
  destinationClientPlans: ClientTrainingPlan[];
  getClientTrainingPlans: (clientId: string) => void;
  onClose: () => void;
  onSelect: (result: string) => void;
  onSave: () => void;
  destinationClientPlansIsLoading: boolean;
}

const TrainingPlanSelector = (props: Props) => {
  const {
    clientId: destinationClientId,
    title,
    destinationClientPlans,
    getClientTrainingPlans,
    onClose,
    onSelect,
    onSave,
    destinationClientPlansIsLoading,
  } = props;

  const [destinationTrainingPlanId, setDestinationTrainingPlanId] =
    useState<string>(null);

  // Loads this client's training plans on init
  useEffect(() => {
    getClientTrainingPlans(destinationClientId);
  }, [destinationClientId]);

  useEffect(() => {
    // select default ACTIVE training plan or choose first inactive one
    const activePlan: ClientTrainingPlan = destinationClientPlans.find(
      (ctp) => ctp.active
    );
    let selectedId: string = null;
    if (destinationClientPlans.length > 0 && activePlan) {
      selectedId = activePlan.id;
    } else if (destinationClientPlans.length > 0) {
      selectedId = destinationClientPlans[0].id;
    }

    setDestinationTrainingPlanId(selectedId);
    onSelect(selectedId);
  }, [destinationClientPlans]);

  return (
    <div>
      {destinationClientPlansIsLoading ? (
        <div
          style={{
            position: "absolute",
            zIndex: 3,
            top: "calc(50% - 24px)",
            left: "calc(50% - 24px)",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div
          className="d-flex flex-column"
          style={{
            width: 480,
            padding: 24,
          }}
        >
          <div
            className="flex-grow-0 d-flex justify-content-center"
            style={{
              fontSize: "20px",
              fontWeight: 700,
              lineHeight: "30px",
              letterSpacing: ".02em",
              marginTop: "20px",
              marginBottom: "20px",
              textAlign: "center",
            }}
          >
            Copy {title} to Training Plan
          </div>
          <div
            style={{
              paddingLeft: 6,
              position: "relative",
              overflowY: "auto",
            }}
          >
            {destinationClientPlans?.length > 0 &&
              destinationClientPlans?.map(
                (currentPlan: ClientTrainingPlan, index: number) => {
                  const isSelected =
                    currentPlan.id === destinationTrainingPlanId;
                  return (
                    <div
                      className="d-flex"
                      key={currentPlan.id}
                      style={{
                        minHeight: 48,
                        padding: 15,
                        marginTop: 12,
                        borderRadius: 12,
                        position: "relative",
                        backgroundColor: "#E6E7EC",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                        }}
                      >
                        <div className="d-flex">
                          <div
                            className={`d-flex align-items-center ${
                              currentPlan.active ? "bold" : "medium-bold"
                            }`}
                            style={{
                              fontSize: "14px",
                              lineHeight: "16px",
                              marginRight: 8,
                              color: colors.caliber_secondary,
                            }}
                          >
                            {currentPlan.name}
                          </div>
                          <div
                            className="d-flex paragraph-normal align-items-center"
                            style={{
                              flex: 1,
                            }}
                          >
                            <div
                              role="button"
                              className="d-flex align-items-center justify-content-center"
                              tabIndex={0}
                              style={{
                                padding: "10px 10px",
                                borderRadius: "4px",
                                height: 18,
                                width: 52,
                                color: currentPlan.active
                                  ? colors.caliber_green_200
                                  : colors.caliber_gray_text,
                                backgroundColor: currentPlan.active
                                  ? "#D9EFD5"
                                  : "#DADCE3",
                                fontSize: "12px",
                                lineHeight: "14px",
                              }}
                            >
                              {currentPlan.active ? "Active" : "Inactive"}
                            </div>
                            <div
                              className="d-flex flex-column justify-content-center pointer"
                              style={{
                                alignItems: "end",
                                flex: 1,
                              }}
                            >
                              <RadioButton
                                checked={isSelected}
                                onClick={() => {
                                  setDestinationTrainingPlanId(currentPlan.id);
                                  onSelect(currentPlan.id);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              )}

            {destinationClientPlans.length == 0 && (
              <div className="d-flex justify-content-around">
                No training plans created for this client yet.
              </div>
            )}
          </div>

          <div
            className="d-flex justify-content-around"
            style={{
              paddingTop: 24,
            }}
          >
            <ButtonTag
              text="Cancel"
              onClick={onClose}
              height={32}
              bgColor={colors.caliber_gray_text}
              textColor={colors.caliber_white}
              minWidth={160}
            />
            {destinationClientPlans.length > 0 && (
              <ButtonTag
                text="Save"
                onClick={onSave}
                height={32}
                bgColor={colors.caliber_green_200}
                textColor={colors.caliber_white}
                minWidth={160}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getClientTrainingPlans: (clientId: string) => {
    dispatch(getClientTrainingPlans(clientId));
  },
});

const mapStateToProps = (state: StoreState) => ({
  destinationClientPlansIsLoading: state.clientPlansState.isLoading,
  destinationClientPlans: state.clientPlansState.clientPlans,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrainingPlanSelector);
