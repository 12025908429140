import React from "react";
import {
  StrengthScore,
  StrengthScoreMuscleGroupType,
} from "../../../types/gqlTypes";
import { muscleTitles } from ".";
import colors from "../../../assets/colors";
import Ticker from "../../Icons/Ticker";

interface Props {
  muscleGroup: StrengthScore;
}

const DetailsPanelHeader = (props: Props) => {
  const { muscleGroup } = props;

  const unit =
    muscleGroup?.type === StrengthScoreMuscleGroupType.Weight ? "lbs" : "pts";
  const delta = muscleGroup?.strengthScoreDelta || 0;
  const displayDelta = `${Math.abs(Math.round(delta))} ${unit}`;

  return (
    <div
      className="d-flex align-items-center"
      style={{
        height: 32,
        margin: "0px 8px 40px 8px",
      }}
    >
      <div
        className="d-flex align-items-center bold"
        style={{
          flex: 2,
          fontSize: "20px",
          lineHeight: "24px",
          letterSpacing: "-0.011em",
          color: colors.caliber_secondary,
        }}
      >
        {muscleTitles?.[muscleGroup.type]}
      </div>
      <div
        className="d-flex justify-content-center"
        style={{
          flex: 1,
          bottom: 25,
          width: 160,
        }}
      >
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{
            height: 20,
            marginRight: 5,
          }}
        >
          <Ticker up={delta >= 0} width={14} height={8} />
        </div>
        <div
          className="d-flex flex-column justify-content-center circular-bold-font"
          style={{
            color: colors.caliber_gray_text,
            fontSize: "16px",
            lineHeight: "20px",
            letterSpacing: "-0.23px",
          }}
        >
          {displayDelta}
        </div>
      </div>
      <div
        className="d-flex justify-content-end align-items-center bold"
        style={{
          flex: 1,
          fontSize: "29px",
          lineHeight: "29px",
          letterSpacing: "-0.011em",
          color: "#202443",
        }}
      >
        {muscleGroup?.score || "--"}
      </div>
    </div>
  );
};

export default DetailsPanelHeader;
