import {
  ClientWorkout,
  ExerciseSetInput,
  FormVideo,
  QueryFormVideosByDateRangeArgs,
} from "../../../types/gqlTypes";
import {
  FETCHING_CLIENT_WORKOUT_HISTORY_FAIL,
  FETCHING_CLIENT_WORKOUT_HISTORY_LOADING,
  FETCHING_CLIENT_WORKOUT_HISTORY_SUCCESS,
  RESET_CLIENT_WORKOUT_HISTORY_STATE,
  FETCHING_FORM_VIDEOS_BY_DATE_RANGE_SUCCESS,
  FETCHING_FORM_VIDEOS_BY_DATE_RANGE_LOADING,
  FETCHING_FORM_VIDEOS_BY_DATE_RANGE_FAIL,
  SELECT_WORKOUT_PROGRESS_TABLE,
  ClientWorkoutHistoryAction,
} from "./types";
import apolloClient from "../../../api/apolloClient";
import {
  CLIENT_WORKOUT_HISTORY,
  EDIT_EXERCISE_SETS,
  FETCH_FORM_VIDEOS_BY_DATE_RANGE,
} from "../../../api/gql/clientWorkoutHistory";

export const fetchClientWorkoutHistory =
  (
    clientId: string,
    clientWorkoutTemplateId: string,
    maxQueryResults: number
  ) =>
  async (dispatch, getState) => {
    if (getState().clientSearchState.isLoading) {
      return;
    }
    dispatch({
      type: FETCHING_CLIENT_WORKOUT_HISTORY_LOADING,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const result = (
        await client.query({
          query: CLIENT_WORKOUT_HISTORY,
          variables: {
            clientId,
            clientWorkoutTemplateId,
            maxQueryResults,
          },
        })
      ).data.clientWorkoutHistory as ClientWorkout[];
      dispatch({
        type: FETCHING_CLIENT_WORKOUT_HISTORY_SUCCESS,
        data: result,
        args: {
          clientWorkoutTemplateId,
          maxQueryResults,
        },
      } as ClientWorkoutHistoryAction);
    } catch (error) {
      dispatch({
        type: FETCHING_CLIENT_WORKOUT_HISTORY_FAIL,
        data: error,
      });
    }
  };

export const formVideosByDateRange =
  (args: QueryFormVideosByDateRangeArgs) => async (dispatch, getState) => {
    if (getState().clientSearchState.isLoading) {
      return;
    }
    dispatch({
      type: FETCHING_FORM_VIDEOS_BY_DATE_RANGE_LOADING,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const result = (
        await client.query({
          query: FETCH_FORM_VIDEOS_BY_DATE_RANGE,
          variables: args,
        })
      ).data.formVideosByDateRange as FormVideo[];
      dispatch({
        type: FETCHING_FORM_VIDEOS_BY_DATE_RANGE_SUCCESS,
        formVideos: result,
      });
    } catch (error) {
      dispatch({
        type: FETCHING_FORM_VIDEOS_BY_DATE_RANGE_FAIL,
        data: error,
      });
    }
  };
export const editExerciseSets =
  (clientGymStepId: string, exerciseSets: ExerciseSetInput[]) =>
  async (dispatch, getState) => {
    if (getState().clientSearchState.isLoading) {
      return;
    }
    dispatch({
      type: FETCHING_CLIENT_WORKOUT_HISTORY_LOADING,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      await client.mutate({
        mutation: EDIT_EXERCISE_SETS,
        variables: {
          clientGymStepId,
          exerciseSets,
          clientId: getState().clientDetailState.user.id,
        },
      });
      const refetchResult = (
        await client.query({
          query: CLIENT_WORKOUT_HISTORY,
          variables: {
            clientId: getState().clientDetailState.user.id,
            clientWorkoutTemplateId:
              getState().clientWorkoutHistoryState.args.clientWorkoutTemplateId,
            maxQueryResults:
              getState().clientWorkoutHistoryState.args.maxQueryResults,
          },
        })
      ).data.clientWorkoutHistory as ClientWorkout[];

      dispatch({
        type: FETCHING_CLIENT_WORKOUT_HISTORY_SUCCESS,
        data: refetchResult,
        args: {
          clientId: getState().clientDetailState.user.id,
          clientWorkoutTemplateId:
            getState().clientWorkoutHistoryState.args.clientWorkoutTemplateId,
          maxQueryResults:
            getState().clientWorkoutHistoryState.args.maxQueryResults,
        },
      } as ClientWorkoutHistoryAction);
    } catch (error) {
      dispatch({
        type: FETCHING_CLIENT_WORKOUT_HISTORY_FAIL,
        data: error,
      });
    }
  };
export const resetClientWorkoutHistoryState = () => (dispatch) => {
  dispatch({
    type: RESET_CLIENT_WORKOUT_HISTORY_STATE,
  });
};
export const selectWorkoutProgressTable = (id: string) => (dispatch) => {
  dispatch({
    type: SELECT_WORKOUT_PROGRESS_TABLE,
    id,
  });
};
