import React from "react";
import colors from "../../../assets/colors";
import CloseIcon from "../../Icons/CloseIcons";
import SimpleButton from "../../SimpleButton";

interface Props {
  canBeSaved?: boolean;
  onSave?: () => void;
  onClose?: () => void;
}

const Header = (props: Props) => {
  const { canBeSaved, onSave, onClose } = props;

  return (
    <div
      className="d-flex align-items-center justify-content-end"
      style={{
        height: 64,
      }}
    >
      {canBeSaved && (
        <SimpleButton
          nofocus
          className="d-flex align-items-center justify-content-center medium-bold"
          style={{
            fontSize: "14px",
            lineHeight: "16px",
            letterSpacing: "-0.011em",
            color: colors.caliber_green_200,
            backgroundColor: "#E8F9EF",
            width: 132,
            height: 32,
            borderRadius: 4,
          }}
          onClick={onSave}
        >
          Save view
        </SimpleButton>
      )}

      <SimpleButton
        nofocus
        className="d-flex align-items-center justify-content-center"
        style={{
          height: 32,
          marginRight: 22,
          marginLeft: 18,
        }}
        onClick={onClose}
      >
        <CloseIcon width={24} height={24} />
      </SimpleButton>
    </div>
  );
};

export default Header;
