import React from "react";

interface Props {}

const PlayIcon = (props: Props) => {
  const {} = props;

  return (
    <>
      <svg
        style={{ display: "block" }}
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="30" cy="30" r="30" fill="white" />
        <path
          d="M23.5622 22.366C23.5622 20.8264 25.2288 19.8642 26.5622 20.634L38.8564 27.732C40.1897 28.5018 40.1897 30.4264 38.8564 31.1962L26.5622 38.2942C25.2288 39.064 23.5622 38.1018 23.5622 36.5622L23.5622 22.366Z"
          fill="#01052F"
        />
      </svg>
    </>
  );
};

export default PlayIcon;
