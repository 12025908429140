import React, { useEffect, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import colors from "../../assets/colors";
import CloseIcon from "../Icons/CloseIcons";
import DragHandle from "../Icons/DragHandle";
import SimpleButton from "../SimpleButton";
import { TraitType } from "./TraitsSection";

interface Item {
  type: string;
  text: string;
  originalIndex: string;
}
interface Props {
  text: string;
  traitType: TraitType;
  isDraggable?: boolean;
  editable?: boolean;
  onDelete?: () => void;
  moveCard?: (text?: string, to?: number) => void;
  findCard?: (text?: string) => { index?: number };
}

const TraitItem = (props: Props) => {
  const {
    text,
    traitType,
    isDraggable,
    editable,
    onDelete,
    moveCard,
    findCard,
  } = props;

  const [isHover, setIsHover] = useState(false);

  const card = findCard && findCard(text);
  const originalIndex = card ? card.index : 0;

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: traitType, text, originalIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      const { text: droppedText, originalIndex } = monitor.getItem();
      const didDrop = monitor.didDrop();
      if (isDraggable && moveCard && !didDrop) {
        moveCard(droppedText, originalIndex);
      }
    },
  });

  const [, drop] = useDrop({
    accept: traitType,
    canDrop: () => false,
    hover({ text: droppedText }: Item, monitor) {
      if (isDraggable && findCard && droppedText !== text) {
        const card = findCard(text);
        if (isHover) {
          setIsHover(false);
        }
        if (isDraggable && moveCard && card && card.index > -1) {
          moveCard(droppedText, card.index);
        }
      }
    },
  });

  useEffect(() => {
    if (!editable) {
      setIsHover(false);
    }
  }, [editable]);

  return (
    <div
      ref={(node) => (isDraggable ? preview(drop(node)) : null)}
      className="d-flex"
      onMouseEnter={() => {
        if (editable) {
          setIsHover(true);
        }
      }}
      onMouseLeave={() => setIsHover(false)}
      style={{
        transform: "translate(0, 0)",
        marginBottom: 16,
        opacity: isDragging ? 0 : 1,
      }}
    >
      <div
        className="d-flex flex-wrap align-items-center medium-bold"
        style={{
          fontSize: "14px",
          lineHeight: "14px",
          color: colors.caliber_secondary,
          backgroundColor: colors.caliber_white,
          borderRadius: 8,
          width: isHover ? 346 : 428,
          height: 48,
          transition: "width 0.1s",
          padding: "0px 12px",
          wordBreak: "break-all",
        }}
      >
        {text}
      </div>

      {isHover && (
        <SimpleButton
          nofocus
          onClick={onDelete}
          className="d-flex justify-content-center align-items-center"
          style={{
            width: 34,
            height: 48,
            paddingLeft: 14,
          }}
        >
          <CloseIcon width={20} height={20} />
        </SimpleButton>
      )}
      {isHover && (
        <div
          ref={(node) => (isDraggable ? drag(drop(node)) : null)}
          className="d-flex justify-content-center align-items-center"
          style={{
            width: 48,
            height: 48,
            cursor: isDraggable && "move",
          }}
        >
          <DragHandle />
        </div>
      )}
    </div>
  );
};

export default TraitItem;
