import React from "react";
import { ClientPackageType } from "../../types/gqlTypes";

interface Props {
  clientPackageType: ClientPackageType;
}

const ClientPackageBadge = (props: Props) => {
  const { clientPackageType } = props;

  const properties = {
    [ClientPackageType.Freemium]: {
      minWidth: 80,
      backgroundColor: "#0BA34B",
      color: "#FFFFFF",
    },
    [ClientPackageType.Pro]: {
      minWidth: 80,
      backgroundColor: "#9747FF",
      color: "#FFFFFF",
    },
    [ClientPackageType.Premium]: {
      minWidth: 66,
      backgroundColor: "#FFCC32",
      color: "#A18329",
    },
    [ClientPackageType.Legacy]: {
      minWidth: 64,
      backgroundColor: "#C2C8FE",
      color: "#3547FC",
    },
    [ClientPackageType.TestAccount]: {
      minWidth: 90,
      backgroundColor: "#C2C8FE",
      color: "#3547FC",
    },
  };
  let title;
  switch (clientPackageType) {
    case ClientPackageType.Premium:
      title = "Premium";
      break;
    case ClientPackageType.Pro:
      title = "Pro";
      break;
    case ClientPackageType.Freemium:
      title = "Freemium";
      break;
    case ClientPackageType.Legacy:
      title = "Legacy";
      break;
    case ClientPackageType.TestAccount:
      title = "Test Account";
      break;
    default:
      title = "n/a";
  }

  return (
    <div
      className="d-flex align-items-center justify-content-center medium-bold"
      style={{
        fontSize: "12px",
        lineHeight: "16px",
        height: 24,
        borderRadius: 4,
        ...(properties?.[clientPackageType] && {
          ...properties?.[clientPackageType],
        }),
      }}
    >
      {title}
    </div>
  );
};

export default ClientPackageBadge;
