import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { format, parse } from "date-fns";
import colors from "../../assets/colors";
import {
  ZoomCall,
  ZoomCallType,
  CalendarItemStatusType,
  MutationCreateUpdateZoomCallArgs,
  Client,
} from "../../types/gqlTypes";
import { useKeyPress } from "../../utils/customHooks";
import ButtonTag from "../ButtonTag";
import Dropdown from "../Dropdown";
import "react-datepicker/dist/react-datepicker.css";
import { createUpdateZoomCall } from "../../redux/actions/ClientDetail";
import { StoreState } from "../../redux/reducers";

interface Props {
  show?: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
  confirmButtonColor?: string;
  cancelButtonColor?: string;
  closeOnDimmerClick?: boolean;
  onCancel?: () => void;
  zoomCall: ZoomCall;
  dispatchCreateUpdateZoomCall: (MutationCreateUpdateZoomCallArgs) => ZoomCall;
  addMode: boolean;
  editMode: boolean;
  client: Client;
}

interface DateInputFieldProps {
  value?: string;
  onClick?: (date?: any) => void;
}

const EditDialog = (props: Props) => {
  const {
    show,
    confirmButtonText,
    cancelButtonText,
    confirmButtonColor,
    cancelButtonColor,
    closeOnDimmerClick,
    onCancel,
    zoomCall,
    dispatchCreateUpdateZoomCall,
    editMode,
    client,
  } = props;

  useKeyPress("Enter", () => {
    onConfirm?.();
  });
  useKeyPress("Escape", () => {
    onCancel?.();
  });

  const dateFormatMask = "MM.dd.yyyy";
  const [localStatus, setLocalStatus] = useState(
    zoomCall?.status || CalendarItemStatusType.Scheduled
  );
  const [localType, setLocalType] = useState(
    zoomCall?.type || ZoomCallType.Strategy
  );
  const parsedDate = zoomCall?.date
    ? parse(zoomCall.date?.slice(0, 10) || null, "yyyy-MM-dd", new Date())
    : new Date();
  const [localDate, setLocalDate] = useState(
    format(parsedDate, dateFormatMask) || null
  );
  const [localId] = useState(zoomCall?.id || null);

  useEffect(() => {
    setLocalStatus(zoomCall?.status);
    setLocalType(zoomCall?.type);
  }, [zoomCall]);

  const zoomCallTypeOptions = [
    { text: "Intro", value: ZoomCallType.Intro },
    { text: "Strategy", value: ZoomCallType.Strategy },
  ];

  const zoomCallStatusTypeOptions = [
    { text: "Scheduled", value: CalendarItemStatusType.Scheduled },
    { text: "Completed", value: CalendarItemStatusType.Completed },
  ];

  const DateInputField = (props: DateInputFieldProps) => {
    return (
      <div
        role="button"
        tabIndex={0}
        onClick={(a: any) => {
          if (props?.onClick) {
            props.onClick();
          }
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter" && props?.onClick) {
            props?.onClick();
          }
        }}
        className="d-flex flex-column justify-content-center align-items-center medium-bold pointer"
        style={{
          backgroundColor: colors.caliber_gray_bg,
          color: colors.caliber_gray_text,
          padding: "0px 35px 0px 20px",
          borderRadius: 8,
          minWidth: 90,
          margin: 0,
          fontSize: "18px",
          height: "36px",
        }}
      >
        {localDate || "--"}
      </div>
    );
  };

  const onConfirm = () => {
    dispatchCreateUpdateZoomCall({
      calendarItemStatusType: localStatus,
      clientId: client.id,
      date: parse(localDate, dateFormatMask, new Date()),
      zoomCallId: localId,
      zoomCallType: localType,
    });
  };

  return (
    <Modal
      centered
      show={show}
      onHide={closeOnDimmerClick ? onCancel : () => {}}
    >
      <div
        className="d-flex flex-column"
        style={{ padding: "25px 15px 15px 15px", minHeight: 100 }}
      >
        {show && (
          <div
            className="d-flex flex-column justify-content-center align-items-center paragraph-large"
            style={{
              flex: 1,
              marginTop: 3,
              marginBottom: 3,
              textAlign: "center",
              whiteSpace: "pre-line",
              color: colors.caliber_secondary,
              lineHeight: "24px",
              fontSize: "18px",
            }}
          >
            {editMode ? "Edit Zoom Call" : "Add Zoom Call"}
            <div
              className="col-16 flex-grow-1"
              style={{
                marginTop: 25,
              }}
            >
              <div
                className="d-flex justify-content-between align-items-center"
                style={{
                  margin: "20px 9px 0px 13px",
                }}
              >
                <div
                  className="medium-bold"
                  style={{
                    fontSize: "12px",
                    lineHeight: "16px",
                  }}
                >
                  Type
                </div>

                <div
                  className="medium-bold"
                  style={{
                    padding: "1px 10px",
                    borderRadius: 4,
                    maxWidth: 200,
                  }}
                >
                  <Dropdown
                    height={36}
                    value={localType}
                    items={zoomCallTypeOptions}
                    onSelect={(value) => {
                      setLocalType(value);
                    }}
                    textColor={colors.caliber_gray_text}
                    bgColor={colors.caliber_light_gray}
                    borderColor={colors.caliber_light_gray}
                  />
                </div>
              </div>

              <div
                className="d-flex justify-content-between align-items-center"
                style={{
                  margin: "20px 9px 0px 13px",
                }}
              >
                <div
                  className="medium-bold"
                  style={{
                    fontSize: "12px",
                    lineHeight: "16px",
                  }}
                >
                  Date
                </div>

                <div
                  className="medium-bold"
                  style={{
                    padding: "1px 10px",
                    borderRadius: 4,
                    maxWidth: 200,
                  }}
                >
                  <DatePicker
                    onChange={(date) => {
                      setLocalDate(format(date, dateFormatMask));
                    }}
                    customInput={<DateInputField />}
                    selected={parsedDate}
                  />
                </div>
              </div>

              <div
                className="d-flex justify-content-between align-items-center"
                style={{
                  margin: "20px 9px 0px 13px",
                }}
              >
                <div
                  className="medium-bold"
                  style={{
                    fontSize: "12px",
                    lineHeight: "16px",
                  }}
                >
                  Status
                </div>

                <div
                  className="medium-bold"
                  style={{
                    padding: "1px 10px",
                    borderRadius: 4,
                    maxWidth: 200,
                  }}
                >
                  <Dropdown
                    height={36}
                    value={localStatus}
                    items={zoomCallStatusTypeOptions}
                    onSelect={(value) => setLocalStatus(value)}
                    textColor={colors.caliber_gray_text}
                    bgColor={colors.caliber_light_gray}
                    borderColor={colors.caliber_light_gray}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          className="d-flex justify-content-center"
          style={{
            height: 62,
          }}
        >
          {onCancel && (
            <ButtonTag
              margin={6}
              text={cancelButtonText || "Leave"}
              onClick={({ event }) => {
                event.stopPropagation();
                onCancel();
              }}
              height={24}
              textColor={colors.caliber_white}
              bgColor={cancelButtonColor || colors.caliber_gray_text}
              minWidth={91}
            />
          )}
          <ButtonTag
            margin={6}
            text={confirmButtonText || "Delete"}
            onClick={({ event }) => {
              event.stopPropagation();
              onConfirm();
              onCancel();
            }}
            height={24}
            textColor={colors.caliber_white}
            bgColor={confirmButtonColor || colors.caliber_green_200}
            minWidth={91}
          />
        </div>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  dispatchCreateUpdateZoomCall: (args: MutationCreateUpdateZoomCallArgs) => {
    dispatch(createUpdateZoomCall(args));
  },
});

const mapStateToProps = (state: StoreState) => ({
  client: state.clientDetailState.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditDialog);
