import SendBird from "sendbird";
import { Client, User } from "../../../types/gqlTypes";
import {
  sbGetGroupChannelList,
  sbSendFileMessage,
  sbSendTextMessage,
} from "../Messages";
import { MESSAGES_SEND_MESSAGE_SUCCESS } from "../Messages/types";
import {
  DISCARD_COMPARISON_IMAGE,
  SAVE_COMPARISON_IMAGE,
  UPLOAD_COMPARISON_IMAGE_FAIL,
  UPLOAD_COMPARISON_IMAGE_SUCCESS,
  UPLOAD_COMPARISON_IMAGE_UPLOADING,
} from "./types";

export const saveComparisonImage =
  (userId: string, img: any) => async (dispatch, getState) => {
    dispatch({
      type: SAVE_COMPARISON_IMAGE,
      userId,
      img,
    });
  };
export const discardComparisonImage =
  (userId: string) => async (dispatch, getState) => {
    dispatch({
      type: DISCARD_COMPARISON_IMAGE,
      userId,
    });
  };
const b64toBlob = (b64Data: any, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};
export const sendProgressComparisonPhotoToClient =
  ({ file, user, message }: { file: any; user: User; message?: string }) =>
  async (dispatch, getState) => {
    if (getState().comparisonToolState?.isUploading) {
      return;
    }
    dispatch({
      type: UPLOAD_COMPARISON_IMAGE_UPLOADING,
    });
    const clientId = user?.id;
    const trainerId = (user as Client)?.assignedTrainer?.id;
    try {
      const sb = SendBird.getInstance();
      const groupChannelQuery =
        sb?.GroupChannel?.createMyGroupChannelListQuery();
      if (groupChannelQuery) {
        groupChannelQuery.includeEmpty = true;
        groupChannelQuery.userIdsIncludeFilter = [trainerId, clientId];
        const channels = await sbGetGroupChannelList(groupChannelQuery);
        const activeChannel = channels[0];

        const fileParams = new sb.FileMessageParams();
        fileParams.file = b64toBlob(file?.split(",")?.[1]) as any;
        fileParams.mimeType = "image/jpeg";

        const sentMessage = await sbSendFileMessage(activeChannel, fileParams);

        dispatch({
          type: MESSAGES_SEND_MESSAGE_SUCCESS,
          message: sentMessage,
          channel: activeChannel,
        });
        if (message) {
          const sentMessage = await sbSendTextMessage(activeChannel, message);
          dispatch({
            type: MESSAGES_SEND_MESSAGE_SUCCESS,
            message: sentMessage,
            channel: activeChannel,
          });
        }

        dispatch({
          type: UPLOAD_COMPARISON_IMAGE_SUCCESS,
          userId: clientId,
        });
      }
    } catch (error) {
      dispatch({
        type: UPLOAD_COMPARISON_IMAGE_FAIL,
        data: error,
      });
    }
  };
