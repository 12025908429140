import React, { useCallback, useEffect, useState } from "react";
import { useDrop } from "react-dnd";
import colors from "../../assets/colors";
import ConfirmationDialog from "../ConfirmationDialog";
import AddTraitButton from "./AddTraitButton";
import TraitInput from "./TraitInput";
import TraitItem from "./TraitItem";

export enum TraitType {
  EDUCATION = "EDUCATION",
  CERTIFICATION = "CERTIFICATION",
}
interface Props {
  traitType: TraitType;
  title: string;
  items: string[];
  onChange: (items: string[]) => void;
}

const TraitSection = (props: Props) => {
  const { traitType, title, items, onChange } = props;

  const [localItems, setLocalItems] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(null);

  const placeholders = {
    [TraitType.EDUCATION]: "Add education",
    [TraitType.CERTIFICATION]: "Add certification",
  };
  const addButtonTexts = {
    [TraitType.EDUCATION]: "+ Add Education",
    [TraitType.CERTIFICATION]: "+ Add Certification",
  };

  const onDelete = (index) => {
    const items = localItems.filter((_, itemIndex) => itemIndex !== index);
    setLocalItems(items);
    onChange(items);
    setConfirmDelete(null);
  };

  const moveCard = (id: string, atIndex: number) => {
    const { index } = findCard(id);
    const items = [...localItems];
    if (index > -1) {
      const temp = items[index];
      items[index] = items[atIndex];
      items[atIndex] = temp;
      setLocalItems(items);
    }
  };
  const findCard = (text: string) => {
    return {
      index: localItems.indexOf(text),
    };
  };

  const onDrop = useCallback(
    (item: any, monitor) => {
      onChange(localItems);
    },
    [localItems]
  );

  const [{ isOver, didDrop }, drop] = useDrop({
    accept: traitType,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      didDrop: monitor.didDrop(),
    }),
  });

  useEffect(() => {
    if (!isOver && !didDrop && items) {
      setLocalItems([...items]);
    }
  }, [items, isOver]);

  const addNewTrait = () => {
    if (inputValue?.length > 0) {
      onChange([...localItems, inputValue]);
      setInputValue("");
      document?.getElementById(`trait-input-${traitType}`)?.focus();
    }
  };

  return (
    <div className="d-flex flex-column">
      {typeof confirmDelete === "number" && (
        <ConfirmationDialog
          show
          text={`Are you sure you want to delete the ${title} item ${
            confirmDelete + 1
          } ?`}
          cancelButtonText="Cancel"
          onCancel={() => setConfirmDelete(null)}
          onConfirm={() => onDelete(confirmDelete)}
        />
      )}
      <div
        className="d-flex align-items-center bold"
        style={{
          fontSize: "18px",
          lineHeight: "18px",
          letterSpacing: "-0.022em",
          color: colors.caliber_secondary,
          marginBottom: 24,
        }}
      >
        {title}
      </div>

      <div ref={drop} className="d-flex flex-column">
        {localItems.map((text, index) => {
          return (
            <TraitItem
              key={`${traitType}-${text}`}
              traitType={traitType}
              text={text}
              isDraggable
              editable={!isOver}
              onDelete={() => setConfirmDelete(index)}
              moveCard={moveCard}
              findCard={findCard}
            />
          );
        })}
      </div>

      <TraitInput
        id={`trait-input-${traitType}`}
        placeholder={placeholders[traitType]}
        value={inputValue}
        onChange={(value) => setInputValue(value)}
        onEnter={addNewTrait}
      />

      <AddTraitButton
        text={addButtonTexts[traitType]}
        disabled={inputValue?.length === 0}
        onClick={addNewTrait}
      />
    </div>
  );
};

export default TraitSection;
