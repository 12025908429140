import React, { useMemo, useCallback, useState, useEffect } from "react";
import { format, parse, eachDayOfInterval } from "date-fns";
import { Line } from "react-chartjs-2";
import colors from "../../assets/colors";
import {
  ClientWorkout,
  ClientExerciseStatisticType,
  ExerciseTargetBaseType,
  Exercise,
  ClientSupersetGymStep,
  ClientGymStep,
} from "../../types/gqlTypes";
import Dropdown from "../Dropdown";
import DeleteIcon from "../../assets/images/DeleteSolidIcon.svg";

interface Props {
  clientWorkoutHistory: ClientWorkout[];
  selectedExercise: Exercise;
  isExerciseHiddenWeight: boolean;
  closeChart: () => void;
}

const TrainingProgressChart = (props: Props) => {
  const {
    clientWorkoutHistory,
    selectedExercise,
    isExerciseHiddenWeight,
    closeChart,
  } = props;
  const [chartType, setChartType] = useState<ClientExerciseStatisticType>(null);
  const isExerciseTimeBased = (exercise: Exercise) =>
    exercise?.targetBase === ExerciseTargetBaseType.Time;

  useEffect(() => {
    if (isExerciseTimeBased(selectedExercise)) {
      setChartType(ClientExerciseStatisticType.MaxTime);
    } else if (isExerciseHiddenWeight) {
      setChartType(ClientExerciseStatisticType.MaxReps);
    } else {
      setChartType(ClientExerciseStatisticType.EstimatedOneRepMax);
    }
  }, [selectedExercise]);

  const lineColor = "#3446FB";

  const datasetOptions = {
    fill: true,
    borderColor: lineColor,
    borderCapStyle: "butt",
    borderDash: [],
    borderDashOffset: 0.0,
    pointBorderColor: lineColor,
    pointBackgroundColor: lineColor,
    pointHoverRadius: 6,
    pointHoverBackgroundColor: lineColor,
    pointHoverBorderColor: lineColor,
    pointRadius: 4,
    pointHitRadius: 4,
  };

  const dropdownTypeOptions = useMemo(() => {
    const array = [];
    if (isExerciseTimeBased(selectedExercise) && isExerciseHiddenWeight) {
      array.push({
        text: "Max Time",
        value: ClientExerciseStatisticType.MaxTime,
      });
    } else if (isExerciseTimeBased(selectedExercise)) {
      array.push({
        text: "Max Time",
        value: ClientExerciseStatisticType.MaxTime,
      });
      array.push({
        text: "Max Weight",
        value: ClientExerciseStatisticType.MaxWeightEver,
      });
    } else if (isExerciseHiddenWeight) {
      array.push({
        text: "Max Reps",
        value: ClientExerciseStatisticType.MaxReps,
      });
    } else {
      array.push({
        text: "Estimated 1 Rep Max",
        value: ClientExerciseStatisticType.EstimatedOneRepMax,
      });
      array.push({
        text: "Max Weight",
        value: ClientExerciseStatisticType.MaxWeightEver,
      });
      array.push({
        text: "Max Volume",
        value: ClientExerciseStatisticType.MaxVolume,
      });
      array.push({
        text: "Max Reps",
        value: ClientExerciseStatisticType.MaxReps,
      });
    }
    return array;
  }, [selectedExercise, clientWorkoutHistory]);

  const normalizedData = useMemo(() => {
    const data = {};

    const createNormalizedData = (gymStep: ClientGymStep, date: Date) => {
      if (!data?.[gymStep?.exercise?.id]) {
        data[gymStep.exercise.id] = {};
        data[gymStep.exercise.id][ClientExerciseStatisticType.MaxReps] = {
          x: [],
          y: [],
          maxValue: 0,
        };
        data[gymStep.exercise.id][
          ClientExerciseStatisticType.EstimatedOneRepMax
        ] = { x: [], y: [], maxValue: 0 };
        data[gymStep.exercise.id][ClientExerciseStatisticType.MaxTime] = {
          x: [],
          y: [],
          maxValue: 0,
        };
        data[gymStep.exercise.id][ClientExerciseStatisticType.MaxVolume] = {
          x: [],
          y: [],
          maxValue: 0,
        };
        data[gymStep.exercise.id][ClientExerciseStatisticType.MaxWeightEver] = {
          x: [],
          y: [],
          maxValue: 0,
        };
      }

      gymStep.maxGymStepStatistics.forEach(({ value, type }) => {
        if (!data?.[gymStep?.exercise?.id]?.[type]) {
          return;
        }
        if (data[gymStep.exercise.id][type].x.length > 0) {
          const prevDate =
            data?.[gymStep?.exercise?.id]?.[type]?.x[
              data?.[gymStep?.exercise?.id]?.[type]?.x.length - 1
            ];
          const range = eachDayOfInterval({ start: prevDate, end: date });
          if (range.length > 2) {
            range.forEach((rangeDate, index) => {
              if (index > 0 && index !== range.length - 1) {
                data?.[gymStep?.exercise?.id]?.[type]?.x.push(rangeDate);
                data?.[gymStep?.exercise?.id]?.[type]?.y.push(null);
              }
            });
          }
        }
        data[gymStep.exercise.id][type].x.push(date);
        data[gymStep.exercise.id][type].y.push(Math.round(value * 10) / 10);
        data[gymStep.exercise.id][type].maxValue = Math.max(
          data[gymStep.exercise.id][type].maxValue,
          Math.round(value * 10) / 10
        );
      });
    };

    clientWorkoutHistory?.forEach((_, workoutIndex) => {
      const workout =
        clientWorkoutHistory[clientWorkoutHistory.length - 1 - workoutIndex];
      const {
        gymSteps,
        workoutCalendarItem: { clientWorkoutDate },
      } = workout;
      const date = parse(clientWorkoutDate, "yyyy-MM-dd", new Date());
      gymSteps?.forEach((gymStep) => {
        const isSuperset: boolean = gymStep.exercise == null;
        const clientGymStep: ClientGymStep = gymStep;
        let superset: ClientSupersetGymStep;
        if (isSuperset) {
          superset = gymStep;
          superset.clientGymSteps.forEach((clientGymStep) => {
            createNormalizedData(clientGymStep, date);
          });
        } else {
          createNormalizedData(clientGymStep, date);
        }
      });
    });
    return data;
  }, [clientWorkoutHistory]);

  const chartOptions = useMemo(() => {
    let stepSize = 50;
    let label = "lbs";
    let yAxisLabel = "Weight, lbs";
    let max;
    let reminder;
    switch (chartType) {
      case ClientExerciseStatisticType.EstimatedOneRepMax:
        reminder =
          (normalizedData?.[selectedExercise?.id]?.[chartType]?.maxValue || 0) %
          stepSize;
        max =
          (normalizedData?.[selectedExercise?.id]?.[chartType]?.maxValue || 0) +
          (3 * stepSize - reminder);
        break;
      case ClientExerciseStatisticType.MaxVolume:
        reminder =
          (normalizedData?.[selectedExercise?.id]?.[chartType]?.maxValue || 0) %
          stepSize;
        max =
          (normalizedData?.[selectedExercise?.id]?.[chartType]?.maxValue || 0) +
          (3 * stepSize - reminder);
        break;
      case ClientExerciseStatisticType.MaxWeightEver:
        reminder =
          (normalizedData?.[selectedExercise?.id]?.[chartType]?.maxValue || 0) %
          stepSize;
        max =
          (normalizedData?.[selectedExercise?.id]?.[chartType]?.maxValue || 0) +
          (3 * stepSize - reminder);
        break;
      case ClientExerciseStatisticType.MaxReps:
        stepSize = 5;
        label = "reps";
        yAxisLabel = "Reps";
        reminder =
          (normalizedData?.[selectedExercise?.id]?.[chartType]?.maxValue || 0) %
          stepSize;
        max =
          (normalizedData?.[selectedExercise?.id]?.[chartType]?.maxValue || 0) +
          (3 * stepSize - reminder);
        break;
      case ClientExerciseStatisticType.MaxTime:
        stepSize = 1;
        label = "min";
        yAxisLabel = "Time, min";
        const actualTime =
          Math.round(
            (normalizedData?.[selectedExercise?.id]?.[chartType]?.maxValue /
              60) *
              10
          ) / 10;
        reminder = actualTime % stepSize;
        max = actualTime + (3 * stepSize - reminder);
        break;
    }
    const options = {
      animation: {
        duration: 1,
      },
      spanGaps: true,
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      layout: {
        padding: {
          top: 60,
        },
      },
      scales: {
        xAxes: [
          {
            ticks: {
              minRotation: 0,
              maxRotation: 0,
              callback: (tick, ind) => {
                let divider = 1;
                if (
                  normalizedData?.[selectedExercise?.id]?.[chartType]?.x
                    ?.length > 28
                ) {
                  divider = 7;
                } else if (
                  normalizedData?.[selectedExercise?.id]?.[chartType]?.x
                    ?.length > 14
                ) {
                  divider = 3;
                } else if (
                  normalizedData?.[selectedExercise?.id]?.[chartType]?.x
                    ?.length > 6
                ) {
                  divider = 2;
                }
                return ind % divider ? "" : tick;
              },
            },
            scaleLabel: {
              display: true,
              labelString: "Date",
              fontFamily: "Circular-Medium, serif",
              fontColor: "#AEB1BF",
            },
            gridLines: {
              display: false,
            },
          },
          {
            position: "top",
            ticks: {
              display: false,
            },
            gridLines: {
              display: false,
              drawTicks: false,
            },
          },
        ],
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: yAxisLabel,
              fontFamily: "Circular-Medium, serif",
              fontColor: "#AEB1BF",
            },
            gridLines: {
              tickMarkLength: 0,
            },
            ticks: {
              min: 0,
              max,
              stepSize,
              padding: 20,
              beginAtZero: false,
            },
          },
          {
            position: "right",
            ticks: { display: false },
            gridLines: {
              display: false,
              drawTicks: false,
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          labelColor: () => ({}),
          labelTextColor: () => colors.caliber_secondary,
          label: (item) => {
            let value;
            if (label === "min") {
              const time = [];
              const actualTime =
                normalizedData?.[selectedExercise?.id]?.[chartType]?.y?.[
                  item?.index
                ];
              const min = Math.floor((actualTime || 0) / 60);
              const sec = (actualTime || 0) - min * 60;
              if (min > 0) {
                time.push(`${min}`);
                sec > 0
                  ? time.push(`:${sec > 9 ? "" : "0"}${sec} mins`)
                  : time.push(`${min === 1 ? " min" : " mins"}`);
              } else {
                sec === 1 ? time.push(`${sec} sec`) : time.push(`${sec} secs`);
              }
              value = time.join("");
            } else {
              value = `${Math.round(item.value)} ${label}`;
            }
            return value;
          },
          displayColors: () => false,
          footer: (items) => items?.[0]?.label,
          title: () => null,
        },
        footerFontColor: colors.caliber_gray_47,
        footerFontFamily: "Circular, serif",
        bodyFontFamily: "Circular-Medium, serif",
        displayColors: false,
        caretPadding: 15,
        caretSize: 7,
        yAlign: "bottom",
        xAlign: "center",
        footerFontSize: 13,
        bodyFontSize: 17,
        backgroundColor: colors.caliber_white,
        xPadding: 14,
        yPadding: 10,
        footerAlign: "center",
        bodyAlign: "center",
      },
    };
    return options;
  }, [normalizedData, selectedExercise, chartType]);

  const chartData = useCallback(
    (canvas) => {
      const data = {
        labels: [],
        datasets: [
          {
            ...datasetOptions,
            backgroundColor: "transparent",
            data: [],
          },
        ],
      };
      normalizedData?.[selectedExercise?.id]?.[chartType]?.x?.map(
        (date, index) => {
          data.labels.push(format(date, "MMM d"));
          const val = normalizedData[selectedExercise?.id][chartType].y[index];
          if (
            isExerciseTimeBased(selectedExercise) &&
            chartType === ClientExerciseStatisticType.MaxTime &&
            typeof val === "number"
          ) {
            data.datasets[0].data.push(Math.round((val / 60) * 10) / 10);
          } else {
            data.datasets[0].data.push(val);
          }
        }
      );
      return data;
    },
    [normalizedData, selectedExercise, chartType]
  );

  const chartHeight = Math.max(window.innerHeight - 415, 300);
  const chartWidth =
    window.innerWidth > 1100
      ? window.innerWidth - 635
      : Math.max(window.innerWidth - 480, 400);

  return (
    <div
      className="d-flex flex-column"
      style={{
        backgroundColor: colors.caliber_white,
        padding: 24,
        borderRadius: 8,
        marginLeft: 15,
      }}
    >
      <div
        className="d-flex justify-content-between"
        style={{ marginBottom: 25 }}
      >
        <div style={{ width: 210 }}>
          <Dropdown
            value={chartType}
            items={dropdownTypeOptions}
            onSelect={(value) => setChartType(value)}
            height={36}
            width={256}
          />
        </div>
        <div
          className="pointer nofocus"
          role="button"
          tabIndex={0}
          onClick={closeChart}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              closeChart();
            }
          }}
        >
          <img src={DeleteIcon} />
        </div>
      </div>
      <div
        style={{
          flex: 1,
          backgroundColor: colors.caliber_gray_bg,
          maxWidth: chartWidth,
          maxHeight: chartHeight,
          width: chartWidth,
          height: chartHeight,
          padding: "0px 20px 0px 10px",
          borderRadius: 8,
        }}
      >
        <Line
          width={chartWidth}
          height={chartHeight}
          options={chartOptions}
          data={chartData}
        />
      </div>
    </div>
  );
};

export default TrainingProgressChart;
