import React, { useMemo } from "react";
import { connect } from "react-redux";
import colors from "../../assets/colors";
import { searchTrainers } from "../../redux/actions/AdminTeamTrainers";
import { AdminSearchTrainersState } from "../../redux/actions/AdminTeamTrainers/types";
import { resetClientSearchFilters } from "../../redux/actions/ClientSearch";
import {
  clearMasquerade,
  masqueradeAsTrainer,
} from "../../redux/actions/Masquerade";
import { MasqueradeState } from "../../redux/actions/Masquerade/types";
import { StoreState } from "../../redux/reducers";
import { Trainer, UserStatusType } from "../../types/gqlTypes";
import ButtonTag from "../ButtonTag";
import ProfilePhoto from "../ProfilePhoto";
import SearchDropdown, { DropdownItem } from "../SearchDropdown";

interface Props {
  searchTrainerState: AdminSearchTrainersState;
  masqueradeState: MasqueradeState;
  searchTrainers: (page: number, searchCriteria: string) => void;
  masqueradeAsTrainer: (trainer: Trainer) => void;
  clearMasquerade: () => void;
  resetClientSearchFilters: () => void;
}

const MasqueradeComponent = (props: Props) => {
  const {
    searchTrainerState,
    masqueradeState,
    searchTrainers,
    masqueradeAsTrainer,
    clearMasquerade,
    resetClientSearchFilters,
  } = props;

  const { foundTrainers } = searchTrainerState;

  const searchItems: DropdownItem[] = useMemo(() => {
    return foundTrainers.map((trainer) => ({
      text: `${trainer.firstName} ${trainer.lastName}`,
      value: trainer.id,
      thumbnailUrl: trainer.profileIconMediaUrl?.url,
    }));
  }, [foundTrainers]);

  return (
    <div
      className="d-flex flex-row justify-content-center"
      style={{
        marginLeft: 40,
      }}
    >
      <div
        style={{
          width: 40,
          marginRight: 14,
        }}
      >
        {masqueradeState.masqueradeTrainer && (
          <ProfilePhoto
            imageUrl={
              masqueradeState.masqueradeTrainer.profileIconMediaUrl?.url
            }
            size={40}
            bgColor={colors.caliber_blue}
          />
        )}
      </div>

      <div
        style={{
          width: 311,
        }}
      >
        <SearchDropdown
          placeholder="Log in as Coach..."
          height={40}
          items={searchItems}
          search={searchTrainers}
          value={masqueradeState.masqueradeTrainer?.id}
          page={searchTrainerState.page}
          totalPages={searchTrainerState.totalPages}
          onSelect={(trainerId: string) => {
            const trainer = foundTrainers.find(
              (trainer) => trainer.id === trainerId
            );
            masqueradeAsTrainer(trainer);
            resetClientSearchFilters();
          }}
          showThumbnails
          textColor={
            masqueradeState.masqueradeTrainer ? colors.caliber_white : null
          }
          bgColor={masqueradeState.masqueradeTrainer ? "#191d42" : null}
        />
      </div>
      {masqueradeState.masqueradeTrainer && (
        <div
          className="d-flex justify-content-center"
          style={{
            marginLeft: 14,
          }}
        >
          <ButtonTag
            text="Log Out"
            onClick={() => {
              clearMasquerade();
              resetClientSearchFilters();
            }}
            bgColor="#343759"
            textColor={colors.caliber_white}
            height={40}
            minWidth={70}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  searchTrainerState: state.adminSearchTrainersState,
  masqueradeState: state.masqueradeState,
});

const mapDispatchToProps = (dispatch) => ({
  searchTrainers: (page: number, searchCriteria: string) => {
    dispatch(
      searchTrainers(
        page,
        searchCriteria,
        UserStatusType[(UserStatusType.Active, UserStatusType.Inactive)]
      )
    );
  },
  masqueradeAsTrainer: (trainer: Trainer) => {
    dispatch(masqueradeAsTrainer(trainer));
  },
  clearMasquerade: () => {
    dispatch(clearMasquerade());
  },
  resetClientSearchFilters: () => {
    dispatch(resetClientSearchFilters());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MasqueradeComponent);
