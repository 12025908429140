import React from "react";

interface Props {
  defaultColor: string;
  legColor: string;
  chestColor: string;
  armColor: string;
  shoulderColor: string;
  skeletonSize: {
    width: number;
    height: number;
  };
}

const FrontMaleSkeleton = (props: Props) => {
  const {
    defaultColor,
    legColor,
    chestColor,
    armColor,
    shoulderColor,
    skeletonSize: { width, height },
  } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 218 431"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <FrontMaleHead color={defaultColor} />
      <FrontMaleNeck color={defaultColor} />
      <FrontMaleTraps color={defaultColor} />

      <FrontMaleUpperChest color={chestColor} />
      <FrontMaleMidChest color={chestColor} />

      <FrontMaleShoulders color={shoulderColor} />

      <FrontMaleTriceps color={armColor} />
      <FrontMaleBrachialis color={armColor} />
      <FrontMaleForearms color={armColor} />

      <FrontMaleAbs color={defaultColor} />
      <FrontMaleObliques color={defaultColor} />

      <FrontMaleHands color={defaultColor} />

      <FrontMaleBottomLegs color={legColor} />
      <FrontMaleQuads color={legColor} />
      <FrontMaleHipFlexors color={legColor} />

      <FrontMaleFeet color={defaultColor} />
    </svg>
  );
};

const FrontMaleAbs = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M91.1563 120.596L89.0215 124.085L89.7978 131.454L99.108 125.831L104.927 125.249L105.704 115.556L91.1563 120.596Z"
        fill={color}
      />
      <path
        d="M90.3796 135.719L90.1855 144.831L95.4229 142.312L105.121 141.536L105.509 129.32L90.3796 135.719Z"
        fill={color}
      />
      <path
        d="M90.185 149.096L89.7969 158.21L96.0046 158.404L105.315 162.669L105.897 147.546L90.185 149.096Z"
        fill={color}
      />
      <path
        d="M91.3496 163.639L96.781 197.954L105.315 203.771L106.285 174.108L103.959 165.382L91.3496 163.639Z"
        fill={color}
      />
      <path
        d="M127.245 120.596L129.38 124.085L128.603 131.454L119.293 125.831L113.474 125.249L112.697 115.556L127.245 120.596Z"
        fill={color}
      />
      <path
        d="M128.022 135.719L128.216 144.831L122.979 142.312L113.281 141.536L112.893 129.32L128.022 135.719Z"
        fill={color}
      />
      <path
        d="M128.216 149.096L128.604 158.21L122.396 158.404L113.086 162.669L112.504 147.546L128.216 149.096Z"
        fill={color}
      />
      <path
        d="M127.051 163.639L121.619 197.954L113.086 203.771L112.115 174.108L114.444 165.382L127.051 163.639Z"
        fill={color}
      />
    </>
  );
};

const FrontMaleBottomLegs = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M131.317 301.488L131.9 306.723L127.827 311.764L126.468 319.712L123.754 320.876L121.425 308.854L119.875 304.589L121.231 301.876L131.317 301.488Z"
        fill={color}
      />
      <path
        d="M113.279 301.876L116.188 310.018L122.396 322.04L120.843 333.091L116.382 320.682L112.697 313.701L113.279 301.876Z"
        fill={color}
      />
      <path
        d="M137.912 295.09L130.734 314.865L135.004 313.122L138.495 312.734L137.912 295.09Z"
        fill={color}
      />
      <path
        d="M116.577 327.856L117.935 332.121L120.844 350.153L121.232 374.193L117.159 397.654L117.353 367.601L113.086 355.582L112.504 342.397L116.577 327.856Z"
        fill={color}
      />
      <path
        d="M125.889 338.714L124.918 374.776L117.158 403.859L125.876 399.592L139.66 349.378L134.811 317.581L125.889 338.714Z"
        fill={color}
      />
      <path
        d="M87.0822 301.488L86.5 306.723L90.5729 311.764L91.9314 319.712L94.6458 320.876L96.9746 308.854L98.5272 304.589L97.1687 301.876L87.0822 301.488Z"
        fill={color}
      />
      <path
        d="M105.12 301.876L102.212 310.018L96.0039 322.04L97.5565 333.091L102.018 320.682L105.702 313.701L105.12 301.876Z"
        fill={color}
      />
      <path
        d="M80.4865 295.09L87.6646 314.865L83.3976 313.122L79.9043 312.734L80.4865 295.09Z"
        fill={color}
      />
      <path
        d="M101.823 327.856L100.465 332.121L97.5561 350.153L97.168 374.193L101.241 397.654L101.047 367.601L105.314 355.582L105.896 342.397L101.823 327.856Z"
        fill={color}
      />
      <path
        d="M92.5134 338.714L93.4838 374.776L101.241 403.859L92.8032 399.884L78.7422 349.378L83.5887 317.581L92.5134 338.714Z"
        fill={color}
      />
    </>
  );
};
const FrontMaleMidChest = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M75.1041 94.4648L67.8809 100.045L87.8598 112.064L106.48 108.963L106.076 99.5136L75.1041 94.4648Z"
        fill={color}
      />
      <path
        d="M143.566 94.4648L150.789 100.045L130.81 112.064L112.19 108.963L112.594 99.5136L143.566 94.4648Z"
        fill={color}
      />
    </>
  );
};
const FrontMaleForearms = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M48.2896 157.822L27.9199 193.495L31.0251 201.058L53.1388 172.168L62.0609 149.095L48.2896 157.822Z"
        fill={color}
      />
      <path
        d="M44.7975 139.402L45.962 156.27L21.5221 196.211L19.7754 195.241L31.8026 172.75L33.3525 161.117L39.1721 144.831L44.7975 139.402Z"
        fill={color}
      />
      <path
        d="M48.8711 150.647L62.4509 136.495L60.7042 145.606L49.8415 153.363L48.8711 150.647Z"
        fill={color}
      />
      <path
        d="M170.115 157.822L190.482 193.495L187.377 201.058L165.266 172.168L156.344 149.095L170.115 157.822Z"
        fill={color}
      />
      <path
        d="M173.606 139.402L172.441 156.27L196.884 196.211L198.628 195.241L186.601 172.75L185.051 161.117L179.231 144.831L173.606 139.402Z"
        fill={color}
      />
      <path
        d="M169.532 150.647L155.955 136.495L157.699 145.606L168.562 153.363L169.532 150.647Z"
        fill={color}
      />
    </>
  );
};
const FrontMaleNeck = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M95.2285 47.1157L103.959 59.7164L106.285 77.7485L96.9752 62.0441L95.2285 47.1157Z"
        fill={color}
      />
      <path
        d="M123.172 47.1157L114.444 59.7164L112.115 77.7485L121.425 62.0441L123.172 47.1157Z"
        fill={color}
      />
    </>
  );
};
const FrontMaleObliques = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M86.3069 156.076L86.8891 177.791L89.9916 185.739L88.8298 188.842L75.25 178.179L76.8026 149.677L86.3069 156.076Z"
        fill={color}
      />
      <path
        d="M132.095 156.076L131.513 177.791L128.41 185.739L129.575 188.842L143.152 178.179L141.599 149.677L132.095 156.076Z"
        fill={color}
      />
      <path
        d="M72.9242 107.023L78.5497 111.87L74.6682 113.616L71.7598 112.646L72.9242 107.023Z"
        fill={color}
      />
      <path
        d="M80.8745 111.87L78.1602 114.198L85.9178 117.493L87.4704 115.75L80.8745 111.87Z"
        fill={color}
      />
      <path
        d="M77.385 115.556L86.307 121.178L84.5603 127.768L77.7731 120.015L76.8027 116.332L77.385 115.556Z"
        fill={color}
      />
      <path
        d="M71.9535 114.392L74.6679 116.717L77.9671 123.503L70.7891 121.178L71.9535 114.392Z"
        fill={color}
      />
      <path
        d="M72.9238 124.668L83.2044 137.85L84.1721 129.126L76.0263 124.474L72.9238 124.668Z"
        fill={color}
      />
      <path
        d="M71.3713 125.25L78.5493 137.077L83.5899 142.894L83.0103 150.066L80.6815 149.29L73.312 141.342L70.7891 129.514L71.3713 125.25Z"
        fill={color}
      />
      <path
        d="M145.481 107.023L139.855 111.87L143.734 113.616L146.643 112.646L145.481 107.023Z"
        fill={color}
      />
      <path
        d="M137.526 111.87L140.243 114.198L132.482 117.493L130.93 115.75L137.526 111.87Z"
        fill={color}
      />
      <path
        d="M141.018 115.556L132.094 121.178L133.84 127.768L140.63 120.015L141.598 116.332L141.018 115.556Z"
        fill={color}
      />
      <path
        d="M146.448 114.392L143.734 116.717L140.438 123.503L147.613 121.178L146.448 114.392Z"
        fill={color}
      />
      <path
        d="M145.481 124.668L135.201 137.85L134.23 129.126L142.376 124.474L145.481 124.668Z"
        fill={color}
      />
      <path
        d="M147.031 125.25L139.856 137.077L134.812 142.894L135.395 150.066L137.721 149.29L145.093 141.342L147.613 129.514L147.031 125.25Z"
        fill={color}
      />
    </>
  );
};
const FrontMaleQuads = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M77.3842 186.708L75.0553 241.19L86.8884 294.702L84.7536 294.508L70.9824 246.037L72.7291 183.607L77.3842 186.708Z"
        fill={color}
      />
      <path
        d="M79.1286 209.005L96.5872 248.364L92.5143 296.253H90.3795L77.9668 239.444L79.1286 209.005Z"
        fill={color}
      />
      <path
        d="M99.1068 252.629L94.8398 295.865L101.436 300.13L106.867 280.161L100.853 256.118L99.1068 252.629Z"
        fill={color}
      />
      <path
        d="M141.019 186.708L143.345 241.19L131.512 294.702L133.647 294.508L147.418 246.037L145.674 183.607L141.019 186.708Z"
        fill={color}
      />
      <path
        d="M139.271 209.005L121.812 248.364L125.888 296.253H128.02L140.436 239.444L139.271 209.005Z"
        fill={color}
      />
      <path
        d="M119.293 252.629L123.56 295.865L116.965 300.13L111.533 280.161L117.547 256.118L119.293 252.629Z"
        fill={color}
      />
    </>
  );
};
const FrontMaleShoulders = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M73.5023 76.5841L61.8659 86.8597L55.6582 102.37L71.5642 94.2282L80.4863 76.3901L73.5023 76.5841Z"
        fill={color}
      />
      <path
        d="M144.898 76.5841L156.534 86.8597L162.742 102.37L146.836 94.2282L137.914 76.3901L144.898 76.5841Z"
        fill={color}
      />
      <path
        d="M70.9819 76.0023L58.9547 86.2779L52.1674 102.176L51.7793 87.4418L60.8954 75.4204L70.9819 76.0023Z"
        fill={color}
      />
      <path
        d="M147.418 76.0023L159.445 86.2779L166.235 102.176L166.621 87.4418L157.504 75.4204L147.418 76.0023Z"
        fill={color}
      />
    </>
  );
};
const FrontMaleTraps = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M94.8405 61.8501L99.8838 70.9618H77.9668L94.8405 61.8501Z"
        fill={color}
      />
      <path
        d="M123.561 61.8501L118.518 70.9618H140.437L123.561 61.8501Z"
        fill={color}
      />
    </>
  );
};
const FrontMaleUpperChest = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M84.2666 76.0049L76.168 92.4722L106.077 96.9895L105.319 80.4952L89.4814 75.4658L84.2666 76.0049Z"
        fill={color}
      />
      <path
        d="M134.403 76.0049L142.502 92.4722L112.593 96.9895L113.351 80.4952L129.188 75.4658L134.403 76.0049Z"
        fill={color}
      />
    </>
  );
};
const FrontMaleBrachialis = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M49.4512 130.484L51.3919 141.729L60.1199 135.719L67.1039 121.76L69.4301 106.635L64.1928 102.758L55.6589 106.053L49.4512 130.484Z"
        fill={color}
      />
      <path
        d="M168.95 130.484L167.009 141.729L158.281 135.719L151.297 121.76L148.971 106.635L154.208 102.758L162.742 106.053L168.95 130.484Z"
        fill={color}
      />
    </>
  );
};
const FrontMaleHipFlexors = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M84.9462 192.913L95.4208 202.607L103.569 210.557L96.1971 222.579L91.7361 209.587L84.1699 196.402L84.9462 192.913Z"
        fill={color}
      />
      <path
        d="M106.091 213.076L98.5273 224.904L101.436 240.802L106.673 230.332L106.091 213.076Z"
        fill={color}
      />
      <path
        d="M133.452 192.913L122.978 202.607L114.832 210.557L122.202 222.579L126.663 209.587L134.229 196.402L133.452 192.913Z"
        fill={color}
      />
      <path
        d="M112.309 213.076L119.875 224.904L116.964 240.802L111.727 230.332L112.309 213.076Z"
        fill={color}
      />
      <path
        d="M80.2917 192.913L99.3003 235.373L102.405 255.924L80.0977 205.71L80.2917 192.913Z"
        fill={color}
      />
      <path
        d="M138.108 192.913L119.099 235.373L115.994 255.924L138.302 205.71L138.108 192.913Z"
        fill={color}
      />
    </>
  );
};
const FrontMaleTriceps = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M51.5852 109.157L47.5123 128.738L50.2267 144.637L43.8249 134.943L43.6309 120.403L51.5852 109.157Z"
        fill={color}
      />
      <path
        d="M166.814 109.157L170.89 128.738L168.173 144.637L174.575 134.943L174.769 120.403L166.814 109.157Z"
        fill={color}
      />
    </>
  );
};
const FrontMaleHead = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M126.662 19.9719L125.113 6.20467L115.8 0H102.6L93.2899 6.20467L91.7373 19.9719L88.6348 22.6849L89.9906 30.4414L93.678 33.1545L94.2576 43.624L104.732 52.9323H113.668L124.142 43.624L124.724 33.1545L128.409 30.4414L129.768 22.6849L126.662 19.9719Z"
        fill={color}
      />
    </>
  );
};
const FrontMaleHands = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M18.623 197.083L21.1433 203.093L28.5155 204.257L29.0977 215.115L25.2189 230.625L22.6959 230.431L25.1444 220.105L22.5258 220.033L18.429 231.013L15.3265 230.625L19.1813 219.523L16.4882 219.015L11.2509 230.819L7.37212 230.431L12.7078 218.144L10.8628 217.052L4.26961 227.521L0.970368 227.133L10.8628 208.328L9.89507 206.776L0.58222 210.653L0 208.522L7.5662 201.735L18.623 197.083Z"
        fill={color}
      />
      <path
        d="M199.379 197.083L196.859 203.093L189.487 204.257L188.904 215.115L192.786 230.625L195.306 230.431L192.858 220.105L195.476 220.033L199.573 231.013L202.678 230.625L198.821 219.523L201.514 219.015L206.751 230.819L210.63 230.431L205.294 218.144L207.139 217.052L213.732 227.521L217.032 227.133L207.139 208.328L208.11 206.776L217.42 210.653L217.999 208.522L210.436 201.735L199.379 197.083Z"
        fill={color}
      />
    </>
  );
};
const FrontMaleFeet = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M111.922 409.094L112.504 425.186L113.863 430.806H117.935L119.1 426.929L120.456 426.735L119.477 431L125.308 429.063L126.663 428.287L132.377 427.123L132.677 422.47L127.052 412.971L123.173 408.124L113.669 404.244L111.922 409.094Z"
        fill={color}
      />
      <path
        d="M106.48 409.094L105.898 425.186L104.539 430.806H100.466L99.3018 426.929L97.9459 426.735L98.2357 430.612L93.0967 429.063L91.7382 428.287L85.9187 427.027L85.7246 422.47L91.3501 412.971L95.2289 408.124L104.733 404.244L106.48 409.094Z"
        fill={color}
      />
    </>
  );
};

export default FrontMaleSkeleton;
