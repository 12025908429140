import React from "react";
import { Spinner } from "react-bootstrap";

interface Props {}

const Loader = (props: Props) => {
  return <Spinner animation="border" role="status" />;
};

export default Loader;
