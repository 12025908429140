import React, { useEffect, useMemo, useState } from "react";
import colors from "../../assets/colors";
import MobileAppBuildNumber from "../../components/MobileAppBuildNumber";
import RulesBuilder from "../../components/RulesBuilder";

enum NavTypes {
  MobileAppBuildNumber,
  RulesBuilder,
}
interface Props {}

const SystemSettingsScreen = (props: Props) => {
  const {} = props;
  const [currentNav, setCurrentNav] = useState(null);

  const navs = [
    { title: "App Build Number", value: NavTypes.MobileAppBuildNumber },
    { title: "View SLA Configuration", value: NavTypes.RulesBuilder },
  ];

  const CurrentComponent = useMemo(() => {
    switch (currentNav) {
      case NavTypes.MobileAppBuildNumber:
        return <MobileAppBuildNumber />;
      case NavTypes.RulesBuilder:
        // @ts-ignore
        return <RulesBuilder />;
      default:
        return null;
    }
  }, [currentNav]);

  useEffect(() => {
    setCurrentNav(NavTypes.MobileAppBuildNumber);
  }, []);

  return (
    <div
      className="col-16 d-flex flex-column"
      style={{
        flex: 1,
      }}
    >
      <div
        className="d-flex"
        style={{
          marginBottom: 24,
          height: 48,
          backgroundColor: colors.caliber_secondary_gray_5,
        }}
      >
        {navs.map((nav) => {
          const isCurrent = nav.value === currentNav;
          return (
            <div
              key={nav.title}
              role="button"
              tabIndex={0}
              onClick={() => setCurrentNav(nav.value)}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  setCurrentNav(nav.value);
                }
              }}
              className="d-flex pointer nofocus"
              style={{
                margin: 8,
                backgroundColor: isCurrent && colors.nav_item_grey,
                color: isCurrent
                  ? colors.caliber_secondary
                  : colors.caliber_gray_text,
                height: 31,
                fontSize: "14px",
                lineHeight: "24px",
                padding: "4px 8px",
                borderRadius: 4,
              }}
            >
              {nav.title}
            </div>
          );
        })}
      </div>

      {CurrentComponent}
    </div>
  );
};

export default SystemSettingsScreen;
