import Hls from "hls.js";
import React, { useEffect, useRef, useState } from "react";
import colors from "../../assets/colors";
import { Exercise, FormVideo } from "../../types/gqlTypes";
import CloseIcon from "../Icons/CloseIcons";
import VideoIcon from "../Icons/VideoIcon";
import Modal from "../Modal";
import Popup from "../Popover";
import SimpleButton from "../SimpleButton";
import { exerciseThumbnailImageUrl } from "../../utils";

interface Props {
  formVideo: FormVideo;
  exercise: Exercise;
}

const FormVideoComponent = (props: Props) => {
  const { formVideo, exercise } = props;
  const [openModal, setOpenModal] = useState(false);
  const [hovered, setHovered] = useState(false);
  const videoLoopRef = useRef(null);
  const videoLoopRef1 = useRef(null);

  useEffect(() => {
    if (openModal && formVideo?.videoMessage?.videoMessageMediaUrl?.url) {
      const hls = new Hls();
      hls.loadSource(formVideo?.videoMessage?.videoMessageMediaUrl?.url);
      hls.attachMedia(videoLoopRef.current);
    }
    if (hovered && formVideo?.videoMessage?.videoMessageMediaUrl?.url) {
      setTimeout(() => {
        const hls = new Hls();
        hls.loadSource(formVideo?.videoMessage?.videoMessageMediaUrl?.url);
        hls.attachMedia(videoLoopRef1.current);
      }, 0);
    }
  }, [openModal, hovered, formVideo]);

  const formVideoPopup = (formVideo: FormVideo) => {
    if (!formVideo) {
      return <div style={{ width: 18, height: 18, marginRight: 13 }} />;
    }
    return (
      <Popup
        popupkey="formVideoPopup"
        trigger={
          <div
            role="button"
            tabIndex={0}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setOpenModal(true);
            }}
            onKeyDown={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setOpenModal(true);
            }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            className="nofocus pointer"
            style={{ marginRight: 13 }}
          >
            <VideoIcon />
          </div>
        }
        triggerEvents={["hover"]}
        placement="bottom"
      >
        <div
          className="d-flex align-items-center"
          style={{
            backgroundColor: colors.caliber_white,
            borderRadius: 10,
            boxShadow: "0px 2px 16px #E7E7EB",
            padding: 8,
          }}
        >
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video
            style={{
              borderRadius: 8,
            }}
            autoPlay
            loop
            ref={videoLoopRef1}
            width={130}
          />
        </div>
      </Popup>
    );
  };

  return (
    <div className="d-flex">
      {formVideoPopup(formVideo)}
      {openModal && (
        <Modal
          onClose={(event) => {
            setOpenModal?.(false);
            event?.stopPropagation();
            event?.preventDefault();
          }}
          closeOnDimmerClick
        >
          <div
            className="d-flex flex-column"
            style={{
              padding: "16px 24px",
            }}
          >
            <div className="d-flex align-items-center">
              <img
                style={{
                  width: 48,
                  height: 48,
                  marginRight: 10,
                  borderRadius: 8,
                }}
                src={exerciseThumbnailImageUrl(exercise)}
              />
              <div
                className="d-flex align-items-center flex-wrap bold"
                style={{
                  flex: 1,
                  fontSize: "16px",
                  lineHeight: "18px",
                  letterSpacing: "-0.03em",
                  color: "#01052F",
                  height: 48,
                }}
              >
                {exercise?.name}
              </div>
              <SimpleButton onClick={() => setOpenModal(false)}>
                <CloseIcon width={27} height={27} />
              </SimpleButton>
            </div>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video
              style={{
                borderRadius: 8,
                marginTop: 14,
              }}
              controls
              ref={videoLoopRef}
              width={330}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default FormVideoComponent;
