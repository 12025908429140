import React, { useMemo, useCallback, useState } from "react";
import { format, parse, subMonths, isAfter } from "date-fns";
import { Line } from "react-chartjs-2";
import colors from "../../assets/colors";
import { StrengthScoreLight } from "../../types/gqlTypes";
import SimpleButton from "../SimpleButton";
import EmptyCircle from "./EmptyCircle";
import { DashboardTypes } from "../../models/strengthscore";

interface Props {
  legendText?: string;
  lineColor: string;
  areaColor: string;
  strengthType: DashboardTypes;
  historicalStrengthScore?: StrengthScoreLight[];
  historicalStrengthBalance?: StrengthScoreLight[];
}
enum ChartTypeEnum {
  ONE_MONTH = "ONE_MONTH",
  THREE_MONTHS = "THREE_MONTHS",
  SIX_MONTHS = "SIX_MONTHS",
}

const StrengthChart = (props: Props) => {
  const {
    legendText,
    lineColor,
    areaColor,
    strengthType,
    historicalStrengthScore,
    historicalStrengthBalance,
  } = props;

  const chartBackgroundColor = "#F5F7F9";
  const [chartType, setChartType] = useState(ChartTypeEnum.ONE_MONTH);
  const isStrengthScore = strengthType === DashboardTypes.score;
  let chartLegendText = isStrengthScore ? "Strength Score" : "Strength Balance";
  if (legendText) {
    chartLegendText = legendText;
  }

  const getStartDate = (chartType: ChartTypeEnum) => {
    switch (chartType) {
      case ChartTypeEnum.ONE_MONTH:
        return subMonths(new Date(), 1);
      case ChartTypeEnum.THREE_MONTHS:
        return subMonths(new Date(), 3);
      case ChartTypeEnum.SIX_MONTHS:
        return subMonths(new Date(), 6);
    }
    return null;
  };
  const chartTypeOptions = [
    { text: "1 month", value: ChartTypeEnum.ONE_MONTH },
    { text: "3 months", value: ChartTypeEnum.THREE_MONTHS },
    { text: "6 months", value: ChartTypeEnum.SIX_MONTHS },
  ];

  const datasetOptions = {
    fill: true,
    borderColor: lineColor,
    borderCapStyle: "butt",
    borderDash: [],
    borderWidth: 3,
    borderDashOffset: 0.0,
    pointBorderColor: lineColor,
    pointBackgroundColor: chartBackgroundColor,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: lineColor,
    pointHoverBorderColor: lineColor,
    pointRadius: 4,
  };

  const normalizedData = useMemo(() => {
    const startDate = getStartDate(chartType);
    let minValue;
    let dataToIterate;
    if (isStrengthScore) {
      minValue = historicalStrengthScore?.[0]?.score;
      dataToIterate = historicalStrengthScore || [];
    } else {
      minValue = Math.min(historicalStrengthBalance?.[0]?.balance * 100, 50);
      dataToIterate = historicalStrengthBalance || [];
    }
    const data = {
      x: [],
      y: [],
      minValue,
      maxValue: 100,
      dateRange: "",
    };

    dataToIterate?.forEach(({ endDate, score, balance }) => {
      const value = isStrengthScore ? score : Math.round(balance * 100);
      const parsedDate = parse(endDate.slice(0, 10), "yyyy-MM-dd", new Date());
      if (isAfter(parsedDate, startDate)) {
        data.x.push(parsedDate);
        data.y.push(value);
        data.minValue = Math.min(data.minValue, value);
        data.maxValue = Math.max(data.maxValue, value);
      }
    });
    const firstDate = format(startDate, "MMM d");
    const lastDate = format(new Date(), "MMM d");
    data.dateRange = `${firstDate} - ${lastDate}`;
    return data;
  }, [chartType, historicalStrengthScore, historicalStrengthBalance]);

  const chartOptions = useMemo(() => {
    const stepSize = 50;
    const tickLabel = isStrengthScore ? "" : "%";
    const tooltipLabel = isStrengthScore ? "" : "%";
    const minReminder = normalizedData.minValue % stepSize;
    const min = normalizedData.minValue - minReminder;
    const maxReminder = normalizedData.maxValue % stepSize;
    const max =
      normalizedData.maxValue + (isStrengthScore ? stepSize - maxReminder : 0);

    const options = {
      animation: {
        duration: 1,
      },
      spanGaps: true,
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      layout: {
        padding: {
          right: 6,
          top: 65,
        },
      },
      scales: {
        xAxes: [
          {
            ticks: {
              minRotation: 0,
              maxRotation: 0,
              callback: (tick, ind) => {
                return null;
              },
            },
            scaleLabel: {
              display: false,
              labelString: "Date",
              fontFamily: "Circular-Medium, serif",
              fontColor: "#AEB1BF",
            },
            gridLines: {
              display: false,
              drawBorder: false,
            },
          },
          {
            position: "top",
            ticks: {
              display: false,
            },
            gridLines: {
              display: false,
              drawBorder: false,
              drawTicks: false,
            },
          },
        ],
        yAxes: [
          {
            scaleLabel: {
              display: false,
              labelString: "yAxisLabel",
              fontFamily: "Circular-Medium, serif",
              fontColor: "#AEB1BF",
            },
            gridLines: {
              tickMarkLength: 0,
              color: "#E8E8E8",
              drawBorder: false,
            },
            ticks: {
              min,
              max,
              stepSize,
              padding: 20,
              beginAtZero: min === 0,
              callback: (value) => `${value}${tickLabel}`,
            },
          },
          {
            position: "right",
            ticks: { display: false },
            gridLines: {
              display: false,
              drawTicks: false,
              drawBorder: false,
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          labelColor: () => ({}),
          labelTextColor: () => colors.caliber_secondary,
          label: (item) => {
            return `${item?.value}${tooltipLabel}`;
          },
          displayColors: () => false,
          footer: (items) => items?.[0]?.label,
          title: () => null,
        },
        footerFontColor: colors.caliber_gray_47,
        footerFontFamily: "Circular, serif",
        bodyFontFamily: "Circular-Medium, serif",
        displayColors: false,
        caretPadding: 10,
        caretSize: 7,
        yAlign: "bottom",
        xAlign: "center",
        footerFontSize: 13,
        bodyFontSize: 17,
        backgroundColor: "#FCFCFF",
        xPadding: 10,
        yPadding: 6,
        footerAlign: "center",
        bodyAlign: "center",
      },
    };
    return options;
  }, [normalizedData]);

  const chartData = useCallback(() => {
    const data = {
      labels: normalizedData.x.map((date) => format(date, "MMM d")),
      datasets: [
        {
          ...datasetOptions,
          backgroundColor: areaColor,
          data: [...normalizedData.y],
        },
      ],
    };
    return data;
  }, [normalizedData]);

  const chartHeight = 268;
  const chartWidth = 334;
  return (
    <div
      className="d-flex flex-column"
      style={{
        flex: 1,
        position: "relative",
        marginLeft: 8,
        maxWidth: 333,
      }}
    >
      <div
        className="d-flex align-items-center"
        style={{
          width: 294,
          height: 40,
          padding: 2,
          marginLeft: 16,
          borderRadius: 4,
          zIndex: 3,
          backgroundColor: chartBackgroundColor,
        }}
      >
        {chartTypeOptions.map(({ text, value }, index) => {
          const isSelected = value === chartType;
          return (
            <SimpleButton
              key={text}
              onClick={() => setChartType(value)}
              nofocus
              className="d-flex justify-content-center align-items-center bold"
              style={{
                width: 96,
                height: 36,
                borderRadius: 4,
                marginLeft: index === 0 ? 0 : 2,
                backgroundColor: isSelected
                  ? colors.caliber_white
                  : chartBackgroundColor,
                color: isSelected
                  ? colors.caliber_secondary
                  : colors.caliber_secondary_gray_47,
                fontSize: "14px",
                letterSpacing: "-0.033em",
              }}
            >
              {text}
            </SimpleButton>
          );
        })}
      </div>
      <div
        style={{
          backgroundColor: chartBackgroundColor,
          width: chartWidth - 81 - (isStrengthScore ? 0 : 12),
          height: chartHeight - 35,
          borderRadius: 8,
          position: "absolute",
          top: 60,
          left: 55 + (isStrengthScore ? 0 : 12),
          zIndex: 0,
        }}
      >
        {/* backgound rectangular */}
      </div>
      <div
        style={{
          flex: 1,
          zIndex: 2,
          maxWidth: chartWidth,
          maxHeight: chartHeight,
          width: chartWidth,
          height: chartHeight,
          padding: "0px 20px 0px 10px",
          borderRadius: 8,
        }}
      >
        <Line
          width={chartWidth}
          height={chartHeight}
          options={chartOptions}
          data={chartData}
        />
      </div>
      <div className="d-flex justify-content-between" style={{ zIndex: 3 }}>
        <div className="d-flex">
          <div
            style={{
              marginLeft: 56 + (isStrengthScore ? 0 : 12),
            }}
          >
            <EmptyCircle radius={12} thickness={2} color={lineColor} />
          </div>
          <div
            className="d-flex align-items-center bold"
            style={{
              height: 12,
              marginLeft: 12,
              fontSize: "12px",
              lineHeight: "12px",
              color: colors.caliber_secondary,
            }}
          >
            {chartLegendText}
          </div>
        </div>
        <div
          className="d-flex align-items-center medium-bold"
          style={{
            height: 12,
            marginRight: 25,
            fontSize: "12px",
            lineHeight: "12px",
            color: colors.caliber_secondary_gray_47,
            letterSpacing: "-0.29px",
          }}
        >
          {normalizedData.dateRange}
        </div>
      </div>
    </div>
  );
};

export default StrengthChart;
