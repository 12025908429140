import apolloClient from "../../../api/apolloClient";
import {
  DELETE_CLIENT_GYM_STEP_TEMPLATE,
  DELETE_CLIENT_TRAINING_PLAN,
  DELETE_CLIENT_WORKOUT_TEMPLATE,
  SET_DEFAULT_WORKOUT_GROUP,
} from "../../../api/gql/trainingPrograms/clientTrainingPlan";
import {
  DELETE_CLIENT_WORKOUT_GROUP_TEMPLATE,
  DELETE_CUSTOM_TRAINING_PLAN,
  DELETE_GYM_STEP_IN_CUSTOM_TRAINING_PLAN,
  DELETE_GYM_STEP_IN_LIBRARY_TRAINING_PLAN,
  DELETE_LIBRARY_TRAINING_PLAN,
  DELETE_WORKOUT_IN_CUSTOM_TRAINING_PLAN,
  DELETE_WORKOUT_IN_LIBRARY_TRAINING_PLAN,
} from "../../../api/gql/trainingPrograms/customTrainingPlan";
import {
  DELETE_MASTER_GYM_STEP,
  DELETE_MASTER_TRAINING_PLAN,
  DELETE_MASTER_WORKOUT,
} from "../../../api/gql/trainingPrograms/masterTrainingPlan";
import { ProgramType } from "../../../components/TrainingProgram";
import { ClientTrainingPlan } from "../../../types/gqlTypes";
import { getTrainer } from "../../reducers";
import { selectCurrentPlan } from "../../reducers/TrainingPrograms/selectors";
import { ClientDetailState } from "../ClientDetail/types";
import {
  DELETE_CLIENT_WORKOUT_GROUP_FAIL,
  DELETE_CLIENT_WORKOUT_GROUP_LOADING,
  DELETE_CLIENT_WORKOUT_GROUP_SUCCESS,
  DELETE_GYM_STEP_FAIL,
  DELETE_GYM_STEP_LOADING,
  DELETE_GYM_STEP_SUCCESS,
  DELETE_TRAINING_PLAN_FAIL,
  DELETE_TRAINING_PLAN_LOADING,
  DELETE_TRAINING_PLAN_SUCCESS,
  DELETE_WORKOUT_FAIL,
  DELETE_WORKOUT_LOADING,
  DELETE_WORKOUT_SUCCESS,
  SET_DEFAULT_WORKOUT_GROUP_SUCCESS,
  TrainingProgramsState,
} from "./types";

export const deleteGymStepInPlan =
  (args: { id?: string }) => async (dispatch, getState) => {
    const trainingProgramsState = getState()
      .trainingProgramsState as TrainingProgramsState;

    if (trainingProgramsState.isLoading) {
      return;
    }
    dispatch({ type: DELETE_GYM_STEP_LOADING });
    try {
      const apollo = await apolloClient(
        getState().authState.authToken,
        dispatch
      );

      const { programType } = trainingProgramsState;
      const currentPlan = selectCurrentPlan(getState());

      if (programType === ProgramType.Custom) {
        apollo.mutate({
          mutation: DELETE_GYM_STEP_IN_CUSTOM_TRAINING_PLAN,
          variables: {
            trainerId: getTrainer(getState()).id,
            customTrainingPlanId: currentPlan.id,
            masterGymStepId: args.id,
          },
        });
      } else if (programType === ProgramType.Library) {
        apollo.mutate({
          mutation: DELETE_GYM_STEP_IN_LIBRARY_TRAINING_PLAN,
          variables: {
            trainerId: getTrainer(getState()).id,
            libraryTrainingPlanId: currentPlan.id,
            masterGymStepId: args.id,
          },
        });
      } else if (programType === ProgramType.Master) {
        apollo.mutate({
          mutation: DELETE_MASTER_GYM_STEP,
          variables: {
            masterGymStepId: args.id,
          },
        });
      } else if (programType === ProgramType.Client) {
        const { user: selectedUser }: ClientDetailState =
          getState().clientDetailState;

        await apollo.mutate({
          mutation: DELETE_CLIENT_GYM_STEP_TEMPLATE,
          variables: {
            clientId: selectedUser.id,
            clientGymStepTemplateId: args.id,
          },
        });
      }

      dispatch({
        type: DELETE_GYM_STEP_SUCCESS,
        id: args.id,
      });
    } catch (error) {
      dispatch({
        type: DELETE_GYM_STEP_FAIL,
        data: error,
      });
    }
  };

export const deleteWorkoutInPlan =
  (args: { id?: string }) => async (dispatch, getState) => {
    const trainingProgramsState = getState()
      .trainingProgramsState as TrainingProgramsState;
    const currentPlan = selectCurrentPlan(getState());

    if (trainingProgramsState.isDeleting) {
      return;
    }
    dispatch({ type: DELETE_WORKOUT_LOADING });
    try {
      const apollo = await apolloClient(
        getState().authState.authToken,
        dispatch
      );

      const { programType } = trainingProgramsState;
      if (programType === ProgramType.Custom) {
        apollo.mutate({
          mutation: DELETE_WORKOUT_IN_CUSTOM_TRAINING_PLAN,
          variables: {
            trainerId: getTrainer(getState()).id,
            customTrainingPlanId: currentPlan.id,
            masterWorkoutId: args.id,
          },
        });
      } else if (programType === ProgramType.Library) {
        apollo.mutate({
          mutation: DELETE_WORKOUT_IN_LIBRARY_TRAINING_PLAN,
          variables: {
            trainerId: getTrainer(getState()).id,
            libraryTrainingPlanId: currentPlan.id,
            masterWorkoutId: args.id,
          },
        });
      } else if (programType === ProgramType.Master) {
        apollo.mutate({
          mutation: DELETE_MASTER_WORKOUT,
          variables: {
            masterWorkoutId: args.id,
          },
        });
      } else if (programType === ProgramType.Client) {
        const { user: selectedUser }: ClientDetailState =
          getState().clientDetailState;

        apollo.mutate({
          mutation: DELETE_CLIENT_WORKOUT_TEMPLATE,
          variables: {
            clientId: selectedUser.id,
            clientWorkoutTemplateId: args.id,
          },
        });
      }

      dispatch({
        type: DELETE_WORKOUT_SUCCESS,
        id: args.id,
      });
    } catch (error) {
      dispatch({
        type: DELETE_WORKOUT_FAIL,
        data: error,
      });
    }
  };

export const deleteTrainingPlan =
  (args: { id: string; programType?: ProgramType }) =>
  async (dispatch, getState) => {
    const trainingProgramsState = getState()
      .trainingProgramsState as TrainingProgramsState;

    if (trainingProgramsState.isDeleting) {
      return;
    }
    dispatch({ type: DELETE_TRAINING_PLAN_LOADING });
    try {
      const apollo = await apolloClient(
        getState().authState.authToken,
        dispatch
      );

      const actualProgramType =
        args.programType || trainingProgramsState.programType;
      if (actualProgramType === ProgramType.Custom) {
        apollo.mutate({
          mutation: DELETE_CUSTOM_TRAINING_PLAN,
          variables: {
            trainerId: getTrainer(getState()).id,
            customTrainingPlanId: args.id,
          },
        });
      } else if (actualProgramType === ProgramType.Library) {
        apollo.mutate({
          mutation: DELETE_LIBRARY_TRAINING_PLAN,
          variables: {
            trainerId: getTrainer(getState()).id,
            libraryTrainingPlanId: args.id,
          },
        });
      } else if (actualProgramType === ProgramType.Master) {
        apollo.mutate({
          mutation: DELETE_MASTER_TRAINING_PLAN,
          variables: {
            masterTrainingPlanId: args.id,
          },
        });
      } else if (actualProgramType === ProgramType.Client) {
        const { user: selectedUser }: ClientDetailState =
          getState().clientDetailState;

        apollo.mutate({
          mutation: DELETE_CLIENT_TRAINING_PLAN,
          variables: {
            clientId: selectedUser.id,
            clientTrainingPlanId: args.id,
          },
        });
      }

      dispatch({
        type: DELETE_TRAINING_PLAN_SUCCESS,
        id: args.id,
        programType: actualProgramType,
      });
    } catch (error) {
      dispatch({
        type: DELETE_TRAINING_PLAN_FAIL,
        data: error,
      });
    }
  };

export const deleteClientWorkoutGroupTemplate =
  (
    clientId: string,
    clientWorkoutGroupTemplateId: string,
    defaultWorkoutGroupId: string
  ) =>
  async (dispatch, getState) => {
    const trainingProgramsState = getState()
      .trainingProgramsState as TrainingProgramsState;
    if (trainingProgramsState.isDeleting) {
      return;
    }
    dispatch({ type: DELETE_CLIENT_WORKOUT_GROUP_LOADING });
    try {
      const apollo = await apolloClient(
        getState().authState.authToken,
        dispatch
      );

      if (defaultWorkoutGroupId !== null) {
        const updatedPlan = (
          await apollo.mutate({
            mutation: SET_DEFAULT_WORKOUT_GROUP,
            variables: {
              clientId,
              clientWorkoutGroupTemplateId: defaultWorkoutGroupId,
            },
          })
        ).data
          .setDefaultWorkoutGroupForClientTrainingPlan as ClientTrainingPlan;

        dispatch({
          type: SET_DEFAULT_WORKOUT_GROUP_SUCCESS,
          plan: updatedPlan,
        });
      }

      apollo.mutate({
        mutation: DELETE_CLIENT_WORKOUT_GROUP_TEMPLATE,
        variables: {
          clientId,
          clientWorkoutGroupTemplateId,
        },
      });

      dispatch({
        type: DELETE_CLIENT_WORKOUT_GROUP_SUCCESS,
        id: clientWorkoutGroupTemplateId,
      });
    } catch (error) {
      dispatch({
        type: DELETE_CLIENT_WORKOUT_GROUP_FAIL,
        data: error,
      });
    }
  };
