export const UPDATE_NOTES_SUCCESS = "UPDATE_NOTES_SUCCESS";
export const UPDATE_NOTES_FAIL = "UPDATE_NOTES_FAIL";
export const UPDATE_NOTES_SAVING = "UPDATE_NOTES_SAVING";

export interface UpdateNotesState {
  isSaving: boolean;
}

export interface UpdateNotesAction {
  type: string;
}
