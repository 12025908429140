import React from "react";
import {
  BodyMeasurementType,
  BodyStatCalendarItem,
  CalendarDay,
} from "../../../types/gqlTypes";
import colors from "../../../assets/colors";
import CategoryButton from "./CategoryButton";

interface Props {
  calendarDay: CalendarDay;
  onClick: (value: BodyMeasurementType) => void;
}

const SelectCategory = (props: Props) => {
  const { calendarDay, onClick } = props;

  const disabledBodyStats = {
    [BodyMeasurementType.BodyWeight]: false,
    [BodyMeasurementType.BodyFatPercent]: false,
    [BodyMeasurementType.WaistSize]: false,
  };
  Object.keys(disabledBodyStats).forEach((value) => {
    const exist = calendarDay?.items?.some(
      (item: BodyStatCalendarItem) => item.bodyMeasurementType === value
    );
    disabledBodyStats[value] = exist;
  });

  return (
    <div>
      <div
        className="medium-bold"
        style={{
          color: colors.caliber_gray_text,
          fontSize: "14px",
          lineHeight: "24px",
          margin: "16px 0px",
        }}
      >
        Select category
      </div>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{
          marginTop: 30,
        }}
      >
        {Object.keys(disabledBodyStats).map((bodyStat: BodyMeasurementType) => {
          return (
            <CategoryButton
              disabled={disabledBodyStats[bodyStat]}
              onClick={(value) => onClick(value)}
              bodyStat={bodyStat}
            />
          );
        })}
      </div>
    </div>
  );
};

export default SelectCategory;
