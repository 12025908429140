import React from "react";
import { connect } from "react-redux";
import ProfileFitnessConsultations from "../../components/ProfileFitnessConsultations";
import ClientProfileInfoModule from "../../components/ClientProfileInfoModule";
import { StoreState } from "../../redux/reducers";
import ProfileNotesModule from "../../components/ProfileNotesModule";
import { ClientConsultation } from "../../types/gqlTypes";

interface Props {
  clientConsultation: ClientConsultation;
}
const TrainerClientProfileTab = (props: Props) => {
  const { clientConsultation } = props;

  return (
    <div
      className="d-flex"
      style={{
        marginBottom: 70,
      }}
    >
      <div className="flex-grow-0 margin-13-12 d-flex">
        <ClientProfileInfoModule fullView />
      </div>
      <div className="flex-grow-1 margin-13-12 d-flex ">
        <ProfileFitnessConsultations clientConsultation={clientConsultation} />
      </div>
      <div className="flex-grow-0 margin-13-12 d-flex">
        <ProfileNotesModule />
      </div>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  clientConsultation: state.clientDetailState.clientConsultation,
});

export default connect(mapStateToProps)(TrainerClientProfileTab);
