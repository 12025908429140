import React from "react";

interface Props {}

const DragHandle = (props: Props) => {
  const {} = props;

  return (
    <svg
      style={{ display: "block" }}
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0" width="24" height="2" fill="#C2C3CD" />
      <rect y="4" width="24" height="2" fill="#C2C3CD" />
      <rect y="8" width="24" height="2" fill="#C2C3CD" />
      <rect y="12" width="24" height="2" fill="#C2C3CD" />
      <rect y="16" width="24" height="2" fill="#C2C3CD" />
    </svg>
  );
};

export default DragHandle;
