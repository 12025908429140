import React from "react";
import Tasks from "../../components/Tasks";

interface Props {}

const TasksScreen = (props: Props) => {
  const {} = props;

  return (
    <div className="d-flex" style={{ flex: 1 }}>
      {/* @ts-ignore */}
      <Tasks />
    </div>
  );
};

export default TasksScreen;
