import React from "react";
import colors from "../../assets/colors";

interface Props {
  weekStartsOnSunday: boolean;
  columns: number;
}
const CalendarHeader = (props: Props) => {
  const { weekStartsOnSunday, columns } = props;
  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  if (weekStartsOnSunday) {
    daysOfWeek.unshift("Sunday");
  } else {
    daysOfWeek.push("Sunday");
  }
  return (
    <div
      className="flex-grow-0 d-inline-flex heading-small"
      style={{
        color: colors.caliber_gray_border,
        marginBottom: 10,
      }}
    >
      {daysOfWeek.map((day) => {
        return (
          <div
            key={day}
            style={{
              flex: `1 1 ${100 / columns}%`,
            }}
          >
            {day}
          </div>
        );
      })}
    </div>
  );
};
export default CalendarHeader;
