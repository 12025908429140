import React from "react";
import colors from "../../../assets/colors";
import { ClientSearchResult, UserStatusType } from "../../../types/gqlTypes";

interface Props {
  width?: number;
  isLastColumn?: boolean;
  isDragging?: boolean;
  row: ClientSearchResult;
}

const StatusCell = (props: Props) => {
  const { width, isLastColumn, isDragging, row } = props;

  const { status } = row;
  const borderColor = colors.table_gray;
  const rowHeight = 64;

  const properties = {
    [UserStatusType.Active]: {
      minWidth: 60,
      backgroundColor: "#1FC866",
      color: "#FFFFFF",
    },
    [UserStatusType.TrialUser]: {
      minWidth: 48,
      backgroundColor: "#3446FB",
      color: "#FFFFFF",
    },
    [UserStatusType.PausedSubscription]: {
      minWidth: 64,
      backgroundColor: "#82869B",
      color: "#FFFFFF",
    },
    [UserStatusType.PendingCancellation]: {
      minWidth: 69,
      backgroundColor: "#FFCC32",
      color: "#000000",
    },
    [UserStatusType.OnHold]: {
      minWidth: 57,
      backgroundColor: "#FA8C84",
      color: "#FFFFFF",
    },
    [UserStatusType.Cancelled]: {
      minWidth: 80,
      backgroundColor: "#F73843",
      color: "#FFFFFF",
    },
  };
  const titles = {
    [UserStatusType.Active]: "Active",
    [UserStatusType.TrialUser]: "Trial",
    [UserStatusType.PausedSubscription]: "Paused",
    [UserStatusType.PendingCancellation]: "Pending",
    [UserStatusType.OnHold]: "Failed",
    [UserStatusType.Cancelled]: "Cancelled",
  };

  return (
    <td
      className="d-flex"
      style={{
        width,
        minWidth: isLastColumn && width,
        backgroundColor: colors.caliber_gray_bg,
        position: "relative",
      }}
    >
      <div
        className="d-flex"
        style={{
          position: "absolute",
          top: isDragging ? -8 : 0,
          backgroundColor: isDragging ? "#E8EAEE" : "transparent",
          width: "100%",
          height: isDragging ? rowHeight + 16 : rowHeight,
          zIndex: isDragging ? 4 : 1,
          marginBottom: isDragging ? 0 : 8,
        }}
      >
        <div
          className="d-flex align-items-center"
          style={{
            flex: "1 1 100%",
            zIndex: isDragging ? 3 : 2,
            backgroundColor: colors.caliber_white,
            height: rowHeight,
            borderRight: `1px solid ${borderColor}`,
            paddingLeft: 18,
            margin: isDragging ? 8 : 0,
            borderTopRightRadius: isLastColumn && 8,
            borderBottomRightRadius: isLastColumn && 8,

            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          <div
            className="d-flex align-items-center justify-content-center medium-bold"
            style={{
              fontSize: "12px",
              lineHeight: "16px",
              height: 24,
              borderRadius: 4,
              ...(properties?.[status] && { ...properties?.[status] }),
            }}
          >
            {titles[status]}
          </div>
        </div>
      </div>
    </td>
  );
};

export default StatusCell;
