import {
  CaliberPageImpl_Trainer,
  MediaUrl,
  Trainer,
} from "../../../types/gqlTypes";

export const ADMIN_TRAINER_CREATING_SUCCESS = "ADMIN_TRAINER_CREATING_SUCCESS";
export const ADMIN_TRAINER_CREATING_FAIL = "ADMIN_TRAINER_CREATING_FAIL";
export const ADMIN_TRAINER_CREATING_SAVING = "ADMIN_TRAINER_CREATING_SAVING";

export const ADMIN_TRAINER_UPDATING_SUCCESS = "ADMIN_TRAINER_UPDATING_SUCCESS";
export const ADMIN_TRAINER_UPDATING_FAIL = "ADMIN_TRAINER_UPDATING_FAIL";
export const ADMIN_TRAINER_UPDATING_SAVING = "ADMIN_TRAINER_UPDATING_SAVING";

export const ADMIN_TRAINERS_SEARCH_SUCCESS = "ADMIN_TRAINERS_SEARCH_SUCCESS";
export const ADMIN_TRAINERS_SEARCH_FAIL = "ADMIN_TRAINERS_SEARCH_FAIL";
export const ADMIN_TRAINERS_SEARCH_LOADING = "ADMIN_TRAINERS_SEARCH_LOADING";
export const UPDATE_TRAINER_PROFILE_ICON = "UPDATE_TRAINER_PROFILE_ICON";

export const ADMIN_TRAINER_RESET_SEARCH = "ADMIN_TRAINER_RESET_SEARCH";

export interface AdminSearchTrainersState {
  isLoading: boolean;
  foundTrainers: Trainer[];
  page: number;
  totalPages: number;
}

export interface AdminSearchTrainersAction {
  type: string;
  data?: CaliberPageImpl_Trainer;
  id?: string;
  profileIconMediaUrl?: MediaUrl;
  trainer?: Trainer;
}
export interface AdminCreateUpdateTrainerState {
  errorToCreate: boolean;
  errorToUpdate: boolean;
  successToCreate: boolean;
  successToUpdate: boolean;
  isSaving: boolean;
  errorMessage: string;
}

export interface AdminCreateUpdateTrainerAction {
  type: string;
  errorMessage?: {
    graphQLErrors?: {
      extensions?: {
        errorMessage?: string;
      };
    }[];
  };
  trainer?: Trainer;
}
