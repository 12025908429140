import {
  TrainingPlanType,
  TrainingPlanRepRangeType,
  TrainingPlanVersionType,
  TrainingPlanFrequencyType,
  TrainingPlanDurationType,
  TrainingPlanProgramType,
  GenderType,
} from "../../types/gqlTypes";

export const genderDisplayText = (gender: GenderType) => {
  switch (gender) {
    case GenderType.Female:
      return "F";
    case GenderType.Male:
      return "M";
    case GenderType.NotSpecified:
      return "N";
    case GenderType.Other:
      return "O";
    default:
      return "";
  }
};
export const fullGenderDisplayText = (gender: GenderType) => {
  switch (gender) {
    case GenderType.Female:
      return "Female";
    case GenderType.Male:
      return "Male";
    default:
      return "";
  }
};
export const programTypeDisplayText = (type: TrainingPlanProgramType) => {
  switch (type) {
    case TrainingPlanProgramType.Cardio:
      return "Cardio";
    case TrainingPlanProgramType.Mobility:
      return "Mobility";
    case TrainingPlanProgramType.Strength:
      return "Strength";
    default:
      return "";
  }
};
export const durationDisplayText = (duration: TrainingPlanDurationType) => {
  switch (duration) {
    case TrainingPlanDurationType.Min_30:
      return "30 mins";
    case TrainingPlanDurationType.Min_45:
      return "45 mins";
    case TrainingPlanDurationType.Min_60:
      return "60 mins";
    case TrainingPlanDurationType.Min_75:
      return "75 mins";
    case TrainingPlanDurationType.Min_90:
      return "90 mins";
    default:
      return "";
  }
};
export const frequencyDisplayText = (frequency: TrainingPlanFrequencyType) => {
  switch (frequency) {
    case TrainingPlanFrequencyType.FivePerWeek:
      return "5 days";
    case TrainingPlanFrequencyType.FourPerWeek:
      return "4 days";
    case TrainingPlanFrequencyType.ThreePerWeek:
      return "3 days";
    case TrainingPlanFrequencyType.TwoPerWeek:
      return "2 days";
    default:
      return "";
  }
};
export const versionDisplayText = (version: TrainingPlanVersionType) => {
  switch (version) {
    case TrainingPlanVersionType.A:
      return "Version A";
    case TrainingPlanVersionType.B:
      return "Version B";
    case TrainingPlanVersionType.C:
      return "Version C";
    case TrainingPlanVersionType.D:
      return "Version D";
    case TrainingPlanVersionType.E:
      return "Version E";
    default:
      return "";
  }
};
export const repRangeDisplayText = (range: TrainingPlanRepRangeType) => {
  switch (range) {
    case TrainingPlanRepRangeType.Periodized:
      return "Periodized";
    case TrainingPlanRepRangeType.Range_10To_12:
      return "10-12 reps";
    case TrainingPlanRepRangeType.Range_4To_6:
      return "4-6 reps";
    case TrainingPlanRepRangeType.Range_6To_8:
      return "6-8 reps";
    case TrainingPlanRepRangeType.Range_8To_10:
      return "8-10 reps";
    default:
      return "";
  }
};

export const planTypeDisplayText = (plan: TrainingPlanType) => {
  switch (plan) {
    case TrainingPlanType.BarbellDumbbellMachineCable:
      return "BDMC";
    case TrainingPlanType.BodyWeight:
      return "BW";
    case TrainingPlanType.DumbbellBench:
      return "DB";
    case TrainingPlanType.DumbbellMachineCable:
      return "DMC";
    case TrainingPlanType.Kettlebell:
      return "KB";
    case TrainingPlanType.MachineCable:
      return "MC";
    case TrainingPlanType.PowerRackBarbell:
      return "PRB";
    case TrainingPlanType.ResistanceBand:
      return "RB";
    case TrainingPlanType.SmithDumbbellMachineCable:
      return "SDMC";
    case TrainingPlanType.Trx:
      return "TRX";
    case TrainingPlanType.Dumbbell:
      return "D";
    case TrainingPlanType.BarbellDumbbellBench:
      return "BDB";
    case TrainingPlanType.PowerRackBarbellDumbbell:
      return "PRBD";
    default:
      return "";
  }
};
export const fullPlanTypeDisplayText = (plan: TrainingPlanType) => {
  switch (plan) {
    case TrainingPlanType.BarbellDumbbellMachineCable:
      return "Barbell + Dumbbell + Machine + Cable";
    case TrainingPlanType.BodyWeight:
      return "Body Weight";
    case TrainingPlanType.DumbbellBench:
      return "Dumbbell + Bench";
    case TrainingPlanType.DumbbellMachineCable:
      return "Dumbbell + Machine + Cable";
    case TrainingPlanType.Kettlebell:
      return "Kettlebell";
    case TrainingPlanType.MachineCable:
      return "Machine + Cable";
    case TrainingPlanType.PowerRackBarbell:
      return "Power Rack + Barbell";
    case TrainingPlanType.ResistanceBand:
      return "Resistance Band";
    case TrainingPlanType.SmithDumbbellMachineCable:
      return "Smith + Dumbbell + Machine + Cable";
    case TrainingPlanType.Trx:
      return "TRX";
    case TrainingPlanType.Dumbbell:
      return "Dumbbell";
    case TrainingPlanType.BarbellDumbbellBench:
      return "Barbell + Dumbbell + Bench";
    case TrainingPlanType.PowerRackBarbellDumbbell:
      return "Power Rack + Barbell + Dumbbell";
    default:
      return "";
  }
};
