import React, { useState } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { useKeyPress } from "../../utils/customHooks";
import colors from "../../assets/colors";
import ButtonTag from "../ButtonTag";
import { selectCurrentWorkoutGroups } from "../../redux/reducers/TrainingPrograms/selectors";
import { ClientWorkoutGroupTemplate } from "../../types/gqlTypes";
import { deleteClientWorkoutGroupTemplate } from "../../redux/actions/TrainingPrograms/delete";
import AddWorkoutGroupItem from "./AddWorkoutGroupItem";
import { StoreState } from "../../redux/reducers";
import { renderWorkoutGroupThumbnailIcon } from "../../utils";

interface Props {
  show: boolean;
  workoutGroups: ClientWorkoutGroupTemplate[];
  workoutGroupId: string;
  clientId: string;
  onClose: () => void;
  deleteClientWorkoutGroupTemplate: (
    clientId: string,
    clientWorkoutGroupTemplateId: string,
    newDefaultWorkoutGroup: string
  ) => void;
}

const DeleteWorkoutGroupDialog = (props: Props) => {
  const {
    show,
    workoutGroups,
    workoutGroupId,
    clientId,
    onClose,
    deleteClientWorkoutGroupTemplate,
  } = props;

  const [showSelectDefaultWorkoutGroup, setShowSelectDefaultWorkoutGroup] =
    useState(false);
  const [selectedDefaultWorkoutGroup, setSelectedDefaultWorkoutGroup] =
    useState(null);

  const onDelete = () => {
    deleteClientWorkoutGroupTemplate(
      clientId,
      workoutGroupId,
      selectedDefaultWorkoutGroup
    );
    onClose();
  };

  useKeyPress("Enter", () => {
    onDelete();
  });

  useKeyPress("Escape", () => {
    onClose();
  });

  const workoutGroupItem = workoutGroups.find((group) => {
    return group.id === workoutGroupId;
  });

  const defaultWorkoutGroupSelections = workoutGroups.filter((group) => {
    return group.id !== workoutGroupId;
  });

  return (
    <Modal centered show={show} onHide={onClose}>
      <div
        className="d-flex flex-column"
        style={{
          padding: "25px 15px 15px 15px",
          minHeight: 100,
        }}
      >
        {!showSelectDefaultWorkoutGroup ? (
          <div>
            <div
              className="d-flex flex-column justify-content-center align-items-center paragraph-large"
              style={{
                flex: 1,
                marginBottom: 8,
                textAlign: "center",
                color: colors.caliber_secondary,
                lineHeight: "24px",
                fontSize: "18px",
              }}
            >
              Are you sure you want to delete
            </div>
            <div
              className="d-flex flex-column justify-content-center align-items-center paragraph-large"
              style={{
                flex: 1,
                marginBottom: 8,
                textAlign: "center",
                color: colors.caliber_secondary,
                lineHeight: "24px",
                fontSize: "18px",
                fontWeight: 900,
              }}
            >
              {workoutGroupItem.title}
            </div>
            <div
              className="d-flex flex-column justify-content-center align-items-center paragraph-large"
              style={{
                flex: 1,
                marginBottom: 8,
                textAlign: "center",
                color: colors.caliber_secondary_gray_47,
                lineHeight: "14px",
                fontSize: "12px",
              }}
            >
              This will permanently delete this workout <br />
              group, along with all associated data.
            </div>
            <div
              className="d-flex justify-content-center"
              style={{
                height: 62,
              }}
            >
              <ButtonTag
                margin={6}
                text="Cancel"
                onClick={({ event }) => {
                  event.stopPropagation();
                  onClose();
                }}
                height={24}
                textColor={colors.caliber_white}
                bgColor={colors.caliber_gray_text}
                minWidth={80}
              />
              <ButtonTag
                margin={6}
                text="Delete"
                onClick={({ event }) => {
                  event.stopPropagation();
                  if (workoutGroupItem.default && workoutGroups.length > 1) {
                    setShowSelectDefaultWorkoutGroup(true);
                  } else {
                    onDelete();
                  }
                }}
                height={24}
                textColor={colors.caliber_white}
                bgColor={colors.caliber_red_300}
                minWidth={80}
              />
            </div>
          </div>
        ) : (
          <div>
            <div
              className="d-flex flex-column justify-content-center align-items-center paragraph-large"
              style={{
                flex: 1,
                marginBottom: 8,
                textAlign: "center",
                color: colors.caliber_secondary,
                lineHeight: "24px",
                fontSize: "20px",
                fontWeight: 700,
              }}
            >
              Select a new default Workout Group
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              {defaultWorkoutGroupSelections.map((workoutGroup) => {
                return (
                  <AddWorkoutGroupItem
                    key={workoutGroup.id}
                    icon={
                      <img
                        style={{
                          width: 32,
                          height: 32,
                          borderRadius: 8,
                        }}
                        src={renderWorkoutGroupThumbnailIcon(
                          workoutGroup.workoutGroupType
                        )}
                        alt={workoutGroup.workoutGroupType}
                      />
                    }
                    title={workoutGroup.title}
                    isSelected={workoutGroup.id === selectedDefaultWorkoutGroup}
                    onSelected={() => {
                      setSelectedDefaultWorkoutGroup(workoutGroup.id);
                    }}
                  />
                );
              })}
            </div>
            <div
              className="d-flex justify-content-center"
              style={{
                height: 62,
              }}
            >
              <ButtonTag
                margin={6}
                text="Cancel"
                onClick={({ event }) => {
                  event.stopPropagation();
                  onClose();
                }}
                height={24}
                textColor={colors.caliber_white}
                bgColor={colors.caliber_gray_text}
                minWidth={80}
              />
              <ButtonTag
                margin={6}
                text="Save"
                onClick={({ event }) => {
                  event.stopPropagation();
                  onDelete();
                }}
                disabled={selectedDefaultWorkoutGroup === null}
                height={24}
                textColor={colors.caliber_white}
                bgColor={colors.caliber_green_200}
                minWidth={80}
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: StoreState) => ({
  workoutGroups: selectCurrentWorkoutGroups(state),
});

const mapDispatchToProps = (dispatch) => ({
  deleteClientWorkoutGroupTemplate: (
    clientId: string,
    clientWorkoutGroupTemplateId: string,
    newDefaultWorkoutGroup: string
  ) => {
    dispatch(
      deleteClientWorkoutGroupTemplate(
        clientId,
        clientWorkoutGroupTemplateId,
        newDefaultWorkoutGroup
      )
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteWorkoutGroupDialog);
