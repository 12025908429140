import { gql } from "apollo-boost";
import { userFragment } from "..";

export const GET_CLIENT_DATA = gql`
  ${userFragment}
  query GetClientData($id: UUID) {
    user(id: $id) {
      ...UserData
    }
    userBodyWeight: mostRecentBodyMeasurement(
      clientId: $id
      bodyMeasurementType: BODY_WEIGHT
    )
    userFatPercent: mostRecentBodyMeasurement(
      clientId: $id
      bodyMeasurementType: BODY_FAT_PERCENT
    )
    clientConsultation(clientId: $id) {
      id
      questionAndAnswersMap
    }
    goals(clientId: $id) {
      ... on TextGoal {
        id
        text
        targetDate
      }
    }
    chatRoom(clientId: $id) {
      lastDateTrainerReceivedMessage
      lastDateTrainerSentMessage
    }
    zoomCalls(clientId: $id) {
      id
      date
      status
      type
    }
  }
`;

export const CREATE_CLIENT_QUERY = gql`
  mutation CreateClientFromWeb(
    $assignedTrainerId: UUID
    $assignedProGroupId: UUID
    $email: String!
    $firstName: String!
    $gender: GenderType
    $height: Float
    $lastName: String!
    $packageType: ClientPackageType!
    $password: String!
    $profileIconMediaUrlId: UUID
    $weight: Float
  ) {
    createClientFromWeb(
      assignedTrainerId: $assignedTrainerId
      assignedProGroupId: $assignedProGroupId
      email: $email
      firstName: $firstName
      gender: $gender
      height: $height
      lastName: $lastName
      packageType: $packageType
      password: $password
      profileIconMediaUrlId: $profileIconMediaUrlId
      weight: $weight
    ) {
      id
    }
  }
`;
export const UPDATE_CLIENT_QUERY = gql`
  mutation UpdateClientFromWeb(
    $assignedTrainerId: UUID
    $assignedProGroupId: UUID
    $clientId: UUID!
    $email: String!
    $firstName: String!
    $gender: GenderType
    $height: Float
    $lastName: String!
    $packageType: ClientPackageType!
    $password: String
    $profileIconMediaUrlId: UUID
    $status: UserStatusType
  ) {
    updateClientFromWeb(
      assignedTrainerId: $assignedTrainerId
      assignedProGroupId: $assignedProGroupId
      clientId: $clientId
      email: $email
      firstName: $firstName
      gender: $gender
      height: $height
      lastName: $lastName
      packageType: $packageType
      password: $password
      profileIconMediaUrlId: $profileIconMediaUrlId
      status: $status
    ) {
      id
    }
  }
`;

export const SEARCH_CLIENTS = gql`
  query SearchClientsTab(
    $pageNumber: Int!
    $pageSize: Int!
    $searchCriteria: String
    $sortOrder: SortOrder!
    $sortField: ClientSearchResultSortCriteria!
    $statusCriteria: [UserStatusType]
    $clientPackageTypeCriteria: [ClientPackageType]
    $trainerIdFilter: UUID
    $managerIdFilter: UUID
  ) {
    searchClientsTab(
      pageNumber: $pageNumber
      pageSize: $pageSize
      searchCriteria: $searchCriteria
      sortOrder: $sortOrder
      sortField: $sortField
      statusCriteria: $statusCriteria
      clientPackageTypeCriteria: $clientPackageTypeCriteria
      managerIdFilter: $managerIdFilter
      trainerIdFilter: $trainerIdFilter
    ) {
      content {
        id
        profileMediaIconUrl
        firstName
        lastName
      }
      last
      totalPages
      totalElements
      number
    }
  }
`;

export const UPDATE_CLIENT_CALLS_LEFT = gql`
  mutation UpdateClientSalesPackageCallsLeft($clientId: UUID, $callsLeft: Int) {
    updateClientSalesPackageCallsLeft(
      clientId: $clientId
      callsLeft: $callsLeft
    ) {
      callsLeft
    }
  }
`;

export const UPDATE_CLIENT_PACKAGE_END_DATE = gql`
  mutation UpdateClientSalesPackageEndDate(
    $clientId: UUID
    $packageEndDate: LocalDate
  ) {
    updateClientSalesPackageEndDate(
      clientId: $clientId
      packageEndDate: $packageEndDate
    ) {
      packageEndDate
    }
  }
`;

export const GET_SEARCH_CLIENTS_TAB_QUERY = (columns) => gql`
  query SearchClientsTab(
    $pageNumber: Int!
    $pageSize: Int!
    $searchCriteria: String
    $sortOrder: SortOrder!
    $sortField: ClientSearchResultSortCriteria!
    $statusCriteria: [UserStatusType]
    $clientPackageTypeCriteria: [ClientPackageType]
    $trainerIdFilter: UUID
    $managerIdFilter: UUID
  ) {
    searchClientsTab(
      pageNumber: $pageNumber
      pageSize: $pageSize
      searchCriteria: $searchCriteria
      sortOrder: $sortOrder
      sortField: $sortField
      statusCriteria: $statusCriteria
      clientPackageTypeCriteria: $clientPackageTypeCriteria
      managerIdFilter: $managerIdFilter
      trainerIdFilter: $trainerIdFilter
    ) {
      content {
        id
        profileMediaIconUrl
        ${columns.join("\n")}
      }
      last
      totalPages
      totalElements
      number
    }
  }
`;

export const SEARCH_CLIENTS_BY_TRAINER_AND_PACKAGE_TYPE = gql`
  query ClientsByTrainerAndSalesPackageType(
    $trainerId: UUID
    $packageType: ClientPackageType
  ) {
    clientsByTrainerAndSalesPackageType(
      trainerId: $trainerId
      packageType: $packageType
    ) {
      id
    }
  }
`;

export const zoomCallFragment = gql`
  fragment ZoomCallProperty on ZoomCall {
    id
    date
    status
    type
  }
`;

export const FETCH_CLIENT_PROFILE_FOR_CLIENTS_TAB = gql`
  query FetchClientProfileForClientsTab($id: UUID) {
    clientSalesPackage(clientId: $id) {
      clientPackageType
    }
    mostRecentBodyMeasurement(clientId: $id, bodyMeasurementType: BODY_WEIGHT)
    user(id: $id) {
      id
      birthDate
      city
      clientAuthId
      country
      email
      firstName
      gender
      height
      isConsultationCompleted
      isProfileCompleted
      lastName
      phone
      state
      status
      type
      profileIconMediaUrl {
        id
        url
      }
      ... on Client {
        assignedTrainer {
          id
          firstName
          lastName
        }
        proGroups {
          id
          name
        }
      }
    }
  }
`;

export const CHANGE_CLIENT_EMAIL = gql`
  mutation ChangeClientEmail($clientId: UUID!, $newEmail: String!) {
    changeClientEmail(clientId: $clientId, newEmail: $newEmail)
  }
`;
export const UPDATE_NOTES = gql`
  mutation UpdateNotes($notes: String, $userId: UUID!) {
    updateNotes(notes: $notes, userId: $userId) {
      notes
    }
  }
`;

export const ClientNutritionTargetFragment = gql`
  fragment ClientNutritionTargetProperty on ClientNutritionTarget {
    calories
    protein
    carbs
    fat
    macroTargetsActiveDate
    isIntermittentFasting
    isIntermittentFastingActiveDate
    isLogging
    isLoggingActiveDate
    phase
    phaseActiveDate
    protocol
    protocolActiveDate
    mealPlanMediaUrl {
      id
      url
      type
      objectName
    }
  }
`;

export const GET_CLIENT_NUTRITION_TARGET = gql`
  ${ClientNutritionTargetFragment}
  query ClientNutritionTarget($clientId: UUID) {
    clientNutritionTarget(clientId: $clientId) {
      ...ClientNutritionTargetProperty
    }
  }
`;

export const CREATE_UPDATE_CLIENT_NUTRITION_TARGET = gql`
  ${ClientNutritionTargetFragment}
  mutation CreateUpdateClientNutritionTarget(
    $clientId: UUID
    $calories: Int
    $carbs: Int
    $fat: Int
    $protein: Int
    $macroTargetsActiveDate: LocalDate
    $isIntermittentFasting: Boolean
    $isIntermittentFastingActiveDate: LocalDate
    $isLogging: Boolean
    $isLoggingActiveDate: LocalDate
    $phase: NutritionTargetPhaseType
    $phaseActiveDate: LocalDate
    $protocol: NutritionTargetProtocolType
    $protocolActiveDate: LocalDate
    $mealPlanMediaUrlId: UUID
  ) {
    createUpdateClientNutritionTarget(
      clientId: $clientId
      calories: $calories
      carbs: $carbs
      fat: $fat
      protein: $protein
      macroTargetsActiveDate: $macroTargetsActiveDate
      isIntermittentFasting: $isIntermittentFasting
      isIntermittentFastingActiveDate: $isIntermittentFastingActiveDate
      isLogging: $isLogging
      isLoggingActiveDate: $isLoggingActiveDate
      phase: $phase
      phaseActiveDate: $phaseActiveDate
      protocol: $protocol
      protocolActiveDate: $protocolActiveDate
      mealPlanMediaUrlId: $mealPlanMediaUrlId
    ) {
      ...ClientNutritionTargetProperty
    }
  }
`;

export const CREATE_UPDATE_ZOOM_CALL = gql`
  ${zoomCallFragment}
  mutation CreateUpdateZoomCall(
    $clientId: UUID
    $zoomCallId: UUID
    $date: OffsetDateTime
    $calendarItemStatusType: CalendarItemStatusType
    $zoomCallType: ZoomCallType
  ) {
    createUpdateZoomCall(
      calendarItemStatusType: $calendarItemStatusType
      clientId: $clientId
      date: $date
      zoomCallId: $zoomCallId
      zoomCallType: $zoomCallType
    ) {
      ...ZoomCallProperty
    }
  }
`;

export const DELETE_ZOOM_CALL = gql`
  mutation DeleteZoomCall($clientId: UUID, $zoomCallId: UUID) {
    deleteZoomCall(clientId: $clientId, zoomCallId: $zoomCallId)
  }
`;
