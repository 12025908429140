import React from "react";
import colors from "../../assets/colors";
import LessonList from "./LessonList";
import LessonsHeader from "./LessonsHeader";

interface Props {}

const LessonsDashboard = (props: Props) => {
  const {} = props;

  return (
    <div
      className="d-flex flex-column"
      style={{
        width: 840,
        height: 430,
        borderRadius: 12,
        backgroundColor: colors.caliber_white,
        padding: 26,
      }}
    >
      <LessonsHeader />

      <div className="d-flex justify-content-between">
        <LessonList isCompleted key="completed-lessons" />
        <LessonList isCompleted={false} key="incomplete-lessons" />
      </div>
    </div>
  );
};

export default LessonsDashboard;
