import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { addDays, startOfWeek, differenceInDays } from "date-fns";
import { StoreState } from "../../redux/reducers";
import Arc from "./Arc";
import colors from "../../assets/colors";
import { StrengthScoreDashboard } from "../../types/gqlTypes";
import {
  DashboardTypes,
  StrengthScoreInfo,
  StrengthBalanceInfo,
} from "../../models/strengthscore";
import Loader from "../Loader";
import { regenerateStrengthScore } from "../../redux/actions/ClientStrengthDashboard";
import ConfirmationDialog from "../ConfirmationDialog";
import SimpleButton from "../SimpleButton";

interface OwnProps {
  dashboardType: DashboardTypes;
  strengthValue: number;
  strengthLevelProperty: StrengthBalanceInfo & StrengthScoreInfo;
}
interface Props extends OwnProps {
  strengthScoreDashboard: StrengthScoreDashboard;
  isLoading: boolean;
  recalculate: (clientId: string) => void;
}

const StrengthArc = (props: Props) => {
  const {
    dashboardType,
    strengthValue,
    strengthLevelProperty,
    strengthScoreDashboard,
    isLoading,
    recalculate,
  } = props;

  const { id: clientId } = useParams();
  const [confirmRecalculate, setConfirmRecalculate] = useState(false);
  const today = new Date();
  const nextSunday = addDays(startOfWeek(today), 7);
  const daysTillUpdate = differenceInDays(nextSunday, today) + 1;
  const cardWidth = 182;

  if (isLoading) {
    return (
      <div
        className="d-flex"
        style={{
          flex: 1,
          backgroundColor: "#FCFCFF",
          border: "1px solid #F2F3F5",
          borderRadius: 8,
          height: 232,
          minWidth: cardWidth,
          maxWidth: cardWidth,
          width: cardWidth,
          padding: "26px 10px 0px 10px",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 100,
            left: "calc(50% - 16px)",
          }}
        >
          <Loader />
        </div>
      </div>
    );
  }

  const isStrengthScore = dashboardType === DashboardTypes.score;
  const isStrengthBalance = dashboardType === DashboardTypes.balance;

  let strengthDelta;
  let displayDelta;
  let displayBalance;
  let markerPercent;

  if (isStrengthBalance) {
    strengthDelta = Math.round(
      strengthScoreDashboard?.overallStrengthScore?.strengthBalanceDelta * 100
    );
    displayBalance = `${Math.round(strengthValue * 100)}%`;
    markerPercent = strengthValue;
    displayDelta = `${Math.abs(strengthDelta)} pct`;
  } else if (isStrengthScore) {
    displayBalance = `${strengthValue}`;
    strengthDelta =
      strengthScoreDashboard?.overallStrengthScore?.strengthScoreDelta;
    markerPercent = (strengthValue - strengthLevelProperty.startNumber) / 100;
    displayDelta = `${Math.abs(Math.round(strengthDelta))} pts`;
  }

  const hasNoScore = !strengthValue || strengthValue == null;
  const delta = Math.round(strengthDelta * 100);
  const isTickerUp = delta >= 0;

  if (hasNoScore) {
    markerPercent = 0;
    strengthLevelProperty.tagText = "pending";
    displayBalance = `--${isStrengthBalance ? "%" : ""}`;
    displayDelta = `-- ${isStrengthBalance ? "pct" : "pts"}`;
  }

  return (
    <div
      className="d-flex flex-column align-items-center"
      style={{
        flex: 1,
        backgroundColor: "#FCFCFF",
        border: "1px solid #F2F3F5",
        borderRadius: 8,
        height: 232,
        width: cardWidth,
        padding: "26px 10px 0px 10px",
        position: "relative",
      }}
    >
      {confirmRecalculate && (
        <ConfirmationDialog
          show={confirmRecalculate}
          text="Recalculate this week’s strength metrics?"
          confirmButtonText="Recalculate"
          cancelButtonText="Cancel"
          onConfirm={() => {
            recalculate(clientId);
            setConfirmRecalculate(false);
          }}
          onCancel={() => setConfirmRecalculate(false)}
        />
      )}
      <Arc
        dashboardType={dashboardType}
        startColor={strengthLevelProperty.areaColor}
        endColor={strengthLevelProperty.lineColor}
        markerPercent={markerPercent}
        color={strengthLevelProperty.markerColor}
        startNumber={strengthLevelProperty.startText}
        endNumber={strengthLevelProperty.endText}
        text={displayBalance}
        levelText={strengthLevelProperty.tagText}
        displayDelta={displayDelta}
        isTickerUp={isTickerUp}
      />
      <div
        className="d-flex justify-content-center medium-bold"
        style={{
          width: cardWidth,
          fontSize: "10px",
          letterSpacing: "0.04px",
          lineHeight: "13px",
          color: colors.caliber_gray_border,
          position: "absolute",
          top: 177,
        }}
      >
        {`NEXT UPDATE IN ${daysTillUpdate} DAY${
          daysTillUpdate !== 1 ? "S" : ""
        }`}
      </div>
      <SimpleButton
        onClick={() => setConfirmRecalculate(true)}
        className="d-flex justify-content-center medium-bold"
        nofocus
        style={{
          width: cardWidth,
          textDecoration: "underline",
          fontSize: "12px",
          lineHeight: "13px",
          color: colors.caliber_gray_border,
          position: "absolute",
          top: 195,
        }}
      >
        Recalculate
      </SimpleButton>
    </div>
  );
};
const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  strengthScoreDashboard:
    state.clientStrengthDashboardState.strengthScoreDashboard,
  isLoading: state.clientStrengthDashboardState.isLoading,
  strengthLevelProperty: ownProps.strengthLevelProperty,
  strengthValue: ownProps.strengthValue,
  dashboardType: ownProps.dashboardType,
});
const mapDispatchToProps = (dispatch) => ({
  recalculate: (clientId: string) => {
    dispatch(regenerateStrengthScore(clientId));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(StrengthArc);
