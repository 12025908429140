import React, { useState } from "react";

interface Props {
  children: any;
  id?: any;
  style?: React.CSSProperties;
  nopointer?: boolean;
  nofocus?: boolean;
  disabled?: boolean;
  className?: string;
  isIcon?: boolean;
  iconStyle?: React.CSSProperties;
  styleOnHover?: React.CSSProperties;
  onClick: (event?: any) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onBlur?: (event: any) => void;
}

const SimpleButton = (props: Props) => {
  const {
    children,
    id,
    style,
    nopointer,
    nofocus,
    disabled,
    className,
    isIcon,
    iconStyle,
    styleOnHover,
    onClick,
    onMouseEnter,
    onMouseLeave,
    onBlur,
  } = props;

  const [hover, setHover] = useState(false);

  return (
    <div
      id={id}
      role="button"
      tabIndex={0}
      onBlur={(event) => onBlur?.(event)}
      onMouseEnter={(event) => {
        if (onMouseEnter) {
          onMouseEnter();
        }
        if (styleOnHover) {
          setHover(true);
        }
      }}
      onMouseLeave={(event) => {
        if (onMouseLeave) {
          onMouseLeave();
        }
        if (styleOnHover) {
          setHover(false);
        }
      }}
      onClick={(event) => {
        event.stopPropagation();
        if (!disabled) {
          onClick(event);
        }
      }}
      onKeyDown={(event) => {
        if (event.key === "Enter" && !disabled) {
          onClick(event);
        }
      }}
      className={`${!nopointer && !disabled ? "pointer" : ""} ${
        nofocus ? "nofocus" : ""
      } ${className}`}
      style={{
        ...(style || {}),
        ...(styleOnHover && hover && styleOnHover),
      }}
    >
      {isIcon ? <img src={children} style={iconStyle || {}} /> : children}
    </div>
  );
};

export default SimpleButton;
