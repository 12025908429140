import {
  WEEKLY_CONTENT_BY_CLIENT_ID_SUCCESS,
  WEEKLY_CONTENT_BY_CLIENT_ID_FAIL,
  WEEKLY_CONTENT_BY_CLIENT_ID_LOADING,
  WEEKLY_CONTENT_BY_WEEK_NUMBER_SUCCESS,
  WEEKLY_CONTENT_BY_WEEK_NUMBER_FAIL,
  WEEKLY_CONTENT_BY_WEEK_NUMBER_LOADING,
  WeeklyContentAction,
  WeeklyContentState,
} from "../../actions/WeeklyContent/types";

const initialState: WeeklyContentState = {
  isLoading: false,
  data: null,
};

const weeklyContentState = (
  state = initialState,
  action: WeeklyContentAction
) => {
  switch (action.type) {
    case WEEKLY_CONTENT_BY_CLIENT_ID_LOADING:
    case WEEKLY_CONTENT_BY_WEEK_NUMBER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case WEEKLY_CONTENT_BY_CLIENT_ID_SUCCESS:
    case WEEKLY_CONTENT_BY_WEEK_NUMBER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data,
      };
    case WEEKLY_CONTENT_BY_CLIENT_ID_FAIL:
    case WEEKLY_CONTENT_BY_WEEK_NUMBER_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default weeklyContentState;
