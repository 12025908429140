import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { StoreState } from "../../../redux/reducers";
import {
  CalendarDay,
  CalendarItem,
  ClientHabitTarget,
  HabitCalendarItem,
  HabitType,
  MutationCreateHabitCalendarItemArgs,
  MutationCreateHabitRecurringEventDefinitionArgs,
  MutationUpdateRecurringEventDefinitionArgs,
  RecurringEventDefinition,
} from "../../../types/gqlTypes";
import colors from "../../../assets/colors";
import Header, { HeaderType } from "../Header";
import RecurringMenu from "../../Calendar/RecurringMenu";
import SimpleButton from "../../SimpleButton";
import Checkbox from "../../Icons/Checkbox";
import Footer from "../Footer";
import {
  createHabitCalendarItem,
  createHabitRecurringEventDefinition,
  updateRecurringEventDefinition,
} from "../../../redux/actions/Calendar";
import SelectHabit from "./SelectHabit";
import StepsHabitCard from "./StepsHabitCard";

interface OwnProps {
  onClose: () => void;
  onBack: () => void;
  day: Date;
  calendarDay: CalendarDay;
  calendarItem?: CalendarItem;
}
interface Props extends OwnProps {
  createHabitCalendarItem: (args: MutationCreateHabitCalendarItemArgs) => void;
  createHabitRecurringEventDefinition: (
    args: MutationCreateHabitRecurringEventDefinitionArgs
  ) => void;
  updateRecurringEventDefinition: (
    args: MutationUpdateRecurringEventDefinitionArgs
  ) => void;
  habits: ClientHabitTarget[];
}

const HabitEvent = (props: Props) => {
  const {
    day,
    calendarItem,
    calendarDay,
    onClose,
    onBack,
    createHabitCalendarItem,
    createHabitRecurringEventDefinition,
    updateRecurringEventDefinition,
    habits,
  } = props;
  const { id: clientId } = useParams();
  const [selectedHabit, setSelectedHabit] = useState<HabitType>(null);
  const [repeat, setRepeat] = useState(false);
  const [recurringEvent, setRecurringEvent] =
    useState<RecurringEventDefinition>({
      RRule: "",
      endDate: format(day, "yyyy-MM-dd"),
      startDate: format(day, "yyyy-MM-dd"),
    });
  const isUpdating = !!calendarItem;

  useEffect(() => {
    if (calendarItem) {
      setRepeat(true);
      setSelectedHabit((calendarItem as HabitCalendarItem)?.habitType);
      setRecurringEvent({
        RRule: calendarItem?.recurringEventDefinition?.RRule,
        endDate: calendarItem?.recurringEventDefinition?.endDate,
        startDate: calendarItem?.recurringEventDefinition?.startDate,
      });
    }
  }, [calendarItem]);

  const headerTypesAndHabits = {
    [HabitType.Steps]: HeaderType.STEPS_HABIT,
  };
  const onBackClick = () => {
    if (calendarItem) {
      onClose();
    } else if (selectedHabit) {
      setSelectedHabit(null);
      setRepeat(false);
    } else {
      onBack();
    }
  };
  const onSuccess = () => {
    if (repeat && selectedHabit) {
      const ind = recurringEvent.RRule.indexOf("RRULE:");
      if (isUpdating) {
        updateRecurringEventDefinition({
          clientId,
          rRule: recurringEvent.RRule.slice(ind + 6),
          recurringEventDefinitionId:
            calendarItem?.recurringEventDefinition?.id,
          endDate: recurringEvent.endDate,
        });
      } else {
        createHabitRecurringEventDefinition({
          clientId,
          habitType: selectedHabit,
          rRule: recurringEvent.RRule.slice(ind + 6),
          startDate: recurringEvent.startDate,
          endDate: recurringEvent.endDate,
        });
      }
    } else if (selectedHabit) {
      const [year, month, dayOfMonth] = format(day, "yyyy-MM-dd").split("-");
      createHabitCalendarItem({
        clientId,
        habitType: selectedHabit,
        quantity: 0,
        dayOfMonth: Number(dayOfMonth),
        month: Number(month),
        year: Number(year),
      });
    }
    onClose();
  };

  const repeatStyle: React.CSSProperties = {
    fontSize: "16px",
    lineHeight: "16px",
    letterSpacing: "-0.022em",
    color: colors.caliber_gray_text,
    marginLeft: 8,
    marginBottom: 24,
  };

  return (
    <div className="d-flex flex-column">
      <Header
        day={day}
        type={
          selectedHabit ? headerTypesAndHabits[selectedHabit] : HeaderType.HABIT
        }
        onBackClick={onBackClick}
      />

      {!selectedHabit && (
        <SelectHabit
          habits={habits}
          calendarDay={calendarDay}
          onClick={(habit) => setSelectedHabit(habit)}
        />
      )}

      {selectedHabit === HabitType.Steps && (
        <div
          style={{
            marginTop: 27,
            marginBottom: 24,
          }}
        >
          <StepsHabitCard
            habit={habits?.find(
              (habit) => habit?.habitType === HabitType.Steps
            )}
          />
        </div>
      )}

      {selectedHabit && (
        <div className="d-flex" style={{}}>
          <Checkbox
            disabled={isUpdating}
            checked={repeat}
            onClick={() => setRepeat(!repeat)}
          />
          <SimpleButton
            disabled={isUpdating}
            onClick={() => setRepeat(!repeat)}
            nofocus
            className="bold"
            style={repeatStyle}
          >
            Repeat
          </SimpleButton>
        </div>
      )}

      {repeat && (
        <RecurringMenu
          day={day}
          recurringEvent={recurringEvent}
          onChange={(recurringEvent) => setRecurringEvent(recurringEvent)}
        />
      )}

      {selectedHabit && (
        <Footer
          successText={calendarItem ? "Update activity" : "Add activity"}
          onSuccess={onSuccess}
          onCancel={() => {
            if (calendarItem) {
              onClose();
            } else {
              onBack();
            }
          }}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  createHabitCalendarItem: (args: MutationCreateHabitCalendarItemArgs) => {
    dispatch(createHabitCalendarItem(args));
  },
  createHabitRecurringEventDefinition: (
    args: MutationCreateHabitRecurringEventDefinitionArgs
  ) => {
    dispatch(createHabitRecurringEventDefinition(args));
  },
  updateRecurringEventDefinition: (
    args: MutationUpdateRecurringEventDefinitionArgs
  ) => {
    dispatch(updateRecurringEventDefinition(args));
  },
});

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  onClose: ownProps.onClose,
  onBack: ownProps.onBack,
  day: ownProps.day,
  calendarItem: ownProps.calendarItem,
  calendarDay: ownProps.calendarDay,

  habits: state.clientHabitsState.habits,
});

export default connect(mapStateToProps, mapDispatchToProps)(HabitEvent);
