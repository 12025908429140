import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import colors from "../../assets/colors";
import { StoreState } from "../../redux/reducers";
import DebugClientsTab from "./DebugClientsTab";
import DebugErrorHandle from "./DebugErrorHandle";
import DebugNutritionColumns from "./DebugNutritionColumns";
import DebugReminderNotification from "./DebugReminderNotification";
import DebugSecurity from "./DebugSecurity";
import DebugStrengthMetrics from "./DebugStrengthMetrics";
import DebugStrengthScore from "./DebugStrengthScore";

enum NavTypes {
  DebugStrengthScore,
  TestCrash,
  DebugStrengthMetrics,
  DebugReminderNotification,
  DebugErrorHandle,
  DebugNutritionColumns,
  DebugClientsTab,
  Security,
}
interface Props {}

const DebugMasqueradeTrainer = (props: Props) => {
  const {} = props;

  const [currentNav, setCurrentNav] = useState(NavTypes.DebugStrengthMetrics);

  const navs = [
    {
      title: "Debug Strength Metrics 2.0",
      value: NavTypes.DebugStrengthMetrics,
    },
    {
      title: "Debug Reminder Notifications",
      value: NavTypes.DebugReminderNotification,
    },
    { title: "Debug Error Handle", value: NavTypes.DebugErrorHandle },
    { title: "Debug Nutrition Columns", value: NavTypes.DebugNutritionColumns },
    { title: "Debug Clients Tab", value: NavTypes.DebugClientsTab },
    { title: "Test Crash", value: NavTypes.TestCrash },
    { title: "Security", value: NavTypes.Security },
  ];

  const CurrentComponent = useMemo(() => {
    switch (currentNav) {
      case NavTypes.DebugStrengthScore:
        return <DebugStrengthScore />;
      case NavTypes.DebugStrengthMetrics:
        return <DebugStrengthMetrics />;
      case NavTypes.DebugReminderNotification:
        return <DebugReminderNotification />;
      case NavTypes.DebugErrorHandle:
        return <DebugErrorHandle />;
      case NavTypes.DebugNutritionColumns:
        return <DebugNutritionColumns />;
      case NavTypes.DebugClientsTab:
        return <DebugClientsTab />;
      case NavTypes.TestCrash:
        const arr = [];
        arr[0]();
        return <div> </div>;
      case NavTypes.Security:
        return <DebugSecurity />;
      default:
        return <DebugStrengthScore />;
    }
  }, [currentNav]);

  return (
    <div
      className="col-16 d-flex flex-column"
      style={{
        flex: 1,
      }}
    >
      <div
        className="d-flex"
        style={{
          marginBottom: 24,
          height: 48,
          backgroundColor: colors.caliber_secondary_gray_5,
        }}
      >
        {navs.map((nav) => {
          const isCurrent = nav.value === currentNav;
          return (
            <div
              role="button"
              tabIndex={0}
              onClick={() => setCurrentNav(nav.value)}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  setCurrentNav(nav.value);
                }
              }}
              className="d-flex pointer nofocus"
              style={{
                margin: 8,
                backgroundColor: isCurrent && colors.nav_item_grey,
                color: isCurrent
                  ? colors.caliber_secondary
                  : colors.caliber_gray_text,
                height: 31,
                fontSize: "14px",
                lineHeight: "24px",
                padding: "4px 8px",
                borderRadius: 4,
              }}
            >
              {nav.title}
            </div>
          );
        })}
      </div>
      {CurrentComponent}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state: StoreState) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DebugMasqueradeTrainer);
