import React from "react";

interface Props {
  startColor: string;
  endColor: string;
}

const StrengthScoreArc = (props: Props) => {
  const { startColor, endColor } = props;

  return (
    <svg
      width="140"
      height="70"
      viewBox="0 0 140 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 70H12C12 37.9675 37.9675 12 70 12C102.033 12 128 37.9675 128 70H140C140 31.3401 108.66 0 70 0C31.3401 0 0 31.3401 0 70Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="1.33971"
          y1="35"
          x2="140"
          y2="35"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={endColor} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default StrengthScoreArc;
