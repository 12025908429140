import React, { useContext } from "react";
import colors from "../../assets/colors";
import { ClientNutritionTarget, MacronutrientType } from "../../types/gqlTypes";
import { chartColors, ChartTypes } from "./NutritionChart";
import { NutritionProgressContext } from "./index";

interface Props {
  chartType: ChartTypes;
  clientNutritionTarget: ClientNutritionTarget;
}

interface TableRow {
  color: string;
  title: string;
  dailyTarget: number;
  dailyAverage: number;
  vsTarget: string;
}

const SummaryTable = (props: Props) => {
  const { chartType, clientNutritionTarget } = props;
  const { nutritionDailyAverages } = useContext(NutritionProgressContext);

  const pushTableRow = (
    table,
    nutrientType,
    nutrientTarget,
    title,
    dailyAverage
  ) => {
    const dailyTarget = clientNutritionTarget?.[nutrientTarget];
    const vsTarget = Number((dailyAverage * 100) / (dailyTarget || 1)).toFixed(
      1
    );
    table.push({
      color: chartColors[nutrientType],
      title,
      dailyTarget,
      dailyAverage,
      vsTarget,
    });
  };

  const tableRows: TableRow[] = [];
  if (chartType === ChartTypes.CALORIES) {
    pushTableRow(
      tableRows,
      MacronutrientType.Calories,
      "calories",
      "Calories",
      nutritionDailyAverages.CALORIES
    );
  }
  if (chartType === ChartTypes.PROTEIN || chartType === ChartTypes.MACROS) {
    pushTableRow(
      tableRows,
      MacronutrientType.Protein,
      "protein",
      "Protein",
      nutritionDailyAverages.PROTEIN
    );
  }
  if (chartType === ChartTypes.FAT || chartType === ChartTypes.MACROS) {
    pushTableRow(
      tableRows,
      MacronutrientType.Fat,
      "fat",
      "Fat",
      nutritionDailyAverages.FAT
    );
  }
  if (chartType === ChartTypes.CARBS || chartType === ChartTypes.MACROS) {
    pushTableRow(
      tableRows,
      MacronutrientType.Carbs,
      "carbs",
      "Carbs",
      nutritionDailyAverages.CARBS
    );
  }

  const tableHeaderStyle: React.CSSProperties = {
    width: 80,
    height: 14,
    fontSize: "12px",
    lineHeight: "14px",
    margin: "16px 10px 16px 10px",
    color: colors.caliber_gray_text,
  };
  const tableCellStyle: React.CSSProperties = {
    width: 80,
    height: 14,
    fontSize: "12px",
    lineHeight: "14px",
    color: colors.caliber_secondary,
    margin: "0px 10px 14px 10px",
  };
  const cellClassname =
    "d-flex align-items-center justify-content-center medium-bold";

  return (
    <div
      className="d-flex flex-column align-items-center"
      style={{
        marginBottom: 20,
      }}
    >
      <div
        style={{
          width: 72,
          height: 5,
          marginBottom: 25,
          marginTop: 10,
          backgroundColor: colors.caliber_secondary_gray_11,
          borderRadius: 10,
        }}
      >
        {/* rectangular */}
      </div>
      <div
        className="d-flex flex-wrap"
        style={{
          width: 400,
          backgroundColor: colors.caliber_secondary_gray_5,
          borderRadius: 10,
          paddingBottom: 8,
        }}
      >
        <table cellSpacing="0" cellPadding="0">
          <thead>
            <tr>
              <td>
                <div style={tableHeaderStyle}> </div>
              </td>
              <td>
                <div style={tableHeaderStyle} className={cellClassname}>
                  Daily Target
                </div>
              </td>
              <td>
                <div style={tableHeaderStyle} className={cellClassname}>
                  Daily Average
                </div>
              </td>
              <td>
                <div style={tableHeaderStyle} className={cellClassname}>
                  vs Target
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            {tableRows.map(
              ({ color, title, dailyAverage, dailyTarget, vsTarget }) => {
                return (
                  <tr key={`${chartType}-${title}-summary-table`}>
                    <td>
                      <div
                        style={{ ...tableCellStyle, marginLeft: 20 }}
                        className="d-flex align-items-center medium-bold"
                      >
                        <div
                          style={{
                            backgroundColor: color,
                            width: 14,
                            height: 14,
                            borderRadius: 2,
                            marginRight: 8,
                          }}
                        >
                          {/* rect */}
                        </div>
                        <div>{title}</div>
                      </div>
                    </td>
                    <td>
                      <div style={tableCellStyle} className={cellClassname}>
                        {dailyTarget != null && dailyTarget}
                        {dailyTarget == null && "No target set"}
                      </div>
                    </td>
                    <td>
                      <div style={tableCellStyle} className={cellClassname}>
                        {dailyAverage != null && dailyAverage}
                        {dailyAverage == null && "Not enough data"}
                      </div>
                    </td>
                    <td>
                      <div style={tableCellStyle} className={cellClassname}>
                        {dailyAverage != null && `${vsTarget}%`}
                        {dailyAverage == null && "--%"}
                      </div>
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SummaryTable;
