import apolloClient from "../../../api/apolloClient";
import {
  CREATE_UPDATE_COACH_PROFILE,
  GET_COACH_PROFILE,
} from "../../../api/gql/users/trainers";
import { getAppConfig } from "../../../config";
import {
  CoachProfile,
  FileUploadType,
  MutationCreateUpdateCoachProfileArgs,
} from "../../../types/gqlTypes";
import {
  SAVE_COACH_PROFILE_FAIL,
  SAVE_COACH_PROFILE_SAVING,
  SAVE_COACH_PROFILE_SUCCESS,
  GET_COACH_PROFILE_FAIL,
  GET_COACH_PROFILE_LOADING,
  GET_COACH_PROFILE_SUCCESS,
  COACH_PROFILE_IMAGE_UPLOADING,
  COACH_PROFILE_IMAGE_UPLOAD_FAIL,
  COACH_PROFILE_IMAGE_UPLOAD_SUCCESS,
  COACH_PROFILE_IMAGE_UPLOAD_FAIL_SIZE_EXCEEDED,
  CoachProfileAction,
  CHANGE_COACH_PROFILE_STATE,
  COACH_PROFILE_CROPPED_IMAGE_UPLOAD_SUCCESS,
  CANCEL_UPLOAD_COACH_PROFILE_IMAGE,
  RESET_COACH_PROFILE_STATE,
} from "./types";

export const getCoachProfile =
  (trainerId: string) => async (dispatch, getState) => {
    dispatch({
      type: GET_COACH_PROFILE_LOADING,
    } as CoachProfileAction);
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const result = (
        await client.query({
          query: GET_COACH_PROFILE,
          variables: { trainerId },
        })
      ).data.coachProfile as CoachProfile;
      dispatch({
        type: GET_COACH_PROFILE_SUCCESS,
        coachProfile: result,
      } as CoachProfileAction);
    } catch (error) {
      console.error("Error getting a coach profile:", error);
      dispatch({
        type: GET_COACH_PROFILE_FAIL,
      } as CoachProfileAction);
    }
  };

export const createUpdateCoachProfile =
  (args: MutationCreateUpdateCoachProfileArgs) =>
  async (dispatch, getState) => {
    dispatch({
      type: SAVE_COACH_PROFILE_SAVING,
    } as CoachProfileAction);
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const result = (
        await client.mutate({
          mutation: CREATE_UPDATE_COACH_PROFILE,
          variables: args,
        })
      ).data.createUpatedCoachProfile as CoachProfile;

      dispatch({
        type: SAVE_COACH_PROFILE_SUCCESS,
        coachProfile: result,
      } as CoachProfileAction);
    } catch (error) {
      console.error("Error creating/updating a coach profile:", error);
      dispatch({
        type: SAVE_COACH_PROFILE_FAIL,
      } as CoachProfileAction);
    }
  };

export const uploadCoachProfileImage =
  (file: any) => async (dispatch, getState) => {
    if (getState().coachProfileState?.isUploading) {
      return;
    }
    dispatch({
      type: COACH_PROFILE_IMAGE_UPLOADING,
    } as CoachProfileAction);

    try {
      const apiUrl = `${getAppConfig().apiUrl}/upload`;
      const authInfo = getState().authState;

      const formData = new FormData();

      formData.append("file", file);
      formData.append("type", FileUploadType.ProfilePic);

      const uploadResponse = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authInfo.authToken}`,
        },
        body: formData,
      });
      const json = await uploadResponse.json();
      if (json?.message?.includes("Maximum upload size exceeded")) {
        dispatch({
          type: COACH_PROFILE_IMAGE_UPLOAD_FAIL_SIZE_EXCEEDED,
          data: json,
          message:
            "Maximum upload size exceeded.\nPlease reduce the file size and try again.",
        } as CoachProfileAction);
      } else {
        dispatch({
          type: COACH_PROFILE_IMAGE_UPLOAD_SUCCESS,
          response: json,
        } as CoachProfileAction);
      }
    } catch (error) {
      console.log("failed to upload a coach profile image", error);
      dispatch({
        type: COACH_PROFILE_IMAGE_UPLOAD_FAIL,
        data: error,
      } as CoachProfileAction);
    }
  };
export const uploadCroppedCoachProfileImage =
  (file: any) => async (dispatch, getState) => {
    if (getState().coachProfileState?.isUploading) {
      return;
    }
    dispatch({
      type: COACH_PROFILE_IMAGE_UPLOADING,
    } as CoachProfileAction);

    try {
      const apiUrl = `${getAppConfig().apiUrl}/upload`;
      const authInfo = getState().authState;

      const formData = new FormData();

      formData.append("file", file);
      formData.append("type", FileUploadType.CoachProfilePic);

      const uploadResponse = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authInfo.authToken}`,
        },
        body: formData,
      });
      const json = await uploadResponse.json();
      dispatch({
        type: COACH_PROFILE_CROPPED_IMAGE_UPLOAD_SUCCESS,
        response: json,
      } as CoachProfileAction);
    } catch (error) {
      console.log("failed to upload a cropped coach profile image", error);
      dispatch({
        type: CANCEL_UPLOAD_COACH_PROFILE_IMAGE,
      } as CoachProfileAction);
    }
  };

export const cancelUploadCoachProfileImage =
  () => async (dispatch, getState) => {
    dispatch({
      type: CANCEL_UPLOAD_COACH_PROFILE_IMAGE,
    } as CoachProfileAction);
  };

export const changeCoachProfileState =
  (coachProfile: CoachProfile) => async (dispatch, getState) => {
    dispatch({
      type: CHANGE_COACH_PROFILE_STATE,
      coachProfile,
    } as CoachProfileAction);
  };

export const resetCoachProfileState = () => async (dispatch, getState) => {
  dispatch({
    type: RESET_COACH_PROFILE_STATE,
  } as CoachProfileAction);
};
