import React from "react";

interface Props {}

const SuccessIcon = (props: Props) => {
  const {} = props;

  return (
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="48" cy="48" rx="48" ry="48" fill="#8FE3B2" />
      <path
        d="M70.3327 34.6665L40.9994 63.9998L27.6661 50.6665"
        stroke="white"
        strokeWidth="8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SuccessIcon;
