import React, { useMemo } from "react";
import { connect } from "react-redux";
import { StoreState } from "../../../redux/reducers";
import {
  KeyDriverExplanationTextType,
  StrengthScore,
  StrengthScoreMuscleGroupType,
} from "../../../types/gqlTypes";
import { muscleTitles } from ".";

interface OwnProps {
  muscleGroup: StrengthScore;
}
interface Props extends OwnProps {
  bodyWeight: number;
}

const StrengthScoreExplanation = (props: Props) => {
  const { muscleGroup, bodyWeight } = props;

  const {
    ormDelta,
    orm,
    type,
    exerciseLight,
    strengthScoreDelta,
    keyDriverExplanationTextType,
  } = muscleGroup || {};

  const isScoreDeltaNegative = strengthScoreDelta < 0;
  const isOrmDeltaNegative = ormDelta < 0;

  const text = useMemo(() => {
    const texts = {
      [KeyDriverExplanationTextType.OrmDelta]: `\
        Your ${muscleTitles?.[type]} Strength Score went ${
        isScoreDeltaNegative ? "down" : "up"
      } by ${Math.abs(strengthScoreDelta)} pts. \
        A key driver of this change was that you ${
          isOrmDeltaNegative ? "decreased" : "increased"
        } \
        your estimated 1-rep max for ${exerciseLight?.name} by ${Math.abs(
        ormDelta
      )} ${Math.abs(ormDelta) === 1 ? "lb" : "lbs"}.`,
      [KeyDriverExplanationTextType.Orm]: `\
        Your ${muscleTitles?.[type]} Strength Score went ${
        isScoreDeltaNegative ? "down" : "up"
      } by ${Math.abs(strengthScoreDelta)} pts. \
        A key driver of this change was that your estimated 1-rep max for ${
          exerciseLight?.name
        } was ${orm} ${orm === 1 ? "lb" : "lbs"}.`,
      [KeyDriverExplanationTextType.BodyWeight]: `A key driver of this change is that your body weight is now ${bodyWeight} ${
        bodyWeight === 1 ? "lb" : "lbs"
      }.`,
      [KeyDriverExplanationTextType.Demographic]:
        "A key driver of this change was an update to your demographic information.",
      [KeyDriverExplanationTextType.None]: `Your ${muscleTitles?.[type]} Strength Score cannot yet be calculated. \
        Please reach out to your coach for more information.`,
    };

    if (
      strengthScoreDelta === 0 &&
      type !== StrengthScoreMuscleGroupType.Weight
    ) {
      return `Your ${muscleTitles?.[type]} Strength Score did not change this week.`;
    }
    if (
      strengthScoreDelta === 0 &&
      type === StrengthScoreMuscleGroupType.Weight
    ) {
      return "Your weight did not change this week.";
    }
    return texts?.[keyDriverExplanationTextType];
  }, [muscleGroup]);

  return (
    <div
      className="medium-bold"
      style={{
        fontSize: "14px",
        lineHeight: "16px",
        letterSpacing: "-0.022em",
        color: "#000632",
        margin: "20px 4px",
        width: 326,
        minHeight: 48,
      }}
    >
      {text}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  muscleGroup: ownProps.muscleGroup,
  bodyWeight: state.clientDetailState.bodyWeight,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StrengthScoreExplanation);
