import React, { useState } from "react";
import colors from "../../assets/colors";
import { User } from "../../types/gqlTypes";
import SimpleButton from "../SimpleButton";

interface Props {
  user: User;
  message: string;
  canBeSent: boolean;
  canBeEdited: boolean;
  canBeDiscarded: boolean;
  onEdit: () => void;
  onDiscard: () => void;
  onMessageChange: (value: string) => void;
  onSend: () => void;
  onSwap: () => void;
}

const Footer = (props: Props) => {
  const {
    user,
    message,
    canBeSent,
    canBeEdited,
    canBeDiscarded,
    onEdit,
    onDiscard,
    onMessageChange,
    onSend,
    onSwap,
  } = props;

  const buttonStyle: React.CSSProperties = {
    height: 34,
    fontSize: "13px",
    lineHeight: "16px",
    letterSpacing: "-0.022em",
    color: colors.caliber_white,
    borderRadius: 8,
  };

  return (
    <div className="d-flex flex-column align-items-center">
      <div className="d-flex" style={{ marginTop: 32 }}>
        {!canBeDiscarded && <SwapIcon onClick={onSwap} />}

        {!canBeDiscarded && (
          <SimpleButton
            onClick={onEdit}
            disabled={!canBeEdited}
            className="d-flex align-items-center justify-content-center bold"
            style={{
              width: 80,
              backgroundColor: canBeEdited
                ? "#5B64A0"
                : colors.caliber_gray_border,
              ...buttonStyle,
            }}
          >
            Edit
          </SimpleButton>
        )}

        {canBeDiscarded && (
          <SimpleButton
            onClick={onDiscard}
            className="d-flex align-items-center justify-content-center bold"
            style={{
              width: 80,
              backgroundColor: canBeDiscarded
                ? "#5B64A0"
                : colors.caliber_gray_border,
              ...buttonStyle,
            }}
          >
            Discard
          </SimpleButton>
        )}
      </div>

      <div
        className="bold"
        style={{
          width: 280,
          fontSize: "14px",
          lineHeight: "14px",
          color: colors.caliber_gray_text,
          marginTop: 80,
          marginBottom: 11,
        }}
      >
        Add a note ( Optional )
      </div>

      <textarea
        value={message}
        onChange={(event) => onMessageChange(event.target.value)}
        style={{
          width: 280,
          height: 72,
          backgroundColor: colors.caliber_white,
          color: colors.caliber_gray_text,
          border: "1px solid white",
          padding: 12,
          fontSize: "14px",
          lineHeight: "16px",
        }}
      />

      <SimpleButton
        onClick={onSend}
        disabled={!canBeSent}
        className="d-flex align-items-center justify-content-center bold"
        style={{
          minWidth: 184,
          backgroundColor: canBeSent
            ? colors.caliber_green_200
            : colors.caliber_gray_border,
          marginTop: 46,
          marginBottom: 46,
          ...buttonStyle,
        }}
      >
        {`Send to ${user?.firstName} ${user?.lastName}`}
      </SimpleButton>
    </div>
  );
};

const SwapIcon = ({ onClick }: { onClick: () => void }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <SimpleButton
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{
        position: "relative",
        width: 48,
        height: 48,
        marginRight: 20,
      }}
    >
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="24" cy="16" r="16" fill={hovered ? "#5B64A0" : "#989BAD"} />
        <path
          d="M22.0677 11.3339C23.3138 10.8177 24.6858 10.8177 25.9319 11.3339C26.7132 11.6575 27.3857 12.1596 27.9059 12.7959C28 12.911 28.0011 13.0439 27.9433 13.1505C27.8846 13.2588 27.7638 13.3411 27.6092 13.3411H26.7701C26.4803 13.3411 26.2453 13.576 26.2453 13.8659C26.2453 14.1557 26.4803 14.3906 26.7701 14.3906H29.0735C29.4049 14.3906 29.6735 14.122 29.6735 13.7906V11.4872C29.6735 11.1974 29.4385 10.9625 29.1487 10.9625H29.0417C28.811 10.9625 28.6239 11.1495 28.6239 11.3803C28.6239 11.4812 28.558 11.5615 28.4602 11.5987C28.3616 11.6363 28.2482 11.6226 28.1704 11.5498C26.476 9.96588 23.9454 9.42005 21.666 10.3642C20.6814 10.7721 19.8189 11.4314 19.1717 12.2712C18.6323 12.9711 18.25 13.7843 18.0532 14.6443C17.9869 14.9341 18.1908 15.2035 18.4751 15.2477C18.7634 15.2924 19.0241 15.0942 19.0894 14.8203C19.4648 13.2461 20.5541 11.9608 22.0677 11.3339Z"
          fill="white"
          stroke="white"
          strokeWidth="0.2"
        />
        <path
          d="M25.5058 19.88C24.2597 20.3961 22.8877 20.3962 21.6416 19.88C20.8603 19.5563 20.1878 19.0543 19.6676 18.4179C19.5735 18.3029 19.5724 18.1699 19.6302 18.0633C19.6889 17.9551 19.8097 17.8728 19.9643 17.8728H20.8034C21.0932 17.8728 21.3281 17.6378 21.3281 17.348C21.3281 17.0582 21.0932 16.8232 20.8034 16.8232H18.5C18.1686 16.8232 17.9 17.0919 17.9 17.4232V19.7266C17.9 20.0164 18.1349 20.2514 18.4248 20.2514H18.5318C18.7625 20.2514 18.9495 20.0643 18.9495 19.8336C18.9495 19.7327 19.0155 19.6524 19.1133 19.6151C19.2119 19.5776 19.3253 19.5913 19.4031 19.664C21.0975 21.248 23.6281 21.7938 25.9075 20.8497C26.8921 20.4418 27.7546 19.7825 28.4017 18.9427C28.9412 18.2427 29.3235 17.4296 29.5202 16.5696C29.5866 16.2797 29.3827 16.0103 29.0983 15.9662C28.8101 15.9215 28.5494 16.1196 28.4841 16.3935C28.1087 17.9677 27.0194 19.2531 25.5058 19.88Z"
          fill="white"
          stroke="white"
          strokeWidth="0.2"
        />
      </svg>
      {hovered && (
        <div
          style={{
            fontSize: "10px",
            lineHeight: "12px",
            letterSpacing: "-0.01em",
            color: "#808297",
            position: "absolute",
            bottom: 0,
            left: 0,
            width: 48,
            textAlign: "center",
          }}
        >
          Swap
        </div>
      )}
    </SimpleButton>
  );
};

export default Footer;
