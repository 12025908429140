import React from "react";
import CloseIcon from "../../Icons/CloseIcons";
import SimpleButton from "../../SimpleButton";

interface Props {
  onClose: () => void;
}

const Header = (props: Props) => {
  const { onClose } = props;

  return (
    <div className="d-flex justify-content-between">
      <div
        className="bold"
        style={{
          fontSize: "20px",
          lineHeight: "24px",
          letterSpacing: "-0.022em",
          color: "#000000",
        }}
      >
        Edit set
      </div>
      <SimpleButton onClick={onClose}>
        <CloseIcon width={26} height={26} />
      </SimpleButton>
    </div>
  );
};

export default Header;
