import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import colors from "../../assets/colors";
import {
  discardComparisonImage,
  saveComparisonImage,
  sendProgressComparisonPhotoToClient,
} from "../../redux/actions/ComparisonTool";
import { StoreState } from "../../redux/reducers";
import { User } from "../../types/gqlTypes";
import SuccessIcon from "../Icons/SuccessIcon";
import ComparisonModal from "./ComparisonModal";
import Footer from "./Footer";
import PhotosPicker from "./PhotosPicker";

interface OwnProps {
  imgUrl1: string;
  date1: Date;
  imgUrl2: string;
  date2: Date;
  onDelete1: () => void;
  onDelete2: () => void;
  onSwap: () => void;
  deleteBoth: () => void;
  onClose: () => void;
}
interface Props extends OwnProps {
  user: User;
  library: { [key: string]: any };
  isUploading: boolean;
  discardComparisonImage: (userId: string) => void;
  saveComparisonImage: (userId: string, img: any) => void;
  sendProgressComparisonPhotoToClient: (args: {
    file: any;
    user: User;
    message?: string;
  }) => void;
}

const PhotoComparison = (props: Props) => {
  const {
    imgUrl1,
    date1,
    imgUrl2,
    date2,
    onDelete1,
    onDelete2,
    onSwap,
    deleteBoth,
    onClose,
    user,
    library,
    isUploading,
    discardComparisonImage,
    saveComparisonImage,
    sendProgressComparisonPhotoToClient,
  } = props;
  const [message, setMessage] = useState("");
  const [isCompModalOpen, setCompModalOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const savedImage = library?.[user?.id];
  const isThereImage = !!savedImage;

  const onSend = () => {
    if (savedImage && user?.id) {
      sendProgressComparisonPhotoToClient({
        file: savedImage,
        message,
        user,
      });
      deleteBoth();
      setMessage("");
    }
  };

  useEffect(() => {
    if (isUploading) {
      setSuccess(true);
      setTimeout(() => {
        onClose();
      }, 2000);
    }
  }, [isUploading]);

  if (isUploading || success) {
    return (
      <div
        className="d-flex flex-column"
        style={{
          width: 380,
          flexGrow: 0,
          backgroundColor: "#E7E9ED",
          borderRadius: 12,
          height: window.innerHeight - 320,
          overflowY: "scroll",
        }}
      >
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            marginTop: 142,
          }}
        >
          <SuccessIcon />
        </div>
        <div
          className="d-flex align-items-center justify-content-center bold"
          style={{
            marginTop: 58,
            fontSize: "19px",
            lineHeight: "28px",
            color: colors.caliber_secondary,
          }}
        >
          Succesfully sent!
        </div>
      </div>
    );
  }

  return (
    <div
      className="d-flex flex-column"
      style={{
        width: 380,
        flexGrow: 0,
        backgroundColor: "#E7E9ED",
        borderRadius: 12,
        height: window.innerHeight - 320,
        overflowY: "scroll",
      }}
    >
      {isCompModalOpen && (
        <ComparisonModal
          imgUrl1={imgUrl1}
          date1={date1}
          imgUrl2={imgUrl2}
          date2={date2}
          onCrop={(img) => {
            saveComparisonImage(user?.id, img);
            setCompModalOpen(false);
          }}
        />
      )}

      {!isThereImage && (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            fontSize: "16px",
            lineHeight: "20px",
            color: colors.caliber_secondary,
            marginTop: 48,
          }}
        >
          Select 2 images to assess
        </div>
      )}
      <div
        className="d-flex align-items-center justify-content-center"
        style={{
          fontSize: "16px",
          lineHeight: "20px",
          color: colors.caliber_secondary,
          marginBottom: 32,
          marginTop: isThereImage && 48,
        }}
      >
        {`${user?.firstName} ${user?.lastName}'s progress`}
      </div>

      {!isThereImage && (
        <div className="d-flex justify-content-center">
          <PhotosPicker
            imgUrl1={imgUrl1}
            date1={date1}
            imgUrl2={imgUrl2}
            date2={date2}
            onDelete1={onDelete1}
            onDelete2={onDelete2}
          />
        </div>
      )}

      {isThereImage && (
        <div className="d-flex justify-content-center">
          <img
            src={savedImage}
            style={{
              width: 280,
              height: 280,
            }}
          />
        </div>
      )}

      <Footer
        user={user}
        message={message}
        canBeSent={savedImage && user?.id}
        canBeEdited={!!imgUrl1 && !!imgUrl2 && !isThereImage}
        canBeDiscarded={isThereImage}
        onEdit={() => setCompModalOpen(!isCompModalOpen)}
        onDiscard={() => discardComparisonImage(user?.id)}
        onMessageChange={setMessage}
        onSend={onSend}
        onSwap={onSwap}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  discardComparisonImage: (userId: string) => {
    dispatch(discardComparisonImage(userId));
  },
  saveComparisonImage: (userId: string, img: any) => {
    dispatch(saveComparisonImage(userId, img));
  },
  sendProgressComparisonPhotoToClient: (args: {
    file: any;
    user: User;
    message?: string;
  }) => {
    dispatch(sendProgressComparisonPhotoToClient(args));
  },
});

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  user: state.clientDetailState.user,
  library: state.comparisonToolState.library,
  isUploading: state.comparisonToolState.isUploading,

  imgUrl1: ownProps.imgUrl1,
  date1: ownProps.date1,
  imgUrl2: ownProps.imgUrl2,
  date2: ownProps.date2,
  onDelete1: ownProps.onDelete1,
  onDelete2: ownProps.onDelete2,
  onSwap: ownProps.onSwap,
  deleteBoth: ownProps.deleteBoth,
});

export default connect(mapStateToProps, mapDispatchToProps)(PhotoComparison);
