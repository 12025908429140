import React, { useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";
import colors from "../../assets/colors";
import DeleteIcon from "../../assets/images/DeleteCalendarItemIcon.svg";
import FilterIcon from "../../assets/images/FilterIcon.svg";
import FilterIconSelected from "../../assets/images/FilterIconSelected.svg";
import { searchExercises } from "../../redux/actions/SearchExercises";
import { StoreState } from "../../redux/reducers";
import { FilterTypes } from "../../screens/AdminExerciseLibraryScreen";
import {
  ClientGymStep,
  Direction,
  Exercise,
  ExerciseSortCriteria,
  ExerciseType,
  MasterGymStep,
  QuerySearchExercisesArgs,
  Superset,
} from "../../types/gqlTypes";
import { useDebounce } from "../../utils/customHooks";
import ExerciseItem from "../ExerciseItem";
import ExerciseLibraryDetailsView from "../ExerciseLibraryDetailsView";
import Loader from "../Loader";
import SimpleButton from "../SimpleButton";
import AppliedFilters from "./AppliedFilters";

export enum FilterTypeEnum {
  EXERCISE_TYPE,
  MUSCLE,
}
interface OwnProps {
  excludeGymSteps: (MasterGymStep & ClientGymStep)[];
  showVideoOnHover?: boolean;
  onToggleDragging?: (value: boolean) => void;
}
interface Props extends OwnProps {
  searchExercises: (args: QuerySearchExercisesArgs) => void;
  isSearchLoading: boolean;
  exerciseList: Exercise[];
  isLastPage: boolean;
  currentPage: number;
  totalElements: number;
}

const ExerciseSearchLibrary = (props: Props) => {
  const {
    excludeGymSteps,
    searchExercises,
    onToggleDragging,
    isSearchLoading,
    exerciseList,
    isLastPage,
    currentPage,
    totalElements,
  } = props;

  const [searchString, setSearchString] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const debouncedSearchValue = useDebounce(searchString, 400);
  const [queryFilterArgs, setQueryFilterArgs] =
    useState<QuerySearchExercisesArgs>({
      page: 0,
      textCriteria: "",
      showWarmups: false,
    });

  const [filteredExercises, numberOfExcludedExercises] = useMemo(() => {
    const idObject = {};
    let numberOfExcludedExercises = 0;
    excludeGymSteps?.forEach((gymStep) => {
      idObject[gymStep.exercise?.id] = true;
      if (gymStep.exercise == null) {
        (gymStep as Superset).gymSteps.forEach((supersetChild) => {
          idObject[supersetChild.exercise?.id] = true;
        });
      }
    });
    const filteredList = exerciseList.filter((exercise) => {
      const includeExercise = !idObject[exercise.id];
      if (!includeExercise) {
        numberOfExcludedExercises += 1;
      }
      return includeExercise;
    });
    return [filteredList, numberOfExcludedExercises];
  }, [excludeGymSteps, exerciseList]);

  useEffect(() => {
    searchExercises({
      ...queryFilterArgs,
      page: 0,
      sortField: ExerciseSortCriteria.Name,
      sortDirection: Direction.Asc,
      textCriteria: debouncedSearchValue,
    } as QuerySearchExercisesArgs);
  }, [debouncedSearchValue, queryFilterArgs]);

  const loadMore = () => {
    searchExercises({
      ...queryFilterArgs,
      page: currentPage + 1,
      sortField: ExerciseSortCriteria.Name,
      sortDirection: Direction.Asc,
      textCriteria: searchString,
    } as QuerySearchExercisesArgs);
  };

  const onSelectFilterTag = (tag: any, type: FilterTypes) => {
    if (type === FilterTypes.Equipment) {
      if (queryFilterArgs.exerciseEquipmentCriteria === tag) {
        const newQueries = { ...queryFilterArgs };
        delete newQueries.exerciseEquipmentCriteria;
        setQueryFilterArgs(newQueries);
      } else {
        setQueryFilterArgs({
          ...queryFilterArgs,
          exerciseEquipmentCriteria: tag,
        });
      }
    } else if (type === FilterTypes.Level) {
      if (queryFilterArgs.exerciseLevelCriteria === tag) {
        const newQueries = { ...queryFilterArgs };
        delete newQueries.exerciseLevelCriteria;
        setQueryFilterArgs(newQueries);
      } else {
        setQueryFilterArgs({
          ...queryFilterArgs,
          exerciseLevelCriteria: tag,
        });
      }
    } else if (type === FilterTypes.MuscleGroup) {
      const isEmpty = !queryFilterArgs.muscleGroupCriteria;
      const isSelected =
        !isEmpty && queryFilterArgs.muscleGroupCriteria.includes(tag);
      if (isEmpty) {
        setQueryFilterArgs({
          ...queryFilterArgs,
          muscleGroupCriteria: [tag],
        });
      } else if (
        isSelected &&
        queryFilterArgs.muscleGroupCriteria.length === 1
      ) {
        const newQueries = { ...queryFilterArgs };
        delete newQueries.muscleGroupCriteria;
        setQueryFilterArgs(newQueries);
      } else if (isSelected) {
        setQueryFilterArgs({
          ...queryFilterArgs,
          muscleGroupCriteria: queryFilterArgs.muscleGroupCriteria.filter(
            (muscle) => muscle !== tag
          ),
        });
      } else {
        setQueryFilterArgs({
          ...queryFilterArgs,
          muscleGroupCriteria: [...queryFilterArgs.muscleGroupCriteria, tag],
        });
      }
    } else if (type === FilterTypes.Type) {
      if (queryFilterArgs.exerciseTypeCriteria === tag) {
        const newQueries = { ...queryFilterArgs, showWarmups: false };
        delete newQueries.exerciseTypeCriteria;
        setQueryFilterArgs(newQueries);
      } else {
        setQueryFilterArgs({
          ...queryFilterArgs,
          showWarmups: false,
          exerciseTypeCriteria: tag,
        });
      }
    } else if (type === FilterTypes.Warmups) {
      setQueryFilterArgs({
        ...queryFilterArgs,
        showWarmups: !queryFilterArgs.showWarmups,
        exerciseTypeCriteria: !queryFilterArgs.showWarmups
          ? ExerciseType.Warmup
          : undefined,
      });
    }
  };

  const showLoader = !showFilter && isSearchLoading;
  const showExerciseList = !showFilter && filteredExercises?.length > 0;
  const actualNumberOfExercises = Math.max(
    totalElements - numberOfExcludedExercises,
    0
  );

  return (
    <div
      className="d-flex flex-column"
      style={{
        flex: 1,
        position: "relative",
      }}
    >
      <div
        className="d-flex flex-column flex-grow-0"
        style={{
          position: "relative",
        }}
      >
        <input
          className="bordered-item heading-small"
          style={{
            border: `1px solid ${colors.caliber_gray_5}`,
            backgroundColor: colors.caliber_gray_5,
            borderRadius: 8,
            height: 48,
            marginTop: 8,
            marginBottom: 8,
            marginRight: 54,
            paddingLeft: 20,
          }}
          value={searchString}
          onChange={(event) => setSearchString(event.target.value)}
          placeholder="Search Exercises"
        />
        <SimpleButton
          isIcon
          onClick={() => setSearchString("")}
          nofocus
          iconStyle={{
            position: "absolute",
            top: 25,
            right: 10,
          }}
        >
          {DeleteIcon}
        </SimpleButton>
        <SimpleButton
          isIcon
          onClick={() => setShowFilter(!showFilter)}
          nofocus
          iconStyle={{
            position: "absolute",
            top: 8,
            right: 0,
          }}
        >
          {showFilter ? FilterIconSelected : FilterIcon}
        </SimpleButton>
      </div>

      {showLoader && (
        <div
          style={{
            zIndex: 2,
            position: "absolute",
            flex: 1,
            top: "90%",
            left: "45%",
          }}
        >
          <Loader />
        </div>
      )}

      {!showFilter && (
        <AppliedFilters
          resultNumber={actualNumberOfExercises}
          onSelectFilterTag={onSelectFilterTag}
          queryFilterArgs={queryFilterArgs}
        />
      )}
      {showFilter && (
        <div
          className="d-flex justify-content-center align-items-center medium-bold"
          style={{ fontSize: "14px", height: 20 }}
        >
          {actualNumberOfExercises === 0
            ? "No exercise was found"
            : `${actualNumberOfExercises} result${
                actualNumberOfExercises === 1 ? "" : "s"
              }`}
        </div>
      )}
      {showFilter && (
        <ExerciseLibraryDetailsView
          asFilterComponent
          onFilterTagClick={onSelectFilterTag}
          filterState={queryFilterArgs}
        />
      )}

      <div
        className="d-flex flex-column overflow-auto"
        style={{
          position: "relative",
          flex: "1 1 0",
        }}
      >
        {showExerciseList && (
          <InfiniteScroll
            className="flex-grow-0"
            pageStart={0}
            useWindow={false}
            loadMore={loadMore}
            hasMore={!isSearchLoading && !isLastPage}
            loader={null}
          >
            {filteredExercises.map((exercise, index) => {
              return (
                <ExerciseItem
                  // Activates drop zone when adding exercise
                  onToggleDragging={onToggleDragging}
                  showVideoOnHover
                  draggable
                  exercise={exercise}
                  showMenu={false}
                  key={exercise.id}
                  id={exercise.id}
                  index={index}
                  // no moveCard here because no reordering is happening within the Exercise Search Library
                />
              );
            })}
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state: StoreState) => ({
  isSearchLoading: state.searchExercisesState.isLoading,
  exerciseList: state.searchExercisesState.exerciseList,
  totalElements: state.searchExercisesState.totalElements,
  isLastPage: state.searchExercisesState.isLastPage,
  currentPage: state.searchExercisesState.currentPage,
});
const mapDispatchToProps = (dispatch) => ({
  searchExercises: (args: QuerySearchExercisesArgs) => {
    dispatch(searchExercises(args));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExerciseSearchLibrary);
