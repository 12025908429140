import React, { useState, useEffect } from "react";
import { format, differenceInMonths } from "date-fns";
import LeftAngleIcon from "../../assets/images/LeftAngleIcon.svg";
import RightAngleIcon from "../../assets/images/RightAngleIcon.svg";

interface Props {
  goBack: () => void;
  goForth: () => void;
  goToday: () => void;
  isLoading: boolean;
  currentDate: Date;
  maxMonthsToGo: number;
}

const ControlPanel = (props: Props) => {
  const { goBack, goForth, goToday, currentDate, maxMonthsToGo, isLoading } =
    props;

  const countDiff = () => {
    const difference = differenceInMonths(currentDate, new Date());
    const disableBack = difference <= 0 - maxMonthsToGo || isLoading; // when go back diff < 0
    const disableForth = difference >= maxMonthsToGo || isLoading;
    return { disableBack, disableForth };
  };

  const [{ disableBack, disableForth }, setDiffs] = useState(countDiff());

  useEffect(() => {
    setDiffs(countDiff());
  }, [currentDate, maxMonthsToGo, isLoading]);

  return (
    <div
      className="d-flex align-items-center"
      style={{
        margin: 0,
        marginBottom: 30,
        padding: "10px 0px",
        boxSizing: "border-box",
      }}
    >
      <div
        role="button"
        tabIndex={0}
        onClick={goToday}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            goToday();
          }
        }}
        className="todayButton pointer heading-small"
      >
        Today
      </div>
      <div
        role="button"
        tabIndex={0}
        onClick={disableBack ? () => {} : goBack}
        onKeyDown={(event) => {
          if (event.key === "Enter" && !disableBack) {
            goBack();
          }
        }}
        className={`d-flex flex-column controlButtons ${
          !disableBack ? "pointer enabled" : undefined
        }`}
        style={{ marginLeft: 30, opacity: disableBack ? 0.5 : 1 }}
      >
        <img style={{ margin: "auto" }} src={LeftAngleIcon} alt="icon" />
      </div>

      <div
        role="button"
        tabIndex={0}
        onClick={disableForth ? () => {} : goForth}
        onKeyDown={(event) => {
          if (event.key === "Enter" && !disableForth) {
            goForth();
          }
        }}
        className={`d-flex flex-column controlButtons ${
          !disableForth ? "pointer enabled" : undefined
        }`}
        style={{ marginLeft: 30, opacity: disableForth ? 0.5 : 1 }}
      >
        <img style={{ margin: "auto" }} src={RightAngleIcon} alt="icon" />
      </div>
      <div
        className="heading-normal"
        style={{
          marginLeft: 30,
        }}
      >
        {format(currentDate, "MMMM yyyy")}
      </div>
    </div>
  );
};
export default ControlPanel;
