import React from "react";
import Dropdown from "../../Dropdown";
import { RecurrenceFrequencyType } from "./Frequency";

interface Props {
  frequencyType: RecurrenceFrequencyType;
  weekDuration: number;
  monthDuration: number;
  onSelectMonthDuration: (value: number) => void;
  onSelectWeekDuration: (value: number) => void;
}

const RepeatingFor = (props: Props) => {
  const {
    frequencyType,
    weekDuration,
    monthDuration,
    onSelectMonthDuration,
    onSelectWeekDuration,
  } = props;

  let onSelect;
  let value;
  let optionsNumber;
  let title;
  if (frequencyType === RecurrenceFrequencyType.WEEKLY) {
    onSelect = onSelectWeekDuration;
    value = weekDuration;
    optionsNumber = 52;
    title = "weeks";
  }
  if (frequencyType === RecurrenceFrequencyType.MONTHLY) {
    onSelect = onSelectMonthDuration;
    value = monthDuration;
    optionsNumber = 12;
    title = "months";
  }

  const textStyle: React.CSSProperties = {
    fontSize: "16px",
    lineHeight: "16px",
    color: "#000000",
    height: 36,
    margin: "0px 12px",
  };
  return (
    <div
      className="d-flex align-items-center"
      style={{
        margin: "12px 12px",
        height: 36,
      }}
    >
      <div className="d-flex align-items-center medium-bold" style={textStyle}>
        For the next
      </div>
      <div
        style={{
          width: 58,
        }}
      >
        <Dropdown
          height={36}
          customPadding="0px 24px 0px 10px"
          items={Array.from({ length: optionsNumber }, (_, i) => ({
            value: i + 1,
            text: `${i + 1}`,
          }))}
          value={value}
          onSelect={onSelect}
        />
      </div>
      <div className="d-flex align-items-center medium-bold" style={textStyle}>
        {title}
      </div>
    </div>
  );
};

export default RepeatingFor;
