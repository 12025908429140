import React, { useEffect } from "react";
import { connect } from "react-redux";
import { StoreState } from "../../redux/reducers";
import colors from "../../assets/colors";
import { CoachProfile, User, UserType } from "../../types/gqlTypes";
import ImagesSection from "./ImagesSection";
import ListSection from "./ListSection";
import SectionHeader from "./SectionHeader";
import TextSection from "./TextSection";
import { getCoachProfile } from "../../redux/actions/CoachProfile";
import { MasqueradeState } from "../../redux/actions/Masquerade/types";

interface Props {
  coachProfile: CoachProfile;
  user: User;
  masqueradeState: MasqueradeState;
  getCoachProfile: (trainerId: string) => void;
}

const TrainerProfile = (props: Props) => {
  const { coachProfile, user, masqueradeState, getCoachProfile } = props;

  useEffect(() => {
    const isTrainer = user.type === UserType.Trainer;
    const isMasq = !!masqueradeState?.masqueradeTrainer?.id;
    if (isTrainer) {
      getCoachProfile(user?.id);
    }
    if (isMasq) {
      getCoachProfile(masqueradeState?.masqueradeTrainer?.id);
    }
  }, [user, masqueradeState]);

  return (
    <div
      className="d-flex flex-column"
      style={{
        flex: 1,
        padding: "48px 32px",
      }}
    >
      <div
        className="bold"
        style={{
          fontSize: "24px",
          lineHeight: "24px",
          letterSpacing: "-0.022em",
          color: colors.caliber_secondary,
        }}
      >
        Profile
      </div>
      <div
        style={{
          fontSize: "14px",
          lineHeight: "16px",
          letterSpacing: "-0.022em",
          color: colors.caliber_gray_text,
          width: 427,
          marginTop: 16,
        }}
      >
        This profile is view only. To request edits, please reach out to your
        trainer manager.
      </div>

      <div
        style={{
          height: 1,
          backgroundColor: colors.caliber_gray_border,
          margin: "48px 0px",
        }}
      >
        {/* line */}
      </div>

      <div className="d-flex">
        <SectionHeader title="Bio" />
        <TextSection title="Bio" value={coachProfile?.bio || ""} />
      </div>

      <div className="d-flex">
        <SectionHeader title="Certifications" />
        <ListSection
          title="Certifications"
          list={coachProfile?.certifications?.split("~~~")}
        />
      </div>

      <div className="d-flex">
        <SectionHeader title="Education" />
        <ListSection
          title="Education"
          list={coachProfile?.education?.split("~~~")}
        />
      </div>

      <div className="d-flex">
        <SectionHeader title="Images" />
        <ImagesSection photos={coachProfile?.photos} />
      </div>

      <div className="d-flex">
        <SectionHeader title="Fun facts" />
        <TextSection title="Fun facts" value={coachProfile?.funFacts || ""} />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getCoachProfile: (trainerId: string) => {
    dispatch(getCoachProfile(trainerId));
  },
});

const mapStateToProps = (state: StoreState) => ({
  coachProfile: state.coachProfileState.coachProfile,
  user: state.authenticatedUserState.user,
  masqueradeState: state.masqueradeState,
});

export default connect(mapStateToProps, mapDispatchToProps)(TrainerProfile);
