import { gql } from "apollo-boost";

export const mediaUrlFragment = gql`
  fragment MediaUrl on MediaUrl {
    hd
    id
    mimeType
    muxVideoUpload {
      assetId
      id
      muxJson
      muxUploadId
      playbackId
      status
      uploadUrl
      videoProcessingStatus
    }
    type
    url
  }
`;

export const settingsFragment = gql`
  fragment SettingsData on Settings {
    id
    clientsTabColumnPreferences
    enableGeneralNotifications
    enableMessageNotifications
    hasNewNotification
    unitBodystat
    unitDistance
    unitWeight
    ... on ClientSettings {
      enableAppleHealth
      enableGoogleFit
      enableCronometer
    }
    ... on TrainerSettings {
      id
      firstAutoMessage
      secondAutoMessage
      thirdAutoMessage
    }
  }
`;
// TODO: add settings { ...SettingsData } - now throws an error
export const userFragment = gql`
  ${mediaUrlFragment}
  ${settingsFragment}
  fragment UserData on User {
    id
    firstName
    lastName
    birthDate
    age
    height
    phone
    country
    city
    state
    clientAuthId
    profileIconMediaUrl {
      ...MediaUrl
    }
    settings {
      ...SettingsData
    }
    timezone {
      description
      id
    }
    email
    gender
    status
    type
    userEnabled
    isProfileCompleted
    isConsultationCompleted
    sendbirdAccessToken
    joinedDate
    ... on Trainer {
      enabledFeatures
      proGroups {
        id
        name
      }
    }
    ... on Client {
      lastActiveDate
      deviceType
      notes
      nextPaymentDate
      trialEndDate
      subscriptionCancelledDate
      subscriptionEndDate
      memberAvatarType
      contentWeekNumber
      assignedTrainer {
        id
        firstName
        lastName
        sendbirdAccessToken
        profileIconMediaUrl {
          ...MediaUrl
        }
      }
      clientSalesPackage {
        callsLeft
        clientPackageType
        packageEndDate
      }
      proGroups {
        id
        name
      }
    }
  }
`;

export const FETCH_USER_GQL = gql`
  ${userFragment}
  query BasicUserData {
    user {
      ...UserData
    }
  }
`;

export const DELETE_USER_FROM_PLATFORM = gql`
  mutation DeleteUserFromPlatform($userId: UUID) {
    deleteUserFromPlatform(userId: $userId)
  }
`;

export const SEARCH_TIMEZONES = gql`
  query SearchTimezones($page: Int!, $textCriteria: String) {
    searchTimeZones(page: $page, textCriteria: $textCriteria) {
      content {
        description
        id
      }
      last
      totalPages
      number
    }
  }
`;

export const UPDATE_USER_TIMEZONE = gql`
  mutation UpdateUserTimeZone($userId: UUID!, $timeZoneTypeId: UUID) {
    updateUserTimeZone(userId: $userId, timeZoneTypeId: $timeZoneTypeId) {
      timezone {
        description
        id
      }
    }
  }
`;
export const UPDATE_TRAINER_SETTINGS = gql`
  mutation UpdateTrainerSettings(
    $trainerId: UUID
    $trainerSettings: TrainerSettingsInput
  ) {
    updateTrainerSettings(
      trainerId: $trainerId
      trainerSettings: $trainerSettings
    ) {
      id
      firstAutoMessage
      secondAutoMessage
      thirdAutoMessage
      clientsTabColumnPreferences
      enableGeneralNotifications
      enableMessageNotifications
      hasNewNotification
      unitBodystat
      unitDistance
      unitWeight
    }
  }
`;
export const UPDATE_ADMIN_SETTINGS = gql`
  mutation UpdateAdminSettings($adminSettings: AdminSettingsInput) {
    updateAdminSettings(adminSettings: $adminSettings) {
      id
      clientsTabColumnPreferences
      enableGeneralNotifications
      enableMessageNotifications
      hasNewNotification
      unitBodystat
      unitDistance
      unitWeight
    }
  }
`;
export const UPDATE_MANAGER_SETTINGS = gql`
  mutation UpdateManagerSettings($managerSettings: ManagerSettingsInput) {
    updateManagerSettings(managerSettings: $managerSettings) {
      id
      clientsTabColumnPreferences
      enableGeneralNotifications
      enableMessageNotifications
      hasNewNotification
      unitBodystat
      unitDistance
      unitWeight
    }
  }
`;
