export const REASSIGN_CLIENT_TRAINER_SUCCESS =
  "REASSIGN_CLIENT_TRAINER_SUCCESS";
export const REASSIGN_CLIENT_TRAINER_FAIL = "REASSIGN_CLIENT_TRAINER_FAIL";
export const REASSIGN_CLIENT_TRAINER_LOADING =
  "REASSIGN_CLIENT_TRAINER_LOADING";

export interface ReassignClientTrainerState {
  isLoading: boolean;
}

export interface ReassignClientTrainerAction {
  type: string;
}
