import { CaliberPageImpl_TimeZoneType } from "../../../types/gqlTypes";
import apolloClient from "../../../api/apolloClient";
import {
  SEARCH_TIMEZONES_FAIL,
  SEARCH_TIMEZONES_LOADING,
  SEARCH_TIMEZONES_SUCCESS,
  SearchTimezonesAction,
} from "./types";
import { SEARCH_TIMEZONES } from "../../../api/gql/users";

export const searchTimezones =
  (page: number, textCriteria: string) => async (dispatch, getState) => {
    if (getState().searchTimezonesState.isLoading) {
      return;
    }
    dispatch({
      type: SEARCH_TIMEZONES_LOADING,
    } as SearchTimezonesAction);
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const result = (
        await client.query({
          query: SEARCH_TIMEZONES,
          variables: {
            page,
            textCriteria,
          },
        })
      ).data.searchTimeZones as CaliberPageImpl_TimeZoneType;

      dispatch({
        type: SEARCH_TIMEZONES_SUCCESS,
        data: result,
      });
    } catch (error) {
      console.error("Search Timezones failed", error);
      dispatch({
        type: SEARCH_TIMEZONES_FAIL,
        data: error,
      });
    }
  };
