import React, { useState } from "react";
import { connect } from "react-redux";
import colors from "../../../assets/colors";
import { searchTrainers } from "../../../redux/actions/AdminTeamTrainers";
import { StoreState } from "../../../redux/reducers";
import { User, UserStatusType } from "../../../types/gqlTypes";
import { toTitleCase } from "../../../utils";
import SimpleButton from "../../SimpleButton";
import DropdownButton from "./DropdownButton";

export interface DropdownItem {
  text: string;
  value: string;
}

interface OwnProps {
  currentValue: string;
  onSelect: (value: User | string) => void;
}
interface Props extends OwnProps {
  value: UserStatusType[];
  page: number;
  totalPages: number;
  searchTrainers: (page: number, searchCriteria: string) => void;
  onChange: (statusCriteria: UserStatusType[]) => void;
}

const TeamMemberStatusDropdown = (props: Props) => {
  const { value, onChange } = props;

  const [open, setOpen] = useState(false);

  const onBlur = () => {
    setTimeout(() => {
      if (document.activeElement.id !== "dropdown_element") {
        setOpen(false);
      }
    }, 0);
  };

  const items = [
    { value: UserStatusType.Active, text: "Active" },
    { value: UserStatusType.Inactive, text: "Inactive" },
  ];

  const onCheck = (newValue) => {
    const isExist = value?.includes(newValue as UserStatusType);
    let newStatus;
    if (isExist) {
      newStatus = value?.filter((status) => status !== newValue);
    } else {
      newStatus = [...value, newValue];
    }
    onChange(newStatus);
  };

  const isChecked = (dropdownItem) => {
    return value?.includes(dropdownItem as UserStatusType);
  };

  return (
    <div
      style={{
        position: "relative",
        flex: 1,
        zIndex: 11,
        marginTop: 8,
        marginLeft: 8,
        height: 48,
      }}
      className="d-flex flex-column"
    >
      <DropdownButton
        text={toTitleCase(value.toString().replace(",", ", "))}
        isOpen={open}
        onBlur={() => {}}
        onClick={() => setOpen(!open)}
      />

      {open && (
        <div
          id="dropdown_element"
          onBlur={onBlur}
          className="d-flex flex-column medium-bold"
          style={{
            padding: 5,
            zIndex: 2,
            backgroundColor: colors.caliber_white,
            borderRadius: 8,
            position: "absolute",
            top: 50,
            marginBottom: 10,
            boxShadow: "0px 2px 16px #E7E7EB",
          }}
        >
          {items?.map((item, index) => {
            return (
              <SimpleButton
                nofocus
                nopointer
                key={`${item.text}-${item.value}`}
                id="filter_element"
                onBlur={onBlur}
                onClick={() => {}}
                className="d-flex align-items-center justify-content-between medium-bold"
                style={{
                  width: 186,
                  fontSize: 12,
                  height: 40,
                  padding: 12,
                  margin: "0px 8px",
                  marginTop: index === 0 && 8,
                  marginBottom: index === items.length - 1 && 8,
                  backgroundColor: colors.caliber_white,
                  color: colors.caliber_secondary,
                  borderRadius: 4,
                  zIndex: 9999,
                }}
                styleOnHover={{
                  backgroundColor: colors.caliber_gray_2,
                }}
              >
                {item.text}
                <SimpleButton
                  nofocus
                  id="filter_element"
                  onClick={() => {
                    onCheck(item.value);
                  }}
                >
                  <Checkbox checked={isChecked(item.value)} />
                </SimpleButton>
              </SimpleButton>
            );
          })}
        </div>
      )}
    </div>
  );
};

const Checkbox = ({ checked }: { checked?: boolean }) => {
  return checked ? (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="15"
        height="15"
        rx="3.5"
        fill="white"
        stroke="#01052F"
      />
      <path
        d="M5.56737 11.9588L5.56735 11.9588L2.51411 8.90552L2.51408 8.90549C2.02864 8.42028 2.02864 7.63252 2.51408 7.1473L2.51414 7.14725C2.99933 6.66182 3.78712 6.66179 4.27261 7.14728L4.27262 7.14729L6.09175 8.96615L6.09184 8.96625C6.28778 9.16181 6.60545 9.16166 6.80152 8.96635L6.80172 8.96615L11.7274 4.04048L11.7274 4.04045C12.2126 3.55502 13.0004 3.55498 13.4859 4.04048C13.719 4.2736 13.85 4.58992 13.85 4.9196C13.85 5.24927 13.719 5.56559 13.4859 5.79872L7.32585 11.9588C6.84036 12.4442 6.05257 12.4442 5.56737 11.9588Z"
        fill="#01052F"
        stroke="white"
        strokeWidth="0.3"
      />
    </svg>
  ) : (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="15"
        height="15"
        rx="3.5"
        fill="white"
        stroke="#01052F"
      />
    </svg>
  );
};

const mapDispatchToProps = (dispatch) => ({
  searchTrainers: (page: number, searchCriteria: string) => {
    dispatch(
      searchTrainers(
        page,
        searchCriteria,
        UserStatusType[UserStatusType.Active]
      )
    );
  },
});

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  trainers: state.adminSearchTrainersState.foundTrainers,
  page: state.adminSearchTrainersState.page,
  totalPages: state.adminSearchTrainersState.totalPages,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamMemberStatusDropdown);
