import {
  Client,
  ClientSearchResultSortCriteria,
  SortOrder,
} from "../../../types/gqlTypes";
import {
  CREATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_SUCCESS,
} from "../../actions/AdminClients/types";
import {
  CHANGE_COLUMNS_IN_CLIENTS_TAB,
  CHANGE_COLUMNS_ON_REORDER_IN_CLIENTS_TAB,
  CHANGE_COLUMN_WIDTH_IN_CLIENTS_TAB,
  CHANGE_PAGE_IN_CLIENTS_TAB,
  CHANGE_PAGE_SIZE_IN_CLIENTS_TAB,
  CHANGE_SORTING_IN_CLIENTS_TAB,
  ClientsTabAction,
  ClientsTabState,
  CLOSE_COLUMN_FILTER_IN_CLIENTS_TAB,
  CLOSE_NEW_CLIENT_IN_CLIENTS_TAB,
  CLOSE_SETTINGS_IN_CLIENTS_TAB,
  FETCH_CLIENT_PROFILE_FOR_CLIENTS_TAB_FAIL,
  FETCH_CLIENT_PROFILE_FOR_CLIENTS_TAB_LOADING,
  FETCH_CLIENT_PROFILE_FOR_CLIENTS_TAB_SUCCESS,
  RESET_FETCHED_CLIENT_PROFILE_FOR_CLIENTS_TAB,
  GET_TABLE_SETTINGS_FOR_CLIENTS_TAB,
  ON_HOVER_ENTER_RESIZING_IN_CLIENTS_TAB,
  ON_HOVER_LEAVE_RESIZING_IN_CLIENTS_TAB,
  OPEN_COLUMN_FILTER_IN_CLIENTS_TAB,
  OPEN_EDIT_CLIENT_IN_CLIENTS_TAB,
  OPEN_NEW_CLIENT_IN_CLIENTS_TAB,
  OPEN_SETTINGS_IN_CLIENTS_TAB,
  RESET_COLUMNS_VIEW_IN_CLIENTS_TAB,
  RESET_SORTING_IN_CLIENTS_TAB,
  SEARCH_CLIENTS_TAB_FAIL,
  SEARCH_CLIENTS_TAB_LOADING,
  SEARCH_CLIENTS_TAB_SUCCESS,
  SELECT_CLIENT_IN_CLIENTS_TAB,
  START_DRAGGING_COLUMN_IN_CLIENTS_TAB,
  STOP_DRAGGING_COLUMN_IN_CLIENTS_TAB,
  TURN_ON_EDIT_CLIENT_MODE_IN_CLIENTS_TAB,
  UPDATE_CLIENT_PROFILE_CLIENTS_TAB_SUCCESS,
  SET_CLIENTS_TAB_TRAINER_FILTER,
} from "../../actions/ClientsTab/types";

const initialState: ClientsTabState = {
  isLoading: false,
  pageNumber: 0,
  totalPages: 0,
  pageSize: 50,
  isSettingsOpen: false,
  isFilterOpen: false,
  searchCriteria: "",
  trainerFilter: "all",
  sortOrder: SortOrder.Asc,
  sortField: ClientSearchResultSortCriteria.LastName,
  columns: [],
  widths: [],
  isDragging: false,
  content: null,
  whichColumnFilterOpen: null,
  whichColumnDragging: null,
  whichRowSelected: null,
  isNewClientDetailsOpen: false,
};

const clientsTabState = (state = initialState, action: ClientsTabAction) => {
  switch (action.type) {
    case GET_TABLE_SETTINGS_FOR_CLIENTS_TAB: {
      return {
        ...state,
        ...action.settings,
      };
    }
    case SEARCH_CLIENTS_TAB_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case SEARCH_CLIENTS_TAB_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: action.result.content,
        pageNumber: action.result.number,
        totalPages: action.result.totalPages,
        totalElements: action.result.totalElements,
        ...(action.args && { ...action.args }),
      };
    case SEARCH_CLIENTS_TAB_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case START_DRAGGING_COLUMN_IN_CLIENTS_TAB: {
      return {
        ...state,
        whichColumnDragging: action.column,
      };
    }
    case STOP_DRAGGING_COLUMN_IN_CLIENTS_TAB: {
      return {
        ...state,
        whichColumnDragging: null,
      };
    }
    case CHANGE_COLUMN_WIDTH_IN_CLIENTS_TAB: {
      return {
        ...state,
        widths: action.widths,
      };
    }
    case OPEN_COLUMN_FILTER_IN_CLIENTS_TAB: {
      return {
        ...state,
        whichColumnFilterOpen: action.column,
        isFilterOpen: true,
      };
    }
    case CLOSE_COLUMN_FILTER_IN_CLIENTS_TAB: {
      return {
        ...state,
        whichColumnFilterOpen: null,
        isFilterOpen: false,
      };
    }
    case OPEN_SETTINGS_IN_CLIENTS_TAB: {
      return {
        ...state,
        isSettingsOpen: true,
        isNewClientDetailsOpen: false,
        isEditClientDetailsOpen: false,
      };
    }
    case CLOSE_SETTINGS_IN_CLIENTS_TAB: {
      return {
        ...state,
        isSettingsOpen: false,
      };
    }
    case CHANGE_COLUMNS_IN_CLIENTS_TAB: {
      let newWidths = [...state.widths];
      if (newWidths.length > action.columns.length) {
        newWidths = newWidths.slice(0, action.columns.length);
      } else if (newWidths.length < action.columns.length) {
        new Array(action.columns.length - newWidths.length)
          .fill(1)
          .forEach((_) => {
            newWidths.push(200);
          });
      }
      return {
        ...state,
        columns: action.columns,
        widths: newWidths,
        isSettingsOpen: false,
      } as ClientsTabState;
    }
    case CHANGE_COLUMNS_ON_REORDER_IN_CLIENTS_TAB: {
      return {
        ...state,
        columns: action.columns,
      };
    }
    case CHANGE_PAGE_IN_CLIENTS_TAB: {
      return {
        ...state,
        pageNumber: action.pageNumber,
      };
    }
    case CHANGE_PAGE_SIZE_IN_CLIENTS_TAB: {
      return {
        ...state,
        pageNumber: 0,
        pageSize: action.pageSize,
      };
    }
    case SELECT_CLIENT_IN_CLIENTS_TAB: {
      return {
        ...state,
        whichRowSelected: action.index,
      };
    }
    case CHANGE_SORTING_IN_CLIENTS_TAB: {
      return {
        ...state,
        isSortingApplied: true,
      };
    }
    case RESET_SORTING_IN_CLIENTS_TAB: {
      return {
        ...state,
        isSortingApplied: false,
      };
    }
    case RESET_COLUMNS_VIEW_IN_CLIENTS_TAB: {
      return {
        ...state,
        columns: action.columns,
        widths: action.widths,
        isSettingsOpen: false,
        sortOrder: SortOrder.Asc,
        sortField: ClientSearchResultSortCriteria.LastName,
        statusCriteria: action.statusCriteria,
        clientPackageTypeCriteria: action.clientPackageTypeCriteria,
      };
    }
    case ON_HOVER_ENTER_RESIZING_IN_CLIENTS_TAB:
      return {
        ...state,
        isResizingHovered: true,
      };
    case ON_HOVER_LEAVE_RESIZING_IN_CLIENTS_TAB:
      return {
        ...state,
        isResizingHovered: false,
      };
    case OPEN_NEW_CLIENT_IN_CLIENTS_TAB:
      return {
        ...state,
        isNewClientDetailsOpen: true,
        isEditClientDetailsOpen: false,
        isSettingsOpen: false,
        isEditClientOn: true,
        clientIdToEdit: null,
        fetchedClient: null,
        fetchedClientPackageType: null,
        fetchedClientWeight: null,
      };
    case CLOSE_NEW_CLIENT_IN_CLIENTS_TAB:
      return {
        ...state,
        isNewClientDetailsOpen: false,
        isEditClientDetailsOpen: false,
        clientIdToEdit: null,
        isEditClientOn: false,
        isSettingsOpen: false,
        fetchedClient: null,
        fetchedClientPackageType: null,
        fetchedClientWeight: null,
      };
    case OPEN_EDIT_CLIENT_IN_CLIENTS_TAB:
      return {
        ...state,
        isNewClientDetailsOpen: false,
        isEditClientDetailsOpen: true,
        clientIdToEdit: action.clientId,
        isSettingsOpen: false,
        isEditClientOn: true,
      };
    case FETCH_CLIENT_PROFILE_FOR_CLIENTS_TAB_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_CLIENT_PROFILE_FOR_CLIENTS_TAB_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        fetchedClient: action.user as Client,
        fetchedClientPackageType: action.clientPackageType,
        fetchedClientWeight: action.weight,
      };
    }
    case FETCH_CLIENT_PROFILE_FOR_CLIENTS_TAB_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case RESET_FETCHED_CLIENT_PROFILE_FOR_CLIENTS_TAB:
      return {
        ...state,
        isEditClientDetailsOpen: false,
        fetchedClient: null,
        clientIdToEdit: null,
        fetchedClientPackageType: null,
      };
    case UPDATE_CLIENT_PROFILE_CLIENTS_TAB_SUCCESS:
      return {
        ...state,
        isEditClientOn: true,
      };
    case CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        isEditClientOn: true,
        isEditClientDetailsOpen: true,
        isNewClientDetailsOpen: false,
        clientIdToEdit: action.user.id,
      };
    case UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        isEditClientOn: true,
      };
    case TURN_ON_EDIT_CLIENT_MODE_IN_CLIENTS_TAB:
      return {
        ...state,
        isEditClientOn: true,
      };
    case SET_CLIENTS_TAB_TRAINER_FILTER:
      return {
        ...state,
        trainerFilter: action.trainerFilter,
      };
    default:
      return state;
  }
};

export default clientsTabState;
