import React from "react";
import colors from "../../../assets/colors";
import { ClientWorkoutTemplate } from "../../../types/gqlTypes";
import WorkoutTemplateCard from "./WorkoutTemplateCard";

interface Props {
  workoutTemplates: ClientWorkoutTemplate[];
  onSelect: (workout: ClientWorkoutTemplate) => void;
}

const WorkoutTemplateList = (props: Props) => {
  const { workoutTemplates, onSelect } = props;

  return (
    <div className="d-flex flex-column">
      <div
        className="medium-bold"
        style={{
          color: colors.caliber_gray_text,
          fontSize: "14px",
          lineHeight: "24px",
          margin: "16px 0px",
        }}
      >
        Select workout
      </div>
      <div
        className="d-flex flex-column"
        style={{
          height: 440,
          width: 408,
          overflowY: "auto",
        }}
      >
        {workoutTemplates.map((workoutTemplate) => {
          return (
            <WorkoutTemplateCard
              key={workoutTemplate.id}
              workoutTemplate={workoutTemplate}
              onClick={() => onSelect(workoutTemplate)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default WorkoutTemplateList;
