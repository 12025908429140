import {
  CalendarDataAction,
  CalendarDataState,
  CALENDAR_DATA_LOADING,
  CALENDAR_FETCH_DATA_FAILED,
  CALENDAR_FETCH_DATA_SUCCESS,
  CALENDAR_FETCH_ITEM_FAILED,
  CALENDAR_FETCH_ITEM_LOADING,
  CALENDAR_FETCH_ITEM_SUCCESS,
  CALENDAR_FETCH_NUTRITION_DATA_SUCCESS,
  CLOSE_CALENDAR_FETCH_ITEM_FAILED_DIALOG,
  CREATE_CALENDAR_ITEM,
  CREATE_CALENDAR_ITEM_SAVING,
  DELETE_CALENDAR_ITEM_FAILED,
  DELETE_CALENDAR_ITEM_SAVING,
  DELETE_CALENDAR_ITEM_SUCCESS,
  MOVE_CALENDAR_ITEM_SAVING,
  MOVE_CALENDAR_ITEM_SUCCESS,
} from "../../actions/Calendar/types";
import { RESET_CLIENT_STATE } from "../../actions/ClientPlans/types";

const initialState: CalendarDataState = {
  isLoading: false,
  isLoadingItem: false,
  isSavingEvent: false,
  isDeletingEvent: false,
  isMovingCalendarItem: false,
  calendarItemsByDay: null,
  calendarFetchItemFailed: false,
  args: {},
};

const calendarDataState = (
  state = initialState,
  action: CalendarDataAction
) => {
  switch (action.type) {
    case DELETE_CALENDAR_ITEM_SAVING:
      return {
        ...state,
        isDeletingEvent: true,
      };
    case MOVE_CALENDAR_ITEM_SAVING:
      return {
        ...state,
        isMovingCalendarItem: true,
      };
    case DELETE_CALENDAR_ITEM_FAILED:
      return {
        ...state,
        isDeletingEvent: false,
      };
    case CALENDAR_DATA_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case CALENDAR_FETCH_ITEM_LOADING:
      return {
        ...state,
        isLoadingItem: true,
      };
    case CREATE_CALENDAR_ITEM_SAVING:
      return {
        ...state,
        isSavingEvent: true,
      };
    case DELETE_CALENDAR_ITEM_SUCCESS: {
      const { calendarItemId, date } = action;
      const copyCalendar = [...state.calendarItemsByDay];
      const index = copyCalendar.findIndex((day) => day.date === date);
      copyCalendar[index] = {
        ...copyCalendar[index],
        items: [
          ...copyCalendar[index].items.filter(
            (item) => item?.id !== calendarItemId
          ),
        ],
      };
      return {
        ...state,
        calendarItemsByDay: copyCalendar,
        isDeletingEvent: false,
      };
    }
    case CREATE_CALENDAR_ITEM: {
      const { calendarItem, date } = action;
      const copyCalendar = [...state.calendarItemsByDay];
      const index = copyCalendar.findIndex((day) => day.date === date);
      if (index === -1) {
        copyCalendar.push({
          id: `${Math.floor(Math.random() * 1000000000)}`,
          items: [calendarItem],
          date,
        });
      } else {
        copyCalendar[index] = {
          ...copyCalendar[index],
          items: [...copyCalendar[index].items, calendarItem],
        };
      }
      return {
        ...state,
        calendarItemsByDay: copyCalendar,
        isSavingEvent: false,
      };
    }
    case CALENDAR_FETCH_NUTRITION_DATA_SUCCESS:
    case CALENDAR_FETCH_DATA_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSavingEvent: false,
        isDeletingEvent: false,
        isMovingCalendarItem: false,
        args: action.args,
        calendarItemsByDay: action.data,
      };
    }
    case CALENDAR_FETCH_ITEM_SUCCESS: {
      const { calendarItem, calendarDayId } = action;
      const copyCalendar = [...state.calendarItemsByDay];
      const dayIndex = copyCalendar.findIndex(
        (day) => day?.id === calendarDayId
      );
      const itemIndex = copyCalendar?.[dayIndex]?.items?.findIndex(
        (item) => item?.id === calendarItem?.id
      );
      copyCalendar[dayIndex] = {
        ...copyCalendar[dayIndex],
        items: [
          ...copyCalendar[dayIndex].items.slice(0, itemIndex),
          calendarItem,
          ...copyCalendar[dayIndex].items.slice(itemIndex + 1),
        ],
      };
      return {
        ...state,
        isLoadingItem: false,
        calendarItemsByDay: copyCalendar,
      };
    }
    case MOVE_CALENDAR_ITEM_SUCCESS: {
      const { calendarDay, calendarItemId, oldCalendarDayId } = action;
      const copyCalendar = [...state.calendarItemsByDay];
      let dayIndex = copyCalendar.findIndex(
        (day) => day?.date === calendarDay.date
      );
      const oldDayIndex = copyCalendar.findIndex(
        (day) => day?.id === oldCalendarDayId
      );
      if (dayIndex === -1) {
        copyCalendar.push(calendarDay);
        dayIndex = copyCalendar.length - 1;
      }
      copyCalendar[dayIndex] = {
        ...copyCalendar[dayIndex],
        items: calendarDay.items,
      };
      copyCalendar[oldDayIndex] = {
        ...copyCalendar[oldDayIndex],
        items: copyCalendar[oldDayIndex].items.filter(
          (item) => item.id !== calendarItemId
        ),
      };
      return {
        ...state,
        isMovingCalendarItem: false,
        calendarItemsByDay: copyCalendar,
      };
    }
    case CALENDAR_FETCH_DATA_FAILED:
      return {
        ...state,
        isLoading: false,
        isSavingEvent: false,
        isMovingCalendarItem: false,
      };
    case CALENDAR_FETCH_ITEM_FAILED:
      return {
        ...state,
        isLoadingItem: false,
        calendarFetchItemFailed: true,
      };
    case CLOSE_CALENDAR_FETCH_ITEM_FAILED_DIALOG:
      return {
        ...state,
        calendarFetchItemFailed: false,
      };
    case RESET_CLIENT_STATE:
      return {
        ...state,
        isLoading: false,
        calendarItemsByDay: null,
      };
    default:
      return state;
  }
};
export default calendarDataState;
