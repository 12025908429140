import React from "react";
import colors from "../../../assets/colors";

interface Props {
  viewExerciseMode?: boolean;
  label: string;
  isHighlighted: boolean;
  onClick?: () => void;
}

const TagItem = (props: Props) => {
  const { viewExerciseMode, label, isHighlighted, onClick } = props;

  const tagStyle = {
    letterSpacing: "-0.38px",
    fontSize: "14px",
    lineHeight: "16px",
    color: isHighlighted ? colors.caliber_secondary : colors.caliber_gray_text,
    backgroundColor: isHighlighted ? "#e5e7eb" : "#ebedf0",
    borderRadius: 4,
    height: 32,
    minWidth: 81,
    marginRight: 12,
    marginBottom: 12,
    paddingLeft: 16,
    paddingRight: 16,
  };
  if (viewExerciseMode) {
    tagStyle.color = isHighlighted ? colors.caliber_secondary : "#B9BAC6";
    tagStyle.backgroundColor = isHighlighted ? "#E5E7EB" : "#EBEDEF";
  }

  const tagClassName = `d-flex ${
    isHighlighted ? "medium-bold" : "paragraph-normal"
  } justify-content-center align-content-center align-items-center`;

  if (onClick) {
    return (
      <div
        className={`${tagClassName} pointer`}
        role="button"
        tabIndex={0}
        onClick={onClick}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            onClick();
          }
        }}
        style={tagStyle}
      >
        {label}
      </div>
    );
  }
  return (
    <div className={tagClassName} style={tagStyle}>
      {label}
    </div>
  );
};

export default TagItem;
