import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Modal from "../Modal";
import AddWorkoutGroupItem from "./AddWorkoutGroupItem";
import {
  Client,
  ClientTrainingPlan,
  MutationCopyMasterWorkoutGroupToClientWorkoutGroupTemplateArgs,
  WorkoutGroupType,
} from "../../types/gqlTypes";
import WorkoutGroupGymIcon from "../../assets/images/WorkoutGroupGymIcon.svg";
import WorkoutGroupHomeIcon from "../../assets/images/WorkoutGroupHomeIcon.svg";
import WorkoutGroupTravelIcon from "../../assets/images/WorkoutGroupTravelIcon.svg";
import colors from "../../assets/colors";
import SimpleButton from "../SimpleButton";
import { StoreState } from "../../redux/reducers";
import { selectFullClientPlan } from "../../redux/reducers/TrainingPrograms/selectors";
import { copyMasterWorkoutGroupToClientGroup } from "../../redux/actions/TrainingPrograms/createAssign";

interface Props {
  onClose: () => void;
  masterWorkoutGroupsId: string;
  selectedClient: Client;
  clientPlan: ClientTrainingPlan;
  copyMasterWorkoutGroupToClientGroup: (
    args: MutationCopyMasterWorkoutGroupToClientWorkoutGroupTemplateArgs
  ) => void;
}

const AddWorkoutGroupModal = (props: Props) => {
  const {
    onClose,
    masterWorkoutGroupsId,
    selectedClient,
    clientPlan,
    copyMasterWorkoutGroupToClientGroup,
  } = props;

  const [groupName, setGroupName] = useState("");
  const [selectedGroupType, setSelectedGroupType] = useState(
    WorkoutGroupType.Gym
  );

  const textRef = useRef(null);

  useEffect(() => {
    textRef.current.focus();
  }, []);

  return (
    <Modal
      onClose={() => {
        onClose();
      }}
      closeOnDimmerClick
    >
      <div
        className="d-flex flex-column"
        style={{
          width: 480,
          height: 480,
          padding: "56px 38px 56px 38px",
        }}
      >
        <input
          className="heading-normal nofocus light-placeholder"
          ref={textRef}
          style={{
            height: 32,
            borderRadius: 2,
            background: "#F1F1F4",
            border: "0px",
            width: "100%",
            padding: 10,
            marginBottom: 20,
            resize: "none",
            fontSize: 16,
            lineHeight: "20px",
          }}
          placeholder="Name Workout Group"
          value={groupName}
          onChange={(event) => {
            setGroupName(event.target.value);
          }}
        />
        <AddWorkoutGroupItem
          icon={
            <img
              style={{
                width: 32,
                height: 32,
                borderRadius: 8,
              }}
              src={WorkoutGroupGymIcon}
              alt="Gym"
            />
          }
          title="Gym Workouts"
          isSelected={selectedGroupType === WorkoutGroupType.Gym}
          onSelected={() => {
            setSelectedGroupType(WorkoutGroupType.Gym);
          }}
        />
        <AddWorkoutGroupItem
          icon={
            <img
              style={{
                width: 32,
                height: 32,
                borderRadius: 8,
              }}
              src={WorkoutGroupHomeIcon}
              alt="Home"
            />
          }
          title="Home Workouts"
          isSelected={selectedGroupType === WorkoutGroupType.Home}
          onSelected={() => {
            setSelectedGroupType(WorkoutGroupType.Home);
          }}
        />
        <AddWorkoutGroupItem
          icon={
            <img
              style={{
                width: 32,
                height: 32,
                borderRadius: 8,
              }}
              src={WorkoutGroupTravelIcon}
              alt="Travel"
            />
          }
          title="Travel Workouts"
          isSelected={selectedGroupType === WorkoutGroupType.Travel}
          onSelected={() => {
            setSelectedGroupType(WorkoutGroupType.Travel);
          }}
        />
        <div
          className="d-flex"
          style={{
            justifyContent: "center",
            marginTop: 44,
          }}
        >
          <SimpleButton
            className="d-flex justify-content-center align-items-center medium-bold"
            style={{
              fontSize: "14px",
              lineHeight: "17px",
              letterSpacing: "-0.-22em",
              color: colors.caliber_white,
              backgroundColor: colors.caliber_gray_text,
              width: 99,
              height: 32,
              borderRadius: 8,
              marginRight: 16,
            }}
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </SimpleButton>
          <SimpleButton
            className="d-flex justify-content-center align-items-center medium-bold"
            style={{
              fontSize: "14px",
              lineHeight: "17px",
              letterSpacing: "-0.-22em",
              color: colors.caliber_white,
              backgroundColor: colors.caliber_green_200,
              width: 83,
              height: 32,
              borderRadius: 8,
            }}
            onClick={() => {
              copyMasterWorkoutGroupToClientGroup({
                clientId: selectedClient.id,
                clientTrainingPlanId: clientPlan.id,
                masterWorkoutGroupId: masterWorkoutGroupsId,
                title: groupName,
                workoutGroupType: selectedGroupType,
              } as MutationCopyMasterWorkoutGroupToClientWorkoutGroupTemplateArgs);
              onClose();
            }}
            disabled={groupName === ""}
          >
            Save
          </SimpleButton>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedClient: state.clientDetailState.user,
  clientPlan: selectFullClientPlan(state),
});

const mapDispatchToProps = (dispatch) => ({
  copyMasterWorkoutGroupToClientGroup: (
    args: MutationCopyMasterWorkoutGroupToClientWorkoutGroupTemplateArgs
  ) => {
    dispatch(copyMasterWorkoutGroupToClientGroup(args));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddWorkoutGroupModal);
