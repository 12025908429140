import { selectWorkouts } from ".";
import {
  ClientGymStepTemplate,
  ClientWorkoutTemplate,
  MasterGymStep,
  MasterWorkout,
  Workout,
} from "../../../types/gqlTypes";
import {
  ADD_GYM_STEP_FAIL,
  ADD_GYM_STEP_LOADING,
  ADD_GYM_STEP_SUCCESS,
  ADD_WORKOUT_TO_TRAINING_PLAN_FAIL,
  ADD_WORKOUT_TO_TRAINING_PLAN_LOADING,
  ADD_WORKOUT_TO_TRAINING_PLAN_SUCCESS,
  TrainingProgramsAction,
  TrainingProgramsState,
} from "../../actions/TrainingPrograms/types";
import { replaceGymSteps, replaceWorkouts } from "./helperFunctions";

const addReducer = (
  state: TrainingProgramsState,
  action: TrainingProgramsAction
) => {
  switch (action.type) {
    case ADD_WORKOUT_TO_TRAINING_PLAN_FAIL:
      return {
        ...state,
        isLoading: false,
        message: (action as any).data.message,
      };
    case ADD_WORKOUT_TO_TRAINING_PLAN_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_WORKOUT_TO_TRAINING_PLAN_SUCCESS: {
      const newWorkout: MasterWorkout | ClientWorkoutTemplate = {
        id: action?.ids?.[0],
        title: "New Workout",
        thumbnailImage: null,
        instructions: null,
        sortOrder: null,
      };

      (newWorkout as Workout).gymSteps = [];

      const newWorkouts: (MasterWorkout | ClientWorkoutTemplate)[] = [
        newWorkout,
        ...selectWorkouts(state),
      ];
      return {
        ...state,
        ...replaceWorkouts({ newWorkouts, state }),
        workoutIndex: 0,
        isEditWorkout: true,
        isLoading: false,
        showExercises: true,
        showPlans: false,
      };
    }

    case ADD_GYM_STEP_FAIL:
      return {
        ...state,
        isSaving: false,
        message: (action as any).data.message,
      };
    case ADD_GYM_STEP_LOADING:
      return {
        ...state,
        isSaving: true,
      };
    case ADD_GYM_STEP_SUCCESS:
      const newGymSteps: ClientGymStepTemplate[] | MasterGymStep[] = (
        action.workout as Workout
      ).gymSteps;

      return {
        ...state,
        ...replaceGymSteps({ newGymSteps, state }),
        isSaving: false,
      };
    default:
      return state;
  }
};
export default addReducer;
