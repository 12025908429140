import {
  ClientCronometerUserDiaryAction,
  ClientCronometerUserDiaryState,
  GET_CLIENT_CRONOMETER_USER_DIARY_FAIL,
  GET_CLIENT_CRONOMETER_USER_DIARY_LOADING,
  GET_CLIENT_CRONOMETER_USER_DIARY_SUCCESS,
} from "../../actions/ClientCronometerUserDiary/types";

const initialState: ClientCronometerUserDiaryState = {
  isLoading: false,
  userDiary: null,
};

const clientCronometerUserDiaryState = (
  state = initialState,
  action: ClientCronometerUserDiaryAction
) => {
  switch (action.type) {
    case GET_CLIENT_CRONOMETER_USER_DIARY_SUCCESS: {
      return {
        ...state,
        userDiary: action.userDiary,
        isLoading: false,
      };
    }
    case GET_CLIENT_CRONOMETER_USER_DIARY_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_CLIENT_CRONOMETER_USER_DIARY_FAIL: {
      return {
        ...state,
        userDiary: null,
        isLoading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default clientCronometerUserDiaryState;
