import apolloClient from "../../../api/apolloClient";
import { REASSIGN_CLIENT_TRAINER } from "../../../api/gql/users/trainers";
import { MutationReassignClientArgs } from "../../../types/gqlTypes";
import {
  connectToSendbird,
  disconnectFromSendbird,
  registerChannelHandler,
} from "../Messages";
import {
  REASSIGN_CLIENT_TRAINER_FAIL,
  REASSIGN_CLIENT_TRAINER_LOADING,
  REASSIGN_CLIENT_TRAINER_SUCCESS,
} from "./types";

export const reassignClientTrainer =
  (args: MutationReassignClientArgs) => async (dispatch, getState) => {
    if (getState().reassignClientTrainerState.isLoading) {
      return;
    }
    dispatch({
      type: REASSIGN_CLIENT_TRAINER_LOADING,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const result = (
        await client.mutate({
          mutation: REASSIGN_CLIENT_TRAINER,
          variables: args,
        })
      ).data.reassignClient;

      dispatch({
        type: REASSIGN_CLIENT_TRAINER_SUCCESS,
        user: result,
        message: "The Client's Trainer has been reassigned",
      });
      dispatch(disconnectFromSendbird());
      dispatch(connectToSendbird());
      dispatch(registerChannelHandler(args.trainerId));
    } catch (error) {
      console.error("Error reassignClientTrainer:", error);
      dispatch({
        type: REASSIGN_CLIENT_TRAINER_FAIL,
        data: error,
        message: "The Client's Trainer has not been reassigned",
      });
    }
  };
