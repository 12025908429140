import React from "react";
import { useParams } from "react-router-dom";
import { GroupChannel, Member } from "sendbird";
import colors from "../../assets/colors";
import ArrowDownIcon from "../../assets/images/ArrowDownIcon.svg";
import MessagesChatPane from "../MessagesChatPane";
import ProfilePhoto from "../ProfilePhoto";
import SimpleButton from "../SimpleButton";

interface Props {
  clientChannel: GroupChannel;
  client: Member;
  isPremium: boolean;
  onClose: () => void;
}

const FullviewChatWindow = (props: Props) => {
  const { clientChannel, client, isPremium, onClose } = props;
  const { id: clientId } = useParams();

  return (
    <div
      className="d-flex flex-column"
      style={{
        position: "fixed",
        backgroundColor: colors.caliber_white,
        borderRadius: "6px 6px 0px 0px",
        boxShadow: "0px 2px 16px #808297",
        bottom: 0,
        right: 32,
        zIndex: 999,
        minHeight: 450,
        minWidth: 400,
        height: 594,
        width: 600,
        maxHeight: window.innerHeight * 0.8,
        maxWidth: window.innerWidth * 0.8,
      }}
    >
      <div
        className="d-flex justify-content-between align-items-center"
        style={{
          padding: "16px 26px",
          borderBottom: "1px solid #E8EAEE",
          position: "relative",
        }}
      >
        <div className="d-flex align-items-center">
          <div
            style={{
              width: 48,
              height: 48,
              marginLeft: 2,
              marginRight: 16,
            }}
          >
            <ProfilePhoto
              isPremium={isPremium}
              imageUrl={client?.profileUrl}
              size={48}
            />
          </div>
          <div
            className="bold"
            style={{
              fontSize: "14px",
              lineHeight: "24px",
              color: colors.caliber_secondary,
              height: 24,
            }}
          >
            {client?.nickname}
          </div>
        </div>
        <SimpleButton nofocus style={{}} onClick={onClose}>
          <img src={ArrowDownIcon} />
        </SimpleButton>
      </div>
      <div
        className="d-flex flex-column"
        style={{
          flex: "1 1 409px",
          height: 409,
        }}
      >
        <MessagesChatPane
          isClientPremium={isPremium}
          isChatWindow
          key={clientId}
          channel={clientChannel}
          clientId={clientId}
        />
      </div>
    </div>
  );
};

export default FullviewChatWindow;
