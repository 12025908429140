import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import DataTable, { TableColumn } from "react-data-table-component";
import { getClientCronometerUserDiary } from "../../redux/actions/ClientCronometerUserDiary";
import { StoreState } from "../../redux/reducers";
import colors from "../../assets/colors";

interface Macros {
  alcohol: number;
  fat: number;
  fiber: number;
  kcal: number;
  magnesium: number;
  net_carbs: number;
  potassium: number;
  protein: number;
  sodium: number;
  total_carbs: number;
  sugars: number;
}

interface Nutrients {
  Sugars: number;
}

interface Food {
  name: string;
  serving: string;
}

interface Meal {
  foods: Food[];
  macros: Macros;
  name: string;
}

interface UserDiarySummary {
  nutrients: Nutrients;
  foods: Meal[];
  macros: Macros;
  food_grams: number;
}

interface Props {
  userDiary: UserDiarySummary;
  isLoading: boolean;
  day: Date;
  getCronometerUserDiary: (clientId: string, day: Date) => void;
  data: Meal;
}

const FoodDiaryProgress = (props: Props) => {
  const { userDiary, day, getCronometerUserDiary } = props;
  const { id: clientId } = useParams();
  const [dayField, setDayField] = useState(day as Date);

  const init = () => {
    const day = new Date();
    setDayField(day);
    getCronometerUserDiary(clientId, day);
  };

  useEffect(() => {
    init();
  }, [day]);

  const editPackageEndDateInput = (
    <DatePicker
      onChange={(date: Date) => {
        setDayField(date);
        getCronometerUserDiary(clientId, date);
      }}
      selected={dayField}
    />
  );

  const columns: TableColumn<Meal>[] = React.useMemo(
    () => [
      {
        name: "Meal",
        selector: (row) => row.name,
      },
      {
        name: "Calories",
        selector: (row) => row.macros.kcal,
      },
      {
        name: "Fat (g)",
        selector: (row) => row.macros.fat,
      },
      {
        name: "Protein (g)",
        selector: (row) => row.macros.protein,
      },
      {
        name: "Carbs (g)",
        selector: (row) => row.macros.total_carbs,
      },
      {
        name: "Fiber (g)",
        selector: (row) => row.macros.fiber,
      },
      {
        name: "Sugar (g)",
        selector: (row) =>
          row.name === "Total" ? row.macros?.sugars?.toFixed(1) : "n/a",
      },
      {
        name: "Sodium (mg)",
        selector: (row) => row.macros.sodium,
      },
      {
        name: "Potassium (mg)",
        selector: (row) => row.macros.potassium,
      },
    ],
    [day]
  );

  const ExpandedComponent = (props: Props) => {
    const { data } = props;
    return (
      <div
        style={{
          fontSize: "16px",
          fontFamily: "Circular, serif",
          color: "#000000DE",
          paddingLeft: "5px",
        }}
      >
        {data?.foods?.map((food) => (
          <p>
            {food.name}
            <br />
            {food.serving}
          </p>
        ))}
      </div>
    );
  };

  const isExpanded = (row) => true;

  const renderData = () => {
    const newUserDiary = userDiary;

    newUserDiary.foods.push({
      name: "Total",
      macros: {
        alcohol: userDiary.macros.alcohol,
        fat: userDiary.macros.fat,
        fiber: userDiary.macros.fiber,
        kcal: userDiary.macros.kcal,
        magnesium: userDiary.macros.magnesium,
        net_carbs: userDiary.macros.net_carbs,
        potassium: userDiary.macros.potassium,
        protein: userDiary.macros.protein,
        sodium: userDiary.macros.sodium,
        total_carbs: userDiary.macros.total_carbs,
        sugars: userDiary.nutrients.Sugars,
      } as Macros,
    } as Meal);
    return newUserDiary.foods;
  };

  const conditionalRowStyles = [
    {
      when: (row: Meal) => row.name === "Total",
      style: {
        backgroundColor: colors.caliber_secondary_gray_5,
      },
    },
    {
      when: (row: Meal) => row.name !== "Total",
      style: {
        backgroundColor: "#FAFAFA",
      },
    },
  ];

  return (
    <div
      style={{
        marginBottom: 70,
      }}
    >
      Food Diary {editPackageEndDateInput}
      <div
        style={{
          paddingBottom: 10,
        }}
      />
      {userDiary && (
        <DataTable
          columns={columns}
          data={renderData()}
          pagination={false}
          expandableRows
          expandableRowsComponent={ExpandedComponent}
          expandableRowExpanded={isExpanded}
          expandableRowDisabled={(row: Meal) => row.name === "Total"}
          conditionalRowStyles={conditionalRowStyles}
          customStyles={{
            header: {
              style: {
                fontSize: "14px",
                fontFamily: "Circular, serif",
                color: colors.caliber_gray_border,
                minHeight: "0px",
                paddingLeft: "0px",
                paddingRight: "0px",
              },
            },
            cells: {
              style: {
                fontSize: "14px",
                fontFamily: "Circular, serif",
              },
            },
          }}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getCronometerUserDiary: (clientId: string, day: Date) => {
    dispatch(getClientCronometerUserDiary(clientId, day));
  },
});

const mapStateToProps = (state: StoreState) => ({
  userDiary: state.clientCronometerUserDiaryState.userDiary,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(FoodDiaryProgress);
