import React from "react";
import { ClientsTabColumnsType } from "../../../redux/actions/ClientsTab/types";
import { ClientPackageType, UserStatusType } from "../../../types/gqlTypes";
import SimpleButton from "../../SimpleButton";

interface Props {
  showCancelled?: boolean;
  showFreemium?: boolean;
  isFilterOpen: boolean;
  text: ClientsTabColumnsType;
  column: "status" | "packageType";
  clientPackageTypeCriteria?: ClientPackageType[];
  statusCriteria?: UserStatusType[];
  onOpenFilter?: (column: "status" | "packageType") => void;
  onClose: () => void;
}

const Filter = (props: Props) => {
  const {
    showCancelled,
    showFreemium,
    isFilterOpen,
    column,
    text,
    clientPackageTypeCriteria,
    statusCriteria,
    onOpenFilter,
    onClose,
  } = props;

  let isFilterApplied;
  let isFilterable;

  if (text === ClientsTabColumnsType.clientPackageType && showFreemium) {
    isFilterApplied = clientPackageTypeCriteria?.length !== 3;
    isFilterable = true;
  } else if (
    text === ClientsTabColumnsType.clientPackageType &&
    !showFreemium
  ) {
    isFilterApplied = clientPackageTypeCriteria?.length !== 2;
    isFilterable = true;
  } else if (text === ClientsTabColumnsType.status && showCancelled) {
    isFilterApplied = statusCriteria?.length !== 6;
    isFilterable = true;
  } else if (text === ClientsTabColumnsType.status && !showCancelled) {
    isFilterApplied = statusCriteria?.length !== 5;
    isFilterable = true;
  } else {
    isFilterable = false;
    isFilterApplied = false;
  }

  const onBlur = () => {
    setTimeout(() => {
      if (document.activeElement.id !== "filter_element") {
        onClose();
      }
    }, 0);
  };

  if (isFilterable) {
    return (
      <SimpleButton
        nofocus
        id="filter_element"
        onClick={() => {
          if (isFilterOpen) {
            onClose();
          } else {
            onOpenFilter(column);
          }
        }}
        onBlur={onBlur}
      >
        {isFilterApplied ? <FilterIcon /> : <EmptyFilterIcon />}
      </SimpleButton>
    );
  }

  return null;
};

const EmptyFilterIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4Z"
        fill="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 6V7.33333H16V6H4ZM8.66667 14H11.3333V12.6667H8.66667V14ZM14 10.6667H6V9.33333H14V10.6667Z"
        fill="#82869B"
      />
    </svg>
  );
};

const FilterIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4Z"
        fill="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 6V7.33333H16V6H4ZM8.66667 14H11.3333V12.6667H8.66667V14ZM14 10.6667H6V9.33333H14V10.6667Z"
        fill="#01052F"
      />
      <circle cx="16" cy="6" r="2.5" fill="#1FC866" stroke="white" />
    </svg>
  );
};

export default Filter;
