import React, { useState } from "react";
import SimpleButton from "../../SimpleButton";

interface Props {
  isSelected: boolean;
  onClick: () => void;
}

const EditButton = (props: Props) => {
  const { isSelected, onClick } = props;

  const [hovered, setHovered] = useState(false);

  if (isSelected) {
    return (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 6C0 2.68629 2.68629 0 6 0H26C29.3137 0 32 2.68629 32 6V26C32 29.3137 29.3137 32 26 32H6C2.68629 32 0 29.3137 0 26V6Z"
          fill="white"
        />
        <path
          d="M6 0.5H26V-0.5H6V0.5ZM31.5 6V26H32.5V6H31.5ZM26 31.5H6V32.5H26V31.5ZM0.5 26V6H-0.5V26H0.5ZM6 31.5C2.96243 31.5 0.5 29.0376 0.5 26H-0.5C-0.5 29.5899 2.41015 32.5 6 32.5V31.5ZM31.5 26C31.5 29.0376 29.0376 31.5 26 31.5V32.5C29.5899 32.5 32.5 29.5899 32.5 26H31.5ZM26 0.5C29.0376 0.5 31.5 2.96243 31.5 6H32.5C32.5 2.41015 29.5899 -0.5 26 -0.5V0.5ZM6 -0.5C2.41015 -0.5 -0.5 2.41015 -0.5 6H0.5C0.5 2.96243 2.96243 0.5 6 0.5V-0.5Z"
          fill="#3547FC"
        />
        <path
          d="M25 16.0058C24.7348 16.0058 24.4804 16.1112 24.2929 16.2987C24.1054 16.4862 24 16.7406 24 17.0058V23.0058C24 23.271 23.8946 23.5254 23.7071 23.7129C23.5196 23.9004 23.2652 24.0058 23 24.0058H9C8.73478 24.0058 8.48043 23.9004 8.29289 23.7129C8.10536 23.5254 8 23.271 8 23.0058V9.0058C8 8.74058 8.10536 8.48623 8.29289 8.29869C8.48043 8.11116 8.73478 8.0058 9 8.0058H15C15.2652 8.0058 15.5196 7.90044 15.7071 7.71291C15.8946 7.52537 16 7.27101 16 7.0058C16 6.74058 15.8946 6.48623 15.7071 6.29869C15.5196 6.11116 15.2652 6.0058 15 6.0058H9C8.20435 6.0058 7.44129 6.32187 6.87868 6.88448C6.31607 7.44709 6 8.21015 6 9.0058V23.0058C6 23.8014 6.31607 24.5645 6.87868 25.1271C7.44129 25.6897 8.20435 26.0058 9 26.0058H23C23.7956 26.0058 24.5587 25.6897 25.1213 25.1271C25.6839 24.5645 26 23.8014 26 23.0058V17.0058C26 16.7406 25.8946 16.4862 25.7071 16.2987C25.5196 16.1112 25.2652 16.0058 25 16.0058ZM10 16.7658V21.0058C10 21.271 10.1054 21.5254 10.2929 21.7129C10.4804 21.9004 10.7348 22.0058 11 22.0058H15.24C15.3716 22.0066 15.5021 21.9813 15.6239 21.9316C15.7457 21.8818 15.8566 21.8085 15.95 21.7158L22.87 14.7858L25.71 12.0058C25.8037 11.9128 25.8781 11.8022 25.9289 11.6804C25.9797 11.5585 26.0058 11.4278 26.0058 11.2958C26.0058 11.1638 25.9797 11.0331 25.9289 10.9112C25.8781 10.7894 25.8037 10.6788 25.71 10.5858L21.47 6.2958C21.377 6.20207 21.2664 6.12768 21.1446 6.07691C21.0227 6.02614 20.892 6 20.76 6C20.628 6 20.4973 6.02614 20.3754 6.07691C20.2536 6.12768 20.143 6.20207 20.05 6.2958L17.23 9.1258L10.29 16.0558C10.1973 16.1492 10.124 16.2601 10.0742 16.3819C10.0245 16.5037 9.99924 16.6342 10 16.7658ZM20.76 8.4158L23.59 11.2458L22.17 12.6658L19.34 9.8358L20.76 8.4158ZM12 17.1758L17.93 11.2458L20.76 14.0758L14.83 20.0058H12V17.1758Z"
          fill="#3547FC"
        />
      </svg>
    );
  }

  return (
    <SimpleButton
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {hovered ? (
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 6C0 2.68629 2.68629 0 6 0H26C29.3137 0 32 2.68629 32 6V26C32 29.3137 29.3137 32 26 32H6C2.68629 32 0 29.3137 0 26V6Z"
            fill="#D6DAFE"
          />
          <path
            d="M25 16.0058C24.7348 16.0058 24.4804 16.1112 24.2929 16.2987C24.1054 16.4862 24 16.7406 24 17.0058V23.0058C24 23.271 23.8946 23.5254 23.7071 23.7129C23.5196 23.9004 23.2652 24.0058 23 24.0058H9C8.73478 24.0058 8.48043 23.9004 8.29289 23.7129C8.10536 23.5254 8 23.271 8 23.0058V9.0058C8 8.74058 8.10536 8.48623 8.29289 8.29869C8.48043 8.11116 8.73478 8.0058 9 8.0058H15C15.2652 8.0058 15.5196 7.90044 15.7071 7.71291C15.8946 7.52537 16 7.27101 16 7.0058C16 6.74058 15.8946 6.48623 15.7071 6.29869C15.5196 6.11116 15.2652 6.0058 15 6.0058H9C8.20435 6.0058 7.44129 6.32187 6.87868 6.88448C6.31607 7.44709 6 8.21015 6 9.0058V23.0058C6 23.8014 6.31607 24.5645 6.87868 25.1271C7.44129 25.6897 8.20435 26.0058 9 26.0058H23C23.7956 26.0058 24.5587 25.6897 25.1213 25.1271C25.6839 24.5645 26 23.8014 26 23.0058V17.0058C26 16.7406 25.8946 16.4862 25.7071 16.2987C25.5196 16.1112 25.2652 16.0058 25 16.0058ZM10 16.7658V21.0058C10 21.271 10.1054 21.5254 10.2929 21.7129C10.4804 21.9004 10.7348 22.0058 11 22.0058H15.24C15.3716 22.0066 15.5021 21.9813 15.6239 21.9316C15.7457 21.8818 15.8566 21.8085 15.95 21.7158L22.87 14.7858L25.71 12.0058C25.8037 11.9128 25.8781 11.8022 25.9289 11.6804C25.9797 11.5585 26.0058 11.4278 26.0058 11.2958C26.0058 11.1638 25.9797 11.0331 25.9289 10.9112C25.8781 10.7894 25.8037 10.6788 25.71 10.5858L21.47 6.2958C21.377 6.20207 21.2664 6.12768 21.1446 6.07691C21.0227 6.02614 20.892 6 20.76 6C20.628 6 20.4973 6.02614 20.3754 6.07691C20.2536 6.12768 20.143 6.20207 20.05 6.2958L17.23 9.1258L10.29 16.0558C10.1973 16.1492 10.124 16.2601 10.0742 16.3819C10.0245 16.5037 9.99924 16.6342 10 16.7658ZM20.76 8.4158L23.59 11.2458L22.17 12.6658L19.34 9.8358L20.76 8.4158ZM12 17.1758L17.93 11.2458L20.76 14.0758L14.83 20.0058H12V17.1758Z"
            fill="#3547FC"
          />
        </svg>
      ) : (
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 10.0058C18.7348 10.0058 18.4804 10.1112 18.2929 10.2987C18.1054 10.4862 18 10.7406 18 11.0058V17.0058C18 17.271 17.8946 17.5254 17.7071 17.7129C17.5196 17.9004 17.2652 18.0058 17 18.0058H3C2.73478 18.0058 2.48043 17.9004 2.29289 17.7129C2.10536 17.5254 2 17.271 2 17.0058V3.0058C2 2.74058 2.10536 2.48623 2.29289 2.29869C2.48043 2.11116 2.73478 2.0058 3 2.0058H9C9.26521 2.0058 9.51957 1.90044 9.70711 1.71291C9.89464 1.52537 10 1.27101 10 1.0058C10 0.740582 9.89464 0.486228 9.70711 0.298692C9.51957 0.111155 9.26521 0.00579834 9 0.00579834H3C2.20435 0.00579834 1.44129 0.321869 0.87868 0.884478C0.316071 1.44709 0 2.21015 0 3.0058V17.0058C0 17.8014 0.316071 18.5645 0.87868 19.1271C1.44129 19.6897 2.20435 20.0058 3 20.0058H17C17.7956 20.0058 18.5587 19.6897 19.1213 19.1271C19.6839 18.5645 20 17.8014 20 17.0058V11.0058C20 10.7406 19.8946 10.4862 19.7071 10.2987C19.5196 10.1112 19.2652 10.0058 19 10.0058ZM4 10.7658V15.0058C4 15.271 4.10536 15.5254 4.29289 15.7129C4.48043 15.9004 4.73478 16.0058 5 16.0058H9.24C9.3716 16.0066 9.50207 15.9813 9.62391 15.9316C9.74574 15.8818 9.85656 15.8085 9.95 15.7158L16.87 8.7858L19.71 6.0058C19.8037 5.91284 19.8781 5.80223 19.9289 5.68038C19.9797 5.55852 20.0058 5.42781 20.0058 5.2958C20.0058 5.16379 19.9797 5.03308 19.9289 4.91122C19.8781 4.78936 19.8037 4.67876 19.71 4.5858L15.47 0.295798C15.377 0.20207 15.2664 0.127676 15.1446 0.0769069C15.0227 0.0261382 14.892 0 14.76 0C14.628 0 14.4973 0.0261382 14.3754 0.0769069C14.2536 0.127676 14.143 0.20207 14.05 0.295798L11.23 3.1258L4.29 10.0558C4.19732 10.1492 4.12399 10.2601 4.07423 10.3819C4.02446 10.5037 3.99924 10.6342 4 10.7658ZM14.76 2.4158L17.59 5.2458L16.17 6.6658L13.34 3.8358L14.76 2.4158ZM6 11.1758L11.93 5.2458L14.76 8.0758L8.83 14.0058H6V11.1758Z"
            fill="#3547FC"
          />
        </svg>
      )}
    </SimpleButton>
  );
};

export default EditButton;
