import React from "react";
import colors from "../../assets/colors";

interface Props {
  value: string;
  id: string;
  placeholder?: string;
  onChange: (value: string) => void;
  onEnter: () => void;
}

const TraitInput = (props: Props) => {
  const { value, id, placeholder, onChange, onEnter } = props;

  const repsInputStyle: React.CSSProperties = {
    width: 428,
    height: 48,
    backgroundColor: colors.caliber_secondary_gray_11,
    border: `1px solid ${colors.caliber_secondary_gray_11}`,
    borderRadius: 8,
    fontSize: "14px",
    color: colors.caliber_gray_text,
    paddingLeft: 16,
    paddingRight: 16,
  };
  return (
    <input
      id={id}
      placeholder={placeholder}
      className="medium-bold"
      value={value}
      onKeyDown={(event) => {
        if (event.key === "Enter" && value?.length > 0) {
          onEnter();
        }
      }}
      onChange={(event) => onChange(event.target.value)}
      style={repsInputStyle}
    />
  );
};

export default TraitInput;
