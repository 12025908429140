import React from "react";
import colors from "../../assets/colors";
import ArrowIcons, { ArrowIconType } from "../Icons/ArrowIcons";
import SimpleButton from "../SimpleButton";

interface Props {
  onBack: () => void;
  onSuccess: () => void;
  disabled?: boolean;
}

const Footer = (props: Props) => {
  const { onBack, onSuccess, disabled } = props;

  const backBtnStyle: React.CSSProperties = {
    color: "#AEB1BF",
    fontSize: "14px",
    lineHeight: "20px",
    marginLeft: 8,
    height: 20,
  };

  return (
    <div className="d-flex justify-content-between align-items-center">
      <SimpleButton
        className="d-flex align-items-center"
        nofocus
        onClick={onBack}
      >
        <ArrowIcons
          isLeft
          width={20}
          height={20}
          iconType={ArrowIconType.ARROW_BACK}
        />
        <div className="d-flex justify-content-center" style={backBtnStyle}>
          back
        </div>
      </SimpleButton>

      <SimpleButton
        nofocus
        nopointer={disabled}
        className="d-flex justify-content-center align-items-center medium-bold"
        onClick={() => {
          if (!disabled) {
            onSuccess();
          }
        }}
        style={{
          fontSize: "14px",
          lineHeight: "17px",
          letterSpacing: "-0.022em",
          color: disabled
            ? colors.caliber_secondary_gray_11
            : colors.caliber_white,
          backgroundColor: disabled
            ? colors.caliber_secondary_gray_47
            : colors.caliber_green_200,
          borderRadius: 8,
          width: 146,
          height: 36,
        }}
      >
        Save new habit
      </SimpleButton>
    </div>
  );
};

export default Footer;
