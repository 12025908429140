import { Exercise } from "../../../types/gqlTypes";

export const ADMIN_EXERCISE_LIB_SAVE_SUCCESS =
  "ADMIN_EXERCISE_LIB_SAVE_SUCCESS";
export const ADMIN_EXERCISE_LIB_SAVE_FAIL = "ADMIN_EXERCISE_LIB_SAVE_FAIL";
export const ADMIN_EXERCISE_DELETE_SUCCESS = "ADMIN_EXERCISE_DELETE_SUCCESS";
export const ADMIN_EXERCISE_DELETE_FAIL = "ADMIN_EXERCISE_DELETE_FAIL";
export const ADMIN_EXERCISE_LIB_SAVING = "ADMIN_EXERCISE_LIB_SAVING";
export const ADMIN_EXERCISE_DELETE = "ADMIN_EXERCISE_DELETE";
export const RESET_ADMIN_EXERCISE_LIBRARY_STATE =
  "RESET_ADMIN_EXERCISE_LIBRARY_STATE";

export interface AdminExerciseLibraryState {
  isSaving: boolean;
  isLoading: boolean;
  wasDeletedExerciseActive: boolean;
  lastSavedExercise: Exercise;
}

export interface AdminExerciseLibraryAction {
  type: string;
  savedExercise?: Exercise;
  deletedExerciseId?: string;
  data?: any;
}
