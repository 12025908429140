import { format, parse } from "date-fns";
import React, { useMemo, useState } from "react";
import colors from "../../assets/colors";
import {
  ClientGymStep,
  ClientGymStepTemplate,
  ClientSupersetGymStep,
  ClientSupersetGymStepTemplate,
  ClientWorkout,
  ClientWorkoutTemplate,
  Exercise,
  ExerciseTargetBaseType,
  ExerciseType,
  FormVideo,
  GymStep,
} from "../../types/gqlTypes";
import {
  exerciseThumbnailImageUrl,
  renderWorkoutGroupThumbnailIcon,
} from "../../utils";
import { useRerenderOnResize } from "../../utils/customHooks";
import Dropdown from "../Dropdown";
import SimpleButton from "../SimpleButton";
import TrainingProgressChart from "./chart";
import Feedback from "./feedback";
import FormVideoComponent from "./FormVideoComponent";
import "./index.css";

interface Props {
  clientWorkoutHistory: ClientWorkout[];
  clientWorkoutTemplates: ClientWorkoutTemplate[];
  formVideos: FormVideo[];
  selectedWorkout: string;
  setSelectedWorkout: (value: string) => void;
  onEditSet: (gymStep: ClientGymStep, date: string, setNumber: number) => void;
}

const TrainingProgressTable = (props: Props) => {
  const {
    clientWorkoutHistory,
    clientWorkoutTemplates,
    formVideos,
    selectedWorkout: selectedWorkoutTemplate,
    setSelectedWorkout,
    onEditSet,
  } = props;
  useRerenderOnResize();
  const [selectedExercise, setSelectedExercise] = useState<Exercise>(null);

  const rowHeight = 30;
  const headerRowHeight = 52;
  const tableHeight = Math.max(window.innerHeight - 315, 300);
  const tableWidth =
    window.innerWidth > 1100
      ? window.innerWidth - 300
      : Math.max(window.innerWidth - 150, 400);
  const dateCardWidth = 138;
  const exerciseIds = {};
  const gymStepByExerciseId = {};
  const hiddenWeight = {};
  const tableBody = {};
  const exerciseNames = [];
  const exercises = {};
  const gymStepTemplateByExerciseId = {};
  const hiddenWeightExercises = {};

  const formVideosByGymStepId = useMemo(() => {
    const obj = {};
    formVideos?.forEach((video) => {
      if (video.setNumber) {
        obj[`${video.clientGymStep.id}-${video.setNumber}`] = video;
      }
    });
    return obj;
  }, [formVideos]);

  const workoutTemplateOptions = useMemo(() => {
    return clientWorkoutTemplates.map((workout, index) => {
      return {
        text: `${workout?.title} (${workout?.clientWorkoutGroupTemplate.title})`,
        value: index.toString(),
        image: renderWorkoutGroupThumbnailIcon(
          workout?.clientWorkoutGroupTemplate.workoutGroupType
        ),
      };
    });
  }, [clientWorkoutTemplates]);

  const isExerciseInClientsPlan = (exerciseId: string) => {
    const workout = clientWorkoutTemplates?.[
      selectedWorkoutTemplate
    ] as ClientWorkoutTemplate;
    return workout?.gymSteps?.some(
      (gymStep) => gymStep?.exercise?.id === exerciseId
    );
  };

  const generateExerciseColumnData = (
    gymStep: ClientGymStep,
    formattedDate,
    dateIndex
  ) => {
    const { targetBase, id: exerciseId, type } = gymStep.exercise;
    if (type === ExerciseType.Warmup) {
      return;
    }
    exerciseIds[exerciseId] = gymStep.exercise;
    gymStepByExerciseId[`${formattedDate}-${exerciseId}-${dateIndex}`] =
      gymStep;
    hiddenWeight[exerciseId] = !!gymStep.hideWeightForExerciseSets;

    if (!tableBody[exerciseId]) {
      tableBody[exerciseId] = {};
    }
    gymStep.exerciseSets.forEach((exerciseSet, setIndex) => {
      const { actualReps, actualWeight, actualTime } = exerciseSet;
      const setName = `SET ${setIndex + 1}`;
      const reps = [];
      if (targetBase === ExerciseTargetBaseType.Reps) {
        reps.push(actualReps);
      } else if (targetBase === ExerciseTargetBaseType.Time) {
        const min = Math.floor((actualTime || 0) / 60);
        const sec = (actualTime || 0) - min * 60;
        if (min > 0) {
          reps.push(`${min}`);
          sec > 0
            ? reps.push(`:${sec > 9 ? "" : "0"}${sec} mins`)
            : reps.push(`${min === 1 ? " min" : " mins"}`);
        } else {
          sec === 1 ? reps.push(`${sec} sec`) : reps.push(`${sec} secs`);
        }
      }
      if (!tableBody[exerciseId][setName]) {
        tableBody[exerciseId][setName] = {};
      }
      if (exerciseSet) {
        tableBody[exerciseId][setName][
          `${formattedDate}-${dateIndex}`
        ] = `${reps.join("")} x ${Math.round((actualWeight || 0) * 2) / 2} lbs`;
      } else {
        tableBody[exerciseId][setName][`${formattedDate}-${dateIndex}`] = "";
      }
    });
    const numberOfSets = gymStep?.exerciseSets?.length || 0;
    if (numberOfSets < 3) {
      new Array(3).fill(1).forEach((_, setIndex) => {
        const setName = `SET ${setIndex + 1}`;
        if (!tableBody[exerciseId][setName]) {
          tableBody[exerciseId][setName] = {};
          tableBody[exerciseId][setName][`${formattedDate}-${dateIndex}`] = "";
        }
      });
    }
  };

  const generateExerciseSetsCellData = (
    gymStepTemplate: ClientGymStepTemplate
  ) => {
    const { exercise, hideWeightForExerciseSets } = gymStepTemplate;

    if (exercise?.type === ExerciseType.Warmup) {
      return;
    }
    if (tableBody?.[exercise?.id]) {
      exerciseNames.push({ id: exercise.id, name: exercise.name });
      exercises[exercise.id] = exercise;
      gymStepTemplateByExerciseId[exercise.id] = gymStepTemplate;
      hiddenWeightExercises[exercise.id] = !!hideWeightForExerciseSets;
    } else if (
      !tableBody?.[exercise?.id] &&
      isExerciseInClientsPlan(exercise?.id)
    ) {
      tableBody[exercise?.id] = { "SET 1": "", "SET 2": "", "SET 3": "" };
      exerciseNames.push({ id: exercise.id, name: exercise.name });
      exercises[exercise.id] = exercise;
      gymStepTemplateByExerciseId[exercise.id] = gymStepTemplate;
      hiddenWeightExercises[exercise.id] = !!hideWeightForExerciseSets;
    }
  };

  const table = useMemo(() => {
    const dates = [];
    const feedback = [];
    clientWorkoutHistory.forEach((workout, dateIndex) => {
      const {
        gymSteps,
        workoutCalendarItem: { clientWorkoutDate: date, rating, notes },
      } = workout;
      const formattedDate = format(
        parse(date, "yyyy-MM-dd", new Date()),
        "MMM dd"
      );
      dates.push(formattedDate);
      feedback.push({ rating, notes });
      gymSteps?.forEach((gymStep, gymStepIndex) => {
        const isSuperset: boolean = gymStep.exercise == null;
        if (isSuperset) {
          const supersetGymStep: ClientSupersetGymStep = gymStep as GymStep;
          supersetGymStep.clientGymSteps.forEach((supersetChild) => {
            generateExerciseColumnData(supersetChild, formattedDate, dateIndex);
          });
        } else {
          generateExerciseColumnData(gymStep, formattedDate, dateIndex);
        }
      });
    });

    clientWorkoutTemplates?.[selectedWorkoutTemplate]?.gymSteps.forEach(
      (gymStepTemplate) => {
        const isSuperset: boolean = gymStepTemplate.exercise == null;
        if (isSuperset) {
          const supersetGymStepTemplate: ClientSupersetGymStepTemplate =
            gymStepTemplate as GymStep;
          supersetGymStepTemplate.clientGymStepTemplates?.forEach(
            (supersetChildTemplate) => {
              generateExerciseSetsCellData(supersetChildTemplate);
            }
          );
        } else {
          generateExerciseSetsCellData(gymStepTemplate);
        }
      }
    );

    Object.keys(exerciseIds).forEach((exerciseId) => {
      const exist = exerciseNames.some(({ id }) => id === exerciseId);
      if (!exist) {
        exerciseNames.push({
          id: exerciseId,
          name: exerciseIds[exerciseId]?.name,
        });
        exercises[exerciseId] = exerciseIds[exerciseId];
        hiddenWeightExercises[exerciseId] = hiddenWeight[exerciseId];
      }
    });
    return {
      exerciseNames,
      tableBody,
      dates,
      exercises,
      gymStepByExerciseId,
      gymStepTemplateByExerciseId,
      feedback,
      hiddenWeightExercises,
    };
  }, [
    clientWorkoutHistory,
    clientWorkoutTemplates,
    generateExerciseSetsCellData,
    generateExerciseColumnData,
  ]);

  const exerciseCard = (table, setNumber, exerciseId, exerciseName) => {
    const { target } = table?.gymStepTemplateByExerciseId?.[exerciseId] || {};
    return (
      <td
        key={`exerciseCard-${exerciseName}`}
        rowSpan={selectedExercise ? 1 : setNumber}
        style={{
          position: "sticky",
          left: 0,
          top: "auto",
          height: Math.max(120, rowHeight * setNumber + 24),
          backgroundColor: colors.caliber_gray_bg,
          width: selectedExercise ? 256 : 268,
        }}
      >
        <div
          key={`${exerciseName}-${setNumber}`}
          style={{
            width: 268,
            height: Math.max(120, rowHeight * setNumber + 24),
            backgroundColor: colors.caliber_gray_bg,
          }}
        >
          <div
            role="button"
            tabIndex={0}
            onClick={() => {
              if (selectedExercise?.id === exerciseId) {
                setSelectedExercise(null);
              } else {
                setSelectedExercise(table?.exercises?.[exerciseId]);
              }
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                if (selectedExercise?.id === exerciseId) {
                  setSelectedExercise(null);
                } else {
                  setSelectedExercise(table?.exercises?.[exerciseId]);
                }
              }
            }}
            className="d-flex align-items-center bold nofocus pointer"
            style={{
              width: 256,
              height: 82,
              backgroundColor:
                exerciseId === selectedExercise?.id
                  ? colors.caliber_secondary_gray_5
                  : colors.caliber_white,
              borderRadius: 8,
              padding: 4,
            }}
          >
            <img
              style={{
                width: 64,
                height: 64,
                marginLeft: 8,
                marginRight: 8,
                borderRadius: 8,
              }}
              src={exerciseThumbnailImageUrl(table?.exercises?.[exerciseId])}
            />
            <div
              className="bold d-flex flex-column justify-content-center"
              style={{
                height: 66,
                width: 164,
                marginLeft: 4,
                fontSize: "16px",
                lineHeight: "16px",
                letterSpacing: "-0.03em",
                whiteSpace: "pre-wrap",
              }}
            >
              {exerciseName}
              {target && (
                <div className="d-flex">
                  <div
                    className="bold"
                    style={{
                      fontSize: "14px",
                      lineHeight: "14px",
                      letterSpacing: "-0.03em",
                      marginTop: 7,
                      backgroundColor: "#DADCE3",
                      borderRadius: 6,
                      padding: "2px 8px",
                    }}
                  >
                    {target}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </td>
    );
  };
  const setCard = (setNumber, setName, exerciseName) => {
    return (
      <td
        key={`setCard-${setName}-${exerciseName}`}
        style={{
          position: "sticky",
          left: 268,
          top: "auto",
          height: Math.max(120, rowHeight * setNumber + 24) / setNumber,
          width: 76,
          backgroundColor: colors.caliber_gray_bg,
        }}
      >
        <div
          style={{
            width: 76,
            height: Math.max(120, rowHeight * setNumber + 24) / setNumber,
            backgroundColor: colors.caliber_gray_bg,
          }}
        >
          <div
            className="d-flex align-items-center justify-content-center circular-medium-font"
            style={{
              width: 44,
              height: 22,
              padding: 4,
              fontSize: "12px",
              lineHeight: "14px",
              borderRadius: 6,
              backgroundColor: "#DADCE3",
            }}
          >
            {setName}
          </div>
        </div>
      </td>
    );
  };
  const dateCard = (feedback, date, index) => {
    return (
      <th
        key={`dateCard-${date}-${index}`}
        style={{
          position: "sticky",
          top: 0,
          left: "auto",
          height: headerRowHeight,
          zIndex: 2,
          backgroundColor: colors.caliber_gray_bg,
        }}
      >
        <div
          style={{
            width: dateCardWidth,
            height: headerRowHeight,
            padding: "11px 0px 19px 0px",
            backgroundColor: colors.caliber_gray_bg,
          }}
        >
          <div
            className="d-flex align-items-center justify-content-center circular-medium-font"
            style={{
              height: 22,
              width: dateCardWidth - 16,
              backgroundColor: "#DADCE3",
              color: colors.caliber_secondary,
              borderRadius: 4,
              lineHeight: "14px",
              fontSize: "12px",
            }}
          >
            {date}
            <Feedback index={index} feedback={feedback} />
          </div>
        </div>
      </th>
    );
  };
  const headerCorner = (
    <th
      colSpan={selectedExercise ? 1 : 2}
      style={{
        position: "sticky",
        top: 0,
        left: 0,
        zIndex: 3,
        width: selectedExercise ? 256 : 344,
        height: headerRowHeight,
        backgroundColor: colors.caliber_gray_bg,
      }}
    >
      <div
        style={{
          height: 36,
          marginBottom: 12,
          width: selectedExercise ? 256 : 313,
          backgroundColor: colors.caliber_gray_bg,
        }}
      >
        <Dropdown
          value={selectedWorkoutTemplate}
          items={workoutTemplateOptions}
          height={36}
          onSelect={(value) => {
            setSelectedWorkout(value);
            setSelectedExercise(null);
          }}
          displayTextWithImage
        />
      </div>
    </th>
  );
  const dataCard = (
    exerciseId,
    exerciseName,
    setName,
    date,
    setNumber,
    dateIndex,
    setIndex
  ) => {
    const gymStep = table.gymStepByExerciseId?.[
      `${date}-${exerciseId}-${dateIndex}`
    ] as ClientGymStep;
    const doesGymStepExist = !!gymStep;
    const isThereAnyData =
      !!table.tableBody[exerciseId][setName][`${date}-${dateIndex}`];
    const gymStepId =
      table?.gymStepByExerciseId?.[`${date}-${exerciseId}-${dateIndex}`]?.id;
    const formVideo = formVideosByGymStepId?.[
      `${gymStepId}-${setIndex + 1}`
    ] as FormVideo;
    const isThereVideoForm = !!formVideo;
    const data = table.tableBody[exerciseId][setName][`${date}-${dateIndex}`];
    return (
      <td
        key={`dataCard-${exerciseName}-${setName}-${date}-${dateIndex}`}
        style={{
          width: dateCardWidth,
          height: Math.max(120, rowHeight * setNumber + 24) / setNumber,
          backgroundColor: colors.caliber_gray_bg,
        }}
      >
        <div
          style={{
            width: dateCardWidth,
            height: Math.max(120, rowHeight * setNumber + 24) / setNumber,
            backgroundColor: colors.caliber_gray_bg,
          }}
        >
          <SimpleButton
            nopointer={!doesGymStepExist || !isThereAnyData}
            nofocus={!doesGymStepExist || !isThereAnyData}
            onClick={() => {
              if (doesGymStepExist && isThereAnyData) {
                onEditSet(gymStep, date, setIndex);
              }
            }}
            className="d-flex align-items-center justify-content-between circular-medium-font"
            style={{
              width: dateCardWidth - 16,
              height: 22,
              backgroundColor: colors.caliber_white,
              color: colors.caliber_secondary,
              borderRadius: 4,
              fontSize: "12px",
              lineHeight: "14px",
              paddingLeft: data ? 13 : 26,
            }}
          >
            {data || "---"}
            {isThereVideoForm && (
              <FormVideoComponent
                formVideo={formVideo}
                exercise={gymStep?.exercise}
              />
            )}
          </SimpleButton>
        </div>
      </td>
    );
  };

  return (
    <div className="d-flex">
      <div
        style={{
          height: tableHeight,
          width: selectedExercise ? 280 : tableWidth,
          overflow: "auto",
          position: "relative",
        }}
      >
        <div>
          <table>
            <thead>
              <tr>
                {headerCorner}
                {!selectedExercise &&
                  table.dates.map((date, index) =>
                    dateCard(table.feedback?.[index], date, index)
                  )}
              </tr>
            </thead>
            <tbody>
              {table.exerciseNames.map(
                ({ id: exerciseId, name: exerciseName }) => {
                  const setNumber = table.tableBody[exerciseId]
                    ? Object.keys(table.tableBody[exerciseId]).length
                    : 0;
                  return (
                    table.tableBody[exerciseId] &&
                    Object.keys(table.tableBody[exerciseId]).map(
                      (setName, setIndex) => {
                        return (
                          <tr key={`row-${exerciseName}-${setName}`}>
                            {setIndex === 0 &&
                              exerciseCard(
                                table,
                                setNumber,
                                exerciseId,
                                exerciseName
                              )}

                            {!selectedExercise &&
                              setCard(setNumber, setName, exerciseName)}

                            {!selectedExercise &&
                              table.dates.map((date, dateIndex) =>
                                dataCard(
                                  exerciseId,
                                  exerciseName,
                                  setName,
                                  date,
                                  setNumber,
                                  dateIndex,
                                  setIndex
                                )
                              )}
                          </tr>
                        );
                      }
                    )
                  );
                }
              )}
            </tbody>
          </table>
        </div>
      </div>
      {selectedExercise && (
        <TrainingProgressChart
          selectedExercise={selectedExercise}
          isExerciseHiddenWeight={
            table.hiddenWeightExercises?.[selectedExercise?.name]
          }
          clientWorkoutHistory={clientWorkoutHistory}
          closeChart={() => setSelectedExercise(null)}
        />
      )}
    </div>
  );
};
export default TrainingProgressTable;
