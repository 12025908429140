import { gql } from "apollo-boost";
import {
  clientGymStepTemplatesFields,
  clientGymStepTemplatesSelectors,
  commonGymStepFields,
} from "../shared";

export const REORDER_CLIENT_WORKOUT_TEMPLATES = gql`
  mutation ReorderClientWorkoutTemplates(
    $clientId: UUID
    $clientTrainingPlanId: UUID
    $clientWorkoutGroupTemplateId: UUID
    $clientWorkoutTemplateIds: [UUID]
  ) {
    reorderClientWorkoutTemplates(
      clientId: $clientId
      clientTrainingPlanId: $clientTrainingPlanId
      clientWorkoutGroupTemplateId: $clientWorkoutGroupTemplateId
      clientWorkoutTemplateIds: $clientWorkoutTemplateIds
    ) {
      clientWorkoutTemplates {
        id
      }
      clientWorkoutGroupTemplates {
        id
        clientWorkoutTemplates {
          id
        }
      }
    }
  }
`;
export const REORDER_CLIENT_GYM_STEP_TEMPLATES = gql`
  ${clientGymStepTemplatesFields}
  mutation ReorderClientGymStepTemplates(
    $clientId: UUID
    $clientGymStepTemplateIds: [UUID]
    $clientWorkoutTemplateId: UUID
  ){
    reorderClientGymStepTemplates(
      clientId: $clientId
      clientGymStepTemplateIds: $clientGymStepTemplateIds
      clientWorkoutTemplateId: $clientWorkoutTemplateId
    ){
      gymSteps {
        ${clientGymStepTemplatesSelectors}
      }
    }
  }
`;
export const ADD_CLIENT_GYM_STEP_TEMPLATE = gql`
  ${clientGymStepTemplatesFields}
  mutation AddClientGymStepTemplate(
    $clientId: UUID
    $clientWorkoutTemplateId: UUID
    $exerciseId: UUID
    $position: Int
  ){
    addClientGymStepTemplate(
      clientId: $clientId
      clientWorkoutTemplateId: $clientWorkoutTemplateId
      exerciseId: $exerciseId
      position: $position
    ){
      gymSteps {
        ${clientGymStepTemplatesSelectors}
      }
    }
  }
`;
export const ADD_CLIENT_WORKOUT_TEMPLATE = gql`
  mutation AddClientWorkoutTemplate(
    $clientId: UUID
    $clientTrainingPlanId: UUID
    $clientWorkoutGroupTemplateId: UUID
  ) {
    addNewClientWorkoutTemplate(
      clientId: $clientId
      clientTrainingPlanId: $clientTrainingPlanId
      clientWorkoutGroupTemplateId: $clientWorkoutGroupTemplateId
    ) {
      clientWorkoutTemplates {
        id
      }
      clientWorkoutGroupTemplates {
        id
        clientWorkoutTemplates {
          id
        }
      }
    }
  }
`;
export const DELETE_CLIENT_WORKOUT_TEMPLATE = gql`
  mutation DeleteClientWorkoutTemplate(
    $clientId: UUID
    $clientWorkoutTemplateId: UUID
  ) {
    deleteClientWorkoutTemplate(
      clientId: $clientId
      clientWorkoutTemplateId: $clientWorkoutTemplateId
    )
  }
`;
export const DELETE_CLIENT_GYM_STEP_TEMPLATE = gql`
  mutation DeleteClientGymStepTemplate(
    $clientId: UUID
    $clientGymStepTemplateId: UUID
  ) {
    deleteClientGymStepTemplate(
      clientId: $clientId
      clientGymStepTemplateId: $clientGymStepTemplateId
    )
  }
`;

export const UPDATE_CLIENT_WORKOUT_TEMPLATE = gql`
  mutation UpdateClientWorkoutTemplate(
    $clientId: UUID
    $clientWorkoutTemplateId: UUID
    $instructions: String
    $title: String
    $thumbnailImageMediaUrlId: UUID
  ) {
    updateClientWorkoutTemplate(
      clientId: $clientId
      clientWorkoutTemplateId: $clientWorkoutTemplateId
      instructions: $instructions
      title: $title
      thumbnailImageMediaUrlId: $thumbnailImageMediaUrlId
    ) {
      id
      title
      instructions
      thumbnailImage {
        id
        url
      }
    }
  }
`;

export const UPDATE_CLIENT_GYM_STEP_TEMPLATE = gql`
  mutation UpdateClientGymStepTemplate(
    $clientId: UUID
    $clientGymStepTemplateId: UUID
    $target: String
    $supersetTag: SupersetTagType
    $targetRepsLowerBound: Int
    $targetRepsUpperBound: Int
    $targetSets: Int
    $targetWeight: Float
    $targetTime: Int
    $hideWeightForExerciseSets: Boolean
    $generateSmartWarmupSets: Boolean
    $smartWarmupSetsCount: Int
    $restPeriod: Int
  ){
    updateClientGymStepTemplate(
      clientId: $clientId
      clientGymStepTemplateId: $clientGymStepTemplateId
      target: $target
      supersetTag: $supersetTag
      targetRepsLowerBound: $targetRepsLowerBound
      targetRepsUpperBound: $targetRepsUpperBound
      targetSets: $targetSets
      targetWeight: $targetWeight
      targetTime: $targetTime
      hideWeightForExerciseSets: $hideWeightForExerciseSets
      generateSmartWarmupSets: $generateSmartWarmupSets
      smartWarmupSetsCount: $smartWarmupSetsCount
      restPeriod: $restPeriod
    ){
      ${commonGymStepFields}
    }
  }
`;

export const UPDATE_CLIENT_TRAINING_PLAN = gql`
  mutation UpdateClientTrainingPlan(
    $clientId: UUID
    $clientTrainingPlanId: UUID
    $name: String
  ) {
    updateClientTrainingPlan(
      clientId: $clientId
      clientTrainingPlanId: $clientTrainingPlanId
      name: $name
    ) {
      id
      name
    }
  }
`;

export const TOGGLE_ACTIVATION_CLIENT_TRAINING_PLAN = gql`
  mutation UpdateClientTrainingPlanState(
    $clientId: UUID
    $clientTrainingPlanId: UUID
  ) {
    updateClientTrainingPlanState(
      clientId: $clientId
      clientTrainingPlanId: $clientTrainingPlanId
    ) {
      id
      active
    }
  }
`;
export const DELETE_CLIENT_TRAINING_PLAN = gql`
  mutation DeleteClientTrainingPlan(
    $clientId: UUID
    $clientTrainingPlanId: UUID
  ) {
    deleteClientTrainingPlan(
      clientId: $clientId
      clientTrainingPlanId: $clientTrainingPlanId
    )
  }
`;

export const clientTrainingPlanFragment = gql`
  ${clientGymStepTemplatesFields}
  fragment ClientTrainingPlanProperty on ClientTrainingPlan{
    id
    name
    active
    clientWorkoutGroupTemplates{
      id
      title
      default
      workoutGroupType
      createdByTrainer
      clientWorkoutTemplates{
        id
        instructions
        title
        sortOrder
        thumbnailImage {
          id
          url
        }
        clientWorkoutGroupTemplate {
          id
          title
          default
          workoutGroupType
        }
        gymSteps {
          ${clientGymStepTemplatesSelectors}
        }
      }
    }
    
  }
`;

export const REORDER_CLIENT_WORKOUT_GROUP = gql`
  ${clientTrainingPlanFragment}
  mutation ReorderClientWorkoutGroup(
    $clientId: UUID
    $clientTrainingPlanId: UUID
    $clientWorkoutGroupTemplateIds: [UUID]
  ) {
    reorderClientWorkoutGroupTemplates(
      clientId: $clientId
      clientTrainingPlanId: $clientTrainingPlanId
      clientWorkoutGroupTemplateIds: $clientWorkoutGroupTemplateIds
    ) {
      ...ClientTrainingPlanProperty
    }
  }
`;

export const MOVE_CLIENT_WORKOUT_TEMPLATE = gql`
  ${clientTrainingPlanFragment}
  mutation MoveClientWorkoutTemplate(
    $clientId: UUID
    $clientWorkoutTemplateId: UUID
    $clientWorkoutTemplateIds: [UUID]
    $destinationClientWorkoutGroupTemplateId: UUID
  ) {
    moveClientWorkoutTemplate(
      clientId: $clientId
      clientWorkoutTemplateId: $clientWorkoutTemplateId
      clientWorkoutTemplateIds: $clientWorkoutTemplateIds
      destinationClientWorkoutGroupTemplateId: $destinationClientWorkoutGroupTemplateId
    ) {
      ...ClientTrainingPlanProperty
    }
  }
`;

export const GET_CLIENT_TRAINING_PLANS = gql`
  ${clientTrainingPlanFragment}
  query GetClientTrainingPlans($clientId: UUID) {
    clientTrainingPlans(clientId: $clientId) {
      ...ClientTrainingPlanProperty
    }
  }
`;
export const GET_CLIENT_TRAINING_PLAN_LAST_UPDATED = gql`
  query GetClientTrainingPlanLastUpdated(
    $clientId: UUID
    $clientTrainingPlanId: UUID
  ) {
    clientTrainingPlanLastUpdatedDate(
      clientId: $clientId
      clientTrainingPlanId: $clientTrainingPlanId
    )
  }
`;
export const ASSIGN_MASTER_TRAINING_PLAN = gql`
  ${clientTrainingPlanFragment}
  mutation AssignMasterTrainingPlan(
    $clientId: UUID
    $masterTrainingPlanId: UUID
  ) {
    assignMasterTrainingPlan(
      clientId: $clientId
      masterTrainingPlanId: $masterTrainingPlanId
    ) {
      ...ClientTrainingPlanProperty
    }
  }
`;
export const ASSIGN_CUSTOM_TRAINING_PLAN = gql`
  ${clientTrainingPlanFragment}
  mutation AssignCustomTrainingPlan(
    $clientId: UUID
    $customTrainingPlanId: UUID
  ) {
    assignCustomTrainingPlan(
      clientId: $clientId
      customTrainingPlanId: $customTrainingPlanId
    ) {
      ...ClientTrainingPlanProperty
    }
  }
`;

export const COPY_MASTER_WORKOUT_GROUP_TO_CLIENT = gql`
  ${clientTrainingPlanFragment}
  mutation CopyMasterWorkoutGroupToClient(
    $clientId: UUID
    $clientTrainingPlanId: UUID
    $masterWorkoutGroupId: UUID
    $title: String
    $workoutGroupType: WorkoutGroupType
  ) {
    copyMasterWorkoutGroupToClientWorkoutGroupTemplate(
      clientId: $clientId
      clientTrainingPlanId: $clientTrainingPlanId
      masterWorkoutGroupId: $masterWorkoutGroupId
      title: $title
      workoutGroupType: $workoutGroupType
    ) {
      ...ClientTrainingPlanProperty
    }
  }
`;

export const CREATE_NEW_CLIENT_TRAINING_PLAN = gql`
  ${clientTrainingPlanFragment}
  mutation CreateNewClientTrainingPlan($clientId: UUID, $name: String) {
    createNewClientTrainingPlan(clientId: $clientId, name: $name) {
      ...ClientTrainingPlanProperty
    }
  }
`;

export const ADD_NEW_CLIENT_WORKOUT_GROUP = gql`
  ${clientTrainingPlanFragment}
  mutation AddNewClientWorkoutGroup(
    $clientId: UUID
    $clientTrainingPlanId: UUID
    $title: String
    $workoutGroupType: WorkoutGroupType
  ) {
    addNewClientWorkoutGroupTemplate(
      clientId: $clientId
      clientTrainingPlanId: $clientTrainingPlanId
      title: $title
      workoutGroupType: $workoutGroupType
    ) {
      ...ClientTrainingPlanProperty
    }
  }
`;

export const COPY_TRAINING_PLAN_TO_CLIENTS = gql`
  mutation CopyTrainingPlanToClients(
    $clientId: UUID
    $clientIds: [UUID]
    $trainingPlanId: UUID
  ) {
    copyTrainingPlanToClients(
      clientId: $clientId
      clientIds: $clientIds
      trainingPlanId: $trainingPlanId
    )
  }
`;

export const UPDATE_CLIENT_WORKOUT_GROUP_TEMPLATE = gql`
  mutation UpdateClientWorkoutGroupTemplate(
    $clientId: UUID
    $clientWorkoutGroupTemplateId: UUID
    $title: String
    $workoutGroupType: WorkoutGroupType
  ) {
    updateClientWorkoutGroupTemplate(
      clientId: $clientId
      clientWorkoutGroupTemplateId: $clientWorkoutGroupTemplateId
      title: $title
      workoutGroupType: $workoutGroupType
    ) {
      id
      title
      workoutGroupType
    }
  }
`;

export const SET_DEFAULT_WORKOUT_GROUP = gql`
  ${clientTrainingPlanFragment}
  mutation SetDefaultWorkoutGroup(
    $clientId: UUID
    $clientWorkoutGroupTemplateId: UUID
  ) {
    setDefaultWorkoutGroupForClientTrainingPlan(
      clientId: $clientId
      clientWorkoutGroupTemplateId: $clientWorkoutGroupTemplateId
    ) {
      ...ClientTrainingPlanProperty
    }
  }
`;

export const COPY_CLIENT_WORKOUT_TEMPLATE = gql`
  ${clientTrainingPlanFragment}
  mutation CopyClientWorkoutTemplate(
    $clientId: UUID
    $clientWorkoutTemplateId: UUID
    $destinationClientWorkoutGroupTemplateId: UUID
  ) {
    copyClientWorkoutTemplate(
      clientId: $clientId
      clientWorkoutTemplateId: $clientWorkoutTemplateId
      destinationClientWorkoutGroupTemplateId: $destinationClientWorkoutGroupTemplateId
    ) {
      ...ClientTrainingPlanProperty
    }
  }
`;

export const COPY_CLIENT_WORKOUT_GROUP_TEMPLATE = gql`
  ${clientTrainingPlanFragment}
  mutation CopyClientWorkoutGroupTemplate(
    $clientId: UUID
    $clientWorkoutGroupTemplateId: UUID
    $destinationClientTrainingPlanId: UUID
    $name: String
  ) {
    copyClientWorkoutGroupTemplate(
      clientId: $clientId
      clientWorkoutGroupTemplateId: $clientWorkoutGroupTemplateId
      destinationClientTrainingPlanId: $destinationClientTrainingPlanId
      name: $name
    ) {
      ...ClientTrainingPlanProperty
    }
  }
`;

export const GET_CLIENT_ACTIVE_TRAINING_PLANS = gql`
  ${clientTrainingPlanFragment}
  query ClientActiveTrainingPlan($clientId: UUID) {
    clientActiveTrainingPlan(clientId: $clientId) {
      ...ClientTrainingPlanProperty
    }
  }
`;
