import { CalendarItemType } from "../../../types/gqlTypes";
import {
  DebugToolsAction,
  DebugToolsState,
  GET_ACL_DATA_FOR_CLIENT_FAIL,
  GET_ACL_DATA_FOR_CLIENT_LOADING,
  GET_ACL_DATA_FOR_CLIENT_SUCCESS,
  GET_CLIENT_NUTRITION_COLUMNS_FOR_DEBUG_FAIL,
  GET_CLIENT_NUTRITION_COLUMNS_FOR_DEBUG_LOADING,
  GET_CLIENT_NUTRITION_COLUMNS_FOR_DEBUG_SUCCESS,
  GET_CLIENT_WEEK_NUTRITION_DATA_FOR_DEBUG_FAIL,
  GET_CLIENT_WEEK_NUTRITION_DATA_FOR_DEBUG_LOADING,
  GET_CLIENT_WEEK_NUTRITION_DATA_FOR_DEBUG_SUCCESS,
  RESET_DEBUG_TOOLS_STATE,
  SEND_REMINDER_NOTIFICATION_FAIL,
  SEND_REMINDER_NOTIFICATION_LOADING,
  SEND_REMINDER_NOTIFICATION_SUCCESS,
} from "../../actions/DebugTools/types";

const initialState: DebugToolsState = {
  isLoading: false,
  sentReminderResult: null,
  clientNutritionColumns: null,
  nutritionCalendarData: null,
  aclData: null,
};

const debugToolsState = (state = initialState, action: DebugToolsAction) => {
  switch (action.type) {
    case RESET_DEBUG_TOOLS_STATE:
      return {
        ...state,
        isLoading: false,
        sentReminderResult: null,
        clientNutritionColumns: null,
      };
    case GET_CLIENT_NUTRITION_COLUMNS_FOR_DEBUG_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_CLIENT_NUTRITION_COLUMNS_FOR_DEBUG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        clientNutritionColumns: action.client,
      };
    case GET_CLIENT_NUTRITION_COLUMNS_FOR_DEBUG_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case SEND_REMINDER_NOTIFICATION_LOADING:
      return {
        ...state,
        isLoading: true,
        sentReminderResult: null,
      };
    case SEND_REMINDER_NOTIFICATION_SUCCESS:
      return {
        ...state,
        sentReminderResult: action.sentReminderResult,
        isLoading: false,
      };
    case SEND_REMINDER_NOTIFICATION_FAIL:
      return {
        ...state,
        isLoading: false,
        sentReminderResult: "fail",
      };
    case GET_CLIENT_WEEK_NUTRITION_DATA_FOR_DEBUG_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case GET_CLIENT_WEEK_NUTRITION_DATA_FOR_DEBUG_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_CLIENT_WEEK_NUTRITION_DATA_FOR_DEBUG_SUCCESS: {
      const nutritionCalendarData = [];
      action.calendarData?.forEach((day, index) => {
        nutritionCalendarData.push({ date: day.date, items: [] });
        day?.items?.forEach((item) => {
          if (item?.enumType === CalendarItemType.ImportedNutrition) {
            nutritionCalendarData?.[index]?.items?.push(item);
          }
        });
      });
      return {
        ...state,
        isLoading: false,
        nutritionCalendarData,
      };
    }
    case GET_ACL_DATA_FOR_CLIENT_LOADING:
      return {
        ...state,
        isLoading: true,
        aclData: null,
      };
    case GET_ACL_DATA_FOR_CLIENT_SUCCESS:
      return {
        ...state,
        aclData: action.aclData,
        isLoading: false,
      };
    case GET_ACL_DATA_FOR_CLIENT_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default debugToolsState;
