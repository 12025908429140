import React from "react";
import { connect } from "react-redux";
import { StoreState } from "../../../redux/reducers";
import {
  DashboardTypes,
  StrengthBalanceInfos,
} from "../../../models/strengthscore";
import {
  StrengthScoreLight,
  StrengthScoreMuscleGroupType,
} from "../../../types/gqlTypes";
import StrengthChart from "../../StrengthChart";
import { muscleTitles } from "../DetailsPanel";
import colors from "../../../assets/colors";

interface OwnProps {
  muscleType: StrengthScoreMuscleGroupType;
}
interface Props extends OwnProps {
  historicalStrengthScoreMuscleGroup: StrengthScoreLight[];
}

const MuscleDetailsChart = (props: Props) => {
  const { muscleType, historicalStrengthScoreMuscleGroup } = props;

  let title = `${muscleTitles?.[muscleType]} Strength Score`;
  let legendText = "Strength Score";
  if (muscleType === StrengthScoreMuscleGroupType.Weight) {
    title = "Weight";
    legendText = "Weight";
  }

  return (
    <div className="d-flex flex-column">
      <div
        className="d-flex align-items-center bold"
        style={{
          fontSize: "14px",
          lineHeight: "17px",
          letterSpacing: "-0.29px",
          color: colors.caliber_gray_text,
          height: 64,
          marginTop: 15,
        }}
      >
        {title}
      </div>
      <div
        style={{
          width: 366,
        }}
      >
        <StrengthChart
          legendText={legendText}
          areaColor={StrengthBalanceInfos[0].areaColor}
          lineColor={StrengthBalanceInfos[0].lineColor}
          strengthType={DashboardTypes.score}
          historicalStrengthScore={historicalStrengthScoreMuscleGroup}
        />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  muscleType: ownProps.muscleType,
  historicalStrengthScoreMuscleGroup:
    state.clientStrengthDashboardState.historicalStrengthScoreMuscleGroup,
});

export default connect(mapStateToProps, mapDispatchToProps)(MuscleDetailsChart);
