import {
  CaliberPageImpl_Manager,
  MutationCreateUserProfileArgs,
  MutationUpdateUserProfileArgs,
  UserStatusType,
  UserType,
} from "../../../types/gqlTypes";
import apolloClient from "../../../api/apolloClient";
import {
  ADMIN_MANAGER_CREATING_FAIL,
  ADMIN_MANAGER_CREATING_SAVING,
  ADMIN_MANAGER_CREATING_SUCCESS,
  RESET_CREATE_MANAGER_TRAINER_STATE,
  ADMIN_MANAGER_UPDATING_FAIL,
  ADMIN_MANAGER_UPDATING_SAVING,
  ADMIN_MANAGER_UPDATING_SUCCESS,
  AdminCreateUpdateManagerAction,
  AdminSearchManagersAction,
  ADMIN_MANAGERS_SEARCH_FAIL,
  ADMIN_MANAGERS_SEARCH_LOADING,
  ADMIN_MANAGERS_SEARCH_SUCCESS,
  ADMIN_MANAGER_RESET_SEARCH,
} from "./types";
import {
  CREATE_MANAGER,
  SEARCH_MANAGERS,
  UPDATE_MANAGER,
} from "../../../api/gql/users/managers";
import {
  AdminCreateUpdateTrainerAction,
  ADMIN_TRAINER_RESET_SEARCH,
} from "../AdminTeamTrainers/types";

export const createManager =
  (args: MutationCreateUserProfileArgs) => async (dispatch, getState) => {
    if (getState().adminCreateUpdateManagerState.isSaving) {
      return;
    }
    dispatch({
      type: ADMIN_MANAGER_CREATING_SAVING,
    } as AdminCreateUpdateManagerAction);
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      await client.mutate({
        mutation: CREATE_MANAGER,
        variables: args,
      });

      dispatch({
        type: ADMIN_MANAGER_CREATING_SUCCESS,
      } as AdminCreateUpdateManagerAction);
    } catch (error) {
      console.error("Create Manager failed", error);
      dispatch({
        type: ADMIN_MANAGER_CREATING_FAIL,
        data: error,
        message: "The manager has not been created.",
      });
    }
  };

export const resetAdminCreateManagerTrainerState = () => (dispatch) => {
  dispatch({
    type: RESET_CREATE_MANAGER_TRAINER_STATE,
  } as AdminCreateUpdateManagerAction);
};

export const resetSearch = (role: UserType) => (dispatch) => {
  switch (role) {
    case UserType.Manager:
      dispatch({
        type: ADMIN_MANAGER_RESET_SEARCH,
      } as AdminCreateUpdateManagerAction);
      break;
    case UserType.Trainer:
      dispatch({
        type: ADMIN_TRAINER_RESET_SEARCH,
      } as AdminCreateUpdateTrainerAction);
      break;
  }
};

export const editManager =
  (args: MutationUpdateUserProfileArgs) => async (dispatch, getState) => {
    if (getState().adminCreateUpdateManagerState.isSaving) {
      return;
    }
    dispatch({
      type: ADMIN_MANAGER_UPDATING_SAVING,
    } as AdminCreateUpdateManagerAction);
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const result = (
        await client.mutate({
          mutation: UPDATE_MANAGER,
          variables: args,
        })
      ).data.updateUserProfile;
      dispatch({
        type: ADMIN_MANAGER_UPDATING_SUCCESS,
        manager: result,
      } as AdminCreateUpdateManagerAction);
    } catch (error) {
      console.error("Update Manager failed", error);
      dispatch({
        type: ADMIN_MANAGER_UPDATING_FAIL,
        data: error,
        message: "The manager has not been updated.",
      } as AdminCreateUpdateManagerAction);
    }
  };

export const searchManagers =
  (page: number, searchCriteria: string, statusCriteria: UserStatusType[]) =>
  async (dispatch, getState) => {
    if (getState().adminSearchManagersState.isLoading) {
      return;
    }
    dispatch({
      type: ADMIN_MANAGERS_SEARCH_LOADING,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const result = (
        await client.query({
          query: SEARCH_MANAGERS,
          variables: {
            page,
            searchCriteria,
            statusCriteria,
          },
        })
      ).data.searchManagers as CaliberPageImpl_Manager;

      dispatch({
        type: ADMIN_MANAGERS_SEARCH_SUCCESS,
        data: result,
      } as AdminSearchManagersAction);
    } catch (error) {
      console.error("Search Managers failed", error);
      dispatch({
        type: ADMIN_MANAGERS_SEARCH_FAIL,
        data: error,
      });
    }
  };
