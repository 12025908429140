import React from "react";
import colors from "../../../assets/colors";
import { ClientsTabColumnsType } from "../../../redux/actions/ClientsTab/types";
import { ClientSearchResult } from "../../../types/gqlTypes";

interface Props {
  isLastColumn?: boolean;
  isDragging?: boolean;
  width?: number;
  column: ClientsTabColumnsType;
  row: ClientSearchResult;
}

const StrengthScoreCell = (props: Props) => {
  const { isLastColumn, isDragging, width, column, row } = props;

  const color = colors.black;
  const borderColor = colors.table_gray;
  const rowHeight = 64;

  const keys = {
    [ClientsTabColumnsType.strengthScore]: "strengthScoreDelta",
    [ClientsTabColumnsType.strengthBalance]: "strengthBalanceDelta",
    [ClientsTabColumnsType.armsMuscleGroupScore]: "armsMuscleGroupScoreDelta",
    [ClientsTabColumnsType.backMuscleGroupScore]: "backMuscleGroupScoreDelta",
    [ClientsTabColumnsType.legsMuscleGroupScore]: "legsMuscleGroupScoreDelta",
    [ClientsTabColumnsType.chestMuscleGroupScore]: "chestMuscleGroupScoreDelta",
    [ClientsTabColumnsType.shouldersMuscleGroupScore]:
      "shouldersMuscleGroupScoreDelta",
  };

  let value = row?.[column];
  let delta = `${Math.abs(Math.round(row?.[keys[column]]))} pts`;
  let isRed = Math.round(row?.[keys[column]]) < 0;

  if (column === ClientsTabColumnsType.strengthBalance) {
    value = Math.round(row?.[column] * 100);
    delta = `${Math.abs(Math.round(row?.[keys[column]] * 100))}%`;
    isRed = Math.round(row?.[keys[column]] * 100) < 0;
  }

  return (
    <td
      className="d-flex"
      style={{
        width,
        minWidth: isLastColumn && width,
        backgroundColor: colors.caliber_gray_bg,
        position: "relative",
      }}
    >
      <div
        className="d-flex"
        style={{
          position: "absolute",
          top: isDragging ? -8 : 0,
          backgroundColor: isDragging ? "#E8EAEE" : "transparent",
          width: "100%",
          height: isDragging ? rowHeight + 16 : rowHeight,
          zIndex: isDragging ? 4 : 1,
          marginBottom: isDragging ? 0 : 8,
        }}
      >
        <div
          className="d-flex align-items-center"
          style={{
            flex: "1 1 100%",
            fontSize: "14px",
            lineHeight: "20px",
            color,
            zIndex: isDragging ? 3 : 2,
            backgroundColor: colors.caliber_white,
            height: rowHeight,
            borderRight: `1px solid ${borderColor}`,
            paddingLeft: 18,
            margin: isDragging ? 8 : 0,
            borderTopRightRadius: isLastColumn && 8,
            borderBottomRightRadius: isLastColumn && 8,

            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          <div>{`${value || 0}`}</div>

          <div
            style={{
              marginLeft: 10,
              color: isRed ? "#D23A2D" : colors.caliber_green_200,
            }}
          >
            {`${isRed ? "-" : "+"}${delta}`}
          </div>
        </div>
      </div>
    </td>
  );
};

export default StrengthScoreCell;
