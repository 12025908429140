import { ClientNutritionTarget } from "../../../types/gqlTypes";

export const SAVING_CLIENT_NUTRITION_TARGET_SUCCESS =
  "SAVING_CLIENT_NUTRITION_TARGET_SUCCESS";
export const SAVING_CLIENT_NUTRITION_TARGET_FAIL =
  "SAVING_CLIENT_NUTRITION_TARGET_FAIL";
export const SAVING_CLIENT_NUTRITION_TARGET_SAVING =
  "SAVING_CLIENT_NUTRITION_TARGET_SAVING";

export const GET_CLIENT_NUTRITION_TARGET_SUCCESS =
  "GET_CLIENT_NUTRITION_TARGET_SUCCESS";
export const GET_CLIENT_NUTRITION_TARGET_FAIL =
  "GET_CLIENT_NUTRITION_TARGET_FAIL";
export const GET_CLIENT_NUTRITION_TARGET_LOADING =
  "GET_CLIENT_NUTRITION_TARGET_LOADING";

export interface ClientNutritionTargetState {
  isSaving: boolean;
  isLoading: boolean;
  clientNutritionTarget: ClientNutritionTarget;
}

export interface ClientNutritionTargetAction {
  type: string;
  data?: ClientNutritionTarget;
  clientNutritionTarget?: ClientNutritionTarget;
}
