import React from "react";
import colors from "../../assets/colors";
import { ClientSearchResult, ClientTrainingPlan } from "../../types/gqlTypes";
import ButtonTag from "../ButtonTag";

interface Props {
  clients: ClientSearchResult[];
  plan: ClientTrainingPlan;
  onCopy: () => void;
  onCancel: () => void;
}

const CopyPlanToClientsConfirm = (props: Props) => {
  const { clients, plan, onCopy, onCancel } = props;

  const listItemStyle = {
    fontSize: "16px",
    lineHeight: "24px",
    color: colors.caliber_secondary,
    margin: 5,
  };

  return (
    <div
      className="d-flex flex-column"
      style={{
        minHeight: 250,
        width: 520,
        padding: 25,
      }}
    >
      <div
        className="d-flex flex-column justify-content-center align-items-center medium-bold"
        style={{
          color: colors.caliber_secondary,
          fontSize: "18px",
          lineHeight: "24px",
        }}
      >
        Are you sure you want to copy
      </div>
      <div
        className="d-flex flex-column justify-content-center align-items-center medium-bold"
        style={{
          color: colors.caliber_gray_text,
          fontSize: "18px",
          lineHeight: "24px",
          marginBottom: 40,
        }}
      >
        {plan?.name} to
      </div>
      <ul className="d-flex flex-column justify-content-center align-items-center">
        {clients.map((client) => {
          return (
            client && (
              <li>
                <div className="bold" style={listItemStyle}>
                  {client?.firstName} {client?.lastName}
                </div>
              </li>
            )
          );
        })}
      </ul>
      <div
        className="d-flex justify-content-center"
        style={{
          marginTop: 40,
        }}
      >
        <ButtonTag
          margin="0px 12px"
          text="Cancel"
          onClick={onCancel}
          height={24}
          bgColor={colors.caliber_gray_text}
          textColor={colors.caliber_white}
          minWidth={80}
        />
        <ButtonTag
          text="Copy"
          onClick={onCopy}
          height={24}
          bgColor={colors.caliber_green_200}
          textColor={colors.caliber_white}
          minWidth={80}
        />
      </div>
    </div>
  );
};

export default CopyPlanToClientsConfirm;
