import React, { useState } from "react";
import colors from "../../assets/colors";
import { ClientHabitTarget, HabitType } from "../../types/gqlTypes";
import { numberWithCommas } from "../../utils";
import ConfirmationDialog from "../ConfirmationDialog";
import CalendarIcons, { CalendarIconType } from "../Icons/CalendarIcons";
import MeatballsMenu from "../MeatballsMenu";

interface Props {
  habit: ClientHabitTarget;
  onEdit: () => void;
  onDelete: () => void;
}

const HabitCard = (props: Props) => {
  const { habit, onDelete, onEdit } = props;
  const [confirmDelete, setConfirmDelete] = useState(false);

  const iconStyle: React.CSSProperties = {
    marginRight: 16,
  };
  const meatballStyle: React.CSSProperties = {
    marginBottom: 24,
  };
  const titleStyle: React.CSSProperties = {
    fontSize: "18px",
    lineHeight: "22px",
    letterSpacing: "-0.022em",
    color: colors.black,
  };
  const subTitleStyle: React.CSSProperties = {
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "-0.022em",
    color: colors.caliber_gray_text,
    marginTop: 16,
  };
  const targetTitleStyle: React.CSSProperties = {
    fontSize: "14px",
    lineHeight: "17px",
    color: colors.caliber_secondary_gray_11,
    marginTop: 16,
  };
  const labelStyle: React.CSSProperties = {
    backgroundColor: colors.caliber_secondary_gray_11,
    borderRadius: 6,
    fontSize: "12px",
    lineHeight: "16px",
    color: colors.caliber_secondary,
    marginTop: 10,
    padding: "2px 8px",
  };

  if (habit?.habitType === HabitType.Steps) {
    return (
      <div
        className="d-flex flex-column"
        style={{
          width: 376,
          height: 197,
          backgroundColor: colors.caliber_white,
          borderRadius: 8,
          padding: "24px 18px 32px 16px",
        }}
      >
        {confirmDelete && (
          <ConfirmationDialog
            show
            cancelButtonText="Cancel"
            text="Are you sure want to delete this habit?"
            onConfirm={onDelete}
            onCancel={() => setConfirmDelete(false)}
          />
        )}

        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex">
            <div style={iconStyle}>
              <CalendarIcons
                width={48}
                height={48}
                type={CalendarIconType.STEPS_HABIT_LARGE}
              />
            </div>
            <div
              className="d-flex align-items-center medium-bold"
              style={titleStyle}
            >
              Steps
            </div>
          </div>
          <div style={meatballStyle}>
            <MeatballsMenu
              items={[
                { text: "Edit", onClick: onEdit },
                { text: "Delete", onClick: () => setConfirmDelete(true) },
              ]}
            />
          </div>
        </div>

        <div className="medium-bold" style={subTitleStyle}>
          {`Your daily step target is ${numberWithCommas(
            habit?.target
          )} steps.`}
        </div>

        <div className="medium-bold" style={targetTitleStyle}>
          Target
        </div>

        <div className="d-flex">
          <div className="medium-bold" style={labelStyle}>
            {`${numberWithCommas(habit?.target)} / day`}
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default HabitCard;
