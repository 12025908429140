import React, { useEffect, useRef, useState } from "react";
import colors from "../../../assets/colors";
import { useDebounce } from "../../../utils/customHooks";
import FilterIcon from "../../../assets/images/FilterIcon.svg";
import FilterIconSelected from "../../../assets/images/FilterIconSelected.svg";

export enum ButtonState {
  AddExercise,
  EditExercise,
  SaveExercise,
  SaveExerciseDisabled,
}

interface ButtonData {
  text: string;
  action?: () => void;
}

interface Props {
  isFilterOn?: boolean;
  viewOnly?: boolean;
  buttonState: ButtonState;
  onSearchTextEntered: (text: string) => void;
  onAddExercise: () => void;
  onEditExercise: () => void;
  onSaveExercise: () => void;
  onCancelEditing: () => void;
  onFilterToggleClicked: () => void;
}

const ExerciseLibraryHeader = (props: Props) => {
  const {
    isFilterOn,
    buttonState,
    viewOnly,
    onSearchTextEntered,
    onAddExercise,
    onEditExercise,
    onSaveExercise,
    onCancelEditing,
    onFilterToggleClicked,
  } = props;

  const inputFocus = useRef(null);
  const [queryString, setQueryString] = useState("");
  const debouncedSearchValue = useDebounce(queryString, 400);
  useEffect(() => {
    onSearchTextEntered(debouncedSearchValue);
  }, [debouncedSearchValue]);

  let buttonData: ButtonData;
  let isEditing = false;

  switch (buttonState) {
    case ButtonState.AddExercise:
      buttonData = {
        action: onAddExercise,
        text: "Add New Exercise",
      };
      break;
    case ButtonState.EditExercise:
      buttonData = {
        action: onEditExercise,
        text: "Edit Exercise",
      };
      break;
    case ButtonState.SaveExercise:
      buttonData = {
        action: onSaveExercise,
        text: "Save Exercise",
      };
      isEditing = true;
      break;
    case ButtonState.SaveExerciseDisabled:
      buttonData = {
        text: "Save Exercise",
      };
      isEditing = true;
      break;
  }

  const buttonStyle = {
    padding: "1px 10px",
    borderRadius: 4,
    backgroundColor: colors.caliber_green_200,
    color: colors.caliber_white,
    height: 32,
    width: 134,
  };

  return (
    <div
      className="d-flex"
      style={{
        margin: 24,
      }}
    >
      <div
        className="d-flex"
        style={{
          position: "relative",
          flex: 1,
        }}
      >
        <input
          ref={inputFocus}
          className="d-flex bordered-item heading-small search-input "
          style={{
            border: `1px solid ${colors.caliber_secondary_gray_5}`,
            height: 48,
            marginRight: 8,
            backgroundColor: colors.caliber_secondary_gray_5,
            flex: 1,
          }}
          value={queryString}
          onChange={(event) => setQueryString(event.target.value)}
          placeholder="Search Exercises"
        />

        <div
          className="d-flex align-items-center pointer nofocus"
          role="button"
          tabIndex={0}
          onClick={onFilterToggleClicked}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              onFilterToggleClicked();
            }
          }}
        >
          <img
            alt="Filter"
            src={isFilterOn ? FilterIconSelected : FilterIcon}
          />
        </div>
      </div>
      <div
        className={`d-flex justify-content-${isFilterOn ? "between" : "end"}`}
        // className={`d-flex ${!isFilterOn && 'flex-row-reverse'}`}
        style={{
          flex: 1,
        }}
      >
        {isFilterOn && (
          <div
            className="heading-small"
            style={{
              color: colors.caliber_secondary,
              paddingTop: 12,
              marginLeft: 30,
            }}
          >
            Filters
          </div>
        )}
        {!viewOnly && buttonData && (
          <div className="d-flex flex-row">
            {buttonData.action ? (
              <div
                className="d-flex align-self-center pointer paragraph-small align-items-center justify-content-center"
                role="button"
                tabIndex={0}
                style={buttonStyle}
                onClick={buttonData.action}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    buttonData.action();
                  }
                }}
              >
                {buttonData.text}
              </div>
            ) : (
              <div
                className="d-flex align-self-center paragraph-small align-items-center justify-content-center"
                style={{
                  ...buttonStyle,
                  backgroundColor: colors.caliber_gray_12,
                }}
              >
                {buttonData.text}
              </div>
            )}
            {isEditing && (
              <div
                className="d-flex align-self-center pointer paragraph-small align-items-center justify-content-center"
                role="button"
                tabIndex={0}
                style={{
                  ...buttonStyle,
                  backgroundColor: colors.caliber_gray_12,
                  marginLeft: 12,
                }}
                onClick={onCancelEditing}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onCancelEditing();
                  }
                }}
              >
                Cancel
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ExerciseLibraryHeader;
