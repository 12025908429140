import React from "react";
import colors from "../../../assets/colors";
import { BodyMeasurementType } from "../../../types/gqlTypes";
import SimpleButton from "../../SimpleButton";
import CalendarIcons, { CalendarIconType } from "../../Icons/CalendarIcons";

interface Props {
  bodyStat: BodyMeasurementType;
  disabled?: boolean;
  onClick?: (value: BodyMeasurementType) => void;
}

const CategoryButton = (props: Props) => {
  const { bodyStat, disabled, onClick } = props;

  const categories = {
    [BodyMeasurementType.BodyWeight]: {
      text: "Body Weight",
      icon: <CalendarIcons type={CalendarIconType.BODY_WEIGHT} />,
    },
    [BodyMeasurementType.BodyFatPercent]: {
      text: "Body Fat",
      icon: <CalendarIcons type={CalendarIconType.BODY_FAT} />,
    },
    [BodyMeasurementType.WaistSize]: {
      text: "Waist",
      icon: <CalendarIcons type={CalendarIconType.WAIST} />,
    },
  };
  const category = categories[bodyStat];

  const greyRectStyle: React.CSSProperties = {
    position: "absolute",
    width: 110,
    height: 36,
    backgroundColor: colors.caliber_secondary_gray_5,
    borderRadius: "8px 8px 0 0",
    top: 8,
    left: 8,
    zIndex: 1,
  };
  const tagStyle: React.CSSProperties = {
    fontSize: "14px",
    lineHeight: "24px",
    color: colors.caliber_secondary,
    height: 32,
    width: 126,
    zIndex: 2,
  };
  const imageStyle: React.CSSProperties = {
    height: 64,
    width: 126,
    paddingTop: 9,
    zIndex: 2,
  };

  return (
    <SimpleButton
      nofocus
      nopointer={disabled}
      onClick={() => {
        if (!disabled && onClick) {
          onClick(bodyStat);
        }
      }}
      className="d-flex flex-column justify-content-center align-items-center"
      style={{
        position: "relative",
        height: 96,
        width: 126,
        borderRadius: 8,
        backgroundColor: colors.caliber_gray_bg,
        opacity: disabled ? 0.3 : 1,
      }}
    >
      <div style={greyRectStyle}>{/* grey rect */}</div>
      <div
        className="d-flex justify-content-center align-items-center"
        style={imageStyle}
      >
        {category.icon}
      </div>
      <div
        className="d-flex justify-content-center align-items-center"
        style={tagStyle}
      >
        {category.text}
      </div>
    </SimpleButton>
  );
};

export default CategoryButton;
