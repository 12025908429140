import {
  ExerciseEquipmentType,
  ExerciseLevelType,
  ExerciseType,
  MuscleGroupType,
  ExerciseTargetBaseType,
} from "../../types/gqlTypes";

export const exerciseTypeText = (type: ExerciseType): string => {
  switch (type) {
    case ExerciseType.Cardio:
      return "Cardio";
    case ExerciseType.Mobility:
      return "Mobility";
    case ExerciseType.Strength:
      return "Strength";
    case ExerciseType.Warmup:
      return "Warmup";
  }
  return "";
};

export const exerciseLevel = (level: ExerciseLevelType): string => {
  switch (level) {
    case ExerciseLevelType.Beginner:
      return "Beginner";
    case ExerciseLevelType.Advanced:
      return "Advanced";
  }
  return "";
};

export const exerciseBaseType = (baseType: ExerciseTargetBaseType): string => {
  switch (baseType) {
    case ExerciseTargetBaseType.Reps:
      return "Reps";
    case ExerciseTargetBaseType.Time:
      return "Time";
  }
  return "";
};

export const exerciseEquipment = (equipment: ExerciseEquipmentType): string => {
  switch (equipment) {
    case ExerciseEquipmentType.Assisted:
      return "Assisted";
    case ExerciseEquipmentType.Barbell:
      return "Barbell";
    case ExerciseEquipmentType.Bodyweight:
      return "Bodyweight";
    case ExerciseEquipmentType.Cable:
      return "Cable";
    case ExerciseEquipmentType.Dumbbell:
      return "Dumbbell";
    case ExerciseEquipmentType.EzBar:
      return "EZ Bar";
    case ExerciseEquipmentType.FoamRoller:
      return "Foam Roller";
    case ExerciseEquipmentType.HammerStrength:
      return "Hammer Strength";
    case ExerciseEquipmentType.Kettlebell:
      return "Kettlebell";
    case ExerciseEquipmentType.Landmine:
      return "Landmine";
    case ExerciseEquipmentType.Machine:
      return "Machine";
    case ExerciseEquipmentType.ResistanceBand:
      return "Resistance Band";
    case ExerciseEquipmentType.SmithMachine:
      return "Smith Machine";
    case ExerciseEquipmentType.TrapBar:
      return "Trap Bar";
    case ExerciseEquipmentType.Trx:
      return "TRX";
    case ExerciseEquipmentType.Weighted:
      return "Weighted";
  }
  return "";
};

export const exerciseMuscleGroupText = (
  muscleGroup: MuscleGroupType
): string => {
  switch (muscleGroup) {
    case MuscleGroupType.Abductors:
      return "Abductors";
    case MuscleGroupType.Abs:
      return "Abs";
    case MuscleGroupType.Adductors:
      return "Adductors";
    case MuscleGroupType.Biceps:
      return "Biceps";
    case MuscleGroupType.Brachialis:
      return "Brachialis";
    case MuscleGroupType.Calves:
      return "Calves";
    case MuscleGroupType.ErectorSpinae:
      return "Erector Spinae";
    case MuscleGroupType.Forearms:
      return "Forearms";
    case MuscleGroupType.FrontShoulders:
      return "Front Shoulders";
    case MuscleGroupType.Glutes:
      return "Glutes";
    case MuscleGroupType.Hamstrings:
      return "Hamstrings";
    case MuscleGroupType.HipFlexors:
      return "Hip Flexors";
    case MuscleGroupType.Infraspinatus:
      return "Infraspinatus";
    case MuscleGroupType.Lats:
      return "Lats";
    case MuscleGroupType.LowerBack:
      return "Lower Back";
    case MuscleGroupType.MidChest:
      return "Mid Chest";
    case MuscleGroupType.MidShoulders:
      return "Mid Shoulders";
    case MuscleGroupType.Obliques:
      return "Obliques";
    case MuscleGroupType.Quads:
      return "Quads";
    case MuscleGroupType.RearShoulders:
      return "Rear Shoulders";
    case MuscleGroupType.Rhomboids:
      return "Rhomboids";
    case MuscleGroupType.Subscapularis:
      return "Subscapularis";
    case MuscleGroupType.TeresMajor:
      return "Teres Major";
    case MuscleGroupType.TransverseAbdominis:
      return "Transverse Abdominis";
    case MuscleGroupType.Traps:
      return "Traps";
    case MuscleGroupType.Triceps:
      return "Triceps";
    case MuscleGroupType.UpperChest:
      return "Upper Chest";
  }
  return "";
};
