import { StoreState } from "..";
import { ProgramType } from "../../../components/TrainingProgram";
import {
  ClientGymStepTemplate,
  ClientTrainingPlan,
  ClientWorkoutGroupTemplate,
  ClientWorkoutTemplate,
  CustomTrainingPlan,
  GymStep,
  LibraryTrainingPlan,
  MasterGymStep,
  MasterTrainingPlan,
  MasterWorkout,
  Superset,
} from "../../../types/gqlTypes";

export const selectClientPlans = (state: StoreState) => {
  return state.trainingProgramsState.clientPlans;
};
export const selectShallowClientPlan = (
  state: StoreState
): ClientTrainingPlan => {
  const { clientPlanIndex } = state.trainingProgramsState;
  return selectClientPlans(state)?.[clientPlanIndex];
};
export const selectShallowCurrentPlan = (
  state: StoreState
): (
  | ClientTrainingPlan
  | CustomTrainingPlan
  | MasterTrainingPlan
  | LibraryTrainingPlan
)[] => {
  const {
    programType,
    customPlansContent,
    masterPlansContent,
    libraryPlansContent,
  } = state.trainingProgramsState;
  if (programType === ProgramType.Client) {
    return selectClientPlans(state);
  }
  if (programType === ProgramType.Custom) {
    return customPlansContent?.content;
  }
  if (programType === ProgramType.Library) {
    return libraryPlansContent?.content;
  }
  if (programType === ProgramType.Master) {
    return masterPlansContent?.content;
  }
  return null;
};
export const selectFullClientPlan = (state: StoreState): ClientTrainingPlan => {
  const { clientPlanIndex, cachedPlans } = state.trainingProgramsState;
  const planId = selectClientPlans(state)?.[clientPlanIndex]?.id;
  return cachedPlans?.[planId] as ClientTrainingPlan;
};
export const selectClientPlan = (state: StoreState): ClientTrainingPlan => {
  return selectFullClientPlan(state);
};
export const selectMasterOrCustomPlans = (
  state: StoreState
): (MasterTrainingPlan | CustomTrainingPlan | LibraryTrainingPlan)[] => {
  const {
    programType,
    customPlansContent,
    libraryPlansContent,
    masterPlansContent,
  } = state.trainingProgramsState;
  if (programType === ProgramType.Custom) {
    return customPlansContent?.content;
  }
  if (programType === ProgramType.Library) {
    return libraryPlansContent?.content;
  }
  if (programType === ProgramType.Master) {
    return masterPlansContent?.content;
  }
  return null;
};
export const selectShallowMasterOrCustomPlan = (
  state: StoreState
): MasterTrainingPlan | CustomTrainingPlan | LibraryTrainingPlan => {
  const {
    programType,
    planIndex,
    customPlansContent,
    libraryPlansContent,
    masterPlansContent,
  } = state.trainingProgramsState;
  if (programType === ProgramType.Custom) {
    return customPlansContent?.content?.[planIndex];
  }
  if (programType === ProgramType.Library) {
    return libraryPlansContent?.content?.[planIndex];
  }
  if (programType === ProgramType.Master) {
    return masterPlansContent?.content?.[planIndex];
  }
  return null;
};
export const selectFullMasterOrCustomPlan = (
  state: StoreState
): MasterTrainingPlan | CustomTrainingPlan | LibraryTrainingPlan => {
  const {
    programType,
    planIndex,
    customPlansContent,
    libraryPlansContent,
    masterPlansContent,
    cachedPlans,
  } = state.trainingProgramsState;
  if (programType === ProgramType.Custom) {
    const planId = customPlansContent?.content?.[planIndex]?.id;
    return cachedPlans?.[planId] as CustomTrainingPlan;
  }
  if (programType === ProgramType.Library) {
    const planId = libraryPlansContent?.content?.[planIndex]?.id;
    return cachedPlans?.[planId] as LibraryTrainingPlan;
  }
  if (programType === ProgramType.Master) {
    const planId = masterPlansContent?.content?.[planIndex]?.id;
    return cachedPlans?.[planId] as MasterTrainingPlan;
  }
  return null;
};
export const selectMasterOrCustomPlan = (
  state: StoreState
): MasterTrainingPlan | CustomTrainingPlan | LibraryTrainingPlan => {
  return (
    selectFullMasterOrCustomPlan(state) ||
    selectShallowMasterOrCustomPlan(state)
  );
};
export const selectCurrentFullPlan = (
  state: StoreState
):
  | MasterTrainingPlan
  | CustomTrainingPlan
  | LibraryTrainingPlan
  | ClientTrainingPlan => {
  const { programType } = state.trainingProgramsState;
  if (
    programType === ProgramType.Custom ||
    programType === ProgramType.Library ||
    programType === ProgramType.Master
  ) {
    return selectFullMasterOrCustomPlan(state);
  }
  if (programType === ProgramType.Client) {
    return selectFullClientPlan(state);
  }
  return null;
};
export const selectCurrentShallowPlan = (
  state: StoreState
):
  | MasterTrainingPlan
  | CustomTrainingPlan
  | ClientTrainingPlan
  | LibraryTrainingPlan => {
  const { programType } = state.trainingProgramsState;
  if (
    programType === ProgramType.Custom ||
    programType === ProgramType.Library ||
    programType === ProgramType.Master
  ) {
    return selectShallowMasterOrCustomPlan(state);
  }
  if (programType === ProgramType.Client) {
    return selectShallowClientPlan(state);
  }
  return null;
};
export const selectCurrentPlan = (
  state: StoreState
):
  | MasterTrainingPlan
  | CustomTrainingPlan
  | ClientTrainingPlan
  | LibraryTrainingPlan => {
  return selectCurrentFullPlan(state) || selectCurrentShallowPlan(state);
};

export const selectClientWorkoutGroup = (
  state: StoreState
): ClientWorkoutGroupTemplate => {
  const { workoutGroupIndex } = state.trainingProgramsState;
  const currentWorkoutGroups = selectCurrentWorkoutGroups(state);
  return currentWorkoutGroups?.[workoutGroupIndex];
};

export const selectClientWorkouts = (
  state: StoreState
): ClientWorkoutTemplate[] => {
  const currentWorkoutGroup = selectClientWorkoutGroup(state);
  return currentWorkoutGroup?.clientWorkoutTemplates;
};

export const selectMasterOrCustomWorkouts = (
  state: StoreState
): MasterWorkout[] => {
  const { programType } = state.trainingProgramsState;
  const currentPlan = selectFullMasterOrCustomPlan(state);
  if (
    programType === ProgramType.Custom ||
    programType === ProgramType.Library ||
    programType === ProgramType.Master
  ) {
    return currentPlan?.masterWorkoutGroups[0].masterWorkouts;
  }
  return null;
};
export const selectMasterOrCustomWorkout = (
  state: StoreState
): MasterWorkout => {
  const { programType, workoutIndex } = state.trainingProgramsState;
  const currentWorkouts = selectMasterOrCustomWorkouts(state);
  if (
    programType === ProgramType.Custom ||
    programType === ProgramType.Library ||
    programType === ProgramType.Master
  ) {
    return currentWorkouts?.[workoutIndex];
  }
  return null;
};
export const selectClientWorkout = (
  state: StoreState
): ClientWorkoutTemplate => {
  const { programType, workoutIndex } = state.trainingProgramsState;
  const workouts = selectClientWorkouts(state);
  if (programType === ProgramType.Client) {
    return workouts?.[workoutIndex];
  }
  return null;
};
export const selectMasterOrCustomGymSteps = (
  state: StoreState
): MasterGymStep[] => {
  const { programType } = state.trainingProgramsState;
  const currentWorkout = selectMasterOrCustomWorkout(state);
  if (
    programType === ProgramType.Custom ||
    programType === ProgramType.Library ||
    programType === ProgramType.Master
  ) {
    return currentWorkout?.gymSteps;
  }
  return null;
};
export const selectClientGymSteps = (
  state: StoreState
): ClientGymStepTemplate[] => {
  const { programType } = state.trainingProgramsState;
  const workout = selectClientWorkout(state);
  if (programType === ProgramType.Client) {
    return workout?.gymSteps;
  }
  return null;
};
export const selectCurrentWorkoutGroups = (
  state: StoreState
): ClientWorkoutGroupTemplate[] => {
  const currentPlan = selectClientPlan(state);
  return currentPlan?.clientWorkoutGroupTemplates;
};
export const selectCurrentWorkouts = (
  state: StoreState
): (ClientWorkoutTemplate | MasterWorkout)[] => {
  const { programType } = state.trainingProgramsState;
  if (programType === ProgramType.Client) {
    return selectClientWorkouts(state);
  }
  if (
    programType === ProgramType.Custom ||
    programType === ProgramType.Library ||
    programType === ProgramType.Master
  ) {
    return selectMasterOrCustomWorkouts(state);
  }
  return null;
};
export const selectCurrentWorkout = (
  state: StoreState
): ClientWorkoutTemplate | MasterWorkout => {
  const { programType } = state.trainingProgramsState;
  if (programType === ProgramType.Client) {
    return selectClientWorkout(state);
  }
  if (
    programType === ProgramType.Custom ||
    programType === ProgramType.Library ||
    programType === ProgramType.Master
  ) {
    return selectMasterOrCustomWorkout(state);
  }
  return null;
};
export const selectCurrentGymSteps = (
  state: StoreState
): (ClientGymStepTemplate | MasterGymStep)[] => {
  const { programType } = state.trainingProgramsState;
  if (programType === ProgramType.Client) {
    return selectClientGymSteps(state);
  }
  if (
    programType === ProgramType.Custom ||
    programType === ProgramType.Library ||
    programType === ProgramType.Master
  ) {
    return selectMasterOrCustomGymSteps(state);
  }
  return null;
};

export const selectCurrentSuperset = (state: StoreState): Superset => {
  const gymSteps: GymStep[] = selectCurrentGymSteps(state);
  return gymSteps?.find(
    (gymStep) => gymStep.id === state.trainingProgramsState.supersetId
  );
};
