import React from "react";
import colors from "../../../assets/colors";
import {
  StrengthScore,
  StrengthScoreMuscleGroupType,
} from "../../../types/gqlTypes";
import Ticker from "../../Icons/Ticker";

interface Props {
  lastMuscleGroup: StrengthScore;
  thisMuscleGroup: StrengthScore;
}

const MuscleDetailsContent = (props: Props) => {
  const { lastMuscleGroup, thisMuscleGroup } = props;

  const unit =
    lastMuscleGroup?.type === StrengthScoreMuscleGroupType.Weight
      ? "lbs"
      : "pts";

  const muscleTitles = {
    [StrengthScoreMuscleGroupType.Legs]: {
      title:
        "Key exercises that work the leg muscles include squats, deadlifts, lunges, and calf raises.",
      text: "Because leg muscles are some of the largest muscles in the human body, increasing leg strength can have an impact on more than just your legs themselves. \
        Stronger legs will significantly improve your performance on various cardio activities, like running and jogging, along with many sports. \
        Having strong legs will also lower your risk of injury while performing many movements, and will positively contribute to your metabolism, \
        allowing you to burn more calories each day at rest.",
    },
    [StrengthScoreMuscleGroupType.Chest]: {
      title:
        "Key exercises that work the chest muscles include bench presses, push-ups, dips, and flys.",
      text: "A strong chest means more than just having defined pecs. \
        Other benefits include improved posture and better breathing. \
        Because your chest muscles help to stabilize your shoulders, a stronger chest can help improve posture. \
        Additionally, because your pecs are attached to your ribs, they help them expand when you breathe, making it easier to breathe deeply. \
        Better posture also means less slouching, which can improve lung capacity and make day-to-day activities feel less strenuous.",
    },
    [StrengthScoreMuscleGroupType.Arms]: {
      title:
        "Key exercises that work the arm muscles include curls, tricep extensions, and pushdowns.",
      text: "When you think of arms, you probably think of your biceps. \
        And while having developed biceps is great, it’s important to focus on developing your triceps as well, \
        since they actually make up almost two thirds of your upper arm. In fact, developing strong triceps is one of the best ways to improve arm definition. \
        Additionally, having strong forearms is a contributor to overall grip strength. \
        Having a strong grip will often determine how far you can progress on foundational exercises like deadlifts and rows.",
    },
    [StrengthScoreMuscleGroupType.Weight]: {
      title:
        "Weight can fluctuate from week to week, but it doesn’t always tell the full story.",
      text: "While people think of losing weight as equivalent to losing body fat, \
        the two are separate ideas. In fact, it’s possible to lose body fat while maintaining weight because you’re gaining muscle. \
        Thus, when we calculate your Strength Score, we use the concept of relative strength - how strong you are compared to how much you weigh. \
        This means that a decrease in scale weight will positively impact your Strength Score, while an increase will negatively impact your Strength Score. \
        Remember, however, that changes in weight are not synonymous with body recomposition.",
    },
    [StrengthScoreMuscleGroupType.Back]: {
      title:
        "Key exercises that work the back muscles include deadlifts, rows, pullups, and pulldowns.",
      text: "The back includes the largest muscles in the body, second only to your legs, which means that it’s important that you train your back comphrensively. \
        Having a well-balanced back will help to improve your posture, and serve to protect you against injuries. \
        In addition, because your back muscles are so large, ensuring they’re strong will carry over positively across many exercises for other muscle groups as well. \
        Finally, a strong back is a key factor in creating a ‘v taper’ which can help achieve the appearance of a smaller waist.",
    },
    [StrengthScoreMuscleGroupType.Shoulders]: {
      title:
        "Key exercises that work the shoulder muscles include overhead presses, lateral raises, and front raises.",
      text: "Your shoulders do a fair amount of work whenever you perform back and chest exercises, \
        but doing isolated movements that single out your front, mid, and rear shoulders is still necessary. \
        Strong shoulders make everyday activities easier, whether you’re throwing a ball, or lifting heavy luggage above your head. \
        Additionally, strong shoulders will help prevent common (and annoying) shoulder injuries. \
        Because so many upper-body exercises involve your shoulders to some extent, strengthening them will also help you progress when training your chest, back, and arms.",
    },
  };

  const lastWeekStyle: React.CSSProperties = {
    fontSize: "12px",
    lineHeight: "12px",
    letterSpacing: "-0.011em",
    color: colors.caliber_secondary_gray_29,
    marginBottom: 10,
    width: 183,
  };
  const scoreStyle: React.CSSProperties = {
    fontSize: "24px",
    lineHeight: "24px",
    letterSpacing: "-0.022em",
    color: "#000000",
    marginRight: 8,
  };
  const tickerDivStyle: React.CSSProperties = {
    width: 14,
    height: 9,
    marginTop: 4,
    marginRight: 8,
  };
  const ptsStyle: React.CSSProperties = {
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "-0.23px",
    color: colors.caliber_gray_text,
    marginBottom: 4,
  };
  const titleDivStyle: React.CSSProperties = {
    fontSize: "16px",
    lineHeight: "18px",
    letterSpacing: "-0.011em",
    color: "#0C113A",
    marginBottom: 16,
    width: 366,
  };
  const textDivStyle: React.CSSProperties = {
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "-0.011em",
    color: colors.caliber_secondary_gray_47,
    width: 366,
    height: 200,
    maxHeight: 200,
    overflowY: "auto",
  };

  return (
    <div className="d-flex flex-column">
      <div
        className="d-flex"
        style={{
          marginTop: 24,
          marginBottom: 24,
        }}
      >
        <div className="d-flex flex-column">
          <div className="medium-bold" style={lastWeekStyle}>
            Last Week
          </div>
          <div className="d-flex">
            <div className="bold" style={scoreStyle}>
              {lastMuscleGroup?.score}
            </div>
            <div style={tickerDivStyle}>
              <Ticker
                up={lastMuscleGroup?.strengthScoreDelta >= 0}
                width={14}
                height={9}
              />
            </div>
            <div className="bold" style={ptsStyle}>
              {`${Math.abs(lastMuscleGroup?.strengthScoreDelta)} ${unit}`}
            </div>
          </div>
        </div>

        <div className="d-flex flex-column">
          <div className="medium-bold" style={lastWeekStyle}>
            This Week
          </div>
          <div className="d-flex">
            <div className="bold" style={scoreStyle}>
              {thisMuscleGroup?.score}
            </div>
            <div style={tickerDivStyle}>
              <Ticker
                up={thisMuscleGroup?.strengthScoreDelta >= 0}
                width={14}
                height={9}
              />
            </div>
            <div className="bold" style={ptsStyle}>
              {`${Math.abs(thisMuscleGroup?.strengthScoreDelta)} ${unit}`}
            </div>
          </div>
        </div>
      </div>

      <div style={titleDivStyle}>
        {muscleTitles?.[thisMuscleGroup?.type]?.title}
      </div>
      <div style={textDivStyle}>
        {muscleTitles?.[thisMuscleGroup?.type]?.text}
      </div>
    </div>
  );
};

export default MuscleDetailsContent;
