import { isBefore, parse } from "date-fns";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getActiveClientTrainingPlan } from "../../redux/actions/ClientPlans";
import {
  fetchClientWorkoutHistory,
  formVideosByDateRange,
  resetClientWorkoutHistoryState,
  selectWorkoutProgressTable,
} from "../../redux/actions/ClientWorkoutHistory";
import { StoreState } from "../../redux/reducers";
import {
  ClientTrainingPlan,
  ClientWorkout,
  ClientWorkoutTemplate,
  FormVideo,
  QueryFormVideosByDateRangeArgs,
} from "../../types/gqlTypes";
import { useRerenderOnResize } from "../../utils/customHooks";
import Loader from "../Loader";
import EditClientExerciseSetModal from "./EditClientExerciseSetModal";
import "./index.css";
import TrainingProgressTable from "./table";

interface Props {
  isLoading: boolean;
  isActivePlanLoading: boolean;
  clientWorkoutHistory: ClientWorkout[];
  formVideos: FormVideo[];
  selectedWorkoutId: string;
  activePlan: ClientTrainingPlan;
  currentPlan: string;
  firstName: string;
  lastName: string;
  resetClientWorkoutHistoryState: () => void;
  selectWorkoutProgressTable: (id: string) => void;
  fetchClientWorkoutHistory: (
    clientId: string,
    clientWorkoutTemplateId: string,
    maxQueryResults: number
  ) => void;
  getActiveClientTrainingPlan: (clientId: string) => void;
  formVideosByDateRange: (args: QueryFormVideosByDateRangeArgs) => void;
}

const TrainingProgress = (props: Props) => {
  const {
    resetClientWorkoutHistoryState,
    fetchClientWorkoutHistory,
    getActiveClientTrainingPlan,
    selectWorkoutProgressTable,
    formVideosByDateRange,
    isLoading,
    selectedWorkoutId,
    isActivePlanLoading,
    clientWorkoutHistory,
    formVideos,
    activePlan,
  } = props;
  useRerenderOnResize();
  const { id: clientId } = useParams();

  const [maxQueryResults] = useState(30);
  const [selectedWorkout, setSelectedWorkout] = useState("0");
  const [editExerciseSet, setEditExerciseSet] = useState({
    gymStep: null,
    date: null,
    setNumber: null,
  });
  const [workoutTemplates, setWorkoutTemplates] = useState(
    [] as ClientWorkoutTemplate[]
  );

  const populateWorkoutTemplateList = (activePlan: ClientTrainingPlan) => {
    const array: ClientWorkoutTemplate[] = [];

    activePlan &&
      activePlan.clientWorkoutGroupTemplates.map((workoutGroupTemplate) => {
        workoutGroupTemplate.clientWorkoutTemplates?.map((workoutTemplate) => {
          workoutTemplate.gymSteps?.length && array.push(workoutTemplate);
        });
      });

    return array;
  };

  useEffect(() => {
    getActiveClientTrainingPlan(clientId);
    return () => {
      resetClientWorkoutHistoryState();
    };
  }, [clientId]);

  // Initial rendering
  useEffect(() => {
    setWorkoutTemplates(populateWorkoutTemplateList(activePlan));
  }, [activePlan]);

  useEffect(() => {
    generate();
  }, [selectedWorkout, workoutTemplates]);

  useEffect(() => {
    if (!selectedWorkout && workoutTemplates?.length) {
      setSelectedWorkout(workoutTemplates[0].id);
    }
  }, [selectedWorkout, workoutTemplates]);

  // SelectedWorkoutId comes from the State and is set when the Coach views progress from the Workout on the calendar
  useEffect(() => {
    if (selectedWorkoutId) {
      setSelectedWorkout(
        workoutTemplates
          ?.findIndex((workout) => workout.id === selectedWorkoutId)
          .toString()
      );
    }
    return () => {
      if (workoutTemplates?.length) {
        selectWorkoutProgressTable(null);
      }
    };
  }, [selectedWorkoutId, workoutTemplates]);

  useEffect(() => {
    const lastIndex = clientWorkoutHistory?.length - 1;
    if (lastIndex !== -1) {
      let startDate =
        clientWorkoutHistory?.[0]?.workoutCalendarItem?.clientWorkoutDate;
      let endDate = startDate;
      if (lastIndex > 0) {
        endDate =
          clientWorkoutHistory?.[lastIndex]?.workoutCalendarItem
            ?.clientWorkoutDate;
        const parsedStartDate = parse(startDate, "yyyy-MM-dd", new Date());
        const parsedEndDate = parse(endDate, "yyyy-MM-dd", new Date());
        if (isBefore(parsedEndDate, parsedStartDate)) {
          endDate = startDate;
          startDate =
            clientWorkoutHistory?.[lastIndex]?.workoutCalendarItem
              ?.clientWorkoutDate;
        }
      }
      formVideosByDateRange({
        clientId,
        startDate,
        endDate,
      });
    }
  }, [clientId, clientWorkoutHistory]);

  const generate = () => {
    if (workoutTemplates?.[selectedWorkout]) {
      fetchClientWorkoutHistory(
        clientId,
        workoutTemplates[selectedWorkout].id,
        maxQueryResults
      );
    }
  };

  return (
    <div
      className="d-flex flex-column"
      style={{
        flex: 1,
      }}
    >
      {editExerciseSet?.gymStep && (
        <EditClientExerciseSetModal
          onClose={() =>
            setEditExerciseSet({ gymStep: null, date: null, setNumber: null })
          }
          clientGymStep={editExerciseSet?.gymStep}
          date={editExerciseSet?.date}
          setNumber={editExerciseSet?.setNumber}
        />
      )}
      <div
        className="d-flex"
        style={{
          flex: 1,
          position: "relative",
        }}
      >
        {(isLoading || isActivePlanLoading) && (
          <div
            style={{
              position: "absolute",
              top: 90,
              left: "calc(50% - 20px)",
            }}
          >
            <Loader />
          </div>
        )}
        {workoutTemplates && (
          <TrainingProgressTable
            formVideos={formVideos}
            onEditSet={(gymStep, date, setNumber) =>
              setEditExerciseSet({ gymStep, date, setNumber })
            }
            selectedWorkout={selectedWorkout}
            setSelectedWorkout={(value) => setSelectedWorkout(value)}
            clientWorkoutHistory={clientWorkoutHistory || []}
            clientWorkoutTemplates={workoutTemplates}
          />
        )}
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  fetchClientWorkoutHistory: (
    clientId: string,
    clientWorkoutTemplateId: string,
    maxQueryResults: number
  ) => {
    dispatch(
      fetchClientWorkoutHistory(
        clientId,
        clientWorkoutTemplateId,
        maxQueryResults
      )
    );
  },
  resetClientWorkoutHistoryState: () => {
    dispatch(resetClientWorkoutHistoryState());
  },
  selectWorkoutProgressTable: (id: string) => {
    dispatch(selectWorkoutProgressTable(id));
  },
  getActiveClientTrainingPlan: (clientId: string) => {
    dispatch(getActiveClientTrainingPlan(clientId));
  },
  formVideosByDateRange: (args: QueryFormVideosByDateRangeArgs) => {
    dispatch(formVideosByDateRange(args));
  },
});

const mapStateToProps = (state: StoreState) => ({
  isLoading: state.clientWorkoutHistoryState.isLoading,
  selectedWorkoutId: state.clientWorkoutHistoryState.selectedWorkoutId,
  clientWorkoutHistory: state.clientWorkoutHistoryState.clientWorkoutHistory,
  formVideos: state.clientWorkoutHistoryState.formVideos,
  activePlan: state.clientPlansState.activePlan,
  isActivePlanLoading: state.clientPlansState.isActivePlanLoading,
  firstName: state.clientDetailState.user.firstName,
  lastName: state.clientDetailState.user.lastName,
});

export default connect(mapStateToProps, mapDispatchToProps)(TrainingProgress);
