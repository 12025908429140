import apolloClient from "../../../api/apolloClient";
import { UPDATE_NOTES } from "../../../api/gql/users/clients";
import { MutationUpdateNotesArgs } from "../../../types/gqlTypes";
import {
  UPDATE_NOTES_FAIL,
  UPDATE_NOTES_SAVING,
  UPDATE_NOTES_SUCCESS,
} from "./types";

export const updateNotes =
  (args: MutationUpdateNotesArgs) => async (dispatch, getState) => {
    dispatch({
      type: UPDATE_NOTES_SAVING,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const result = (
        await client.mutate({
          mutation: UPDATE_NOTES,
          variables: args,
        })
      ).data.updateNotes.notes;
      dispatch({
        type: UPDATE_NOTES_SUCCESS,
        notes: result,
      });
    } catch (error) {
      console.error("Error updateNotes:", error);
      dispatch({
        type: UPDATE_NOTES_FAIL,
        data: error,
      });
    }
  };
