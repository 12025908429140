import { Trainer } from "../../../types/gqlTypes";
import { getTableSettingsForClientsTab } from "../ClientsTab";
import { disconnectFromSendbird } from "../Messages";
import {
  subscribeUnreadMessageCount,
  unsubscribeUnreadMessageCount,
} from "../UnreadMessageCount";
import { UserState } from "../User/types";
import {
  MasqueradeAction,
  MASQUERADE_DISABLED,
  MASQUERADE_ENABLED,
} from "./types";

export const masqueradeAsTrainer =
  (trainer: Trainer) => (dispatch, getState) => {
    dispatch(clearMasquerade());
    dispatch({
      trainer,
      type: MASQUERADE_ENABLED,
    } as MasqueradeAction);
    dispatch(getTableSettingsForClientsTab(trainer));
    subscribeUnreadMessageCount(dispatch, getState, trainer.id);
  };

export const subscribeUnreadAsTrainer =
  (trainerId: string) => (dispatch, getState) => {
    subscribeUnreadMessageCount(dispatch, getState, trainerId);
  };
export const unsubscribeUnreadAsTrainer = () => (dispatch) => {
  unsubscribeUnreadMessageCount(dispatch);
};

export const clearMasquerade = () => (dispatch, getState) => {
  dispatch(disconnectFromSendbird());
  dispatch({
    type: MASQUERADE_DISABLED,
    user: (getState().authenticatedUserState as UserState)?.user,
  } as MasqueradeAction);
  dispatch(
    getTableSettingsForClientsTab(
      (getState().authenticatedUserState as UserState)?.user
    )
  );
  unsubscribeUnreadMessageCount(dispatch);
};
