import apolloClient from "../../../api/apolloClient";
import { SEARCH_EXERCISES } from "../../../api/gql/exerciseLibrary";
import {
  CaliberPageImpl_Exercise,
  QuerySearchExercisesArgs,
} from "../../../types/gqlTypes";
import {
  SearchExercisesAction,
  SEARCH_EXERCISES_LOADING,
  SEARCH_EXERCISES_LOAD_FAIL,
  SEARCH_EXERCISES_LOAD_SUCCESS,
} from "./types";

export const searchExercises =
  (args: QuerySearchExercisesArgs) => async (dispatch, getState) => {
    if (getState().searchExercisesState.isLoading) {
      return;
    }
    dispatch({ type: SEARCH_EXERCISES_LOADING });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const result = (
        await client.query({
          query: SEARCH_EXERCISES,
          variables: {
            showWarmups: true,
            ...args,
          } as QuerySearchExercisesArgs,
        })
      ).data.searchExercises as CaliberPageImpl_Exercise;

      dispatch({
        type: SEARCH_EXERCISES_LOAD_SUCCESS,
        args,
        data: result,
      } as SearchExercisesAction);
    } catch (error) {
      dispatch({
        type: SEARCH_EXERCISES_LOAD_FAIL,
        data: error,
      });
    }
  };
