import apolloClient from "../../../api/apolloClient";
import {
  WEEKLY_CONTENT_BY_CLIENT_ID_SUCCESS,
  WEEKLY_CONTENT_BY_CLIENT_ID_FAIL,
  WEEKLY_CONTENT_BY_CLIENT_ID_LOADING,
  WEEKLY_CONTENT_BY_WEEK_NUMBER_SUCCESS,
  WEEKLY_CONTENT_BY_WEEK_NUMBER_FAIL,
  WEEKLY_CONTENT_BY_WEEK_NUMBER_LOADING,
} from "./types";
import {
  CONTENT_FOR_WEEK_BY_CLIENT_ID,
  CONTENT_FOR_WEEK_BY_WEEK_NUMBER,
} from "../../../api/gql/weeklyContent";
import {
  QueryContentForWeekByClientIdArgs,
  QueryContentForWeekByWeekNumberArgs,
  ContentWeek,
} from "../../../types/gqlTypes";

export const getWeeklyContentByClientId =
  (args: QueryContentForWeekByClientIdArgs) => async (dispatch, getState) => {
    if (getState().weeklyContentState.isLoading) {
      return;
    }
    dispatch({
      type: WEEKLY_CONTENT_BY_CLIENT_ID_LOADING,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const result = (
        await client.query({
          query: CONTENT_FOR_WEEK_BY_CLIENT_ID,
          variables: args,
        })
      ).data.contentForWeekByClientId as ContentWeek;

      dispatch({
        type: WEEKLY_CONTENT_BY_CLIENT_ID_SUCCESS,
        data: result,
      });
    } catch (error) {
      console.error("Error getWeeklyContentByClientId:", error);
      dispatch({
        type: WEEKLY_CONTENT_BY_CLIENT_ID_FAIL,
      });
    }
  };

export const getWeeklyContentByWeekNumber =
  (args: QueryContentForWeekByWeekNumberArgs) => async (dispatch, getState) => {
    dispatch({
      type: WEEKLY_CONTENT_BY_WEEK_NUMBER_LOADING,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const result = (
        await client.query({
          query: CONTENT_FOR_WEEK_BY_WEEK_NUMBER,
          variables: args,
        })
      ).data.contentForWeekByWeekNumber as ContentWeek;

      dispatch({
        type: WEEKLY_CONTENT_BY_WEEK_NUMBER_SUCCESS,
        data: result,
      });
    } catch (error) {
      console.error("Error getWeeklyContentByWeekNumber:", error);
      dispatch({
        type: WEEKLY_CONTENT_BY_WEEK_NUMBER_FAIL,
      });
    }
  };
