import React from "react";
import colors from "../../assets/colors";

interface Props {
  value: any;
  title: string;
}

const UserDataRow = (props: Props) => {
  const { value, title } = props;

  return (
    <div
      key={title}
      style={{ margin: "12px 0px" }}
      className="d-flex justify-content-between align-items-center"
    >
      <div
        className="medium-bold"
        style={{
          fontSize: "12px",
          lineHeight: "16px",
          color: colors.caliber_secondary,
        }}
      >
        {title}
      </div>
      {value && (
        <div
          className="medium-bold"
          style={{
            padding: "1px 10px",
            borderRadius: 4,
            maxWidth: 200,
            wordBreak: "break-all",
            fontSize: "12px",
            lineHeight: "16px",
            color: colors.caliber_secondary,
            backgroundColor: colors.caliber_light_gray,
          }}
        >
          {value}
        </div>
      )}
    </div>
  );
};

export default UserDataRow;
