import React from "react";

interface Props {}

const StrengthBalanceArc = (props: Props) => {
  const {} = props;

  return (
    <svg
      width="140"
      height="70"
      viewBox="0 0 140 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M128.903 32.2L118.95 38.9133C124.642 47.8859 127.938 58.5284 127.938 69.9408H139.938C139.938 56.0425 135.888 43.0902 128.903 32.2Z"
        fill="#27AE60"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M125.792 27.8246C118.1 17.6589 107.696 9.65867 95.6575 4.90003L91.5503 16.1844C101.327 20.1128 109.786 26.6268 116.081 34.88L125.792 27.8246Z"
        fill="#6FCF97"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.9492 2.88903C83.6218 1.00928 76.9198 0 69.9821 0C63.7113 0 57.6332 0.824546 51.8501 2.37113L55.3621 13.8583C60.0327 12.6454 64.932 12 69.9821 12C75.6988 12 81.2224 12.8271 86.4397 14.3681L89.9492 2.88903Z"
        fill="#F2C94C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.87 33.492L15.1503 26.4302C23.283 16.2063 34.2159 8.30955 46.7889 3.90002L50.8946 15.1803C40.5869 18.7671 31.6027 25.1803 24.87 33.492Z"
        fill="#F2994A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 69.9626H0C0 55.4111 4.44016 41.8965 12.0395 30.7L21.989 37.411C15.6842 46.692 12 57.8971 12 69.9626Z"
        fill="#EB5757"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.8514 2.37107C50.1442 2.82764 48.4627 3.34715 46.8095 3.92694L50.9152 15.2073C52.3724 14.7002 53.856 14.2497 55.3634 13.8582L51.8514 2.37107ZM86.4411 14.3681L89.9506 2.88897C91.9056 3.46976 93.8248 4.13364 95.7042 4.87653L91.5971 16.1609C89.9159 15.4854 88.1958 14.8864 86.4411 14.3681ZM12.0229 30.7373C13.019 29.2698 14.0693 27.8421 15.1709 26.4571L24.8906 33.5189C23.8645 34.7857 22.8907 36.0966 21.9724 37.4483L12.0229 30.7373ZM116.128 34.8565L125.839 27.8011C126.931 29.2445 127.968 30.7315 128.948 32.2591L118.995 38.9725C118.098 37.5578 117.141 36.1846 116.128 34.8565Z"
        fill="white"
      />
    </svg>
  );
};

export default StrengthBalanceArc;
