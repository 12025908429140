import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import colors from "../../assets/colors";
import ArrowDownIcon from "../../assets/images/ArrowDownIcon.svg";
import ArrowUpIcon from "../../assets/images/ArrowUpIcon.svg";
import ErrorIcon from "../../assets/images/ErrorIcon.svg";
import { useDebounce } from "../../utils/customHooks";
import ProfilePhoto from "../ProfilePhoto";
import "./index.css";

export interface DropdownItem {
  thumbnailUrl?: string;
  text: string;
  value: string;
}

interface Props {
  placeholder: string;
  items: DropdownItem[];
  search: (page: number, searchString: string) => void;
  onSelect: (value: string) => void;

  value?: string;
  selectedText?: any;
  label?: string;
  height?: number;
  borderRadius?: number;
  margin?: string;
  page?: number;
  totalPages?: number;
  error?: boolean;
  errorMessage?: string;
  showThumbnails?: boolean;
  customThumbnail?: any;
  bgColor?: string;
  borderColor?: string;
  textColor?: string;
  disabled?: boolean;
}

const SearchDropdown = (props: Props) => {
  const {
    items,
    label,
    onSelect,
    value,
    placeholder,
    height,
    borderRadius,
    margin,
    search,
    error,
    errorMessage,
    page,
    totalPages,
    showThumbnails,
    customThumbnail,
    bgColor,
    borderColor,
    textColor,
    disabled,
  } = props;

  const [open, setOpen] = useState(false);
  const inputRef = useRef(null);
  const defaultHeight = 48;
  const itemHeight = showThumbnails ? 48 : 32;
  const itemMargin = 3;
  const searchHeight = 36;
  const searchMargin = 10;
  const maxDropdownHeight = itemHeight * 6;

  let dropdownHeight = items.length * (itemHeight + 2 * itemMargin) + 3;
  if (search) {
    dropdownHeight += searchHeight + 2 * searchMargin;
  }

  const selectedItem = items.find((item) => item.value === value);
  // eslint-disable-next-line react/destructuring-assignment
  const selectedText = props?.selectedText
    ? props?.selectedText
    : selectedItem?.text;
  const [hovered, setHovered] = useState("");
  const [searchString, setSearchString] = useState("");
  const debouncedSearchValue = useDebounce(searchString, 400);

  const onBlur = () => {
    setTimeout(() => {
      if (document.activeElement.id !== "dropdown_element") {
        setOpen(false);
      }
    }, 0);
  };

  useEffect(() => {
    if (search && open) {
      search(0, debouncedSearchValue);
    }
  }, [debouncedSearchValue, open]);
  useEffect(() => {
    if (open && inputRef?.current?.focus) {
      inputRef.current.focus();
    }
  }, [open]);

  const dropdownColor = open
    ? bgColor || colors.caliber_gray_11
    : bgColor || colors.caliber_secondary_gray_5;
  const dropdownBorderColor = open
    ? borderColor || bgColor || colors.caliber_gray_11
    : borderColor || bgColor || colors.caliber_secondary_gray_5;

  return (
    <div
      style={{
        flex: 1,
        position: "relative",
      }}
      className="d-flex flex-column"
    >
      <div
        className="d-flex heading-xsmall"
        style={{
          flex: 1,
          marginLeft: 5,
        }}
      >
        {label}
      </div>
      <div
        style={{
          flex: 1,
          overflow: "hidden",
          backgroundColor: dropdownColor,
          height: height || defaultHeight,
          borderRadius: borderRadius || 8,
          border: `1px solid ${
            error ? colors.caliber_error_color : dropdownBorderColor
          }`,
          paddingLeft: 20,
          paddingRight: 35,
        }}
        className="d-flex flex-column"
      >
        <div className="d-flex">
          {customThumbnail && (
            <div
              className="d-flex align-items-center"
              style={{ marginRight: 13 }}
            >
              <img src={customThumbnail} />
            </div>
          )}
          <div
            title={selectedText || placeholder}
            role="button"
            tabIndex={0}
            onBlur={onBlur}
            onClick={() => {
              if (!disabled) {
                setOpen(!open);
              }
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter" && !disabled) {
                setOpen(!open);
              }
            }}
            className={`d-flex flex-column justify-content-center ${
              disabled ? "" : "pointer"
            } medium-bold`}
            style={{
              width: "100%",
              overflow: "hidden",
              whiteSpace: "nowrap",
              outline: "none",
              height: height || defaultHeight,
              color:
                selectedText === placeholder
                  ? colors.caliber_placeholder_color
                  : textColor || colors.caliber_secondary,
            }}
          >
            {selectedText || placeholder}
          </div>
        </div>
        {error && (
          <div
            className="d-flex paragraph-small align-items-center"
            style={{
              color: colors.caliber_error_color,
            }}
          >
            <img
              style={{
                width: 16,
                height: 16,
                marginRight: 5,
              }}
              alt="Error"
              src={ErrorIcon}
            />
            {errorMessage}
          </div>
        )}
      </div>
      <div
        id="dropdown_element"
        role="button"
        tabIndex={0}
        onBlur={onBlur}
        onClick={() => {
          if (!disabled) {
            setOpen(!open);
          }
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter" && !disabled) {
            setOpen(!open);
          }
        }}
        className="d-flex flex-column justify-content-center"
        style={{
          outline: "none",
          position: "absolute",
          top: label ? 24 : 1,
          right: 10,
          height: height || defaultHeight,
        }}
      >
        <img alt="Dropdown" src={open ? ArrowUpIcon : ArrowDownIcon} />
      </div>

      {open && (
        <div
          id="dropdown_element"
          onBlur={onBlur}
          className="d-flex flex-column itemOnHover medium-bold"
          style={{
            minWidth: 272,
            width: "100%",
            margin: margin || 0,
            padding: 5,
            height: Math.min(dropdownHeight, maxDropdownHeight) + 8,
            zIndex: 2,
            backgroundColor: colors.caliber_white,
            borderRadius: 8,
            position: "absolute",
            top: (height || defaultHeight) + 5,
            marginBottom: 10,
            boxShadow: "0px 2px 16px #E7E7EB",
          }}
        >
          {search && (
            <div id="dropdown_element" onBlur={onBlur} className="d-flex">
              <input
                ref={inputRef}
                autoComplete="off"
                id="dropdown_element"
                onBlur={onBlur}
                className="nofocus"
                placeholder="Search"
                onChange={(event) => setSearchString(event.target.value)}
                value={searchString}
                style={{
                  flex: 1,
                  height: searchHeight,
                  paddingLeft: 12,
                  margin: `${searchMargin}px ${itemMargin}px`,
                  border: `1px solid ${colors.caliber_gray_5}`,
                  backgroundColor: colors.caliber_gray_5,
                  borderRadius: 8,
                }}
              />
            </div>
          )}
          <div
            className="d-flex flex-column overflow-auto"
            style={{
              maxHeight: maxDropdownHeight,
              position: "relative",
              flex: 1,
            }}
          >
            <InfiniteScroll
              pageStart={0}
              useWindow={false}
              loadMore={() => search && search(page + 1, searchString)}
              hasMore={totalPages > page + 1}
            >
              {items.map(({ text, value, thumbnailUrl }) => {
                const isSelected = selectedItem && selectedItem.value === value;
                const isHovered = !isSelected && value === hovered;
                let backgroundColor = colors.caliber_white;
                if (isSelected) {
                  backgroundColor = colors.caliber_gray_bg;
                } else if (isHovered) {
                  backgroundColor = colors.caliber_gray_2;
                }
                return (
                  <div
                    key={`search_dropdown_${text}-${value}`}
                    id="dropdown_element"
                    role="button"
                    tabIndex={0}
                    onBlur={onBlur}
                    onClick={() => {
                      onSelect(value);
                      setOpen(false);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        onSelect(value);
                        setOpen(false);
                      }
                    }}
                    onMouseEnter={() => setHovered(value)}
                    onMouseLeave={() => setHovered("")}
                    className={`nofocus d-flex align-items-center ${
                      isSelected ? "" : "itemOnHover"
                    } ${disabled ? "" : "pointer"}`}
                    style={{
                      zIndex: 3,
                      flex: 1,
                      paddingLeft: 8,
                      minHeight: itemHeight,
                      margin: itemMargin,
                      borderRadius: 8,
                      backgroundColor,
                      color: colors.caliber_secondary,
                    }}
                  >
                    {showThumbnails && (
                      <div
                        style={{
                          marginRight: 13,
                        }}
                      >
                        <ProfilePhoto
                          key={`search_dropdown_pic_${text}-${value}`}
                          imageUrl={thumbnailUrl}
                          size={40}
                          bgColor={backgroundColor}
                        />
                      </div>
                    )}
                    {customThumbnail && (
                      <div style={{ marginRight: 13 }}>
                        <img src={customThumbnail} />
                      </div>
                    )}
                    {text}
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        </div>
      )}
    </div>
  );
};
export default SearchDropdown;
