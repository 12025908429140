import React from "react";
import colors from "../../assets/colors";
import SimpleButton from "../SimpleButton";

interface Props {
  onCancel: () => void;
  onSuccess: () => void;
  successText: string;
}

const Footer = (props: Props) => {
  const { onCancel, onSuccess, successText } = props;

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        marginTop: 32,
        marginBottom: 12,
      }}
    >
      <SimpleButton
        nofocus
        onClick={onCancel}
        className="d-flex justify-content-center align-items-center"
        style={{
          width: 160,
          height: 32,
          backgroundColor: colors.caliber_gray_text,
          borderRadius: 4,
          color: colors.caliber_white,
          fontSize: "14px",
          marginRight: 12,
        }}
      >
        Cancel
      </SimpleButton>
      <SimpleButton
        nofocus
        onClick={onSuccess}
        className="d-flex justify-content-center align-items-center"
        style={{
          width: 160,
          height: 32,
          backgroundColor: colors.caliber_green_200,
          borderRadius: 4,
          color: colors.caliber_white,
          fontSize: "14px",
        }}
      >
        {successText}
      </SimpleButton>
    </div>
  );
};

export default Footer;
