import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { StoreState } from "../../redux/reducers";
import Dropdown from "../Dropdown";
import colors from "../../assets/colors";

interface Props {
  bodyFatPercent: number;
  bodyWeight: number;
}

const DailyCaloriesCalculator = (props: Props) => {
  const { bodyFatPercent, bodyWeight } = props;
  const initialState = {
    weight: bodyWeight || 100,
    bodyFat: bodyFatPercent || 20,
    activityLevel: 1.1,
    BMR: 0,
    TDEE: 0,
    adjustment: 0,
    dailyCalorieAdjustment: 0,
    dailyCalorieTarget: 0,
  };
  const [state, setState] = useState(initialState);

  useEffect(() => {
    setState({
      ...state,
      weight: bodyWeight || 100,
      bodyFat: bodyFatPercent || 20,
    });
  }, [bodyFatPercent, bodyWeight]);

  useEffect(() => {
    const weightKG = state.weight * 0.453592;
    const leanMass = weightKG * 0.01 * (100 - state.bodyFat);
    const BMR = 370 + 21.6 * leanMass;
    const TDEE = BMR * state.activityLevel;
    const dailyCalorieAdjustment = Math.round(TDEE * state.adjustment);
    const dailyCalorieTarget =
      Math.round((TDEE + dailyCalorieAdjustment) / 50) * 50;
    setState({
      ...state,
      BMR: Math.round(BMR),
      TDEE: Math.round(TDEE),
      dailyCalorieAdjustment,
      dailyCalorieTarget,
    });
  }, [state.weight, state.bodyFat, state.activityLevel, state.adjustment]);

  const activityLevelOptions = [
    { text: "<1 hour of exercise per week", value: 1.1 },
    { text: "1-3 hours of exercise per week", value: 1.2 },
    { text: "4-6 hours of exercise per week", value: 1.35 },
    { text: "6+ hours of exercise per week", value: 1.45 },
  ];

  const adjustmentOptions = [
    { text: "25% deficit", value: -0.25 },
    { text: "20% deficit", value: -0.2 },
    { text: "15% deficit", value: -0.15 },
    { text: "10% deficit", value: -0.1 },
    { text: "5% deficit", value: -0.05 },
    { text: "No adjustment", value: 0 },
    { text: "5% surplus", value: 0.05 },
    { text: "10% surplus", value: 0.1 },
    { text: "15% surplus", value: 0.15 },
  ];

  const inputStyle = {
    height: 24,
    borderRadius: 6,
    backgroundColor: colors.caliber_gray_bg,
    border: `1px solid ${colors.caliber_gray_bg}`,
    width: 60,
    padding: 2,
    color: colors.caliber_secondary,
    fontSize: "14px",
    lineHeight: "16px",
    "text-align": "center",
  };
  const titleStyle = {
    fontSize: "12px",
    lineHeight: "15px",
    width: 160,
    marginTop: 19,
    marginBottom: 19,
  };
  const labelStyle = {
    fontSize: "12px",
    lineHeight: "15px",
    color: colors.caliber_secondary,
    marginLeft: 10,
  };
  const badgeStyle = {
    backgroundColor: colors.caliber_light_gray,
    color: colors.caliber_gray_47,
    fontSize: "14px",
    lineHeight: "16px",
    height: 24,
    minWidth: 100,
    borderRadius: 6,
  };

  return (
    <div
      className="d-flex"
      style={{
        backgroundColor: colors.caliber_white,
        borderRadius: 8,
        position: "relative",
        minHeight: 535,
        minWidth: 548,
        padding: 16,
      }}
    >
      <div
        style={{
          backgroundColor: colors.caliber_gray_5,
          height: 80,
          width: "calc(100% - 32px)",
          borderRadius: "8px 8px 0 0",
          position: "absolute",
        }}
      >
        {/* gray rectangle */}
      </div>
      <div
        className="d-flex flex-column medium-bold"
        style={{
          backgroundColor: colors.caliber_white,
          margin: "30px 16px 0px 16px",
          borderRadius: 8,
          minHeight: 457,
          minWidth: 484,
          zIndex: 2,
          paddingLeft: 24,
        }}
      >
        <div
          style={{
            fontSize: "20px",
            lineHeight: "25px",
            letterSpacing: "-0.11px",
            color: colors.caliber_secondary,
            margin: "16px 0px",
          }}
        >
          Daily Calories Calculator
        </div>
        <div className="d-flex align-items-center">
          <div style={titleStyle}>Current Weight</div>
          <input
            onChange={(event) => {
              setState({ ...state, weight: Number(event.currentTarget.value) });
            }}
            type="number"
            value={state.weight.toString()}
            style={inputStyle}
          />
          <div style={labelStyle}>lbs</div>
        </div>
        <div className="d-flex align-items-center">
          <div style={titleStyle}>Body Fat</div>
          <input
            onChange={(event) => {
              setState({
                ...state,
                bodyFat: Number(event.currentTarget.value),
              });
            }}
            type="number"
            value={state.bodyFat.toString()}
            style={inputStyle}
          />
          <div style={labelStyle}>%</div>
        </div>
        <div className="d-flex align-items-center">
          <div style={titleStyle}>Activity Level</div>
          <div style={{ width: 272 }}>
            <Dropdown
              items={activityLevelOptions}
              value={state.activityLevel}
              onSelect={(activityLevel) =>
                setState({ ...state, activityLevel })
              }
              width={272}
              height={24}
              fontSize={14}
              bgColor={colors.caliber_light_gray}
              borderColor={colors.caliber_light_gray}
              textColor={colors.caliber_secondary}
            />
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div
            style={titleStyle}
            className="d-flex flex-column justify-content-center"
          >
            BMR
          </div>
          <div
            style={badgeStyle}
            className="d-flex flex-column justify-content-center align-items-center"
          >{`${state.BMR} calories`}</div>
        </div>
        <div className="d-flex align-items-center">
          <div
            style={titleStyle}
            className="d-flex flex-column justify-content-center"
          >
            TDEE
          </div>
          <div
            style={badgeStyle}
            className="d-flex flex-column justify-content-center align-items-center"
          >{`${state.TDEE} calories`}</div>
        </div>
        <div className="d-flex align-items-center">
          <div style={titleStyle}>Adjustment</div>
          <div style={{ width: 172 }}>
            <Dropdown
              items={adjustmentOptions}
              value={state.adjustment}
              onSelect={(adjustment) => setState({ ...state, adjustment })}
              width={172}
              height={24}
              fontSize={14}
              bgColor={colors.caliber_light_gray}
              borderColor={colors.caliber_light_gray}
              textColor={colors.caliber_secondary}
            />
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div
            style={titleStyle}
            className="d-flex flex-column justify-content-center"
          >
            Daily Calorie Adjustment
          </div>
          <div
            style={badgeStyle}
            className="d-flex flex-column justify-content-center align-items-center"
          >{`${state.dailyCalorieAdjustment} calories`}</div>
        </div>
        <div className="d-flex align-items-center">
          <div
            style={titleStyle}
            className="d-flex flex-column justify-content-center"
          >
            Daily Calorie Target
          </div>
          <div
            style={badgeStyle}
            className="d-flex flex-column justify-content-center align-items-center"
          >{`${state.dailyCalorieTarget} calories`}</div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  bodyWeight: state.clientDetailState.bodyWeight,
  bodyFatPercent: state.clientDetailState.bodyFatPercent,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DailyCaloriesCalculator);
