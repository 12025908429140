import { UPDATE_UNREAD_MESSAGE_COUNT } from "./types";
import UnreadMessageCountInstance from "./UnreadMessageCountInstance";

export const subscribeUnreadMessageCount = (dispatch, getState, userId) => {
  const token = getState().authState.authToken;
  UnreadMessageCountInstance.subscribe(token, userId, (unreadMessageCount) => {
    dispatch({
      type: UPDATE_UNREAD_MESSAGE_COUNT,
      unreadMessageCount,
    });
  });
};

export const unsubscribeUnreadMessageCount = (dispatch) => {
  UnreadMessageCountInstance.unsubscribe();
  dispatch({
    type: UPDATE_UNREAD_MESSAGE_COUNT,
    unreadMessageCount: 0,
  });
};
