import apolloClient from "../../../api/apolloClient";
import { SEARCH_CLIENTS_BY_TRAINER_AND_PACKAGE_TYPE } from "../../../api/gql/users/clients";
import {
  FETCH_USER_GQL,
  UPDATE_TRAINER_SETTINGS,
  UPDATE_USER_TIMEZONE,
} from "../../../api/gql/users";
import {
  ClientPackageType,
  TrainerSettings,
  TrainerSettingsInput,
  User,
  UserType,
} from "../../../types/gqlTypes";
import { getTrainerId } from "../../reducers";
import { getTableSettingsForClientsTab } from "../ClientsTab";
import {
  MASQUERADE_SAVE_AUTO_MESSAGES_SUCCESS,
  MASQUERADE_USER_TIMEZONE_SAVE_SUCCESS,
} from "../Masquerade/types";
import { subscribeUnreadMessageCount } from "../UnreadMessageCount";
import {
  GET_TRAINER_PREMIUM_CLIENTS,
  RESET_USER_DATA,
  SAVE_AUTO_MESSAGES_FAIL,
  SAVE_AUTO_MESSAGES_LOADING,
  SAVE_AUTO_MESSAGES_SUCCESS,
  UserAction,
  USER_LOADING,
  USER_LOAD_FAIL,
  USER_LOAD_SUCCESS,
  USER_TIMEZONE_SAVE_FAIL,
  USER_TIMEZONE_SAVE_SUCCESS,
  USER_TIMEZONE_SAVING,
} from "./types";

const fetchUserData = () => async (dispatch, getState) => {
  if (getState().authenticatedUserState.isLoading) {
    return;
  }
  dispatch({
    type: USER_LOADING,
  });

  try {
    const client = await apolloClient(getState().authState.authToken, dispatch);
    const userResult = await client.query({ query: FETCH_USER_GQL });

    dispatch({
      type: USER_LOAD_SUCCESS,
      fetchedUser: userResult.data.user,
    } as UserAction);
    dispatch(getTableSettingsForClientsTab(userResult.data.user));
    if (userResult.data.user.type === UserType.Trainer) {
      subscribeUnreadMessageCount(dispatch, getState, userResult.data.user.id);
    }
  } catch (error) {
    dispatch({
      type: USER_LOAD_FAIL,
      error: error.toString(),
    } as UserAction);
    console.error("Failed to load user", error);
  }
};

export const resetUserData = () => async (dispatch) => {
  dispatch({ type: RESET_USER_DATA });
};

export const updateUserTimezone =
  (timeZoneTypeId: string) => async (dispatch, getState) => {
    if (getState().authenticatedUserState.isSavingTimezone) {
      return;
    }
    dispatch({
      type: USER_TIMEZONE_SAVING,
    });

    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const isMasquerading =
        getState()?.masqueradeState?.masqueradeTrainer !== null;
      const result = (
        await client.mutate({
          mutation: UPDATE_USER_TIMEZONE,
          variables: {
            userId: isMasquerading
              ? getState()?.masqueradeState?.masqueradeTrainer?.id
              : getState().authenticatedUserState.user.id,
            timeZoneTypeId,
          },
        })
      ).data.updateUserTimeZone as User;
      if (isMasquerading) {
        dispatch({
          type: MASQUERADE_USER_TIMEZONE_SAVE_SUCCESS,
          trainer: result,
        });
      } else {
        dispatch({
          type: USER_TIMEZONE_SAVE_SUCCESS,
          fetchedUser: result,
        });
      }
    } catch (error) {
      dispatch({
        type: USER_TIMEZONE_SAVE_FAIL,
        data: error,
      } as UserAction);
      console.error("Failed to update user timezone", error);
    }
  };
export const getTrainerPremiumClients = () => async (dispatch, getState) => {
  try {
    const client = await apolloClient(getState().authState.authToken, dispatch);
    const trainerId = getTrainerId(getState());
    if (trainerId) {
      const result = await client.query({
        query: SEARCH_CLIENTS_BY_TRAINER_AND_PACKAGE_TYPE,
        variables: {
          trainerId,
          packageType: ClientPackageType.Premium,
        },
      });

      dispatch({
        type: GET_TRAINER_PREMIUM_CLIENTS,
        premiumClients: result.data
          .clientsByTrainerAndSalesPackageType as User[],
      });
    }
  } catch (error) {
    console.error("Failed to get Trainer's Premium Clients", error);
  }
};

export const updateTrainerAutoMessages =
  (
    trainerId: string,
    settingsId: string,
    firstAutoMessage: string,
    secondAutoMessage: string,
    thirdAutoMessage: string
  ) =>
  async (dispatch, getState) => {
    if (getState().authenticatedUserState.isSavingAutoMessages) {
      return;
    }
    dispatch({
      type: SAVE_AUTO_MESSAGES_LOADING,
    });

    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const isMasquerading =
        getState()?.masqueradeState?.masqueradeTrainer !== null;
      const trainerSettings = {
        firstAutoMessage,
        id: settingsId,
      } as TrainerSettingsInput;
      if (secondAutoMessage.length > 0) {
        trainerSettings.secondAutoMessage = secondAutoMessage;
      }
      if (thirdAutoMessage.length > 0) {
        trainerSettings.thirdAutoMessage = thirdAutoMessage;
      }
      const result = (
        await client.mutate({
          mutation: UPDATE_TRAINER_SETTINGS,
          variables: { trainerId, trainerSettings },
        })
      ).data.updateTrainerSettings as TrainerSettings;
      if (isMasquerading) {
        dispatch({
          type: MASQUERADE_SAVE_AUTO_MESSAGES_SUCCESS,
          trainerSettings: result,
        });
      } else {
        dispatch({
          type: SAVE_AUTO_MESSAGES_SUCCESS,
          trainerSettings: result,
        });
      }
    } catch (error) {
      dispatch({
        type: SAVE_AUTO_MESSAGES_FAIL,
        data: error,
      } as UserAction);
      console.error("Failed to update auto messages", error);
    }
  };

export default fetchUserData;
