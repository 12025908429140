import "cropperjs/dist/cropper.css";
import React from "react";
import Cropper from "react-cropper";

interface Props {
  name: string;
  imgUrl: string;
  onInitialized: (cropped: any) => void;
}

const PhotoCropper = (props: Props) => {
  const { name, imgUrl, onInitialized } = props;

  return (
    <div>
      <div
        className="d-flex"
        style={{
          height: 272,
          maxWidth: 400,
        }}
      >
        <Cropper
          checkCrossOrigin={false}
          checkOrientation={false}
          style={{ height: 272, width: "100%" }}
          zoomTo={0}
          aspectRatio={134 / 272}
          src={imgUrl}
          preview={`.${name}`}
          guides={false}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background
          responsive
          onInitialized={onInitialized}
        />
      </div>
    </div>
  );
};

export default PhotoCropper;
