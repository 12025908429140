import { TrainerSettings, User } from "../../../types/gqlTypes";

export const USER_LOADING = "USER_LOADING";
export const USER_LOAD_SUCCESS = "USER_LOAD_SUCCESS";
export const USER_LOAD_FAIL = "USER_LOAD_FAIL";

export const USER_TIMEZONE_SAVING = "USER_TIMEZONE_SAVING";
export const USER_TIMEZONE_SAVE_SUCCESS = "USER_TIMEZONE_SAVE_SUCCESS";
export const USER_TIMEZONE_SAVE_FAIL = "USER_TIMEZONE_SAVE_FAIL";

export const SAVE_AUTO_MESSAGES_SUCCESS = "SAVE_AUTO_MESSAGES_SUCCESS";
export const SAVE_AUTO_MESSAGES_FAIL = "SAVE_AUTO_MESSAGES_FAIL";
export const SAVE_AUTO_MESSAGES_LOADING = "SAVE_AUTO_MESSAGES_LOADING";

export const RESET_USER_DATA = "RESET_USER_DATA";
export const GET_TRAINER_PREMIUM_CLIENTS = "GET_TRAINER_PREMIUM_CLIENTS";

export interface UserState {
  isLoading: boolean;
  isSavingTimezone: boolean;
  isSavingAutoMessages: boolean;
  doneLoading: boolean;
  user?: User;
  error: string;
  premiumClients?: User[];
}

export interface UserAction {
  type: string;
  fetchedUser?: User;
  error?: string;
  trainerSettings?: TrainerSettings;
  premiumClients?: User[];
}
