import {
  CaliberPageImpl_ClientSearchResult,
  ClientSearchResult,
} from "../../../types/gqlTypes";

export const CLIENTS_LOADING = "CLIENTS_LOADING";
export const CLIENTS_LOAD_SUCCESS = "CLIENTS_LOAD_SUCCESS";
export const CLIENTS_LOAD_FAIL = "CLIENTS_LOAD_FAIL";
export const RESET_CLIENT_SEARCH_LIST = "RESET_CLIENT_SEARCH_LIST";
export const RESET_CLIENT_SEARCH_LIST_FILTER =
  "RESET_CLIENT_SEARCH_LIST_FILTER";
export const TOGGLE_CLIENT_SEARCH_LIST_FILTER =
  "TOGGLE_CLIENT_SEARCH_LIST_FILTER";

export interface ClientSearchState {
  isLoading: boolean;
  page: number;
  totalPages: number;
  totalElements: number;
  foundClients: ClientSearchResult[];
  isFilterOpen: boolean;
}

export interface ClientSearchAction {
  type: string;
  data: CaliberPageImpl_ClientSearchResult;
}
