import React from "react";
import colors from "../../../assets/colors";
import GearIcon from "../../Icons/GearIcon";
import SimpleButton from "../../SimpleButton";

interface Props {
  onClick: () => void;
  isSelected?: boolean;
}

const SettingsGear = (props: Props) => {
  const { isSelected, onClick } = props;
  const borderColor = colors.table_gray;

  return (
    <th
      style={{
        position: "sticky",
        top: 0,
        right: 0,
        width: 56,
        height: 64,
        zIndex: 3,
        backgroundColor: "#F2F3F5",
      }}
    >
      <div
        className="d-flex align-items-center justify-content-center"
        style={{
          width: 56,
          height: 56,
          backgroundColor: colors.caliber_white,
          borderTopRightRadius: 8,
          borderBottomRightRadius: 8,
          borderLeft: `1px solid ${borderColor}`,
          marginBottom: 8,
        }}
      >
        <SimpleButton nofocus onClick={onClick}>
          <GearIcon isSelected={isSelected} />
        </SimpleButton>
      </div>
    </th>
  );
};

export default SettingsGear;
