import React, { useMemo, useState } from "react";
import { MuscleGroupType } from "../../types/gqlTypes";
import colors from "../../assets/colors";
import TagItem from "../ExerciseLibraryTagSection/TagItem";
import { exerciseMuscleGroupText } from "../../models/exercises";

interface Props {
  isFilter?: boolean;
  title: string;
  selectedMuscles: MuscleGroupType[];
  excludedMuscles?: MuscleGroupType[];
  onMuscleSelected?: (muscle: MuscleGroupType) => void;
}

const ExerciseLibraryMuscleGroup = (props: Props) => {
  const {
    isFilter,
    title,
    selectedMuscles,
    excludedMuscles,
    onMuscleSelected,
  } = props;

  const [isExpanded, setIsExpanded] = useState(isFilter);

  let buttonLabel = isExpanded ? "Hide Muscles" : "View Muscles";
  buttonLabel = onMuscleSelected ? "Add muscles +" : buttonLabel;

  const arrayToIterate = useMemo(() => {
    if (excludedMuscles?.length > 0) {
      return Object.values(MuscleGroupType).filter(
        (muscle) => !excludedMuscles.includes(muscle)
      );
    }
    return Object.values(MuscleGroupType);
  }, [excludedMuscles]);

  return (
    <div
      className="d-flex flex-column"
      style={{
        marginBottom: isFilter ? 0 : 16,
      }}
    >
      {!isFilter && (
        <div
          className="d-flex medium-bold justify-content-between align-items-center"
          style={{
            flex: 1,
            height: 64,
            borderRadius: 8,
            backgroundColor: "#E3E4E8",
            padding: 16,
            fontSize: "14px",
            lineHeight: "32px",
          }}
        >
          {title}
          <div
            className="d-flex pointer medium-bold align-items-center justify-content-center"
            role="button"
            tabIndex={0}
            style={{
              padding: "1px 10px",
              borderRadius: 4,
              backgroundColor: colors.caliber_green_200,
              height: 32,
              width: 155,
              fontSize: "14px",
              lineHeight: "32px",
            }}
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                setIsExpanded(!isExpanded);
              }
            }}
          >
            {buttonLabel}
          </div>
        </div>
      )}
      {isExpanded && (
        <div
          className="d-flex flex-wrap"
          style={{
            marginTop: isFilter ? 0 : 16,
          }}
        >
          {arrayToIterate.map((muscle) => (
            <TagItem
              key={muscle}
              label={exerciseMuscleGroupText(muscle)}
              isHighlighted={selectedMuscles.includes(muscle)}
              onClick={onMuscleSelected ? () => onMuscleSelected(muscle) : null}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ExerciseLibraryMuscleGroup;
