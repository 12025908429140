/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDrop } from "react-dnd";
import { connect } from "react-redux";
import { StoreState } from "../../redux/reducers";
import colors from "../../assets/colors";
import { CoachProfilePhoto } from "../../types/gqlTypes";
import Image from "./Image";
import ImageCropper from "./ImageCropper";
import NoImg from "./NoImg";
import {
  cancelUploadCoachProfileImage,
  uploadCroppedCoachProfileImage,
} from "../../redux/actions/CoachProfile";
import ConfirmationDialog from "../ConfirmationDialog";

interface OwnProps {
  photos: CoachProfilePhoto[];
  editable?: boolean;
  onChange: (photos: CoachProfilePhoto[]) => void;
}
interface Props extends OwnProps {
  uploadCroppedCoachProfileImage: (file: any) => void;
  cancelUploadCoachProfileImage: () => void;
  isUploading: boolean;
}

const Images = (props: Props) => {
  const {
    onChange,
    uploadCroppedCoachProfileImage,
    cancelUploadCoachProfileImage,
    editable,
    photos,
    isUploading,
  } = props;

  const [localItems, setLocalItems] = useState([]);
  const [wasUploaded, setWasUploaded] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(null);

  const moveCard = (id: string, atIndex: number) => {
    const { index } = findCard(id);
    const items = [...localItems];
    if (index > -1) {
      const temp = items[index];
      items[index] = items[atIndex];
      items[atIndex] = temp;
      setLocalItems(items);
    }
  };
  const findCard = (id: string) => {
    return {
      index: localItems.findIndex((item) => item?.mediaUrl?.id === id),
    };
  };

  const onDrop = useCallback(
    (item: any, monitor) => {
      onChange(localItems);
    },
    [localItems]
  );

  const [{ isOver, didDrop }, drop] = useDrop({
    accept: "coach_profile_images",
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      didDrop: monitor.didDrop(),
    }),
  });

  useEffect(() => {
    setLocalItems(
      Array.from({ length: 6 }, (_, index) => {
        return photos?.[index] || {};
      })
    );
  }, [isOver, didDrop, photos]);

  const onDeleteImage = (index) => {
    onChange((photos || [])?.filter((_, photoIndex) => photoIndex !== index));
    setConfirmDelete(null);
  };

  const lastPic: CoachProfilePhoto = useMemo(() => {
    let lastOne;
    photos.forEach((photo) => {
      if (photo?.mediaUrl?.url) {
        lastOne = photo;
      }
    });
    return lastOne;
  }, [photos, wasUploaded]);

  return (
    <div className="d-flex flex-column">
      {typeof confirmDelete === "number" && (
        <ConfirmationDialog
          show
          text={`Are you sure you want to delete the image ${
            confirmDelete + 1
          } ?`}
          cancelButtonText="Cancel"
          onCancel={() => setConfirmDelete(null)}
          onConfirm={() => onDeleteImage(confirmDelete)}
        />
      )}

      {wasUploaded && lastPic?.mediaUrl?.url && (
        <ImageCropper
          isUploading={isUploading}
          imgUrl={lastPic?.mediaUrl?.url}
          onCancel={() => {
            cancelUploadCoachProfileImage();
            setWasUploaded(false);
          }}
          onCrop={(blob) => {
            uploadCroppedCoachProfileImage(blob);
            setWasUploaded(false);
          }}
        />
      )}
      <div
        className="bold"
        style={{
          fontSize: "14px",
          lineHeight: "32px",
          color: colors.caliber_secondary,
          marginTop: 30,
          marginBottom: 15,
        }}
      >
        Images
      </div>
      <div ref={drop} className="d-flex flex-column">
        {localItems?.map((photo, index) => {
          const isEmpty = !photo?.mediaUrl?.url;
          const prevPhoto = localItems?.[index - 1];
          const isUploadable = index === 0 || !!prevPhoto?.mediaUrl?.url;
          return isEmpty ? (
            <NoImg
              key={`empty-image-${index}`}
              index={index}
              editable={editable && isUploadable}
              onUpload={() => setWasUploaded(true)}
            />
          ) : (
            <Image
              key={`not-empty-image-${photo?.mediaUrl?.id || index}`}
              mediaUrl={photo.mediaUrl}
              isDraggable={editable}
              editable={!isOver && editable}
              onDelete={() => setConfirmDelete(index)}
              moveCard={moveCard}
              findCard={findCard}
            />
          );
        })}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  uploadCroppedCoachProfileImage: (file: any) => {
    dispatch(uploadCroppedCoachProfileImage(file));
  },
  cancelUploadCoachProfileImage: () => {
    dispatch(cancelUploadCoachProfileImage());
  },
});

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  photos: ownProps.photos,
  editable: ownProps.editable,
  onChange: ownProps.onChange,

  isUploading: state.coachProfileState.isUploading,
});

export default connect(mapStateToProps, mapDispatchToProps)(Images);
