import { compareAsc } from "date-fns";
import _ from "lodash";
import {
  AdminMessage,
  FileMessage,
  GroupChannel,
  Reaction,
  UserMessage,
} from "sendbird";
import { AUTH_LOGGED_OUT } from "../../actions/Auth/types";
import {
  removeIsVideoUploadingByChannelId,
  setIsVideoUploadingByChannelId,
} from "../../actions/Messages";
import {
  CHANNEL_ACTIVE,
  CHANNEL_DEACTIVATE,
  FETCHING_MESSAGES,
  FETCHING_MESSAGES_DONE,
  MESSAGES_CHANNEL_LIST_RECEIVED,
  MESSAGES_DELETE_MESSAGE_SUCCESS,
  MESSAGES_DISCONNECTED,
  MESSAGES_MESSAGE_LIST_FAIL,
  MESSAGES_MESSAGE_LIST_LOADING,
  MESSAGES_MESSAGE_LIST_SUCCESS,
  MESSAGES_MESSAGE_RECEIVED,
  MESSAGES_SEND_MESSAGE_FAIL,
  MESSAGES_SEND_MESSAGE_START,
  MESSAGES_SEND_MESSAGE_SUCCESS,
  MessagesAction,
  MessagesState,
  REMOVE_VIDEO_UPLOAD_FAIL,
  UPDATE_MESSAGE_REACTION_SUCCESS,
  VIDEO_STARTS_UPLOADING,
  VIDEO_STARTS_UPLOADING_FAKE,
  VIDEO_UPLOAD_FAIL,
  VIDEO_UPLOAD_PROGRESS,
  VIDEO_UPLOAD_SUCCESS,
} from "../../actions/Messages/types";

const createInitialState = (): MessagesState => ({
  messageListByClient: new Map<
    string,
    (AdminMessage | UserMessage | FileMessage)[]
  >(),
  sendingMessage: false,
  activeChannel: null,
  availableChannels: [],
  isLoading: false,
  messageListQueries: {},
  fetchingError: false,
  channelsVideoUploadProgress: {},
});

const appendMessage = (
  messages: (AdminMessage | UserMessage | FileMessage)[],
  messageListByClient: Map<
    string,
    (AdminMessage | UserMessage | FileMessage)[]
  >,
  channelUrl: string
): Map<string, (AdminMessage | UserMessage | FileMessage)[]> => {
  const clientMessages = messagesWithClients(messageListByClient, channelUrl);
  const mergedMessages = _.uniqBy(
    [...messages, ...clientMessages.get(channelUrl)],
    (item: AdminMessage | UserMessage | FileMessage) => {
      return item.messageId;
    }
  );

  clientMessages.set(channelUrl, mergedMessages);
  return new Map(clientMessages);
};

/**
 * Utility helper to insert client if it doesn't exist in the map
 * @param messageListByClient
 * @param channelUrl
 */
const messagesWithClients = (
  messageListByClient: Map<
    string,
    (AdminMessage | UserMessage | FileMessage)[]
  >,
  channelUrl: string
): Map<string, (AdminMessage | UserMessage | FileMessage)[]> => {
  if (!messageListByClient.has(channelUrl)) {
    messageListByClient.set(channelUrl, []);
  }
  return messageListByClient;
};

const sortChannels = (channels: GroupChannel[]) => {
  channels.sort((a, b) => {
    if (a.lastMessage == null) {
      return 0;
    }
    if (b.lastMessage == null) {
      return 0;
    }
    return compareAsc(
      new Date(b.lastMessage.createdAt),
      new Date(a.lastMessage.createdAt)
    );
  });
  return channels;
};

const updateChannel = (channel: GroupChannel, channels: GroupChannel[]) => {
  const updateChannels = channels.filter(
    (channel) => channel.url !== channel.url
  );
  updateChannels.push(channel);
  return sortChannels(channels);
};

const updateLastChannelMessage = (
  message: AdminMessage | UserMessage | FileMessage,
  channel: GroupChannel,
  channels: GroupChannel[]
) => {
  if (message) {
    // eslint-disable-next-line no-param-reassign
    channel.lastMessage = message;
  }
  return updateChannel(channel, channels);
};

const updateMessageReact = (
  messageListByClient: Map<
    string,
    (AdminMessage | UserMessage | FileMessage)[]
  >,
  channelUrl: string,
  messageId: number,
  emojiKey: string,
  userId: string,
  isAdding: boolean
) => {
  const clientMessages = messagesWithClients(messageListByClient, channelUrl);
  const updatedReactionMessages = [...clientMessages.get(channelUrl)];
  const message = updatedReactionMessages.find(
    (message) => message.messageId === messageId
  );

  if (message) {
    let reaction: Reaction = message.reactions.find(
      (reaction) => reaction.key === emojiKey
    );
    if (reaction) {
      reaction.userIds = reaction.userIds.filter((id) => id !== userId);
      if (isAdding) {
        reaction.userIds.push(userId);
      }
    } else if (isAdding) {
      reaction = {
        key: emojiKey,
        userIds: [userId],
        updatedAt: new Date().getTime(),
      } as Reaction;
    }
    // Prevent Dupes / Or remove
    message.reactions = message.reactions.filter(
      (r) => r.key !== reaction?.key
    );
    if (reaction && reaction.userIds.length > 0) {
      message.reactions.push(reaction);
    }
    // Sort by Keys
    message.reactions = message.reactions.sort((a, b) => {
      return a.key.localeCompare(b.key);
    });
  }
  clientMessages.set(channelUrl, updatedReactionMessages);
  return new Map(clientMessages);
};

export const messagesState = (
  state = createInitialState(),
  action: MessagesAction
) => {
  switch (action.type) {
    case FETCHING_MESSAGES: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCHING_MESSAGES_DONE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case VIDEO_UPLOAD_SUCCESS: {
      removeIsVideoUploadingByChannelId(action.channelId);
      const newChannels = { ...state.channelsVideoUploadProgress };
      delete newChannels[action.channelId];
      return {
        ...state,
        channelsVideoUploadProgress: newChannels,
        isMediaUploading: false,
        isMediaFakeUploading: false,
      };
    }
    case REMOVE_VIDEO_UPLOAD_FAIL: {
      removeIsVideoUploadingByChannelId(action.channelId);
      const newChannels = { ...state.channelsVideoUploadProgress };
      delete newChannels[action.channelId];
      return {
        ...state,
        isMediaUploading: false,
        isMediaFakeUploading: false,
        progress: null,
        channelsVideoUploadProgress: newChannels,
      };
    }
    case VIDEO_UPLOAD_FAIL: {
      return {
        ...state,
        isMediaUploading: false,
        isMediaFakeUploading: false,
        channelsVideoUploadProgress: {
          ...state.channelsVideoUploadProgress,
          [action.channelId]: -1,
        },
      };
    }
    case VIDEO_STARTS_UPLOADING: {
      setIsVideoUploadingByChannelId(action.channelId);
      return {
        ...state,
        isMediaUploading: true,
        channelsVideoUploadProgress: {
          ...state.channelsVideoUploadProgress,
          [action.channelId]: 0,
        },
      };
    }
    case VIDEO_STARTS_UPLOADING_FAKE: {
      setIsVideoUploadingByChannelId(action.channelId);
      return {
        ...state,
        isMediaFakeUploading: true,
        channelsVideoUploadProgress: {
          ...state.channelsVideoUploadProgress,
          [action.channelId]: 0,
        },
      };
    }
    case VIDEO_UPLOAD_PROGRESS: {
      return {
        ...state,
        channelsVideoUploadProgress: {
          ...state.channelsVideoUploadProgress,
          [action.channelId]: action.progress,
        },
      };
    }
    case AUTH_LOGGED_OUT:
    case MESSAGES_DISCONNECTED:
      return {
        ...createInitialState(),
      };
    case CHANNEL_ACTIVE:
      return {
        ...state,
        isLoading: false,
        activeChannel: action.activeChannel,
      };
    case CHANNEL_DEACTIVATE:
      return {
        ...state,
        isLoading: false,
        activeChannel: null,
      };
    case MESSAGES_CHANNEL_LIST_RECEIVED:
      return {
        ...state,
        isLoading: false,
        availableChannels: sortChannels(action.channels),
      };
    case MESSAGES_MESSAGE_RECEIVED:
      if (action.channel === state.activeChannel && !action.isMasquerading) {
        if (action?.channel?.unreadMessageCount) {
          // eslint-disable-next-line no-param-reassign
          action.channel.unreadMessageCount = 0;
        }
      }
      return {
        ...state,
        isLoading: false,
        messageListByClient: appendMessage(
          [action.message],
          state.messageListByClient,
          action.channel.url
        ),
        channels: [
          ...updateLastChannelMessage(
            action.message,
            action.channel,
            state.availableChannels
          ),
        ],
      };
    case MESSAGES_MESSAGE_LIST_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case MESSAGES_MESSAGE_LIST_FAIL:
      return {
        ...state,
        isLoading: false,
        fetchingError: true,
      };
    case MESSAGES_MESSAGE_LIST_SUCCESS:
      if (action.shouldMarkAsRead) {
        if (state?.activeChannel?.unreadMessageCount) {
          // eslint-disable-next-line no-param-reassign
          state.activeChannel.unreadMessageCount = 0;
        }
      }
      const queriesCopy = { ...state.messageListQueries };
      if (action.messageListQuery && state?.activeChannel?.url) {
        queriesCopy[state.activeChannel.url] = action.messageListQuery;
      }
      return {
        ...state,
        isLoading: false,
        messageListByClient: appendMessage(
          action.messageList,
          state.messageListByClient,
          action.channel.url
        ),
        channels: [
          ...updateLastChannelMessage(
            action.message,
            state.activeChannel ? state.activeChannel : action.channel,
            state.availableChannels
          ),
        ],
        messageListQueries: queriesCopy,
        fetchingError: false,
      };
    case MESSAGES_SEND_MESSAGE_START:
      return {
        ...state,
        sendingMessage: true,
      };
    case MESSAGES_SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        sendingMessage: false,
        messageListByClient: appendMessage(
          [action.message],
          state.messageListByClient,
          action.channel.url
        ),
        channels: [
          ...updateLastChannelMessage(
            action.message,
            action.channel,
            state.availableChannels
          ),
        ],
      };
    case MESSAGES_SEND_MESSAGE_FAIL:
      return {
        ...state,
        isLoading: false,
        sendingMessage: false,
      };
    case UPDATE_MESSAGE_REACTION_SUCCESS:
      return {
        ...state,
        messageListByClient: updateMessageReact(
          state.messageListByClient,
          action.channel.url,
          action.messageId,
          action.emojiKey,
          action.reactionUserId,
          action.isAddingReaction
        ),
      };
    case MESSAGES_DELETE_MESSAGE_SUCCESS:
      const clientMessageList = messagesWithClients(
        state.messageListByClient,
        state.activeChannel.url
      );
      // eslint-disable-next-line eqeqeq
      clientMessageList.set(
        state.activeChannel.url,
        clientMessageList
          .get(state.activeChannel.url)
          .filter((message) => message.messageId != action.message.messageId)
      );
      return {
        ...state,
        isLoading: false,
        messageListByClient: new Map(clientMessageList),
      };
    default:
      return {
        ...state,
      };
  }
};
