import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { createHttpLink } from "apollo-link-http";
import { getAppConfig } from "../../config";
import { OPEN_ERROR_DIALOG } from "../../redux/actions/ErrorSuccessDialog/types";
import { CaliberError } from "../../types/gqlTypes";
import * as Sentry from "@sentry/react";

const apolloClient = async (token, dispatch = null) => {
  const apiUrl = `${getAppConfig().apiUrl}/graphql`;

  // eslint-disable-next-line import/no-unresolved,global-require
  const introspectionQueryResultData = require("../gql/fragmentTypes");

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  });

  const httpLink = createHttpLink({
    uri: apiUrl,
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        Sentry.captureMessage(error.message, { level: "error" });
      });
    }
    if (networkError) {
      Sentry.captureException(networkError);
    }
    if (
      graphQLErrors?.[0]?.extensions?.errorName !==
        CaliberError.CustomTrainingPlanExists &&
      graphQLErrors?.[0]?.extensions?.errorName !==
        CaliberError.CoachProfileNotFound
    ) {
      dispatch?.({
        type: OPEN_ERROR_DIALOG,
        graphQLErrors,
      });
    }
    if (
      graphQLErrors?.length > 0 &&
      graphQLErrors?.[0]?.extensions?.errorName === "ACCESS_DENIED"
    ) {
      console.error("Access Denied!", graphQLErrors);
    }
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    };
  });

  const links = authLink.concat(errorLink).concat(httpLink);

  return new ApolloClient({
    link: links,
    cache: new InMemoryCache({ fragmentMatcher }),
  });
};

export default apolloClient;
