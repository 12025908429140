import {
  RulesAction,
  RulesState,
  RulesActionType,
} from "../../actions/Rules/types";

const initialState: RulesState = {
  isLoading: false,
  rules: null,
};

const rulesState = (state = initialState, action: RulesAction) => {
  switch (action.type) {
    case RulesActionType[RulesActionType.GET_RULES_LOADING]:
      return {
        ...state,
        isLoading: true,
      } as RulesState;
    case RulesActionType[RulesActionType.GET_RULES_SUCCESS]: {
      return {
        ...state,
        isLoading: false,
        rules: action.ruleList,
      } as RulesState;
    }
    case RulesActionType[RulesActionType.GET_RULES_FAIL]:
      return {
        ...state,
        isLoading: false,
        rules: null,
      } as RulesState;
    default:
      return state;
  }
};

export default rulesState;
