import { AUTH_LOGGED_OUT } from "../../actions/Auth/types";
import {
  MasqueradeAction,
  MasqueradeState,
  MASQUERADE_DISABLED,
  MASQUERADE_ENABLED,
  MASQUERADE_SAVE_AUTO_MESSAGES_SUCCESS,
  MASQUERADE_USER_TIMEZONE_SAVE_SUCCESS,
} from "../../actions/Masquerade/types";
import { RESET_USER_DATA } from "../../actions/User/types";

const initialState: MasqueradeState = {
  masqueradeTrainer: null,
};

const masqueradeState = (
  state = initialState,
  action: MasqueradeAction
): MasqueradeState => {
  switch (action.type) {
    case MASQUERADE_ENABLED:
      return {
        ...state,
        masqueradeTrainer: action.trainer,
      };
    case MASQUERADE_USER_TIMEZONE_SAVE_SUCCESS:
      return {
        ...state,
        masqueradeTrainer: {
          ...state.masqueradeTrainer,
          timezone: action.trainer.timezone,
        },
      };
    case MASQUERADE_SAVE_AUTO_MESSAGES_SUCCESS:
      return {
        ...state,
        masqueradeTrainer: {
          ...state.masqueradeTrainer,
          settings: {
            ...state.masqueradeTrainer.settings,
            ...action.trainerSettings,
          },
        },
      };
    case MASQUERADE_DISABLED:
    case AUTH_LOGGED_OUT:
    case RESET_USER_DATA:
      return {
        ...state,
        masqueradeTrainer: null,
      };
  }
  return state;
};

export default masqueradeState;
