import { gql } from "apollo-boost";

export const medialUrlFragment = gql`
  fragment MediaUrl on MediaUrl {
    id
    mimeType
    type
    url
  }
`;

export const exerciseMediaFragment = gql`
  ${medialUrlFragment}
  fragment ExerciseMedia on ExerciseMedia {
    id
    empty
    thumbnailUrl {
      ...MediaUrl
    }
    heroImageUrl {
      ...MediaUrl
    }
    videoLesson {
      id
      videoLessonUrl {
        ...MediaUrl
      }
      thumbnailUrl {
        ...MediaUrl
      }
      chapters {
        id
        marker
        number
        title
      }
    }
    videoUrl {
      ...MediaUrl
    }
  }
`;

export const exerciseFragment = gql`
  ${exerciseMediaFragment}
  fragment ExerciseProperty on Exercise {
    id
    isStrengthScoreKeyExercise
    isCustomExercise
    media {
      ...ExerciseMedia
    }
    name
    targetBase
  }
`;

const exerciseSetFragment = gql`
  fragment ExerciseSet on ExerciseSet {
    id
    setId
    sortOrder
    actualReps
    actualTime
    actualWeight
  }
`;

export const exerciseGymStepFragment = gql`
  ${exerciseFragment}
  ${exerciseSetFragment}
  fragment ClientGymStep on ClientGymStep {
    id
    completionDate
    sortOrder
    status
    target
    hideWeightForExerciseSets
    targetRepsLowerBound
    targetRepsUpperBound
    restPeriod
    maxGymStepStatistics {
      type
      value
    }
    exercise {
      ...ExerciseProperty
    }
    exerciseSets {
      ...ExerciseSet
    }
    mostRecentlyCompletedExerciseSteps {
      ...ExerciseSet
    }
  }
`;

export const workoutCalendarItemFragment = gql`
  fragment WorkoutCalendarItem on WorkoutCalendarItem {
    clientWorkoutDate
    daySortOrder
    enumType
    id
    sort
    status
    title
    type
    rating
    notes
  }
`;

export const clientWorkoutCalendarItemFragment = gql`
  ${exerciseSetFragment}
  ${medialUrlFragment}
  fragment ClientWorkoutForCalendar on ClientWorkout {
    id
    achievements {
      exercise {
        id
        name
      }
      exerciseAchievements {
        type
        value
      }
    }
    sourceTemplate {
      id
    }
    sortOrder
    title
    gymSteps {
      id
      sortOrder
      ... on ClientGymStep {
        status
        completionDate
        target
        restPeriod
        exercise {
          id
          name
          targetBase
          isCustomExercise
          media {
            thumbnailUrl {
              ...MediaUrl
            }
          }
        }
        exerciseSets {
          ...ExerciseSet
        }
      }
      ... on ClientSupersetGymStep {
        status
        clientGymSteps {
          id
          status
          completionDate
          target
          restPeriod
          exercise {
            id
            name
            targetBase
            isCustomExercise
            media {
              thumbnailUrl {
                ...MediaUrl
              }
            }
          }
          exerciseSets {
            ...ExerciseSet
          }
        }
      }
    }
    workoutCalendarItem {
      clientWorkoutDate
      daySortOrder
      enumType
      id
      sort
      status
      title
      type
    }
  }
`;

export const macronutrientEventFragment = gql`
  fragment MacronutrientEvent on MacronutrientEvent {
    date
    id
    value
    unit
    macronutrient
    nutritionSyncSourceType
  }
`;

const calendarItemFragment = gql`
  ${macronutrientEventFragment}
  ${medialUrlFragment}
  ${clientWorkoutCalendarItemFragment}
  fragment CalendarItem on CalendarItem {
    daySortOrder
    enumType
    id
    sort
    status
    title
    type
    ... on BodyStatCalendarItem {
      title
      type
      status
      sort
      id
      daySortOrder
      enumType
      bodyMeasurementType
      bodyMeasurementValue
    }
    ... on ImportedBodyStatCalendarItem {
      title
      type
      status
      sort
      id
      daySortOrder
      enumType
      bodyMeasurementType
      bodyMeasurementValue
    }
    ... on CardioActivityCalendarItem {
      cardioActivityType
      calories
      distance
      level
      speed
      time
      status
    }
    ... on ImportedCardioActivityCalendarItem {
      averageHeartRate
      averagePace
      calories
      cardioActivityType
      daySortOrder
      distance
      elevationGain
      enumType
      id
      level
      sort
      speed
      status
      time
      title
      type
      source
    }
    ... on ProgressPhotoCalendarItem {
      status
      photos {
        id
        pose
        poseMediaUrl {
          ...MediaUrl
        }
      }
    }
    ... on WorkoutCalendarItem {
      status
      clientWorkout {
        ...ClientWorkoutForCalendar
      }
    }
    ... on ImportedNutritionCalendarItem {
      daySortOrder
      enumType
      id
      macronutrientEvents {
        ...MacronutrientEvent
      }
      sort
      status
      title
      type
      source
    }
  }
`;

export const SHALLOW_CALENDAR_ITEM_FRAGMENT = gql`
  fragment ShallowCalendarItem on CalendarItem {
    daySortOrder
    enumType
    id
    sort
    status
    title
    type
    recurring
    scheduledBy
    recurringEventDefinition {
      id
      RRule
      startDate
      endDate
      type
      recurringEventExceptions {
        date
        id
        recurrenceType
      }
    }
    ... on WorkoutCalendarItem {
      clientWorkout {
        id
        clientGymSteps {
          id
          status
        }
      }
      recurringEventDefinition {
        id
      }
    }
    ... on CardioActivityCalendarItem {
      cardioActivityType
    }
    ... on ImportedCardioActivityCalendarItem {
      cardioActivityType
    }
    ... on ImportedBodyStatCalendarItem {
      bodyMeasurementType
      bodyMeasurementValue
    }
    ... on BodyStatCalendarItem {
      bodyMeasurementType
      bodyMeasurementValue
    }
    ... on RecurringWorkoutCalendarItem {
      clientWorkout {
        id
        gymSteps {
          id
          sortOrder
          ... on ClientGymStep {
            status
          }
          ... on ClientSupersetGymStep {
            clientGymSteps {
              id
              status
              sortOrder
            }
          }
        }
      }
      clientWorkoutTemplate {
        id
        title
        gymSteps {
          id
          sortOrder
          ... on ClientSupersetGymStepTemplate {
            clientGymStepTemplates {
              id
              sortOrder
            }
          }
        }
        thumbnailImage {
          url
        }
      }
    }
    ... on RecurringCardioActivityCalendarItem {
      cardioActivityType
    }
    ... on RecurringBodyStatCalendarItem {
      bodyMeasurementType
    }
    ... on HabitCalendarItem {
      habitType
      quantity
      target
    }
    ... on ImportedHabitCalendarItem {
      habitType
      quantity
      target
    }
    ... on RecurringHabitCalendarItem {
      habitType
      quantity
      target
    }
  }
`;

export const FETCH_SHALLOW_CALENDAR_ITEMS = gql`
  ${SHALLOW_CALENDAR_ITEM_FRAGMENT}
  query Calendar($clientId: UUID!, $startDate: LocalDate, $endDate: LocalDate) {
    calendarDateRange(
      clientId: $clientId
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      date
      items {
        ...ShallowCalendarItem
      }
    }
  }
`;
export const FETCH_DETAILED_CALENDAR_ITEMS = gql`
  ${calendarItemFragment}
  query Calendar($clientId: UUID!, $startDate: LocalDate, $endDate: LocalDate) {
    calendarDateRange(
      clientId: $clientId
      startDate: $startDate
      endDate: $endDate
    ) {
      date
      items {
        ...CalendarItem
      }
    }
  }
`;
export const FETCH_BODY_STAT_CALENDAR_ITEMS = gql`
  query BodyStatCalendarItems(
    $clientId: UUID!
    $startDate: LocalDate
    $endDate: LocalDate
  ) {
    calendarDateRange(
      clientId: $clientId
      startDate: $startDate
      endDate: $endDate
    ) {
      date
      items {
        id
        daySortOrder
        sort
        ... on BodyStatCalendarItem {
          title
          type
          status
          sort
          id
          daySortOrder
          enumType
          bodyMeasurementType
          bodyMeasurementValue
        }
        ... on ImportedBodyStatCalendarItem {
          title
          type
          status
          sort
          id
          daySortOrder
          enumType
          bodyMeasurementType
          bodyMeasurementValue
        }
      }
    }
  }
`;
export const FETCH_NUTRITION_CALENDAR_ITEMS = gql`
  ${macronutrientEventFragment}
  query ImportedNutritionCalendarItem(
    $clientId: UUID!
    $startDate: LocalDate
    $endDate: LocalDate
  ) {
    calendarDateRange(
      clientId: $clientId
      startDate: $startDate
      endDate: $endDate
    ) {
      date
      items {
        ... on ImportedNutritionCalendarItem {
          daySortOrder
          enumType
          id
          macronutrientEvents {
            ...MacronutrientEvent
          }
          sort
          status
          title
          type
          source
        }
      }
    }
    clientNutritionTarget(clientId: $clientId) {
      calories
      protein
      carbs
      fat
      calories7DayAverage
      protein7DayAverage
      fat7DayAverage
      carbs7DayAverage
    }
  }
`;

export const FETCH_PROGRESS_PHOTO_CALENDAR_ITEMS = gql`
  query ProgressPhotoCalendarItems(
    $clientId: UUID!
    $startDate: LocalDate
    $endDate: LocalDate
  ) {
    calendarDateRange(
      clientId: $clientId
      startDate: $startDate
      endDate: $endDate
    ) {
      date
      items {
        ... on ProgressPhotoCalendarItem {
          daySortOrder
          enumType
          id
          sort
          type
          photos {
            id
            pose
            poseMediaUrl {
              id
              url
            }
          }
        }
      }
    }
  }
`;

export const FETCH_CALENDAR_ITEM = gql`
  ${calendarItemFragment}
  query CalendarItem($calendarItemId: UUID, $clientId: UUID) {
    calendarItem(calendarItemId: $calendarItemId, clientId: $clientId) {
      ...CalendarItem
    }
    clientNutritionTarget(clientId: $clientId) {
      calories
      protein
      carbs
      fat
    }
  }
`;

export const CREATE_WORKOUT_CALENDAR_ITEM = gql`
  ${clientWorkoutCalendarItemFragment}
  mutation CreateWorkoutCalendarItem(
    $clientId: UUID!
    $clientWorkoutTemplateId: UUID!
    $dayOfMonth: Int!
    $month: Int!
    $year: Int!
  ) {
    createWorkoutCalendarItem(
      clientId: $clientId
      clientWorkoutTemplateId: $clientWorkoutTemplateId
      dayOfMonth: $dayOfMonth
      month: $month
      year: $year
    ) {
      daySortOrder
      sort
      id
      type
      enumType
      sort
      status
      title
      clientWorkout {
        ...ClientWorkoutForCalendar
      }
    }
  }
`;
export const CREATE_CLIENT_WORKOUT_TEMPLATE_RECURRING_EVENT_DEFINITION = gql`
  mutation CreateClientWorkoutTemplateRecurringEventDefinition(
    $clientId: UUID!
    $clientWorkoutTemplateId: UUID!
    $endDate: LocalDate
    $rRule: String!
    $startDate: LocalDate!
  ) {
    createClientWorkoutTemplateRecurringEventDefinition(
      clientId: $clientId
      clientWorkoutTemplateId: $clientWorkoutTemplateId
      endDate: $endDate
      rRule: $rRule
      startDate: $startDate
    ) {
      id
    }
  }
`;
export const CREATE_CARDIO_CALENDAR_ITEM = gql`
  mutation CreateCardioActivityCalendarItem(
    $clientId: UUID!
    $cardioActivityType: CardioActivityType!
    $dayOfMonth: Int!
    $month: Int!
    $year: Int!
  ) {
    createCardioActivityCalendarItem(
      clientId: $clientId
      cardioActivityType: $cardioActivityType
      dayOfMonth: $dayOfMonth
      month: $month
      year: $year
    ) {
      daySortOrder
      sort
      id
      type
      enumType
      sort
      status
      title
      cardioActivityType
    }
  }
`;
export const CREATE_CARDIO_ACTIVITY_RECURRING_EVENT_DEFINITION = gql`
  mutation CreateCardioActivityRecurringEventDefinition(
    $cardioActivityType: CardioActivityType!
    $clientId: UUID!
    $endDate: LocalDate
    $rRule: String!
    $startDate: LocalDate!
  ) {
    createCardioActivityRecurringEventDefinition(
      cardioActivityType: $cardioActivityType
      clientId: $clientId
      endDate: $endDate
      rRule: $rRule
      startDate: $startDate
    ) {
      id
    }
  }
`;
export const CREATE_BODY_STAT_CALENDAR_ITEM = gql`
  mutation CreateBodyStatCalendarItem(
    $clientId: UUID!
    $recurringEventDefinitionId: UUID
    $bodyMeasurementType: BodyMeasurementType
    $bodyMeasurementValue: Float
    $dayOfMonth: Int!
    $month: Int!
    $year: Int!
    $status: CalendarItemStatusType
  ) {
    createBodyStatCalendarItem(
      clientId: $clientId
      recurringEventDefinitionId: $recurringEventDefinitionId
      bodyMeasurementType: $bodyMeasurementType
      bodyMeasurementValue: $bodyMeasurementValue
      dayOfMonth: $dayOfMonth
      month: $month
      year: $year
      status: $status
    ) {
      daySortOrder
      sort
      id
      type
      enumType
      sort
      status
      title
      bodyMeasurementType
      bodyMeasurementValue
    }
  }
`;

export const CREATE_BODY_STAT_RECURRING_EVENT_DEFINITION = gql`
  mutation CreateBodyStatRecurringEventDefinition(
    $clientId: UUID!
    $bodyMeasurementType: BodyMeasurementType!
    $endDate: LocalDate!
    $startDate: LocalDate!
    $rRule: String!
  ) {
    createBodyStatRecurringEventDefinition(
      clientId: $clientId
      bodyMeasurementType: $bodyMeasurementType
      endDate: $endDate
      startDate: $startDate
      rRule: $rRule
    ) {
      id
    }
  }
`;
export const CREATE_HABIT_CALENDAR_ITEM = gql`
  mutation CreateHabitCalendarItem(
    $clientId: UUID!
    $recurringEventDefinitionId: UUID
    $habitType: HabitType!
    $dayOfMonth: Int!
    $month: Int!
    $year: Int!
    $quantity: Int!
  ) {
    createHabitCalendarItem(
      clientId: $clientId
      recurringEventDefinitionId: $recurringEventDefinitionId
      habitType: $habitType
      dayOfMonth: $dayOfMonth
      month: $month
      year: $year
      quantity: $quantity
    ) {
      id
    }
  }
`;

export const CREATE_HABIT_RECURRING_EVENT_DEFINITION = gql`
  mutation CreateHabitRecurringEventDefinition(
    $clientId: UUID!
    $endDate: LocalDate
    $startDate: LocalDate!
    $habitType: HabitType!
    $rRule: String!
  ) {
    createHabitRecurringEventDefinition(
      clientId: $clientId
      habitType: $habitType
      endDate: $endDate
      startDate: $startDate
      rRule: $rRule
    ) {
      id
    }
  }
`;
export const CREATE_PROGRESS_PHOTO_CALENDAR_ITEM = gql`
  mutation CreateProgressPhotoCalendarItem(
    $clientId: UUID!
    $dayOfMonth: Int!
    $month: Int!
    $year: Int!
  ) {
    createProgressPhotoCalendarItem(
      clientId: $clientId
      dayOfMonth: $dayOfMonth
      month: $month
      year: $year
    ) {
      daySortOrder
      sort
      id
      type
      enumType
      sort
      status
      title
    }
  }
`;
export const CREATE_PROGRESS_PHOTO_RECURRING_EVENT_DEFINITION = gql`
  mutation CreateProgressPhotoRecurringEventDefinition(
    $clientId: UUID!
    $endDate: LocalDate
    $rRule: String!
    $startDate: LocalDate!
  ) {
    createProgressPhotoRecurringEventDefinition(
      clientId: $clientId
      endDate: $endDate
      rRule: $rRule
      startDate: $startDate
    ) {
      id
    }
  }
`;
export const DELETE_CALENDAR_ITEM = gql`
  mutation DeleteCalendarItem($clientId: UUID, $calendarItemId: UUID) {
    deleteCalendarItem(clientId: $clientId, calendarItemId: $calendarItemId)
  }
`;
export const DELETE_RECURRING_EVENT = gql`
  mutation DeleteRecurringEvent(
    $clientId: UUID!
    $date: LocalDate!
    $recurringEventDefinitionId: UUID!
  ) {
    deleteRecurringEvent(
      clientId: $clientId
      date: $date
      recurringEventDefinitionId: $recurringEventDefinitionId
    )
  }
`;

export const DELETE_RECURRING_EVENT_DEFINITION = gql`
  mutation DeleteRecurringEventDefinition(
    $clientId: UUID!
    $recurringEventDefinitionId: UUID!
  ) {
    deleteRecurringEventDefinition(
      clientId: $clientId
      recurringEventDefinitionId: $recurringEventDefinitionId
    )
  }
`;
export const UPDATE_RECURRING_EVENT_DEFINITION = gql`
  mutation UpdateRecurringEventDefinition(
    $clientId: UUID!
    $endDate: LocalDate
    $rRule: String!
    $recurringEventDefinitionId: UUID!
  ) {
    updateRecurringEventDefinition(
      clientId: $clientId
      endDate: $endDate
      rRule: $rRule
      recurringEventDefinitionId: $recurringEventDefinitionId
    ) {
      id
    }
  }
`;

export const MOVE_CALENDAR_ITEM = gql`
  ${SHALLOW_CALENDAR_ITEM_FRAGMENT}
  mutation MoveCalendarItem(
    $clientId: UUID
    $calendarItemId: UUID
    $date: LocalDate
  ) {
    moveCalendarItem(
      clientId: $clientId
      calendarItemId: $calendarItemId
      date: $date
    ) {
      date
      id
      items {
        ...ShallowCalendarItem
      }
    }
  }
`;
export const MOVE_RECURRING_EVENT = gql`
  mutation MoveRecurringEvent(
    $clientId: UUID!
    $currentDate: LocalDate!
    $newDate: LocalDate!
    $recurringEventDefinitionId: UUID!
  ) {
    moveRecurringEvent(
      clientId: $clientId
      currentDate: $currentDate
      newDate: $newDate
      recurringEventDefinitionId: $recurringEventDefinitionId
    )
  }
`;
