import React, { useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import colors from "../../../assets/colors";
import {
  hideExercisesInPlan,
  openWorkoutsTrainingPlan,
  showExercisesInPlan,
} from "../../../redux/actions/TrainingPrograms";
import { deleteGymStepInPlan } from "../../../redux/actions/TrainingPrograms/delete";
import {
  getIsAdmin,
  getIsMasquerade,
  StoreState,
} from "../../../redux/reducers";
import {
  selectCurrentGymSteps,
  selectCurrentSuperset,
} from "../../../redux/reducers/TrainingPrograms/selectors";
import {
  GymStep,
  MutationUpdateSupersetArgs,
  Superset,
} from "../../../types/gqlTypes";
import { ProgramType } from "../../TrainingProgram";
import EditExercise from "../../EditExercise";
import SupersetIcon from "../../../assets/images/SupersetIcon.svg";
import SupersetExerciseList from "../SupersetExerciseList";
import { updateSuperset } from "../../../redux/actions/TrainingPrograms/supersets";
import MeatballsMenu from "../../MeatballsMenu";
import ConfirmationDialog from "../../ConfirmationDialog";

interface OwnProps {}

interface Props extends OwnProps {
  updateSuperset: (args: MutationUpdateSupersetArgs) => void;
  deleteGymStep: (args: { id: string }) => void;
  openWorkouts: () => void;
  onToggleDragging?: (value: boolean) => void;
  showExercisesInPlan: () => void;
  hideExercisesInPlan: () => void;
  programType: ProgramType;
  isMasquerade: boolean;
  isAdmin: boolean;
  superset: Superset;
  highlightDropZone: boolean;
  showWorkoutExerciseList: boolean;
  showExerciseLibrary: boolean;
}

const SupersetEditor = (props: Props) => {
  const {
    updateSuperset,
    deleteGymStep,
    onToggleDragging,
    showExercisesInPlan,
    hideExercisesInPlan,
    programType,
    isMasquerade,
    isAdmin,
    superset,
    highlightDropZone,
    showWorkoutExerciseList,
    showExerciseLibrary,
  } = props;

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [showMeatball, setShowMeatball] = useState(false);
  const numberOfExercises = superset?.gymSteps?.length;
  const { id: clientId } = useParams();
  let canBeChanged = true;
  if (programType === ProgramType.Master) {
    // only admins with no Masquerade and in Program Library component
    canBeChanged = isAdmin && !isMasquerade && !clientId;
  }
  const [showEditExercise, setShowEditExercise] = useState(canBeChanged);
  const meatballsOptions = [];

  if (showEditExercise) {
    meatballsOptions.push({
      text: "Hide Editor",
      onClick: () => setShowEditExercise(false),
    });
  } else {
    meatballsOptions.push({
      text: "Show Editor",
      onClick: () => setShowEditExercise(true),
    });
  }

  if (showExerciseLibrary) {
    if (showWorkoutExerciseList) {
      meatballsOptions.push({
        text: "Hide Workout",
        onClick: () => {
          hideExercisesInPlan();
        },
      });
    } else {
      meatballsOptions.push({
        text: "Show Workout",
        onClick: () => {
          showExercisesInPlan();
        },
      });
    }
  }
  meatballsOptions.push({
    text: "Delete",
    onClick: () => setConfirmDialog(true),
  });

  const style: React.CSSProperties = {
    borderRadius: 8,
    paddingLeft: 4,
    transform: "translate(0, 0)",
    backgroundColor: colors.caliber_white,
    paddingRight: canBeChanged ? 1 : 8,
  };

  return (
    <div
      className="d-flex"
      style={{
        flex: 1,
      }}
    >
      <div
        className="d-flex flex-column"
        style={{
          flex: "1 1 50%",
          marginRight: 12,
        }}
      >
        <div className="d-flex flex-column">
          <div
            className="d-flex flex-column"
            style={{
              flex: 1,
              backgroundColor: colors.caliber_white,
              borderRadius: 12,
            }}
          >
            <div
              className="d-flex flex-column flex-nowrap nofocus"
              style={style}
              onMouseEnter={() => {
                setShowMeatball(true);
              }}
              onMouseLeave={() => {
                setShowMeatball(false);
              }}
            >
              {confirmDialog && (
                <ConfirmationDialog
                  cancelButtonText="Cancel"
                  show={confirmDialog}
                  text="Do you really want to delete this superset?"
                  onCancel={() => setConfirmDialog(false)}
                  onConfirm={() => {
                    deleteGymStep({ id: superset?.id });
                    setConfirmDialog(false);
                  }}
                />
              )}
              <div
                className="d-flex"
                style={{
                  flex: 1,
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: 24,
                    height: 24,
                    margin: 8,
                    borderRadius: 8,
                  }}
                >
                  <img
                    style={{
                      width: 24,
                      height: 24,
                      borderRadius: 8,
                    }}
                    src={SupersetIcon}
                    alt="Thumbnail"
                  />
                </div>
                <div
                  className="d-flex flex-column"
                  style={{
                    marginLeft: 10,
                    flex: "1 1 0",
                  }}
                >
                  <div
                    className="d-flex justify-content-between align-items-center"
                    style={{
                      flex: "1 1 0",
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <div
                        className="bold"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          marginRight: 10,
                          fontSize: "16px",
                          lineHeight: "15px",
                          justifyContent: "center",
                        }}
                      >
                        Superset {numberOfExercises === 1 ? "⚠️" : ""}
                      </div>
                      <div
                        className="d-flex align-items-center"
                        style={{
                          marginTop: 10,
                          marginRight: 10,
                        }}
                      >
                        {typeof numberOfExercises === "number" && (
                          <div
                            className="align-items-center bold badgeStyleGray"
                            style={{
                              marginBottom: 5,
                            }}
                          >
                            {`${numberOfExercises} Exercise${
                              numberOfExercises === 1 ? "" : "s"
                            }`}
                          </div>
                        )}
                      </div>

                      {!showEditExercise && (
                        <div
                          className="d-flex align-items-center"
                          style={{
                            marginTop: 10,
                            marginRight: 10,
                          }}
                        >
                          <div
                            className="align-items-center bold badgeStyleGray"
                            style={{
                              marginBottom: 5,
                            }}
                          >
                            {superset.targetSets} sets
                          </div>
                        </div>
                      )}

                      {!showEditExercise && (
                        <div
                          className="d-flex align-items-center"
                          style={{
                            marginTop: 10,
                            marginRight: 10,
                          }}
                        >
                          {typeof numberOfExercises === "number" && (
                            <div
                              className="align-items-center bold badgeStyleGray"
                              style={{
                                marginBottom: 5,
                              }}
                            >
                              Rest for {superset.restPeriod} min
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    {canBeChanged && showMeatball && (
                      <div
                        style={{
                          position: "absolute",
                          right: 8,
                          top: 5,
                        }}
                      >
                        <MeatballsMenu items={meatballsOptions} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showEditExercise && (
          <EditExercise
            id={superset?.id}
            gymStep={superset as GymStep}
            onCancelEdit={() => setShowEditExercise(false)}
            onSave={(args) => {
              updateSuperset(args);
              setShowEditExercise(false);
            }}
            isSuperset
          />
        )}

        <SupersetExerciseList
          gymSteps={(superset as Superset)?.gymSteps}
          // @ts-ignore
          canBeChanged
          onToggleDragging={onToggleDragging}
          highlightDropZone={highlightDropZone}
        />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateSuperset: (args: MutationUpdateSupersetArgs) => {
    dispatch(updateSuperset(args));
  },
  deleteGymStep: (args: { id: string }) => {
    dispatch(deleteGymStepInPlan(args));
    dispatch(openWorkoutsTrainingPlan());
  },
  openWorkouts: () => {
    dispatch(openWorkoutsTrainingPlan());
  },
  showExercisesInPlan: () => {
    dispatch(showExercisesInPlan());
  },
  hideExercisesInPlan: () => {
    dispatch(hideExercisesInPlan());
  },
});

const mapStateToProps = (state: StoreState) => ({
  gymSteps: selectCurrentGymSteps(state),
  showExerciseLibrary: state.trainingProgramsState.showExerciseLibrary,
  showWorkoutExerciseList: state.trainingProgramsState.showWorkoutExerciseList,
  programType: state.trainingProgramsState.programType,
  superset: selectCurrentSuperset(state),
  isMasquerade: getIsMasquerade(state),
  isAdmin: getIsAdmin(state),
  clientId: state.clientDetailState.id,
});

export default connect(mapStateToProps, mapDispatchToProps)(SupersetEditor);
