"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
var event_target_shim_1 = require("event-target-shim");
var SUCCESSFUL_CHUNK_UPLOAD_CODES = [200, 201, 202, 204, 308];
var TEMPORARY_ERROR_CODES = [408, 502, 503, 504]; // These error codes imply a chunk may be retried
var UpChunk = /** @class */ (function () {
    function UpChunk(options) {
        var _this = this;
        this.endpoint = options.endpoint;
        this.file = options.file;
        this.headers = options.headers || {};
        this.chunkSize = options.chunkSize || 5120;
        this.attempts = options.attempts || 5;
        this.delayBeforeAttempt = options.delayBeforeAttempt || 1;
        this.chunkCount = 0;
        this.chunkByteSize = this.chunkSize * 1024;
        this.totalChunks = Math.ceil(this.file.size / this.chunkByteSize);
        this.attemptCount = 0;
        this.offline = false;
        this.paused = false;
        this.reader = new FileReader();
        this.eventTarget = new event_target_shim_1.EventTarget();
        this.validateOptions();
        this.getEndpoint().then(function () { return _this.sendChunks(); });
        // restart sync when back online
        // trigger events when offline/back online
        window.addEventListener('online', function () {
            if (!_this.offline) {
                return;
            }
            _this.offline = false;
            _this.dispatch('online');
            _this.sendChunks();
        });
        window.addEventListener('offline', function () {
            _this.offline = true;
            _this.dispatch('offline');
        });
    }
    /**
     * Subscribe to an event
     */
    UpChunk.prototype.on = function (eventName, fn) {
        this.eventTarget.addEventListener(eventName, fn);
    };
    UpChunk.prototype.pause = function () {
        this.paused = true;
    };
    UpChunk.prototype.resume = function () {
        if (this.paused) {
            this.paused = false;
            this.sendChunks();
        }
    };
    /**
     * Dispatch an event
     */
    UpChunk.prototype.dispatch = function (eventName, detail) {
        var event = new CustomEvent(eventName, { detail: detail });
        this.eventTarget.dispatchEvent(event);
    };
    /**
     * Validate options and throw error if not of the right type
     */
    UpChunk.prototype.validateOptions = function () {
        if (!this.endpoint ||
            (typeof this.endpoint !== 'function' && typeof this.endpoint !== 'string')) {
            throw new TypeError('endpoint must be defined as a string or a function that returns a promise');
        }
        if (!(this.file instanceof File)) {
            throw new TypeError('file must be a File object');
        }
        if (this.headers && typeof this.headers !== 'object') {
            throw new TypeError('headers must be null or an object');
        }
        if (this.chunkSize &&
            (typeof this.chunkSize !== 'number' ||
                this.chunkSize <= 0 ||
                this.chunkSize % 256 !== 0)) {
            throw new TypeError('chunkSize must be a positive number in multiples of 256');
        }
        if (this.attempts &&
            (typeof this.attempts !== 'number' || this.attempts <= 0)) {
            throw new TypeError('retries must be a positive number');
        }
        if (this.delayBeforeAttempt &&
            (typeof this.delayBeforeAttempt !== 'number' ||
                this.delayBeforeAttempt < 0)) {
            throw new TypeError('delayBeforeAttempt must be a positive number');
        }
    };
    /**
     * Endpoint can either be a URL or a function that returns a promise that resolves to a string.
     */
    UpChunk.prototype.getEndpoint = function () {
        var _this = this;
        if (typeof this.endpoint === 'string') {
            this.endpointValue = this.endpoint;
            return Promise.resolve(this.endpoint);
        }
        return this.endpoint(this.file).then(function (value) {
            _this.endpointValue = value;
            return _this.endpointValue;
        });
    };
    /**
     * Get portion of the file of x bytes corresponding to chunkSize
     */
    UpChunk.prototype.getChunk = function () {
        var _this = this;
        return new Promise(function (resolve) {
            // Since we start with 0-chunkSize for the range, we need to subtract 1.
            var length = _this.totalChunks === 1 ? _this.file.size : _this.chunkByteSize;
            var start = length * _this.chunkCount;
            _this.reader.onload = function () {
                if (_this.reader.result !== null) {
                    _this.chunk = new Blob([_this.reader.result], {
                        type: 'application/octet-stream',
                    });
                }
                resolve();
            };
            _this.reader.readAsArrayBuffer(_this.file.slice(start, start + length));
        });
    };
    /**
     * Send chunk of the file with appropriate headers and add post parameters if it's last chunk
     */
    UpChunk.prototype.sendChunk = function () {
        var rangeStart = this.chunkCount * this.chunkByteSize;
        var rangeEnd = rangeStart + this.chunk.size - 1;
        var headers = __assign({}, this.headers, { 'Content-Type': this.file.type, 'Content-Length': this.chunk.size, 'Content-Range': "bytes " + rangeStart + "-" + rangeEnd + "/" + this.file.size });
        this.dispatch('attempt', {
            chunkNumber: this.chunkCount,
            chunkSize: this.chunk.size,
        });
        return fetch(this.endpointValue, {
            headers: headers,
            method: 'PUT',
            body: this.chunk,
        });
    };
    /**
     * Called on net failure. If retry counter !== 0, retry after delayBeforeAttempt
     */
    UpChunk.prototype.manageRetries = function () {
        var _this = this;
        if (this.attemptCount < this.attempts) {
            this.attemptCount = this.attemptCount + 1;
            setTimeout(function () { return _this.sendChunks(); }, this.delayBeforeAttempt * 1000);
            this.dispatch('attemptFailure', {
                message: "An error occured uploading chunk " + this.chunkCount + ". " + (this
                    .attempts - this.attemptCount) + " retries left.",
                chunkNumber: this.chunkCount,
                attemptsLeft: this.attempts - this.attemptCount,
            });
            return;
        }
        this.dispatch('error', {
            message: "An error occured uploading chunk " + this.chunkCount + ". No more retries, stopping upload",
            chunk: this.chunkCount,
            attempts: this.attemptCount,
        });
    };
    /**
     * Manage the whole upload by calling getChunk & sendChunk
     * handle errors & retries and dispatch events
     */
    UpChunk.prototype.sendChunks = function () {
        var _this = this;
        if (this.paused || this.offline) {
            return;
        }
        this.getChunk()
            .then(function () { return _this.sendChunk(); })
            .then(function (res) {
            if (SUCCESSFUL_CHUNK_UPLOAD_CODES.includes(res.status)) {
                _this.chunkCount = _this.chunkCount + 1;
                if (_this.chunkCount < _this.totalChunks) {
                    _this.sendChunks();
                }
                else {
                    _this.dispatch('success');
                }
                var percentProgress = Math.round((100 / _this.totalChunks) * _this.chunkCount);
                _this.dispatch('progress', percentProgress);
            }
            else if (TEMPORARY_ERROR_CODES.includes(res.status)) {
                if (_this.paused || _this.offline) {
                    return;
                }
                _this.manageRetries();
            }
            else {
                if (_this.paused || _this.offline) {
                    return;
                }
                _this.dispatch('error', {
                    message: "Server responded with " + res.status + ". Stopping upload.",
                    chunkNumber: _this.chunkCount,
                    attempts: _this.attemptCount,
                });
            }
        })["catch"](function (err) {
            if (_this.paused || _this.offline) {
                return;
            }
            // this type of error can happen after network disconnection on CORS setup
            _this.manageRetries();
        });
    };
    return UpChunk;
}());
exports.UpChunk = UpChunk;
exports.createUpload = function (options) { return new UpChunk(options); };
