import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { deleteUserFromPlatform } from "../../../redux/actions/DeleteUserFromPlatform";
import deleteUserFromPlatformWebSocket from "../../../redux/actions/DeleteUserFromPlatform/DeletingUserSocketInstance";
import { StoreState } from "../../../redux/reducers";
import { User, UserType } from "../../../types/gqlTypes";
import ConfirmationDialog from "../../ConfirmationDialog";
import WindowLog from "../../WindowLog";

interface OwnProps {
  userToDelete: User;
  onClose: () => void;
}

interface Props extends OwnProps {
  authenticatedUser: User;
  deleteUserFromPlatform: (
    userId: string,
    adminId: string,
    cb: (info) => void
  ) => void;
}

const DeleteClientButton = (props: Props) => {
  const { userToDelete, authenticatedUser, onClose, deleteUserFromPlatform } =
    props;
  const [parsedInfo, setParsedInfo] = useState({
    message: "",
    operationComplete: "",
  });
  const [confirmDialog, setConfirmDialog] = useState(false);

  useEffect(() => {
    if (!parsedInfo) {
      deleteUserFromPlatformWebSocket.unsubscribe();
      onClose?.();
    }
  }, [parsedInfo]);

  const getUserTypeLabel = () => {
    switch (userToDelete.type) {
      case UserType.Trainer:
        return "coach";
      default:
        return userToDelete.type.toLowerCase();
    }
  };

  return (
    <div>
      {parsedInfo?.message && (
        <WindowLog
          text={parsedInfo?.message}
          show
          disabled={!parsedInfo?.operationComplete}
          onConfirm={() => setParsedInfo(null)}
        />
      )}
      {confirmDialog && (
        <ConfirmationDialog
          show={confirmDialog}
          cancelButtonText="Cancel"
          text={`Do you want to permanently delete this ${getUserTypeLabel()} ${
            userToDelete.email
          }?  This action cannot be undone.`}
          onCancel={() => setConfirmDialog(false)}
          onConfirm={() => {
            deleteUserFromPlatform(
              userToDelete.id,
              authenticatedUser.id,
              (info) => {
                setParsedInfo(info);
              }
            );
            setConfirmDialog(false);
          }}
        />
      )}
      <a
        aria-hidden="true"
        onClick={() => setConfirmDialog(true)}
        style={{
          cursor: "pointer",
          color: "red",
          fontSize: 12,
        }}
      >
        Delete {getUserTypeLabel()}
      </a>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  deleteUserFromPlatform: (
    userId: string,
    adminId: string,
    cb: (info) => void
  ) => {
    dispatch(deleteUserFromPlatform(userId, adminId, cb));
  },
});

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  userToDelete: ownProps.userToDelete,
  onClose: ownProps.onClose,

  authenticatedUser: state.authenticatedUserState.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteClientButton);
