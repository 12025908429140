import {
  CaliberPageImpl_TimeZoneType,
  TimeZoneType,
} from "../../../types/gqlTypes";

export const SEARCH_TIMEZONES_SUCCESS = "SEARCH_TIMEZONES_SUCCESS";
export const SEARCH_TIMEZONES_FAIL = "SEARCH_TIMEZONES_FAIL";
export const SEARCH_TIMEZONES_LOADING = "SEARCH_TIMEZONES_LOADING";

export interface SearchTimezonesState {
  isLoading: boolean;
  isLastPage: boolean;
  page: number;
  totalPages: number;
  timezones: TimeZoneType[];
}

export interface SearchTimezonesAction {
  type: string;
  data: CaliberPageImpl_TimeZoneType;
}
