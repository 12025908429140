import _ from "lodash";
import {
  ComponentLocationType,
  ProgramType,
} from "../../../components/TrainingProgram";
import {
  COPY_CLIENT_WORKOUT_TEMPLATE_FAIL,
  COPY_CLIENT_WORKOUT_TEMPLATE_SAVING,
  COPY_CLIENT_WORKOUT_TEMPLATE_SUCCESS,
  COPY_TRAINING_PLAN_TO_CLIENTS_FAIL,
  COPY_TRAINING_PLAN_TO_CLIENTS_SAVING,
  COPY_TRAINING_PLAN_TO_CLIENTS_SUCCESS,
  COPY_TRAINING_PLAN_TO_TRAINER_FAIL,
  COPY_TRAINING_PLAN_TO_TRAINER_SAVING,
  COPY_TRAINING_PLAN_TO_TRAINER_SUCCESS,
  ON_STOP_CREATE_CUSTOM_PLAN_IN_PROGRAM_LIBRARY,
  TOGGLE_COPY_TRAINING_PLAN_TO_CLIENTS,
  TOGGLE_COPY_TRAINING_PLAN_TO_TRAINER,
  TOGGLE_CREATE_CUSTOM_TRAINING_PLAN,
  TrainingProgramsAction,
  TrainingProgramsState,
  VIEW_NEWLY_CREATED_CUSTOM_PLAN,
} from "../../actions/TrainingPrograms/types";

const copyReducer = (
  state: TrainingProgramsState,
  action: TrainingProgramsAction
) => {
  switch (action.type) {
    case VIEW_NEWLY_CREATED_CUSTOM_PLAN: {
      const newContent = [...(state?.customPlansContent?.content || [])];
      return {
        ...state,
        componentLocation: ComponentLocationType.AdminLibrary,
        programType: ProgramType.Custom,
        customPlansContent: {
          ...state.customPlansContent,
          content: _.uniqBy(
            [state.newlyCreatedCustomPlan, ...newContent],
            (item) => item.id
          ),
        },
        showPlans: true,
        showWorkouts: true,
        showExerciseLibrary: false,
        showExercises: false,
        planIndex: 0,
        showFilter: false,
        showCopyPlanToTrainer: false,
        showCreateCustomPlan: false,
        copyPlanToTrainer: null,
        failedToCreateCustomPlan: false,
        successToCreateCustomPlan: false,
      };
    }
    case TOGGLE_COPY_TRAINING_PLAN_TO_CLIENTS: {
      if (typeof action.index === "number") {
        return {
          ...state,
          showCopyPlanToClients: true,
          copyPlanToClients: state.clientPlans?.[action.index],
        };
      }
      return {
        ...state,
        showCopyPlanToClients: false,
        copyPlanToClients: null,
      };
    }
    case TOGGLE_COPY_TRAINING_PLAN_TO_TRAINER: {
      if (typeof action.index === "number") {
        return {
          ...state,
          showCopyPlanToTrainer: true,
          successToCreateCustomPlan: false,
          copyPlanToTrainer: state.clientPlans?.[action.index],
        };
      }
      if (state.successToCreateCustomPlan) {
        const newContent = [...(state?.customPlansContent?.content || [])];
        return {
          ...state,
          showCopyPlanToTrainer: false,
          showCreateCustomPlan: false,
          copyPlanToTrainer: null,
          successToCreateCustomPlan: false,
          customPlansContent: {
            ...state.customPlansContent,
            content: _.uniqBy(
              [state.newlyCreatedCustomPlan, ...newContent],
              (item) => item.id
            ),
          },
        };
      }
      return {
        ...state,
        showCopyPlanToTrainer: false,
        showCreateCustomPlan: false,
        copyPlanToTrainer: null,
        successToCreateCustomPlan: false,
      };
    }
    case ON_STOP_CREATE_CUSTOM_PLAN_IN_PROGRAM_LIBRARY:
      return {
        ...state,
        showCreateNewPlans: false,
        showWorkouts: false,
      };
    case TOGGLE_CREATE_CUSTOM_TRAINING_PLAN: {
      if (state.componentLocation === ComponentLocationType.AdminLibrary) {
        return {
          ...state,
          planIndex: null,
          showWorkouts: true,
          showCreateNewPlans: true,
          showFilter: false,
          copyPlanToTrainer: null,
          showCopyPlanToTrainer: false,
          successToCreateCustomPlan: false,
        };
      }
      return {
        ...state,
        copyPlanToTrainer: null,
        showCopyPlanToTrainer: false,
        successToCreateCustomPlan: false,
        showCreateCustomPlan: !state.showCreateCustomPlan,
      };
    }
    case COPY_TRAINING_PLAN_TO_CLIENTS_SAVING:
      return {
        ...state,
        isCopying: true,
      };
    case COPY_TRAINING_PLAN_TO_CLIENTS_SUCCESS:
      return {
        ...state,
        isCopying: false,
      };
    case COPY_TRAINING_PLAN_TO_CLIENTS_FAIL:
      return {
        ...state,
        isCopying: false,
        message: (action as any).data.message,
      };
    case COPY_TRAINING_PLAN_TO_TRAINER_SAVING:
      return {
        ...state,
        isCopying: true,
      };
    case COPY_TRAINING_PLAN_TO_TRAINER_SUCCESS:
      return {
        ...state,
        isCopying: false,
        successToCreateCustomPlan: true,
        failedToCreateCustomPlan: false,
        newlyCreatedCustomPlan: action.plan,
      };
    case COPY_TRAINING_PLAN_TO_TRAINER_FAIL:
      return {
        ...state,
        isCopying: false,
        failedToCreateCustomPlan: true,
        message: (action as any).data.message,
      };
    case COPY_CLIENT_WORKOUT_TEMPLATE_SAVING:
      return {
        ...state,
        isCopying: true,
      };
    case COPY_CLIENT_WORKOUT_TEMPLATE_FAIL:
      return {
        ...state,
        isCopying: false,
        message: (action as any).data.message,
      };
    case COPY_CLIENT_WORKOUT_TEMPLATE_SUCCESS:
      const { cachedPlans } = state;
      const planId = action.plan.id;
      return {
        ...state,
        isCopying: false,
        cachedPlans: {
          ...cachedPlans,
          [planId]: action.plan,
        },
      };
    default:
      return state;
  }
};
export default copyReducer;
