import html2canvas from "html2canvas";
import mergeImages from "merge-images";
import React, { useState } from "react";
import colors from "../../assets/colors";
import Loader from "../Loader";
import Modal from "../Modal";
import SimpleButton from "../SimpleButton";
import DateBadge from "./DateBadge";
import PhotoCropper from "./PhotoCropper";
import Tools from "./Tools";

interface Props {
  imgUrl1: string;
  date1: Date;
  imgUrl2: string;
  date2: Date;
  onCrop: (img: any) => void;
}

const PhotoComparisonTool = (props: Props) => {
  const { imgUrl1, imgUrl2, date1, date2, onCrop } = props;
  const [isLoading, setLoading] = useState(false);
  const [cropData1, setCropData1] = useState<any>("#");
  const [cropData2, setCropData2] = useState<any>("#");

  const getCroppedBoxes = async () => {
    setLoading(true);
    const padding = 4;
    const onePicWidth = 134;
    const dateTop = 17;
    const dateLeft = 38;
    const scale = 4;
    const bgRect: any = document.createElement("canvas");
    bgRect.width = 280 * scale;
    bgRect.height = 280 * scale;
    const ctx = bgRect.getContext("2d");
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, 280 * scale, 280 * scale);
    const img1 = await html2canvas(document.querySelector("#image_preview_1"), {
      scale,
      backgroundColor: "rgba(0,0,0,0)",
      useCORS: true,
    });
    const img2 = await html2canvas(document.querySelector("#image_preview_2"), {
      scale,
      backgroundColor: "rgba(0,0,0,0)",
      useCORS: true,
    });
    const date1 = await html2canvas(document.querySelector("#capture_date_1"), {
      scale,
      backgroundColor: "rgba(0,0,0,0)",
    });
    const date2 = await html2canvas(document.querySelector("#capture_date_2"), {
      scale,
      backgroundColor: "rgba(0,0,0,0)",
    });

    const mergedImg = await mergeImages(
      [
        { src: bgRect?.toDataURL(), x: 0, y: 0 },
        { src: img1?.toDataURL(), x: padding * scale, y: padding * scale },
        {
          src: img2?.toDataURL(),
          x: (onePicWidth + 2 * padding) * scale,
          y: padding * scale,
        },
        { src: date1?.toDataURL(), x: dateLeft * scale, y: dateTop * scale },
        {
          src: date2?.toDataURL(),
          x: (2 * padding + onePicWidth + dateLeft) * scale,
          y: dateTop * scale,
        },
      ],
      {
        width: 280 * scale,
        height: 280 * scale,
        format: "image/jpeg",
        quality: 1,
      }
    );
    setLoading(false);
    onCrop(mergedImg);
  };

  return (
    <Modal onClose={() => {}}>
      <div
        className="d-flex flex-column"
        style={{
          backgroundColor: "#F2F3F5",
          padding: 10,
        }}
      >
        <div className="d-flex">
          <div className="d-flex flex-column">
            <PhotoCropper
              name="image_preview_1"
              imgUrl={imgUrl1}
              onInitialized={(instance) => setCropData1(instance)}
            />
            <Tools instance={cropData1} />
          </div>
          <div className="d-flex flex-column">
            <PhotoCropper
              name="image_preview_2"
              imgUrl={imgUrl2}
              onInitialized={(instance) => setCropData2(instance)}
            />
            <Tools instance={cropData2} />
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <div
            className="d-flex"
            style={{
              padding: 4,
              backgroundColor: colors.caliber_white,
              position: "relative",
            }}
          >
            <div
              className="image_preview_1"
              id="image_preview_1"
              style={{
                height: 272,
                width: 134,
                overflow: "hidden",
              }}
            />
            <div
              className="image_preview_2"
              id="image_preview_2"
              style={{
                height: 272,
                width: 134,
                marginLeft: 4,
                overflow: "hidden",
              }}
            />
            <DateBadge
              date={date1}
              id="capture_date_1"
              style={{
                position: "absolute",
                top: 21,
                left: 42,
              }}
            />
            <DateBadge
              date={date2}
              id="capture_date_2"
              style={{
                position: "absolute",
                top: 21,
                right: 42,
              }}
            />
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <SimpleButton
            onClick={getCroppedBoxes}
            className="d-flex align-items-center justify-content-center bold"
            style={{
              minWidth: 184,
              backgroundColor: colors.caliber_green_200,
              marginTop: 46,
              marginBottom: 46,
              height: 34,
              fontSize: "13px",
              lineHeight: "16px",
              letterSpacing: "-0.022em",
              color: colors.caliber_white,
              borderRadius: 8,
              position: "relative",
            }}
          >
            Save
            {isLoading && (
              <div
                className="d-flex align-items-center justify-content-center"
                style={{
                  height: 34,
                  position: "absolute",
                  right: 25,
                }}
              >
                <Loader />
              </div>
            )}
          </SimpleButton>
        </div>
      </div>
    </Modal>
  );
};

export default PhotoComparisonTool;
