import React from "react";

interface Props {
  defaultColor: string;
  legColor: string;
  chestColor: string;
  armColor: string;
  shoulderColor: string;
  skeletonSize: {
    width: number;
    height: number;
  };
}

const FrontFemaleSkeleton = (props: Props) => {
  const {
    defaultColor,
    legColor,
    chestColor,
    armColor,
    shoulderColor,
    skeletonSize: { width, height },
  } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 218 431"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <FrontFemaleHead color={defaultColor} />
      <FrontFemaleNeck color={defaultColor} />
      <FrontFemaleTraps color={defaultColor} />

      <FrontFemaleUpperChest color={chestColor} />
      <FrontFemaleMidChest color={chestColor} />

      <FrontFemaleMidShoulders color={shoulderColor} />
      <FrontFemaleFrontShoulders color={shoulderColor} />

      <FrontFemaleForearms color={armColor} />
      <FrontFemaleBiceps color={armColor} />

      <FrontFemaleHands color={defaultColor} />

      <FrontFemaleAbs color={defaultColor} />
      <FrontFemaleObluques color={defaultColor} />
      <FrontFemaleTransverseAbdominis color={defaultColor} />

      <FrontFemaleAbductors color={legColor} />
      <FrontFemaleQuads color={legColor} />
      <FrontFemaleHipFlexors color={legColor} />
      <FrontFemaleBottomLegs color={legColor} />

      <FrontFemaleFeet color={defaultColor} />
    </svg>
  );
};

const FrontFemaleFeet = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M82.4616 406.474L81.8851 422.426L80.5408 428H76.5026L75.3496 424.156L74.0026 423.965L74.2922 427.809L69.1966 426.27L67.8523 425.503L62.0846 424.252L61.8906 419.735L67.4671 410.318L71.3113 405.512L80.7321 401.668L82.4616 406.474Z"
        fill={color}
      />
      <path
        d="M95.5371 406.474L96.1136 422.426L97.4579 428H101.496L102.649 424.156L103.996 423.965L103.707 427.809L108.802 426.27L110.146 425.503L115.914 424.252L116.108 419.735L110.532 410.318L106.687 405.512L97.2666 401.668L95.5371 406.474Z"
        fill={color}
      />
    </>
  );
};
const FrontFemaleBottomLegs = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M63.2386 299.802L62.6621 304.99L66.6977 309.987L68.0446 317.869L70.7359 319.021L73.0419 307.104L74.5801 302.875L73.2358 300.184L63.2386 299.802Z"
        fill={color}
      />
      <path
        d="M81.1156 300.184L78.233 308.257L72.0801 320.174L73.6183 331.13L78.0417 318.83L81.6947 311.91L81.1156 300.184Z"
        fill={color}
      />
      <path
        d="M58.0471 289.231L62.4679 312.872L59.5853 311.333L57.2793 319.021L58.0471 289.231Z"
        fill={color}
      />
      <path
        d="M77.8484 325.942L76.5041 330.168L73.6189 348.044L73.2363 371.878L77.2719 395.134L77.0806 365.343L81.3101 353.426L81.8866 340.356L77.8484 325.942Z"
        fill={color}
      />
      <path
        d="M68.6209 336.703L69.5827 372.455L77.2712 401.283L68.9079 397.344L54.9707 347.276L59.7767 315.754L68.6209 336.703Z"
        fill={color}
      />
      <path
        d="M114.763 299.802L115.34 304.99L111.302 309.987L109.957 317.869L107.266 319.021L104.957 307.104L103.422 302.875L104.766 300.184L114.763 299.802Z"
        fill={color}
      />
      <path
        d="M96.8831 300.184L99.7683 308.257L105.919 320.174L104.38 331.13L99.9596 318.83L96.3066 311.91L96.8831 300.184Z"
        fill={color}
      />
      <path
        d="M100.151 325.942L101.498 330.168L104.38 348.044L104.766 371.878L100.73 395.134L100.921 365.343L96.6918 353.426L96.1152 340.356L100.151 325.942Z"
        fill={color}
      />
      <path
        d="M109.381 336.703L108.419 372.455L100.73 401.283L109.091 397.344L123.031 347.276L118.225 315.754L109.381 336.703Z"
        fill={color}
      />
      <path
        d="M119.954 289.231L115.533 312.872L118.416 311.333L120.722 319.021L119.954 289.231Z"
        fill={color}
      />
    </>
  );
};
const FrontFemaleHipFlexors = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M60.1855 182.357V201.829L73.8331 228.745L82.4515 255.47L81.5562 236.954L60.1855 182.357Z"
        fill={color}
      />
      <path
        d="M82.3296 208.561L82.4306 208.433L81.7425 206.68L67.3696 190.948L66.291 190.566L75.8339 215.152L82.3296 208.561Z"
        fill={color}
      />
      <path
        d="M76.8945 217.888L82.8137 233.136L84.7876 214.427L83.6213 211.457L76.8945 217.888Z"
        fill={color}
      />
      <path
        d="M117.815 182.357V201.829L104.168 228.745L95.5469 255.47L96.4448 236.954L117.815 182.357Z"
        fill={color}
      />
      <path
        d="M95.672 208.561L95.5684 208.433L96.2591 206.68L110.632 190.948L111.711 190.566L102.168 215.152L95.672 208.561Z"
        fill={color}
      />
      <path
        d="M101.104 217.888L95.1875 233.136L93.2109 214.427L94.3799 211.457L101.104 217.888Z"
        fill={color}
      />
    </>
  );
};
const FrontFemaleAbductors = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M56.5937 179.684L57.3137 198.2L51.2059 234.852L48.334 205.264L56.5937 179.684Z"
        fill={color}
      />
      <path
        d="M121.407 179.684L120.688 198.2L126.793 234.852L129.667 205.264L121.407 179.684Z"
        fill={color}
      />
    </>
  );
};
const FrontFemaleQuads = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M53.9 233.325L54.6199 255.278L65.7516 285.823L62.3404 294.603L51.3867 256.044L53.9 233.325Z"
        fill={color}
      />
      <path
        d="M75.2405 244.397L75.5407 245.112L81.1597 258.525L77.5306 291.739L71.8027 288.684L76.7681 257.76L75.2405 244.397Z"
        fill={color}
      />
      <path
        d="M124.1 233.325L123.38 255.278L112.246 285.823L115.66 294.603L126.614 256.044L124.1 233.325Z"
        fill={color}
      />
      <path
        d="M102.759 244.397L102.459 245.112L96.8398 258.525L100.469 291.739L106.197 288.684L101.231 257.76L102.759 244.397Z"
        fill={color}
      />
      <path
        d="M58.389 203.357L71.1386 231.418L74.0131 253.942L68.6253 285.632L56.9517 253.371L56.2344 220.154L58.389 203.357Z"
        fill={color}
      />
      <path
        d="M119.61 203.357L106.861 231.418L103.986 253.942L109.374 285.632L121.045 253.371L121.765 220.154L119.61 203.357Z"
        fill={color}
      />
    </>
  );
};
const FrontFemaleForearms = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M40.6684 161.16L24.9805 203.508L29.9778 204.661L46.5876 170.514L49.6428 151.424L40.6684 161.16Z"
        fill={color}
      />
      <path
        d="M36.0868 144.743L38.3769 160.969L23.4435 200.817L21.1348 199.279L26.9025 168.527L36.0868 144.743Z"
        fill={color}
      />
      <path
        d="M137.332 161.16L153.02 203.508L148.022 204.661L131.413 170.514L128.357 151.424L137.332 161.16Z"
        fill={color}
      />
      <path
        d="M141.915 144.743L139.625 160.969L154.558 200.817L156.864 199.279L151.099 168.527L141.915 144.743Z"
        fill={color}
      />
    </>
  );
};
const FrontFemaleBiceps = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M39.465 150.317C39.3582 150.432 39.2335 150.529 39.0957 150.604L39.8927 157.723L51.1599 145.697L52.2013 137.94C47.6849 141.768 43.5005 145.965 39.465 150.317Z"
        fill={color}
      />
      <path
        d="M138.539 150.317C138.646 150.432 138.77 150.529 138.908 150.604L138.111 157.723L126.844 145.697L125.803 137.94C130.319 141.768 134.503 145.965 138.539 150.317Z"
        fill={color}
      />
      <path
        d="M40.4792 109.045L37.6153 139.587L35.7051 116.871L40.4792 109.045Z"
        fill={color}
      />
      <path
        d="M137.523 109.045L140.387 139.587L142.298 116.871L137.523 109.045Z"
        fill={color}
      />
      <path
        d="M125.595 134.075C125.488 133.989 125.369 133.918 125.242 133.865L122.057 110.19L128.167 101.423L134.469 110.586L139.814 142.655L139.325 146.961C135.016 142.374 130.521 137.99 125.595 134.075Z"
        fill={color}
      />
      <path
        d="M52.4069 134.075C52.5147 133.989 52.6336 133.918 52.7603 133.865L55.9484 110.19L49.8379 101.423L43.5388 110.586L38.1855 142.642L38.677 146.948C42.9862 142.374 47.4814 137.99 52.4069 134.075Z"
        fill={color}
      />
    </>
  );
};
const FrontFemaleMidShoulders = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M121.789 76.582L125.301 74.6826L136.295 86.8415L136.353 91.4692C136.353 91.4692 122.002 76.6909 121.789 76.582Z"
        fill={color}
      />
      <path
        d="M56.2087 76.582L52.6965 74.6826L41.7056 86.8415L41.6445 91.4692C41.6445 91.4692 55.9988 76.6909 56.2087 76.582Z"
        fill={color}
      />
    </>
  );
};
const FrontFemaleFrontShoulders = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M120.673 77.0867C120.673 77.3284 136.101 93.5199 136.101 93.5199L136.568 107.9L131.221 98.7373L120.91 94.3461L113.654 80.9838L120.673 77.0867Z"
        fill={color}
      />
      <path
        d="M57.3269 77.0867C57.3269 77.3284 41.8967 93.5199 41.8967 93.5199L41.4238 107.9L46.7691 98.7373L57.0985 94.3461L64.3539 80.9838L57.3269 77.0867Z"
        fill={color}
      />
    </>
  );
};
const FrontFemaleHands = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M18.4562 200.336L20.9535 206.295L28.2595 207.448L28.836 218.212L24.9917 233.588L22.4918 233.394L24.92 223.161L22.3244 223.089L18.2623 233.97L15.1884 233.588L19.0088 222.585L16.3415 222.08L11.1502 233.779L7.30597 233.394L12.5928 221.214L10.765 220.133L4.22949 230.512L0.96173 230.129L10.765 211.483L9.80328 209.947L0.576507 213.791L0 211.677L7.49725 204.948L18.4562 200.336Z"
        fill={color}
      />
      <path
        d="M159.543 200.336L157.043 206.295L149.739 207.448L149.16 218.212L153.007 233.588L155.504 233.394L153.079 223.161L155.674 223.089L159.734 233.97L162.81 233.588L158.99 222.585L161.657 222.08L166.849 233.779L170.693 233.394L165.406 221.214L167.234 220.133L173.769 230.512L177.037 230.129L167.234 211.483L168.193 209.947L177.422 213.791L177.999 211.677L170.502 204.948L159.543 200.336Z"
        fill={color}
      />
    </>
  );
};
const FrontFemaleAbs = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M73.041 122.638L87.459 116.151L87.0286 126.676L74.0506 133.307L73.041 122.638Z"
        fill={color}
      />
      <path
        d="M75.3496 135.9L75.6365 143.396L86.3059 141.091L86.8824 130.855L75.3496 135.9Z"
        fill={color}
      />
      <path
        d="M88.0377 209.275L85.2268 181.982L85.1578 164.213L75.0357 159.25L73.8906 191.703L88.0377 209.275Z"
        fill={color}
      />
      <path
        d="M76.0692 147.001L75.9258 156.227L86.1621 158.966L86.3056 144.549L76.0692 147.001Z"
        fill={color}
      />
      <path
        d="M104.955 122.638L90.5371 116.151L90.9702 126.676L103.948 133.307L104.955 122.638Z"
        fill={color}
      />
      <path
        d="M102.651 135.9L102.364 143.396L91.6944 141.091L91.1152 130.855L102.651 135.9Z"
        fill={color}
      />
      <path
        d="M89.9609 209.275L92.7717 181.982L92.8408 164.213L102.96 159.25L104.108 191.703L89.9609 209.275Z"
        fill={color}
      />
      <path
        d="M101.93 147.001L102.073 156.227L91.8368 158.966L91.6934 144.549L101.93 147.001Z"
        fill={color}
      />
    </>
  );
};
const FrontFemaleObluques = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M71.4066 162.135L63.006 155.834L62.2754 164.298L56.3164 175.253L65.49 187.885L70.2642 188.648L71.9804 170.705L71.4066 162.135Z"
        fill={color}
      />
      <path
        d="M106.592 162.135L114.993 155.834L115.723 164.298L121.682 175.253L112.509 187.885L107.735 188.648L106.016 170.705L106.592 162.135Z"
        fill={color}
      />
    </>
  );
};
const FrontFemaleTransverseAbdominis = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M64.0762 130.342L70.0724 133.671L69.379 125.781L64.0762 130.342Z"
        fill={color}
      />
      <path
        d="M68.9537 122.782L57.4688 118.019L61.7939 129.654L68.9537 122.782Z"
        fill={color}
      />
      <path
        d="M61.8594 130.804L69.6887 135.575L71.0251 145.12L63.387 140.158L61.8594 130.804Z"
        fill={color}
      />
      <path
        d="M63.7695 142.45L70.6425 147.224L71.5989 156.96L63.9608 150.85L63.7695 142.45Z"
        fill={color}
      />
      <path
        d="M113.919 130.342L107.926 133.671L108.619 125.781L113.919 130.342Z"
        fill={color}
      />
      <path
        d="M109.041 122.782L120.526 118.019L116.204 129.654L109.041 122.782Z"
        fill={color}
      />
      <path
        d="M116.136 130.804L108.307 135.575L106.971 145.12L114.609 140.158L116.136 130.804Z"
        fill={color}
      />
      <path
        d="M114.228 142.45L107.352 147.224L106.398 156.96L114.037 150.85L114.228 142.45Z"
        fill={color}
      />
    </>
  );
};
const FrontFemaleMidChest = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M68.0116 96.9629L60.1425 95.1086L51.3594 99.4999L56.7073 103.7L60.9076 115.917L70.6445 119.926L86.1624 112.259L84.4832 99.4866L68.0116 96.9629Z"
        fill={color}
      />
      <path
        d="M110.072 96.9285L117.942 95.0742L126.725 99.4654L121.377 103.665L117.176 115.883L107.44 119.891L91.9216 112.225L93.6008 99.4522L110.072 96.9285Z"
        fill={color}
      />
    </>
  );
};
const FrontFemaleUpperChest = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M68.1626 80.7927L61.502 93.3767L69.8706 95.2363L83.9512 97.2286L82.291 82.129L68.1626 80.7927Z"
        fill={color}
      />
      <path
        d="M109.921 80.7583L116.582 93.3423L108.213 95.2018L94.1328 97.1942L95.793 82.0945L109.921 80.7583Z"
        fill={color}
      />
    </>
  );
};
const FrontFemaleTraps = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M74.6553 67.4304L76.3715 75.4478L66.2521 77.929L59.7617 75.6391L74.6553 67.4304Z"
        fill={color}
      />
      <path
        d="M103.342 67.4304L101.623 75.4478L111.745 77.929L118.236 75.6391L103.342 67.4304Z"
        fill={color}
      />
    </>
  );
};
const FrontFemaleNeck = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M76.9538 59.4131L84.5918 70.2942L88.0376 82.4212L80.009 76.019L76 62.2768L76.9538 59.4131Z"
        fill={color}
      />
      <path
        d="M100.469 59.4131L92.8305 70.2942L89.3848 82.4212L97.4134 76.019L101.422 62.2768L100.469 59.4131Z"
        fill={color}
      />
    </>
  );
};
const FrontFemaleHead = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M105.77 35.3237L105.863 25.112L87.5047 12.3262L70.1935 22.6653L70.1643 34.8561L67.3535 35.0421L68.6208 43.4818L73.1398 45.0545L75.1855 55.1386L83.6206 64.6942L93.036 64.4126L101.328 55.4176L102.993 45.0545L107.51 43.4818L108.673 35.2307L105.77 35.3237Z"
        fill={color}
      />
      <path
        d="M68.3915 33.7298L68.1684 21.741L87.6315 10.3179L108.12 23.9857L107.934 32.9807L112.251 18.16L100.062 2.06146L85.259 0L72.5148 3.18517L63.1445 21.1751L68.3915 33.7298Z"
        fill={color}
      />
    </>
  );
};

export default FrontFemaleSkeleton;
