import React from "react";

interface Props {
  isSelected?: boolean;
}

const GearIcon = (props: Props) => {
  const { isSelected } = props;

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {isSelected ? (
        <rect
          width="31.9992"
          height="32"
          rx="15.9996"
          fill="#3446FB"
          fillOpacity="0.1"
        />
      ) : (
        <rect
          x="0.25"
          y="0.25"
          width="31.4992"
          height="31.5"
          rx="15.7496"
          stroke="#F2F3F5"
          strokeWidth="0.5"
        />
      )}
      <path
        d="M24.9025 13.8283L23.3358 13.6292C23.2067 13.2317 23.0475 12.8483 22.8617 12.4842L23.8292 11.2383C24.2208 10.7342 24.175 10.0225 23.7275 9.58917L22.415 8.27667C21.9775 7.825 21.2658 7.78 20.7608 8.17083L19.5167 9.13833C19.1525 8.9525 18.7692 8.79333 18.3708 8.66417L18.1717 7.1C18.0967 6.4725 17.5642 6 16.9333 6H15.0667C14.4358 6 13.9033 6.4725 13.8283 7.0975L13.6292 8.66417C13.2308 8.79333 12.8475 8.95167 12.4833 9.13833L11.2383 8.17083C10.735 7.78 10.0233 7.825 9.58917 8.2725L8.27667 9.58417C7.825 10.0225 7.77917 10.7342 8.17083 11.2392L9.13833 12.4842C8.95167 12.8483 8.79333 13.2317 8.66417 13.6292L7.1 13.8283C6.4725 13.9033 6 14.4358 6 15.0667V16.9333C6 17.5642 6.4725 18.0967 7.0975 18.1717L8.66417 18.3708C8.79333 18.7683 8.9525 19.1517 9.13833 19.5158L8.17083 20.7617C7.77917 21.2658 7.825 21.9775 8.2725 22.4108L9.585 23.7233C10.0233 24.1742 10.7342 24.2192 11.2392 23.8283L12.4842 22.8608C12.8483 23.0475 13.2317 23.2067 13.6292 23.335L13.8283 24.8983C13.9033 25.5275 14.4358 26 15.0667 26H16.9333C17.5642 26 18.0967 25.5275 18.1717 24.9025L18.3708 23.3358C18.7683 23.2067 19.1517 23.0475 19.5158 22.8617L20.7617 23.8292C21.2658 24.2208 21.9775 24.175 22.4108 23.7275L23.7233 22.415C24.175 21.9767 24.2208 21.2658 23.8292 20.7608L22.8617 19.5158C23.0483 19.1517 23.2075 18.7683 23.3358 18.3708L24.8992 18.1717C25.5267 18.0967 25.9992 17.5642 25.9992 16.9333V15.0667C26 14.4358 25.5275 13.9033 24.9025 13.8283ZM16 20.1667C13.7025 20.1667 11.8333 18.2975 11.8333 16C11.8333 13.7025 13.7025 11.8333 16 11.8333C18.2975 11.8333 20.1667 13.7025 20.1667 16C20.1667 18.2975 18.2975 20.1667 16 20.1667Z"
        fill={isSelected ? "#3446FB" : "#C2C3CD"}
      />
    </svg>
  );
};

export default GearIcon;
