import { ClientHabitTarget } from "../../../types/gqlTypes";

export const CREATE_UPDATE_CLIENT_HABIT_TARGET_SUCCESS =
  "CREATE_UPDATE_CLIENT_HABIT_TARGET_SUCCESS";
export const CREATE_UPDATE_CLIENT_HABIT_TARGET_FAIL =
  "CREATE_UPDATE_CLIENT_HABIT_TARGET_FAIL";
export const CREATE_UPDATE_CLIENT_HABIT_TARGET_SAVING =
  "CREATE_UPDATE_CLIENT_HABIT_TARGET_SAVING";

export const GET_CLIENT_HABIT_TARGET_SUCCESS =
  "GET_CLIENT_HABIT_TARGET_SUCCESS";
export const GET_CLIENT_HABIT_TARGET_FAIL = "GET_CLIENT_HABIT_TARGET_FAIL";
export const GET_CLIENT_HABIT_TARGET_LOADING =
  "GET_CLIENT_HABIT_TARGET_LOADING";

export const DELETE_CLIENT_HABIT_TARGET_SUCCESS =
  "DELETE_CLIENT_HABIT_TARGET_SUCCESS";
export const DELETE_CLIENT_HABIT_TARGET_FAIL =
  "DELETE_CLIENT_HABIT_TARGET_FAIL";
export const DELETE_CLIENT_HABIT_TARGET_LOADING =
  "DELETE_CLIENT_HABIT_TARGET_LOADING";

export const RESET_CLIENT_HABIT_TARGET_STATE =
  "RESET_CLIENT_HABIT_TARGET_STATE";

export interface ClientHabitsState {
  isSaving: boolean;
  isLoading: boolean;
  habits: ClientHabitTarget[];
}

export interface ClientHabitsAction {
  type: string;
  data?: ClientHabitTarget[];
  habitTargetId?: string;
}
