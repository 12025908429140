import React from "react";
import { ContentLessonSet } from "../../types/gqlTypes";
import SimpleButton from "../SimpleButton";
import CheckIcon from "../Icons/CheckIcon";

interface Props {
  onOpenModal: () => void;
  weeklyItem: ContentLessonSet;
}

const LessonItem = (props: Props) => {
  const { onOpenModal, weeklyItem } = props;

  return (
    <SimpleButton
      onClick={() => {
        if (weeklyItem.lessonPages) {
          onOpenModal();
        }
      }}
      className="d-flex pointer"
      style={{
        width: 360,
        height: 48,
        padding: "8px 11px",
        borderRadius: 8,
        backgroundColor: "#FAFAFB",
      }}
    >
      <img
        style={{
          width: 32,
          height: 32,
          borderRadius: 4,
          marginRight: 9,
        }}
        src={weeklyItem.thumbnailImage.url}
      />

      <div
        className="d-flex align-items-center bold"
        style={{
          fontSize: "12px",
          lineHeight: "14px",
          height: 32,
          width: 299 + (weeklyItem.read ? 0 : 35),
          wordWrap: "break-word",
          color: "#000000",
        }}
      >
        {weeklyItem.title}
      </div>
      {weeklyItem.read && (
        <div
          style={{
            width: 24,
            height: 24,
            margin: "4px 5px 4px 9px",
          }}
        >
          <CheckIcon />
        </div>
      )}
    </SimpleButton>
  );
};

export default LessonItem;
