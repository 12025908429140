import { format } from "date-fns";
import React, { useState } from "react";
import { Image } from "react-bootstrap";
import ReactPlayer from "react-player";
import { FileMessage, UserMessage } from "sendbird";
import MessageShortcutMenu from "../MessageShortcutMenu";
import MessageEmoji from "./MessageEmoji";
import MessageFileDownloadIcon from "../../assets/images/MessageFileDownloadIcon.svg";
import colors from "../../assets/colors";
import Modal from "../Modal";
import SimpleButton from "../SimpleButton";
import PlayIcon from "../Icons/PlayIcon";

interface Props {
  onDelete: () => void;
  addReaction: (message: UserMessage | FileMessage, emojiKey: string) => void;
  setEmojiMenuOpen: (isClosed: boolean) => void;
  message: UserMessage | FileMessage;
  showProfilePic?: boolean;
  isMasquerading?: boolean;
  isDeletable?: boolean;
  emojiMenuOpen?: boolean;
}

const FileMessageItem = (props: Props) => {
  const {
    onDelete,
    addReaction,
    setEmojiMenuOpen,
    message,
    showProfilePic,
    isMasquerading,
    isDeletable,
    emojiMenuOpen,
  } = props;
  const fileMessage = message.isFileMessage() ? (message as FileMessage) : null;
  const isVideoMessage = fileMessage?.type?.startsWith("video");
  const thumbnail = fileMessage?.thumbnails?.[0];
  const [hovered, setHovered] = useState(false);
  const [showImageUrl, setShowImageUrl] = useState(null);

  const whatTimeIsIt = (message: UserMessage | FileMessage) => {
    const createdDate = new Date(message.createdAt);
    return format(createdDate, "h:mm aa").toLowerCase();
  };
  const onMouseEnter = () => {
    setHovered(true);
  };
  const onMouseLeave = () => {
    if (!emojiMenuOpen) {
      setHovered(false);
    }
  };

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className="d-flex"
      style={{
        marginRight: 88,
        position: "relative",
        marginBottom: 5,
      }}
    >
      {showImageUrl && (
        <Modal onClose={() => setShowImageUrl(null)} closeOnDimmerClick>
          <div
            style={{
              backgroundColor: colors.caliber_white,
              borderRadius: 8,
              padding: 16,
              overflowY: "auto",
              maxHeight: window.innerHeight * 0.8,
            }}
          >
            {isVideoMessage ? (
              <ReactPlayer
                style={{
                  maxHight: window.innerHeight * 0.9,
                }}
                url={fileMessage.url}
                controls
              />
            ) : (
              <Image
                src={showImageUrl}
                style={{
                  maxWidth: window.innerWidth * 0.5,
                  borderRadius: 8,
                }}
              />
            )}
          </div>
        </Modal>
      )}

      {!showProfilePic && hovered && (
        <div
          className="medium-bold"
          style={{
            color: colors.caliber_secondary_gray_47,
            fontSize: "12px",
            lineHeight: "12px",
            position: "absolute",
            top: 18,
            right: -54,
          }}
        >
          {whatTimeIsIt(message)}
        </div>
      )}
      {fileMessage.type && fileMessage.type.startsWith("image") && (
        <div
          style={{
            flex: "1 1 0",
            marginBottom: message?.reactions?.[0] ? 35 : 8,
            position: "relative",
          }}
        >
          <Image
            onClick={() => {
              setShowImageUrl(fileMessage.url);
            }}
            style={{
              height: 120,
              maxHeight: 120,
              cursor: "pointer",
              borderRadius: 8,
            }}
            src={fileMessage.url}
          />
          {hovered && !isMasquerading && (
            <MessageShortcutMenu
              key="message_shortcut_menu_image"
              toggleMenu={setEmojiMenuOpen}
              onClose={() => setHovered(false)}
              isDeletable={isDeletable}
              selectedReactions={message?.reactions}
              onDelete={onDelete}
              addReaction={(emojiKey: string) => {
                addReaction(message, emojiKey);
                setHovered(false);
              }}
            />
          )}
          {message?.reactions?.length > 0 && (
            <MessageEmoji
              reactions={message.reactions}
              reactionTapped={(emojiKey) => {
                if (!isMasquerading) {
                  addReaction(message, emojiKey);
                }
              }}
            />
          )}
        </div>
      )}

      {fileMessage?.type?.startsWith("video") && (
        <div
          className="d-flex"
          style={{
            maxHeight: 200,
            position: "relative",
          }}
        >
          <Image
            onClick={() => {
              setShowImageUrl(true);
            }}
            style={{
              height: thumbnail?.real_height || 200,
              maxHeight: 200,
              cursor: "pointer",
              borderRadius: 8,
            }}
            src={thumbnail?.url}
          />
          <SimpleButton
            style={{
              position: "absolute",
              top: "calc(50% - 30px)",
              left: "calc(50% - 30px)",
            }}
            onClick={() => setShowImageUrl(true)}
          >
            <PlayIcon />
          </SimpleButton>
        </div>
      )}

      {fileMessage?.type?.includes("pdf") && (
        <a href={fileMessage.url}>
          <button className="general-btn" type="button">
            <div className="heading-xsmall justify-content-center">
              <img
                style={{
                  marginRight: 12,
                }}
                src={MessageFileDownloadIcon}
                alt="download"
              />
              {fileMessage.name}
            </div>
          </button>
        </a>
      )}
    </div>
  );
};

export default FileMessageItem;
