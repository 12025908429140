import {
  ADMIN_EXERCISE_LIB_SAVE_FAIL,
  ADMIN_EXERCISE_LIB_SAVE_SUCCESS,
  ADMIN_EXERCISE_LIB_SAVING,
  AdminExerciseLibraryAction,
  AdminExerciseLibraryState,
  ADMIN_EXERCISE_DELETE_SUCCESS,
  ADMIN_EXERCISE_DELETE,
  ADMIN_EXERCISE_DELETE_FAIL,
  RESET_ADMIN_EXERCISE_LIBRARY_STATE,
} from "../../actions/AdminExerciseLibrary/types";

const initialState: AdminExerciseLibraryState = {
  isSaving: false,
  isLoading: false,
  wasDeletedExerciseActive: false,
  lastSavedExercise: null,
};

const adminExerciseLibraryState = (
  state = initialState,
  action: AdminExerciseLibraryAction
) => {
  switch (action.type) {
    case ADMIN_EXERCISE_LIB_SAVING:
      return {
        ...state,
        isSaving: true,
        lastSavedExercise: null,
      };
    case ADMIN_EXERCISE_LIB_SAVE_FAIL:
      return {
        ...state,
        isLoading: false,
        isSaving: false,
        lastSavedExercise: null,
      };
    case ADMIN_EXERCISE_DELETE:
      return {
        ...state,
        isLoading: true,
      };
    case ADMIN_EXERCISE_LIB_SAVING:
      return {
        ...state,
        isSaving: true,
        lastSavedExercise: null,
      };
    case ADMIN_EXERCISE_DELETE_SUCCESS: {
      return {
        ...state,
        isSaving: false,
        isLoading: false,
      };
    }
    case ADMIN_EXERCISE_LIB_SAVE_SUCCESS:
      return {
        ...state,
        isSaving: false,
        lastSavedExercise: action.savedExercise,
      };
    case ADMIN_EXERCISE_DELETE_FAIL: {
      if (
        action?.data?.graphQLErrors?.[0]?.extensions?.errorName ===
        "EXERCISE_IS_ACTIVE"
      ) {
        return {
          ...state,
          wasDeletedExerciseActive: true,
        };
      }
      return state;
    }
    case RESET_ADMIN_EXERCISE_LIBRARY_STATE: {
      return { ...initialState };
    }
    default:
      return state;
  }
};

export default adminExerciseLibraryState;
