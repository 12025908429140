import React, { useEffect } from "react";
import colors from "../../assets/colors";
import CheckedBox from "../../assets/images/CheckboxChecked.svg";
import UncheckedBox from "../../assets/images/CheckboxUnchecked.svg";
import { ExerciseTargetBaseType, GymStep } from "../../types/gqlTypes";
import SimpleButton from "../SimpleButton";

interface Props {
  gymStep?: GymStep;
  hideWeight?: boolean;
  warmups?: boolean;
  warmupsCount?: number;
  setWarmups?: (value: boolean) => void;
  setWarmupsCount?: (value: number) => void;
}

const Warmups = (props: Props) => {
  const {
    gymStep,
    hideWeight,
    warmups,
    warmupsCount,
    setWarmups,
    setWarmupsCount,
  } = props;

  const isRepBased =
    gymStep?.exercise?.targetBase === ExerciseTargetBaseType.Reps;
  const canIncludeWarmups = isRepBased && !hideWeight;

  useEffect(() => {
    if (hideWeight) {
      setWarmups(false);
      setWarmupsCount(null);
    }
  }, [hideWeight]);

  return (
    <div className="d-flex flex-column">
      <div
        className="d-flex"
        style={{
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <div
          className="flex-grow-1 bold"
          style={{
            marginRight: 10,
            minWidth: 150,
            fontSize: "16px",
            lineHeight: "32px",
            letterSpacing: "-0.02em",
            color: canIncludeWarmups
              ? colors.caliber_secondary
              : colors.caliber_gray_text,
          }}
        >
          Include warmup
        </div>
        <SimpleButton
          nofocus
          disabled={!canIncludeWarmups}
          onClick={() => {
            if (!warmups) {
              setWarmupsCount(3);
            }
            setWarmups(!warmups);
            (document.activeElement as HTMLElement)?.blur();
          }}
          className="align-items-center"
          style={{
            width: 24,
          }}
        >
          <img
            style={{
              width: 24,
              height: 24,
            }}
            src={warmups ? CheckedBox : UncheckedBox}
          />
        </SimpleButton>
      </div>

      {warmups && (
        <div
          className="d-flex"
          style={{
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <div
            className="flex-grow-1 bold"
            style={{
              marginRight: 10,
              minWidth: 150,
              fontSize: "16px",
              lineHeight: "32px",
              letterSpacing: "-0.02em",
              color: colors.caliber_secondary,
            }}
          >
            Warmups sets
          </div>
          <SimpleButton
            nofocus
            onClick={() => {
              setWarmupsCount(3);
              (document.activeElement as HTMLElement)?.blur();
            }}
            className="d-flex align-items-center justify-content-center medium-bold"
            style={{
              width: 36,
              height: 36,
              color:
                warmupsCount === 3
                  ? colors.caliber_secondary
                  : colors.caliber_secondary_gray_47,
              backgroundColor: warmupsCount === 3 ? "#AEB1BF" : "#DADCE3",
              borderBottomLeftRadius: 8,
              borderTopLeftRadius: 8,
            }}
          >
            3
          </SimpleButton>
          <SimpleButton
            nofocus
            onClick={() => {
              setWarmupsCount(4);
              (document.activeElement as HTMLElement)?.blur();
            }}
            className="d-flex align-items-center justify-content-center medium-bold"
            style={{
              width: 36,
              height: 36,
              color:
                warmupsCount === 4
                  ? colors.caliber_secondary
                  : colors.caliber_secondary_gray_47,
              backgroundColor: warmupsCount === 4 ? "#AEB1BF" : "#DADCE3",
              borderBottomRightRadius: 8,
              borderTopRightRadius: 8,
            }}
          >
            4
          </SimpleButton>
        </div>
      )}
    </div>
  );
};

export default Warmups;
