import {
  CaliberPageImpl_Trainer,
  MutationCreateUserProfileArgs,
  MutationUpdateUserProfileArgs,
  UserStatusType,
  UserType,
} from "../../../types/gqlTypes";
import apolloClient from "../../../api/apolloClient";
import {
  ADMIN_TRAINER_CREATING_FAIL,
  ADMIN_TRAINER_CREATING_SAVING,
  ADMIN_TRAINER_CREATING_SUCCESS,
  ADMIN_TRAINER_UPDATING_FAIL,
  ADMIN_TRAINER_UPDATING_SAVING,
  ADMIN_TRAINER_UPDATING_SUCCESS,
  AdminCreateUpdateTrainerAction,
  ADMIN_TRAINERS_SEARCH_SUCCESS,
  AdminSearchTrainersAction,
  ADMIN_TRAINERS_SEARCH_LOADING,
  ADMIN_TRAINERS_SEARCH_FAIL,
} from "./types";
import {
  CREATE_TRAINER,
  SEARCH_TRAINERS,
  UPDATE_TRAINER,
} from "../../../api/gql/users/trainers";

export const createTrainer =
  (args: MutationCreateUserProfileArgs) => async (dispatch, getState) => {
    if (getState().adminCreateUpdateTrainerState.isSaving) {
      return;
    }
    dispatch({
      type: ADMIN_TRAINER_CREATING_SAVING,
    } as AdminCreateUpdateTrainerAction);
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      await client.mutate({
        mutation: CREATE_TRAINER,
        variables: args,
      });
      dispatch({
        type: ADMIN_TRAINER_CREATING_SUCCESS,
      } as AdminCreateUpdateTrainerAction);
    } catch (error) {
      console.error("Create Trainer failed", error);
      dispatch({
        type: ADMIN_TRAINER_CREATING_FAIL,
        data: error,
        message: "The trainer has not been created.",
      } as AdminCreateUpdateTrainerAction);
    }
  };

export const editTrainer =
  (args: MutationUpdateUserProfileArgs) => async (dispatch, getState) => {
    if (getState().adminCreateUpdateTrainerState.isSaving) {
      return;
    }
    dispatch({
      type: ADMIN_TRAINER_UPDATING_SAVING,
    } as AdminCreateUpdateTrainerAction);
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const result = (
        await client.mutate({
          mutation: UPDATE_TRAINER,
          variables: args,
        })
      ).data.updateUserProfile;
      dispatch({
        type: ADMIN_TRAINER_UPDATING_SUCCESS,
        trainer: result,
      } as AdminCreateUpdateTrainerAction);
    } catch (error) {
      console.error("Update Trainer failed", error);
      dispatch({
        type: ADMIN_TRAINER_UPDATING_FAIL,
        data: error,
        message: "The trainer has not been updated.",
      } as AdminCreateUpdateTrainerAction);
    }
  };

export const searchTrainers =
  (page: number, searchCriteria: string, statusCriteria: UserStatusType[]) =>
  async (dispatch, getState) => {
    if (getState()?.userState?.user?.type === UserType.Trainer) {
      return;
    }
    if (getState().adminSearchTrainersState.isLoading) {
      return;
    }
    dispatch({
      type: ADMIN_TRAINERS_SEARCH_LOADING,
    } as AdminSearchTrainersAction);
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const result = (
        await client.query({
          query: SEARCH_TRAINERS,
          variables: {
            page,
            searchCriteria,
            statusCriteria,
          },
        })
      ).data.searchTrainers as CaliberPageImpl_Trainer;

      dispatch({
        type: ADMIN_TRAINERS_SEARCH_SUCCESS,
        data: result,
      } as AdminSearchTrainersAction);
    } catch (error) {
      console.error("Search Trainers failed", error);
      dispatch({
        type: ADMIN_TRAINERS_SEARCH_FAIL,
        data: error,
      } as AdminSearchTrainersAction);
    }
  };
