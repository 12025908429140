import apolloClient from "../../../api/apolloClient";
import {
  GET_CLIENT_DATA,
  CHANGE_CLIENT_EMAIL,
  CREATE_UPDATE_ZOOM_CALL,
  UPDATE_CLIENT_CALLS_LEFT,
  DELETE_ZOOM_CALL,
  UPDATE_CLIENT_PACKAGE_END_DATE,
} from "../../../api/gql/users/clients";
import {
  ChatRoom,
  ClientConsultation,
  ClientSalesPackage,
  MutationCreateUpdateZoomCallArgs,
  MutationDeleteZoomCallArgs,
  MutationUpdateClientSalesPackageEndDateArgs,
  TextGoal,
  User,
  ZoomCall,
} from "../../../types/gqlTypes";
import {
  ClientDetailAction,
  CHANGE_CLIENT_EMAIL_SAVING,
  CHANGE_CLIENT_EMAIL_SAVING_FAIL,
  CHANGE_CLIENT_EMAIL_SAVING_SUCCESS,
  CLIENT_CALLS_LEFT_SAVING,
  CLIENT_CALLS_LEFT_SAVING_FAIL,
  CLIENT_CALLS_LEFT_SAVING_SUCCESS,
  CLIENT_DETAIL_LOADING,
  CLIENT_DETAIL_LOAD_FAIL,
  CLIENT_DETAIL_LOAD_SUCCESS,
  ZOOM_CALL_SAVING,
  ZOOM_CALL_SAVING_FAIL,
  ZOOM_CALL_UPDATING_SUCCESS,
  ZOOM_CALL_ADDING_SUCCESS,
  ZOOM_CALL_DELETING_SUCCESS,
  CLIENT_SALES_PACKAGE_END_DATE_SAVING,
  CLIENT_SALES_PACKAGE_END_DATE_SAVING_FAIL,
  CLIENT_SALES_PACKAGE_END_DATE_SAVING_SUCCESS,
} from "./types";

export const getClientDetail = (id: string) => async (dispatch, getState) => {
  if (getState().clientDetailState.isLoading) {
    return;
  }
  dispatch({
    type: CLIENT_DETAIL_LOADING,
  });
  try {
    const client = await apolloClient(getState().authState.authToken, dispatch);
    const variables = { id };
    const result = await client.query({ query: GET_CLIENT_DATA, variables });

    const user = result.data.user as User;
    const bodyWeight = result.data.userBodyWeight;
    const bodyFatPercent = result.data.userFatPercent;
    const clientConsultation = result.data
      .clientConsultation as ClientConsultation;
    const goals = result.data.goals as TextGoal[];
    const lastMessage = result.data.chatRoom as ChatRoom;
    const { callsLeft, packageEndDate, clientPackageType }: ClientSalesPackage =
      result.data.user.clientSalesPackage;
    const { zoomCalls } = result.data;

    dispatch({
      type: CLIENT_DETAIL_LOAD_SUCCESS,
      user,
      bodyWeight,
      bodyFatPercent,
      clientConsultation,
      lastMessage,
      textGoal: goals.find(({ __typename }) => __typename === "TextGoal"),
      callsLeft,
      packageEndDate,
      clientPackageType,
      zoomCalls,
    } as ClientDetailAction);
  } catch (error) {
    dispatch({
      type: CLIENT_DETAIL_LOAD_FAIL,
    });
  }
};

export const updateClientCallsLeft =
  (clientId: string, callsLeft: number) => async (dispatch, getState) => {
    if (getState().clientDetailState.isSaving) {
      return;
    }
    dispatch({
      type: CLIENT_CALLS_LEFT_SAVING,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const result = await client.mutate({
        mutation: UPDATE_CLIENT_CALLS_LEFT,
        variables: { clientId, callsLeft },
      });

      dispatch({
        type: CLIENT_CALLS_LEFT_SAVING_SUCCESS,
        callsLeft: result.data.updateClientSalesPackageCallsLeft.callsLeft,
      });
    } catch (error) {
      dispatch({
        type: CLIENT_CALLS_LEFT_SAVING_FAIL,
      });
    }
  };

export const updateClientEmail =
  (clientId: string, email: string) => async (dispatch, getState) => {
    if (getState().clientDetailState.isSaving) {
      return;
    }
    dispatch({
      type: CHANGE_CLIENT_EMAIL_SAVING,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      await client.mutate({
        mutation: CHANGE_CLIENT_EMAIL,
        variables: { clientId, newEmail: email },
      });

      dispatch({
        type: CHANGE_CLIENT_EMAIL_SAVING_SUCCESS,
        email,
      });
    } catch (error) {
      dispatch({
        type: CHANGE_CLIENT_EMAIL_SAVING_FAIL,
      });
    }
  };

export const createUpdateZoomCall =
  (args: MutationCreateUpdateZoomCallArgs) => async (dispatch, getState) => {
    if (getState().clientDetailState.isSaving) {
      return;
    }
    dispatch({
      type: ZOOM_CALL_SAVING,
    });

    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const result = await client.mutate({
        mutation: CREATE_UPDATE_ZOOM_CALL,
        variables: args,
      });

      if (args.zoomCallId) {
        dispatch({
          type: ZOOM_CALL_UPDATING_SUCCESS,
          selectedZoomCall: result.data.createUpdateZoomCall,
        });
      } else {
        dispatch({
          type: ZOOM_CALL_ADDING_SUCCESS,
          selectedZoomCall: result.data.createUpdateZoomCall,
        });
      }

      // Update strategy calls left
      dispatch(getClientDetail(args.clientId));
    } catch (error) {
      dispatch({
        type: ZOOM_CALL_SAVING_FAIL,
        data: error,
      });
    }
  };

export const deleteZoomCall =
  (args: MutationDeleteZoomCallArgs) => async (dispatch, getState) => {
    if (getState().clientDetailState.isSaving) {
      return;
    }
    dispatch({
      type: ZOOM_CALL_SAVING,
    });

    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      await client.mutate({
        mutation: DELETE_ZOOM_CALL,
        variables: args,
      });

      dispatch({
        type: ZOOM_CALL_DELETING_SUCCESS,
        selectedZoomCall: { id: args.zoomCallId } as ZoomCall,
      });

      // Update strategy calls left
      dispatch(getClientDetail(args.clientId));
    } catch (error) {
      dispatch({
        type: ZOOM_CALL_SAVING_FAIL,
        data: error,
      });
    }
  };

export const updateClientSalesPackageEndDate =
  (args: MutationUpdateClientSalesPackageEndDateArgs) =>
  async (dispatch, getState) => {
    if (getState().clientDetailState.isSaving) {
      return;
    }
    dispatch({
      type: CLIENT_SALES_PACKAGE_END_DATE_SAVING,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const result = await client.mutate({
        mutation: UPDATE_CLIENT_PACKAGE_END_DATE,
        variables: args,
      });

      dispatch({
        type: CLIENT_SALES_PACKAGE_END_DATE_SAVING_SUCCESS,
        callsLeft: result.data.updateClientSalesPackageCallsLeft.callsLeft,
      });
    } catch (error) {
      dispatch({
        type: CLIENT_SALES_PACKAGE_END_DATE_SAVING_FAIL,
      });
    }
  };
