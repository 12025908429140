import React from "react";
import colors from "../../../assets/colors";
import DefaultWorkoutImage from "../../../assets/images/DefaultWorkoutImage.svg";
import {
  ClientWorkoutTemplate,
  Superset,
  Workout,
} from "../../../types/gqlTypes";
import SimpleButton from "../../SimpleButton";

interface Props {
  workoutTemplate: ClientWorkoutTemplate;
  onClick?: () => void;
}

const WorkoutTemplateCard = (props: Props) => {
  const { workoutTemplate, onClick } = props;

  const titleStyle = {
    fontSize: "18px",
    lineHeight: "22px",
    letterSpacing: "-0.03em",
    color: colors.caliber_secondary,
    height: 40,
    paddingTop: 5,
    margin: "0px 8px",
  };
  const tagStyle = {
    fontSize: "14px",
    lineHeight: "16px",
    color: colors.caliber_secondary,
    height: 40,
    paddingBottom: 5,
    margin: "0px 16px",
  };

  const numberOfExercises = () => {
    let counter: number = 0;
    (workoutTemplate as Workout)?.gymSteps?.forEach((gymStep) => {
      if (gymStep.exercise) {
        counter += 1;
      } else {
        (gymStep as Superset).gymSteps.forEach((supersetChild) => {
          if (supersetChild.exercise) {
            counter += 1;
          }
        });
      }
    });

    return counter;
  };

  return (
    <SimpleButton
      onClick={() => {
        onClick?.();
      }}
      nofocus
      className="d-flex bold pointer"
      style={{
        width: 398,
        height: 80,
        padding: 8,
        marginBottom: 8,
        marginRight: 2,
        backgroundColor: colors.caliber_secondary_gray_5,
        borderRadius: 8,
      }}
    >
      <div
        style={{
          width: 64,
          height: 64,
          borderRadius: 8,
          margin: "0px 8px",
        }}
      >
        <img
          style={{
            width: 64,
            height: 64,
            borderRadius: 8,
          }}
          src={workoutTemplate?.thumbnailImage?.url || DefaultWorkoutImage}
        />
      </div>
      <div className="d-flex flex-column">
        <div className="d-flex align-items-center" style={titleStyle}>
          {workoutTemplate?.title}
        </div>
        <div className="d-flex align-items-center" style={tagStyle}>
          {`${numberOfExercises() || 0} Exercise${
            numberOfExercises() === 1 ? "" : "s"
          }`}
        </div>
      </div>
    </SimpleButton>
  );
};

export default WorkoutTemplateCard;
