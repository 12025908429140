import React, { useState } from 'react';
import { connect } from 'react-redux';
import SendBird from 'sendbird';
import SimpleButton from '../SimpleButton';
import MessageTrashIcon from '../../assets/images/MessageTrashIcon.svg';
import NewEmoji from '../../assets/images/Emoji/NewEmoji.svg';
import colors from '../../assets/colors';
import { getTrainer, StoreState } from '../../redux/reducers';
import { User } from '../../types/gqlTypes';
import EmojiPickerPopup from './EmojiPickerPopup';

interface Props {
  isDeletable: boolean;
  selectedReactions: SendBird.Reaction[];
  onDelete: () => void;
  onClose: () => void;
  toggleMenu: (isClosed: boolean) => void;
  addReaction: (emojiKey: string) => void;
  trainer: User;
}

export enum EmojiTypes {
  Arm = 'arm',
  Handsup = 'handsup',
  Ok = 'ok',
  Shades = 'shades',
  Smiley = 'smiley',
  Thumbsup = 'thumbsup',
  NewEmoji = 'newemoji',
}

const MessageShortcutMenu = (props: Props) => {
  const {
    isDeletable,
    selectedReactions,
    onDelete,
    onClose,
    toggleMenu,
    addReaction,
    trainer,
  } = props;
  const [show, setShow] = useState(false);
  const [hover, setHover] = useState(null);

  const onBlur = () => {
    setTimeout(() => {
      const emojiButton = document.activeElement?.className?.split(' ')?.find(className => className === 'emojiButton');
      if (emojiButton == null) {
        onClose();
      }
    }, 0);
  };

  const smallerGrayCircleStyle: React.CSSProperties = {
    position: 'absolute',
    zIndex: 2,
    width: 28,
    height: 28,
    borderRadius: 14,
    backgroundColor: colors.caliber_secondary_gray_5,
  };

  return (
    <div>
      {show && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            position: 'absolute',
            right: isDeletable ? 138 : 106,
            top: -359,
          }}
          onBlur={onBlur}
        >
          <EmojiPickerPopup
            onEmojiClick={addReaction}
          />
        </div>
      )}
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          position: 'absolute',
          right: 60,
          top: -18,
          width: isDeletable ? 69 : 36,
          height: 36,
          borderRadius: 6,
          backgroundColor: colors.caliber_white,
          boxShadow: '0px 0px 10px 0px #B9B9B9',
        }}
      >
        <div
          className="d-flex justify-content-center align-items-center"
          onBlur={onBlur}
          style={{
            position: 'relative',
          }}
        >
          <SimpleButton
            isIcon
            nofocus
            className="d-flex justify-content-center align-items-center"
            onClick={() => {
              setShow(!show);
              toggleMenu(!show);
            }}
            onMouseEnter={() => setHover(EmojiTypes.NewEmoji)}
            onMouseLeave={() => setHover(null)}
            iconStyle={{
              width: 20,
              height: 20,
            }}
            style={{
              zIndex: 3,
              width: 20,
              height: 20,
            }}
          >
            {NewEmoji}
          </SimpleButton>
          {hover === EmojiTypes.NewEmoji && (
            <div style={smallerGrayCircleStyle}>{/* gray circle */}</div>
          )}
        </div>
        {isDeletable && (
          <SimpleButton
            style={{
              height: 24,
              width: 20,
              marginLeft: 8,
            }}
            onClick={onDelete}
          >
            <img alt="Trash" src={MessageTrashIcon}/>
          </SimpleButton>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  trainer: getTrainer(state),
});

export default connect(mapStateToProps)(MessageShortcutMenu);
