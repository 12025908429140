import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import colors from "../../assets/colors";
import { searchTrainers } from "../../redux/actions/AdminTeamTrainers";
import { AdminSearchTrainersState } from "../../redux/actions/AdminTeamTrainers/types";
import { MasqueradeState } from "../../redux/actions/Masquerade/types";
import { reassignClientTrainer } from "../../redux/actions/ReassignClientTrainer";
import { ClientDetailState } from "../../redux/actions/ClientDetail/types";
import { getIsClientPremium, StoreState } from "../../redux/reducers";
import {
  Client,
  MutationReassignClientArgs,
  UserStatusType,
} from "../../types/gqlTypes";
import { formatMeasurement } from "../../utils/measurements";
import ClientStatusTag from "../ClientStatusTag";
import ConfirmationDialog from "../ConfirmationDialog";
import Loader from "../Loader";
import ProfilePhoto from "../ProfilePhoto";
import SearchDropdown, { DropdownItem } from "../SearchDropdown";
import CollapsedProfile from "./collapsedProfile";
import FullViewProfile from "./fullViewProfile";

interface OwnProps {
  clientInfo: Client;
  fullView: boolean;
  notShowLessInfo?: boolean;
  isLoading?: boolean;
  bodyWeight: number;
}
interface Props extends OwnProps {
  clientDetailState: ClientDetailState;
  searchTrainerState: AdminSearchTrainersState;
  masqueradeState: MasqueradeState;
  isReassigningLoading: boolean;
  isPremium: boolean;
  searchTrainers: (page: number, searchCriteria: string) => void;
  reassignClientTrainer: (args: MutationReassignClientArgs) => void;
}

const Profile = (props: Props) => {
  const {
    fullView,
    notShowLessInfo,
    bodyWeight,
    clientInfo,
    clientDetailState,
    searchTrainerState,
    masqueradeState,
    isReassigningLoading,
    isPremium,
    isLoading,
    searchTrainers,
    reassignClientTrainer,
  } = props;

  const { id: clientId } = useParams();

  const [newTrainer, setNewTrainer] = useState(null);
  const [localFullView, setFullView] = useState(fullView);
  const toggleView = () => setFullView(!localFullView);
  const profilePic = clientInfo.profileIconMediaUrl?.url;

  const {
    firstName,
    lastName,
    age,
    country,
    city,
    state,
    phone,
    email,
    height,
    status,
    deviceType,
    gender,
    assignedTrainer,
    proGroups,
  } = clientInfo;

  const client = {
    /* if collapsed: third argument (space between) should be false */
    /* TODO: when API will be ready - pull units preferred by trainer - not client  */
    location: formatMeasurement([city, state, country], "location"),
    clientAge: formatMeasurement(age, "age"),
    clientHeight: formatMeasurement(height, "IN", fullView),
    clientWeight: formatMeasurement(bodyWeight, "LB", fullView),
    phone,
    email,
    settings: clientInfo.settings,
    joinedDate: clientInfo?.joinedDate,
    status,
    deviceType,
    gender,
    assignedTrainer,
    clientPackageType: clientDetailState.clientPackageType,
    callsLeft: clientDetailState.callsLeft,
    packageEndDate: clientDetailState.packageEndDate,
    proGroups: proGroups,
  };

  const { foundTrainers } = searchTrainerState;

  const searchItems: DropdownItem[] = useMemo(() => {
    let trainerFound = false;
    const trainersOptions = foundTrainers.map((trainer) => {
      trainerFound =
        trainerFound || trainer.id === clientInfo?.assignedTrainer?.id;
      return {
        text: `${trainer.firstName} ${trainer.lastName}`,
        value: trainer.id,
        thumbnailUrl: trainer.profileIconMediaUrl?.url,
      };
    });
    if (!trainerFound) {
      trainersOptions.unshift({
        text: `${clientInfo?.assignedTrainer?.firstName} ${clientInfo?.assignedTrainer?.lastName}`,
        value: clientInfo?.assignedTrainer?.id,
        thumbnailUrl: clientInfo?.assignedTrainer?.profileIconMediaUrl?.url,
      });
    }
    return trainersOptions;
  }, [foundTrainers]);

  const picWidth = 96;
  const picPadding = 10;

  return (
    <div className="d-flex flex-column" style={{ position: "relative" }}>
      {(isReassigningLoading || isLoading) && (
        <div
          style={{
            position: "absolute",
            flex: 1,
            top: 240,
            left: "45%",
            zIndex: 3,
          }}
        >
          <Loader />
        </div>
      )}
      {newTrainer && (
        <ConfirmationDialog
          show
          text={`Are you sure you want to reassign ${firstName || ""} ${
            lastName || ""
          } to ${newTrainer?.firstName || ""} ${newTrainer?.lastName || ""}?`}
          confirmButtonText="Reassign"
          cancelButtonText="Cancel"
          onConfirm={() => {
            reassignClientTrainer({ clientId, trainerId: newTrainer.id });
            setNewTrainer(null);
          }}
          onCancel={() => {
            setNewTrainer(null);
          }}
        />
      )}
      <div className="col-16 " style={{ position: "relative" }}>
        <div
          style={{
            backgroundColor: colors.caliber_gray_5,
            height: 80,
            borderRadius: "8px 8px 0 0",
          }}
        >
          {/* gray rectangle */}
        </div>
        <div
          style={{
            backgroundColor: colors.caliber_white,
            padding: picPadding,
            zIndex: 2,
            borderRadius: "50%",
            position: "absolute",
            top: 42,
            left: `calc(50% - ${picWidth / 2 + picPadding}px)`,
          }}
        >
          <ProfilePhoto
            isPremium={isPremium}
            imageUrl={profilePic}
            size={picWidth}
          />
        </div>
      </div>
      <div
        style={{ marginTop: 75 }}
        className="align-self-center heading-normal"
      >
        {`${firstName || ""} ${lastName || ""}`}
      </div>
      <div className="d-flex justify-content-center">
        <ClientStatusTag status={status} />
      </div>
      {masqueradeState.masqueradeTrainer && (
        <div className="d-flex justify-content-center">
          <div
            style={{
              minWidth: 124,
              maxWidth: 240,
              marginTop: 14,
            }}
          >
            <SearchDropdown
              placeholder=""
              showThumbnails
              height={24}
              margin="0px 0px 0px 24px"
              items={searchItems}
              search={searchTrainers}
              value={clientInfo?.assignedTrainer?.id}
              page={searchTrainerState.page}
              totalPages={searchTrainerState.totalPages}
              onSelect={(trainerId: string) => {
                if (clientInfo?.assignedTrainer?.id !== trainerId) {
                  setNewTrainer(
                    foundTrainers.find((trainer) => trainer.id === trainerId)
                  );
                }
              }}
              borderColor="#DCDDE0"
              textColor="#AEB1BF"
              bgColor="#FFF"
              borderRadius={24}
            />
          </div>
        </div>
      )}
      {fullView || localFullView ? (
        <FullViewProfile
          notShowLessInfo={notShowLessInfo}
          showConnectedDevices
          client={client}
          collapse={toggleView}
        />
      ) : (
        <CollapsedProfile client={client} expand={toggleView} />
      )}
    </div>
  );
};
const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  clientDetailState: state.clientDetailState,
  searchTrainerState: state.adminSearchTrainersState,
  masqueradeState: state.masqueradeState,
  isReassigningLoading: state.reassignClientTrainerState.isLoading,
  isPremium: getIsClientPremium(state),

  clientInfo: ownProps.clientInfo,
  fullView: ownProps.fullView,
  notShowLessInfo: ownProps.notShowLessInfo,
  isLoading: ownProps.isLoading,
  bodyWeight: ownProps.bodyWeight,
});

const mapDispatchToProps = (dispatch) => ({
  searchTrainers: (page: number, searchCriteria: string) => {
    dispatch(
      searchTrainers(
        page,
        searchCriteria,
        UserStatusType[UserStatusType.Active]
      )
    );
  },
  reassignClientTrainer: (args: MutationReassignClientArgs) => {
    dispatch(reassignClientTrainer(args));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
