import React from "react";
import colors from "../../../assets/colors";
import ArrowIcons, { ArrowIconType } from "../../Icons/ArrowIcons";
import SimpleButton from "../../SimpleButton";

interface Props {
  text: string;
  isOpen: boolean;
  onBlur: () => void;
  onClick: () => void;
}

const DropdownButton = (props: Props) => {
  const { text, isOpen, onBlur, onClick } = props;

  return (
    <SimpleButton
      id="dropdown_element"
      onBlur={onBlur}
      onClick={onClick}
      className="d-flex align-items-center justify-content-between medium-bold"
      style={{
        backgroundColor: colors.caliber_gray_5,
        border: `1px solid ${colors.caliber_gray_5}`,
        borderRadius: 8,
        height: 48,
        paddingLeft: 16,
        paddingRight: 17,
        flex: 1,
        marginRight: 400,
      }}
    >
      <div
        style={{
          fontSize: "14px",
          color: "#2C3052",
        }}
      >
        {text}
      </div>

      <ArrowIcons
        iconType={ArrowIconType.DROPDOWN}
        direction={isOpen ? "up" : "down"}
        color={colors.caliber_secondary_gray_47}
        width={8}
        height={12}
      />
    </SimpleButton>
  );
};

export default DropdownButton;
