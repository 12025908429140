import React, { useRef } from "react";
import { Image } from "react-bootstrap";
import PhotoIcon from "../../assets/images/PhotoIcon.svg";
import colors from "../../assets/colors";
import Loader from "../Loader";

interface Props {
  imageUrl?: string;
  onImagePicked: (file: any) => void;
  width: number;
  height: number;
  hasRoundBorder?: boolean;
  disabled?: boolean;
  showLoader?: boolean;
}

const EditImage = (props: Props) => {
  const {
    imageUrl,
    onImagePicked,
    width,
    height,
    hasRoundBorder,
    disabled,
    showLoader,
  } = props;

  const fileInputRef = useRef(null);

  const imageStyle = {
    backgroundColor: colors.caliber_white,
    borderRadius: hasRoundBorder ? 16 : 0,
    width,
    height,
  };

  return (
    <div
      className={`d-flex ${showLoader ? "" : "pointer"}`}
      role="button"
      tabIndex={0}
      onClick={() => {
        if (!disabled) {
          fileInputRef.current.click();
        }
      }}
      onKeyDown={(event) => {
        if (event.key === "Enter" && !disabled) {
          fileInputRef.current.click();
        }
      }}
      style={{
        position: "relative",
        backgroundColor: colors.caliber_white,
        borderRadius: hasRoundBorder ? 16 : 0,
      }}
    >
      <input
        ref={fileInputRef}
        type="file"
        name="image"
        onChange={(event) => {
          if (event.target == null || event.target.files[0] == null) {
            return;
          }
          const file = event.target.files[0];
          if (file.type.startsWith("image")) {
            onImagePicked(event.target.files[0]);
          } else {
            // TODO: Display error
            console.log("Invalid filetype", file.type);
          }
        }}
        style={{
          display: "none",
        }}
      />
      <div style={imageStyle}>
        {imageUrl && <Image style={imageStyle} src={imageUrl} />}
      </div>
      <div
        className="d-flex paragraph-small justify-content-center align-items-center flex-column"
        style={{
          position: "absolute",
          backgroundColor: `${colors.caliber_secondary}4D`,
          width: "100%",
          height: "100%",
          color: colors.caliber_white,
          borderRadius: hasRoundBorder ? 16 : 0,
        }}
      >
        {showLoader ? (
          <Loader />
        ) : (
          <>
            <img
              style={{
                marginBottom: 12,
                objectFit: "contain",
              }}
              src={PhotoIcon}
              alt="Edit"
            />
            Edit Image
          </>
        )}
      </div>
    </div>
  );
};

export default EditImage;
