import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  AdminMessage,
  FileMessage,
  GroupChannel,
  Member,
  UserMessage,
} from "sendbird";
import colors from "../../assets/colors";
import EmptyMessagePane from "../../assets/images/EmptyMessagePane.svg";
import { HEADER_HEIGHT } from "../../components/Header";
import Loader from "../../components/Loader";
import MessageListPaneItem from "../../components/MessageListPaneItem";
import MessagesChatPane from "../../components/MessagesChatPane";
import { MessagesState } from "../../redux/actions/Messages/types";
import { getTrainerPremiumClients } from "../../redux/actions/User";
import { StoreState } from "../../redux/reducers";
import { User } from "../../types/gqlTypes";

interface Props {
  messagesState: MessagesState;
  premiumClients: User[];
  getTrainerPremiumClients: () => void;
}

interface ClientMessageItem {
  client: Member;
  lastMessage: UserMessage | FileMessage | AdminMessage;
  channel: GroupChannel;
}

const TrainerMessagesScreen = (props: Props) => {
  const { messagesState, premiumClients, getTrainerPremiumClients } = props;

  const [search, setSearch] = useState("");
  const [firstRenderChats, setFirstRenderChats] = useState({});
  const location = useLocation();
  const splitPath = location.pathname.split("/");
  const selectedClientId = splitPath[2];

  const selectedChannel = messagesState.availableChannels.find((channel) => {
    return channel.members.find((member) => member.userId === selectedClientId);
  });

  useEffect(() => {
    getTrainerPremiumClients();
  }, []);

  const premiumClientsObject = useMemo(() => {
    const obj = {};
    premiumClients?.map((client) => {
      obj[client.id] = true;
    });
    return obj;
  }, [premiumClients]);

  const clientMessageList: ClientMessageItem[] = messagesState.availableChannels
    .filter((channel) => {
      return channel.members.find((member) => {
        const metaData = member.metaData as any;
        return metaData.role === "CLIENT";
      });
    })
    .map((channel) => ({
      client: channel.members.find((member) => {
        const metaData = member.metaData as any;
        return metaData.role === "CLIENT";
      }),
      lastMessage: channel.lastMessage,
      channel,
    }));

  const filteredClientMessageList = useMemo(() => {
    if (search.length === 0) {
      return clientMessageList;
    }
    return clientMessageList.filter((item) => {
      const strArr = search.split(" ");
      return strArr.some((searchString) =>
        item.client.nickname
          .toLocaleLowerCase()
          .includes(searchString.toLocaleLowerCase())
      );
    });
  }, [search, clientMessageList]);

  return (
    <div className="d-flex w-100">
      <div
        className="d-flex flex-column overflow-auto"
        style={{
          flex: "0 0 310px",
          height: `calc(100vh - ${HEADER_HEIGHT}px)`,
        }}
      >
        {messagesState?.isLoading && (
          <div
            style={{
              position: "absolute",
              top: "5%",
              left: "calc(50% - 24px)",
            }}
          >
            <Loader />
          </div>
        )}
        <div
          style={{
            backgroundColor: colors.caliber_white,
            height: 68,
            width: 296,
            padding: 16,
            marginBottom: 2,
          }}
        >
          <input
            className="d-flex align-items-center medium-bold"
            style={{
              paddingLeft: 12,
              backgroundColor: colors.caliber_gray_bg,
              border: `1px solid ${colors.caliber_gray_bg}`,
              fontSize: "14px",
              lineHeight: "32px",
              height: 36,
              width: 264,
              borderRadius: 8,
              color: colors.caliber_secondary,
            }}
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            placeholder="Search"
          />
        </div>
        {filteredClientMessageList.map(
          (item) =>
            item.client && (
              <Link
                key={`/messages/${item.client.userId}`}
                to={`/messages/${item.client.userId}`}
              >
                <MessageListPaneItem
                  isClientPremium={premiumClientsObject?.[item.client?.userId]}
                  key={item.client.userId}
                  unreadCount={item.channel.unreadMessageCount}
                  userInfo={item.client}
                  lastMessage={item.lastMessage}
                  isSelected={selectedClientId === item.client.userId}
                />
              </Link>
            )
        )}
      </div>
      <div
        className="d-flex flex-grow-1"
        style={{
          height: `calc(100vh - ${HEADER_HEIGHT}px)`,
        }}
      >
        {selectedClientId && selectedChannel ? (
          <MessagesChatPane
            isClientPremium={premiumClientsObject?.[selectedClientId]}
            isNotFirstRenderChat={firstRenderChats[selectedClientId]}
            onFirstRender={() =>
              setFirstRenderChats((state) => ({
                ...state,
                [selectedClientId]: true,
              }))
            }
            key={selectedClientId}
            channel={selectedChannel}
            clientId={selectedClientId}
          />
        ) : (
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{
              flex: 1,
            }}
          >
            <img src={EmptyMessagePane} />
            <div
              className="medium-bold"
              style={{
                fontSize: "16px",
                lineHeight: "24px",
                color: colors.caliber_secondary,
                marginTop: 24,
              }}
            >
              Select a client to compose a message
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getTrainerPremiumClients: () => {
    dispatch(getTrainerPremiumClients());
  },
});

const mapStateToProps = (state: StoreState) => ({
  messagesState: state.messagesState,
  premiumClients: state.authenticatedUserState.premiumClients,
  authenticatedUser: state.authenticatedUserState.user,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrainerMessagesScreen);
