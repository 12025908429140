import React from "react";
import colors from "../../../assets/colors";
import SimpleButton from "../../SimpleButton";

interface Props {
  disabled: boolean;
  onSave: () => void;
}

const Footer = (props: Props) => {
  const { disabled, onSave } = props;

  return (
    <div className="d-flex justify-content-end">
      <SimpleButton
        disabled={disabled}
        onClick={onSave}
        className="d-flex justify-content-center align-items-center medium-bold"
        style={{
          fontSize: "14px",
          lineHeight: "17px",
          letterSpacing: "-0.022em",
          backgroundColor: disabled
            ? colors.caliber_secondary_gray_11
            : colors.caliber_green_200,
          color: disabled
            ? colors.caliber_secondary_gray_38
            : colors.caliber_white,
          marginRight: 14,
          width: 146,
          height: 36,
          borderRadius: 8,
          marginTop: 48,
        }}
      >
        Save set
      </SimpleButton>
    </div>
  );
};

export default Footer;
