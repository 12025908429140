import { gql } from "apollo-boost";

export const CREATE_UPDATE_CLIENT_HABIT_TARGET = gql`
  mutation CreateUpdateClientHabitTarget(
    $clientId: UUID
    $habitType: HabitType
    $target: Int
  ) {
    createUpdateClientHabitTarget(
      clientId: $clientId
      habitType: $habitType
      target: $target
    ) {
      id
      target
      habitType
    }
  }
`;

export const GET_CLIENT_HABITS_TARGETS = gql`
  query ClientHabitTargets($clientId: UUID) {
    clientHabitTargets(clientId: $clientId) {
      id
      target
      habitType
    }
  }
`;

export const DELETE_CLIENT_HABIT_TARGET = gql`
  mutation DeleteHabitTarget($clientId: UUID, $habitTargetId: UUID) {
    deleteHabitTarget(clientId: $clientId, habitTargetId: $habitTargetId)
  }
`;
