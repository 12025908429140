import React from "react";

const Memo = () => {
  return (
    <div
      style={{
        backgroundColor: "#EBEDFF",
        padding: "12px 24px 12px 12px",
        borderRadius: 4,
        margin: "12px 24px 8px 24px",
      }}
    >
      <div
        style={{
          fontSize: "12px",
          lineHeight: "14px",
          letterSpacing: "-0.em",
          color: "#111B79",
        }}
      >
        Customize the columns you would like to see displayed on your Client
        dashboard. Once you’ve selected your columns, your customized view will
        be saved as a preset.
      </div>
    </div>
  );
};

export default Memo;
