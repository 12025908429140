import {
  ComparisonToolAction,
  ComparisonToolState,
  DISCARD_COMPARISON_IMAGE,
  SAVE_COMPARISON_IMAGE,
  UPLOAD_COMPARISON_IMAGE_FAIL,
  UPLOAD_COMPARISON_IMAGE_SUCCESS,
  UPLOAD_COMPARISON_IMAGE_UPLOADING,
} from "../../actions/ComparisonTool/types";

const initialState: ComparisonToolState = {
  library: {},
  isUploading: false,
};

const comparisonToolState = (
  state = initialState,
  action: ComparisonToolAction
) => {
  switch (action.type) {
    case UPLOAD_COMPARISON_IMAGE_UPLOADING:
      return {
        ...state,
        isUploading: true,
      };
    case UPLOAD_COMPARISON_IMAGE_FAIL:
      return {
        ...state,
        isUploading: false,
      };
    case UPLOAD_COMPARISON_IMAGE_SUCCESS: {
      const newLibrary = { ...state.library };
      delete newLibrary[action.userId];
      return {
        ...state,
        isUploading: false,
        library: newLibrary,
      };
    }
    case SAVE_COMPARISON_IMAGE:
      return {
        ...state,
        library: {
          ...state.library,
          [action.userId]: action.img,
        },
      };
    case DISCARD_COMPARISON_IMAGE:
      const newLibrary = { ...state.library };
      delete newLibrary[action.userId];
      return {
        ...state,
        library: newLibrary,
      };
    default:
      return state;
  }
};

export default comparisonToolState;
