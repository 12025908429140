import React from "react";
import colors from "../../../assets/colors";
import { getWeekdayName, WeekdayRecurrenceType } from "../../../utils";
import Dropdown from "../../Dropdown";
import RadioButton from "../../Icons/RadioButton";
import SimpleButton from "../../SimpleButton";
import { RecurrenceFrequencyType } from "./Frequency";

export enum DayOfMonthType {
  LAST = "LAST",
  FIRST = "FIRST",
}
export enum DaysOfWeekType {
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
  SIX = 6,
}
interface Props {
  isSelected: boolean;
  frequencyType: RecurrenceFrequencyType;
  dayOfMonth?: DayOfMonthType;
  daysOfWeek?: DaysOfWeekType;
  weekdays: WeekdayRecurrenceType[];
  onCheck: () => void;
  onSelectDayOfMonth?: (value: DayOfMonthType) => void;
  onSelectDaysOfWeek?: (value: DaysOfWeekType) => void;
  onSelectWeekdays?: (value: WeekdayRecurrenceType[]) => void;
}

const EveryDayFrequency = (props: Props) => {
  const {
    isSelected,
    frequencyType,
    dayOfMonth,
    daysOfWeek,
    weekdays,
    onCheck,
    onSelectDayOfMonth,
    onSelectDaysOfWeek,
    onSelectWeekdays,
  } = props;

  const week = [
    WeekdayRecurrenceType.MONDAY,
    WeekdayRecurrenceType.TUESDAY,
    WeekdayRecurrenceType.WEDNESDAY,
    WeekdayRecurrenceType.THURSDAY,
    WeekdayRecurrenceType.FRIDAY,
    WeekdayRecurrenceType.SATURDAY,
    WeekdayRecurrenceType.SUNDAY,
  ];
  const textStyle: React.CSSProperties = {
    fontSize: "16px",
    lineHeight: "16px",
    color: "#000000",
    height: 36,
    margin: "0px 8px",
  };
  if (frequencyType === RecurrenceFrequencyType.MONTHLY) {
    return (
      <div
        className="d-flex align-items-center"
        style={{
          margin: "12px 12px",
          height: 36,
        }}
      >
        <RadioButton checked={isSelected} onClick={onCheck} />
        <SimpleButton
          nofocus
          onClick={onCheck}
          className="d-flex align-items-center medium-bold"
          style={textStyle}
        >
          Every
        </SimpleButton>
        <div
          style={{
            width: 80,
            marginRight: 12,
          }}
        >
          <Dropdown
            height={36}
            customPadding="0px 24px 0px 10px"
            onSelect={onSelectDayOfMonth}
            value={dayOfMonth}
            items={[
              { value: DayOfMonthType.FIRST, text: "First" },
              { value: DayOfMonthType.LAST, text: "Last" },
            ]}
          />
        </div>
        <div
          style={{
            width: 125,
          }}
        >
          <Dropdown
            height={36}
            customPadding="0px 24px 0px 10px"
            onSelect={(value) => onSelectWeekdays([value])}
            value={weekdays[0]}
            items={week.map((day) => ({
              value: day,
              text: getWeekdayName(day, 0),
            }))}
          />
        </div>
      </div>
    );
  }
  return (
    <div
      className="d-flex align-items-center"
      style={{
        margin: "12px 12px",
        height: 36,
      }}
    >
      <RadioButton checked={isSelected} onClick={onCheck} />
      <SimpleButton
        nofocus
        onClick={onCheck}
        className="d-flex align-items-center medium-bold"
        style={textStyle}
      >
        Every
      </SimpleButton>
      <div
        style={{
          width: 58,
        }}
      >
        <Dropdown
          height={36}
          customPadding="0px 24px 0px 10px"
          onSelect={onSelectDaysOfWeek}
          value={daysOfWeek}
          items={[
            { value: DaysOfWeekType.ONE, text: "1" },
            { value: DaysOfWeekType.TWO, text: "2" },
            { value: DaysOfWeekType.THREE, text: "3" },
            { value: DaysOfWeekType.FOUR, text: "4" },
            { value: DaysOfWeekType.FIVE, text: "5" },
            { value: DaysOfWeekType.SIX, text: "6" },
          ]}
        />
      </div>
      <div className="d-flex align-items-center medium-bold" style={textStyle}>
        week on
      </div>
      <div className="d-flex">
        {week.map((day, index) => {
          const letter = getWeekdayName(day, 1);
          const isFirst = index === 0;
          const isLast = index === 6;
          const isSelected = weekdays.includes(day);
          return (
            <SimpleButton
              key={day}
              onClick={() => {
                if (isSelected) {
                  onSelectWeekdays(
                    weekdays.filter((weekday) => weekday !== day)
                  );
                } else {
                  onSelectWeekdays([...weekdays, day]);
                }
              }}
              nofocus
              className="d-flex justify-content-center align-items-center medium-bold"
              style={{
                fontSize: "12px",
                lineHeight: "12px",
                letterSpacing: "-0.044em",
                color: isSelected
                  ? colors.caliber_secondary
                  : colors.caliber_gray_text,
                backgroundColor: isSelected
                  ? colors.caliber_secondary_gray_29
                  : colors.caliber_secondary_gray_5,
                borderTopLeftRadius: isFirst && 4,
                borderBottomLeftRadius: isFirst && 4,
                borderTopRightRadius: isLast && 4,
                borderBottomRightRadius: isLast && 4,
                borderRight: !isLast && "1px solid #B5B7C4",
                width: 28,
                height: 36,
              }}
            >
              {letter}
            </SimpleButton>
          );
        })}
      </div>
    </div>
  );
};

export default EveryDayFrequency;
