import React, { useState } from "react";
import colors from "../../assets/colors";
import { useRerenderOnResize } from "../../utils/customHooks";
import ButtonTag from "../ButtonTag";

interface Props {
  onClick?: (props: object) => void;
  title: string;
  titleColor?: string;
  names: string[];
  selectedNames: string[];
  variant?: "filter-button" | "tag-button";
  buttonMinWidth?: number;
  displayTextFunction?: (type: string) => string;
  hoverDisplayTextFunction?: (type: string) => string;
}

const ButtonTagGroup = (props: Props) => {
  const {
    onClick,
    title,
    titleColor,
    names,
    variant,
    buttonMinWidth,
    selectedNames,
    displayTextFunction,
    hoverDisplayTextFunction,
  } = props;
  const [currentHoverText, setCurrentHoverText] = useState("");

  const minWidth = 208;
  const itemWidth = 104;
  useRerenderOnResize();
  const filterDiv = document.getElementById(`filter-side-${title}`);
  const width = filterDiv ? filterDiv.offsetWidth : minWidth;
  const paddingRight = (width % itemWidth) + 8;

  const onClickTag = (props) => {
    onClick(props);
  };
  const onMouseEnter = (textDescription) => {
    setCurrentHoverText(textDescription);
  };
  const onMouseLeave = () => {
    setCurrentHoverText("");
  };

  return (
    <div className="d-flex flex-column">
      <div id={`filter-side-${title}`} key={title}>
        <div
          className="d-flex flex-wrap justify-content-between paragraph-small"
          style={{
            padding: "4px 0px",
            minWidth,
            paddingRight,
            color: titleColor || colors.caliber_gray_border,
          }}
        >
          <div>{title}</div>
          <div
            style={{ height: 20, marginTop: 1 }}
            className={`paragraph-${
              width > 313 ? "small" : "xsmall"
            } d-flex flex-column justify-content-center`}
          >
            {hoverDisplayTextFunction && currentHoverText}
          </div>
        </div>
        <div className="d-flex flex-wrap">
          {names.map((name) => {
            const isSelected = selectedNames.includes(name);
            return (
              <ButtonTag
                variant={variant}
                minWidth={buttonMinWidth}
                margin="4px 8px 4px 0px"
                key={name}
                name={name}
                isSelected={isSelected}
                text={displayTextFunction ? displayTextFunction(name) : name}
                textDescription={
                  hoverDisplayTextFunction ? hoverDisplayTextFunction(name) : ""
                }
                onClick={onClickTag}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default ButtonTagGroup;
