import React, { useMemo } from "react";
import { Bar } from "react-chartjs-2";
import colors from "../../assets/colors";
import { ClientNutritionTarget, MacronutrientType } from "../../types/gqlTypes";
import { useRerenderOnResize } from "../../utils/customHooks";
import SummaryTable from "./SummaryTable";

export interface ValueType {
  y: number[];
  excess: number[];
  maxValue: number;
  sum: number;
  quantity: number;
}

export interface ChartDataType {
  [MacronutrientType.Calories]: ValueType;
  [MacronutrientType.Fat]: ValueType;
  [MacronutrientType.Carbs]: ValueType;
  [MacronutrientType.Protein]: ValueType;
  MACROS: {
    [MacronutrientType.Fat]: number[];
    [MacronutrientType.Carbs]: number[];
    [MacronutrientType.Protein]: number[];
  };
}

interface Props {
  clientNutritionTarget: ClientNutritionTarget;
  labels: string[];
  chartType: ChartTypes;
  data: ChartDataType;
}

export enum ChartTypes {
  CALORIES = "CALORIES",
  FAT = "FAT",
  CARBS = "CARBS",
  PROTEIN = "PROTEIN",
  MACROS = "MACROS",
}

export const chartColors = {
  [MacronutrientType.Calories]: "#615375",
  [MacronutrientType.Fat]: "#3446FB",
  [MacronutrientType.Carbs]: "#1FC866",
  [MacronutrientType.Protein]: "#FFCC32",
};

const NutritionChart = (props: Props) => {
  const { clientNutritionTarget, labels, data, chartType } = props;
  useRerenderOnResize();

  const calculateMaxTick = (stepSize, chartType) => {
    const reminder = data?.[chartType]?.maxValue % stepSize;
    return Math.round(data?.[chartType]?.maxValue + stepSize - reminder);
  };

  const chartData = useMemo(() => {
    const datasets = [];
    let labelString =
      chartType.charAt(0).toUpperCase() + chartType.slice(1).toLowerCase();
    const title =
      chartType.charAt(0).toUpperCase() + chartType.slice(1).toLowerCase();
    let stepSize = chartType === ChartTypes.CALORIES ? 700 : 100;
    let maxTick =
      chartType !== ChartTypes.MACROS && calculateMaxTick(stepSize, chartType);
    const label = chartType === ChartTypes.CALORIES ? "cals" : "g";

    if (chartType === ChartTypes.MACROS) {
      labelString = "Percentage %";
      maxTick = 100;
      stepSize = 25;
      datasets.push({
        label: `${chartType}CarbsBars`,
        maxBarThickness: 32,
        data: data?.[chartType]?.[MacronutrientType.Carbs],
        backgroundColor: chartColors[ChartTypes.CARBS],
      });
      datasets.push({
        label: `${chartType}FatBars`,
        maxBarThickness: 32,
        data: data?.[chartType]?.[MacronutrientType.Fat],
        backgroundColor: chartColors[ChartTypes.FAT],
      });
      datasets.push({
        label: `${chartType}ProteinBars`,
        maxBarThickness: 32,
        data: data?.[chartType]?.[MacronutrientType.Protein],
        backgroundColor: chartColors[ChartTypes.PROTEIN],
      });
    } else {
      labelString = `${labelString}${
        chartType === ChartTypes.CALORIES ? "" : ", g"
      }`;
      datasets.push({
        label: `${chartType}Bars`,
        maxBarThickness: 32,
        data: data?.[chartType]?.y,
        backgroundColor: chartColors[chartType],
      });
      datasets.push({
        label: `${chartType}ExcessBars`,
        maxBarThickness: 32,
        data: data?.[chartType]?.excess,
        backgroundColor: "#F73843",
      });
    }
    const options = {
      chartArea: {
        backgroundColor: "#E8EAEE",
        borderRadius: "8px",
      },
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            stacked: true,
            scaleLabel: {
              display: true,
              labelString,
              fontFamily: "Circular-Medium, serif",
              fontColor: "#AEB1BF",
            },
            gridLines: {
              tickMarkLength: 0,
              display: false,
            },
            ticks: {
              padding: 20,
              beginAtZero: false,
              max: maxTick,
              stepSize,
            },
          },
        ],
        xAxes: [
          {
            stacked: true,
            ticks: {
              minRotation: 0,
              maxRotation: 0,
              callback: (tick, ind) => {
                let divider = 1;
                if (labels?.length > 30) {
                  divider = 4;
                } else if (labels?.length > 20) {
                  divider = 3;
                }
                return ind % divider ? "" : tick;
              },
            },
            scaleLabel: {
              display: true,
              labelString: "Date",
              fontFamily: "Circular-Medium, serif",
              fontColor: "#AEB1BF",
            },
            gridLines: {
              display: false,
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          labelColor: () => ({}),
          labelTextColor: () => colors.caliber_secondary,
          label: (item, data) => {
            if (chartType === ChartTypes.MACROS) {
              const protein = data?.datasets?.[2]?.data?.[item?.index] || 0;
              const fat = data?.datasets?.[1]?.data?.[item?.index] || 0;
              const carbs = data?.datasets?.[0]?.data?.[item?.index] || 0;
              return `Protein ${protein}%, Fat ${fat}%, Carbs ${carbs}%`;
            }
            const y = data?.datasets?.[0]?.data?.[item?.index] || 0;
            const excess = data?.datasets?.[1]?.data?.[item?.index] || 0;
            return `${Math.round(y + excess)} ${label}`;
          },
          displayColors: () => false,
          title: () => null,
        },
        displayColors: false,
        yAlign: "left",
        bodyFontSize: 20,
        backgroundColor: colors.caliber_white,
      },
    };
    return { datasets, options, title };
  }, [labels, data, chartType]);

  const beforeDraw = (chart) => {
    if (
      chart?.config?.options?.chartArea &&
      chart?.config?.options?.chartArea?.backgroundColor
    ) {
      const { ctx } = chart.chart;
      const { chartArea } = chart;

      ctx.save();
      ctx.fillStyle = chart.config.options.chartArea.backgroundColor;
      ctx.fillStyle = chart.config.options.chartArea.borderRadius;
      ctx.fillRect(
        chartArea.left,
        chartArea.top,
        chartArea.right - chartArea.left,
        chartArea.bottom - chartArea.top
      );
      ctx.restore();
    }
  };
  const chartHeight = 250;
  const chartWidth =
    window.innerWidth > 1100
      ? window.innerWidth - 300
      : Math.max(window.innerWidth - 150, 400);

  return (
    <div
      className="d-flex flex-column"
      style={{
        flex: 1,
      }}
    >
      <div
        className="medium-bold"
        style={{
          fontSize: "29px",
          lineHeight: "14px",
          marginTop: 20,
          marginBottom: 25,
        }}
      >
        {chartData.title}
      </div>
      <div
        style={{
          maxWidth: chartWidth,
          maxHeight: chartHeight,
          width: chartWidth,
          height: chartHeight,
        }}
      >
        <Bar
          options={chartData.options}
          data={{
            labels,
            datasets: chartData.datasets,
          }}
          plugins={[{ beforeDraw }]}
        />
      </div>
      <SummaryTable
        key={chartType}
        chartType={chartType}
        clientNutritionTarget={clientNutritionTarget}
      />
    </div>
  );
};

export default NutritionChart;
