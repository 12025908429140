import React, { useMemo } from "react";
import colors from "../../../assets/colors";
import {
  StrengthScore,
  StrengthScoreDashboard,
  StrengthScoreMuscleGroupType,
} from "../../../types/gqlTypes";
import DeltaGraphs from "./DeltaGraphs";
import DetailsPanelHeader from "./DetailsPanelHeader";
import StrengthScoreDetailsHeader from "./StrengthScoreDetailsHeader";
import StrengthScoreExplanation from "./StrengthScoreExplanation";
import ViewMore from "./ViewMore";

interface Props {
  selectMuscle: (muscle: StrengthScoreMuscleGroupType) => void;
  strengthScoreDashboardChanges: StrengthScoreDashboard[];
}

export const muscleTitles = {
  [StrengthScoreMuscleGroupType.Legs]: "Legs",
  [StrengthScoreMuscleGroupType.Chest]: "Chest",
  [StrengthScoreMuscleGroupType.Arms]: "Arms",
  [StrengthScoreMuscleGroupType.Weight]: "Weight",
  [StrengthScoreMuscleGroupType.Back]: "Back",
  [StrengthScoreMuscleGroupType.Shoulders]: "Shoulders",
};

const DetailsPanel = (props: Props) => {
  const { selectMuscle, strengthScoreDashboardChanges } = props;

  const muscleGroupsNames = [
    StrengthScoreMuscleGroupType.Legs,
    StrengthScoreMuscleGroupType.Chest,
    StrengthScoreMuscleGroupType.Back,
    StrengthScoreMuscleGroupType.Shoulders,
    StrengthScoreMuscleGroupType.Arms,
    StrengthScoreMuscleGroupType.Weight,
  ];

  const [thisWeekMuscleGroups, lastWeekMuscleGroups] = useMemo(() => {
    const thisWeekMuscleGroups: { [key: string]: StrengthScore } = {};
    const lastWeekMuscleGroups: { [key: string]: StrengthScore } = {};
    strengthScoreDashboardChanges?.[0]?.muscleGroupScores?.forEach(
      (muscleGroup) => {
        thisWeekMuscleGroups[muscleGroup.type] = muscleGroup;
      }
    );
    strengthScoreDashboardChanges?.[1]?.muscleGroupScores?.forEach(
      (muscleGroup) => {
        lastWeekMuscleGroups[muscleGroup.type] = muscleGroup;
      }
    );
    return [thisWeekMuscleGroups, lastWeekMuscleGroups];
  }, [strengthScoreDashboardChanges]);

  return (
    <div
      className="d-flex flex-column"
      style={{
        width: 380,
        marginBottom: 70,
      }}
    >
      <StrengthScoreDetailsHeader />

      <div
        className="d-flex flex-column"
        style={{
          width: 380,
          height: 781,
          overflowY: "auto",
        }}
      >
        {muscleGroupsNames.map((muscle, index) => {
          return (
            thisWeekMuscleGroups?.[muscle] && (
              <div
                className="d-flex flex-column"
                style={{
                  marginTop: index === 0 ? 0 : 24,
                  borderRadius: 8,
                  width: 366,
                  padding: "24px 16px",
                  backgroundColor: colors.caliber_white,
                  border: "1px solid #f5f7f9",
                }}
              >
                <DetailsPanelHeader
                  muscleGroup={thisWeekMuscleGroups?.[muscle]}
                />

                <DeltaGraphs
                  strengthScoreType={muscle}
                  lastWeekDelta={
                    lastWeekMuscleGroups?.[muscle]?.strengthScoreDelta
                  }
                  thisWeekDelta={
                    thisWeekMuscleGroups?.[muscle]?.strengthScoreDelta
                  }
                />

                <StrengthScoreExplanation
                  muscleGroup={thisWeekMuscleGroups?.[muscle]}
                />

                <ViewMore onClick={() => selectMuscle(muscle)} />
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};

export default DetailsPanel;
