import React from "react";
import SimpleButton from "../../SimpleButton";

interface Props {
  onReset: () => void;
}

const ResetButton = (props: Props) => {
  const { onReset } = props;

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{
        marginTop: 60,
        marginBottom: 95,
      }}
    >
      <div className="d-flex justify-content-center">
        <SimpleButton
          className="d-flex align-items-center justify-content-center"
          onClick={onReset}
          style={{
            fontSize: "14px",
            lineHeight: "16px",
            color: "#F73843",
            width: 194,
            height: 28,
            borderRadius: 16,
            backgroundColor: "#F6EAEB",
            marginBottom: 17,
          }}
        >
          Reset to standard view
        </SimpleButton>
      </div>

      <div
        className="d-flex align-items-center justify-content-center medium-bold"
        style={{
          fontSize: "10px",
          lineHeight: "14px",
          color: "#AEB1BF",
        }}
      >
        This will restore the original view and you will lose any
      </div>
      <div
        className="d-flex align-items-center justify-content-center medium-bold"
        style={{
          fontSize: "10px",
          lineHeight: "14px",
          color: "#AEB1BF",
        }}
      >
        {"changes you've made to your table."}
      </div>
    </div>
  );
};

export default ResetButton;
