import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import { StoreState } from "../../../redux/reducers";
import Modal from "../../Modal";
import EditWorkout from "./EditWorkout";
import ExerciseCard from "./ExerciseCard";
import Header from "./Header";
import Footer from "./Footer";
import {
  ClientGymStep,
  ExerciseSetInput,
  ExerciseTargetBaseType,
} from "../../../types/gqlTypes";
import { editExerciseSets } from "../../../redux/actions/ClientWorkoutHistory";

interface OwnProps {
  onClose: () => void;
  clientGymStep: ClientGymStep;
  date: string;
  setNumber: number;
}
interface Props extends OwnProps {
  editExerciseSets: (
    clientGymStepId: string,
    exerciseSets: ExerciseSetInput[]
  ) => void;
}

const EditClientExerciseSetModal = (props: Props) => {
  const { onClose, editExerciseSets, clientGymStep, date, setNumber } = props;
  const isTimeBased =
    clientGymStep?.exercise?.targetBase === ExerciseTargetBaseType.Time;
  const exerciseSet = useMemo(() => {
    return clientGymStep?.exerciseSets?.[setNumber];
  }, [clientGymStep, setNumber]);

  const [time, setTime] = useState(null);
  const [weight, setWeight] = useState(null);
  const [reps, setReps] = useState(null);

  const canBeSaved = useMemo(() => {
    if (isTimeBased) {
      return (
        time !== exerciseSet?.actualTime || weight !== exerciseSet?.actualWeight
      );
    }
    return (
      reps !== exerciseSet?.actualReps || weight !== exerciseSet?.actualWeight
    );
  }, [time, weight, reps, isTimeBased, exerciseSet]);

  return (
    <Modal onClose={onClose} closeOnDimmerClick>
      <div
        className="d-flex flex-column"
        style={{
          width: 468,
          height: 456,
          padding: "27px 32px",
        }}
      >
        <Header onClose={onClose} />
        <ExerciseCard
          exercise={clientGymStep?.exercise}
          date={date}
          setNumber={setNumber}
        />
        <EditWorkout
          exerciseSet={exerciseSet}
          isTimeBased={isTimeBased}
          hideWeight={!!clientGymStep?.hideWeightForExerciseSets}
          onRepsChange={(value) => {
            setReps(value);
          }}
          onTimeChange={(value) => {
            setTime(value);
          }}
          onWeightChange={(value) => {
            setWeight(value);
          }}
        />
        <Footer
          onSave={() => {
            const exerciseSetInputs = clientGymStep?.exerciseSets.map(
              (set, index) => {
                if (index === setNumber) {
                  return {
                    setId: index,
                    sortOrder: index,
                    actualReps: reps,
                    actualTime: time,
                    actualWeight: weight,
                  };
                }
                return {
                  setId: index,
                  sortOrder: index,
                  actualReps: clientGymStep?.exerciseSets?.[index]?.actualReps,
                  actualTime: clientGymStep?.exerciseSets?.[index]?.actualTime,
                  actualWeight:
                    clientGymStep?.exerciseSets?.[index]?.actualWeight,
                };
              }
            );
            editExerciseSets(clientGymStep?.id, exerciseSetInputs);
            onClose();
          }}
          disabled={!canBeSaved}
        />
      </div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  editExerciseSets: (
    clientGymStepId: string,
    exerciseSets: ExerciseSetInput[]
  ) => {
    dispatch(editExerciseSets(clientGymStepId, exerciseSets));
  },
});

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  onClose: ownProps.onClose,
  clientGymStep: ownProps.clientGymStep,
  date: ownProps.date,
  setNumber: ownProps.setNumber,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditClientExerciseSetModal);
