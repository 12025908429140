import React from "react";

const ScaleIcon = () => {
  return (
    <svg
      width="240"
      height="189"
      viewBox="0 0 240 189"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.06856 42.1715C5.47331 49.5853 21.858 46.455 32.0545 55.0903C48.5107 69.0483 20.8575 90.0617 28.4034 110.14C39.0807 138.548 121.111 167.311 182.94 143.998C223.6 128.665 245.325 94.6514 238.88 66.0156C225.926 8.45094 98.834 -13.8199 39.032 8.61707C15.9005 17.2915 -5.02208 31.9237 1.06856 42.1715Z"
        fill="#F8F8F9"
      />
      <path
        d="M93.1494 118.543L174.851 118.543C182.113 118.543 188 112.64 188 105.357V23.4285C188 16.1461 182.113 10.2425 174.851 10.2425L93.1494 10.2425C85.8872 10.2425 80 16.1461 80 23.4285L80 105.357C80 112.64 85.8872 118.543 93.1494 118.543Z"
        fill="#272E5F"
      />
      <path
        d="M155.537 10.2424H112.464V37.9387H155.537V10.2424Z"
        fill="#C9CACB"
      />
      <path
        d="M163.502 43.7891L104.498 43.7891V10.2397H105.673V42.6113L162.327 42.6113V10.2397H163.502V43.7891Z"
        fill="#7D829F"
      />
      <path
        d="M155.537 25.5689C149.184 21.2408 141.68 18.9268 134 18.9268C126.32 18.9268 118.817 21.2408 112.464 25.5689V37.9358L155.537 37.9358V25.5689Z"
        fill="#F5F7F9"
      />
      <path
        d="M133.999 28.4329C132.617 28.4329 131.498 29.5558 131.498 30.9409C131.498 32.3261 132.617 33.449 133.999 33.449C135.38 33.449 136.5 32.3261 136.5 30.9409C136.5 29.5558 135.38 28.4329 133.999 28.4329Z"
        fill="#EE2624"
      />
      <path
        d="M132.621 30.2983L143.937 20.3378L134.004 31.6824L132.621 30.2983Z"
        fill="#EE2624"
      />
      <path
        d="M166.563 87.1524C153.173 87.0089 146.972 106.06 136.914 103.749C127.375 101.57 129.804 83.7385 116.875 66.8368C107.822 55.005 92.5293 45.3135 80.003 43.5498L80.003 105.357C80.003 108.855 81.3884 112.209 83.8543 114.681C86.3203 117.154 89.6649 118.543 93.1523 118.543L174.851 118.543C178.338 118.543 181.683 117.154 184.149 114.681C186.615 112.209 188 108.855 188 105.357V101.125C182.184 93.2028 174.001 87.2271 166.563 87.1524Z"
        fill="#7D829F"
      />
    </svg>
  );
};

export default ScaleIcon;
