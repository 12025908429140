import React, { useEffect } from "react";
import { Col } from "react-bootstrap";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";
import { connect } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import colors from "../../assets/colors";
import ErrorSuccessDialog from "../../components/ErrorSuccessDialog";
import Header, { HEADER_HEIGHT } from "../../components/Header";
import SideNavItem, { NAV_COLLAPSED_WIDTH } from "../../components/SideNavItem";
import { RoutesInfo } from "../../navigation";
import { logoutUser } from "../../redux/actions/Auth";
import { MasqueradeState } from "../../redux/actions/Masquerade/types";
import {
  connectToSendbird,
  registerChannelHandler,
} from "../../redux/actions/Messages";
import { resetUserData } from "../../redux/actions/User";
import { UserState } from "../../redux/actions/User/types";
import { StoreState } from "../../redux/reducers";
import { UserType } from "../../types/gqlTypes";
import TrainerClientHomeTab from "../TrainerClientHomeTab";
import TrainerProfileScreen from "../TrainerProfileScreen";
import TrainerSettingsScreen from "../TrainerSettingsScreen";

export interface OwnProps {
  routeInfos: Array<RoutesInfo>;
}

interface Props extends OwnProps {
  masqueradeState: MasqueradeState;
  unreadMessageCount: number;
  userState: UserState;
  logout: () => void;
  resetUserData: () => void;
  connectToChat: () => void;
  registerChannel: (trainerId: string) => void;
}

const BaseHomeScreen = (props: Props) => {
  const {
    masqueradeState,
    userState,
    unreadMessageCount,
    routeInfos,
    logout,
    resetUserData,
    connectToChat,
    registerChannel,
  } = props;

  const collapseMenu = useMediaQuery({ maxWidth: 1100 });

  const trainerId =
    masqueradeState?.masqueradeTrainer?.id || userState?.user?.id;
  const isMasquerade = !!masqueradeState?.masqueradeTrainer;
  const isTrainer = userState?.user?.type === UserType.Trainer;
  const isAdminOrManager =
    userState?.user?.type === UserType.Admin ||
    userState?.user?.type === UserType.Manager;

  useEffect(() => {
    if (
      (isAdminOrManager && isMasquerade && trainerId) ||
      (trainerId && isTrainer)
    ) {
      connectToChat();
      registerChannel(trainerId);
    }
  }, [trainerId]);

  useEffect(() => {
    return resetUserData;
  }, []);

  const routesToIterate = routeInfos;

  return (
    <Router>
      <ErrorSuccessDialog />
      <Header userInfo={userState.user} logout={logout} />
      <DndProvider backend={Backend}>
        <div
          className="d-flex flex-fill"
          style={{
            paddingTop: HEADER_HEIGHT,
            paddingLeft: NAV_COLLAPSED_WIDTH,
          }}
        >
          <div
            className="fixed-left-navbar"
            style={{
              position: "fixed",
              left: 0,
              height: "100%",
              zIndex: 1,
              backgroundColor: "#ffffff",
            }}
          >
            <span
              style={{
                backgroundColor: colors.wire_line_grey,
                width: 2,
                position: "absolute",
                height: "100%",
                right: 0,
              }}
            />
            {routesToIterate.map((route) => {
              const hasMultiplePaths = Array.isArray(route.path);
              const toPath = hasMultiplePaths
                ? route.path[0]
                : (route.path as string);
              return (
                <SideNavItem
                  unreadMessageCount={unreadMessageCount}
                  key={toPath}
                  to={toPath}
                  paths={route.path}
                  activeIcon={route.activeIcon}
                  inactiveIcon={route.inactiveIcon}
                  title={route.title}
                  iconOnly={collapseMenu}
                />
              );
            })}
          </div>
          <Col
            className="d-flex flex-fill"
            style={{
              backgroundColor: colors.caliber_gray_bg,
            }}
          >
            <Switch>
              <Route path="/clients/:id" component={TrainerClientHomeTab} />

              {(isTrainer || isMasquerade) && (
                <Route path="/settings" component={TrainerSettingsScreen} />
              )}

              {(isTrainer || isMasquerade) && (
                <Route path="/profile" component={TrainerProfileScreen} />
              )}

              {routesToIterate.map((route) => (
                <Route
                  key={route.path}
                  exact={route.exact}
                  path={route.path}
                  component={route.main}
                />
              ))}
            </Switch>
          </Col>
        </div>
      </DndProvider>
    </Router>
  );
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    dispatch(logoutUser());
  },
  resetUserData: () => {
    dispatch(resetUserData());
  },
  connectToChat: () => {
    dispatch(connectToSendbird());
  },
  registerChannel: (trainerId: string) => {
    dispatch(registerChannelHandler(trainerId));
  },
});

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  masqueradeState: state.masqueradeState,
  userState: state.authenticatedUserState,
  routeInfos: ownProps.routeInfos,
  unreadMessageCount: state.unreadMessageCountState.unreadMessageCount,
});

export default connect(mapStateToProps, mapDispatchToProps)(BaseHomeScreen);
