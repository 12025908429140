import {
  UPDATE_UNREAD_MESSAGE_COUNT,
  UnreadMessageCountAction,
  UnreadMessageCountState,
} from "../../actions/UnreadMessageCount/types";

const initialState: UnreadMessageCountState = {
  unreadMessageCount: 0,
};

const unreadMessageCountState = (
  state = initialState,
  action: UnreadMessageCountAction
) => {
  switch (action.type) {
    case UPDATE_UNREAD_MESSAGE_COUNT:
      return {
        ...state,
        unreadMessageCount: action.unreadMessageCount,
      };
    default:
      return state;
  }
};

export default unreadMessageCountState;
