import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import colors from "../../assets/colors";
import { updateClientEmail } from "../../redux/actions/ClientDetail";
import { getIsAdmin, StoreState } from "../../redux/reducers";
import EditIcon from "../Icons/EditIcon";
import SimpleButton from "../SimpleButton";

interface OwnProps {
  email: string;
}
interface Props extends OwnProps {
  updateClientEmail: (clientId: string, email: string) => void;
  isAdmin: boolean;
}

const ClientEmail = (props: Props) => {
  const { email, updateClientEmail, isAdmin } = props;
  const { id: clientId } = useParams();
  const title = "Email";

  const inputRef = useRef(null);
  const [editing, setEditing] = useState(null);

  const onSave = () => {
    setEditing(null);
    const emailWithoutWhitespace = editing.replace(/\s/g, "");
    updateClientEmail(clientId, emailWithoutWhitespace);
  };

  const validateEmail = (email) => {
    // true if pass
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const editEmail = (
    <input
      ref={inputRef}
      value={editing}
      onChange={(event) => {
        setEditing(event.target.value);
      }}
      onKeyDown={(event) => {
        const { key } = event;
        if (key === "Enter" && validateEmail(editing)) {
          onSave();
        }
        if (key === "Escape") {
          setEditing(null);
        }
      }}
      onBlur={() => {
        if (validateEmail(editing)) {
          onSave();
        }
      }}
      style={{
        backgroundColor: validateEmail(editing) ? "#F3F3F5" : "#F6EAEB",
        color: colors.caliber_secondary,
        borderRadius: 4,
        borderColor: colors.caliber_white,
        boxShadow: "none",
        border: "none",
        borderWidth: 0,
        width: 200,
        height: 20,
        fontSize: "12px",
      }}
    />
  );

  const emailDiv = (
    <div className="d-flex">
      {isAdmin && (
        <SimpleButton
          onClick={() => {
            setEditing(email);
            setTimeout(() => {
              inputRef?.current?.focus();
            }, 0);
          }}
          className="d-flex align-items-center justify-content-center"
          style={{
            marginRight: 6,
          }}
        >
          <EditIcon />
        </SimpleButton>
      )}
      <div
        className="medium-bold"
        style={{
          padding: "1px 10px",
          borderRadius: 4,
          maxWidth: 200,
          wordBreak: "break-all",
          fontSize: "12px",
          lineHeight: "16px",
          color: colors.caliber_secondary,
          backgroundColor: colors.caliber_light_gray,
        }}
      >
        {email}
      </div>
    </div>
  );

  return (
    <div
      key={title}
      style={{ margin: "12px 0px" }}
      className="d-flex justify-content-between align-items-center"
    >
      <div
        className="medium-bold"
        style={{
          fontSize: "12px",
          lineHeight: "16px",
          color: colors.caliber_secondary,
        }}
      >
        {title}
      </div>

      {editing ? editEmail : emailDiv}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateClientEmail: (clientId: string, email: string) => {
    dispatch(updateClientEmail(clientId, email));
  },
});

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  email: ownProps.email,
  isAdmin: getIsAdmin(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientEmail);
