import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import colors from "../../../assets/colors";
import {
  createClientFromWeb,
  updateClientFromWeb,
} from "../../../redux/actions/AdminClients";
import {
  closeNewClientInClientsTab,
  fetchClientProfileForClientsTab,
  searchClientsTab,
} from "../../../redux/actions/ClientsTab";
import { getIsAdmin, StoreState } from "../../../redux/reducers";
import {
  Client,
  ClientPackageType,
  FileUploadType,
  GenderType,
  MutationCreateClientFromWebArgs,
  MutationUpdateClientFromWebArgs,
  ProGroup,
  Trainer,
  User,
  UserStatusType,
} from "../../../types/gqlTypes";
import { HEADER_HEIGHT } from "../../Header";
import Profile from "./Profile";
import apolloClient from "../../../api/apolloClient";
import { SEARCH_TRAINERS } from "../../../api/gql/users/trainers";
import { getAllProGroups } from "../../../redux/actions/ProGroups";
import { getAppConfig } from "../../../config";
import DefaultUserImage from "../../Icons/DefaultUserImage";
import DeleteClientButton from "./DeleteClientButton";

interface Props {
  isNewClientDetailsOpen: boolean;
  isEditClientDetailsOpen: boolean;
  clientIdToEdit: string;
  fetchedClient?: Client;
  clientPackageType?: ClientPackageType;
  clientWeight?: number;
  isEditClientOn?: boolean;
  isLoading?: boolean;
  isAdmin: boolean;
  onClose?: () => void;
  fetchUserData: (id: string) => void;
  createClientProfile: (args: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    coach: Trainer;
    packageType: ClientPackageType;
    imgUrlId: string;
  }) => void;
  createClientFromWeb: (args: MutationCreateClientFromWebArgs) => void;
  updateClientFromWeb: (args: MutationUpdateClientFromWebArgs) => void;
  turnOnEditMode: () => void;
  authToken: string;
  proGroups?: ProGroup[];
  getAllProGroups: () => void;
}

export interface IFormState {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  coach: User;
  packageType: ClientPackageType;
  assignedProGroupId: string;
  imgUrlId: string;
  imgUrl: string;
  heightFt: number;
  heightIn: number;
  weight: number;
  gender: GenderType;
  status: UserStatusType;
  profilePicFile: string;
}

const ClientDetails = (props: Props) => {
  const {
    isEditClientDetailsOpen,
    isNewClientDetailsOpen,
    clientIdToEdit,
    fetchedClient,
    clientPackageType,
    clientWeight,
    isAdmin,
    isLoading,
    onClose,
    fetchUserData,
    createClientFromWeb,
    updateClientFromWeb,
    authToken,
    proGroups,
    getAllProGroups,
  } = props;

  const initialFormState: IFormState = {
    firstName: null,
    lastName: null,
    email: null,
    password: null,
    coach: null,
    packageType: null,
    assignedProGroupId: null,
    imgUrlId: null,
    imgUrl: null,
    heightFt: null,
    heightIn: null,
    weight: null,
    gender: null,
    status: null,
    profilePicFile: null,
  };

  const [trainerOptions, setTrainerOptions] = useState([]);

  // Yup validation schema
  const schema = yup
    .object({
      firstName: yup.string().nullable().required("First name is required."),
      email: yup
        .string()
        .nullable()
        .required("Email is required.")
        .email("Email address is invalid."),
      password: yup
        .string()
        .nullable()
        .test("Password required", "Password is required.", (value) => {
          return !(isNewClientDetailsOpen && !value);
        }),
      packageType: yup.mixed().required("Package type is required."),
      assignedProGroupId: yup
        .mixed()
        .test(
          "Pro Group required",
          "Pro Group is required for the Pro package type.",
          (value) => {
            if (inputPackageType === ClientPackageType.Pro && !value) {
              return false;
            }
            return true;
          }
        ),
      heightFt: yup.number().nullable().min(1).max(8),
      heightIn: yup.number().nullable().min(0).max(11),
      profilePicFile: yup
        .mixed()
        .test("File type", "Only image files are allowed.", (files) => {
          if (files?.length === 0 || files === null) {
            return true;
          }
          return files[0].type.includes("image/");
        })
        .test("File size", "The file is too large.", (files) => {
          if (files?.length === 0 || files === null) {
            return true;
          }
          return files[0].size < 500 * 1024;
        }),
    })
    .required();

  // Init react-hook-form
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useForm<IFormState>({
    resolver: yupResolver(schema),
    defaultValues: initialFormState,
  });

  // Handle submit
  const onSubmit = (data) => {
    const params: any = {
      assignedTrainerId: data.coach?.id,
      assignedProGroupId: data.assignedProGroupId,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      gender: data.gender,
      height: data.heightFt * 12 + data.heightIn,
      packageType: data.packageType,
      profileIconMediaUrlId: data.imgUrlId,
    };
    if (isNewClientDetailsOpen) {
      params.password = data.password;
      params.weight = data.weight;
      createClientFromWeb(params);
      return;
    }
    if (isEditClientDetailsOpen && clientIdToEdit) {
      params.clientId = clientIdToEdit;
      params.status = data.status;
      updateClientFromWeb(params);
    }
  };

  // Watch packageType to change form rendering based on type
  const inputPackageType = watch("packageType");
  const profilePicUrl = watch("imgUrl");

  // Reset form on any changes to edit/create state
  useEffect(() => {
    setTrainerOptions([]);
    reset(initialFormState);
    if (isEditClientDetailsOpen && clientIdToEdit) {
      fetchUserData(clientIdToEdit);
    }
  }, [isEditClientDetailsOpen, isNewClientDetailsOpen, clientIdToEdit]);

  // Update form state based on fetched client data
  useEffect(() => {
    if (fetchedClient) {
      const newFormState = {
        firstName: fetchedClient.firstName,
        lastName: fetchedClient.lastName,
        email: fetchedClient.email,
        password: null,
        coach: fetchedClient.assignedTrainer,
        packageType: clientPackageType,
        imgUrl: fetchedClient.profileIconMediaUrl?.url,
        imgUrlId: fetchedClient.profileIconMediaUrl?.id,
        heightFt: fetchedClient.height
          ? Math.floor(fetchedClient.height / 12)
          : null,
        heightIn: fetchedClient.height
          ? Math.round(
              fetchedClient.height - Math.floor(fetchedClient.height / 12) * 12
            )
          : null,
        weight: clientWeight,
        gender: fetchedClient.gender,
        status: fetchedClient.status,
        assignedProGroupId: fetchedClient.proGroups[0]?.id,
        profilePicFile: null,
      };
      // To populate defaultOptions for coach select
      if (newFormState.coach) {
        setTrainerOptions([
          {
            value: newFormState.coach,
            label: `${newFormState.coach.firstName} ${newFormState.coach.lastName}`,
          },
        ]);
      }
      reset(newFormState);
    }
  }, [fetchedClient]);

  // Load pro groups into store if they haven't been loaded yet
  useEffect(() => {
    if (!proGroups.length && isAdmin) {
      getAllProGroups();
    }
  }, []);

  // Generate options for enum types for select dropdowns
  const generateOptionsFromType = (enumType: any) => {
    return Object.values(enumType).map((element) => ({
      value: element,
      label: (element as string)
        .split("_")
        .map((s) => s[0].toUpperCase() + s.substring(1).toLowerCase())
        .join(" "),
    }));
  };
  const userStatusTypeOptions = generateOptionsFromType(UserStatusType);
  const genderTypeOptions = generateOptionsFromType(GenderType);
  const clientPackageTypeOptions = generateOptionsFromType(ClientPackageType);

  // Populate Pro Group options
  const proGroupOptions = proGroups.map((proGroup) => ({
    value: proGroup.id,
    label: proGroup.name,
  }));

  // Populate trainer options on search
  const getTrainerOptions = async (inputValue: string) => {
    const client = await apolloClient(authToken);
    const result = (
      await client.query({
        query: SEARCH_TRAINERS,
        variables: {
          page: 0,
          searchCriteria: inputValue,
          statusCriteria: UserStatusType.Active,
        },
      })
    ).data.searchTrainers.content;
    return result.map((trainer) => ({
      value: trainer as Trainer,
      label: `${trainer.firstName} ${trainer.lastName}`,
    }));
  };

  const handleProfilePicChange = async (event) => {
    setValue("profilePicFile", event.target.files);
    const file = event.target.files[0];

    // Manually trigger validation on field
    const imageValid = await trigger("profilePicFile");

    // Upload image
    if (imageValid && file) {
      try {
        const apiUrl = `${getAppConfig().apiUrl}/upload`;

        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", FileUploadType.ProfilePic);

        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          body: formData,
        });
        const json = await response.json();
        setValue("imgUrlId", json.mediaUrl.id);
        setValue("imgUrl", json.mediaUrl.url);
      } catch (error) {
        console.log("Failed to upload.", error);
      }
    }
  };

  if (!isEditClientDetailsOpen && !isNewClientDetailsOpen) {
    return null;
  }

  return (
    <div
      className="d-flex flex-column align-items-center pb-5"
      style={{
        borderLeft: "1px solid #DADCE3",
        backgroundColor: colors.caliber_white,
        height: `calc(100vh - ${HEADER_HEIGHT}px)`,
        overflowY: "scroll",
      }}
    >
      <i
        aria-hidden="true"
        className="bi bi-x-circle-fill"
        style={{
          alignSelf: "flex-end",
          marginTop: 20,
          marginRight: 25,
          fontSize: 21,
          color: "#777777",
          cursor: "pointer",
        }}
        onClick={onClose}
      />

      {isEditClientDetailsOpen && fetchedClient && (
        <Profile
          firstName={fetchedClient.firstName}
          lastName={fetchedClient?.lastName}
          imageUrl={fetchedClient.profileIconMediaUrl?.url}
          packageType={clientPackageType}
        />
      )}

      <Container className="mt-4 px-4">
        {isNewClientDetailsOpen && <h2 className="pb-4">Add New Client</h2>}
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3">
            <Form.Label>First name</Form.Label>
            <Controller
              name="firstName"
              control={control}
              render={({ field: { value, onChange, ref } }) => (
                <Form.Control
                  type="text"
                  value={value}
                  onChange={onChange}
                  ref={ref}
                  isInvalid={!!errors.firstName}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.firstName?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Last name</Form.Label>
            <Controller
              name="lastName"
              control={control}
              render={({ field: { value, onChange, ref } }) => (
                <Form.Control
                  type="text"
                  value={value}
                  onChange={onChange}
                  ref={ref}
                />
              )}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Controller
              name="email"
              control={control}
              render={({ field: { value, onChange, ref } }) => (
                <Form.Control
                  type="email"
                  value={value}
                  onChange={onChange}
                  ref={ref}
                  isInvalid={!!errors.email}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email?.message}
            </Form.Control.Feedback>
          </Form.Group>

          {isNewClientDetailsOpen && (
            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Controller
                name="password"
                control={control}
                render={({ field: { value, onChange, ref } }) => (
                  <Form.Control
                    type="password"
                    value={value}
                    onChange={onChange}
                    ref={ref}
                    isInvalid={!!errors.password}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors.password?.message}
              </Form.Control.Feedback>
            </Form.Group>
          )}

          {isEditClientDetailsOpen && (
            <Form.Group className="mb-3">
              <Form.Label>Status</Form.Label>
              <Controller
                name="status"
                control={control}
                render={({ field: { value, onChange, ref } }) => (
                  <Select
                    options={userStatusTypeOptions}
                    value={userStatusTypeOptions.find(
                      (option) => option.value === value
                    )}
                    onChange={(val) => onChange(val.value)}
                    ref={ref}
                    isSearchable={false}
                    className="clbr-select-container"
                    classNamePrefix="clbr-select"
                  />
                )}
              />
            </Form.Group>
          )}

          <Form.Group className="mb-3">
            <Form.Label>Gender</Form.Label>
            <Controller
              name="gender"
              control={control}
              render={({ field: { value, onChange, ref } }) => (
                <Select
                  options={genderTypeOptions}
                  value={genderTypeOptions.find(
                    (option) => option.value === value
                  )}
                  onChange={(val) => onChange(val.value)}
                  ref={ref}
                  isSearchable={false}
                  className="clbr-select-container"
                  classNamePrefix="clbr-select"
                />
              )}
            />
          </Form.Group>

          {isNewClientDetailsOpen && (
            <Form.Group>
              <Form.Label>Weight</Form.Label>
              <Controller
                name="weight"
                control={control}
                render={({ field: { value, onChange, ref } }) => (
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="number"
                      value={value ?? ""}
                      onChange={onChange}
                      ref={ref}
                      isInvalid={!!errors.weight}
                    />
                    <InputGroup.Text>lbs</InputGroup.Text>
                    <Form.Control.Feedback type="invalid">
                      {errors.weight?.message}
                    </Form.Control.Feedback>
                  </InputGroup>
                )}
              />
            </Form.Group>
          )}

          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Height</Form.Label>
              <Controller
                name="heightFt"
                control={control}
                render={({ field: { value, onChange, ref } }) => (
                  <InputGroup>
                    <Form.Control
                      type="number"
                      value={value ?? ""}
                      onChange={onChange}
                      ref={ref}
                      isInvalid={!!errors.heightFt}
                    />
                    <InputGroup.Text>ft</InputGroup.Text>
                  </InputGroup>
                )}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>&nbsp;</Form.Label>
              <Controller
                name="heightIn"
                control={control}
                render={({ field: { value, onChange, ref } }) => (
                  <InputGroup>
                    <Form.Control
                      type="number"
                      value={value ?? ""}
                      onChange={onChange}
                      ref={ref}
                      isInvalid={!!errors.heightIn}
                    />
                    <InputGroup.Text>in</InputGroup.Text>
                  </InputGroup>
                )}
              />
            </Form.Group>
          </Row>
          <Form.Group className="mb-3">
            <Form.Label>Package type</Form.Label>
            <Controller
              name="packageType"
              control={control}
              render={({ field: { value, onChange, ref } }) => (
                <Select
                  options={clientPackageTypeOptions}
                  value={clientPackageTypeOptions.find(
                    (option) => option.value === value
                  )}
                  onChange={(val) => onChange(val.value)}
                  ref={ref}
                  isSearchable={false}
                  className="clbr-select-container"
                  classNamePrefix="clbr-select"
                />
              )}
            />
            {errors.packageType && (
              <Form.Control.Feedback
                type="invalid"
                style={{ display: "block" }}
              >
                {errors.packageType?.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          {(inputPackageType === ClientPackageType.Premium ||
            inputPackageType === ClientPackageType.Legacy ||
            inputPackageType === ClientPackageType.TestAccount) && (
            <Form.Group className="mb-3">
              <Form.Label>Coach</Form.Label>
              <Controller
                name="coach"
                control={control}
                render={({ field: { value, onChange, ref } }) => (
                  <AsyncSelect
                    defaultOptions={trainerOptions}
                    value={trainerOptions.find(
                      (option) => option.value.id === value.id
                    )}
                    loadOptions={getTrainerOptions}
                    onChange={(val) => onChange(val.value)}
                    ref={ref}
                    className="clbr-select-container"
                    classNamePrefix="clbr-select"
                  />
                )}
              />
            </Form.Group>
          )}

          {inputPackageType === ClientPackageType.Pro && (
            <Form.Group className="mb-3">
              <Form.Label>Pro Group</Form.Label>
              <Controller
                name="assignedProGroupId"
                control={control}
                render={({ field: { value, onChange, ref } }) => (
                  <Select
                    options={proGroupOptions}
                    value={proGroupOptions.find(
                      (option) => option.value === value
                    )}
                    onChange={(val) => onChange(val.value)}
                    ref={ref}
                    isSearchable={false}
                    className="clbr-select-container"
                    classNamePrefix="clbr-select"
                  />
                )}
              />
              {errors.assignedProGroupId && (
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: "block" }}
                >
                  {errors.assignedProGroupId?.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          )}

          <Row>
            <Form.Label>Profile image</Form.Label>
          </Row>
          <Row className="mb-5">
            <Col className="col-2">
              {profilePicUrl ? (
                <Image
                  className="rounded-circle"
                  style={{
                    width: 56,
                    height: 56,
                    border: "1px solid #ced4da",
                    objectFit: "cover",
                  }}
                  src={profilePicUrl}
                />
              ) : (
                <DefaultUserImage width={56} />
              )}
            </Col>
            <Form.Group as={Col}>
              <Controller
                name="profilePicFile"
                control={control}
                render={({ field: { ref } }) => (
                  <Form.Control
                    type="file"
                    onChange={(event) => handleProfilePicChange(event)}
                    ref={ref}
                    isInvalid={!!errors.profilePicFile}
                  />
                )}
              />
              <Form.Text className="text-muted" style={{ fontSize: 12 }}>
                Minimum 48px x 48px ・ Maximum 500kb
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                {errors.profilePicFile?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Button className="mb-2" type="submit" disabled={isLoading}>
            {isLoading && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
                style={{ marginRight: 7 }}
              />
            )}
            {isEditClientDetailsOpen ? "Save Client" : "Add New Client"}
          </Button>
        </Form>

        {isAdmin && isEditClientDetailsOpen && fetchedClient && (
          <DeleteClientButton
            userToDelete={fetchedClient}
            onClose={() => {
              searchClientsTab({});
              onClose();
            }}
          />
        )}
      </Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onClose: () => {
    dispatch(closeNewClientInClientsTab());
  },
  fetchUserData: (id: string) => {
    dispatch(fetchClientProfileForClientsTab(id));
  },
  createClientFromWeb: (args: MutationCreateClientFromWebArgs) => {
    dispatch(createClientFromWeb(args));
  },
  updateClientFromWeb: (args: MutationUpdateClientFromWebArgs) => {
    dispatch(updateClientFromWeb(args));
  },
  searchClientsTab: (args: any) => {
    dispatch(searchClientsTab(args));
  },
  getAllProGroups: () => {
    dispatch(getAllProGroups());
  },
});

const mapStateToProps = (state: StoreState) => ({
  isNewClientDetailsOpen: state.clientsTabState.isNewClientDetailsOpen,
  isEditClientDetailsOpen: state.clientsTabState.isEditClientDetailsOpen,
  clientIdToEdit: state.clientsTabState.clientIdToEdit,
  fetchedClient: state.clientsTabState.fetchedClient,
  clientPackageType: state.clientsTabState.fetchedClientPackageType,
  clientWeight: state.clientsTabState.fetchedClientWeight,
  isLoading: state.adminCreateClientState.isLoading,
  isAdmin: getIsAdmin(state),
  proGroups: state.proGroupsState.proGroups,
  authToken: state.authState.authToken,
});
// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(ClientDetails);
