import React from "react";
import { connect } from "react-redux";
import colors from "../../assets/colors";
import {
  closeRightMostPanel,
  selectTrainingPlan,
  selectWorkoutTrainingPlan,
} from "../../redux/actions/TrainingPrograms";
import { StoreState } from "../../redux/reducers";
import CloseIcon from "../Icons/CloseIcons";
import SimpleButton from "../SimpleButton";

interface Props {
  showExerciseLibrary: boolean;
  showSupersetEditor: boolean;
  showExercises: boolean;
  planIndex: number;
  workoutIndex: number;
  selectTrainingPlan: (index: number) => void;
  selectWorkoutTrainingPlan: (index: number) => void;
  closeRightMostPanel: () => void;
}

const TrainingProgramBreadcrumbs = (props: Props) => {
  const {
    showExerciseLibrary,
    showSupersetEditor,
    showExercises,
    planIndex,
    workoutIndex,
    selectTrainingPlan,
    selectWorkoutTrainingPlan,
    closeRightMostPanel,
  } = props;

  const getActivePaneColor = (thisPane: string) => {
    let active: boolean = false;
    if (
      !showExercises &&
      !showExerciseLibrary &&
      thisPane === "showWorkoutGroups"
    ) {
      active = true;
    } else if (
      showExercises &&
      !showSupersetEditor &&
      !showExerciseLibrary &&
      thisPane === "showExercises"
    ) {
      active = true;
    } else if (
      showSupersetEditor &&
      !showExerciseLibrary &&
      thisPane === "showSupersetEditor"
    ) {
      active = true;
    } else if (showExerciseLibrary && thisPane === "showExerciseLibrary") {
      active = true;
    }

    if (active) {
      return "#000000";
    }
    return colors.caliber_secondary_gray_47;
  };

  const textStyle: React.CSSProperties = {
    fontSize: "16px",
    lineHeight: "32px",
    letterSpacing: "-0.011em",
  };

  const slash = (
    <div
      className="bold"
      style={{
        ...textStyle,
        margin: "0px 12px",
      }}
    >
      /
    </div>
  );

  return (
    <div
      className="d-flex align-items-center justify-content-between "
      style={{
        flex: 1,
      }}
    >
      <div className="d-flex">
        <SimpleButton
          onClick={() => selectTrainingPlan(planIndex)}
          className="bold"
          style={{
            ...textStyle,
            color: getActivePaneColor("showWorkoutGroups"),
          }}
        >
          Workout groups
        </SimpleButton>

        {showExercises && (
          <div className="d-flex">
            {slash}
            <SimpleButton
              onClick={() => selectWorkoutTrainingPlan(workoutIndex)}
              className="bold"
              style={{
                ...textStyle,
                color: getActivePaneColor("showExercises"),
              }}
            >
              Workout
            </SimpleButton>
          </div>
        )}

        {showSupersetEditor && (
          <div className="d-flex">
            {slash}
            <div
              className="bold"
              style={{
                ...textStyle,
                color: getActivePaneColor("showSupersetEditor"),
              }}
            >
              Superset
            </div>
          </div>
        )}

        {showExerciseLibrary && (
          <div className="d-flex">
            {slash}
            <div
              className="bold"
              style={{
                ...textStyle,
                color: getActivePaneColor("showExerciseLibrary"),
              }}
            >
              Exercise Library
            </div>
          </div>
        )}
      </div>

      {showExercises && (
        <SimpleButton onClick={() => closeRightMostPanel()}>
          <CloseIcon width={26} height={26} />
        </SimpleButton>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  selectTrainingPlan: (index: number) => {
    dispatch(selectTrainingPlan(index));
  },
  selectWorkoutTrainingPlan: (index: number) => {
    dispatch(selectWorkoutTrainingPlan(index));
  },
  closeRightMostPanel: () => {
    dispatch(closeRightMostPanel());
  },
});

const mapStateToProps = (state: StoreState) => ({
  showExerciseLibrary: state.trainingProgramsState.showExerciseLibrary,
  showExercises: state.trainingProgramsState.showExercises,
  showSupersetEditor: state.trainingProgramsState.showSupersetEditor,
  planIndex: state.trainingProgramsState.planIndex,
  workoutIndex: state.trainingProgramsState.workoutIndex,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrainingProgramBreadcrumbs);
