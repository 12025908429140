import _ from "lodash";
import {
  SEARCH_EXERCISES_LOAD_FAIL,
  SEARCH_EXERCISES_LOAD_SUCCESS,
  SEARCH_EXERCISES_LOADING,
  SearchExercisesAction,
  SearchExercisesState,
} from "../../actions/SearchExercises/types";
import {
  ADMIN_EXERCISE_LIB_SAVE_SUCCESS,
  AdminExerciseLibraryAction,
  ADMIN_EXERCISE_DELETE_SUCCESS,
} from "../../actions/AdminExerciseLibrary/types";

const initialState: SearchExercisesState = {
  isLoading: false,
  currentArgs: null,
  exerciseList: [],
  isLastPage: false,
  currentPage: 0,
  totalElements: 0,
};

const searchExercisesState = (
  state = initialState,
  action: SearchExercisesAction & AdminExerciseLibraryAction
) => {
  switch (action.type) {
    case SEARCH_EXERCISES_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case SEARCH_EXERCISES_LOAD_SUCCESS: {
      const isFirstPage = action.data.number === 0;
      let mergedData;
      if (!isFirstPage) {
        const collections = [...state.exerciseList, ...action.data.content];
        mergedData = _.uniqBy(collections, (item) => item.id);
      }
      return {
        ...state,
        currentArgs: action.args,
        isLoading: false,
        exerciseList: isFirstPage ? action.data.content : mergedData,
        isLastPage: action.data.last,
        currentPage: action.data.number,
        totalElements: action.data.totalElements,
        totalPages: action.data.totalPages,
      };
    }
    case SEARCH_EXERCISES_LOAD_FAIL:
      return {
        ...state,
        isLoading: false,
        exerciseList: [],
        isLastPage: false,
        currentPage: 0,
        totalElements: 0,
      };
    case ADMIN_EXERCISE_LIB_SAVE_SUCCESS: {
      const updatedList = state.exerciseList.filter(
        (exercise) => action.savedExercise.id !== exercise.id
      );
      updatedList.push(action.savedExercise);
      return {
        ...state,
        exerciseList: updatedList.sort((a, b) => a.name.localeCompare(b.name)),
      };
    }
    case ADMIN_EXERCISE_DELETE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        exerciseList: state.exerciseList.filter(
          (exercise) => action?.deletedExerciseId !== exercise?.id
        ),
      };
    }

    default:
      return state;
  }
};

export default searchExercisesState;
