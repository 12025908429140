import { gql } from "apollo-boost";

export const CaliberRulesFragment = gql`
  fragment CaliberRulesProperty on CaliberRule {
    description
    condition
    id
    lowerLimitParam
    name
    objectClassSimpleName
    objectCrudOperation
    paramOperator
    paramUnit
    type
    upperLimitParam
    ruleAutocompletable
    ... on CoachReminderRule {
      taskExpirationDurationHours
    }
    ... on CoachReminderRuleFromCron {
      taskExpirationDurationHours
      snoozePeriod
    }
    ... on TaskAutocompletionRule {
      taskAutocompletionSourceRuleName
    }
    ... on SnoozeResetRule {
      snoozedTaskSourceRuleName
    }
  }
`;

export const CALIBER_RULES_GQL = gql`
  ${CaliberRulesFragment}
  query CaliberRules {
    caliberRules {
      ...CaliberRulesProperty
    }
  }
`;
