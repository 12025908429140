import React from "react";
import { connect } from "react-redux";
import WorkoutGroupHeader from "./WorkoutGroupHeader";
import { ClientWorkoutGroupTemplate } from "../../types/gqlTypes";
import WorkoutList from "../TrainingProgramWorkoutList/WorkoutList";
import { StoreState } from "../../redux/reducers";

interface Props {
  workoutGroup: ClientWorkoutGroupTemplate;
  index?: number;
  selectedWorkoutGroupIndex?: number;
  moveWorkoutGroupCard: (id: string, toIndex: number) => void;
  findCardIndex: (id: string) => { index: number };
}

const WorkoutGroupItem = (props: Props) => {
  const {
    workoutGroup,
    index,
    selectedWorkoutGroupIndex,
    moveWorkoutGroupCard,
    findCardIndex,
  } = props;

  const isSelected = selectedWorkoutGroupIndex == index;

  return (
    <div
      style={{
        flex: 1,
      }}
    >
      {/* @ts-ignore */}
      <WorkoutGroupHeader
        workoutGroup={workoutGroup}
        index={index}
        moveWorkoutGroupCard={moveWorkoutGroupCard}
        findCardIndex={findCardIndex}
      />
      {isSelected && <WorkoutList workoutGroupId={workoutGroup.id} />}
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedWorkoutGroupIndex: state.trainingProgramsState.workoutGroupIndex,
});

export default connect(mapStateToProps)(WorkoutGroupItem);
