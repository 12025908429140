import { format } from "date-fns";
import apolloClient from "../../../api/apolloClient";
import {
  CREATE_BODY_STAT_CALENDAR_ITEM,
  CREATE_BODY_STAT_RECURRING_EVENT_DEFINITION,
  CREATE_CARDIO_ACTIVITY_RECURRING_EVENT_DEFINITION,
  CREATE_CARDIO_CALENDAR_ITEM,
  CREATE_CLIENT_WORKOUT_TEMPLATE_RECURRING_EVENT_DEFINITION,
  CREATE_HABIT_CALENDAR_ITEM,
  CREATE_HABIT_RECURRING_EVENT_DEFINITION,
  CREATE_PROGRESS_PHOTO_CALENDAR_ITEM,
  CREATE_PROGRESS_PHOTO_RECURRING_EVENT_DEFINITION,
  CREATE_WORKOUT_CALENDAR_ITEM,
  DELETE_CALENDAR_ITEM,
  DELETE_RECURRING_EVENT,
  DELETE_RECURRING_EVENT_DEFINITION,
  FETCH_BODY_STAT_CALENDAR_ITEMS,
  FETCH_CALENDAR_ITEM,
  FETCH_DETAILED_CALENDAR_ITEMS,
  FETCH_NUTRITION_CALENDAR_ITEMS,
  FETCH_PROGRESS_PHOTO_CALENDAR_ITEMS,
  FETCH_SHALLOW_CALENDAR_ITEMS,
  MOVE_CALENDAR_ITEM,
  MOVE_RECURRING_EVENT,
  UPDATE_RECURRING_EVENT_DEFINITION,
} from "../../../api/gql/calendar";
import {
  MutationCreateBodyStatCalendarItemArgs,
  MutationCreateBodyStatRecurringEventDefinitionArgs,
  MutationCreateCardioActivityCalendarItemArgs,
  MutationCreateCardioActivityRecurringEventDefinitionArgs,
  MutationCreateClientWorkoutTemplateRecurringEventDefinitionArgs,
  MutationCreateHabitCalendarItemArgs,
  MutationCreateHabitRecurringEventDefinitionArgs,
  MutationCreateProgressPhotoCalendarItemArgs,
  MutationCreateProgressPhotoRecurringEventDefinitionArgs,
  MutationCreateWorkoutCalendarItemArgs,
  MutationDeleteRecurringEventArgs,
  MutationDeleteRecurringEventDefinitionArgs,
  MutationMoveRecurringEventArgs,
  MutationUpdateRecurringEventDefinitionArgs,
  QueryCalendarDateRangeArgs,
} from "../../../types/gqlTypes";
import {
  CalendarDataAction,
  CalendarDataState,
  CALENDAR_DATA_LOADING,
  CALENDAR_FETCH_DATA_FAILED,
  CALENDAR_FETCH_DATA_SUCCESS,
  CALENDAR_FETCH_ITEM_FAILED,
  CALENDAR_FETCH_ITEM_LOADING,
  CALENDAR_FETCH_ITEM_SUCCESS,
  CALENDAR_FETCH_NUTRITION_DATA_SUCCESS,
  CLOSE_CALENDAR_FETCH_ITEM_FAILED_DIALOG,
  CREATE_CALENDAR_ITEM,
  CREATE_CALENDAR_ITEM_SAVING,
  DELETE_CALENDAR_ITEM_FAILED,
  DELETE_CALENDAR_ITEM_SAVING,
  DELETE_CALENDAR_ITEM_SUCCESS,
  MOVE_CALENDAR_ITEM_SAVING,
  MOVE_CALENDAR_ITEM_SUCCESS,
} from "./types";

const QUERY_DATE_FORMAT = "yyyy-MM-dd";

export enum CalendarProgressItems {
  BODY_STAT,
  NUTRITION,
  PROGRESS_PHOTO,
}
const refetchCalendar = async (dispatch, getState) => {
  if (getState().calendarDataState.isLoading) {
    return;
  }
  dispatch({ type: CALENDAR_DATA_LOADING });
  try {
    const client = await apolloClient(getState().authState.authToken, dispatch);
    const result = await client.query({
      query: FETCH_SHALLOW_CALENDAR_ITEMS,
      variables: getState().calendarDataState.args,
    });
    dispatch({
      type: CALENDAR_FETCH_DATA_SUCCESS,
      data: result.data.calendarDateRange,
      args: getState().calendarDataState.args,
    } as CalendarDataAction);
  } catch (error) {
    dispatch({ type: CALENDAR_FETCH_DATA_FAILED, data: error });
  }
};
export const getCalendarItems =
  (clientId: string, startDate: Date, endDate: Date, fetchDetailed: Boolean) =>
  async (dispatch, getState) => {
    if (getState().calendarDataState.isLoading) {
      return;
    }
    dispatch({ type: CALENDAR_DATA_LOADING });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const variables: QueryCalendarDateRangeArgs = {
        clientId,
        endDate: format(endDate, QUERY_DATE_FORMAT),
        startDate: format(startDate, QUERY_DATE_FORMAT),
      };

      const result = await client.query({
        query: fetchDetailed
          ? FETCH_DETAILED_CALENDAR_ITEMS
          : FETCH_SHALLOW_CALENDAR_ITEMS,
        variables,
      });
      dispatch({
        type: CALENDAR_FETCH_DATA_SUCCESS,
        data: result.data.calendarDateRange,
        args: variables,
      } as CalendarDataAction);
    } catch (error) {
      dispatch({ type: CALENDAR_FETCH_DATA_FAILED, data: error });
    }
  };
export const getCalendarProgressItems =
  (
    clientId: string,
    startDate: Date,
    endDate: Date,
    type: CalendarProgressItems
  ) =>
  async (dispatch, getState) => {
    if (getState().calendarDataState.isLoading) {
      return;
    }
    dispatch({ type: CALENDAR_DATA_LOADING });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );

      let query;
      switch (type) {
        case CalendarProgressItems.BODY_STAT:
          query = FETCH_BODY_STAT_CALENDAR_ITEMS;
          break;
        case CalendarProgressItems.NUTRITION:
          query = FETCH_NUTRITION_CALENDAR_ITEMS;
          break;
        case CalendarProgressItems.PROGRESS_PHOTO:
          query = FETCH_PROGRESS_PHOTO_CALENDAR_ITEMS;
          break;
      }
      const variables: QueryCalendarDateRangeArgs = {
        clientId,
        endDate: format(endDate, QUERY_DATE_FORMAT),
        startDate: format(startDate, QUERY_DATE_FORMAT),
      };

      const result = await client.query({ query, variables });

      if (
        type === CalendarProgressItems.BODY_STAT ||
        type === CalendarProgressItems.PROGRESS_PHOTO
      ) {
        dispatch({
          type: CALENDAR_FETCH_DATA_SUCCESS,
          data: result.data.calendarDateRange,
        } as CalendarDataAction);
      } else if (type === CalendarProgressItems.NUTRITION) {
        dispatch({
          type: CALENDAR_FETCH_NUTRITION_DATA_SUCCESS,
          data: result.data.calendarDateRange,
          clientNutritionTarget: result.data.clientNutritionTarget,
        });
      }
    } catch (error) {
      dispatch({ type: CALENDAR_FETCH_DATA_FAILED, data: error });
    }
  };
export const getCalendarItem =
  (clientId: string, calendarItemId: string, calendarDayId: string) =>
  async (dispatch, getState) => {
    if (getState().calendarDataState.isLoadingItem) {
      return;
    }
    dispatch({ type: CALENDAR_FETCH_ITEM_LOADING });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const result = await client.query({
        query: FETCH_CALENDAR_ITEM,
        variables: { clientId, calendarItemId },
      });
      dispatch({
        type: CALENDAR_FETCH_ITEM_SUCCESS,
        calendarItem: result?.data?.calendarItem,
        clientNutritionTarget: result?.data?.clientNutritionTarget,
        calendarDayId,
      });
    } catch (error) {
      dispatch({ type: CALENDAR_FETCH_ITEM_FAILED });
    }
  };

export const createWorkoutCalendarItem =
  (args: MutationCreateWorkoutCalendarItemArgs) =>
  async (dispatch, getState) => {
    if (getState().calendarDataState.isSavingEvent) {
      return;
    }
    dispatch({ type: CREATE_CALENDAR_ITEM_SAVING });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );

      const result = await client.mutate({
        mutation: CREATE_WORKOUT_CALENDAR_ITEM,
        variables: args,
      });
      dispatch({
        type: CREATE_CALENDAR_ITEM,
        calendarItem: result.data.createWorkoutCalendarItem,
        date: `${args.year}-${args.month < 10 ? "0" : ""}${args.month}-${
          args.dayOfMonth < 10 ? "0" : ""
        }${args.dayOfMonth}`,
      } as CalendarDataAction);
    } catch (error) {
      dispatch({ type: CALENDAR_FETCH_DATA_FAILED, data: error });
    }
  };
export const createClientWorkoutTemplateRecurringEventDefinition =
  (args: MutationCreateClientWorkoutTemplateRecurringEventDefinitionArgs) =>
  async (dispatch, getState) => {
    if (getState().calendarDataState.isSavingEvent) {
      return;
    }
    dispatch({ type: CREATE_CALENDAR_ITEM_SAVING });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );

      await client.mutate({
        mutation: CREATE_CLIENT_WORKOUT_TEMPLATE_RECURRING_EVENT_DEFINITION,
        variables: args,
      });
      refetchCalendar(dispatch, getState);
    } catch (error) {
      dispatch({ type: CALENDAR_FETCH_DATA_FAILED, data: error });
    }
  };
export const createCardioActivityCalendarItem =
  (args: MutationCreateCardioActivityCalendarItemArgs) =>
  async (dispatch, getState) => {
    if (getState().calendarDataState.isSavingEvent) {
      return;
    }
    dispatch({ type: CREATE_CALENDAR_ITEM_SAVING });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );

      const result = await client.mutate({
        mutation: CREATE_CARDIO_CALENDAR_ITEM,
        variables: args,
      });
      dispatch({
        type: CREATE_CALENDAR_ITEM,
        calendarItem: result.data.createCardioActivityCalendarItem,
        date: `${args.year}-${args.month < 10 ? "0" : ""}${args.month}-${
          args.dayOfMonth < 10 ? "0" : ""
        }${args.dayOfMonth}`,
      } as CalendarDataAction);
    } catch (error) {
      dispatch({ type: CALENDAR_FETCH_DATA_FAILED, data: error });
    }
  };
export const createCardioActivityRecurringEventDefinition =
  (args: MutationCreateCardioActivityRecurringEventDefinitionArgs) =>
  async (dispatch, getState) => {
    if (getState().calendarDataState.isSavingEvent) {
      return;
    }
    dispatch({ type: CREATE_CALENDAR_ITEM_SAVING });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );

      await client.mutate({
        mutation: CREATE_CARDIO_ACTIVITY_RECURRING_EVENT_DEFINITION,
        variables: args,
      });
      refetchCalendar(dispatch, getState);
    } catch (error) {
      dispatch({ type: CALENDAR_FETCH_DATA_FAILED, data: error });
    }
  };

export const createBodyStatCalendarItem =
  (args: MutationCreateBodyStatCalendarItemArgs) =>
  async (dispatch, getState) => {
    if (getState().calendarDataState.isSavingEvent) {
      return;
    }
    dispatch({ type: CREATE_CALENDAR_ITEM_SAVING });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );

      await client.mutate({
        mutation: CREATE_BODY_STAT_CALENDAR_ITEM,
        variables: args,
      });
      refetchCalendar(dispatch, getState);
    } catch (error) {
      dispatch({ type: CALENDAR_FETCH_DATA_FAILED, data: error });
    }
  };
export const createBodyStatRecurringEventDefinition =
  (args: MutationCreateBodyStatRecurringEventDefinitionArgs) =>
  async (dispatch, getState) => {
    if (getState().calendarDataState.isSavingEvent) {
      return;
    }
    dispatch({ type: CREATE_CALENDAR_ITEM_SAVING });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );

      await client.mutate({
        mutation: CREATE_BODY_STAT_RECURRING_EVENT_DEFINITION,
        variables: args,
      });
      refetchCalendar(dispatch, getState);
    } catch (error) {
      dispatch({ type: CALENDAR_FETCH_DATA_FAILED, data: error });
    }
  };
export const createProgressPhotoCalendarItem =
  (args: MutationCreateProgressPhotoCalendarItemArgs) =>
  async (dispatch, getState) => {
    if (getState().calendarDataState.isSavingEvent) {
      return;
    }
    dispatch({ type: CREATE_CALENDAR_ITEM_SAVING });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );

      const result = await client.mutate({
        mutation: CREATE_PROGRESS_PHOTO_CALENDAR_ITEM,
        variables: args,
      });
      dispatch({
        type: CREATE_CALENDAR_ITEM,
        calendarItem: result.data.createProgressPhotoCalendarItem,
        date: `${args.year}-${args.month < 10 ? "0" : ""}${args.month}-${
          args.dayOfMonth < 10 ? "0" : ""
        }${args.dayOfMonth}`,
      } as CalendarDataAction);
    } catch (error) {
      dispatch({ type: CALENDAR_FETCH_DATA_FAILED, data: error });
    }
  };
export const createProgressPhotoRecurringEventDefinition =
  (args: MutationCreateProgressPhotoRecurringEventDefinitionArgs) =>
  async (dispatch, getState) => {
    if (getState().calendarDataState.isSavingEvent) {
      return;
    }
    dispatch({ type: CREATE_CALENDAR_ITEM_SAVING });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );

      await client.mutate({
        mutation: CREATE_PROGRESS_PHOTO_RECURRING_EVENT_DEFINITION,
        variables: args,
      });
      refetchCalendar(dispatch, getState);
    } catch (error) {
      dispatch({ type: CALENDAR_FETCH_DATA_FAILED, data: error });
    }
  };
export const createHabitCalendarItem =
  (args: MutationCreateHabitCalendarItemArgs) => async (dispatch, getState) => {
    if (getState().calendarDataState.isSavingEvent) {
      return;
    }
    dispatch({ type: CREATE_CALENDAR_ITEM_SAVING });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );

      await client.mutate({
        mutation: CREATE_HABIT_CALENDAR_ITEM,
        variables: args,
      });
      refetchCalendar(dispatch, getState);
    } catch (error) {
      dispatch({ type: CALENDAR_FETCH_DATA_FAILED, data: error });
    }
  };
export const createHabitRecurringEventDefinition =
  (args: MutationCreateHabitRecurringEventDefinitionArgs) =>
  async (dispatch, getState) => {
    if (getState().calendarDataState.isSavingEvent) {
      return;
    }
    dispatch({ type: CREATE_CALENDAR_ITEM_SAVING });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );

      await client.mutate({
        mutation: CREATE_HABIT_RECURRING_EVENT_DEFINITION,
        variables: args,
      });
      refetchCalendar(dispatch, getState);
    } catch (error) {
      dispatch({ type: CALENDAR_FETCH_DATA_FAILED, data: error });
    }
  };
export const deleteCalendarItem =
  (calendarItemId: string, date: Date) => async (dispatch, getState) => {
    const calendarDataState = getState().calendarDataState as CalendarDataState;
    if (calendarDataState.isDeletingEvent) {
      return;
    }
    dispatch({
      type: DELETE_CALENDAR_ITEM_SAVING,
    });
    try {
      const apollo = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const { clientId } = calendarDataState.args;
      await apollo.mutate({
        mutation: DELETE_CALENDAR_ITEM,
        variables: { clientId, calendarItemId },
      });

      dispatch({
        type: DELETE_CALENDAR_ITEM_SUCCESS,
        calendarItemId,
        date: format(date, "yyyy-MM-dd"),
      });
    } catch (error) {
      console.error("Error :", error);
      dispatch({
        type: DELETE_CALENDAR_ITEM_FAILED,
        data: error,
      });
    }
  };
export const deleteRecurringEvent =
  (args: MutationDeleteRecurringEventArgs) => async (dispatch, getState) => {
    if (getState().calendarDataState.isDeletingEvent) {
      return;
    }
    dispatch({
      type: DELETE_CALENDAR_ITEM_SAVING,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      await client.mutate({
        mutation: DELETE_RECURRING_EVENT,
        variables: args,
      });

      refetchCalendar(dispatch, getState);
    } catch (error) {
      console.error("Error :", error);
      dispatch({
        type: DELETE_CALENDAR_ITEM_FAILED,
        data: error,
      });
    }
  };
export const deleteRecurringEventDefinition =
  (args: MutationDeleteRecurringEventDefinitionArgs) =>
  async (dispatch, getState) => {
    if (getState().calendarDataState.isDeletingEvent) {
      return;
    }
    dispatch({
      type: DELETE_CALENDAR_ITEM_SAVING,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      await client.mutate({
        mutation: DELETE_RECURRING_EVENT_DEFINITION,
        variables: args,
      });

      refetchCalendar(dispatch, getState);
    } catch (error) {
      console.error("Error :", error);
      dispatch({
        type: DELETE_CALENDAR_ITEM_FAILED,
        data: error,
      });
    }
  };
export const updateRecurringEventDefinition =
  (args: MutationUpdateRecurringEventDefinitionArgs) =>
  async (dispatch, getState) => {
    if (getState().calendarDataState.isSavingEvent) {
      return;
    }
    dispatch({
      type: DELETE_CALENDAR_ITEM_SAVING,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      await client.mutate({
        mutation: UPDATE_RECURRING_EVENT_DEFINITION,
        variables: args,
      });

      refetchCalendar(dispatch, getState);
    } catch (error) {
      console.error("Error :", error);
      dispatch({
        type: DELETE_CALENDAR_ITEM_FAILED,
        data: error,
      });
    }
  };

export const closeCalendarFetchItemFailedDialog =
  () => async (dispatch, getState) => {
    dispatch({
      type: CLOSE_CALENDAR_FETCH_ITEM_FAILED_DIALOG,
    });
  };

export const moveCalendarItem =
  (calendarItemId: string, date: Date, oldCalendarDayId: string) =>
  async (dispatch, getState) => {
    const calendarDataState = getState().calendarDataState as CalendarDataState;
    if (calendarDataState.isMovingCalendarItem) {
      return;
    }
    dispatch({ type: MOVE_CALENDAR_ITEM_SAVING });
    try {
      const apollo = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const { clientId } = calendarDataState.args;

      const result = await apollo.mutate({
        mutation: MOVE_CALENDAR_ITEM,
        variables: {
          clientId,
          calendarItemId,
          date: format(date, "yyyy-MM-dd"),
        },
      });
      dispatch({
        type: MOVE_CALENDAR_ITEM_SUCCESS,
        calendarDay: result.data.moveCalendarItem,
        oldCalendarDayId,
        calendarItemId,
      } as CalendarDataAction);
    } catch (error) {
      dispatch({ type: CALENDAR_FETCH_DATA_FAILED, data: error });
    }
  };

export const moveRecurringEvent =
  (args: MutationMoveRecurringEventArgs) => async (dispatch, getState) => {
    if (getState().calendarDataState.isMovingCalendarItem) {
      return;
    }
    dispatch({ type: MOVE_CALENDAR_ITEM_SAVING });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );

      await client.mutate({
        mutation: MOVE_RECURRING_EVENT,
        variables: args,
      });
      refetchCalendar(dispatch, getState);
    } catch (error) {
      dispatch({ type: CALENDAR_FETCH_DATA_FAILED, data: error });
    }
  };
