import React from "react";
import colors from "../../../assets/colors";
import Checkbox from "../../Icons/Checkbox";

interface Props {
  text?: string;
  isChecked?: boolean;
  onClick?: () => void;
}

const ListItem = (props: Props) => {
  const { text, isChecked, onClick } = props;

  return (
    <div
      className="d-flex align-items-center justify-content-between"
      style={{
        border: !isChecked && "1px solid #F2F3F5",
        borderRadius: 6,
        marginBottom: 4,
        height: 48,
        marginRight: 24,
        marginLeft: 24,
        backgroundColor: isChecked ? "#F7F8FF" : colors.caliber_white,
      }}
    >
      <div
        style={{
          fontSize: "14px",
          lineHeight: "32px",
          color: colors.caliber_secondary,
          marginLeft: 15,
        }}
      >
        {text}
      </div>

      <div
        style={{
          marginRight: 14,
        }}
      >
        <Checkbox
          type="filled"
          onClick={onClick}
          checked={isChecked}
          width={20}
          height={20}
          color={
            isChecked
              ? colors.caliber_blue_200
              : colors.caliber_secondary_gray_29
          }
        />
      </div>
    </div>
  );
};

export default ListItem;
