import React, { useEffect, useRef } from "react";
import Hls from "hls.js";
import VideoPlayIcon from "../../assets/images/VideoPlayIcon.svg";
import colors from "../../assets/colors";
import Loader from "../Loader";

interface Props {
  videoUrl?: string;
  onVideoPicked: (file: any) => void;
  width?: number;
  height: number;
  disabled?: boolean;
  showLoader?: boolean;
}

const EditVideo = (props: Props) => {
  const { videoUrl, onVideoPicked, width, height, disabled, showLoader } =
    props;

  const fileInputRef = useRef(null);
  const videoRef = useRef(null);
  useEffect(() => {
    if (videoRef && videoUrl != null) {
      const hls = new Hls();
      hls.loadSource(videoUrl);
      hls.attachMedia(videoRef.current);
    }
  }, [videoRef]);

  const imageStyle = {
    backgroundColor: colors.caliber_white,
    width,
    height,
  };

  return (
    <div
      className={`d-flex ${showLoader ? "" : "pointer"}`}
      role="button"
      tabIndex={0}
      onClick={() => {
        if (!disabled) {
          fileInputRef.current.click();
        }
      }}
      onKeyDown={(event) => {
        if (event.key === "Enter" && !disabled) {
          fileInputRef.current.click();
        }
      }}
      style={{
        position: "relative",
        backgroundColor: colors.caliber_white,
        width,
        height,
      }}
    >
      <input
        ref={fileInputRef}
        type="file"
        name="image"
        onChange={(event) => {
          if (event.target == null || event.target.files[0] == null) {
            return;
          }
          const file = event.target.files[0];
          if (file.type.startsWith("video")) {
            onVideoPicked(event.target.files[0]);
          } else {
            // TODO: Display error
            console.log("Invalid filetype", file.type);
          }
        }}
        style={{
          display: "none",
        }}
      />
      <div style={imageStyle}>
        {videoUrl && (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <video ref={videoRef} width={width} height={height} />
        )}
      </div>
      <div
        className="d-flex paragraph-small justify-content-center align-items-center flex-column"
        style={{
          position: "absolute",
          backgroundColor: `${colors.caliber_secondary}4D`,
          width: "100%",
          height: "100%",
          color: colors.caliber_white,
        }}
      >
        {showLoader ? (
          <Loader />
        ) : (
          <>
            <img
              style={{
                marginBottom: 12,
              }}
              src={VideoPlayIcon}
              alt="Play"
            />
            Edit Video
          </>
        )}
      </div>
    </div>
  );
};

export default EditVideo;
