import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FormControl } from "react-bootstrap";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { format, parse } from "date-fns";
import { StoreState } from "../../redux/reducers";
import colors from "../../assets/colors";
import {
  createTextGoal,
  updateTextGoal,
} from "../../redux/actions/Goals/TextGoal";
import EditIcon from "../../assets/images/EditIcon.svg";
import Loader from "../Loader";
import { TextGoal } from "../../types/gqlTypes";
import "react-datepicker/dist/react-datepicker.css";
import { useKeyPress } from "../../utils/customHooks";

interface Props {
  isLoading: boolean;
  isGoalLoading: boolean;
  textGoal: TextGoal;
  createTextGoal: (clientId: string, text: string, date: string) => void;
  updateTextGoal: (
    goalId: string,
    text: string,
    date: string,
    clientId: string
  ) => void;
}
interface DateInputFieldProps {
  value?: string;
  onClick?: (date?: any) => void;
}

const GoalModule = (props: Props) => {
  const { createTextGoal, updateTextGoal, isLoading, isGoalLoading, textGoal } =
    props;

  const { id: clientId } = useParams();
  const [editMode, setEditMode] = useState(false);
  const textRef = useRef(null);
  const [goal, setGoal] = useState("");
  const [date, setDate] = useState(null);
  const [height, setHeight] = useState(100);
  const parsedDate: Date = textGoal?.targetDate
    ? parse(textGoal?.targetDate, "yyyy-MM-dd", new Date())
    : new Date();

  useKeyPress("Enter", () => {
    if (editMode) {
      onGoalChange?.();
    }
  });
  useKeyPress("Escape", () => {
    if (editMode) {
      cancel?.();
    }
  });

  useEffect(() => {
    cancel();
  }, [textGoal]);

  const toggleEditMode = () => {
    setEditMode(!editMode);
    setTimeout(() => {
      if (!editMode && textRef && textRef.current) {
        // to set focus in the end of the sentence
        textRef.current.focus();
        textRef.current.selectionStart = goal.length;
        textRef.current.selectionEnd = goal.length;
      }
    }, 0);
  };
  const cancel = () => {
    setGoal(textGoal?.text || "");
    setEditMode(false);
    if (textGoal?.targetDate) {
      const date = parse(textGoal?.targetDate, "yyyy-MM-dd", new Date());
      setDate(format(date, "M.dd.yyyy"));
    }
  };

  const DateInputField = (props: DateInputFieldProps) => {
    return (
      <div
        role="button"
        tabIndex={0}
        onClick={(a: any) => {
          if (props?.onClick) {
            props.onClick();
          }
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter" && props?.onClick) {
            props?.onClick();
          }
        }}
        className="d-flex flex-column justify-content-center align-items-center medium-bold pointer"
        style={{
          backgroundColor: colors.caliber_gray_bg,
          padding: "2px 10px",
          borderRadius: 4,
          minWidth: 90,
          margin: 0,
          fontSize: "12px",
          lineHeight: "16px",
        }}
      >
        {date || "--"}
      </div>
    );
  };

  const onGoalChange = () => {
    let formattedDate: Date;
    if (date) {
      formattedDate = parse(date, "MM.dd.yyyy", new Date());
    } else {
      formattedDate = new Date();
    }
    if (textGoal?.id) {
      updateTextGoal(
        textGoal.id,
        goal,
        format(formattedDate, "MM/dd/yyyy"),
        clientId
      );
    } else {
      createTextGoal(clientId, goal, format(formattedDate, "MM/dd/yyyy"));
    }
    toggleEditMode();
  };

  useEffect(() => {
    if (editMode) {
      setHeight(textRef.current.scrollHeight + 2);
    }
  }, [editMode]);

  const dateBadge = (
    <div
      className="d-flex flex-column justify-content-center align-items-center medium-bold"
      style={{
        backgroundColor: colors.caliber_gray_bg,
        padding: "2px 10px",
        borderRadius: 4,
        minWidth: 90,
        margin: 0,
        fontSize: "12px",
        lineHeight: "16px",
      }}
    >
      {date || "--"}
    </div>
  );

  const dateInput = (
    <DatePicker
      onChange={(date) => {
        setDate(format(date, "MM.dd.yyyy"));
      }}
      customInput={<DateInputField />}
      selected={parsedDate}
    />
  );
  const goalInput = (
    <FormControl
      className="heading-xsmall"
      ref={textRef}
      value={goal}
      as="textarea"
      onChange={(event) => {
        const target = event.target as HTMLTextAreaElement;
        setGoal(target.value);
      }}
      style={{
        height,
        padding: 5,
        resize: "none",
        textAlign: "center",
        lineHeight: "20px",
        marginTop: 5,
      }}
    />
  );
  const goalText = (
    <div
      className="bold d-flex flex-column justify-content-center"
      style={{
        flex: 1,
        padding: 8,
        lineHeight: "20px",
        textAlign: "center",
      }}
    >
      {goal}
    </div>
  );

  const saveButton = (
    <div
      className="pointer nofocus paragraph-normal medium-bold"
      role="button"
      tabIndex={0}
      onClick={onGoalChange}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          onGoalChange();
        }
      }}
      style={{
        backgroundColor: colors.caliber_green_200,
        color: colors.caliber_white,
        padding: "1px 10px",
        borderRadius: 4,
        marginBottom: 2,
      }}
    >
      Save
    </div>
  );
  const cancelButton = (
    <div
      role="button"
      tabIndex={0}
      className="pointer nofocus paragraph-normal medium-bold"
      style={{
        backgroundColor: colors.caliber_red_200,
        color: colors.caliber_white,
        padding: "1px 5px",
        borderRadius: 4,
        marginBottom: 2,
      }}
      onClick={cancel}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          cancel();
        }
      }}
    >
      Cancel
    </div>
  );
  const editButton = (
    <div
      className="pointer nofocus"
      role="button"
      tabIndex={0}
      style={{
        height: 18,
        lineHeight: "18px",
      }}
      onClick={toggleEditMode}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          toggleEditMode();
        }
      }}
    >
      <img alt="Edit" src={EditIcon} />
    </div>
  );
  return (
    <div
      className="bordered-item d-flex flex-column"
      style={{
        backgroundColor: colors.caliber_white,
        position: "relative",
        minHeight: 65,
        marginTop: 24,
        padding: 13,
      }}
    >
      {isLoading || isGoalLoading ? (
        <div
          style={{
            position: "absolute",
            top: 30,
            left: "45%",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div>
          <div
            className="d-flex justify-content-between"
            style={{ padding: "11px 11px 0px 11px" }}
          >
            {editMode ? (
              cancelButton
            ) : (
              <div
                className="bold"
                style={{
                  fontSize: "18px",
                  lineHeight: "22px",
                  letterSpacing: "-0.29px",
                  color: "#333333",
                }}
              >
                Goals
              </div>
            )}
            {editMode ? saveButton : editButton}
          </div>
          <div className="d-flex heading-xsmall" style={{ marginTop: 15 }}>
            {editMode ? goalInput : goalText}
          </div>
          <div
            className="d-flex justify-content-between"
            style={{
              margin: "20px 9px 0px 13px",
            }}
          >
            <div
              className="medium-bold d-flex flex-column justify-content-center"
              style={{
                fontSize: "12px",
                lineHeight: "16px",
              }}
            >
              Target completion
            </div>
            <div className="d-flex">{editMode ? dateInput : dateBadge}</div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isLoading: state.clientDetailState.isLoading,
  isGoalLoading: state.clientDetailState.isGoalLoading,
  textGoal: state.clientDetailState.textGoal,
});
const mapDispatchToProps = (dispatch) => ({
  createTextGoal: (clientId: string, text: string, date: string) =>
    dispatch(createTextGoal(clientId, text, date)),
  updateTextGoal: (
    goalId: string,
    text: string,
    date: string,
    clientId: string
  ) => dispatch(updateTextGoal(goalId, text, date, clientId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GoalModule);
