import _ from "lodash";
import { selectTrainingPlan } from ".";
import {
  ComponentLocationType,
  ProgramType,
} from "../../../components/TrainingProgram";
import {
  CaliberPageImpl_CustomTrainingPlan,
  CaliberPageImpl_LibraryTrainingPlan,
  CaliberPageImpl_MasterTrainingPlan,
  ClientTrainingPlan,
  ClientWorkoutGroupTemplate,
  ClientWorkoutTemplate,
  CustomTrainingPlan,
  GymStep,
  LibraryTrainingPlan,
  MasterTrainingPlan,
  MasterWorkout,
  TrainingPlan,
} from "../../../types/gqlTypes";
import {
  TrainingProgramsAction,
  TrainingProgramsState,
} from "../../actions/TrainingPrograms/types";

export const replaceGymSteps = ({
  newGymSteps,
  state,
}: {
  newGymSteps: GymStep[];
  state: TrainingProgramsState;
}) => {
  const { programType, workoutGroupIndex, workoutIndex, cachedPlans } = state;
  if (
    programType === ProgramType.Custom ||
    programType === ProgramType.Library ||
    programType === ProgramType.Master
  ) {
    const plan = selectTrainingPlan(state);
    const newPlan = { ...cachedPlans?.[plan?.id] } as
      | MasterTrainingPlan
      | CustomTrainingPlan
      | LibraryTrainingPlan;
    const workouts = [...newPlan?.masterWorkoutGroups[0].masterWorkouts];
    workouts[workoutIndex] = {
      ...workouts[workoutIndex],
      gymSteps: newGymSteps,
    };
    newPlan.masterWorkoutGroups[0].masterWorkouts = workouts as MasterWorkout[];
    const newState: TrainingProgramsState = {
      ...state,
      cachedPlans: {
        ...cachedPlans,
        [plan.id]: newPlan,
      },
      isSaving: false,
      isDeleting: false,
      isLoading: false,
    };
    return newState;
  }
  if (programType === ProgramType.Client) {
    const clientPlan = selectTrainingPlan(state);
    const newPlan = { ...cachedPlans?.[clientPlan?.id] } as ClientTrainingPlan;
    const workoutGroup = newPlan.clientWorkoutGroupTemplates[workoutGroupIndex];
    const workouts = [...(workoutGroup?.clientWorkoutTemplates || [])];
    workouts[workoutIndex] = {
      ...workouts[workoutIndex],
      gymSteps: newGymSteps || [],
    };
    workoutGroup.clientWorkoutTemplates = workouts as ClientWorkoutTemplate[];
    newPlan.clientWorkoutGroupTemplates[workoutGroupIndex] = workoutGroup;

    const newState: TrainingProgramsState = {
      ...state,
      cachedPlans: {
        ...cachedPlans,
        [clientPlan.id]: newPlan,
      },
      isSaving: false,
      isDeleting: false,
      isLoading: false,
    };
    return newState;
  }
  return state;
};
export const replaceWorkouts = ({
  newWorkouts,
  state,
}: {
  newWorkouts: (ClientWorkoutTemplate | MasterWorkout)[];
  state: TrainingProgramsState;
}) => {
  const { programType, workoutGroupIndex, cachedPlans } = state;
  if (
    programType === ProgramType.Custom ||
    programType === ProgramType.Library ||
    programType === ProgramType.Master
  ) {
    const plan = selectTrainingPlan(state);
    const newPlan = { ...cachedPlans?.[plan?.id] } as
      | MasterTrainingPlan
      | CustomTrainingPlan
      | LibraryTrainingPlan;
    newPlan.masterWorkoutGroups[0].masterWorkouts =
      newWorkouts as MasterWorkout[];
    const newState: TrainingProgramsState = {
      ...state,
      isSaving: false,
      isDeleting: false,
      isLoading: false,
      cachedPlans: {
        ...cachedPlans,
        [plan.id]: newPlan,
      },
    };
    return newState;
  }
  if (programType === ProgramType.Client) {
    const clientPlan = selectTrainingPlan(state);
    const newPlan = { ...cachedPlans?.[clientPlan?.id] } as ClientTrainingPlan;
    const workoutGroup = newPlan.clientWorkoutGroupTemplates[workoutGroupIndex];
    workoutGroup.clientWorkoutTemplates =
      newWorkouts as ClientWorkoutTemplate[];
    newPlan.clientWorkoutGroupTemplates[workoutGroupIndex] = workoutGroup;

    const newState: TrainingProgramsState = {
      ...state,
      isSaving: false,
      isDeleting: false,
      isLoading: false,
      cachedPlans: {
        ...cachedPlans,
        [clientPlan.id]: newPlan,
      },
    };
    return newState;
  }
  return state;
};

export const replaceWorkoutGroups = ({
  newWorkoutGroups,
  state,
}: {
  newWorkoutGroups: ClientWorkoutGroupTemplate[];
  state: TrainingProgramsState;
}) => {
  const { cachedPlans } = state;
  const clientPlan = selectTrainingPlan(state);
  const newPlan = { ...cachedPlans?.[clientPlan?.id] } as ClientTrainingPlan;
  newPlan.clientWorkoutGroupTemplates = newWorkoutGroups;

  const newState = {
    ...state,
    isSaving: false,
    isDeleting: false,
    isLoading: false,
    cachedPlans: {
      ...cachedPlans,
      [clientPlan.id]: newPlan,
    },
  } as TrainingProgramsState;
  return newState;
};

export const onClickWorkout = (
  state: TrainingProgramsState,
  action: TrainingProgramsAction
) => {
  return {
    ...state,
    isEditWorkout: false,
    showWorkouts: true,
    showExercises: true,
    showWorkoutExerciseList: true,
    showExerciseLibrary: false,
    showSupersetEditor: false,
    supersetId: null,
    showPlans: false,
    workoutIndex: action.index,
  } as TrainingProgramsState;
};
export const onClickWorkoutGroup = (
  state: TrainingProgramsState,
  action: TrainingProgramsAction
) => {
  const plan = state.cachedPlans[
    state.clientPlans[state.clientPlanIndex].id
  ] as ClientTrainingPlan;
  const group =
    plan?.clientWorkoutGroupTemplates?.length > 0
      ? plan?.clientWorkoutGroupTemplates[action.index]
      : null;
  return {
    ...state,
    isEditWorkout: false,
    showWorkouts: true,
    showExercises: group?.clientWorkoutTemplates?.length > 0,
    showWorkoutExerciseList: group?.clientWorkoutTemplates?.length > 0,
    showExerciseLibrary: false,
    showPlans: false,
    workoutGroupIndex: action.index,
    workoutIndex: 0, // Resets the workout to the first one when selecting a new workout group
  } as TrainingProgramsState;
};
export const onClickTrainingPlan = (
  state: TrainingProgramsState,
  action: TrainingProgramsAction
) => {
  return {
    ...state,
    planIndex: action.index,
    showWorkouts: true,
    showExercises: false,
    showExerciseLibrary: false,
    showFilter: false,
    showPlans: state.programType !== ProgramType.Client,
    showCreateNewPlans: false,
  } as TrainingProgramsState;
};
export const onClickClientTrainingPlan = (
  state: TrainingProgramsState,
  action: TrainingProgramsAction
) => {
  return {
    ...state,
    programType: ProgramType.Client,
    clientPlanIndex: action.index,
    workoutGroupIndex: 0,
    workoutIndex: null,
    showWorkouts: true,
    showExercises: false,
    showFilter: false,
    showPlans: false,
    showSupersetEditor: false,
    supersetId: null,
  } as TrainingProgramsState;
};
export const changeTrainingPlanComponentLocation = (
  state: TrainingProgramsState,
  initialState: TrainingProgramsState,
  action: TrainingProgramsAction
) => {
  if (state.componentLocation === action.componentLocation) {
    return {
      ...state,
      showPlans: state.programType !== ProgramType.Client,
      showExercises: false,
      showExerciseLibrary: false,
    };
  }
  if (action.componentLocation === ComponentLocationType.AdminLibrary) {
    return {
      ...initialState,
      showPlans: true,
      showFilter: true,
      programType: ProgramType.Master,
      componentLocation: action.componentLocation,
    } as TrainingProgramsState;
  }
  if (action.componentLocation === ComponentLocationType.ClientTab) {
    return {
      ...initialState,
      programType: ProgramType.Client,
      showWorkouts: true,
      showExerciseLibrary: false,
      componentLocation: action.componentLocation,
    };
  }
  return state;
};
export const toggleEditTrainingPlan = (
  state: TrainingProgramsState,
  action: TrainingProgramsAction
) => {
  const programType = action.programType || state.programType;
  const isClient = programType === ProgramType.Client;
  if (
    ((isClient && state.clientPlanIndex === action.index) ||
      (!isClient && state.planIndex === action.index)) &&
    state.isEditPlan
  ) {
    return {
      ...state,
      isEditPlan: false,
    };
  }
  return {
    ...state,
    planIndex: !isClient ? action.index : null,
    clientPlanIndex: isClient ? action.index : state.clientPlanIndex,
    showWorkouts: true,
    isEditPlan: true,
    showExercises: false,
    showExerciseLibrary: false,
    isEditWorkout: false,
    isEditGymStep: false,
    programType,
  };
};
export const getTrainingPlans = (
  state: TrainingProgramsState,
  action: TrainingProgramsAction
) => {
  const { programType } = action;
  let collections;
  let mergedData;
  let isFirstPage: boolean;
  let result;
  let newPlans: TrainingPlan[];
  if (programType === ProgramType.Custom) {
    result = action.getPlansResult as CaliberPageImpl_CustomTrainingPlan;
    newPlans = result?.content;
    isFirstPage = result.number === 0;
    if (!isFirstPage) {
      collections = [...state.customPlansContent.content, ...newPlans];
      mergedData = _.uniqBy(
        collections as MasterTrainingPlan[],
        (item) => item.id
      );
    } else {
      mergedData = newPlans;
    }
    if (state.newlyCreatedCustomPlan) {
      mergedData = _.uniqBy(
        [state.newlyCreatedCustomPlan, ...newPlans],
        (item) => item.id
      );
    }
    return {
      ...state,
      isLoadingPlans: false,
      workoutIndex: null,
      customPlansContent: {
        ...result,
        content: mergedData,
      },
    };
  }
  if (programType === ProgramType.Library) {
    result = action.getPlansResult as CaliberPageImpl_LibraryTrainingPlan;
    newPlans = result?.content;
    isFirstPage = result.number === 0;
    if (!isFirstPage) {
      collections = [...state.customPlansContent.content, ...newPlans];
      mergedData = _.uniqBy(
        collections as LibraryTrainingPlan[],
        (item) => item.id
      );
    } else {
      mergedData = newPlans;
    }
    if (state.newlyCreatedLibraryPlan) {
      mergedData = _.uniqBy(
        [state.newlyCreatedLibraryPlan, ...newPlans],
        (item) => item.id
      );
    }
    return {
      ...state,
      isLoadingPlans: false,
      workoutIndex: null,
      libraryPlansContent: {
        ...result,
        content: mergedData,
      },
    };
  }
  if (programType === ProgramType.Master) {
    result = action.getPlansResult as CaliberPageImpl_MasterTrainingPlan;
    newPlans = result?.content || [];
    isFirstPage = result.number === 0;
    if (!isFirstPage) {
      collections = [
        ...(state?.masterPlansContent?.content || []),
        ...newPlans,
      ];
      mergedData = _.uniqBy(
        collections as MasterTrainingPlan[],
        (item) => item.id
      );
    } else {
      mergedData = newPlans;
    }
    if (state.newlyCreatedMasterPlan) {
      collections = [state.newlyCreatedMasterPlan, ...newPlans];
      mergedData = _.uniqBy(
        collections as MasterTrainingPlan[],
        (item) => item.id
      );
    }
    return {
      ...state,
      isLoadingPlans: false,
      masterPlansContent: {
        ...result,
        content: mergedData,
      },
    } as TrainingProgramsState;
  }
  if (programType === ProgramType.Client) {
    const clientPlans = action.getPlansResult as ClientTrainingPlan[];
    const activePlanIndex = clientPlans?.findIndex((plan) => plan?.active);
    const planIndex = activePlanIndex === -1 ? 0 : activePlanIndex;

    return {
      ...state,
      isLoadingPlans: false,
      clientPlans,
      clientPlanIndex: clientPlans?.length > 0 ? planIndex : null,
      programType:
        clientPlans?.length > 0 ? ProgramType.Client : ProgramType.Master,
      showPlans: clientPlans?.length === 0,
      showWorkouts: clientPlans?.length !== 0,
    } as TrainingProgramsState;
  }
  return state;
};

export const toggleEditWorkoutGroup = (
  state: TrainingProgramsState,
  action: TrainingProgramsAction
) => {
  if (state.workoutGroupIndex === action.index && state.isEditWorkoutGroup) {
    return {
      ...state,
      isEditWorkoutGroup: false,
    };
  }
  return {
    ...state,
    workoutGroupIndex: action.index,
    isEditWorkoutGroup: true,
  };
};
