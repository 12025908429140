import apolloClient from "../../../api/apolloClient";
import {
  CREATE_UPDATE_CLIENT_HABIT_TARGET,
  DELETE_CLIENT_HABIT_TARGET,
  GET_CLIENT_HABITS_TARGETS,
} from "../../../api/gql/habits";
import {
  ClientHabitTarget,
  MutationCreateUpdateClientHabitTargetArgs,
} from "../../../types/gqlTypes";
import {
  ClientHabitsAction,
  CREATE_UPDATE_CLIENT_HABIT_TARGET_FAIL,
  CREATE_UPDATE_CLIENT_HABIT_TARGET_SAVING,
  CREATE_UPDATE_CLIENT_HABIT_TARGET_SUCCESS,
  DELETE_CLIENT_HABIT_TARGET_FAIL,
  DELETE_CLIENT_HABIT_TARGET_LOADING,
  DELETE_CLIENT_HABIT_TARGET_SUCCESS,
  GET_CLIENT_HABIT_TARGET_FAIL,
  GET_CLIENT_HABIT_TARGET_LOADING,
  GET_CLIENT_HABIT_TARGET_SUCCESS,
} from "./types";

export const createUpdateClientHabitTarget =
  (args: MutationCreateUpdateClientHabitTargetArgs) =>
  async (dispatch, getState) => {
    dispatch({
      type: CREATE_UPDATE_CLIENT_HABIT_TARGET_SAVING,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const result = (
        await client.mutate({
          mutation: CREATE_UPDATE_CLIENT_HABIT_TARGET,
          variables: args,
        })
      ).data.createUpdateClientHabitTarget as ClientHabitTarget;

      dispatch({
        type: CREATE_UPDATE_CLIENT_HABIT_TARGET_SUCCESS,
        data: [result],
      } as ClientHabitsAction);
    } catch (error) {
      console.error("Error createUpdateClientHabitTarget:", error);
      dispatch({
        type: CREATE_UPDATE_CLIENT_HABIT_TARGET_FAIL,
      });
    }
  };
export const getClientHabitsTargets =
  (clientId: string) => async (dispatch, getState) => {
    dispatch({
      type: GET_CLIENT_HABIT_TARGET_LOADING,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const result = (
        await client.query({
          query: GET_CLIENT_HABITS_TARGETS,
          variables: {
            clientId,
          },
        })
      ).data.clientHabitTargets as ClientHabitTarget[];
      dispatch({
        type: GET_CLIENT_HABIT_TARGET_SUCCESS,
        data: result,
      } as ClientHabitsAction);
    } catch (error) {
      console.error("Error getClientHabitTarget:", error);
      dispatch({
        type: GET_CLIENT_HABIT_TARGET_FAIL,
      });
    }
  };
export const deleteClientHabitTarget =
  (clientId: string, habitTargetId: string) => async (dispatch, getState) => {
    dispatch({
      type: DELETE_CLIENT_HABIT_TARGET_LOADING,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      await client.mutate({
        mutation: DELETE_CLIENT_HABIT_TARGET,
        variables: {
          clientId,
          habitTargetId,
        },
      });

      dispatch({
        type: DELETE_CLIENT_HABIT_TARGET_SUCCESS,
        habitTargetId,
      } as ClientHabitsAction);
    } catch (error) {
      console.error("Error getClientHabitTarget:", error);
      dispatch({
        type: DELETE_CLIENT_HABIT_TARGET_FAIL,
      });
    }
  };
export const resetClientHabitTargetState = () => async (dispatch, getState) => {
  dispatch({
    type: DELETE_CLIENT_HABIT_TARGET_LOADING,
  });
};
