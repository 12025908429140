import {
  TaskActionType,
  TasksAction,
  TasksState,
} from "../../actions/Tasks/types";

const initialState: TasksState = {
  isLoading: false,
  isCronRunning: false,
  tasks: null,
};

const tasksState = (state = initialState, action: TasksAction) => {
  switch (action.type) {
    case TaskActionType[TaskActionType.GET_TASKS_LOADING]:
      return {
        ...state,
        isLoading: true,
      } as TasksState;
    case TaskActionType[TaskActionType.GET_TASKS_SUCCESS]:
      return {
        ...state,
        isLoading: false,
        tasks: action.taskList,
      } as TasksState;
    case TaskActionType[TaskActionType.GET_TASKS_FAIL]:
      return {
        ...state,
        isLoading: false,
        tasks: null,
      } as TasksState;
    case TaskActionType[TaskActionType.MARK_COACH_TASK_STATUS_LOADING]:
      return {
        ...state,
        isLoading: true,
      } as TasksState;
    case TaskActionType[TaskActionType.MARK_COACH_TASK_STATUS_SUCCESS]:
      return {
        ...state,
        isLoading: false,
        tasks: state.tasks.map((task) => {
          if (task.id === action.updatedTask.id) {
            return action.updatedTask;
          }
          return task;
        }),
      } as TasksState;
    case TaskActionType[TaskActionType.MARK_COACH_TASK_STATUS_FAIL]:
      return {
        ...state,
        isLoading: false,
        tasks: null,
      } as TasksState;
    case TaskActionType[TaskActionType.ADMIN_RUN_RULES_ENGINE_CRON_LOADING]:
      return {
        ...state,
        isCronRunning: true,
      } as TasksState;
    case TaskActionType[TaskActionType.ADMIN_RUN_RULES_ENGINE_CRON_SUCCESS]:
      return {
        ...state,
        isCronRunning: false,
      } as TasksState;
    case TaskActionType[TaskActionType.ADMIN_RUN_RULES_ENGINE_CRON_FAIL]:
      return {
        ...state,
        isCronRunning: false,
      } as TasksState;
    case TaskActionType[TaskActionType.DELETE_COACH_TASK_LOADING]:
      return {
        ...state,
        isLoading: true,
      } as TasksState;
    case TaskActionType[TaskActionType.DELETE_COACH_TASK_SUCCESS]:
      return {
        ...state,
        isLoading: false,
        tasks: state.tasks.filter((task) => task.id !== action.deletedTaskId),
      } as TasksState;
    case TaskActionType[TaskActionType.DELETE_COACH_TASK_FAIL]:
      return {
        ...state,
        isLoading: false,
      } as TasksState;
    default:
      return state;
  }
};

export default tasksState;
