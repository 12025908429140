import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useDrag, useDrop } from "react-dnd";
import { Link } from "react-router-dom";
import {
  ClientWorkoutGroupTemplate,
  MutationCopyClientWorkoutGroupTemplateArgs,
  MutationUpdateClientWorkoutGroupTemplateArgs,
  UserType,
  WorkoutGroupType,
} from "../../types/gqlTypes";
import WorkoutGroupGymIcon from "../../assets/images/WorkoutGroupGymIcon.svg";
import WorkoutGroupHomeIcon from "../../assets/images/WorkoutGroupHomeIcon.svg";
import WorkoutGroupTravelIcon from "../../assets/images/WorkoutGroupTravelIcon.svg";
import WorkoutGroupProIcon from "../../assets/images/WorkoutGroupProIcon.svg";
import colors from "../../assets/colors";
import {
  selectWorkoutGroupTrainingPlan,
  toggleEditWorkoutGroup,
} from "../../redux/actions/TrainingPrograms";
import { StoreState } from "../../redux/reducers";
import PremiumClientIcon from "../Icons/PremiumClientIcon";
import MeatballsMenu from "../MeatballsMenu";
import { useKeyPress } from "../../utils/customHooks";
import SimpleButton from "../SimpleButton";
import {
  setDefaultWorkoutGroup,
  updateWorkoutGroup,
} from "../../redux/actions/TrainingPrograms/update";
import Dropdown from "../Dropdown";
import DeleteWorkoutGroupDialog from "./DeleteWorkoutGroupDialog";
import { renderWorkoutGroupThumbnailIcon } from "../../utils";
import CopyWorkoutGroupModal from "../CopyWorkoutGroupModal";
import Modal from "../Modal";
import ButtonTag from "../ButtonTag";
import { copyClientWorkoutGroupTemplate } from "../../redux/actions/TrainingPrograms/copy";
import { UserState } from "../../redux/actions/User/types";
import { MasqueradeState } from "../../redux/actions/Masquerade/types";
import { ProgramType } from "../TrainingProgram";

interface CardItem {
  type: string;
  id: string;
  originalIndex: string;
}

interface Props {
  workoutGroup: ClientWorkoutGroupTemplate;
  index?: number;
  selectedWorkoutGroupIndex: number;
  isEditWorkoutGroup: boolean;
  clientId: string;
  selectWorkoutGroup: (index: number) => void;
  toggleEditWorkoutGroup: (index: number) => void;
  updateWorkoutGroup: (
    args: MutationUpdateClientWorkoutGroupTemplateArgs
  ) => void;
  setDefaultWorkoutGroup: (
    clientId: string,
    clientWorkoutGroupTemplateId: string
  ) => void;
  moveWorkoutGroupCard: (id: string, toIndex: number) => void;
  findCardIndex: (id: string) => { index: number };
  saveWorkoutGroupToLibrary: (
    args: MutationCopyClientWorkoutGroupTemplateArgs
  ) => void;
  userState: UserState;
  masqueradeState: MasqueradeState;
}

const WorkoutGroupHeader = (props: Props) => {
  const {
    workoutGroup,
    index,
    selectedWorkoutGroupIndex,
    isEditWorkoutGroup,
    clientId,
    selectWorkoutGroup,
    toggleEditWorkoutGroup,
    updateWorkoutGroup,
    setDefaultWorkoutGroup,
    moveWorkoutGroupCard,
    findCardIndex,
    saveWorkoutGroupToLibrary,
    userState,
    masqueradeState,
  } = props;

  const isMasquerade = !!masqueradeState?.masqueradeTrainer;
  const isAdminOrManager =
    userState?.user?.type === UserType.Admin ||
    userState?.user?.type === UserType.Manager;

  const workoutGroupTypeOptions = [
    { text: "Gym", value: WorkoutGroupType.Gym, image: WorkoutGroupGymIcon },
    { text: "Home", value: WorkoutGroupType.Home, image: WorkoutGroupHomeIcon },
    {
      text: "Travel",
      value: WorkoutGroupType.Travel,
      image: WorkoutGroupTravelIcon,
    },
    { text: "Pro", value: WorkoutGroupType.Pro, image: WorkoutGroupProIcon },
  ];

  const [showMeatball, setShowMeatball] = useState(false);
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] =
    useState(false);

  const [showCopyWorkoutGroupModal, setShowCopyWorkoutGroupModal] =
    useState(false);
  const [
    showCopyWorkoutGroupToClientModal,
    setShowCopyWorkoutGroupToClientModal,
  ] = useState(false);
  const [
    showSaveToLibraryConfirmationModal,
    setShowSaveToLibraryConfirmationModal,
  ] = useState(false);

  const numberOfWorkouts =
    (workoutGroup as ClientWorkoutGroupTemplate)?.clientWorkoutTemplates
      ?.length || 0;

  const meatballsOptions = [
    { text: "Edit", onClick: () => toggleEditWorkoutGroup(index) },
    { text: "Copy", onClick: () => setShowCopyWorkoutGroupModal(true) },
    {
      text: "Copy to Client",
      onClick: () => setShowCopyWorkoutGroupToClientModal(true),
    },
  ];

  const saveWorkoutGroupToLibraryOption = {
    text: "Save to Library",
    onClick: () => {
      // Save to My Workouts
      saveWorkoutGroupToLibrary({
        clientId,
        clientWorkoutGroupTemplateId: workoutGroup.id,
        name: workoutGroup.title,
      });

      // Show confirmation modal
      setShowSaveToLibraryConfirmationModal(true);
    },
  };

  if (!workoutGroup.default) {
    meatballsOptions.push({
      text: "Make default",
      onClick: () => {
        setDefaultWorkoutGroup(clientId, workoutGroup.id);
      },
    });
  }

  if (!isAdminOrManager || isMasquerade) {
    meatballsOptions.push(saveWorkoutGroupToLibraryOption);
  }

  meatballsOptions.push({
    text: "Delete",
    onClick: () => setShowDeleteConfirmationDialog(true),
  });

  const [localName, setLocalName] = useState("");
  const [localType, setLocalType] = useState(
    workoutGroup?.workoutGroupType || null
  );
  const textRef = useRef(null);

  useKeyPress("Escape", () => {
    if (isEditWorkoutGroup) {
      setLocalName?.(workoutGroup.title);
      setLocalType?.(workoutGroup.workoutGroupType);
      toggleEditWorkoutGroup(index);
    }
  });

  useEffect(() => {
    setLocalName(workoutGroup.title);
    setLocalType(workoutGroup.workoutGroupType);
    if (workoutGroup.title === "") {
      toggleEditWorkoutGroup(index);
    }
  }, [workoutGroup]);

  useEffect(() => {
    if (isEditWorkoutGroup) {
      setTimeout(() => {
        if (isEditWorkoutGroup && textRef && textRef.current) {
          // to set focus in the end of the sentence
          textRef.current.focus();
          textRef.current.selectionStart = localName?.length || 0;
          textRef.current.selectionEnd = localName?.length || 0;
        }
      }, 0);
    }
  }, [isEditWorkoutGroup]);

  const originalIndex = findCardIndex(workoutGroup.id);

  const [, dragRef] = useDrag({
    item: {
      type: "workout_group",
      id: workoutGroup.id,
      originalIndex,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: (monitor) => !isEditWorkoutGroup,
    end: (dropResult, monitor) => {
      const { id: droppedId, originalIndex } = monitor.getItem();
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        moveWorkoutGroupCard(droppedId, originalIndex);
      }
    },
  });

  const [, dropRef] = useDrop({
    accept: "workout_group",
    canDrop: () => false,
    hover({ id: draggedId }: CardItem, monitor) {
      if (draggedId !== workoutGroup.id) {
        const card = findCardIndex(workoutGroup.id);
        moveWorkoutGroupCard(draggedId, card.index);
      }
    },
  });

  const onSave = () => {
    if (localName?.length) {
      updateWorkoutGroup({
        clientId,
        clientWorkoutGroupTemplateId: workoutGroup.id,
        title: localName,
        workoutGroupType: localType,
      });
      toggleEditWorkoutGroup(index);
    } else {
      textRef.current.focus();
    }
  };

  const editWorkoutGroupInputField = (
    <div
      className="d-flex justify-content-center"
      style={{
        alignItems: "center",
        width: "100%",
      }}
    >
      <input
        className="heading-normal nofocus"
        ref={textRef}
        value={localName}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onSave();
          }
        }}
        onChange={(event) => setLocalName(event.target.value)}
        style={{
          width: "100%",
          padding: 5,
          resize: "none",
          lineHeight: "20px",
          border: 0,
        }}
      />
      <SimpleButton
        style={{
          padding: "1px 6px",
          borderRadius: 4,
          marginLeft: 6,
          color: localName === "" ? "#808297" : colors.caliber_white,
          backgroundColor: localName === "" ? "#e6e6ea" : colors.caliber_purple,
          height: 24,
        }}
        className="d-flex align-items-center pointer paragraph-normal nofocus"
        onClick={onSave}
        disabled={localName === ""}
      >
        Save
      </SimpleButton>
    </div>
  );

  const renderShowSaveToLibraryConfirmationModal = (
    <Modal onClose={() => {}}>
      <div
        className="d-flex flex-column"
        style={{
          // minHeight: 250,
          width: 520,
          padding: 25,
        }}
      >
        <div
          className="d-flex flex-column justify-content-center align-items-center medium-bold"
          style={{
            color: colors.caliber_secondary,
            fontSize: "18px",
            lineHeight: "24px",
          }}
        >
          {workoutGroup.title} was successfully copied!
        </div>
        <div
          className="d-flex justify-content-center"
          style={{
            marginTop: 40,
          }}
        >
          {/* Link to My Workout Groups */}
          <Link to="/myworkoutgroups">
            <ButtonTag
              text="View"
              height={32}
              bgColor={colors.caliber_gray_text}
              textColor={colors.caliber_white}
              minWidth={80}
              margin="0px 12px"
              onClick={() => setShowSaveToLibraryConfirmationModal(false)}
            />
          </Link>

          <ButtonTag
            text="Got it"
            onClick={() => setShowSaveToLibraryConfirmationModal(false)}
            height={32}
            bgColor={colors.caliber_green_200}
            textColor={colors.caliber_white}
            minWidth={80}
          />
        </div>
      </div>
    </Modal>
  );

  return (
    <div>
      {showSaveToLibraryConfirmationModal &&
        renderShowSaveToLibraryConfirmationModal}

      {showDeleteConfirmationDialog && (
        <DeleteWorkoutGroupDialog
          show={showDeleteConfirmationDialog}
          workoutGroupId={workoutGroup.id}
          clientId={clientId}
          onClose={() => {
            setShowDeleteConfirmationDialog(false);
          }}
        />
      )}

      {showCopyWorkoutGroupModal && (
        <CopyWorkoutGroupModal
          selectedClientId={clientId}
          workoutGroup={workoutGroup}
          onClose={() => {
            setShowCopyWorkoutGroupModal(false);
          }}
          title={workoutGroup.title}
          sourcePlanType={ProgramType.Client}
        />
      )}

      {showCopyWorkoutGroupToClientModal && (
        // @ts-ignore
        <CopyWorkoutGroupModal
          workoutGroup={workoutGroup}
          onClose={() => {
            setShowCopyWorkoutGroupToClientModal(false);
          }}
          title={workoutGroup.title}
          sourcePlanType={ProgramType.Client}
        />
      )}
      <div
        ref={(node) => dragRef(dropRef(node))}
        role="button"
        tabIndex={0}
        className="d-flex flex-column flex-nowrap nofocus"
        style={{
          margin: "12px 0px",
          minHeight: 48,
          borderRadius: 12,
          backgroundColor:
            isEditWorkoutGroup && selectedWorkoutGroupIndex === index
              ? colors.caliber_white
              : colors.caliber_gray_11,
          opacity: 1,
          cursor: !showCopyWorkoutGroupModal && "pointer",
        }}
        onClick={() => {
          selectWorkoutGroup?.(index);
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            selectWorkoutGroup?.(index);
          }
        }}
        onMouseEnter={() => {
          setShowMeatball(true);
        }}
        onMouseLeave={() => {
          setShowMeatball(false);
        }}
      >
        <div
          className="d-flex"
          style={{
            justifyContent: "space-between",
          }}
        >
          <div
            className="d-flex"
            style={{
              flex: 1,
            }}
          >
            {isEditWorkoutGroup && selectedWorkoutGroupIndex == index && (
              <div
                style={{
                  padding: 5,
                  width: 130,
                }}
              >
                <Dropdown
                  height={36}
                  value={localType}
                  items={workoutGroupTypeOptions}
                  onSelect={(value) => setLocalType(value)}
                  textColor={colors.caliber_gray_text}
                  bgColor={colors.caliber_light_gray}
                  borderColor={colors.caliber_light_gray}
                />
              </div>
            )}
            {(!isEditWorkoutGroup ||
              (isEditWorkoutGroup && selectedWorkoutGroupIndex != index)) && (
              <div
                style={{
                  width: 32,
                  height: 32,
                  margin: 8,
                  marginRight: 2,
                  borderRadius: 12,
                }}
              >
                <div
                  style={{
                    position: "relative",
                    width: 32,
                    height: 32,
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      bottom: -6,
                      right: -6,
                    }}
                  >
                    {workoutGroup.default && (
                      <PremiumClientIcon
                        width={16}
                        height={16}
                        bgColor={colors.caliber_white}
                      />
                    )}
                  </div>

                  <img
                    style={{
                      width: 32,
                      height: 32,
                      borderRadius: 8,
                    }}
                    src={renderWorkoutGroupThumbnailIcon(
                      workoutGroup.workoutGroupType
                    )}
                    alt="Thumbnail"
                  />
                </div>
              </div>
            )}
            <div
              className="d-flex justify-content-between align-items-center"
              style={{
                flex: 1,
                marginLeft: 10,
                marginRight: 12,
              }}
            >
              <div
                className="d-flex align-items-center"
                style={{
                  width: "100%",
                }}
              >
                <div
                  className="bold"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    margin: 0,
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                >
                  {isEditWorkoutGroup && selectedWorkoutGroupIndex == index
                    ? editWorkoutGroupInputField
                    : localName}
                </div>
                {(!isEditWorkoutGroup ||
                  (isEditWorkoutGroup && selectedWorkoutGroupIndex != index)) &&
                  numberOfWorkouts > 0 && (
                    <div
                      className="d-flex align-items-center"
                      style={{
                        height: 24,
                        borderRadius: 8,
                        backgroundColor: colors.caliber_purple_200,
                        fontSize: "14px",
                        lineHeight: "16px",
                        color: colors.caliber_blue_200,
                        marginRight: 12,
                        marginLeft: 10,
                        minWidth: 100,
                        justifyContent: "center",
                      }}
                    >
                      <div className="d-flex align-items-center bold">
                        {`${numberOfWorkouts} Workout${
                          numberOfWorkouts === 1 ? "" : "s"
                        }`}
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>

          <div
            className="d-flex"
            style={{
              alignItems: "center",
            }}
          >
            {showMeatball && !isEditWorkoutGroup && (
              <div className="d-flex align-items-center">
                <div
                  className="d-flex align-items-center"
                  style={{
                    // position: 'absolute',
                    flex: 1,
                    justifyContent: "end",
                    right: 20,
                  }}
                >
                  <MeatballsMenu items={meatballsOptions} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  selectWorkoutGroup: (index: number) => {
    dispatch(selectWorkoutGroupTrainingPlan(index));
  },
  toggleEditWorkoutGroup: (index: number) => {
    dispatch(toggleEditWorkoutGroup(index));
  },
  updateWorkoutGroup: (args: MutationUpdateClientWorkoutGroupTemplateArgs) => {
    dispatch(updateWorkoutGroup(args));
  },
  setDefaultWorkoutGroup: (
    clientId: string,
    clientWorkoutGroupTemplateId: string
  ) => {
    dispatch(setDefaultWorkoutGroup(clientId, clientWorkoutGroupTemplateId));
  },
  saveWorkoutGroupToLibrary: (
    args: MutationCopyClientWorkoutGroupTemplateArgs
  ) => {
    dispatch(copyClientWorkoutGroupTemplate(args));
  },
});

const mapStateToProps = (state: StoreState, props: Props) => ({
  selectedWorkoutGroupIndex: state.trainingProgramsState.workoutGroupIndex,
  isEditWorkoutGroup: state.trainingProgramsState.isEditWorkoutGroup,
  clientId: state.clientDetailState.user.id,
  userState: state.authenticatedUserState,
  masqueradeState: state.masqueradeState,
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkoutGroupHeader);
