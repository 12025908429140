import React, { useState } from "react";
import colors from "../../assets/colors";
import { GenderType, StrengthScore } from "../../types/gqlTypes";
import SimpleButton from "../SimpleButton";
import Skeleton from "../Skeleton";

interface Props {
  muscleGroups: StrengthScore[];
  gender: GenderType;
}

const GradientRect = (
  <svg
    width="70"
    height="16"
    viewBox="0 0 70 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="70"
      width="16"
      height="70"
      rx="4"
      transform="rotate(90 70 0)"
      fill="url(#paint0_linear123)"
    />
    <defs>
      <linearGradient
        id="paint0_linear123"
        x1="78"
        y1="0"
        x2="78"
        y2="70"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#272E5F" />
        <stop offset="0.23" stopColor="#52587F" />
        <stop offset="0.45" stopColor="#A9ABBF" />
        <stop offset="0.7" stopColor="#D4D5DF" />
        <stop offset="1" stopColor="#EDEDED" />
      </linearGradient>
    </defs>
  </svg>
);

const StrengthSkeleton = (props: Props) => {
  const { gender, muscleGroups } = props;

  const [skeletonView, setSkeletonView] = useState<"front" | "back">("front");
  const togglerHeight = 22;
  const togglerWidth = 84;

  const togglerStyle: React.CSSProperties = {
    height: togglerHeight,
    width: togglerWidth,
    backgroundColor: colors.caliber_secondary_gray_11,
    borderRadius: 8,
    padding: 1,
    marginBottom: 16,
  };
  const inactiveTogglerStyle: React.CSSProperties = {
    fontSize: "10px",
    color: colors.caliber_secondary_gray_38,
    letterSpacing: "-0.033em",
    width: togglerWidth / 2,
    height: togglerHeight - 2,
  };
  const activeTogglerStyle: React.CSSProperties = {
    fontSize: "10px",
    color: colors.caliber_secondary_gray_47,
    backgroundColor: "#F5F7F9",
    letterSpacing: "-0.033em",
    width: togglerWidth / 2,
    height: togglerHeight - 2,
    borderRadius: 8,
  };
  const textStyle: React.CSSProperties = {
    width: 70,
    height: 12,
    color: colors.caliber_secondary_gray_47,
    letterSpacing: "-0.022em",
    fontSize: "11px",
    lineHeight: "12px",
  };
  const togglerClassname =
    "d-flex justify-content-center align-items-center bold nofocus";

  return (
    <div
      className="d-flex flex-column align-items-center"
      style={{
        width: 286,
        minWidth: 286,
        maxWidth: 286,
        flex: 1,
      }}
    >
      <div className={togglerClassname} style={togglerStyle}>
        <SimpleButton
          onClick={() => setSkeletonView("back")}
          style={
            skeletonView === "back" ? activeTogglerStyle : inactiveTogglerStyle
          }
          className={togglerClassname}
        >
          Back
        </SimpleButton>
        <SimpleButton
          onClick={() => setSkeletonView("front")}
          style={
            skeletonView === "front" ? activeTogglerStyle : inactiveTogglerStyle
          }
          className={togglerClassname}
        >
          Front
        </SimpleButton>
      </div>
      <Skeleton
        gender={gender}
        muscleGroups={muscleGroups}
        height={260}
        view={skeletonView}
      />

      <div className="d-flex align-items-center" style={{ marginTop: 15 }}>
        <div className="d-flex flex-column">
          <div style={textStyle} className="d-flex justify-content-end">
            UNDER
          </div>
          <div style={textStyle} className="d-flex justify-content-end">
            DEVELOPED
          </div>
        </div>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            width: 70,
            height: 24,
            margin: "0px 12px",
          }}
        >
          {GradientRect}
        </div>
        <div className="d-flex flex-column">
          <div style={textStyle} className="d-flex">
            OVER
          </div>
          <div style={textStyle} className="d-flex">
            DEVELOPED
          </div>
        </div>
      </div>
    </div>
  );
};

export default StrengthSkeleton;
