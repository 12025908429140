const colors = {
  black: "#000000",
  caliber_blue: "#000632",
  caliber_red: "#ff1400",
  caliber_silver: "#adaeaf",
  caliber_oat: "#f2f0e3",

  caliber_primary: "#F73843",
  caliber_secondary: "#01052f",
  caliber_white: "#FFFFFF",
  caliber_blue_200: "#3446FB",
  caliber_blue_100: "#CACFFE",
  caliber_green_200: "#1FC866",
  caliber_green_100: "#D4F0D3",
  caliber_gold_200: "#FFCC32",
  caliber_gold_100: "#FFF1AD",
  caliber_red_300: "#F73843",
  caliber_red_200: "#F15C4E",
  caliber_red_100: "#F9DFDF",

  caliber_calendar_item_red: "#FF1400",
  caliber_calendar_item_green: "#36890F",
  caliber_calendar_item_blue: "#5B64A0",

  wireframe_purple: "#CED0E5",
  wireframe_grey: "#AAB2B7",
  wireframe_grey_2: "#969696",
  wireframe_grey_4: "#979DA3",
  wireframe_grey_5: "#E5E5E5",
  // More Wires
  wire_line_grey: "#e6e7eb",
  nav_item_grey: "#F8F9FA",
  transparent: "#00000000",

  caliber_gray_bg: "#F2F3F5",
  caliber_gray_text: "#808297",
  caliber_gray_border: "#C2C3CD",
  caliber_light_gray: "#F3F3F5",
  caliber_secondary_gray_1: "#DADBE1",
  caliber_secondary_gray_5: "#E8EAEE",
  caliber_secondary_gray_11: "#DADCE3",
  caliber_secondary_gray_29: "#AEB1BF",
  caliber_secondary_gray_38: "#989BAD",
  caliber_secondary_gray_47: "#82869B",
  caliber_dark_gray: "#C3C3CE",
  caliber_gray_2: "#EDEEF1",
  caliber_gray_4: "#E7E9ED",
  caliber_gray_5: "#E5E6EA",
  caliber_gray_11: "#D7D9DF",
  caliber_gray_12: "#AEB1BF",
  caliber_gray_47: "#82869B",
  caliber_purple: "#6673F3",
  caliber_purple_200: "rgba(52, 70, 251, 0.2)",
  caliber_light_blue: "#5B64A0",
  caliber_light_blue_20: "#DEE0EC",
  caliber_placeholder_color: "#828283",
  caliber_error_color: "#F73843",

  table_header_gray: "#EEF1F5",
  table_border_gray: "#E6EBF0",
  table_gray: "#F2F2F2",
  table_blue: "#E3EFFD",
};

export default colors;
