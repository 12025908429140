import React, { useState } from "react";
import { connect } from "react-redux";
import { StoreState } from "../../redux/reducers";
import {
  StrengthScoreDashboard,
  StrengthScoreMuscleGroupType,
} from "../../types/gqlTypes";
import DetailsPanel from "./DetailsPanel";
import StrengthScoreMuscleDetails from "./StrengthScoreMuscleDetails";

interface Props {
  strengthScoreDashboardChanges: StrengthScoreDashboard[];
}

const StrengthScoreDetails = (props: Props) => {
  const { strengthScoreDashboardChanges } = props;

  const [selectedMuscle, setSelectedMuscle] =
    useState<StrengthScoreMuscleGroupType>(null);

  return (
    <div
      className="d-flex"
      style={{
        padding: "0px 12px",
      }}
    >
      <div
        style={{
          width: 376,
          flex: 0,
        }}
      >
        <DetailsPanel
          selectMuscle={(muscle) => setSelectedMuscle(muscle)}
          strengthScoreDashboardChanges={strengthScoreDashboardChanges}
        />
      </div>
      <div
        style={{
          flex: 1,
        }}
      >
        <StrengthScoreMuscleDetails
          onClose={() => setSelectedMuscle(null)}
          muscleType={selectedMuscle}
        />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state: StoreState) => ({
  strengthScoreDashboardChanges:
    state.clientStrengthDashboardState.strengthScoreDashboardChanges,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StrengthScoreDetails);
