import React, { useState } from "react";
import colors from "../../assets/colors";
import CrossedEyeIcon from "../../assets/images/CrossedEyeIcon.svg";
import ErrorIcon from "../../assets/images/ErrorIcon.svg";
import EyeIcon from "../../assets/images/EyeIcon.svg";
import "./index.css";

interface Props {
  value?: string;
  height?: number;
  placeholder?: string;
  error?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  bgColor?: string;
  borderBgColor?: string;
  color?: string;
  margin?: string | number;
  type?: InputTypes;
  label?: string;
  readOnly?: boolean;
  style?: React.CSSProperties;
  onChange: (value: string) => void;
}
export enum InputTypes {
  Text = "text",
  Password = "password",
  Dropdown = "dropdown",
}

const Input = (props: Props) => {
  const {
    value,
    height,
    bgColor,
    color,
    error,
    errorMessage,
    disabled,
    placeholder,
    margin,
    type,
    label,
    borderBgColor,
    readOnly,
    style,
    onChange,
  } = props;

  const [eye, setEye] = useState(false);

  return (
    <div
      tabIndex={-1}
      className="d-flex flex-column"
      style={{
        flex: 1,
        margin: margin || "8px 0px",
        position: "relative",
      }}
    >
      <div
        className="d-flex heading-xsmall"
        style={{
          flex: 1,
          marginLeft: 5,
        }}
      >
        {label}
      </div>
      <input
        readOnly={readOnly}
        className={`nofocus bordered-item ${
          readOnly ? "paragraph-large" : "heading-small"
        }`}
        style={{
          opacity: disabled ? 0.5 : 1,
          color: color || colors.caliber_secondary,
          border: `2px solid ${
            error
              ? colors.caliber_error_color
              : borderBgColor || colors.caliber_gray_5
          }`,
          backgroundColor: bgColor || colors.caliber_gray_5,
          borderRadius: 8,
          height: height || 48,
          paddingLeft: 20,
          ...(style && { ...style }),
        }}
        type={eye ? InputTypes.Text : type || InputTypes.Text}
        value={value}
        onChange={(event) => !disabled && onChange(event.target.value)}
        placeholder={placeholder}
      />
      {type === InputTypes.Password && (
        <div
          role="button"
          tabIndex={0}
          onClick={() => setEye(!eye)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              setEye(!eye);
            }
          }}
          className="d-flex justify-content-center align-items-center"
          style={{
            outline: "none",
            height: height || 48,
            position: "absolute",
            top: label ? 24 : 0,
            right: 15,
          }}
        >
          <img
            style={{
              width: 16,
              height: 16,
            }}
            alt="eye"
            src={eye ? CrossedEyeIcon : EyeIcon}
          />
        </div>
      )}
      {error && (
        <div
          className="d-flex paragraph-small align-items-center"
          style={{
            flex: 1,
            color: colors.caliber_error_color,
          }}
        >
          <img
            style={{
              width: 16,
              height: 16,
              marginRight: 5,
            }}
            alt="Error"
            src={ErrorIcon}
          />
          {errorMessage}
        </div>
      )}
    </div>
  );
};
export default Input;
