import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import colors from "../../assets/colors";
import { onCloseNewOrEditMasterProgram } from "../../redux/actions/TrainingPrograms";
import { createTrainingPlan } from "../../redux/actions/TrainingPrograms/createAssign";
import { updateTrainingPlan } from "../../redux/actions/TrainingPrograms/update";
import { StoreState } from "../../redux/reducers";
import {
  MasterTrainingPlan,
  MutationCreateMasterTrainingPlanArgs,
  MutationUpdateMasterTrainingPlanArgs,
  QuerySearchMasterTrainingPlansArgs,
} from "../../types/gqlTypes";
import ButtonTag from "../ButtonTag";
import PlanCard from "../PlanCard";
import PlanFilter, { filterItems } from "../PlanFilter";

interface OwnProps {
  planToEdit?: MasterTrainingPlan;
  isEdit?: boolean;
}
interface Props extends OwnProps {
  closeNewProgramSection: () => void;
  createPlan: (args: MutationCreateMasterTrainingPlanArgs) => void;
  updatePlan: (args: MutationUpdateMasterTrainingPlanArgs) => void;
}

const AdminNewTrainingProgram = (props: Props) => {
  const { closeNewProgramSection, createPlan, updatePlan, planToEdit, isEdit } =
    props;

  const numberOfAttributes = 7;
  const [filterState, setFilterState] = useState(
    {} as QuerySearchMasterTrainingPlansArgs
  );
  const [plan, setPlan] = useState({} as MasterTrainingPlan);

  useEffect(() => {
    if (planToEdit && isEdit) {
      const newFilterState = {} as QuerySearchMasterTrainingPlansArgs;
      filterItems.forEach((item) => {
        newFilterState[item.type] = [planToEdit[item.tag]];
      });
      setFilterState(newFilterState);
      setPlan(planToEdit);
    }
  }, [isEdit, planToEdit]);

  const onChangeFilterState = (props) => {
    const { tag, type, name, isSelected } = props;
    const newFilterState = { ...filterState };
    const newPlan = { ...plan };
    if (isSelected) {
      delete newPlan[tag];
      delete newFilterState[type];
    } else {
      newPlan[tag] = name;
      newFilterState[type] = [name];
    }
    setFilterState(newFilterState);
    setPlan(newPlan);
  };

  const onSave = () => {
    const args = {
      duration: plan.duration,
      gender: plan.gender,
      planVersion: plan.planVersion,
      program: plan.program,
      repRange: plan.repRange,
      frequency: plan.frequency,
      type: plan.type,
    };
    if (isEdit) {
      updatePlan({
        masterTrainingPlanId: planToEdit.id,
        ...args,
      });
    } else {
      createPlan(args);
    }
  };

  return (
    <div
      className="d-flex flex-column margin-13-12"
      style={{
        flex: 1,
      }}
    >
      <div className="d-flex justify-content-end">
        <ButtonTag
          minWidth={150}
          margin={8}
          text="Cancel"
          onClick={closeNewProgramSection}
          textColor={colors.caliber_white}
          bgColor={colors.caliber_gray_text}
        />
        <ButtonTag
          onClick={onSave}
          disabled={numberOfAttributes > Object.keys(plan).length}
          minWidth={150}
          margin={8}
          text="Save Program"
          textColor={colors.caliber_white}
          bgColor={colors.caliber_green_200}
        />
      </div>
      <div
        className="d-flex flex-column"
        style={{
          flex: 1,
          width: "60%",
          marginBottom: 60,
          marginLeft: 10,
        }}
      >
        <div
          className="heading-small"
          style={{
            marginBottom: 19,
          }}
        >
          New Training Program
        </div>
        <PlanCard
          plan={plan}
          bgColor={colors.caliber_white}
          selectedButtonBgColor={colors.caliber_gray_bg}
          filterState={filterState}
        />
        <div
          style={{
            marginTop: 50,
          }}
        >
          <PlanFilter
            filterState={filterState}
            onChangeFilterState={onChangeFilterState}
          />
        </div>
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  closeNewProgramSection: () => {
    dispatch(onCloseNewOrEditMasterProgram());
  },
  createPlan: (args: MutationCreateMasterTrainingPlanArgs) => {
    dispatch(createTrainingPlan(args));
  },
  updatePlan: (args: MutationUpdateMasterTrainingPlanArgs) => {
    dispatch(updateTrainingPlan(args));
  },
});

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  planToEdit: ownProps.planToEdit,
  isEdit: ownProps.isEdit,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminNewTrainingProgram);
