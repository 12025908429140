import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import colors from "../../assets/colors";
import { frequencyDisplayText } from "../../models/tags";
import {
  onClickNewOrEditMasterProgram,
  toggleEditTrainingPlan,
} from "../../redux/actions/TrainingPrograms";
import { addWorkoutToPlan } from "../../redux/actions/TrainingPrograms/add";
import { toggleCopyTrainingPlanToClients } from "../../redux/actions/TrainingPrograms/copy";
import {
  cloneCustomTrainingPlan,
  cloneMasterTrainingPlan,
} from "../../redux/actions/TrainingPrograms/createAssign";
import { deleteTrainingPlan } from "../../redux/actions/TrainingPrograms/delete";
import { updateTrainingPlan } from "../../redux/actions/TrainingPrograms/update";
import {
  getIsAdmin,
  getIsMasquerade,
  getTrainerId,
  StoreState,
} from "../../redux/reducers";
import {
  selectFullClientPlan,
  selectFullMasterOrCustomPlan,
} from "../../redux/reducers/TrainingPrograms/selectors";
import {
  Client,
  ClientTrainingPlan,
  CustomTrainingPlan,
  LibraryTrainingPlan,
  MasterTrainingPlan,
  MutationUpdateClientTrainingPlanArgs,
  MutationUpdateCustomTrainingPlanArgs,
  MutationUpdateLibraryTrainingPlanArgs,
  MutationUpdateMasterTrainingPlanArgs,
  Trainer,
  TrainingPlan,
} from "../../types/gqlTypes";
import { useKeyPress } from "../../utils/customHooks";
import ConfirmationDialog from "../ConfirmationDialog";
import MeatballsMenu, { MeatballItem } from "../MeatballsMenu";
import SimpleButton from "../SimpleButton";
import { ComponentLocationType, ProgramType } from "../TrainingProgram";
import NewPlanHeader from "./NewPlanHeader";
import AddWorkoutGroupModal from "../WorkoutGroupList/AddWorkoutGroupModal";
import CopyWorkoutGroupModal from "../CopyWorkoutGroupModal";
import { useLocation } from "react-router-dom";

interface Props {
  plan:
    | MasterTrainingPlan
    | CustomTrainingPlan
    | TrainingPlan
    | LibraryTrainingPlan;
  clientPlan: ClientTrainingPlan;
  programType: ProgramType;
  componentLocation: ComponentLocationType;
  clientPlanIndex: number;
  planIndex: number;
  isEditPlan: boolean;
  isMasquerade: boolean;
  isAdmin: boolean;
  canEditClientInfo: boolean;
  isLoading: boolean;
  showCreateNewPlans: boolean;
  selectedUser: Client | Trainer;
  destinationTrainerId: string;

  deletePlan: (args: { id: string }) => void;
  toggleEditTrainingPlan: (index: number) => void;
  updateTrainingPlan: (
    args:
      | MutationUpdateClientTrainingPlanArgs
      | MutationUpdateCustomTrainingPlanArgs
  ) => void;
  toggleCopyTrainingPlanToClients: (index: number) => void;
  onClickNewOrEditMasterProgram: (index: number) => void;
  cloneMasterTrainingPlan: (trainingPlanId: string) => void;
  cloneCustomTrainingPlan: (trainingPlanId: string) => void;
}

const PlanHeader = (props: Props) => {
  const {
    programType,
    plan,
    componentLocation,
    clientPlanIndex,
    planIndex,
    isEditPlan,
    clientPlan,
    isMasquerade,
    isAdmin,
    showCreateNewPlans,
    selectedUser,
    destinationTrainerId,
    deletePlan,
    toggleEditTrainingPlan,
    updateTrainingPlan,
    toggleCopyTrainingPlanToClients,
    onClickNewOrEditMasterProgram,
    cloneMasterTrainingPlan,
    cloneCustomTrainingPlan,
  } = props;

  const actualPlan = programType === ProgramType.Client ? clientPlan : plan;
  let canBeAssigned = componentLocation === ComponentLocationType.ClientTab;
  let canBeChanged = true;
  let isPlanDeleteable = true;
  const pathName = useLocation().pathname;

  if (programType === ProgramType.Master) {
    // only admins with no Masquerade and in Program Library component
    canBeChanged =
      isAdmin &&
      !isMasquerade &&
      componentLocation === ComponentLocationType.AdminLibrary;
    isPlanDeleteable = canBeChanged;
  }
  if (programType === ProgramType.Client) {
    isPlanDeleteable = !clientPlan?.active;
    canBeAssigned = false;
  }

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [localName, setLocalName] = useState("");
  const [height, setHeight] = useState(35);
  const textRef = useRef(null);
  const [isAddWorkoutGroupModalOpen, setIsAddWorkoutGroupModalOpen] =
    useState(false);
  const [
    showCopyWorkoutGroupToClientModal,
    setShowCopyWorkoutGroupToClientModal,
  ] = useState<boolean>(false);

  const isClientPlan = programType === ProgramType.Client;
  const actualPlanIndex = isClientPlan ? clientPlanIndex : planIndex;

  useKeyPress("Escape", () => {
    if (isEditPlan) {
      setLocalName?.(getPlanName(actualPlan) || "");
      toggleEditTrainingPlan(actualPlanIndex);
    }
  });

  const getPlanName = (plan) => {
    let name;
    if (programType === ProgramType.Master) {
      const days = frequencyDisplayText(plan?.frequency) || "\u00a0 days";
      name = `${days} training program`;
    } else if (programType === ProgramType.Custom) {
      name = (plan as CustomTrainingPlan)?.name;
    } else if (programType === ProgramType.Library) {
      name = (plan as LibraryTrainingPlan)?.name;
    } else if (programType === ProgramType.Client) {
      name = clientPlan?.name;
    }
    return name;
  };
  useEffect(() => {
    setLocalName(getPlanName(actualPlan) || "");
  }, [actualPlan]);

  useEffect(() => {
    if (isEditPlan) {
      setHeight(textRef?.current?.scrollHeight + 2);
      setTimeout(() => {
        if (isEditPlan && textRef && textRef.current) {
          // to set focus in the end of the sentence
          textRef.current.focus();
          textRef.current.selectionStart = localName?.length || 0;
          textRef.current.selectionEnd = localName?.length || 0;
        }
      }, 0);
    }
  }, [isEditPlan]);

  useEffect(() => {
    if (
      programType === ProgramType.Client &&
      clientPlan &&
      clientPlan.name === ""
    ) {
      onEdit();
    }
  }, [actualPlan]);

  if (showCreateNewPlans) {
    return <NewPlanHeader />;
  }

  const onSave = () => {
    if (localName?.length) {
      if (programType === ProgramType.Client) {
        updateTrainingPlan({
          clientTrainingPlanId: clientPlan.id,
          name: localName,
          clientId: selectedUser.id,
        } as MutationUpdateClientTrainingPlanArgs);
      } else if (programType === ProgramType.Custom) {
        const customPlan = plan as CustomTrainingPlan;
        updateTrainingPlan({
          customTrainingPlanId: customPlan.id,
          name: localName,
          trainerId: destinationTrainerId,
        } as MutationUpdateCustomTrainingPlanArgs);
      } else if (programType === ProgramType.Library) {
        const libraryPlan = plan as LibraryTrainingPlan;
        updateTrainingPlan({
          libraryTrainingPlanId: libraryPlan.id,
          name: localName,
          trainerId: destinationTrainerId,
        } as MutationUpdateLibraryTrainingPlanArgs);
      }
      toggleEditTrainingPlan(actualPlanIndex);
    } else {
      textRef.current.focus();
    }
  };

  const onDeletePlan = () => {
    if (programType === ProgramType.Client) {
      deletePlan({
        id: clientPlan.id,
      });
    } else {
      deletePlan({
        id: plan.id,
      });
    }
  };

  const editInput = (
    <input
      className="heading-normal nofocus"
      ref={textRef}
      value={localName}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onSave();
        }
      }}
      onChange={(event) => setLocalName(event.target.value)}
      style={{
        height,
        borderRadius: 8,
        border: `1px solid ${colors.caliber_gray_border}`,
        width: "100%",
        padding: 5,
        resize: "none",
        lineHeight: "20px",
      }}
      placeholder="New Training Plan"
    />
  );

  const onEdit = () => {
    if (programType === ProgramType.Master) {
      onClickNewOrEditMasterProgram(actualPlanIndex);
    } else {
      toggleEditTrainingPlan(actualPlanIndex);
    }
  };

  const meatballsOptions: MeatballItem[] = [{ text: "Edit", onClick: onEdit }];
  if (canBeAssigned) {
    meatballsOptions.push({
      text: "Add to training plan",
      onClick: () => {
        setIsAddWorkoutGroupModalOpen(true);
      },
    });
  }
  if (programType === ProgramType.Client) {
    meatballsOptions.push({
      text: "Copy to clients",
      onClick: () => toggleCopyTrainingPlanToClients(actualPlanIndex),
    });
  }
  if (programType === ProgramType.Custom && pathName !== "/proworkoutgroups") {
    meatballsOptions.push({
      text: "Copy to client",
      onClick: () => setShowCopyWorkoutGroupToClientModal(true),
    });
  }
  if (programType === ProgramType.Master && canBeChanged) {
    meatballsOptions.push({
      text: "Clone",
      onClick: () => cloneMasterTrainingPlan(plan?.id),
    });
  }
  if (programType === ProgramType.Custom && pathName === "/proworkoutgroups") {
    meatballsOptions.push({
      text: "Clone",
      onClick: () => cloneCustomTrainingPlan(plan?.id),
    });
  }
  if (isPlanDeleteable) {
    meatballsOptions.push({
      text: "Delete",
      onClick: () => setConfirmDialog(true),
    });
  }

  return (
    <div
      className="d-flex justify-content-between"
      style={{
        flex: "0 0 96px",
        padding: "8px 20px",
        borderRadius: 8,
        backgroundColor: colors.caliber_white,
      }}
    >
      {confirmDialog && (
        <ConfirmationDialog
          show
          cancelButtonText="Cancel"
          text={`Do you really want to delete ${getPlanName(plan)}?`}
          onCancel={() => setConfirmDialog(false)}
          onConfirm={() => {
            setConfirmDialog(false);
            onDeletePlan();
          }}
        />
      )}
      {isAddWorkoutGroupModalOpen && (
        <AddWorkoutGroupModal
          onClose={() => {
            setIsAddWorkoutGroupModalOpen(false);
          }}
          masterWorkoutGroupsId={
            (plan as MasterTrainingPlan).masterWorkoutGroups[0].id
          }
        />
      )}
      {showCopyWorkoutGroupToClientModal &&
        programType === ProgramType.Custom && (
          // @ts-ignore
          <CopyWorkoutGroupModal
            workoutGroup={(plan as CustomTrainingPlan).masterWorkoutGroups[0]}
            onClose={() => {
              setShowCopyWorkoutGroupToClientModal(false);
            }}
            title={(plan as CustomTrainingPlan).name}
            sourcePlanType={ProgramType.Custom}
          />
        )}
      <div
        className="align-self-center bold"
        style={{
          flex: 1,
          lineHeight: "24px",
          fontSize: "20px",
        }}
      >
        {isEditPlan ? editInput : localName}
      </div>
      <div className="d-flex flex-column justify-content-center">
        {canBeChanged && !isEditPlan && (
          <MeatballsMenu items={meatballsOptions} />
        )}

        {canBeAssigned && !isEditPlan && programType === ProgramType.Master && (
          <SimpleButton
            style={{
              padding: "1px 8px",
              borderRadius: 4,
              color: colors.caliber_green_200,
              backgroundColor: colors.caliber_green_100,
            }}
            className="d-flex align-items-center pointer paragraph-normal nofocus"
            onClick={() => {
              setIsAddWorkoutGroupModalOpen(true);
            }}
          >
            Add to training plan
          </SimpleButton>
        )}

        {isEditPlan && (
          <SimpleButton
            style={{
              padding: "1px 6px",
              borderRadius: 4,
              marginLeft: 6,
              color: colors.caliber_white,
              backgroundColor: colors.caliber_purple,
            }}
            className="d-flex align-items-center pointer paragraph-normal nofocus"
            onClick={onSave}
          >
            Save
          </SimpleButton>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addNewWorkout: (selectedWorkoutGroupIndex: number) => {
    dispatch(addWorkoutToPlan(selectedWorkoutGroupIndex));
  },
  deletePlan: (args: { id: string }) => {
    dispatch(deleteTrainingPlan(args));
  },
  toggleEditTrainingPlan: (index: number) => {
    dispatch(toggleEditTrainingPlan(index));
  },
  updateTrainingPlan: (
    args:
      | MutationUpdateClientTrainingPlanArgs
      | MutationUpdateCustomTrainingPlanArgs
      | MutationUpdateMasterTrainingPlanArgs
  ) => {
    dispatch(updateTrainingPlan(args));
  },
  toggleCopyTrainingPlanToClients: (index: number) => {
    dispatch(toggleCopyTrainingPlanToClients(index));
  },
  onClickNewOrEditMasterProgram: (index: number) => {
    dispatch(onClickNewOrEditMasterProgram(index));
  },
  cloneMasterTrainingPlan: (trainingPlanId: string) => {
    dispatch(cloneMasterTrainingPlan(trainingPlanId));
  },
  cloneCustomTrainingPlan: (trainingPlanId: string) => {
    dispatch(cloneCustomTrainingPlan(trainingPlanId));
  },
});
const mapStateToProps = (state: StoreState) => ({
  plan: selectFullMasterOrCustomPlan(state),
  clientPlan: selectFullClientPlan(state),
  componentLocation: state.trainingProgramsState.componentLocation,
  showCreateNewPlans: state.trainingProgramsState.showCreateNewPlans,
  clientPlanIndex: state.trainingProgramsState.clientPlanIndex,
  planIndex: state.trainingProgramsState.planIndex,
  isEditPlan: state.trainingProgramsState.isEditPlan,
  programType: state.trainingProgramsState.programType,
  isMasquerade: getIsMasquerade(state),
  isAdmin: getIsAdmin(state),
  selectedUser: state.clientDetailState.user,
  destinationTrainerId: getTrainerId(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanHeader);
