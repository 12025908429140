import {
  CaliberPageImpl_Manager,
  Manager,
  MediaUrl,
} from "../../../types/gqlTypes";

export const ADMIN_MANAGER_CREATING_SUCCESS = "ADMIN_MANAGER_CREATING_SUCCESS";
export const ADMIN_MANAGER_CREATING_FAIL = "ADMIN_MANAGER_CREATING_FAIL";
export const ADMIN_MANAGER_CREATING_SAVING = "ADMIN_MANAGER_CREATING_SAVING";

export const ADMIN_MANAGER_UPDATING_SUCCESS = "ADMIN_MANAGER_UPDATING_SUCCESS";
export const ADMIN_MANAGER_UPDATING_FAIL = "ADMIN_MANAGER_UPDATING_FAIL";
export const ADMIN_MANAGER_UPDATING_SAVING = "ADMIN_MANAGER_UPDATING_SAVING";

export const RESET_CREATE_MANAGER_TRAINER_STATE =
  "RESET_MANAGERS_TRAINERS_STATE";
export const ADMIN_MANAGERS_SEARCH_SUCCESS = "ADMIN_MANAGERS_SEARCH_SUCCESS";
export const ADMIN_MANAGERS_SEARCH_FAIL = "ADMIN_MANAGERS_SEARCH_FAIL";
export const ADMIN_MANAGERS_SEARCH_LOADING = "ADMIN_MANAGERS_SEARCH_LOADING";
export const UPDATE_MANAGER_PROFILE_ICON = "UPDATE_MANAGER_PROFILE_ICON";

export const ADMIN_MANAGER_RESET_SEARCH = "ADMIN_MANAGER_RESET_SEARCH";

export interface AdminSearchManagersState {
  isLoading: boolean;
  foundManagers: Manager[];
  page: number;
  totalPages: number;
}

export interface AdminSearchManagersAction {
  type: string;
  data?: CaliberPageImpl_Manager;
  id?: string;
  profileIconMediaUrl?: MediaUrl;
  manager?: Manager;
}

export interface AdminCreateUpdateManagerState {
  errorToCreate: boolean;
  errorToUpdate: boolean;
  successToCreate: boolean;
  successToUpdate: boolean;
  isSaving: boolean;
  errorMessage: string;
}

export interface AdminCreateUpdateManagerAction {
  type: string;
  errorMessage?: {
    graphQLErrors?: {
      extensions?: {
        errorMessage?: string;
      };
    }[];
  };
  manager?: Manager;
}
