import React, { useEffect, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import colors from "../../assets/colors";
import { MediaUrl } from "../../types/gqlTypes";
import CloseIcon from "../Icons/CloseIcons";
import DragHandle from "../Icons/DragHandle";
import SimpleButton from "../SimpleButton";

interface Item {
  type: string;
  id: string;
  originalIndex: string;
}
interface Props {
  mediaUrl: MediaUrl;
  isDraggable?: boolean;
  editable?: boolean;
  onDelete?: () => void;
  moveCard?: (id?: string, to?: number) => void;
  findCard?: (id?: string) => { index?: number };
}

const Image = (props: Props) => {
  const { mediaUrl, isDraggable, editable, onDelete, moveCard, findCard } =
    props;

  const [isHover, setIsHover] = useState(false);

  const card = findCard && findCard(mediaUrl?.id);
  const originalIndex = card ? card.index : 0;

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: "coach_profile_images", id: mediaUrl?.id, originalIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      const { id: droppedId, originalIndex } = monitor.getItem();
      const didDrop = monitor.didDrop();
      if (isDraggable && moveCard && !didDrop) {
        moveCard(droppedId, originalIndex);
      }
    },
  });

  const [, drop] = useDrop({
    accept: "coach_profile_images",
    canDrop: () => false,
    hover({ id: droppedId }: Item, monitor) {
      if (isDraggable && findCard && droppedId !== mediaUrl?.id) {
        const card = findCard(mediaUrl?.id);
        if (isHover) {
          setIsHover(false);
        }
        if (isDraggable && moveCard && card && card.index > -1) {
          moveCard(droppedId, card.index);
        }
      }
    },
  });

  const cutName = (name) => {
    if (name?.length > 15) {
      const indOfExtension = name?.lastIndexOf(".");
      const extension = name.slice(indOfExtension);
      return `${name.slice(0, 16)}...${extension}`;
    }
    return name;
  };

  useEffect(() => {
    if (!editable) {
      setIsHover(false);
    }
  }, [editable]);

  return (
    <div
      ref={(node) => (isDraggable ? preview(drop(node)) : null)}
      className="d-flex"
      onMouseEnter={() => {
        if (editable) {
          setIsHover(true);
        }
      }}
      onMouseLeave={() => setIsHover(false)}
      style={{
        transform: "translate(0, 0)",
        marginBottom: 20,
        height: 64,
        padding: "8px 12px",
        opacity: isDragging ? 0 : 1,
        borderRadius: 8,
        backgroundColor: isHover ? colors.caliber_white : "transparent",
      }}
    >
      <img
        style={{
          borderRadius: 4,
          width: 48,
          height: 48,
          marginRight: 12,
        }}
        src={mediaUrl?.url}
      />

      <div
        className="d-flex align-items-center medium-bold"
        style={{
          fontSize: "14px",
          lineHeight: "14px",
          color: colors.caliber_secondary,
          backgroundColor: colors.caliber_secondary_gray_11,
          borderRadius: 8,
          whiteSpace: "nowrap",
          overflow: "hidden",
          width: isHover ? 256 : 428,
          height: 48,
          transition: "width 0.1s",
          padding: "0px 12px",
        }}
      >
        {cutName(`image-${mediaUrl?.id}.png`)}
      </div>

      {isHover && (
        <SimpleButton
          nofocus
          onClick={onDelete}
          className="d-flex justify-content-center align-items-center"
          style={{
            width: 34,
            height: 48,
            paddingLeft: 14,
          }}
        >
          <CloseIcon width={20} height={20} />
        </SimpleButton>
      )}
      {isHover && (
        <div
          ref={(node) => (isDraggable ? drag(drop(node)) : null)}
          className="d-flex justify-content-center align-items-center"
          style={{
            width: 48,
            height: 48,
            cursor: isDraggable && "move",
          }}
        >
          <DragHandle />
        </div>
      )}
    </div>
  );
};

export default Image;
