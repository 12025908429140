import {
  UPDATE_COACH_PROFILE_ICON_SUCCESS,
  UPDATE_COACH_PROFILE_ICON_SAVING,
  UPDATE_COACH_PROFILE_ICON_FAIL,
  UpdateCoachProfileIconAction,
  UpdateCoachProfileIconState as UpdateCoachProfileIconState,
} from "../../actions/UpdateCoachProfileIcon/types";

const initialState: UpdateCoachProfileIconState = {
  isSaving: false,
};

const updateCoachProfileIconState = (
  state = initialState,
  action: UpdateCoachProfileIconAction
) => {
  switch (action.type) {
    case UPDATE_COACH_PROFILE_ICON_SAVING:
      return {
        ...state,
        isSaving: true,
      };
    case UPDATE_COACH_PROFILE_ICON_SUCCESS:
      return {
        ...state,
        isSaving: false,
      };
    case UPDATE_COACH_PROFILE_ICON_FAIL:
      return {
        ...state,
        isSaving: false,
      };
    default:
      return state;
  }
};

export default updateCoachProfileIconState;
