import { useEffect, useRef, useState } from "react";

export const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
};

export const useRerenderOnResize = () => {
  const getSize = () => {
    return {
      height: window ? window.innerHeight : undefined,
    };
  };
  const [size, setSize] = useState(getSize);

  useEffect(() => {
    const handleResize = () => {
      setSize(getSize());
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return size;
};

export const useKeyPress = (targetKey: string, targetEvent: () => void) => {
  const onKeyPress = (event) => {
    if (event?.key === targetKey) {
      targetEvent();
    }
  };
  useEffect(() => {
    window.addEventListener("keyup", onKeyPress);
    return () => {
      window.removeEventListener("keyup", onKeyPress);
    };
  }, [targetKey, targetEvent]);
};

export const useInterval = (callback: () => any, delay: number) => {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (delay === 0) {
      return undefined;
    }
    const id = setInterval(() => {
      savedCallback?.current?.();
    }, delay);
    return () => clearInterval(id);
  }, [delay]);
};
