import {
  ADMIN_TRAINER_CREATING_FAIL,
  ADMIN_TRAINER_CREATING_SAVING,
  ADMIN_TRAINER_CREATING_SUCCESS,
  ADMIN_TRAINER_UPDATING_SUCCESS,
  ADMIN_TRAINER_UPDATING_SAVING,
  ADMIN_TRAINER_UPDATING_FAIL,
  AdminCreateUpdateTrainerAction,
  AdminCreateUpdateTrainerState,
  ADMIN_TRAINER_RESET_SEARCH,
} from "../../actions/AdminTeamTrainers/types";
import { RESET_CREATE_MANAGER_TRAINER_STATE } from "../../actions/AdminTeamManagers/types";

const initialState: AdminCreateUpdateTrainerState = {
  errorToCreate: false,
  errorToUpdate: false,
  successToCreate: false,
  successToUpdate: false,
  isSaving: false,
  errorMessage: null,
};

const adminCreateUpdateTrainerAction = (
  state = initialState,
  action: AdminCreateUpdateTrainerAction
) => {
  switch (action.type) {
    case RESET_CREATE_MANAGER_TRAINER_STATE:
      return {
        ...state,
        errorToCreate: false,
        successToCreate: false,
        errorToUpdate: false,
        successToUpdate: false,
        isSaving: false,
        errorMessage: null,
      };
    case ADMIN_TRAINER_CREATING_SAVING:
    case ADMIN_TRAINER_UPDATING_SAVING:
      return {
        ...state,
        isSaving: true,
      };
    case ADMIN_TRAINER_CREATING_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        isSaving: false,
        errorToCreate: false,
        successToCreate: true,
      };
    case ADMIN_TRAINER_UPDATING_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        isSaving: false,
        errorToUpdate: false,
        successToUpdate: true,
      };
    case ADMIN_TRAINER_CREATING_FAIL: {
      const errorMessages = [];
      action.errorMessage?.graphQLErrors.forEach((err) => {
        if (err?.extensions?.errorMessage) {
          errorMessages.push(err?.extensions?.errorMessage);
        }
      });
      return {
        ...state,
        errorMessage: errorMessages.join("\n"),
        errorToCreate: true,
        successToCreate: false,
        isSaving: false,
      };
    }
    case ADMIN_TRAINER_UPDATING_FAIL: {
      const errorMessages = [];
      action.errorMessage?.graphQLErrors.forEach((err) => {
        if (err?.extensions?.errorMessage) {
          errorMessages.push(err?.extensions?.errorMessage);
        }
      });
      return {
        ...state,
        errorMessage: errorMessages.join("\n"),
        errorToUpdate: true,
        successToUpdate: false,
        isSaving: false,
      };
    }
    case ADMIN_TRAINER_RESET_SEARCH:
      return {
        ...state,
        errorMessage: null,
        isSaving: false,
        errorToUpdate: false,
        successToUpdate: true,
      };
    default:
      return state;
  }
};

export default adminCreateUpdateTrainerAction;
