import React from "react";
import colors from "../../assets/colors";

interface Props {
  title: "Bio" | "Certifications" | "Education" | "Images" | "Fun facts";
}

const SectionHeader = (props: Props) => {
  const { title } = props;

  const body = {
    Bio: "Tell us about yourself. What is your training philosophy? What types of clients have you worked with in the past? How many years of experience do you have?",
    Certifications:
      "Add any certifications here, inclusive of nutrition, training, first-aid, and any specializations (e.g. kettlebell, yoga, meditation).",
    Education:
      "Add any certifications here, inclusive of nutrition, training, first-aid, and any specializations (e.g. kettlebell, yoga, meditation).",
    Images: "Upload up to 6 square images.",
    "Fun facts":
      "What are your favorite foods? Do you have any pets? What do you like to do for fun?",
  };

  return (
    <div
      className="d-flex flex-column"
      style={{
        flex: 2,
      }}
    >
      <div
        className="d-flex bold"
        style={{
          fontSize: "18px",
          lineHeight: "18px",
          letterSpacing: "-0.022em",
          color: colors.caliber_secondary,
          marginTop: 24,
          marginBottom: 16,
          width: 284,
        }}
      >
        {title}
      </div>

      <div
        className="d-flex"
        style={{
          fontSize: "12px",
          lineHeight: "16px",
          letterSpacing: "-0.011em",
          color: colors.caliber_secondary_gray_47,
          width: 284,
        }}
      >
        {body[title]}
      </div>
    </div>
  );
};

export default SectionHeader;
