import {
  ClientPlansState,
  GetClientPlansAction,
  GET_CLIENT_ACTIVE_PLAN_FAIL,
  GET_CLIENT_ACTIVE_PLAN_LOADING,
  GET_CLIENT_ACTIVE_PLAN_SUCCESS,
  GET_CLIENT_PLANS_LOADING,
  GET_CLIENT_PLANS_LOAD_FAIL,
  GET_CLIENT_PLANS_LOAD_SUCCESS,
  RESET_CLIENT_STATE,
} from "../../actions/ClientPlans/types";

const initialState: ClientPlansState = {
  isLoading: false,
  isActivePlanLoading: false,
  hasActivePlan: false,
  clientPlans: [],
  activePlan: null,
};

const clientPlansState = (
  state = initialState,
  action: GetClientPlansAction
) => {
  switch (action.type) {
    case GET_CLIENT_PLANS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_CLIENT_ACTIVE_PLAN_LOADING:
      return {
        ...state,
        isActivePlanLoading: true,
      };
    case GET_CLIENT_ACTIVE_PLAN_FAIL:
      return {
        ...state,
        isActivePlanLoading: false,
        activePlan: null,
      };
    case GET_CLIENT_ACTIVE_PLAN_SUCCESS:
      return {
        ...state,
        isActivePlanLoading: false,
        activePlan: action.planData.plan,
      };
    case RESET_CLIENT_STATE:
      return {
        ...state,
        hasActivePlan: false,
        activePlan: null,
        clientPlans: [],
      };
    case GET_CLIENT_PLANS_LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasActivePlan: action.planData.data.some((plan) => plan.active),
        clientPlans: action.planData.data,
      };
    case GET_CLIENT_PLANS_LOAD_FAIL:
      return {
        ...state,
        isLoading: false,
        hasActivePlan: false,
        clientPlans: [],
      };
    default:
      return state;
  }
};

export default clientPlansState;
