import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import colors from "../../assets/colors";
import BreakfastIcon from "../../assets/images/BreakfastIcon.svg";
import DeleteIcon from "../../assets/images/DeleteSolidIcon.svg";
import { createUpdateClientNutritionTarget } from "../../redux/actions/ClientNutritionTarget";
import { fileUpload, resetMediaState } from "../../redux/actions/Media";
import { MediaState } from "../../redux/actions/Media/types";
import { StoreState } from "../../redux/reducers";
import {
  ClientNutritionTarget,
  FileUploadType,
  MutationCreateUpdateClientNutritionTargetArgs,
} from "../../types/gqlTypes";
import ConfirmationDialog from "../ConfirmationDialog";
import Loader from "../Loader";

interface Props {
  createUpdateClientNutritionTarget: (
    args: MutationCreateUpdateClientNutritionTargetArgs
  ) => void;
  uploadMealPlan: (file: any, type: FileUploadType) => void;
  resetMediaState: () => void;
  isLoading: boolean;
  clientNutritionTarget: ClientNutritionTarget;
  mediaState: MediaState;
}

const NutritionMealPlan = (props: Props) => {
  const {
    createUpdateClientNutritionTarget,
    uploadMealPlan,
    resetMediaState,
    isLoading,
    clientNutritionTarget,
    mediaState,
  } = props;
  const { id: clientId } = useParams();
  const fileInputRef = useRef(null);
  const [hovered, setHovered] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const planName = clientNutritionTarget?.mealPlanMediaUrl?.objectName || "";

  useEffect(() => {
    if (
      mediaState?.fileInfo?.mediaUrl.url &&
      mediaState?.fileInfo?.mediaUrl.url !==
        clientNutritionTarget?.mealPlanMediaUrl?.url
    ) {
      createUpdateClientNutritionTarget({
        clientId,
        mealPlanMediaUrlId: mediaState.fileInfo?.mediaUrl?.id,
      });
    }
    return () => {
      resetMediaState();
    };
  }, [mediaState.fileInfo?.mediaUrl]);

  const onDeletePlan = () => {
    setConfirmDialog(false);
    const copy = { ...clientNutritionTarget };
    delete copy.mealPlanMediaUrl;
    createUpdateClientNutritionTarget({
      ...copy,
      clientId,
      mealPlanMediaUrlId: null,
    });
  };

  return (
    <div
      className="d-flex flex-column medium-bold"
      style={{
        backgroundColor: colors.caliber_white,
        borderRadius: 8,
        width: 272,
        height: 234,
        marginTop: 24,
        padding: 16,
        position: "relative",
      }}
    >
      {confirmDialog && (
        <ConfirmationDialog
          show={confirmDialog}
          cancelButtonText="Cancel"
          text="Do you really want to delete the Meal Plan?"
          onCancel={() => setConfirmDialog(false)}
          onConfirm={onDeletePlan}
        />
      )}
      <div
        style={{
          fontSize: "20px",
          lineHeight: "25px",
          letterSpacing: "-0.29px",
          color: colors.caliber_secondary,
          marginBottom: 10,
        }}
      >
        Meal Plan
      </div>
      {(isLoading || mediaState?.isUploading) && (
        <div
          style={{
            position: "absolute",
            top: "30%",
            left: "48%",
          }}
        >
          <Loader />
        </div>
      )}
      <div
        className="d-flex flex-column"
        style={{
          backgroundColor: colors.caliber_gray_bg,
          borderRadius: 4,
          width: 240,
          height: 170,
        }}
      >
        {!clientNutritionTarget?.mealPlanMediaUrl && (
          <div
            style={{
              padding: "8px 36px",
            }}
            className="d-flex flex-column"
          >
            <div
              style={{ marginTop: 15 }}
              className="d-flex justify-content-center"
            >
              <img src={BreakfastIcon} />
            </div>
            <div
              className="d-flex flex-column justify-content-center align-items-center"
              style={{
                fontSize: "10px",
                lineHeight: "16px",
                letterSpacing: "0.11px",
                color: colors.caliber_gray_border,
                marginBottom: 7,
              }}
            >
              No meal plan uploaded
            </div>

            <div
              className={`${
                mediaState.isUploading ? "" : "pointer"
              } d-flex flex-column justify-content-center align-items-center`}
              role="button"
              tabIndex={0}
              onClick={() => {
                if (!mediaState.isUploading) {
                  fileInputRef.current.click();
                }
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter" && !mediaState.isUploading) {
                  fileInputRef.current.click();
                }
              }}
              style={{
                position: "relative",
                backgroundColor: "#E4E5E9",
                borderRadius: 4,
                width: 148,
                height: 24,
                color: colors.caliber_secondary,
                fontSize: "12px",
                lineHeight: "24px",
                margin: "0px 10px",
              }}
            >
              Upload Meal Plan
              <input
                ref={fileInputRef}
                type="file"
                name="image"
                accept="application/pdf"
                onChange={(event) => {
                  if (event.target == null || event.target.files[0] == null) {
                    return;
                  }
                  const file = event.target.files[0];
                  if (file.type === "application/pdf") {
                    uploadMealPlan(
                      event.target.files[0],
                      FileUploadType.MealPlanDoc
                    );
                  } else {
                    // TODO: Display error
                    console.log("Invalid filetype", file.type);
                  }
                }}
                style={{ display: "none" }}
              />
            </div>
            <div
              className="d-flex flex-column justify-content-center align-items-center"
              style={{
                fontSize: "10px",
                lineHeight: "32px",
                color: colors.caliber_gray_border,
                marginTop: 8,
              }}
            >
              .Pdf format only・Max 5mb
            </div>
          </div>
        )}

        {clientNutritionTarget?.mealPlanMediaUrl && (
          <div
            className="d-flex flex-column"
            style={{
              backgroundColor: colors.caliber_white,
              borderRadius: 2,
              border: `1px solid ${colors.caliber_gray_bg}`,
              boxShadow: "0px 2px 16px #D4D5DB",
              margin: "22px 23px",
              width: 196,
              height: 138,
            }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            <div
              role="button"
              tabIndex={0}
              onClick={() => {
                if (hovered) {
                  setConfirmDialog(true);
                }
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter" && hovered) {
                  setConfirmDialog(true);
                }
              }}
              className="d-flex justify-content-end nofocus"
              style={{ height: 32 }}
            >
              {hovered && (
                <img
                  className="pointer"
                  style={{
                    height: 16,
                    width: 16,
                    margin: 8,
                  }}
                  src={DeleteIcon}
                />
              )}
            </div>
            <div
              className="d-flex flex-column justify-content-center align-items-center"
              style={{
                fontSize: "20px",
                lineHeight: "24px",
                color: colors.caliber_gray_border,
                marginTop: 9,
              }}
            >
              PDF
            </div>
            <div
              role="button"
              tabIndex={0}
              onClick={() => {
                window.open(
                  clientNutritionTarget?.mealPlanMediaUrl?.url,
                  "_blank"
                );
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  window.open(
                    clientNutritionTarget?.mealPlanMediaUrl?.url,
                    "_blank"
                  );
                }
              }}
              className="d-flex flex-column justify-content-center align-items-center pointer"
              style={{
                width: 145,
                height: 20,
                borderRadius: 4,
                backgroundColor: colors.caliber_gray_text,
                marginTop: 23,
                marginLeft: 25,
                padding: "0px 8px",
              }}
            >
              <div
                style={{
                  whiteSpace: "nowrap",
                  color: colors.caliber_white,
                  fontSize: "12px",
                  lineHeight: "16px",
                  overflowX: "hidden",
                  width: 120,
                }}
              >
                {planName?.length > 52
                  ? `${planName.slice(35, 52)}...`
                  : planName.slice(35)}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  createUpdateClientNutritionTarget: (
    args: MutationCreateUpdateClientNutritionTargetArgs
  ) => {
    dispatch(createUpdateClientNutritionTarget(args));
  },
  uploadMealPlan: (file: any, type: FileUploadType) => {
    dispatch(fileUpload(file, type));
  },
  resetMediaState: () => {
    dispatch(resetMediaState());
  },
});

const mapStateToProps = (state: StoreState) => ({
  clientNutritionTarget: state.clientNutritionTargetState.clientNutritionTarget,
  isLoading: state.clientNutritionTargetState.isLoading,
  mediaState: state.mediaState,
});

export default connect(mapStateToProps, mapDispatchToProps)(NutritionMealPlan);
