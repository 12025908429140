import React from "react";
import { connect } from "react-redux";
import AdminNewTrainingProgram from "../../components/AdminNewTrainingProgram";
import { HEADER_HEIGHT } from "../../components/Header";
import TrainingProgram, {
  ComponentLocationType,
} from "../../components/TrainingProgram";
import { StoreState } from "../../redux/reducers";
import { MasterTrainingPlan } from "../../types/gqlTypes";

interface Props {
  showNewOrEditMasterProgramSection: boolean;
  editingMasterProgram: MasterTrainingPlan;
}

const AdminProgramLibraryScreen = (props: Props) => {
  const { showNewOrEditMasterProgramSection, editingMasterProgram } = props;

  return (
    <div
      className="d-flex"
      style={{
        flex: 1,
        height: window.innerHeight - HEADER_HEIGHT,
      }}
    >
      {showNewOrEditMasterProgramSection ? (
        <div className="d-flex">
          <AdminNewTrainingProgram
            isEdit={!!editingMasterProgram}
            planToEdit={editingMasterProgram}
          />
        </div>
      ) : (
        // @ts-ignore
        <TrainingProgram
          componentLocation={ComponentLocationType.AdminLibrary}
        />
      )}
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({});
const mapStateToProps = (state: StoreState) => ({
  showNewOrEditMasterProgramSection:
    state.trainingProgramsState.showNewOrEditMasterProgramSection,
  editingMasterProgram: state.trainingProgramsState.editingMasterProgram,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminProgramLibraryScreen);
