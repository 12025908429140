import React from "react";
import { Modal } from "react-bootstrap";
import { format } from "date-fns";
import { CalendarItem, CalendarDay } from "../../types/gqlTypes";
import DraggableCalendarItemComponent from "./DraggableCalendarItem";

interface Props {
  date: Date;
  day: CalendarDay;
  onClose: () => void;
  viewEvent: (item: CalendarItem, dayId: string, day: Date) => void;
  onDelete: (item: CalendarItem, date: Date) => void;
}

const CalendarFullDayModal = (props: Props) => {
  const { date, day, onClose, viewEvent, onDelete } = props;

  return (
    <Modal size="sm" show centered onHide={onClose}>
      <Modal.Body>
        <div className="d-flex flex-column">
          <div
            className="heading-small"
            style={{
              marginBottom: 8,
            }}
          >
            {`${format(date, "EEEE")}・${format(date, "MMM dd yyyy")}`}
          </div>
          <div>
            {day?.items?.map((item) => {
              return (
                <DraggableCalendarItemComponent
                  isDraggable={false}
                  calendarDay={day}
                  key={item.id}
                  onDelete={onDelete}
                  viewEvent={() => viewEvent(item, day?.id, date)}
                  date={date}
                  item={item}
                />
              );
            })}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default CalendarFullDayModal;
