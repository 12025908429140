import { format, parse } from "date-fns";
import React from "react";
import { useLocation } from "react-router-dom";
import colors from "../../assets/colors";
import ArrowUpIcon from "../../assets/images/ArrowUpIcon.svg";
import {
  ClientPackageType,
  ClientSettings,
  DeviceType,
  GenderType,
  ProGroup,
  Trainer,
  UserStatusType,
} from "../../types/gqlTypes";
import { toTitleCase } from "../../utils";
import ClientEmail from "./ClientEmail";
import UserDataRow from "./UserDataRow";

interface Props {
  showConnectedDevices: boolean;
  notShowLessInfo?: boolean;
  collapse: () => void;
  client: {
    location: string;
    clientAge: string;
    clientHeight: string;
    clientWeight: string;
    phone: string;
    email: string;
    settings: ClientSettings;
    deviceType: DeviceType;
    status: UserStatusType;
    joinedDate: string;
    gender: GenderType;
    assignedTrainer: Trainer;
    clientPackageType: ClientPackageType;
    callsLeft: number;
    packageEndDate: string;
    proGroups: ProGroup[];
  };
}
interface UserDataField {
  title: string;
  key: string | number;
}
interface IntegratedServicesField {
  title: string;
  key: boolean;
}

const FullViewProfile = (props: Props) => {
  const {
    showConnectedDevices,
    notShowLessInfo,
    collapse,
    client: {
      settings,
      location,
      clientAge,
      clientHeight,
      clientWeight,
      phone,
      email,
      status,
      joinedDate,
      deviceType,
      gender,
      assignedTrainer,
      clientPackageType,
      proGroups,
    },
  } = props;
  const routerLocation = useLocation();
  const isProfile = routerLocation.pathname.split("/").includes("profile");

  const deviceTypeNames = {
    [DeviceType.Android]: "Android",
    [DeviceType.Ios]: "iOS",
    [DeviceType.Web]: "Web",
  };
  const genderTypeNames = {
    [GenderType.Female]: "Female",
    [GenderType.Male]: "Male",
    [GenderType.NotSpecified]: "Not Specified",
  };
  const date = joinedDate
    ? parse(joinedDate?.slice(0, 10) || null, "yyyy-MM-dd", new Date())
    : new Date();
  const isTrial = status === UserStatusType.TrialUser;
  const isCancelled = status === UserStatusType.Cancelled;

  const userDataFields1: Array<UserDataField> = [
    { title: "Location", key: location },
    { title: "Age", key: clientAge },
    { title: "Gender", key: genderTypeNames[gender] },
    { title: "Height", key: clientHeight },
    { title: "Weight", key: clientWeight },
    { title: "Phone Number", key: phone },
  ];
  const userDataFields2: Array<UserDataField> = [
    { title: "Device Type", key: deviceTypeNames[deviceType] },
    {
      title: "Member Since",
      key: !isTrial && !isCancelled && format(date, "M.dd.yyyy"),
    },
    {
      title: "Package Type",
      key: clientPackageType && toTitleCase(clientPackageType),
    },
  ];

  if (
    clientPackageType !== ClientPackageType.Freemium &&
    clientPackageType !== ClientPackageType.Pro &&
    clientPackageType !== ClientPackageType.Plus
  ) {
    userDataFields2.push({
      title: "Coach",
      key: `${assignedTrainer?.firstName} ${assignedTrainer?.lastName}`,
    });
  }

  if (clientPackageType === ClientPackageType.Pro && proGroups.length) {
    userDataFields2.push({
      title: "Pro Group",
      key: `${proGroups[0].name}`,
    });
  }

  const integratedServicesFields: Array<IntegratedServicesField> = [
    { title: "Apple HealthKit", key: settings?.enableAppleHealth },
    { title: "Google Fit", key: settings?.enableGoogleFit },
    { title: "Cronometer", key: settings?.enableCronometer },
  ];
  const badgeStyle = {
    padding: "1px 10px",
    borderRadius: 4,
  };
  return (
    <div
      className="col-16 flex-grow-1"
      style={{
        marginTop: 25,
      }}
    >
      <div
        style={{ color: colors.caliber_gray_border }}
        className="paragraph-small"
      >
        User data
      </div>

      {userDataFields1.map(({ title, key }) => {
        return <UserDataRow value={key} title={title} />;
      })}

      <ClientEmail email={email} />

      {userDataFields2.map(({ title, key }) => {
        return <UserDataRow value={key} title={title} />;
      })}

      {showConnectedDevices && (
        <div
          style={{
            color: colors.caliber_gray_border,
            marginTop: 15,
          }}
          className="paragraph-small"
        >
          Connected devices
        </div>
      )}
      {showConnectedDevices &&
        integratedServicesFields.map(({ title, key }) => {
          return (
            <div
              key={title}
              style={{ margin: "12px 0px" }}
              className="d-flex justify-content-between align-items-center"
            >
              <div
                className="medium-bold"
                style={{
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: colors.caliber_secondary,
                }}
              >
                {title}
              </div>
              <div
                className="paragraph-small bold"
                style={{
                  ...badgeStyle,
                  backgroundColor: key
                    ? colors.caliber_green_200
                    : colors.caliber_gray_bg,
                  color: key
                    ? colors.caliber_white
                    : colors.caliber_gray_border,
                }}
              >
                {key ? "Connected" : "Not connected"}
              </div>
            </div>
          );
        })}
      {!notShowLessInfo && !isProfile && (
        <div className="flex-center heading-xsmall">
          <div
            role="button"
            className="pointer"
            tabIndex={0}
            onClick={collapse}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                collapse();
              }
            }}
            style={{
              color: colors.caliber_gray_border,
              border: `1px solid ${colors.caliber_gray_bg}`,
              borderRadius: 24,
              padding: "1px 7px 1px 14px",
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            Less Info
            <img
              alt="Collapse"
              style={{ paddingBottom: 2 }}
              src={ArrowUpIcon}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default FullViewProfile;
