import {
  UPDATE_NOTES_FAIL,
  UPDATE_NOTES_SAVING,
  UPDATE_NOTES_SUCCESS,
  UpdateNotesAction,
  UpdateNotesState,
} from "../../actions/UpdateNotes/types";

const initialState: UpdateNotesState = {
  isSaving: false,
};

const updateNotesState = (state = initialState, action: UpdateNotesAction) => {
  switch (action.type) {
    case UPDATE_NOTES_SAVING:
      return {
        ...state,
        isSaving: true,
      };
    case UPDATE_NOTES_SUCCESS:
      return {
        ...state,
        isSaving: false,
      };
    case UPDATE_NOTES_FAIL:
      return {
        ...state,
        isSaving: false,
      };
    default:
      return state;
  }
};

export default updateNotesState;
