import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";
import colors from "../../../assets/colors";
import { searchTrainers } from "../../../redux/actions/AdminTeamTrainers";
import { StoreState } from "../../../redux/reducers";
import { User, UserStatusType } from "../../../types/gqlTypes";
import { useDebounce } from "../../../utils/customHooks";
import DropdownButton from "./DropdownButton";
import DropdownItemComponent from "./DropdownItem";
import SearchInput from "./SearchInput";

export interface DropdownItem {
  text: string;
  value: string;
}

interface OwnProps {
  currentValue: string;
  currentText: string;
  onSelect: (value: User | string) => void;
}
interface Props extends OwnProps {
  trainers: User[];
  page: number;
  totalPages: number;
  searchTrainers: (page: number, searchCriteria: string) => void;
}

const SearchTrainerDropdown = (props: Props) => {
  const {
    trainers,
    currentValue,
    currentText,
    page,
    totalPages,
    onSelect,
    searchTrainers,
  } = props;

  const items = trainers.map((trainer) => {
    const fullName = `${trainer.firstName} ${trainer.lastName}`;
    return { text: fullName, value: trainer.id };
  });
  const [open, setOpen] = useState(false);

  const dropdownHeight = (items?.length + 1) * 38 + 64;

  const [searchString, setSearchString] = useState("");
  const debouncedSearchValue = useDebounce(searchString, 400);

  const onBlur = () => {
    setTimeout(() => {
      if (document.activeElement.id !== "dropdown_element") {
        setOpen(false);
      }
    }, 0);
  };
  const onItemSelect = (value: string) => {
    if (value === "all") {
      onSelect("all");
    } else {
      const trainer = trainers.find((trainer) => trainer.id === value);
      onSelect(trainer);
    }
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      searchTrainers(0, debouncedSearchValue);
    }
  }, [open, debouncedSearchValue]);

  return (
    <div
      style={{
        position: "relative",
        flex: 1,
        minWidth: 200,
        zIndex: 11,
      }}
      className="d-flex flex-column"
    >
      <DropdownButton
        text={currentText}
        isOpen={open}
        onBlur={onBlur}
        onClick={() => setOpen(!open)}
      />

      {open && (
        <div
          id="dropdown_element"
          onBlur={onBlur}
          className="d-flex flex-column medium-bold"
          style={{
            width: 428,
            padding: 5,
            height: Math.min(dropdownHeight, 300),
            zIndex: 2,
            backgroundColor: colors.caliber_white,
            borderRadius: 8,
            position: "absolute",
            top: 50,
            marginBottom: 10,
            boxShadow: "0px 2px 16px #E7E7EB",
          }}
        >
          <SearchInput
            value={searchString}
            onChange={(value) => setSearchString(value)}
            onBlur={onBlur}
          />
          <div
            className="d-flex flex-column overflow-auto"
            style={{
              position: "relative",
              flex: 1,
            }}
          >
            <InfiniteScroll
              pageStart={0}
              useWindow={false}
              loadMore={() => searchTrainers?.(page + 1, searchString)}
              hasMore={totalPages > page + 1}
            >
              <DropdownItemComponent
                isSelected={currentValue === "all"}
                index={0}
                onBlur={onBlur}
                onSelect={onItemSelect}
                text="All Coaches"
                value="all"
              />
              {items.map(({ text, value }) => {
                return (
                  <DropdownItemComponent
                    text={text}
                    value={value}
                    isSelected={currentValue === value}
                    onBlur={onBlur}
                    onSelect={onItemSelect}
                  />
                );
              })}
            </InfiniteScroll>
          </div>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  searchTrainers: (page: number, searchCriteria: string) => {
    dispatch(
      searchTrainers(
        page,
        searchCriteria,
        UserStatusType[UserStatusType.Active]
      )
    );
  },
});

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  trainers: state.adminSearchTrainersState.foundTrainers,
  page: state.adminSearchTrainersState.page,
  totalPages: state.adminSearchTrainersState.totalPages,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchTrainerDropdown);
