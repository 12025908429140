import apolloClient from "../../../api/apolloClient";
import {
  MOVE_CLIENT_WORKOUT_TEMPLATE,
  REORDER_CLIENT_GYM_STEP_TEMPLATES,
  REORDER_CLIENT_WORKOUT_GROUP,
  REORDER_CLIENT_WORKOUT_TEMPLATES,
} from "../../../api/gql/trainingPrograms/clientTrainingPlan";
import {
  REORDER_GYMSTEPS_IN_CUSTOM_TRAINING_PLAN,
  REORDER_WORKOUTS_IN_CUSTOM_TRAINING_PLAN,
  REORDER_WORKOUTS_IN_LIBRARY_TRAINING_PLAN,
} from "../../../api/gql/trainingPrograms/customTrainingPlan";
import {
  ADMIN_REORDER_MASTER_GYM_STEPS,
  REORDER_MASTER_WORKOUTS,
} from "../../../api/gql/trainingPrograms/masterTrainingPlan";
import { ProgramType } from "../../../components/TrainingProgram";
import {
  ClientTrainingPlan,
  ClientWorkoutTemplate,
  CustomTrainingPlan,
  Exercise,
  LibraryTrainingPlan,
  MasterTrainingPlan,
  MasterWorkout,
  MutationReorderClientGymStepTemplatesArgs,
  Workout,
} from "../../../types/gqlTypes";
import { getTrainer } from "../../reducers";
import {
  selectClientPlan,
  selectClientWorkoutGroup,
  selectCurrentWorkout,
  selectMasterOrCustomPlan,
  selectShallowMasterOrCustomPlan,
} from "../../reducers/TrainingPrograms/selectors";
import { ClientDetailState } from "../ClientDetail/types";
import {
  REORDER_GYM_STEPS_IN_TRAINING_PLAN_FAIL,
  REORDER_GYM_STEPS_IN_TRAINING_PLAN_SAVING,
  REORDER_GYM_STEPS_IN_TRAINING_PLAN_SUCCESS,
  REORDER_WORKOUT_ACROSS_GROUPS_FAIL,
  REORDER_WORKOUT_ACROSS_GROUPS_SAVING,
  REORDER_WORKOUT_ACROSS_GROUPS_SUCCESS,
  REORDER_WORKOUT_FAIL,
  REORDER_WORKOUT_GROUP_FAIL,
  REORDER_WORKOUT_GROUP_SAVING,
  REORDER_WORKOUT_GROUP_SUCCESS,
  REORDER_WORKOUT_SAVING,
  REORDER_WORKOUT_SUCCESS,
} from "./types";

export const reorderGymStepsInPlan =
  (args: { ids?: string[]; id?: string; exercise?: Exercise }) =>
  async (dispatch, getState) => {
    dispatch({ type: REORDER_GYM_STEPS_IN_TRAINING_PLAN_SAVING });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const state = getState();
      const { programType } = state.trainingProgramsState;
      const workout = selectCurrentWorkout(state);
      let result: Workout;
      if (
        programType === ProgramType.Custom ||
        programType === ProgramType.Library
      ) {
        const customPlan = selectShallowMasterOrCustomPlan(state) as
          | CustomTrainingPlan
          | LibraryTrainingPlan;
        result = (
          await client.mutate({
            mutation: REORDER_GYMSTEPS_IN_CUSTOM_TRAINING_PLAN,
            variables: {
              masterGymStepIds: args.ids,
              masterWorkoutId: workout.id,
              customTrainingPlanId: customPlan.id,
              trainerId: getTrainer(getState()).id,
            },
          })
        ).data.reorderMasterGymStepsInCustomTrainingPlan as MasterWorkout;
      } else if (programType === ProgramType.Master) {
        result = (
          await client.mutate({
            mutation: ADMIN_REORDER_MASTER_GYM_STEPS,
            variables: {
              masterGymStepIds: args.ids,
              masterWorkoutId: workout.id,
            },
          })
        ).data.reorderMasterGymSteps as MasterWorkout;
      } else if (programType === ProgramType.Client) {
        const { user: selectedUser }: ClientDetailState =
          state.clientDetailState;

        result = (
          await client.mutate({
            mutation: REORDER_CLIENT_GYM_STEP_TEMPLATES,
            variables: {
              clientId: selectedUser.id,
              clientGymStepTemplateIds: args.ids,
              clientWorkoutTemplateId: workout.id,
            } as MutationReorderClientGymStepTemplatesArgs,
          })
        ).data.reorderClientGymStepTemplates as ClientWorkoutTemplate;
      }

      dispatch({
        type: REORDER_GYM_STEPS_IN_TRAINING_PLAN_SUCCESS,
        exercise: args.exercise,
        id: args.id,
        workout: result,
      });
    } catch (error) {
      dispatch({
        type: REORDER_GYM_STEPS_IN_TRAINING_PLAN_FAIL,
        data: error,
      });
    }
  };

export const reorderWorkoutsInPlan =
  (args: { ids: string[] }) => async (dispatch, getState) => {
    if (getState().trainingProgramsState.isSaving) {
      return;
    }
    dispatch({ type: REORDER_WORKOUT_SAVING });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );

      const state = getState();
      const { programType, workoutGroupIndex } = state.trainingProgramsState;
      const plan = selectMasterOrCustomPlan(state);
      const clientPlan = selectClientPlan(state);
      const workoutGroup = selectClientWorkoutGroup(state);

      let result: string[];
      if (programType === ProgramType.Custom) {
        result = (
          (
            await client.mutate({
              mutation: REORDER_WORKOUTS_IN_CUSTOM_TRAINING_PLAN,
              variables: {
                customTrainingPlanId: plan.id,
                masterWorkoutIds: args.ids,
                trainerId: getTrainer(getState()).id,
              },
            })
          ).data.reorderMasterWorkoutsInCustomTrainingPlan as CustomTrainingPlan
        ).masterWorkouts?.map((workout) => workout.id);
      } else if (programType === ProgramType.Library) {
        result = (
          (
            await client.mutate({
              mutation: REORDER_WORKOUTS_IN_LIBRARY_TRAINING_PLAN,
              variables: {
                libraryTrainingPlanId: plan.id,
                masterWorkoutIds: args.ids,
                trainerId: getTrainer(getState()).id,
              },
            })
          ).data
            .reorderMasterWorkoutsInLibraryTrainingPlan as LibraryTrainingPlan
        ).masterWorkouts?.map((workout) => workout.id);
      } else if (programType === ProgramType.Master) {
        result = (
          (
            await client.mutate({
              mutation: REORDER_MASTER_WORKOUTS,
              variables: {
                masterTrainingPlanId: plan.id,
                masterWorkoutIds: args.ids,
              },
            })
          ).data.reorderMasterWorkouts as MasterTrainingPlan
        ).workoutGroups[0].workouts?.map((workout) => workout.id);
      } else if (programType === ProgramType.Client) {
        const { user: selectedUser }: ClientDetailState =
          state.clientDetailState;

        result = (
          (
            await client.mutate({
              mutation: REORDER_CLIENT_WORKOUT_TEMPLATES,
              variables: {
                clientTrainingPlanId: clientPlan.id,
                clientWorkoutGroupTemplateId: workoutGroup.id,
                clientWorkoutTemplateIds: args.ids,
                clientId: selectedUser.id,
              },
            })
          ).data.reorderClientWorkoutTemplates as ClientTrainingPlan
        ).clientWorkoutGroupTemplates[
          workoutGroupIndex
        ].clientWorkoutTemplates?.map((workout) => workout.id);
      }

      dispatch({
        type: REORDER_WORKOUT_SUCCESS,
        ids: result,
      });
    } catch (error) {
      dispatch({
        type: REORDER_WORKOUT_FAIL,
        data: error,
      });
    }
  };

export const reorderClientWorkoutGroups =
  (clientId: string, groupIds: []) => async (dispatch, getState) => {
    if (getState().trainingProgramsState.isSaving) {
      return;
    }
    dispatch({ type: REORDER_WORKOUT_GROUP_SAVING });
    const client = await apolloClient(getState().authState.authToken, dispatch);

    const state = getState();
    const clientPlan = selectClientPlan(state) as ClientTrainingPlan;

    try {
      const result = (
        (
          await client.mutate({
            mutation: REORDER_CLIENT_WORKOUT_GROUP,
            variables: {
              clientId,
              clientTrainingPlanId: clientPlan.id,
              clientWorkoutGroupTemplateIds: groupIds,
            },
          })
        ).data.reorderClientWorkoutGroupTemplates as ClientTrainingPlan
      ).clientWorkoutGroupTemplates?.map((group) => group.id);

      dispatch({
        type: REORDER_WORKOUT_GROUP_SUCCESS,
        ids: result,
      });
    } catch (error) {
      dispatch({
        type: REORDER_WORKOUT_GROUP_FAIL,
        data: error,
      });
    }
  };

export const moveClientWorkoutTemplate =
  (
    clientId: string,
    workoutId: string,
    workoutIds: [],
    destinationGroupId: string
  ) =>
  async (dispatch, getState) => {
    if (getState().trainingProgramsState.isSaving) {
      return;
    }
    dispatch({ type: REORDER_WORKOUT_ACROSS_GROUPS_SAVING });
    const client = await apolloClient(getState().authState.authToken, dispatch);

    const vars = {
      clientId,
      clientWorkoutTemplateId: workoutId,
      clientWorkoutTemplateIds: workoutIds,
      destinationClientWorkoutGroupTemplateId: destinationGroupId,
    };

    try {
      const result = (
        await client.mutate({
          mutation: MOVE_CLIENT_WORKOUT_TEMPLATE,
          variables: vars,
        })
      ).data.moveClientWorkoutTemplate as ClientTrainingPlan;

      dispatch({
        type: REORDER_WORKOUT_ACROSS_GROUPS_SUCCESS,
        plan: result,
      });
    } catch (error) {
      dispatch({
        type: REORDER_WORKOUT_ACROSS_GROUPS_FAIL,
        data: error,
      });
    }
  };
