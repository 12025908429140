import { createSlice } from "@reduxjs/toolkit";
import { connectToSendbird, fetchPreviousMessages } from "./messengerThunk";
import { RootState } from "../../../redux/configureStore";
import { GroupChannel, MessageCollection } from "@sendbird/chat/groupChannel";
import {
  AdminMessage,
  BaseMessage,
  FileMessage,
  UserMessage,
} from "@sendbird/chat/message";

export enum CaliberChannelType {
  CALIBER_PRO_GROUP = "caliber_pro_group",
  CALIBER_COACHING = "caliber_coaching",
}

interface MessengerState {
  activeChannel: {
    channel: GroupChannel;
    messageCollection: MessageCollection;
    messages: (BaseMessage | UserMessage | FileMessage | AdminMessage | any)[];
    tempMessages: any[];
  };
  channels: GroupChannel[];
  selectedChannelType: CaliberChannelType;
  status: string;
  error: string;
}

export const messengerSlice = createSlice({
  name: "messenger",
  initialState: {
    activeChannel: {
      channel: {},
      messages: [],
    },
    channels: [],
    selectedChannelType: CaliberChannelType.CALIBER_COACHING,
    status: "",
    error: "",
  } as MessengerState,
  reducers: {
    setActiveChannel: (state, action) => {
      state.activeChannel.channel = action.payload;
    },
    setMessageCollection: (state, action) => {
      state.activeChannel.messageCollection = action.payload;
    },
    setMessages: (state, action) => {
      if (action.payload.length === 0) {
        state.activeChannel.messages = [];
      }
      if (state.activeChannel.channel.url === action.payload[0]?.channelUrl) {
        state.activeChannel.messages = action.payload.sort(sortMessages);
      }
    },
    addMessages: (state, action) => {
      const messages = state.activeChannel.messages;
      action.payload.forEach((message) => messages.push(message));
      messages.sort(sortMessages);
      state.activeChannel.messages = messages;
    },
    updateMessages: (state, action) => {
      action.payload.forEach((updatedMessage) => {
        const messageIndex = state.activeChannel.messages.findIndex(
          (message) => {
            if ("reqId" in message) {
              return message.reqId === updatedMessage.reqId;
            } else {
              return false;
            }
          }
        );
        if (messageIndex !== -1) {
          state.activeChannel.messages[messageIndex] = updatedMessage;
        }
      });
    },
    updateChannel: (state, action) => {
      const channelIndex = state.channels.findIndex(
        (channel) => channel.url === action.payload.url
      );
      state.channels[channelIndex] = action.payload;
      state.channels.sort(sortChannels);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(connectToSendbird.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(connectToSendbird.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.channels = action.payload;
      })
      .addCase(fetchPreviousMessages.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchPreviousMessages.fulfilled, (state, action) => {
        state.status = "succeeded";
        const messages = state.activeChannel.messages;
        action.payload.forEach((newMessage) => {
          if (
            messages.findIndex(
              (message) => message.messageId === newMessage.messageId
            ) === -1
          ) {
            messages.push(newMessage);
          }
        });
        messages.sort(sortMessages);
        state.activeChannel.messages = messages;
      });
  },
});

// Selectors
export const selectChannels = (state: RootState): Array<GroupChannel> => {
  switch (state.messenger.selectedChannelType) {
    case CaliberChannelType.CALIBER_PRO_GROUP:
      return state.messenger.channels.filter(
        (channel) => channel.customType === CaliberChannelType.CALIBER_PRO_GROUP
      );
      break;
    case CaliberChannelType.CALIBER_COACHING:
      return state.messenger.channels.filter(
        (channel) => channel.customType === ""
      );
      break;
    default:
      return state.messenger.channels;
  }
};

// Actions
export const {
  setActiveChannel,
  setMessageCollection,
  setMessages,
  addMessages,
  updateMessages,
  updateChannel,
} = messengerSlice.actions;

// Helpers
const sortChannels = (a, b) => {
  if (!a.lastMessage?.createdAt) {
    return 1;
  }
  return b.lastMessage.createdAt - a.lastMessage.createdAt;
};

const sortMessages = (a, b) => {
  return a.createdAt - b.createdAt;
};

export default messengerSlice.reducer;
