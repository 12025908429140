export const DELETE_USER_FROM_PLATFORM_SUCCESS =
  "DELETE_USER_FROM_PLATFORM_SUCCESS";
export const DELETE_USER_FROM_PLATFORM_FAIL = "DELETE_USER_FROM_PLATFORM_FAIL";
export const DELETE_USER_FROM_PLATFORM_LOADING =
  "DELETE_USER_FROM_PLATFORM_LOADING";

export interface DeleteUserFromPlatformState {
  isLoading: boolean;
}

export interface DeleteUserFromPlatformAction {
  type: string;
}
