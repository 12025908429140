import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { StoreState } from "../../redux/reducers";
import colors from "../../assets/colors";
import SimpleButton from "../SimpleButton";
import { onSuccessDialogOpen } from "../../redux/actions/ErrorSuccessDialog";
import { uploadCoachProfileImage } from "../../redux/actions/CoachProfile";
import Loader from "../Loader";

interface OwnProps {
  editable?: boolean;
  showLoader?: boolean;
  isError?: boolean;
  index: number;
  onUpload: () => void;
}
interface Props extends OwnProps {
  onSuccessDialogOpen: (message: string) => void;
  uploadCoachProfileImage: (file: any) => void;
}

const NoImg = (props: Props) => {
  const {
    editable,
    showLoader,
    isError,
    index,
    onUpload,
    onSuccessDialogOpen,
    uploadCoachProfileImage,
  } = props;
  const fileInputRef = useRef(null);
  const [isHover, setIsHover] = useState(false);

  const NO_IMG = (
    <div
      className="d-flex flex-column justify-content-center"
      style={{
        height: 48,
        width: 48,
        backgroundColor: colors.caliber_secondary_gray_29,
        borderRadius: 4,
        marginRight: 12,
      }}
    >
      <div
        className="d-flex justify-content-center bold"
        style={{
          color: colors.caliber_secondary_gray_5,
          fontSize: "10px",
          lineHeight: "10px",
        }}
      >
        NO
      </div>
      <div
        className="d-flex justify-content-center bold"
        style={{
          color: colors.caliber_secondary_gray_5,
          fontSize: "10px",
          lineHeight: "10px",
        }}
      >
        IMG
      </div>
    </div>
  );

  return (
    <div
      className="d-flex flex-column"
      onMouseEnter={() => {
        if (editable) {
          setIsHover(true);
        }
      }}
      onMouseLeave={() => setIsHover(false)}
      style={{
        height: 84,
        padding: "8px 12px",
      }}
    >
      <input
        ref={fileInputRef}
        type="file"
        name="image"
        onChange={(event) => {
          if (event.target == null || event.target.files[0] == null) {
            return;
          }
          const file = event.target.files[0];
          if (file.type.startsWith("image")) {
            const imageFile = event.target.files[0];
            if (imageFile?.size > 500 * 1024) {
              onSuccessDialogOpen(
                "Maximum upload size exceeded.\nPlease reduce the file size and try again."
              );
            } else {
              uploadCoachProfileImage(imageFile);
              onUpload();
            }
          } else {
            onSuccessDialogOpen(
              "Invalid file type. Please upload .png format."
            );
          }
        }}
        style={{
          display: "none",
        }}
      />
      <div className="d-flex">
        {NO_IMG}

        <div
          className={`d-flex justify-content-${
            isHover && editable ? "between" : "center"
          } bold`}
          style={{
            flex: 1,
            height: 48,
            backgroundColor: colors.caliber_gray_5,
            color: colors.caliber_dark_gray,
            borderRadius: 8,
            border: `2px solid ${
              isError ? colors.caliber_error_color : colors.caliber_gray_5
            }`,
            position: "relative",
          }}
        >
          <div
            className="d-flex align-items-center medium-bold"
            style={{
              flex: 1,
              height: 48,
              padding: "8px 16px",
              fontSize: "14px",
              color: colors.caliber_secondary_gray_38,
            }}
          >
            {`Upload image ${index + 1}`}
          </div>

          {isHover && editable && !showLoader && (
            <SimpleButton
              className="d-flex justify-content-center align-items-center bold"
              onClick={() => {
                if (!showLoader) {
                  fileInputRef.current.click();
                }
              }}
              style={{
                fontSize: "14px",
                lineHeight: "14px",
                color: colors.caliber_white,
                backgroundColor: colors.caliber_green_200,
                width: 80,
                height: 32,
                borderRadius: 6,
                marginTop: 8,
                marginRight: 8,
              }}
            >
              Upload
            </SimpleButton>
          )}

          {showLoader && (
            <div
              style={{
                position: "absolute",
                top: 7,
                right: 14,
              }}
            >
              <Loader />
            </div>
          )}
        </div>
      </div>

      <div
        className="d-flex align-items-center justify-content-end medium-bold"
        style={{
          fontSize: "12px",
          lineHeight: "28px",
          height: 28,
          color: colors.caliber_secondary_gray_29,
        }}
      >
        {isHover && editable ? "Minimum 48 x 48px ・ Maximum 500kb" : ""}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onSuccessDialogOpen: (message: string) => {
    dispatch(onSuccessDialogOpen(message));
  },
  uploadCoachProfileImage: (file: any) => {
    dispatch(uploadCoachProfileImage(file));
  },
});

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  editable: ownProps.editable,
  showLoader: ownProps.showLoader,
  isError: ownProps.isError,
  index: ownProps.index,
  onUpload: ownProps.onUpload,
});

export default connect(mapStateToProps, mapDispatchToProps)(NoImg);
