import { GraphQLError } from "graphql";
import { CaliberErrorObject } from "../../../types/gqlTypes";

export const OPEN_SUCCESS_DIALOG = "OPEN_SUCCESS_DIALOG";
export const OPEN_ERROR_DIALOG = "OPEN_ERROR_DIALOG";
export const CLOSE_ERROR_SUCCESS_DIALOG = "CLOSE_ERROR_SUCCESS_DIALOG";
export const GET_CALIBER_ERRORS = "GET_CALIBER_ERRORS";
export const THROW_CALIBER_ERROR_LOADING = "THROW_CALIBER_ERROR_LOADING";
export const THROW_CALIBER_ERROR_FAILED = "THROW_CALIBER_ERROR_FAILED";
export const THROW_CALIBER_ERROR_SUCCESS = "THROW_CALIBER_ERROR_SUCCESS";

export interface ErrorSuccessDialogState {
  show: boolean;
  isLoading?: boolean;
  messages: { fold: boolean; text: string }[];
  graphQLErrors?: GraphQLError[];
  errors?: CaliberErrorObject[];
}

export interface ErrorSuccessDialogAction {
  type: string;
  message?: string;
  graphQLErrors?: GraphQLError[];
  errors?: CaliberErrorObject[];
  caliberErrorNumber?: number;
}
