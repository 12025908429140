import apolloClient from "../../../api/apolloClient";
import {
  SET_DEFAULT_WORKOUT_GROUP,
  TOGGLE_ACTIVATION_CLIENT_TRAINING_PLAN,
  UPDATE_CLIENT_GYM_STEP_TEMPLATE,
  UPDATE_CLIENT_TRAINING_PLAN,
  UPDATE_CLIENT_WORKOUT_GROUP_TEMPLATE,
  UPDATE_CLIENT_WORKOUT_TEMPLATE,
} from "../../../api/gql/trainingPrograms/clientTrainingPlan";
import {
  TOGGLE_ACTIVATION_CUSTOM_TRAINING_PLAN,
  UPDATE_CUSTOM_TRAINING_PLAN,
  UPDATE_GYM_STEP_FOR_CUSTOM_TRAINING_PLAN,
  UPDATE_LIBRARY_TRAINING_PLAN,
  UPDATE_WORKOUT_IN_CUSTOM_TRAINING_PLAN,
} from "../../../api/gql/trainingPrograms/customTrainingPlan";
import {
  UPDATE_MASTER_GYM_STEP,
  UPDATE_MASTER_TRAINING_PLAN,
  UPDATE_MASTER_WORKOUT,
} from "../../../api/gql/trainingPrograms/masterTrainingPlan";
import { ProgramType } from "../../../components/TrainingProgram";
import {
  ClientGymStepTemplate,
  ClientTrainingPlan,
  ClientWorkoutGroupTemplate,
  ClientWorkoutTemplate,
  CustomTrainingPlan,
  LibraryTrainingPlan,
  MasterGymStep,
  MasterTrainingPlan,
  MasterWorkout,
  MutationToggleCustomTrainingPlanStateArgs,
  MutationUpdateClientGymStepTemplateArgs,
  MutationUpdateClientTrainingPlanArgs,
  MutationUpdateClientTrainingPlanStateArgs,
  MutationUpdateClientWorkoutGroupTemplateArgs,
  MutationUpdateClientWorkoutTemplateArgs,
  MutationUpdateCustomTrainingPlanArgs,
  MutationUpdateLibraryTrainingPlanArgs,
  MutationUpdateMasterGymStepArgs,
  MutationUpdateMasterGymStepForCustomTrainingPlanArgs,
  MutationUpdateMasterTrainingPlanArgs,
  MutationUpdateMasterWorkoutArgs,
  MutationUpdateMasterWorkoutInCustomTrainingPlanArgs,
} from "../../../types/gqlTypes";
import { getTrainer } from "../../reducers";
import { selectCurrentPlan } from "../../reducers/TrainingPrograms/selectors";
import { ClientDetailState } from "../ClientDetail/types";
import { getTrainingPlan, getTrainingPlans } from "./get";
import {
  SET_DEFAULT_WORKOUT_GROUP_FAIL,
  SET_DEFAULT_WORKOUT_GROUP_LOADING,
  SET_DEFAULT_WORKOUT_GROUP_SUCCESS,
  TOGGLE_ACTIVE_CLIENT_TRAINING_PLAN_FAIL,
  TOGGLE_ACTIVE_CLIENT_TRAINING_PLAN_LOADING,
  TOGGLE_ACTIVE_CLIENT_TRAINING_PLAN_SUCCESS,
  TOGGLE_CUSTOM_TRAINING_PLAN_STATE_FAIL,
  TrainingProgramsState,
  UPDATE_GYM_STEP_FAIL,
  UPDATE_GYM_STEP_LOADING,
  UPDATE_GYM_STEP_SUCCESS,
  UPDATE_TRAINING_PLAN_FAIL,
  UPDATE_TRAINING_PLAN_LOADING,
  UPDATE_TRAINING_PLAN_SUCCESS,
  UPDATE_WORKOUT_FAIL,
  UPDATE_WORKOUT_GROUP_FAIL,
  UPDATE_WORKOUT_GROUP_LOADING,
  UPDATE_WORKOUT_GROUP_SUCCESS,
  UPDATE_WORKOUT_LOADING,
  UPDATE_WORKOUT_SUCCESS,
} from "./types";

export const updateGymStepInPlan =
  (
    args:
      | MutationUpdateClientGymStepTemplateArgs
      | MutationUpdateMasterGymStepArgs
      | MutationUpdateMasterGymStepForCustomTrainingPlanArgs
  ) =>
  async (dispatch, getState) => {
    const trainingProgramsState = getState()
      .trainingProgramsState as TrainingProgramsState;

    if (trainingProgramsState.isSaving || trainingProgramsState.isLoading) {
      return;
    }
    dispatch({ type: UPDATE_GYM_STEP_LOADING });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );

      const { programType } = trainingProgramsState;
      let updatedGymStep: MasterGymStep | ClientGymStepTemplate;
      if (
        programType === ProgramType.Custom ||
        programType === ProgramType.Library
      ) {
        const customPlan = selectCurrentPlan(getState()) as
          | CustomTrainingPlan
          | LibraryTrainingPlan;
        updatedGymStep = (
          await client.mutate({
            mutation: UPDATE_GYM_STEP_FOR_CUSTOM_TRAINING_PLAN,
            variables: {
              ...args,
              customTrainingPlanId: customPlan.id,
              trainerId: getTrainer(getState()).id,
            },
          })
        ).data.updateMasterGymStepForCustomTrainingPlan as MasterGymStep;
      } else if (programType === ProgramType.Master) {
        updatedGymStep = (
          await client.mutate({
            mutation: UPDATE_MASTER_GYM_STEP,
            variables: args,
          })
        ).data.updateMasterGymStep as MasterGymStep;
      } else if (programType === ProgramType.Client) {
        const { user: selectedUser }: ClientDetailState =
          getState().clientDetailState;

        updatedGymStep = (
          await client.mutate({
            mutation: UPDATE_CLIENT_GYM_STEP_TEMPLATE,
            variables: {
              ...args,
              clientId: selectedUser.id,
            },
          })
        ).data.updateClientGymStepTemplate as ClientGymStepTemplate;
      }

      dispatch({
        type: UPDATE_GYM_STEP_SUCCESS,
        gymStep: updatedGymStep,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_GYM_STEP_FAIL,
        data: error,
      });
    }
  };

export const updateWorkoutToPlan =
  (
    args:
      | MutationUpdateClientWorkoutTemplateArgs
      | MutationUpdateMasterWorkoutArgs
      | MutationUpdateMasterWorkoutInCustomTrainingPlanArgs
  ) =>
  async (dispatch, getState) => {
    if (getState().trainingProgramsState.isSaving) {
      return;
    }
    dispatch({ type: UPDATE_WORKOUT_LOADING });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );

      const { programType } = getState().trainingProgramsState;
      let updatedWorkout: ClientWorkoutTemplate | MasterWorkout;
      if (
        programType === ProgramType.Custom ||
        programType === ProgramType.Library
      ) {
        updatedWorkout = (
          await client.mutate({
            mutation: UPDATE_WORKOUT_IN_CUSTOM_TRAINING_PLAN,
            variables: args,
          })
        ).data.updateMasterWorkoutInCustomTrainingPlan as MasterWorkout;
      } else if (programType === ProgramType.Master) {
        updatedWorkout = (
          await client.mutate({
            mutation: UPDATE_MASTER_WORKOUT,
            variables: args,
          })
        ).data.updateMasterWorkout as MasterWorkout;
      } else if (programType === ProgramType.Client) {
        updatedWorkout = (
          await client.mutate({
            mutation: UPDATE_CLIENT_WORKOUT_TEMPLATE,
            variables: args,
          })
        ).data.updateClientWorkoutTemplate as ClientWorkoutTemplate;
      }

      dispatch({
        type: UPDATE_WORKOUT_SUCCESS,
        workout: updatedWorkout,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_WORKOUT_FAIL,
        data: error,
      });
    }
  };

export const updateTrainingPlan =
  (
    args:
      | MutationUpdateClientTrainingPlanArgs
      | MutationUpdateCustomTrainingPlanArgs
      | MutationUpdateLibraryTrainingPlanArgs
      | MutationUpdateMasterTrainingPlanArgs
  ) =>
  async (dispatch, getState) => {
    if (getState().trainingProgramsState.isSaving) {
      return;
    }
    dispatch({ type: UPDATE_TRAINING_PLAN_LOADING });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );

      const { programType } = getState().trainingProgramsState;
      let updatedPlan:
        | CustomTrainingPlan
        | LibraryTrainingPlan
        | MasterTrainingPlan
        | ClientTrainingPlan;
      if (programType === ProgramType.Custom) {
        updatedPlan = (
          await client.mutate({
            mutation: UPDATE_CUSTOM_TRAINING_PLAN,
            variables: args,
          })
        ).data.updateCustomTrainingPlan as CustomTrainingPlan;
      } else if (programType === ProgramType.Library) {
        updatedPlan = (
          await client.mutate({
            mutation: UPDATE_LIBRARY_TRAINING_PLAN,
            variables: args,
          })
        ).data.updateLibraryTrainingPlan as LibraryTrainingPlan;
      } else if (programType === ProgramType.Master) {
        updatedPlan = (
          await client.mutate({
            mutation: UPDATE_MASTER_TRAINING_PLAN,
            variables: args,
          })
        ).data.updateMasterTrainingPlan as MasterTrainingPlan;
      } else if (programType === ProgramType.Client) {
        updatedPlan = (
          await client.mutate({
            mutation: UPDATE_CLIENT_TRAINING_PLAN,
            variables: args,
          })
        ).data.updateClientTrainingPlan as ClientTrainingPlan;
      }

      dispatch({
        type: UPDATE_TRAINING_PLAN_SUCCESS,
        plan: updatedPlan,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_TRAINING_PLAN_FAIL,
        data: error,
      });
    }
  };
export const toggleActiveClientTrainingPlan =
  (args: {
    clientId: string;
    activatePlanId?: string;
    deactivatePlanId?: string;
  }) =>
  async (dispatch, getState) => {
    if (getState().trainingProgramsState.isSaving) {
      return;
    }
    dispatch({ type: TOGGLE_ACTIVE_CLIENT_TRAINING_PLAN_LOADING });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      if (args.deactivatePlanId) {
        await client.mutate({
          mutation: TOGGLE_ACTIVATION_CLIENT_TRAINING_PLAN,
          variables: {
            clientId: args.clientId,
            clientTrainingPlanId: args.deactivatePlanId,
          } as MutationUpdateClientTrainingPlanStateArgs,
        });
      }
      if (args.activatePlanId) {
        await client.mutate({
          mutation: TOGGLE_ACTIVATION_CLIENT_TRAINING_PLAN,
          variables: {
            clientId: args.clientId,
            clientTrainingPlanId: args.activatePlanId,
          } as MutationUpdateClientTrainingPlanStateArgs,
        });
      }

      dispatch(
        getTrainingPlans({ clientId: args.clientId }, ProgramType.Client)
      );
    } catch (error) {
      dispatch({
        type: TOGGLE_ACTIVE_CLIENT_TRAINING_PLAN_FAIL,
        data: error,
      });
    }
  };

export const toggleCustomTrainingPlanState =
  (customTrainingPlanId: string) => async (dispatch, getState) => {
    if (getState().trainingProgramsState.isSaving) {
      return;
    }
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      await client.mutate({
        mutation: TOGGLE_ACTIVATION_CUSTOM_TRAINING_PLAN,
        variables: {
          customTrainingPlanId,
        } as MutationToggleCustomTrainingPlanStateArgs,
      });
      dispatch(
        getTrainingPlan(
          {
            trainingPlanId: customTrainingPlanId,
          },
          true
        )
      );
    } catch (error) {
      dispatch({
        type: TOGGLE_CUSTOM_TRAINING_PLAN_STATE_FAIL,
        data: error,
      });
    }
  };

export const deactivateAndActivateClientTrainingPlan =
  (args: { clientId: string; activatePlanId: string }) =>
  async (dispatch, getState) => {
    if (getState().trainingProgramsState.isSaving) {
      return;
    }
    dispatch({ type: TOGGLE_ACTIVE_CLIENT_TRAINING_PLAN_LOADING });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );

      const updatedPlan = (
        await client.mutate({
          mutation: UPDATE_CLIENT_TRAINING_PLAN,
          variables: args,
        })
      ).data.updateClientTrainingPlan as ClientTrainingPlan;

      dispatch({
        type: TOGGLE_ACTIVE_CLIENT_TRAINING_PLAN_SUCCESS,
        plan: updatedPlan,
      });
    } catch (error) {
      dispatch({
        type: TOGGLE_ACTIVE_CLIENT_TRAINING_PLAN_FAIL,
        data: error,
      });
    }
  };

export const updateWorkoutGroup =
  (args: MutationUpdateClientWorkoutGroupTemplateArgs) =>
  async (dispatch, getState) => {
    if (getState().trainingProgramsState.isSaving) {
      return;
    }
    dispatch({ type: UPDATE_WORKOUT_GROUP_LOADING });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );

      const updatedWorkoutGroup = (
        await client.mutate({
          mutation: UPDATE_CLIENT_WORKOUT_GROUP_TEMPLATE,
          variables: args,
        })
      ).data.updateClientWorkoutGroupTemplate as ClientWorkoutGroupTemplate;

      dispatch({
        type: UPDATE_WORKOUT_GROUP_SUCCESS,
        workoutGroup: updatedWorkoutGroup,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_WORKOUT_GROUP_FAIL,
        data: error,
      });
    }
  };

export const setDefaultWorkoutGroup =
  (clientId: string, clientWorkoutGroupTemplateId: string) =>
  async (dispatch, getState) => {
    if (getState().trainingProgramsState.isSaving) {
      return;
    }
    dispatch({ type: SET_DEFAULT_WORKOUT_GROUP_LOADING });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );

      const updatedPlan = (
        await client.mutate({
          mutation: SET_DEFAULT_WORKOUT_GROUP,
          variables: {
            clientId,
            clientWorkoutGroupTemplateId,
          },
        })
      ).data.setDefaultWorkoutGroupForClientTrainingPlan as ClientTrainingPlan;

      dispatch({
        type: SET_DEFAULT_WORKOUT_GROUP_SUCCESS,
        plan: updatedPlan,
      });
    } catch (error) {
      dispatch({
        type: SET_DEFAULT_WORKOUT_GROUP_FAIL,
        data: error,
      });
    }
  };
