import React from "react";
import colors from "../../../assets/colors";
import { ClientHabitTarget } from "../../../types/gqlTypes";
import { numberWithCommas } from "../../../utils";
import ArrowIcons from "../../Icons/ArrowIcons";
import CalendarIcons, { CalendarIconType } from "../../Icons/CalendarIcons";
import SimpleButton from "../../SimpleButton";

interface Props {
  isClickable?: boolean;
  onClick?: () => void;
  height?: number;
  disabled?: boolean;
  habit?: ClientHabitTarget;
}

const StepsHabitCard = (props: Props) => {
  const { isClickable, onClick, height, disabled, habit } = props;
  return (
    <SimpleButton
      nofocus
      nopointer={!isClickable || disabled}
      onClick={() => {
        if (isClickable && !disabled) {
          onClick?.();
        }
      }}
      className="d-flex"
      style={{
        height: height || 80,
        backgroundColor: colors.caliber_secondary_gray_5,
        borderRadius: 8,
        padding: 8,
        position: "relative",
        opacity: disabled ? 0.3 : 1,
        flex: 1,
      }}
    >
      <div
        style={{
          marginLeft: 4,
          marginRight: 16,
        }}
      >
        <CalendarIcons
          key="asdsad"
          height={(height || 80) - 2 * 8}
          width={(height || 80) - 2 * 8}
          type={CalendarIconType.STEPS_HABIT_LARGE}
        />
      </div>

      <div className="d-flex flex-column justify-content-center">
        <div
          className="bold"
          style={{
            fontSize: "16px",
            lineHeight: "20px",
            letterSpacing: "-0.033em",
            color: colors.black,
            marginBottom: 4,
          }}
        >
          Steps
        </div>
        <div
          className="medium-bold"
          style={{
            fontSize: "14px",
            lineHeight: "17px",
            letterSpacing: "-0.022em",
            color: colors.caliber_gray_text,
          }}
        >
          {habit
            ? `Daily step target is ${numberWithCommas(habit?.target)} steps.`
            : "Create a daily step target for your client."}
        </div>
      </div>

      {isClickable && !disabled && (
        <div
          style={{
            position: "absolute",
            top: (height || 80) / 2 - 11,
            right: 25,
          }}
        >
          <ArrowIcons isLeft={false} height={22} width={20} color="#AEB1BF" />
        </div>
      )}
    </SimpleButton>
  );
};

export default StepsHabitCard;
