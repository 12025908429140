import React, { useEffect, useRef, useState } from "react";
import colors from "../../../assets/colors";
import { ClientPackageType, ClientSearchResult } from "../../../types/gqlTypes";

interface Props {
  isLastColumn?: boolean;
  isDragging?: boolean;
  width?: number;
  row: ClientSearchResult;
  updateCalls: (clientId: string, callsLeft: number) => void;
}

const CallsLeftCell = (props: Props) => {
  const { isLastColumn, isDragging, width, row, updateCalls } = props;
  const inputRef = useRef(null);

  const color = colors.black;
  const borderColor = colors.table_gray;
  const rowHeight = 64;
  const [hovered, setHovered] = useState(false);
  const [localValue, setLocalValue] = useState("");
  const isPremium = row?.clientPackageType === ClientPackageType.Premium;
  const doCallsExist = typeof row?.callsLeft === "number";

  useEffect(() => {
    if (doCallsExist || isPremium) {
      setLocalValue(`${row?.callsLeft || 0}`);
    }
  }, [row]);

  const inludedKeys = [
    "Backspace",
    "Delete",
    "Control",
    "ArrowRight",
    "ArrowLeft",
  ];

  const input = (
    <input
      ref={inputRef}
      tabIndex={0}
      value={localValue?.toString()}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onChange={(event) => {
        const { value } = event.target;
        if (Number(value) >= 0 && Number(value) <= 50) {
          updateCalls(row.id, Number(value));
          setLocalValue(value);
        }
      }}
      onKeyDown={(event) => {
        const { key } = event;
        if (key === "Enter") {
          inputRef?.current?.blur();
        }
        if ((!inludedKeys.includes(key) && isNaN(Number(key))) || key === " ") {
          event.preventDefault();
        }
      }}
      onBlur={() => {
        if (localValue === "") {
          setLocalValue("0");
        }
      }}
      onClick={() => {
        if (localValue === "0") {
          setLocalValue("");
        } else {
          inputRef.current?.focus();
          inputRef.current.selectionStart = 0;
          inputRef.current.selectionEnd = localValue?.length;
        }
      }}
      style={{
        textAlign: "center",
        backgroundColor: hovered ? "#E8EAEE" : "#ffffff",
        color: colors.caliber_secondary,
        borderRadius: 8,
        borderColor: colors.caliber_white,
        boxShadow: "none",
        border: "none",
        borderWidth: 0,
        width: 30,
        height: 28,
        fontSize: "14px",
        padding: "4px 6px",
        marginRight: 4,
      }}
    />
  );

  return (
    <td
      className="d-flex"
      style={{
        width,
        minWidth: isLastColumn && width,
        backgroundColor: colors.caliber_gray_bg,
        position: "relative",
      }}
    >
      <div
        className="d-flex"
        style={{
          position: "absolute",
          top: isDragging ? -8 : 0,
          backgroundColor: isDragging ? "#E8EAEE" : "transparent",
          width: "100%",
          height: isDragging ? rowHeight + 16 : rowHeight,
          zIndex: isDragging ? 4 : 1,
          marginBottom: isDragging ? 0 : 8,
        }}
      >
        <div
          className="d-flex align-items-center"
          style={{
            flex: "1 1 100%",
            fontSize: "14px",
            lineHeight: "20px",
            color,
            zIndex: isDragging ? 3 : 2,
            backgroundColor: colors.caliber_white,
            height: rowHeight,
            borderRight: `1px solid ${borderColor}`,
            paddingLeft: 18,
            margin: isDragging ? 8 : 0,
            borderTopRightRadius: isLastColumn && 8,
            borderBottomRightRadius: isLastColumn && 8,

            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {(doCallsExist || isPremium) && input}
          <div>{doCallsExist || isPremium ? "calls" : "n/a"}</div>
        </div>
      </div>
    </td>
  );
};

export default CallsLeftCell;
