import { format, parse } from "date-fns";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import colors from "../../assets/colors";
import { createUpdateClientNutritionTarget } from "../../redux/actions/ClientNutritionTarget";
import { StoreState } from "../../redux/reducers";
import {
  ClientNutritionTarget,
  MutationCreateUpdateClientNutritionTargetArgs,
  NutritionTargetPhaseType,
  NutritionTargetProtocolType,
} from "../../types/gqlTypes";
import Dropdown from "../Dropdown";
import Loader from "../Loader";

interface Props {
  createUpdateClientNutritionTarget: (
    args: MutationCreateUpdateClientNutritionTargetArgs
  ) => void;
  isLoading: boolean;
  isSaving: boolean;
  clientNutritionTarget: ClientNutritionTarget;
}

const NutritionDietTypes = (props: Props) => {
  const {
    createUpdateClientNutritionTarget,
    isLoading,
    isSaving,
    clientNutritionTarget,
  } = props;
  const { id: clientId } = useParams();

  const [state, setState] = useState({
    ...clientNutritionTarget,
    hasChanged: false,
  });
  const { isLogging, protocol, phase, isIntermittentFasting, hasChanged } =
    state;

  useEffect(() => {
    setState({ ...clientNutritionTarget, hasChanged: false });
  }, [
    clientNutritionTarget?.isLogging,
    clientNutritionTarget?.protocol,
    clientNutritionTarget?.phase,
    clientNutritionTarget?.isIntermittentFasting,
  ]);

  const phaseOptions = [
    { text: "Cutting", value: NutritionTargetPhaseType.Cutting },
    { text: "Bulking", value: NutritionTargetPhaseType.Bulking },
    { text: "Reverse Diet", value: NutritionTargetPhaseType.ReverseDiet },
    { text: "Maintenance", value: NutritionTargetPhaseType.Maintenance },
  ];
  const protocolOptions = [
    { text: "Flexible Diet", value: NutritionTargetProtocolType.FlexibleDiet },
    { text: "Meal Plan", value: NutritionTargetProtocolType.MealPlan },
    { text: "Keto", value: NutritionTargetProtocolType.Keto },
    { text: "Paleo", value: NutritionTargetProtocolType.Paleo },
    { text: "Vegan", value: NutritionTargetProtocolType.Vegan },
    { text: "Vegetarian", value: NutritionTargetProtocolType.Vegetarian },
    { text: "Low Carb", value: NutritionTargetProtocolType.LowCarb },
    { text: "Whole30", value: NutritionTargetProtocolType.Whole_30 },
    { text: "IIFYM", value: NutritionTargetProtocolType.Iifym },
    { text: "High Protein", value: NutritionTargetProtocolType.HighProtein },
  ];
  const booleanOptions = [
    { text: "Yes", value: true },
    { text: "No", value: false },
  ];

  const onSave = () => {
    setState({ ...state, hasChanged: false });
    const obj: MutationCreateUpdateClientNutritionTargetArgs = {};
    if (isLogging !== clientNutritionTarget?.isLogging) {
      obj.isLogging = isLogging;
      obj.isLoggingActiveDate = format(new Date(), "yyyy-MM-dd");
    }
    if (protocol !== clientNutritionTarget?.protocol) {
      obj.protocol = protocol;
      obj.protocolActiveDate = format(new Date(), "yyyy-MM-dd");
    }
    if (phase !== clientNutritionTarget?.phase) {
      obj.phase = phase;
      obj.phaseActiveDate = format(new Date(), "yyyy-MM-dd");
    }
    if (
      isIntermittentFasting !== clientNutritionTarget?.isIntermittentFasting
    ) {
      obj.isIntermittentFasting = isIntermittentFasting;
      obj.isIntermittentFastingActiveDate = format(new Date(), "yyyy-MM-dd");
    }
    createUpdateClientNutritionTarget({
      ...obj,
      clientId,
    });
  };

  const dropdown = (key, options) => (
    <div>
      <Dropdown
        key={key}
        onSelect={(value) => {
          setState({ ...state, hasChanged: true, [key]: value });
        }}
        value={state[key]}
        items={options}
        height={24}
        fontSize={14}
        bgColor={colors.caliber_light_gray}
        borderColor={colors.caliber_light_gray}
        textColor={colors.caliber_secondary}
      />
    </div>
  );

  const parseAndFormat = (date) => {
    if (date) {
      return format(parse(date, "yyyy-MM-dd", new Date()), "M.d.yyyy");
    }
    return "--";
  };

  const dateBadge = (date) => {
    return (
      <div
        className="d-flex justify-content-between align-items-center"
        style={{
          height: 20,
          margin: "20px 0px",
        }}
      >
        <div
          style={{
            color: colors.caliber_secondary,
            fontSize: "12px",
            lineHeight: "15px",
            margin: "7px 0px",
          }}
        >
          Active since
        </div>
        <div
          className="d-flex flex-column justify-content-center align-items-center medium-bold"
          style={{
            backgroundColor: colors.caliber_gray_bg,
            padding: "2px 10px",
            borderRadius: 4,
            minWidth: 90,
            fontSize: "12px",
            lineHeight: "16px",
          }}
        >
          {parseAndFormat(date)}
        </div>
      </div>
    );
  };
  const saveButton = (
    <div
      role="button"
      tabIndex={0}
      className="d-flex flex-column justify-content-center align-items-center pointer nofocus"
      style={{
        backgroundColor: "#D4F5E1",
        borderRadius: 4,
        height: 22,
        width: 46,
        color: colors.caliber_green_200,
        fontSize: "14px",
        lineHeight: "32px",
      }}
      onClick={onSave}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          onSave();
        }
      }}
    >
      Save
    </div>
  );
  const labelStyle = {
    fontSize: "14px",
    lineHeight: "17px",
    color: colors.caliber_gray_border,
    margin: "18px 0px 8px 0px",
  };

  return (
    <div
      className="d-flex flex-column medium-bold"
      style={{
        padding: "16px 24px",
        width: 272,
        minHeight: 557,
        backgroundColor: colors.caliber_white,
        borderRadius: 8,
        position: "relative",
      }}
    >
      {(isLoading || isSaving) && (
        <div
          style={{
            position: "absolute",
            top: "30%",
            left: "48%",
          }}
        >
          <Loader />
        </div>
      )}
      <div
        className="d-flex justify-content-between"
        style={{
          fontSize: "20px",
          lineHeight: "25px",
          letterSpacing: "-0.4px",
          marginTop: 4,
          color: colors.caliber_secondary,
        }}
      >
        Diet Types
        {hasChanged && saveButton}
      </div>
      <div style={labelStyle}>Logging</div>
      {dropdown("isLogging", booleanOptions)}
      {dateBadge(state?.isLoggingActiveDate)}
      <div style={labelStyle}>Type</div>
      {dropdown("protocol", protocolOptions)}
      {dateBadge(state?.protocolActiveDate)}
      <div style={labelStyle}>Phase</div>
      {dropdown("phase", phaseOptions)}
      {dateBadge(state?.phaseActiveDate)}
      <div style={labelStyle}>Intermittent Fasting</div>
      {dropdown("isIntermittentFasting", booleanOptions)}
      {dateBadge(state?.isIntermittentFastingActiveDate)}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  createUpdateClientNutritionTarget: (
    args: MutationCreateUpdateClientNutritionTargetArgs
  ) => {
    dispatch(createUpdateClientNutritionTarget(args));
  },
});

const mapStateToProps = (state: StoreState) => ({
  clientNutritionTarget: state.clientNutritionTargetState.clientNutritionTarget,
  isLoading: state.clientNutritionTargetState.isLoading,
  isSaving: state.clientNutritionTargetState.isSaving,
});

export default connect(mapStateToProps, mapDispatchToProps)(NutritionDietTypes);
