import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  createUpdateClientHabitTarget,
  deleteClientHabitTarget,
  getClientHabitsTargets,
  resetClientHabitTargetState,
} from "../../redux/actions/Habits";
import { ClientHabitsState } from "../../redux/actions/Habits/types";
import { StoreState } from "../../redux/reducers";
import {
  HabitType,
  MutationCreateUpdateClientHabitTargetArgs,
} from "../../types/gqlTypes";
import AddNewHabit from "./AddNewHabit";
import EmptyHabitsState from "./EmptyHabitsState";
import HabitCard from "./HabitCard";
import Header from "./Header";
import UpdateHabitModal from "./UpdateHabitModal";

interface Props {
  clientHabitsState: ClientHabitsState;
  getClientHabitsTargets: (clientId: string) => void;
  createUpdateClientHabitTarget: (
    args: MutationCreateUpdateClientHabitTargetArgs
  ) => void;
  deleteClientHabitTarget: (clientId: string, habitTargetId: string) => void;
  resetClientHabitTargetState: () => void;
}

const Habits = (props: Props) => {
  const {
    clientHabitsState,
    getClientHabitsTargets,
    createUpdateClientHabitTarget,
    deleteClientHabitTarget,
    resetClientHabitTargetState,
  } = props;
  const { id: clientId } = useParams();

  const [isNewHabitModalOpen, openNewHabitModal] = useState(false);
  const [selectedHabit, setSelectedHabit] = useState<HabitType>(null);
  const [isUpdatingHabit, setUpdatingHabit] = useState(false);

  const habitList = [HabitType.Steps];
  const habits = useMemo(() => {
    const obj = {};
    clientHabitsState?.habits?.forEach((habit) => {
      if (habit?.habitType) {
        obj[habit.habitType] = habit;
      }
    });
    return obj;
  }, [clientHabitsState?.habits]);

  const createUpdateNewHabit = (habitType: HabitType, target: number) => {
    createUpdateClientHabitTarget({
      clientId,
      habitType,
      target,
    });
  };

  const onClose = () => {
    setSelectedHabit(null);
    openNewHabitModal(false);
    setUpdatingHabit(false);
  };

  useEffect(() => {
    if (clientId) {
      getClientHabitsTargets(clientId);
    }
    return () => {
      resetClientHabitTargetState();
    };
  }, [clientId]);

  const isThereNoHabits = Object.keys(habits).length === 0;
  const canNewHabitBeCreated = Object.keys(habits).length !== 1;

  return (
    <div
      className="d-flex flex-column"
      style={{
        flex: 1,
      }}
    >
      {isNewHabitModalOpen && (
        <AddNewHabit
          onClose={() => openNewHabitModal(false)}
          onClick={(habitType) => {
            openNewHabitModal(false);
            setSelectedHabit(habitType);
          }}
        />
      )}

      {selectedHabit && (
        <UpdateHabitModal
          habitType={selectedHabit}
          habitToUpdate={isUpdatingHabit && habits[selectedHabit]}
          onSuccess={(habitType, target) => {
            createUpdateNewHabit(habitType, target);
            onClose();
          }}
          onClose={onClose}
          onBack={() => {
            if (isUpdatingHabit) {
              setUpdatingHabit(false);
            } else {
              openNewHabitModal(true);
            }
            setSelectedHabit(null);
          }}
        />
      )}

      {isThereNoHabits && (
        <EmptyHabitsState onNewHabitClick={() => openNewHabitModal(true)} />
      )}

      {!isThereNoHabits && (
        <div className="d-flex flex-column">
          <Header
            disabled={!canNewHabitBeCreated}
            onNewHabitClick={() => openNewHabitModal(true)}
          />

          <div className="d-flex flex-wrap">
            {habitList.map((habitType) => {
              return (
                habits?.[habitType] && (
                  <HabitCard
                    key={habitType}
                    onEdit={() => {
                      setSelectedHabit(habitType);
                      setUpdatingHabit(true);
                      openNewHabitModal(false);
                    }}
                    onDelete={() => {
                      deleteClientHabitTarget(
                        clientId,
                        habits?.[habitType]?.id
                      );
                    }}
                    habit={habits[habitType]}
                  />
                )
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getClientHabitsTargets: (clientId: string) => {
    dispatch(getClientHabitsTargets(clientId));
  },
  createUpdateClientHabitTarget: (
    args: MutationCreateUpdateClientHabitTargetArgs
  ) => {
    dispatch(createUpdateClientHabitTarget(args));
  },
  deleteClientHabitTarget: (clientId: string, habitTargetId: string) => {
    dispatch(deleteClientHabitTarget(clientId, habitTargetId));
  },
  resetClientHabitTargetState: () => {
    dispatch(resetClientHabitTargetState());
  },
});

const mapStateToProps = (state: StoreState) => ({
  clientHabitsState: state.clientHabitsState,
});

export default connect(mapStateToProps, mapDispatchToProps)(Habits);
