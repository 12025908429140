import React, { useState } from "react";
import colors from "../../assets/colors";
import CalendarIcons, { CalendarIconType } from "../Icons/CalendarIcons";
import SimpleButton from "../SimpleButton";

interface Props {
  onSelect: (index: string) => void;
  disableProgressPhotos?: boolean;
  noHabitEnabled?: boolean;
}

const SelectEventType = (props: Props) => {
  const { onSelect, disableProgressPhotos, noHabitEnabled } = props;
  const [hovered, setHovered] = useState(null);

  const eventsProperty = [
    {
      text: "Workout",
      icon: <CalendarIcons type={CalendarIconType.WORKOUT_CIRCLE} />,
    },
    {
      text: "Cardio",
      icon: <CalendarIcons type={CalendarIconType.CARDIO_CIRCLE} />,
    },
    {
      text: "Body Stat",
      icon: <CalendarIcons type={CalendarIconType.BODY_STAT_CIRCLE} />,
    },
    {
      text: "Progress Photos",
      icon: <CalendarIcons type={CalendarIconType.PROGRESS_PHOTO_CIRCLE} />,
    },
  ];
  if (!noHabitEnabled) {
    eventsProperty.push({
      text: "Habit",
      icon: <CalendarIcons type={CalendarIconType.HABIT_CIRCLE} />,
    });
  }
  return (
    <div>
      <div
        className="medium-bold"
        style={{
          color: colors.caliber_gray_text,
          fontSize: "14px",
          lineHeight: "24px",
          margin: "16px 0px",
        }}
      >
        Select event type
      </div>
      <div className="d-flex flex-wrap">
        {eventsProperty.map((event, index) => {
          const disabled =
            event.text === "Progress Photos" && disableProgressPhotos;
          return (
            <SimpleButton
              key={event.text}
              onMouseEnter={() => setHovered(disabled ? null : index)}
              onMouseLeave={() => setHovered(null)}
              onClick={() => {
                onSelect(index.toString());
              }}
              disabled={disabled}
              nofocus
              className="d-flex medium-bold"
              style={{
                backgroundColor:
                  hovered === index
                    ? colors.caliber_secondary_gray_11
                    : colors.caliber_gray_bg,
                borderRadius: 4,
                width: 196,
                height: 48,
                padding: 8,
                marginRight: index % 2 === 0 && 16,
                marginTop: index > 1 && 16,
                opacity: disabled ? 0.4 : 1,
              }}
            >
              <div
                style={{
                  margin: "0px 8px",
                  height: 32,
                }}
              >
                {event.icon}
              </div>
              <div
                className="d-flex align-items-center"
                style={{
                  flex: 1,
                  color: colors.caliber_secondary,
                  fontSize: "14px",
                  lineHeight: "24px",
                }}
              >
                {event.text}
              </div>
            </SimpleButton>
          );
        })}
      </div>
    </div>
  );
};

export default SelectEventType;
