import React from "react";
import { connect } from "react-redux";
import { StoreState } from "../../redux/reducers";
import CopyPlanToClients from "./CopyPlanToClients";
import CopyPlanToTrainer from "./CopyPlanToTrainer";

interface Props {
  showCopyPlanToClients: boolean;
  showCopyPlanToTrainer: boolean;
  showCreateCustomPlan: boolean;
}

const CopyTrainingPlanModal = (props: Props) => {
  const { showCopyPlanToClients, showCopyPlanToTrainer, showCreateCustomPlan } =
    props;

  return (
    <div>
      {showCopyPlanToClients && <CopyPlanToClients />}

      {(showCopyPlanToTrainer || showCreateCustomPlan) && (
        // @ts-ignore
        <CopyPlanToTrainer />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state: StoreState) => ({
  showCopyPlanToClients: state.trainingProgramsState.showCopyPlanToClients,
  showCopyPlanToTrainer: state.trainingProgramsState.showCopyPlanToTrainer,
  showCreateCustomPlan: state.trainingProgramsState.showCreateCustomPlan,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CopyTrainingPlanModal);
