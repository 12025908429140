import { format } from "date-fns";
import { getAppConfig } from "../../../config";
import {
  ClientCronometerUserDiaryAction,
  GET_CLIENT_CRONOMETER_USER_DIARY_FAIL,
  GET_CLIENT_CRONOMETER_USER_DIARY_LOADING,
  GET_CLIENT_CRONOMETER_USER_DIARY_SUCCESS,
} from "./types";

export const getClientCronometerUserDiary =
  (clientId: string, date: Date) => async (dispatch, getState) => {
    if (getState().clientDetailState.isLoading) {
      return;
    }
    dispatch({
      type: GET_CLIENT_CRONOMETER_USER_DIARY_LOADING,
    });
    try {
      const apiUrl = `${
        getAppConfig().apiUrl
      }/cronometer/userDiarySummary/client/${clientId}?day=${format(
        date,
        "yyyy-MM-dd"
      )}`;
      const authInfo = getState().authState;

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authInfo.authToken}`,
        },
      });
      const json = await response.json();

      dispatch({
        type: GET_CLIENT_CRONOMETER_USER_DIARY_SUCCESS,
        userDiary: json,
      } as ClientCronometerUserDiaryAction);
    } catch (error) {
      dispatch({
        type: GET_CLIENT_CRONOMETER_USER_DIARY_FAIL,
      });
    }
  };
