import apolloClient from "../../../api/apolloClient";
import { SEARCH_CLIENTS } from "../../../api/gql/users/clients";
import {
  CaliberPageImpl_ClientSearchResult,
  ClientPackageType,
  ClientSearchResultSortCriteria,
  SortOrder,
  UserStatusType,
} from "../../../types/gqlTypes";
import { getTrainer } from "../../reducers";
import {
  ClientSearchAction,
  CLIENTS_LOADING,
  CLIENTS_LOAD_FAIL,
  CLIENTS_LOAD_SUCCESS,
  RESET_CLIENT_SEARCH_LIST,
  RESET_CLIENT_SEARCH_LIST_FILTER,
  TOGGLE_CLIENT_SEARCH_LIST_FILTER,
} from "./types";

export const searchClients =
  (args: { searchCriteria: string; page: number }) =>
  async (dispatch, getState) => {
    if (getState().clientSearchState.isLoading) {
      return;
    }
    dispatch({
      type: CLIENTS_LOADING,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const trainer = getTrainer(getState());
      const sortOrder = SortOrder.Asc;
      const sortField = ClientSearchResultSortCriteria.LastName;
      const statusCriteria = [
        UserStatusType.Active,
        UserStatusType.TrialUser,
        UserStatusType.PendingCancellation,
        UserStatusType.OnHold,
      ];
      const clientPackageTypeCriteria = [
        ClientPackageType.Premium,
        ClientPackageType.Legacy,
        ClientPackageType.TestAccount,
      ];
      const result = (
        await client.query({
          query: SEARCH_CLIENTS,
          variables: {
            sortOrder,
            sortField,
            pageNumber: args.page,
            pageSize: 25,
            searchCriteria: args.searchCriteria,
            statusCriteria,
            clientPackageTypeCriteria,
            trainerIdFilter: trainer.id,
          },
        })
      ).data.searchClientsTab as CaliberPageImpl_ClientSearchResult;

      dispatch({
        type: CLIENTS_LOAD_SUCCESS,
        data: result,
      } as ClientSearchAction);
    } catch (error) {
      dispatch({
        type: CLIENTS_LOAD_FAIL,
        data: error,
      });
    }
  };

export const resetClientSearchList = () => async (dispatch) => {
  dispatch({
    type: RESET_CLIENT_SEARCH_LIST,
  });
};

export const resetClientSearchFilters = () => async (dispatch) => {
  dispatch({
    type: RESET_CLIENT_SEARCH_LIST_FILTER,
  });
};

export const toggleClientSearchFilter = () => async (dispatch) => {
  dispatch({
    type: TOGGLE_CLIENT_SEARCH_LIST_FILTER,
  });
};
