import React from "react";
import {
  exerciseEquipment,
  exerciseLevel,
  exerciseMuscleGroupText,
  exerciseTypeText,
} from "../../models/exercises";
import { FilterTypes } from "../../screens/AdminExerciseLibraryScreen";
import { ExerciseType, QuerySearchExercisesArgs } from "../../types/gqlTypes";
import CloseIcon from "../Icons/CloseIcons";
import SimpleButton from "../SimpleButton";

interface Props {
  onSelectFilterTag: (tag: any, type: FilterTypes) => void;
  resultNumber: number;
  queryFilterArgs: QuerySearchExercisesArgs;
}

const AppliedFilters = (props: Props) => {
  const { onSelectFilterTag, resultNumber, queryFilterArgs } = props;

  const isWarmup = queryFilterArgs.exerciseTypeCriteria === ExerciseType.Warmup;

  const Lozenge = (props: { text: string; onDelete: () => void }) => {
    const { text, onDelete } = props;
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          margin: 2,
          marginBottom: 5,
          borderRadius: 12,
          padding: "4px 8px",
          height: 26,
          border: "1px solid #C2C3CD",
        }}
      >
        <div
          className="bold"
          style={{
            fontSize: "14px",
            marginRight: 7,
          }}
        >
          {text}
        </div>
        <SimpleButton onClick={onDelete}>
          <CloseIcon width={14} height={14} />
        </SimpleButton>
      </div>
    );
  };

  return (
    <div
      className="d-flex"
      style={{
        marginBottom: 10,
      }}
    >
      <div
        className="d-flex flex-column"
        style={{
          width: 130,
          flex: "0 0 130px",
        }}
      >
        <div
          className="d-flex align-items-center justify-content-center bold"
          style={{
            fontSize: "16px",
            marginTop: 5,
          }}
        >
          Active filters:
        </div>
        <div
          className="d-flex justify-content-center align-items-center medium-bold"
          style={{
            fontSize: "12px",
          }}
        >
          {`(${resultNumber} result${resultNumber === 1 ? "" : "s"} found)`}
        </div>
      </div>
      <div
        className="d-flex flex-wrap"
        style={{
          flex: 1,
          marginTop: 5,
        }}
      >
        <Lozenge
          text={isWarmup ? "Warmup" : "Exercise"}
          onDelete={() => onSelectFilterTag(null, FilterTypes.Warmups)}
        />
        {queryFilterArgs?.exerciseLevelCriteria && (
          <Lozenge
            text={exerciseLevel(queryFilterArgs.exerciseLevelCriteria)}
            onDelete={() =>
              onSelectFilterTag(
                queryFilterArgs.exerciseLevelCriteria,
                FilterTypes.Level
              )
            }
          />
        )}
        {!isWarmup && queryFilterArgs?.exerciseTypeCriteria && (
          <Lozenge
            text={exerciseTypeText(queryFilterArgs.exerciseTypeCriteria)}
            onDelete={() =>
              onSelectFilterTag(
                queryFilterArgs.exerciseTypeCriteria,
                FilterTypes.Type
              )
            }
          />
        )}
        {queryFilterArgs?.muscleGroupCriteria?.map((muscle) => (
          <Lozenge
            text={exerciseMuscleGroupText(muscle)}
            onDelete={() => onSelectFilterTag(muscle, FilterTypes.MuscleGroup)}
          />
        ))}
        {queryFilterArgs?.exerciseEquipmentCriteria && (
          <Lozenge
            text={exerciseEquipment(queryFilterArgs.exerciseEquipmentCriteria)}
            onDelete={() =>
              onSelectFilterTag(
                queryFilterArgs.exerciseEquipmentCriteria,
                FilterTypes.Equipment
              )
            }
          />
        )}
      </div>
    </div>
  );
};

export default AppliedFilters;
