import { gql } from "apollo-boost";
import {
  commonGymStepFields,
  commonMasterTrainingPlanFields,
  masterGymStepsFields,
  masterGymStepsSelectors,
} from "../shared";

export const REORDER_MASTER_WORKOUTS = gql`
  mutation ReorderMasterWorkouts(
    $masterTrainingPlanId: UUID
    $masterWorkoutIds: [UUID]
  ) {
    reorderMasterWorkouts(
      masterTrainingPlanId: $masterTrainingPlanId
      masterWorkoutIds: $masterWorkoutIds
    ) {
      workoutGroups {
        id
        workouts {
          id
        }
      }
    }
  }
`;
export const ADMIN_REORDER_MASTER_GYM_STEPS = gql`
  ${masterGymStepsFields}
  mutation ReorderMasterGymSteps(
    $masterGymStepIds: [UUID]
    $masterWorkoutId: UUID
  ){
    reorderMasterGymSteps(
      masterGymStepIds: $masterGymStepIds
      masterWorkoutId: $masterWorkoutId
    ){
      gymSteps {
        ${masterGymStepsSelectors}
      }
    }
  }
`;
export const ADD_MASTER_GYM_STEP = gql`
  ${masterGymStepsFields}
  mutation AdminAddMasterGymStep(
    $exerciseId: UUID
    $masterWorkoutId: UUID
    $position: Int
  ){
    addMasterGymStep(
      exerciseId: $exerciseId
      masterWorkoutId: $masterWorkoutId
      position: $position
    ){
      gymSteps {
        ${masterGymStepsSelectors}
      }
    }
  }
`;
export const ADD_NEW_MASTER_WORKOUT = gql`
  mutation AddNewMasterWorkout($masterTrainingPlanId: UUID) {
    addNewMasterWorkout(masterTrainingPlanId: $masterTrainingPlanId) {
      masterWorkoutGroups {
        id
        masterWorkouts {
          id
        }
      }
    }
  }
`;
export const DELETE_MASTER_WORKOUT = gql`
  mutation DeleteMasterWorkout($masterWorkoutId: UUID) {
    deleteMasterWorkout(masterWorkoutId: $masterWorkoutId)
  }
`;
export const DELETE_MASTER_GYM_STEP = gql`
  mutation DeleteMasterGymStep($masterGymStepId: UUID) {
    deleteMasterGymStep(masterGymStepId: $masterGymStepId)
  }
`;
export const UPDATE_MASTER_WORKOUT = gql`
  mutation UpdateMasterWorkout(
    $masterWorkoutId: UUID
    $instructions: String
    $thumbnailImageMediaUrlId: UUID
    $title: String
  ) {
    updateMasterWorkout(
      masterWorkoutId: $masterWorkoutId
      instructions: $instructions
      thumbnailImageMediaUrlId: $thumbnailImageMediaUrlId
      title: $title
    ) {
      id
      title
      instructions
      thumbnailImage {
        id
        url
      }
    }
  }
`;

export const UPDATE_MASTER_GYM_STEP = gql`
  mutation UpdateMasterGymStep(
    $masterGymStepId: UUID
    $target: String
    $supersetTag: SupersetTagType
    $targetRepsLowerBound: Int
    $targetRepsUpperBound: Int
    $targetSets: Int
    $targetWeight: Float
    $targetTime: Int
    $hideWeightForExerciseSets: Boolean
    $generateSmartWarmupSets: Boolean
    $smartWarmupSetsCount: Int
    $restPeriod: Int
  ){
    updateMasterGymStep(
      masterGymStepId: $masterGymStepId
      target: $target
      supersetTag: $supersetTag
      targetRepsLowerBound: $targetRepsLowerBound
      targetRepsUpperBound: $targetRepsUpperBound
      targetSets: $targetSets
      targetWeight: $targetWeight
      targetTime: $targetTime
      hideWeightForExerciseSets: $hideWeightForExerciseSets
      generateSmartWarmupSets: $generateSmartWarmupSets
      smartWarmupSetsCount: $smartWarmupSetsCount
      restPeriod: $restPeriod
    ){
      ${commonGymStepFields}
    }
  }
`;

export const masterPlanFragment = gql`
  ${masterGymStepsFields}
  fragment MasterPlanProperty on MasterTrainingPlan {
    ${commonMasterTrainingPlanFields}
    masterWorkoutGroups {
      id
      masterWorkouts {
        id
        sortOrder
        title
        instructions
        thumbnailImage {
          id
          url
        }
        gymSteps {
          ${masterGymStepsSelectors}
        }
      }
    }
  }
`;

export const SEARCH_MASTER_PLANS = gql`
  query SearchPlans(
    $page: Int!
    $durationType: [TrainingPlanDurationType]
    $frequencyType: [TrainingPlanFrequencyType]
    $genderType: [GenderType]
    $planType: [TrainingPlanType]
    $programType: [TrainingPlanProgramType]
    $repRangeType: [TrainingPlanRepRangeType]
    $sortFields: [TrainingPlanSortCriteria]
    $sortDirection: Direction
  ){
    searchMasterTrainingPlans(
      page: $page
      durationType: $durationType
      frequencyType: $frequencyType
      genderType: $genderType
      planType: $planType
      programType: $programType
      repRangeType: $repRangeType
      sortFields: $sortFields
      sortDirection: $sortDirection
    ){
      content {
        ${commonMasterTrainingPlanFields}
      }
      last
      number
      numberOfElements
      totalElements
      totalPages
    }
  }
`;

export const CREATE_MASTER_TRAINING_PLAN = gql`
  ${masterPlanFragment}
  mutation CreateMasterTrainingPlan(
    $duration: TrainingPlanDurationType
    $frequency: TrainingPlanFrequencyType
    $gender: GenderType
    $planVersion: TrainingPlanVersionType
    $program: TrainingPlanProgramType
    $repRange: TrainingPlanRepRangeType
    $type: TrainingPlanType
  ) {
    createMasterTrainingPlan(
      duration: $duration
      frequency: $frequency
      gender: $gender
      planVersion: $planVersion
      program: $program
      repRange: $repRange
      type: $type
    ) {
      ...MasterPlanProperty
    }
  }
`;
export const DELETE_MASTER_TRAINING_PLAN = gql`
  mutation DeleteMasterTrainingPlan($masterTrainingPlanId: UUID) {
    deleteMasterTrainingPlan(masterTrainingPlanId: $masterTrainingPlanId)
  }
`;
export const UPDATE_MASTER_TRAINING_PLAN = gql`
  ${masterPlanFragment}
  mutation UpdateMasterTrainingPlan(
    $masterTrainingPlanId: UUID
    $duration: TrainingPlanDurationType
    $frequency: TrainingPlanFrequencyType
    $gender: GenderType
    $planVersion: TrainingPlanVersionType
    $program: TrainingPlanProgramType
    $repRange: TrainingPlanRepRangeType
    $type: TrainingPlanType
  ) {
    updateMasterTrainingPlan(
      masterTrainingPlanId: $masterTrainingPlanId
      duration: $duration
      frequency: $frequency
      gender: $gender
      planVersion: $planVersion
      program: $program
      repRange: $repRange
      type: $type
    ) {
      ...MasterPlanProperty
    }
  }
`;
export const CLONE_MASTER_TRAINING_PLAN = gql`
  mutation CloneMasterTrainingPlan(
    $trainingPlanId: UUID
  ){
    cloneMasterTrainingPlan(
      trainingPlanId: $trainingPlanId
    ){
      ${commonMasterTrainingPlanFields}
    }
  }
`;
