import React from "react";
import colors from "../../assets/colors";
import CaretDown from "../../assets/images/CaretRedDown.svg";
import CaretUp from "../../assets/images/CaretGreenUp.svg";
import { DashboardTypes } from "../../models/strengthscore";
import StrengthBalanceArc from "./StrengthBalanceArc";
import StrengthScoreArc from "./StrengthScoreArc";

const getX = (degrees, radius) => {
  const radians = (degrees * Math.PI) / 180;
  const xOffset = 8;
  return radius * Math.cos(radians) + xOffset + radius;
};

const getY = (degrees, radius) => {
  const radians = (degrees * Math.PI) / 180;
  const yOffset = 8;
  return radius * Math.sin(radians) + yOffset + radius;
};

interface MarketProps {
  radius: number;
  degrees: number;
  markerColor: string;
}

const Marker = (props: MarketProps) => {
  const { radius, degrees, markerColor } = props;

  return (
    <div
      style={{
        position: "absolute",
        backgroundColor: colors.caliber_white,
        borderRadius: 8,
        width: 16,
        height: 16,
        justifyContent: "center",
        alignItems: "center",
        left: getX(degrees, radius),
        top: getY(degrees, radius),
      }}
    >
      <div
        style={{
          backgroundColor: markerColor,
          width: 12,
          height: 12,
          borderRadius: 6,
          margin: 2,
        }}
      >
        {/* marker */}
      </div>
    </div>
  );
};

interface Props {
  dashboardType: DashboardTypes;
  startColor: string;
  endColor: string;
  color: string;
  markerPercent: number;
  startNumber: number | string;
  endNumber: number | string;
  text: number | string;
  displayDelta: string;
  levelText: string;
  isTickerUp: boolean;
}

const Arc = (props: Props) => {
  const {
    dashboardType,
    startColor,
    endColor,
    markerPercent,
    color,
    text,
    startNumber,
    endNumber,
    displayDelta,
    levelText,
    isTickerUp,
  } = props;

  const radius = 64;
  const isStrengthScore = dashboardType === DashboardTypes.score;
  // from -180 to 0
  const markerPosition = 180 * markerPercent - 180;

  return (
    <div
      style={{
        position: "relative",
        width: 160,
        height: 80,
        backgroundColor: colors.caliber_white,
        borderRadius: "80px 80px 0px 0px",
      }}
    >
      <div
        style={{
          position: "absolute",
          width: 140,
          height: 70,
          margin: "10px 10px 0px 10px",
          borderRadius: "70px 70px 0px 0px",
        }}
      >
        {isStrengthScore ? (
          <StrengthScoreArc startColor={startColor} endColor={endColor} />
        ) : (
          <StrengthBalanceArc />
        )}
      </div>
      <div
        className="d-flex justify-content-center"
        style={{
          position: "absolute",
          bottom: 25,
          width: 160,
        }}
      >
        <div
          className="d-flex flex-column justify-content-center"
          style={{
            height: 23,
            marginRight: 5,
          }}
        >
          <img
            style={{
              width: 8,
              height: 5,
            }}
            src={isTickerUp ? CaretUp : CaretDown}
          />
        </div>
        <div
          className="d-flex flex-column justify-content-center circular-bold-font"
          style={{
            color: colors.caliber_gray_text,
            fontSize: "14px",
            lineHeight: "17px",
            letterSpacing: "-0.34px",
          }}
        >
          {displayDelta}
        </div>
      </div>
      <div
        className="circular-font"
        style={{
          position: "absolute",
          fontSize: "29px",
          fontWeight: 500,
          lineHeight: "36px",
          width: 160,
          textAlign: "center",
          bottom: -6,
          fontFamily: "Circular",
          letterSpacing: "-0.69px",
        }}
      >
        {text}
      </div>
      <div
        className="d-flex justify-content-center bold"
        style={{
          width: 80,
          padding: "4px 8px",
          backgroundColor: color,
          color: colors.caliber_white,
          borderRadius: 4,
          fontSize: "12px",
          lineHeight: "15px",
          letterSpacing: "-0.34px",
          position: "absolute",
          bottom: -40,
          left: 40,
        }}
      >
        {levelText}
      </div>
      <div
        className="circular-font"
        style={{
          position: "absolute",
          fontSize: "11px",
          fontWeight: 500,
          lineHeight: "24px",
          textAlign: "center",
          bottom: -26,
          left: 6,
          color: colors.caliber_gray_text,
          fontFamily: "Circular",
          letterSpacing: "-0.69px",
        }}
      >
        {startNumber}
      </div>
      <div
        className="circular-font"
        style={{
          position: "absolute",
          fontSize: "11px",
          fontWeight: 500,
          lineHeight: "24px",
          textAlign: "center",
          bottom: -26,
          right: 6,
          color: colors.caliber_gray_text,
          fontFamily: "Circular",
          letterSpacing: "-0.69px",
        }}
      >
        {endNumber}
      </div>
      <Marker markerColor={color} degrees={markerPosition} radius={radius} />
    </div>
  );
};

export default Arc;
