import { gql } from "apollo-boost";

const contentWeek = gql`
  fragment ContentWeek on ContentWeek {
    CONTENTFUL_CONTENT_TYPE_ID
    description
    id
    memberAvatarType
    title
    weekNumber
    weekItems {
      id
      read
      sortOrder
      thumbnailImage {
        url
      }
      title
      ... on ContentAction {
        read
        contentDeepLink {
          deepLink
        }
      }
      ... on ContentLessonSet {
        category
        completionButtonLabel
        read
        lessonPages {
          id
          backgroundImage {
            url
            mimeType
          }
          body
        }
      }
    }
  }
`;

export const CONTENT_FOR_WEEK_BY_CLIENT_ID = gql`
  ${contentWeek}
  query ContentForWeekByClientId($clientId: UUID!) {
    contentForWeekByClientId(clientId: $clientId) {
      ...ContentWeek
    }
  }
`;

export const CONTENT_FOR_WEEK_BY_WEEK_NUMBER = gql`
  ${contentWeek}
  query contentForWeekByWeekNumber(
    $clientId: UUID!
    $memberAvatarType: MemberAvatarType
    $weekNumber: Int!
  ) {
    contentForWeekByWeekNumber(
      clientId: $clientId
      memberAvatarType: $memberAvatarType
      weekNumber: $weekNumber
    ) {
      ...ContentWeek
    }
  }
`;
