import React from "react";
import colors from "../../assets/colors";
import TargetIcon from "../Icons/TargetIcon";
import AddNewHabitButton from "./AddNewHabitButton";

interface Props {
  onNewHabitClick: () => void;
}

const EmptyHabitsState = (props: Props) => {
  const { onNewHabitClick } = props;

  return (
    <div
      className="d-flex flex-column justify-content-center"
      style={{
        flex: 1,
      }}
    >
      <div
        className="d-flex justify-content-center"
        style={{
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            width: 160,
            height: 160,
            borderRadius: 80,
            backgroundColor: colors.caliber_secondary_gray_5,
            zIndex: 0,
            top: -52,
            left: "calculate(50% - 80px)",
          }}
        >
          {/* circle */}
        </div>
        <div
          style={{
            zIndex: 1,
          }}
        >
          <TargetIcon />
        </div>
      </div>

      <div
        className="d-flex justify-content-center medium-bold"
        style={{
          fontSize: "20px",
          lineHeight: "25px",
          letterSpacing: "-0.022em",
          color: colors.caliber_secondary_gray_29,
          marginTop: 73,
        }}
      >
        You don’t have any habits set
      </div>

      <div
        className="d-flex justify-content-center"
        style={{
          marginTop: 32,
        }}
      >
        <AddNewHabitButton onClick={onNewHabitClick} />
      </div>
    </div>
  );
};

export default EmptyHabitsState;
