import React from "react";
import { CardioActivityType } from "../../../types/gqlTypes";
import CardioCyclingIcon from "../../../assets/images/cardio_cycling_icon.svg";
import CardioEllipticalIcon from "../../../assets/images/cardio_elliptical_icon.svg";
import CardioRowerIcon from "../../../assets/images/cardio_rower_icon.svg";
import CardioRunningIcon from "../../../assets/images/cardio_running_icon.svg";
import CardioStairclimberIcon from "../../../assets/images/cardio_stairclimber_icon.svg";
import CardioWalkingIcon from "../../../assets/images/cardio_walking_icon.svg";
import CardioOtherIcon from "../../../assets/images/cardio_other_icon.svg";
import SimpleButton from "../../SimpleButton";
import colors from "../../../assets/colors";

interface Props {
  type: CardioActivityType;
  onClick?: () => void;
}

const CardioCard = (props: Props) => {
  const { type, onClick } = props;

  const cardioEvents = {
    [CardioActivityType.Running]: { text: "Running", icon: CardioRunningIcon },
    [CardioActivityType.Walking]: { text: "Walking", icon: CardioWalkingIcon },
    [CardioActivityType.Elliptical]: {
      text: "Elliptical",
      icon: CardioEllipticalIcon,
    },
    [CardioActivityType.Stairclimber]: {
      text: "Stairclimber",
      icon: CardioStairclimberIcon,
    },
    [CardioActivityType.Cycling]: { text: "Cycling", icon: CardioCyclingIcon },
    [CardioActivityType.Rower]: { text: "Rower", icon: CardioRowerIcon },
    [CardioActivityType.General]: { text: "Other", icon: CardioOtherIcon },
  };

  const titleStyle = {
    fontSize: "16px",
    lineHeight: "22px",
    letterSpacing: "-0.03em",
    color: colors.caliber_secondary,
    height: 80,
    margin: "0px 8px",
  };
  return (
    <SimpleButton
      onClick={() => {
        onClick?.();
      }}
      nofocus
      nopointer={!!onClick}
      className="d-flex bold"
      style={{
        width: 398,
        height: 80,
        padding: 8,
        marginBottom: 8,
        marginRight: 2,
        backgroundColor: colors.caliber_secondary_gray_5,
        borderRadius: 8,
      }}
    >
      <div
        style={{
          width: 64,
          height: 64,
          borderRadius: 8,
          margin: "0px 8px",
        }}
      >
        <img
          style={{
            width: 64,
            height: 64,
            borderRadius: 8,
          }}
          src={
            cardioEvents?.[type]?.icon ||
            cardioEvents[CardioActivityType.General].icon
          }
        />
      </div>
      <div className="d-flex flex-column">
        <div className="d-flex align-items-center" style={titleStyle}>
          {cardioEvents?.[type]?.text ||
            cardioEvents[CardioActivityType.General].text}
        </div>
      </div>
    </SimpleButton>
  );
};

export default CardioCard;
