import { selectGymSteps, selectWorkoutGroups, selectWorkouts } from ".";
import { ProgramType } from "../../../components/TrainingProgram";
import {
  ClientTrainingPlan,
  ClientWorkoutGroupTemplate,
  ClientWorkoutTemplate,
  CustomTrainingPlan,
  LibraryTrainingPlan,
  MasterTrainingPlan,
  MasterWorkout,
} from "../../../types/gqlTypes";
import {
  CREATE_SUPERSET_FAIL,
  CREATE_SUPERSET_LOADING,
  CREATE_SUPERSET_SUCCESS,
  SET_DEFAULT_WORKOUT_GROUP_SUCCESS,
  TOGGLE_ACTIVE_CLIENT_TRAINING_PLAN_FAIL,
  TOGGLE_CUSTOM_TRAINING_PLAN_STATE_FAIL,
  TOGGLE_ACTIVE_CLIENT_TRAINING_PLAN_LOADING,
  TOGGLE_ACTIVE_CLIENT_TRAINING_PLAN_SUCCESS,
  TrainingProgramsAction,
  TrainingProgramsState,
  UPDATE_GYM_STEP_FAIL,
  UPDATE_GYM_STEP_LOADING,
  UPDATE_GYM_STEP_SUCCESS,
  UPDATE_TRAINING_PLAN_SUCCESS,
  UPDATE_WORKOUT_FAIL,
  UPDATE_WORKOUT_GROUP_SUCCESS,
  UPDATE_WORKOUT_SUCCESS,
} from "../../actions/TrainingPrograms/types";
import {
  replaceGymSteps,
  replaceWorkoutGroups,
  replaceWorkouts,
} from "./helperFunctions";

const updateReducer = (
  state: TrainingProgramsState,
  action: TrainingProgramsAction
) => {
  switch (action.type) {
    case UPDATE_GYM_STEP_LOADING:
      return {
        ...state,
        isSaving: true,
      };
    case UPDATE_GYM_STEP_FAIL:
      return {
        ...state,
        isSaving: false,
        message: (action as any).data.message,
      };
    case UPDATE_GYM_STEP_SUCCESS:
      return replaceGymSteps({
        newGymSteps: selectGymSteps(state).map((gymStep) => {
          if (gymStep.id === action.gymStep.id) {
            return {
              ...action.gymStep,
              isSaving: false,
              isLoading: false,
              exercise: gymStep.exercise,
            };
          }
          return gymStep;
        }),
        state,
      });
    case UPDATE_WORKOUT_SUCCESS: {
      const newWorkouts = selectWorkouts(state).map((workout) => {
        if (workout.id === action.workout.id) {
          return { ...workout, ...action.workout };
        }
        return workout;
      }) as (ClientWorkoutTemplate | MasterWorkout)[];
      return replaceWorkouts({ newWorkouts, state });
    }
    case UPDATE_WORKOUT_FAIL: {
      return {
        ...state,
        isSaving: false,
        message: (action as any).data.message,
      };
    }
    case UPDATE_WORKOUT_GROUP_SUCCESS: {
      const newWorkoutGroups = selectWorkoutGroups(state).map(
        (workoutGroup) => {
          if (workoutGroup.id === action.workoutGroup.id) {
            return { ...workoutGroup, ...action.workoutGroup };
          }
          return workoutGroup;
        }
      ) as ClientWorkoutGroupTemplate[];
      return replaceWorkoutGroups({ newWorkoutGroups, state });
    }
    case SET_DEFAULT_WORKOUT_GROUP_SUCCESS: {
      const existingPlanIndex = state.clientPlans.findIndex((plan) => {
        return plan.id === action.plan.id;
      });
      const plans = [...state.clientPlans];
      plans[existingPlanIndex] = action.plan as ClientTrainingPlan;
      return {
        ...state,
        clientPlans: [...plans],
        cachedPlans: {
          ...state.cachedPlans,
          [action.plan.id]: action.plan,
        },
      };
    }
    case UPDATE_TRAINING_PLAN_SUCCESS: {
      const { plan } = action;
      const obj: any = {};
      if (state.programType === ProgramType.Client) {
        obj.clientPlans = state.clientPlans.map((clientPlan) => {
          if (plan.id === clientPlan.id) {
            return plan;
          }
          return clientPlan;
        });
      } else if (state.programType === ProgramType.Master) {
        const planIndex = state?.masterPlansContent?.content?.findIndex(
          (masterPlan) => masterPlan.id === plan.id
        );
        const newContent = [...(state?.masterPlansContent?.content || [])];
        newContent[planIndex] = plan as MasterTrainingPlan;
        obj.planIndex = planIndex;
        obj.showPlans = true;
        obj.masterPlansContent = {
          ...state.masterPlansContent,
          content: newContent,
        };
      } else if (state.programType === ProgramType.Custom) {
        const planIndex = state?.customPlansContent?.content?.findIndex(
          (customPlan) => customPlan.id === plan.id
        );
        const newContent = [...(state?.customPlansContent?.content || [])];
        newContent[planIndex] = plan as CustomTrainingPlan;
        obj.planIndex = planIndex;
        obj.showPlans = true;
        obj.customPlansContent = {
          ...state.customPlansContent,
          content: newContent,
        };
      } else if (state.programType === ProgramType.Library) {
        const planIndex = state?.libraryPlansContent?.content?.findIndex(
          (libraryPlan) => libraryPlan.id === plan.id
        );
        const newContent = [...(state?.libraryPlansContent?.content || [])];
        newContent[planIndex] = plan as LibraryTrainingPlan;
        obj.planIndex = planIndex;
        obj.showPlans = true;
        obj.libraryPlansContent = {
          ...state.libraryPlansContent,
          content: newContent,
        };
      }
      return {
        ...state,
        ...obj,
        isSaving: false,
        isDeleting: false,
        isLoading: false,
        showWorkouts: true,
        editingMasterProgram: null,
        showNewOrEditMasterProgramSection: false,
        cachedPlans: {
          ...state.cachedPlans,
          [plan.id]: {
            ...state.cachedPlans[plan.id],
            ...plan,
          },
        },
      };
    }

    case TOGGLE_ACTIVE_CLIENT_TRAINING_PLAN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case TOGGLE_ACTIVE_CLIENT_TRAINING_PLAN_FAIL:
      return {
        ...state,
        isLoading: false,
        message: (action as any).data.message,
      };
    case TOGGLE_CUSTOM_TRAINING_PLAN_STATE_FAIL:
      return {
        ...state,
        isLoading: false,
        message: (action as any).data.message,
      };
    case TOGGLE_ACTIVE_CLIENT_TRAINING_PLAN_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_SUPERSET_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_SUPERSET_SUCCESS:
      const newWorkouts = selectWorkouts(state).map((workout) => {
        if (workout.id === action.workout.id) {
          return { ...workout, ...action.workout };
        }
        return workout;
      }) as (ClientWorkoutTemplate | MasterWorkout)[];
      return {
        ...replaceWorkouts({ newWorkouts, state }),
        supersetId: action.convertedSupersetId,
        showWorkouts: false,
        showSupersetEditor: true,
      } as TrainingProgramsState;
    case CREATE_SUPERSET_FAIL: {
      return {
        ...state,
        isSaving: false,
        message: (action as any).data.message,
      };
    }
    default:
      return state;
  }
};
export default updateReducer;
