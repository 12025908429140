import {
  FETCHING_CLIENT_WORKOUT_HISTORY_SUCCESS,
  FETCHING_CLIENT_WORKOUT_HISTORY_LOADING,
  FETCHING_CLIENT_WORKOUT_HISTORY_FAIL,
  RESET_CLIENT_WORKOUT_HISTORY_STATE,
  SELECT_WORKOUT_PROGRESS_TABLE,
  FETCHING_FORM_VIDEOS_BY_DATE_RANGE_FAIL,
  FETCHING_FORM_VIDEOS_BY_DATE_RANGE_LOADING,
  FETCHING_FORM_VIDEOS_BY_DATE_RANGE_SUCCESS,
  ClientWorkoutHistoryAction,
  ClientWorkoutHistoryState,
} from "../../actions/ClientWorkoutHistory/types";

const initialState: ClientWorkoutHistoryState = {
  isLoading: false,
  isLoadingFormVideos: false,
  selectedWorkoutId: null,
  clientWorkoutHistory: [],
  formVideos: [],
  args: null,
};

const clientWorkoutHistoryState = (
  state = initialState,
  action: ClientWorkoutHistoryAction
) => {
  switch (action.type) {
    case FETCHING_FORM_VIDEOS_BY_DATE_RANGE_FAIL:
      return {
        ...state,
        isLoadingFormVideos: false,
      };
    case FETCHING_FORM_VIDEOS_BY_DATE_RANGE_LOADING:
      return {
        ...state,
        isLoadingFormVideos: true,
      };
    case FETCHING_FORM_VIDEOS_BY_DATE_RANGE_SUCCESS:
      return {
        ...state,
        isLoadingFormVideos: false,
        formVideos: action.formVideos,
      };
    case SELECT_WORKOUT_PROGRESS_TABLE: {
      return {
        ...state,
        selectedWorkoutId: action.id,
      };
    }
    case FETCHING_CLIENT_WORKOUT_HISTORY_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case FETCHING_CLIENT_WORKOUT_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        clientWorkoutHistory: action.data,
        args: action.args,
      };
    case FETCHING_CLIENT_WORKOUT_HISTORY_FAIL:
      return {
        ...state,
        isLoading: false,
        clientWorkoutHistory: [],
        args: null,
      };
    case RESET_CLIENT_WORKOUT_HISTORY_STATE:
      return {
        ...state,
        clientWorkoutHistory: [],
        formVideos: [],
        args: null,
        isLoadingFormVideos: false,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default clientWorkoutHistoryState;
