import apolloClient from "../../../api/apolloClient";
import {
  ADD_NEW_CLIENT_WORKOUT_GROUP,
  ASSIGN_CUSTOM_TRAINING_PLAN,
  ASSIGN_MASTER_TRAINING_PLAN,
  COPY_MASTER_WORKOUT_GROUP_TO_CLIENT,
  CREATE_NEW_CLIENT_TRAINING_PLAN,
} from "../../../api/gql/trainingPrograms/clientTrainingPlan";
import {
  CLONE_CUSTOM_TRAINING_PLAN,
  CREATE_CUSTOM_TRAINING_PLAN,
  CREATE_LIBRARY_TRAINING_PLAN,
} from "../../../api/gql/trainingPrograms/customTrainingPlan";
import {
  CLONE_MASTER_TRAINING_PLAN,
  CREATE_MASTER_TRAINING_PLAN,
} from "../../../api/gql/trainingPrograms/masterTrainingPlan";
import {
  ComponentLocationType,
  ProgramType,
} from "../../../components/TrainingProgram";
import {
  ClientTrainingPlan,
  CustomTrainingPlan,
  LibraryTrainingPlan,
  MasterTrainingPlan,
  MutationAssignCustomTrainingPlanArgs,
  MutationAssignMasterTrainingPlanArgs,
  MutationCopyMasterWorkoutGroupToClientWorkoutGroupTemplateArgs,
  MutationCreateCustomTrainingPlanArgs,
  MutationCreateLibraryTrainingPlanArgs,
  MutationCreateMasterTrainingPlanArgs,
  WorkoutGroupType,
} from "../../../types/gqlTypes";
import {
  ADD_NEW_CLIENT_WORKOUT_GROUP_FAIL,
  ADD_NEW_CLIENT_WORKOUT_GROUP_LOADING,
  ADD_NEW_CLIENT_WORKOUT_GROUP_SUCCESS,
  ASSIGN_TRAINING_PLAN_FAIL,
  ASSIGN_TRAINING_PLAN_LOADING,
  ASSIGN_TRAINING_PLAN_SUCCESS,
  CLONE_CUSTOM_TRAINING_PLAN_FAIL,
  CLONE_CUSTOM_TRAINING_PLAN_LOADING,
  CLONE_CUSTOM_TRAINING_PLAN_SUCCESS,
  CLONE_MASTER_TRAINING_PLAN_FAIL,
  CLONE_MASTER_TRAINING_PLAN_LOADING,
  CLONE_MASTER_TRAINING_PLAN_SUCCESS,
  COPY_MASTER_WORKOUT_GROUP_TO_CLIENT_FAIL,
  COPY_MASTER_WORKOUT_GROUP_TO_CLIENT_LOADING,
  COPY_MASTER_WORKOUT_GROUP_TO_CLIENT_SUCCESS,
  CREATE_CUSTOM_PLAN_IN_PROGRAM_LIBRARY,
  CREATE_LIBRARY_PLAN_IN_PROGRAM_LIBRARY,
  CREATE_TRAINING_PLAN_FAIL,
  CREATE_TRAINING_PLAN_LOADING,
  CREATE_TRAINING_PLAN_SUCCESS,
} from "./types";

export const createTrainingPlan =
  (
    args:
      | MutationCreateCustomTrainingPlanArgs
      | MutationCreateLibraryTrainingPlanArgs
      | MutationCreateMasterTrainingPlanArgs,
    programType?: ProgramType
  ) =>
  async (dispatch, getState) => {
    if (getState().trainingProgramsState.isLoading) {
      return;
    }
    dispatch({ type: CREATE_TRAINING_PLAN_LOADING });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );

      const actualProgramType =
        programType || getState().trainingProgramsState.programType;
      let newPlan:
        | CustomTrainingPlan
        | MasterTrainingPlan
        | LibraryTrainingPlan;
      if (actualProgramType === ProgramType.Custom) {
        newPlan = (
          await client.mutate({
            mutation: CREATE_CUSTOM_TRAINING_PLAN,
            variables: args,
          })
        ).data.createCustomTrainingPlan as CustomTrainingPlan;
      } else if (actualProgramType === ProgramType.Library) {
        newPlan = (
          await client.mutate({
            mutation: CREATE_LIBRARY_TRAINING_PLAN,
            variables: args,
          })
        ).data.createLibraryTrainingPlan as LibraryTrainingPlan;
      } else if (actualProgramType === ProgramType.Master) {
        newPlan = (
          await client.mutate({
            mutation: CREATE_MASTER_TRAINING_PLAN,
            variables: args,
          })
        ).data.createMasterTrainingPlan as MasterTrainingPlan;
      }

      if (
        actualProgramType === ProgramType.Custom &&
        getState().trainingProgramsState.componentLocation ===
          ComponentLocationType.AdminLibrary
      ) {
        dispatch({
          type: CREATE_CUSTOM_PLAN_IN_PROGRAM_LIBRARY,
          plan: newPlan,
        });
      } else if (
        actualProgramType === ProgramType.Library &&
        getState().trainingProgramsState.componentLocation ===
          ComponentLocationType.AdminLibrary
      ) {
        dispatch({
          type: CREATE_LIBRARY_PLAN_IN_PROGRAM_LIBRARY,
          plan: newPlan,
        });
      } else {
        dispatch({
          type: CREATE_TRAINING_PLAN_SUCCESS,
          programType: actualProgramType,
          plan: newPlan,
        });
      }
    } catch (error) {
      dispatch({
        type: CREATE_TRAINING_PLAN_FAIL,
        data: error,
      });
    }
  };

export const assignTrainingPlan =
  (
    args:
      | MutationAssignMasterTrainingPlanArgs
      | MutationAssignCustomTrainingPlanArgs
  ) =>
  async (dispatch, getState) => {
    if (getState().trainingProgramsState.isLoading) {
      return;
    }
    dispatch({ type: ASSIGN_TRAINING_PLAN_LOADING });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      let newPlan;
      const { programType } = getState().trainingProgramsState;
      if (programType === ProgramType.Master) {
        newPlan = (
          await client.mutate({
            mutation: ASSIGN_MASTER_TRAINING_PLAN,
            variables: args,
          })
        ).data.assignMasterTrainingPlan as ClientTrainingPlan;
      } else if (programType === ProgramType.Custom) {
        newPlan = (
          await client.mutate({
            mutation: ASSIGN_CUSTOM_TRAINING_PLAN,
            variables: args,
          })
        ).data.assignCustomTrainingPlan as ClientTrainingPlan;
      }

      dispatch({
        type: ASSIGN_TRAINING_PLAN_SUCCESS,
        plan: newPlan,
      });
    } catch (error) {
      dispatch({
        type: ASSIGN_TRAINING_PLAN_FAIL,
        data: error,
      });
    }
  };

export const copyMasterWorkoutGroupToClientGroup =
  (args: MutationCopyMasterWorkoutGroupToClientWorkoutGroupTemplateArgs) =>
  async (dispatch, getState) => {
    if (getState().trainingProgramsState.isLoading) {
      return;
    }
    dispatch({ type: COPY_MASTER_WORKOUT_GROUP_TO_CLIENT_LOADING });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const newPlan = (
        await client.mutate({
          mutation: COPY_MASTER_WORKOUT_GROUP_TO_CLIENT,
          variables: args,
        })
      ).data
        .copyMasterWorkoutGroupToClientWorkoutGroupTemplate as ClientTrainingPlan;
      dispatch({
        type: COPY_MASTER_WORKOUT_GROUP_TO_CLIENT_SUCCESS,
        plan: newPlan,
      });
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: COPY_MASTER_WORKOUT_GROUP_TO_CLIENT_FAIL,
        data: error,
      });
    }
  };

export const createNewClientTrainingPlan =
  (clientId: string, name: string) => async (dispatch, getState) => {
    if (getState().trainingProgramsState.isLoading) {
      return;
    }
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const newPlan = (
        await client.mutate({
          mutation: CREATE_NEW_CLIENT_TRAINING_PLAN,
          variables: {
            clientId,
            name,
          },
        })
      ).data.createNewClientTrainingPlan as ClientTrainingPlan;

      dispatch({
        type: ASSIGN_TRAINING_PLAN_SUCCESS,
        plan: newPlan,
      });
    } catch (error) {
      dispatch({
        type: ASSIGN_TRAINING_PLAN_FAIL,
        data: error,
      });
    }
  };

export const addNewClientWorkoutGroup =
  (
    clientId: string,
    trainingPlanId: string,
    title: string,
    workoutGroupType: WorkoutGroupType
  ) =>
  async (dispatch, getState) => {
    if (getState().trainingProgramsState.isLoading) {
      return;
    }
    dispatch({ type: ADD_NEW_CLIENT_WORKOUT_GROUP_LOADING });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const updatedPlan = (
        await client.mutate({
          mutation: ADD_NEW_CLIENT_WORKOUT_GROUP,
          variables: {
            clientId,
            clientTrainingPlanId: trainingPlanId,
            title,
            workoutGroupType,
          },
        })
      ).data.addNewClientWorkoutGroupTemplate as ClientTrainingPlan;
      dispatch({
        type: ADD_NEW_CLIENT_WORKOUT_GROUP_SUCCESS,
        plan: updatedPlan,
      });
    } catch (error) {
      dispatch({
        type: ADD_NEW_CLIENT_WORKOUT_GROUP_FAIL,
        data: error,
      });
    }
  };

export const cloneMasterTrainingPlan =
  (trainingPlanId: string) => async (dispatch, getState) => {
    if (getState().trainingProgramsState.isLoading) {
      return;
    }
    dispatch({ type: CLONE_MASTER_TRAINING_PLAN_LOADING });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const plan = (
        await client.mutate({
          mutation: CLONE_MASTER_TRAINING_PLAN,
          variables: { trainingPlanId },
        })
      ).data.cloneMasterTrainingPlan as MasterTrainingPlan;

      dispatch({
        type: CLONE_MASTER_TRAINING_PLAN_SUCCESS,
        plan,
        id: trainingPlanId,
      });
    } catch (error) {
      dispatch({
        type: CLONE_MASTER_TRAINING_PLAN_FAIL,
        data: error,
      });
    }
  };

export const cloneCustomTrainingPlan =
  (trainingPlanId: string) => async (dispatch, getState) => {
    if (getState().trainingProgramsState.isLoading) {
      return;
    }
    dispatch({ type: CLONE_CUSTOM_TRAINING_PLAN_LOADING });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const plan = (
        await client.mutate({
          mutation: CLONE_CUSTOM_TRAINING_PLAN,
          variables: { trainingPlanId },
        })
      ).data.cloneCustomTrainingPlan as CustomTrainingPlan;

      dispatch({
        type: CLONE_CUSTOM_TRAINING_PLAN_SUCCESS,
        plan,
        id: trainingPlanId,
      });
    } catch (error) {
      dispatch({
        type: CLONE_CUSTOM_TRAINING_PLAN_FAIL,
        data: error,
      });
    }
  };
