import React from "react";
import colors from "../../assets/colors";
import CloseIcon from "../Icons/CloseIcons";
import Modal from "../Modal";
import SimpleButton from "../SimpleButton";

interface Props {
  imgUrl: string;
  index: number;
  onClose: () => void;
}

const ImageModal = (props: Props) => {
  const { imgUrl, index, onClose } = props;

  const modalStyle: React.CSSProperties = {
    width: 480,
    height: 528,
  };
  const titleStyle: React.CSSProperties = {
    fontSize: "16px",
    lineHeight: "16px",
    color: colors.caliber_secondary_gray_29,
  };
  const imageStyle: React.CSSProperties = {
    margin: "0px 32px 32px 32px",
    borderRadius: 8,
    width: 416,
    height: 416,
  };

  return (
    <Modal onClose={onClose} closeOnDimmerClick>
      <div style={modalStyle} className="d-flex flex-column">
        <div
          style={{ height: 80, padding: "0px 32px" }}
          className="d-flex justify-content-between align-items-center medium-bold"
        >
          <div style={titleStyle}>{`Image ${index + 1}`}</div>
          <SimpleButton onClick={onClose}>
            <CloseIcon />
          </SimpleButton>
        </div>

        <img src={imgUrl} style={imageStyle} />
      </div>
    </Modal>
  );
};

export default ImageModal;
