import React from "react";
import ClientProfileInfoModule from "../../components/ClientProfileInfoModule";
import GoalModule from "../../components/GoalModule";
import StrengthDashboard from "../../components/StrengthDashboard";
import { DashboardTypes } from "../../models/strengthscore";
import LastMessageModule from "../../components/LastMessageModule";
import LessonsDashboard from "../../components/LessonsDashboard";
import ZoomCalls from "../../components/ZoomCalls";

const TrainerClientDashboardTab = () => {
  return (
    <div className="d-flex">
      <div
        style={{
          width: 272,
        }}
        className="d-flex flex-column margin-13-12"
      >
        <ClientProfileInfoModule fullView={false} />
        <GoalModule />
        <LastMessageModule />
      </div>
      <div className="d-flex flex-column margin-13-12">
        <StrengthDashboard dashboardType={DashboardTypes.score} />
        <div
          style={{
            marginTop: 24,
          }}
        >
          <StrengthDashboard dashboardType={DashboardTypes.balance} />
        </div>
        <div
          style={{
            marginTop: 24,
            marginBottom: 60,
          }}
        >
          <LessonsDashboard />
        </div>
      </div>
      <div className="d-flex flex-column margin-13-12">
        <ZoomCalls />
      </div>
    </div>
  );
};

export default TrainerClientDashboardTab;
