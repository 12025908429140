import React, { useState } from "react";
import { Link } from "react-router-dom";
import colors from "../../assets/colors";
import {
  durationDisplayText,
  frequencyDisplayText,
  genderDisplayText,
  planTypeDisplayText,
  programTypeDisplayText,
  repRangeDisplayText,
  versionDisplayText,
} from "../../models/tags";
import {
  ClientTrainingPlan,
  CustomTrainingPlan,
  LibraryTrainingPlan,
  MasterTrainingPlan,
  QueryClientTrainingPlansArgs,
  QuerySearchCustomTrainingPlansArgs,
  QuerySearchMasterTrainingPlansArgs,
} from "../../types/gqlTypes";
import ButtonTag from "../ButtonTag";
import ConfirmationDialog from "../ConfirmationDialog";
import MeatballsMenu from "../MeatballsMenu";
import { ComponentLocationType } from "../TrainingProgram";

interface Props {
  onPlanClick?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  onClone?: () => void;
  margin?: string | number;
  bgColor?: string;
  buttonBgColor?: string;
  selectedButtonBgColor?: string;
  index?: number;
  plan:
    | MasterTrainingPlan
    | ClientTrainingPlan
    | CustomTrainingPlan
    | LibraryTrainingPlan;
  filterState:
    | QuerySearchCustomTrainingPlansArgs
    | QuerySearchMasterTrainingPlansArgs
    | QueryClientTrainingPlansArgs;
  showEditMenu?: boolean;
  componentLocation?: ComponentLocationType;
}
interface Tag {
  type: string;
  tag: string;
  displayTextFunction: (text: string) => string;
}
const tags: Array<Tag> = [
  { type: "genderType", tag: "gender", displayTextFunction: genderDisplayText },
  {
    type: "programType",
    tag: "program",
    displayTextFunction: programTypeDisplayText,
  },
  { type: "planType", tag: "type", displayTextFunction: planTypeDisplayText },
  {
    type: "repRangeType",
    tag: "repRange",
    displayTextFunction: repRangeDisplayText,
  },
  {
    type: "durationType",
    tag: "duration",
    displayTextFunction: durationDisplayText,
  },
  {
    type: "frequencyType",
    tag: "frequency",
    displayTextFunction: frequencyDisplayText,
  },
  {
    type: "versionType",
    tag: "planVersion",
    displayTextFunction: versionDisplayText,
  },
];

const PlanCard = (props: Props) => {
  const {
    plan,
    filterState,
    bgColor,
    buttonBgColor,
    selectedButtonBgColor,
    index,
    margin,
    showEditMenu,
    onPlanClick,
    onEdit,
    onDelete,
    onClone,
    componentLocation,
  } = props;

  const [confirmDialog, setConfirmDialog] = useState(false);

  const meatballsOptions = [];
  if (onEdit) {
    meatballsOptions.push({ text: "Edit", onClick: onEdit });
  }
  if (onClone) {
    meatballsOptions.push({ text: "Clone", onClick: onClone });
  }
  if (onDelete) {
    meatballsOptions.push({
      text: "Delete",
      onClick: () => setConfirmDialog(true),
    });
  }

  return (
    <Link
      // Only change the URL for master and custom plan perspective, otherwise trainers cannot
      //   add workout groups to client training programs
      to={
        componentLocation !== ComponentLocationType.ClientTab
          ? `/programLibrary/${plan.id}`
          : () => {}
      }
      onClick={() => onPlanClick && onPlanClick()}
    >
      <div
        className="d-flex align-items-center nofocus"
        style={{
          cursor: "pointer",
          position: "relative",
          minHeight: 80,
          borderRadius: 8,
          padding: "8px 20px",
          paddingRight: showEditMenu && 30,
          margin: margin || 0,
          backgroundColor: bgColor || colors.caliber_gray_5,
        }}
      >
        {confirmDialog && (
          <ConfirmationDialog
            show={confirmDialog}
            text="Do you really want to delete the plan?"
            onCancel={() => setConfirmDialog(false)}
            onConfirm={onDelete}
          />
        )}
        {showEditMenu && (
          <div
            style={{
              position: "absolute",
              right: 0,
              top: 15,
            }}
          >
            <MeatballsMenu items={meatballsOptions} />
          </div>
        )}
        <div data-index={index} className="d-flex flex-column">
          <div data-index={index} className="d-flex flex-wrap">
            {tags.map(({ type, tag, displayTextFunction }) => {
              const value = plan[tag];
              const isSelected =
                filterState &&
                filterState[type] &&
                filterState[type].includes(value);
              return (
                value && (
                  <ButtonTag
                    noPointer={!showEditMenu}
                    bgColor={buttonBgColor}
                    selectedBgColor={selectedButtonBgColor}
                    variant="tag-button"
                    margin={6}
                    nofocus
                    name={value}
                    text={displayTextFunction(value)}
                    key={`${plan.id}-${tag}`}
                    isSelected={isSelected}
                  />
                )
              );
            })}
          </div>
        </div>
      </div>
    </Link>
  );
};
export default PlanCard;
