import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import colors from "../../assets/colors";
import CheckboxChecked from "../../assets/images/CheckboxChecked.svg";
import CheckboxUnchecked from "../../assets/images/CheckboxUnchecked.svg";
import SearchIcon from "../../assets/images/SearchIcon.svg";
import {
  resetClientSearchList,
  searchClients,
} from "../../redux/actions/ClientSearch";
import { ClientSearchState } from "../../redux/actions/ClientSearch/types";
import { StoreState } from "../../redux/reducers";
import { ClientSearchResult } from "../../types/gqlTypes";
import { useDebounce, useRerenderOnResize } from "../../utils/customHooks";
import ButtonTag from "../ButtonTag";
import RadioButton from "../Icons/RadioButton";
import Loader from "../Loader";
import ProfilePhoto from "../ProfilePhoto";

interface OwnProps {
  onSelectClient: (client: ClientSearchResult) => void;
  onCopy: () => void;
  onCancel: () => void;
  selectedClients: { [key: string]: ClientSearchResult };
  clients: ClientSearchResult[];
  copyLabel: string;
  multiselect: boolean;
}
interface Props extends OwnProps {
  searchClients: (args: { page: number; searchCriteria: string }) => void;
  resetClientSearchList: () => void;
  clientSearchState: ClientSearchState;
}

const ClientListToCopy = (props: Props) => {
  const {
    searchClients,
    resetClientSearchList,
    onSelectClient,
    onCopy,
    onCancel,
    clientSearchState,
    selectedClients,
    clients,
    copyLabel,
    multiselect,
  } = props;
  const { id: clientId } = useParams();
  useRerenderOnResize();
  const inputFocus = useRef(null);
  const [queryString, setQueryString] = useState("");
  const debouncedSearchValue = useDebounce(queryString, 400);

  useEffect(() => {
    inputFocus.current.focus();
  }, []);
  useEffect(() => {
    searchClients({ page: 0, searchCriteria: debouncedSearchValue });
    return () => {
      resetClientSearchList();
    };
  }, [debouncedSearchValue]);

  return (
    <div
      className="d-flex flex-column"
      style={{
        height: Math.min(window.innerHeight * 0.55, 480) + 215,
        width: 414,
        padding: 24,
      }}
    >
      <div className="heading-normal flex-grow-0 d-flex justify-content-center">
        Select a client
      </div>
      <div
        className="d-flex flex-column flex-grow-0"
        style={{
          position: "relative",
          margin: "15px 6px",
        }}
      >
        <input
          ref={inputFocus}
          className="bordered-item heading-small d-flex flex-column justify-content-center align-items-center"
          style={{
            border: `1px solid ${colors.caliber_secondary_gray_5}`,
            backgroundColor: colors.caliber_secondary_gray_5,
            color: colors.caliber_gray_text,
            borderRadius: 8,
            height: 48,
            paddingLeft: 24,
            marginRight: 5,
          }}
          value={queryString}
          onChange={(event) => setQueryString(event.target.value)}
          placeholder="Search"
        />
        <img
          style={{
            position: "absolute",
            right: 34,
            top: 17,
          }}
          src={SearchIcon}
          alt="icon"
        />
      </div>

      <div
        style={{
          height: Math.min(window.innerHeight * 0.55, 480),
          paddingLeft: 6,
          position: "relative",
          overflowY: "auto",
        }}
      >
        {clientSearchState?.isLoading && (
          <div
            style={{
              position: "absolute",
              zIndex: 3,
              top: 10,
              left: "calc(50% - 24px)",
            }}
          >
            <Loader />
          </div>
        )}
        <InfiniteScroll
          className="flex-grow-0 d-flex flex-column"
          style={{
            flex: 0,
            height: Math.min(window.innerHeight * 0.55, 480),
            maxHeight: Math.min(window.innerHeight * 0.55, 480),
            position: "relative",
          }}
          useWindow={false}
          pageStart={0}
          loadMore={() => {
            searchClients({
              page: clientSearchState.page + 1,
              searchCriteria: queryString,
            });
          }}
          hasMore={clientSearchState.page < clientSearchState.totalPages - 1}
        >
          {clientSearchState?.foundClients?.map((client) => {
            return (
              client.id !== clientId && (
                <div
                  className="d-flex"
                  key={client.id}
                  style={{
                    flexGrow: 0,
                    marginRight: 5,
                    marginTop: 4,
                    backgroundColor: colors.caliber_secondary_gray_5,
                    borderRadius: 6,
                    height: 56,
                    padding: "10px 16px",
                  }}
                >
                  <div
                    style={{
                      marginRight: 25,
                    }}
                  >
                    <ProfilePhoto
                      imageUrl={client?.profileMediaIconUrl}
                      size={36}
                      bgColor={colors.caliber_secondary_gray_5}
                    />
                  </div>
                  <div
                    className="d-flex flex-column justify-content-center bold"
                    style={{
                      flex: 1,
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: colors.caliber_secondary,
                    }}
                  >
                    {client?.firstName} {client?.lastName}
                  </div>
                  {multiselect && (
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={() => onSelectClient(client)}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          onSelectClient(client);
                        }
                      }}
                      className="d-flex flex-column justify-content-center pointer nofocus"
                      style={{
                        width: 16,
                        height: 36,
                        paddingTop: 10,
                        paddingBottom: 10,
                      }}
                    >
                      <img
                        src={
                          selectedClients?.[client?.id]
                            ? CheckboxChecked
                            : CheckboxUnchecked
                        }
                      />
                    </div>
                  )}

                  {!multiselect && (
                    <div
                      className="d-flex flex-column justify-content-center pointer"
                      style={{
                        alignItems: "end",
                        flex: 1,
                      }}
                    >
                      <RadioButton
                        checked={selectedClients?.[client?.id] && true}
                        onClick={() => onSelectClient(client)}
                      />
                    </div>
                  )}
                </div>
              )
            );
          })}
        </InfiniteScroll>
      </div>
      <div
        className="d-flex justify-content-around"
        style={{
          paddingTop: 24,
        }}
      >
        <ButtonTag
          text="Cancel"
          onClick={onCancel}
          height={32}
          bgColor={colors.caliber_gray_text}
          textColor={colors.caliber_white}
          minWidth={160}
        />
        <ButtonTag
          disabled={clients.length === 0}
          text={copyLabel || "Copy the program"}
          onClick={onCopy}
          height={32}
          bgColor={
            clients.length === 0
              ? colors.caliber_gray_2
              : colors.caliber_green_200
          }
          textColor={
            clients.length === 0
              ? colors.caliber_gray_border
              : colors.caliber_white
          }
          minWidth={160}
        />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  searchClients: (args: { page: number; searchCriteria: string }) => {
    dispatch(searchClients(args));
  },
  resetClientSearchList: () => {
    dispatch(resetClientSearchList());
  },
});

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  clientSearchState: state.clientSearchState,
  selectClient: ownProps.onSelectClient,
  onCopy: ownProps.onCopy,
  onCancel: ownProps.onCancel,
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientListToCopy);
