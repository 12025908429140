import Keycloak from "keycloak-js";
import {
  AUTH_AUTHENTICATING,
  AUTH_AUTHENTICATION_SUCCESS,
  AUTH_LOGGED_OUT,
  AuthAction,
  AUTH_AUTHENTICATION_FAIL,
} from "./types";
import { getAppConfig } from "../../../config";
import { unsubscribeUnreadMessageCount } from "../UnreadMessageCount";
import history from "../../../utils/history";

// This logic is modified from the Auth0 vanilla-spa-js sample code available here:
//   https://github.com/auth0-samples/auth0-javascript-samples/tree/master/01-Login

/**
 * Initializes the Keycloak client
 */
const keycloak = new Keycloak({
  url: getAppConfig().keycloakUrl,
  realm: getAppConfig().keycloakRealm,
  clientId: getAppConfig().clientId,
});

export const authenticateUser = () => async (dispatch, getState) => {
  dispatch({
    type: AUTH_AUTHENTICATING,
  });

  try {
    if (window.location.search.includes("code=")) {
      history.push(window.location.pathname);
    }
  } catch (error) {
    console.log(error);
  }

  keycloak
    .init({
      onLoad: "login-required",
      checkLoginIframe: false,
    })
    .then((response) => {
      const isAuthenticated = keycloak.authenticated;

      if (isAuthenticated) {
        const user = keycloak.tokenParsed.sub;
        const { token } = keycloak;
        dispatch({
          type: AUTH_AUTHENTICATION_SUCCESS,
          authToken: token,
          authUser: user,
        } as AuthAction);
      } else {
        dispatch({
          type: AUTH_AUTHENTICATION_FAIL,
          authToken: null,
          authUser: null,
        } as AuthAction);
      }
    });
};

export const logoutUser = () => async (dispatch, getState) => {
  unsubscribeUnreadMessageCount(dispatch);
  /* dispatch({
    type: AUTH_AUTHENTICATING
  }); */
  console.log("Logging out");
  keycloak.logout();
  dispatch({
    type: AUTH_LOGGED_OUT,
  });
};
