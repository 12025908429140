import {
  SAVING_CLIENT_NUTRITION_TARGET_SUCCESS,
  SAVING_CLIENT_NUTRITION_TARGET_SAVING,
  SAVING_CLIENT_NUTRITION_TARGET_FAIL,
  GET_CLIENT_NUTRITION_TARGET_FAIL,
  GET_CLIENT_NUTRITION_TARGET_LOADING,
  GET_CLIENT_NUTRITION_TARGET_SUCCESS,
  ClientNutritionTargetAction,
  ClientNutritionTargetState,
} from "../../actions/ClientNutritionTarget/types";
import { RESET_CLIENT_STATE } from "../../actions/ClientPlans/types";
import {
  CALENDAR_FETCH_ITEM_SUCCESS,
  CALENDAR_FETCH_NUTRITION_DATA_SUCCESS,
} from "../../actions/Calendar/types";

const initialState: ClientNutritionTargetState = {
  isLoading: false,
  isSaving: false,
  clientNutritionTarget: null,
};

const clientNutritionTargetState = (
  state = initialState,
  action: ClientNutritionTargetAction
) => {
  switch (action.type) {
    case RESET_CLIENT_STATE: {
      return initialState;
    }
    case GET_CLIENT_NUTRITION_TARGET_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case SAVING_CLIENT_NUTRITION_TARGET_SAVING:
      return {
        ...state,
        isSaving: true,
      };
    case SAVING_CLIENT_NUTRITION_TARGET_SUCCESS:
    case GET_CLIENT_NUTRITION_TARGET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSaving: false,
        clientNutritionTarget: action.data,
      };
    case CALENDAR_FETCH_NUTRITION_DATA_SUCCESS: {
      return {
        ...state,
        clientNutritionTarget: action.clientNutritionTarget,
      };
    }
    case CALENDAR_FETCH_ITEM_SUCCESS: {
      return {
        ...state,
        clientNutritionTarget: action.clientNutritionTarget,
      };
    }
    case GET_CLIENT_NUTRITION_TARGET_FAIL:
    case SAVING_CLIENT_NUTRITION_TARGET_FAIL:
      return {
        ...state,
        isLoading: false,
        isSaving: false,
      };
    default:
      return state;
  }
};

export default clientNutritionTargetState;
