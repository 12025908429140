import { format, parse } from "date-fns";
import "rc-slider/assets/index.css";
import Range from "rc-slider/lib/Range";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import colors from "../../assets/colors";
import { createUpdateClientNutritionTarget } from "../../redux/actions/ClientNutritionTarget";
import { StoreState } from "../../redux/reducers";
import {
  ClientNutritionTarget,
  MutationCreateUpdateClientNutritionTargetArgs,
} from "../../types/gqlTypes";
import { useKeyPress } from "../../utils/customHooks";
import Loader from "../Loader";

interface Props {
  createUpdateClientNutritionTarget: (
    args: MutationCreateUpdateClientNutritionTargetArgs
  ) => void;
  isLoading: boolean;
  isSaving: boolean;
  clientNutritionTarget: ClientNutritionTarget;
}

const NutritionMacroTargets = (props: Props) => {
  const {
    createUpdateClientNutritionTarget,
    isLoading,
    isSaving,
    clientNutritionTarget,
  } = props;
  const { id: clientId } = useParams();

  const [fat, setFat] = useState(null);
  const [protein, setProtein] = useState(null);
  const [carbs, setCarbs] = useState(null);
  const [fatPercent, setFatPercent] = useState(null);
  const [proteinPercent, setProteinPercent] = useState(null);
  const [carbsPercent, setCarbsPercent] = useState(null);
  const [target, setTarget] = useState(0);
  const [hasChanged, setHasChanged] = useState(false);

  useKeyPress("Enter", () => {
    if (hasChanged) {
      onSave();
    }
  });
  useKeyPress("Escape", () => {
    if (hasChanged) {
      resetLocalState();
    }
  });

  useEffect(() => {
    resetLocalState();
  }, [
    clientNutritionTarget?.fat,
    clientNutritionTarget?.protein,
    clientNutritionTarget?.carbs,
    clientNutritionTarget?.calories,
  ]);

  const resetLocalState = useCallback(() => {
    const fat = Math.round(clientNutritionTarget?.fat || 0);
    const carbs = Math.round(clientNutritionTarget?.carbs || 0);
    const protein = Math.round(clientNutritionTarget?.protein || 0);
    const target = clientNutritionTarget?.calories || 0;
    setFat(fat);
    setProtein(protein);
    setCarbs(carbs);
    setFatPercent(getFatPercent(fat, target));
    setCarbsPercent(getCarbPercent(carbs, target));
    setProteinPercent(getProteinPercent(fat, carbs, target));
    setTarget(clientNutritionTarget?.calories || 0);
    setHasChanged(false);
  }, [
    clientNutritionTarget?.fat,
    clientNutritionTarget?.protein,
    clientNutritionTarget?.carbs,
    clientNutritionTarget?.calories,
  ]);

  const getFatPercent = (fat, cal) =>
    Math.round(((fat || 0) * 900) / (cal || 1));
  const getCarbPercent = (fat, cal) =>
    Math.round(((fat || 0) * 400) / (cal || 1));
  const getProteinPercent = (fat, carbs, cal) =>
    100 - Math.min(100, getFatPercent(fat, cal) + getCarbPercent(carbs, cal));

  const onChange = (values) => {
    const protein = values[1];
    let carbs = values[2] - values[1];
    const fat = Math.round(((target || 0) - (protein + carbs) * 4) / 9);
    carbs = Math.round(((target || 0) - (protein * 4 + fat * 9)) / 4);
    setProtein(protein);
    setCarbs(carbs);
    setFat(fat);
    setFatPercent(getFatPercent(fat, target));
    setCarbsPercent(getCarbPercent(carbs, target));
    setProteinPercent(getProteinPercent(fat, carbs, target));
    setHasChanged(true);
  };
  const onSave = () => {
    setHasChanged(false);
    createUpdateClientNutritionTarget({
      clientId,
      fat,
      protein,
      carbs,
      calories: target,
      macroTargetsActiveDate: format(new Date(), "yyyy-MM-dd"),
    });
  };

  const saveButton = (
    <div
      role="button"
      tabIndex={0}
      className="d-flex flex-column justify-content-center align-items-center pointer nofocus"
      style={{
        backgroundColor: "#D4F5E1",
        borderRadius: 4,
        height: 22,
        width: 46,
        color: colors.caliber_green_200,
        fontSize: "14px",
        lineHeight: "32px",
      }}
      onClick={onSave}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          onSave();
        }
      }}
    >
      Save
    </div>
  );

  const handleStyle = {
    width: 24,
    height: 24,
    color: colors.caliber_white,
    backgroundColor: colors.caliber_white,
    borderColor: colors.caliber_white,
    boxShadow: "0px 2px 16px rgba(128, 130, 151, 0.2)",
  };
  const hiddenHandle = {
    visibility: "hidden",
  };
  const titleStyle = {
    color: colors.caliber_secondary,
    fontSize: "12px",
    lineHeight: "15px",
    margin: "7px 0px",
  };
  const coloredTitle = {
    fontSize: "14px",
    lineHeight: "17px",
  };
  const inputStyle = {
    marginLeft: 66,
    height: 24,
    borderRadius: 6,
    backgroundColor: colors.caliber_gray_bg,
    border: `1px solid ${colors.caliber_gray_bg}`,
    width: 70,
    padding: 2,
    color: colors.caliber_secondary,
    fontSize: "14px",
    lineHeight: "16px",
    "text-align": "center",
  };
  const labelStyle = {
    fontSize: "12px",
    lineHeight: "15px",
    color: colors.caliber_secondary,
    marginLeft: 10,
  };

  return (
    <div
      className="d-flex flex-column medium-bold"
      style={{
        marginTop: 24,
        padding: "24px 56px",
        width: 548,
        minHeight: 257,
        backgroundColor: colors.caliber_white,
        borderRadius: 8,
        position: "relative",
      }}
    >
      {(isLoading || isSaving) && (
        <div
          style={{
            position: "absolute",
            top: "30%",
            left: "48%",
          }}
        >
          <Loader />
        </div>
      )}
      <div
        className="d-flex justify-content-between"
        style={{
          fontSize: "20px",
          lineHeight: "25px",
          letterSpacing: "-0.11px",
          color: colors.caliber_secondary,
        }}
      >
        Macro Targets
        {hasChanged && saveButton}
      </div>
      <div
        className="d-flex align-items-center"
        style={{ margin: "15px 0px 2px 0px" }}
      >
        <div style={titleStyle}>Daily Calorie Target</div>
        <div className="d-flex align-items-center">
          <input
            onChange={(event) => {
              setHasChanged(true);
              const newTarget = Number(event.currentTarget.value);
              const newFat = Math.round((fatPercent * newTarget) / 900);
              const newCarb = Math.round((carbsPercent * newTarget) / 400);
              const newProtein = Math.round(
                (newTarget - newFat * 9 - newCarb * 4) / 4
              );
              setFat(newFat);
              setCarbs(newCarb);
              setProtein(newProtein);
              setTarget(newTarget);
            }}
            type="number"
            value={target.toString()}
            style={inputStyle}
          />
        </div>
        <div style={labelStyle}>cal</div>
      </div>
      <div className="d-flex" style={{ margin: "10px 0px" }}>
        <div
          className="d-flex flex-column"
          style={{
            flex: 1,
          }}
        >
          <div style={titleStyle}>Protein</div>
          <div
            style={{ ...coloredTitle, color: "#1FC866" }}
          >{`${proteinPercent}% ${protein}g`}</div>
        </div>
        <div
          className="d-flex flex-column"
          style={{
            flex: 1,
          }}
        >
          <div style={titleStyle}>Carbs</div>
          <div
            style={{ ...coloredTitle, color: "#3446FB" }}
          >{`${carbsPercent}% ${carbs}g`}</div>
        </div>
        <div
          className="d-flex flex-column"
          style={{
            flex: 1,
          }}
        >
          <div style={titleStyle}>Fat</div>
          <div
            style={{ ...coloredTitle, color: "#F2994A" }}
          >{`${fatPercent}% ${fat}g`}</div>
        </div>
      </div>
      <div
        style={{
          flex: 1,
        }}
      >
        <Range
          onChange={onChange}
          value={[-1, protein, carbs + protein, carbs + protein + fat + 1]}
          count={4}
          railStyle={{
            visibility: "hidden",
          }}
          trackStyle={[
            { backgroundColor: "#1FC866", opacity: 0.4, height: 14 },
            { backgroundColor: "#3446FB", opacity: 0.4, height: 14 },
            { backgroundColor: "#F2994A", opacity: 0.4, height: 14 },
          ]}
          handleStyle={[hiddenHandle, handleStyle, handleStyle, hiddenHandle]}
          pushable
          id="macro_targets_sliders"
          step={1}
          min={-1}
          max={carbs + protein + fat + 1}
        />
      </div>
      <div className="d-flex align-items-center" style={{ height: 20 }}>
        <div style={titleStyle}>Active since</div>
        <div
          className="d-flex flex-column justify-content-center align-items-center medium-bold"
          style={{
            backgroundColor: colors.caliber_gray_bg,
            padding: "2px 10px",
            borderRadius: 4,
            minWidth: 90,
            marginLeft: 60,
            fontSize: "12px",
            lineHeight: "16px",
          }}
        >
          {clientNutritionTarget?.macroTargetsActiveDate
            ? format(
                parse(
                  clientNutritionTarget?.macroTargetsActiveDate,
                  "yyyy-MM-dd",
                  new Date()
                ),
                "M.d.yyyy"
              )
            : "--"}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  createUpdateClientNutritionTarget: (
    args: MutationCreateUpdateClientNutritionTargetArgs
  ) => {
    dispatch(createUpdateClientNutritionTarget(args));
  },
});

const mapStateToProps = (state: StoreState) => ({
  clientNutritionTarget: state.clientNutritionTargetState.clientNutritionTarget,
  isLoading: state.clientNutritionTargetState.isLoading,
  isSaving: state.clientNutritionTargetState.isSaving,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NutritionMacroTargets);
