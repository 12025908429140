import {
  StrengthScoreDashboard,
  StrengthScoreLight,
} from "../../../types/gqlTypes";

export const CLIENT_STRENGTH_DASHBOARD_SUCCESS =
  "CLIENT_STRENGTH_DASHBOARD_SUCCESS";
export const CLIENT_STRENGTH_DASHBOARD_FAIL = "CLIENT_STRENGTH_DASHBOARD_FAIL";
export const CLIENT_STRENGTH_DASHBOARD_LOADING =
  "CLIENT_STRENGTH_DASHBOARD_LOADING";

export const CLIENT_STRENGTH_SCORE_MUSCLE_GROUP_SUCCESS =
  "CLIENT_STRENGTH_SCORE_MUSCLE_GROUP_SUCCESS";
export const CLIENT_STRENGTH_SCORE_MUSCLE_GROUP_FAIL =
  "CLIENT_STRENGTH_SCORE_MUSCLE_GROUP_FAIL";
export const CLIENT_STRENGTH_SCORE_MUSCLE_GROUP_LOADING =
  "CLIENT_STRENGTH_SCORE_MUSCLE_GROUP_LOADING";

export const REGENERATE_STRENGTH_DASHBOARD_SUCCESS =
  "REGENERATE_STRENGTH_DASHBOARD_SUCCESS";
export const REGENERATE_STRENGTH_DASHBOARD_FAIL =
  "REGENERATE_STRENGTH_DASHBOARD_FAIL";
export const REGENERATE_STRENGTH_DASHBOARD_LOADING =
  "REGENERATE_STRENGTH_DASHBOARD_LOADING";

export const RESET_CLIENT_STRENGTH_DASHBOARD =
  "RESET_CLIENT_STRENGTH_DASHBOARD";

export interface ClientStrengthDashboardState {
  isLoading: boolean;
  isLoadingMuscleScores: boolean;
  strengthScoreDashboardChanges: StrengthScoreDashboard[];
  strengthScoreDashboard: StrengthScoreDashboard;
  historicalStrengthScore: StrengthScoreLight[];
  historicalStrengthScoreMuscleGroup: StrengthScoreLight[];
  historicalStrengthBalance: StrengthScoreLight[];
}

export interface ClientStrengthDashboardAction {
  type: string;
  data?: StrengthScoreDashboard;
  strengthScoreDashboardChangesResult?: StrengthScoreDashboard[];
  strengthScoreResult?: StrengthScoreDashboard;
  historicalStrengthScoreResult?: StrengthScoreLight[];
  historicalStrengthScoreMuscleGroup?: StrengthScoreLight[];
  historicalStrengthBalanceResult?: StrengthScoreLight[];
}
