import React from "react";

interface Props {
  defaultColor: string;
  legColor: string;
  backColor: string;
  armColor: string;
  shoulderColor: string;
  skeletonSize: {
    width: number;
    height: number;
  };
}

const BackMaleSkeleton = (props: Props) => {
  const {
    defaultColor,
    legColor,
    backColor,
    armColor,
    shoulderColor,
    skeletonSize: { width, height },
  } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 218 431"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <BackMaleHead color={defaultColor} />
      <BackMaleNeck color={defaultColor} />
      <BackMaleTraps color={defaultColor} />

      <BackMaleRhomboids color={backColor} />
      <BackMaleTeresMajor color={backColor} />
      <BackMaleErectorSpinae color={backColor} />
      <BackMaleLowerBack color={backColor} />

      <BackMaleMidShoulders color={shoulderColor} />
      <BackMaleRearShoulders color={shoulderColor} />

      <BackMaleTriceps color={armColor} />
      <BackMaleForearms color={armColor} />

      <BackMaleHands color={defaultColor} />

      <BackMaleObligues color={defaultColor} />

      <BackMaleAdductors color={legColor} />
      <BackMaleAbductors color={legColor} />
      <BackMaleCalcaneanTendon color={legColor} />
      <BackMaleGlutes color={legColor} />
      <BackMaleHamstring color={legColor} />
      <BackMaleCalves color={legColor} />

      <BackMaleFeet color={defaultColor} />
    </svg>
  );
};

const BackMaleCalves = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M87.9336 298.571L88.9571 351.274L98.0254 361.496L102.412 354.924L102.704 340.763L98.7565 319.593L87.9336 298.571Z"
        fill={color}
      />
      <path
        d="M85.1561 295.506L84.5712 338.574L87.0569 354.924L82.8166 358.43L75.3594 343.245L77.2602 313.9L80.9157 301.929L81.2082 293.313L85.1561 295.506Z"
        fill={color}
      />
      <path
        d="M130.026 298.571L129.005 351.274L119.937 361.496L115.55 354.924L115.258 340.763L119.206 319.593L130.026 298.571Z"
        fill={color}
      />
      <path
        d="M132.807 295.506L133.392 338.574L130.906 354.924L135.147 358.43L142.604 343.245L140.703 313.9L137.047 301.929L136.755 293.313L132.807 295.506Z"
        fill={color}
      />
    </>
  );
};
const BackMaleForearms = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M41.3347 146.491L42.8953 152.524L40.7498 172.77L26.1279 201.386L20.8613 193.405L26.7128 183.865L35.4859 150.772L41.3347 146.491Z"
        fill={color}
      />
      <path
        d="M50.6932 143.181L45.2352 151.552L45.4292 166.345L37.0469 187.175L51.4748 169.848L59.6631 149.02L55.9597 151.746L53.0327 150.578L50.6932 143.181Z"
        fill={color}
      />
      <path
        d="M167.266 143.181L172.727 151.552L172.533 166.345L180.915 187.175L166.487 169.848L158.299 149.02L162.002 151.746L164.927 150.578L167.266 143.181Z"
        fill={color}
      />
      <path
        d="M176.666 146.491L175.105 152.524L177.251 172.77L191.873 201.386L197.139 193.405L191.291 183.865L182.515 150.772L176.666 146.491Z"
        fill={color}
      />
    </>
  );
};
const BackMaleGlutes = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M75.9431 176.37L72.5801 196.08L81.6457 183.526L92.761 178.122L86.3247 171.552L75.9431 176.37Z"
        fill={color}
      />
      <path
        d="M94.955 182.358L97.5869 188.927L106.068 194.328L106.799 212.287L85.7405 222.214L76.6749 231.849L71.8496 219.002L72.5807 202.066L80.6228 188.197L94.955 182.358Z"
        fill={color}
      />
      <path
        d="M142.019 176.37L145.382 196.08L136.317 183.526L125.201 178.122L131.637 171.552L142.019 176.37Z"
        fill={color}
      />
      <path
        d="M123.008 182.358L120.376 188.927L111.895 194.328L111.164 212.287L132.222 222.214L141.288 231.849L146.113 219.002L145.382 202.066L137.34 188.197L123.008 182.358Z"
        fill={color}
      />
    </>
  );
};
const BackMaleHamstring = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M95.2477 222.36L93.7855 254.187L98.4646 269.956L100.658 296.09L95.3939 308.352L85.1559 282.948L82.8164 252.727L87.3492 226.155L95.2477 222.36Z"
        fill={color}
      />
      <path
        d="M82.5244 231.849L78.5764 267.472L82.3781 279.299L76.6756 300.907L74.9209 279.153L70.0957 264.26L75.7983 235.498L82.5244 231.849Z"
        fill={color}
      />
      <path
        d="M122.715 222.36L124.177 254.187L119.498 269.956L117.305 296.09L122.569 308.352L132.807 282.948L135.146 252.727L130.611 226.155L122.715 222.36Z"
        fill={color}
      />
      <path
        d="M135.439 231.849L139.387 267.472L135.586 279.299L141.288 300.907L143.043 279.153L147.868 264.26L142.166 235.498L135.439 231.849Z"
        fill={color}
      />
    </>
  );
};
const BackMaleRearShoulders = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M67.2617 74.7173L74.0437 76.071L52.4005 100.891L51.4434 95.155L67.2617 74.7173Z"
        fill={color}
      />
      <path
        d="M150.609 74.7969L143.827 76.1506L165.471 100.97L166.428 95.2346L150.609 74.7969Z"
        fill={color}
      />
    </>
  );
};
const BackMaleTeresMajor = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M72.8733 81.7642H78.1372L85.7406 101.767L64.3926 98.8469L64.8312 90.9611L72.8733 81.7642Z"
        fill={color}
      />
      <path
        d="M145.09 81.7642H139.826L132.223 101.767L153.571 98.8469L153.132 90.9611L145.09 81.7642Z"
        fill={color}
      />
    </>
  );
};
const BackMaleTraps = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M102.267 48.9153L105.191 53.2947L104.314 70.2315L91.8826 66.8739L79.8926 65.706L102.267 48.9153Z"
        fill={color}
      />
      <path
        d="M115.698 48.9153L112.773 53.2947L113.651 70.2315L126.079 66.8739L138.072 65.706L115.698 48.9153Z"
        fill={color}
      />
    </>
  );
};
const BackMaleTriceps = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M64.2474 126.879L61.1742 97.0952L44.8906 113.788L46.0444 139.136L54.8867 143.375L64.2474 126.879Z"
        fill={color}
      />
      <path
        d="M153.686 126.879L156.756 97.0952L173.042 113.788L171.889 139.136L163.044 143.375L153.686 126.879Z"
        fill={color}
      />
    </>
  );
};
const BackMaleAbductors = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M70.3866 224.696L74.4807 234.331L69.8017 250.392L69.2168 227.761L70.3866 224.696Z"
        fill={color}
      />
      <path
        d="M147.575 224.696L143.48 234.331L148.16 250.392L148.744 227.761L147.575 224.696Z"
        fill={color}
      />
    </>
  );
};
const BackMaleAdductors = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M97.5866 220.316L96.8555 248.348L99.4874 262.508L104.02 241.632V216.083L97.5866 220.316Z"
        fill={color}
      />
      <path
        d="M120.375 220.316L121.106 248.348L118.474 262.508L113.941 241.632V216.083L120.375 220.316Z"
        fill={color}
      />
    </>
  );
};
const BackMaleRhomboids = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M66.1465 70.2313L80.9147 75.1947L89.1029 96.803L104.751 111.401L103.581 87.1654L103.728 76.2166L81.0609 68.0415L66.1465 70.2313Z"
        fill={color}
      />
      <path
        d="M151.816 70.2313L137.047 75.1947L128.856 96.803L113.211 111.401L114.381 87.1654L114.234 76.2166L136.901 68.0415L151.816 70.2313Z"
        fill={color}
      />
    </>
  );
};
const BackMaleErectorSpinae = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M64.5388 101.183L86.6179 105.562L104.459 122.496L105.5 153.5L96.5 177L86.7642 167.027L64.3926 106L64.5388 101.183Z"
        fill={color}
      />
      <path
        d="M153.423 101.183L131.344 105.562L113.503 122.496L112.5 153.5L121.5 177L131.198 167.027L153.57 106L153.423 101.183Z"
        fill={color}
      />
    </>
  );
};
const BackMaleLowerBack = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M114.264 165.026L109 151L103.906 165.026L97 181.678L98.7264 186.96L109.085 194L119.443 186.96L121 181.678L114.264 165.026Z"
        fill={color}
      />
    </>
  );
};
const BackMaleNeck = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M104.606 32.1243L107.53 33.8787L107.969 42.9297L106.209 50.0326L103.551 46.3166L105.337 40.156L104.606 32.1243Z"
        fill={color}
      />
      <path
        d="M113.357 32.1243L110.433 33.8787L109.994 42.9297L111.791 50.1653L114.45 46.4493L112.626 40.156L113.357 32.1243Z"
        fill={color}
      />
    </>
  );
};
const BackMaleMidShoulders = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M60.5915 73.4434L67.2617 74.7173L51.4434 95.1551L50.3535 89.2096L60.5915 73.4434Z"
        fill={color}
      />
      <path
        d="M157.28 73.5229L150.609 74.7969L166.428 95.2347L167.518 89.2892L157.28 73.5229Z"
        fill={color}
      />
    </>
  );
};
const BackMaleHead = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M124.754 18.9859L124.169 8.91298L116.273 0.881209L108.999 0L101.689 0.881209L93.79 8.91298L93.2078 18.9859L89.9883 20.8836L93.2078 29.0587L94.67 29.6427L94.8162 39.2803L97.4481 44.3897L102.127 36.2146L100.08 29.3507L104.613 31.2485L108.999 30.1735L113.349 31.2485L117.882 29.3507L115.835 36.2146L120.514 44.3897L123.146 39.2803L123.292 29.6427L124.754 29.0587L127.973 20.8836L124.754 18.9859Z"
        fill={color}
      />
    </>
  );
};
const BackMaleFeet = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M84.0363 418.435L87.9337 421.742L94.37 421.158L97.2944 417.851L100.607 422.522L96.7095 425.052L87.3488 427L79.9395 419.213L81.1119 416.099L84.0363 418.435Z"
        fill={color}
      />
      <path
        d="M133.966 418.435L130.066 421.742L123.632 421.158L120.705 417.851L117.393 422.522L121.29 425.052L130.651 427L138.06 419.213L136.89 416.099L133.966 418.435Z"
        fill={color}
      />
    </>
  );
};
const BackMaleCalcaneanTendon = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M88.2267 356.824L83.9863 360.62L90.42 391.133L92.0311 418.873L94.2244 420.041L95.5403 391.425L98.6109 369.817L97.7336 364.853L88.2267 356.824Z"
        fill={color}
      />
      <path
        d="M84.8637 378.138L88.8117 397.702L89.8379 418.581L87.9344 420.041L85.5948 418.289L86.4722 414.637L82.9629 394.053V375.802L84.8637 378.138Z"
        fill={color}
      />
      <path
        d="M129.738 356.824L133.978 360.62L127.542 391.133L125.933 418.873L123.74 420.041L122.424 391.425L119.354 369.817L120.231 364.853L129.738 356.824Z"
        fill={color}
      />
      <path
        d="M133.099 378.138L129.149 397.702L128.125 418.581L130.026 420.041L132.368 418.289L131.491 414.637L135 394.053V375.802L133.099 378.138Z"
        fill={color}
      />
    </>
  );
};
const BackMaleObligues = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M77.1137 149.655L83.255 163.815L83.4012 169.801L75.2129 172.428L77.1137 149.655Z"
        fill={color}
      />
      <path
        d="M140.85 149.655L134.709 163.815L134.562 169.801L142.751 172.428L140.85 149.655Z"
        fill={color}
      />
    </>
  );
};
const BackMaleHands = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M18.7188 199.244L21.2523 205.277L28.6617 206.445L29.2466 217.346L25.3465 232.921L22.8129 232.725L25.2747 222.36L22.6428 222.288L18.522 233.309L15.4036 232.921L19.277 221.776L16.5733 221.264L11.3094 233.115L7.40934 232.725L12.7716 220.388L10.9186 219.294L4.28822 229.805L0.975682 229.418L10.9186 210.533L9.94292 208.977L0.584877 212.871L0 210.729L7.60341 203.916L18.7188 199.244Z"
        fill={color}
      />
      <path
        d="M199.282 199.244L196.748 205.277L189.339 206.445L188.754 217.346L192.654 232.921L195.188 232.725L192.726 222.36L195.358 222.288L199.478 233.309L202.597 232.921L198.723 221.776L201.427 221.264L206.691 233.115L210.591 232.725L205.229 220.388L207.082 219.294L213.712 229.805L217.025 229.418L207.082 210.533L208.058 208.977L217.416 212.871L218.001 210.729L210.397 203.916L199.282 199.244Z"
        fill={color}
      />
    </>
  );
};

export default BackMaleSkeleton;
