import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getClientNutritionColumnsForDebug,
  getWeekOfNutritionCalendarItems,
} from "../../../redux/actions/DebugTools";
import { StoreState } from "../../../redux/reducers";
import {
  CalendarDay,
  ClientSearchResult,
  MacronutrientType,
} from "../../../types/gqlTypes";
import WeeklyNutritionTable from "./WeeklyNutritionTable";

interface Props {
  client: ClientSearchResult;
  nutritionCalendarData: CalendarDay[];
  getClientNutritionColumnsForDebug: () => void;
  getNutritionCalendarData: (clientId: string) => void;
}

const DebugNutritionColumns = (props: Props) => {
  const {
    client,
    nutritionCalendarData,
    getClientNutritionColumnsForDebug,
    getNutritionCalendarData,
  } = props;
  const { id: clientId } = useParams();

  useEffect(() => {
    getClientNutritionColumnsForDebug();
    getNutritionCalendarData(clientId);
  }, []);

  return (
    <div>
      <div
        style={{
          marginTop: 20,
        }}
      >
        Calories Weekly Average
      </div>
      <WeeklyNutritionTable
        nutrient={MacronutrientType.Calories}
        weeklyNumber={client?.calories7DayAverage}
        target={client?.caloriesTarget}
        data={nutritionCalendarData}
      />

      <div
        style={{
          marginTop: 20,
        }}
      >
        Protein Weekly Average
      </div>
      <WeeklyNutritionTable
        nutrient={MacronutrientType.Protein}
        weeklyNumber={client?.protein7DayAverage}
        target={client?.proteinTarget}
        data={nutritionCalendarData}
      />

      <div
        style={{
          marginTop: 20,
        }}
      >
        Fat Weekly Average
      </div>
      <WeeklyNutritionTable
        nutrient={MacronutrientType.Fat}
        weeklyNumber={client?.fat7DayAverage}
        target={client?.fatTarget}
        data={nutritionCalendarData}
      />

      <div
        style={{
          marginTop: 20,
        }}
      >
        Carbs Weekly Average
      </div>
      <WeeklyNutritionTable
        nutrient={MacronutrientType.Carbs}
        weeklyNumber={client?.carbs7DayAverage}
        target={client?.carbsTarget}
        data={nutritionCalendarData}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getClientNutritionColumnsForDebug: () => {
    dispatch(getClientNutritionColumnsForDebug());
  },
  getNutritionCalendarData: (clientId: string) => {
    dispatch(getWeekOfNutritionCalendarItems(clientId));
  },
});

const mapStateToProps = (state: StoreState) => ({
  client: state.debugToolsState.clientNutritionColumns,
  nutritionCalendarData: state.debugToolsState.nutritionCalendarData,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DebugNutritionColumns);
