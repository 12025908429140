import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import colors from "../../assets/colors";
import {
  createManager,
  editManager,
  resetAdminCreateManagerTrainerState,
  resetSearch,
} from "../../redux/actions/AdminTeamManagers";
import {
  createTrainer,
  editTrainer,
} from "../../redux/actions/AdminTeamTrainers";
import { resetMediaState } from "../../redux/actions/Media";
import { updateCoachProfileIcon } from "../../redux/actions/UpdateCoachProfileIcon";
import { StoreState } from "../../redux/reducers";
import {
  Manager,
  MutationCreateUserProfileArgs,
  MutationUpdateCoachProfileIconUrlArgs,
  MutationUpdateUserProfileArgs,
  Trainer,
  UnitType,
  UserStatusType,
  UserType,
} from "../../types/gqlTypes";
import ButtonTag from "../ButtonTag";
import { InputTypes } from "../Input";
import DeleteClientButton from "../UserTable/ClientDetails/DeleteClientButton";
import PersonalInfo from "./PersonalInfo";
import ProfileImage from "./ProfileImage";

interface OwnProps {
  closeForm: () => void;
  editMode?: boolean;
  viewMode?: boolean;
  userToEdit?: Manager & Trainer;
}
interface Props extends OwnProps {
  successManagerCreating: boolean;
  successManagerUpdating: boolean;
  successTrainerCreating: boolean;
  successTrainerUpdating: boolean;

  updateCoachProfileIcon: (args: MutationUpdateCoachProfileIconUrlArgs) => void;
  createManager: (args: MutationCreateUserProfileArgs) => void;
  createTrainer: (args: MutationCreateUserProfileArgs) => void;
  editTrainer: (args: MutationUpdateUserProfileArgs) => void;
  editManager: (args: MutationUpdateUserProfileArgs) => void;

  resetState: () => void;
  resetMediaState: () => void;
  resetSearch: (role: UserType) => void;
}

const TeamMemberForm = (props: Props) => {
  const {
    successManagerCreating,
    successManagerUpdating,
    successTrainerCreating,
    successTrainerUpdating,

    closeForm,
    createManager,
    createTrainer,
    editTrainer,
    editManager,
    resetState,
    resetSearch,
    resetMediaState,
    updateCoachProfileIcon,

    editMode,
    viewMode,
    userToEdit,
  } = props;

  const initialState = {
    role: "",
    firstName: "",
    lastName: "",
    status: UserStatusType.Active,
    email: "",
    password: "",
    manager: "",
    profileIconMediaUrlId: "",
    isAvailable: true,
  };
  const inititalErrorState = {
    role: false,
    firstName: false,
    lastName: false,
    email: false,
    status: false,
    password: false,
    profileIconMediaUrlId: false,
    manager: false,
    isAvailable: false,
  };
  const [state, setState] = useState(initialState);
  const [errorState, setErrorState] = useState(inititalErrorState);

  useEffect(() => {
    if ((editMode || viewMode) && userToEdit) {
      setState({
        role: userToEdit.type,
        firstName: userToEdit.firstName,
        lastName: userToEdit.lastName,
        email: userToEdit.email,
        status: userToEdit.status,
        password: "",
        profileIconMediaUrlId: userToEdit?.profileIconMediaUrl?.id,
        manager: userToEdit?.assignedManager?.id,
        isAvailable: userToEdit.isAvailable,
      });
      setErrorState(inititalErrorState);
    } else if (!userToEdit) {
      setErrorState(inititalErrorState);
      setState(initialState);
    }
  }, [editMode, viewMode, userToEdit]);

  const inputs = [
    {
      key: "role",
      type: "dropdown",
      placeholder: "Role",
      label: "Role",
      validate: (value) => (value ? value.length === 0 : true),
      errorMessage: "Choose a role",
    },
    {
      key: "firstName",
      type: "text",
      placeholder: "First Name",
      label: "First Name",
      validate: (value) => (value ? value.length === 0 : true),
      errorMessage: "Enter first name",
    },
    {
      key: "lastName",
      type: "text",
      placeholder: "Last Name",
      label: "Last Name",
      validate: (value) => (value ? value.length === 0 : true),
      errorMessage: "Enter last name",
    },
    {
      key: "email",
      type: "text",
      placeholder: "E-mail",
      label: "E-mail",
      validate: (value) => !/\S+@\S+\.\S+/.test(value),
      errorMessage: "Enter a valid email",
    },
    {
      key: "status",
      type: "dropdown",
      placeholder: "Status",
      label: "Status",
      validate: (value) => (value ? value.length === 0 : true),
      errorMessage: "Choose a status",
    },
    {
      key: "isAvailable",
      type: "dropdown",
      placeholder: "Available For Matching",
      label: "Available For Matching",
      validate: (value) => false,
      errorMessage: "Choose if available",
    },
    {
      key: "password",
      type: "password",
      placeholder: "Password",
      label: "Password",
      validate: (value) => (value ? value.length === 0 : true),
      errorMessage: "Enter a valid password",
    },
    {
      key: "manager",
      placeholder: "Manager",
      label: "Manager",
      validate: (value) => (value ? value.length === 0 : true),
      errorMessage: "Choose a manager",
      type: "dropdown",
    },
  ];

  const validateInputs = () => {
    const newErrorState = {
      role: state.role.length === 0,
      profileIconMediaUrlId: state.profileIconMediaUrlId.length === 0,
    };
    let errorFound = newErrorState.role || newErrorState.profileIconMediaUrlId;
    inputs
      .filter(
        (input) =>
          !((viewMode || editMode) && input.type === InputTypes.Password)
      )
      .forEach(({ key, validate }) => {
        if (key === "manager" && state.role !== UserType.Trainer) {
          newErrorState[key] = false;
        } else {
          const err = validate(state[key]);
          newErrorState[key] = err;
          errorFound = errorFound || err;
        }
      });
    setErrorState({ ...errorState, ...newErrorState });
    return errorFound;
  };

  const onSave = () => {
    const errorFound = validateInputs();
    if (!errorFound) {
      const args = {
        firstName: state.firstName,
        lastName: state.lastName,
        email: state.email,
        defaultPassword: state.password,
        profileIconMediaUrlId: state.profileIconMediaUrlId,
        type: state.role,
        assignedManagerId: state.manager,
        status: state.status,
        isAvailable: state.isAvailable,
      } as MutationCreateUserProfileArgs & MutationUpdateUserProfileArgs;
      if (state.role === UserType.Manager && editMode) {
        if (
          state.profileIconMediaUrlId !== userToEdit?.profileIconMediaUrl?.id
        ) {
          updateCoachProfileIcon({
            trainerId: userToEdit.id,
            mediaUrlId: state.profileIconMediaUrlId,
          });
        }
        editManager({
          birthDate: userToEdit.birthDate,
          city: userToEdit.city,
          country: userToEdit.country,
          dateMask: "yyyy-MM-dd",
          email: state.email,
          firstName: state.firstName,
          lastName: state.lastName,
          gender: userToEdit.gender,
          isConsultationCompleted: userToEdit.isConsultationCompleted,
          isProfileCompleted: userToEdit.isProfileCompleted,
          phone: userToEdit.phone,
          state: userToEdit.state,
          unitHeight: UnitType.In,
          userId: userToEdit.id,
          status: state.status,
        });
      } else if (state.role === UserType.Manager && !editMode) {
        delete args.assignedManagerId;
        createManager(args);
      } else if (state.role === UserType.Trainer && editMode) {
        if (
          state.profileIconMediaUrlId !== userToEdit?.profileIconMediaUrl?.id
        ) {
          updateCoachProfileIcon({
            trainerId: userToEdit.id,
            mediaUrlId: state.profileIconMediaUrlId,
          });
        }
        const editArgs = {
          birthDate: userToEdit.birthDate,
          city: userToEdit.city,
          country: userToEdit.country,
          dateMask: "yyyy-MM-dd",
          email: state.email,
          firstName: state.firstName,
          lastName: state.lastName,
          gender: userToEdit.gender,
          isConsultationCompleted: userToEdit.isConsultationCompleted,
          isProfileCompleted: userToEdit.isProfileCompleted,
          phone: userToEdit.phone,
          state: userToEdit.state,
          unitHeight: UnitType.In,
          userId: userToEdit.id,
          status: state.status,
        };
        if (userToEdit.assignedManager.id !== state.manager) {
          /* eslint-disable */
          editArgs["newAssignedManagerId"] = state.manager;
          /* eslint-enable */
        }
        editTrainer(editArgs);
      } else if (state.role === UserType.Trainer && !editMode) {
        createTrainer(args);
      }
    }
  };

  useEffect(() => {
    if (
      successManagerCreating ||
      successTrainerCreating ||
      successManagerUpdating ||
      successTrainerUpdating
    ) {
      closeForm();
    }
    return () => {
      resetState();
      resetMediaState();
    };
  }, [
    successManagerCreating,
    successManagerUpdating,
    successTrainerCreating,
    successTrainerUpdating,
  ]);

  return (
    <div
      className="d-flex flex-column"
      style={{
        flex: 1,
      }}
    >
      <div className="d-flex justify-content-end">
        <ButtonTag
          onClick={closeForm}
          text="Close"
          height={32}
          margin="0px 8px"
          textColor={colors.caliber_white}
          bgColor={colors.caliber_gray_text}
          minWidth={134}
        />
        {!viewMode && (
          <ButtonTag
            text="Save"
            onClick={onSave}
            height={32}
            margin="0px 8px"
            textColor={colors.caliber_white}
            bgColor={colors.caliber_green_200}
            minWidth={134}
          />
        )}
      </div>
      {!(editMode || viewMode) && (
        <div
          className="heading-small"
          style={{
            color: colors.caliber_gray_text,
            marginTop: 50,
          }}
        >
          New Team Member
        </div>
      )}

      <PersonalInfo
        viewMode={viewMode}
        editMode={editMode}
        userToEdit={userToEdit}
        // @ts-ignore
        values={state}
        errorValues={errorState}
        // @ts-ignore
        inputs={inputs}
        onChange={(key, value) => {
          if (!viewMode) {
            setState({ ...state, [key]: value });
          }
        }}
      />

      {!viewMode && (
        <div
          className="heading-small"
          style={{
            color: colors.caliber_secondary,
            marginTop: 30,
            marginBottom: 18,
          }}
        >
          Profile Image
        </div>
      )}
      {!viewMode && (
        <ProfileImage
          ownImageUrl={editMode && userToEdit?.profileIconMediaUrl?.url}
          error={errorState.profileIconMediaUrlId}
          onMediaUrlIdChange={(id) =>
            setState({ ...state, profileIconMediaUrlId: id })
          }
        />
      )}

      {!viewMode && userToEdit?.status === UserStatusType.Inactive && (
        <DeleteClientButton
          userToDelete={userToEdit}
          onClose={() => {
            resetSearch(userToEdit.type);
          }}
        />
      )}
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  createManager: (args: MutationCreateUserProfileArgs) => {
    dispatch(createManager(args));
  },
  createTrainer: (args: MutationCreateUserProfileArgs) => {
    dispatch(createTrainer(args));
  },
  editTrainer: (args: MutationUpdateUserProfileArgs) => {
    dispatch(editTrainer(args));
  },
  editManager: (args: MutationUpdateUserProfileArgs) => {
    dispatch(editManager(args));
  },
  resetState: () => {
    dispatch(resetAdminCreateManagerTrainerState());
  },
  resetMediaState: () => {
    dispatch(resetMediaState());
  },
  updateCoachProfileIcon: (args: MutationUpdateCoachProfileIconUrlArgs) => {
    dispatch(updateCoachProfileIcon(args));
  },
  resetSearch: (role: UserType) => {
    dispatch(resetSearch(role));
  },
});
const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  editMode: ownProps.editMode,
  closeForm: ownProps.closeForm,

  successManagerCreating: state.adminCreateUpdateManagerState.successToCreate,
  successManagerUpdating: state.adminCreateUpdateManagerState.successToUpdate,
  successTrainerCreating: state.adminCreateUpdateTrainerState.successToCreate,
  successTrainerUpdating: state.adminCreateUpdateTrainerState.successToUpdate,
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamMemberForm);
