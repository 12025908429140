import React from "react";
import Dropdown from "../../Dropdown";

export enum RecurrenceFrequencyType {
  MONTHLY = "MONTHLY",
  WEEKLY = "WEEKLY",
}
interface Props {
  frequencyType: RecurrenceFrequencyType;
  onSelect: (value: RecurrenceFrequencyType) => void;
}

const Frequency = (props: Props) => {
  const { frequencyType, onSelect } = props;

  const textStyle: React.CSSProperties = {
    fontSize: "16px",
    lineHeight: "16px",
    color: "#000000",
    height: 36,
    margin: "0px 12px",
  };
  return (
    <div
      className="d-flex"
      style={{
        margin: "12px 0px",
      }}
    >
      <div className="d-flex align-items-center medium-bold" style={textStyle}>
        Frequency
      </div>
      <div
        style={{
          width: 126,
        }}
      >
        <Dropdown
          height={36}
          onSelect={onSelect}
          value={frequencyType}
          items={[
            { value: RecurrenceFrequencyType.WEEKLY, text: "Weekly" },
            { value: RecurrenceFrequencyType.MONTHLY, text: "Monthly" },
          ]}
        />
      </div>
    </div>
  );
};

export default Frequency;
