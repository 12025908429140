export enum DashboardTypes {
  balance = "balance",
  score = "score",
}

export interface StrengthScoreInfo {
  startNumber: number;
  endNumber: number;
  startText: string;
  endText: string;
  markerColor: string;
  tagColor: string;
  areaColor: string;
  lineColor: string;
  tagText: string;
}

export interface StrengthBalanceInfo {
  startText: string;
  endText: string;
  startNumber: number;
  endNumber: number;
  tagColor: string;
  markerColor: string;
  tagText: string;
  areaColor: string;
  lineColor: string;
}

export const StrengthScoreInfos: StrengthScoreInfo[] = [
  {
    startNumber: 100,
    startText: "100",
    endNumber: 200,
    endText: "200",
    markerColor: "#8C316F",
    tagColor: "#8C316F",
    areaColor: "#AA428A",
    lineColor: "#722157",
    tagText: "Beginner",
  },
  {
    startNumber: 200,
    startText: "200",
    endNumber: 300,
    endText: "300",
    markerColor: "#69CD51",
    tagColor: "#69CD51",
    areaColor: "#78EC5D",
    lineColor: "#5CB347",
    tagText: "Intermediate",
  },
  {
    startNumber: 300,
    startText: "300",
    endNumber: 400,
    endText: "400",
    markerColor: "#3D89F8",
    tagColor: "#3D89F8",
    areaColor: "#4B94FF",
    lineColor: "#1E6FE7",
    tagText: "Advanced",
  },
  {
    startNumber: 400,
    startText: "400",
    endNumber: 500,
    endText: "500",
    markerColor: "#FF6914",
    tagColor: "#7427D5",
    areaColor: "#FBC53C",
    lineColor: "#FA5A00",
    tagText: "Expert",
  },
  {
    startNumber: 500,
    startText: "500",
    endNumber: 600,
    endText: "600",
    markerColor: "#030F6B",
    tagColor: "#030F6B",
    areaColor: "#021492",
    lineColor: "#000632",
    tagText: "Elite",
  },
];

export const StrengthBalanceInfos: StrengthBalanceInfo[] = [
  {
    startText: "0%",
    startNumber: 0,
    endText: "100%",
    endNumber: 0.2,
    tagColor: "#ed5553",
    markerColor: "#ed5553",
    tagText: "Very Poor",
    areaColor: "#AEB1BF",
    lineColor: "#272D5F",
  },
  {
    startText: "0%",
    startNumber: 0.2,
    endText: "100%",
    endNumber: 0.4,
    tagColor: "#f3983c",
    markerColor: "#f3983c",
    tagText: "Poor",
    areaColor: "#AEB1BF",
    lineColor: "#272D5F",
  },
  {
    startText: "0%",
    startNumber: 0.4,
    endText: "100%",
    endNumber: 0.6,
    tagColor: "#f3c834",
    markerColor: "#f3c834",
    tagText: "Fair",
    areaColor: "#AEB1BF",
    lineColor: "#272D5F",
  },
  {
    startText: "0%",
    startNumber: 0.6,
    endText: "100%",
    endNumber: 0.8,
    tagColor: "#6ccf93",
    markerColor: "#6ccf93",
    tagText: "Good",
    areaColor: "#AEB1BF",
    lineColor: "#272D5F",
  },
  {
    startText: "0%",
    startNumber: 0.8,
    endText: "100%",
    endNumber: 1.1,
    tagColor: "#1ead58",
    markerColor: "#1ead58",
    tagText: "Excellent",
    areaColor: "#AEB1BF",
    lineColor: "#272D5F",
  },
];
