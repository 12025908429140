export const AUTH_AUTHENTICATING = "AUTH_AUTHENTICATING";
export const AUTH_LOGGED_OUT = "AUTH_LOGGED_OUT";
export const AUTH_AUTHENTICATION_SUCCESS = "AUTH_AUTHENTICATION_SUCCESS";
export const AUTH_AUTHENTICATION_FAIL = "AUTH_AUTHENTICATION_FAIL";

export interface AuthAction {
  type: string;
  authToken?: string;
  authUser?: any;
}

export interface AuthState {
  isAuthStateLoaded: boolean;
  isAuthenticating: boolean;
  isAuthenticated: boolean;
  authToken?: string;
  authUser?: any;
}
