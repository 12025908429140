import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDrop } from "react-dnd";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import colors from "../../assets/colors";
import {
  closeSupersetEditorInPlan,
  hideExercisesInPlan,
  openExerciseLibraryTrainingPlan,
  openSupersetEditorInPlan,
  showExercisesInPlan,
} from "../../redux/actions/TrainingPrograms";
import { addGymStepToPlan } from "../../redux/actions/TrainingPrograms/add";
import { deleteGymStepInPlan } from "../../redux/actions/TrainingPrograms/delete";
import { reorderGymStepsInPlan } from "../../redux/actions/TrainingPrograms/reorder";
import { updateGymStepInPlan } from "../../redux/actions/TrainingPrograms/update";
import { getIsAdmin, getIsMasquerade, StoreState } from "../../redux/reducers";
import {
  selectClientWorkoutGroup,
  selectCurrentGymSteps,
  selectCurrentSuperset,
} from "../../redux/reducers/TrainingPrograms/selectors";
import {
  ClientGymStepTemplate,
  ClientWorkoutGroupTemplate,
  GymStep,
  MasterGymStep,
  MutationConvertGymStepToSupersetArgs,
  MutationMoveSupersetChildToWorkoutArgs,
  MutationUpdateClientGymStepTemplateArgs,
  MutationUpdateMasterGymStepArgs,
  MutationUpdateMasterGymStepForCustomTrainingPlanArgs,
  Superset,
} from "../../types/gqlTypes";
import ExerciseItem, { DraggableItem } from "../ExerciseItem";
import ExerciseSearchLibrary from "../ExerciseSearchLibrary";
import { ProgramType } from "../TrainingProgram";
import EmptyExerciseList from "../TrainingProgramWorkoutList/EmptyExerciseList";
import EditWorkoutHeader from "../TrainingProgramWorkoutList/EditWorkoutHeader";
import SimpleButton from "../SimpleButton";
import SupersetEditor from "./SupersetEditor";
import {
  convertGymStepToSuperset,
  moveSupersetChildToWorkout,
} from "../../redux/actions/TrainingPrograms/supersets";

interface OwnProps {}

interface Props extends OwnProps {
  updateGymStep: (
    args:
      | MutationUpdateClientGymStepTemplateArgs
      | MutationUpdateMasterGymStepArgs
      | MutationUpdateMasterGymStepForCustomTrainingPlanArgs
  ) => void;
  deleteGymStep: (args: { id: string }) => void;
  openExerciseLibrary: () => void;
  reorderGymSteps: (args: { id: string; ids: string[] }) => void;
  addGymStep: (args: { exerciseId: string; index: number }) => void;
  convertGymStepToSuperset: (
    args: MutationConvertGymStepToSupersetArgs,
    index: number
  ) => void;
  moveSupersetChildToWorkout: (
    args: MutationMoveSupersetChildToWorkoutArgs
  ) => void;
  showExerciseLibrary: boolean;
  showSupersetEditor: boolean;
  showWorkoutExerciseList: boolean;
  workoutGroup: ClientWorkoutGroupTemplate;
  gymSteps: (MasterGymStep | ClientGymStepTemplate)[];
  programType: ProgramType;
  isMasquerade: boolean;
  isAdmin: boolean;
  openSupersetEditor: (gymStep: GymStep) => void;
  closeSupersetEditor: () => void;
  selectedSuperset: Superset;
  closeWorkoutExerciseList: () => void;
}

const ExerciseList = (props: Props) => {
  const {
    updateGymStep,
    deleteGymStep,
    openExerciseLibrary,
    reorderGymSteps,
    addGymStep,
    convertGymStepToSuperset,
    moveSupersetChildToWorkout,
    showExerciseLibrary,
    showSupersetEditor,
    showWorkoutExerciseList,
    workoutGroup,
    gymSteps,
    programType,
    isMasquerade,
    isAdmin,
    openSupersetEditor,
    closeSupersetEditor,
    selectedSuperset,
    closeWorkoutExerciseList,
  } = props;
  const { id: clientId } = useParams();
  let canBeChanged = true;
  if (programType === ProgramType.Master) {
    // only admins with no Masquerade and in Program Library component
    canBeChanged = isAdmin && !isMasquerade && !clientId;
  }

  const [localItems, setLocalItems] = useState([] as string[]);
  const [selectedEditMode, setSelectedEditMode] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  const gymStepObject = useMemo(() => {
    const object: { [key: string]: GymStep } = {};
    gymSteps?.forEach((gymStep) => {
      object[gymStep.id] = gymStep;
    });
    return object;
  }, [gymSteps]);

  // Reusable function to determine if the ExerciseItem can be dropped here
  //   If the superset editor is open, don't let the user drag exercises from the library to the workout list,
  //   only to the superset editor
  const canDropExerciseIntoList = useCallback(
    (item: DraggableItem, monitor) => {
      if (
        (showExerciseLibrary && !showSupersetEditor) ||
        (showSupersetEditor && item.isSupersetChild) ||
        item.isWorkoutChild
      ) {
        return true;
      }
      return false;
    },
    [showExerciseLibrary, showSupersetEditor]
  );

  // Populates pre-drop UI and post-drop UI
  const moveCard = (id: string, atIndex: number) => {
    if (canDrop) {
      const { index } = findCard(id);
      const items = [...localItems];
      if (index > -1) {
        // switch order of two items
        const temp = items[index];
        items[index] = items[atIndex];
        items[atIndex] = temp;
        setLocalItems(items);
      } else {
        // add item
        items.splice(atIndex, 0, id);
        setLocalItems(items);
      }
    }
  };

  // Convenience function for looking up gymSteps
  const findCard = (id: string) => {
    return {
      index: localItems.indexOf(id),
    };
  };

  // Executes drop action to API
  const onDrop = useCallback(
    (item: DraggableItem, monitor) => {
      const isReorder = !!gymStepObject?.[item?.id];
      if (isReorder) {
        const gymStepIds = localItems.map(
          (exerciseId) => gymStepObject[exerciseId].id
        );
        reorderGymSteps({
          ids: gymStepIds,
          id: gymStepObject[item.id].id,
        });
      } else {
        const gymStepIds = localItems.map(
          (exerciseId) => gymStepObject?.[exerciseId]?.id || exerciseId
        );
        if (!gymStepIds.includes(item.id)) {
          gymStepIds.push(item.id);
        }
        // This could be a child of a superset
        if (item.isSupersetChild) {
          moveSupersetChildToWorkout({
            supersetChildGymStepId: item.id,
            position: gymStepIds.findIndex((id) => id === item.id),
          });
          if (selectedSuperset.gymSteps.length === 1) {
            closeSupersetEditor();
          }
        } else {
          addGymStep({
            exerciseId: item.id,
            index: gymStepIds.findIndex((id) => id === item.id),
          });
        }
      }
    },
    [localItems, showExerciseLibrary, showSupersetEditor]
  );

  // Configures dropping within the ExerciseList including:
  //   - rules for what can be dropped
  //   - call to API when drop happens
  const [{ isOver, didDrop, canDrop }, drop] = useDrop({
    accept: "exercise",
    canDrop: canDropExerciseIntoList,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      didDrop: monitor.didDrop(),
    }),
  });

  useEffect(() => {
    setSelectedEditMode(null);
    if (!isOver && !didDrop) {
      setLocalItems(gymSteps?.map((gymStep) => gymStep.id));
    }
  }, [gymSteps, isOver]);

  // Only responsible for styling drop zone but no actual functionality
  const dropboxStyle = useMemo(() => {
    const style: React.CSSProperties = {
      position: "relative",
      flex: "1 1 0",
      backgroundColor: "transparent",
      border: "none",
      padding: "0px 6px",
    };
    /* Disallow styling drop zone when dragging from the exerciseSearchLibrary and
        dropping into WorkoutItem->ExerciseList if editing Superset.  */
    if (isDragging && canDrop) {
      style.backgroundColor = "#9da4d6";
      style.border = "2px dotted #5b64a0";
    }
    return style;
  }, [isDragging, canDrop]);

  return (
    <div
      className="d-flex"
      style={{
        flex: 1,
      }}
    >
      {showWorkoutExerciseList && (
        <div
          className="d-flex flex-column"
          style={{
            flex: "1 1 50%",
            marginRight: 12,
          }}
        >
          <EditWorkoutHeader />
          {programType === ProgramType.Client &&
            workoutGroup.createdByTrainer === false && (
              <div
                className="alert alert-warning py-3 px-2 text-center"
                style={{ fontSize: 14, borderRadius: 12 }}
              >
                ⚠️ <strong>Warning:</strong> This workout plan was created by
                the client and can be edited or deleted by the client.
              </div>
            )}
          <div
            className="d-flex justify-content-between"
            style={{
              padding: "8px 12px",
            }}
          >
            <div
              style={{
                lineHeight: "32px",
                color: colors.caliber_gray_border,
              }}
              className="paragraph-normal align-self-center"
            >
              Exercises
            </div>
            {canBeChanged && !showSupersetEditor && !showExerciseLibrary && (
              <div className="d-flex flex-column align-items-center justify-content-center">
                <SimpleButton
                  className="pointer nofocus circular-medium-font"
                  style={{
                    backgroundColor: colors.caliber_gray_11,
                    color: colors.caliber_secondary,
                    padding: "1px 12px",
                    borderRadius: 8,
                    fontSize: "14px",
                    lineHeight: "24px",
                  }}
                  onClick={openExerciseLibrary}
                >
                  Add exercise
                </SimpleButton>
              </div>
            )}
          </div>

          {!showExerciseLibrary && localItems?.length === 0 && (
            <EmptyExerciseList onAddNewExercise={openExerciseLibrary} />
          )}

          {/* Allows drop zone to be style and for objects to be dropped within the zone */}
          {(showExerciseLibrary || localItems?.length > 0) && (
            <div
              className="d-flex flex-column overflow-auto"
              style={dropboxStyle}
            >
              {/* Defines drop zone area */}
              <div
                ref={canBeChanged ? drop : null}
                style={{
                  flex: "1 1 0",
                }}
              >
                {localItems?.map((gymStepId, index) => {
                  const isVisible =
                    typeof selectedEditMode !== "number" ||
                    index === selectedEditMode;
                  const gymStep = gymStepObject?.[gymStepId];
                  const exercise = gymStep?.exercise;
                  return (
                    isVisible && (
                      <ExerciseItem
                        key={gymStepId}
                        id={gymStepId}
                        index={index}
                        showTarget
                        showVideoOnHover
                        onToggleDragging={(newDrag) => {
                          setIsDragging(newDrag); // Activates drop zone when reordering
                        }}
                        gymStep={gymStep}
                        showMenu={canBeChanged}
                        exercise={exercise}
                        selectedEditMode={selectedEditMode}
                        transparent={!gymStep}
                        draggable={canBeChanged}
                        onStartEdit={() => setSelectedEditMode(index)}
                        onSaveEdit={(args) => updateGymStep(args)}
                        onDelete={() => deleteGymStep({ id: gymStep?.id })}
                        onCancelEdit={() => setSelectedEditMode(null)}
                        moveCard={moveCard}
                        findCard={findCard}
                        onStartEditSuperset={() => openSupersetEditor(gymStep)}
                        onConvertGymStepToSuperset={() => {
                          convertGymStepToSuperset(
                            { gymStepId: gymStep?.id },
                            index
                          );
                          if (showExercisesInPlan && showExerciseLibrary) {
                            closeWorkoutExerciseList();
                          }
                        }}
                      />
                    )
                  );
                })}
              </div>
            </div>
          )}
        </div>
      )}

      {showSupersetEditor && (
        <div
          className="d-flex flex-column"
          style={{
            flex: "1 1 50%",
          }}
        >
          <SupersetEditor
            onToggleDragging={(newDrag) => setIsDragging(newDrag)}
            highlightDropZone={isDragging}
          />
        </div>
      )}

      {showExerciseLibrary && (
        <div
          className="d-flex flex-column"
          style={{
            flex: "1 1 50%",
          }}
        >
          <ExerciseSearchLibrary
            showVideoOnHover
            // @ts-ignore
            excludeGymSteps={gymSteps}
            // Activates drop zone when adding exercise
            onToggleDragging={(newDrag) => setIsDragging(newDrag)}
          />
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateGymStep: (
    args:
      | MutationUpdateClientGymStepTemplateArgs
      | MutationUpdateMasterGymStepArgs
      | MutationUpdateMasterGymStepForCustomTrainingPlanArgs
  ) => {
    dispatch(updateGymStepInPlan(args));
  },
  deleteGymStep: (args: { id: string }) => {
    dispatch(deleteGymStepInPlan(args));
  },
  openExerciseLibrary: () => {
    dispatch(openExerciseLibraryTrainingPlan());
  },
  reorderGymSteps: (args: { id: string; ids: string[] }) => {
    dispatch(reorderGymStepsInPlan(args));
  },
  addGymStep: (args: { exerciseId: string; index: number }) => {
    dispatch(addGymStepToPlan(args));
  },
  openSupersetEditor: (gymStep: GymStep) => {
    dispatch(openSupersetEditorInPlan(gymStep));
  },
  convertGymStepToSuperset: (
    args: MutationConvertGymStepToSupersetArgs,
    index: number
  ) => {
    dispatch(convertGymStepToSuperset(args, index));
  },
  moveSupersetChildToWorkout: (
    args: MutationMoveSupersetChildToWorkoutArgs
  ) => {
    dispatch(moveSupersetChildToWorkout(args));
  },
  closeSupersetEditor: () => {
    dispatch(closeSupersetEditorInPlan());
  },
  closeWorkoutExerciseList: () => {
    dispatch(hideExercisesInPlan());
  },
});

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  gymSteps: selectCurrentGymSteps(state),
  showExerciseLibrary: state.trainingProgramsState.showExerciseLibrary,
  showSupersetEditor: state.trainingProgramsState.showSupersetEditor,
  showWorkoutExerciseList: state.trainingProgramsState.showWorkoutExerciseList,
  programType: state.trainingProgramsState.programType,
  isMasquerade: getIsMasquerade(state),
  isAdmin: getIsAdmin(state),
  selectedSuperset: selectCurrentSuperset(state),
  workoutGroup: selectClientWorkoutGroup(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExerciseList);
