import {
  ComponentLocationType,
  ProgramType,
} from "../../../components/TrainingProgram";
import {
  CustomTrainingPlanType,
  QuerySearchMasterTrainingPlansArgs,
  Superset,
} from "../../../types/gqlTypes";
import {
  BROWSE_MASTER_TRAINING_PLANS,
  CHANGE_PROGRAM_TYPE_TRAINING_PLAN,
  CHANGE_CUSTOM_TRAINING_PLAN_TYPE,
  CHANGE_TRAINING_PLAN_COMPONENT_LOCATION,
  ON_CHANGE_FILTER_STATE_TRAINING_PLAN,
  ON_CLICK_NEW_OR_EDIT_MASTER_PROGRAM,
  ON_CLOSE_EDITING_MASTER_PLAN,
  ON_CLOSE_NEW_OR_EDIT_MASTER_PROGRAM,
  ON_STOP_CREATE_CUSTOM_PLAN_IN_PROGRAM_LIBRARY,
  OPEN_EXERCISE_LIBRARY_TRAINING_PLAN,
  SELECT_CLIENT_TRAINING_PLAN,
  SELECT_TRAINING_PLAN,
  SELECT_WORKOUT_GROUP_TRAINING_PLAN,
  SELECT_WORKOUT_TRAINING_PLAN,
  START_EDIT_WORKOUT_TRAINING_PLAN,
  STOP_EDIT_WORKOUT_TRAINING_PLAN,
  TOGGLE_EDIT_WORKOUT_GROUP,
  TOGGLE_EDIT_TRAINING_PLAN,
  TOGGLE_FILTER_TRAINING_PLAN,
  OPEN_SUPERSET_EDITOR,
  OPEN_WORKOUTS,
  TrainingProgramsState,
  OPEN_WORKOUT_GROUPS,
  CLOSE_EXERCISE_SEARCH_LIBRARY,
  CLOSE_EXERCISE_LIST,
  OPEN_EXERCISE_LIST,
  CLOSE_SUPERSET_EDITOR,
} from "./types";

export const openExerciseLibraryTrainingPlan = () => async (dispatch) => {
  dispatch({ type: OPEN_EXERCISE_LIBRARY_TRAINING_PLAN });
};
export const toggleFilterTrainingPlan = () => async (dispatch) => {
  dispatch({ type: TOGGLE_FILTER_TRAINING_PLAN });
};
export const onChangeFilterStateTrainingPlan =
  (filterState: QuerySearchMasterTrainingPlansArgs) => async (dispatch) => {
    dispatch({ type: ON_CHANGE_FILTER_STATE_TRAINING_PLAN, filterState });
  };
export const browseMasterTrainingPlans = () => async (dispatch) => {
  dispatch({ type: BROWSE_MASTER_TRAINING_PLANS });
};
export const selectTrainingPlan = (index: number) => async (dispatch) => {
  dispatch({ type: SELECT_TRAINING_PLAN, index });
};
export const selectClientTrainingPlan = (index: number) => async (dispatch) => {
  dispatch({ type: SELECT_CLIENT_TRAINING_PLAN, index });
};
export const selectWorkoutGroupTrainingPlan =
  (index: number) => async (dispatch) => {
    dispatch({ type: SELECT_WORKOUT_GROUP_TRAINING_PLAN, index });
  };
export const selectWorkoutTrainingPlan =
  (index: number) => async (dispatch) => {
    dispatch({ type: SELECT_WORKOUT_TRAINING_PLAN, index });
  };
export const startEditWorkoutTrainingPlan =
  (index?: number) => async (dispatch) => {
    dispatch({ type: START_EDIT_WORKOUT_TRAINING_PLAN, index });
  };
export const stopEditWorkoutTrainingPlan =
  (index?: number) => async (dispatch) => {
    dispatch({ type: STOP_EDIT_WORKOUT_TRAINING_PLAN, index });
  };
export const toggleEditTrainingPlan =
  (index: number, programType?: ProgramType) => async (dispatch) => {
    dispatch({ type: TOGGLE_EDIT_TRAINING_PLAN, index, programType });
  };
export const toggleEditWorkoutGroup = (index: number) => async (dispatch) => {
  dispatch({ type: TOGGLE_EDIT_WORKOUT_GROUP, index });
};
export const changeProgramTypeTrainingPlan =
  (programType: ProgramType) => async (dispatch) => {
    dispatch({ type: CHANGE_PROGRAM_TYPE_TRAINING_PLAN, programType });
  };
export const changeCustomTrainingPlanType =
  (customTrainingPlanType: CustomTrainingPlanType) => async (dispatch) => {
    dispatch({
      type: CHANGE_CUSTOM_TRAINING_PLAN_TYPE,
      customTrainingPlanType,
    });
  };
export const changeTrainingPlanComponentLocation =
  (componentLocation: ComponentLocationType) => async (dispatch) => {
    dispatch({
      type: CHANGE_TRAINING_PLAN_COMPONENT_LOCATION,
      componentLocation,
    });
  };
export const onClickNewOrEditMasterProgram =
  (index?: number) => async (dispatch) => {
    dispatch({ type: ON_CLICK_NEW_OR_EDIT_MASTER_PROGRAM, index });
  };
export const onCloseNewOrEditMasterProgram = () => async (dispatch) => {
  dispatch({ type: ON_CLOSE_NEW_OR_EDIT_MASTER_PROGRAM });
};
export const onCloseEditingMasterPlan = () => async (dispatch) => {
  dispatch({ type: ON_CLOSE_EDITING_MASTER_PLAN });
};
export const onStopCreateCustomPlanInProgramLibrary =
  () => async (dispatch) => {
    dispatch({ type: ON_STOP_CREATE_CUSTOM_PLAN_IN_PROGRAM_LIBRARY });
  };
export const openSupersetEditorInPlan =
  (superset: Superset) => async (dispatch) => {
    dispatch({ type: OPEN_SUPERSET_EDITOR, superset });
  };
export const closeSupersetEditorInPlan = () => async (dispatch) => {
  dispatch({ type: CLOSE_SUPERSET_EDITOR });
};
export const openWorkoutsTrainingPlan = () => async (dispatch) => {
  dispatch({ type: OPEN_WORKOUTS });
};
export const closeRightMostPanel = () => async (dispatch, getState) => {
  const state: TrainingProgramsState = getState().trainingProgramsState;

  // Workout groups / Workout / Superset / Exercise Library
  if (state.showSupersetEditor && state.showExerciseLibrary) {
    dispatch({ type: OPEN_EXERCISE_LIST });
    return dispatch({ type: CLOSE_EXERCISE_SEARCH_LIBRARY });
  }

  // Workout groups / Workout / Superset
  if (state.showSupersetEditor && state.showExercises) {
    return dispatch({ type: OPEN_WORKOUTS });
  }

  // Workout groups / Workout / Exercise Library
  if (state.showExercises && state.showExerciseLibrary) {
    return dispatch({ type: OPEN_WORKOUTS });
  }

  // Workout groups / Workout
  if (state.showWorkouts) {
    return dispatch({ type: OPEN_WORKOUT_GROUPS });
  }

  return null;
};
export const showExercisesInPlan = () => async (dispatch) => {
  return dispatch({ type: OPEN_EXERCISE_LIST });
};
export const hideExercisesInPlan = () => async (dispatch) => {
  return dispatch({ type: CLOSE_EXERCISE_LIST });
};
