import React, { useEffect, useMemo, useRef } from "react";
import { Navbar, NavbarBrand } from "react-bootstrap";
import { connect } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory, useLocation } from "react-router-dom";
import colors from "../../assets/colors";
import ArrowDownIcon from "../../assets/images/ArrowDownIcon.svg";
import ArrowUpIcon from "../../assets/images/ArrowUpIcon.svg";
import CaliberLogo from "../../assets/images/CaliberLogo.svg";
import CaliberLogoText from "../../assets/images/CaliberLogoText.svg";
import CaliberLogoTextLight from "../../assets/images/CaliberLogoTextLight.svg";
import { MasqueradeState } from "../../redux/actions/Masquerade/types";
import { StoreState } from "../../redux/reducers";
import { User, UserType } from "../../types/gqlTypes";
import Dropdown from "../Dropdown";
import MasqueradeComponent from "../MasqueradeComponent";
import ProfilePhoto from "../ProfilePhoto";

export const HEADER_HEIGHT = 68;

export interface OwnProps {
  userInfo: User;
  logout: () => void;
}

interface Props extends OwnProps {
  masqueradeState: MasqueradeState;
}

const Header = (props: Props) => {
  const { userInfo, logout, masqueradeState } = props;

  const iconOnly = useMediaQuery({ maxWidth: 768 });
  const isManagerOrAdmin =
    userInfo?.type === UserType.Manager || userInfo?.type === UserType.Admin;

  const firstUpdate = useRef(true);
  const isMasquerading = masqueradeState.masqueradeTrainer != null;
  const history = useHistory();
  const location = useLocation();
  const isClientTabNew = location.pathname.includes("/clientsNEW");
  const isMasqueradeAvailable = isManagerOrAdmin && !isClientTabNew;

  useEffect(() => {
    if (
      !location.pathname.includes("/messages") &&
      (!firstUpdate.current || isMasquerading)
    ) {
      if (firstUpdate.current) {
        firstUpdate.current = false;
      }
      history?.push("/");
    }
  }, [isMasquerading]);

  const dropdownOptions = useMemo(() => {
    const options = [];
    const isTrainer = userInfo?.type === UserType.Trainer;
    const isMasquerading = masqueradeState?.masqueradeTrainer != null;
    if (isTrainer || isMasquerading) {
      options.push({ text: "Profile", value: "profile" });
      options.push({ text: "Settings", value: "settings" });
    }
    options.push({ text: "Log Out", value: "logout" });
    return options;
  }, [userInfo, masqueradeState]);

  return (
    <Navbar
      className="fixed-top d-flex justify-content-between"
      style={{
        padding: "13px 0px 13px 26px",
        backgroundColor: isMasquerading
          ? colors.caliber_blue
          : colors.caliber_white,
      }}
    >
      <div className="d-flex">
        <NavbarBrand>
          {iconOnly ? (
            <img src={CaliberLogo} alt="logo" />
          ) : (
            <img
              src={isMasquerading ? CaliberLogoTextLight : CaliberLogoText}
              alt="logo"
            />
          )}
        </NavbarBrand>
        {isMasqueradeAvailable && <MasqueradeComponent />}
      </div>
      <div
        style={{
          minWidth: 250,
        }}
      >
        <Dropdown
          onSelect={(value) => {
            if (value === "logout") {
              logout();
            } else if (value === "settings") {
              history?.push("/settings");
            } else if (value === "profile") {
              history?.push("/profile");
            }
          }}
          bgColor={colors.caliber_white}
          borderColor={colors.caliber_white}
          textColor={colors.caliber_secondary}
          items={dropdownOptions}
          arrowDownIcon={ArrowDownIcon}
          arrowUpIcon={ArrowUpIcon}
          customDropdownToggle={
            <div className="d-flex justify-content-center align-items-center">
              <ProfilePhoto
                imageUrl={userInfo?.profileIconMediaUrl?.url}
                size={40}
                bgColor={
                  isMasquerading ? colors.caliber_blue : colors.caliber_white
                }
              />
              <div
                className="d-flex justify-content-center align-items-center medium-bold"
                style={{
                  marginLeft: 12,
                  marginRight: 30,
                  color: isMasquerading ? colors.caliber_white : colors.black,
                }}
              >
                {userInfo?.firstName} {userInfo?.lastName}
              </div>
            </div>
          }
        />
      </div>
      <span
        style={{
          backgroundColor: colors.wire_line_grey,
          height: 2,
          position: "absolute",
          width: "100%",
          bottom: 0,
          left: 0,
        }}
      />
    </Navbar>
  );
};

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  masqueradeState: state.masqueradeState,
  logout: ownProps.logout,
  userInfo: ownProps.userInfo,
});

export default connect(mapStateToProps)(Header);
