import _ from "lodash";
import { initialListsState } from ".";
import { ProgramType } from "../../../components/TrainingProgram";
import {
  ADD_NEW_CLIENT_WORKOUT_GROUP_SUCCESS,
  ASSIGN_TRAINING_PLAN_SUCCESS,
  CLONE_CUSTOM_TRAINING_PLAN_FAIL,
  CLONE_CUSTOM_TRAINING_PLAN_LOADING,
  CLONE_CUSTOM_TRAINING_PLAN_SUCCESS,
  CLONE_MASTER_TRAINING_PLAN_FAIL,
  CLONE_MASTER_TRAINING_PLAN_LOADING,
  CLONE_MASTER_TRAINING_PLAN_SUCCESS,
  COPY_MASTER_WORKOUT_GROUP_TO_CLIENT_SUCCESS,
  CREATE_CUSTOM_PLAN_IN_PROGRAM_LIBRARY,
  CREATE_LIBRARY_PLAN_IN_PROGRAM_LIBRARY,
  CREATE_TRAINING_PLAN_FAIL,
  CREATE_TRAINING_PLAN_LOADING,
  CREATE_TRAINING_PLAN_SUCCESS,
  TrainingProgramsAction,
  TrainingProgramsState,
} from "../../actions/TrainingPrograms/types";
import {
  ClientTrainingPlan,
  CustomTrainingPlan,
} from "../../../types/gqlTypes";

const createAssignReducer = (
  state: TrainingProgramsState,
  action: TrainingProgramsAction
) => {
  switch (action.type) {
    case ASSIGN_TRAINING_PLAN_SUCCESS: {
      return {
        ...state,
        clientPlans: [action.plan, ...state.clientPlans],
        clientPlanIndex: 0,
        showPlans: false,
        showWorkouts: true,
        programType: ProgramType.Client,
      } as TrainingProgramsState;
    }
    case COPY_MASTER_WORKOUT_GROUP_TO_CLIENT_SUCCESS: {
      const existingPlanIndex = state.clientPlanIndex;
      const plans = [...state.clientPlans];
      plans[existingPlanIndex] = action.plan as ClientTrainingPlan;
      return {
        ...state,
        planIndex: null,
        workoutGroupIndex: plans[existingPlanIndex].workoutGroups?.length - 1,
        clientPlans: [...plans],
        cachedPlans: {
          ...state.cachedPlans,
          [action.plan.id]: action.plan,
        },
        showPlans: false,
        showWorkouts: true,
        programType: ProgramType.Client,
      } as TrainingProgramsState;
    }
    case ADD_NEW_CLIENT_WORKOUT_GROUP_SUCCESS: {
      const existingPlanIndex = state.clientPlans.findIndex((plan) => {
        return plan.id === action.plan.id;
      });
      const plans = [...state.clientPlans];
      plans[existingPlanIndex] = action.plan as ClientTrainingPlan;
      return {
        ...state,
        clientPlans: [...plans],
        cachedPlans: {
          ...state.cachedPlans,
          [action.plan.id]: action.plan,
        },
        showPlans: false,
        showWorkouts: true,
        programType: ProgramType.Client,
      } as TrainingProgramsState;
    }
    case CLONE_MASTER_TRAINING_PLAN_LOADING:
    case CLONE_CUSTOM_TRAINING_PLAN_LOADING:
      return {
        ...state,
        isSaving: true,
      };
    case CLONE_MASTER_TRAINING_PLAN_FAIL:
    case CLONE_CUSTOM_TRAINING_PLAN_FAIL:
      return {
        ...state,
        isSaving: false,
      };
    case CLONE_MASTER_TRAINING_PLAN_SUCCESS: {
      const content = [...state.masterPlansContent.content];
      const indexOfCloned = content.findIndex(({ id }) => id === action.id);
      return {
        ...state,
        isSaving: false,
        planIndex: indexOfCloned + 1,
        showWorkouts: true,
        showFilter: false,
        masterPlansContent: {
          ...state.masterPlansContent,
          content: [
            ...content.slice(0, indexOfCloned),
            action.plan,
            ...content.slice(indexOfCloned),
          ],
        },
      };
    }
    case CLONE_CUSTOM_TRAINING_PLAN_SUCCESS: {
      const content = state.customPlansContent.content;
      const indexOfCloned = content.findIndex(
        (element) => element.id === action.id
      );
      content.splice(indexOfCloned + 1, 0, action.plan as CustomTrainingPlan);
      return {
        ...state,
        isSaving: false,
        planIndex: indexOfCloned + 1,
        customPlansContent: {
          ...state.customPlansContent,
          content: content,
        },
      };
    }
    case CREATE_TRAINING_PLAN_LOADING:
      return {
        ...state,
        isSaving: true,
      };
    case CREATE_TRAINING_PLAN_SUCCESS: {
      if (action.programType === ProgramType.Master) {
        return {
          ...state,
          ...initialListsState,
          showPlans: true,
          showWorkouts: true,
          showNewOrEditMasterProgramSection: false,
          editingMasterProgram: null,
          planIndex: 0,
          newlyCreatedMasterPlan: action.plan,
        } as TrainingProgramsState;
      }
      if (action.programType === ProgramType.Custom) {
        return {
          ...state,
          newlyCreatedCustomPlan: action.plan,
          isCopying: false,
          successToCreateCustomPlan: true,
          failedToCreateCustomPlan: false,
        };
      }
      return state;
    }
    case CREATE_CUSTOM_PLAN_IN_PROGRAM_LIBRARY: {
      const newContent = [...(state?.customPlansContent?.content || [])];
      return {
        ...state,
        showCreateNewPlans: false,
        successToCreateCustomPlan: null,
        failedToCreateCustomPlan: null,
        newlyCreatedCustomPlan: null,
        isCopying: null,
        planIndex: 0,
        customPlansContent: {
          ...state.customPlansContent,
          content: _.uniqBy([action.plan, ...newContent], (item) => item.id),
        },
      };
    }
    case CREATE_LIBRARY_PLAN_IN_PROGRAM_LIBRARY: {
      const newContent = [...(state?.libraryPlansContent?.content || [])];
      return {
        ...state,
        showCreateNewPlans: false,
        successToCreateCustomPlan: null,
        failedToCreateCustomPlan: null,
        newlyCreatedCustomPlan: null,
        isCopying: null,
        planIndex: 0,
        libraryPlansContent: {
          ...state.libraryPlansContent,
          content: _.uniqBy([action.plan, ...newContent], (item) => item.id),
        },
      };
    }
    case CREATE_TRAINING_PLAN_FAIL:
      if (state.programType === ProgramType.Custom) {
        return {
          ...state,
          isSaving: false,
          failedToCreateCustomPlan: true,
        };
      }
      return {
        ...state,
        isSaving: false,
      };
    default:
      return state;
  }
};
export default createAssignReducer;
