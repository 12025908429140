import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import colors from "../../assets/colors";
import CloseIcon from "../Icons/CloseIcons";
import Loader from "../Loader";
import Modal from "../Modal";
import SimpleButton from "../SimpleButton";

interface Props {
  imgUrl: string;
  isUploading: boolean;
  onCancel: () => void;
  onCrop: (blob: any) => void;
}

const ImageCropper = (props: Props) => {
  const { imgUrl, isUploading, onCancel, onCrop } = props;

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ width: 100, aspect: 1 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [canBeCropped, setCanBeCropped] = useState(false);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  const onSuccess = async () => {
    if (previewCanvasRef?.current?.toBlob) {
      previewCanvasRef?.current?.toBlob((blob) => {
        onCrop(blob);
      });
    }
  };

  useEffect(() => {
    const newCanBeCropped =
      previewCanvasRef?.current?.toBlob &&
      previewCanvasRef?.current?.width > 0 &&
      previewCanvasRef?.current?.height > 0 &&
      !isUploading;
    if (
      (newCanBeCropped && !canBeCropped) ||
      (!newCanBeCropped && canBeCropped)
    ) {
      setCanBeCropped(newCanBeCropped);
    }
  });

  return (
    <Modal onClose={() => {}}>
      <canvas
        ref={previewCanvasRef}
        style={{
          display: "none",
          width: Math.round(completedCrop?.width ?? 0),
          height: Math.round(completedCrop?.height ?? 0),
        }}
      />

      <div
        className="d-flex flex-column"
        style={{
          padding: 32,
        }}
      >
        <div
          className="d-flex justify-content-between medium-bold"
          style={{
            marginBottom: 32,
          }}
        >
          <div
            style={{
              fontSize: "16px",
              lineHeight: "20px",
              color: colors.caliber_secondary,
            }}
          >
            Crop image
          </div>
          <SimpleButton onClick={onCancel}>
            <CloseIcon width={20} height={20} />
          </SimpleButton>
        </div>

        {isUploading && (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              width: 416,
              height: 416,
            }}
          >
            <Loader />
          </div>
        )}

        {!isUploading && (
          <ReactCrop
            crossorigin="Access-Control-Allow-Origin: *"
            src={imgUrl}
            imageStyle={{
              width: 416,
            }}
            onImageLoaded={onLoad}
            crop={crop}
            onChange={(c) => {
              setCrop(c);
            }}
            onComplete={(c) => {
              setCompletedCrop(c);
            }}
          />
        )}

        <div className="d-flex justify-content-center">
          <SimpleButton
            className="d-flex justify-content-center align-items-center medium-bold"
            onClick={onCancel}
            style={{
              fontSize: "12px",
              lineHeight: "16px",
              color: colors.caliber_white,
              width: 134,
              height: 32,
              marginTop: 32,
              borderRadius: 4,
              backgroundColor: colors.caliber_gray_text,
            }}
          >
            Cancel
          </SimpleButton>
          <SimpleButton
            className="d-flex justify-content-center align-items-center medium-bold"
            onClick={onSuccess}
            disabled={!canBeCropped}
            style={{
              fontSize: "12px",
              lineHeight: "16px",
              color: colors.caliber_white,
              width: 134,
              height: 32,
              marginTop: 32,
              marginLeft: 16,
              borderRadius: 4,
              backgroundColor: canBeCropped
                ? colors.caliber_green_200
                : colors.caliber_gray_text,
            }}
          >
            Upload image
          </SimpleButton>
        </div>
      </div>
    </Modal>
  );
};

export default ImageCropper;
