import React from "react";
import ClientsActiveIcon from "../assets/images/ClientsActiveIcon.svg";
import ClientsInactiveIcon from "../assets/images/ClientsInactiveIcon.svg";
import TeamActiveIcon from "../assets/images/TeamActiveIcon.svg";
import TeamInactiveIcon from "../assets/images/TeamInactiveIcon.svg";
import DebugActiveIcon from "../assets/images/DebugActiveIcon.svg";
import DebugInactiveIcon from "../assets/images/DebugInactiveIcon.svg";
import ExerciseLibActiveIcon from "../assets/images/ExerciseLibActiveIcon.svg";
import ExerciseLibInactiveIcon from "../assets/images/ExerciseLibInactiveIcon.svg";
import MessagesActiveIcon from "../assets/images/MessagesActiveIcon.svg";
import MessagesInactiveIcon from "../assets/images/MessagesInactiveIcon.svg";
import ProgramLibActiveIcon from "../assets/images/ProgramLibActiveIcon.svg";
import ProgramLibInactiveIcon from "../assets/images/ProgramLibInactiveIcon.svg";
import SystemSettingsActiveIcon from "../assets/images/SystemSettingsActiveIcon.svg";
import SystemSettingsInactiveIcon from "../assets/images/SystemSettingsInactiveIcon.svg";
import DebugMasqueradeTrainer from "../components/DebugMasqueradeTrainer";
import AdminDebugScreen from "../screens/AdminDebugScreen";
import AdminExerciseLibraryScreen from "../screens/AdminExerciseLibraryScreen";
import AdminProgramLibraryScreen from "../screens/AdminProgramLibraryScreen";
import AdminTeamScreen from "../screens/AdminTeamScreen";
import TrainerClientCalendarTab from "../screens/TrainerClientCalendarTab";
import TrainerClientDashboardTab from "../screens/TrainerClientDashboardTab";
import TrainerClientHabitTab from "../screens/TrainerClientHabitTab";
import TrainerClientNutritionProgramTab from "../screens/TrainerClientNutritionProgramTab";
import TrainerClientProfileTab from "../screens/TrainerClientProfileTab";
import TrainerClientProgressTab from "../screens/TrainerClientProgressTab";
import TrainerClientsScreen from "../screens/TrainerClientsScreen";
import TrainerClientTrainingProgramTab from "../screens/TrainerClientTrainingProgramTab";
import TrainerMessagesScreen from "../screens/TrainerMessagesScreen";
import TasksActiveIcon from "../assets/images/TasksActiveIcon.svg";
import TasksInactiveIcon from "../assets/images/TasksInactiveIcon.svg";
import TasksScreen from "../screens/TasksScreen";
import SystemSettingsScreen from "../screens/SystemSettingsScreen";
import Messenger from "../features/messenger/components/messenger";

export interface RoutesInfo {
  path: string | string[];
  exact: boolean;
  title?: string;
  activeIcon?: any;
  inactiveIcon?: any;
  main: () => {};
}

/**
 * To set the default screen after login for a user type:
 *
 * Add '/' to the path for the nav item
 */

export const trainerPrimaryRoutes: Array<RoutesInfo> = [
  {
    path: ["/", "/messages", "/messages/:id"],
    exact: true,
    title: "Messages",
    activeIcon: MessagesActiveIcon,
    inactiveIcon: MessagesInactiveIcon,
    main: () => <TrainerMessagesScreen />,
  },
  /*
  {
    path: ["/messenger", "/messenger/:id"],
    exact: true,
    title: "Messenger",
    activeIcon: MessagesActiveIcon,
    inactiveIcon: MessagesInactiveIcon,
    main: () => <Messenger />,
  },
  */
  {
    path: "/tasks",
    exact: true,
    title: "Tasks",
    activeIcon: TasksActiveIcon,
    inactiveIcon: TasksInactiveIcon,
    main: () => <TasksScreen />,
  },
  {
    path: "/clients",
    exact: false,
    title: "Clients",
    activeIcon: ClientsActiveIcon,
    inactiveIcon: ClientsInactiveIcon,
    main: () => <TrainerClientsScreen />,
  },
  {
    path: [
      "/programLibrary/",
      "/programLibrary/:trainingPlanId",
      "/myworkoutgroups",
      "/proworkoutgroups",
      "/workoutlibrary",
    ],
    exact: true,
    title: "Program Library",
    activeIcon: ProgramLibActiveIcon,
    inactiveIcon: ProgramLibInactiveIcon,
    // @ts-ignore
    main: () => <AdminProgramLibraryScreen viewOnly />,
  },
  {
    path: "/exerciselibrary",
    exact: true,
    title: "Exercise Library",
    activeIcon: ExerciseLibActiveIcon,
    inactiveIcon: ExerciseLibInactiveIcon,
    main: () => <AdminExerciseLibraryScreen viewOnly />,
  },
];

export const trainerClientRoutes: Array<RoutesInfo> = [
  {
    path: "/clients/:id/dashboard",
    exact: false,
    title: "Dashboard",
    main: () => <TrainerClientDashboardTab />,
  },
  {
    path: "/clients/:id/profile",
    exact: false,
    title: "Profile",
    main: () => <TrainerClientProfileTab />,
  },
  {
    path: "/clients/:id/calendar",
    exact: false,
    title: "Calendar",
    main: () => <TrainerClientCalendarTab />,
  },
  {
    path: "/clients/:id/trainingProgram",
    exact: false,
    title: "Training Program",
    main: () => <TrainerClientTrainingProgramTab />,
  },
  {
    path: "/clients/:id/nutritionProgram",
    exact: false,
    title: "Nutrition Program",
    main: () => <TrainerClientNutritionProgramTab />,
  },
  {
    path: "/clients/:id/progress",
    exact: false,
    title: "Progress",
    main: () => <TrainerClientProgressTab />,
  },
  {
    path: "/clients/:id/habits",
    exact: false,
    title: "Habits",
    main: () => <TrainerClientHabitTab />,
  },
  {
    path: "/clients/:id/debugTab",
    exact: false,
    title: "Debug",
    main: () => <DebugMasqueradeTrainer />,
  },
];

export const adminPrimaryRoutes: Array<RoutesInfo> = [
  {
    path: ["/clients", "/"],
    exact: true,
    title: "Members",
    activeIcon: ClientsActiveIcon,
    inactiveIcon: ClientsInactiveIcon,
    main: () => <TrainerClientsScreen />,
  },
  {
    path: "/tasks",
    exact: true,
    title: "Tasks",
    activeIcon: TasksActiveIcon,
    inactiveIcon: TasksInactiveIcon,
    main: () => <TasksScreen />,
  },
  {
    path: "/team",
    exact: true,
    title: "Team",
    activeIcon: TeamActiveIcon,
    inactiveIcon: TeamInactiveIcon,
    main: () => <AdminTeamScreen />,
  },
  {
    path: [
      "/programLibrary/",
      "/programLibrary/:trainingPlanId",
      "/myworkoutgroups",
      "proworkoutgroups",
      "workoutlibrary",
    ],
    exact: true,
    title: "Program Library",
    activeIcon: ProgramLibActiveIcon,
    inactiveIcon: ProgramLibInactiveIcon,
    main: () => <AdminProgramLibraryScreen />,
  },
  {
    path: "/exerciselibrary",
    exact: true,
    title: "Exercise Library",
    activeIcon: ExerciseLibActiveIcon,
    inactiveIcon: ExerciseLibInactiveIcon,
    main: () => <AdminExerciseLibraryScreen />,
  },
  {
    path: "/debug",
    exact: true,
    title: "Debug",
    activeIcon: DebugActiveIcon,
    inactiveIcon: DebugInactiveIcon,
    main: () => <AdminDebugScreen />,
  },
  {
    path: "/system-settings",
    exact: true,
    title: "System Settings",
    activeIcon: SystemSettingsActiveIcon,
    inactiveIcon: SystemSettingsInactiveIcon,
    main: () => <SystemSettingsScreen />,
  },
];

export const managerPrimaryRoutes: Array<RoutesInfo> = [
  {
    path: "/tasks",
    exact: true,
    title: "Tasks",
    activeIcon: TasksActiveIcon,
    inactiveIcon: TasksInactiveIcon,
    main: () => <TasksScreen />,
  },
  {
    path: ["/clients", "/"],
    exact: true,
    title: "Members",
    activeIcon: ClientsActiveIcon,
    inactiveIcon: ClientsInactiveIcon,
    main: () => <TrainerClientsScreen />,
  },
  {
    path: "/team",
    exact: true,
    title: "Team",
    activeIcon: TeamActiveIcon,
    inactiveIcon: TeamInactiveIcon,
    main: () => <AdminTeamScreen />,
  },
  {
    path: ["/programLibrary/", "/programLibrary/:trainingPlanId"],
    exact: true,
    title: "Program Library",
    activeIcon: ProgramLibActiveIcon,
    inactiveIcon: ProgramLibInactiveIcon,
    main: () => <AdminProgramLibraryScreen />,
  },
  {
    path: "/exerciselibrary",
    exact: true,
    title: "Exercise Library",
    activeIcon: ExerciseLibActiveIcon,
    inactiveIcon: ExerciseLibInactiveIcon,
    main: () => <AdminExerciseLibraryScreen />,
  },
];
