import React, { useState } from "react";
import DataTable from "react-data-table-component";
import Moment from "react-moment";
import { connect } from "react-redux";
import { format, parse } from "date-fns";
import colors from "../../assets/colors";
import { StoreState } from "../../redux/reducers";
import {
  CalendarItemStatusType,
  Client,
  ClientPackageType,
  MutationDeleteZoomCallArgs,
  ZoomCall,
  ZoomCallType,
} from "../../types/gqlTypes";
import ServiceTracker from "../ClientProfileInfoModule/ServiceTracker";
import EditIcon from "../../assets/images/EditIcon.svg";
import DeleteSolidIcon from "../../assets/images/DeleteSolidIcon.svg";
import EditDialog from "./EditDialog";
import { toTitleCase } from "../../utils";
import ConfirmationDialog from "../ConfirmationDialog";
import { deleteZoomCall } from "../../redux/actions/ClientDetail";

interface Props {
  client: Client;
  zoomCalls: ZoomCall[];
  clientPackageType: ClientPackageType;
  deleteZoomCall: (args: MutationDeleteZoomCallArgs) => void;
}

const ZoomCalls = (props: Props) => {
  const { client, zoomCalls, clientPackageType, deleteZoomCall } = props;

  const [editMode, setEditMode] = useState(false);
  const [addMode, setAddMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [selectedLocalZoomCall, setSelectedLocalZoomCall] = useState(
    {} as ZoomCall
  );

  const lineStyle: React.CSSProperties = {
    height: 1,
    backgroundColor: colors.caliber_gray_bg,
    marginBottom: 24,
  };

  const isPremium = clientPackageType === ClientPackageType.Premium;

  const toggleEditMode = (zoomCall: ZoomCall) => {
    setSelectedLocalZoomCall(zoomCall);
    setEditMode(!editMode);
  };

  const toggleAddMode = () => {
    let defaultZoomCallType: ZoomCallType = ZoomCallType.Strategy;
    if (zoomCalls && zoomCalls?.length > 0) {
      defaultZoomCallType = ZoomCallType.Strategy;
    } else {
      defaultZoomCallType = ZoomCallType.Intro;
    }

    setSelectedLocalZoomCall({
      type: defaultZoomCallType,
      status: CalendarItemStatusType.Scheduled,
    } as ZoomCall);
    setAddMode(!addMode);
  };

  const toggleDeleteMode = (zoomCall: ZoomCall) => {
    setSelectedLocalZoomCall(zoomCall);
    setDeleteMode(!deleteMode);
  };

  const addNewButton = (
    <div
      className="pointer nofocus"
      role="button"
      tabIndex={0}
      style={{
        height: 18,
        lineHeight: "18px",
      }}
      onClick={toggleAddMode}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          toggleAddMode();
        }
      }}
    >
      Add New +
    </div>
  );

  const renderActionCell = (zoomCall: ZoomCall) => {
    return (
      <div className="d-flex flex-column">
        <div className="flex-center">
          <div
            className="pointer nofocus"
            role="button"
            tabIndex={0}
            style={{
              height: 18,
              lineHeight: "18px",
              paddingRight: "5px",
            }}
            onClick={() => toggleEditMode(zoomCall)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                toggleEditMode(zoomCall);
              }
            }}
          >
            <img alt="Edit" src={EditIcon} />
          </div>

          <div
            className="pointer nofocus"
            role="button"
            tabIndex={0}
            style={{
              height: 18,
              lineHeight: "18px",
            }}
            onClick={() => toggleDeleteMode(zoomCall)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                toggleDeleteMode(zoomCall);
              }
            }}
          >
            <img
              alt="Delete"
              src={DeleteSolidIcon}
              style={{
                height: "20px",
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderDate = (args: Date) => {
    return <Moment format="M.D.YYYY">{args}</Moment>;
  };

  const renderDateText = (dateString: string) => {
    const date = dateString
      ? parse(dateString?.slice(0, 10) || null, "yyyy-MM-dd", new Date())
      : new Date();
    return format(date, "M.d.yyyy");
  };

  const columns = React.useMemo(
    () => [
      {
        name: "Type",
        selector: (row) => row.type,
        sortable: true,
        grow: 0,
        cell: (row) => toTitleCase(row.type),
      },
      {
        id: "date",
        name: "Date",
        selector: (row) => row.date,
        sortable: true,
        cell: (row) => renderDate(row.date),
      },
      {
        name: "Status",
        selector: (row) => row.status,
        sortable: true,
        cell: (row) => toTitleCase(row.status),
      },
      {
        name: "Action",
        cell: (row) => renderActionCell(row),
      },
    ],
    [editMode]
  );

  return (
    <div>
      {deleteMode && (
        <ConfirmationDialog
          show
          text={`Are you sure you want to delete the 
            ${toTitleCase(selectedLocalZoomCall.status)} ${toTitleCase(
            selectedLocalZoomCall.type
          )} 
            Zoom Call on ${renderDateText(selectedLocalZoomCall.date)}?`}
          cancelButtonText="Cancel"
          onConfirm={() => {
            deleteZoomCall({
              clientId: client.id,
              zoomCallId: selectedLocalZoomCall.id,
            });

            setDeleteMode(!deleteMode);
          }}
          onCancel={() => setDeleteMode(!deleteMode)}
        />
      )}

      {(addMode || editMode) && (
        <EditDialog
          onCancel={() => {
            if (editMode) {
              setEditMode(!editMode);
            } else setAddMode(!addMode);
          }}
          cancelButtonText="Cancel"
          cancelButtonColor={colors.caliber_error_color}
          confirmButtonText="Save"
          show
          zoomCall={selectedLocalZoomCall}
          addMode={addMode}
          editMode={editMode}
        />
      )}

      <div
        className="d-flex flex-column"
        style={{
          backgroundColor: colors.caliber_white,
          borderRadius: 8,
          width: 500,
          padding: "32px 24px",
        }}
      >
        <div className="d-flex flex-column">
          <div
            className="d-flex justify-content-between align-items-center bold"
            style={{ marginBottom: 24 }}
          >
            <div
              style={{
                fontSize: "20px",
                letterSpacing: "-0.03em",
                color: colors.caliber_secondary,
              }}
            >
              Zoom Calls
            </div>
          </div>

          <div style={lineStyle}>{/* line */}</div>

          {zoomCalls && (
            <DataTable
              title="Call History"
              columns={columns}
              data={zoomCalls}
              pagination
              striped
              actions={addNewButton}
              defaultSortFieldId="date"
              paginationPerPage={6}
              paginationComponentOptions={{
                noRowsPerPage: true,
              }}
              customStyles={{
                header: {
                  style: {
                    fontSize: "12px",
                    fontFamily: "Circular, serif",
                    color: colors.caliber_gray_border,
                    minHeight: "0px",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  },
                },
                cells: {
                  style: {
                    fontFamily: "Circular, serif",
                  },
                },
              }}
            />
          )}

          {isPremium && <ServiceTracker />}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  zoomCalls: state.clientDetailState.zoomCalls,
  clientPackageType: state.clientDetailState.clientPackageType,
  client: state.clientDetailState.user,
});

const mapDispatchToProps = (dispatch) => ({
  deleteZoomCall: (args: MutationDeleteZoomCallArgs) => {
    dispatch(deleteZoomCall(args));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ZoomCalls);
