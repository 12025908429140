import React, { useEffect, useMemo, useState } from "react";
import { addWeeks, endOfWeek, format, startOfWeek, subWeeks } from "date-fns";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { getWeeklyContentByWeekNumber } from "../../redux/actions/WeeklyContent";
import { StoreState } from "../../redux/reducers";
import {
  Client,
  QueryContentForWeekByWeekNumberArgs,
} from "../../types/gqlTypes";
import SimpleButton from "../SimpleButton";
import ArrowIcons from "../Icons/ArrowIcons";
import colors from "../../assets/colors";

interface Props {
  client: Client;
  isLoading: boolean;
  getWeeklyContentByWeekNumber: (
    args: QueryContentForWeekByWeekNumberArgs
  ) => void;
}

const LessonsHeader = (props: Props) => {
  const { client, isLoading, getWeeklyContentByWeekNumber } = props;
  const { id: clientId } = useParams();

  const [weekNum, setWeekNum] = useState(1);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    const currentWeekNum =
      (client?.contentWeekNumber || 1) > 14 ? 14 : client?.contentWeekNumber;
    setStartDate(startOfWeek(new Date()));
    setEndDate(endOfWeek(new Date()));
    setWeekNum(currentWeekNum);
    if (currentWeekNum) {
      getWeeklyContentByWeekNumber({
        clientId,
        memberAvatarType: client.memberAvatarType,
        weekNumber: currentWeekNum,
      });
    }
  }, [client]);

  const dates = useMemo(() => {
    const fromDate = format(startDate, "MMM dd");
    const toDate = format(endDate, "MMM dd");
    return `${fromDate} - ${toDate}`;
  }, [startDate, endDate]);

  const prevWeek = () => {
    if (weekNum > 1 && !isLoading) {
      getWeeklyContentByWeekNumber({
        clientId,
        memberAvatarType: client?.memberAvatarType,
        weekNumber: weekNum - 1,
      });
      setStartDate(subWeeks(startDate, 1));
      setEndDate(subWeeks(endDate, 1));
      setWeekNum(weekNum - 1);
    }
  };

  const nextWeek = () => {
    if (weekNum < 14 && !isLoading) {
      getWeeklyContentByWeekNumber({
        clientId,
        memberAvatarType: client?.memberAvatarType,
        weekNumber: weekNum + 1,
      });
      setStartDate(addWeeks(startDate, 1));
      setEndDate(addWeeks(endDate, 1));
      setWeekNum(weekNum + 1);
    }
  };

  return (
    <div className="d-flex flex-column">
      <div
        className="d-flex justify-content-between align-items-center"
        style={{
          height: 53,
          paddingBottom: 26,
        }}
      >
        <div
          className="bold"
          style={{
            fontSize: "20px",
            lineHeight: "25px",
            letterSpacing: "-0.033em",
            color: "#000000",
          }}
        >
          Caliber Lessons
        </div>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            width: 180,
            height: 36,
          }}
        >
          <SimpleButton
            onClick={prevWeek}
            nofocus
            disabled={weekNum == 1}
            style={{
              width: 22,
              height: 20,
              marginRight: 10,
            }}
          >
            {weekNum !== 1 && <ArrowIcons isLeft width={22} height={20} />}
          </SimpleButton>

          <div
            className="d-flex flex-column"
            style={{
              height: 36,
            }}
          >
            <div
              className="d-flex justify-content-center align-items-center bold"
              style={{
                fontSize: "14px",
                lineHeight: "18px",
                letterSpacing: "-0.03em",
                color: colors.caliber_gray_text,
                width: 92,
              }}
            >
              Week {weekNum}
            </div>
            <div
              className="d-flex justify-content-center align-items-center medium-bold"
              style={{
                fontSize: "12px",
                lineHeight: "18px",
                letterSpacing: "-0.03em",
                color: colors.caliber_gray_border,
                width: 92,
              }}
            >
              {dates}
            </div>
          </div>

          <SimpleButton
            onClick={nextWeek}
            nofocus
            disabled={weekNum === 14}
            style={{
              width: 22,
              height: 20,
              marginLeft: 10,
            }}
          >
            {weekNum !== 14 && (
              <ArrowIcons isLeft={false} width={22} height={20} />
            )}
          </SimpleButton>
        </div>
      </div>

      <div
        style={{
          height: 1,
          backgroundColor: colors.caliber_gray_bg,
          marginBottom: 32,
        }}
      >
        {/* line */}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getWeeklyContentByWeekNumber: (args: QueryContentForWeekByWeekNumberArgs) => {
    dispatch(getWeeklyContentByWeekNumber(args));
  },
});

const mapStateToProps = (state: StoreState) => ({
  client: state.clientDetailState.user,
  isLoading: state.weeklyContentState.isLoading,
});

export default connect(mapStateToProps, mapDispatchToProps)(LessonsHeader);
