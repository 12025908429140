export interface Config {
  keycloakRealm: string;
  keycloakUrl: string;
  clientId: string;
  apiUrl: string;
  appUrl: string;
  sbAppId: string;
  isProduction: boolean;
}

// eslint-disable-next-line import/prefer-default-export
export const localAppConfig: Config = {
  clientId: "caliber-platform-web",
  keycloakRealm: "caliber-dev",
  keycloakUrl: "https://id.dev.caliberstrong.com",
  apiUrl: "http://localhost:8080",
  appUrl: "http://localhost:3000",
  sbAppId: "A4BA31A6-1EF7-4F01-8AE5-A51CDA503F65",
  isProduction: false,
};

// eslint-disable-next-line import/prefer-default-export
export const devAppConfig: Config = {
  clientId: "caliber-platform-web",
  keycloakRealm: "caliber-dev",
  keycloakUrl: "https://id.dev.caliberstrong.com",
  apiUrl: "https://api.dev.caliberstrong.com",
  appUrl: "https://app.dev.caliberstrong.com",
  sbAppId: "A4BA31A6-1EF7-4F01-8AE5-A51CDA503F65",
  isProduction: false,
};

// eslint-disable-next-line import/prefer-default-export
export const stagingAppConfig: Config = {
  clientId: "caliber-platform-web",
  keycloakRealm: "caliber",
  keycloakUrl: "https://id.caliberstrong.com",
  apiUrl: "https://api.staging.caliberstrong.com",
  appUrl: "https://app.staging.caliberstrong.com",
  sbAppId: "D3937C6B-D125-4D6C-AA9D-85C4BC66235E",
  isProduction: false,
};

// eslint-disable-next-line import/prefer-default-export
export const prodAppConfig: Config = {
  clientId: "caliber-platform-web",
  keycloakRealm: "caliber",
  keycloakUrl: "https://id.caliberstrong.com",
  apiUrl: "https://api.caliberstrong.com",
  appUrl: "https://app.caliberstrong.com",
  sbAppId: "D3937C6B-D125-4D6C-AA9D-85C4BC66235E",
  isProduction: true,
};

export const getAppConfig = (): Config => {
  // Local API (env variable is only injected locally during compile time using `yarn start-local-api-server`)
  if (process.env.REACT_APP_LOCAL_API_SERVER) {
    return localAppConfig;
  }

  // Staging API
  if (window.location.host === "app.staging.caliberstrong.com") {
    return stagingAppConfig;
  }

  // Production API
  if (window.location.host === "app.caliberstrong.com") {
    return prodAppConfig;
  }
  // Dev API
  return devAppConfig;
};
