import apolloClient from "../../../api/apolloClient";
import {
  CREATE_UPDATE_CLIENT_NUTRITION_TARGET,
  GET_CLIENT_NUTRITION_TARGET,
} from "../../../api/gql/users/clients";
import {
  ClientNutritionTarget,
  MutationCreateUpdateClientNutritionTargetArgs,
  QueryClientNutritionTargetArgs,
} from "../../../types/gqlTypes";
import {
  GET_CLIENT_NUTRITION_TARGET_FAIL,
  GET_CLIENT_NUTRITION_TARGET_LOADING,
  GET_CLIENT_NUTRITION_TARGET_SUCCESS,
  SAVING_CLIENT_NUTRITION_TARGET_FAIL,
  SAVING_CLIENT_NUTRITION_TARGET_SAVING,
  SAVING_CLIENT_NUTRITION_TARGET_SUCCESS,
} from "./types";

export const getClientNutritionTarget =
  (args: QueryClientNutritionTargetArgs) => async (dispatch, getState) => {
    if (getState().clientNutritionTargetState.isLoading) {
      return;
    }
    dispatch({
      type: GET_CLIENT_NUTRITION_TARGET_LOADING,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const result = (
        await client.query({
          query: GET_CLIENT_NUTRITION_TARGET,
          variables: args,
        })
      ).data.clientNutritionTarget as ClientNutritionTarget;

      dispatch({
        type: GET_CLIENT_NUTRITION_TARGET_SUCCESS,
        data: result,
      });
    } catch (error) {
      console.error("Error getClientNutritionTarget:", error);
      dispatch({
        type: GET_CLIENT_NUTRITION_TARGET_FAIL,
        data: error,
      });
    }
  };

export const createUpdateClientNutritionTarget =
  (args: MutationCreateUpdateClientNutritionTargetArgs) =>
  async (dispatch, getState) => {
    if (getState().clientNutritionTargetState.isSaving) {
      return;
    }
    dispatch({
      type: SAVING_CLIENT_NUTRITION_TARGET_SAVING,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const { clientNutritionTarget } = getState().clientNutritionTargetState;
      const result = (
        await client.mutate({
          mutation: CREATE_UPDATE_CLIENT_NUTRITION_TARGET,
          variables: {
            calories: clientNutritionTarget?.calories,
            carbs: clientNutritionTarget?.carbs,
            fat: clientNutritionTarget?.fat,
            isIntermittentFasting: clientNutritionTarget?.isIntermittentFasting,
            isIntermittentFastingActiveDate:
              clientNutritionTarget?.isIntermittentFastingActiveDate,
            isLogging: clientNutritionTarget?.isLogging,
            isLoggingActiveDate: clientNutritionTarget?.isLoggingActiveDate,
            macroTargetsActiveDate:
              clientNutritionTarget?.macroTargetsActiveDate,
            mealPlanMediaUrlId: clientNutritionTarget?.mealPlanMediaUrl?.id,
            phase: clientNutritionTarget?.phase,
            phaseActiveDate: clientNutritionTarget?.phaseActiveDate,
            protein: clientNutritionTarget?.protein,
            protocol: clientNutritionTarget?.protocol,
            protocolActiveDate: clientNutritionTarget?.protocolActiveDate,
            ...args,
          },
        })
      ).data.createUpdateClientNutritionTarget as ClientNutritionTarget;

      dispatch({
        type: SAVING_CLIENT_NUTRITION_TARGET_SUCCESS,
        data: result,
      });
    } catch (error) {
      console.error("Error createUpdateClientNutritionTarget:", error);
      dispatch({
        type: SAVING_CLIENT_NUTRITION_TARGET_FAIL,
        data: error,
      });
    }
  };
