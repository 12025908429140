import React, { useEffect, useState } from "react";
import colors from "../../assets/colors";
import { ClientHabitTarget, HabitType } from "../../types/gqlTypes";
import { numberWithCommas, onKeyDownRestrict } from "../../utils";
import CloseIcon from "../Icons/CloseIcons";
import Modal from "../Modal";
import StepsHabitCard from "../ScheduleEventModal/HabitEvent/StepsHabitCard";
import SimpleButton from "../SimpleButton";
import Footer from "./Footer";

interface Props {
  onClose: () => void;
  onBack: () => void;
  onSuccess: (habitType: HabitType, target: number) => void;
  habitType: HabitType;
  habitToUpdate?: ClientHabitTarget;
}

const UpdateHabitModal = (props: Props) => {
  const { onClose, onBack, onSuccess, habitType, habitToUpdate } = props;
  const isUpdating = !!habitToUpdate;
  const [value, setValue] = useState(0);

  const onKeyDown = (event) => {
    if (onKeyDownRestrict(event.key, ["."])) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (isUpdating) {
      setValue(habitToUpdate?.target);
    }
  }, [habitToUpdate]);

  const habitCards = {
    [HabitType.Steps]: <StepsHabitCard height={96} />,
  };

  const emptyInputStyle: React.CSSProperties = {
    width: 100,
    height: 32,
    backgroundColor: colors.caliber_secondary_gray_11,
    border: `1px solid ${colors.caliber_secondary_gray_11}`,
    borderRadius: 8,
    fontSize: "18px",
    letterSpacing: "-0.022em",
    color: colors.caliber_secondary_gray_47,
    textAlign: "right",
    paddingRight: 12,
  };
  const inputStyle: React.CSSProperties = {
    width: 100,
    height: 32,
    backgroundColor: colors.caliber_white,
    border: "1px solid #3446FB",
    borderRadius: 8,
    fontSize: "18px",
    letterSpacing: "-0.022em",
    color: "#000000",
    textAlign: "right",
    paddingRight: 12,
  };
  const labelStyle: React.CSSProperties = {
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "-0.022em",
    color: colors.caliber_gray_text,
    marginLeft: 14,
    marginRight: 14,
    width: 30,
  };

  const canBeSave =
    (isUpdating && value !== habitToUpdate?.target && value !== 0) ||
    (!isUpdating && value !== 0);

  return (
    <Modal onClose={onClose} closeOnDimmerClick>
      <div
        className="d-flex flex-column"
        style={{
          padding: "28px 33px 48px 33px",
          width: 560,
          height: 420,
        }}
      >
        <div
          className="d-flex justify-content-between align-items-center"
          style={{
            marginBottom: 48,
          }}
        >
          <div
            className="bold"
            style={{
              fontSize: "24px",
              lineHeight: "24px",
              letterSpacing: "-0.033em",
              color: colors.black,
            }}
          >
            Adjust habit
          </div>
          <SimpleButton nofocus onClick={onClose}>
            <CloseIcon width={27} height={27} />
          </SimpleButton>
        </div>

        {habitCards[habitType]}

        <div
          style={{
            height: 1,
            backgroundColor: colors.caliber_gray_bg,
            marginTop: 24,
            marginBottom: 15,
          }}
        >
          {/* line */}
        </div>

        <div className="d-flex justify-content-between align-items-center medium-bold">
          <div>Steps</div>
          <div className="d-flex">
            <input
              className="medium-bold"
              value={numberWithCommas(value)}
              onChange={(event) => {
                setValue(Number(event.target.value.split(",").join("")));
              }}
              onKeyDown={onKeyDown}
              style={value === 0 ? emptyInputStyle : inputStyle}
            />
            <div
              className="d-flex align-items-center medium-bold"
              style={labelStyle}
            >
              steps
            </div>
          </div>
        </div>

        <div
          style={{
            height: 1,
            backgroundColor: colors.caliber_gray_bg,
            marginTop: 15,
            marginBottom: 48,
          }}
        >
          {/* line */}
        </div>

        <Footer
          onBack={onBack}
          onSuccess={() => {
            onSuccess(habitType, value);
          }}
          disabled={!canBeSave}
        />
      </div>
    </Modal>
  );
};

export default UpdateHabitModal;
