import {
  REASSIGN_CLIENT_TRAINER_FAIL,
  REASSIGN_CLIENT_TRAINER_LOADING,
  REASSIGN_CLIENT_TRAINER_SUCCESS,
  ReassignClientTrainerAction,
  ReassignClientTrainerState,
} from "../../actions/ReassignClientTrainer/types";

const initialState: ReassignClientTrainerState = {
  isLoading: false,
};

const reassignClientTrainerState = (
  state = initialState,
  action: ReassignClientTrainerAction
) => {
  switch (action.type) {
    case REASSIGN_CLIENT_TRAINER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case REASSIGN_CLIENT_TRAINER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case REASSIGN_CLIENT_TRAINER_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default reassignClientTrainerState;
