import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { format } from "date-fns";
import { NAV_COLLAPSED_WIDTH, NAV_WIDTH } from "../ClientNavItem";
import LeftArrowButton from "../../assets/images/LeftArrowButton.svg";
import RightArrowButton from "../../assets/images/RightArrowButton.svg";
import "./index.css";
import SimpleButton from "../SimpleButton";
import { useKeyPress, useRerenderOnResize } from "../../utils/customHooks";
import colors from "../../assets/colors";
import { PhotoTypes } from ".";
import { PoseType } from "../../types/gqlTypes";

interface Props {
  photosOnly: {
    date: Date;
    id: string;
    photo: string;
    pose: PoseType & PhotoTypes;
  }[];
  photoType: PhotoTypes;
  clickedPhotoId: string;
}

const PictureCarousel = (props: Props) => {
  const { photosOnly, photoType, clickedPhotoId } = props;
  useRerenderOnResize();

  const [pickedPhoto, setPickedPhoto] = useState(1);
  const picRef = useRef<HTMLDivElement>();

  const pictures = useMemo(() => {
    return photosOnly.filter((photo) => {
      return photoType === PhotoTypes.ALL || photo.pose === photoType;
    });
  }, [photosOnly, photoType]);

  useEffect(() => {
    setPickedPhoto(0);
  }, [photoType]);
  useEffect(() => {
    setPickedPhoto(
      photosOnly.findIndex((photo) => clickedPhotoId === photo.id)
    );
  }, [clickedPhotoId]);

  useEffect(() => {
    if (picRef?.current) {
      picRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [pickedPhoto]);

  const moveForward = useCallback(() => {
    const { length } = pictures;
    if (length === 1) {
      setPickedPhoto(0);
    } else if (length === 2) {
      if (pickedPhoto === 0) {
        setPickedPhoto(1);
      } else {
        setPickedPhoto(0);
      }
    } else if (pickedPhoto !== length - 1) {
      setPickedPhoto(pickedPhoto + 1);
    }
  }, [pictures, pickedPhoto]);
  const moveBack = useCallback(() => {
    const { length } = pictures;
    if (length === 1) {
      setPickedPhoto(0);
    } else if (length === 2) {
      if (pickedPhoto === 0) {
        setPickedPhoto(1);
      } else {
        setPickedPhoto(0);
      }
    } else if (pickedPhoto !== 0) {
      setPickedPhoto(pickedPhoto - 1);
    }
  }, [pictures, pickedPhoto]);

  useKeyPress("ArrowRight", moveForward);
  useKeyPress("ArrowLeft", moveBack);

  const [prevPhoto, nextPhoto] = useMemo(() => {
    const { length } = pictures;
    let newNext;
    let newPrev;
    if (length === 1) {
      newNext = null;
      newPrev = null;
    } else if (length === 2) {
      if (pickedPhoto === 0) {
        newNext = 1;
        newPrev = null;
      } else {
        newNext = null;
        newPrev = 0;
      }
    } else if (pickedPhoto === 0) {
      newNext = 1;
      newPrev = null;
    } else if (pickedPhoto < length - 1) {
      newNext = pickedPhoto + 1;
      newPrev = pickedPhoto - 1;
    } else if (pickedPhoto === length - 1) {
      newNext = null;
      newPrev = pickedPhoto - 1;
    }
    return [newPrev, newNext];
  }, [pickedPhoto, pictures]);

  const width =
    window.innerWidth > 1100
      ? window.innerWidth - (NAV_WIDTH + 56)
      : window.innerWidth - (NAV_COLLAPSED_WIDTH + 56);

  return (
    <div
      className="d-flex flex-column"
      style={{
        marginBottom: 60,
        marginTop: 76,
      }}
    >
      <div
        className="d-flex justify-content-center"
        style={{
          width,
          overflowX: "hidden",
        }}
      >
        <div style={{ height: 500, minWidth: 350 }}>
          <img
            className="blurred_img"
            style={{
              height: 500,
            }}
            src={pictures?.[prevPhoto]?.photo}
          />
        </div>
        <div
          style={{
            height: 500,
            maxWidth: width,
            position: "relative",
          }}
        >
          <SimpleButton onClick={moveBack}>
            <img
              style={{
                position: "absolute",
                top: "55%",
                left: -74,
              }}
              src={LeftArrowButton}
            />
          </SimpleButton>
          <img
            style={{
              height: 500,
              maxWidth: width,
              margin: "0px 11px",
            }}
            src={pictures?.[pickedPhoto]?.photo}
          />
          <SimpleButton onClick={moveForward}>
            <img
              style={{
                position: "absolute",
                top: "55%",
                right: -74,
                zIndex: 2,
              }}
              src={RightArrowButton}
            />
          </SimpleButton>
        </div>
        <div style={{ height: 500, minWidth: 350 }}>
          <img
            className="blurred_img"
            style={{
              height: 500,
            }}
            src={pictures?.[nextPhoto]?.photo}
          />
        </div>
      </div>

      <div
        className="d-flex"
        style={{
          width,
          overflowX: "auto",
        }}
      >
        {pictures.map((picture, index) => {
          return (
            <SimpleButton
              key={picture.id}
              onClick={() => setPickedPhoto(index)}
              className="d-flex flex-column justify-content-center nofocus"
              style={{
                margin: 12,
                marginLeft: index === 0 ? 0 : 12,
                marginRight: index === pictures.length - 1 ? 0 : 12,
              }}
            >
              <div
                ref={(ref) => {
                  if (index === pickedPhoto) {
                    picRef.current = ref;
                  }
                }}
                className="d-flex justify-content-center"
              >
                <div
                  className="d-flex align-items-center justify-content-center medium-bold"
                  style={{
                    width: 77,
                    height: 22,
                    backgroundColor:
                      index === pickedPhoto
                        ? "#F73843"
                        : colors.caliber_secondary_gray_11,
                    color: colors.caliber_secondary,
                    borderRadius: 6,
                    fontSize: "12px",
                    lineHeight: "14px",
                    marginBottom: 12,
                  }}
                >
                  {format(picture.date, "MM.dd.yyyy")}
                </div>
              </div>
              <img
                style={{
                  height: 135,
                  maxHeight: 135,
                  borderRadius: 8,

                  border: index === pickedPhoto && "4px solid #F73843",
                }}
                src={picture.photo}
              />
            </SimpleButton>
          );
        })}
      </div>
    </div>
  );
};

export default PictureCarousel;
