import React from "react";

interface Props {
  isSelected: boolean;
}

const PhotoComparisonIcon = (props: Props) => {
  const { isSelected } = props;

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="16"
        cy="16"
        r="16"
        fill={isSelected ? "#5B64A0" : "#989BAD"}
      />
      <g clipPath="url(#clip0)">
        <path
          d="M22.1667 23.3333H9.83333C8.822 23.3333 8 22.5113 8 21.5V10.5C8 9.48863 8.822 8.66663 9.83333 8.66663H22.1667C23.178 8.66663 24 9.48863 24 10.5V21.5C24 22.5113 23.178 23.3333 22.1667 23.3333ZM9.83333 9.66663C9.374 9.66663 9 10.0406 9 10.5V21.5C9 21.9593 9.374 22.3333 9.83333 22.3333H22.1667C22.626 22.3333 23 21.9593 23 21.5V10.5C23 10.0406 22.626 9.66663 22.1667 9.66663H9.83333Z"
          fill="white"
        />
      </g>
      <path d="M16 9V17" stroke="white" />
      <path d="M8 17H23" stroke="white" />
      <g clipPath="url(#clip1)">
        <path
          d="M22.1667 23.3333H9.83333C8.822 23.3333 8 22.5113 8 21.5V10.5C8 9.48863 8.822 8.66663 9.83333 8.66663H22.1667C23.178 8.66663 24 9.48863 24 10.5V21.5C24 22.5113 23.178 23.3333 22.1667 23.3333ZM9.83333 9.66663C9.374 9.66663 9 10.0406 9 10.5V21.5C9 21.9593 9.374 22.3333 9.83333 22.3333H22.1667C22.626 22.3333 23 21.9593 23 21.5V10.5C23 10.0406 22.626 9.66663 22.1667 9.66663H9.83333Z"
          fill="white"
        />
      </g>
      <path d="M16 9V17" stroke="white" />
      <path d="M8 17H23" stroke="white" />
      <defs>
        <clipPath id="clip0">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
        <clipPath id="clip1">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PhotoComparisonIcon;
