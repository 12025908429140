import { getYear, parse, subMonths } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import DeleteIcon from "../../assets/images/DeleteSolidIcon.svg";
import FemaleBackGray from "../../assets/images/progressDefaultPhotos/Female/Female Back Gray.png";
import FemaleFrontGray from "../../assets/images/progressDefaultPhotos/Female/Female Front Gray.png";
import FemaleSideGray from "../../assets/images/progressDefaultPhotos/Female/Female Side Gray.png";
import MaleBackGray from "../../assets/images/progressDefaultPhotos/Male/Male Back Gray.png";
import MaleFrontGray from "../../assets/images/progressDefaultPhotos/Male/Male Front Gray.png";
import MaleSideGray from "../../assets/images/progressDefaultPhotos/Male/Male Side Gray.png";
import {
  CalendarProgressItems,
  getCalendarProgressItems,
} from "../../redux/actions/Calendar";
import { StoreState } from "../../redux/reducers";
import {
  CalendarDay,
  CalendarItemType,
  GenderType,
  PhotoUpload,
  PoseType,
  ProgressPhotoCalendarItem,
} from "../../types/gqlTypes";
import Dropdown from "../Dropdown";
import PhotoComparisonIcon from "../Icons/PhotoComparisonIcon";
import Loader from "../Loader";
import PhotoComparison from "../PhotoComparison";
import SimpleButton from "../SimpleButton";
import PhotoThrouple from "./PhotoThrouple";
import PictureCarousel from "./PictureCarousel";

interface Props {
  calendarData: CalendarDay[];
  isLoading: boolean;
  userGender: GenderType;
  getProgressPhotoCalendarItems: (
    clientId: string,
    startDate: Date,
    endDate: Date
  ) => void;
}

export enum PhotoTypes {
  ALL = "ALL",
  FRONT = "FRONT",
  SIDE = "SIDE",
  BACK = "BACK",
}

const PhotoProgress = (props: Props) => {
  const { calendarData, isLoading, userGender, getProgressPhotoCalendarItems } =
    props;
  const { id: clientId } = useParams();
  const [photoType, setPhotoType] = useState<PhotoTypes>(PhotoTypes.ALL);
  const [clickedPhotoId, setClickedPhotoId] = useState<string>(null);
  const [isComparisonOn, setComparisonOn] = useState(false);
  const [imagesToCompare, setImagesToCompare] = useState([null, null]);

  useEffect(() => {
    const endDate = new Date();
    const startDate = subMonths(endDate, 36);
    getProgressPhotoCalendarItems(clientId, startDate, endDate);
  }, []);

  const dropdownTypeOptions = [
    { text: "All Photos", value: PhotoTypes.ALL },
    { text: "Front Photos", value: PhotoTypes.FRONT },
    { text: "Side Photos", value: PhotoTypes.SIDE },
    { text: "Back Photos", value: PhotoTypes.BACK },
  ];
  const defaultPictures = {
    [GenderType.Female]: {
      [PoseType.Front]: FemaleFrontGray,
      [PoseType.Side]: FemaleSideGray,
      [PoseType.Back]: FemaleBackGray,
    },
    [GenderType.Male]: {
      [PoseType.Front]: MaleFrontGray,
      [PoseType.Side]: MaleSideGray,
      [PoseType.Back]: MaleBackGray,
    },
  };

  const normalizedData = useMemo(() => {
    const dataByYear = {};
    const photosOnly = [];
    calendarData?.forEach((day) => {
      day?.items?.forEach((item: ProgressPhotoCalendarItem) => {
        if (
          item?.enumType === CalendarItemType.ProgressPic &&
          item?.photos?.length > 0
        ) {
          const pictures = {};
          item?.photos?.forEach((photo: PhotoUpload) => {
            pictures[photo?.pose] = photo;
          });
          const poses = [PoseType.Front, PoseType.Side, PoseType.Back];
          const oneDayPoses = [];
          const parsedDate = parse(day.date, "yyyy-MM-dd", new Date());
          const year = getYear(parsedDate);
          poses.forEach((pose, index) => {
            if (pictures?.[pose]) {
              photosOnly.push({
                date: parsedDate,
                pose,
                id: pictures?.[pose]?.poseMediaUrl?.id,
                photo: pictures[pose]?.poseMediaUrl?.url,
              });
              oneDayPoses.push({
                date: parsedDate,
                pose,
                id: pictures?.[pose]?.poseMediaUrl?.id,
                photo: pictures[pose]?.poseMediaUrl?.url,
                isDefault: false,
              });
            } else {
              oneDayPoses.push({
                date: parsedDate,
                pose,
                id: `${item.id}${index}`,
                photo:
                  defaultPictures?.[userGender]?.[pose] ||
                  defaultPictures[GenderType.Male][pose],
                isDefault: true,
              });
            }
          });
          if (!dataByYear?.[year]) {
            dataByYear[year] = [];
          }
          dataByYear[year].push(oneDayPoses);
        }
      });
    });
    return { dataByYear, photosOnly };
  }, [calendarData]);

  const filteredPhotosByYear = useMemo(() => {
    const filteredPhotosByYear = {};
    Object.keys(normalizedData.dataByYear).map((year) => {
      normalizedData.dataByYear[year].map(([front, side, back]) => {
        const photoArray = [];
        if (
          photoType === PhotoTypes.ALL ||
          (front.pose === photoType && !front.isDefault)
        ) {
          photoArray.push(front);
        }
        if (
          photoType === PhotoTypes.ALL ||
          (side.pose === photoType && !side.isDefault)
        ) {
          photoArray.push(side);
        }
        if (
          photoType === PhotoTypes.ALL ||
          (back.pose === photoType && !back.isDefault)
        ) {
          photoArray.push(back);
        }
        if (photoArray.length > 0) {
          if (!filteredPhotosByYear?.[year]) {
            filteredPhotosByYear[year] = [];
          }
          filteredPhotosByYear[year].push(photoArray);
        }
      });
    });
    return filteredPhotosByYear;
  }, [normalizedData, photoType]);

  const onPictureClick = (picture) => {
    if (isComparisonOn && !picture.isDefault) {
      const firstId = imagesToCompare[0]?.id;
      const secondId = imagesToCompare[1]?.id;
      const isFirst = !imagesToCompare[0];
      const isSecond = !imagesToCompare[1];
      if (isFirst && firstId !== picture?.id && secondId !== picture?.id) {
        setImagesToCompare([picture, imagesToCompare[1]]);
      } else if (
        isSecond &&
        firstId !== picture?.id &&
        secondId !== picture?.id
      ) {
        setImagesToCompare([imagesToCompare[0], picture]);
      }
    } else if (!picture.isDefault) {
      setClickedPhotoId(picture.id);
    }
  };

  return (
    <div
      className="d-flex"
      style={{
        flex: 1,
      }}
    >
      <div
        className="d-flex flex-column"
        style={{
          flex: 1,
          position: "relative",
          height: window.innerHeight - 260,
          overflowY: "scroll",
          marginRight: 5,
        }}
      >
        {isLoading && (
          <div
            style={{
              position: "absolute",
              top: 30,
              left: "48%",
            }}
          >
            <Loader />
          </div>
        )}
        <div
          className="d-flex align-item-center"
          style={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        >
          <Dropdown
            height={36}
            width={192}
            value={photoType}
            items={dropdownTypeOptions}
            onSelect={(value) => setPhotoType(value)}
          />
          {!clickedPhotoId && (
            <SimpleButton
              nofocus
              onClick={() => setComparisonOn(!isComparisonOn)}
              style={{
                height: 32,
                width: 32,
                margin: "3px 25px 3px 19px",
              }}
              iconStyle={{ height: 30, width: 30 }}
            >
              <PhotoComparisonIcon isSelected={isComparisonOn} />
            </SimpleButton>
          )}
          {clickedPhotoId && (
            <SimpleButton
              isIcon
              nofocus
              onClick={() => setClickedPhotoId(null)}
              style={{
                height: 30,
                width: 30,
                margin: "3px 25px 3px 19px",
              }}
              iconStyle={{ height: 30, width: 30 }}
            >
              {DeleteIcon}
            </SimpleButton>
          )}
        </div>
        {!clickedPhotoId &&
          Object.keys(filteredPhotosByYear)
            .sort()
            .map((year) => {
              return (
                <PhotoThrouple
                  key={filteredPhotosByYear[year]?.[0]?.[0]?.id}
                  filteredPhotosByYear={filteredPhotosByYear}
                  year={year}
                  onClick={onPictureClick}
                />
              );
            })}
        {clickedPhotoId && (
          <PictureCarousel
            clickedPhotoId={clickedPhotoId}
            photoType={photoType}
            photosOnly={normalizedData.photosOnly.filter(
              (photo) =>
                photoType === PhotoTypes.ALL || photo.pose === photoType
            )}
          />
        )}
      </div>

      {isComparisonOn && (
        <PhotoComparison
          imgUrl1={imagesToCompare?.[0]?.photo}
          date1={imagesToCompare?.[0]?.date}
          imgUrl2={imagesToCompare?.[1]?.photo}
          date2={imagesToCompare?.[1]?.date}
          onDelete1={() => setImagesToCompare([null, imagesToCompare?.[1]])}
          onDelete2={() => setImagesToCompare([imagesToCompare?.[0], null])}
          deleteBoth={() => setImagesToCompare([null, null])}
          onSwap={() =>
            setImagesToCompare([imagesToCompare?.[1], imagesToCompare?.[0]])
          }
          onClose={() => setComparisonOn(false)}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getProgressPhotoCalendarItems: (
    clientId: string,
    startDate: Date,
    endDate: Date
  ) => {
    dispatch(
      getCalendarProgressItems(
        clientId,
        startDate,
        endDate,
        CalendarProgressItems.PROGRESS_PHOTO
      )
    );
  },
});

const mapStateToProps = (state: StoreState) => ({
  calendarData: state.calendarDataState.calendarItemsByDay,
  isLoading: state.calendarDataState.isLoading,
  clientNutritionTarget: state.clientNutritionTargetState.clientNutritionTarget,
  userGender: state.clientDetailState.user.gender,
});

export default connect(mapStateToProps, mapDispatchToProps)(PhotoProgress);
