import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import ChatWindow from "../../components/ChatWindow";
import ClientNavigationBar from "../../components/ClientNavigationBar";
import DashboardNavigation from "../../components/DashboardNavigation";
import { trainerClientRoutes } from "../../navigation";
import {
  getClientTrainingPlans,
  resetClientState,
} from "../../redux/actions/ClientPlans";
import { getStrengthScores } from "../../redux/actions/ClientStrengthDashboard";
import { UserState } from "../../redux/actions/User/types";
import { getClientDetail } from "../../redux/actions/ClientDetail";
import { getWeeklyContentByClientId } from "../../redux/actions/WeeklyContent";
import { StoreState } from "../../redux/reducers";
import {
  QueryContentForWeekByClientIdArgs,
  UserType,
} from "../../types/gqlTypes";
import TrainerDashboardStrengthScoreDetails from "../TrainerDashboardStrengthScoreDetails";

interface Props {
  userState: UserState;
  getUserData: (id: string) => void;
  getStrengthScores: (clientId: string) => void;
  getWeeklyContentByClientId: (args: QueryContentForWeekByClientIdArgs) => void;
  resetClientState: () => void;
  getClientTrainingPlans: (clientId: string) => void;
}

const TrainerClientHomeTab = (props: Props) => {
  const {
    userState,
    getUserData,
    getStrengthScores,
    resetClientState,
    getClientTrainingPlans,
  } = props;
  const { id } = useParams();

  const { isExact: isStrengthScoreDetails } =
    useRouteMatch("/clients/:id/dashboard/strength-score-details") || {};

  useEffect(() => {
    if (id) {
      getUserData(id);
      getStrengthScores(id);
      getClientTrainingPlans(id);
    }
    return resetClientState;
  }, [id]);

  const isTrainer = userState?.user?.type === UserType.Trainer;

  return (
    <div
      className="d-flex flex-column"
      style={{
        margin: 12,
        position: "relative",
        flex: 1,
      }}
    >
      {isStrengthScoreDetails ? (
        <DashboardNavigation />
      ) : (
        <ClientNavigationBar routes={trainerClientRoutes} id={id} />
      )}

      <Switch>
        <Route
          path="/clients/:id/dashboard/strength-score-details"
          component={TrainerDashboardStrengthScoreDetails}
        />

        {trainerClientRoutes
          .filter((route) => !isTrainer || route?.title !== "Debug")
          .map((route) => (
            <Route
              key={route.path}
              exact={route.exact}
              path={route.path}
              component={route.main}
            />
          ))}
      </Switch>

      <ChatWindow />
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  getUserData: (id: string) => {
    dispatch(getClientDetail(id));
  },
  resetClientState: () => {
    dispatch(resetClientState());
  },
  getStrengthScores: (clientId: string) => {
    dispatch(getStrengthScores(clientId));
  },
  getWeeklyContentByClientId: (args: QueryContentForWeekByClientIdArgs) => {
    dispatch(getWeeklyContentByClientId(args));
  },
  getClientTrainingPlans: (clientId: string) => {
    dispatch(getClientTrainingPlans(clientId));
  },
});

const mapStateToProps = (state: StoreState) => ({
  userState: state.authenticatedUserState,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrainerClientHomeTab);
