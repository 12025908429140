import React from "react";
import { useParams, Link } from "react-router-dom";
import colors from "../../assets/colors";
import RightArrow from "../../assets/images/RightArrowGray.svg";
import { DashboardTypes } from "../../models/strengthscore";

interface Props {
  dashboardType: DashboardTypes;
}

const StrengthDashboardHeader = (props: Props) => {
  const { dashboardType } = props;
  const { id: clientId } = useParams();

  const isStrengthScore = dashboardType === DashboardTypes.score;
  const title = isStrengthScore ? "Strength Score" : "Strength Balance";

  const lineStyle: React.CSSProperties = {
    height: 1,
    backgroundColor: colors.caliber_gray_bg,
    marginBottom: 24,
  };

  return (
    <div className="d-flex flex-column">
      <div
        className="d-flex justify-content-between align-items-center bold"
        style={{ marginBottom: 24 }}
      >
        <div
          style={{
            fontSize: "20px",
            letterSpacing: "-0.03em",
            color: colors.caliber_secondary,
          }}
        >
          {title}
        </div>
        {isStrengthScore && (
          <Link
            className="d-flex"
            key={`link_client_strength-score-details_${clientId}`}
            to={`/clients/${clientId}/dashboard/strength-score-details`}
            style={{ textDecoration: "none" }}
          >
            <div
              className="d-flex"
              style={{
                fontSize: "12px",
                letterSpacing: "-0.03em",
                color: colors.caliber_secondary_gray_29,
                paddingRight: 5,
              }}
            >
              Strength Score Details
            </div>
            <img
              style={{
                height: 18,
              }}
              src={RightArrow}
            />
          </Link>
        )}
      </div>
      <div style={lineStyle}>{/* line */}</div>
    </div>
  );
};

export default StrengthDashboardHeader;
