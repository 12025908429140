import { gql } from "apollo-boost";

export const strengthScoreDashboard = gql`
  fragment StrengthScoreDashboard on StrengthScoreDashboard {
    id
    ageDiscount
    ageDiscountChanged
    startDate
    endDate
    explanationText
    level
    strengthBalanceScore
    overallStrengthScore {
      balance
      orm
      ormDelta
      score
      strengthBalanceDelta
      strengthScoreDelta
      type
    }
    muscleGroupScores {
      balance
      orm
      ormDelta
      score
      strengthBalanceDelta
      strengthScoreDelta
      keyDriverExplanationTextType
      exerciseLight {
        id
        name
      }
      type
    }
  }
`;

export const STRENGTH_SCORES_GQL = gql`
  ${strengthScoreDashboard}
  query StrengthScores($clientId: UUID) {
    strengthScores(clientId: $clientId) {
      ...StrengthScoreDashboard
    }
  }
`;

export const GET_STRENGTH_SCORES = gql`
  ${strengthScoreDashboard}
  query StrengthScoresByDateRange(
    $clientId: UUID!
    $endDate: LocalDate!
    $startDate: LocalDate!
    $type: StrengthScoreMuscleGroupType!
  ) {
    strengthScoresByDateRange(
      clientId: $clientId
      endDate: $endDate
      startDate: $startDate
      type: $type
    ) {
      endDate
      score
      balance
    }
    strengthScoreDashboardChanges(clientId: $clientId) {
      ...StrengthScoreDashboard
    }
    strengthBalancesByDateRange(
      clientId: $clientId
      endDate: $endDate
      startDate: $startDate
      type: $type
    ) {
      endDate
      score
      balance
    }
  }
`;
export const GET_STRENGTH_SCORES_MUSCLE_GROUP = gql`
  query StrengthScoresByDateRange(
    $clientId: UUID!
    $endDate: LocalDate!
    $startDate: LocalDate!
    $type: StrengthScoreMuscleGroupType!
  ) {
    strengthScoresByDateRange(
      clientId: $clientId
      endDate: $endDate
      startDate: $startDate
      type: $type
    ) {
      endDate
      score
      balance
    }
  }
`;

export const REGENERATE_STRENGTH_SCORE = gql`
  ${strengthScoreDashboard}
  mutation RegenerateStrengthScore($clientId: UUID) {
    regenerateStrengthScores(clientId: $clientId) {
      ...StrengthScoreDashboard
    }
  }
`;
