import React, { useState } from "react";
import { connect } from "react-redux";
import CoachProfileComponent from "../../components/CoachProfileComponent";
import TeamMemberForm from "../../components/TeamMemberForm";
import TeamList from "../../components/TeamList";
import { getCoachProfile } from "../../redux/actions/CoachProfile";
import { StoreState } from "../../redux/reducers";
import { Manager, Trainer, UserType } from "../../types/gqlTypes";

interface Props {
  getCoachProfile: (trainerId: string) => void;
}

const AdminTeamScreen = (props: Props) => {
  const { getCoachProfile } = props;

  const [createMode, setCreateMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editProfile, setEditProfile] = useState(null);
  const [viewMode, setViewMode] = useState(false);
  const [viewUser, setViewUser] = useState(null);

  const startViewingUser = (user: Manager & Trainer) => {
    if (!editProfile && !editMode && !createMode && user === viewUser) {
      setViewUser(null);
      setViewMode(false);
      setEditProfile(null);
    } else if (!editProfile && !editMode && !createMode) {
      setViewUser(user);
      setViewMode(true);
      setEditMode(false);
      setCreateMode(false);
      setEditProfile(null);
    }
  };
  const startEditingUser = (user: Manager & Trainer) => {
    setViewUser(user);
    setEditMode(true);
    setViewMode(false);
    setCreateMode(false);
    setEditProfile(null);
  };

  const closeForm = () => {
    setViewUser(null);
    setCreateMode(false);
    setViewMode(false);
    setEditMode(false);
  };

  const onEditProfile = (user: Manager & Trainer) => {
    if (user?.type === UserType.Trainer) {
      getCoachProfile(user?.id);
      setEditProfile(user?.id);
      setEditMode(false);
      setViewMode(false);
      setCreateMode(false);
      setViewUser(null);
    }
  };

  return (
    <div
      className="d-flex"
      style={{
        flex: 1,
      }}
    >
      <div
        className="d-flex"
        style={{
          flex: "1 1 60%",
        }}
      >
        <TeamList
          selectedUserId={viewUser?.id || editProfile}
          createEditMode={createMode || editMode}
          startViewingUser={startViewingUser}
          startEditingUser={startEditingUser}
          onEditProfile={onEditProfile}
          closeForm={closeForm}
          createNewUser={() => {
            closeForm();
            setCreateMode(true);
          }}
        />
      </div>
      {(createMode || editMode || viewMode) && (
        <div
          style={{
            flex: "1 1 40%",
          }}
        >
          <TeamMemberForm
            userToEdit={viewUser}
            viewMode={viewMode}
            editMode={editMode}
            closeForm={closeForm}
          />
        </div>
      )}

      {editProfile && (
        <CoachProfileComponent
          editable
          trainerId={editProfile}
          onClose={() => setEditProfile(false)}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getCoachProfile: (trainerId: string) => {
    dispatch(getCoachProfile(trainerId));
  },
});

const mapStateToProps = (state: StoreState) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AdminTeamScreen);
