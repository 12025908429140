import React from "react";
import colors from "../../assets/colors";

interface Props {
  label: string;
  onTimeChange: (time: number) => void;
  currentTime?: number;
}

const ChapterTimeInput = (props: Props) => {
  const { label, onTimeChange, currentTime } = props;

  return (
    <div
      className="d-flex paragraph-small flex-column"
      style={{
        marginRight: 16,
        marginBottom: 20,
      }}
    >
      {label}
      <input
        className="bordered-item paragraph-normal"
        style={{
          border: `1px solid ${colors.caliber_gray_5}`,
          backgroundColor: colors.caliber_gray_5,
          borderRadius: 8,
          height: 48,
          width: 125,
          marginTop: 12,
          marginBottom: 8,
          paddingLeft: 20,
          paddingRight: 20,
        }}
        value={currentTime}
        type="number"
        placeholder="0"
        onChange={(event) => {
          onTimeChange(Number.parseInt(event.target.value, 10));
        }}
      />
    </div>
  );
};

export default ChapterTimeInput;
