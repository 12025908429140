import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { onStopCreateCustomPlanInProgramLibrary } from "../../redux/actions/TrainingPrograms";
import { createTrainingPlan } from "../../redux/actions/TrainingPrograms/createAssign";
import {
  getTrainerId,
  getTrainerProGroups,
  StoreState,
} from "../../redux/reducers";
import {
  ClientTrainingPlan,
  MutationCreateCustomTrainingPlanArgs,
  MutationCreateLibraryTrainingPlanArgs,
  ProGroup,
} from "../../types/gqlTypes";
import { useKeyPress } from "../../utils/customHooks";
import { ProgramType } from "../TrainingProgram";

interface Props {
  isLoading: boolean;
  plan: ClientTrainingPlan;
  nameExists: boolean;
  showCreateCustomPlan: boolean;
  trainerId: string;
  trainerProGroups: ProGroup[];
  onCancel: () => void;
  createCustomTrainingPlan: (
    args: MutationCreateCustomTrainingPlanArgs
  ) => void;
  createLibraryTrainingPlan: (
    args: MutationCreateLibraryTrainingPlanArgs
  ) => void;
}

const NewPlanHeader = (props: Props) => {
  const {
    isLoading,
    nameExists,
    trainerId,
    trainerProGroups,
    onCancel,
    createCustomTrainingPlan,
    createLibraryTrainingPlan,
  } = props;

  const [name, setName] = useState("");
  const [selectedProGroupId, setSelectedProGroupId] = useState("");
  const [didChangeName, setDidChangeName] = useState(true);
  const canBeSaved =
    (!nameExists || didChangeName) && name.length > 0 && !isLoading;
  const isProGroupPlan =
    window.location.href.indexOf("proworkoutgroups") !== -1;
  const isWorkoutLibraryPlan =
    window.location.href.indexOf("workoutlibrary") !== -1;

  useEffect(() => {
    if (nameExists) {
      setDidChangeName(!nameExists);
    }
  }, [nameExists]);

  useEffect(() => {
    if (isProGroupPlan && trainerProGroups.length) {
      setSelectedProGroupId(trainerProGroups[0].id);
    }
  }, []);

  useKeyPress("Escape", () => {
    onCancel();
  });

  const onSave = () => {
    if (name.length > 0 && canBeSaved) {
      if (!isWorkoutLibraryPlan) {
        const args: MutationCreateCustomTrainingPlanArgs = {
          title: name,
        };
        if (isProGroupPlan) {
          args.proGroupId = selectedProGroupId;
        } else {
          args.trainerId = trainerId;
        }
        createCustomTrainingPlan(args);
      } else {
        const args: MutationCreateLibraryTrainingPlanArgs = {
          name: name,
          trainerId: trainerId,
        };
        createLibraryTrainingPlan(args);
      }
    }
  };

  const fieldStyle: React.CSSProperties = {
    minHeight: 130,
    borderRadius: 8,
    backgroundColor: "#E6E6EB",
  };

  return (
    <div>
      <div style={fieldStyle} className="d-flex flex-column p-4">
        <Form noValidate>
          <Form.Group className="mb-3" controlId="formWorkoutGroupName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter workout group name"
              value={name}
              onChange={(event) => {
                setName(event.target.value);
                if (nameExists) {
                  setDidChangeName(true);
                }
              }}
              isInvalid={nameExists}
            />
            <Form.Control.Feedback type="invalid">
              Name of plan already exists. Please choose a different name.
            </Form.Control.Feedback>
          </Form.Group>
          {isProGroupPlan && (
            <Form.Group className="mb-3" controlId="formWorkoutGroupProGroup">
              <Form.Label>Pro Group</Form.Label>
              <Form.Control
                as="select"
                onChange={(event) => {
                  // @ts-ignore (doesn't recognize select type)
                  setSelectedProGroupId(event.target.value);
                }}
              >
                {trainerProGroups.map(({ id, name }, index) => {
                  return <option value={id}>{name}</option>;
                })}
              </Form.Control>
            </Form.Group>
          )}
          <Button
            className="float-right"
            variant="primary"
            disabled={!canBeSaved}
            onClick={onSave}
          >
            Create workout group
          </Button>
        </Form>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onCancel: () => {
    dispatch(onStopCreateCustomPlanInProgramLibrary());
  },
  createCustomTrainingPlan: (args: MutationCreateCustomTrainingPlanArgs) => {
    dispatch(createTrainingPlan(args, ProgramType.Custom));
  },
  createLibraryTrainingPlan: (args: MutationCreateLibraryTrainingPlanArgs) => {
    dispatch(createTrainingPlan(args, ProgramType.Library));
  },
});

const mapStateToProps = (state: StoreState) => ({
  plan: state.trainingProgramsState.copyPlanToTrainer,
  nameExists: state.trainingProgramsState.failedToCreateCustomPlan,
  isLoading: state.trainingProgramsState.isCopying,
  showCreateCustomPlan: state.trainingProgramsState.showCreateCustomPlan,
  trainerId: getTrainerId(state),
  trainerProGroups: getTrainerProGroups(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewPlanHeader);
