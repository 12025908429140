import React, { useState } from "react";
import { connect } from "react-redux";
import { formatDistanceToNow } from "date-fns";
import ReactTooltip from "react-tooltip";
import colors from "../../assets/colors";
import CaretDownGray from "../../assets/images/CaretDownGrayIcon.svg";
import CaretDownGreen from "../../assets/images/CaretDownGreenIcon.svg";
import { MasqueradeState } from "../../redux/actions/Masquerade/types";
import {
  selectClientTrainingPlan,
  toggleEditTrainingPlan,
} from "../../redux/actions/TrainingPrograms";
import { toggleCopyTrainingPlanToClients } from "../../redux/actions/TrainingPrograms/copy";
import { deleteTrainingPlan } from "../../redux/actions/TrainingPrograms/delete";
import { UserState } from "../../redux/actions/User/types";
import { getCanEditClientInfo, StoreState } from "../../redux/reducers";
import {
  ClientTrainingPlan,
  MutationCreateCustomTrainingPlanArgs,
  MutationCreateMasterTrainingPlanArgs,
} from "../../types/gqlTypes";
import ConfirmationDialog from "../ConfirmationDialog";
import MeatballsMenu from "../MeatballsMenu";
import Popup from "../Popover";
import SimpleButton from "../SimpleButton";
import { ProgramType } from "../TrainingProgram";
import { getTrainingPlanLastDateUpdated } from "../../redux/actions/TrainingPrograms/get";

interface OwnProps {
  plan: ClientTrainingPlan;
  index: number;
  isSelected: boolean;
  togglePlanActivation: () => void;
  clientId: string;
}

interface Props extends OwnProps {
  masqueradeState: MasqueradeState;
  userState: UserState;
  canEditClientInfo: boolean;
  deleteTrainingPlan: (args: { id: string; programType: ProgramType }) => void;
  toggleEditTrainingPlan: (index: number) => void;
  selectPlan: (index: number) => void;
  lastUpdatedPlanDateById: Map<string, Date>;
  getTrainingPlanLastDateUpdated: (clientId: string, planId: string) => void;
  createCustomTrainingPlan: (
    args:
      | MutationCreateCustomTrainingPlanArgs
      | MutationCreateMasterTrainingPlanArgs
  ) => void;
  toggleCopyTrainingPlanToClients: (index: number) => void;
}

const ClientTrainingPlanItem = (props: Props) => {
  const {
    deleteTrainingPlan,
    toggleEditTrainingPlan,
    selectPlan,
    lastUpdatedPlanDateById,
    getTrainingPlanLastDateUpdated,
    togglePlanActivation,
    toggleCopyTrainingPlanToClients,
    canEditClientInfo,
    index,
    isSelected,
    plan: { active, name, id },
    clientId,
  } = props;

  const [confirmDialog, setConfirmDialog] = useState(false);

  const style = {
    padding: "2px 6px",
    cursor: "pointer",
    borderRadius: 4,
    height: 24,
    width: 80,
    color: colors.caliber_gray_text,
    backgroundColor: "#DDDEE4",
  };
  if (active) {
    style.color = colors.caliber_green_200;
    style.backgroundColor = "#D9EFD5";
  }

  const [showMeatball, setShowMeatball] = useState(false);

  let lastUpdatedText = "";

  if (lastUpdatedPlanDateById.get(id)) {
    lastUpdatedText = `${formatDistanceToNow(
      lastUpdatedPlanDateById.get(id)
    )} ago`;
  }

  const trigger = (
    <div
      role="button"
      className="d-flex align-items-center justify-content-center nofocus"
      tabIndex={0}
      style={style}
    >
      <div
        style={{
          margin: 0,
        }}
      >
        {active ? "Active" : "Inactive"}
      </div>
      <img
        alt="v"
        style={{
          height: 4,
          margin: "8px 2px",
        }}
        src={active ? CaretDownGreen : CaretDownGray}
      />
    </div>
  );
  const meatballsMenuItems = [
    { text: "Edit", onClick: () => toggleEditTrainingPlan(index) },
  ];
  if (canEditClientInfo) {
    meatballsMenuItems.push({
      text: "Copy to client",
      onClick: () => toggleCopyTrainingPlanToClients(index),
    });
  }
  meatballsMenuItems.push({
    text: "Delete",
    onClick: () => setConfirmDialog(true),
  });

  const toolTipId = Math.random();
  return (
    <div>
      <div data-for={`getContent_${toolTipId}`} data-tip>
        <SimpleButton
          onClick={() => selectPlan(index)}
          className="d-flex nofocus"
          style={{
            minHeight: 80,
            padding: 20,
            paddingTop: 33,
            marginTop: 12,
            borderRadius: 8,
            position: "relative",
            backgroundColor: isSelected ? colors.caliber_white : "#E6E7EC",
          }}
          onMouseEnter={() => {
            setShowMeatball(true);
            getTrainingPlanLastDateUpdated(clientId, id);
          }}
          onMouseLeave={() => {
            setShowMeatball(false);
          }}
        >
          {confirmDialog && (
            <ConfirmationDialog
              show={confirmDialog}
              cancelButtonText="Cancel"
              text={`Do you really want to delete ${name}?`}
              onCancel={() => setConfirmDialog(false)}
              onConfirm={() => {
                setConfirmDialog(false);
                deleteTrainingPlan({
                  id,
                  programType: ProgramType.Client,
                });
              }}
            />
          )}
          <div>
            <div className="d-flex">
              {showMeatball && (
                <div
                  style={{
                    position: "absolute",
                    right: 9,
                    top: 5,
                  }}
                >
                  <MeatballsMenu
                    items={meatballsMenuItems.filter(
                      (item) => !(active && item.text === "Delete")
                    )}
                  />
                </div>
              )}
              <div
                className={`d-flex align-items-center ${
                  active ? "bold" : "medium-bold"
                }`}
                style={{
                  flex: 1,
                  fontSize: "16px",
                  lineHeight: "16px",
                  marginRight: 8,
                  color: isSelected ? colors.caliber_secondary : "#797B92",
                }}
              >
                {name}
              </div>
              <div className="d-flex paragraph-normal align-items-center">
                <Popup
                  popupkey={`popup-client-plan-item-${id}`}
                  placement="bottom"
                  trigger={trigger}
                >
                  <div
                    role="button"
                    className="popover-item"
                    tabIndex={0}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        togglePlanActivation();
                      }
                    }}
                    onClick={togglePlanActivation}
                  >
                    {active ? "Deactivate" : "Activate"}
                  </div>
                </Popup>
              </div>
            </div>
          </div>
        </SimpleButton>
      </div>
      <ReactTooltip
        id={`getContent_${toolTipId}`}
        getContent={[
          () => {
            return `Last Updated: ${lastUpdatedText}`;
          },
          1000,
        ]}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  toggleCopyTrainingPlanToClients: (index: number) => {
    dispatch(toggleCopyTrainingPlanToClients(index));
  },
  deleteTrainingPlan: (args: { id: string; programType?: ProgramType }) => {
    dispatch(deleteTrainingPlan(args));
  },
  toggleEditTrainingPlan: (index: number) => {
    dispatch(toggleEditTrainingPlan(index));
  },
  selectPlan: (index: number) => {
    dispatch(selectClientTrainingPlan(index));
  },
  getTrainingPlanLastDateUpdated: (clientId: string, planId: string) => {
    dispatch(getTrainingPlanLastDateUpdated(clientId, planId));
  },
});

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  plan: ownProps.plan,
  index: ownProps.index,
  isSelected: ownProps.isSelected,
  togglePlanActivation: ownProps.togglePlanActivation,
  canEditClientInfo: getCanEditClientInfo(state),
  lastUpdatedPlanDateById: state.trainingProgramsState.lastUpdatedPlanDateById,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientTrainingPlanItem);
