import React from "react";
import colors from "../../assets/colors";
import { HabitType } from "../../types/gqlTypes";
import CloseIcon from "../Icons/CloseIcons";
import Modal from "../Modal";
import StepsHabitCard from "../ScheduleEventModal/HabitEvent/StepsHabitCard";
import SimpleButton from "../SimpleButton";

interface Props {
  onClick: (habitType: HabitType) => void;
  onClose: () => void;
}

const AddNewHabit = (props: Props) => {
  const { onClick, onClose } = props;

  return (
    <Modal onClose={onClose} closeOnDimmerClick>
      <div
        className="d-flex flex-column"
        style={{
          width: 560,
          height: 340,
          padding: "26px 26px 48px 32px",
        }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div
            className="bold"
            style={{
              fontSize: "24px",
              lineHeight: "24px",
              letterSpacing: "-0.033em",
              color: colors.black,
            }}
          >
            Add new habit
          </div>
          <SimpleButton nofocus onClick={onClose}>
            <CloseIcon width={27} height={27} />
          </SimpleButton>
        </div>

        <div
          style={{
            fontSize: "14px",
            lineHeight: "18px",
            letterSpacing: "-0.022em",
            color: colors.caliber_gray_text,
            marginTop: 60,
            marginBottom: 32,
            width: 397,
          }}
        >
          Choose a habit to assign to your client. This will help your client
          develop healthy habits outside of what you’re doing with them in their
          workout and nutrition plan.
        </div>

        <div className="d-flex flex-column">
          <StepsHabitCard
            isClickable
            onClick={() => onClick(HabitType.Steps)}
          />
        </div>
      </div>
    </Modal>
  );
};
export default AddNewHabit;
