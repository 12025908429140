import {
  CaliberPageImpl_ClientSearchResult,
  Client,
  ClientPackageType,
  ClientSearchResult,
  ClientSearchResultSortCriteria,
  SortOrder,
  User,
  UserStatusType,
} from "../../../types/gqlTypes";

export const SEARCH_CLIENTS_TAB_SUCCESS = "SEARCH_CLIENTS_TAB_SUCCESS";
export const SEARCH_CLIENTS_TAB_FAIL = "SEARCH_CLIENTS_TAB_FAIL";
export const SEARCH_CLIENTS_TAB_LOADING = "SEARCH_CLIENTS_TAB_LOADING";

export const FETCH_CLIENT_PROFILE_FOR_CLIENTS_TAB_SUCCESS =
  "FETCH_CLIENT_PROFILE_FOR_CLIENTS_TAB_SUCCESS";
export const FETCH_CLIENT_PROFILE_FOR_CLIENTS_TAB_FAIL =
  "FETCH_CLIENT_PROFILE_FOR_CLIENTS_TAB_FAIL";
export const FETCH_CLIENT_PROFILE_FOR_CLIENTS_TAB_LOADING =
  "FETCH_CLIENT_PROFILE_FOR_CLIENTS_TAB_LOADING";
export const RESET_FETCHED_CLIENT_PROFILE_FOR_CLIENTS_TAB =
  "RESET_FETCHED_CLIENT_PROFILE_FOR_CLIENTS_TAB";

export const UPDATE_CLIENT_PROFILE_CLIENTS_TAB_SUCCESS =
  "UPDATE_CLIENT_PROFILE_CLIENTS_TAB_SUCCESS";
export const UPDATE_CLIENT_PROFILE_CLIENTS_TAB_FAIL =
  "UPDATE_CLIENT_PROFILE_CLIENTS_TAB_FAIL";
export const UPDATE_CLIENT_PROFILE_CLIENTS_TAB_SAVING =
  "UPDATE_CLIENT_PROFILE_CLIENTS_TAB_SAVING";

export const START_DRAGGING_COLUMN_IN_CLIENTS_TAB =
  "START_DRAGGING_COLUMN_IN_CLIENTS_TAB";
export const STOP_DRAGGING_COLUMN_IN_CLIENTS_TAB =
  "STOP_DRAGGING_COLUMN_IN_CLIENTS_TAB";

export const CHANGE_COLUMN_WIDTH_IN_CLIENTS_TAB =
  "CHANGE_COLUMN_WIDTH_IN_CLIENTS_TAB";

export const OPEN_COLUMN_FILTER_IN_CLIENTS_TAB =
  "OPEN_COLUMN_FILTER_IN_CLIENTS_TAB";
export const CLOSE_COLUMN_FILTER_IN_CLIENTS_TAB =
  "CLOSE_COLUMN_FILTER_IN_CLIENTS_TAB";

export const OPEN_SETTINGS_IN_CLIENTS_TAB = "OPEN_SETTINGS_IN_CLIENTS_TAB";
export const CLOSE_SETTINGS_IN_CLIENTS_TAB = "CLOSE_SETTINGS_IN_CLIENTS_TAB";

export const CHANGE_COLUMNS_IN_CLIENTS_TAB = "CHANGE_COLUMNS_IN_CLIENTS_TAB";
export const CHANGE_COLUMNS_ON_REORDER_IN_CLIENTS_TAB =
  "CHANGE_COLUMNS_ON_REORDER_IN_CLIENTS_TAB";

export const CHANGE_PAGE_IN_CLIENTS_TAB = "CHANGE_PAGE_IN_CLIENTS_TAB";
export const CHANGE_PAGE_SIZE_IN_CLIENTS_TAB =
  "CHANGE_PAGE_SIZE_IN_CLIENTS_TAB";

export const SELECT_CLIENT_IN_CLIENTS_TAB = "CHANGE_PAGE_SIZE_IN_CLIENTS_TAB";

export const CHANGE_SORTING_IN_CLIENTS_TAB = "CHANGE_SORTING_IN_CLIENTS_TAB";
export const RESET_SORTING_IN_CLIENTS_TAB = "RESET_SORTING_IN_CLIENTS_TAB";

export const RESET_COLUMNS_VIEW_IN_CLIENTS_TAB =
  "RESET_COLUMNS_VIEW_IN_CLIENTS_TAB";

export const ON_HOVER_ENTER_RESIZING_IN_CLIENTS_TAB =
  "ON_HOVER_ENTER_RESIZING_IN_CLIENTS_TAB";
export const ON_HOVER_LEAVE_RESIZING_IN_CLIENTS_TAB =
  "ON_HOVER_LEAVE_RESIZING_IN_CLIENTS_TAB";

export const OPEN_NEW_CLIENT_IN_CLIENTS_TAB = "OPEN_NEW_CLIENT_IN_CLIENTS_TAB";
export const OPEN_EDIT_CLIENT_IN_CLIENTS_TAB =
  "OPEN_EDIT_CLIENT_IN_CLIENTS_TAB";
export const CLOSE_NEW_CLIENT_IN_CLIENTS_TAB =
  "CLOSE_NEW_CLIENT_IN_CLIENTS_TAB";

export const GET_TABLE_SETTINGS_FOR_CLIENTS_TAB =
  "GET_TABLE_SETTINGS_FOR_CLIENTS_TAB";

export const TURN_ON_EDIT_CLIENT_MODE_IN_CLIENTS_TAB =
  "TURN_ON_EDIT_CLIENT_MODE_IN_CLIENTS_TAB";

export const SET_CLIENTS_TAB_TRAINER_FILTER = "SET_CLIENTS_TAB_TRAINER_FILTER";

export const CLIENTS_TAB_PREFS_SAVING = "CLIENTS_TAB_PREFS_SAVING";
export const CLIENTS_TAB_PREFS_SAVING_FAIL = "CLIENTS_TAB_PREFS_SAVING_FAIL";
export const CLIENTS_TAB_PREFS_SAVING_SUCCESS =
  "CLIENTS_TAB_PREFS_SAVING_SUCCESS";

export interface ClientsTabState {
  isLoading: boolean;
  pageNumber: number;
  searchCriteria: string;
  statusCriteria?: UserStatusType[];
  trainerFilter: string | User;
  clientPackageTypeCriteria?: ClientPackageType[];
  trainerIdFilter?: string;
  managerIdFilter?: string;
  sortOrder?: SortOrder;
  sortField?: ClientSearchResultSortCriteria;
  numOfRows?: number;
  page?: number;
  totalPages?: number;
  pageSize?: number;
  totalElements?: number;
  isSettingsOpen?: boolean;
  isFilterOpen?: boolean;
  columns?: ClientsTabColumnsType[];
  widths?: number[];
  isDragging?: boolean;
  content?: ClientSearchResult[];
  whichColumnFilterOpen?: "status" | "packageType";
  whichColumnDragging?: ClientSearchResultSortCriteria;
  whichRowSelected?: number;
  isSortingApplied?: boolean;
  isResizingHovered?: boolean;
  isNewClientDetailsOpen?: boolean;
  isEditClientDetailsOpen?: boolean;
  clientIdToEdit?: boolean;
  fetchedClient?: Client;
  fetchedClientPackageType?: ClientPackageType;
  fetchedClientWeight?: Number;
  isEditClientOn?: boolean;
}

export interface ClientsTabAction {
  statusCriteria?: UserStatusType[];
  clientPackageTypeCriteria?: ClientPackageType[];
  type: string;
  result?: CaliberPageImpl_ClientSearchResult;
  index?: number;
  pageNumber?: number;
  pageSize?: number;
  totalElements?: number;
  widths?: number[];
  columns?: ClientsTabColumnsType[];
  column?: "status" | "packageType";
  sortOrder: SortOrder;
  sortField: ClientSearchResultSortCriteria;
  fetchedUser?: User;
  trainer?: User;
  trainerFilter?: string | User;
  user?: User;
  clientId?: string;
  clientPackageType?: ClientPackageType;
  weight?: number;
  args?: {
    pageNumber: number;
    pageSize: number;
    searchCriteria: string;
    sortOrder?: SortOrder;
    sortField?: ClientSearchResultSortCriteria;
    statusCriteria?: UserStatusType[];
    clientPackageTypeCriteria?: ClientPackageType[];
    trainerIdFilter?: string;
    managerIdFilter?: string;
  };
  settings?: {
    columns: ClientsTabColumnsType[];
    widths: number[];
    statusCriteria: UserStatusType[];
    clientPackageTypeCriteria: ClientPackageType[];
  };
}

export enum ClientsTabColumnsType {
  age = "age",
  armsMuscleGroupScore = "armsMuscleGroupScore",
  armsMuscleGroupScoreDelta = "armsMuscleGroupScoreDelta",
  backMuscleGroupScore = "backMuscleGroupScore",
  backMuscleGroupScoreDelta = "backMuscleGroupScoreDelta",
  birthDate = "birthDate",
  callsLeft = "callsLeft",
  calories7DayAverage = "calories7DayAverage",
  caloriesTarget = "caloriesTarget",
  carbs7DayAverage = "carbs7DayAverage",
  carbsTarget = "carbsTarget",
  chestMuscleGroupScore = "chestMuscleGroupScore",
  chestMuscleGroupScoreDelta = "chestMuscleGroupScoreDelta",
  clientPackageType = "clientPackageType",
  email = "email",
  fat7DayAverage = "fat7DayAverage",
  fatTarget = "fatTarget",
  firstName = "firstName",
  fullName = "fullName",
  gender = "gender",
  height = "height",
  id = "id",
  joinedDate = "joinedDate",
  lastActiveDate = "lastActiveDate",
  lastDateClientReceivedMessage = "lastDateClientReceivedMessage",
  lastDateClientSentMessage = "lastDateClientSentMessage",
  lastName = "lastName",
  legsMuscleGroupScore = "legsMuscleGroupScore",
  legsMuscleGroupScoreDelta = "legsMuscleGroupScoreDelta",
  managerId = "managerId",
  mostRecentlyCompletedWorkoutDate = "mostRecentlyCompletedWorkoutDate",
  packageEndDate = "packageEndDate",
  profileMediaIconUrl = "profileMediaIconUrl",
  protein7DayAverage = "protein7DayAverage",
  proteinTarget = "proteinTarget",
  shouldersMuscleGroupScore = "shouldersMuscleGroupScore",
  shouldersMuscleGroupScoreDelta = "shouldersMuscleGroupScoreDelta",
  status = "status",
  strengthBalance = "strengthBalance",
  strengthBalanceDelta = "strengthBalanceDelta",
  strengthScore = "strengthScore",
  strengthScoreDelta = "strengthScoreDelta",
  trainerFirstName = "trainerFirstName",
  trainerFullName = "trainerFullName",
  trainerId = "trainerId",
  trainerLastName = "trainerLastName",
  weight = "weight",
}
