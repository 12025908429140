import React from "react";
import colors from "../../assets/colors";

interface Props {
  radius: number;
  thickness: number;
  color: string;
}

const EmptyCircle = (props: Props) => {
  const { radius, thickness, color } = props;

  const innerRadius = radius - 2 * thickness;
  return (
    <div
      style={{
        position: "relative",
        width: radius,
        height: radius,
      }}
    >
      <div
        style={{
          position: "absolute",
          width: radius,
          height: radius,
          borderRadius: radius / 2,
          backgroundColor: color,
          zIndex: 1,
        }}
      >
        {/* outer circle */}
      </div>
      <div
        style={{
          position: "absolute",
          width: innerRadius,
          height: innerRadius,
          borderRadius: innerRadius / 2,
          margin: thickness,
          backgroundColor: colors.caliber_white,
          zIndex: 2,
        }}
      >
        {/* inner circle */}
      </div>
    </div>
  );
};

export default EmptyCircle;
