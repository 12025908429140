import React from "react";
import { connect } from "react-redux";
import colors from "../../../assets/colors";
import { resetColumnsViewInClientsTab } from "../../../redux/actions/ClientsTab";
import { StoreState } from "../../../redux/reducers";
import SimpleButton from "../../SimpleButton";

interface Props {
  resetColumnsViewInClientsTab: () => void;
}

const DebugClientsTab = (props: Props) => {
  const { resetColumnsViewInClientsTab } = props;

  return (
    <div>
      <SimpleButton
        onClick={resetColumnsViewInClientsTab}
        nofocus
        className="d-flex flex-column justify-content-center align-items-center medium-bold"
        style={{
          backgroundColor: colors.caliber_green_200,
          borderRadius: 8,
          width: 300,
          height: 50,
          color: colors.caliber_white,
          padding: "5px 12px",
          marginTop: 20,
          fontSize: "18px",
          lineHeight: "18px",
        }}
      >
        Reset User table settings
      </SimpleButton>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  resetColumnsViewInClientsTab: () => {
    dispatch(resetColumnsViewInClientsTab());
  },
});

const mapStateToProps = (state: StoreState) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DebugClientsTab);
