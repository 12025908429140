import React, { useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { matchPath, NavLink, useLocation } from "react-router-dom";
import colors from "../../assets/colors";
import "./index.css";
import UnreadMessageCount from "./UnreadMessageCount";

export const NAV_WIDTH = 240;
export const NAV_COLLAPSED_WIDTH = 80;

interface Props {
  title: string;
  activeIcon: string;
  inactiveIcon: string;
  to: string;
  paths: string | string[];
  iconOnly: boolean;
  unreadMessageCount?: number;
}

const SideNavItem = (props: Props) => {
  const { title, to, activeIcon, inactiveIcon, paths, unreadMessageCount } =
    props;

  const [hovered, setHovered] = useState(false);
  const location = useLocation();

  const match = matchPath(`/${location.pathname.split("/")[1]}`, {
    path: paths,
    exact: true,
  });
  const isActive = match && match.isExact;
  const icon = isActive || hovered ? activeIcon : inactiveIcon;
  const backgroundColor =
    isActive || hovered ? "#F2F3F5" : colors.caliber_white;

  return (
    <OverlayTrigger
      placement="right"
      overlay={
        hovered ? (
          <Popover
            id=""
            style={{
              border: "none",
              boxShadow: "0px 2px 16px #808297",
            }}
          >
            <div
              className="bold"
              style={{
                padding: "3px 12px",
                fontSize: "12px",
                lineHeight: "24px",
                color: colors.caliber_secondary,
              }}
            >
              {title}
            </div>
          </Popover>
        ) : (
          <div />
        )
      }
    >
      <div
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        className="d-flex"
        style={{
          height: 48,
          width: NAV_COLLAPSED_WIDTH,
          backgroundColor,
          position: "relative",
        }}
      >
        <NavLink className="d-flex flex-fill" to={to}>
          <div
            className="d-flex"
            style={{
              height: 48,
              paddingLeft: 26,
              alignItems: "center",
              borderRadius: 4,
            }}
          >
            <img src={icon} alt="icon" />

            {title === "Messages" && (
              <UnreadMessageCount count={unreadMessageCount} />
            )}
          </div>
        </NavLink>
        <div
          style={{
            backgroundColor: isActive
              ? colors.caliber_secondary
              : colors.wire_line_grey,
            width: isActive ? 4 : 2,
            height: 48,
            position: "absolute",
            right: 0,
          }}
        />
      </div>
    </OverlayTrigger>
  );
};

export default SideNavItem;
