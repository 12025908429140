import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import colors from "../../assets/colors";
import {
  adminRegenerateStrengthScores,
  getStrengthScores,
} from "../../redux/actions/ClientStrengthDashboard";
import { StoreState } from "../../redux/reducers";
import {
  StrengthScore,
  StrengthScoreDashboard,
  StrengthScoreMuscleGroupType,
  User,
} from "../../types/gqlTypes";
import SimpleButton from "../SimpleButton";
import StrengthSkeleton from "../StrengthDashboard/StrengthSkeleton";
import FloatingBox from "./FloatingBox";

interface Props {
  getStrengthScores: (clientId: string) => void;
  regenerateStrengthScore: (clientId: string, weeksAgo: number) => void;
  client: User;
  strengthScoreDashboard: StrengthScoreDashboard;
  strengthScoreDashboardChanges: StrengthScoreDashboard[];
}

const DebugStrengthMetrics = (props: Props) => {
  const {
    getStrengthScores,
    regenerateStrengthScore,
    client,
    strengthScoreDashboard,
    strengthScoreDashboardChanges,
  } = props;
  const { id: clientId } = useParams();
  const defaultColor = "#EDEDED";
  const [openThisWeekModal, setOpenThisWeekModal] = useState(false);
  const [openLastWeekModal, setOpenLastWeekModal] = useState(false);

  const [data, setData] = useState(null);
  const [zIndex1, setZIndex1] = useState(9998);
  const [zIndex2, setZIndex2] = useState(9998);

  useEffect(() => {
    if (clientId) {
      getStrengthScores(clientId);
    }
  }, [clientId]);

  // Standard Deviation
  const SD_contributions = {
    [`${StrengthScoreMuscleGroupType.Legs}`]: 0.35,
    [`${StrengthScoreMuscleGroupType.Chest}`]: 0.15,
    [`${StrengthScoreMuscleGroupType.Back}`]: 0.25,
    [`${StrengthScoreMuscleGroupType.Shoulders}`]: 0.1,
    [`${StrengthScoreMuscleGroupType.Arms}`]: 0.15,
  };
  const getColor = (
    muscleGroup: StrengthScore,
    overallStrengthScore: StrengthScore
  ) => {
    const SD =
      (muscleGroup?.score * SD_contributions[muscleGroup?.type] || 0) /
      (overallStrengthScore?.score || 1);
    if (SD > 1 && muscleGroup?.score > overallStrengthScore?.score) {
      return "#272E5F";
    }
    if (SD > 0 && SD <= 1 && muscleGroup?.score > overallStrengthScore?.score) {
      return "#52587F";
    }
    if (SD > 0 && SD <= 1 && muscleGroup?.score < overallStrengthScore?.score) {
      return "#A9ABBF";
    }
    if (SD > 1 && muscleGroup?.score < overallStrengthScore?.score) {
      return "#D4D5DF";
    }
    if (SD === 0) {
      return "#52587F";
    }
    return defaultColor;
  };

  useEffect(() => {
    const data: any = {};
    data["thisWeek-text-explanation"] =
      strengthScoreDashboardChanges?.[0].explanationText;
    data["thisWeek-overallStrengthScore"] =
      strengthScoreDashboardChanges?.[0].overallStrengthScore.score;
    data["thisWeek-overallStrengthScore-delta"] =
      strengthScoreDashboardChanges?.[0].overallStrengthScore.strengthScoreDelta;
    data["thisWeek-ageDiscountChanged"] =
      strengthScoreDashboardChanges?.[0].ageDiscountChanged;

    strengthScoreDashboardChanges?.[0].muscleGroupScores.map(
      (muscleGroup: StrengthScore) => {
        data[`thisWeek-${muscleGroup.type}`] = muscleGroup?.score;
        data[`thisWeek-${muscleGroup.type}-delta`] =
          muscleGroup?.strengthScoreDelta;

        data[`thisWeek-${muscleGroup.type}-KE`] =
          muscleGroup?.exerciseLight?.name;
        data[`thisWeek-${muscleGroup.type}-ORM`] = muscleGroup?.orm;
        data[`thisWeek-${muscleGroup.type}-ORM-delta`] = muscleGroup?.ormDelta;

        data[`thisWeek-${muscleGroup.type}-SD`] =
          Math.round(
            ((muscleGroup?.score * SD_contributions[muscleGroup?.type] || 0) /
              (data["thisWeek-overallStrengthScore"] || 1)) *
              100
          ) / 100;

        data[`thisWeek-${muscleGroup.type}-textColor`] = getColor(
          muscleGroup,
          strengthScoreDashboardChanges?.[0].overallStrengthScore
        );
        data[`thisWeek-${muscleGroup.type}-color`] = getColor(
          muscleGroup,
          strengthScoreDashboardChanges?.[0].overallStrengthScore
        );

        data[`thisWeek-${muscleGroup.type}-compare`] =
          muscleGroup?.score > data["thisWeek-overallStrengthScore"]
            ? "GREATER"
            : "LESSER";
        data[`thisWeek-${muscleGroup.type}-compare`] =
          muscleGroup?.score === data["thisWeek-overallStrengthScore"]
            ? "EQUAL"
            : data[`thisWeek-${muscleGroup.type}-compare`];
      }
    );

    if (strengthScoreDashboardChanges?.[1]) {
      data["lastWeek-text-explanation"] =
        strengthScoreDashboardChanges?.[1].explanationText;
      data["lastWeek-overallStrengthScore"] =
        strengthScoreDashboardChanges?.[1].overallStrengthScore.score;
      data["lastWeek-overallStrengthScore-delta"] =
        strengthScoreDashboardChanges?.[1].overallStrengthScore.strengthScoreDelta;
      data["lastWeek-ageDiscountChanged"] =
        strengthScoreDashboardChanges?.[1].ageDiscountChanged;

      strengthScoreDashboardChanges?.[1].muscleGroupScores.map(
        (muscleGroup: StrengthScore) => {
          data[`lastWeek-${muscleGroup.type}`] = muscleGroup?.score;
          data[`lastWeek-${muscleGroup.type}-delta`] =
            muscleGroup?.strengthScoreDelta;

          data[`lastWeek-${muscleGroup.type}-KE`] =
            muscleGroup?.exerciseLight?.name;
          data[`lastWeek-${muscleGroup.type}-ORM`] = muscleGroup?.orm;
          data[`lastWeek-${muscleGroup.type}-ORM-delta`] =
            muscleGroup?.ormDelta;
        }
      );
    }
    setData(data);
  }, [strengthScoreDashboardChanges]);

  const calculateThisWeek = () => {
    regenerateStrengthScore(clientId, 1);
  };
  const calculateLastWeek = () => {
    regenerateStrengthScore(clientId, 2);
  };

  const firstTableColumnsTitles = [
    { colTitle: "Last Week", colValue: "lastWeek", type: "" },
    { colTitle: "Last Week' Delta", colValue: "lastWeek", type: "-delta" },
    { colTitle: "This Week", colValue: "thisWeek", type: "" },
    { colTitle: "This Week' Delta", colValue: "thisWeek", type: "-delta" },
  ];
  const secondTableColumnsTitles = [
    { colTitle: "Last Week's\nKE", colValue: "lastWeek", type: "-KE" },
    { colTitle: "This Week's\nKE", colValue: "thisWeek", type: "-KE" },
    { colTitle: "Last Week's\nKE ORM", colValue: "lastWeek", type: "-ORM" },
    { colTitle: "This Week's\nKE ORM", colValue: "thisWeek", type: "-ORM" },
    {
      colTitle: "This Week's\nKE ORM Change",
      colValue: "thisWeek",
      type: "-ORM-delta",
    },
  ];
  const SDTableColumns = [
    {
      colTitle: "This Week' Standart Deviation",
      colValue: "thisWeek",
      type: "-SD",
    },
    {
      colTitle: "Compare SS to Overall SS",
      colValue: "thisWeek",
      type: "-compare",
    },
    {
      colTitle: "This Week' Muscle TextColor",
      colValue: "thisWeek",
      type: "-textColor",
    },
    {
      colTitle: "This Week' Muscle Color",
      colValue: "thisWeek",
      type: "-color",
    },
  ];
  const firstTableRowsTitles = [
    { rowTitle: "Overall SS", rowValue: "overallStrengthScore" },
    { rowTitle: "Legs SS", rowValue: StrengthScoreMuscleGroupType.Legs },
    { rowTitle: "Chest SS", rowValue: StrengthScoreMuscleGroupType.Chest },
    { rowTitle: "Back SS", rowValue: StrengthScoreMuscleGroupType.Back },
    {
      rowTitle: "Shoulders SS",
      rowValue: StrengthScoreMuscleGroupType.Shoulders,
    },
    { rowTitle: "Arms SS", rowValue: StrengthScoreMuscleGroupType.Arms },
    { rowTitle: "Weight SS", rowValue: StrengthScoreMuscleGroupType.Weight },
  ];
  const secondTableRowsTitles = [
    { rowTitle: "Legs", rowValue: StrengthScoreMuscleGroupType.Legs },
    { rowTitle: "Chest", rowValue: StrengthScoreMuscleGroupType.Chest },
    { rowTitle: "Back", rowValue: StrengthScoreMuscleGroupType.Back },
    { rowTitle: "Shoulders", rowValue: StrengthScoreMuscleGroupType.Shoulders },
    { rowTitle: "Arms", rowValue: StrengthScoreMuscleGroupType.Arms },
  ];

  const cellStyle: React.CSSProperties = {
    height: 25,
    border: "1px solid #ddd",
    padding: 5,
  };
  const secondTableCellStyle: React.CSSProperties = {
    height: 25,
    border: "1px solid #ddd",
    padding: 5,
  };
  const firstTableHeaderStyle: React.CSSProperties = {
    width: 150,
    height: 50,
    backgroundColor: "#D8D3E7",
    border: "1px solid #ddd",
    padding: 5,
    whiteSpace: "pre-wrap",
  };
  const secondTableHeaderStyle: React.CSSProperties = {
    height: 50,
    backgroundColor: "#DCE9D5",
    border: "1px solid #ddd",
    padding: 5,
    display: "block",
    whiteSpace: "pre-wrap",
  };
  const thirdTableHeaderStyle: React.CSSProperties = {
    height: 25,
    backgroundColor: "#FDF2D0",
    border: "1px solid #ddd",
    padding: 5,
  };
  const cellClassname = "d-flex justify-content-center align-items-center";
  return (
    <div className="d-flex flex-column">
      <div className="d-flex">
        <SimpleButton
          className="d-flex justify-content-center align-items-center"
          onClick={calculateThisWeek}
          style={{
            width: 200,
            height: 25,
            background: colors.caliber_green_100,
            border: `1px solid ${colors.caliber_green_200}`,
            fontSize: "14px",
            marginLeft: 100,
          }}
        >
          Calculate This Week
        </SimpleButton>
        <SimpleButton
          className="d-flex justify-content-center align-items-center"
          onClick={calculateLastWeek}
          style={{
            width: 200,
            height: 25,
            background: colors.caliber_red_100,
            border: `1px solid ${colors.caliber_red_200}`,
            fontSize: "14px",
            marginLeft: 100,
          }}
        >
          Calculate Last Week
        </SimpleButton>
      </div>

      <div className="d-flex">
        <SimpleButton
          className="d-flex justify-content-center align-items-center"
          onClick={() => setOpenThisWeekModal(true)}
          style={{
            width: 200,
            height: 25,
            border: `1px solid ${colors.caliber_green_200}`,
            fontSize: "14px",
            marginTop: 20,
            marginLeft: 100,
          }}
        >
          Show Debug Text This Week
        </SimpleButton>
        <SimpleButton
          className="d-flex justify-content-center align-items-center"
          onClick={() => setOpenLastWeekModal(true)}
          style={{
            width: 200,
            height: 25,
            border: `1px solid ${colors.caliber_red_200}`,
            fontSize: "14px",
            marginTop: 20,
            marginLeft: 100,
          }}
        >
          Show Debug Text Last Week
        </SimpleButton>
      </div>

      <div
        className="d-flex"
        style={{
          marginTop: 20,
        }}
      >
        <table style={{ border: "1px solid #ddd" }}>
          <thead>
            <tr>
              <td>
                <div className={cellClassname} style={firstTableHeaderStyle}>
                  {" "}
                </div>
              </td>
              {firstTableColumnsTitles.map(({ colTitle }) => {
                return (
                  <td>
                    <div
                      className={cellClassname}
                      style={firstTableHeaderStyle}
                    >
                      {colTitle}
                    </div>
                  </td>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {firstTableRowsTitles.map(({ rowTitle, rowValue }) => {
              return (
                <tr>
                  <td>
                    <div className={cellClassname} style={cellStyle}>
                      {rowTitle}
                    </div>
                  </td>
                  {firstTableColumnsTitles.map(({ colValue, type }) => {
                    if (type === "-color") {
                      return (
                        <td>
                          <div
                            className={cellClassname}
                            style={{
                              ...cellStyle,
                              backgroundColor:
                                data?.[`${colValue}-${rowValue}${type}`],
                            }}
                          />
                        </td>
                      );
                    }
                    return (
                      <td>
                        <div className={cellClassname} style={cellStyle}>
                          {data?.[`${colValue}-${rowValue}${type}`]}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div
        className="d-flex"
        style={{
          marginTop: 20,
        }}
      >
        <table style={{ border: "1px solid #ddd" }}>
          <thead>
            <tr>
              <td>
                <div
                  className={cellClassname}
                  style={{ ...secondTableHeaderStyle, width: 100 }}
                >
                  {" "}
                </div>
              </td>
              {secondTableColumnsTitles.map(({ colTitle }) => {
                return (
                  <td>
                    <div
                      className={cellClassname}
                      style={secondTableHeaderStyle}
                    >
                      {colTitle}
                    </div>
                  </td>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {secondTableRowsTitles.map(({ rowTitle, rowValue }) => {
              return (
                <tr>
                  <td>
                    <div className={cellClassname} style={secondTableCellStyle}>
                      {rowTitle}
                    </div>
                  </td>
                  {secondTableColumnsTitles.map(({ colValue, type }) => {
                    return (
                      <td>
                        <div
                          className={cellClassname}
                          style={secondTableCellStyle}
                        >
                          {data?.[`${colValue}-${rowValue}${type}`]}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div
        className="d-flex"
        style={{
          marginTop: 20,
        }}
      >
        <table style={{ border: "1px solid #ddd" }}>
          <thead>
            <tr>
              <td>
                <div className={cellClassname} style={thirdTableHeaderStyle}>
                  {" "}
                </div>
              </td>
              <td>
                <div className={cellClassname} style={thirdTableHeaderStyle}>
                  Last Week
                </div>
              </td>
              <td>
                <div className={cellClassname} style={thirdTableHeaderStyle}>
                  This Week
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className={cellClassname} style={secondTableCellStyle}>
                  Age
                </div>
              </td>
              <td>
                <div className={cellClassname} style={secondTableCellStyle}>
                  {data?.["lastWeek-ageDiscountChanged"]
                    ? "Age did change"
                    : "Age did not change"}
                </div>
              </td>
              <td>
                <div className={cellClassname} style={secondTableCellStyle}>
                  {data?.["thisWeek-ageDiscountChanged"]
                    ? "Age did change"
                    : "Age did not change"}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="d-flex">
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            width: 250,
            height: 350,
            backgroundColor: "white",
            marginTop: 50,
          }}
        >
          <StrengthSkeleton
            muscleGroups={strengthScoreDashboard?.muscleGroupScores}
            gender={client?.gender}
          />
        </div>

        <div
          className="d-flex"
          style={{
            marginTop: 50,
            marginLeft: 10,
          }}
        >
          <table style={{ border: "1px solid #ddd" }}>
            <thead>
              <tr>
                <td>
                  <div className={cellClassname} style={firstTableHeaderStyle}>
                    {" "}
                  </div>
                </td>
                {SDTableColumns.map(({ colTitle }) => {
                  return (
                    <td>
                      <div
                        className={cellClassname}
                        style={firstTableHeaderStyle}
                      >
                        {colTitle}
                      </div>
                    </td>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {firstTableRowsTitles.map(({ rowTitle, rowValue }) => {
                return (
                  <tr>
                    <td>
                      <div className={cellClassname} style={cellStyle}>
                        {rowTitle}
                      </div>
                    </td>
                    {SDTableColumns.map(({ colValue, type }) => {
                      if (type === "-color") {
                        return (
                          <td>
                            <div
                              className={cellClassname}
                              style={{
                                ...cellStyle,
                                backgroundColor:
                                  data?.[`${colValue}-${rowValue}${type}`],
                              }}
                            />
                          </td>
                        );
                      }
                      return (
                        <td>
                          <div className={cellClassname} style={cellStyle}>
                            {data?.[`${colValue}-${rowValue}${type}`]}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {openThisWeekModal && (
        <FloatingBox
          index={1}
          zIndex={zIndex1}
          key="this_week"
          onClose={() => setOpenThisWeekModal(false)}
          onFocus={() => {
            setZIndex1(9999);
            setZIndex2(9998);
          }}
          title="This Week Strength Score Explanation"
        >
          {data?.["thisWeek-text-explanation"]}
        </FloatingBox>
      )}
      {openLastWeekModal && (
        <FloatingBox
          index={2}
          zIndex={zIndex2}
          key="last_week"
          onClose={() => setOpenLastWeekModal(false)}
          onFocus={() => {
            setZIndex1(9998);
            setZIndex2(9999);
          }}
          title="Last Week Strength Score Explanation"
        >
          {data?.["lastWeek-text-explanation"]}
        </FloatingBox>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getStrengthScores: (clientId: string) => {
    dispatch(getStrengthScores(clientId));
  },
  regenerateStrengthScore: (clientId: string, weeksAgo: number) => {
    dispatch(adminRegenerateStrengthScores(clientId, weeksAgo));
  },
});

const mapStateToProps = (state: StoreState) => ({
  strengthScoreDashboardChanges:
    state.clientStrengthDashboardState.strengthScoreDashboardChanges,
  client: state.clientDetailState.user,
  strengthScoreDashboard:
    state.clientStrengthDashboardState.strengthScoreDashboard,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DebugStrengthMetrics);
