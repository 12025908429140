import {
  ComponentLocationType,
  ProgramType,
} from "../../../components/TrainingProgram";
import {
  CaliberPageImpl_CustomTrainingPlan,
  CaliberPageImpl_LibraryTrainingPlan,
  CaliberPageImpl_MasterTrainingPlan,
  ClientGymStepTemplate,
  ClientTrainingPlan,
  ClientWorkoutGroupTemplate,
  ClientWorkoutTemplate,
  CustomTrainingPlan,
  CustomTrainingPlanType,
  Exercise,
  LibraryTrainingPlan,
  MasterGymStep,
  MasterTrainingPlan,
  MasterWorkout,
  QuerySearchMasterTrainingPlansArgs,
  Superset,
  TrainingPlan,
} from "../../../types/gqlTypes";

export const ADD_WORKOUT_TO_TRAINING_PLAN_SUCCESS =
  "ADD_WORKOUT_TO_TRAINING_PLAN_SUCCESS";
export const ADD_WORKOUT_TO_TRAINING_PLAN_FAIL =
  "ADD_WORKOUT_TO_TRAINING_PLAN_FAIL";
export const ADD_WORKOUT_TO_TRAINING_PLAN_LOADING =
  "ADD_WORKOUT_TO_TRAINING_PLAN_LOADING";

export const UPDATE_WORKOUT_SUCCESS = "UPDATE_WORKOUT_SUCCESS";
export const UPDATE_WORKOUT_FAIL = "UPDATE_WORKOUT_FAIL";
export const UPDATE_WORKOUT_LOADING = "UPDATE_WORKOUT_LOADING";

export const DELETE_WORKOUT_SUCCESS = "DELETE_WORKOUT_SUCCESS";
export const DELETE_WORKOUT_FAIL = "DELETE_WORKOUT_FAIL";
export const DELETE_WORKOUT_LOADING = "DELETE_WORKOUT_LOADING";

export const REORDER_WORKOUT_SUCCESS = "REORDER_WORKOUT_SUCCESS";
export const REORDER_WORKOUT_FAIL = "REORDER_WORKOUT_FAIL";
export const REORDER_WORKOUT_SAVING = "REORDER_WORKOUT_SAVING";

export const REORDER_WORKOUT_ACROSS_GROUPS_SUCCESS =
  "REORDER_WORKOUT_ACROSS_GROUPS_SUCCESS";
export const REORDER_WORKOUT_ACROSS_GROUPS_FAIL =
  "REORDER_WORKOUT_ACROSS_GROUPS_FAIL";
export const REORDER_WORKOUT_ACROSS_GROUPS_SAVING =
  "REORDER_WORKOUT_ACROSS_GROUPS_SAVING";

export const REORDER_WORKOUT_GROUP_SUCCESS = "REORDER_WORKOUT_GROUP_SUCCESS";
export const REORDER_WORKOUT_GROUP_FAIL = "REORDER_WORKOUT_GROUP_FAIL";
export const REORDER_WORKOUT_GROUP_SAVING = "REORDER_WORKOUT_GROUP_SAVING";

export const REORDER_GYM_STEPS_IN_TRAINING_PLAN_SUCCESS =
  "REORDER_GYM_STEPS_IN_TRAINING_PLAN_SUCCESS";
export const REORDER_GYM_STEPS_IN_TRAINING_PLAN_FAIL =
  "REORDER_GYM_STEPS_IN_TRAINING_PLAN_FAIL";
export const REORDER_GYM_STEPS_IN_TRAINING_PLAN_SAVING =
  "REORDER_GYM_STEPS_IN_TRAINING_PLAN_SAVING";

export const UPDATE_GYM_STEP_LOADING = "UPDATE_GYM_STEP_LOADING";
export const UPDATE_GYM_STEP_SUCCESS = "UPDATE_GYM_STEP_SUCCESS";
export const UPDATE_GYM_STEP_FAIL = "UPDATE_GYM_STEP_FAIL";

export const DELETE_GYM_STEP_SUCCESS = "DELETE_GYM_STEP_SUCCESS";
export const DELETE_GYM_STEP_FAIL = "DELETE_GYM_STEP_FAIL";
export const DELETE_GYM_STEP_LOADING = "DELETE_GYM_STEP_LOADING";

export const ADD_GYM_STEP_SUCCESS = "ADD_GYM_STEP_SUCCESS";
export const ADD_GYM_STEP_FAIL = "ADD_GYM_STEP_FAIL";
export const ADD_GYM_STEP_LOADING = "ADD_GYM_STEP_LOADING";

export const GET_TRAINING_PLANS_SUCCESS = "GET_TRAINING_PLANS_SUCCESS";
export const GET_TRAINING_PLAN_SUCCESS = "GET_TRAINING_PLAN_SUCCESS";
export const GET_TRAINING_PLAN_LAST_UPDATED_SUCCESS =
  "GET_TRAINING_PLAN_LAST_UPDATED_SUCCESS";
export const GET_TRAINING_PLAN_FAIL = "GET_TRAINING_PLAN_FAIL";
export const GET_TRAINING_PLAN_LOADING = "GET_TRAINING_PLAN_LOADING";
export const GET_TRAINING_PLANS_FAIL = "GET_TRAINING_PLANS_FAIL";
export const GET_TRAINING_PLANS_LOADING = "GET_TRAINING_PLANS_LOADING";
export const LOAD_TRAINING_PLAN_FROM_ROUTE_SUCCESS =
  "LOAD_TRAINING_PLAN_FROM_ROUTE_SUCCESS";

export const CREATE_TRAINING_PLAN_SUCCESS = "CREATE_TRAINING_PLAN_SUCCESS";
export const CREATE_TRAINING_PLAN_FAIL = "CREATE_TRAINING_PLAN_FAIL";
export const CREATE_TRAINING_PLAN_LOADING = "CREATE_TRAINING_PLAN_LOADING";

export const ASSIGN_TRAINING_PLAN_SUCCESS = "ASSIGN_TRAINING_PLAN_SUCCESS";
export const ASSIGN_TRAINING_PLAN_FAIL = "ASSIGN_TRAINING_PLAN_FAIL";
export const ASSIGN_TRAINING_PLAN_LOADING = "ASSIGN_TRAINING_PLAN_LOADING";

export const COPY_MASTER_WORKOUT_GROUP_TO_CLIENT_SUCCESS =
  "COPY_MASTER_WORKOUT_GROUP_TO_CLIENT_SUCCESS";
export const COPY_MASTER_WORKOUT_GROUP_TO_CLIENT_FAIL =
  "COPY_MASTER_WORKOUT_GROUP_TO_CLIENT_FAIL";
export const COPY_MASTER_WORKOUT_GROUP_TO_CLIENT_LOADING =
  "COPY_MASTER_WORKOUT_GROUP_TO_CLIENT_LOADING";

export const ADD_NEW_CLIENT_WORKOUT_GROUP_SUCCESS =
  "ADD_NEW_CLIENT_WORKOUT_GROUP_SUCCESS";
export const ADD_NEW_CLIENT_WORKOUT_GROUP_FAIL =
  "ADD_NEW_CLIENT_WORKOUT_GROUP_FAIL";
export const ADD_NEW_CLIENT_WORKOUT_GROUP_LOADING =
  "ADD_NEW_CLIENT_WORKOUT_GROUP_LOADING";

export const CLONE_MASTER_TRAINING_PLAN_SUCCESS =
  "CLONE_MASTER_TRAINING_PLAN_SUCCESS";
export const CLONE_MASTER_TRAINING_PLAN_FAIL =
  "CLONE_MASTER_TRAINING_PLAN_FAIL";
export const CLONE_MASTER_TRAINING_PLAN_LOADING =
  "CLONE_MASTER_TRAINING_PLAN_LOADING";

export const CLONE_CUSTOM_TRAINING_PLAN_SUCCESS =
  "CLONE_CUSTOM_TRAINING_PLAN_SUCCESS";
export const CLONE_CUSTOM_TRAINING_PLAN_FAIL =
  "CLONE_CUSTOM_TRAINING_PLAN_FAIL";
export const CLONE_CUSTOM_TRAINING_PLAN_LOADING =
  "CLONE_CUSTOM_TRAINING_PLAN_LOADING";

export const DELETE_TRAINING_PLAN_SUCCESS = "DELETE_TRAINING_PLAN_SUCCESS";
export const DELETE_TRAINING_PLAN_FAIL = "DELETE_TRAINING_PLAN_FAIL";
export const DELETE_TRAINING_PLAN_LOADING = "DELETE_TRAINING_PLAN_LOADING";

export const DELETE_CLIENT_WORKOUT_GROUP_SUCCESS =
  "DELETE_CLIENT_WORKOUT_GROUP_SUCCESS";
export const DELETE_CLIENT_WORKOUT_GROUP_FAIL =
  "DELETE_CLIENT_WORKOUT_GROUP_FAIL";
export const DELETE_CLIENT_WORKOUT_GROUP_LOADING =
  "DELETE_CLIENT_WORKOUT_GROUP_LOADING";

export const UPDATE_TRAINING_PLAN_SUCCESS = "UPDATE_TRAINING_PLAN_SUCCESS";
export const UPDATE_TRAINING_PLAN_FAIL = "UPDATE_TRAINING_PLAN_FAIL";
export const UPDATE_TRAINING_PLAN_LOADING = "UPDATE_TRAINING_PLAN_LOADING";

export const TOGGLE_ACTIVE_CLIENT_TRAINING_PLAN_SUCCESS =
  "TOGGLE_ACTIVE_CLIENT_TRAINING_PLAN_SUCCESS";
export const TOGGLE_ACTIVE_CLIENT_TRAINING_PLAN_FAIL =
  "TOGGLE_ACTIVE_CLIENT_TRAINING_PLAN_FAIL";
export const TOGGLE_ACTIVE_CLIENT_TRAINING_PLAN_LOADING =
  "TOGGLE_ACTIVE_CLIENT_TRAINING_PLAN_LOADING";
export const TOGGLE_CUSTOM_TRAINING_PLAN_STATE_FAIL =
  "TOGGLE_CUSTOM_TRAINING_PLAN_STATE_FAIL";

export const COPY_TRAINING_PLAN_TO_CLIENTS_SAVING =
  "COPY_TRAINING_PLAN_TO_CLIENTS_SAVING";
export const COPY_TRAINING_PLAN_TO_CLIENTS_SUCCESS =
  "COPY_TRAINING_PLAN_TO_CLIENTS_SUCCESS";
export const COPY_TRAINING_PLAN_TO_CLIENTS_FAIL =
  "COPY_TRAINING_PLAN_TO_CLIENTS_FAIL";

export const COPY_TRAINING_PLAN_TO_TRAINER_SAVING =
  "COPY_TRAINING_PLAN_TO_TRAINER_SAVING";
export const COPY_TRAINING_PLAN_TO_TRAINER_SUCCESS =
  "COPY_TRAINING_PLAN_TO_TRAINER_SUCCESS";
export const COPY_TRAINING_PLAN_TO_TRAINER_FAIL =
  "COPY_TRAINING_PLAN_TO_TRAINER_FAIL";
export const VIEW_NEWLY_CREATED_CUSTOM_PLAN = "VIEW_NEWLY_CREATED_CUSTOM_PLAN";

export const COPY_CLIENT_WORKOUT_TEMPLATE_SAVING =
  "COPY_CLIENT_WORKOUT_TEMPLATE_SAVING";
export const COPY_CLIENT_WORKOUT_TEMPLATE_SUCCESS =
  "COPY_CLIENT_WORKOUT_TEMPLATE_SUCCESS";
export const COPY_CLIENT_WORKOUT_TEMPLATE_FAIL =
  "COPY_CLIENT_WORKOUT_TEMPLATE_FAIL";

export const OPEN_EXERCISE_LIBRARY_TRAINING_PLAN =
  "OPEN_EXERCISE_LIBRARY_TRAINING_PLAN";
export const TOGGLE_FILTER_TRAINING_PLAN = "TOGGLE_FILTER_TRAINING_PLAN";
export const ON_CHANGE_FILTER_STATE_TRAINING_PLAN =
  "ON_CHANGE_FILTER_STATE_TRAINING_PLAN";
export const TOGGLE_CREATE_NEW_TRAINING_PLAN =
  "TOGGLE_CREATE_NEW_TRAINING_PLAN";
export const BROWSE_MASTER_TRAINING_PLANS = "BROWSE_MASTER_TRAINING_PLANS";
export const SELECT_TRAINING_PLAN = "SELECT_TRAINING_PLAN";
export const SELECT_CLIENT_TRAINING_PLAN = "SELECT_CLIENT_TRAINING_PLAN";
export const SELECT_WORKOUT_TRAINING_PLAN = "SELECT_WORKOUT_TRAINING_PLAN";
export const SELECT_WORKOUT_GROUP_TRAINING_PLAN =
  "SELECT_WORKOUT_GROUP_TRAINING_PLAN";
export const START_EDIT_WORKOUT_TRAINING_PLAN =
  "START_EDIT_WORKOUT_TRAINING_PLAN";
export const STOP_EDIT_WORKOUT_TRAINING_PLAN =
  "STOP_EDIT_WORKOUT_TRAINING_PLAN";
export const TOGGLE_EDIT_TRAINING_PLAN = "TOGGLE_EDIT_TRAINING_PLAN";
export const TOGGLE_COPY_TRAINING_PLAN_TO_CLIENTS =
  "TOGGLE_COPY_TRAINING_PLAN_TO_CLIENTS";
export const TOGGLE_COPY_TRAINING_PLAN_TO_TRAINER =
  "TOGGLE_COPY_TRAINING_PLAN_TO_TRAINER";
export const TOGGLE_CREATE_CUSTOM_TRAINING_PLAN =
  "TOGGLE_CREATE_CUSTOM_TRAINING_PLAN";
export const CHANGE_PROGRAM_TYPE_TRAINING_PLAN =
  "CHANGE_PROGRAM_TYPE_TRAINING_PLAN";
export const CHANGE_CUSTOM_TRAINING_PLAN_TYPE =
  "CHANGE_CUSTOM_TRAINING_PLAN_TYPE";
export const CHANGE_TRAINING_PLAN_COMPONENT_LOCATION =
  "CHANGE_TRAINING_PLAN_COMPONENT_LOCATION";

export const ON_CLICK_NEW_OR_EDIT_MASTER_PROGRAM =
  "ON_CLICK_NEW_OR_EDIT_MASTER_PROGRAM";
export const ON_CLOSE_NEW_OR_EDIT_MASTER_PROGRAM =
  "ON_CLOSE_NEW_OR_EDIT_MASTER_PROGRAM";
export const ON_CLOSE_EDITING_MASTER_PLAN = "ON_CLOSE_EDITING_MASTER_PLAN";
export const CREATE_CUSTOM_PLAN_IN_PROGRAM_LIBRARY =
  "CREATE_CUSTOM_PLAN_IN_PROGRAM_LIBRARY";

export const CREATE_LIBRARY_PLAN_IN_PROGRAM_LIBRARY =
  "CREATE_LIBRARY_PLAN_IN_PROGRAM_LIBRARY";
export const ON_STOP_CREATE_CUSTOM_PLAN_IN_PROGRAM_LIBRARY =
  "ON_STOP_CREATE_CUSTOM_PLAN_IN_PROGRAM_LIBRARY";

export const TOGGLE_EDIT_WORKOUT_GROUP = "TOGGLE_EDIT_WORKOUT_GROUP";
export const UPDATE_WORKOUT_GROUP_LOADING = "UPDATE_WORKOUT_GROUP_LOADING";
export const UPDATE_WORKOUT_GROUP_SUCCESS = "UPDATE_WORKOUT_GROUP_SUCCESS";
export const UPDATE_WORKOUT_GROUP_FAIL = "UPDATE_WORKOUT_GROUP_FAIL";

export const SET_DEFAULT_WORKOUT_GROUP_LOADING =
  "SET_DEFAULT_WORKOUT_GROUP_LOADING";
export const SET_DEFAULT_WORKOUT_GROUP_SUCCESS =
  "SET_DEFAULT_WORKOUT_GROUP_SUCCESS";
export const SET_DEFAULT_WORKOUT_GROUP_FAIL = "SET_DEFAULT_WORKOUT_GROUP_FAIL";

export const OPEN_SUPERSET_EDITOR = "OPEN_SUPERSET_EDITOR";
export const CLOSE_SUPERSET_EDITOR = "CLOSE_SUPERSET_EDITOR";
export const OPEN_WORKOUTS = "OPEN_WORKOUTS";
export const OPEN_WORKOUT_GROUPS = "OPEN_WORKOUT_GROUPS";
export const CLOSE_EXERCISE_SEARCH_LIBRARY = "CLOSE_EXERCISE_SEARCH_LIBRARY";
export const OPEN_EXERCISE_LIST = "OPEN_EXERCISE_LIST";
export const CLOSE_EXERCISE_LIST = "CLOSE_EXERCISE_LIST";

export const CREATE_SUPERSET_LOADING = "CREATE_SUPERSET_LOADING";
export const CREATE_SUPERSET_SUCCESS = "CREATE_SUPERSET_SUCCESS";
export const CREATE_SUPERSET_FAIL = "CREATE_SUPERSET_FAIL";

export interface TrainingProgramsState {
  isLoading: boolean;
  isSaving: boolean;
  isDeleting: boolean;
  isLoadingPlan?: boolean;
  isLoadingPlans?: boolean;

  componentLocation?: ComponentLocationType;
  programType: ProgramType;
  customTrainingPlanType?: CustomTrainingPlanType;

  lastUpdatedPlanDateById: Map<string, Date>;

  customPlansContent?: CaliberPageImpl_CustomTrainingPlan;
  libraryPlansContent?: CaliberPageImpl_LibraryTrainingPlan;
  masterPlansContent?: CaliberPageImpl_MasterTrainingPlan;
  clientPlans?: ClientTrainingPlan[];
  cachedPlans: {
    [key: string]:
      | ClientTrainingPlan
      | MasterTrainingPlan
      | CustomTrainingPlan
      | LibraryTrainingPlan;
  };

  clientPlanIndex?: number;
  planIndex?: number;
  workoutGroupIndex?: number;
  workoutIndex?: number;
  supersetId?: string;

  filterState?: QuerySearchMasterTrainingPlansArgs;

  isEditPlan?: boolean;
  isEditWorkoutGroup?: boolean;
  isEditWorkout?: boolean;

  showPlans?: boolean;
  showCreateNewPlans?: boolean;
  showFilter?: boolean;
  showWorkouts?: boolean;
  showExercises?: boolean;
  showExerciseLibrary?: boolean;
  showSupersetEditor?: boolean;
  showWorkoutExerciseList?: boolean;

  showCopyPlanToClients?: boolean;
  showCopyPlanToTrainer?: boolean;
  showCreateCustomPlan?: boolean;
  copyPlanToClients?: ClientTrainingPlan;
  copyPlanToTrainer?: ClientTrainingPlan;
  isCopying?: boolean;
  failedToCreateCustomPlan?: boolean;
  successToCreateCustomPlan?: boolean;
  newlyCreatedCustomPlan?: CustomTrainingPlan;
  newlyCreatedLibraryPlan?: LibraryTrainingPlan;
  newlyCreatedMasterPlan?: MasterTrainingPlan;

  showCopyWorkoutGroupToClients?: boolean;
  showCopyWorkoutGroupToTrainer?: boolean;
  copyWorkoutGroupToClients?: ClientTrainingPlan;
  copyWorkoutGroupToTrainer?: ClientTrainingPlan;

  showNewOrEditMasterProgramSection?: boolean;
  editingMasterProgram?: MasterTrainingPlan;
}

export interface TrainingProgramsAction {
  type: string;
  exercise?: Exercise;
  gymStep?: MasterGymStep | ClientGymStepTemplate;
  workout?: ClientWorkoutTemplate | MasterWorkout;
  workoutGroup?: ClientWorkoutGroupTemplate;
  superset?: Superset;
  id?: string;
  ids?: string[];
  index?: number;
  timeUpdated?: Date;
  customPlansContent?: CaliberPageImpl_CustomTrainingPlan;
  masterPlansContent?: CaliberPageImpl_MasterTrainingPlan;
  libraryPlansContent?: CaliberPageImpl_LibraryTrainingPlan;
  plan?:
    | MasterTrainingPlan
    | ClientTrainingPlan
    | CustomTrainingPlan
    | TrainingPlan;
  programType?: ProgramType;
  customTrainingPlanType?: CustomTrainingPlanType;
  filterState?: QuerySearchMasterTrainingPlansArgs;
  getPlansResult?:
    | CaliberPageImpl_MasterTrainingPlan
    | CaliberPageImpl_CustomTrainingPlan
    | CaliberPageImpl_LibraryTrainingPlan
    | ClientTrainingPlan[];
  activePlan?: ClientTrainingPlan;
  deactivePlan?: ClientTrainingPlan;
  componentLocation?: ComponentLocationType;
  convertedSupersetId: string;
}
