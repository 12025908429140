import { ADD_RECENT_EMOJI, MAX_RECENT_EMOJIS, RecentEmojisState } from '../../actions/RecentEmojis/types';
import { AUTH_LOGGED_OUT } from '../../actions/Auth/types';

const initialState = (): RecentEmojisState => ({
  recentEmojis: [],
});

export const recentEmojis = (state = initialState(), action): RecentEmojisState => {
  switch (action.type) {
    case AUTH_LOGGED_OUT: {
      return initialState();
    }
    case ADD_RECENT_EMOJI:
      const emoji = action.emoji;
      const newRecentEmojis = state.recentEmojis.filter((recentEmoji) => recentEmoji !== emoji);
      newRecentEmojis.unshift(emoji);
      if (newRecentEmojis.length > MAX_RECENT_EMOJIS) {
        newRecentEmojis.pop();
      }

      return {
        ...state,
        recentEmojis: newRecentEmojis,
      }
  }
  return state;
}
