import { CaliberError } from "../../../types/gqlTypes";
import {
  ADMIN_MANAGER_CREATING_SUCCESS,
  ADMIN_MANAGER_UPDATING_SUCCESS,
} from "../../actions/AdminTeamManagers/types";
import {
  ADMIN_TRAINER_CREATING_SUCCESS,
  ADMIN_TRAINER_UPDATING_SUCCESS,
} from "../../actions/AdminTeamTrainers/types";
import {
  CLOSE_ERROR_SUCCESS_DIALOG,
  ErrorSuccessDialogAction,
  ErrorSuccessDialogState,
  GET_CALIBER_ERRORS,
  OPEN_ERROR_DIALOG,
  OPEN_SUCCESS_DIALOG,
  THROW_CALIBER_ERROR_FAILED,
  THROW_CALIBER_ERROR_LOADING,
  THROW_CALIBER_ERROR_SUCCESS,
} from "../../actions/ErrorSuccessDialog/types";

const initialState: ErrorSuccessDialogState = {
  show: false,
  messages: null,
  errors: null,
};

const errorMessages = {
  userAlreadyExists: "User with this email already exists.",
  accessDenied: "A permissions error has occurred, please notify your manager.",
  wordpressException: "Something went wrong in WordPress.",
  api: "An unexpected API error has occurred. Please see the error logs.",
  else: "An Unexpected Error has occurred. Please see the error logs.",
  httpError: "An Unexpected Error has occurred, please notify your manager.",
};

const errorSuccessDialogState = (
  state = initialState,
  action: ErrorSuccessDialogAction
) => {
  switch (action.type) {
    case GET_CALIBER_ERRORS: {
      return {
        ...state,
        errors: action.errors,
      };
    }
    case THROW_CALIBER_ERROR_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case THROW_CALIBER_ERROR_FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case THROW_CALIBER_ERROR_SUCCESS: {
      if (action?.caliberErrorNumber === 500) {
        return {
          ...state,
          isLoading: false,
          messages: [{ text: errorMessages.httpError, fold: false }],
        };
      }
      return {
        ...state,
        isLoading: false,
      };
    }
    case ADMIN_TRAINER_UPDATING_SUCCESS: {
      return {
        ...state,
        show: true,
        messages: [{ text: "The trainer has been updated.", fold: false }],
        graphQLErrors: null,
      };
    }
    case ADMIN_TRAINER_CREATING_SUCCESS: {
      return {
        ...state,
        show: true,
        messages: [{ text: "The trainer has been created.", fold: false }],
        graphQLErrors: null,
      };
    }
    case ADMIN_MANAGER_CREATING_SUCCESS: {
      return {
        ...state,
        show: true,
        messages: [{ text: "The manager has been created.", fold: false }],
        graphQLErrors: null,
      };
    }
    case ADMIN_MANAGER_UPDATING_SUCCESS: {
      return {
        ...state,
        show: true,
        messages: [{ text: "The manager has been updated.", fold: false }],
        graphQLErrors: null,
      };
    }
    case OPEN_ERROR_DIALOG: {
      const messages = [];
      action?.graphQLErrors?.map((error) => {
        if (error?.extensions?.errorName === CaliberError.AccessDenied) {
          messages.push({ text: errorMessages.accessDenied, fold: false });
        } else if (
          error?.extensions?.errorName === CaliberError.WordpressException
        ) {
          messages.push({
            text: errorMessages.wordpressException,
            fold: false,
          });
        } else if (
          error?.extensions?.errorName === CaliberError.Auth0UserAlreadyExists
        ) {
          messages.push({ text: errorMessages.userAlreadyExists, fold: false });
        } else if (error?.extensions?.errorName === CaliberError.Api) {
          messages.push({ text: errorMessages.api, fold: false });
        } else {
          messages.push({ text: errorMessages.else, fold: false });
        }
        messages.push({ text: error?.message, fold: false });
      });
      if (messages?.length === 0) {
        return {
          ...state,
          show: true,
          messages: [{ text: errorMessages.httpError, fold: false }],
        };
      }
      return {
        ...state,
        show: true,
        graphQLErrors: action.graphQLErrors,
        messages,
      };
    }
    case OPEN_SUCCESS_DIALOG:
      return {
        ...state,
        show: true,
        messages: [{ text: action.message, fold: false }],
      };
    case CLOSE_ERROR_SUCCESS_DIALOG:
      return {
        ...state,
        show: false,
        graphQLErrors: null,
        messages: null,
      };
    default:
      return state;
  }
};

export default errorSuccessDialogState;
