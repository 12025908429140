import { RESET_CLIENT_STATE } from "../../actions/ClientPlans/types";
import {
  CLIENT_STRENGTH_DASHBOARD_SUCCESS,
  CLIENT_STRENGTH_DASHBOARD_LOADING,
  CLIENT_STRENGTH_DASHBOARD_FAIL,
  RESET_CLIENT_STRENGTH_DASHBOARD,
  REGENERATE_STRENGTH_DASHBOARD_SUCCESS,
  REGENERATE_STRENGTH_DASHBOARD_LOADING,
  REGENERATE_STRENGTH_DASHBOARD_FAIL,
  ClientStrengthDashboardAction,
  ClientStrengthDashboardState,
  CLIENT_STRENGTH_SCORE_MUSCLE_GROUP_LOADING,
  CLIENT_STRENGTH_SCORE_MUSCLE_GROUP_SUCCESS,
  CLIENT_STRENGTH_SCORE_MUSCLE_GROUP_FAIL,
} from "../../actions/ClientStrengthDashboard/types";

const initialState: ClientStrengthDashboardState = {
  isLoading: false,
  isLoadingMuscleScores: false,
  strengthScoreDashboard: null,
  strengthScoreDashboardChanges: null,
  historicalStrengthScore: null,
  historicalStrengthScoreMuscleGroup: null,
  historicalStrengthBalance: null,
};

const clientStrengthDashboardState = (
  state = initialState,
  action: ClientStrengthDashboardAction
) => {
  switch (action.type) {
    case CLIENT_STRENGTH_SCORE_MUSCLE_GROUP_LOADING:
      return {
        ...state,
        isLoadingMuscleScores: true,
        historicalStrengthScoreMuscleGroup: null,
      };
    case CLIENT_STRENGTH_SCORE_MUSCLE_GROUP_SUCCESS:
      return {
        ...state,
        isLoadingMuscleScores: false,
        historicalStrengthScoreMuscleGroup:
          action.historicalStrengthScoreMuscleGroup,
      };
    case CLIENT_STRENGTH_SCORE_MUSCLE_GROUP_FAIL:
      return {
        ...state,
        isLoadingMuscleScores: false,
      };
    case CLIENT_STRENGTH_DASHBOARD_LOADING:
    case REGENERATE_STRENGTH_DASHBOARD_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case RESET_CLIENT_STATE:
    case RESET_CLIENT_STRENGTH_DASHBOARD:
      return {
        ...state,
        isLoading: false,
        strengthScoreDashboardChanges: null,
        strengthScoreDashboard: null,
        historicalStrengthScore: null,
        historicalStrengthScoreMuscleGroup: null,
        historicalStrengthBalance: null,
      };
    case CLIENT_STRENGTH_DASHBOARD_SUCCESS:
    case REGENERATE_STRENGTH_DASHBOARD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        strengthScoreDashboard: action.strengthScoreDashboardChangesResult?.[0],
        strengthScoreDashboardChanges:
          action.strengthScoreDashboardChangesResult,
        historicalStrengthScore: action.historicalStrengthScoreResult,
        historicalStrengthBalance: action.historicalStrengthBalanceResult,
      };
    case CLIENT_STRENGTH_DASHBOARD_FAIL:
    case REGENERATE_STRENGTH_DASHBOARD_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default clientStrengthDashboardState;
