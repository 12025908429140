import React from "react";
import colors from "../../../assets/colors";
import { StrengthScoreMuscleGroupType } from "../../../types/gqlTypes";
import CloseIcon from "../../Icons/CloseIcons";
import SimpleButton from "../../SimpleButton";

interface Props {
  muscleType: StrengthScoreMuscleGroupType;
  onClose: () => void;
}

const MuscleDetailsHeader = (props: Props) => {
  const { muscleType, onClose } = props;

  const muscleTitles = {
    [StrengthScoreMuscleGroupType.Legs]: [
      "Legs",
      "Quads - Hamstrings - Glutes - Calves",
    ],
    [StrengthScoreMuscleGroupType.Chest]: ["Chest", "Upper Chest - Mid Chest "],
    [StrengthScoreMuscleGroupType.Arms]: [
      "Arms",
      "Biceps - Triceps - Brachialis - Forearms",
    ],
    [StrengthScoreMuscleGroupType.Weight]: [
      "Weight",
      "Muscle - Fat - Water - Bones",
    ],
    [StrengthScoreMuscleGroupType.Back]: [
      "Back",
      "Erector Spinae - Lats - Rhomboids - Traps",
    ],
    [StrengthScoreMuscleGroupType.Shoulders]: [
      "Shoulders",
      "Front Shoulders - Mid Shoulders - Rear Shoulders",
    ],
  };

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="d-flex flex-column">
        <div
          className="bold"
          style={{
            color: "#000000",
            fontSize: "24px",
            lineHeight: "24px",
            letterSpacing: "-0.022em",
          }}
        >
          {muscleTitles?.[muscleType]?.[0]}
        </div>
        <div
          className="medium-bold"
          style={{
            color: colors.caliber_secondary_gray_38,
            fontSize: "16px",
            lineHeight: "20px",
            letterSpacing: "-0.022em",
          }}
        >
          {muscleTitles?.[muscleType]?.[1]}
        </div>
      </div>
      <SimpleButton
        nofocus
        onClick={onClose}
        style={{
          width: 40,
          height: 40,
          marginRight: 15,
        }}
      >
        <CloseIcon width={40} height={40} />
      </SimpleButton>
    </div>
  );
};

export default MuscleDetailsHeader;
