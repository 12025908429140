import React from "react";
import colors from "../../assets/colors";

interface Props {
  onChange: (value: string) => void;
  text: string;
}

const FunFacts = (props: Props) => {
  const { onChange, text } = props;

  const repsInputStyle: React.CSSProperties = {
    width: 428,
    height: 144,
    backgroundColor: colors.caliber_secondary_gray_11,
    border: `1px solid ${colors.caliber_secondary_gray_11}`,
    borderRadius: 8,
    fontSize: "14px",
    color: colors.caliber_gray_text,
    padding: 16,
  };

  return (
    <div className="d-flex flex-column">
      <div
        className="d-flex align-items-center medium-bold"
        style={{
          fontSize: "18px",
          lineHeight: "18px",
          letterSpacing: "-0.022em",
          color: colors.caliber_secondary,
          marginTop: 32,
        }}
      >
        Fun facts
      </div>

      <div
        className="d-flex"
        style={{
          marginTop: 24,
          marginBottom: 32,
        }}
      >
        <textarea
          className="medium-bold"
          placeholder="Add fun facts about trainer"
          value={text}
          onChange={(event) => onChange(event.target.value)}
          style={repsInputStyle}
        />
      </div>
    </div>
  );
};

export default FunFacts;
