import React from "react";
import StrengthScoreDetails from "../../components/StrengthScoreDetails";

interface Props {}

const TrainerDashboardStrengthScoreDetails = (props: Props) => {
  const {} = props;
  return (
    <div>
      <StrengthScoreDetails />
    </div>
  );
};

export default TrainerDashboardStrengthScoreDetails;
