import React from "react";
import colors from "../../../assets/colors";
import ArrowIcons, { ArrowIconType } from "../../Icons/ArrowIcons";
import SimpleButton from "../../SimpleButton";

interface Props {
  title?: string;
  isFolded?: boolean;
  onClick?: () => void;
}

const SectionHeader = (props: Props) => {
  const { title, isFolded, onClick } = props;

  return (
    <div
      className="d-flex flex-column"
      style={{
        margin: "40px 24px 0px 24px",
      }}
    >
      <div className="d-flex align-items-center justify-content-between medium-bold">
        <div
          style={{
            fontSize: "16px",
            lineHeight: "16px",
            letterSpacing: "-0.022em",
            color: colors.caliber_secondary_gray_47,
          }}
        >
          {title}
        </div>

        <SimpleButton
          nofocus
          onClick={onClick}
          style={{
            marginRight: 10,
          }}
        >
          <ArrowIcons
            direction={isFolded ? "down" : "up"}
            iconType={ArrowIconType.DEFAULT}
            color="#C2C3CD"
          />
        </SimpleButton>
      </div>

      <div
        style={{
          height: 1,
          backgroundColor: "#EAEAEA",
          marginBottom: 12,
        }}
      >
        {/* horizontal line */}
      </div>
    </div>
  );
};

export default SectionHeader;
