import React from "react";
import colors from "../../assets/colors";
import AutoMessage from "../../components/AutoMessage";
import TimezoneSettings from "../../components/TimezoneSettings";

interface Props {}

const TrainerSettingsScreen = (props: Props) => {
  const {} = props;

  const dividerStyle: React.CSSProperties = {
    height: 1,
    backgroundColor: colors.caliber_secondary_gray_11,
  };

  return (
    <div
      className="d-flex flex-column"
      style={{
        flex: 1,
        padding: 32,
      }}
    >
      <div
        className="bold"
        style={{
          fontSize: "24px",
          lineHeight: "24px",
          color: colors.caliber_secondary,
          letterSpacing: "-0.033em",
        }}
      >
        Your settings
      </div>
      <div
        className=""
        style={{
          fontSize: "14px",
          lineHeight: "14px",
          color: colors.caliber_gray_text,
          letterSpacing: "-0.011em",
          width: 250,
          marginTop: 12,
          marginBottom: 43,
        }}
      >
        In this section, you can customize certain features of the Caliber
        platform.
      </div>
      <div style={dividerStyle}>{/* horizontal line */}</div>
      <TimezoneSettings />
      <div style={dividerStyle}>{/* horizontal line */}</div>
      <AutoMessage />
    </div>
  );
};

export default TrainerSettingsScreen;
