import React from "react";
import { connect } from "react-redux";
import { ClientsTabColumnsType } from "../../../redux/actions/ClientsTab/types";
import { updateClientCallsLeft } from "../../../redux/actions/ClientDetail";
import { StoreState } from "../../../redux/reducers";
import { ClientSearchResult } from "../../../types/gqlTypes";
import ActiveCell from "./ActiveCell";
import CallsLeftCell from "./CallsLeftCell";
import GenderCell from "./GenderCell";
import HeightCell from "./HeightCell";
import MemberSinceCell from "./MemberSinceCell";
import NutritionCell from "./NutritionCell";
import PackageCell from "./PackageCell";
import StatusCell from "./StatusCell";
import StrengthScoreCell from "./StrengthScoreCell";
import TextCell from "./TextCell";
import UserNameCell from "./UserNameCell";
import WeightCell from "./WeightCell";
import PackageEndDateCell from "./PackageEndDateCell";

interface OwnProps {
  columnName: ClientsTabColumnsType;
  isLastColumn?: boolean;
  isDragging?: boolean;
  isLastRow?: boolean;
  width?: number;
  column: ClientsTabColumnsType;
  row: ClientSearchResult;
}
interface Props extends OwnProps {
  updateClientCallsLeft: (clientId: string, callsLeft: number) => void;
}

const BodyCell = (props: Props) => {
  const {
    isLastColumn,
    columnName,
    isDragging,
    isLastRow,
    width,
    row,
    column,
    updateClientCallsLeft,
  } = props;

  switch (columnName) {
    case ClientsTabColumnsType.fullName:
      return <UserNameCell row={row} width={width} isLastRow={isLastRow} />;
    case ClientsTabColumnsType.clientPackageType:
      return (
        <PackageCell
          row={row}
          width={width}
          isDragging={isDragging}
          isLastColumn={isLastColumn}
        />
      );
    case ClientsTabColumnsType.status:
      return (
        <StatusCell
          row={row}
          width={width}
          isDragging={isDragging}
          isLastColumn={isLastColumn}
        />
      );
    case ClientsTabColumnsType.gender:
      return (
        <GenderCell
          row={row}
          isDragging={isDragging}
          width={width}
          isLastColumn={isLastColumn}
        />
      );
    case ClientsTabColumnsType.lastActiveDate:
    case ClientsTabColumnsType.lastDateClientReceivedMessage:
    case ClientsTabColumnsType.lastDateClientSentMessage:
    case ClientsTabColumnsType.mostRecentlyCompletedWorkoutDate:
      return (
        <ActiveCell
          row={row}
          column={column}
          isDragging={isDragging}
          width={width}
          isLastColumn={isLastColumn}
        />
      );
    case ClientsTabColumnsType.packageEndDate:
      return (
        <PackageEndDateCell
          row={row}
          column={column}
          isDragging={isDragging}
          width={width}
          isLastColumn={isLastColumn}
        />
      );
    case ClientsTabColumnsType.joinedDate:
      return (
        <MemberSinceCell
          row={row}
          isDragging={isDragging}
          width={width}
          isLastColumn={isLastColumn}
        />
      );
    case ClientsTabColumnsType.weight:
      return (
        <WeightCell
          row={row}
          isDragging={isDragging}
          width={width}
          isLastColumn={isLastColumn}
        />
      );
    case ClientsTabColumnsType.height:
      return (
        <HeightCell
          row={row}
          isDragging={isDragging}
          width={width}
          isLastColumn={isLastColumn}
        />
      );
    case ClientsTabColumnsType.strengthScore:
    case ClientsTabColumnsType.strengthBalance:
    case ClientsTabColumnsType.armsMuscleGroupScore:
    case ClientsTabColumnsType.backMuscleGroupScore:
    case ClientsTabColumnsType.legsMuscleGroupScore:
    case ClientsTabColumnsType.chestMuscleGroupScore:
    case ClientsTabColumnsType.shouldersMuscleGroupScore:
      return (
        <StrengthScoreCell
          row={row}
          column={column}
          isDragging={isDragging}
          width={width}
          isLastColumn={isLastColumn}
        />
      );
    case ClientsTabColumnsType.fat7DayAverage:
    case ClientsTabColumnsType.protein7DayAverage:
    case ClientsTabColumnsType.carbs7DayAverage:
    case ClientsTabColumnsType.calories7DayAverage:
      return (
        <NutritionCell
          row={row}
          column={column}
          isDragging={isDragging}
          width={width}
          isLastColumn={isLastColumn}
        />
      );
    case ClientsTabColumnsType.callsLeft:
      return (
        <CallsLeftCell
          row={row}
          isDragging={isDragging}
          width={width}
          isLastColumn={isLastColumn}
          updateCalls={updateClientCallsLeft}
        />
      );
    default:
      return (
        <TextCell
          row={row}
          column={column}
          isDragging={isDragging}
          width={width}
          isLastColumn={isLastColumn}
        />
      );
  }
};

const mapDispatchToProps = (dispatch) => ({
  updateClientCallsLeft: (clientId: string, callsLeft: number) => {
    dispatch(updateClientCallsLeft(clientId, callsLeft));
  },
});

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  columnName: ownProps.columnName,
  isLastColumn: ownProps.isLastColumn,
  isDragging: ownProps.isDragging,
  isLastRow: ownProps.isLastRow,
  width: ownProps.width,
  column: ownProps.column,
  row: ownProps.row,
});

export default connect(mapStateToProps, mapDispatchToProps)(BodyCell);
