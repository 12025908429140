/* eslint-disable react/prop-types */
import { format } from "date-fns";
import React, { useState } from "react";
import { FileMessage, UserMessage } from "sendbird";
import colors from "../../assets/colors";
import MessageShortcutMenu from "../MessageShortcutMenu";
import MessageEmoji from "./MessageEmoji";
import { formattedTime } from "../../utils";

interface Props {
  onDelete: () => void;
  addReaction: (message: UserMessage | FileMessage, emojiKey: string) => void;
  setEmojiMenuOpen: (isClosed: boolean) => void;
  message: UserMessage | FileMessage;
  showProfilePic?: boolean;
  isMasquerading?: boolean;
  isDeletable?: boolean;
  emojiMenuOpen?: boolean;
}

interface CardioFeedbackMessageDataWrapper {
  cardioActivityCalendarItem: CardioFeedbackMessageData;
}

interface CardioFeedbackMessageData {
  title: string;
  calories?: number;
  distance?: number;
  level?: number;
  time?: number;
}

const TextMessageItem = (props: Props) => {
  const {
    onDelete,
    addReaction,
    setEmojiMenuOpen,
    message,
    showProfilePic,
    isMasquerading,
    isDeletable,
    emojiMenuOpen,
  } = props;
  const textMessage = message.isUserMessage() ? (message as UserMessage) : null;
  const [hovered, setHovered] = useState(false);

  const whatTimeIsIt = (message: UserMessage | FileMessage) => {
    const createdDate = new Date(message.createdAt);
    return format(createdDate, "h:mm aa").toLowerCase();
  };
  const onMouseEnter = () => {
    setHovered(true);
  };
  const onMouseLeave = () => {
    if (!emojiMenuOpen) {
      setHovered(false);
    }
  };

  const linkify = (text: string) => {
    const urlRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    const array = [];
    const matches = [...text.matchAll(urlRegex)];
    let startIndex = 0;
    matches.forEach((oneMatch) => {
      const { index } = oneMatch;
      const matchedString = oneMatch[0];
      const { length } = matchedString;
      array.push(text.slice(startIndex, index));
      array.push(
        <a key={matchedString} href={matchedString} target="_blank">
          {matchedString}
        </a>
      );
      startIndex = index + length;
    });
    if (startIndex !== 0) {
      array.push(text.slice(startIndex, text.length));
    }
    return array.length === 0 ? text : array;
  };

  const badgeStyle: React.CSSProperties = {
    fontSize: "12px",
    lineHeight: "14px",
    letterSpacing: "-0.022em",
    padding: "2px 8px",
    borderRadius: 4,
    color: colors.caliber_white,
    marginRight: 12,
    marginBottom: 6,
  };
  const badgeStyleWhite = {
    ...badgeStyle,
    color: colors.caliber_blue,
    backgroundColor: colors.caliber_white,
  };
  const centeredClassname =
    "d-flex justify-content-center align-items-center medium-bold";

  const processWorkoutRatingMessage = (data: any) => {
    const rating = data?.workoutCalendarItem?.rating;
    const title = data?.workoutCalendarItem?.title;
    return (
      <div className="d-flex justify-content-end">
        {rating && (
          <div
            className={centeredClassname}
            style={{ ...badgeStyle, backgroundColor: "#F73843" }}
          >
            {`RPE・${rating}`}
          </div>
        )}
        <div
          className={centeredClassname}
          style={{ ...badgeStyle, backgroundColor: "#52587F" }}
        >
          {title}
        </div>
      </div>
    );
  };

  const processCardioFeedbackMessage = (
    data: CardioFeedbackMessageDataWrapper
  ) => {
    const cardioData = data.cardioActivityCalendarItem;
    // Prevent crash when a message mistakenly comes through as cardio_feedback
    if (!cardioData) {
      return null;
    }
    let avgPace: string;
    if (cardioData.time && cardioData.distance) {
      avgPace = formattedTime(
        Math.floor(cardioData.time / cardioData.distance)
      );
    }

    return (
      <div className="d-flex justify-content-end">
        <div
          className={centeredClassname}
          style={{ ...badgeStyle, backgroundColor: "#52587F" }}
        >
          {cardioData.title}
        </div>
        {cardioData.calories > 0 && (
          <div className={centeredClassname} style={badgeStyleWhite}>
            Calories: {cardioData.calories}
          </div>
        )}
        {cardioData.distance > 0 && (
          <div className={centeredClassname} style={badgeStyleWhite}>
            Distance: {cardioData.distance}mi
          </div>
        )}
        {cardioData.level > 0 && (
          <div className={centeredClassname} style={badgeStyleWhite}>
            Level: {cardioData.level}
          </div>
        )}
        {cardioData.time > 0 && (
          <div className={centeredClassname} style={badgeStyleWhite}>
            Time: {formattedTime(cardioData.time, true)}
          </div>
        )}
        {avgPace && (
          <div className={centeredClassname} style={badgeStyleWhite}>
            Avg Pace: {avgPace}/mi
          </div>
        )}
      </div>
    );
  };

  const ratingBadges = (message: UserMessage) => {
    if (
      message?.customType !== "workout_rating" &&
      message?.customType !== "cardio_feedback"
    ) {
      return null;
    }
    let data: any;
    try {
      data = JSON.parse(message?.data?.replace(/\\/g, ""));
    } catch (error) {
      /* error */
    }
    return message.customType === "workout_rating"
      ? processWorkoutRatingMessage(data)
      : processCardioFeedbackMessage(data);
  };

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{
        whiteSpace: "pre-line",
        wordBreak: "break-word",
        fontSize: "14px",
        lineHeight: "20px",
        color: colors.caliber_secondary,
        borderRadius: 8,
        backgroundColor: "#D9DCE2",
        padding: "14px 12px",
        marginRight: 88,
        marginBottom: message?.reactions?.[0] ? 35 : 8,
        position: "relative",
      }}
    >
      {ratingBadges(message as UserMessage)}
      {linkify(textMessage.message)}
      {!showProfilePic && hovered && (
        <div
          className="medium-bold"
          style={{
            color: colors.caliber_secondary_gray_47,
            fontSize: "12px",
            lineHeight: "12px",
            position: "absolute",
            top: 18,
            right: -54,
          }}
        >
          {whatTimeIsIt(message)}
        </div>
      )}
      {hovered && !isMasquerading && (
        <MessageShortcutMenu
          key="message_shortcut_menu_text"
          toggleMenu={setEmojiMenuOpen}
          onClose={() => {
            setHovered(false);
            setEmojiMenuOpen(false);
          }}
          isDeletable={isDeletable}
          selectedReactions={message?.reactions}
          onDelete={onDelete}
          addReaction={(emojiKey: string) => {
            addReaction(message, emojiKey);
            setHovered(false);
            setEmojiMenuOpen(false);
          }}
        />
      )}
      {message?.reactions?.length > 0 && (
        <MessageEmoji
          reactions={message.reactions}
          reactionTapped={(emojiKey) => {
            if (!isMasquerading) {
              addReaction(message, emojiKey);
            }
          }}
        />
      )}
    </div>
  );
};

export default TextMessageItem;
