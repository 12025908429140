import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import {
  eachDayOfInterval,
  format,
  isBefore,
  parse,
  subDays,
  subMonths,
} from "date-fns";
import { connect } from "react-redux";
import {
  CalendarProgressItems,
  getCalendarProgressItems,
} from "../../redux/actions/Calendar";
import { StoreState } from "../../redux/reducers";
import {
  CalendarDay,
  CalendarItemType,
  ClientNutritionTarget,
  ImportedNutritionCalendarItem,
  MacronutrientType,
} from "../../types/gqlTypes";
import Loader from "../Loader";
import Dropdown from "../Dropdown";
import NutritionChart, {
  ChartDataType,
  ChartTypes,
  ValueType,
} from "./NutritionChart";

interface Props {
  calendarData: CalendarDay[];
  isLoading: boolean;
  clientNutritionTarget: ClientNutritionTarget;
  getNutritionCalendarItems: (
    clientId: string,
    startDate: Date,
    endDate: Date
  ) => void;
}

enum Periods {
  WEEK,
  TWO_WEEK,
  MONTH,
  YEAR,
}

type NutritionDailyAverages = {
  [key in MacronutrientType]: number;
};

interface NutritionProgressContextType {
  nutritionDailyAverages: NutritionDailyAverages;
}

export const NutritionProgressContext = createContext(
  {} as NutritionProgressContextType
);

const NutritionProgress = (props: Props) => {
  const {
    calendarData,
    isLoading,
    clientNutritionTarget,
    getNutritionCalendarItems,
  } = props;

  const { id: clientId } = useParams();
  const [period, setPeriod] = useState<Periods>(Periods.TWO_WEEK);

  const getStartDate = (period: Periods, endDate: Date) => {
    let startDate;
    switch (period) {
      case Periods.WEEK:
        startDate = subDays(endDate, 6);
        break;
      case Periods.TWO_WEEK:
        startDate = subDays(endDate, 13);
        break;
      case Periods.MONTH:
        startDate = subDays(endDate, 30);
        break;
      case Periods.YEAR:
        startDate = subMonths(endDate, 12);
        startDate = subDays(startDate, 1);
        break;
    }
    return startDate;
  };
  const getChartData = useCallback(() => {
    const endDate = subDays(new Date(), 1);
    const startDate = getStartDate(period, endDate);
    getNutritionCalendarItems(clientId, startDate, endDate);
  }, [period]);

  useEffect(() => {
    getChartData();
  }, [period]);

  const dropdownPeriodOptions = [
    { text: "Last 7 days", value: Periods.WEEK },
    { text: "Last 14 days", value: Periods.TWO_WEEK },
    { text: "Last 1 month", value: Periods.MONTH },
    { text: "Last 1 year", value: Periods.YEAR },
  ];

  const addMacroData = (data, labels, date, values) => {
    const nutritions = [
      { type: MacronutrientType.Calories, target: "calories" },
      { type: MacronutrientType.Fat, target: "fat" },
      { type: MacronutrientType.Carbs, target: "carbs" },
      { type: MacronutrientType.Protein, target: "protein" },
    ];
    /* eslint-disable no-param-reassign */
    if (!values) {
      nutritions.forEach(({ type }) => {
        data?.[type]?.y?.push(null);
        data?.[type]?.excess?.push(null);
      });
      data.MACROS[MacronutrientType.Fat].push(null);
      data.MACROS[MacronutrientType.Carbs].push(null);
      data.MACROS[MacronutrientType.Protein].push(null);
    } else {
      let macrosSum = 0;
      const macros = {
        [MacronutrientType.Fat]: 0,
        [MacronutrientType.Carbs]: 0,
        [MacronutrientType.Protein]: 0,
      };
      nutritions.forEach(({ type, target }) => {
        const y = Math.min(
          values?.[type],
          clientNutritionTarget?.[target] || 0
        );
        const excess = Math.max(
          values?.[type] - (clientNutritionTarget?.[target] || 0),
          0
        );
        data[type].y.push(y);
        data[type].excess.push(excess);
        data[type].maxValue = Math.max(data[type].maxValue, values[type]);
        data[type].sum += values[type];
        data[type].quantity += 1;
        if (type !== MacronutrientType.Calories) {
          const multiplier = MacronutrientType.Fat ? 9 : 4;
          macrosSum += (y + excess) * multiplier;
          macros[type] = (y + excess) * multiplier;
        }
      });
      const fat = Math.round((macros[MacronutrientType.Fat] * 100) / macrosSum);
      const carbs = Math.round(
        (macros[MacronutrientType.Carbs] * 100) / macrosSum
      );
      data.MACROS[MacronutrientType.Fat].push(fat);
      data.MACROS[MacronutrientType.Carbs].push(carbs);
      data.MACROS[MacronutrientType.Protein].push(100 - fat - carbs);
    }
    /* eslint-enable no-param-reassign */
    labels.push(format(date, "MMM dd"));
    return { newData: data, newLabels: labels };
  };
  const normalizedData = useMemo(() => {
    let labels: string[] = [];
    let data: ChartDataType = {
      [MacronutrientType.Calories]: {
        y: [],
        excess: [],
        maxValue: 0,
        sum: 0,
        quantity: 0,
      },
      [MacronutrientType.Fat]: {
        y: [],
        excess: [],
        maxValue: 0,
        sum: 0,
        quantity: 0,
      },
      [MacronutrientType.Carbs]: {
        y: [],
        excess: [],
        maxValue: 0,
        sum: 0,
        quantity: 0,
      },
      [MacronutrientType.Protein]: {
        y: [],
        excess: [],
        maxValue: 0,
        sum: 0,
        quantity: 0,
      },
      MACROS: {
        [MacronutrientType.Fat]: [],
        [MacronutrientType.Carbs]: [],
        [MacronutrientType.Protein]: [],
      },
    };
    const yesterday = subDays(new Date(), 1);
    let prevDate = getStartDate(period, yesterday);
    calendarData?.map((calendarDay) => {
      calendarDay?.items?.map((item: ImportedNutritionCalendarItem) => {
        if (item.enumType === CalendarItemType.ImportedNutrition) {
          const date = parse(calendarDay.date, "yyyy-MM-dd", new Date());
          if (isBefore(prevDate, date)) {
            const range = eachDayOfInterval({ start: prevDate, end: date });
            if (range.length > 2) {
              // if non consecutive days
              range.forEach((rangeDate, index) => {
                if (index > 0 && index !== range.length - 1) {
                  const { newData, newLabels } = addMacroData(
                    data,
                    labels,
                    rangeDate,
                    null
                  );
                  data = newData;
                  labels = newLabels;
                }
              });
            }
          }
          const macros = {
            [MacronutrientType.Calories]: 0,
            [MacronutrientType.Fat]: 0,
            [MacronutrientType.Carbs]: 0,
            [MacronutrientType.Protein]: 0,
          };
          item?.macronutrientEvents?.forEach(({ macronutrient, value }) => {
            macros[macronutrient] = value;
          });
          const { newData, newLabels } = addMacroData(
            data,
            labels,
            date,
            macros
          );
          data = newData;
          labels = newLabels;

          prevDate = date;
        }
      });
    });
    if (labels.length === 0) {
      // if no data - add null values for range of days
      const range = eachDayOfInterval({ start: prevDate, end: yesterday });
      range.forEach((rangeDate) => {
        const { newData, newLabels } = addMacroData(
          data,
          labels,
          rangeDate,
          null
        );
        data = newData;
        labels = newLabels;
      });
    } else if (isBefore(prevDate, yesterday)) {
      // if last calendarItem was before today
      const range = eachDayOfInterval({ start: prevDate, end: yesterday });
      range.forEach((rangeDate, index) => {
        if (index > 0) {
          const { newData, newLabels } = addMacroData(
            data,
            labels,
            rangeDate,
            null
          );
          data = newData;
          labels = newLabels;
        }
      });
    }
    return { data, labels };
  }, [calendarData]);

  const calcNutritionDailyAverage = (chartData: ValueType) => {
    const combinedChartData = [];
    chartData.y.forEach((value, index) => {
      const combinedValue = value + chartData.excess[index];
      if (combinedValue !== 0) {
        combinedChartData.push(combinedValue);
      }
    });
    const average =
      combinedChartData.reduce((acc, val) => acc + val, 0) /
      combinedChartData.length;
    return Math.round(average);
  };

  const nutritionDailyAverages: NutritionDailyAverages = {
    CALORIES: calcNutritionDailyAverage(
      normalizedData.data[MacronutrientType.Calories]
    ),
    PROTEIN: calcNutritionDailyAverage(
      normalizedData.data[MacronutrientType.Protein]
    ),
    CARBS: calcNutritionDailyAverage(
      normalizedData.data[MacronutrientType.Carbs]
    ),
    FAT: calcNutritionDailyAverage(normalizedData.data[MacronutrientType.Fat]),
  };

  return (
    <div
      className="d-flex flex-column"
      style={{
        flex: 1,
        minWidth: 0,
        marginBottom: 20,
        position: "relative",
      }}
    >
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: "40%",
            left: "calc(50% - 24px)",
          }}
        >
          <Loader />
        </div>
      )}
      <div
        style={{
          width: 250,
          position: "absolute",
          right: 0,
          top: 0,
        }}
      >
        <Dropdown
          value={period}
          items={dropdownPeriodOptions}
          onSelect={(value) => setPeriod(value)}
          height={36}
          textColor="#82869B"
        />
      </div>
      <NutritionProgressContext.Provider
        value={{ nutritionDailyAverages: nutritionDailyAverages }}
      >
        <NutritionChart
          key={ChartTypes.CALORIES}
          clientNutritionTarget={clientNutritionTarget}
          labels={normalizedData.labels}
          data={normalizedData.data}
          chartType={ChartTypes.CALORIES}
        />
        <NutritionChart
          key={ChartTypes.MACROS}
          clientNutritionTarget={clientNutritionTarget}
          labels={normalizedData.labels}
          data={normalizedData.data}
          chartType={ChartTypes.MACROS}
        />
        <NutritionChart
          key={ChartTypes.PROTEIN}
          clientNutritionTarget={clientNutritionTarget}
          labels={normalizedData.labels}
          data={normalizedData.data}
          chartType={ChartTypes.PROTEIN}
        />
        <NutritionChart
          key={ChartTypes.FAT}
          clientNutritionTarget={clientNutritionTarget}
          labels={normalizedData.labels}
          data={normalizedData.data}
          chartType={ChartTypes.FAT}
        />
        <NutritionChart
          key={ChartTypes.CARBS}
          clientNutritionTarget={clientNutritionTarget}
          labels={normalizedData.labels}
          data={normalizedData.data}
          chartType={ChartTypes.CARBS}
        />
      </NutritionProgressContext.Provider>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getNutritionCalendarItems: (
    clientId: string,
    startDate: Date,
    endDate: Date
  ) => {
    dispatch(
      getCalendarProgressItems(
        clientId,
        startDate,
        endDate,
        CalendarProgressItems.NUTRITION
      )
    );
  },
});

const mapStateToProps = (state: StoreState) => ({
  calendarData: state.calendarDataState.calendarItemsByDay,
  isLoading: state.calendarDataState.isLoading,
  clientNutritionTarget: state.clientNutritionTargetState.clientNutritionTarget,
});

export default connect(mapStateToProps, mapDispatchToProps)(NutritionProgress);
