import React from "react";
import { connect } from "react-redux";
import { StoreState } from "../../redux/reducers";
import { StrengthScoreDashboard } from "../../types/gqlTypes";

interface Props {
  strengthScoreDashboard: StrengthScoreDashboard;
}

const DebugStrengthScore = (props: Props) => {
  const { strengthScoreDashboard } = props;
  return (
    <div
      style={{
        whiteSpace: "pre-line",
        margin: 16,
      }}
    >
      {strengthScoreDashboard?.explanationText}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state: StoreState) => ({
  strengthScoreDashboard:
    state.clientStrengthDashboardState.strengthScoreDashboard,
});

export default connect(mapStateToProps, mapDispatchToProps)(DebugStrengthScore);
