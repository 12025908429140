import { parseISO } from "date-fns";
import React from "react";
import Moment from "react-moment";
import colors from "../../../assets/colors";
import { ClientsTabColumnsType } from "../../../redux/actions/ClientsTab/types";
import { ClientSearchResult } from "../../../types/gqlTypes";

interface Props {
  isLastColumn?: boolean;
  isDragging?: boolean;
  width?: number;
  column: ClientsTabColumnsType;
  row: ClientSearchResult;
}

const PackageEndDateCell = (props: Props) => {
  const { isLastColumn, isDragging, width, row, column } = props;

  const text = row?.[column];
  const color = colors.black;
  const borderColor = colors.table_gray;
  const rowHeight = 64;
  const packageEndDate = text ? parseISO(text) : null;

  return (
    <td
      className="d-flex"
      style={{
        width,
        minWidth: isLastColumn && width,
        backgroundColor: colors.caliber_gray_bg,
        position: "relative",
      }}
    >
      <div
        className="d-flex"
        style={{
          position: "absolute",
          top: isDragging ? -8 : 0,
          backgroundColor: isDragging ? "#E8EAEE" : "transparent",
          width: "100%",
          height: isDragging ? rowHeight + 16 : rowHeight,
          zIndex: isDragging ? 4 : 1,
          marginBottom: isDragging ? 0 : 8,
        }}
      >
        <div
          className="d-flex align-items-center"
          style={{
            flex: "1 1 100%",
            fontSize: "14px",
            lineHeight: "20px",
            color,
            zIndex: isDragging ? 3 : 2,
            backgroundColor: colors.caliber_white,
            height: rowHeight,
            borderRight: `1px solid ${borderColor}`,
            paddingLeft: 18,
            margin: isDragging ? 8 : 0,
            borderTopRightRadius: isLastColumn && 8,
            borderBottomRightRadius: isLastColumn && 8,

            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {packageEndDate ? <Moment fromNow>{packageEndDate}</Moment> : "n/a"}
        </div>
      </div>
    </td>
  );
};

export default PackageEndDateCell;
