export const UPDATE_UNREAD_MESSAGE_COUNT = "UPDATE_UNREAD_MESSAGE_COUNT";

export interface UnreadMessageCountState {
  unreadMessageCount: number;
}

export interface UnreadMessageCountAction {
  type: string;
  unreadMessageCount: number;
}
