import React, { useMemo } from "react";
import { format } from "date-fns";
import {
  ProgressPhotoCalendarItem,
  PoseType,
  CalendarDay,
} from "../../types/gqlTypes";
import colors from "../../assets/colors";
import ProgressIcon from "../../assets/images/ProgressIcon.svg";
import Modal from "../Modal";
import Loader from "../Loader";

interface Props {
  date: Date;
  isLoading: boolean;
  itemId: string;
  calendarDay: CalendarDay;
  onClose: () => void;
}

const CalendarProgressModal = (props: Props) => {
  const { date, isLoading, itemId, calendarDay, onClose } = props;

  const poseArray = [PoseType.Front, PoseType.Side, PoseType.Back];
  const poseObject = {
    [PoseType.Front]: "Front",
    [PoseType.Side]: "Side",
    [PoseType.Back]: "Back",
  };

  const pictures = useMemo(() => {
    const item = calendarDay?.items.find(
      (item) => item?.id === itemId
    ) as ProgressPhotoCalendarItem;
    const obj = {};
    item?.photos?.map((photo) => {
      obj[photo?.pose] = photo?.poseMediaUrl?.url;
    });
    return obj;
  }, [isLoading, itemId]);

  return (
    <Modal onClose={onClose} closeOnDimmerClick>
      <div
        className="d-flex flex-column"
        style={{
          flex: 1,
          padding: 24,
          minWidth: 300,
          minHeight: 250,
          position: "relative",
        }}
      >
        {isLoading && (
          <div
            style={{
              position: "absolute",
              top: "48%",
              left: "48%",
            }}
          >
            <Loader />
          </div>
        )}
        <div
          className="medium-bold"
          style={{
            color: colors.caliber_secondary,
            fontSize: "12px",
            lineHeight: "24px",
          }}
        >
          {`${format(date, "EEEE")}・${format(date, "MMM dd yyyy")}`}
        </div>
        <div
          className="d-flex align-items-center medium-bold"
          style={{
            color: colors.caliber_secondary,
            marginTop: 5,
            fontSize: "20px",
            lineHeight: "24px",
          }}
        >
          <img
            style={{ width: 24, height: 24, marginRight: 10 }}
            src={ProgressIcon}
          />
          Progress Photos
        </div>
        <div
          style={{
            flex: 1,
            marginTop: 20,
            marginBottom: 20,
            overflowX: "auto",
            maxWidth: 740,
            position: "relative",
          }}
          className="d-flex"
        >
          {poseArray.map((pose, index) => {
            return (
              pictures[pose] && (
                <div
                  className="d-flex flex-column"
                  style={{
                    margin: 8,
                    borderRadius: 8,
                  }}
                >
                  <img
                    style={{
                      maxHeight: 500,
                      borderRadius: 8,
                    }}
                    src={pictures[pose]}
                  />
                  <div
                    style={{
                      flex: 1,
                      height: 24,
                      marginTop: 8,
                      fontSize: "12px",
                      lineHeight: "24px",
                      textAlign: "center",
                      color: colors.caliber_gray_47,
                    }}
                  >
                    {poseObject[pose]}
                  </div>
                </div>
              )
            );
          })}
        </div>
      </div>
    </Modal>
  );
};
export default CalendarProgressModal;
