import React, { useState } from "react";
import colors from "../../../assets/colors";
import RadioButton from "../../Icons/RadioButton";

interface Props {
  text?: string;
  value?: string;
  isSelected?: boolean;
  index?: number;
  onBlur: () => void;
  onSelect: (value: string) => void;
}

const DropdownItemComponent = (props: Props) => {
  const { text, value, isSelected, index, onBlur, onSelect } = props;

  const [hovered, setHovered] = useState(false);

  let backgroundColor = colors.caliber_white;
  if (hovered) {
    backgroundColor = colors.caliber_gray_2;
  }
  if (isSelected) {
    backgroundColor = colors.caliber_gray_bg;
  }

  return (
    <div className="d-flex flex-column">
      <div
        key={`search_coach_dropdown_${text}-${value}`}
        id="dropdown_element"
        role="button"
        tabIndex={0}
        onBlur={onBlur}
        onClick={() => {
          onSelect(value);
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            onSelect(value);
          }
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        className="nofocus d-flex justify-content-between align-items-center pointer"
        style={{
          zIndex: 3,
          flex: 1,
          paddingLeft: 8,
          paddingRight: 8,
          minHeight: 32,
          margin: 3,
          borderRadius: 8,
          backgroundColor,
          color: colors.caliber_secondary,
        }}
      >
        <div>{text}</div>
        <RadioButton
          id="dropdown_element"
          checked={isSelected}
          onClick={() => onSelect(value)}
        />
      </div>
      {index === 0 && (
        <div
          style={{
            height: 1,
            backgroundColor: colors.caliber_secondary_gray_11,
          }}
        />
      )}
    </div>
  );
};

export default DropdownItemComponent;
