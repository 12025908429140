import React from "react";
import SimpleButton from "../SimpleButton";

interface Props {
  checked: boolean;
  disabled?: boolean;
  color?: string;
  width?: number;
  height?: number;
  type?: "filled";
  onClick: () => void;
}

const Checkbox = (props: Props) => {
  const { checked, onClick, disabled, color, width, height, type } = props;

  let fillColor = color || "#01052F";
  if (disabled) {
    fillColor = "C2C3CD";
  }

  if (type === "filled") {
    return (
      <SimpleButton disabled={disabled} nofocus onClick={onClick}>
        {checked ? (
          <svg
            style={{ display: "block" }}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="20" height="20" rx="4" fill="#3446FB" />
            <path
              d="M7.56737 13.9588L7.56735 13.9588L4.51411 10.9055L4.51408 10.9055C4.02864 10.4203 4.02864 9.63252 4.51408 9.1473L4.51414 9.14725C4.99933 8.66182 5.78712 8.66179 6.27261 9.14728L6.27262 9.14729L8.09175 10.9662L8.09184 10.9662C8.28778 11.1618 8.60545 11.1617 8.80152 10.9663L8.80172 10.9661L13.7274 6.04048L13.7274 6.04045C14.2126 5.55502 15.0004 5.55498 15.4859 6.04048C15.719 6.2736 15.85 6.58992 15.85 6.9196C15.85 7.24927 15.719 7.56559 15.4859 7.79872L9.32585 13.9588C8.84036 14.4442 8.05257 14.4442 7.56737 13.9588Z"
              fill="white"
              stroke="white"
              strokeWidth="0.3"
            />
          </svg>
        ) : (
          <svg
            style={{ display: "block" }}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.5"
              y="0.5"
              width="19"
              height="19"
              rx="3.5"
              fill="white"
              stroke="#AEB1BF"
            />
          </svg>
        )}
      </SimpleButton>
    );
  }

  return (
    <SimpleButton disabled={disabled} nofocus onClick={onClick}>
      <svg
        style={{ display: "block" }}
        width={width || "16"}
        height={height || "16"}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.5"
          y="0.5"
          width="15"
          height="15"
          rx="3.5"
          fill="white"
          stroke={fillColor}
        />
        {checked && (
          <path
            d="M5.56737 11.959L5.56735 11.959L2.51411 8.90576L2.51408 8.90574C2.02864 8.42053 2.02864 7.63276 2.51408 7.14755L2.51414 7.1475C2.99933 6.66207 3.78712 6.66203 4.27261 7.14752L4.27262 7.14753L6.09175 8.9664L6.09184 8.96649C6.28778 9.16205 6.60545 9.16191 6.80152 8.96659L6.80172 8.96639L11.7274 4.04072L11.7274 4.0407C12.2126 3.55526 13.0004 3.55523 13.4859 4.04072C13.719 4.27385 13.85 4.59017 13.85 4.91984C13.85 5.24951 13.719 5.56584 13.4859 5.79896L7.32585 11.959C6.84036 12.4445 6.05257 12.4445 5.56737 11.959Z"
            fill={fillColor}
            stroke="white"
            strokeWidth="0.3"
          />
        )}
      </svg>
    </SimpleButton>
  );
};

export default Checkbox;
