export const ADD_RECENT_EMOJI = 'ADD_RECENT_EMOJI';

export const MAX_RECENT_EMOJIS = 21;

export interface RecentEmojisAction {
  type: string,
  emoji: string,
}

export interface RecentEmojisState {
  recentEmojis: string[],
}
