import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import colors from "../../../assets/colors";
import {
  changeColumnsInClientsTab,
  closeSettingsInClientsTab,
  resetColumnsViewInClientsTab,
} from "../../../redux/actions/ClientsTab";
import { ClientsTabColumnsType } from "../../../redux/actions/ClientsTab/types";
import {
  getIsAdminOrManager,
  getIsMasquerade,
  StoreState,
} from "../../../redux/reducers";
import { ClientSearchResultSortCriteria } from "../../../types/gqlTypes";
import { HEADER_HEIGHT } from "../../Header";
import Header from "./Header";
import ListItem from "./ListItem";
import Memo from "./Memo";
import ResetButton from "./ResetButton";
import SectionHeader from "./SectionHeader";

interface Props {
  columns: string[];
  isSettingsOpen: boolean;
  isAdminOrManager: boolean;
  isMasquerade: boolean;
  onSave: (columns: ClientSearchResultSortCriteria[]) => void;
  onClose?: () => void;
  onResetView: () => void;
}

const UserTableSettings = (props: Props) => {
  const {
    columns,
    isSettingsOpen,
    isAdminOrManager,
    isMasquerade,
    onSave,
    onClose,
    onResetView,
  } = props;

  const [hasChanges, setHasChanged] = useState(false);
  const [foldedSections, setFoldedSections] = useState({});
  const [checkedColumns, setCheckedColumns] = useState({});

  useEffect(() => {
    if (isSettingsOpen) {
      const obj = {};
      columns.forEach((column) => {
        obj[column] = true;
      });
      setCheckedColumns(obj);
      setHasChanged(false);
    }
  }, [columns, isSettingsOpen]);

  const onSaveClick = () => {
    onSave(
      Object.keys(checkedColumns).filter(
        (col) => checkedColumns[col]
      ) as ClientSearchResultSortCriteria[]
    );
  };
  const onCheck = (column) => {
    setHasChanged(true);
    setCheckedColumns({
      ...checkedColumns,
      [column]: !checkedColumns[column],
    });
  };
  const onFold = (title) => {
    setFoldedSections({
      ...foldedSections,
      [title]: !foldedSections[title],
    });
  };
  const fields = {
    Activity: [
      { title: "Active", value: ClientsTabColumnsType.lastActiveDate },
      {
        title: "Message received",
        value: ClientsTabColumnsType.lastDateClientSentMessage,
      },
      {
        title: "Message sent",
        value: ClientsTabColumnsType.lastDateClientReceivedMessage,
      },
      {
        title: "Workout completed",
        value: ClientsTabColumnsType.mostRecentlyCompletedWorkoutDate,
      },
      { title: "Calls left", value: ClientsTabColumnsType.callsLeft },
      {
        title: "Package end date",
        value: ClientsTabColumnsType.packageEndDate,
      },
    ],
    Profile: [
      { title: "Gender", value: ClientsTabColumnsType.gender },
      { title: "Weight", value: ClientsTabColumnsType.weight },
      { title: "Age", value: ClientsTabColumnsType.age },
      { title: "Height", value: ClientsTabColumnsType.height },
      { title: "Status", value: ClientsTabColumnsType.status },
      { title: "Package type", value: ClientsTabColumnsType.clientPackageType },
      { title: "Member since", value: ClientsTabColumnsType.joinedDate },
    ],
    "Strength Metrics": [
      { title: "Strength score", value: ClientsTabColumnsType.strengthScore },
      {
        title: "Strength balance",
        value: ClientsTabColumnsType.strengthBalance,
      },
      {
        title: "Legs score",
        value: ClientsTabColumnsType.legsMuscleGroupScore,
      },
      {
        title: "Chest score",
        value: ClientsTabColumnsType.chestMuscleGroupScore,
      },
      {
        title: "Back score",
        value: ClientsTabColumnsType.backMuscleGroupScore,
      },
      {
        title: "Shoulders score",
        value: ClientsTabColumnsType.shouldersMuscleGroupScore,
      },
      {
        title: "Arms score",
        value: ClientsTabColumnsType.armsMuscleGroupScore,
      },
    ],
    Nutrition: [
      {
        title: "Calories (7-day)",
        value: ClientsTabColumnsType.calories7DayAverage,
      },
      {
        title: "Protein (7-day)",
        value: ClientsTabColumnsType.protein7DayAverage,
      },
      { title: "Fat (7-day)", value: ClientsTabColumnsType.fat7DayAverage },
      { title: "Carbs (7-day)", value: ClientsTabColumnsType.carbs7DayAverage },
    ],
  };

  if (isAdminOrManager && !isMasquerade) {
    fields.Profile.push({
      title: "Coach",
      value: ClientsTabColumnsType.trainerFullName,
    });
  }

  const numberOfColumnsSelected = Object.keys(checkedColumns)?.length;

  if (!isSettingsOpen) {
    return null;
  }

  return (
    <div
      className="d-flex flex-column"
      style={{
        width: 448,
        borderLeft: "1px solid #DADCE3",
        backgroundColor: colors.caliber_white,
        paddingBottom: 24,
        height: window.innerHeight - HEADER_HEIGHT - 8,
        overflowY: "scroll",
        paddingTop: 16,
      }}
    >
      <Header
        onSave={onSaveClick}
        onClose={onClose}
        canBeSaved={hasChanges && numberOfColumnsSelected > 1}
      />

      <Memo />

      {Object.keys(fields).map((sectionTitle) => {
        const isFolded = foldedSections[sectionTitle];
        return (
          <div key={sectionTitle}>
            <SectionHeader
              title={sectionTitle}
              isFolded={isFolded}
              onClick={() => onFold(sectionTitle)}
            />
            {!isFolded &&
              fields?.[sectionTitle]?.map(({ title, value }) => {
                return (
                  <ListItem
                    key={title}
                    text={title}
                    isChecked={checkedColumns?.[value]}
                    onClick={() => onCheck(value)}
                  />
                );
              })}
          </div>
        );
      })}

      <ResetButton onReset={onResetView} />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onClose: () => {
    dispatch(closeSettingsInClientsTab());
  },
  onSave: (columns: ClientsTabColumnsType[]) => {
    dispatch(changeColumnsInClientsTab(columns));
  },
  onResetView: () => {
    dispatch(resetColumnsViewInClientsTab());
  },
});

const mapStateToProps = (state: StoreState) => ({
  columns: state.clientsTabState.columns,
  isSettingsOpen: state.clientsTabState.isSettingsOpen,
  isAdminOrManager: getIsAdminOrManager(state),
  isMasquerade: getIsMasquerade(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(UserTableSettings);
