import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import colors from "../../assets/colors";
import {
  copyTrainingPlanToClients,
  toggleCopyTrainingPlanToClients,
} from "../../redux/actions/TrainingPrograms/copy";
import { StoreState } from "../../redux/reducers";
import {
  ClientSearchResult,
  ClientTrainingPlan,
  MutationCopyTrainingPlanToClientsArgs,
} from "../../types/gqlTypes";
import ButtonTag from "../ButtonTag";
import Loader from "../Loader";
import Modal from "../Modal";
import ClientListToCopy from "./ClientListToCopy";
import CopyPlanToClientsConfirm from "./CopyPlanToClientsConfirm";

interface Props {
  isCopyingPlan: boolean;
  plan: ClientTrainingPlan;
  selectedClientId: string;
  copyTrainingPlanToClients: (
    args: MutationCopyTrainingPlanToClientsArgs
  ) => void;
  toggleCopyTrainingPlanToClients: () => void;
}

const CopyPlanToClients = (props: Props) => {
  const {
    isCopyingPlan,
    plan,
    selectedClientId: selectedClientId,
    copyTrainingPlanToClients,
    toggleCopyTrainingPlanToClients,
  } = props;

  const [confirmCopyDialog, setConfirmCopyDialog] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [selectedClients, setSelectedClients] = useState<{
    [key: string]: ClientSearchResult;
  }>({});

  const selectClient = (client: ClientSearchResult) => {
    const copy = { ...selectedClients };
    if (selectedClients?.[client?.id]) {
      delete copy[client?.id];
    } else {
      copy[client?.id] = client;
    }
    setSelectedClients(copy);
  };
  const successTextStyle = {
    fontSize: "18px",
    lineHeight: "24px",
    height: 100,
    "text-align": "center",
  };

  useEffect(() => {
    if (!isCopyingPlan && loadingDialog) {
      setSuccessDialog(true);
      setLoadingDialog(false);
    }
  }, [isCopyingPlan]);

  return (
    <div>
      <Modal onClose={() => {}}>
        {confirmCopyDialog && (
          <CopyPlanToClientsConfirm
            onCancel={() => setConfirmCopyDialog(false)}
            onCopy={() => {
              const destinationClientIds = Object.values(selectedClients).map(
                (client) => client?.id
              );
              if (plan?.id && destinationClientIds.length > 0) {
                copyTrainingPlanToClients({
                  trainingPlanId: plan?.id,
                  clientIds: destinationClientIds,
                  clientId: selectedClientId,
                });
                setLoadingDialog(true);
                setConfirmCopyDialog(false);
              }
            }}
            plan={plan}
            clients={Object.values(selectedClients)}
          />
        )}
        {successDialog && (
          <div
            className="d-flex flex-column justify-content-center align-items-center medium-bold"
            style={{
              height: 164,
              width: 500,
              padding: "24px 33px",
            }}
          >
            <div style={successTextStyle}>
              The program was successfully copied with an inactive plan. To
              edit, go to the pages of the clients training programs.
            </div>
            <ButtonTag
              text="Got it"
              onClick={toggleCopyTrainingPlanToClients}
              height={24}
              bgColor={colors.caliber_green_200}
              textColor={colors.caliber_white}
              minWidth={69}
            />
          </div>
        )}
        {loadingDialog && (
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{
              height: 200,
              width: 200,
            }}
          >
            <Loader />
          </div>
        )}
        {!confirmCopyDialog && !successDialog && !loadingDialog && (
          // @ts-ignore
          <ClientListToCopy
            clients={Object.values(selectedClients)}
            onSelectClient={selectClient}
            selectedClients={selectedClients}
            onCancel={toggleCopyTrainingPlanToClients}
            onCopy={() => setConfirmCopyDialog(true)}
            multiselect
          />
        )}
      </Modal>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  copyTrainingPlanToClients: (args: MutationCopyTrainingPlanToClientsArgs) => {
    dispatch(copyTrainingPlanToClients(args));
  },
  toggleCopyTrainingPlanToClients: () => {
    dispatch(toggleCopyTrainingPlanToClients());
  },
});

const mapStateToProps = (state: StoreState) => ({
  plan: state.trainingProgramsState.copyPlanToClients,
  isCopyingPlan: state.trainingProgramsState.isCopying,
  selectedClientId: state.clientDetailState.user.id,
});

export default connect(mapStateToProps, mapDispatchToProps)(CopyPlanToClients);
