import React, { useState } from "react";
import { connect } from "react-redux";
import Modal from "../Modal";
import { StoreState } from "../../redux/reducers";
import { selectCurrentWorkoutGroups } from "../../redux/reducers/TrainingPrograms/selectors";
import {
  Client,
  ClientWorkoutGroupTemplate,
  WorkoutGroupType,
} from "../../types/gqlTypes";
import SimpleButton from "../SimpleButton";
import colors from "../../assets/colors";
import AddWorkoutGroupItem from "../WorkoutGroupList/AddWorkoutGroupItem";
import WorkoutGroupGymIcon from "../../assets/images/WorkoutGroupGymIcon.svg";
import WorkoutGroupHomeIcon from "../../assets/images/WorkoutGroupHomeIcon.svg";
import WorkoutGroupFreestyleIcon from "../../assets/images/WorkoutGroupFreestyleIcon.svg";
import WorkoutGroupTravelIcon from "../../assets/images/WorkoutGroupTravelIcon.svg";
import { moveClientWorkoutTemplate } from "../../redux/actions/TrainingPrograms/reorder";

interface Props {
  workoutGroups: ClientWorkoutGroupTemplate[];
  selectedClient: Client;
  onClose: () => void;
  workoutId: string;
  currentWorkoutGroupId: string;
  moveClientWorkoutTemplate: (
    clientId: string,
    workoutId: string,
    workoutIds: [],
    destinationGroupId: string
  ) => void;
}

const MoveToGroupModal = (props: Props) => {
  const {
    workoutGroups,
    selectedClient,
    onClose,
    workoutId,
    currentWorkoutGroupId,
    moveClientWorkoutTemplate,
  } = props;

  const [selectedGroup, setSelectedGroup] = useState(null);

  return (
    <Modal onClose={onClose} closeOnDimmerClick>
      <div
        className="d-flex flex-column"
        style={{
          width: 480,
          height: 480,
          padding: "38px 38px 56px 38px",
        }}
      >
        <div
          className="heading-medium"
          style={{
            marginBottom: 12,
          }}
        >
          Select a new workout group
        </div>
        {workoutGroups
          .filter((group) => {
            return group.id !== currentWorkoutGroupId;
          })
          .map((group) => {
            let icon = "";
            switch (group.workoutGroupType) {
              case WorkoutGroupType.Gym:
                icon = WorkoutGroupGymIcon;
                break;
              case WorkoutGroupType.Home:
                icon = WorkoutGroupHomeIcon;
                break;
              case WorkoutGroupType.Freestyle:
                icon = WorkoutGroupFreestyleIcon;
                break;
              case WorkoutGroupType.Travel:
                icon = WorkoutGroupTravelIcon;
                break;
            }
            return (
              <AddWorkoutGroupItem
                key={group.id}
                icon={
                  <img
                    style={{
                      width: 32,
                      height: 32,
                      borderRadius: 8,
                    }}
                    src={icon}
                    alt={group.workoutGroupType}
                  />
                }
                title={group.title}
                isSelected={selectedGroup === group.id}
                onSelected={() => {
                  setSelectedGroup(group.id);
                }}
              />
            );
          })}
        <div
          className="d-flex"
          style={{
            justifyContent: "center",
            marginTop: 44,
          }}
        >
          <SimpleButton
            className="d-flex justify-content-center align-items-center medium-bold"
            style={{
              fontSize: "14px",
              lineHeight: "17px",
              letterSpacing: "-0.-22em",
              color: colors.caliber_white,
              backgroundColor: colors.caliber_gray_text,
              width: 99,
              height: 32,
              borderRadius: 8,
              marginRight: 16,
            }}
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </SimpleButton>
          <SimpleButton
            className="d-flex justify-content-center align-items-center medium-bold"
            style={{
              fontSize: "14px",
              lineHeight: "17px",
              letterSpacing: "-0.-22em",
              color: colors.caliber_white,
              backgroundColor: colors.caliber_green_200,
              width: 83,
              height: 32,
              borderRadius: 8,
            }}
            onClick={() => {
              const group = workoutGroups.find(
                (group) => group.id === selectedGroup
              );
              const workoutIds = group.clientWorkoutTemplates.map(
                (workout) => workout.id
              );
              workoutIds.push(workoutId);
              moveClientWorkoutTemplate(
                selectedClient.id,
                workoutId,
                // @ts-ignore
                workoutIds,
                selectedGroup
              );
              onClose();
            }}
            disabled={selectedGroup === null}
          >
            Move
          </SimpleButton>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: StoreState) => ({
  workoutGroups: selectCurrentWorkoutGroups(state),
  selectedClient: state.clientDetailState.user,
});

const mapDispatchToProps = (dispatch) => ({
  moveClientWorkoutTemplate: (
    clientId: string,
    workoutId: string,
    workoutIds: [],
    destinationGroupId: string
  ) => {
    dispatch(
      moveClientWorkoutTemplate(
        clientId,
        workoutId,
        workoutIds,
        destinationGroupId
      )
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MoveToGroupModal);
