import React from "react";
import colors from "../../../assets/colors";
import EditButton from "./EditButton";

interface Props {
  isSelected: boolean;
  onClick: () => void;
}

const EditCell = (props: Props) => {
  const { isSelected, onClick } = props;

  const color = colors.black;
  const borderColor = colors.table_gray;
  const rowHeight = 64;

  return (
    <td
      className="d-flex"
      style={{
        width: 56,
        backgroundColor: colors.caliber_gray_bg,
        position: "relative",
        flexGrow: 0,
      }}
    >
      <div
        className="d-flex"
        style={{
          position: "absolute",
          top: 0,
          backgroundColor: "transparent",
          width: "100%",
          height: rowHeight,
          zIndex: 1,
          marginBottom: 8,
        }}
      >
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            flex: "1 1 100%",
            fontSize: "14px",
            lineHeight: "20px",
            color,
            zIndex: 2,
            backgroundColor: colors.caliber_white,
            height: rowHeight,
            borderRight: `1px solid ${borderColor}`,
            borderBottomRightRadius: 8,
            borderTopRightRadius: 8,
            margin: 0,
          }}
        >
          <EditButton isSelected={isSelected} onClick={onClick} />
        </div>
      </div>
    </td>
  );
};

export default EditCell;
