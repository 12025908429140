import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  startOfMonth,
  differenceInMonths,
  subMonths,
  addMonths,
  getYear,
  getMonth,
  startOfWeek,
  addDays,
} from "date-fns";
import { connect } from "react-redux";
import { StoreState } from "../../redux/reducers";
import { CalendarDataState } from "../../redux/actions/Calendar/types";
import {
  closeCalendarFetchItemFailedDialog,
  getCalendarItems,
} from "../../redux/actions/Calendar";
import Calendar from "../../components/Calendar";
import colors from "../../assets/colors";
import { CalendarDay } from "../../types/gqlTypes";
import Modal from "../../components/Modal";
import ButtonTag from "../../components/ButtonTag";
import {
  getClientHabitsTargets,
  resetClientHabitTargetState,
} from "../../redux/actions/Habits";
import { getClientTrainingPlans } from "../../redux/actions/ClientPlans";

interface Props {
  getCalendarItems: (
    clientId: string,
    startDate: Date,
    endDate: Date,
    fetchDetailed: Boolean
  ) => void;
  closeCalendarFetchItemFailedDialog: () => void;
  getClientHabitsTargets: (clientId: string) => void;
  getClientTrainingPlans: (clientId: string) => void;
  resetClientHabitTargetState: () => void;
  calendarDataState: CalendarDataState;
  isLoading: boolean;
  calendarFetchItemFailed: boolean;
  calendarItemsByDay: CalendarDay[];
}

const TrainerClientCalendarTab = (props: Props) => {
  const {
    getCalendarItems,
    closeCalendarFetchItemFailedDialog,
    getClientHabitsTargets,
    getClientTrainingPlans,
    resetClientHabitTargetState,
    calendarItemsByDay,
    isLoading,
    calendarFetchItemFailed,
  } = props;

  const { id } = useParams();
  const maxMonthsToGo = 6;
  const [{ currentDate, currentMonth, currentYear }, setCurrent] = useState({
    currentDate: new Date(),
    currentYear: getYear(new Date()),
    currentMonth: getMonth(new Date()),
  });

  const goBack = () => {
    const difference = differenceInMonths(currentDate, new Date());
    if (difference >= 0 - maxMonthsToGo) {
      const now = subMonths(currentDate, 1);
      setCurrent({
        currentDate: now,
        currentYear: getYear(now),
        currentMonth: getMonth(now),
      });
    }
  };
  const goForth = () => {
    const difference = differenceInMonths(currentDate, new Date());
    if (difference <= maxMonthsToGo) {
      const now = addMonths(currentDate, 1);
      setCurrent({
        currentDate: now,
        currentYear: getYear(now),
        currentMonth: getMonth(now),
      });
    }
  };
  const goToday = () => {
    const now = new Date();
    setCurrent({
      currentDate: now,
      currentYear: getYear(now),
      currentMonth: getMonth(now),
    });
  };

  const getCalendar = () => {
    const startDate = startOfWeek(startOfMonth(currentDate), {
      weekStartsOn: 0,
    });
    const endDate = addDays(startDate, 34);
    getCalendarItems(id, startDate, endDate, false);
    getClientTrainingPlans(id);
  };

  useEffect(() => {
    getCalendar();
    getClientHabitsTargets(id);
    return () => {
      resetClientHabitTargetState();
    };
  }, [id, currentDate]);

  useEffect(() => {
    getClientHabitsTargets(id);
  }, [id]);

  return (
    <div
      style={{
        backgroundColor: colors.caliber_white,
        padding: 24,
        marginBottom: 70,
        minHeight: 730,
      }}
      className="d-flex col-16 bordered-item"
    >
      {calendarFetchItemFailed && (
        <Modal onClose={() => {}}>
          <div className="d-flex flex-column">
            <div
              className="d-flex flex-column justify-content-center align-items-center paragraph-large"
              style={{
                flex: 1,
                padding: 15,
                whiteSpace: "pre-wrap",
                minHeight: 107,
                color: colors.caliber_gray_text,
              }}
            >
              This calendar item has been updated. Please refresh your browser.
            </div>
            <div
              className="d-flex flex-column align-items-center"
              style={{
                height: 62,
              }}
            >
              <ButtonTag
                text="Refresh"
                onClick={() => {
                  closeCalendarFetchItemFailedDialog();
                  getCalendar();
                }}
                height={32}
                textColor={colors.caliber_white}
                bgColor={colors.caliber_green_200}
                minWidth={91}
              />
            </div>
          </div>
        </Modal>
      )}
      <Calendar
        goBack={goBack}
        goForth={goForth}
        goToday={goToday}
        maxMonthsToGo={maxMonthsToGo}
        currentDate={currentDate}
        currentMonth={currentMonth}
        currentYear={currentYear}
        isLoading={isLoading}
        calendarItemsByDay={calendarItemsByDay}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getCalendarItems: (
    clientId: string,
    startDate: Date,
    endDate: Date,
    fetchDetailed: Boolean
  ) => {
    dispatch(getCalendarItems(clientId, startDate, endDate, fetchDetailed));
  },
  closeCalendarFetchItemFailedDialog: () => {
    dispatch(closeCalendarFetchItemFailedDialog());
  },
  getClientHabitsTargets: (clientId: string) => {
    dispatch(getClientHabitsTargets(clientId));
  },
  resetClientHabitTargetState: () => {
    dispatch(resetClientHabitTargetState());
  },
  getClientTrainingPlans: (clientId: string) => {
    dispatch(getClientTrainingPlans(clientId));
  },
});

const mapStateToProps = (state: StoreState) => ({
  calendarDataState: state.calendarDataState,
  isLoading: state.calendarDataState.isLoading,
  calendarItemsByDay: state.calendarDataState.calendarItemsByDay,
  calendarFetchItemFailed: state.calendarDataState.calendarFetchItemFailed,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrainerClientCalendarTab);
