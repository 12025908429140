import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import colors from "../../assets/colors";
import {
  Exercise,
  ExerciseEquipmentType,
  ExerciseInput,
  ExerciseLevelType,
  ExerciseType,
  FileUploadType,
  MuscleGroupType,
  ExerciseTargetBaseType,
} from "../../types/gqlTypes";
import ExerciseLibraryTagSection from "../ExerciseLibraryTagSection";
import {
  exerciseEquipment,
  exerciseLevel,
  exerciseTypeText,
} from "../../models/exercises";
import ExerciseLibraryMuscleGroup from "../ExerciseLibraryMuscleGroups";
import EditImage from "../EditImage";
import { StoreState } from "../../redux/reducers";
import { fileUpload, videoUpload } from "../../redux/actions/Media";
import { MediaState } from "../../redux/actions/Media/types";
import EditVideo from "../EditVideo";
import ChapterTimeInput from "../ChapterTimeInput";
import {
  createVideoLessonObject,
  exerciseInputFromExercise,
  MediaContext,
  updateChapter,
} from "./utils";
import Dropdown from "../Dropdown";

interface OwnProps {
  exercise?: Exercise;
  onInputChanged: (input: ExerciseInput) => void;
}

interface Props extends OwnProps {
  uploadImage: (file: any, type: FileUploadType) => void;
  uploadVideo: (file: any, type: FileUploadType) => void;
  mediaState: MediaState;
}

const ExerciseLibraryAddEditExercise = (props: Props) => {
  const { exercise, onInputChanged, uploadImage, uploadVideo, mediaState } =
    props;

  const title = exercise ? "Edit Exercise" : "New Exercise";

  const mediaContext = useRef<MediaContext>();

  const [exerciseInput, setExerciseInput] = useState(
    exerciseInputFromExercise(exercise)
  );
  useEffect(() => {
    onInputChanged(exerciseInput);
  }, [exerciseInput]);

  if (exerciseInput.media == null) {
    exerciseInput.media = {};
  }

  const getMuxThumbnailUrl = (url: string): string => {
    if (url != null) {
      const regex = /^https:\/\/stream.mux.com\/(.*).m3u8/;
      const match = url?.match(regex);
      if (match) {
        return `https://image.mux.com/${match[1]}/thumbnail.jpg?time=0`;
      }
    }
    return "";
  };

  useEffect(() => {
    if (mediaState.fileInfo) {
      if (mediaContext.current === MediaContext.Thumbnail) {
        exerciseInput.media.thumbnailUrl = {
          id: mediaState.fileInfo.mediaUrl.id,
          url: mediaState.fileInfo.mediaUrl.url,
          type: FileUploadType.ExerciseThumbnail,
        };
        setExerciseInput({ ...exerciseInput });
      }
      if (mediaContext.current === MediaContext.HeroImage) {
        exerciseInput.media.heroImageUrl = {
          id: mediaState.fileInfo.mediaUrl.id,
          url: mediaState.fileInfo.mediaUrl.url,
          type: FileUploadType.ExerciseThumbnail,
        };
        setExerciseInput({ ...exerciseInput });
      }
      if (mediaContext.current === MediaContext.VideoLessonThumbnail) {
        if (exerciseInput.media.videoLesson == null) {
          exerciseInput.media.videoLesson = createVideoLessonObject();
        }
        if (exerciseInput.media.videoLesson.videoLessonUrl == null) {
          exerciseInput.media.videoLesson.videoLessonUrl = {};
        }
        exerciseInput.media.videoLesson.thumbnailUrl = {
          id: mediaState.fileInfo.mediaUrl.id,
          url: mediaState.fileInfo.mediaUrl.url,
          type: FileUploadType.VideoLessonThumbnail,
        };
        setExerciseInput({ ...exerciseInput });
      }
      mediaContext.current = null;
    }
  }, [mediaState.fileInfo]);

  useEffect(() => {
    if (mediaState.mediaUrl) {
      if (mediaContext.current === MediaContext.VideoLesson) {
        if (exerciseInput.media.videoLesson == null) {
          exerciseInput.media.videoLesson = createVideoLessonObject();
        }
        if (exerciseInput.media.videoLesson.videoLessonUrl == null) {
          exerciseInput.media.videoLesson.videoLessonUrl = {};
        }
        exerciseInput.media.videoLesson.videoLessonUrl = {
          id: mediaState.mediaUrl.id,
          url: mediaState.mediaUrl.url,
        };
        if (exerciseInput.media?.videoLesson?.thumbnailUrl == null) {
          exerciseInput.media.videoLesson.thumbnailUrl = {
            id: `${mediaState.mediaUrl.id}_video_lesson_thumbnail`,
            url: getMuxThumbnailUrl(
              exerciseInput?.media?.videoLesson?.videoLessonUrl?.url
            ),
            type: FileUploadType.VideoLessonThumbnail,
          };
        }
        setExerciseInput({ ...exerciseInput });
      }

      if (mediaContext.current === MediaContext.VideoLoop) {
        if (exerciseInput.media.videoUrl == null) {
          exerciseInput.media.videoUrl = {};
        }
        exerciseInput.media.videoUrl = {
          id: mediaState.mediaUrl.id,
          url: mediaState.mediaUrl.url,
        };
        setExerciseInput({ ...exerciseInput });
      }
      mediaContext.current = null;
    }
  }, [mediaState.mediaUrl]);

  const muxThumbnailUrl = getMuxThumbnailUrl(
    exerciseInput.media?.videoUrl?.url
  );
  const muxVideoThumbnailUrl = getMuxThumbnailUrl(
    exerciseInput?.media?.videoLesson?.videoLessonUrl?.url
  );

  const sectionSpacing = 48;

  const inputStyle = {
    border: `1px solid ${colors.caliber_gray_5}`,
    backgroundColor: colors.caliber_gray_5,
    borderRadius: 8,
    height: 48,
    marginTop: 8,
    marginBottom: 8,
    paddingLeft: 20,
    paddingRight: 20,
  };

  let numTakeawayFields = exerciseInput.takeaways.length + 1;
  numTakeawayFields = numTakeawayFields > 5 ? 5 : numTakeawayFields;

  const requiredStar = (
    <span style={{ color: "red", paddingBottom: 7, marginLeft: 4 }}>*</span>
  );

  return (
    <div
      className="d-flex flex-column"
      style={{
        marginLeft: 16,
        marginRight: 45,
        marginTop: 20,
        flex: 1,
      }}
    >
      <div
        className="paragraph-large"
        style={{
          color: colors.caliber_gray_text,
        }}
      >
        {title}
      </div>

      <div
        className="d-flex"
        style={{
          marginTop: sectionSpacing,
        }}
      >
        <ExerciseLibraryTagSection
          title="Exercise Type"
          tags={["Exercise", "Warmup"]}
          selectedTags={[
            exerciseInput.type === ExerciseType.Warmup ? "Warmup" : "Exercise",
          ]}
          onTagSelected={(tag) => {
            if (tag === "Warmup") {
              exerciseInput.type = ExerciseType.Warmup;
              setExerciseInput({ ...exerciseInput });
            } else if (
              exerciseInput.type === ExerciseType.Warmup &&
              tag === "Exercise"
            ) {
              exerciseInput.type = null;
              setExerciseInput({ ...exerciseInput });
            }
          }}
        />
      </div>

      <div
        className="d-flex flex-column"
        style={{
          marginTop: sectionSpacing,
        }}
      >
        <div
          className="medium-bold"
          style={{
            marginBottom: 16,
          }}
        >
          Exercise Content
        </div>
        <input
          className="bordered-item heading-small"
          style={inputStyle}
          value={exerciseInput.name}
          onChange={(event) => {
            exerciseInput.name = event.target.value;
            setExerciseInput({ ...exerciseInput });
          }}
          placeholder="Name *"
        />
        <textarea
          className="bordered-item heading-small"
          style={{
            ...inputStyle,
            height: 180,
          }}
          value={exerciseInput.instructions}
          onChange={(event) => {
            exerciseInput.instructions = event.target.value;
            setExerciseInput({ ...exerciseInput });
          }}
          placeholder="Instructions"
        />
      </div>

      <div
        className="d-flex"
        style={{
          marginTop: sectionSpacing,
        }}
      >
        <ExerciseLibraryTagSection
          title="Level"
          tags={[
            exerciseLevel(ExerciseLevelType.Beginner),
            exerciseLevel(ExerciseLevelType.Advanced),
          ]}
          selectedTags={[exerciseLevel(exerciseInput.level)]}
          onTagSelected={(tag) => {
            let newLevel;
            switch (tag) {
              case exerciseLevel(ExerciseLevelType.Beginner):
                newLevel = ExerciseLevelType.Beginner;
                break;
              case exerciseLevel(ExerciseLevelType.Advanced):
                newLevel = ExerciseLevelType.Advanced;
                break;
            }
            exerciseInput.level = newLevel;
            setExerciseInput({ ...exerciseInput });
          }}
        />
        {exerciseInput.type !== ExerciseType.Warmup && (
          <ExerciseLibraryTagSection
            title="Type"
            tags={[
              exerciseTypeText(ExerciseType.Strength),
              exerciseTypeText(ExerciseType.Cardio),
              exerciseTypeText(ExerciseType.Mobility),
            ]}
            selectedTags={[exerciseTypeText(exerciseInput.type)]}
            onTagSelected={(tag) => {
              let newType;
              switch (tag) {
                case exerciseTypeText(ExerciseType.Strength):
                  newType = ExerciseType.Strength;
                  break;
                case exerciseTypeText(ExerciseType.Cardio):
                  newType = ExerciseType.Cardio;
                  break;
                case exerciseTypeText(ExerciseType.Mobility):
                  newType = ExerciseType.Mobility;
                  break;
              }
              exerciseInput.type = newType;
              setExerciseInput({ ...exerciseInput });
            }}
          />
        )}
      </div>
      <div>
        <div
          className="bold"
          style={{
            marginBottom: 16,
            fontSize: "14px",
            lineHeight: "32px",
            color: colors.caliber_secondary,
          }}
        >
          Measurement
          {requiredStar}
        </div>
        <div
          className="d-flex"
          style={{
            width: 190,
          }}
        >
          <Dropdown
            height={36}
            value={exerciseInput.targetBase}
            items={[
              { text: "Reps", value: ExerciseTargetBaseType.Reps },
              { text: "Time", value: ExerciseTargetBaseType.Time },
            ]}
            onSelect={(targetBase) =>
              setExerciseInput({ ...exerciseInput, targetBase })
            }
          />
        </div>
      </div>

      <div className="form-check" style={{ marginTop: 20 }}>
        <input
          className="form-check-input"
          type="checkbox"
          checked={exerciseInput.includeBodyWeightInCalculations}
          id="flexCheckDefault"
          onChange={(e) => {
            // I'm following existing convention here but this is awful and needs to be rebuilt
            exerciseInput.includeBodyWeightInCalculations = e.target.checked;
            setExerciseInput({ ...exerciseInput });
          }}
        />
        <label className="form-check-label" htmlFor="flexCheckDefault">
          Include body weight in calculations
        </label>
      </div>

      <div
        className="d-flex flex-column"
        style={{
          marginTop: sectionSpacing,
        }}
      >
        <div
          className="medium-bold"
          style={{
            marginBottom: 16,
            fontSize: "14px",
            lineHeight: "32px",
            color: colors.caliber_secondary,
          }}
        >
          Muscle Groups
        </div>
        <ExerciseLibraryMuscleGroup
          title="Primary Muscle Groups"
          selectedMuscles={exerciseInput.primaryMuscleGroups}
          onMuscleSelected={(muscle: MuscleGroupType) => {
            if (exerciseInput.primaryMuscleGroups.includes(muscle)) {
              exerciseInput.primaryMuscleGroups =
                exerciseInput.primaryMuscleGroups.filter(
                  (oldMuscle) => oldMuscle !== muscle
                );
            } else {
              exerciseInput.primaryMuscleGroups.push(muscle);
            }
            setExerciseInput({ ...exerciseInput });
          }}
        />
        <ExerciseLibraryMuscleGroup
          title="Secondary Muscle Groups"
          selectedMuscles={exerciseInput.secondaryMuscleGroups}
          onMuscleSelected={(muscle: MuscleGroupType) => {
            if (exerciseInput.secondaryMuscleGroups.includes(muscle)) {
              exerciseInput.secondaryMuscleGroups =
                exerciseInput.secondaryMuscleGroups.filter(
                  (oldMuscle) => oldMuscle !== muscle
                );
            } else {
              exerciseInput.secondaryMuscleGroups.push(muscle);
            }
            setExerciseInput({ ...exerciseInput });
          }}
        />
      </div>

      <div
        className="d-flex"
        style={{
          marginTop: sectionSpacing,
        }}
      >
        <ExerciseLibraryTagSection
          title="Equipment"
          tags={Object.values(ExerciseEquipmentType).map((key) =>
            exerciseEquipment(key)
          )}
          selectedTags={[exerciseEquipment(exerciseInput.equipment)]}
          onTagSelected={(tag) => {
            const newEquipment = Object.values(ExerciseEquipmentType).find(
              (key) => tag === exerciseEquipment(key)
            );
            exerciseInput.equipment = newEquipment;
            setExerciseInput({ ...exerciseInput });
          }}
        />
      </div>

      <div
        className="d-flex flex-wrap"
        style={{
          marginTop: sectionSpacing,
        }}
      >
        <div
          className="medium-bold"
          style={{
            marginRight: 30,
            fontSize: "14px",
            lineHeight: "32px",
            color: colors.caliber_secondary,
          }}
        >
          Thumbnail Image
          <div
            style={{
              marginTop: 16,
              width: 94,
              height: 94,
            }}
          >
            <EditImage
              imageUrl={exerciseInput.media?.thumbnailUrl?.url}
              width={94}
              height={94}
              hasRoundBorder
              showLoader={
                mediaState.isUploading &&
                mediaContext.current === MediaContext.Thumbnail
              }
              disabled={mediaState.isUploading}
              onImagePicked={(imageFile) => {
                mediaContext.current = MediaContext.Thumbnail;
                uploadImage(imageFile, FileUploadType.ExerciseThumbnail);
              }}
            />
          </div>
        </div>

        <div
          className="medium-bold"
          style={{
            marginRight: 30,
            fontSize: "14px",
            lineHeight: "32px",
            color: colors.caliber_secondary,
          }}
        >
          Hero Image
          <div
            style={{
              marginTop: 16,
              height: 94,
              width: 166,
            }}
          >
            <EditImage
              imageUrl={exerciseInput.media?.heroImageUrl?.url}
              height={94}
              width={166}
              disabled={mediaState.isUploading}
              showLoader={
                mediaState.isUploading &&
                mediaContext.current === MediaContext.HeroImage
              }
              onImagePicked={(imageFile) => {
                mediaContext.current = MediaContext.HeroImage;
                uploadImage(imageFile, FileUploadType.ExerciseHero);
              }}
            />
          </div>
        </div>
      </div>
      <div
        className="medium-bold"
        style={{
          fontSize: "14px",
          lineHeight: "32px",
          color: colors.caliber_secondary,
          marginTop: 16,
        }}
      >
        Thumbnail Video
        <div
          className="d-flex flex-column"
          style={{
            marginTop: 16,
          }}
        >
          <EditVideo
            key={`video_${exerciseInput.media?.videoUrl?.url}`}
            videoUrl={exerciseInput.media?.videoUrl?.url}
            disabled={mediaState.isUploading}
            showLoader={
              mediaState.isUploading &&
              mediaContext.current === MediaContext.VideoLoop
            }
            onVideoPicked={(file) => {
              mediaContext.current = MediaContext.VideoLoop;
              uploadVideo(file, FileUploadType.ExerciseVideo);
            }}
            height={94}
            width={166}
          />
          {muxThumbnailUrl && (
            <a href={muxThumbnailUrl} target="_blank">
              Mux Image
            </a>
          )}
        </div>
      </div>

      <div
        className="d-flex flex-column"
        style={{
          marginTop: sectionSpacing,
        }}
      >
        <div
          className="medium-bold"
          style={{
            marginBottom: 16,
            fontSize: "14px",
            lineHeight: "32px",
            color: colors.caliber_secondary,
          }}
        >
          <div className="d-flex flex-column">
            201 video hero image
            <div className="d-flex">
              <EditImage
                imageUrl={
                  exerciseInput.media?.videoLesson?.thumbnailUrl?.url ||
                  muxVideoThumbnailUrl
                }
                height={94}
                width={166}
                showLoader={
                  mediaState.isUploading &&
                  mediaContext.current === MediaContext.VideoLessonThumbnail
                }
                disabled={mediaState.isUploading}
                onImagePicked={(imageFile) => {
                  mediaContext.current = MediaContext.VideoLessonThumbnail;
                  uploadImage(imageFile, FileUploadType.VideoLessonThumbnail);
                }}
              />
            </div>
          </div>
          Video Lesson (For Advanced Exercises)
        </div>
        <div className="d-flex">
          <div
            className="d-flex flex-column medium-bold"
            style={{
              fontSize: "14px",
              lineHeight: "32px",
              color: colors.caliber_secondary,
            }}
          >
            <EditVideo
              key={`video_lesson_${exerciseInput.media?.videoLesson?.videoLessonUrl?.url}`}
              videoUrl={exerciseInput.media?.videoLesson?.videoLessonUrl?.url}
              disabled={mediaState.isUploading}
              showLoader={
                mediaState.isUploading &&
                mediaContext.current === MediaContext.VideoLesson
              }
              onVideoPicked={(file) => {
                mediaContext.current = MediaContext.VideoLesson;
                uploadVideo(file, FileUploadType.VideoLesson);
              }}
              height={144}
              width={258}
            />
            {muxVideoThumbnailUrl && (
              <a href={muxVideoThumbnailUrl} target="_blank">
                Mux Image
              </a>
            )}
          </div>
          <div
            className="d-flex flex-column"
            style={{
              marginLeft: 20,
            }}
          >
            <div className="d-flex">
              <ChapterTimeInput
                label="Intro"
                onTimeChange={(time) => {
                  setExerciseInput(updateChapter(exerciseInput, 0, time));
                }}
                currentTime={
                  exerciseInput.media?.videoLesson?.chapters[0]?.marker
                }
              />
              <ChapterTimeInput
                label="Setup"
                onTimeChange={(time) => {
                  setExerciseInput(updateChapter(exerciseInput, 1, time));
                }}
                currentTime={
                  exerciseInput.media?.videoLesson?.chapters[1]?.marker
                }
              />
            </div>
            <div className="d-flex">
              <ChapterTimeInput
                label="Movement"
                onTimeChange={(time) => {
                  setExerciseInput(updateChapter(exerciseInput, 2, time));
                }}
                currentTime={
                  exerciseInput.media?.videoLesson?.chapters[2]?.marker
                }
              />
              <ChapterTimeInput
                label="Common Mistakes"
                onTimeChange={(time) => {
                  setExerciseInput(updateChapter(exerciseInput, 3, time));
                }}
                currentTime={
                  exerciseInput.media?.videoLesson?.chapters[3]?.marker
                }
              />
            </div>
            <div className="d-flex">
              <ChapterTimeInput
                label="Takeaways"
                onTimeChange={(time) => {
                  setExerciseInput(updateChapter(exerciseInput, 4, time));
                }}
                currentTime={
                  exerciseInput.media?.videoLesson?.chapters[4]?.marker
                }
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="d-flex flex-column"
        style={{
          marginTop: sectionSpacing,
          paddingBottom: 40,
        }}
      >
        <div
          className="medium-bold"
          style={{
            marginBottom: 16,
            fontSize: "14px",
            lineHeight: "32px",
            color: colors.caliber_secondary,
          }}
        >
          Takeaways (optional)
        </div>
        {_.range(0, numTakeawayFields).map((index) => {
          const placeholder = `Takeaway ${index + 1}`;
          const value =
            exerciseInput.takeaways.length >= index + 1
              ? exerciseInput.takeaways[index]
              : "";
          return (
            <input
              key={placeholder}
              className="bordered-item heading-small"
              style={inputStyle}
              value={value}
              onChange={(event) => {
                if (exerciseInput.takeaways.length < index + 1) {
                  exerciseInput.takeaways.push(event.target.value);
                } else {
                  exerciseInput.takeaways[index] = event.target.value;
                }
                setExerciseInput({ ...exerciseInput });
              }}
              placeholder={placeholder}
            />
          );
        })}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  uploadImage: (file: any, type: FileUploadType) => {
    dispatch(fileUpload(file, type));
  },
  uploadVideo: (file: any, type: FileUploadType) => {
    dispatch(videoUpload(file, type));
  },
});

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  mediaState: state.mediaState,
  exercise: ownProps.exercise,
  onInputChanged: ownProps.onInputChanged,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExerciseLibraryAddEditExercise);
