import React from "react";
import colors from "../../../assets/colors";
import { ClientWorkoutGroupTemplate } from "../../../types/gqlTypes";
import { renderWorkoutGroupThumbnailIcon } from "../../../utils";
import PremiumClientIcon from "../../Icons/PremiumClientIcon";
import SimpleButton from "../../SimpleButton";

interface Props {
  workoutGroupTemplate: ClientWorkoutGroupTemplate;
  onClick?: () => void;
}

const WorkoutGroupTemplateCard = (props: Props) => {
  const { workoutGroupTemplate, onClick } = props;

  const titleStyle = {
    fontSize: "18px",
    lineHeight: "22px",
    letterSpacing: "-0.03em",
    color: colors.caliber_secondary,
    height: 40,
    margin: "0px 8px",
  };

  return (
    <SimpleButton
      onClick={() => {
        onClick?.();
      }}
      nofocus
      className="d-flex bold pointer"
      style={{
        width: 398,
        height: 48,
        padding: 8,
        marginBottom: 12,
        marginRight: 2,
        backgroundColor: colors.caliber_secondary_gray_5,
        borderRadius: 12,
      }}
    >
      <div
        style={{
          width: 32,
          borderRadius: 8,
        }}
      >
        <div
          style={{
            position: "relative",
            width: 32,
            height: 32,
          }}
        >
          <div
            style={{
              position: "absolute",
              bottom: -6,
              right: -6,
            }}
          >
            {workoutGroupTemplate.default && (
              <PremiumClientIcon
                width={16}
                height={16}
                bgColor={colors.caliber_white}
              />
            )}
          </div>

          <img
            style={{
              width: 32,
              height: 32,
              borderRadius: 8,
            }}
            src={renderWorkoutGroupThumbnailIcon(
              workoutGroupTemplate?.workoutGroupType
            )}
          />
        </div>
      </div>
      <div className="d-flex flex-column justify-content-center">
        <div className="d-flex align-items-center" style={titleStyle}>
          {workoutGroupTemplate?.title}
        </div>
      </div>
    </SimpleButton>
  );
};

export default WorkoutGroupTemplateCard;
