import apolloClient from "../../../api/apolloClient";
import {
  CoachTask,
  CoachTaskStatusType,
  MutationDeleteCoachTaskArgs,
  UserType,
} from "../../../types/gqlTypes";
import { TaskActionType, TasksAction } from "./types";
import {
  COACH_TASKS_GQL,
  COACH_TASKS_FOR_MANAGER_GQL,
  COACH_TASKS_FOR_ADMIN_GQL,
  MARK_COACH_TASK_STATUS_GQL,
  ADMIN_RUN_RULES_ENGINE_CRON_GQL,
  DELETE_COACH_TASK_GQL,
} from "../../../api/gql/tasks";

export const getTasks =
  (
    userType: UserType,
    statusCriteria: CoachTaskStatusType[],
    userId?: string
  ) =>
  async (dispatch, getState) => {
    if (getState().tasksState.isLoading) {
      return;
    }

    dispatch({
      type: TaskActionType[TaskActionType.GET_TASKS_LOADING],
    } as TasksAction);

    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );

      let result: CoachTask[];
      switch (userType) {
        case UserType.Trainer:
          result = (
            await client.query({
              query: COACH_TASKS_GQL,
              variables: {
                trainerId: userId,
                statusCriteria,
              },
            })
          ).data.coachTasksByStatus as CoachTask[];
          break;
        case UserType.Manager:
          result = (
            await client.query({
              query: COACH_TASKS_FOR_MANAGER_GQL,
              variables: {
                managerId: userId,
                statusCriteria,
              },
            })
          ).data.coachTasksForManagerByStatus as CoachTask[];
          break;
        case UserType.Admin:
          result = (
            await client.query({
              query: COACH_TASKS_FOR_ADMIN_GQL,
              variables: {
                statusCriteria,
              },
            })
          ).data.coachTasksForAdminByStatus as CoachTask[];
          break;
      }

      dispatch({
        type: TaskActionType[TaskActionType.GET_TASKS_SUCCESS],
        taskList: result,
      } as TasksAction);
    } catch (error) {
      console.error("Get Tasks failed", error);
      dispatch({
        type: TaskActionType.GET_TASKS_FAIL,
        data: error,
      });
    }
  };

export const markCoachTaskStatus =
  (trainerId: string, coachTaskId: string, status: CoachTaskStatusType) =>
  async (dispatch, getState) => {
    if (getState().tasksState.isLoading) {
      return;
    }

    dispatch({
      type: TaskActionType[TaskActionType.MARK_COACH_TASK_STATUS_LOADING],
    } as TasksAction);

    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );

      const result = (
        await client.mutate({
          mutation: MARK_COACH_TASK_STATUS_GQL,
          variables: {
            trainerId,
            coachTaskId,
            status,
          },
        })
      ).data.markCoachTaskStatus as CoachTask;

      dispatch({
        type: TaskActionType[TaskActionType.MARK_COACH_TASK_STATUS_SUCCESS],
        updatedTask: result,
      } as TasksAction);
    } catch (error) {
      console.error("Mark Task Status failed", error);
      dispatch({
        type: TaskActionType.MARK_COACH_TASK_STATUS_FAIL,
        data: error,
      });
    }
  };

export const adminRunRulesEngineCron =
  (
    userType: UserType,
    statusCriteria: CoachTaskStatusType[],
    userId?: string
  ) =>
  async (dispatch, getState) => {
    if (getState().tasksState.isLoading) {
      return;
    }

    dispatch({
      type: TaskActionType[TaskActionType.ADMIN_RUN_RULES_ENGINE_CRON_LOADING],
    } as TasksAction);

    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );

      await client.query({
        query: ADMIN_RUN_RULES_ENGINE_CRON_GQL,
      });

      dispatch({
        type: TaskActionType[
          TaskActionType.ADMIN_RUN_RULES_ENGINE_CRON_SUCCESS
        ],
      } as TasksAction);

      // Refresh screen with new tasks
      dispatch(getTasks(userType, statusCriteria, userId));
    } catch (error) {
      console.error("Admin run rules engine cron failed", error);
      dispatch({
        type: TaskActionType.ADMIN_RUN_RULES_ENGINE_CRON_FAIL,
        data: error,
      });
    }
  };

export const deleteCoachTask =
  (args: MutationDeleteCoachTaskArgs) => async (dispatch, getState) => {
    if (getState().tasksState.isLoading) {
      return;
    }

    dispatch({
      type: TaskActionType[TaskActionType.DELETE_COACH_TASK_LOADING],
    } as TasksAction);

    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );

      await client.mutate({
        mutation: DELETE_COACH_TASK_GQL,
        variables: args,
      });

      dispatch({
        type: TaskActionType[TaskActionType.DELETE_COACH_TASK_SUCCESS],
        deletedTaskId: args.coachTaskId,
      } as TasksAction);
    } catch (error) {
      console.error("Delete Task failed", error);
      dispatch({
        type: TaskActionType.DELETE_COACH_TASK_FAIL,
        data: error,
      });
    }
  };
