import React from "react";
import { useRerenderOnResize, useKeyPress } from "../../utils/customHooks";
import colors from "../../assets/colors";

interface Props {
  children: React.ReactNode;
  onClose: (event?: any) => void;
  closeOnDimmerClick?: boolean;
}

const Modal = (props: Props) => {
  const { children, closeOnDimmerClick, onClose } = props;
  useRerenderOnResize();
  useKeyPress("Escape", onClose);

  return (
    <div
      className="d-flex fixed-top justify-content-center align-items-center"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 9997,
        width: window.innerWidth,
        height: window.innerHeight,
      }}
    >
      <div
        role="button"
        tabIndex={0}
        onClick={(event) => {
          if (closeOnDimmerClick) {
            onClose(event);
          }
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter" && closeOnDimmerClick) {
            onClose(event);
          }
        }}
        className=""
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight,
          backgroundColor: "#FFFFFF",
          opacity: 0.8,
          zIndex: 9998,
        }}
      >
        {/* for opacity */}
      </div>
      <div
        className="d-flex"
        style={{
          overflowX: "auto",
          overflowY: "auto",
          minWidth: 200,
          minHeight: 150,
          maxHeight: window.innerHeight * 0.95,
          maxWidth: window.innerWidth * 0.95,
          backgroundColor: colors.caliber_white,
          borderRadius: 8,
          boxShadow: "0px 2px 16px #808297",
          zIndex: 9999,
          position: "relative",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
