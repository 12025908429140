import React, { useMemo, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import colors from "../../assets/colors";
import DownArrow from "../../assets/images/DarkArrowDownIcon.svg";
import UpArrow from "../../assets/images/DarkArrowUpIcon.svg";
import DumbbellWorkoutIcon from "../../assets/images/DumbbellWorkoutIcon.svg";
import ChevronRight from "../../assets/images/ChevronRight.svg";
import {
  selectWorkoutTrainingPlan,
  startEditWorkoutTrainingPlan,
} from "../../redux/actions/TrainingPrograms";
import { deleteWorkoutInPlan } from "../../redux/actions/TrainingPrograms/delete";
import { getIsAdmin, getIsMasquerade, StoreState } from "../../redux/reducers";
import {
  ClientWorkoutTemplate,
  MasterWorkout,
  MutationCopyClientWorkoutTemplateArgs,
  Superset,
  Workout,
} from "../../types/gqlTypes";
import ConfirmationDialog from "../ConfirmationDialog";
import MeatballsMenu from "../MeatballsMenu";
import { ProgramType } from "../TrainingProgram";
import MoveToGroupModal from "./MoveToGroupModal";
import { copyClientWorkoutTemplate } from "../../redux/actions/TrainingPrograms/copy";

export interface WorkoutCardItem {
  type: string;
  id: string;
  originalIndex: number;
  workoutGroupId: string;
  workoutItem: ClientWorkoutTemplate | MasterWorkout;
}

interface OwnProps {
  workout: ClientWorkoutTemplate | MasterWorkout;
  index?: number;
  workoutGroupId?: string;
  asHeader?: boolean;
  numberOfExercises?: number;
  moveCard?: (
    id?: string,
    to?: number,
    workoutGroupSource?: string,
    workoutItem?: MasterWorkout | ClientWorkoutTemplate
  ) => void;
  findCard?: (id?: string) => { index?: number };
}

interface Props extends OwnProps {
  programType: ProgramType;
  workoutIndex: number;
  isMasquerade: boolean;
  isAdmin: boolean;
  selectWorkout: (index: number) => void;
  deleteWorkout: (args: { id: string }) => void;
  startEditWorkout: (index: number) => void;
  copyClientWorkout: (args: MutationCopyClientWorkoutTemplateArgs) => void;
}

const WorkoutItem = (props: Props) => {
  const {
    workout,
    index,
    asHeader,
    programType,
    workoutIndex,
    workoutGroupId,
    isMasquerade,
    isAdmin,
    moveCard,
    findCard,
    selectWorkout,
    deleteWorkout,
    startEditWorkout,
    copyClientWorkout,
  } = props;
  const { id: clientId } = useParams();
  let canBeChanged = true;
  if (programType === ProgramType.Master) {
    // only admins with no Masquerade and in Program Library component
    canBeChanged = isAdmin && !isMasquerade && !clientId;
  }
  const draggable = canBeChanged && !asHeader;
  const isSelected = workoutIndex === index && !asHeader;
  const workoutImage = DumbbellWorkoutIcon;
  const numberOfExercises = () => {
    let counter: number = 0;
    (workout as Workout)?.gymSteps?.forEach((gymStep) => {
      if (gymStep.exercise) {
        counter += 1;
      } else {
        (gymStep as Superset).gymSteps.forEach((supersetChild) => {
          if (supersetChild.exercise) {
            counter += 1;
          }
        });
      }
    });

    return counter;
  };

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [expand, setExpand] = useState(false);
  const [showMeatball, setShowMeatball] = useState(false);
  const [showMoveToGroup, setShowMoveToGroup] = useState(false);

  const [instructions, expandButtonText, expandIcon] = useMemo(() => {
    let instrString = workout?.instructions;
    let expandButton;
    let expandIcon;
    if (workout?.instructions?.length > 100 && !expand) {
      instrString = `${workout.instructions.slice(0, 100)}... `;
      expandButton = " read more";
      expandIcon = DownArrow;
    }
    if (expand) {
      expandButton = " read less";
      expandIcon = UpArrow;
    }
    return [instrString, expandButton, expandIcon];
  }, [expand, workout]);

  const card = findCard && findCard(workout?.id);
  const originalIndex = card ? card.index : 0;

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: "workout",
      id: workout?.id,
      originalIndex,
      workoutGroupId,
      workoutItem: workout,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      const cardItem: WorkoutCardItem = monitor.getItem();
      const didDrop = monitor.didDrop();
      if (draggable && moveCard && !didDrop) {
        moveCard(
          cardItem.id,
          cardItem.originalIndex,
          cardItem.workoutGroupId,
          cardItem.workoutItem
        );
      }
    },
  });

  const [, drop] = useDrop({
    accept: "workout",
    canDrop: () => false,
    hover(cardItem: WorkoutCardItem, monitor) {
      if (draggable && findCard && cardItem.id !== workout?.id) {
        const card = findCard(workout?.id);
        if (moveCard && card && card.index > -1) {
          moveCard(
            cardItem.id,
            card.index,
            cardItem.workoutGroupId,
            cardItem.workoutItem
          );
        }
      }
    },
  });

  const meatballsOptions = [];
  meatballsOptions.push({
    text: "Edit",
    onClick: () => startEditWorkout(asHeader ? workoutIndex : index),
  });

  if (clientId && workoutGroupId) {
    meatballsOptions.push({
      text: "Copy",
      onClick: () =>
        copyClientWorkout({
          clientId,
          clientWorkoutTemplateId: workout.id,
          destinationClientWorkoutGroupTemplateId: workoutGroupId,
        } as MutationCopyClientWorkoutTemplateArgs),
    });
  }

  if (workoutGroupId) {
    meatballsOptions.push({
      text: "Move to",
      onClick: () => setShowMoveToGroup(true),
    });
  }
  meatballsOptions.push({
    text: "Delete",
    onClick: () => setConfirmDialog(true),
  });

  const badgeStyle: React.CSSProperties = {
    height: 24,
    padding: "2px 8px",
    marginBottom: 12,
    borderRadius: 8,
    backgroundColor: colors.caliber_gray_bg,
    fontSize: "14px",
    lineHeight: "16px",
  };
  const style: React.CSSProperties = {
    margin: "4px 0px",
    minHeight: asHeader ? 80 : 64,
    borderRadius: 8,
    paddingLeft: 4,
    cursor: draggable && "move",
    transform: "translate(0, 0)",
    backgroundColor: isSelected ? "#fafafb" : colors.caliber_white,
    paddingRight: canBeChanged ? 1 : 8,
    opacity: isDragging ? 0 : 1,
  };
  if (asHeader) {
    style.margin = 0;
  } else {
    style.cursor = "pointer";
  }

  return (
    <div
      ref={(node) => (draggable ? drag(drop(node)) : null)}
      role="button"
      tabIndex={0}
      onClick={() => {
        if (!asHeader) {
          selectWorkout?.(index);
        }
      }}
      onKeyDown={(event) => {
        if (event.key === "Enter" && !asHeader) {
          selectWorkout?.(index);
        }
      }}
      className="d-flex flex-column flex-nowrap nofocus"
    >
      {showMoveToGroup && (
        <MoveToGroupModal
          onClose={() => {
            setShowMoveToGroup(false);
          }}
          workoutId={workout.id}
          currentWorkoutGroupId={workoutGroupId}
        />
      )}
      <div
        className="d-flex flex-column flex-nowrap nofocus"
        style={style}
        onMouseEnter={() => {
          setShowMeatball(true);
        }}
        onMouseLeave={() => {
          setShowMeatball(false);
        }}
      >
        {confirmDialog && (
          <ConfirmationDialog
            cancelButtonText="Cancel"
            show={confirmDialog}
            text={`Do you really want to delete ${workout?.title}?`}
            onCancel={() => setConfirmDialog(false)}
            onConfirm={() => {
              deleteWorkout({ id: workout.id });
              setConfirmDialog(false);
            }}
          />
        )}
        <div
          className="d-flex"
          style={{
            flex: 1,
            alignItems: "center",
          }}
        >
          {asHeader && (
            <div
              style={{
                width: 48,
                height: 48,
                margin: 8,
                borderRadius: 8,
              }}
            >
              <img
                style={{
                  width: 48,
                  height: 48,
                  borderRadius: 8,
                }}
                src={workoutImage}
                alt="Thumbnail"
              />
            </div>
          )}
          <div
            className="d-flex flex-column"
            style={{
              marginLeft: 10,
              flex: "1 1 0",
            }}
          >
            <div
              className="d-flex justify-content-between align-items-center"
              style={{
                flex: "1 1 0",
                paddingTop: 5,
              }}
            >
              <div className="d-flex flex-column">
                <div
                  className="bold"
                  style={{
                    flex: 1,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    margin: 0,
                    marginTop: 10,
                    fontSize: asHeader ? "16px" : "14px",
                    lineHeight: asHeader ? "15px" : "14px",
                  }}
                >
                  {workout?.title}
                </div>
                <div
                  className="d-flex"
                  style={{
                    marginTop: 10,
                  }}
                >
                  <div
                    className="d-flex align-items-center bold"
                    style={badgeStyle}
                  >
                    {`${numberOfExercises()} Exercise${
                      numberOfExercises() === 1 ? "" : "s"
                    }`}
                  </div>
                </div>
              </div>
              {isSelected && (
                <div
                  style={{
                    width: 22,
                    height: 20,
                    position: "absolute",
                    right: 8,
                    top: 24,
                  }}
                >
                  <img
                    style={{
                      width: 22,
                      height: 20,
                      borderRadius: 8,
                    }}
                    src={ChevronRight}
                    alt="Thumbnail"
                  />
                </div>
              )}

              {canBeChanged && showMeatball && (
                <div
                  style={{
                    position: "absolute",
                    right: 8,
                    top: 5,
                  }}
                >
                  <MeatballsMenu items={meatballsOptions} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {asHeader && (
        <div
          className="paragraph-normal"
          style={{
            color: colors.caliber_gray_text,
            lineHeight: "16px",
            padding: 12,
          }}
        >
          <span>{instructions}</span>
          {/* eslint-disable */}
          {workout?.instructions?.length > 100 && (
            <span
              onClick={() => setExpand(!expand)}
              className="pointer nofocus circular-bold-font"
              style={{
                color: colors.caliber_secondary,
                wordBreak: "keep-all",
                whiteSpace: "nowrap",
              }}
            >
              {expandButtonText}
              <img
                style={{ width: 20, height: 20, paddingBottom: 2 }}
                src={expandIcon}
              />
            </span>
          )}
          {/* eslint-enable */}
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  selectWorkout: (index: number) => {
    dispatch(selectWorkoutTrainingPlan(index));
  },
  deleteWorkout: (args: { id: string }) => {
    dispatch(deleteWorkoutInPlan(args));
  },
  startEditWorkout: (index: number) => {
    dispatch(startEditWorkoutTrainingPlan(index));
  },
  copyClientWorkout: (args: MutationCopyClientWorkoutTemplateArgs) => {
    dispatch(copyClientWorkoutTemplate(args));
  },
});

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  programType: state.trainingProgramsState.programType,
  workoutIndex: state.trainingProgramsState.workoutIndex,
  isMasquerade: getIsMasquerade(state),
  isAdmin: getIsAdmin(state),

  workout: ownProps.workout,
  asHeader: ownProps.asHeader,
  index: ownProps.index,
  workoutGroupid: ownProps.workoutGroupId,
  clientId: state.clientDetailState.id,
  moveCard: ownProps.moveCard,
  findCard: ownProps.findCard,
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkoutItem);
