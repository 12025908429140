import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import colors from "../../assets/colors";
import { selectClientTrainingPlan } from "../../redux/actions/TrainingPrograms";
import { copyClientWorkoutGroupTemplate } from "../../redux/actions/TrainingPrograms/copy";
import { copyMasterWorkoutGroupToClientGroup } from "../../redux/actions/TrainingPrograms/createAssign";
import { StoreState } from "../../redux/reducers";
import { selectClientPlans } from "../../redux/reducers/TrainingPrograms/selectors";
import {
  ClientSearchResult,
  ClientTrainingPlan,
  ClientWorkoutGroupTemplate,
  MasterWorkoutGroup,
  MutationCopyClientWorkoutGroupTemplateArgs,
  MutationCopyMasterWorkoutGroupToClientWorkoutGroupTemplateArgs,
  WorkoutGroupType,
} from "../../types/gqlTypes";
import ButtonTag from "../ButtonTag";
import ClientListToCopy from "../CopyTrainingPlanModal/ClientListToCopy";
import Modal from "../Modal";
import { ProgramType } from "../TrainingProgram";
import TrainingPlanSelector from "./TrainingPlanSelector";

interface Props {
  selectedClientId: string;
  workoutGroup: ClientWorkoutGroupTemplate | MasterWorkoutGroup;
  onClose: () => void;
  copyClientWorkoutGroupTemplate: (
    args: MutationCopyClientWorkoutGroupTemplateArgs
  ) => void;
  copyMasterWorkoutGroup: (
    args: MutationCopyMasterWorkoutGroupToClientWorkoutGroupTemplateArgs
  ) => void;
  selectClientTrainingPlan: (args: number) => void;
  thisClientsPlans: ClientTrainingPlan[];
  title: string;
  sourcePlanType: ProgramType;
}

const CopyWorkoutGroupModal = (props: Props) => {
  const {
    selectedClientId,
    workoutGroup,
    onClose,
    copyClientWorkoutGroupTemplate,
    copyMasterWorkoutGroup,
    selectClientTrainingPlan,
    thisClientsPlans,
    title,
    sourcePlanType,
  } = props;

  const { id: clientId } = useParams();

  const [destinationClientId, setDestinationClientId] =
    useState<string>(selectedClientId);
  const [destinationTrainingPlanId, setDestinationTrainingPlanId] =
    useState<string>(null);
  const [isSavedToInactivePlan, setIsSavedToInactivePlan] =
    useState<boolean>(false);
  // This is a weird data structure for a single select radio input but we want it to be compatible
  //   with the multiselect CopyListToClients component.  For this component, we'll only be storing
  //   one element in this object.
  const [selectedClientsSearchResult, setSelectedClientSearchResult] =
    useState<{ [key: string]: ClientSearchResult }>({});

  return (
    <Modal onClose={onClose}>
      {/*
        Optional phase 0: client selection
      */}
      {!isSavedToInactivePlan && !destinationClientId && (
        <div
          className="d-flex flex-column"
          style={{
            width: 480,
            padding: 24,
          }}
        >
          <ClientListToCopy
            onCancel={onClose}
            copyLabel="Next"
            // @ts-ignore
            clients={{}}
            onSelectClient={(client: ClientSearchResult) => {
              const copy = {};
              copy[client?.id] = client;
              setSelectedClientSearchResult(copy);
            }}
            selectedClients={selectedClientsSearchResult}
            onCopy={() => {
              // 1) load selected client's workout groups
              // 2) set destinationClientId
              // Note: just trying to pull the single value out of this overly complicated (but very compatible!) object
              Object.keys(selectedClientsSearchResult) &&
                setDestinationClientId(
                  Object.keys(selectedClientsSearchResult)[0]
                );
            }}
          />
        </div>
      )}

      {/*
        phase 1: training plan selection
      */}
      {!isSavedToInactivePlan && destinationClientId && (
        <TrainingPlanSelector
          clientId={destinationClientId}
          title={title}
          onClose={onClose}
          onSelect={(resultId: string) => {
            setDestinationTrainingPlanId(resultId);
          }}
          onSave={() => {
            // copy a client workout group template to a client training plan
            if (sourcePlanType === ProgramType.Client) {
              copyClientWorkoutGroupTemplate({
                clientWorkoutGroupTemplateId: workoutGroup.id,
                clientId,
                destinationClientTrainingPlanId: destinationTrainingPlanId,
              });
            }

            // copy a master workout group (from a custom training plan) to a client training plan
            if (sourcePlanType === ProgramType.Custom) {
              copyMasterWorkoutGroup({
                clientId: destinationClientId,
                clientTrainingPlanId: destinationTrainingPlanId,
                masterWorkoutGroupId: workoutGroup.id,
                title,
                workoutGroupType: WorkoutGroupType.Gym,
              });
            }
            if (sourcePlanType === ProgramType.Client) {
              // If an active plan is selected, close it.  Otherwise continue flow
              const ctp = thisClientsPlans.find(
                (clientTrainingPlan) =>
                  clientTrainingPlan.id === destinationTrainingPlanId
              );
              if (ctp?.active) {
                onClose();
              }
            }

            setIsSavedToInactivePlan(true);
          }}
        />
      )}

      {/*
        phase 2: confirmation dialog
      */}
      {isSavedToInactivePlan && (
        <div
          className="d-flex flex-column"
          style={{
            // minHeight: 250,
            width: 520,
            padding: 25,
          }}
        >
          <div
            className="d-flex flex-column justify-content-center align-items-center medium-bold"
            style={{
              color: colors.caliber_secondary,
              fontSize: "18px",
              lineHeight: "24px",
            }}
          >
            {title} was successfully copied!
          </div>
          <div
            className="d-flex justify-content-center"
            style={{
              marginTop: 40,
            }}
          >
            {/* Open this client's inactive plan */}
            {destinationClientId === clientId && (
              <ButtonTag
                text="View"
                onClick={() => {
                  // Select inactive training plan
                  selectClientTrainingPlan(
                    thisClientsPlans.findIndex(
                      (clientTrainingPlan) =>
                        clientTrainingPlan.id === destinationTrainingPlanId
                    )
                  );
                  // Reset local state and close modal
                  setIsSavedToInactivePlan(false);
                  onClose();
                }}
                height={32}
                bgColor={colors.caliber_gray_text}
                textColor={colors.caliber_white}
                minWidth={80}
                margin="0px 12px"
              />
            )}
            {/* Link to another client's training program tab */}
            {destinationClientId != clientId && (
              <Link
                to={`/clients/${destinationClientId}/trainingProgram`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ButtonTag
                  text="View"
                  height={32}
                  bgColor={colors.caliber_gray_text}
                  textColor={colors.caliber_white}
                  minWidth={80}
                  margin="0px 12px"
                  onClick={onClose}
                />
              </Link>
            )}
            <ButtonTag
              text="Got it"
              onClick={onClose}
              height={32}
              bgColor={colors.caliber_green_200}
              textColor={colors.caliber_white}
              minWidth={80}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  copyClientWorkoutGroupTemplate: (
    args: MutationCopyClientWorkoutGroupTemplateArgs
  ) => {
    dispatch(copyClientWorkoutGroupTemplate(args));
  },
  selectClientTrainingPlan: (args: number) => {
    dispatch(selectClientTrainingPlan(args));
  },
  copyMasterWorkoutGroup: (
    args: MutationCopyMasterWorkoutGroupToClientWorkoutGroupTemplateArgs
  ) => {
    dispatch(copyMasterWorkoutGroupToClientGroup(args));
  },
});

const mapStateToProps = (state: StoreState) => ({
  thisClientsPlans: selectClientPlans(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CopyWorkoutGroupModal);
