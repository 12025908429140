import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import ClientProfileInfoModule from "../../components/ClientProfileInfoModule";
import ClientTrainingPlans from "../../components/ClientTrainingPlans";
import TrainingProgram, {
  ComponentLocationType,
  ProgramType,
} from "../../components/TrainingProgram";
import { getTrainingPlans } from "../../redux/actions/TrainingPrograms/get";
import { StoreState } from "../../redux/reducers";
import {
  QueryClientTrainingPlansArgs,
  QuerySearchCustomTrainingPlansArgs,
  QuerySearchMasterTrainingPlansArgs,
} from "../../types/gqlTypes";

interface Props {
  getTrainingPlans: (
    args:
      | QuerySearchCustomTrainingPlansArgs
      | QuerySearchMasterTrainingPlansArgs
      | QueryClientTrainingPlansArgs,
    programType?: ProgramType
  ) => void;
}

const TrainerClientTrainingProgramTab = (props: Props) => {
  const { getTrainingPlans } = props;
  const { id: clientId } = useParams();

  useEffect(() => {
    getTrainingPlans({ clientId }, ProgramType.Client);
  }, [clientId]);

  return (
    <div
      className="d-flex"
      style={{
        flex: 1,
      }}
    >
      <div
        className="d-flex flex-column"
        style={{
          width: 272,
          marginLeft: 12,
        }}
      >
        <ClientProfileInfoModule fullView={false} />
        <ClientTrainingPlans />
      </div>
      <div
        className="d-flex"
        style={{
          flex: 1,
        }}
      >
        {/* @ts-ignore */}
        <TrainingProgram componentLocation={ComponentLocationType.ClientTab} />
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  getTrainingPlans: (
    args:
      | QuerySearchCustomTrainingPlansArgs
      | QuerySearchMasterTrainingPlansArgs
      | QueryClientTrainingPlansArgs,
    programType?: ProgramType
  ) => {
    dispatch(getTrainingPlans(args, programType));
  },
});

const mapStateToProps = (state: StoreState) => ({
  masqueradeState: state.masqueradeState,
  user: state.authenticatedUserState.user,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrainerClientTrainingProgramTab);
