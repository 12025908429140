import {
  DELETE_USER_FROM_PLATFORM_FAIL,
  DELETE_USER_FROM_PLATFORM_LOADING,
  DELETE_USER_FROM_PLATFORM_SUCCESS,
  DeleteUserFromPlatformAction,
  DeleteUserFromPlatformState,
} from "../../actions/DeleteUserFromPlatform/types";

const initialState: DeleteUserFromPlatformState = {
  isLoading: false,
};

const deleteUserFromPlatformState = (
  state = initialState,
  action: DeleteUserFromPlatformAction
) => {
  switch (action.type) {
    case DELETE_USER_FROM_PLATFORM_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_USER_FROM_PLATFORM_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case DELETE_USER_FROM_PLATFORM_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default deleteUserFromPlatformState;
