import React from "react";
import colors from "../../../assets/colors";
import { ClientWorkoutGroupTemplate } from "../../../types/gqlTypes";
import WorkoutGroupTemplateCard from "./WorkoutGroupTemplateCard";

interface Props {
  workoutGroupTemplates: ClientWorkoutGroupTemplate[];
  onSelect: (workoutGroup: ClientWorkoutGroupTemplate) => void;
}

const WorkoutGroupTemplateList = (props: Props) => {
  const { workoutGroupTemplates, onSelect } = props;

  return (
    <div className="d-flex flex-column">
      <div
        className="medium-bold"
        style={{
          color: colors.caliber_gray_text,
          fontSize: "14px",
          lineHeight: "24px",
          margin: "16px 0px",
        }}
      >
        {workoutGroupTemplates
          ? "Select workout group"
          : "Please activate a training program to put a workout on the calendar."}
      </div>
      <div
        className="d-flex flex-column"
        style={{
          width: 408,
        }}
      >
        {workoutGroupTemplates?.map((workoutGroupTemplate) => {
          return (
            <WorkoutGroupTemplateCard
              key={workoutGroupTemplate.id}
              workoutGroupTemplate={workoutGroupTemplate}
              onClick={() => onSelect(workoutGroupTemplate)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default WorkoutGroupTemplateList;
