import React from "react";
import colors from "../../assets/colors";
import {
  CalendarItem,
  CalendarItemStatusType,
  CalendarItemType,
  ClientGymStepStatusType,
  WorkoutCalendarItem,
} from "../../types/gqlTypes";

interface Props {
  calendarItem: CalendarItem;
}

const CalendarItemCircle = (props: Props) => {
  const { calendarItem } = props;

  const circleColors = {
    [CalendarItemType.CardioActivity]: colors.caliber_calendar_item_red,
    [CalendarItemType.ImportedCardioActivity]: colors.caliber_calendar_item_red,
    [CalendarItemType.Workout]: colors.caliber_calendar_item_red,
    [CalendarItemType.BodyStat]: colors.caliber_calendar_item_blue,
    [CalendarItemType.ImportedBodyStat]: colors.caliber_calendar_item_blue,
    [CalendarItemType.ProgressPic]: colors.caliber_calendar_item_blue,
    [CalendarItemType.ImportedNutrition]: colors.caliber_calendar_item_green,
    [CalendarItemType.CustomActivity]: colors.caliber_calendar_item_red,
    [CalendarItemType.Steps]: colors.caliber_gold_200,
    [CalendarItemType.ImportedSteps]: colors.caliber_gold_200,
  };

  const calculateCompleteness = (calendarItem: CalendarItem) => {
    if (calendarItem?.enumType === CalendarItemType.Workout) {
      const workoutItem = calendarItem as WorkoutCalendarItem;
      let completed = 0;
      workoutItem?.clientWorkout?.clientGymSteps?.forEach((gymStep) => {
        if (gymStep?.status === ClientGymStepStatusType.Completed) {
          completed += 1;
        }
      });
      return (
        completed / (workoutItem?.clientWorkout?.clientGymSteps?.length || 1)
      );
    }
    return 1;
  };

  if (calendarItem?.status === CalendarItemStatusType.Scheduled) {
    const radius = 6;
    const completeness = calculateCompleteness(calendarItem);
    const circumference = 2 * Math.PI * radius;
    const strokeDasharray1 = completeness * circumference;
    const strokeDasharray2 = (1 - completeness) * circumference;

    return (
      <svg
        style={{
          margin: "0px 4px 0px 2px",
          display: "block",
          borderRadius: "50%",
          transform: "rotate(-90deg)",
        }}
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g strokeWidth={5}>
          <circle
            r={radius}
            cx={radius}
            cy={radius}
            strokeDasharray={`${strokeDasharray1} ${circumference}`}
            strokeDashoffset={0}
            stroke={circleColors?.[calendarItem?.enumType]}
          />
          <circle
            r={radius}
            cx={radius}
            cy={radius}
            strokeDasharray={`${strokeDasharray2} ${circumference}`}
            strokeDashoffset={-strokeDasharray1}
            stroke="#C2C3CD"
          />
        </g>
      </svg>
    );
  }
  if (calendarItem?.status === CalendarItemStatusType.Completed) {
    return (
      <svg
        style={{
          margin: "0px 4px 0px 2px",
          display: "block",
        }}
        width="13"
        height="12"
        viewBox="0 0 13 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill={circleColors?.[calendarItem?.enumType]}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.428589 6C0.428589 2.68629 3.11488 0 6.42859 0C9.7423 0 12.4286 2.68629 12.4286 6C12.4286 9.31371 9.7423 12 6.42859 12C3.11488 12 0.428589 9.31371 0.428589 6Z"
        />
      </svg>
    );
  }
  return null;
};

export default CalendarItemCircle;
