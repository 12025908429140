import { format } from "date-fns";
import React from "react";
import colors from "../../assets/colors";

interface Props {
  date: Date;
  id?: string;
  style?: React.CSSProperties;
}

const DateBadge = (props: Props) => {
  const { date, id, style } = props;

  return (
    <div
      id={id}
      className="d-flex align-items-center justify-content-center"
      style={{
        backgroundColor: "#A4A3A5",
        color: colors.caliber_secondary,
        fontSize: "9px",
        lineHeight: "14px",
        width: 58,
        height: 18,
        borderRadius: 6,
        ...style,
      }}
    >
      {date && format(date, "M/dd/yyyy")}
    </div>
  );
};

export default DateBadge;
