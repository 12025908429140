import React, { useState } from "react";
import SimpleButton from "../SimpleButton";

interface Props {
  isDefault?: boolean;
  isAsc?: boolean;
  onAscClick?: () => void;
  onDescClick?: () => void;
}

const SortButton = (props: Props) => {
  const { isDefault, isAsc, onAscClick, onDescClick } = props;
  const [hovered1, setHovered1] = useState(false);
  const [hovered2, setHovered2] = useState(false);

  let ascColor = !isDefault && isAsc ? "#01052F" : "#82869B";
  let descColor = !isDefault && !isAsc ? "#01052F" : "#82869B";
  if (hovered1) {
    ascColor = "#01052F";
  }
  if (hovered2) {
    descColor = "#01052F";
  }
  return (
    <div id="sort_icon" className="d-flex flex-column">
      <SimpleButton
        nofocus
        onClick={onAscClick}
        onMouseEnter={() => setHovered1(true)}
        onMouseLeave={() => setHovered1(false)}
      >
        <svg
          style={{
            display: "block",
          }}
          width="20"
          height="10"
          viewBox="0 0 20 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.63971 4.37442C9.83641 4.17001 10.1636 4.17001 10.3603 4.37442L13.5154 7.65331C13.8211 7.97096 13.5959 8.5 13.1551 8.5H6.84489C6.40406 8.5 6.17894 7.97096 6.4846 7.65331L9.63971 4.37442Z"
            fill={ascColor}
          />
        </svg>
      </SimpleButton>

      <SimpleButton
        nofocus
        onClick={onDescClick}
        onMouseEnter={() => setHovered2(true)}
        onMouseLeave={() => setHovered2(false)}
      >
        <svg
          style={{
            display: "block",
          }}
          width="20"
          height="10"
          viewBox="0 0 20 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.3603 5.62558C10.1636 5.82999 9.83641 5.82999 9.63971 5.62558L6.4846 2.34669C6.17894 2.02904 6.40406 1.5 6.84489 1.5L13.1551 1.5C13.5959 1.5 13.8211 2.02904 13.5154 2.34669L10.3603 5.62558Z"
            fill={descColor}
          />
        </svg>
      </SimpleButton>
    </div>
  );
};

export default SortButton;
