import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import PlusIconDarker from "../../assets/images/PlusIconDarker.svg";
import { getTrainingPlans } from "../../redux/actions/TrainingPrograms/get";
import { toggleActiveClientTrainingPlan } from "../../redux/actions/TrainingPrograms/update";
import { StoreState } from "../../redux/reducers";
import { selectClientPlans } from "../../redux/reducers/TrainingPrograms/selectors";
import {
  ClientTrainingPlan,
  CustomTrainingPlan,
  LibraryTrainingPlan,
  MasterTrainingPlan,
  QueryClientTrainingPlansArgs,
  QuerySearchCustomTrainingPlansArgs,
  QuerySearchMasterTrainingPlansArgs,
} from "../../types/gqlTypes";
import { ProgramType } from "../TrainingProgram";
import ClientTrainingPlanItem from "./ClientTrainingPlanItem";
import AddTrainingPlanButton from "../AddTrainingPlanButton";
import { createNewClientTrainingPlan } from "../../redux/actions/TrainingPrograms/createAssign";

interface Props {
  clientPlanIndex: number;
  plans: (
    | MasterTrainingPlan
    | ClientTrainingPlan
    | CustomTrainingPlan
    | LibraryTrainingPlan
  )[];
  getTrainingPlans: (
    args:
      | QuerySearchCustomTrainingPlansArgs
      | QuerySearchMasterTrainingPlansArgs
      | QueryClientTrainingPlansArgs,
    programType?: ProgramType
  ) => void;
  toggleActiveClientTrainingPlan: (args: {
    clientId: string;
    activatePlanId?: string;
    deactivatePlanId?: string;
  }) => void;
  addTrainingPlan: (clientId: string) => void;
}

const ClientTrainingPlans = (props: Props) => {
  const {
    clientPlanIndex,
    plans,
    getTrainingPlans,
    toggleActiveClientTrainingPlan,
    addTrainingPlan,
  } = props;
  const { id: clientId } = useParams();

  useEffect(() => {
    if (clientId) {
      getTrainingPlans({ clientId }, ProgramType.Client);
    }
  }, [clientId]);

  const togglePlanActivation = (plan: ClientTrainingPlan) => {
    const activePlan = plans?.find(
      (plan) => (plan as ClientTrainingPlan)?.active
    );
    const args: any = { clientId };
    if (plan?.active) {
      // to deactivate active plan
      args.deactivatePlanId = plan?.id;
    } else if (!plan?.active && activePlan) {
      // to deactivate active plan and activate chosen plan
      args.deactivatePlanId = activePlan?.id;
      args.activatePlanId = plan?.id;
    } else if (!activePlan && !plan?.active) {
      args.activatePlanId = plan?.id;
      // no active plan - just activate inactive plan
    }
    toggleActiveClientTrainingPlan(args);
  };

  return (
    <div
      className="d-flex flex-column"
      style={{
        flex: 1,
        position: "relative",
      }}
    >
      <div
        className="d-flex flex-column"
        style={{
          alignItems: "center",
          marginTop: 24,
          marginBottom: 12,
        }}
      >
        <AddTrainingPlanButton
          icon={<img alt="Add Training Plan" src={PlusIconDarker} />}
          title="Add training plan"
          width={167}
          onClick={() => {
            addTrainingPlan(clientId);
          }}
        />
      </div>
      <div
        className="d-flex flex-column"
        style={{
          flex: "1 1 0",
          overflowY: "auto",
          minHeight: 200,
        }}
      >
        {plans?.map((currentPlan: ClientTrainingPlan, index: number) => {
          const isSelected = index === clientPlanIndex;
          return (
            // @ts-ignore
            <ClientTrainingPlanItem
              index={index}
              key={currentPlan?.id}
              plan={currentPlan}
              isSelected={isSelected}
              togglePlanActivation={() => {
                togglePlanActivation(currentPlan);
              }}
              clientId={clientId}
            />
          );
        })}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addTrainingPlan: (clientId: string) => {
    dispatch(createNewClientTrainingPlan(clientId, ""));
  },
  getTrainingPlans: (
    args:
      | QuerySearchCustomTrainingPlansArgs
      | QuerySearchMasterTrainingPlansArgs
      | QueryClientTrainingPlansArgs,
    programType?: ProgramType
  ) => {
    dispatch(getTrainingPlans(args, programType));
  },
  toggleActiveClientTrainingPlan: (args: {
    clientId: string;
    activatePlanId?: string;
    deactivatePlanId?: string;
  }) => {
    dispatch(toggleActiveClientTrainingPlan(args));
  },
});

const mapStateToProps = (state: StoreState) => ({
  plans: selectClientPlans(state),
  clientPlanIndex: state.trainingProgramsState.clientPlanIndex,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientTrainingPlans);
