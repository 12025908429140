import { format, parse } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { useParams } from "react-router-dom";
import colors from "../../assets/colors";
import {
  updateClientCallsLeft,
  updateClientSalesPackageEndDate,
} from "../../redux/actions/ClientDetail";
import { StoreState } from "../../redux/reducers";
import {
  MutationUpdateClientSalesPackageEndDateArgs,
  User,
  UserType,
} from "../../types/gqlTypes";
import EditIcon from "../Icons/EditIcon";
import SimpleButton from "../SimpleButton";

interface Props {
  packageEndDate: string;
  callsLeft: number;
  updateClientCallsLeft: (clientId: string, callsLeft: number) => void;
  updateClientSalesPackageEndDate: (
    args: MutationUpdateClientSalesPackageEndDateArgs
  ) => void;
  user: User;
}
interface DateInputFieldProps {
  value?: string;
  onClick?: (date?: any) => void;
}

const ServiceTracker = (props: Props) => {
  const {
    packageEndDate,
    callsLeft,
    updateClientCallsLeft,
    updateClientSalesPackageEndDate,
    user,
  } = props;
  const { id: clientId } = useParams();

  const [editingCallsLeft, setEditingCallsLeft] = useState(null);
  const [editingPackageEndDate, setEditingPackageEndDate] = useState(
    false as boolean
  );
  const [packageEndDateField, setPackageEndDateField] = useState(
    packageEndDate as string
  );
  const inputRef = useRef(null);
  const parsedDate: Date = packageEndDate
    ? parse(packageEndDate, "yyyy-MM-dd", new Date())
    : new Date();

  const init = () => {
    if (packageEndDate) {
      const date = parse(packageEndDate, "yyyy-MM-dd", new Date());
      setPackageEndDateField(format(date, "M.dd.yyyy"));
    }
  };

  useEffect(() => {
    init();
  }, [packageEndDate]);

  const onSaveCallsLeft = () => {
    updateClientCallsLeft(clientId, Number(editingCallsLeft) || 0);
    setEditingCallsLeft(null);
  };

  const onSavePackageEndDate = (date: Date) => {
    updateClientSalesPackageEndDate({
      clientId,
      packageEndDate: format(date, "yyyy-MM-dd"),
    } as MutationUpdateClientSalesPackageEndDateArgs);
    setEditingPackageEndDate(false);
  };

  const DateInputField = (props: DateInputFieldProps) => {
    return (
      <div
        role="button"
        tabIndex={0}
        onClick={(a: any) => {
          if (props?.onClick) {
            props.onClick();
          }
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter" && props?.onClick) {
            props?.onClick();
          }
        }}
        className="d-flex flex-column justify-content-center align-items-center medium-bold pointer"
        style={{
          backgroundColor: colors.caliber_gray_bg,
          padding: "2px 10px",
          borderRadius: 4,
          minWidth: 90,
          margin: 0,
          fontSize: "12px",
          lineHeight: "16px",
        }}
      >
        {packageEndDateField || "--"}
      </div>
    );
  };

  const editCallsLeftInput = (
    <input
      ref={inputRef}
      value={editingCallsLeft}
      onChange={(event) => {
        const { value } = event.target;
        if ((Number(value) >= 0 && Number(value) <= 50) || value === "") {
          setEditingCallsLeft(value);
        }
      }}
      onKeyDown={(event) => {
        const { key } = event;
        if (key === "Enter") {
          onSaveCallsLeft();
        }
        if (key !== "Backspace" && isNaN(Number(key))) {
          event.preventDefault();
        }
      }}
      onBlur={() => {
        onSaveCallsLeft();
      }}
      style={{
        textAlign: "center",
        backgroundColor: "#F3F3F5",
        color: colors.caliber_secondary,
        borderRadius: 4,
        borderColor: colors.caliber_white,
        boxShadow: "none",
        border: "none",
        borderWidth: 0,
        width: 30,
        height: 20,
        fontSize: "12px",
      }}
    />
  );

  const callsLeftDiv = (
    <div className="d-flex">
      <SimpleButton
        onClick={() => {
          setEditingCallsLeft(callsLeft?.toString() || "0");
          setTimeout(() => {
            inputRef?.current?.focus();
          }, 0);
        }}
        className="d-flex align-items-center justify-content-center"
        style={{
          marginRight: 6,
        }}
      >
        <EditIcon />
      </SimpleButton>
      <div
        className="bold"
        style={{
          padding: "1px 10px",
          borderRadius: 4,
          fontSize: "12px",
          backgroundColor: colors.caliber_white,
          color: colors.caliber_secondary,
        }}
      >
        {callsLeft || 0}
      </div>
    </div>
  );

  const editPackageEndDateInput = (
    <DatePicker
      onChange={(date: Date) => {
        setPackageEndDateField(format(date, "MM.dd.yyyy"));
        onSavePackageEndDate(date);
      }}
      customInput={<DateInputField />}
      selected={parsedDate}
    />
  );

  const packageEndDateDiv = (
    <div className="d-flex">
      {user.type != UserType.Trainer && (
        <SimpleButton
          onClick={() => {
            setEditingPackageEndDate(true);
          }}
          className="d-flex align-items-center justify-content-center"
          style={{
            marginRight: 6,
          }}
        >
          <EditIcon />
        </SimpleButton>
      )}
      <div
        className="bold"
        style={{
          padding: "1px 10px",
          borderRadius: 4,
          fontSize: "12px",
          backgroundColor: colors.caliber_white,
          color: colors.caliber_secondary,
        }}
      >
        {packageEndDateField || "n/a"}
      </div>
    </div>
  );

  return (
    <div>
      <div
        style={{
          color: colors.caliber_gray_border,
          marginTop: 15,
        }}
        className="paragraph-small"
      >
        Service tracker
      </div>

      <div
        style={{ margin: "12px 0px" }}
        className="d-flex justify-content-between align-items-center"
      >
        <div
          className="medium-bold"
          style={{
            fontSize: "12px",
            lineHeight: "16px",
            color: colors.caliber_secondary,
          }}
        >
          Strategy calls left
        </div>
        {typeof editingCallsLeft === "string"
          ? editCallsLeftInput
          : callsLeftDiv}
      </div>

      <div
        style={{ margin: "12px 0px" }}
        className="d-flex justify-content-between align-items-center"
      >
        <div
          className="medium-bold"
          style={{
            fontSize: "12px",
            lineHeight: "16px",
            color: colors.caliber_secondary,
          }}
        >
          Package end date
        </div>
        {editingPackageEndDate ? editPackageEndDateInput : packageEndDateDiv}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateClientCallsLeft: (clientId: string, callsLeft: number) => {
    dispatch(updateClientCallsLeft(clientId, callsLeft));
  },
  updateClientSalesPackageEndDate: (
    args: MutationUpdateClientSalesPackageEndDateArgs
  ) => {
    dispatch(updateClientSalesPackageEndDate(args));
  },
});

const mapStateToProps = (state: StoreState) => ({
  callsLeft: state.clientDetailState.callsLeft,
  packageEndDate: state.clientDetailState.packageEndDate,
  user: state.authenticatedUserState.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceTracker);
