import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import colors from "../../assets/colors";
import {
  copyTrainingPlanToTrainer,
  toggleCopyTrainingPlanToTrainer,
  viewNewlyCreatedCustomPlan,
} from "../../redux/actions/TrainingPrograms/copy";
import { createTrainingPlan } from "../../redux/actions/TrainingPrograms/createAssign";
import { getTrainerId, StoreState } from "../../redux/reducers";
import {
  ClientTrainingPlan,
  MutationCopyTrainingPlanToTrainerArgs,
  MutationCreateCustomTrainingPlanArgs,
} from "../../types/gqlTypes";
import ConfirmationDialog from "../ConfirmationDialog";
import CloseIcon from "../Icons/CloseIcons";
import Loader from "../Loader";
import Modal from "../Modal";
import SimpleButton from "../SimpleButton";
import { ProgramType } from "../TrainingProgram";

interface Props {
  isCopying: boolean;
  plan: ClientTrainingPlan;
  nameExists: boolean;
  success: boolean;
  showCreateCustomPlan: boolean;
  destinationTrainerId: string;
  selectedClientId: string;
  onCancel: () => void;
  viewPlan: () => void;
  copyTrainingPlanToTrainer: (
    args: MutationCopyTrainingPlanToTrainerArgs
  ) => void;
  createCustomTrainingPlan: (
    args: MutationCreateCustomTrainingPlanArgs
  ) => void;
}

const CopyPlanToTrainer = (props: Props) => {
  const {
    isCopying,
    plan,
    nameExists,
    success,
    showCreateCustomPlan,
    destinationTrainerId,
    selectedClientId,
    onCancel,
    viewPlan,
    copyTrainingPlanToTrainer,
    createCustomTrainingPlan,
  } = props;
  const history = useHistory();

  const inputRef = useRef(null);
  const [name, setName] = useState("");
  const [didChangeName, setDidChangeName] = useState(true);
  const canBeSaved = (!nameExists || didChangeName) && name.length > 0;

  useEffect(() => {
    if (nameExists) {
      setDidChangeName(!nameExists);
    }
  }, [nameExists]);

  const onSave = () => {
    if (name.length > 0 && canBeSaved) {
      if (showCreateCustomPlan) {
        createCustomTrainingPlan({
          trainerId: destinationTrainerId,
          title: name,
        });
      } else {
        copyTrainingPlanToTrainer({
          trainerId: destinationTrainerId,
          trainingPlanId: plan.id,
          name,
          clientId: selectedClientId,
        });
      }
    }
  };

  const modalStyle: React.CSSProperties = {
    padding: 24,
    width: 486,
    height: 250,
    position: "relative",
  };
  const titleStyle: React.CSSProperties = {
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "-0.022em",
    color: "#000000",
  };
  const fieldStyle: React.CSSProperties = {
    minHeight: 80,
    borderRadius: 8,
    marginTop: 29,
  };
  const inputStyle: React.CSSProperties = {
    border: `1px solid ${
      nameExists ? colors.caliber_primary : colors.caliber_gray_border
    }`,
    backgroundColor: colors.caliber_white,
    borderRadius: 8,
    height: 32,
    fontSize: "18px",
    lineHeight: "24px",
    letterSpacing: "-0.03em",
    color: colors.caliber_secondary,
    margin: "24px 16px 0px 12px",
    padding: "3px 12px 5px 12px",
  };
  const errTextStyle: React.CSSProperties = {
    fontSize: "14px",
    lineHeight: "16px",
    color: colors.caliber_primary,
    margin: "5px 16px",
    visibility: nameExists ? "visible" : "hidden",
  };
  const nextBtnStyle: React.CSSProperties = {
    fontSize: "14px",
    lineHeight: "14px",
    letterSpacing: "-0.03em",
    backgroundColor: canBeSaved
      ? colors.caliber_green_200
      : colors.caliber_secondary_gray_11,
    color: canBeSaved ? colors.caliber_white : colors.caliber_secondary_gray_38,
    borderRadius: 8,
    width: 146,
    height: 36,
    margin: "32px 0px 12px 0px",
  };
  const loaderStyle: React.CSSProperties = {
    ...modalStyle,
    position: "absolute",
    opacity: 0.5,
    top: "45%",
    left: "calc(50% - 24px)",
  };

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  if (success) {
    return (
      <ConfirmationDialog
        show
        closeOnDimmerClick={false}
        text={`${name} has been added to your workout group library`}
        confirmButtonText="Got it"
        cancelButtonText="View"
        onConfirm={onCancel}
        onCancel={() => {
          viewPlan();
          history?.push("/programLibrary");
        }}
      />
    );
  }

  return (
    <Modal onClose={onCancel} closeOnDimmerClick={false}>
      <div style={modalStyle} className="d-flex flex-column">
        {isCopying && (
          <div style={loaderStyle}>
            <Loader />
          </div>
        )}

        <div className="d-flex align-items-center justify-content-between bold">
          <div style={titleStyle}>Name this custom Workout Group</div>
          <SimpleButton onClick={onCancel}>
            <CloseIcon width={26} height={26} />
          </SimpleButton>
        </div>

        <div style={fieldStyle} className="d-flex flex-column ">
          <input
            ref={inputRef}
            value={name}
            onChange={(event) => {
              setName(event.target.value);
              if (nameExists) {
                setDidChangeName(true);
              }
            }}
            style={inputStyle}
            className="d-flex align-items-center bold nofocus"
          />
          <div style={errTextStyle} className="d-flex align-items-center">
            Name of plan already exists. Choose a different name.
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <SimpleButton
            nofocus
            disabled={!canBeSaved}
            className="d-flex justify-content-center align-items-center bold"
            onClick={onSave}
            style={nextBtnStyle}
          >
            Next
          </SimpleButton>
        </div>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onCancel: () => {
    dispatch(toggleCopyTrainingPlanToTrainer());
  },
  viewPlan: () => {
    dispatch(viewNewlyCreatedCustomPlan());
  },
  copyTrainingPlanToTrainer: (args: MutationCopyTrainingPlanToTrainerArgs) => {
    dispatch(copyTrainingPlanToTrainer(args));
  },
  createCustomTrainingPlan: (args: MutationCreateCustomTrainingPlanArgs) => {
    dispatch(createTrainingPlan(args, ProgramType.Custom));
  },
});

const mapStateToProps = (state: StoreState) => ({
  plan: state.trainingProgramsState.copyPlanToTrainer,
  nameExists: state.trainingProgramsState.failedToCreateCustomPlan,
  success: state.trainingProgramsState.successToCreateCustomPlan,
  isCopyingPlan: state.trainingProgramsState.isCopying,
  showCreateCustomPlan: state.trainingProgramsState.showCreateCustomPlan,
  destinationTrainerId: getTrainerId(state),
  selectedClientId: state.clientDetailState.user.id,
});

export default connect(mapStateToProps, mapDispatchToProps)(CopyPlanToTrainer);
