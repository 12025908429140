import React from "react";

interface Props {}

const VideoIcon = (props: Props) => {
  const {} = props;

  return (
    <svg
      style={{ display: "block" }}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9Z"
        fill="#1FC866"
      />
      <path
        d="M3.75 6.2505C3.75017 6.20413 3.78763 6.16668 3.834 6.1665H10.7467C10.7931 6.16668 10.8306 6.2042 10.8307 6.25052C10.8307 6.25059 10.8307 6.25065 10.8307 6.25072V11.7329C10.8307 11.733 10.8307 11.733 10.8307 11.7331C10.8306 11.7794 10.7931 11.8169 10.7467 11.8171C10.7466 11.8171 10.7466 11.8171 10.7465 11.8171H3.83422C3.83415 11.8171 3.83408 11.8171 3.83402 11.8171C3.78763 11.8169 3.75017 11.7795 3.75 11.7331V6.2505Z"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M14.4 7.31635V10.6858L12.7363 9.7775V8.22467L14.4 7.31635Z"
        stroke="white"
        strokeWidth="1.2"
      />
    </svg>
  );
};

export default VideoIcon;
