import { gql } from "apollo-boost";
import {
  clientGymStepTemplatesFields,
  clientGymStepTemplatesSelectors,
  commonExerciseFields,
  commonSupersetChildFields,
  commonSupersetFields,
  masterGymStepsFields,
  masterGymStepsSelectors,
} from "../shared";

export const workoutResponseFragments = gql`
  ${masterGymStepsFields}
  ${clientGymStepTemplatesFields}
`;

export const workoutResponse = `
  ...WorkoutProperty
  gymSteps {
    ${masterGymStepsSelectors}
    ${clientGymStepTemplatesSelectors}
  }  
`;

export const CONVERT_GYM_STEP_TO_SUPERSET = gql`
  ${workoutResponseFragments}
  mutation ConvertGymStepToSuperset(
    $clientId: UUID
    $trainerId: UUID
    $gymStepId: UUID
    ){ 
      convertGymStepToSuperset (
        clientId: $clientId
        gymStepId: $gymStepId
        trainerId: $trainerId
    ) {
      ${workoutResponse}
    }
  }
`;

export const MOVE_SUPERSET_CHILD_TO_WORKOUT = gql`
  ${workoutResponseFragments}
  mutation MoveSupersetChildToWorkout(
    $clientId: UUID
    $trainerId: UUID
    $supersetChildGymStepId: UUID
    $position: Int!
  ){
    moveSupersetChildToWorkout (
      clientId: $clientId
      trainerId: $trainerId
      supersetChildGymStepId: $supersetChildGymStepId
      position: $position
    ) {
      ${workoutResponse}
    }
  }
`;

export const ADD_CHILD_TO_SUPERSET = gql`
  ${workoutResponseFragments}
  mutation AddChildToSuperset(
    $clientId: UUID
    $trainerId: UUID
    $supersetGymStepId: UUID
    $exerciseId: UUID
    $position: Int!
    $isLibraryTrainingPlan: Boolean
  ){
    addChildToSuperset (
      clientId: $clientId
      trainerId: $trainerId
      supersetGymStepId: $supersetGymStepId
      exerciseId: $exerciseId
      position: $position
      isLibraryTrainingPlan: $isLibraryTrainingPlan
    ) {
      ${workoutResponse}
    }
  }
`;

export const ADD_CHILD_TO_SUPERSET_IN_LIBRARY_TRAINING_PLAN = gql`
  ${workoutResponseFragments}
  mutation AddChildToSupersetInLibraryTrainingPlan(
    $clientId: UUID
    $trainerId: UUID
    $supersetGymStepId: UUID
    $exerciseId: UUID
    $position: Int!
    $libraryTrainingPlanId: UUID!
  ){
    addChildToSupersetInLibraryTrainingPlan (
      clientId: $clientId
      trainerId: $trainerId
      supersetGymStepId: $supersetGymStepId
      exerciseId: $exerciseId
      position: $position
      libraryTrainingPlanId: $libraryTrainingPlanId
    ) {
      ${workoutResponse}
    }
  }
`;

export const MOVE_WORKOUT_CHILD_TO_SUPERSET = gql`
  ${workoutResponseFragments}
  mutation MoveWorkoutChildToSuperset(
    $clientId: UUID
    $trainerId: UUID
    $destinationSupersetId: UUID
    $gymStepId: UUID
    $position: Int!
  ){
    moveWorkoutChildToSuperset (
      clientId: $clientId
      trainerId: $trainerId
      destinationSupersetId: $destinationSupersetId
      gymStepId: $gymStepId
      position: $position
    ) {
      ${workoutResponse}
    }
  }
`;

export const DELETE_CHILD_FROM_SUPERSET = gql`
  ${workoutResponseFragments}
  mutation DeleteChildFromSuperset(
    $clientId: UUID
    $trainerId: UUID
    $supersetChildGymStepId: UUID
    $isLibraryTrainingPlan: Boolean
  ){ 
    deleteChildFromSuperset (
      clientId: $clientId
      trainerId: $trainerId
      supersetChildGymStepId: $supersetChildGymStepId
      isLibraryTrainingPlan: $isLibraryTrainingPlan
    ) {
      ${workoutResponse}
    }
  }
`;

export const REORDER_CHILDREN_IN_SUPERSET = gql`
  ${workoutResponseFragments}
  mutation ReorderChildrenInSuperset(
    $clientId: UUID
    $trainerId: UUID
    $supersetGymStepId: UUID
    $supersetChildGymStepIds: [UUID]
  ){
    reorderChildrenInSuperset (
      clientId: $clientId
      trainerId: $trainerId
      supersetGymStepId: $supersetGymStepId
      supersetChildGymStepIds:  $supersetChildGymStepIds
    ) {
      ${workoutResponse}
    }
  }
`;

export const UPDATE_SUPERSET = gql`
  ${masterGymStepsFields}
  ${clientGymStepTemplatesFields}  
  mutation UpdateSuperset(
    $clientId: UUID
    $trainerId: UUID
    $supersetGymStepId: UUID
    $targetSets: Int
    $restPeriod: Int
    ){
      updateSuperset (
        clientId: $clientId
        trainerId: $trainerId
        supersetGymStepId: $supersetGymStepId
        targetSets: $targetSets
        restPeriod: $restPeriod
    ) {
      ${masterGymStepsSelectors}
      ${clientGymStepTemplatesSelectors}
    }
  }
`;

export const UPDATE_SUPERSET_CHILD = gql`
mutation UpdateSupersetChild(
    $clientId: UUID
    $trainerId: UUID
    $supersetChildGymStepId: UUID
    $targetRepsLowerBound: Int
    $targetRepsUpperBound: Int
    $hideWeightForExerciseSets: Boolean
    $target: String
    $targetTime: Int
  ){
    updateSupersetChild(
      clientId: $clientId
      trainerId: $trainerId
      supersetChildGymStepId: $supersetChildGymStepId
      targetRepsLowerBound: $targetRepsLowerBound
      targetRepsUpperBound: $targetRepsUpperBound
      hideWeightForExerciseSets: $hideWeightForExerciseSets
      target: $target
      targetTime: $targetTime
    ) {
      id
      ${commonSupersetChildFields}
      supersetGymStep {
        id
        ${commonSupersetFields}
        gymSteps {
          id
          ${commonSupersetChildFields}
          ${commonExerciseFields}
          supersetGymStep {
            id
          }
        }
      }
    }
  }
`;
