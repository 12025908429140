import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDrop } from "react-dnd";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import colors from "../../../assets/colors";
import {
  closeSupersetEditorInPlan,
  hideExercisesInPlan,
  openExerciseLibraryTrainingPlan,
} from "../../../redux/actions/TrainingPrograms";
import {
  getIsAdmin,
  getIsMasquerade,
  StoreState,
} from "../../../redux/reducers";

import {
  ClientGymStepTemplate,
  GymStep,
  MasterGymStep,
  MutationAddChildToSupersetArgs,
  MutationDeleteChildFromSupersetArgs,
  MutationMoveWorkoutChildToSupersetArgs,
  MutationReorderChildrenInSupersetArgs,
  MutationUpdateSupersetChildArgs,
  Superset,
} from "../../../types/gqlTypes";
import ExerciseItem, { DraggableItem } from "../../ExerciseItem";
import { ProgramType } from "../../TrainingProgram";
import EmptyExerciseList from "../../TrainingProgramWorkoutList/EmptyExerciseList";
import SimpleButton from "../../SimpleButton";
import {
  addChildToSuperset,
  deleteChildFromSuperset,
  moveWorkoutChildToSuperset,
  reorderChildrenInSuperset,
  updateSupersetChild,
} from "../../../redux/actions/TrainingPrograms/supersets";
import { selectCurrentSuperset } from "../../../redux/reducers/TrainingPrograms/selectors";

interface OwnProps {}

interface Props extends OwnProps {
  updateSupersetChild: (args: MutationUpdateSupersetChildArgs) => void;
  deleteChildFromSuperset: (
    args: MutationDeleteChildFromSupersetArgs,
    programType?: ProgramType
  ) => void;
  openExerciseLibrary: () => void;
  reorderChildrenInSuperset: (
    args: MutationReorderChildrenInSupersetArgs
  ) => void;
  addChildToSuperset: (
    args: MutationAddChildToSupersetArgs,
    programType?: ProgramType
  ) => void;
  moveWorkoutChildToSuperset: (
    args: MutationMoveWorkoutChildToSupersetArgs
  ) => void;
  closeSupersetEditor: () => void;
  onToggleDragging?: (value: boolean) => void;
  showExerciseLibrary: boolean;
  gymSteps: (MasterGymStep | ClientGymStepTemplate)[];
  programType: ProgramType;
  isMasquerade: boolean;
  isAdmin: boolean;
  superset: Superset;
}

const SupersetExerciseList = (props: Props) => {
  const {
    updateSupersetChild,
    deleteChildFromSuperset,
    openExerciseLibrary,
    reorderChildrenInSuperset,
    addChildToSuperset,
    moveWorkoutChildToSuperset,
    closeSupersetEditor,
    onToggleDragging,
    showExerciseLibrary,
    gymSteps,
    programType,
    isMasquerade,
    isAdmin,
    superset,
  } = props;
  const { id: clientId } = useParams();
  let canBeChanged = true;
  if (programType === ProgramType.Master) {
    // only admins with no Masquerade and in Program Library component
    canBeChanged = isAdmin && !isMasquerade && !clientId;
  }

  const [localItems, setLocalItems] = useState([] as string[]);
  const [selectedEditMode, setSelectedEditMode] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  const gymStepObject = useMemo(() => {
    const object: { [key: string]: GymStep } = {};
    gymSteps?.forEach((gymStep) => {
      object[gymStep.id] = gymStep;
    });
    return object;
  }, [gymSteps]);

  const moveCard = (id: string, atIndex: number) => {
    if (canDrop) {
      const { index } = findCard(id);
      const items = [...localItems];
      if (index > -1) {
        const temp = items[index];
        items[index] = items[atIndex];
        items[atIndex] = temp;
        setLocalItems(items);
      } else {
        items.splice(atIndex, 0, id);
        setLocalItems(items);
      }
    }
  };
  const findCard = (id: string) => {
    return {
      index: localItems.indexOf(id),
    };
  };

  const canDropExerciseIntoList = (item: DraggableItem, monitor) => {
    if (!item.isSuperset) {
      return true;
    }
    return false;
  };

  const onDrop = useCallback(
    (item: any, monitor) => {
      const isReorder = !!gymStepObject?.[item?.id];
      if (isReorder) {
        const gymStepIds = localItems.map(
          (exerciseId) => gymStepObject[exerciseId].id
        );
        reorderChildrenInSuperset({
          supersetChildGymStepIds: gymStepIds,
          supersetGymStepId: superset.id,
        });
      } else {
        const gymStepIds = localItems.map(
          (exerciseId) => gymStepObject?.[exerciseId]?.id || exerciseId
        );
        if (!gymStepIds.includes(item.id)) {
          gymStepIds.push(item.id);
        }

        // Are we adding to a workout or to a superset?
        if (item.isWorkoutChild) {
          moveWorkoutChildToSuperset({
            destinationSupersetId: superset.id,
            gymStepId: item.id,
            position: gymStepIds.findIndex((id) => id === item.id),
          });
        } else {
          addChildToSuperset(
            {
              supersetGymStepId: superset.id,
              exerciseId: item.id,
              position: gymStepIds.findIndex((id) => id === item.id),
            },
            programType
          );
        }
      }
    },
    [localItems]
  );

  const [{ isOver, didDrop, canDrop }, drop] = useDrop({
    accept: "exercise",
    drop: onDrop,
    canDrop: canDropExerciseIntoList,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      didDrop: monitor.didDrop(),
    }),
  });

  useEffect(() => {
    setSelectedEditMode(null);
    if (!isOver && !didDrop) {
      setLocalItems(gymSteps?.map((gymStep) => gymStep.id));
    }
  }, [gymSteps, isOver]);

  const dropboxStyle = useMemo(() => {
    const style: React.CSSProperties = {
      position: "relative",
      flex: "1 1 0",
      backgroundColor: "transparent",
      border: "none",
      padding: "0px 6px",
    };
    if (isDragging || canDrop) {
      style.backgroundColor = "#9da4d6";
      style.border = "2px dotted #5b64a0";
    }
    return style;
  }, [isDragging, canDrop]);

  return (
    <div
      className="d-flex"
      style={{
        flex: 1,
      }}
    >
      <div
        className="d-flex flex-column"
        style={{
          flex: "1 1 50%",
          marginRight: 12,
        }}
      >
        <div
          className="d-flex justify-content-between"
          style={{
            padding: "8px 12px",
          }}
        >
          <div
            style={{
              lineHeight: "32px",
              color: colors.caliber_gray_border,
            }}
            className="paragraph-normal align-self-center"
          >
            Exercises
          </div>
          {canBeChanged && (
            <div className="d-flex flex-column align-items-center justify-content-center">
              <SimpleButton
                className="pointer nofocus circular-medium-font"
                style={{
                  backgroundColor: colors.caliber_gray_11,
                  color: colors.caliber_secondary,
                  padding: "1px 12px",
                  borderRadius: 8,
                  fontSize: "14px",
                  lineHeight: "24px",
                }}
                onClick={openExerciseLibrary}
              >
                Add exercise
              </SimpleButton>
            </div>
          )}
        </div>

        {!showExerciseLibrary && localItems?.length === 0 && (
          <EmptyExerciseList onAddNewExercise={openExerciseLibrary} />
        )}

        {(showExerciseLibrary || localItems?.length > 0) && (
          <div
            className="d-flex flex-column overflow-auto"
            style={dropboxStyle}
          >
            <div
              ref={canBeChanged ? drop : null}
              style={{
                flex: "1 1 0",
              }}
            >
              {localItems?.map((gymStepId, index) => {
                const isVisible =
                  typeof selectedEditMode !== "number" ||
                  index === selectedEditMode;
                const gymStep = gymStepObject?.[gymStepId];
                const exercise = gymStep?.exercise;
                return (
                  isVisible && (
                    <ExerciseItem
                      key={gymStepId}
                      id={gymStepId}
                      index={index}
                      showTarget
                      showVideoOnHover
                      onToggleDragging={(newDrag) => {
                        setIsDragging(newDrag); // activates drop zone for SupersetExerciseList
                        onToggleDragging(newDrag); // activates drop zone for WorkoutItem->ExerciseList
                      }}
                      gymStep={gymStep}
                      showMenu={canBeChanged}
                      exercise={exercise}
                      selectedEditMode={selectedEditMode}
                      transparent={!gymStep}
                      draggable={canBeChanged}
                      onStartEdit={() => setSelectedEditMode(index)}
                      onSaveEdit={(args) => updateSupersetChild(args)}
                      onDelete={() => {
                        deleteChildFromSuperset(
                          {
                            supersetChildGymStepId: gymStep?.id,
                          },
                          programType
                        );
                        if (superset.gymSteps.length === 1) {
                          closeSupersetEditor();
                        }
                      }}
                      onCancelEdit={() => setSelectedEditMode(null)}
                      moveCard={moveCard}
                      findCard={findCard}
                    />
                  )
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateSupersetChild: (args: MutationUpdateSupersetChildArgs) => {
    dispatch(updateSupersetChild(args));
  },
  deleteChildFromSuperset: (
    args: MutationDeleteChildFromSupersetArgs,
    programType?: ProgramType
  ) => {
    dispatch(deleteChildFromSuperset(args, programType));
  },
  openExerciseLibrary: () => {
    dispatch(openExerciseLibraryTrainingPlan());
    dispatch(hideExercisesInPlan());
  },
  reorderChildrenInSuperset: (args: MutationReorderChildrenInSupersetArgs) => {
    dispatch(reorderChildrenInSuperset(args));
  },
  addChildToSuperset: (
    args: MutationAddChildToSupersetArgs,
    programType?: ProgramType
  ) => {
    dispatch(addChildToSuperset(args, programType));
  },
  moveWorkoutChildToSuperset: (
    args: MutationMoveWorkoutChildToSupersetArgs
  ) => {
    dispatch(moveWorkoutChildToSuperset(args));
  },
  closeSupersetEditor: () => {
    dispatch(closeSupersetEditorInPlan());
  },
});

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  showExerciseLibrary: state.trainingProgramsState.showExerciseLibrary,
  programType: state.trainingProgramsState.programType,
  isMasquerade: getIsMasquerade(state),
  isAdmin: getIsAdmin(state),
  superset: selectCurrentSuperset(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupersetExerciseList);
