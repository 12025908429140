import React, { useState } from "react";
import { Link } from "react-router-dom";
import colors from "../../../assets/colors";
import { ClientPackageType, ClientSearchResult } from "../../../types/gqlTypes";
import ProfilePhoto from "../../ProfilePhoto";

interface Props {
  width?: number;
  isLastRow?: boolean;
  row: ClientSearchResult;
  outerBoxStyle?: React.CSSProperties;
  innerBoxStyle?: React.CSSProperties;
}

const UserNameCell = (props: Props) => {
  const { width, isLastRow, row, outerBoxStyle, innerBoxStyle } = props;

  const [hovered, setHovered] = useState(false);
  const { clientPackageType, profileMediaIconUrl, fullName, id } = row || {};

  const borderColor = colors.table_gray;
  const color = colors.black;
  const rowHeight = 64;
  const isNameTooLong = fullName?.length >= 12;
  const fullNameArray = fullName?.split(" ");
  const firstName = fullNameArray?.shift();
  const lastName = fullNameArray?.join(" ");

  let backgroundColor = colors.caliber_white;
  if (hovered) {
    backgroundColor = "#F9F9FA";
  }

  return (
    <td
      style={{
        position: "sticky",
        top: "auto",
        left: 0,
        zIndex: 3,
        width,
        height: rowHeight + (isLastRow ? 0 : 8),
      }}
    >
      <Link to={`/clients/${id}/dashboard`}>
        <div
          className="d-flex"
          style={
            outerBoxStyle || {
              borderBottomLeftRadius: 8,
              borderTopLeftRadius: 8,
              marginBottom: 8,
              borderRight: `1px solid ${borderColor}`,
              backgroundColor: colors.caliber_white,
              height: rowHeight,
              width,
            }
          }
        >
          <div
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            className="d-flex align-items-center bold"
            style={
              innerBoxStyle || {
                fontSize: "16px",
                lineHeight: "18px",
                color,
                border: `1px solid ${colors.caliber_gray_bg}`,
                borderRadius: 4,
                margin: "8px 12px",
                height: rowHeight - 16,
                width: width - 24,
                backgroundColor,

                whiteSpace: "nowrap",
                overflow: "hidden",
              }
            }
          >
            <div
              style={{
                margin: "6px 7px",
              }}
            >
              <ProfilePhoto
                isPremium={clientPackageType === ClientPackageType.Premium}
                key={`clients_tab_user_pic_${profileMediaIconUrl}`}
                imageUrl={profileMediaIconUrl}
                size={36}
              />
            </div>

            {isNameTooLong ? (
              <div className="d-flex flex-column">
                <div>{firstName}</div>
                <div>{lastName}</div>
              </div>
            ) : (
              <div>{fullName}</div>
            )}
          </div>
        </div>
      </Link>
    </td>
  );
};

export default UserNameCell;
