import React from "react";
import Popup from "../Popover";
import MeatballsMenuIcon from "../../assets/images/MeatballsMenuIcon.svg";
import colors from "../../assets/colors";

export interface MeatballItem {
  text: string;
  onClick: () => void;
}
interface Props {
  items: MeatballItem[];
}

const MeatballsMenu = (props: Props) => {
  const { items } = props;

  return (
    <Popup
      popupkey="popupKey"
      placement="bottom"
      trigger={
        <div
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
          }}
          onKeyDown={(event) => {
            event.stopPropagation();
            event.preventDefault();
          }}
          role="button"
          tabIndex={0}
          className="nofocus pointer"
          style={{
            width: 24,
            marginRight: 12,
            marginLeft: 4,
          }}
        >
          <img alt="Menu" src={MeatballsMenuIcon} />
        </div>
      }
    >
      <div className="d-flex flex-column">
        {items.map(({ text, onClick }, index) => {
          return (
            <div
              key={text}
              className="popover-item d-flex align-items-center medium-bold"
              role="button"
              tabIndex={0}
              style={{
                minWidth: 137,
                minHeight: 38,
                padding: 11,
                color: colors.caliber_gray_text,
                fontSize: "14px",
                lineHeight: "16px",
                paddingTop: index === 0 && 14,
                paddingBottom: index === items.length - 1 && 14,
              }}
              onClick={(event) => {
                event.stopPropagation();
                onClick();
              }}
              onKeyDown={(event) => {
                event.stopPropagation();
                if (event.key === "Enter") {
                  onClick();
                }
              }}
            >
              {text}
            </div>
          );
        })}
      </div>
    </Popup>
  );
};
export default MeatballsMenu;
