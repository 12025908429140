import {
  GET_COACH_PROFILE_FAIL,
  GET_COACH_PROFILE_LOADING,
  GET_COACH_PROFILE_SUCCESS,
  SAVE_COACH_PROFILE_FAIL,
  SAVE_COACH_PROFILE_SAVING,
  SAVE_COACH_PROFILE_SUCCESS,
  COACH_PROFILE_IMAGE_UPLOADING,
  COACH_PROFILE_IMAGE_UPLOAD_SUCCESS,
  COACH_PROFILE_IMAGE_UPLOAD_FAIL,
  COACH_PROFILE_IMAGE_UPLOAD_FAIL_SIZE_EXCEEDED,
  CoachProfileAction,
  CoachProfileState,
  CHANGE_COACH_PROFILE_STATE,
  COACH_PROFILE_CROPPED_IMAGE_UPLOAD_SUCCESS,
  CANCEL_UPLOAD_COACH_PROFILE_IMAGE,
  RESET_COACH_PROFILE_STATE,
} from "../../actions/CoachProfile/types";

const initialState: CoachProfileState = {
  isLoading: false,
  isSaving: false,
  isUploading: false,
  coachProfile: {
    bio: "",
    certifications: "",
    education: "",
    funFacts: "",
    id: "",
    photos: [],
  },
};

const coachProfileState = (
  state = initialState,
  action: CoachProfileAction
) => {
  switch (action.type) {
    case CHANGE_COACH_PROFILE_STATE: {
      return {
        ...state,
        coachProfile: action.coachProfile,
      };
    }
    case COACH_PROFILE_IMAGE_UPLOADING:
      return {
        ...state,
        isUploading: true,
      };
    case CANCEL_UPLOAD_COACH_PROFILE_IMAGE: {
      const newPhotos = [...state.coachProfile?.photos];
      newPhotos.pop();
      return {
        ...state,
        isUploading: false,
        coachProfile: {
          ...state.coachProfile,
          photos: [...newPhotos],
        },
      };
    }
    case COACH_PROFILE_CROPPED_IMAGE_UPLOAD_SUCCESS: {
      const newPhotos = [...state.coachProfile?.photos].filter(Boolean);
      newPhotos.pop();
      return {
        ...state,
        isUploading: false,
        coachProfile: {
          ...state.coachProfile,
          photos: [
            ...newPhotos,
            {
              id: action.response?.mediaUrl?.id || "",
              sortOrder: state.coachProfile?.photos?.length || 0,
              mediaUrl: {
                ...(action.response?.mediaUrl || {}),
              },
            },
          ],
        },
      };
    }
    case COACH_PROFILE_IMAGE_UPLOAD_SUCCESS: {
      const newPhotos = state.coachProfile?.photos || [];
      return {
        ...state,
        isUploading: false,
        coachProfile: {
          ...state.coachProfile,
          photos: [
            ...newPhotos.filter(Boolean),
            {
              id: action.response?.mediaUrl?.id || "",
              sortOrder: state.coachProfile?.photos?.length || 0,
              mediaUrl: {
                ...(action.response?.mediaUrl || {}),
              },
            },
          ].slice(0, 6),
        },
      };
    }
    case GET_COACH_PROFILE_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_COACH_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        coachProfile: action.coachProfile,
      };
    case SAVE_COACH_PROFILE_SAVING:
      return {
        ...state,
        isSaving: true,
      };
    case SAVE_COACH_PROFILE_SUCCESS:
      return {
        ...state,
        isSaving: false,
      };
    case RESET_COACH_PROFILE_STATE: {
      return {
        ...initialState,
      };
    }
    case GET_COACH_PROFILE_FAIL: {
      return {
        ...initialState,
      };
    }
    case SAVE_COACH_PROFILE_FAIL:
    case COACH_PROFILE_IMAGE_UPLOAD_FAIL:
    case COACH_PROFILE_IMAGE_UPLOAD_FAIL_SIZE_EXCEEDED:
      return {
        ...state,
        isLoading: false,
        isUploading: false,
        isSaving: false,
      };
    default:
      return state;
  }
};

export default coachProfileState;
