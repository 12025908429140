import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import colors from "../../assets/colors";
import BodyStatsProgress from "../../components/BodyStatsProgress";
import FoodDiaryProgress from "../../components/FoodDiaryProgress";
import NutritionProgress from "../../components/NutritionProgress";
import PhotoProgress from "../../components/PhotoProgress";
import TrainingProgress from "../../components/TrainingProgress";
import { StoreState } from "../../redux/reducers";

enum NavTypes {
  TrainingProgram = "trainingProgram",
  BodyStatProgress = "bodyStatProgress",
  NutritionProgress = "nutritionProgress",
  PhotoProgress = "photoProgress",
  FoodDiary = "foodDiary",
}

interface Props {}

const TrainerClientProgressTab = (props: Props) => {
  const {} = props;

  const [currentNav, setCurrentNav] = useState(NavTypes.TrainingProgram);

  const navs = [
    { title: "Training", value: NavTypes.TrainingProgram },
    { title: "Body Stats", value: NavTypes.BodyStatProgress },
    { title: "Nutrition", value: NavTypes.NutritionProgress },
    { title: "Photo", value: NavTypes.PhotoProgress },
    { title: "Food Diary", value: NavTypes.FoodDiary },
  ];

  const CurrentComponent = useMemo(() => {
    switch (currentNav) {
      case NavTypes.BodyStatProgress:
        return <BodyStatsProgress />;
      case NavTypes.TrainingProgram:
        // @ts-ignore
        return <TrainingProgress />;
      case NavTypes.NutritionProgress:
        return <NutritionProgress />;
      case NavTypes.PhotoProgress:
        return <PhotoProgress />;
      case NavTypes.FoodDiary:
        // @ts-ignore
        return <FoodDiaryProgress />;
      default:
        // @ts-ignore
        return <TrainingProgress />;
    }
  }, [currentNav]);

  return (
    <div
      className="col-16 d-flex flex-column"
      style={{
        flex: 1,
      }}
    >
      <div
        className="d-flex"
        style={{
          marginBottom: 24,
          height: 48,
          backgroundColor: colors.caliber_secondary_gray_5,
        }}
      >
        {navs.map((nav) => {
          const isCurrent = nav.value === currentNav;
          return (
            <div
              key={nav.title}
              role="button"
              tabIndex={0}
              onClick={() => setCurrentNav(nav.value)}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  setCurrentNav(nav.value);
                }
              }}
              className={`d-flex pointer nofocus ${
                isCurrent ? "medium-bold" : ""
              }`}
              style={{
                margin: 8,
                backgroundColor: isCurrent && colors.nav_item_grey,
                color: isCurrent
                  ? colors.caliber_secondary
                  : colors.caliber_gray_text,
                height: 31,
                fontSize: "14px",
                lineHeight: "24px",
                padding: "4px 8px",
                borderRadius: 4,
              }}
            >
              {nav.title}
            </div>
          );
        })}
      </div>
      {CurrentComponent}
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state: StoreState) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrainerClientProgressTab);
