import { format } from "date-fns";
import React from "react";
import colors from "../../assets/colors";
import BackIcon from "../../assets/images/BackIcon.svg";
import SimpleButton from "../SimpleButton";
import CalendarIcons, { CalendarIconType } from "../Icons/CalendarIcons";
import CloseIcon from "../Icons/CloseIcons";

export enum HeaderType {
  STRENGTH_WORKOUT = "STRENGTH_WORKOUT",
  BODY_STAT = "BODY_STAT",
  CARDIO = "CARDIO",
  PROGRESS_PHOTO = "PROGRESS_PHOTO",
  SCHEDULE_EVENT = "SCHEDULE_EVENT",
  HABIT = "HABIT",
  STEPS_HABIT = "STEPS_HABIT",
}
interface Props {
  day: Date;
  type: HeaderType;
  onBackClick: () => void;
}

const Header = (props: Props) => {
  const { day, type, onBackClick } = props;

  const properties = {
    [HeaderType.STRENGTH_WORKOUT]: {
      title: "Strength workout",
      icon: <CalendarIcons type={CalendarIconType.WORKOUT_RECT} />,
    },
    [HeaderType.BODY_STAT]: {
      title: "Body Stat",
      icon: <CalendarIcons type={CalendarIconType.BODY_STAT_RECT} />,
    },
    [HeaderType.CARDIO]: {
      title: "Cardio workout",
      icon: <CalendarIcons type={CalendarIconType.CARDIO_RECT} />,
    },
    [HeaderType.PROGRESS_PHOTO]: {
      title: "Progress photo",
      icon: <CalendarIcons type={CalendarIconType.PROGRESS_PHOTO_RECT} />,
    },
    [HeaderType.HABIT]: {
      title: "Habit",
      icon: <CalendarIcons type={CalendarIconType.HABIT_RECT} />,
    },
    [HeaderType.STEPS_HABIT]: {
      title: "Steps",
      icon: <CalendarIcons type={CalendarIconType.STEPS_HABIT_SMALL} />,
    },
    [HeaderType.SCHEDULE_EVENT]: { title: "Schedule New Event", icon: null },
  };

  const titleStyle: React.CSSProperties = {
    color: colors.caliber_secondary,
    fontSize: "14px",
    lineHeight: "20px",
    height: 20,
  };
  const backBtnStyle: React.CSSProperties = {
    color: "#AEB1BF",
    fontSize: "14px",
    lineHeight: "20px",
    marginLeft: 8,
    height: 20,
  };

  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-between align-items-center">
        <div className="medium-bold" style={titleStyle}>
          {`${format(day, "EEEE")}・${format(day, "MMM dd, yyyy")}`}
        </div>
        <SimpleButton nofocus className="d-flex" onClick={onBackClick}>
          {type === HeaderType.SCHEDULE_EVENT ? (
            <CloseIcon width={20} height={20} />
          ) : (
            <>
              <img
                style={{
                  width: 20,
                  height: 20,
                }}
                src={BackIcon}
              />
              <div
                className="d-flex justify-content-center"
                style={backBtnStyle}
              >
                back
              </div>
            </>
          )}
        </SimpleButton>
      </div>

      <div
        className="d-flex align-items-center medium-bold"
        style={{
          marginTop: 8,
        }}
      >
        {properties?.[type]?.icon}
        <div
          style={{
            color: colors.caliber_secondary,
            fontSize: "20px",
            lineHeight: "24px",
            marginLeft: type === HeaderType.SCHEDULE_EVENT ? 0 : 8,
          }}
        >
          {properties?.[type]?.title}
        </div>
      </div>
    </div>
  );
};

export default Header;
