import { combineReducers } from "@reduxjs/toolkit";
import {
  Client,
  ClientPackageType,
  Trainer,
  TrainerFeature,
  UserType,
} from "../../types/gqlTypes";
import { AdminCreateClientState } from "../actions/AdminClients/types";
import {
  AdminCreateUpdateManagerState,
  AdminSearchManagersState,
} from "../actions/AdminTeamManagers/types";
import {
  AdminCreateUpdateTrainerState,
  AdminSearchTrainersState,
} from "../actions/AdminTeamTrainers/types";
import { AdminExerciseLibraryState } from "../actions/AdminExerciseLibrary/types";
import { AuthState } from "../actions/Auth/types";
import { CalendarDataState } from "../actions/Calendar/types";
import { ClientPlansState } from "../actions/ClientPlans/types";
import { ClientSearchState } from "../actions/ClientSearch/types";
import { ClientsTabState } from "../actions/ClientsTab/types";
import { ClientStrengthDashboardState } from "../actions/ClientStrengthDashboard/types";
import { ClientWorkoutHistoryState } from "../actions/ClientWorkoutHistory/types";
import { CoachProfileState } from "../actions/CoachProfile/types";
import { ComparisonToolState } from "../actions/ComparisonTool/types";
import { ClientNutritionTargetState } from "../actions/ClientNutritionTarget/types";
import { DebugToolsState } from "../actions/DebugTools/types";
import { DeleteUserFromPlatformState } from "../actions/DeleteUserFromPlatform/types";
import { ErrorSuccessDialogState } from "../actions/ErrorSuccessDialog/types";
import { ClientHabitsState } from "../actions/Habits/types";
import { MasqueradeState } from "../actions/Masquerade/types";
import { MediaState } from "../actions/Media/types";
import { MessagesState } from "../actions/Messages/types";
import { ReassignClientTrainerState } from "../actions/ReassignClientTrainer/types";
import { SearchExercisesState } from "../actions/SearchExercises/types";
import { SearchTimezonesState } from "../actions/SearchTimezones/types";
import { TrainingProgramsState } from "../actions/TrainingPrograms/types";
import { UnreadMessageCountState } from "../actions/UnreadMessageCount/types";
import { UpdateNotesState } from "../actions/UpdateNotes/types";
import { UpdateCoachProfileIconState } from "../actions/UpdateCoachProfileIcon/types";
import { messagesState } from "./Messages";
import { UserState } from "../actions/User/types";
import { ClientDetailState } from "../actions/ClientDetail/types";
import { WeeklyContentState } from "../actions/WeeklyContent/types";
import { RulesState } from "../actions/Rules/types";
import { TasksState } from "../actions/Tasks/types";
import adminCreateClientState from "./AdminCreateClient";
import adminCreateUpdateManagerState from "./AdminCreateManager";
import adminCreateUpdateTrainerState from "./AdminCreateTrainer";
import adminExerciseLibraryState from "./AdminExerciseLibrary";
import adminSearchManagersState from "./AdminSearchManagers";
import adminSearchTrainersState from "./AdminSearchTrainers";
import authState from "./Auth";
import calendarDataState from "./Calendar";
import clientPlansState from "./ClientPlans";
import clientSearchState from "./ClientSearch";
import clientsTabState from "./ClientsTab";
import clientStrengthDashboardState from "./ClientStrengthScore";
import clientWorkoutHistoryState from "./ClientWorkoutHistory";
import coachProfileState from "./CoachProfile";
import comparisonToolState from "./ComparisonTool";
import clientNutritionTargetState from "./CreateUpdateClientNutritionTarget";
import debugToolsState from "./DebugTools";
import deleteUserFromPlatformState from "./DeleteUserFromPlatform";
import errorSuccessDialogState from "./ErrorSuccessDialog";
import clientHabitsState from "./Habits";
import masqueradeState from "./Masquerade";
import mediaState from "./Media";
import reassignClientTrainerState from "./ReassignClientTrainer";
import searchExercisesState from "./SearchExercises";
import searchTimezonesState from "./SearchTimezones";
import trainingProgramsState from "./TrainingPrograms";
import unreadMessageCountState from "./UnreadMessageCount";
import updateNotesState from "./UpdateNotes";
import updateCoachProfileIconState from "./UpdateCoachProfileIcon";
import authenticatedUserState from "./AuthenticatedUser";
import clientDetailState from "./ClientDetail";
import weeklyContentState from "./WeeklyContent";
import tasksState from "./Tasks";
import rulesState from "./Rules";
import clientCronometerUserDiaryState from "./ClientCronometerUserDiary";
import proGroupsState from "./ProGroups";
import { ClientCronometerUserDiaryState } from "../actions/ClientCronometerUserDiary/types";
import adminMobileAppBuildNumberState from "./AdminMobileAppBuildNumber";
import { AdminMobileAppBuildNumberState } from "../actions/AdminMobileAppBuildNumber/types";
import { ProGroupsState } from "../actions/ProGroups/types";
import messenger from "../../features/messenger/redux/messengerSlice";
import { RecentEmojisState } from "../actions/RecentEmojis/types";
import { recentEmojis } from "./RecentEmojis";

export interface StoreState {
  adminCreateClientState: AdminCreateClientState;
  adminCreateUpdateManagerState: AdminCreateUpdateManagerState;
  adminCreateUpdateTrainerState: AdminCreateUpdateTrainerState;
  adminExerciseLibraryState: AdminExerciseLibraryState;
  adminMobileAppBuildNumberState: AdminMobileAppBuildNumberState;
  adminSearchManagersState: AdminSearchManagersState;
  adminSearchTrainersState: AdminSearchTrainersState;
  authState: AuthState;
  authenticatedUserState: UserState;
  calendarDataState: CalendarDataState;
  clientCronometerUserDiaryState: ClientCronometerUserDiaryState;
  clientDetailState: ClientDetailState;
  clientHabitsState: ClientHabitsState;
  clientNutritionTargetState: ClientNutritionTargetState;
  clientPlansState: ClientPlansState;
  clientSearchState: ClientSearchState;
  clientStrengthDashboardState: ClientStrengthDashboardState;
  clientWorkoutHistoryState: ClientWorkoutHistoryState;
  clientsTabState: ClientsTabState;
  coachProfileState: CoachProfileState;
  comparisonToolState: ComparisonToolState;
  debugToolsState: DebugToolsState;
  deleteUserFromPlatformState: DeleteUserFromPlatformState;
  errorSuccessDialogState: ErrorSuccessDialogState;
  masqueradeState: MasqueradeState;
  mediaState: MediaState;
  messagesState: MessagesState;
  proGroupsState: ProGroupsState;
  reassignClientTrainerState: ReassignClientTrainerState;
  rulesState: RulesState;
  searchExercisesState: SearchExercisesState;
  searchTimezonesState: SearchTimezonesState;
  tasksState: TasksState;
  trainingProgramsState: TrainingProgramsState;
  unreadMessageCountState: UnreadMessageCountState;
  updateCoachProfileIconState: UpdateCoachProfileIconState;
  updateNotesState: UpdateNotesState;
  weeklyContentState: WeeklyContentState;
  recentEmojis: RecentEmojisState;
}

const reducers = combineReducers({
  messenger,
  adminCreateClientState,
  adminCreateUpdateManagerState,
  adminCreateUpdateTrainerState,
  adminExerciseLibraryState,
  adminMobileAppBuildNumberState,
  adminSearchManagersState,
  adminSearchTrainersState,
  authState,
  authenticatedUserState,
  calendarDataState,
  clientCronometerUserDiaryState,
  clientDetailState,
  clientHabitsState,
  clientNutritionTargetState,
  clientPlansState,
  clientSearchState,
  clientStrengthDashboardState,
  clientWorkoutHistoryState,
  clientsTabState,
  coachProfileState,
  comparisonToolState,
  debugToolsState,
  deleteUserFromPlatformState,
  errorSuccessDialogState,
  masqueradeState,
  mediaState,
  messagesState,
  proGroupsState,
  reassignClientTrainerState,
  rulesState,
  searchExercisesState,
  searchTimezonesState,
  tasksState,
  trainingProgramsState,
  unreadMessageCountState,
  updateCoachProfileIconState,
  updateNotesState,
  weeklyContentState,
  recentEmojis,
});

export default reducers;

export const getIsTrainer = (state: StoreState): boolean => {
  return state?.authenticatedUserState?.user?.type === UserType.Trainer;
};
export const getIsAdmin = (state: StoreState): boolean => {
  return state?.authenticatedUserState?.user?.type === UserType.Admin;
};
export const getIsManager = (state: StoreState): boolean => {
  return state?.authenticatedUserState?.user?.type === UserType.Manager;
};
export const getIsAdminOrManager = (state: StoreState): boolean => {
  return (
    state?.authenticatedUserState?.user?.type === UserType.Admin ||
    state?.authenticatedUserState?.user?.type === UserType.Manager
  );
};
export const getIsMasquerade = (state: StoreState): boolean => {
  return !!state?.masqueradeState?.masqueradeTrainer;
};
export const getTrainerId = (state: StoreState): string => {
  const isMasq = getIsMasquerade(state);
  if (isMasq) {
    return state?.masqueradeState?.masqueradeTrainer?.id;
  }
  return state?.authenticatedUserState?.user?.id;
};
export const getTrainerProGroups = (state: StoreState) => {
  const isMasq = getIsMasquerade(state);
  if (isMasq) {
    return state?.masqueradeState?.masqueradeTrainer?.proGroups;
  }
  // @ts-ignore
  return state?.authenticatedUserState?.user?.proGroups;
};
export const getCanAccessWorkoutLibrary = (state: StoreState) => {
  const isMasq = getIsMasquerade(state);
  if (isMasq) {
    return state?.masqueradeState?.masqueradeTrainer?.enabledFeatures.includes(
      TrainerFeature.WorkoutLibrary
    );
  }

  if (getIsAdmin(state)) {
    return false;
  }

  // @ts-ignore
  return (
    state?.authenticatedUserState?.user as Trainer
  ).enabledFeatures.includes(TrainerFeature.WorkoutLibrary);
};
export const getCanEditClientInfo = (state: StoreState): boolean => {
  return !getIsAdminOrManager(state) || getIsMasquerade(state);
};
export const getTrainer = (state: StoreState) => {
  const isAdminOrManager = getIsAdminOrManager(state);
  const isMasq = getIsMasquerade(state);
  if (isAdminOrManager && isMasq) {
    return state.masqueradeState.masqueradeTrainer;
  }
  if (isAdminOrManager && !isMasq) {
    const client = state.clientDetailState?.user as Client;
    return client?.assignedTrainer;
  }
  return state.authenticatedUserState?.user;
};
export const getIsClientPremium = (state: StoreState) => {
  return (
    state?.clientDetailState?.clientPackageType === ClientPackageType.Premium
  );
};
