import React from "react";

interface Props {}

const EditIcon = (props: Props) => {
  const {} = props;

  return (
    <svg
      style={{ display: "block" }}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.85438 5.99842C9.61775 5.99842 9.42593 6.19024 9.42593 6.42688V10.7114C9.42593 10.948 9.2341 11.1398 8.99746 11.1398H1.28536C1.04872 11.1398 0.856895 10.948 0.856895 10.7114V2.14235C0.856895 1.90571 1.04872 1.71389 1.28536 1.71389H6.42678C6.66341 1.71389 6.85524 1.52207 6.85524 1.28543C6.85524 1.04879 6.66341 0.856995 6.42678 0.856995H1.28536C0.575473 0.856995 0 1.43247 0 2.14235V10.7114C0 11.4213 0.575473 11.9967 1.28536 11.9967H8.99749C9.70737 11.9967 10.2828 11.4213 10.2828 10.7114V6.42685C10.2828 6.19024 10.091 5.99842 9.85438 5.99842Z"
        fill="#82869B"
      />
      <path
        d="M11.5165 0.480581C11.2089 0.172899 10.7916 7.86396e-05 10.3565 0.000128849C9.92121 -0.00112638 9.50352 0.171995 9.19674 0.480857L3.55315 6.12397C3.50633 6.17114 3.47101 6.22848 3.44989 6.29149L2.593 8.8622C2.51821 9.08671 2.63959 9.32933 2.8641 9.40409C2.90766 9.4186 2.95328 9.426 2.99917 9.42605C3.04516 9.42598 3.09087 9.4186 3.13456 9.40421L5.70527 8.54732C5.7684 8.52623 5.82577 8.49073 5.87279 8.44364L11.5164 2.80007C12.1569 2.1596 12.157 1.12112 11.5165 0.480581Z"
        fill="#82869B"
      />
    </svg>
  );
};

export default EditIcon;
