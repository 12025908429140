import React from "react";
import colors from "../../../assets/colors";
import { ClientSearchResult } from "../../../types/gqlTypes";

interface Props {
  isLastColumn?: boolean;
  isDragging?: boolean;
  width?: number;
  row: ClientSearchResult;
}

const WeightCell = (props: Props) => {
  const { row, isLastColumn, isDragging, width } = props;

  const color = colors.black;
  const borderColor = colors.table_gray;
  const rowHeight = 64;

  const { weight } = row || {};
  const isExist = typeof weight === "number";
  let fullText;
  if (!isExist) {
    fullText = "n/a";
  } else {
    fullText = `${weight} lb${weight === 1 ? "" : "s"}`;
  }

  return (
    <td
      className="d-flex"
      style={{
        width,
        backgroundColor: colors.caliber_gray_bg,
        position: "relative",
      }}
    >
      <div
        className="d-flex"
        style={{
          position: "absolute",
          top: isDragging ? -8 : 0,
          backgroundColor: isDragging ? "#E8EAEE" : "transparent",
          width: "100%",
          height: isDragging ? rowHeight + 16 : rowHeight,
          zIndex: isDragging ? 4 : 1,
          marginBottom: isDragging ? 0 : 8,
        }}
      >
        <div
          className="d-flex align-items-center"
          style={{
            flex: "1 1 100%",
            fontSize: "14px",
            lineHeight: "20px",
            color,
            zIndex: isDragging ? 3 : 2,
            backgroundColor: colors.caliber_white,
            height: rowHeight,
            borderRight: `1px solid ${borderColor}`,
            paddingLeft: 18,
            margin: isDragging ? 8 : 0,
            borderTopRightRadius: isLastColumn && 8,
            borderBottomRightRadius: isLastColumn && 8,

            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {fullText}
        </div>
      </div>
    </td>
  );
};

export default WeightCell;
