import _ from "lodash";
import {
  ADMIN_MANAGERS_SEARCH_FAIL,
  ADMIN_MANAGERS_SEARCH_LOADING,
  ADMIN_MANAGERS_SEARCH_SUCCESS,
  ADMIN_MANAGER_UPDATING_SUCCESS,
  UPDATE_MANAGER_PROFILE_ICON,
  AdminSearchManagersAction,
  AdminSearchManagersState,
} from "../../actions/AdminTeamManagers/types";

const initialState: AdminSearchManagersState = {
  isLoading: false,
  foundManagers: [],
  page: 0,
  totalPages: 0,
};

const adminSearchManagersState = (
  state = initialState,
  action: AdminSearchManagersAction
) => {
  switch (action.type) {
    case UPDATE_MANAGER_PROFILE_ICON: {
      const newManagers = [...state.foundManagers];
      const index = newManagers.findIndex(
        (trainer) => trainer.id === action.id
      );
      newManagers[index] = {
        ...newManagers[index],
        profileIconMediaUrl: action.profileIconMediaUrl,
      };
      return {
        ...state,
        foundManagers: newManagers,
      };
    }
    case ADMIN_MANAGER_UPDATING_SUCCESS: {
      const newManagers = [...state.foundManagers];
      const index = newManagers.findIndex(
        (trainer) => trainer.id === action.manager.id
      );
      newManagers[index] = {
        ...newManagers[index],
        ...action.manager,
      };
      return {
        ...state,
        foundManagers: newManagers,
      };
    }
    case ADMIN_MANAGERS_SEARCH_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case ADMIN_MANAGERS_SEARCH_SUCCESS:
      const isFirstPage = action.data.number === 0;
      let mergedData;
      if (!isFirstPage) {
        const collections = [...state.foundManagers, ...action.data.content];
        mergedData = _.uniqBy(collections, (item) => item.id);
      }
      return {
        ...state,
        isLoading: false,
        foundManagers: isFirstPage ? action.data.content : mergedData,
        page: action.data.number,
        totalPages: action.data.totalPages,
      };
    case ADMIN_MANAGERS_SEARCH_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default adminSearchManagersState;
