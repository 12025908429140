import React from "react";
import VideoLessonTrackIcon from "../../../assets/images/VideoLessonTrackIcon.svg";
import { formattedTime } from "../../../utils";

interface Props {
  label: string;
  timeStamp: number;
}

const VideoLessonRow = (props: Props) => {
  const { label, timeStamp } = props;

  return (
    <div
      className="d-flex justify-content-between align-items-center"
      style={{
        height: 44,
        paddingLeft: 24,
        paddingRight: 24,
        marginTop: 8,
        borderRadius: 8,
        backgroundColor: "#e8eaee",
      }}
    >
      <div
        className="d-flex heading-xsmall"
        style={{
          color: "#5B64A0",
        }}
      >
        <img
          style={{
            marginRight: 8,
          }}
          alt="LessonTrack"
          src={VideoLessonTrackIcon}
        />
        {label}
      </div>
      <div
        className="heading-xsmall"
        style={{
          color: "#272E5F",
        }}
      >
        {formattedTime(timeStamp)}
      </div>
    </div>
  );
};

export default VideoLessonRow;
