import { gql } from "apollo-boost";
import {
  exerciseFragment,
  exerciseGymStepFragment,
  workoutCalendarItemFragment,
} from "../calendar";

export const clientWorkoutFragment = gql`
  ${exerciseFragment}
  ${exerciseGymStepFragment}
  ${workoutCalendarItemFragment}
  fragment ClientWorkout on ClientWorkout {
    id
    achievements {
      exercise {
        ...ExerciseProperty
      }
      exerciseAchievements {
        type
        value
      }
    }
    sortOrder
    title
    instructions
    gymSteps {
      ... on ClientGymStep {
        ...ClientGymStep
      }
      ... on ClientSupersetGymStep {
        clientGymSteps {
          ...ClientGymStep
        }
      }
    }
    workoutCalendarItem {
      ...WorkoutCalendarItem
    }
  }
`;
export const CLIENT_WORKOUT_HISTORY = gql`
  ${clientWorkoutFragment}
  query ClientWorkoutHistory(
    $clientId: UUID
    $clientWorkoutTemplateId: UUID
    $maxQueryResults: Int!
  ) {
    clientWorkoutHistory(
      clientId: $clientId
      clientWorkoutTemplateId: $clientWorkoutTemplateId
      maxQueryResults: $maxQueryResults
    ) {
      ...ClientWorkout
    }
  }
`;
export const FETCH_FORM_VIDEOS_BY_DATE_RANGE = gql`
  query FormVideosByDateRange(
    $clientId: UUID!
    $endDate: LocalDate
    $startDate: LocalDate
  ) {
    formVideosByDateRange(
      clientId: $clientId
      endDate: $endDate
      startDate: $startDate
    ) {
      clientGymStep {
        id
      }
      id
      setNumber
      videoMessage {
        id
        videoMessageMediaUrl {
          url
          id
          muxVideoUpload {
            uploadUrl
          }
        }
      }
    }
  }
`;
export const EDIT_EXERCISE_SETS = gql`
  mutation SaveExerciseSets(
    $clientGymStepId: UUID!
    $exerciseSets: [ExerciseSetInput]
    $clientId: UUID
  ) {
    saveExerciseSets(
      clientGymStepId: $clientGymStepId
      exerciseSets: $exerciseSets
      clientId: $clientId
    ) {
      id
    }
  }
`;
