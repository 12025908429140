import apolloClient from "../../../api/apolloClient";
import {
  ADD_CLIENT_GYM_STEP_TEMPLATE,
  ADD_CLIENT_WORKOUT_TEMPLATE,
} from "../../../api/gql/trainingPrograms/clientTrainingPlan";
import {
  ADD_GYM_STEP_TO_CUSTOM_TRAINING_PLAN,
  ADD_GYM_STEP_TO_LIBRARY_TRAINING_PLAN,
  ADD_WORKOUT_TO_CUSTOM_TRAINING_PLAN,
  ADD_WORKOUT_TO_LIBRARY_TRAINING_PLAN,
} from "../../../api/gql/trainingPrograms/customTrainingPlan";
import {
  ADD_MASTER_GYM_STEP,
  ADD_NEW_MASTER_WORKOUT,
} from "../../../api/gql/trainingPrograms/masterTrainingPlan";
import { ProgramType } from "../../../components/TrainingProgram";
import {
  ClientTrainingPlan,
  ClientWorkoutTemplate,
  CustomTrainingPlan,
  LibraryTrainingPlan,
  MasterTrainingPlan,
  MasterWorkout,
} from "../../../types/gqlTypes";
import { getTrainer } from "../../reducers";
import {
  selectCurrentFullPlan,
  selectCurrentWorkout,
  selectFullClientPlan,
  selectFullMasterOrCustomPlan,
} from "../../reducers/TrainingPrograms/selectors";
import { ClientDetailState } from "../ClientDetail/types";

import {
  ADD_GYM_STEP_FAIL,
  ADD_GYM_STEP_LOADING,
  ADD_GYM_STEP_SUCCESS,
  ADD_WORKOUT_TO_TRAINING_PLAN_FAIL,
  ADD_WORKOUT_TO_TRAINING_PLAN_LOADING,
  ADD_WORKOUT_TO_TRAINING_PLAN_SUCCESS,
  TrainingProgramsState,
} from "./types";

export const addGymStepToPlan =
  (args: { exerciseId?: string; index?: number }) =>
  async (dispatch, getState) => {
    if (getState().trainingProgramsState.isSaving) {
      return;
    }
    dispatch({ type: ADD_GYM_STEP_LOADING });
    try {
      const apollo = await apolloClient(
        getState().authState.authToken,
        dispatch
      );

      const trainingProgramsState = getState()
        .trainingProgramsState as TrainingProgramsState;
      const { programType } = trainingProgramsState;
      const workout = selectCurrentWorkout(getState());
      const plan = selectCurrentFullPlan(getState());

      let result: MasterWorkout | ClientWorkoutTemplate;
      if (programType === ProgramType.Custom) {
        result = (
          await apollo.mutate({
            mutation: ADD_GYM_STEP_TO_CUSTOM_TRAINING_PLAN,
            variables: {
              trainerId: getTrainer(getState()).id,
              customTrainingPlanId: plan.id,
              exerciseId: args.exerciseId,
              masterWorkoutId: workout.id,
              position: args.index,
            },
          })
        ).data.addMasterGymStepToCustomTrainingPlan as MasterWorkout;
      } else if (programType === ProgramType.Library) {
        result = (
          await apollo.mutate({
            mutation: ADD_GYM_STEP_TO_LIBRARY_TRAINING_PLAN,
            variables: {
              trainerId: getTrainer(getState()).id,
              libraryTrainingPlanId: plan.id,
              exerciseId: args.exerciseId,
              masterWorkoutId: workout.id,
              position: args.index,
            },
          })
        ).data.addMasterGymStepToLibraryTrainingPlan as MasterWorkout;
      } else if (programType === ProgramType.Master) {
        result = (
          await apollo.mutate({
            mutation: ADD_MASTER_GYM_STEP,
            variables: {
              exerciseId: args.exerciseId,
              masterWorkoutId: workout.id,
              position: args.index,
            },
          })
        ).data.addMasterGymStep as MasterWorkout;
      } else if (programType === ProgramType.Client) {
        const { user: selectedUser }: ClientDetailState =
          getState().clientDetailState;

        result = (
          await apollo.mutate({
            mutation: ADD_CLIENT_GYM_STEP_TEMPLATE,
            variables: {
              clientId: selectedUser.id,
              clientWorkoutTemplateId: workout.id,
              exerciseId: args.exerciseId,
              position: args.index,
            },
          })
        ).data.addClientGymStepTemplate as ClientWorkoutTemplate;
      }

      const exercise = getState().searchExercisesState.exerciseList.find(
        (exercise) => exercise.id === args.exerciseId
      );

      dispatch({
        type: ADD_GYM_STEP_SUCCESS,
        exercise,
        workout: result,
      });
    } catch (error) {
      dispatch({
        type: ADD_GYM_STEP_FAIL,
        data: error,
      });
    }
  };
export const addWorkoutToPlan =
  (selectedWorkoutGroupIndex: number) => async (dispatch, getState) => {
    const trainingProgramsState = getState()
      .trainingProgramsState as TrainingProgramsState;

    if (trainingProgramsState.isSaving) {
      return;
    }
    dispatch({ type: ADD_WORKOUT_TO_TRAINING_PLAN_LOADING });
    try {
      const apollo = await apolloClient(
        getState().authState.authToken,
        dispatch
      );

      const { programType } = trainingProgramsState;
      const plan = selectFullMasterOrCustomPlan(getState());
      const clientPlan = selectFullClientPlan(getState());

      let result: string[];
      if (programType === ProgramType.Custom) {
        result = (
          (
            await apollo.mutate({
              mutation: ADD_WORKOUT_TO_CUSTOM_TRAINING_PLAN,
              variables: {
                trainerId: getTrainer(getState()).id,
                customTrainingPlanId: plan.id,
              },
            })
          ).data.addNewMasterWorkoutToCustomTrainingPlan as CustomTrainingPlan
        ).masterWorkoutGroups[0].masterWorkouts?.map((workout) => workout.id);
      } else if (programType === ProgramType.Library) {
        result = (
          (
            await apollo.mutate({
              mutation: ADD_WORKOUT_TO_LIBRARY_TRAINING_PLAN,
              variables: {
                trainerId: getTrainer(getState()).id,
                libraryTrainingPlanId: plan.id,
              },
            })
          ).data.addNewMasterWorkoutToLibraryTrainingPlan as LibraryTrainingPlan
        ).masterWorkoutGroups[0].masterWorkouts?.map((workout) => workout.id);
      } else if (programType === ProgramType.Master) {
        result = (
          (
            await apollo.mutate({
              mutation: ADD_NEW_MASTER_WORKOUT,
              variables: {
                masterTrainingPlanId: plan.id,
              },
            })
          ).data.addNewMasterWorkout as MasterTrainingPlan
        ).masterWorkoutGroups[0].masterWorkouts?.map((workout) => workout.id);
      } else if (programType === ProgramType.Client) {
        const { user: selectedUser }: ClientDetailState =
          getState().clientDetailState;

        result = (
          (
            await apollo.mutate({
              mutation: ADD_CLIENT_WORKOUT_TEMPLATE,
              variables: {
                clientId: selectedUser.id,
                clientTrainingPlanId: clientPlan.id,
                clientWorkoutGroupTemplateId:
                  clientPlan.clientWorkoutGroupTemplates[
                    selectedWorkoutGroupIndex
                  ].id,
              },
            })
          ).data.addNewClientWorkoutTemplate as ClientTrainingPlan
        ).clientWorkoutGroupTemplates[
          selectedWorkoutGroupIndex
        ].clientWorkoutTemplates?.map((workout) => workout.id);
      }

      dispatch({
        type: ADD_WORKOUT_TO_TRAINING_PLAN_SUCCESS,
        ids: result,
      });
    } catch (error) {
      dispatch({
        type: ADD_WORKOUT_TO_TRAINING_PLAN_FAIL,
        data: error,
      });
    }
  };
