import * as UpChunk from "@mux/upchunk";
import { GroupChannel } from "sendbird";
import * as SockJS from "sockjs-client";
import Stomp from "stompjs";
import apolloClient from "../../../api/apolloClient";
import {
  CREATE_MUX_VIDEO_MESSAGE_UPLOAD,
  CREATE_MUX_VIDEO_UPLOAD,
  DELETE_VIDEO_MESSAGE_URL,
} from "../../../api/gql/media";
import { getAppConfig } from "../../../config";
import {
  FileUploadType,
  MediaUrl,
  MutationCreateMuxVideoUploadArgs,
  VideoProcessingStatusType,
} from "../../../types/gqlTypes";
import { sleep } from "../../../utils";
import {
  VIDEO_STARTS_UPLOADING,
  VIDEO_STARTS_UPLOADING_FAKE,
  VIDEO_UPLOAD_FAIL,
  VIDEO_UPLOAD_PROGRESS,
  VIDEO_UPLOAD_SUCCESS,
} from "../Messages/types";
import {
  MediaAction,
  MEDIA_IS_UPLOADING,
  MEDIA_UPLOAD_FAIL,
  MEDIA_UPLOAD_SUCCESS,
  RESET_MEDIA_STATE,
} from "./types";
import VideoUploadWebsocketInstance from "./VideoUploadWebsocketInstance";

export const resetMediaState = () => async (dispatch, getState) => {
  dispatch({
    type: RESET_MEDIA_STATE,
  } as MediaAction);
};

export const fileUpload =
  (file: any, type: FileUploadType) => async (dispatch, getState) => {
    // Only allow 1 media item to be uploaded at any given time.
    if (getState().mediaState.isUploading) {
      return;
    }
    dispatch({
      type: MEDIA_IS_UPLOADING,
    } as MediaAction);

    try {
      const apiUrl = `${getAppConfig().apiUrl}/upload`;
      const authInfo = getState().authState;

      const formData = new FormData();

      formData.append("file", file);
      formData.append("type", type);

      const uploadResponse = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authInfo.authToken}`,
        },
        body: formData,
      });
      const json = await uploadResponse.json();
      if (json?.message?.includes("Maximum upload size exceeded")) {
        dispatch({
          type: MEDIA_UPLOAD_FAIL,
          data: json,
          message:
            "Maximum upload size exceeded.\nPlease reduce the file size and try again.",
        });
      } else {
        dispatch({
          type: MEDIA_UPLOAD_SUCCESS,
          response: json,
        } as MediaAction);
      }
    } catch (error) {
      console.log("failed to upload", error);
      dispatch({
        type: MEDIA_UPLOAD_FAIL,
        data: error,
      });
    }
  };

export const uploadVideoSocket = async (
  apiUrl: string,
  token: string,
  videoId: string
) => {
  const uploadEndpoint = `${apiUrl}/websocket`;
  const socket = new SockJS(uploadEndpoint);
  const stompClient = Stomp.over(socket);

  return new Promise((resolve, reject) => {
    stompClient.connect(
      {
        Authorization: `Bearer ${token}`,
      },
      () => {
        stompClient.subscribe(`/topic/encoding/${videoId}`, (info) => {
          const data = JSON.parse(info.body);

          if (data.muxVideoUpload.video_processing_status == "ERROR") {
            console.log("Failed to Process Video");
            reject(data);
            stompClient.disconnect();
          }
          if (data.muxVideoUpload.video_processing_status == "READY") {
            resolve(data);
            stompClient.disconnect();
          }
        });
      },
      (err) => {
        reject(err);
      }
    );
  });
};

/**
 * Uploads video file to mux via upchunk in 5mb chunks
 * @param file
 * @param endpoint
 */
const muxUpChunkUpload = (
  file: any,
  endpoint: string,
  channelId: string,
  dispatch: any
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const upload = UpChunk.createUpload({
      endpoint,
      file,
      chunkSize: 5120, // Uploads ~5mb chunks
    });
    upload.on("error", (error) => {
      dispatch(failedVideoUploadProgress(channelId));
      reject(error);
    });
    upload.on("progress", (progress) => {
      dispatch(updateVideoUploadProgress(progress?.detail, channelId));
    });
    upload.on("success", () => {
      dispatch(successVideoUploadProgress(channelId));
      resolve(true);
    });
  });
};

export const updateVideoUploadProgress =
  (progress: number, channelId: string) => async (dispatch, getState) => {
    dispatch({ type: VIDEO_UPLOAD_PROGRESS, progress, channelId });
  };
export const failedVideoUploadProgress =
  (channelId: string) => async (dispatch, getState) => {
    dispatch({ type: VIDEO_UPLOAD_FAIL, channelId });
  };
export const successVideoUploadProgress =
  (channelId: string) => async (dispatch, getState) => {
    dispatch({ type: VIDEO_UPLOAD_SUCCESS, channelId });
  };
export const videoUpload =
  (file: any, type: FileUploadType) => async (dispatch, getState) => {
    // Only allow 1 media item to be uploaded at any given time.
    if (getState().mediaState.isUploading) {
      return;
    }
    dispatch({
      type: MEDIA_IS_UPLOADING,
    } as MediaAction);

    const corsOrigin = `${window.location.protocol}//${window.location.hostname}`;
    const errorMessage =
      "There is a Mux error encoding the video. Please ask an engineer to check the API logs for more details or check the Mux console.";
    const variables: MutationCreateMuxVideoUploadArgs = {
      corsOrigin,
      fileUploadType: type,
    };

    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const { activeChannel } = getState().messagesState;
      const isVideoSmall = file?.size < 5 * 1024 * 1024;
      if (isVideoSmall) {
        // if video is smaller than 5mb do fake progress bar
        dispatch({
          type: VIDEO_STARTS_UPLOADING_FAKE,
          channelId: (activeChannel as GroupChannel)?.url,
        });
      } else {
        dispatch({
          type: VIDEO_STARTS_UPLOADING,
          channelId: (activeChannel as GroupChannel)?.url,
        });
      }
      const media = (
        await client.mutate({
          mutation: CREATE_MUX_VIDEO_UPLOAD,
          variables,
        })
      ).data.createMuxVideoUpload as MediaUrl;

      await muxUpChunkUpload(
        file,
        media.muxVideoUpload.uploadUrl,
        (activeChannel as GroupChannel)?.url,
        dispatch
      );

      VideoUploadWebsocketInstance.subscribe(
        getState().authState.authToken,
        media.id,
        async (body) => {
          const bodyObject = JSON.parse(body);
          if (
            bodyObject?.muxVideoUpload?.video_processing_status ===
            VideoProcessingStatusType.Ready
          ) {
            const updatedMedia = {
              id: bodyObject?.id,
              mimeType: bodyObject?.mimeType,
              type: bodyObject?.type,
              url: bodyObject?.url,
              muxVideoUpload: {
                id: bodyObject?.muxVideoUpload?.id,
              },
            } as MediaUrl;

            const waitForIt = async () => {
              await sleep(550);
            };
            while (
              isVideoSmall &&
              getState().messagesState?.isMediaFakeUploading
            ) {
              waitForIt();
            }
            dispatch({
              type: MEDIA_UPLOAD_SUCCESS,
              mediaUrl: updatedMedia,
            } as MediaAction);
            VideoUploadWebsocketInstance.unsubscribe();
          } else if (
            bodyObject?.muxVideoUpload?.video_processing_status ===
            VideoProcessingStatusType.Error
          ) {
            dispatch({
              type: MEDIA_UPLOAD_FAIL,
              message: errorMessage,
            } as MediaAction);
            VideoUploadWebsocketInstance.unsubscribe();
          }
        }
      );
    } catch (error) {
      console.log("failed to upload", error);
      dispatch({
        type: MEDIA_UPLOAD_FAIL,
        data: error,
        message: errorMessage,
      } as MediaAction);
    }
  };

export const videoMessageUpload = async (
  file,
  clientId: string,
  dispatch,
  getState
) => {
  const client = await apolloClient(getState().authState.authToken, dispatch);
  const corsOrigin = `${window.location.protocol}//${window.location.hostname}`;
  const { activeChannel } = getState().messagesState;
  const isVideoSmall = file?.size < 5 * 1024 * 1024;
  if (isVideoSmall) {
    // if video is smaller than 5mb do fake progress bar
    dispatch({
      type: VIDEO_STARTS_UPLOADING_FAKE,
      channelId: (activeChannel as GroupChannel)?.url,
    });
  } else {
    dispatch({
      type: VIDEO_STARTS_UPLOADING,
      channelId: (activeChannel as GroupChannel)?.url,
    });
  }
  const media = (
    await client.mutate({
      mutation: CREATE_MUX_VIDEO_MESSAGE_UPLOAD,
      variables: {
        corsOrigin,
        clientId,
      },
    })
  ).data.createMuxVideoMessageUpload as MediaUrl;
  await muxUpChunkUpload(
    file,
    media.muxVideoUpload.uploadUrl,
    (activeChannel as GroupChannel)?.url,
    dispatch
  );
  const updatedMedia = (await uploadVideoSocket(
    getAppConfig().apiUrl,
    getState().authState.authToken,
    media.id
  )) as MediaUrl;

  const waitForIt = async () => {
    await sleep(550);
  };
  while (isVideoSmall && getState().messagesState?.isMediaFakeUploading) {
    waitForIt();
  }
  return updatedMedia as MediaUrl;
};

export const deleteVideoMessage =
  (mediaId: string, clientId: string) => async (dispatch, getState) => {
    const client = await apolloClient(getState().authState.authToken, dispatch);
    try {
      await client.mutate({
        mutation: DELETE_VIDEO_MESSAGE_URL,
        variables: {
          clientId,
          mediaUrlId: mediaId,
        },
      });
    } catch (error) {
      console.log("Failed to delete Asset", error);
    }
  };
