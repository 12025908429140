import { ContentWeek } from "../../../types/gqlTypes";

export const WEEKLY_CONTENT_BY_CLIENT_ID_SUCCESS =
  "WEEKLY_CONTENT_BY_CLIENT_ID_SUCCESS";
export const WEEKLY_CONTENT_BY_CLIENT_ID_FAIL =
  "WEEKLY_CONTENT_BY_CLIENT_ID_FAIL";
export const WEEKLY_CONTENT_BY_CLIENT_ID_LOADING =
  "WEEKLY_CONTENT_BY_CLIENT_ID_LOADING";

export const WEEKLY_CONTENT_BY_WEEK_NUMBER_SUCCESS =
  "WEEKLY_CONTENT_BY_WEEK_NUMBER_SUCCESS";
export const WEEKLY_CONTENT_BY_WEEK_NUMBER_FAIL =
  "WEEKLY_CONTENT_BY_WEEK_NUMBER_FAIL";
export const WEEKLY_CONTENT_BY_WEEK_NUMBER_LOADING =
  "WEEKLY_CONTENT_BY_WEEK_NUMBER_LOADING";

export interface WeeklyContentState {
  isLoading: boolean;
  data: ContentWeek;
}

export interface WeeklyContentAction {
  type: string;
  data: ContentWeek;
}
