import React, { useEffect, useRef } from "react";
import colors from "../../../assets/colors";

interface Props {
  value: string;
  onBlur: () => void;
  onChange: (value: string) => void;
}

const SearchInput = (props: Props) => {
  const { value, onBlur, onChange } = props;
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef?.current?.focus) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div id="dropdown_element" onBlur={onBlur} className="d-flex">
      <input
        ref={inputRef}
        autoComplete="off"
        id="dropdown_element"
        onBlur={onBlur}
        className="nofocus"
        placeholder="Search"
        onChange={(event) => onChange(event.target.value)}
        value={value}
        style={{
          flex: 1,
          height: 36,
          paddingLeft: 12,
          margin: "10px 3px",
          border: `1px solid ${colors.caliber_gray_bg}`,
          backgroundColor: colors.caliber_gray_bg,
          borderRadius: 4,
        }}
      />
    </div>
  );
};

export default SearchInput;
