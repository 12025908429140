import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import colors from "../../assets/colors";
import { onErrorSuccessDialogClose } from "../../redux/actions/ErrorSuccessDialog";
import { StoreState } from "../../redux/reducers";
import ButtonTag from "../ButtonTag";
import Ticker from "../Icons/Ticker";
import SimpleButton from "../SimpleButton";

interface Props {
  show: boolean;
  messages: { fold: boolean; text: string }[];
  onClose: () => void;
}

const ErrorSuccessDialog = (props: Props) => {
  const { show, messages, onClose } = props;

  const [whichUnfolded, setWhichUnfolded] = useState({});

  if (!show) {
    return <div />;
  }
  let customMessage = messages;
  if (
    messages?.some((message) => message?.text?.includes("email already exists"))
  ) {
    customMessage = [
      { text: "The email address you entered is already in use.", fold: false },
      { text: "Please choose another address.", fold: false },
    ];
  }

  return (
    <Modal centered show={show} onHide={onClose}>
      <div className="d-flex flex-column">
        <div
          className="d-flex flex-column paragraph-large"
          style={{
            flex: 1,
            padding: 15,
            whiteSpace: "pre-wrap",
            minHeight: 107,
            color: colors.caliber_gray_text,
          }}
        >
          {customMessage?.map(({ text, fold }, index) => {
            return (
              <div className="d-flex">
                {fold && (
                  <SimpleButton
                    nofocus
                    className="d-flex"
                    onClick={() =>
                      setWhichUnfolded({
                        ...whichUnfolded,
                        [index]: !whichUnfolded?.[index],
                      })
                    }
                    style={{
                      margin: "8px 10px 0px 0px",
                    }}
                  >
                    <Ticker width={20} height={20} up={whichUnfolded[index]} />
                  </SimpleButton>
                )}

                <div>{(!fold || whichUnfolded[index]) && text}</div>
              </div>
            );
          })}
        </div>
        <div
          className="d-flex flex-column align-items-center"
          style={{
            height: 62,
          }}
        >
          <ButtonTag
            text="OK"
            onClick={onClose}
            height={32}
            textColor={colors.caliber_white}
            bgColor={colors.caliber_green_200}
            minWidth={91}
          />
        </div>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onClose: () => {
    dispatch(onErrorSuccessDialogClose());
  },
});

const mapStateToProps = (state: StoreState) => ({
  show: state.errorSuccessDialogState.show,
  messages: state.errorSuccessDialogState.messages,
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorSuccessDialog);
