import React, { useState } from "react";
import colors from "../../assets/colors";
import DeleteIcon from "../../assets/images/DeleteCalendarItemIcon.svg";
import ArrowDownIcon from "../../assets/images/DropdownArrowDownIcon.svg";
import ArrowUpIcon from "../../assets/images/DropdownArrowUpIcon.svg";
import ErrorIcon from "../../assets/images/ErrorIcon.svg";
import "./index.css";

interface Props {
  value?: any;
  height?: number;
  width?: number;
  textColor?: string;
  bgColor?: string;
  borderColor?: string;
  placeholder?: string;
  label?: string;
  items: Item[];
  error?: boolean;
  fontSize?: number;
  errorMessage?: string;
  onSelect: (value: any) => void;
  clearable?: boolean;
  customDropdownToggle?: React.ReactNode;
  arrowUpIcon?: any;
  arrowDownIcon?: any;
  customPadding?: string;
  dropup?: boolean;
  disabled?: boolean;
  displayTextWithImage?: boolean;
}
interface Item {
  text: string;
  value: any;
  image?: string;
}

const Dropdown = (props: Props) => {
  const {
    items,
    onSelect,
    clearable,
    value,
    placeholder,
    height,
    width,
    fontSize,
    textColor,
    bgColor,
    borderColor,
    label,
    error,
    errorMessage,
    customDropdownToggle,
    arrowUpIcon,
    arrowDownIcon,
    customPadding,
    dropup,
    disabled,
    displayTextWithImage,
  } = props;

  const [open, setOpen] = useState(false);
  const defaultHeight = 48;
  const itemHeight = 24;
  const itemMargin = 1;
  const maxDropdownHeight = itemHeight * 6;

  const dropdownHeight = items.length * (itemHeight + 2 * itemMargin) + 8;

  const selectedItem = items.find((item) => item.value === value);
  const selectedText = selectedItem ? selectedItem.text : placeholder;
  const [hovered, setHovered] = useState("");

  const onBlur = () => {
    setTimeout(() => {
      if (document.activeElement.id !== `dropdown_element-${value}`) {
        setOpen(false);
      }
    }, 0);
  };

  const dropdownColor = open
    ? bgColor || colors.caliber_gray_11
    : bgColor || colors.caliber_secondary_gray_5;
  const dropdownBorderColor = open
    ? borderColor || colors.caliber_gray_11
    : borderColor || colors.caliber_secondary_gray_5;

  return (
    <div
      style={{
        flex: 1,
        width: width || "100%",
        position: "relative",
      }}
      className="d-flex "
    >
      <div
        id={`dropdown_element-${value}`}
        style={{
          flex: 1,
          overflow: "hidden",
        }}
        onBlur={onBlur}
        className={`d-flex flex-column nofocus ${disabled ? "" : "pointer"}`}
        role="button"
        tabIndex={0}
        onClick={() => {
          if (!disabled) {
            setOpen(!open);
          }
        }}
        onKeyDown={(event) => {
          if (!disabled && event.key === "Enter") {
            setOpen(!open);
          }
        }}
      >
        <div
          className="d-flex heading-xsmall"
          style={{
            flex: 1,
            marginLeft: 5,
          }}
        >
          {label}
        </div>
        {customDropdownToggle && (
          <div
            role="button"
            tabIndex={0}
            id={`dropdown_element-${value}`}
            className="d-flex pointer nofocus"
            onBlur={onBlur}
            onClick={() => setOpen(!open)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                setOpen(!open);
              }
            }}
          >
            {customDropdownToggle}
            <img alt="Delete" src={open ? arrowUpIcon : arrowDownIcon} />
          </div>
        )}
        {!customDropdownToggle && (
          <div
            role="button"
            tabIndex={0}
            id={`dropdown_element-${value}`}
            onBlur={onBlur}
            onClick={() => {
              if (!disabled) {
                setOpen(!open);
              }
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter" && !disabled) {
                setOpen(!open);
              }
            }}
            className={`d-flex flex-column justify-content-center ${
              disabled ? "" : "pointer"
            } medium-bold`}
            style={{
              position: "relative",
              outline: "none",
              padding: customPadding || "0px 35px 0px 20px",
              border: `2px solid ${
                error ? colors.caliber_error_color : dropdownBorderColor
              }`,
              backgroundColor: dropdownColor,
              borderRadius: 8,
              height: height || defaultHeight,
              width: width || "100%",
            }}
          >
            <div
              className="d-flex flex-column justify-content-center"
              style={{
                flex: 1,
                fontSize: fontSize || 16,
                overflow: "hidden",
                height: height || defaultHeight,
                color:
                  selectedText === placeholder
                    ? colors.caliber_placeholder_color
                    : textColor || colors.caliber_gray_47,
              }}
            >
              {selectedItem && selectedItem.image && (
                <div>
                  <img
                    style={{
                      width: 32,
                      height: 32,
                      borderRadius: 8,
                      paddingRight: 10,
                    }}
                    src={selectedItem.image}
                    alt={selectedItem.text}
                  />

                  {displayTextWithImage && selectedText}
                </div>
              )}
              {selectedItem && !selectedItem.image && selectedText}
            </div>
          </div>
        )}
        {error && (
          <div
            className="d-flex paragraph-small align-items-center"
            style={{
              color: colors.caliber_error_color,
            }}
          >
            <img
              style={{
                width: 16,
                height: 16,
                marginRight: 5,
              }}
              alt="Error"
              src={ErrorIcon}
            />
            {errorMessage}
          </div>
        )}
      </div>
      {!customDropdownToggle && (
        <div
          id={`dropdown_element-${value}`}
          role="button"
          tabIndex={0}
          onBlur={onBlur}
          onClick={() => {
            if (clearable && value) {
              onSelect(null);
            } else if (!disabled) {
              setOpen(!open);
            }
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              if (clearable && value) {
                onSelect(null);
              } else if (!disabled) {
                setOpen(!open);
              }
            }
          }}
          className={`d-flex flex-column justify-content-center ${
            disabled ? "" : "pointer"
          }`}
          style={{
            outline: "none",
            position: "absolute",
            left: `calc(${width ? `${width}px` : "100%"} - 19px)`,
            height: height || defaultHeight,
          }}
        >
          {clearable && value ? (
            <img alt="Dropdown" src={DeleteIcon} />
          ) : (
            <img alt="Delete" src={open ? ArrowUpIcon : ArrowDownIcon} />
          )}
        </div>
      )}

      {open && (
        <div
          id={`dropdown_element-${value}`}
          onBlur={onBlur}
          className="d-flex flex-column itemOnHover"
          style={{
            width: "100%",
            padding: 5,
            height: Math.min(dropdownHeight, maxDropdownHeight) + 8,
            zIndex: 9999,
            backgroundColor: colors.caliber_white,
            borderRadius: 8,
            position: "absolute",
            top: !dropup && (height || defaultHeight) + 5,
            bottom: dropup && (height || defaultHeight) + 5,
            boxShadow: "0px 2px 16px #E7E7EB",
          }}
        >
          <div
            className="d-flex flex-column overflow-auto"
            style={{
              maxHeight: maxDropdownHeight,
              position: "relative",
              flex: 1,
              zIndex: 9999,
            }}
          >
            {items.map((item) => {
              const isSelected =
                selectedItem && selectedItem.value === item.value;
              const isHovered = !isSelected && item.value === hovered;
              let backgroundColor = colors.caliber_white;
              if (isSelected) {
                backgroundColor = "#F1F1F4";
              } else if (isHovered) {
                backgroundColor = colors.caliber_gray_2;
              }
              return (
                <div
                  key={`${item.text}-${item.value}`}
                  id={`dropdown_element-${value}`}
                  role="button"
                  tabIndex={0}
                  onBlur={onBlur}
                  onClick={() => {
                    onSelect(item.value);
                    setOpen(false);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      onSelect(item.value);
                      setOpen(false);
                    }
                  }}
                  onMouseEnter={() => setHovered(item.value)}
                  onMouseLeave={() => setHovered("")}
                  className={`nofocus pointer d-flex align-items-center ${
                    isSelected ? "" : "itemOnHover"
                  }`}
                  style={{
                    zIndex: 9999,
                    flex: 1,
                    paddingLeft: 8,
                    fontSize: fontSize || 16,
                    height: itemHeight,
                    margin: itemMargin,
                    borderRadius: 4,
                    backgroundColor,
                    color: colors.caliber_secondary,
                  }}
                >
                  {item.image && (
                    <img
                      style={{
                        width: 24,
                        height: 24,
                        borderRadius: 8,
                        paddingRight: 5,
                      }}
                      src={item.image}
                      alt={item.text}
                    />
                  )}

                  {item.text}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
export default Dropdown;
