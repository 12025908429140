import apolloClient from "../../../api/apolloClient";
import {
  GET_CLIENT_ACTIVE_TRAINING_PLANS,
  GET_CLIENT_TRAINING_PLANS,
} from "../../../api/gql/trainingPrograms/clientTrainingPlan";
import { ClientTrainingPlan } from "../../../types/gqlTypes";
import {
  GetClientPlansAction,
  GET_CLIENT_ACTIVE_PLAN_FAIL,
  GET_CLIENT_ACTIVE_PLAN_LOADING,
  GET_CLIENT_ACTIVE_PLAN_SUCCESS,
  GET_CLIENT_PLANS_LOADING,
  GET_CLIENT_PLANS_LOAD_FAIL,
  GET_CLIENT_PLANS_LOAD_SUCCESS,
  RESET_CLIENT_STATE,
} from "./types";

export const getClientTrainingPlans =
  (clientId: string) => async (dispatch, getState) => {
    if (getState().clientPlansState.isLoading || !clientId) {
      return;
    }
    dispatch({ type: GET_CLIENT_PLANS_LOADING });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const result = (
        await client.query({
          query: GET_CLIENT_TRAINING_PLANS,
          variables: { clientId },
        })
      ).data.clientTrainingPlans as ClientTrainingPlan[];
      dispatch({
        type: GET_CLIENT_PLANS_LOAD_SUCCESS,
        planData: {
          data: result,
        },
      } as GetClientPlansAction);
    } catch (error) {
      dispatch({
        type: GET_CLIENT_PLANS_LOAD_FAIL,
        data: error,
      });
    }
  };
export const getActiveClientTrainingPlan =
  (clientId: string) => async (dispatch, getState) => {
    dispatch({ type: GET_CLIENT_ACTIVE_PLAN_LOADING });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const result = (
        await client.query({
          query: GET_CLIENT_ACTIVE_TRAINING_PLANS,
          variables: { clientId },
        })
      ).data.clientActiveTrainingPlan as ClientTrainingPlan;
      dispatch({
        type: GET_CLIENT_ACTIVE_PLAN_SUCCESS,
        planData: {
          plan: result,
        },
      } as GetClientPlansAction);
    } catch (error) {
      dispatch({
        type: GET_CLIENT_ACTIVE_PLAN_FAIL,
        data: error,
      });
    }
  };
export const resetClientState = () => (dispatch, getState) => {
  if (getState().clientPlansState.isLoading) {
    return;
  }
  dispatch({ type: RESET_CLIENT_STATE });
};
