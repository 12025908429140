import {
  GET_PRO_GROUPS_FAIL,
  GET_PRO_GROUPS_SUCCESS,
  PRO_GROUPS_LOADING,
  ProGroupsActions,
  ProGroupsState,
} from "../../actions/ProGroups/types";

const initialState: ProGroupsState = {
  isLoading: false,
  proGroups: [],
  error: null,
};

const proGroupsState = (state = initialState, action: ProGroupsActions) => {
  switch (action.type) {
    case PRO_GROUPS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_PRO_GROUPS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        proGroups: action.data,
      };
    case GET_PRO_GROUPS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.data,
      };
    default:
      return state;
  }
};

export default proGroupsState;
