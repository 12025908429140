import "rc-slider/assets/index.css";
import Slider from "rc-slider/lib/Slider";
import React, { useEffect, useState } from "react";
import { FormControl } from "react-bootstrap";
import colors from "../../assets/colors";
import {
  ClientGymStepTemplate,
  ClientSupersetGymStepTemplate,
  ExerciseTargetBaseType,
  MasterGymStep,
  MasterSupersetGymStep,
  MutationUpdateClientGymStepTemplateArgs,
  MutationUpdateMasterGymStepArgs,
  MutationUpdateMasterGymStepForCustomTrainingPlanArgs,
  MutationUpdateSupersetArgs,
  MutationUpdateSupersetChildArgs,
} from "../../types/gqlTypes";
import { useKeyPress } from "../../utils/customHooks";
import Dropdown from "../Dropdown";
import Footer from "./Footer";
import TimeSection from "./TimeSection";
import Warmups from "./Warmups";
import WeightField from "./WeightField";

interface Props {
  id?: string;
  gymStep?:
    | MasterGymStep
    | ClientGymStepTemplate
    | MasterSupersetGymStep
    | ClientSupersetGymStepTemplate;
  isSuperset?: boolean;
  isSupersetChild?: boolean;
  onCancelEdit: () => void;
  onSave: (
    args:
      | MutationUpdateClientGymStepTemplateArgs
      | MutationUpdateMasterGymStepArgs
      | MutationUpdateMasterGymStepForCustomTrainingPlanArgs
      | MutationUpdateSupersetArgs
      | MutationUpdateSupersetChildArgs
  ) => void;
}

const EditExercise = (props: Props) => {
  const { onSave, onCancelEdit, gymStep, isSuperset, isSupersetChild } = props;

  const save = () => {
    const obj: MutationUpdateClientGymStepTemplateArgs &
      MutationUpdateMasterGymStepArgs &
      MutationUpdateMasterGymStepForCustomTrainingPlanArgs &
      MutationUpdateSupersetArgs &
      MutationUpdateSupersetChildArgs = {
      target,
      targetRepsLowerBound: lReps,
      targetRepsUpperBound: uReps,
      targetSets: sets,
      supersetTag: superset,
      targetTime,
      hideWeightForExerciseSets: hideWeight,
      generateSmartWarmupSets: warmups,
      restPeriod,
    };
    if (warmups) {
      obj.smartWarmupSetsCount = warmupsCount;
    }
    /* eslint-disable no-underscore-dangle */
    if (gymStep.__typename === "ClientGymStepTemplate") {
      obj.clientGymStepTemplateId = gymStep?.id;
      obj.supersetChildGymStepId = gymStep?.id;
    } else if (gymStep.__typename === "MasterGymStep") {
      obj.masterGymStepId = gymStep?.id;
      obj.supersetChildGymStepId = gymStep?.id;
    } else {
      obj.supersetGymStepId = gymStep?.id;
    }
    /* eslint-enable no-underscore-dangle */
    onSave(obj);
    onCancelEdit();
  };

  useKeyPress("Enter", () => {
    save?.();
  });
  useKeyPress("Escape", () => {
    onCancelEdit?.();
  });

  const generateTarget = (sets, lReps, uReps, time) => {
    const isTime =
      gymStep?.exercise?.targetBase === ExerciseTargetBaseType.Time;
    const setText = Number(sets) === 1 ? " set" : " sets";
    const values = isSupersetChild ? [] : [sets, setText, " x "];
    if (isTime) {
      const min = Math.floor(time / 60);
      const sec = time - min * 60;
      if (min > 0) {
        values.push(`${min}`);
        sec > 0
          ? values.push(`:${sec > 9 ? "" : "0"}${sec} mins`)
          : values.push(`${min === 1 ? " min" : " mins"}`);
      } else {
        sec === 1 ? values.push(`${sec} sec`) : values.push(`${sec} secs`);
      }
    } else {
      values.push(`${lReps}-${uReps} reps`);
    }
    return values.join("");
  };
  const targetLengthLimit = 22;

  const [warmups, setWarmups] = useState(!!gymStep?.generateSmartWarmupSets);
  const [warmupsCount, setWarmupsCount] = useState(
    gymStep?.smartWarmupSetsCount
  );
  const [hideWeight, setHideWeight] = useState(
    !!gymStep?.hideWeightForExerciseSets
  );
  const [sets, setSets] = useState(Number(gymStep?.targetSets) || 0);
  const [superset, setSuperset] = useState(gymStep?.supersetTag || null);
  const [lReps, setLReps] = useState(
    Number(gymStep?.targetRepsLowerBound) || 0
  );
  const [uReps, setUReps] = useState(
    Number(gymStep?.targetRepsUpperBound) || 0
  );
  const [target, setTarget] = useState(gymStep?.target || "");
  const [targetTime, setTargetTime] = useState(gymStep?.targetTime || 0);
  const [isTargetGenerated, setTargetGenerated] = useState(
    generateTarget(
      gymStep?.targetSets,
      gymStep?.targetRepsLowerBound,
      gymStep?.targetRepsUpperBound,
      gymStep?.targetTime
    ) === gymStep?.target
  );
  const [restPeriod, setRestPeriod] = useState(gymStep?.restPeriod || 0);

  useEffect(() => {
    setWarmups(!!gymStep?.generateSmartWarmupSets);
    setWarmupsCount(gymStep?.smartWarmupSetsCount);
    setHideWeight(!!gymStep?.hideWeightForExerciseSets);
    setSets(Number(gymStep?.targetSets) || 0);
    setSuperset(gymStep?.supersetTag || null);
    setLReps(Number(gymStep?.targetRepsLowerBound) || 0);
    setUReps(Number(gymStep?.targetRepsUpperBound) || 0);
    setTarget(gymStep?.target || "");
    setTargetTime(gymStep?.targetTime || 0);
    setTargetGenerated(
      generateTarget(
        gymStep?.targetSets,
        gymStep?.targetRepsLowerBound,
        gymStep?.targetRepsUpperBound,
        gymStep?.targetTime
      ) === gymStep?.target
    );
    setRestPeriod(gymStep?.restPeriod || 0);
  }, [gymStep]);

  const restPeriodsOption = [
    { text: "1 min", value: 1 },
    { text: "2 min", value: 2 },
    { text: "3 min", value: 3 },
    { text: "4 min", value: 4 },
    { text: "5 min", value: 5 },
    { text: "10 min", value: 10 },
  ];

  useEffect(() => {
    if (isTargetGenerated || target.length === 0) {
      setTarget(generateTarget(sets, lReps, uReps, targetTime));
    }
  }, [sets, lReps, uReps, targetTime]);
  useEffect(() => {
    if (target === generateTarget(sets, lReps, uReps, targetTime)) {
      setTargetGenerated(true);
    } else if (isTargetGenerated) {
      setTargetGenerated(false);
    }
  }, [target]);

  interface Slider {
    title: string;
    min: number;
    max: number;
    value: number;
    sliderOnChange: (value: any) => void;
    inputOnChange: (value: any) => void;
    inputOnBlur: (value: any) => void;
  }

  const sliders: Slider[] = [];

  if (isSuperset || !isSupersetChild) {
    sliders.push({
      title: "Sets",
      min: 0,
      max: 10,
      value: sets,
      sliderOnChange: (value) => {
        if (value >= 0 && value <= 10) {
          setSets(Number(value));
        }
      },
      inputOnChange: (value) => {
        if (value >= 0 && value <= 10) {
          setSets(Number(value));
        }
      },
      inputOnBlur: () => {},
    });
  }

  if (
    !isSuperset &&
    gymStep?.exercise?.targetBase === ExerciseTargetBaseType.Reps
  ) {
    sliders.push(
      {
        title: "Lower Reps",
        min: 0,
        max: 30,
        value: lReps,
        sliderOnChange: (value) => {
          if (value >= 0 && value <= 30) {
            if (value > uReps) {
              setUReps(Number(value));
            }
            setLReps(Number(value));
          }
        },
        inputOnChange: (value) => {
          if (value >= 0 && value <= 30) {
            setLReps(Number(value));
          }
        },
        inputOnBlur: (event) => {
          if (lReps > uReps) {
            setUReps(lReps);
          }
        },
      },
      {
        title: "Upper Reps",
        min: 0,
        max: 30,
        value: uReps,
        sliderOnChange: (value) => {
          if (value >= 0 && value <= 30) {
            setUReps(Number(value));
            if (value < lReps) {
              setLReps(Number(value));
            }
          }
        },
        inputOnChange: (value) => {
          if (value >= 0 && value <= 30) {
            setUReps(Number(value));
          }
        },
        inputOnBlur: (event) => {
          if (lReps > uReps) {
            setLReps(uReps);
          }
        },
      }
    );
  }

  return (
    <div
      className="d-flex flex-nowrap nofocus"
      style={{
        borderRadius: 8,
        backgroundColor: colors.caliber_white,
        marginTop: 4,
        marginBottom: 8,
        padding: 16,
      }}
    >
      <div
        className="d-flex flex-column"
        style={{
          flex: "1 1 0",
        }}
      >
        {sliders.map((slider) => {
          return (
            <div
              key={slider.title}
              className="d-flex align-items-center"
              style={{
                backgroundColor: colors.caliber_light_blue_20,
                height: 50,
                margin: "10px 0px",
                borderRadius: 4,
              }}
            >
              <div
                className="d-flex heading-xsmall"
                style={{
                  color: colors.caliber_light_blue,
                  flex: "1 1 0px",
                  width: 50,
                  marginLeft: 10,
                }}
              >
                {slider.title}
              </div>
              <div
                style={{
                  flexGrow: 2,
                  flexShrink: 2,
                  marginTop: "-8px",
                  marginLeft: 12,
                  marginRight: 12,
                }}
              >
                <Slider
                  railStyle={{
                    height: 4,
                    marginTop: 4,
                    backgroundColor: colors.caliber_gray_text,
                  }}
                  trackStyle={{
                    height: 4,
                    marginTop: 4,
                    backgroundColor: colors.caliber_gray_text,
                  }}
                  handleStyle={{
                    width: 24,
                    height: 24,
                    color: colors.caliber_white,
                    backgroundColor: colors.caliber_white,
                    borderColor: colors.caliber_white,
                    boxShadow: "0px 2px 16px rgba(128, 130, 151, 0.2)",
                  }}
                  id="edit_exercise"
                  step={1}
                  min={slider.min}
                  max={slider.max}
                  value={slider.value}
                  onChange={slider.sliderOnChange}
                />
              </div>
              <input
                value={slider.value.toString()}
                onChange={(event) => slider.inputOnChange(event.target.value)}
                onBlur={slider.inputOnBlur}
                className="d-flex justify-content-center align-items-center"
                style={{
                  textAlign: "center",
                  backgroundColor: colors.caliber_white,
                  borderRadius: 4,
                  borderColor: colors.caliber_white,
                  boxShadow: "none",
                  border: "none",
                  borderWidth: 0,
                  width: 40,
                  height: 36,
                  margin: 7,
                  fontSize: "25px",
                  fontWeight: "bolder",
                }}
              />
            </div>
          );
        })}

        {!isSuperset &&
          gymStep?.exercise?.targetBase === ExerciseTargetBaseType.Time && (
            <TimeSection
              onChange={(time: number) => setTargetTime(time)}
              gymStep={gymStep}
            />
          )}

        {!isSuperset && (
          <div
            className="d-flex"
            style={{
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <div
              className="flex-grow-0 bold"
              style={{
                marginRight: 10,
                minWidth: 70,
                fontSize: "16px",
                lineHeight: "32px",
                letterSpacing: "-0.02em",
              }}
            >
              Target
            </div>
            <div className="flex-grow-1">
              <FormControl
                className="flex-grow-1 medium-bold"
                value={target}
                onChange={(event) => {
                  const target = event.target as HTMLTextAreaElement;
                  if (target.value.length <= targetLengthLimit) {
                    setTarget(target.value);
                  }
                }}
                onBlur={(event) => {
                  const target = event.target as HTMLTextAreaElement;
                  if (target.value.length === 0) {
                    setTarget(generateTarget(sets, lReps, uReps, targetTime));
                    setTargetGenerated(true);
                  }
                }}
                style={{
                  color: colors.caliber_gray_text,
                  backgroundColor: colors.caliber_light_gray,
                  border: "none",
                  height: 32,
                  padding: 5,
                  paddingLeft: 20,
                  resize: "none",
                  outline: "none",
                  lineHeight: "32px",
                }}
              />
            </div>
          </div>
        )}
        {!isSupersetChild && (
          <div
            className="d-flex"
            style={{
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <div
              className="flex-grow-0 bold"
              style={{
                marginRight: 10,
                minWidth: 70,
                fontSize: "16px",
                lineHeight: "32px",
                letterSpacing: "-0.02em",
              }}
            >
              Rest
            </div>
            <div
              className="flex-grow-1"
              style={{
                minWidth: 138,
              }}
            >
              <Dropdown
                height={36}
                value={restPeriod}
                items={restPeriodsOption}
                onSelect={(value) => setRestPeriod(value)}
                clearable
                placeholder="None"
                textColor={colors.caliber_gray_text}
                bgColor={colors.caliber_light_gray}
                borderColor={colors.caliber_light_gray}
              />
            </div>
          </div>
        )}

        {!isSuperset && (
          <WeightField
            gymStep={gymStep}
            hideWeight={hideWeight}
            setHideWeight={setHideWeight}
          />
        )}

        {!isSuperset && !isSupersetChild && (
          <Warmups
            gymStep={gymStep}
            hideWeight={hideWeight}
            warmups={warmups}
            warmupsCount={warmupsCount}
            setWarmups={setWarmups}
            setWarmupsCount={setWarmupsCount}
          />
        )}

        <Footer
          onSave={save}
          onCancel={onCancelEdit}
          saveLabel={isSuperset ? "Save Superset" : "Save Exercise"}
        />
      </div>
    </div>
  );
};

export default EditExercise;
