import { format } from "date-fns";
import Hls from "hls.js";
import React, { useEffect, useRef, useState } from "react";
import { Image } from "react-bootstrap";
import { FileMessage, UserMessage } from "sendbird";
import colors from "../../assets/colors";
import { MediaUrl } from "../../types/gqlTypes";
import { getMuxThumbnailUrl } from "../../utils";
import PlayIcon from "../Icons/PlayIcon";
import MessageShortcutMenu from "../MessageShortcutMenu";
import Modal from "../Modal";
import SimpleButton from "../SimpleButton";
import MessageEmoji from "./MessageEmoji";

interface Props {
  onDelete: () => void;
  addReaction: (message: UserMessage | FileMessage, emojiKey: string) => void;
  setEmojiMenuOpen: (isClosed: boolean) => void;
  message: UserMessage | FileMessage;
  showProfilePic?: boolean;
  isMasquerading?: boolean;
  isDeletable?: boolean;
  emojiMenuOpen?: boolean;
}

const VideoMessageItem = (props: Props) => {
  const {
    onDelete,
    addReaction,
    setEmojiMenuOpen,
    message,
    showProfilePic,
    isMasquerading,
    isDeletable,
    emojiMenuOpen,
  } = props;
  const videoLoopRef = useRef(null);

  let messageData;
  try {
    const jsonText = message.isUserMessage() && (message as UserMessage)?.data;
    messageData = JSON.parse(jsonText) || {};
  } catch (err) {
    console.log("Parsing JSON error!", err);
  }
  const isFormVideo = message.customType == "form_video";
  const [hovered, setHovered] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const whatTimeIsIt = (message: UserMessage | FileMessage) => {
    const createdDate = new Date(message.createdAt);
    return format(createdDate, "h:mm aa").toLowerCase();
  };
  const onMouseEnter = () => {
    setHovered(true);
  };
  const onMouseLeave = () => {
    if (!emojiMenuOpen) {
      setHovered(false);
    }
  };

  let videoUrl;
  if (isFormVideo) {
    videoUrl = messageData?.formVideo?.url;
  } else {
    videoUrl = (messageData as MediaUrl)?.url;
  }

  useEffect(() => {
    if (showModal && videoUrl) {
      const hls = new Hls();
      hls.loadSource(videoUrl);
      hls.attachMedia(videoLoopRef.current);
    }
  }, [showModal, videoUrl]);

  const badgeStyle: React.CSSProperties = {
    height: 22,
    borderRadius: 4,
    fontSize: "12px",
    lineHeight: "14px",
    marginTop: 10,
    padding: "4px 8px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    marginRight: 10,
  };
  const workoutBadge: React.CSSProperties = {
    ...badgeStyle,
    backgroundColor: "#5B64A0",
    color: colors.caliber_white,
  };
  const exerciseBadge: React.CSSProperties = {
    ...badgeStyle,
    backgroundColor: "#808297",
    color: colors.caliber_white,
  };
  const setBadge: React.CSSProperties = {
    ...badgeStyle,
    backgroundColor: "#FFF",
    color: colors.caliber_secondary,
  };

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className="d-flex flex-column"
      style={{
        marginRight: 88,
        position: "relative",
        marginBottom: message?.reactions?.[0] ? 35 : 8,
      }}
    >
      {showModal && (
        <Modal onClose={() => setShowModal(false)} closeOnDimmerClick>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video
            style={{
              borderRadius: 8,
            }}
            controls
            ref={videoLoopRef}
            width={330}
          />
        </Modal>
      )}
      {!showProfilePic && hovered && (
        <div
          className="medium-bold"
          style={{
            color: colors.caliber_secondary_gray_47,
            fontSize: "12px",
            lineHeight: "12px",
            position: "absolute",
            top: 18,
            right: -54,
          }}
        >
          {whatTimeIsIt(message)}
        </div>
      )}
      <div
        className="d-flex flex-column"
        style={{
          padding: isFormVideo && 24,
          backgroundColor: isFormVideo && "#D9DCE2",
          borderRadius: isFormVideo && 8,
          maxHeight: window.innerHeight * 0.9,
          position: "relative",
        }}
      >
        <div
          style={{
            position: "relative",
            width: 250,
          }}
        >
          <Image
            className="pointer"
            onClick={() => setShowModal(true)}
            src={getMuxThumbnailUrl(videoUrl)}
            style={{
              width: 250,
              borderRadius: 8,
            }}
          />
          <SimpleButton
            style={{
              position: "absolute",
              top: "calc(50% - 30px)",
              left: "calc(50% - 30px)",
            }}
            onClick={() => setShowModal(true)}
          >
            <PlayIcon />
          </SimpleButton>
        </div>

        {isFormVideo && (
          <div className="d-flex flex-wrap align-items-center medium-bold">
            <div
              id={`${message?.messageId}-form_video_badge_workout`}
              style={workoutBadge}
            >
              {messageData?.formVideo?.workoutTitle}
            </div>
            <div
              id={`${message?.messageId}-form_video_badge_exercise`}
              style={exerciseBadge}
            >
              {messageData?.formVideo?.exerciseName}
            </div>
            {messageData?.formVideo?.setNumber && (
              <div
                id={`${message?.messageId}-form_video_badge_set`}
                style={setBadge}
              >
                {`Set ${messageData?.formVideo?.setNumber}`}
              </div>
            )}
          </div>
        )}
        {message?.reactions?.length > 0 && (
          <MessageEmoji
            reactions={message.reactions}
            reactionTapped={(emojiKey) => {
              if (!isMasquerading) {
                addReaction(message, emojiKey);
              }
            }}
          />
        )}
      </div>

      {hovered && !isMasquerading && (
        <MessageShortcutMenu
          key="message_shortcut_menu_video"
          toggleMenu={setEmojiMenuOpen}
          onClose={() => setHovered(false)}
          isDeletable={isDeletable}
          selectedReactions={message?.reactions}
          onDelete={onDelete}
          addReaction={(emojiKey: string) => {
            addReaction(message, emojiKey);
            setHovered(false);
          }}
        />
      )}
    </div>
  );
};

export default VideoMessageItem;
