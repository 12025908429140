import {
  initialListsState,
  selectGymSteps,
  selectTrainingPlans,
  selectWorkouts,
} from ".";
import { ProgramType } from "../../../components/TrainingProgram";
import {
  ClientTrainingPlan,
  ClientWorkoutTemplate,
  MasterWorkout,
} from "../../../types/gqlTypes";
import {
  DELETE_CLIENT_WORKOUT_GROUP_FAIL,
  DELETE_CLIENT_WORKOUT_GROUP_LOADING,
  DELETE_CLIENT_WORKOUT_GROUP_SUCCESS,
  DELETE_GYM_STEP_FAIL,
  DELETE_GYM_STEP_LOADING,
  DELETE_GYM_STEP_SUCCESS,
  DELETE_TRAINING_PLAN_FAIL,
  DELETE_TRAINING_PLAN_LOADING,
  DELETE_TRAINING_PLAN_SUCCESS,
  DELETE_WORKOUT_FAIL,
  DELETE_WORKOUT_LOADING,
  DELETE_WORKOUT_SUCCESS,
  TrainingProgramsAction,
  TrainingProgramsState,
} from "../../actions/TrainingPrograms/types";
import { replaceGymSteps, replaceWorkouts } from "./helperFunctions";

const deleteReducer = (
  state: TrainingProgramsState,
  action: TrainingProgramsAction
) => {
  switch (action.type) {
    case DELETE_TRAINING_PLAN_SUCCESS: {
      if (
        action.programType === ProgramType.Client &&
        state.clientPlans.length === 1
      ) {
        return {
          ...state,
          ...initialListsState,
          clientPlans: [],
          showPlans: true,
          isSaving: false,
          isDeleting: false,
          isLoading: false,
          planIndex: null,
          programType: ProgramType.Master,
        };
      }
      if (action.programType === ProgramType.Client) {
        return {
          ...state,
          ...initialListsState,
          isSaving: false,
          isDeleting: false,
          isLoading: false,
          clientPlanIndex: 0,
          workoutIndex: null,
          showWorkouts: true,
          clientPlans: state.clientPlans.filter(
            (plan) => plan.id !== action.id
          ),
        };
      }
      if (action.programType === ProgramType.Custom) {
        return {
          ...state,
          isDeleting: false,
          customPlansContent: {
            ...state.customPlansContent,
            content: state.customPlansContent.content.filter(
              (plan) => plan.id !== action.id
            ),
          },
        };
      }
      if (action.programType === ProgramType.Library) {
        return {
          ...state,
          isDeleting: false,
          libraryPlansContent: {
            ...state.libraryPlansContent,
            content: state.libraryPlansContent.content.filter(
              (plan) => plan.id !== action.id
            ),
          },
        };
      }
      if (action.programType === ProgramType.Master) {
        return {
          ...state,
          isDeleting: false,
          masterPlansContent: {
            ...state.masterPlansContent,
            content: state.masterPlansContent.content.filter(
              (plan) => plan.id !== action.id
            ),
          },
        };
      }
      return {
        ...state,
        isSaving: false,
        isDeleting: false,
        isLoading: false,
        plans: selectTrainingPlans(state).filter(
          (plan) => plan.id !== action.id
        ),
        planIndex: Math.max(0, state.planIndex - 1),
      };
    }
    case DELETE_TRAINING_PLAN_FAIL:
      return {
        ...state,
        isDeleting: false,
        message: (action as any).data.message,
      };
    case DELETE_TRAINING_PLAN_LOADING:
      return {
        ...state,
        isDeleting: true,
      };
    case DELETE_WORKOUT_LOADING:
      return {
        ...state,
        isDeleting: true,
      };
    case DELETE_CLIENT_WORKOUT_GROUP_LOADING:
      return {
        ...state,
        isDeleting: true,
      };
    case DELETE_CLIENT_WORKOUT_GROUP_FAIL:
      return {
        ...state,
        isDeleting: false,
        message: (action as any).data.message,
      };
    case DELETE_CLIENT_WORKOUT_GROUP_SUCCESS: {
      const planId = state.clientPlans[state.clientPlanIndex].id;
      const plan = state.cachedPlans[planId] as ClientTrainingPlan;
      plan.clientWorkoutGroupTemplates =
        plan.clientWorkoutGroupTemplates.filter((group) => {
          return group.id !== action.id;
        });
      return {
        ...state,
        cachedPlans: {
          ...state.cachedPlans,
          [planId]: plan,
        },
        workoutGroupIndex: 0,
        showExercises: false,
        isDeleting: false,
        isEditWorkout: false,
      };
    }
    case DELETE_WORKOUT_SUCCESS: {
      let deletedWorkoutIndex;
      const newWorkouts: (MasterWorkout | ClientWorkoutTemplate)[] =
        selectWorkouts(state).filter(({ id }, index) => {
          if (id !== action.id) {
            deletedWorkoutIndex = index;
            return true;
          }
          return false;
        });
      let workoutIndex;
      if (newWorkouts.length === 0) {
        workoutIndex = null;
      } else {
        workoutIndex = Math.max(0, deletedWorkoutIndex - 1);
      }
      return {
        ...state,
        ...replaceWorkouts({ newWorkouts, state }),
        workoutIndex,
        showExercises: false,
        isDeleting: false,
        isEditWorkout: false,
      };
    }
    case DELETE_WORKOUT_FAIL:
      return {
        ...state,
        isDeleting: false,
        message: (action as any).data.message,
      };
    case DELETE_GYM_STEP_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_GYM_STEP_FAIL:
      return {
        ...state,
        isLoading: false,
        message: (action as any).data.message,
      };
    case DELETE_GYM_STEP_SUCCESS:
      return replaceGymSteps({
        newGymSteps: selectGymSteps(state).filter(({ id }) => id !== action.id),
        state,
      });
    default:
      return state;
  }
};

export default deleteReducer;
