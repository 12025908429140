import React from "react";
import { connect } from "react-redux";
import SendBird from "sendbird";
import { User } from "../../types/gqlTypes";
import SimpleButton from "../SimpleButton";
import { getTrainer, StoreState } from "../../redux/reducers";

interface Props {
  reactions: SendBird.Reaction[];
  trainer: User;
  reactionTapped: (emojiKey: string) => void;
}

const MessageEmoji = (props: Props) => {
  const { reactions, trainer, reactionTapped } = props;

  const deprecatedReactions = [
    "arm",
    "handsup",
    "ok",
    "shades",
    "smiley",
    "thumbsup",
  ];

  const sortedReactions = reactions.sort((a, b) => {
    return a.key.localeCompare(b.key);
  });

  const emojiKeyForReaction = (reaction: SendBird.Reaction) => {
    if (deprecatedReactions.includes(reaction.key)) {
      switch (reaction.key) {
        case "arm":
          return "💪";
        case "handsup":
          return "🙌";
        case "ok":
          return "👌";
        case "shades":
          return "😎";
        case "smiley":
          return "🙂";
        case "thumbsup":
          return "👍";
      }
    }
    return reaction.key;
  };

  return (
    <div
      className="d-flex"
      style={{
        left: 20,
        marginTop: 16,
        flexWrap: "wrap",
      }}
    >
      {sortedReactions.map((reaction) => {
        const isOwnReaction = reaction.userIds.includes(trainer.id);
        return (
          <SimpleButton
            id="emoji"
            nofocus
            onClick={() => {
              reactionTapped(reaction.key);
            }}
            style={{
              marginBottom: 8,
            }}
          >
            <div
              className="d-flex medium"
              style={{
                height: 34,
                marginRight: 12,
                borderRadius: 80,
                backgroundColor: isOwnReaction ? "#DAE7F6" : "#FFF",
                border: isOwnReaction ? 1 : 0,
                borderStyle: isOwnReaction ? "solid" : "none",
                borderColor: "#007AFF",
                paddingRight: 16,
                paddingLeft: 16,
                justifyContent: "center",
                alignItems: "center",
                fontSize: 16,
              }}
            >
              {emojiKeyForReaction(reaction)}
              <div
                style={{
                  marginLeft: 6,
                  color: isOwnReaction ? "#007AFF" : "#333333",
                }}
              >
                {reaction.userIds.length}
              </div>
            </div>
          </SimpleButton>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  trainer: getTrainer(state),
});

export default connect(mapStateToProps)(MessageEmoji);
