import React from "react";
import { GroupChannel, Member } from "sendbird";
import colors from "../../assets/colors";
import Loader from "../Loader";
import ProfilePhoto from "../ProfilePhoto";
import SimpleButton from "../SimpleButton";

interface Props {
  lastMessageSnippet: string;
  clientChannel: GroupChannel;
  client: Member;
  isPremium: boolean;
  isLoading: boolean;
  onOpen: () => void;
}

const CollapsedChatWindow = (props: Props) => {
  const {
    lastMessageSnippet,
    clientChannel,
    client,
    isPremium,
    isLoading,
    onOpen,
  } = props;

  return (
    <SimpleButton
      nofocus
      className="d-flex"
      style={{
        position: "fixed",
        zIndex: 5,
        backgroundColor: colors.caliber_white,
        borderRadius: "6px 6px 0px 0px",
        boxShadow: "0px 2px 16px #808297",
        padding: 12,
        height: 74,
        width: 296,
        bottom: 0,
        right: 32,
      }}
      onClick={onOpen}
    >
      {!isLoading || client?.nickname ? (
        <div
          style={{
            width: 48,
            height: 48,
            marginLeft: 2,
            marginRight: 16,
          }}
        >
          <ProfilePhoto
            isPremium={isPremium}
            imageUrl={client?.profileUrl}
            size={48}
          />
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-center">
          <Loader />
        </div>
      )}
      <div className="d-flex flex-column">
        <div
          className="bold"
          style={{
            fontSize: "14px",
            lineHeight: "24px",
            color: colors.caliber_secondary,
            paddingTop: 5,
          }}
        >
          {client?.nickname}
        </div>
        <div
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            width: 200,
            maxWidth: 200,
            whiteSpace: "nowrap",
            fontSize: "12px",
            lineHeight: "12px",
            color: colors.caliber_gray_text,
          }}
        >
          {lastMessageSnippet}
        </div>
      </div>
      {clientChannel?.unreadMessageCount > 0 && (
        <div
          className="paragraph-small"
          style={{
            position: "absolute",
            right: 12,
            top: 12,
            height: 24,
            width: 24,
            paddingTop: 1,
            alignSelf: "center",
            borderRadius: 12,
            backgroundColor: colors.caliber_red,
            color: colors.caliber_white,
            textAlign: "center",
          }}
        >
          {clientChannel?.unreadMessageCount}
        </div>
      )}
    </SimpleButton>
  );
};

export default CollapsedChatWindow;
