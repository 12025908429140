export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  UUID: any,
  LocalDate: any,
  OffsetDateTime: any,
  Long: any,
  Map_UUID_IntegerScalar: any,
  Map_ConsultationQuestionCategoryType_List_QuestionAnswerScalar: any,
  Map_String_StringScalar: any,
  Date: any,
  LocalDateTime: any,
  Period: any,
  UNREPRESENTABLE: any,
};

export type Admin = User & {
   __typename?: 'Admin',
  age?: Maybe<Scalars['Int']>,
  birthDate?: Maybe<Scalars['LocalDate']>,
  city?: Maybe<Scalars['String']>,
  clientAuthId?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  firstName?: Maybe<Scalars['String']>,
  gender?: Maybe<GenderType>,
  height?: Maybe<Scalars['Float']>,
  id?: Maybe<Scalars['UUID']>,
  isConsultationCompleted?: Maybe<Scalars['Boolean']>,
  isProfileCompleted?: Maybe<Scalars['Boolean']>,
  joinedDate?: Maybe<Scalars['OffsetDateTime']>,
  lastName?: Maybe<Scalars['String']>,
  notes?: Maybe<Scalars['String']>,
  parent?: Maybe<HierarchicalDomainModelEntity>,
  phone?: Maybe<Scalars['String']>,
  profileIconMediaUrl?: Maybe<MediaUrl>,
  profileIconUrl?: Maybe<Scalars['String']>,
  sendbirdAccessToken?: Maybe<Scalars['String']>,
  settings?: Maybe<Settings>,
  state?: Maybe<Scalars['String']>,
  status?: Maybe<UserStatusType>,
  timezone?: Maybe<TimeZoneType>,
  type?: Maybe<UserType>,
  userEnabled: Scalars['Boolean'],
};

export type AdminSettings = Settings & {
   __typename?: 'AdminSettings',
  clientsTabColumnPreferences?: Maybe<Scalars['String']>,
  enableGeneralNotifications?: Maybe<Scalars['Boolean']>,
  enableMessageNotifications?: Maybe<Scalars['Boolean']>,
  hasNewNotification?: Maybe<Scalars['Boolean']>,
  id?: Maybe<Scalars['UUID']>,
  unitBodystat?: Maybe<UnitType>,
  unitDistance?: Maybe<UnitType>,
  unitWeight?: Maybe<UnitType>,
};

export type AdminSettingsInput = {
  clientsTabColumnPreferences?: Maybe<Scalars['String']>,
  enableGeneralNotifications?: Maybe<Scalars['Boolean']>,
  enableMessageNotifications?: Maybe<Scalars['Boolean']>,
  hasNewNotification?: Maybe<Scalars['Boolean']>,
  id?: Maybe<Scalars['UUID']>,
  unitBodystat?: Maybe<UnitType>,
  unitDistance?: Maybe<UnitType>,
  unitWeight?: Maybe<UnitType>,
  user?: Maybe<UserInput>,
};

export type AppRequirements = {
   __typename?: 'AppRequirements',
  authenticator?: Maybe<AuthenticatorType>,
  deviceType?: Maybe<DeviceType>,
  id?: Maybe<Scalars['UUID']>,
  minimumAppBuildNumber: Scalars['Int'],
};

export enum AuthenticatorType {
  Auth0 = 'AUTH0',
  Keycloak = 'KEYCLOAK'
}

export type BodyMeasurementEvent = {
   __typename?: 'BodyMeasurementEvent',
  bodyMeasurementType?: Maybe<BodyMeasurementType>,
  date?: Maybe<Scalars['LocalDate']>,
  healthKitSyncSet?: Maybe<HealthKitSyncSet>,
  id?: Maybe<Scalars['UUID']>,
  importableEventType?: Maybe<ImportableEventType>,
  value?: Maybe<Scalars['Float']>,
  workoutSyncSourceType?: Maybe<WorkoutSyncSourceType>,
};

export type BodyMeasurementEventInput = {
  bodyMeasurementType?: Maybe<BodyMeasurementType>,
  date?: Maybe<Scalars['LocalDate']>,
  healthKitSyncSet?: Maybe<HealthKitSyncSetInput>,
  id?: Maybe<Scalars['UUID']>,
  value?: Maybe<Scalars['Float']>,
  workoutSyncSourceType?: Maybe<WorkoutSyncSourceType>,
};

export enum BodyMeasurementType {
  BodyFatPercent = 'BODY_FAT_PERCENT',
  BodyWeight = 'BODY_WEIGHT',
  WaistSize = 'WAIST_SIZE'
}

export type BodyStatCalendarItem = CalendarItem & {
   __typename?: 'BodyStatCalendarItem',
  bodyMeasurementType?: Maybe<BodyMeasurementType>,
  bodyMeasurementValue?: Maybe<Scalars['Float']>,
  client?: Maybe<Client>,
  date?: Maybe<Scalars['LocalDate']>,
  daySortOrder: Scalars['Int'],
  enumType?: Maybe<CalendarItemType>,
  id?: Maybe<Scalars['UUID']>,
  parent?: Maybe<HierarchicalDomainModelEntity>,
  recurring: Scalars['Boolean'],
  recurringEventDefinition?: Maybe<RecurringEventDefinition>,
  scheduledBy?: Maybe<CalendarItemScheduledByType>,
  sort: Scalars['Int'],
  status?: Maybe<CalendarItemStatusType>,
  title?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
};

export type BodyStatRecurringEventDefinition = {
   __typename?: 'BodyStatRecurringEventDefinition',
  RRule?: Maybe<Scalars['String']>,
  bodyMeasurementType?: Maybe<BodyMeasurementType>,
  client?: Maybe<Client>,
  endDate?: Maybe<Scalars['LocalDate']>,
  id?: Maybe<Scalars['UUID']>,
  recurringEventExceptions?: Maybe<Array<Maybe<RecurringEventException>>>,
  startDate?: Maybe<Scalars['LocalDate']>,
  type?: Maybe<RecurringEventType>,
};

export type CalendarDay = {
   __typename?: 'CalendarDay',
  date?: Maybe<Scalars['LocalDate']>,
  id?: Maybe<Scalars['UUID']>,
  items?: Maybe<Array<Maybe<CalendarItem>>>,
};

export type CalendarItem = {
  client?: Maybe<Client>,
  date?: Maybe<Scalars['LocalDate']>,
  daySortOrder: Scalars['Int'],
  enumType?: Maybe<CalendarItemType>,
  id?: Maybe<Scalars['UUID']>,
  recurring: Scalars['Boolean'],
  recurringEventDefinition?: Maybe<RecurringEventDefinition>,
  scheduledBy?: Maybe<CalendarItemScheduledByType>,
  sort: Scalars['Int'],
  status?: Maybe<CalendarItemStatusType>,
  title?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
};

export enum CalendarItemScheduledByType {
  ScheduledByClient = 'SCHEDULED_BY_CLIENT',
  ScheduledByTrainer = 'SCHEDULED_BY_TRAINER'
}

export enum CalendarItemStatusType {
  Completed = 'COMPLETED',
  Incomplete = 'INCOMPLETE',
  Scheduled = 'SCHEDULED'
}

export enum CalendarItemType {
  BodyStat = 'BODY_STAT',
  CardioActivity = 'CARDIO_ACTIVITY',
  CustomActivity = 'CUSTOM_ACTIVITY',
  ImportedBodyStat = 'IMPORTED_BODY_STAT',
  ImportedCardioActivity = 'IMPORTED_CARDIO_ACTIVITY',
  ImportedNutrition = 'IMPORTED_NUTRITION',
  ImportedSleep = 'IMPORTED_SLEEP',
  ImportedSteps = 'IMPORTED_STEPS',
  ImportedWater = 'IMPORTED_WATER',
  ProgressPic = 'PROGRESS_PIC',
  Sleep = 'SLEEP',
  Steps = 'STEPS',
  Water = 'WATER',
  Workout = 'WORKOUT'
}

export enum CaliberError {
  AccessDenied = 'ACCESS_DENIED',
  Api = 'API',
  Auth0Exception = 'AUTH0_EXCEPTION',
  Auth0UserAlreadyExists = 'AUTH0_USER_ALREADY_EXISTS',
  BranchException = 'BRANCH_EXCEPTION',
  CalendarItemNotFound = 'CALENDAR_ITEM_NOT_FOUND',
  CannotSubstituteGymStep = 'CANNOT_SUBSTITUTE_GYM_STEP',
  ChatRoomNotFound = 'CHAT_ROOM_NOT_FOUND',
  ClientProfileNotFound = 'CLIENT_PROFILE_NOT_FOUND',
  CloseCrmException = 'CLOSE_CRM_EXCEPTION',
  CoachProfileNotFound = 'COACH_PROFILE_NOT_FOUND',
  CoachTaskNotFound = 'COACH_TASK_NOT_FOUND',
  CollectionSizeMismatch = 'COLLECTION_SIZE_MISMATCH',
  ConsultationResultAlreadyExists = 'CONSULTATION_RESULT_ALREADY_EXISTS',
  ConsultationResultNotFound = 'CONSULTATION_RESULT_NOT_FOUND',
  ContentNotFound = 'CONTENT_NOT_FOUND',
  CronometerException = 'CRONOMETER_EXCEPTION',
  CustomTrainingPlanExists = 'CUSTOM_TRAINING_PLAN_EXISTS',
  CustomTrainingPlanNotFound = 'CUSTOM_TRAINING_PLAN_NOT_FOUND',
  DataDeletion = 'DATA_DELETION',
  ExerciseIsActive = 'EXERCISE_IS_ACTIVE',
  ExerciseNotFound = 'EXERCISE_NOT_FOUND',
  ExerciseSetEmpty = 'EXERCISE_SET_EMPTY',
  FileUploadMaxSizeExceeded = 'FILE_UPLOAD_MAX_SIZE_EXCEEDED',
  FileUploadTypeInvalid = 'FILE_UPLOAD_TYPE_INVALID',
  FormVideoException = 'FORM_VIDEO_EXCEPTION',
  FormVideoNotFound = 'FORM_VIDEO_NOT_FOUND',
  GoalNotFound = 'GOAL_NOT_FOUND',
  GymStepNotFound = 'GYM_STEP_NOT_FOUND',
  HabitTargetNotFound = 'HABIT_TARGET_NOT_FOUND',
  HealthkitRawDataNotFound = 'HEALTHKIT_RAW_DATA_NOT_FOUND',
  IncorrectUserType = 'INCORRECT_USER_TYPE',
  JwtEmailMissing = 'JWT_EMAIL_MISSING',
  KeycloakException = 'KEYCLOAK_EXCEPTION',
  KeycloakUserAlreadyExists = 'KEYCLOAK_USER_ALREADY_EXISTS',
  LibraryTrainingPlanCategoryNotFound = 'LIBRARY_TRAINING_PLAN_CATEGORY_NOT_FOUND',
  MaxCalendarItemsForType = 'MAX_CALENDAR_ITEMS_FOR_TYPE',
  MaxGoalPerType = 'MAX_GOAL_PER_TYPE',
  MediaUrlNotFound = 'MEDIA_URL_NOT_FOUND',
  MembersWordpressUserAlreadyExists = 'MEMBERS_WORDPRESS_USER_ALREADY_EXISTS',
  MembersWordpressUserNotFound = 'MEMBERS_WORDPRESS_USER_NOT_FOUND',
  MissingStripeData = 'MISSING_STRIPE_DATA',
  MuxException = 'MUX_EXCEPTION',
  ProGroupIncompatibleUserType = 'PRO_GROUP_INCOMPATIBLE_USER_TYPE',
  ProGroupNotFound = 'PRO_GROUP_NOT_FOUND',
  RecurrenceException = 'RECURRENCE_EXCEPTION',
  RecurringEventDefinitionMismatch = 'RECURRING_EVENT_DEFINITION_MISMATCH',
  RecurringEventDefinitionNotFound = 'RECURRING_EVENT_DEFINITION_NOT_FOUND',
  RequiresPlusPackageType = 'REQUIRES_PLUS_PACKAGE_TYPE',
  RevenuecatEntitlementException = 'REVENUECAT_ENTITLEMENT_EXCEPTION',
  RevenuecatException = 'REVENUECAT_EXCEPTION',
  SalesPackageConfigurationError = 'SALES_PACKAGE_CONFIGURATION_ERROR',
  SegmentException = 'SEGMENT_EXCEPTION',
  SendbirdException = 'SENDBIRD_EXCEPTION',
  SettingsInvalid = 'SETTINGS_INVALID',
  SettingsNotExists = 'SETTINGS_NOT_EXISTS',
  SmartWarmupConfigInvalid = 'SMART_WARMUP_CONFIG_INVALID',
  SmartWarmupNotFound = 'SMART_WARMUP_NOT_FOUND',
  SmartWarmupSetNotFound = 'SMART_WARMUP_SET_NOT_FOUND',
  StrengthScoreNotFound = 'STRENGTH_SCORE_NOT_FOUND',
  StrengthScoreValidationFailed = 'STRENGTH_SCORE_VALIDATION_FAILED',
  SupersetNotFound = 'SUPERSET_NOT_FOUND',
  SwitchUserError = 'SWITCH_USER_ERROR',
  TimeZoneNotFound = 'TIME_ZONE_NOT_FOUND',
  TrainingPlanInvalid = 'TRAINING_PLAN_INVALID',
  TrainingPlanNotFound = 'TRAINING_PLAN_NOT_FOUND',
  UserNotificationNotFound = 'USER_NOTIFICATION_NOT_FOUND',
  UserNotFound = 'USER_NOT_FOUND',
  UserNotFoundInProGroup = 'USER_NOT_FOUND_IN_PRO_GROUP',
  VideoLessonChaptersInvalid = 'VIDEO_LESSON_CHAPTERS_INVALID',
  VideoMessageNotFound = 'VIDEO_MESSAGE_NOT_FOUND',
  WordpressException = 'WORDPRESS_EXCEPTION',
  WorkoutGroupNotFound = 'WORKOUT_GROUP_NOT_FOUND',
  WorkoutNotFound = 'WORKOUT_NOT_FOUND',
  WorkoutTemplateNotFound = 'WORKOUT_TEMPLATE_NOT_FOUND',
  ZoomCallNotFound = 'ZOOM_CALL_NOT_FOUND'
}

export type CaliberErrorObject = {
   __typename?: 'CaliberErrorObject',
  code: Scalars['Int'],
  defaultMessage?: Maybe<Scalars['String']>,
  name?: Maybe<CaliberError>,
};

export type CaliberExercise = {
   __typename?: 'CaliberExercise',
  equipment?: Maybe<ExerciseEquipmentType>,
  exerciseImportId?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['UUID']>,
  includeBodyWeightInCalculations?: Maybe<Scalars['Boolean']>,
  instructions?: Maybe<Scalars['String']>,
  isCustomExercise: Scalars['Boolean'],
  isStrengthScoreKeyExercise?: Maybe<Scalars['Boolean']>,
  level?: Maybe<ExerciseLevelType>,
  media?: Maybe<ExerciseMedia>,
  name?: Maybe<Scalars['String']>,
  primaryMuscleGroups?: Maybe<Array<Maybe<MuscleGroupType>>>,
  secondaryMuscleGroups?: Maybe<Array<Maybe<MuscleGroupType>>>,
  takeaways?: Maybe<Array<Maybe<Scalars['String']>>>,
  targetBase?: Maybe<ExerciseTargetBaseType>,
  type?: Maybe<ExerciseType>,
};

export type CaliberExerciseInput = {
  equipment?: Maybe<ExerciseEquipmentType>,
  exerciseImportId?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['UUID']>,
  includeBodyWeightInCalculations?: Maybe<Scalars['Boolean']>,
  instructions?: Maybe<Scalars['String']>,
  isStrengthScoreKeyExercise?: Maybe<Scalars['Boolean']>,
  level?: Maybe<ExerciseLevelType>,
  media?: Maybe<ExerciseMediaInput>,
  name?: Maybe<Scalars['String']>,
  primaryMuscleGroups?: Maybe<Array<Maybe<MuscleGroupType>>>,
  secondaryMuscleGroups?: Maybe<Array<Maybe<MuscleGroupType>>>,
  takeaways?: Maybe<Array<Maybe<Scalars['String']>>>,
  targetBase?: Maybe<ExerciseTargetBaseType>,
  type?: Maybe<ExerciseType>,
};

export type CaliberPageImpl_Admin = {
   __typename?: 'CaliberPageImpl_Admin',
  content?: Maybe<Array<Maybe<Admin>>>,
  last: Scalars['Boolean'],
  number: Scalars['Int'],
  numberOfElements: Scalars['Int'],
  totalElements: Scalars['Long'],
  totalPages: Scalars['Int'],
};

export type CaliberPageImpl_Client = {
   __typename?: 'CaliberPageImpl_Client',
  content?: Maybe<Array<Maybe<Client>>>,
  last: Scalars['Boolean'],
  number: Scalars['Int'],
  numberOfElements: Scalars['Int'],
  totalElements: Scalars['Long'],
  totalPages: Scalars['Int'],
};

export type CaliberPageImpl_ClientCustomExercise = {
   __typename?: 'CaliberPageImpl_ClientCustomExercise',
  content?: Maybe<Array<Maybe<ClientCustomExercise>>>,
  last: Scalars['Boolean'],
  number: Scalars['Int'],
  numberOfElements: Scalars['Int'],
  totalElements: Scalars['Long'],
  totalPages: Scalars['Int'],
};

export type CaliberPageImpl_ClientSearchResult = {
   __typename?: 'CaliberPageImpl_ClientSearchResult',
  content?: Maybe<Array<Maybe<ClientSearchResult>>>,
  last: Scalars['Boolean'],
  number: Scalars['Int'],
  numberOfElements: Scalars['Int'],
  totalElements: Scalars['Long'],
  totalPages: Scalars['Int'],
};

export type CaliberPageImpl_CoachSearchResult = {
   __typename?: 'CaliberPageImpl_CoachSearchResult',
  content?: Maybe<Array<Maybe<CoachSearchResult>>>,
  last: Scalars['Boolean'],
  number: Scalars['Int'],
  numberOfElements: Scalars['Int'],
  totalElements: Scalars['Long'],
  totalPages: Scalars['Int'],
};

export type CaliberPageImpl_CustomTrainingPlan = {
   __typename?: 'CaliberPageImpl_CustomTrainingPlan',
  content?: Maybe<Array<Maybe<CustomTrainingPlan>>>,
  last: Scalars['Boolean'],
  number: Scalars['Int'],
  numberOfElements: Scalars['Int'],
  totalElements: Scalars['Long'],
  totalPages: Scalars['Int'],
};

export type CaliberPageImpl_Exercise = {
   __typename?: 'CaliberPageImpl_Exercise',
  content?: Maybe<Array<Maybe<Exercise>>>,
  last: Scalars['Boolean'],
  number: Scalars['Int'],
  numberOfElements: Scalars['Int'],
  totalElements: Scalars['Long'],
  totalPages: Scalars['Int'],
};

export type CaliberPageImpl_LibraryTrainingPlan = {
   __typename?: 'CaliberPageImpl_LibraryTrainingPlan',
  content?: Maybe<Array<Maybe<LibraryTrainingPlan>>>,
  last: Scalars['Boolean'],
  number: Scalars['Int'],
  numberOfElements: Scalars['Int'],
  totalElements: Scalars['Long'],
  totalPages: Scalars['Int'],
};

export type CaliberPageImpl_Manager = {
   __typename?: 'CaliberPageImpl_Manager',
  content?: Maybe<Array<Maybe<Manager>>>,
  last: Scalars['Boolean'],
  number: Scalars['Int'],
  numberOfElements: Scalars['Int'],
  totalElements: Scalars['Long'],
  totalPages: Scalars['Int'],
};

export type CaliberPageImpl_MasterTrainingPlan = {
   __typename?: 'CaliberPageImpl_MasterTrainingPlan',
  content?: Maybe<Array<Maybe<MasterTrainingPlan>>>,
  last: Scalars['Boolean'],
  number: Scalars['Int'],
  numberOfElements: Scalars['Int'],
  totalElements: Scalars['Long'],
  totalPages: Scalars['Int'],
};

export type CaliberPageImpl_ProgressPhotoCalendarItem = {
   __typename?: 'CaliberPageImpl_ProgressPhotoCalendarItem',
  content?: Maybe<Array<Maybe<ProgressPhotoCalendarItem>>>,
  last: Scalars['Boolean'],
  number: Scalars['Int'],
  numberOfElements: Scalars['Int'],
  totalElements: Scalars['Long'],
  totalPages: Scalars['Int'],
};

export type CaliberPageImpl_TimeZoneType = {
   __typename?: 'CaliberPageImpl_TimeZoneType',
  content?: Maybe<Array<Maybe<TimeZoneType>>>,
  last: Scalars['Boolean'],
  number: Scalars['Int'],
  numberOfElements: Scalars['Int'],
  totalElements: Scalars['Long'],
  totalPages: Scalars['Int'],
};

export type CaliberPageImpl_Trainer = {
   __typename?: 'CaliberPageImpl_Trainer',
  content?: Maybe<Array<Maybe<Trainer>>>,
  last: Scalars['Boolean'],
  number: Scalars['Int'],
  numberOfElements: Scalars['Int'],
  totalElements: Scalars['Long'],
  totalPages: Scalars['Int'],
};

export type CaliberPageImpl_UserNotification = {
   __typename?: 'CaliberPageImpl_UserNotification',
  content?: Maybe<Array<Maybe<UserNotification>>>,
  last: Scalars['Boolean'],
  number: Scalars['Int'],
  numberOfElements: Scalars['Int'],
  totalElements: Scalars['Long'],
  totalPages: Scalars['Int'],
};

export type CaliberRule = {
  condition?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['UUID']>,
  lowerLimitParam: Scalars['Int'],
  name?: Maybe<Scalars['String']>,
  objectClassSimpleName?: Maybe<Scalars['String']>,
  objectCrudOperation?: Maybe<CrudOperationType>,
  paramOperator?: Maybe<ComparisonOperatorType>,
  paramUnit?: Maybe<ChronoUnit>,
  ruleAutocompletable: Scalars['Boolean'],
  type?: Maybe<RuleType>,
  upperLimitParam: Scalars['Int'],
};

export type CardioActivityCalendarItem = CalendarItem & {
   __typename?: 'CardioActivityCalendarItem',
  calories?: Maybe<Scalars['Int']>,
  cardioActivityType?: Maybe<CardioActivityType>,
  client?: Maybe<Client>,
  date?: Maybe<Scalars['LocalDate']>,
  daySortOrder: Scalars['Int'],
  distance?: Maybe<Scalars['Float']>,
  enumType?: Maybe<CalendarItemType>,
  id?: Maybe<Scalars['UUID']>,
  level?: Maybe<Scalars['Int']>,
  notes?: Maybe<Scalars['String']>,
  parent?: Maybe<HierarchicalDomainModelEntity>,
  recurring: Scalars['Boolean'],
  recurringEventDefinition?: Maybe<RecurringEventDefinition>,
  scheduledBy?: Maybe<CalendarItemScheduledByType>,
  sort: Scalars['Int'],
  speed?: Maybe<Scalars['Float']>,
  status?: Maybe<CalendarItemStatusType>,
  time?: Maybe<Scalars['Int']>,
  title?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
};

export type CardioActivityEvent = {
   __typename?: 'CardioActivityEvent',
  averageHeartRate?: Maybe<Scalars['Int']>,
  averagePace?: Maybe<Scalars['Float']>,
  calories?: Maybe<Scalars['Int']>,
  cardioActivity?: Maybe<CardioActivityType>,
  date?: Maybe<Scalars['LocalDate']>,
  distance?: Maybe<Scalars['Float']>,
  elevationGain?: Maybe<Scalars['Float']>,
  healthKitSyncSet?: Maybe<HealthKitSyncSet>,
  id?: Maybe<Scalars['UUID']>,
  importableEventType?: Maybe<ImportableEventType>,
  importedCardioActivityCalendarItem?: Maybe<ImportedCardioActivityCalendarItem>,
  time?: Maybe<Scalars['Int']>,
  workoutSyncSourceType?: Maybe<WorkoutSyncSourceType>,
};

export type CardioActivityEventInput = {
  averageHeartRate?: Maybe<Scalars['Int']>,
  averagePace?: Maybe<Scalars['Float']>,
  calories?: Maybe<Scalars['Int']>,
  cardioActivity?: Maybe<CardioActivityType>,
  date?: Maybe<Scalars['LocalDate']>,
  distance?: Maybe<Scalars['Float']>,
  elevationGain?: Maybe<Scalars['Float']>,
  healthKitSyncSet?: Maybe<HealthKitSyncSetInput>,
  id?: Maybe<Scalars['UUID']>,
  importedCardioActivityCalendarItem?: Maybe<ImportedCardioActivityCalendarItemInput>,
  time?: Maybe<Scalars['Int']>,
  workoutSyncSourceType?: Maybe<WorkoutSyncSourceType>,
};

export type CardioActivityRecurringEventDefinition = {
   __typename?: 'CardioActivityRecurringEventDefinition',
  RRule?: Maybe<Scalars['String']>,
  cardioActivityType?: Maybe<CardioActivityType>,
  client?: Maybe<Client>,
  endDate?: Maybe<Scalars['LocalDate']>,
  id?: Maybe<Scalars['UUID']>,
  recurringEventExceptions?: Maybe<Array<Maybe<RecurringEventException>>>,
  startDate?: Maybe<Scalars['LocalDate']>,
  type?: Maybe<RecurringEventType>,
};

export enum CardioActivityType {
  Basketball = 'BASKETBALL',
  Boxing = 'BOXING',
  Climbing = 'CLIMBING',
  Cycling = 'CYCLING',
  Dance = 'DANCE',
  Elliptical = 'ELLIPTICAL',
  General = 'GENERAL',
  Hiit = 'HIIT',
  Hiking = 'HIKING',
  IndoorCycle = 'INDOOR_CYCLE',
  IndoorRun = 'INDOOR_RUN',
  IndoorWalk = 'INDOOR_WALK',
  JumpRope = 'JUMP_ROPE',
  Kickboxing = 'KICKBOXING',
  MartialArts = 'MARTIAL_ARTS',
  OpenWaterSwim = 'OPEN_WATER_SWIM',
  OutdoorCycle = 'OUTDOOR_CYCLE',
  OutdoorRun = 'OUTDOOR_RUN',
  OutdoorWalk = 'OUTDOOR_WALK',
  Pickleball = 'PICKLEBALL',
  Pilates = 'PILATES',
  PoolSwim = 'POOL_SWIM',
  Rower = 'ROWER',
  Running = 'RUNNING',
  Soccer = 'SOCCER',
  Stairclimber = 'STAIRCLIMBER',
  StairStepper = 'STAIR_STEPPER',
  Swimming = 'SWIMMING',
  Tennis = 'TENNIS',
  Walking = 'WALKING',
  Yoga = 'YOGA'
}

export type ChatNotification = UserNotification & {
   __typename?: 'ChatNotification',
  actionText?: Maybe<Scalars['String']>,
  groupChannelUrl?: Maybe<Scalars['String']>,
  groupName?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['UUID']>,
  lastReactorReaction?: Maybe<Scalars['String']>,
  lastReactorsText?: Maybe<Scalars['String']>,
  lastUpdatedDate?: Maybe<Scalars['OffsetDateTime']>,
  messageId: Scalars['Long'],
  messageText?: Maybe<Scalars['String']>,
  user?: Maybe<User>,
  userNotificationReactors?: Maybe<Array<Maybe<UserNotificationReactor>>>,
  userNotificationStatusType?: Maybe<UserNotificationStatusType>,
  userNotificationType?: Maybe<UserNotificationType>,
};

export type ChatRoom = {
   __typename?: 'ChatRoom',
  client?: Maybe<Client>,
  formVideo?: Maybe<FormVideo>,
  id?: Maybe<Scalars['UUID']>,
  lastDateClientReceivedMessage?: Maybe<Scalars['OffsetDateTime']>,
  lastDateClientSentMessage?: Maybe<Scalars['OffsetDateTime']>,
  lastDateTrainerReceivedMessage?: Maybe<Scalars['OffsetDateTime']>,
  lastDateTrainerSentMessage?: Maybe<Scalars['OffsetDateTime']>,
  lastMessageSentFrom?: Maybe<UserType>,
  lastMessageSentType?: Maybe<SendBirdMessageType>,
  sendbirdChannelUrl?: Maybe<Scalars['String']>,
  sendbirdMessageId?: Maybe<Scalars['Long']>,
  trainer?: Maybe<Trainer>,
};

export type ChatRoomInput = {
  client?: Maybe<ClientInput>,
  formVideo?: Maybe<FormVideoInput>,
  id?: Maybe<Scalars['UUID']>,
  lastDateTrainerReceivedMessage?: Maybe<Scalars['OffsetDateTime']>,
  lastDateTrainerSentMessage?: Maybe<Scalars['OffsetDateTime']>,
  lastMessageSentFrom?: Maybe<UserType>,
  lastMessageSentType?: Maybe<SendBirdMessageType>,
  sendbirdChannelUrl?: Maybe<Scalars['String']>,
  sendbirdMessageId?: Maybe<Scalars['Long']>,
  trainer?: Maybe<TrainerInput>,
};

export enum ChronoUnit {
  Centuries = 'CENTURIES',
  Days = 'DAYS',
  Decades = 'DECADES',
  Eras = 'ERAS',
  Forever = 'FOREVER',
  HalfDays = 'HALF_DAYS',
  Hours = 'HOURS',
  Micros = 'MICROS',
  Millennia = 'MILLENNIA',
  Millis = 'MILLIS',
  Minutes = 'MINUTES',
  Months = 'MONTHS',
  Nanos = 'NANOS',
  Seconds = 'SECONDS',
  Weeks = 'WEEKS',
  Years = 'YEARS'
}

export type Client = User & {
   __typename?: 'Client',
  activeTrainingPlan?: Maybe<ClientTrainingPlan>,
  age?: Maybe<Scalars['Int']>,
  assignedSalesRep?: Maybe<SalesRep>,
  assignedTrainer?: Maybe<Trainer>,
  birthDate?: Maybe<Scalars['LocalDate']>,
  city?: Maybe<Scalars['String']>,
  client?: Maybe<Client>,
  clientAuthId?: Maybe<Scalars['String']>,
  clientPackageType?: Maybe<ClientPackageType>,
  clientSalesPackage?: Maybe<ClientSalesPackage>,
  clientSalesPackages?: Maybe<Array<Maybe<ClientSalesPackage>>>,
  clientTrainingPlans?: Maybe<Array<Maybe<ClientTrainingPlan>>>,
  closeLeadId?: Maybe<Scalars['String']>,
  contentWeekNumber: Scalars['Int'],
  country?: Maybe<Scalars['String']>,
  cronometerToken?: Maybe<CronometerToken>,
  deviceType?: Maybe<DeviceType>,
  email?: Maybe<Scalars['String']>,
  firstName?: Maybe<Scalars['String']>,
  gender?: Maybe<GenderType>,
  height?: Maybe<Scalars['Float']>,
  id?: Maybe<Scalars['UUID']>,
  isConsultationCompleted?: Maybe<Scalars['Boolean']>,
  isProfileCompleted?: Maybe<Scalars['Boolean']>,
  joinedDate?: Maybe<Scalars['OffsetDateTime']>,
  lastActiveDate?: Maybe<Scalars['OffsetDateTime']>,
  lastName?: Maybe<Scalars['String']>,
  memberAvatarType?: Maybe<MemberAvatarType>,
  mostRecentlyCompletedWorkoutDate?: Maybe<Scalars['LocalDate']>,
  nextPaymentDate?: Maybe<Scalars['OffsetDateTime']>,
  notes?: Maybe<Scalars['String']>,
  parent?: Maybe<HierarchicalDomainModelEntity>,
  phone?: Maybe<Scalars['String']>,
  proGroups?: Maybe<Array<Maybe<ProGroup>>>,
  profileIconMediaUrl?: Maybe<MediaUrl>,
  profileIconUrl?: Maybe<Scalars['String']>,
  readContentWeekItems?: Maybe<Array<Maybe<Scalars['String']>>>,
  referralCode?: Maybe<Scalars['String']>,
  sendbirdAccessToken?: Maybe<Scalars['String']>,
  settings?: Maybe<Settings>,
  state?: Maybe<Scalars['String']>,
  status?: Maybe<UserStatusType>,
  subscriptionCancelledDate?: Maybe<Scalars['OffsetDateTime']>,
  subscriptionEndDate?: Maybe<Scalars['OffsetDateTime']>,
  timezone?: Maybe<TimeZoneType>,
  trialEndDate?: Maybe<Scalars['OffsetDateTime']>,
  type?: Maybe<UserType>,
  userEnabled: Scalars['Boolean'],
};

export type ClientConsultation = {
   __typename?: 'ClientConsultation',
  client?: Maybe<Client>,
  id?: Maybe<Scalars['UUID']>,
  questionAndAnswersMap?: Maybe<Scalars['Map_ConsultationQuestionCategoryType_List_QuestionAnswerScalar']>,
};

export type ClientCustomExercise = {
   __typename?: 'ClientCustomExercise',
  client?: Maybe<Client>,
  equipment?: Maybe<ExerciseEquipmentType>,
  exerciseImportId?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['UUID']>,
  includeBodyWeightInCalculations?: Maybe<Scalars['Boolean']>,
  instructions?: Maybe<Scalars['String']>,
  isCustomExercise: Scalars['Boolean'],
  isStrengthScoreKeyExercise?: Maybe<Scalars['Boolean']>,
  level?: Maybe<ExerciseLevelType>,
  media?: Maybe<ExerciseMedia>,
  name?: Maybe<Scalars['String']>,
  primaryMuscleGroups?: Maybe<Array<Maybe<MuscleGroupType>>>,
  secondaryMuscleGroups?: Maybe<Array<Maybe<MuscleGroupType>>>,
  takeaways?: Maybe<Array<Maybe<Scalars['String']>>>,
  targetBase?: Maybe<ExerciseTargetBaseType>,
  type?: Maybe<ExerciseType>,
};

export type ClientEquipmentInventory = {
   __typename?: 'ClientEquipmentInventory',
  barbellWeight?: Maybe<Scalars['Float']>,
  client?: Maybe<Client>,
  id?: Maybe<Scalars['UUID']>,
  plateWeightQuantities?: Maybe<Array<Maybe<PlateWeightQuantity>>>,
  weightUnitType?: Maybe<WeightUnitType>,
};

export type ClientEquipmentInventoryInput = {
  barbellWeight?: Maybe<Scalars['Float']>,
  client?: Maybe<ClientInput>,
  id?: Maybe<Scalars['UUID']>,
  plateWeightQuantities?: Maybe<Array<Maybe<PlateWeightQuantityInput>>>,
  weightUnitType?: Maybe<WeightUnitType>,
};

export type ClientExerciseHistory = {
   __typename?: 'ClientExerciseHistory',
  clientExerciseHistoryStatistics?: Maybe<Array<Maybe<ClientExerciseStatistic>>>,
  completedClientGymSteps?: Maybe<Slice_ClientGymStep>,
};

export type ClientExerciseNote = {
   __typename?: 'ClientExerciseNote',
  client?: Maybe<Client>,
  exercise?: Maybe<Exercise>,
  id?: Maybe<Scalars['UUID']>,
  note?: Maybe<Scalars['String']>,
};

export type ClientExerciseStatistic = {
   __typename?: 'ClientExerciseStatistic',
  actualReps?: Maybe<Scalars['Int']>,
  actualTime?: Maybe<Scalars['Int']>,
  actualWeight?: Maybe<Scalars['Float']>,
  clientWorkoutAchievement?: Maybe<ClientWorkoutAchievement>,
  id?: Maybe<Scalars['UUID']>,
  setId?: Maybe<Scalars['Int']>,
  type?: Maybe<ClientExerciseStatisticType>,
  value?: Maybe<Scalars['Float']>,
};

export enum ClientExerciseStatisticType {
  EstimatedOneRepMax = 'ESTIMATED_ONE_REP_MAX',
  MaxReps = 'MAX_REPS',
  MaxTime = 'MAX_TIME',
  MaxVolume = 'MAX_VOLUME',
  MaxWeightEver = 'MAX_WEIGHT_EVER'
}

export type ClientGymStep = GymStep & {
   __typename?: 'ClientGymStep',
  client?: Maybe<Client>,
  clientSupersetGymStep?: Maybe<ClientSupersetGymStep>,
  completionDate?: Maybe<Scalars['OffsetDateTime']>,
  exercise?: Maybe<Exercise>,
  exerciseSetDisplay?: Maybe<Scalars['String']>,
  exerciseSets?: Maybe<Array<Maybe<ExerciseSet>>>,
  formVideos?: Maybe<Array<Maybe<FormVideo>>>,
  generateSmartWarmupSets?: Maybe<Scalars['Boolean']>,
  hideWeightForExerciseSets?: Maybe<Scalars['Boolean']>,
  id?: Maybe<Scalars['UUID']>,
  maxGymStepStatistics?: Maybe<Array<Maybe<ClientExerciseStatistic>>>,
  mostRecentlyCompletedExerciseSteps?: Maybe<Array<Maybe<ExerciseSet>>>,
  parent?: Maybe<HierarchicalDomainModelEntity>,
  restPeriod?: Maybe<Scalars['Int']>,
  smartWarmup?: Maybe<SmartWarmup>,
  smartWarmupSetsCount?: Maybe<Scalars['Int']>,
  sortOrder: Scalars['Int'],
  status?: Maybe<ClientGymStepStatusType>,
  supersetGymStep?: Maybe<Superset>,
  supersetTag?: Maybe<SupersetTagType>,
  target?: Maybe<Scalars['String']>,
  targetRepsLowerBound?: Maybe<Scalars['Int']>,
  targetRepsUpperBound?: Maybe<Scalars['Int']>,
  targetSets?: Maybe<Scalars['Int']>,
  targetTime?: Maybe<Scalars['Int']>,
  targetWeight?: Maybe<Scalars['Float']>,
  workout?: Maybe<Workout>,
};

export type ClientGymStepInput = {
  client?: Maybe<ClientInput>,
  clientSupersetGymStep?: Maybe<ClientSupersetGymStepInput>,
  completionDate?: Maybe<Scalars['OffsetDateTime']>,
  exercise?: Maybe<ExerciseInput>,
  exerciseSets?: Maybe<Array<Maybe<ExerciseSetInput>>>,
  formVideos?: Maybe<Array<Maybe<FormVideoInput>>>,
  generateSmartWarmupSets?: Maybe<Scalars['Boolean']>,
  hideWeightForExerciseSets?: Maybe<Scalars['Boolean']>,
  id?: Maybe<Scalars['UUID']>,
  restPeriod?: Maybe<Scalars['Int']>,
  smartWarmup?: Maybe<SmartWarmupInput>,
  smartWarmupSetsCount?: Maybe<Scalars['Int']>,
  sortOrder: Scalars['Int'],
  status?: Maybe<ClientGymStepStatusType>,
  supersetGymStep?: Maybe<SupersetInput>,
  supersetTag?: Maybe<SupersetTagType>,
  target?: Maybe<Scalars['String']>,
  targetRepsLowerBound?: Maybe<Scalars['Int']>,
  targetRepsUpperBound?: Maybe<Scalars['Int']>,
  targetSets?: Maybe<Scalars['Int']>,
  targetTime?: Maybe<Scalars['Int']>,
  targetWeight?: Maybe<Scalars['Float']>,
  workout?: Maybe<WorkoutInput>,
};

export enum ClientGymStepStatusType {
  Completed = 'COMPLETED',
  Scheduled = 'SCHEDULED'
}

export type ClientGymStepTemplate = GymStep & {
   __typename?: 'ClientGymStepTemplate',
  client?: Maybe<Client>,
  clientWorkoutTemplate?: Maybe<ClientWorkoutTemplate>,
  exercise?: Maybe<Exercise>,
  generateSmartWarmupSets?: Maybe<Scalars['Boolean']>,
  hideWeightForExerciseSets?: Maybe<Scalars['Boolean']>,
  id?: Maybe<Scalars['UUID']>,
  mostRecentlyCompletedClientGymStep?: Maybe<ClientGymStep>,
  parent?: Maybe<HierarchicalDomainModelEntity>,
  restPeriod?: Maybe<Scalars['Int']>,
  smartWarmupSetsCount?: Maybe<Scalars['Int']>,
  sortOrder: Scalars['Int'],
  supersetGymStep?: Maybe<Superset>,
  supersetTag?: Maybe<SupersetTagType>,
  target?: Maybe<Scalars['String']>,
  targetRepsLowerBound?: Maybe<Scalars['Int']>,
  targetRepsUpperBound?: Maybe<Scalars['Int']>,
  targetSets?: Maybe<Scalars['Int']>,
  targetTime?: Maybe<Scalars['Int']>,
  targetWeight?: Maybe<Scalars['Float']>,
  workout?: Maybe<Workout>,
};

export type ClientGymStepTemplateInput = {
  client?: Maybe<ClientInput>,
  clientWorkoutTemplate?: Maybe<ClientWorkoutTemplateInput>,
  exercise?: Maybe<ExerciseInput>,
  generateSmartWarmupSets?: Maybe<Scalars['Boolean']>,
  hideWeightForExerciseSets?: Maybe<Scalars['Boolean']>,
  id?: Maybe<Scalars['UUID']>,
  restPeriod?: Maybe<Scalars['Int']>,
  smartWarmupSetsCount?: Maybe<Scalars['Int']>,
  sortOrder: Scalars['Int'],
  supersetGymStep?: Maybe<SupersetInput>,
  supersetTag?: Maybe<SupersetTagType>,
  target?: Maybe<Scalars['String']>,
  targetRepsLowerBound?: Maybe<Scalars['Int']>,
  targetRepsUpperBound?: Maybe<Scalars['Int']>,
  targetSets?: Maybe<Scalars['Int']>,
  targetTime?: Maybe<Scalars['Int']>,
  targetWeight?: Maybe<Scalars['Float']>,
  workout?: Maybe<WorkoutInput>,
};

export type ClientHabitTarget = {
   __typename?: 'ClientHabitTarget',
  client?: Maybe<Client>,
  habitType?: Maybe<HabitType>,
  id?: Maybe<Scalars['UUID']>,
  target?: Maybe<Scalars['Int']>,
};

export type ClientInput = {
  age: Scalars['Int'],
  assignedSalesRep?: Maybe<SalesRepInput>,
  assignedTrainer?: Maybe<TrainerInput>,
  birthDate?: Maybe<Scalars['LocalDate']>,
  city?: Maybe<Scalars['String']>,
  client?: Maybe<ClientInput>,
  clientAuthId?: Maybe<Scalars['String']>,
  clientSalesPackages?: Maybe<Array<Maybe<ClientSalesPackageInput>>>,
  clientTrainingPlans?: Maybe<Array<Maybe<ClientTrainingPlanInput>>>,
  closeLeadId?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  deviceType?: Maybe<DeviceType>,
  email?: Maybe<Scalars['String']>,
  firstName?: Maybe<Scalars['String']>,
  gender?: Maybe<GenderType>,
  height?: Maybe<Scalars['Float']>,
  id?: Maybe<Scalars['UUID']>,
  isConsultationCompleted?: Maybe<Scalars['Boolean']>,
  isProfileCompleted?: Maybe<Scalars['Boolean']>,
  joinedDate?: Maybe<Scalars['OffsetDateTime']>,
  lastActiveDate?: Maybe<Scalars['OffsetDateTime']>,
  lastName?: Maybe<Scalars['String']>,
  memberAvatarType?: Maybe<MemberAvatarType>,
  mostRecentlyCompletedWorkoutDate?: Maybe<Scalars['LocalDate']>,
  nextPaymentDate?: Maybe<Scalars['OffsetDateTime']>,
  notes?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  proGroups?: Maybe<Array<Maybe<ProGroupInput>>>,
  profileIconMediaUrl?: Maybe<MediaUrlInput>,
  profileIconUrl?: Maybe<Scalars['String']>,
  readContentWeekItems?: Maybe<Array<Maybe<Scalars['String']>>>,
  referralCode?: Maybe<Scalars['String']>,
  sendbirdAccessToken?: Maybe<Scalars['String']>,
  settings?: Maybe<SettingsInput>,
  state?: Maybe<Scalars['String']>,
  status?: Maybe<UserStatusType>,
  subscriptionCancelledDate?: Maybe<Scalars['OffsetDateTime']>,
  subscriptionEndDate?: Maybe<Scalars['OffsetDateTime']>,
  timezone?: Maybe<TimeZoneTypeInput>,
  trialEndDate?: Maybe<Scalars['OffsetDateTime']>,
  type?: Maybe<UserType>,
};

export type ClientNutritionTarget = {
   __typename?: 'ClientNutritionTarget',
  calories?: Maybe<Scalars['Int']>,
  calories7DayAverage?: Maybe<Scalars['Int']>,
  carbs?: Maybe<Scalars['Int']>,
  carbs7DayAverage?: Maybe<Scalars['Int']>,
  client?: Maybe<Client>,
  fat?: Maybe<Scalars['Int']>,
  fat7DayAverage?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['UUID']>,
  isIntermittentFasting?: Maybe<Scalars['Boolean']>,
  isIntermittentFastingActiveDate?: Maybe<Scalars['LocalDate']>,
  isLogging?: Maybe<Scalars['Boolean']>,
  isLoggingActiveDate?: Maybe<Scalars['LocalDate']>,
  macroTargetsActiveDate?: Maybe<Scalars['LocalDate']>,
  mealPlanMediaUrl?: Maybe<MediaUrl>,
  phase?: Maybe<NutritionTargetPhaseType>,
  phaseActiveDate?: Maybe<Scalars['LocalDate']>,
  protein?: Maybe<Scalars['Int']>,
  protein7DayAverage?: Maybe<Scalars['Int']>,
  protocol?: Maybe<NutritionTargetProtocolType>,
  protocolActiveDate?: Maybe<Scalars['LocalDate']>,
};

export enum ClientPackageType {
  Freemium = 'FREEMIUM',
  Legacy = 'LEGACY',
  Plus = 'PLUS',
  Premium = 'PREMIUM',
  Pro = 'PRO',
  TestAccount = 'TEST_ACCOUNT'
}

export type ClientProfile = {
   __typename?: 'ClientProfile',
  age?: Maybe<Scalars['Int']>,
  bio?: Maybe<Scalars['String']>,
  clientPackageType?: Maybe<ClientPackageType>,
  displayName?: Maybe<Scalars['String']>,
  gender?: Maybe<GenderType>,
  height?: Maybe<Scalars['Float']>,
  hideAge: Scalars['Boolean'],
  hideGender: Scalars['Boolean'],
  hideHeight: Scalars['Boolean'],
  hideLocation: Scalars['Boolean'],
  hideStrengthMetrics: Scalars['Boolean'],
  hideWeight: Scalars['Boolean'],
  hideWorkoutActivity: Scalars['Boolean'],
  id?: Maybe<Scalars['UUID']>,
  location?: Maybe<Scalars['String']>,
  mutualCaliberGroups?: Maybe<Array<Maybe<GroupChannel>>>,
  profileIconMediaUrl?: Maybe<MediaUrl>,
  socialMediaLinks?: Maybe<Array<Maybe<SocialMediaLink>>>,
  strengthScoreDashboard?: Maybe<StrengthScoreDashboard>,
  supporter?: Maybe<Scalars['Boolean']>,
  weight?: Maybe<Scalars['Float']>,
  workoutActivityCalendarItems?: Maybe<Array<Maybe<CalendarDay>>>,
};

export type ClientSalesPackage = {
   __typename?: 'ClientSalesPackage',
  callsLeft?: Maybe<Scalars['Int']>,
  client?: Maybe<Client>,
  clientPackageType?: Maybe<ClientPackageType>,
  id?: Maybe<Scalars['UUID']>,
  packageEndDate?: Maybe<Scalars['LocalDate']>,
  supporter?: Maybe<Scalars['Boolean']>,
};

export type ClientSalesPackageInput = {
  callsLeft?: Maybe<Scalars['Int']>,
  client?: Maybe<ClientInput>,
  clientPackageType?: Maybe<ClientPackageType>,
  id?: Maybe<Scalars['UUID']>,
  packageEndDate?: Maybe<Scalars['LocalDate']>,
  supporter?: Maybe<Scalars['Boolean']>,
};

export type ClientSearchResult = {
   __typename?: 'ClientSearchResult',
  age?: Maybe<Scalars['Int']>,
  armsMuscleGroupScore: Scalars['Int'],
  armsMuscleGroupScoreDelta: Scalars['Float'],
  backMuscleGroupScore: Scalars['Int'],
  backMuscleGroupScoreDelta: Scalars['Float'],
  birthDate?: Maybe<Scalars['Date']>,
  callsLeft?: Maybe<Scalars['Int']>,
  calories7DayAverage?: Maybe<Scalars['Int']>,
  caloriesTarget?: Maybe<Scalars['Int']>,
  carbs7DayAverage?: Maybe<Scalars['Int']>,
  carbsTarget?: Maybe<Scalars['Int']>,
  chestMuscleGroupScore: Scalars['Int'],
  chestMuscleGroupScoreDelta: Scalars['Float'],
  clientPackageType?: Maybe<ClientPackageType>,
  email?: Maybe<Scalars['String']>,
  fat7DayAverage?: Maybe<Scalars['Int']>,
  fatTarget?: Maybe<Scalars['Int']>,
  firstName?: Maybe<Scalars['String']>,
  fullName?: Maybe<Scalars['String']>,
  gender?: Maybe<GenderType>,
  height?: Maybe<Scalars['Float']>,
  id?: Maybe<Scalars['UUID']>,
  joinedDate?: Maybe<Scalars['Date']>,
  lastActiveDate?: Maybe<Scalars['Date']>,
  lastDateClientReceivedMessage?: Maybe<Scalars['Date']>,
  lastDateClientSentMessage?: Maybe<Scalars['Date']>,
  lastName?: Maybe<Scalars['String']>,
  legsMuscleGroupScore: Scalars['Int'],
  legsMuscleGroupScoreDelta: Scalars['Float'],
  managerId?: Maybe<Scalars['UUID']>,
  mostRecentlyCompletedWorkoutDate?: Maybe<Scalars['Date']>,
  packageEndDate?: Maybe<Scalars['Date']>,
  profileMediaIconUrl?: Maybe<Scalars['String']>,
  protein7DayAverage?: Maybe<Scalars['Int']>,
  proteinTarget?: Maybe<Scalars['Int']>,
  shouldersMuscleGroupScore: Scalars['Int'],
  shouldersMuscleGroupScoreDelta: Scalars['Float'],
  status?: Maybe<UserStatusType>,
  strengthBalance: Scalars['Float'],
  strengthBalanceDelta: Scalars['Float'],
  strengthScore: Scalars['Int'],
  strengthScoreDelta: Scalars['Float'],
  trainerFirstName?: Maybe<Scalars['String']>,
  trainerFullName?: Maybe<Scalars['String']>,
  trainerId?: Maybe<Scalars['UUID']>,
  trainerLastName?: Maybe<Scalars['String']>,
  weight?: Maybe<Scalars['Float']>,
};

export enum ClientSearchResultSortCriteria {
  ArmsMuscleGroupScore = 'armsMuscleGroupScore',
  BackMuscleGroupScore = 'backMuscleGroupScore',
  BirthDate = 'birthDate',
  CallsLeft = 'callsLeft',
  Calories7DayAverage = 'calories7DayAverage',
  Carbs7DayAverage = 'carbs7DayAverage',
  ChestMuscleGroupScore = 'chestMuscleGroupScore',
  ClientPackageType = 'clientPackageType',
  Email = 'email',
  Fat7DayAverage = 'fat7DayAverage',
  FirstName = 'firstName',
  Gender = 'gender',
  Height = 'height',
  JoinedDate = 'joinedDate',
  LastActiveDate = 'lastActiveDate',
  LastDateClientReceivedMessage = 'lastDateClientReceivedMessage',
  LastDateClientSentMessage = 'lastDateClientSentMessage',
  LastName = 'lastName',
  LegsMuscleGroupScore = 'legsMuscleGroupScore',
  MostRecentlyCompletedWorkoutDate = 'mostRecentlyCompletedWorkoutDate',
  PackageEndDate = 'packageEndDate',
  Protein7DayAverage = 'protein7DayAverage',
  ShouldersMuscleGroupScore = 'shouldersMuscleGroupScore',
  Status = 'status',
  StrengthBalance = 'strengthBalance',
  StrengthScore = 'strengthScore',
  TrainerFirstName = 'trainerFirstName',
  TrainerLastName = 'trainerLastName',
  Weight = 'weight'
}

export type ClientSettings = Settings & {
   __typename?: 'ClientSettings',
  clientsTabColumnPreferences?: Maybe<Scalars['String']>,
  enableAppleHealth?: Maybe<Scalars['Boolean']>,
  enableCronometer?: Maybe<Scalars['Boolean']>,
  enableExerciseVideoAutoplay?: Maybe<Scalars['Boolean']>,
  enableGeneralNotifications?: Maybe<Scalars['Boolean']>,
  enableGoogleFit?: Maybe<Scalars['Boolean']>,
  enableMessageNotifications?: Maybe<Scalars['Boolean']>,
  enableSamsungHealth?: Maybe<Scalars['Boolean']>,
  hasNewNotification?: Maybe<Scalars['Boolean']>,
  id?: Maybe<Scalars['UUID']>,
  unitBodystat?: Maybe<UnitType>,
  unitDistance?: Maybe<UnitType>,
  unitWeight?: Maybe<UnitType>,
};

export type ClientSettingsInput = {
  clientsTabColumnPreferences?: Maybe<Scalars['String']>,
  enableAppleHealth?: Maybe<Scalars['Boolean']>,
  enableCronometer?: Maybe<Scalars['Boolean']>,
  enableExerciseVideoAutoplay?: Maybe<Scalars['Boolean']>,
  enableGeneralNotifications?: Maybe<Scalars['Boolean']>,
  enableGoogleFit?: Maybe<Scalars['Boolean']>,
  enableMessageNotifications?: Maybe<Scalars['Boolean']>,
  enableSamsungHealth?: Maybe<Scalars['Boolean']>,
  hasNewNotification?: Maybe<Scalars['Boolean']>,
  id?: Maybe<Scalars['UUID']>,
  unitBodystat?: Maybe<UnitType>,
  unitDistance?: Maybe<UnitType>,
  unitWeight?: Maybe<UnitType>,
  user?: Maybe<UserInput>,
};

export enum ClientSortCriteria {
  LastActiveDate = 'lastActiveDate',
  LastDateClientReceivedMessage = 'lastDateClientReceivedMessage',
  LastDateClientSentMessage = 'lastDateClientSentMessage',
  LastName = 'lastName',
  MostRecentlyCompletedWorkoutDate = 'mostRecentlyCompletedWorkoutDate'
}

export type ClientSupersetGymStep = GymStep & {
   __typename?: 'ClientSupersetGymStep',
  client?: Maybe<Client>,
  clientGymSteps?: Maybe<Array<Maybe<ClientGymStep>>>,
  clientSupersetGymStep?: Maybe<ClientSupersetGymStep>,
  completionDate?: Maybe<Scalars['OffsetDateTime']>,
  exercise?: Maybe<Exercise>,
  exerciseSetDisplay?: Maybe<Scalars['String']>,
  exerciseSets?: Maybe<Array<Maybe<ExerciseSet>>>,
  formVideos?: Maybe<Array<Maybe<FormVideo>>>,
  generateSmartWarmupSets?: Maybe<Scalars['Boolean']>,
  gymSteps?: Maybe<Array<Maybe<GymStep>>>,
  hideWeightForExerciseSets?: Maybe<Scalars['Boolean']>,
  id?: Maybe<Scalars['UUID']>,
  maxGymStepStatistics?: Maybe<Array<Maybe<ClientExerciseStatistic>>>,
  mostRecentlyCompletedExerciseSteps?: Maybe<Array<Maybe<ExerciseSet>>>,
  parent?: Maybe<HierarchicalDomainModelEntity>,
  restPeriod?: Maybe<Scalars['Int']>,
  smartWarmup?: Maybe<SmartWarmup>,
  smartWarmupSetsCount?: Maybe<Scalars['Int']>,
  sortOrder: Scalars['Int'],
  status?: Maybe<ClientGymStepStatusType>,
  supersetGymStep?: Maybe<Superset>,
  supersetTag?: Maybe<SupersetTagType>,
  target?: Maybe<Scalars['String']>,
  targetRepsLowerBound?: Maybe<Scalars['Int']>,
  targetRepsUpperBound?: Maybe<Scalars['Int']>,
  targetSets?: Maybe<Scalars['Int']>,
  targetTime?: Maybe<Scalars['Int']>,
  targetWeight?: Maybe<Scalars['Float']>,
  workout?: Maybe<Workout>,
};

export type ClientSupersetGymStepInput = {
  client?: Maybe<ClientInput>,
  clientGymSteps?: Maybe<Array<Maybe<ClientGymStepInput>>>,
  clientSupersetGymStep?: Maybe<ClientSupersetGymStepInput>,
  completionDate?: Maybe<Scalars['OffsetDateTime']>,
  exercise?: Maybe<ExerciseInput>,
  exerciseSets?: Maybe<Array<Maybe<ExerciseSetInput>>>,
  formVideos?: Maybe<Array<Maybe<FormVideoInput>>>,
  generateSmartWarmupSets?: Maybe<Scalars['Boolean']>,
  hideWeightForExerciseSets?: Maybe<Scalars['Boolean']>,
  id?: Maybe<Scalars['UUID']>,
  restPeriod?: Maybe<Scalars['Int']>,
  smartWarmup?: Maybe<SmartWarmupInput>,
  smartWarmupSetsCount?: Maybe<Scalars['Int']>,
  sortOrder: Scalars['Int'],
  status?: Maybe<ClientGymStepStatusType>,
  supersetGymStep?: Maybe<SupersetInput>,
  supersetTag?: Maybe<SupersetTagType>,
  target?: Maybe<Scalars['String']>,
  targetRepsLowerBound?: Maybe<Scalars['Int']>,
  targetRepsUpperBound?: Maybe<Scalars['Int']>,
  targetSets?: Maybe<Scalars['Int']>,
  targetTime?: Maybe<Scalars['Int']>,
  targetWeight?: Maybe<Scalars['Float']>,
  workout?: Maybe<WorkoutInput>,
};

export type ClientSupersetGymStepTemplate = GymStep & {
   __typename?: 'ClientSupersetGymStepTemplate',
  client?: Maybe<Client>,
  clientGymStepTemplates?: Maybe<Array<Maybe<ClientGymStepTemplate>>>,
  clientWorkoutTemplate?: Maybe<ClientWorkoutTemplate>,
  exercise?: Maybe<Exercise>,
  generateSmartWarmupSets?: Maybe<Scalars['Boolean']>,
  gymSteps?: Maybe<Array<Maybe<GymStep>>>,
  hideWeightForExerciseSets?: Maybe<Scalars['Boolean']>,
  id?: Maybe<Scalars['UUID']>,
  mostRecentlyCompletedClientGymStep?: Maybe<ClientGymStep>,
  parent?: Maybe<HierarchicalDomainModelEntity>,
  restPeriod?: Maybe<Scalars['Int']>,
  smartWarmupSetsCount?: Maybe<Scalars['Int']>,
  sortOrder: Scalars['Int'],
  supersetGymStep?: Maybe<Superset>,
  supersetTag?: Maybe<SupersetTagType>,
  target?: Maybe<Scalars['String']>,
  targetRepsLowerBound?: Maybe<Scalars['Int']>,
  targetRepsUpperBound?: Maybe<Scalars['Int']>,
  targetSets?: Maybe<Scalars['Int']>,
  targetTime?: Maybe<Scalars['Int']>,
  targetWeight?: Maybe<Scalars['Float']>,
  workout?: Maybe<Workout>,
};

export type ClientTrainingPlan = TrainingPlan & {
   __typename?: 'ClientTrainingPlan',
  DEFAULT_NAME?: Maybe<Scalars['String']>,
  active: Scalars['Boolean'],
  client?: Maybe<Client>,
  clientWorkoutGroupTemplates?: Maybe<Array<Maybe<ClientWorkoutGroupTemplate>>>,
  clientWorkoutTemplates?: Maybe<Array<Maybe<ClientWorkoutTemplate>>>,
  id?: Maybe<Scalars['UUID']>,
  name?: Maybe<Scalars['String']>,
  parent?: Maybe<HierarchicalDomainModelEntity>,
  sourceMasterTrainingPlan?: Maybe<MasterTrainingPlan>,
  workoutGroups?: Maybe<Array<Maybe<WorkoutGroup>>>,
};

export type ClientTrainingPlanInput = {
  active: Scalars['Boolean'],
  client?: Maybe<ClientInput>,
  clientWorkoutGroupTemplates?: Maybe<Array<Maybe<ClientWorkoutGroupTemplateInput>>>,
  id?: Maybe<Scalars['UUID']>,
  name?: Maybe<Scalars['String']>,
  sourceMasterTrainingPlan?: Maybe<MasterTrainingPlanInput>,
};

export type ClientWorkout = Workout & {
   __typename?: 'ClientWorkout',
  achievements?: Maybe<Array<Maybe<ClientWorkoutAchievement>>>,
  client?: Maybe<Client>,
  clientGymSteps?: Maybe<Array<Maybe<ClientGymStep>>>,
  gymSteps?: Maybe<Array<Maybe<GymStep>>>,
  id?: Maybe<Scalars['UUID']>,
  instructions?: Maybe<Scalars['String']>,
  parent?: Maybe<HierarchicalDomainModelEntity>,
  sortOrder: Scalars['Int'],
  sourceTemplate?: Maybe<ClientWorkoutTemplate>,
  thumbnailImage?: Maybe<MediaUrl>,
  title?: Maybe<Scalars['String']>,
  workoutCalendarItem?: Maybe<WorkoutCalendarItem>,
};

export type ClientWorkoutAchievement = {
   __typename?: 'ClientWorkoutAchievement',
  clientWorkout?: Maybe<ClientWorkout>,
  exercise?: Maybe<Exercise>,
  exerciseAchievements?: Maybe<Array<Maybe<ClientExerciseStatistic>>>,
  id?: Maybe<Scalars['UUID']>,
};

export type ClientWorkoutGroupTemplate = WorkoutGroup & {
   __typename?: 'ClientWorkoutGroupTemplate',
  aclOwner?: Maybe<User>,
  client?: Maybe<Client>,
  clientTrainingPlan?: Maybe<ClientTrainingPlan>,
  clientWorkoutTemplates?: Maybe<Array<Maybe<ClientWorkoutTemplate>>>,
  createdByTrainer: Scalars['Boolean'],
  default: Scalars['Boolean'],
  id?: Maybe<Scalars['UUID']>,
  parent?: Maybe<HierarchicalDomainModelEntity>,
  sortOrder: Scalars['Int'],
  title?: Maybe<Scalars['String']>,
  workoutGroupType?: Maybe<WorkoutGroupType>,
  workouts?: Maybe<Array<Maybe<Workout>>>,
};

export type ClientWorkoutGroupTemplateInput = {
  client?: Maybe<ClientInput>,
  clientTrainingPlan?: Maybe<ClientTrainingPlanInput>,
  clientWorkoutTemplates?: Maybe<Array<Maybe<ClientWorkoutTemplateInput>>>,
  default: Scalars['Boolean'],
  id?: Maybe<Scalars['UUID']>,
  sortOrder: Scalars['Int'],
  title?: Maybe<Scalars['String']>,
  workoutGroupType?: Maybe<WorkoutGroupType>,
};

export type ClientWorkoutTemplate = Workout & {
   __typename?: 'ClientWorkoutTemplate',
  client?: Maybe<Client>,
  clientGymStepTemplates?: Maybe<Array<Maybe<ClientGymStepTemplate>>>,
  clientWorkoutGroupTemplate?: Maybe<ClientWorkoutGroupTemplate>,
  gymSteps?: Maybe<Array<Maybe<GymStep>>>,
  id?: Maybe<Scalars['UUID']>,
  instructions?: Maybe<Scalars['String']>,
  parent?: Maybe<HierarchicalDomainModelEntity>,
  sortOrder: Scalars['Int'],
  thumbnailImage?: Maybe<MediaUrl>,
  title?: Maybe<Scalars['String']>,
};

export type ClientWorkoutTemplateInput = {
  client?: Maybe<ClientInput>,
  clientGymStepTemplates?: Maybe<Array<Maybe<ClientGymStepTemplateInput>>>,
  clientWorkoutGroupTemplate?: Maybe<ClientWorkoutGroupTemplateInput>,
  id?: Maybe<Scalars['UUID']>,
  instructions?: Maybe<Scalars['String']>,
  sortOrder: Scalars['Int'],
  thumbnailImage?: Maybe<MediaUrlInput>,
  title?: Maybe<Scalars['String']>,
};

export type ClientWorkoutTemplateRecurringEventDefinition = {
   __typename?: 'ClientWorkoutTemplateRecurringEventDefinition',
  RRule?: Maybe<Scalars['String']>,
  client?: Maybe<Client>,
  clientWorkoutTemplate?: Maybe<ClientWorkoutTemplate>,
  endDate?: Maybe<Scalars['LocalDate']>,
  id?: Maybe<Scalars['UUID']>,
  recurringEventExceptions?: Maybe<Array<Maybe<RecurringEventException>>>,
  startDate?: Maybe<Scalars['LocalDate']>,
  type?: Maybe<RecurringEventType>,
};

export type CoachProfile = {
   __typename?: 'CoachProfile',
  bio?: Maybe<Scalars['String']>,
  certifications?: Maybe<Scalars['String']>,
  education?: Maybe<Scalars['String']>,
  funFacts?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['UUID']>,
  photos?: Maybe<Array<Maybe<CoachProfilePhoto>>>,
  trainer?: Maybe<Trainer>,
};

export type CoachProfilePhoto = {
   __typename?: 'CoachProfilePhoto',
  coachProfile?: Maybe<CoachProfile>,
  id?: Maybe<Scalars['UUID']>,
  mediaUrl?: Maybe<MediaUrl>,
  sortOrder: Scalars['Int'],
};

export type CoachReminderRule = CaliberRule & {
   __typename?: 'CoachReminderRule',
  caliberRuleType?: Maybe<RuleType>,
  condition?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['UUID']>,
  impact?: Maybe<CoachTaskImpactType>,
  lowerLimitParam: Scalars['Int'],
  name?: Maybe<Scalars['String']>,
  objectClassSimpleName?: Maybe<Scalars['String']>,
  objectCrudOperation?: Maybe<CrudOperationType>,
  paramOperator?: Maybe<ComparisonOperatorType>,
  paramUnit?: Maybe<ChronoUnit>,
  ruleAutocompletable: Scalars['Boolean'],
  taskExpirationDurationHours: Scalars['Int'],
  type?: Maybe<RuleType>,
  upperLimitParam: Scalars['Int'],
  webDeepLinkScreen?: Maybe<WebDeepLinkScreenType>,
};

export type CoachReminderRuleFromCron = CaliberRule & {
   __typename?: 'CoachReminderRuleFromCron',
  caliberRuleType?: Maybe<RuleType>,
  condition?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['UUID']>,
  impact?: Maybe<CoachTaskImpactType>,
  lowerLimitParam: Scalars['Int'],
  name?: Maybe<Scalars['String']>,
  objectClassSimpleName?: Maybe<Scalars['String']>,
  objectCrudOperation?: Maybe<CrudOperationType>,
  paramOperator?: Maybe<ComparisonOperatorType>,
  paramUnit?: Maybe<ChronoUnit>,
  ruleAutocompletable: Scalars['Boolean'],
  snoozePeriod?: Maybe<Scalars['Period']>,
  taskExpirationDurationHours: Scalars['Int'],
  type?: Maybe<RuleType>,
  upperLimitParam: Scalars['Int'],
  webDeepLinkScreen?: Maybe<WebDeepLinkScreenType>,
};

export type CoachSearchResult = {
   __typename?: 'CoachSearchResult',
  capacity?: Maybe<Scalars['Int']>,
  email?: Maybe<Scalars['String']>,
  firstName?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['UUID']>,
  isAvailable?: Maybe<Scalars['Boolean']>,
  lastActiveDate?: Maybe<Scalars['OffsetDateTime']>,
  lastDateTrainerSentMessage?: Maybe<Scalars['OffsetDateTime']>,
  lastName?: Maybe<Scalars['String']>,
  legacyClientCount: Scalars['Int'],
  premiumClientCount: Scalars['Int'],
  profileIconMediaUrl?: Maybe<MediaUrl>,
};

export type CoachTask = {
   __typename?: 'CoachTask',
  assignee?: Maybe<User>,
  autocompleted: Scalars['Boolean'],
  client?: Maybe<Client>,
  createdDate?: Maybe<Scalars['OffsetDateTime']>,
  dueDate?: Maybe<Scalars['OffsetDateTime']>,
  escalatedDate?: Maybe<Scalars['OffsetDateTime']>,
  id?: Maybe<Scalars['UUID']>,
  impact?: Maybe<CoachTaskImpactType>,
  resolvedDate?: Maybe<Scalars['OffsetDateTime']>,
  snoozeUntilDate?: Maybe<Scalars['OffsetDateTime']>,
  sourceRule?: Maybe<ReminderRuleType>,
  status?: Maybe<CoachTaskStatusType>,
  summary?: Maybe<Scalars['String']>,
  taskAutocompletable: Scalars['Boolean'],
  trainer?: Maybe<Trainer>,
  triggerDescription?: Maybe<Scalars['String']>,
  webDeepLinkScreen?: Maybe<WebDeepLinkScreenType>,
};

export enum CoachTaskImpactType {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM'
}

export enum CoachTaskStatusType {
  Closed = 'CLOSED',
  Escalated = 'ESCALATED',
  Open = 'OPEN'
}

export enum ComparisonOperatorType {
  Between = 'BETWEEN',
  Eq = 'EQ',
  Gt = 'GT',
  Gte = 'GTE',
  Lt = 'LT',
  Lte = 'LTE'
}

export enum ConsultationQuestionCategoryType {
  HealthHistory = 'HEALTH_HISTORY',
  NutritionHistory = 'NUTRITION_HISTORY',
  TrainingHistory = 'TRAINING_HISTORY'
}

export type ContentAction = ContentWeekItem & {
   __typename?: 'ContentAction',
  CONTENTFUL_CONTENT_TYPE_ID?: Maybe<Scalars['String']>,
  contentDeepLink?: Maybe<ContentDeepLink>,
  id?: Maybe<Scalars['String']>,
  locked?: Maybe<Scalars['Boolean']>,
  read: Scalars['Boolean'],
  sortOrder: Scalars['Int'],
  thumbnailImage?: Maybe<MediaUrl>,
  title?: Maybe<Scalars['String']>,
};

export type ContentDeepLink = {
   __typename?: 'ContentDeepLink',
  CONTENTFUL_CONTENT_TYPE_ID?: Maybe<Scalars['String']>,
  deepLink?: Maybe<Scalars['String']>,
};

export type ContentLessonPage = {
   __typename?: 'ContentLessonPage',
  CONTENTFUL_CONTENT_TYPE_ID?: Maybe<Scalars['String']>,
  backgroundImage?: Maybe<MediaUrl>,
  body?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  sortOrder: Scalars['Int'],
};

export type ContentLessonSet = ContentWeekItem & {
   __typename?: 'ContentLessonSet',
  CONTENTFUL_CONTENT_TYPE_ID?: Maybe<Scalars['String']>,
  category?: Maybe<Scalars['String']>,
  completionButtonLabel?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  lessonPages?: Maybe<Array<Maybe<ContentLessonPage>>>,
  locked?: Maybe<Scalars['Boolean']>,
  read: Scalars['Boolean'],
  sortOrder: Scalars['Int'],
  thumbnailImage?: Maybe<MediaUrl>,
  title?: Maybe<Scalars['String']>,
};

export type ContentWeek = {
   __typename?: 'ContentWeek',
  CONTENTFUL_CONTENT_TYPE_ID?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  memberAvatarType?: Maybe<MemberAvatarType>,
  title?: Maybe<Scalars['String']>,
  weekItems?: Maybe<Array<Maybe<ContentWeekItem>>>,
  weekNumber: Scalars['Int'],
};

export type ContentWeekItem = {
  id?: Maybe<Scalars['String']>,
  locked?: Maybe<Scalars['Boolean']>,
  read: Scalars['Boolean'],
  sortOrder: Scalars['Int'],
  thumbnailImage?: Maybe<MediaUrl>,
  title?: Maybe<Scalars['String']>,
};

export type ContentWidget = {
   __typename?: 'ContentWidget',
  ctaText?: Maybe<Scalars['String']>,
  deepLink?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  headline?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  image?: Maybe<MediaUrl>,
  location?: Maybe<Location>,
  testVersion?: Maybe<Scalars['String']>,
};

export type CronometerToken = {
   __typename?: 'CronometerToken',
  accessToken?: Maybe<Scalars['String']>,
  client?: Maybe<Client>,
  cronometerUserId?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['UUID']>,
  tokenType?: Maybe<Scalars['String']>,
};

export enum CrudOperationType {
  All = 'ALL',
  Create = 'CREATE',
  CreateAndUpdate = 'CREATE_AND_UPDATE',
  Cron = 'CRON',
  Delete = 'DELETE',
  Read = 'READ',
  Update = 'UPDATE'
}

export type CustomActivityCalendarItem = CalendarItem & {
   __typename?: 'CustomActivityCalendarItem',
  client?: Maybe<Client>,
  date?: Maybe<Scalars['LocalDate']>,
  daySortOrder: Scalars['Int'],
  enumType?: Maybe<CalendarItemType>,
  id?: Maybe<Scalars['UUID']>,
  parent?: Maybe<HierarchicalDomainModelEntity>,
  recurring: Scalars['Boolean'],
  recurringEventDefinition?: Maybe<RecurringEventDefinition>,
  scheduledBy?: Maybe<CalendarItemScheduledByType>,
  sort: Scalars['Int'],
  status?: Maybe<CalendarItemStatusType>,
  text?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
};

export type CustomTrainingPlan = TrainingPlan & {
   __typename?: 'CustomTrainingPlan',
  active: Scalars['Boolean'],
  id?: Maybe<Scalars['UUID']>,
  masterWorkoutGroups?: Maybe<Array<Maybe<MasterWorkoutGroup>>>,
  masterWorkouts?: Maybe<Array<Maybe<MasterWorkout>>>,
  name?: Maybe<Scalars['String']>,
  proGroup?: Maybe<ProGroup>,
  trainer?: Maybe<Trainer>,
  type?: Maybe<CustomTrainingPlanType>,
  workoutGroups?: Maybe<Array<Maybe<WorkoutGroup>>>,
  workouts?: Maybe<Array<Maybe<Workout>>>,
};

export type CustomTrainingPlanInput = {
  active: Scalars['Boolean'],
  id?: Maybe<Scalars['UUID']>,
  masterWorkoutGroups?: Maybe<Array<Maybe<MasterWorkoutGroupInput>>>,
  name?: Maybe<Scalars['String']>,
  proGroup?: Maybe<ProGroupInput>,
  trainer?: Maybe<TrainerInput>,
  type?: Maybe<CustomTrainingPlanType>,
};

export enum CustomTrainingPlanType {
  ProGroup = 'PRO_GROUP',
  Trainer = 'TRAINER'
}


export enum DeviceType {
  All = 'ALL',
  Android = 'ANDROID',
  Ios = 'IOS',
  Web = 'WEB'
}

export enum Direction {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Exercise = {
   __typename?: 'Exercise',
  equipment?: Maybe<ExerciseEquipmentType>,
  exerciseImportId?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['UUID']>,
  includeBodyWeightInCalculations?: Maybe<Scalars['Boolean']>,
  instructions?: Maybe<Scalars['String']>,
  isCustomExercise: Scalars['Boolean'],
  isStrengthScoreKeyExercise?: Maybe<Scalars['Boolean']>,
  level?: Maybe<ExerciseLevelType>,
  media?: Maybe<ExerciseMedia>,
  name?: Maybe<Scalars['String']>,
  primaryMuscleGroups?: Maybe<Array<Maybe<MuscleGroupType>>>,
  secondaryMuscleGroups?: Maybe<Array<Maybe<MuscleGroupType>>>,
  takeaways?: Maybe<Array<Maybe<Scalars['String']>>>,
  targetBase?: Maybe<ExerciseTargetBaseType>,
  type?: Maybe<ExerciseType>,
};

export enum ExerciseEquipmentType {
  Assisted = 'ASSISTED',
  Barbell = 'BARBELL',
  Bodyweight = 'BODYWEIGHT',
  Cable = 'CABLE',
  Dumbbell = 'DUMBBELL',
  EzBar = 'EZ_BAR',
  FoamRoller = 'FOAM_ROLLER',
  HammerStrength = 'HAMMER_STRENGTH',
  Kettlebell = 'KETTLEBELL',
  Landmine = 'LANDMINE',
  Machine = 'MACHINE',
  ResistanceBand = 'RESISTANCE_BAND',
  SmithMachine = 'SMITH_MACHINE',
  TrapBar = 'TRAP_BAR',
  Trx = 'TRX',
  Weighted = 'WEIGHTED'
}

export type ExerciseInput = {
  equipment?: Maybe<ExerciseEquipmentType>,
  exerciseImportId?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['UUID']>,
  includeBodyWeightInCalculations?: Maybe<Scalars['Boolean']>,
  instructions?: Maybe<Scalars['String']>,
  isStrengthScoreKeyExercise?: Maybe<Scalars['Boolean']>,
  level?: Maybe<ExerciseLevelType>,
  media?: Maybe<ExerciseMediaInput>,
  name?: Maybe<Scalars['String']>,
  primaryMuscleGroups?: Maybe<Array<Maybe<MuscleGroupType>>>,
  secondaryMuscleGroups?: Maybe<Array<Maybe<MuscleGroupType>>>,
  takeaways?: Maybe<Array<Maybe<Scalars['String']>>>,
  targetBase?: Maybe<ExerciseTargetBaseType>,
  type?: Maybe<ExerciseType>,
};

export enum ExerciseLevelType {
  Advanced = 'ADVANCED',
  Beginner = 'BEGINNER'
}

export type ExerciseLight = {
   __typename?: 'ExerciseLight',
  id?: Maybe<Scalars['UUID']>,
  name?: Maybe<Scalars['String']>,
};

export type ExerciseMedia = {
   __typename?: 'ExerciseMedia',
  empty: Scalars['Boolean'],
  heroImageUrl?: Maybe<MediaUrl>,
  id?: Maybe<Scalars['UUID']>,
  thumbnailUrl?: Maybe<MediaUrl>,
  videoLesson?: Maybe<VideoLesson>,
  videoUrl?: Maybe<MediaUrl>,
};

export type ExerciseMediaInput = {
  heroImageUrl?: Maybe<MediaUrlInput>,
  id?: Maybe<Scalars['UUID']>,
  thumbnailUrl?: Maybe<MediaUrlInput>,
  videoLesson?: Maybe<VideoLessonInput>,
  videoUrl?: Maybe<MediaUrlInput>,
};

export type ExerciseSet = {
   __typename?: 'ExerciseSet',
  actualReps?: Maybe<Scalars['Int']>,
  actualTime?: Maybe<Scalars['Int']>,
  actualVolume?: Maybe<Scalars['Float']>,
  actualWeight?: Maybe<Scalars['Float']>,
  clientGymStep?: Maybe<ClientGymStep>,
  estimatedOneRepMax?: Maybe<Scalars['Float']>,
  id?: Maybe<Scalars['UUID']>,
  setId?: Maybe<Scalars['Int']>,
  sortOrder: Scalars['Int'],
  timeAsInt: Scalars['Int'],
};

export type ExerciseSetInput = {
  actualReps?: Maybe<Scalars['Int']>,
  actualTime?: Maybe<Scalars['Int']>,
  actualWeight?: Maybe<Scalars['Float']>,
  clientGymStep?: Maybe<ClientGymStepInput>,
  id?: Maybe<Scalars['UUID']>,
  setId?: Maybe<Scalars['Int']>,
  sortOrder: Scalars['Int'],
};

export type ExerciseSetLight = {
   __typename?: 'ExerciseSetLight',
  date?: Maybe<Scalars['LocalDate']>,
  orm?: Maybe<Scalars['Float']>,
};

export enum ExerciseSortCriteria {
  Equipment = 'equipment',
  Level = 'level',
  Name = 'name',
  Type = 'type'
}

export enum ExerciseTargetBaseType {
  Reps = 'REPS',
  Time = 'TIME'
}

export enum ExerciseType {
  Cardio = 'CARDIO',
  Mobility = 'MOBILITY',
  Strength = 'STRENGTH',
  Warmup = 'WARMUP'
}

export enum ExperienceLevelType {
  Advanced = 'ADVANCED',
  Beginner = 'BEGINNER',
  Intermediate = 'INTERMEDIATE',
  Novice = 'NOVICE'
}

export enum Feature {
  ProSignup = 'PRO_SIGNUP',
  RegistrationUpsellWebview = 'REGISTRATION_UPSELL_WEBVIEW',
  SupporterSignup = 'SUPPORTER_SIGNUP'
}

export type FeaturedLibraryTrainingPlan = {
   __typename?: 'FeaturedLibraryTrainingPlan',
  libraryTrainingPlan?: Maybe<LibraryTrainingPlan>,
  sortOrder?: Maybe<Scalars['Int']>,
};

export type FeaturedLibraryTrainingPlanCategory = {
   __typename?: 'FeaturedLibraryTrainingPlanCategory',
  categoryName?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  featuredPlans?: Maybe<Array<Maybe<FeaturedLibraryTrainingPlan>>>,
  sortOrder?: Maybe<Scalars['Int']>,
};

export type FeatureFlag = {
   __typename?: 'FeatureFlag',
  deviceType?: Maybe<DeviceType>,
  enabled: Scalars['Boolean'],
  feature?: Maybe<Feature>,
  id?: Maybe<Scalars['UUID']>,
  minAppBuildNumber: Scalars['Int'],
};

export enum FileUploadType {
  CoachProfilePic = 'COACH_PROFILE_PIC',
  ExerciseHero = 'EXERCISE_HERO',
  ExerciseThumbnail = 'EXERCISE_THUMBNAIL',
  ExerciseVideo = 'EXERCISE_VIDEO',
  FormVideo = 'FORM_VIDEO',
  MealPlanDoc = 'MEAL_PLAN_DOC',
  ProfilePic = 'PROFILE_PIC',
  ProgressPic = 'PROGRESS_PIC',
  VideoLesson = 'VIDEO_LESSON',
  VideoLessonThumbnail = 'VIDEO_LESSON_THUMBNAIL',
  VideoMessage = 'VIDEO_MESSAGE',
  WorkoutThumbnail = 'WORKOUT_THUMBNAIL'
}

export type FormVideo = {
   __typename?: 'FormVideo',
  clientGymStep?: Maybe<ClientGymStep>,
  exerciseName?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['UUID']>,
  setNumber?: Maybe<Scalars['Int']>,
  videoMessage?: Maybe<VideoMessage>,
  workoutName?: Maybe<Scalars['String']>,
};

export type FormVideoInput = {
  clientGymStep?: Maybe<ClientGymStepInput>,
  exerciseName?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['UUID']>,
  setNumber?: Maybe<Scalars['Int']>,
  videoMessage?: Maybe<VideoMessageInput>,
  workoutName?: Maybe<Scalars['String']>,
};

export enum GenderType {
  Female = 'FEMALE',
  Male = 'MALE',
  NotSpecified = 'NOT_SPECIFIED',
  Other = 'OTHER'
}

export type Goal = {
  achieved: Scalars['Boolean'],
  client?: Maybe<Client>,
  id?: Maybe<Scalars['UUID']>,
  targetDate?: Maybe<Scalars['LocalDate']>,
};

export type GroupChannel = {
   __typename?: 'GroupChannel',
  channelUrl?: Maybe<Scalars['String']>,
  coverUrl?: Maybe<Scalars['String']>,
  customType?: Maybe<Scalars['String']>,
  lastMessage?: Maybe<SendBirdMessage>,
  memberCount: Scalars['Int'],
  name?: Maybe<Scalars['String']>,
  super: Scalars['Boolean'],
};

export type GymStep = {
  exercise?: Maybe<Exercise>,
  generateSmartWarmupSets?: Maybe<Scalars['Boolean']>,
  hideWeightForExerciseSets?: Maybe<Scalars['Boolean']>,
  id?: Maybe<Scalars['UUID']>,
  restPeriod?: Maybe<Scalars['Int']>,
  smartWarmupSetsCount?: Maybe<Scalars['Int']>,
  sortOrder: Scalars['Int'],
  supersetGymStep?: Maybe<Superset>,
  supersetTag?: Maybe<SupersetTagType>,
  target?: Maybe<Scalars['String']>,
  targetRepsLowerBound?: Maybe<Scalars['Int']>,
  targetRepsUpperBound?: Maybe<Scalars['Int']>,
  targetSets?: Maybe<Scalars['Int']>,
  targetTime?: Maybe<Scalars['Int']>,
  targetWeight?: Maybe<Scalars['Float']>,
  workout?: Maybe<Workout>,
};

export type HabitCalendarItem = CalendarItem & {
   __typename?: 'HabitCalendarItem',
  client?: Maybe<Client>,
  date?: Maybe<Scalars['LocalDate']>,
  daySortOrder: Scalars['Int'],
  enumType?: Maybe<CalendarItemType>,
  habitType?: Maybe<HabitType>,
  id?: Maybe<Scalars['UUID']>,
  parent?: Maybe<HierarchicalDomainModelEntity>,
  quantity?: Maybe<Scalars['Int']>,
  recurring: Scalars['Boolean'],
  recurringEventDefinition?: Maybe<RecurringEventDefinition>,
  scheduledBy?: Maybe<CalendarItemScheduledByType>,
  sort: Scalars['Int'],
  status?: Maybe<CalendarItemStatusType>,
  target?: Maybe<Scalars['Int']>,
  title?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
};

export type HabitEvent = {
   __typename?: 'HabitEvent',
  date?: Maybe<Scalars['LocalDate']>,
  habitType?: Maybe<HabitType>,
  healthKitSyncSet?: Maybe<HealthKitSyncSet>,
  id?: Maybe<Scalars['UUID']>,
  importableEventType?: Maybe<ImportableEventType>,
  importedStepsHabitCalendarItem?: Maybe<ImportedHabitCalendarItem>,
  quantity?: Maybe<Scalars['Int']>,
  workoutSyncSourceType?: Maybe<WorkoutSyncSourceType>,
};

export type HabitEventInput = {
  date?: Maybe<Scalars['LocalDate']>,
  habitType?: Maybe<HabitType>,
  healthKitSyncSet?: Maybe<HealthKitSyncSetInput>,
  id?: Maybe<Scalars['UUID']>,
  importedStepsHabitCalendarItem?: Maybe<ImportedHabitCalendarItemInput>,
  quantity?: Maybe<Scalars['Int']>,
  workoutSyncSourceType?: Maybe<WorkoutSyncSourceType>,
};

export type HabitRecurringEventDefinition = {
   __typename?: 'HabitRecurringEventDefinition',
  RRule?: Maybe<Scalars['String']>,
  client?: Maybe<Client>,
  endDate?: Maybe<Scalars['LocalDate']>,
  habitType?: Maybe<HabitType>,
  id?: Maybe<Scalars['UUID']>,
  recurringEventExceptions?: Maybe<Array<Maybe<RecurringEventException>>>,
  startDate?: Maybe<Scalars['LocalDate']>,
  type?: Maybe<RecurringEventType>,
};

export enum HabitType {
  Sleep = 'SLEEP',
  Steps = 'STEPS',
  Water = 'WATER'
}

export type HealthKitSyncSet = {
   __typename?: 'HealthKitSyncSet',
  bodyMeasurementEvents?: Maybe<Array<Maybe<BodyMeasurementEvent>>>,
  cardioActivityEvents?: Maybe<Array<Maybe<CardioActivityEvent>>>,
  endDate?: Maybe<Scalars['LocalDate']>,
  habitEvents?: Maybe<Array<Maybe<HabitEvent>>>,
  id?: Maybe<Scalars['UUID']>,
  macronutrientEvents?: Maybe<Array<Maybe<MacronutrientEvent>>>,
  startDate?: Maybe<Scalars['LocalDate']>,
  transactionDate?: Maybe<Scalars['LocalDateTime']>,
};

export type HealthKitSyncSetInput = {
  bodyMeasurementEvents?: Maybe<Array<Maybe<BodyMeasurementEventInput>>>,
  cardioActivityEvents?: Maybe<Array<Maybe<CardioActivityEventInput>>>,
  endDate?: Maybe<Scalars['LocalDate']>,
  habitEvents?: Maybe<Array<Maybe<HabitEventInput>>>,
  id?: Maybe<Scalars['UUID']>,
  macronutrientEvents?: Maybe<Array<Maybe<MacronutrientEventInput>>>,
  startDate?: Maybe<Scalars['LocalDate']>,
  transactionDate?: Maybe<Scalars['LocalDateTime']>,
};

export type HierarchicalDomainModelEntity = {
   __typename?: 'HierarchicalDomainModelEntity',
  id?: Maybe<Scalars['UUID']>,
};

export enum ImportableEventType {
  BodyMeasurement = 'BODY_MEASUREMENT',
  CardioActivity = 'CARDIO_ACTIVITY',
  Habits = 'HABITS',
  Nutrition = 'NUTRITION'
}

export type ImportedBodyStatCalendarItem = CalendarItem & {
   __typename?: 'ImportedBodyStatCalendarItem',
  bodyMeasurementType?: Maybe<BodyMeasurementType>,
  bodyMeasurementValue?: Maybe<Scalars['Float']>,
  client?: Maybe<Client>,
  date?: Maybe<Scalars['LocalDate']>,
  daySortOrder: Scalars['Int'],
  enumType?: Maybe<CalendarItemType>,
  id?: Maybe<Scalars['UUID']>,
  parent?: Maybe<HierarchicalDomainModelEntity>,
  recurring: Scalars['Boolean'],
  recurringEventDefinition?: Maybe<RecurringEventDefinition>,
  scheduledBy?: Maybe<CalendarItemScheduledByType>,
  sort: Scalars['Int'],
  source?: Maybe<WorkoutSyncSourceType>,
  status?: Maybe<CalendarItemStatusType>,
  title?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
};

export type ImportedCardioActivityCalendarItem = CalendarItem & {
   __typename?: 'ImportedCardioActivityCalendarItem',
  averageHeartRate?: Maybe<Scalars['Int']>,
  averagePace?: Maybe<Scalars['Float']>,
  calories?: Maybe<Scalars['Int']>,
  cardioActivityEvent?: Maybe<CardioActivityEvent>,
  cardioActivityType?: Maybe<CardioActivityType>,
  client?: Maybe<Client>,
  date?: Maybe<Scalars['LocalDate']>,
  daySortOrder: Scalars['Int'],
  distance?: Maybe<Scalars['Float']>,
  elevationGain?: Maybe<Scalars['Float']>,
  enumType?: Maybe<CalendarItemType>,
  id?: Maybe<Scalars['UUID']>,
  level?: Maybe<Scalars['Int']>,
  notes?: Maybe<Scalars['String']>,
  parent?: Maybe<HierarchicalDomainModelEntity>,
  recurring: Scalars['Boolean'],
  recurringEventDefinition?: Maybe<RecurringEventDefinition>,
  scheduledBy?: Maybe<CalendarItemScheduledByType>,
  sort: Scalars['Int'],
  source?: Maybe<WorkoutSyncSourceType>,
  speed?: Maybe<Scalars['Float']>,
  status?: Maybe<CalendarItemStatusType>,
  time?: Maybe<Scalars['Int']>,
  title?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
};

export type ImportedCardioActivityCalendarItemInput = {
  averageHeartRate?: Maybe<Scalars['Int']>,
  averagePace?: Maybe<Scalars['Float']>,
  calories?: Maybe<Scalars['Int']>,
  cardioActivityEvent?: Maybe<CardioActivityEventInput>,
  cardioActivityType?: Maybe<CardioActivityType>,
  client?: Maybe<ClientInput>,
  date?: Maybe<Scalars['LocalDate']>,
  distance?: Maybe<Scalars['Float']>,
  elevationGain?: Maybe<Scalars['Float']>,
  id?: Maybe<Scalars['UUID']>,
  level?: Maybe<Scalars['Int']>,
  notes?: Maybe<Scalars['String']>,
  recurringEventDefinition?: Maybe<RecurringEventDefinitionInput>,
  scheduledBy?: Maybe<CalendarItemScheduledByType>,
  sort: Scalars['Int'],
  source?: Maybe<WorkoutSyncSourceType>,
  speed?: Maybe<Scalars['Float']>,
  status?: Maybe<CalendarItemStatusType>,
  time?: Maybe<Scalars['Int']>,
  title?: Maybe<Scalars['String']>,
};

export type ImportedHabitCalendarItem = CalendarItem & {
   __typename?: 'ImportedHabitCalendarItem',
  client?: Maybe<Client>,
  date?: Maybe<Scalars['LocalDate']>,
  daySortOrder: Scalars['Int'],
  enumType?: Maybe<CalendarItemType>,
  habitType?: Maybe<HabitType>,
  id?: Maybe<Scalars['UUID']>,
  parent?: Maybe<HierarchicalDomainModelEntity>,
  quantity?: Maybe<Scalars['Int']>,
  recurring: Scalars['Boolean'],
  recurringEventDefinition?: Maybe<RecurringEventDefinition>,
  scheduledBy?: Maybe<CalendarItemScheduledByType>,
  sort: Scalars['Int'],
  source?: Maybe<WorkoutSyncSourceType>,
  status?: Maybe<CalendarItemStatusType>,
  target?: Maybe<Scalars['Int']>,
  title?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
};

export type ImportedHabitCalendarItemInput = {
  client?: Maybe<ClientInput>,
  date?: Maybe<Scalars['LocalDate']>,
  habitType?: Maybe<HabitType>,
  id?: Maybe<Scalars['UUID']>,
  quantity?: Maybe<Scalars['Int']>,
  recurringEventDefinition?: Maybe<RecurringEventDefinitionInput>,
  scheduledBy?: Maybe<CalendarItemScheduledByType>,
  sort: Scalars['Int'],
  source?: Maybe<WorkoutSyncSourceType>,
  status?: Maybe<CalendarItemStatusType>,
  target?: Maybe<Scalars['Int']>,
  title?: Maybe<Scalars['String']>,
};

export type ImportedNutritionCalendarItem = CalendarItem & {
   __typename?: 'ImportedNutritionCalendarItem',
  client?: Maybe<Client>,
  date?: Maybe<Scalars['LocalDate']>,
  daySortOrder: Scalars['Int'],
  enumType?: Maybe<CalendarItemType>,
  id?: Maybe<Scalars['UUID']>,
  macronutrientEvents?: Maybe<Array<Maybe<MacronutrientEvent>>>,
  parent?: Maybe<HierarchicalDomainModelEntity>,
  recurring: Scalars['Boolean'],
  recurringEventDefinition?: Maybe<RecurringEventDefinition>,
  scheduledBy?: Maybe<CalendarItemScheduledByType>,
  sort: Scalars['Int'],
  source?: Maybe<WorkoutSyncSourceType>,
  status?: Maybe<CalendarItemStatusType>,
  title?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
};

export type ImportedNutritionCalendarItemInput = {
  client?: Maybe<ClientInput>,
  date?: Maybe<Scalars['LocalDate']>,
  id?: Maybe<Scalars['UUID']>,
  macronutrientEvents?: Maybe<Array<Maybe<MacronutrientEventInput>>>,
  recurringEventDefinition?: Maybe<RecurringEventDefinitionInput>,
  scheduledBy?: Maybe<CalendarItemScheduledByType>,
  sort: Scalars['Int'],
  source?: Maybe<WorkoutSyncSourceType>,
  status?: Maybe<CalendarItemStatusType>,
  title?: Maybe<Scalars['String']>,
};

export enum KeyDriverExplanationTextType {
  BodyWeight = 'BODY_WEIGHT',
  Demographic = 'DEMOGRAPHIC',
  None = 'NONE',
  Orm = 'ORM',
  OrmDelta = 'ORM_DELTA'
}

export type LibraryTrainingPlan = TrainingPlan & {
   __typename?: 'LibraryTrainingPlan',
  active?: Maybe<Scalars['Boolean']>,
  daysPerWeek?: Maybe<Scalars['Int']>,
  durationMinutes?: Maybe<Scalars['Int']>,
  equipmentTypes?: Maybe<Array<Maybe<ExerciseEquipmentType>>>,
  id?: Maybe<Scalars['UUID']>,
  masterWorkoutGroups?: Maybe<Array<Maybe<MasterWorkoutGroup>>>,
  masterWorkouts?: Maybe<Array<Maybe<MasterWorkout>>>,
  name?: Maybe<Scalars['String']>,
  splitType?: Maybe<SplitType>,
  trainer?: Maybe<Trainer>,
  workoutGroups?: Maybe<Array<Maybe<WorkoutGroup>>>,
  workouts?: Maybe<Array<Maybe<Workout>>>,
};

export type LibraryTrainingPlanCategory = {
   __typename?: 'LibraryTrainingPlanCategory',
  active?: Maybe<Scalars['Boolean']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['UUID']>,
  libraryTrainingPlans?: Maybe<Array<Maybe<LibraryTrainingPlan>>>,
  name?: Maybe<Scalars['String']>,
  sortOrder?: Maybe<Scalars['Int']>,
};

export type LinkPreviewData = {
   __typename?: 'LinkPreviewData',
  description?: Maybe<Scalars['String']>,
  icon?: Maybe<Scalars['String']>,
  image?: Maybe<Scalars['String']>,
  imageX?: Maybe<Scalars['Int']>,
  imageY?: Maybe<Scalars['Int']>,
  siteName?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
};

export type LiveStatus = {
   __typename?: 'LiveStatus',
  clientGymStepId?: Maybe<Scalars['UUID']>,
  displayName?: Maybe<Scalars['String']>,
  exerciseId?: Maybe<Scalars['UUID']>,
  exerciseName?: Maybe<Scalars['String']>,
  groupChannelUrls?: Maybe<Array<Maybe<Scalars['String']>>>,
  profileIconUrl?: Maybe<Scalars['String']>,
  spotifyTrackAlbumImageUrl?: Maybe<Scalars['String']>,
  spotifyTrackArtistName?: Maybe<Scalars['String']>,
  spotifyTrackTitle?: Maybe<Scalars['String']>,
  spotifyUri?: Maybe<Scalars['String']>,
  userId?: Maybe<Scalars['UUID']>,
  workoutCalendarItemId?: Maybe<Scalars['UUID']>,
};

export type LiveStatusInput = {
  clientGymStepId?: Maybe<Scalars['UUID']>,
  displayName?: Maybe<Scalars['String']>,
  exerciseId?: Maybe<Scalars['UUID']>,
  exerciseName?: Maybe<Scalars['String']>,
  groupChannelUrls?: Maybe<Array<Maybe<Scalars['String']>>>,
  profileIconUrl?: Maybe<Scalars['String']>,
  spotifyTrackAlbumImageUrl?: Maybe<Scalars['String']>,
  spotifyTrackArtistName?: Maybe<Scalars['String']>,
  spotifyTrackTitle?: Maybe<Scalars['String']>,
  spotifyUri?: Maybe<Scalars['String']>,
  userId?: Maybe<Scalars['UUID']>,
  workoutCalendarItemId?: Maybe<Scalars['UUID']>,
};



export enum Location {
  HomeScreenBelowCalendar = 'HOME_SCREEN_BELOW_CALENDAR'
}

export type LocationResult = {
   __typename?: 'LocationResult',
  city?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  googlePlaceId?: Maybe<Scalars['String']>,
  state?: Maybe<Scalars['String']>,
};


export type MacronutrientEvent = {
   __typename?: 'MacronutrientEvent',
  date?: Maybe<Scalars['LocalDate']>,
  healthKitSyncSet?: Maybe<HealthKitSyncSet>,
  id?: Maybe<Scalars['UUID']>,
  importableEventType?: Maybe<ImportableEventType>,
  importedNutritionCalendarItem?: Maybe<ImportedNutritionCalendarItem>,
  macronutrient?: Maybe<MacronutrientType>,
  nutritionSyncSourceType?: Maybe<NutritionSyncSourceType>,
  remaining?: Maybe<Scalars['Float']>,
  target?: Maybe<Scalars['Float']>,
  unit?: Maybe<UnitType>,
  value?: Maybe<Scalars['Float']>,
};

export type MacronutrientEventInput = {
  date?: Maybe<Scalars['LocalDate']>,
  healthKitSyncSet?: Maybe<HealthKitSyncSetInput>,
  id?: Maybe<Scalars['UUID']>,
  importedNutritionCalendarItem?: Maybe<ImportedNutritionCalendarItemInput>,
  macronutrient?: Maybe<MacronutrientType>,
  nutritionSyncSourceType?: Maybe<NutritionSyncSourceType>,
  target?: Maybe<Scalars['Float']>,
  unit?: Maybe<UnitType>,
  value?: Maybe<Scalars['Float']>,
};

export enum MacronutrientType {
  Calories = 'CALORIES',
  Carbs = 'CARBS',
  Fat = 'FAT',
  Protein = 'PROTEIN'
}

export type Manager = User & {
   __typename?: 'Manager',
  age?: Maybe<Scalars['Int']>,
  assignedTrainers?: Maybe<Array<Maybe<Trainer>>>,
  birthDate?: Maybe<Scalars['LocalDate']>,
  city?: Maybe<Scalars['String']>,
  clientAuthId?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  firstName?: Maybe<Scalars['String']>,
  gender?: Maybe<GenderType>,
  height?: Maybe<Scalars['Float']>,
  id?: Maybe<Scalars['UUID']>,
  isConsultationCompleted?: Maybe<Scalars['Boolean']>,
  isProfileCompleted?: Maybe<Scalars['Boolean']>,
  joinedDate?: Maybe<Scalars['OffsetDateTime']>,
  lastName?: Maybe<Scalars['String']>,
  notes?: Maybe<Scalars['String']>,
  parent?: Maybe<HierarchicalDomainModelEntity>,
  phone?: Maybe<Scalars['String']>,
  profileIconMediaUrl?: Maybe<MediaUrl>,
  profileIconUrl?: Maybe<Scalars['String']>,
  sendbirdAccessToken?: Maybe<Scalars['String']>,
  settings?: Maybe<Settings>,
  state?: Maybe<Scalars['String']>,
  status?: Maybe<UserStatusType>,
  timezone?: Maybe<TimeZoneType>,
  type?: Maybe<UserType>,
  userEnabled: Scalars['Boolean'],
};

export type ManagerInput = {
  age: Scalars['Int'],
  assignedTrainers?: Maybe<Array<Maybe<TrainerInput>>>,
  birthDate?: Maybe<Scalars['LocalDate']>,
  city?: Maybe<Scalars['String']>,
  clientAuthId?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  firstName?: Maybe<Scalars['String']>,
  gender?: Maybe<GenderType>,
  height?: Maybe<Scalars['Float']>,
  id?: Maybe<Scalars['UUID']>,
  isConsultationCompleted?: Maybe<Scalars['Boolean']>,
  isProfileCompleted?: Maybe<Scalars['Boolean']>,
  joinedDate?: Maybe<Scalars['OffsetDateTime']>,
  lastName?: Maybe<Scalars['String']>,
  notes?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  profileIconMediaUrl?: Maybe<MediaUrlInput>,
  profileIconUrl?: Maybe<Scalars['String']>,
  sendbirdAccessToken?: Maybe<Scalars['String']>,
  settings?: Maybe<SettingsInput>,
  state?: Maybe<Scalars['String']>,
  status?: Maybe<UserStatusType>,
  timezone?: Maybe<TimeZoneTypeInput>,
  type?: Maybe<UserType>,
};

export type ManagerSettings = Settings & {
   __typename?: 'ManagerSettings',
  clientsTabColumnPreferences?: Maybe<Scalars['String']>,
  enableGeneralNotifications?: Maybe<Scalars['Boolean']>,
  enableMessageNotifications?: Maybe<Scalars['Boolean']>,
  hasNewNotification?: Maybe<Scalars['Boolean']>,
  id?: Maybe<Scalars['UUID']>,
  unitBodystat?: Maybe<UnitType>,
  unitDistance?: Maybe<UnitType>,
  unitWeight?: Maybe<UnitType>,
};

export type ManagerSettingsInput = {
  clientsTabColumnPreferences?: Maybe<Scalars['String']>,
  enableGeneralNotifications?: Maybe<Scalars['Boolean']>,
  enableMessageNotifications?: Maybe<Scalars['Boolean']>,
  hasNewNotification?: Maybe<Scalars['Boolean']>,
  id?: Maybe<Scalars['UUID']>,
  unitBodystat?: Maybe<UnitType>,
  unitDistance?: Maybe<UnitType>,
  unitWeight?: Maybe<UnitType>,
  user?: Maybe<UserInput>,
};




export type MasterGymStep = GymStep & {
   __typename?: 'MasterGymStep',
  exercise?: Maybe<Exercise>,
  generateSmartWarmupSets?: Maybe<Scalars['Boolean']>,
  hideWeightForExerciseSets?: Maybe<Scalars['Boolean']>,
  id?: Maybe<Scalars['UUID']>,
  masterSupersetGymStep?: Maybe<MasterSupersetGymStep>,
  parent?: Maybe<HierarchicalDomainModelEntity>,
  restPeriod?: Maybe<Scalars['Int']>,
  smartWarmupSetsCount?: Maybe<Scalars['Int']>,
  sortOrder: Scalars['Int'],
  supersetGymStep?: Maybe<Superset>,
  supersetTag?: Maybe<SupersetTagType>,
  target?: Maybe<Scalars['String']>,
  targetRepsLowerBound?: Maybe<Scalars['Int']>,
  targetRepsUpperBound?: Maybe<Scalars['Int']>,
  targetSets?: Maybe<Scalars['Int']>,
  targetTime?: Maybe<Scalars['Int']>,
  targetWeight?: Maybe<Scalars['Float']>,
  workout?: Maybe<Workout>,
};

export type MasterGymStepInput = {
  exercise?: Maybe<ExerciseInput>,
  generateSmartWarmupSets?: Maybe<Scalars['Boolean']>,
  hideWeightForExerciseSets?: Maybe<Scalars['Boolean']>,
  id?: Maybe<Scalars['UUID']>,
  masterSupersetGymStep?: Maybe<MasterSupersetGymStepInput>,
  restPeriod?: Maybe<Scalars['Int']>,
  smartWarmupSetsCount?: Maybe<Scalars['Int']>,
  sortOrder: Scalars['Int'],
  supersetGymStep?: Maybe<SupersetInput>,
  supersetTag?: Maybe<SupersetTagType>,
  target?: Maybe<Scalars['String']>,
  targetRepsLowerBound?: Maybe<Scalars['Int']>,
  targetRepsUpperBound?: Maybe<Scalars['Int']>,
  targetSets?: Maybe<Scalars['Int']>,
  targetTime?: Maybe<Scalars['Int']>,
  targetWeight?: Maybe<Scalars['Float']>,
  workout?: Maybe<WorkoutInput>,
};

export type MasterSupersetGymStep = GymStep & {
   __typename?: 'MasterSupersetGymStep',
  exercise?: Maybe<Exercise>,
  generateSmartWarmupSets?: Maybe<Scalars['Boolean']>,
  gymSteps?: Maybe<Array<Maybe<GymStep>>>,
  hideWeightForExerciseSets?: Maybe<Scalars['Boolean']>,
  id?: Maybe<Scalars['UUID']>,
  masterGymSteps?: Maybe<Array<Maybe<MasterGymStep>>>,
  masterSupersetGymStep?: Maybe<MasterSupersetGymStep>,
  parent?: Maybe<HierarchicalDomainModelEntity>,
  restPeriod?: Maybe<Scalars['Int']>,
  smartWarmupSetsCount?: Maybe<Scalars['Int']>,
  sortOrder: Scalars['Int'],
  supersetGymStep?: Maybe<Superset>,
  supersetTag?: Maybe<SupersetTagType>,
  target?: Maybe<Scalars['String']>,
  targetRepsLowerBound?: Maybe<Scalars['Int']>,
  targetRepsUpperBound?: Maybe<Scalars['Int']>,
  targetSets?: Maybe<Scalars['Int']>,
  targetTime?: Maybe<Scalars['Int']>,
  targetWeight?: Maybe<Scalars['Float']>,
  workout?: Maybe<Workout>,
};

export type MasterSupersetGymStepInput = {
  exercise?: Maybe<ExerciseInput>,
  generateSmartWarmupSets?: Maybe<Scalars['Boolean']>,
  hideWeightForExerciseSets?: Maybe<Scalars['Boolean']>,
  id?: Maybe<Scalars['UUID']>,
  masterGymSteps?: Maybe<Array<Maybe<MasterGymStepInput>>>,
  masterSupersetGymStep?: Maybe<MasterSupersetGymStepInput>,
  restPeriod?: Maybe<Scalars['Int']>,
  smartWarmupSetsCount?: Maybe<Scalars['Int']>,
  sortOrder: Scalars['Int'],
  supersetGymStep?: Maybe<SupersetInput>,
  supersetTag?: Maybe<SupersetTagType>,
  target?: Maybe<Scalars['String']>,
  targetRepsLowerBound?: Maybe<Scalars['Int']>,
  targetRepsUpperBound?: Maybe<Scalars['Int']>,
  targetSets?: Maybe<Scalars['Int']>,
  targetTime?: Maybe<Scalars['Int']>,
  targetWeight?: Maybe<Scalars['Float']>,
  workout?: Maybe<WorkoutInput>,
};

export type MasterTrainingPlan = TrainingPlan & {
   __typename?: 'MasterTrainingPlan',
  duration?: Maybe<TrainingPlanDurationType>,
  frequency?: Maybe<TrainingPlanFrequencyType>,
  gender?: Maybe<GenderType>,
  id?: Maybe<Scalars['UUID']>,
  masterWorkoutGroups?: Maybe<Array<Maybe<MasterWorkoutGroup>>>,
  masterWorkouts?: Maybe<Array<Maybe<MasterWorkout>>>,
  parent?: Maybe<HierarchicalDomainModelEntity>,
  planVersion?: Maybe<TrainingPlanVersionType>,
  program?: Maybe<TrainingPlanProgramType>,
  repRange?: Maybe<TrainingPlanRepRangeType>,
  type?: Maybe<TrainingPlanType>,
  workoutGroups?: Maybe<Array<Maybe<WorkoutGroup>>>,
  workouts?: Maybe<Array<Maybe<Workout>>>,
};

export type MasterTrainingPlanInput = {
  duration?: Maybe<TrainingPlanDurationType>,
  frequency?: Maybe<TrainingPlanFrequencyType>,
  gender?: Maybe<GenderType>,
  id?: Maybe<Scalars['UUID']>,
  masterWorkoutGroups?: Maybe<Array<Maybe<MasterWorkoutGroupInput>>>,
  planVersion?: Maybe<TrainingPlanVersionType>,
  program?: Maybe<TrainingPlanProgramType>,
  repRange?: Maybe<TrainingPlanRepRangeType>,
  type?: Maybe<TrainingPlanType>,
};

export type MasterWorkout = Workout & {
   __typename?: 'MasterWorkout',
  gymSteps?: Maybe<Array<Maybe<GymStep>>>,
  id?: Maybe<Scalars['UUID']>,
  instructions?: Maybe<Scalars['String']>,
  masterGymSteps?: Maybe<Array<Maybe<MasterGymStep>>>,
  masterWorkoutGroup?: Maybe<WorkoutGroup>,
  parent?: Maybe<HierarchicalDomainModelEntity>,
  sortOrder: Scalars['Int'],
  thumbnailImage?: Maybe<MediaUrl>,
  title?: Maybe<Scalars['String']>,
};

export type MasterWorkoutGroup = WorkoutGroup & {
   __typename?: 'MasterWorkoutGroup',
  id?: Maybe<Scalars['UUID']>,
  masterTrainingPlan?: Maybe<TrainingPlan>,
  masterWorkouts?: Maybe<Array<Maybe<MasterWorkout>>>,
  parent?: Maybe<HierarchicalDomainModelEntity>,
  sortOrder: Scalars['Int'],
  workouts?: Maybe<Array<Maybe<Workout>>>,
};

export type MasterWorkoutGroupInput = {
  id?: Maybe<Scalars['UUID']>,
  masterTrainingPlan?: Maybe<TrainingPlanInput>,
  masterWorkouts?: Maybe<Array<Maybe<MasterWorkoutInput>>>,
  sortOrder: Scalars['Int'],
};

export type MasterWorkoutInput = {
  id?: Maybe<Scalars['UUID']>,
  instructions?: Maybe<Scalars['String']>,
  masterGymSteps?: Maybe<Array<Maybe<MasterGymStepInput>>>,
  masterWorkoutGroup?: Maybe<WorkoutGroupInput>,
  sortOrder: Scalars['Int'],
  thumbnailImage?: Maybe<MediaUrlInput>,
  title?: Maybe<Scalars['String']>,
};

export type MediaUrl = {
   __typename?: 'MediaUrl',
  hd?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['UUID']>,
  isSecure?: Maybe<Scalars['Boolean']>,
  mimeType?: Maybe<Scalars['String']>,
  muxVideoUpload?: Maybe<MuxVideoUpload>,
  objectName?: Maybe<Scalars['String']>,
  originUrl?: Maybe<Scalars['String']>,
  type?: Maybe<FileUploadType>,
  url?: Maybe<Scalars['String']>,
};

export type MediaUrlInput = {
  hd?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['UUID']>,
  isSecure?: Maybe<Scalars['Boolean']>,
  mimeType?: Maybe<Scalars['String']>,
  muxVideoUpload?: Maybe<MuxVideoUploadInput>,
  objectName?: Maybe<Scalars['String']>,
  originUrl?: Maybe<Scalars['String']>,
  type?: Maybe<FileUploadType>,
  url?: Maybe<Scalars['String']>,
};

export enum MemberAvatarType {
  Freemium = 'FREEMIUM',
  General = 'GENERAL',
  INeedHelp = 'I_NEED_HELP',
  Pro = 'PRO',
  StepItUp = 'STEP_IT_UP'
}

export enum MuscleGroupType {
  Abductors = 'ABDUCTORS',
  Abs = 'ABS',
  Adductors = 'ADDUCTORS',
  Biceps = 'BICEPS',
  Brachialis = 'BRACHIALIS',
  Calves = 'CALVES',
  ErectorSpinae = 'ERECTOR_SPINAE',
  Forearms = 'FOREARMS',
  FrontShoulders = 'FRONT_SHOULDERS',
  Glutes = 'GLUTES',
  Hamstrings = 'HAMSTRINGS',
  HipFlexors = 'HIP_FLEXORS',
  Infraspinatus = 'INFRASPINATUS',
  Lats = 'LATS',
  LowerBack = 'LOWER_BACK',
  MidChest = 'MID_CHEST',
  MidShoulders = 'MID_SHOULDERS',
  Obliques = 'OBLIQUES',
  Quads = 'QUADS',
  RearShoulders = 'REAR_SHOULDERS',
  Rhomboids = 'RHOMBOIDS',
  Subscapularis = 'SUBSCAPULARIS',
  TeresMajor = 'TERES_MAJOR',
  TransverseAbdominis = 'TRANSVERSE_ABDOMINIS',
  Traps = 'TRAPS',
  Triceps = 'TRICEPS',
  UpperChest = 'UPPER_CHEST'
}

/** Mutation root */
export type Mutation = {
   __typename?: 'Mutation',
  addChildToSuperset?: Maybe<Workout>,
  /** Allows a trainer to add an exercise to the workout template. */
  addClientGymStepTemplate?: Maybe<ClientWorkoutTemplate>,
  addLibraryTrainingPlanToCategory?: Maybe<LibraryTrainingPlanCategory>,
  /** Allows an admin to add an exercise to the master workout. */
  addMasterGymStep?: Maybe<MasterWorkout>,
  /** Allows a trainer to add an exercise to the master workout in a custom training plan. */
  addMasterGymStepToCustomTrainingPlan?: Maybe<MasterWorkout>,
  addMasterGymStepToLibraryTrainingPlan?: Maybe<MasterWorkout>,
  addNewClientWorkoutGroupTemplate?: Maybe<ClientTrainingPlan>,
  /** Adds a new empty workout (no exercises) to a client training plan. */
  addNewClientWorkoutTemplate?: Maybe<ClientTrainingPlan>,
  /** Adds a new empty workout (no exercises) to a master training plan. */
  addNewMasterWorkout?: Maybe<MasterTrainingPlan>,
  /** Adds a new empty workout (no exercises) to a custom training plan. */
  addNewMasterWorkoutToCustomTrainingPlan?: Maybe<CustomTrainingPlan>,
  /** Adds a new empty workout (no exercises) to a custom training plan. */
  addNewMasterWorkoutToLibraryTrainingPlan?: Maybe<LibraryTrainingPlan>,
  addPaywallToPaywallConfig?: Maybe<PaywallConfig>,
  addTagsToProGroup?: Maybe<ProGroup>,
  addTrainersToProGroup?: Maybe<ProGroup>,
  adminUpdateReferralOffer?: Maybe<ReferralOffer>,
  /** Copies a custom training plan to a client and marks it inactive. */
  assignCustomTrainingPlan?: Maybe<ClientTrainingPlan>,
  /** Copies a master training plan to a client and marks it inactive. */
  assignMasterTrainingPlan?: Maybe<ClientTrainingPlan>,
  authorizeCronometerRedirectUrl?: Maybe<Scalars['String']>,
  calendarExportCSV: Scalars['Boolean'],
  cancelMuxVideoUpload?: Maybe<MediaUrl>,
  /** Cancel a user's subscription in WooCommerce. */
  cancelSubscription?: Maybe<Scalars['String']>,
  /** Changes email address on all integration partners and in the API DB. */
  changeClientEmail: Scalars['Boolean'],
  clearMinimumAppVersionCache: Scalars['Boolean'],
  cloneCustomTrainingPlan?: Maybe<CustomTrainingPlan>,
  cloneMasterTrainingPlan?: Maybe<MasterTrainingPlan>,
  convertGymStepToSuperset?: Maybe<Workout>,
  /** Copies a Client Workout Group Template to a Client Training Plan or to My Workout Groups. */
  copyClientWorkoutGroupTemplate?: Maybe<TrainingPlan>,
  /** Copies a Client Workout Template to a Client Workout Group Template. */
  copyClientWorkoutTemplate?: Maybe<ClientTrainingPlan>,
  copyMasterWorkoutGroupToClientWorkoutGroupTemplate?: Maybe<ClientTrainingPlan>,
  copyProGroupWorkoutToClientTrainingPlan?: Maybe<ClientTrainingPlan>,
  copySharedWorkoutGroupToClientTrainingPlan?: Maybe<ClientTrainingPlan>,
  copySharedWorkoutToClientWorkoutGroup?: Maybe<ClientTrainingPlan>,
  copyTrainingPlanToClients: Scalars['Boolean'],
  copyTrainingPlanToTrainer?: Maybe<CustomTrainingPlan>,
  createBodyStatCalendarItem?: Maybe<BodyStatCalendarItem>,
  createBodyStatRecurringEventDefinition?: Maybe<BodyStatRecurringEventDefinition>,
  /** 
 * All associated media must be uploaded first.  Place all MediaUrl IDs in the
   * associated id field.  The API will perform the lookup.
 */
  createCaliberExercise?: Maybe<CaliberExercise>,
  createCardioActivityCalendarItem?: Maybe<CardioActivityCalendarItem>,
  createCardioActivityRecurringEventDefinition?: Maybe<CardioActivityRecurringEventDefinition>,
  /** The client can create their own consultation. */
  createClientConsultation?: Maybe<ClientConsultation>,
  /** Admins can create a Client from the Web app. */
  createClientFromWeb?: Maybe<Client>,
  createClientWorkoutTemplateRecurringEventDefinition?: Maybe<ClientWorkoutTemplateRecurringEventDefinition>,
  createCustomActivityCalendarItem?: Maybe<CustomActivityCalendarItem>,
  createCustomExercise?: Maybe<ClientCustomExercise>,
  /** Allows a trainer to create a custom training plan. */
  createCustomTrainingPlan?: Maybe<CustomTrainingPlan>,
  createEmptySuperset?: Maybe<Workout>,
  createFreestyleWorkoutCalendarItem?: Maybe<WorkoutCalendarItem>,
  createHabitCalendarItem?: Maybe<HabitCalendarItem>,
  createHabitRecurringEventDefinition?: Maybe<HabitRecurringEventDefinition>,
  createLibraryTrainingPlan?: Maybe<LibraryTrainingPlan>,
  createLibraryTrainingPlanCategory?: Maybe<LibraryTrainingPlanCategory>,
  /** Allows an admin to create a master training plan. */
  createMasterTrainingPlan?: Maybe<MasterTrainingPlan>,
  /** Used for Caliber Group chat rooms */
  createMuxVideoGroupChannelUpload?: Maybe<MediaUrl>,
  /** Used for Coach-Client messaging and Pro Group form reviews */
  createMuxVideoMessageUpload?: Maybe<MediaUrl>,
  createMuxVideoUpload?: Maybe<MediaUrl>,
  createNewClientTrainingPlan?: Maybe<ClientTrainingPlan>,
  createPaywallConfig?: Maybe<PaywallConfig>,
  createProGroup?: Maybe<ProGroup>,
  createProGroupTag?: Maybe<ProGroupTag>,
  createProgressPhotoCalendarItem?: Maybe<ProgressPhotoCalendarItem>,
  createProgressPhotoRecurringEventDefinition?: Maybe<ProgressPhotoRecurringEventDefinition>,
  /** Default date mask is MM/dd/yyyy */
  createTextGoal?: Maybe<TextGoal>,
  createUpdateClientExerciseNote?: Maybe<ClientExerciseNote>,
  /** Set a habit target for a client */
  createUpdateClientHabitTarget?: Maybe<ClientHabitTarget>,
  /** Set a nutrition target for a client */
  createUpdateClientNutritionTarget?: Maybe<ClientNutritionTarget>,
  /** Create a client profile, returns a fully populated profile, requiring start/end dates to populate the calendar */
  createUpdateClientProfile?: Maybe<ClientProfile>,
  createUpdateClientTrainingPlanFromWizard?: Maybe<ClientTrainingPlan>,
  createUpdateCoachProfile?: Maybe<CoachProfile>,
  /** 
 *  Create a new Freestyle Workout Group Template or add new workout template to
   * Freestyle Workout Group Template based on changes made to a Client Workout
 */
  createUpdateFreestyleClientWorkoutGroupTemplate?: Maybe<ClientTrainingPlan>,
  /** Will also create a new Client Profile if one does not yet exist */
  createUpdateSocialMediaLink?: Maybe<SocialMediaLink>,
  createUpdateZoomCall?: Maybe<ZoomCall>,
  /** Create a new Caliber user. */
  createUserProfile?: Maybe<User>,
  createWorkoutCalendarItem?: Maybe<WorkoutCalendarItem>,
  deauthorizeCronometer: Scalars['Boolean'],
  deleteCalendarItem?: Maybe<Scalars['String']>,
  deleteChildFromSuperset?: Maybe<Workout>,
  deleteClientEquipmentInventory: Scalars['Boolean'],
  /** Allows a trainer to delete a client gym step. */
  deleteClientGymStepTemplate: Scalars['Boolean'],
  /** Allows a trainer to delete the client training plan.  Always set both values. */
  deleteClientTrainingPlan: Scalars['Boolean'],
  /** Allows a trainer to delete the workout group. */
  deleteClientWorkoutGroupTemplate: Scalars['Boolean'],
  /** Allows a trainer to delete the client workout template. */
  deleteClientWorkoutTemplate: Scalars['Boolean'],
  deleteCoachTask: Scalars['Boolean'],
  deleteCustomExercise: Scalars['Boolean'],
  /** Allows a trainer to delete the custom training plan. */
  deleteCustomTrainingPlan: Scalars['Boolean'],
  /** Allows an admin to delete an unused exercise. */
  deleteExercise: Scalars['Boolean'],
  /** Detaches a form video and deletes the message sent to the trainer. */
  deleteFormVideo: Scalars['Boolean'],
  deleteHabitTarget: Scalars['Boolean'],
  deleteLibraryTrainingPlan: Scalars['Boolean'],
  deleteLibraryTrainingPlanCategory: Scalars['Boolean'],
  /** Allows an admin to delete a master gym step. */
  deleteMasterGymStep: Scalars['Boolean'],
  /** Allows a trainer to delete a master gym step in a custom training plan. */
  deleteMasterGymStepInCustomTrainingPlan: Scalars['Boolean'],
  deleteMasterGymStepInLibraryTrainingPlan: Scalars['Boolean'],
  /** Allows an admin to delete the master training plan. */
  deleteMasterTrainingPlan: Scalars['Boolean'],
  /** Allows an admin to delete the master workout. */
  deleteMasterWorkout: Scalars['Boolean'],
  /** Allows a trainer to delete the master workout in a custom training plan. */
  deleteMasterWorkoutInCustomTrainingPlan: Scalars['Boolean'],
  /** Allows a trainer to delete the master workout in a library training plan. */
  deleteMasterWorkoutInLibraryTrainingPlan: Scalars['Boolean'],
  deleteMuxVideoAsset: Scalars['Boolean'],
  deletePaywallConfig: Scalars['Boolean'],
  deleteProGroup?: Maybe<Scalars['String']>,
  deleteRecurringEvent: Scalars['Boolean'],
  deleteRecurringEventDefinition: Scalars['Boolean'],
  /** Used by clients to delete their own account from the platform. */
  deleteSelfFromPlatform?: Maybe<Scalars['String']>,
  deleteSocialMediaLink: Scalars['Boolean'],
  /** Deletes all data for a user asynchronously by connecting to every integration partner including our own database. */
  deleteUserFromPlatform?: Maybe<Scalars['String']>,
  deleteUserNotification: Scalars['Boolean'],
  deleteVideoMessageMediaUrl: Scalars['Boolean'],
  deleteZoomCall: Scalars['Boolean'],
  handleClientWorkoutStart: Scalars['Boolean'],
  includeWorkoutInStrengthMetrics?: Maybe<Scalars['Boolean']>,
  markAllUserNotificationRead: Scalars['Boolean'],
  markClientGymStepStatus?: Maybe<ClientGymStep>,
  markCoachTaskStatus?: Maybe<CoachTask>,
  markContentWeekItem?: Maybe<Scalars['Boolean']>,
  markUserNotificationRead: Scalars['Boolean'],
  markWorkoutCalendarItemStatus?: Maybe<WorkoutCalendarItem>,
  moveCalendarItem?: Maybe<CalendarDay>,
  /** Moves a Client Workout Template to a different Client Workout Group Template in the same Client Training Plan. */
  moveClientWorkoutTemplate?: Maybe<ClientTrainingPlan>,
  moveRecurringEvent: Scalars['Boolean'],
  moveSupersetChildToWorkout?: Maybe<Workout>,
  moveWorkoutChildToSuperset?: Maybe<Workout>,
  processPlusOrder?: Maybe<ClientSalesPackage>,
  processPremiumOrderFromWordpress?: Maybe<Client>,
  processProOrder?: Maybe<ClientSalesPackage>,
  processSupporterOrder?: Maybe<ClientSalesPackage>,
  /** Reassign Client to a new Trainer. trainerId represents the new Trainer. */
  reassignClient?: Maybe<Client>,
  regenerateStrengthScores?: Maybe<StrengthScoreDashboard>,
  /** Update an existing client user profile by user UUID. Partial updates not supported. */
  registerClientFromMobile?: Maybe<User>,
  removeLibraryTrainingPlanFromCategory?: Maybe<LibraryTrainingPlanCategory>,
  removePaywallFromPaywallConfig?: Maybe<PaywallConfig>,
  reorderChildrenInSuperset?: Maybe<Workout>,
  /** Allows a trainer to reorder exercises in the workout template. */
  reorderClientGymStepTemplates?: Maybe<ClientWorkoutTemplate>,
  /** Allows a client to reorder exercises in the workout calendar item. */
  reorderClientGymSteps?: Maybe<ClientWorkout>,
  /** Reorders workout groups in a client training plan. */
  reorderClientWorkoutGroupTemplates?: Maybe<ClientTrainingPlan>,
  /** Reorders client workout templates within a workout group. */
  reorderClientWorkoutTemplates?: Maybe<ClientTrainingPlan>,
  /** Allows an admin to reorder exercises in the master workout. */
  reorderMasterGymSteps?: Maybe<MasterWorkout>,
  /** Allows a trainer to reorder exercises in the master workout in a custom training plan. */
  reorderMasterGymStepsInCustomTrainingPlan?: Maybe<MasterWorkout>,
  /** Reorders master workouts in a master training plan. */
  reorderMasterWorkouts?: Maybe<TrainingPlan>,
  /** Reorders master workouts in a custom training plan. */
  reorderMasterWorkoutsInCustomTrainingPlan?: Maybe<CustomTrainingPlan>,
  /** Reorders master workouts in a library training plan. */
  reorderMasterWorkoutsInLibraryTrainingPlan?: Maybe<LibraryTrainingPlan>,
  /** Allows users to report a group message for violating community rules. */
  reportGroupMessage?: Maybe<Scalars['String']>,
  /** Will always replace the exerciseSets for a given ClientGymStep. */
  saveExerciseSets?: Maybe<ClientGymStep>,
  /** Attached a form video to an optional exercise set and send a message to the trainer or Pro Group. */
  saveFormVideo?: Maybe<FormVideo>,
  /** Saves aggregated data from Caliber-GoogleFit and process it in the Caliber platform. */
  saveGoogleFitSyncSet?: Maybe<HealthKitSyncSet>,
  /** Saves raw data from HealthKit for audit logging. */
  saveHealthKitRawData: Scalars['Boolean'],
  /** Saves aggregated data from Caliber-HealthKit and process it in the Caliber platform. */
  saveHealthKitSyncSet?: Maybe<HealthKitSyncSet>,
  saveLiveStatus?: Maybe<LiveStatus>,
  /** Saves aggregated data from Caliber-SamsungHealth and process it in the Caliber platform. */
  saveSamsungHealthSyncSet?: Maybe<HealthKitSyncSet>,
  sendStartWorkoutEventMessage: Scalars['Boolean'],
  setDefaultWorkoutGroupForClientTrainingPlan?: Maybe<ClientTrainingPlan>,
  sortTrainersInProGroup?: Maybe<Scalars['Map_UUID_IntegerScalar']>,
  /** Replaces given client gym step with a new client gym step in the same position of the Client Workout */
  substituteClientGymStep?: Maybe<ClientGymStep>,
  /** Substitutes a client gym step in a client workout template */
  substituteClientGymStepTemplate?: Maybe<ClientGymStepTemplate>,
  /** Switches the activation status of a custom training plan. */
  toggleCustomTrainingPlanState?: Maybe<CustomTrainingPlan>,
  triggerWorkoutReceivedNotification?: Maybe<WorkoutSharingNotification>,
  /** Update admin settings by settings ID. */
  updateAdminSettings?: Maybe<AdminSettings>,
  updateBodyStatCalendarItem?: Maybe<BodyStatCalendarItem>,
  updateCardioActivityCalendarItem?: Maybe<CardioActivityCalendarItem>,
  /** Mobile will append more consultation data. */
  updateClientConsultation?: Maybe<ClientConsultation>,
  updateClientEquipmentInventory?: Maybe<ClientEquipmentInventory>,
  /** Admins can update a Client from the Web app.  If a not empty password is input, it will update the password. */
  updateClientFromWeb?: Maybe<Client>,
  /** Allows a client to update the target of the exercise in the workout calendar item. */
  updateClientGymStep?: Maybe<ClientGymStep>,
  /** Allows a trainer to update the target of the exercise in the workout template. */
  updateClientGymStepTemplate?: Maybe<ClientGymStepTemplate>,
  /** Allows a client to propagate changes upstream from editing an exercise on the calendar */
  updateClientGymStepTemplateFromClientGymStep?: Maybe<ClientGymStepTemplate>,
  updateClientLastActiveDate?: Maybe<Scalars['String']>,
  updateClientPackageType?: Maybe<Scalars['String']>,
  /** Update an existing client's profilePicUrl. */
  updateClientProfileIconUrl?: Maybe<User>,
  updateClientSalesPackageCallsLeft?: Maybe<ClientSalesPackage>,
  updateClientSalesPackageEndDate?: Maybe<ClientSalesPackage>,
  /** Update client settings by settings ID. */
  updateClientSettings?: Maybe<ClientSettings>,
  updateClientStatus?: Maybe<Client>,
  /** Allows a trainer to update the name and description of the client training plan.  Always set both values. */
  updateClientTrainingPlan?: Maybe<ClientTrainingPlan>,
  /** 
 * Switches the activation status of a client training plan.  If one is already
   * marked active, it will be deactivated and the object sent in the method
   * signature will be activated.  If the item in the method signature is marked
   * active, it will be deactivated.
 */
  updateClientTrainingPlanState?: Maybe<ClientTrainingPlan>,
  /** You can remove clientGymSteps and add new exercises without targets. */
  updateClientWorkout?: Maybe<ClientWorkout>,
  updateClientWorkoutGroupTemplate?: Maybe<ClientWorkoutGroupTemplate>,
  /** Allows a trainer to update the title and instructions of the client workout template.  Always set both values. */
  updateClientWorkoutTemplate?: Maybe<ClientWorkoutTemplate>,
  /** 
 *  Make upstream changes to Workout Templates based on changes made to a Workout
   * Calendar Item and then propagate those changes to any scheduled calendar items.
 */
  updateClientWorkoutTemplateFromClientWorkout?: Maybe<ClientWorkoutTemplate>,
  /** Update an existing coach's profilePicUrl. */
  updateCoachProfileIconUrl?: Maybe<User>,
  updateCustomActivityCalendarItem?: Maybe<CustomActivityCalendarItem>,
  updateCustomExercise?: Maybe<ClientCustomExercise>,
  /** Allows a trainer to update the attributes of the custom training plan.  No partial updates. */
  updateCustomTrainingPlan?: Maybe<CustomTrainingPlan>,
  /** 
 * This will replace the Exercise in the database.  All data must be included. 
   * All associated media must be uploaded first.  Place all MediaUrl IDs in the
   * associated id field.  The API will perform the lookup.
 */
  updateExercise?: Maybe<Exercise>,
  updateFeatureFlag?: Maybe<FeatureFlag>,
  updateHabitCalendarItem?: Maybe<HabitCalendarItem>,
  updateLibraryTrainingPlan?: Maybe<LibraryTrainingPlan>,
  updateLibraryTrainingPlanCategory?: Maybe<LibraryTrainingPlanCategory>,
  /** Update manager settings by settings ID. */
  updateManagerSettings?: Maybe<ManagerSettings>,
  /** Allows an admin to update the target of the exercise in the master workout. */
  updateMasterGymStep?: Maybe<MasterGymStep>,
  /** Allows a trainer to update the target of the exercise in the master workout in a custom training plan. */
  updateMasterGymStepForCustomTrainingPlan?: Maybe<MasterGymStep>,
  /** Allows an admin to update the attributes of the master training plan.  No partial updates. */
  updateMasterTrainingPlan?: Maybe<MasterTrainingPlan>,
  /** Allows an admin to update the attributes of the master workout. Always set all values. */
  updateMasterWorkout?: Maybe<MasterWorkout>,
  /** Allows a trainer to update the attributes of the master workout. Always set all values. */
  updateMasterWorkoutInCustomTrainingPlan?: Maybe<MasterWorkout>,
  updateMinimumAppBuildNumber?: Maybe<AppRequirements>,
  /** Update an existing client's notes from a trainer. */
  updateNotes?: Maybe<User>,
  updatePaywallConfig?: Maybe<PaywallConfig>,
  updateProGroup?: Maybe<ProGroup>,
  /** Update an existing client's profilePicUrl. */
  updateProfileIconUrl?: Maybe<User>,
  updateProgressPhotoCalendarItem?: Maybe<ProgressPhotoCalendarItem>,
  updateRecurringEventDefinition?: Maybe<RecurringEventDefinition>,
  updateSmartWarmup?: Maybe<SmartWarmup>,
  updateSmartWarmupSet?: Maybe<SmartWarmupSet>,
  updateSuperset?: Maybe<GymStep>,
  updateSupersetChild?: Maybe<GymStep>,
  /** Default date mask is MM/dd/yyyy */
  updateTextGoal?: Maybe<TextGoal>,
  updateTrainerAvailability?: Maybe<Trainer>,
  updateTrainerCapacity?: Maybe<Trainer>,
  updateTrainerLastActiveDate?: Maybe<Scalars['String']>,
  /** Update trainer settings by settings ID. */
  updateTrainerSettings?: Maybe<TrainerSettings>,
  /** Update an existing user profile by user UUID. Partial updates not supported. */
  updateUserProfile?: Maybe<User>,
  updateUserTimeZone?: Maybe<User>,
  updateWeightGoal?: Maybe<WeightGoal>,
  updateWorkoutCalendarItem?: Maybe<WorkoutCalendarItem>,
  updateWorkoutDuration?: Maybe<WorkoutCalendarItem>,
};


/** Mutation root */
export type MutationAddChildToSupersetArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  exerciseId?: Maybe<Scalars['UUID']>,
  isLibraryTrainingPlan?: Maybe<Scalars['Boolean']>,
  position: Scalars['Int'],
  supersetGymStepId?: Maybe<Scalars['UUID']>,
  trainerId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationAddClientGymStepTemplateArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  clientWorkoutTemplateId?: Maybe<Scalars['UUID']>,
  exerciseId?: Maybe<Scalars['UUID']>,
  position?: Maybe<Scalars['Int']>
};


/** Mutation root */
export type MutationAddLibraryTrainingPlanToCategoryArgs = {
  libraryTrainingPlanCategoryId?: Maybe<Scalars['UUID']>,
  libraryTrainingPlanId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationAddMasterGymStepArgs = {
  exerciseId?: Maybe<Scalars['UUID']>,
  masterWorkoutId?: Maybe<Scalars['UUID']>,
  position?: Maybe<Scalars['Int']>
};


/** Mutation root */
export type MutationAddMasterGymStepToCustomTrainingPlanArgs = {
  customTrainingPlanId?: Maybe<Scalars['UUID']>,
  exerciseId?: Maybe<Scalars['UUID']>,
  masterWorkoutId?: Maybe<Scalars['UUID']>,
  position?: Maybe<Scalars['Int']>,
  trainerId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationAddMasterGymStepToLibraryTrainingPlanArgs = {
  exerciseId?: Maybe<Scalars['UUID']>,
  libraryTrainingPlanId?: Maybe<Scalars['UUID']>,
  masterWorkoutId?: Maybe<Scalars['UUID']>,
  position?: Maybe<Scalars['Int']>,
  trainerId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationAddNewClientWorkoutGroupTemplateArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  clientTrainingPlanId?: Maybe<Scalars['UUID']>,
  title?: Maybe<Scalars['String']>,
  workoutGroupType?: Maybe<WorkoutGroupType>
};


/** Mutation root */
export type MutationAddNewClientWorkoutTemplateArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  clientTrainingPlanId?: Maybe<Scalars['UUID']>,
  clientWorkoutGroupTemplateId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationAddNewMasterWorkoutArgs = {
  masterTrainingPlanId?: Maybe<Scalars['UUID']>,
  masterWorkoutGroupId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationAddNewMasterWorkoutToCustomTrainingPlanArgs = {
  customTrainingPlanId?: Maybe<Scalars['UUID']>,
  masterWorkoutGroupId?: Maybe<Scalars['UUID']>,
  trainerId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationAddNewMasterWorkoutToLibraryTrainingPlanArgs = {
  libraryTrainingPlanId?: Maybe<Scalars['UUID']>,
  trainerId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationAddPaywallToPaywallConfigArgs = {
  paywallConfigId: Scalars['UUID'],
  paywallLocation: PaywallLocation,
  paywallType?: Maybe<PaywallType>
};


/** Mutation root */
export type MutationAddTagsToProGroupArgs = {
  proGroupId: Scalars['UUID'],
  proGroupTagIds: Array<Maybe<Scalars['UUID']>>
};


/** Mutation root */
export type MutationAddTrainersToProGroupArgs = {
  proGroupId?: Maybe<Scalars['UUID']>,
  trainerIds?: Maybe<Array<Maybe<Scalars['UUID']>>>
};


/** Mutation root */
export type MutationAdminUpdateReferralOfferArgs = {
  referralOffer?: Maybe<ReferralOfferInput>
};


/** Mutation root */
export type MutationAssignCustomTrainingPlanArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  customTrainingPlanId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationAssignMasterTrainingPlanArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  masterTrainingPlanId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationAuthorizeCronometerRedirectUrlArgs = {
  clientId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationCalendarExportCsvArgs = {
  clientId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationCancelMuxVideoUploadArgs = {
  mediaUrlId: Scalars['UUID']
};


/** Mutation root */
export type MutationCancelSubscriptionArgs = {
  clientId: Scalars['UUID']
};


/** Mutation root */
export type MutationChangeClientEmailArgs = {
  clientId: Scalars['UUID'],
  newEmail: Scalars['String']
};


/** Mutation root */
export type MutationCloneCustomTrainingPlanArgs = {
  trainingPlanId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationCloneMasterTrainingPlanArgs = {
  trainingPlanId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationConvertGymStepToSupersetArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  gymStepId?: Maybe<Scalars['UUID']>,
  trainerId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationCopyClientWorkoutGroupTemplateArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  clientWorkoutGroupTemplateId?: Maybe<Scalars['UUID']>,
  destinationClientTrainingPlanId?: Maybe<Scalars['UUID']>,
  name?: Maybe<Scalars['String']>
};


/** Mutation root */
export type MutationCopyClientWorkoutTemplateArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  clientWorkoutTemplateId?: Maybe<Scalars['UUID']>,
  destinationClientWorkoutGroupTemplateId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationCopyMasterWorkoutGroupToClientWorkoutGroupTemplateArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  clientTrainingPlanId?: Maybe<Scalars['UUID']>,
  masterWorkoutGroupId?: Maybe<Scalars['UUID']>,
  title?: Maybe<Scalars['String']>,
  workoutGroupType?: Maybe<WorkoutGroupType>
};


/** Mutation root */
export type MutationCopyProGroupWorkoutToClientTrainingPlanArgs = {
  clientId: Scalars['UUID'],
  proGroupTrainingPlanId: Scalars['UUID'],
  setDefault: Scalars['Boolean']
};


/** Mutation root */
export type MutationCopySharedWorkoutGroupToClientTrainingPlanArgs = {
  clientId: Scalars['UUID'],
  sourceWorkoutGroupTemplateId: Scalars['UUID']
};


/** Mutation root */
export type MutationCopySharedWorkoutToClientWorkoutGroupArgs = {
  clientId: Scalars['UUID'],
  destinationWorkoutGroupId?: Maybe<Scalars['UUID']>,
  setAsDefaultWorkoutGroup?: Maybe<Scalars['Boolean']>,
  sourceWorkoutTemplateId: Scalars['UUID'],
  title?: Maybe<Scalars['String']>,
  workoutGroupType?: Maybe<WorkoutGroupType>
};


/** Mutation root */
export type MutationCopyTrainingPlanToClientsArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  clientIds?: Maybe<Array<Maybe<Scalars['UUID']>>>,
  trainingPlanId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationCopyTrainingPlanToTrainerArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  name: Scalars['String'],
  trainerId?: Maybe<Scalars['UUID']>,
  trainingPlanId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationCreateBodyStatCalendarItemArgs = {
  bodyMeasurementType?: Maybe<BodyMeasurementType>,
  bodyMeasurementValue?: Maybe<Scalars['Float']>,
  clientId: Scalars['UUID'],
  dayOfMonth: Scalars['Int'],
  month: Scalars['Int'],
  recurringEventDefinitionId?: Maybe<Scalars['UUID']>,
  status?: Maybe<CalendarItemStatusType>,
  year: Scalars['Int']
};


/** Mutation root */
export type MutationCreateBodyStatRecurringEventDefinitionArgs = {
  bodyMeasurementType: BodyMeasurementType,
  clientId: Scalars['UUID'],
  endDate?: Maybe<Scalars['LocalDate']>,
  rRule: Scalars['String'],
  startDate: Scalars['LocalDate']
};


/** Mutation root */
export type MutationCreateCaliberExerciseArgs = {
  exercise?: Maybe<CaliberExerciseInput>
};


/** Mutation root */
export type MutationCreateCardioActivityCalendarItemArgs = {
  calories?: Maybe<Scalars['Int']>,
  cardioActivityType: CardioActivityType,
  clientId?: Maybe<Scalars['UUID']>,
  dayOfMonth: Scalars['Int'],
  distance?: Maybe<Scalars['Float']>,
  level?: Maybe<Scalars['Int']>,
  month: Scalars['Int'],
  notes?: Maybe<Scalars['String']>,
  recurringEventDefinitionId?: Maybe<Scalars['UUID']>,
  speed?: Maybe<Scalars['Float']>,
  status?: Maybe<CalendarItemStatusType>,
  time?: Maybe<Scalars['Int']>,
  title?: Maybe<Scalars['String']>,
  year: Scalars['Int']
};


/** Mutation root */
export type MutationCreateCardioActivityRecurringEventDefinitionArgs = {
  cardioActivityType: CardioActivityType,
  clientId: Scalars['UUID'],
  endDate?: Maybe<Scalars['LocalDate']>,
  rRule: Scalars['String'],
  startDate: Scalars['LocalDate']
};


/** Mutation root */
export type MutationCreateClientConsultationArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  questionsAndAnswers?: Maybe<Array<Maybe<QuestionAndAnswerInput>>>
};


/** Mutation root */
export type MutationCreateClientFromWebArgs = {
  assignedProGroupId?: Maybe<Scalars['UUID']>,
  assignedTrainerId?: Maybe<Scalars['UUID']>,
  email: Scalars['String'],
  firstName: Scalars['String'],
  gender?: Maybe<GenderType>,
  height?: Maybe<Scalars['Float']>,
  lastName?: Maybe<Scalars['String']>,
  packageType: ClientPackageType,
  password: Scalars['String'],
  profileIconMediaUrlId?: Maybe<Scalars['UUID']>,
  weight?: Maybe<Scalars['Float']>
};


/** Mutation root */
export type MutationCreateClientWorkoutTemplateRecurringEventDefinitionArgs = {
  clientId: Scalars['UUID'],
  clientWorkoutTemplateId: Scalars['UUID'],
  endDate?: Maybe<Scalars['LocalDate']>,
  rRule: Scalars['String'],
  startDate: Scalars['LocalDate']
};


/** Mutation root */
export type MutationCreateCustomActivityCalendarItemArgs = {
  clientId: Scalars['UUID'],
  dayOfMonth: Scalars['Int'],
  month: Scalars['Int'],
  text?: Maybe<Scalars['String']>,
  year: Scalars['Int']
};


/** Mutation root */
export type MutationCreateCustomExerciseArgs = {
  clientId: Scalars['UUID'],
  equipmentType?: Maybe<ExerciseEquipmentType>,
  exerciseName: Scalars['String'],
  primaryMuscleGroups?: Maybe<Array<Maybe<MuscleGroupType>>>,
  targetBaseType: ExerciseTargetBaseType
};


/** Mutation root */
export type MutationCreateCustomTrainingPlanArgs = {
  duration?: Maybe<TrainingPlanDurationType>,
  frequency?: Maybe<TrainingPlanFrequencyType>,
  gender?: Maybe<GenderType>,
  planVersion?: Maybe<TrainingPlanVersionType>,
  proGroupId?: Maybe<Scalars['UUID']>,
  program?: Maybe<TrainingPlanProgramType>,
  repRange?: Maybe<TrainingPlanRepRangeType>,
  title: Scalars['String'],
  trainerId?: Maybe<Scalars['UUID']>,
  type?: Maybe<TrainingPlanType>
};


/** Mutation root */
export type MutationCreateEmptySupersetArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  position: Scalars['Int'],
  trainerId?: Maybe<Scalars['UUID']>,
  workoutId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationCreateFreestyleWorkoutCalendarItemArgs = {
  clientId: Scalars['UUID'],
  dayOfMonth: Scalars['Int'],
  month: Scalars['Int'],
  year: Scalars['Int']
};


/** Mutation root */
export type MutationCreateHabitCalendarItemArgs = {
  clientId: Scalars['UUID'],
  dayOfMonth: Scalars['Int'],
  habitType: HabitType,
  month: Scalars['Int'],
  quantity: Scalars['Int'],
  recurringEventDefinitionId?: Maybe<Scalars['UUID']>,
  year: Scalars['Int']
};


/** Mutation root */
export type MutationCreateHabitRecurringEventDefinitionArgs = {
  clientId: Scalars['UUID'],
  endDate?: Maybe<Scalars['LocalDate']>,
  habitType: HabitType,
  rRule: Scalars['String'],
  startDate: Scalars['LocalDate']
};


/** Mutation root */
export type MutationCreateLibraryTrainingPlanArgs = {
  name: Scalars['String'],
  trainerId: Scalars['UUID']
};


/** Mutation root */
export type MutationCreateLibraryTrainingPlanCategoryArgs = {
  description?: Maybe<Scalars['String']>,
  name: Scalars['String']
};


/** Mutation root */
export type MutationCreateMasterTrainingPlanArgs = {
  duration?: Maybe<TrainingPlanDurationType>,
  frequency?: Maybe<TrainingPlanFrequencyType>,
  gender?: Maybe<GenderType>,
  planVersion?: Maybe<TrainingPlanVersionType>,
  program?: Maybe<TrainingPlanProgramType>,
  repRange?: Maybe<TrainingPlanRepRangeType>,
  type?: Maybe<TrainingPlanType>
};


/** Mutation root */
export type MutationCreateMuxVideoGroupChannelUploadArgs = {
  clientId: Scalars['UUID'],
  corsOrigin: Scalars['String'],
  userId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationCreateMuxVideoMessageUploadArgs = {
  clientId: Scalars['UUID'],
  corsOrigin: Scalars['String']
};


/** Mutation root */
export type MutationCreateMuxVideoUploadArgs = {
  corsOrigin: Scalars['String'],
  fileUploadType: FileUploadType
};


/** Mutation root */
export type MutationCreateNewClientTrainingPlanArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  name?: Maybe<Scalars['String']>
};


/** Mutation root */
export type MutationCreatePaywallConfigArgs = {
  enabled: Scalars['Boolean'],
  paywalls?: Maybe<Array<Maybe<PaywallInput>>>,
  trackingId: Scalars['String']
};


/** Mutation root */
export type MutationCreateProGroupArgs = {
  mainDescription?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  sendbirdChannelUrl?: Maybe<Scalars['String']>,
  sortOrder?: Maybe<Scalars['Int']>,
  trainerIds?: Maybe<Array<Maybe<Scalars['UUID']>>>,
  workoutDuration?: Maybe<Scalars['String']>,
  workoutDurationDetail?: Maybe<Scalars['String']>,
  workoutEquipment?: Maybe<Scalars['String']>,
  workoutEquipmentDetail?: Maybe<Scalars['String']>,
  workoutExperienceLevel?: Maybe<Scalars['String']>,
  workoutExperienceLevelDetail?: Maybe<Scalars['String']>,
  workoutFrequency?: Maybe<Scalars['String']>,
  workoutFrequencyDetail?: Maybe<Scalars['String']>
};


/** Mutation root */
export type MutationCreateProGroupTagArgs = {
  color: Scalars['String'],
  name: Scalars['String'],
  sortOrder: Scalars['Int']
};


/** Mutation root */
export type MutationCreateProgressPhotoCalendarItemArgs = {
  backPhotoMediaUrlId?: Maybe<Scalars['UUID']>,
  clientId: Scalars['UUID'],
  dayOfMonth: Scalars['Int'],
  frontPhotoMediaUrlId?: Maybe<Scalars['UUID']>,
  month: Scalars['Int'],
  recurringEventDefinitionId?: Maybe<Scalars['UUID']>,
  sidePhotoMediaUrlId?: Maybe<Scalars['UUID']>,
  year: Scalars['Int']
};


/** Mutation root */
export type MutationCreateProgressPhotoRecurringEventDefinitionArgs = {
  clientId: Scalars['UUID'],
  endDate?: Maybe<Scalars['LocalDate']>,
  rRule: Scalars['String'],
  startDate: Scalars['LocalDate']
};


/** Mutation root */
export type MutationCreateTextGoalArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  targetDate?: Maybe<Scalars['String']>,
  text?: Maybe<Scalars['String']>
};


/** Mutation root */
export type MutationCreateUpdateClientExerciseNoteArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  exerciseId?: Maybe<Scalars['UUID']>,
  note?: Maybe<Scalars['String']>
};


/** Mutation root */
export type MutationCreateUpdateClientHabitTargetArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  habitType?: Maybe<HabitType>,
  target?: Maybe<Scalars['Int']>
};


/** Mutation root */
export type MutationCreateUpdateClientNutritionTargetArgs = {
  calories?: Maybe<Scalars['Int']>,
  carbs?: Maybe<Scalars['Int']>,
  clientId?: Maybe<Scalars['UUID']>,
  fat?: Maybe<Scalars['Int']>,
  isIntermittentFasting?: Maybe<Scalars['Boolean']>,
  isIntermittentFastingActiveDate?: Maybe<Scalars['LocalDate']>,
  isLogging?: Maybe<Scalars['Boolean']>,
  isLoggingActiveDate?: Maybe<Scalars['LocalDate']>,
  macroTargetsActiveDate?: Maybe<Scalars['LocalDate']>,
  mealPlanMediaUrlId?: Maybe<Scalars['UUID']>,
  phase?: Maybe<NutritionTargetPhaseType>,
  phaseActiveDate?: Maybe<Scalars['LocalDate']>,
  protein?: Maybe<Scalars['Int']>,
  protocol?: Maybe<NutritionTargetProtocolType>,
  protocolActiveDate?: Maybe<Scalars['LocalDate']>
};


/** Mutation root */
export type MutationCreateUpdateClientProfileArgs = {
  bio?: Maybe<Scalars['String']>,
  clientId?: Maybe<Scalars['UUID']>,
  displayName?: Maybe<Scalars['String']>,
  endDate?: Maybe<Scalars['LocalDate']>,
  hideAge: Scalars['Boolean'],
  hideGender: Scalars['Boolean'],
  hideHeight: Scalars['Boolean'],
  hideLocation: Scalars['Boolean'],
  hideStrengthMetrics: Scalars['Boolean'],
  hideWeight: Scalars['Boolean'],
  hideWorkoutActivity: Scalars['Boolean'],
  startDate?: Maybe<Scalars['LocalDate']>
};


/** Mutation root */
export type MutationCreateUpdateClientTrainingPlanFromWizardArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  duration?: Maybe<TrainingPlanDurationType>,
  experienceLevel?: Maybe<ExperienceLevelType>,
  frequency?: Maybe<TrainingPlanFrequencyType>,
  plan?: Maybe<TrainingPlanType>
};


/** Mutation root */
export type MutationCreateUpdateCoachProfileArgs = {
  bio?: Maybe<Scalars['String']>,
  certifications?: Maybe<Scalars['String']>,
  education?: Maybe<Scalars['String']>,
  funFacts?: Maybe<Scalars['String']>,
  photos?: Maybe<Array<Maybe<Scalars['UUID']>>>,
  trainerId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationCreateUpdateFreestyleClientWorkoutGroupTemplateArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  clientWorkoutId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationCreateUpdateSocialMediaLinkArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  socialMedia?: Maybe<SocialMediaType>,
  username?: Maybe<Scalars['String']>
};


/** Mutation root */
export type MutationCreateUpdateZoomCallArgs = {
  calendarItemStatusType?: Maybe<CalendarItemStatusType>,
  clientId?: Maybe<Scalars['UUID']>,
  date?: Maybe<Scalars['OffsetDateTime']>,
  zoomCallId?: Maybe<Scalars['UUID']>,
  zoomCallType?: Maybe<ZoomCallType>
};


/** Mutation root */
export type MutationCreateUserProfileArgs = {
  assignedManagerId?: Maybe<Scalars['UUID']>,
  assignedProGroupId?: Maybe<Scalars['UUID']>,
  assignedTrainerId?: Maybe<Scalars['UUID']>,
  birthDate?: Maybe<Scalars['String']>,
  bodyWeight?: Maybe<Scalars['Float']>,
  city?: Maybe<Scalars['String']>,
  clientAuthId?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  dateMask?: Maybe<Scalars['String']>,
  defaultPassword?: Maybe<Scalars['String']>,
  email: Scalars['String'],
  firstName?: Maybe<Scalars['String']>,
  gender?: Maybe<GenderType>,
  height?: Maybe<Scalars['Float']>,
  lastName?: Maybe<Scalars['String']>,
  packageType?: Maybe<ClientPackageType>,
  phone?: Maybe<Scalars['String']>,
  profileIconMediaUrlId?: Maybe<Scalars['UUID']>,
  questionsAndAnswers?: Maybe<Array<Maybe<QuestionAndAnswerInput>>>,
  state?: Maybe<Scalars['String']>,
  type: UserType
};


/** Mutation root */
export type MutationCreateWorkoutCalendarItemArgs = {
  clientId: Scalars['UUID'],
  clientWorkoutTemplateId: Scalars['UUID'],
  dayOfMonth: Scalars['Int'],
  month: Scalars['Int'],
  recurringEventDefinitionId?: Maybe<Scalars['UUID']>,
  year: Scalars['Int']
};


/** Mutation root */
export type MutationDeauthorizeCronometerArgs = {
  clientId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationDeleteCalendarItemArgs = {
  calendarItemId?: Maybe<Scalars['UUID']>,
  clientId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationDeleteChildFromSupersetArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  isLibraryTrainingPlan?: Maybe<Scalars['Boolean']>,
  supersetChildGymStepId?: Maybe<Scalars['UUID']>,
  trainerId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationDeleteClientEquipmentInventoryArgs = {
  clientId: Scalars['UUID'],
  weightUnitType: WeightUnitType
};


/** Mutation root */
export type MutationDeleteClientGymStepTemplateArgs = {
  clientGymStepTemplateId?: Maybe<Scalars['UUID']>,
  clientId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationDeleteClientTrainingPlanArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  clientTrainingPlanId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationDeleteClientWorkoutGroupTemplateArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  clientWorkoutGroupTemplateId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationDeleteClientWorkoutTemplateArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  clientWorkoutTemplateId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationDeleteCoachTaskArgs = {
  coachTaskId?: Maybe<Scalars['UUID']>,
  trainerId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationDeleteCustomExerciseArgs = {
  clientId: Scalars['UUID'],
  customExerciseId: Scalars['UUID']
};


/** Mutation root */
export type MutationDeleteCustomTrainingPlanArgs = {
  customTrainingPlanId?: Maybe<Scalars['UUID']>,
  trainerId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationDeleteExerciseArgs = {
  exerciseId?: Maybe<Scalars['UUID']>,
  force?: Maybe<Scalars['Boolean']>
};


/** Mutation root */
export type MutationDeleteFormVideoArgs = {
  clientId: Scalars['UUID'],
  formVideoId: Scalars['UUID']
};


/** Mutation root */
export type MutationDeleteHabitTargetArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  habitTargetId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationDeleteLibraryTrainingPlanArgs = {
  libraryTrainingPlanId: Scalars['UUID'],
  trainerId: Scalars['UUID']
};


/** Mutation root */
export type MutationDeleteLibraryTrainingPlanCategoryArgs = {
  libraryTrainingPlanCategoryId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationDeleteMasterGymStepArgs = {
  masterGymStepId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationDeleteMasterGymStepInCustomTrainingPlanArgs = {
  customTrainingPlanId?: Maybe<Scalars['UUID']>,
  masterGymStepId?: Maybe<Scalars['UUID']>,
  trainerId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationDeleteMasterGymStepInLibraryTrainingPlanArgs = {
  libraryTrainingPlanId?: Maybe<Scalars['UUID']>,
  masterGymStepId?: Maybe<Scalars['UUID']>,
  trainerId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationDeleteMasterTrainingPlanArgs = {
  masterTrainingPlanId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationDeleteMasterWorkoutArgs = {
  masterWorkoutId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationDeleteMasterWorkoutInCustomTrainingPlanArgs = {
  customTrainingPlanId?: Maybe<Scalars['UUID']>,
  masterWorkoutId?: Maybe<Scalars['UUID']>,
  trainerId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationDeleteMasterWorkoutInLibraryTrainingPlanArgs = {
  libraryTrainingPlanId?: Maybe<Scalars['UUID']>,
  masterWorkoutId?: Maybe<Scalars['UUID']>,
  trainerId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationDeleteMuxVideoAssetArgs = {
  muxAssetId: Scalars['String']
};


/** Mutation root */
export type MutationDeletePaywallConfigArgs = {
  paywallConfigId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationDeleteProGroupArgs = {
  proGroupId: Scalars['UUID']
};


/** Mutation root */
export type MutationDeleteRecurringEventArgs = {
  clientId: Scalars['UUID'],
  date: Scalars['LocalDate'],
  notifyWebsocket?: Maybe<Scalars['Boolean']>,
  recurringEventDefinitionId: Scalars['UUID']
};


/** Mutation root */
export type MutationDeleteRecurringEventDefinitionArgs = {
  clientId: Scalars['UUID'],
  recurringEventDefinitionId: Scalars['UUID']
};


/** Mutation root */
export type MutationDeleteSelfFromPlatformArgs = {
  clientId: Scalars['UUID'],
  enteredFirstName: Scalars['String']
};


/** Mutation root */
export type MutationDeleteSocialMediaLinkArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  socialMedia?: Maybe<SocialMediaType>
};


/** Mutation root */
export type MutationDeleteUserFromPlatformArgs = {
  userId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationDeleteUserNotificationArgs = {
  notificationId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationDeleteVideoMessageMediaUrlArgs = {
  clientId: Scalars['UUID'],
  mediaUrlId: Scalars['UUID']
};


/** Mutation root */
export type MutationDeleteZoomCallArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  zoomCallId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationHandleClientWorkoutStartArgs = {
  clientId: Scalars['UUID'],
  workoutCalendarItemId: Scalars['UUID']
};


/** Mutation root */
export type MutationIncludeWorkoutInStrengthMetricsArgs = {
  clientId: Scalars['UUID'],
  includeInStrengthMetrics: Scalars['Boolean'],
  workoutCalendarItemId: Scalars['UUID']
};


/** Mutation root */
export type MutationMarkAllUserNotificationReadArgs = {
  userId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationMarkClientGymStepStatusArgs = {
  clientGymStepId: Scalars['UUID'],
  clientId?: Maybe<Scalars['UUID']>,
  status: ClientGymStepStatusType
};


/** Mutation root */
export type MutationMarkCoachTaskStatusArgs = {
  coachTaskId?: Maybe<Scalars['UUID']>,
  status?: Maybe<CoachTaskStatusType>,
  trainerId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationMarkContentWeekItemArgs = {
  clientId: Scalars['UUID'],
  contentWeekItemId: Scalars['String'],
  isRead: Scalars['Boolean']
};


/** Mutation root */
export type MutationMarkUserNotificationReadArgs = {
  userId?: Maybe<Scalars['UUID']>,
  userNotificationId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationMarkWorkoutCalendarItemStatusArgs = {
  calendarItemId: Scalars['UUID'],
  clientId?: Maybe<Scalars['UUID']>,
  status: CalendarItemStatusType
};


/** Mutation root */
export type MutationMoveCalendarItemArgs = {
  calendarItemId?: Maybe<Scalars['UUID']>,
  clientId?: Maybe<Scalars['UUID']>,
  date?: Maybe<Scalars['LocalDate']>
};


/** Mutation root */
export type MutationMoveClientWorkoutTemplateArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  clientWorkoutTemplateId?: Maybe<Scalars['UUID']>,
  clientWorkoutTemplateIds?: Maybe<Array<Maybe<Scalars['UUID']>>>,
  destinationClientWorkoutGroupTemplateId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationMoveRecurringEventArgs = {
  clientId: Scalars['UUID'],
  currentDate: Scalars['LocalDate'],
  newDate: Scalars['LocalDate'],
  recurringEventDefinitionId: Scalars['UUID']
};


/** Mutation root */
export type MutationMoveSupersetChildToWorkoutArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  position: Scalars['Int'],
  supersetChildGymStepId?: Maybe<Scalars['UUID']>,
  trainerId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationMoveWorkoutChildToSupersetArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  destinationSupersetId?: Maybe<Scalars['UUID']>,
  gymStepId?: Maybe<Scalars['UUID']>,
  position: Scalars['Int'],
  trainerId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationProcessPlusOrderArgs = {
  clientId: Scalars['UUID']
};


/** Mutation root */
export type MutationProcessPremiumOrderFromWordpressArgs = {
  assignedTrainerId?: Maybe<Scalars['UUID']>,
  email: Scalars['String'],
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  questionsAndAnswers?: Maybe<Array<Maybe<QuestionAndAnswerInput>>>,
  strategyCalls: Scalars['Int']
};


/** Mutation root */
export type MutationProcessProOrderArgs = {
  clientId: Scalars['UUID'],
  proGroupId: Scalars['UUID']
};


/** Mutation root */
export type MutationProcessSupporterOrderArgs = {
  clientId: Scalars['UUID']
};


/** Mutation root */
export type MutationReassignClientArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  trainerId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationRegenerateStrengthScoresArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  reindex?: Maybe<Scalars['Boolean']>
};


/** Mutation root */
export type MutationRegisterClientFromMobileArgs = {
  birthDate?: Maybe<Scalars['LocalDate']>,
  city?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  deviceType?: Maybe<DeviceType>,
  email?: Maybe<Scalars['String']>,
  firstName?: Maybe<Scalars['String']>,
  gender?: Maybe<GenderType>,
  height?: Maybe<Scalars['Float']>,
  isConsultationCompleted?: Maybe<Scalars['Boolean']>,
  isProfileCompleted?: Maybe<Scalars['Boolean']>,
  lastName?: Maybe<Scalars['String']>,
  state?: Maybe<Scalars['String']>,
  unitHeight?: Maybe<UnitType>,
  unitWeight?: Maybe<UnitType>,
  userId: Scalars['UUID'],
  weight?: Maybe<Scalars['Float']>
};


/** Mutation root */
export type MutationRemoveLibraryTrainingPlanFromCategoryArgs = {
  libraryTrainingPlanCategoryId?: Maybe<Scalars['UUID']>,
  libraryTrainingPlanId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationRemovePaywallFromPaywallConfigArgs = {
  paywallConfigId: Scalars['UUID'],
  paywallLocation: PaywallLocation
};


/** Mutation root */
export type MutationReorderChildrenInSupersetArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  supersetChildGymStepIds?: Maybe<Array<Maybe<Scalars['UUID']>>>,
  supersetGymStepId?: Maybe<Scalars['UUID']>,
  trainerId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationReorderClientGymStepTemplatesArgs = {
  clientGymStepTemplateIds?: Maybe<Array<Maybe<Scalars['UUID']>>>,
  clientId?: Maybe<Scalars['UUID']>,
  clientWorkoutTemplateId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationReorderClientGymStepsArgs = {
  clientGymStepIds?: Maybe<Array<Maybe<Scalars['UUID']>>>,
  clientId?: Maybe<Scalars['UUID']>,
  clientWorkoutId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationReorderClientWorkoutGroupTemplatesArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  clientTrainingPlanId?: Maybe<Scalars['UUID']>,
  clientWorkoutGroupTemplateIds?: Maybe<Array<Maybe<Scalars['UUID']>>>
};


/** Mutation root */
export type MutationReorderClientWorkoutTemplatesArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  clientTrainingPlanId?: Maybe<Scalars['UUID']>,
  clientWorkoutGroupTemplateId?: Maybe<Scalars['UUID']>,
  clientWorkoutTemplateIds?: Maybe<Array<Maybe<Scalars['UUID']>>>
};


/** Mutation root */
export type MutationReorderMasterGymStepsArgs = {
  masterGymStepIds?: Maybe<Array<Maybe<Scalars['UUID']>>>,
  masterWorkoutId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationReorderMasterGymStepsInCustomTrainingPlanArgs = {
  customTrainingPlanId?: Maybe<Scalars['UUID']>,
  masterGymStepIds?: Maybe<Array<Maybe<Scalars['UUID']>>>,
  masterWorkoutId?: Maybe<Scalars['UUID']>,
  trainerId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationReorderMasterWorkoutsArgs = {
  masterTrainingPlanId?: Maybe<Scalars['UUID']>,
  masterWorkoutIds?: Maybe<Array<Maybe<Scalars['UUID']>>>
};


/** Mutation root */
export type MutationReorderMasterWorkoutsInCustomTrainingPlanArgs = {
  customTrainingPlanId?: Maybe<Scalars['UUID']>,
  masterWorkoutIds?: Maybe<Array<Maybe<Scalars['UUID']>>>,
  trainerId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationReorderMasterWorkoutsInLibraryTrainingPlanArgs = {
  libraryTrainingPlanId?: Maybe<Scalars['UUID']>,
  masterWorkoutIds?: Maybe<Array<Maybe<Scalars['UUID']>>>,
  trainerId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationReportGroupMessageArgs = {
  groupChannelUrl: Scalars['String'],
  note?: Maybe<Scalars['String']>,
  reportedMessageId: Scalars['Long'],
  userId: Scalars['UUID']
};


/** Mutation root */
export type MutationSaveExerciseSetsArgs = {
  clientGymStepId: Scalars['UUID'],
  clientId?: Maybe<Scalars['UUID']>,
  exerciseSets?: Maybe<Array<Maybe<ExerciseSetInput>>>
};


/** Mutation root */
export type MutationSaveFormVideoArgs = {
  clientGymStepId: Scalars['UUID'],
  clientId: Scalars['UUID'],
  formVideoMediaUrl?: Maybe<Scalars['UUID']>,
  setNumber?: Maybe<Scalars['Int']>
};


/** Mutation root */
export type MutationSaveGoogleFitSyncSetArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  healthKitRawDataId?: Maybe<Scalars['UUID']>,
  healthKitSyncSet?: Maybe<HealthKitSyncSetInput>
};


/** Mutation root */
export type MutationSaveHealthKitRawDataArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  rawData?: Maybe<Scalars['String']>
};


/** Mutation root */
export type MutationSaveHealthKitSyncSetArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  healthKitRawDataId?: Maybe<Scalars['UUID']>,
  healthKitSyncSet?: Maybe<HealthKitSyncSetInput>
};


/** Mutation root */
export type MutationSaveLiveStatusArgs = {
  liveStatus?: Maybe<LiveStatusInput>
};


/** Mutation root */
export type MutationSaveSamsungHealthSyncSetArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  healthKitSyncSet?: Maybe<HealthKitSyncSetInput>
};


/** Mutation root */
export type MutationSendStartWorkoutEventMessageArgs = {
  clientId: Scalars['UUID'],
  workoutTitle: Scalars['String']
};


/** Mutation root */
export type MutationSetDefaultWorkoutGroupForClientTrainingPlanArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  clientWorkoutGroupTemplateId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationSortTrainersInProGroupArgs = {
  proGroupId?: Maybe<Scalars['UUID']>,
  trainerSortOrder?: Maybe<Array<Maybe<Scalars['UUID']>>>
};


/** Mutation root */
export type MutationSubstituteClientGymStepArgs = {
  clientGymStepId?: Maybe<Scalars['UUID']>,
  clientId?: Maybe<Scalars['UUID']>,
  exerciseId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationSubstituteClientGymStepTemplateArgs = {
  clientGymStepTemplateId: Scalars['UUID'],
  clientId: Scalars['UUID'],
  exerciseId: Scalars['UUID']
};


/** Mutation root */
export type MutationToggleCustomTrainingPlanStateArgs = {
  customTrainingPlanId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationTriggerWorkoutReceivedNotificationArgs = {
  notificationType: WorkoutSharingNotificationType,
  templateId: Scalars['UUID'],
  userId: Scalars['UUID']
};


/** Mutation root */
export type MutationUpdateAdminSettingsArgs = {
  adminSettings?: Maybe<AdminSettingsInput>
};


/** Mutation root */
export type MutationUpdateBodyStatCalendarItemArgs = {
  bodyMeasurementType?: Maybe<BodyMeasurementType>,
  bodyMeasurementValue: Scalars['Float'],
  calendarItemId: Scalars['UUID'],
  clientId?: Maybe<Scalars['UUID']>,
  status?: Maybe<CalendarItemStatusType>
};


/** Mutation root */
export type MutationUpdateCardioActivityCalendarItemArgs = {
  calendarItemId: Scalars['UUID'],
  calories?: Maybe<Scalars['Int']>,
  cardioActivityType: CardioActivityType,
  clientId?: Maybe<Scalars['UUID']>,
  distance?: Maybe<Scalars['Float']>,
  level?: Maybe<Scalars['Int']>,
  notes?: Maybe<Scalars['String']>,
  speed?: Maybe<Scalars['Float']>,
  status?: Maybe<CalendarItemStatusType>,
  time?: Maybe<Scalars['Int']>,
  title?: Maybe<Scalars['String']>
};


/** Mutation root */
export type MutationUpdateClientConsultationArgs = {
  consultationId?: Maybe<Scalars['UUID']>,
  questionsAndAnswers?: Maybe<Array<Maybe<QuestionAndAnswerInput>>>
};


/** Mutation root */
export type MutationUpdateClientEquipmentInventoryArgs = {
  barbellWeight: Scalars['Float'],
  clientId: Scalars['UUID'],
  plateWeightQuantities: Array<Maybe<PlateWeightQuantityInput>>,
  weightUnitType: WeightUnitType
};


/** Mutation root */
export type MutationUpdateClientFromWebArgs = {
  assignedProGroupId?: Maybe<Scalars['UUID']>,
  assignedSalesRepId?: Maybe<Scalars['UUID']>,
  assignedTrainerId?: Maybe<Scalars['UUID']>,
  clientId: Scalars['UUID'],
  email: Scalars['String'],
  firstName: Scalars['String'],
  gender?: Maybe<GenderType>,
  height?: Maybe<Scalars['Float']>,
  lastName?: Maybe<Scalars['String']>,
  packageType: ClientPackageType,
  password?: Maybe<Scalars['String']>,
  profileIconMediaUrlId?: Maybe<Scalars['UUID']>,
  status?: Maybe<UserStatusType>
};


/** Mutation root */
export type MutationUpdateClientGymStepArgs = {
  clientGymStepId?: Maybe<Scalars['UUID']>,
  clientId?: Maybe<Scalars['UUID']>,
  generateSmartWarmupSets?: Maybe<Scalars['Boolean']>,
  restPeriod?: Maybe<Scalars['Int']>,
  smartWarmupSetsCount?: Maybe<Scalars['Int']>,
  target?: Maybe<Scalars['String']>,
  targetRepsLowerBound?: Maybe<Scalars['Int']>,
  targetRepsUpperBound?: Maybe<Scalars['Int']>,
  targetSets?: Maybe<Scalars['Int']>,
  targetTime?: Maybe<Scalars['Int']>
};


/** Mutation root */
export type MutationUpdateClientGymStepTemplateArgs = {
  clientGymStepTemplateId?: Maybe<Scalars['UUID']>,
  clientId?: Maybe<Scalars['UUID']>,
  generateSmartWarmupSets?: Maybe<Scalars['Boolean']>,
  hideWeightForExerciseSets?: Maybe<Scalars['Boolean']>,
  restPeriod?: Maybe<Scalars['Int']>,
  smartWarmupSetsCount?: Maybe<Scalars['Int']>,
  supersetTag?: Maybe<SupersetTagType>,
  target?: Maybe<Scalars['String']>,
  targetRepsLowerBound?: Maybe<Scalars['Int']>,
  targetRepsUpperBound?: Maybe<Scalars['Int']>,
  targetSets?: Maybe<Scalars['Int']>,
  targetTime?: Maybe<Scalars['Int']>,
  targetWeight?: Maybe<Scalars['Float']>
};


/** Mutation root */
export type MutationUpdateClientGymStepTemplateFromClientGymStepArgs = {
  clientGymStepId?: Maybe<Scalars['UUID']>,
  clientId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationUpdateClientPackageTypeArgs = {
  assignedProGroupId?: Maybe<Scalars['UUID']>,
  assignedTrainerId?: Maybe<Scalars['UUID']>,
  clientId?: Maybe<Scalars['UUID']>,
  packageType?: Maybe<ClientPackageType>
};


/** Mutation root */
export type MutationUpdateClientProfileIconUrlArgs = {
  clientId: Scalars['UUID'],
  mediaUrlId: Scalars['UUID']
};


/** Mutation root */
export type MutationUpdateClientSalesPackageCallsLeftArgs = {
  callsLeft?: Maybe<Scalars['Int']>,
  clientId?: Maybe<Scalars['UUID']>,
  increment?: Maybe<Scalars['Boolean']>
};


/** Mutation root */
export type MutationUpdateClientSalesPackageEndDateArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  packageEndDate?: Maybe<Scalars['LocalDate']>
};


/** Mutation root */
export type MutationUpdateClientSettingsArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  clientSettings?: Maybe<ClientSettingsInput>
};


/** Mutation root */
export type MutationUpdateClientStatusArgs = {
  clientId: Scalars['UUID'],
  status?: Maybe<UserStatusType>,
  wooCommerceDates?: Maybe<Array<Maybe<WooCommerceDateInput>>>
};


/** Mutation root */
export type MutationUpdateClientTrainingPlanArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  clientTrainingPlanId?: Maybe<Scalars['UUID']>,
  name?: Maybe<Scalars['String']>
};


/** Mutation root */
export type MutationUpdateClientTrainingPlanStateArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  clientTrainingPlanId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationUpdateClientWorkoutArgs = {
  clientGymStepIds?: Maybe<Array<Maybe<Scalars['UUID']>>>,
  clientId?: Maybe<Scalars['UUID']>,
  clientWorkoutId: Scalars['UUID'],
  exerciseIds?: Maybe<Array<Maybe<Scalars['UUID']>>>
};


/** Mutation root */
export type MutationUpdateClientWorkoutGroupTemplateArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  clientWorkoutGroupTemplateId?: Maybe<Scalars['UUID']>,
  title?: Maybe<Scalars['String']>,
  workoutGroupType?: Maybe<WorkoutGroupType>
};


/** Mutation root */
export type MutationUpdateClientWorkoutTemplateArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  clientWorkoutTemplateId?: Maybe<Scalars['UUID']>,
  instructions?: Maybe<Scalars['String']>,
  thumbnailImageMediaUrlId?: Maybe<Scalars['UUID']>,
  title?: Maybe<Scalars['String']>
};


/** Mutation root */
export type MutationUpdateClientWorkoutTemplateFromClientWorkoutArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  clientWorkoutId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationUpdateCoachProfileIconUrlArgs = {
  mediaUrlId: Scalars['UUID'],
  trainerId: Scalars['UUID']
};


/** Mutation root */
export type MutationUpdateCustomActivityCalendarItemArgs = {
  calendarItemId: Scalars['UUID'],
  clientId?: Maybe<Scalars['UUID']>,
  text?: Maybe<Scalars['String']>
};


/** Mutation root */
export type MutationUpdateCustomExerciseArgs = {
  clientId: Scalars['UUID'],
  customExerciseId: Scalars['UUID'],
  equipmentType?: Maybe<ExerciseEquipmentType>,
  exerciseName?: Maybe<Scalars['String']>,
  primaryMuscleGroups?: Maybe<Array<Maybe<MuscleGroupType>>>
};


/** Mutation root */
export type MutationUpdateCustomTrainingPlanArgs = {
  customTrainingPlanId?: Maybe<Scalars['UUID']>,
  name?: Maybe<Scalars['String']>,
  trainerId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationUpdateExerciseArgs = {
  exercise: ExerciseInput
};


/** Mutation root */
export type MutationUpdateFeatureFlagArgs = {
  deviceType: DeviceType,
  enabled: Scalars['Boolean'],
  feature: Feature,
  minAppBuildNumber: Scalars['Int']
};


/** Mutation root */
export type MutationUpdateHabitCalendarItemArgs = {
  calendarItemId: Scalars['UUID'],
  clientId?: Maybe<Scalars['UUID']>,
  quantity: Scalars['Int']
};


/** Mutation root */
export type MutationUpdateLibraryTrainingPlanArgs = {
  active?: Maybe<Scalars['Boolean']>,
  daysPerWeek?: Maybe<Scalars['Int']>,
  durationMinutes?: Maybe<Scalars['Int']>,
  genderType?: Maybe<GenderType>,
  libraryTrainingPlanId: Scalars['UUID'],
  name?: Maybe<Scalars['String']>,
  splitType?: Maybe<SplitType>,
  trainerId: Scalars['UUID']
};


/** Mutation root */
export type MutationUpdateLibraryTrainingPlanCategoryArgs = {
  active?: Maybe<Scalars['Boolean']>,
  description?: Maybe<Scalars['String']>,
  gender?: Maybe<GenderType>,
  libraryTrainingPlanCategoryId: Scalars['UUID'],
  name?: Maybe<Scalars['String']>
};


/** Mutation root */
export type MutationUpdateManagerSettingsArgs = {
  managerSettings?: Maybe<ManagerSettingsInput>
};


/** Mutation root */
export type MutationUpdateMasterGymStepArgs = {
  generateSmartWarmupSets?: Maybe<Scalars['Boolean']>,
  hideWeightForExerciseSets?: Maybe<Scalars['Boolean']>,
  masterGymStepId?: Maybe<Scalars['UUID']>,
  restPeriod?: Maybe<Scalars['Int']>,
  smartWarmupSetsCount?: Maybe<Scalars['Int']>,
  supersetTag?: Maybe<SupersetTagType>,
  target?: Maybe<Scalars['String']>,
  targetRepsLowerBound?: Maybe<Scalars['Int']>,
  targetRepsUpperBound?: Maybe<Scalars['Int']>,
  targetSets?: Maybe<Scalars['Int']>,
  targetTime?: Maybe<Scalars['Int']>,
  targetWeight?: Maybe<Scalars['Float']>
};


/** Mutation root */
export type MutationUpdateMasterGymStepForCustomTrainingPlanArgs = {
  customTrainingPlanId?: Maybe<Scalars['UUID']>,
  generateSmartWarmupSets?: Maybe<Scalars['Boolean']>,
  hideWeightForExerciseSets?: Maybe<Scalars['Boolean']>,
  masterGymStepId?: Maybe<Scalars['UUID']>,
  restPeriod?: Maybe<Scalars['Int']>,
  smartWarmupSetsCount?: Maybe<Scalars['Int']>,
  supersetTag?: Maybe<SupersetTagType>,
  target?: Maybe<Scalars['String']>,
  targetRepsLowerBound?: Maybe<Scalars['Int']>,
  targetRepsUpperBound?: Maybe<Scalars['Int']>,
  targetSets?: Maybe<Scalars['Int']>,
  targetTime?: Maybe<Scalars['Int']>,
  targetWeight?: Maybe<Scalars['Float']>,
  trainerId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationUpdateMasterTrainingPlanArgs = {
  duration?: Maybe<TrainingPlanDurationType>,
  frequency?: Maybe<TrainingPlanFrequencyType>,
  gender?: Maybe<GenderType>,
  masterTrainingPlanId?: Maybe<Scalars['UUID']>,
  planVersion?: Maybe<TrainingPlanVersionType>,
  program?: Maybe<TrainingPlanProgramType>,
  repRange?: Maybe<TrainingPlanRepRangeType>,
  type?: Maybe<TrainingPlanType>
};


/** Mutation root */
export type MutationUpdateMasterWorkoutArgs = {
  instructions?: Maybe<Scalars['String']>,
  masterWorkoutId?: Maybe<Scalars['UUID']>,
  thumbnailImageMediaUrlId?: Maybe<Scalars['UUID']>,
  title?: Maybe<Scalars['String']>
};


/** Mutation root */
export type MutationUpdateMasterWorkoutInCustomTrainingPlanArgs = {
  customTrainingPlanId?: Maybe<Scalars['UUID']>,
  instructions?: Maybe<Scalars['String']>,
  masterWorkoutId?: Maybe<Scalars['UUID']>,
  thumbnailImageMediaUrlId?: Maybe<Scalars['UUID']>,
  title?: Maybe<Scalars['String']>,
  trainerId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationUpdateMinimumAppBuildNumberArgs = {
  buildNumber: Scalars['Int'],
  deviceType: DeviceType
};


/** Mutation root */
export type MutationUpdateNotesArgs = {
  notes?: Maybe<Scalars['String']>,
  userId: Scalars['UUID']
};


/** Mutation root */
export type MutationUpdatePaywallConfigArgs = {
  enabled?: Maybe<Scalars['Boolean']>,
  paywallConfigId: Scalars['UUID'],
  paywalls?: Maybe<Array<Maybe<PaywallInput>>>,
  trackingId?: Maybe<Scalars['String']>
};


/** Mutation root */
export type MutationUpdateProGroupArgs = {
  mainDescription?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  proGroupId: Scalars['UUID'],
  sendbirdChannelUrl?: Maybe<Scalars['String']>,
  sortOrder?: Maybe<Scalars['Int']>,
  workoutDuration?: Maybe<Scalars['String']>,
  workoutDurationDetail?: Maybe<Scalars['String']>,
  workoutEquipment?: Maybe<Scalars['String']>,
  workoutEquipmentDetail?: Maybe<Scalars['String']>,
  workoutExperienceLevel?: Maybe<Scalars['String']>,
  workoutExperienceLevelDetail?: Maybe<Scalars['String']>,
  workoutFrequency?: Maybe<Scalars['String']>,
  workoutFrequencyDetail?: Maybe<Scalars['String']>
};


/** Mutation root */
export type MutationUpdateProfileIconUrlArgs = {
  clientId: Scalars['UUID'],
  mediaUrlId: Scalars['UUID']
};


/** Mutation root */
export type MutationUpdateProgressPhotoCalendarItemArgs = {
  backPhotoMediaUrlId?: Maybe<Scalars['UUID']>,
  calendarItemId: Scalars['UUID'],
  clientId?: Maybe<Scalars['UUID']>,
  frontPhotoMediaUrlId?: Maybe<Scalars['UUID']>,
  sidePhotoMediaUrlId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationUpdateRecurringEventDefinitionArgs = {
  clientId: Scalars['UUID'],
  endDate?: Maybe<Scalars['LocalDate']>,
  rRule: Scalars['String'],
  recurringEventDefinitionId: Scalars['UUID']
};


/** Mutation root */
export type MutationUpdateSmartWarmupArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  smartWarmupId?: Maybe<Scalars['UUID']>,
  statusType?: Maybe<CalendarItemStatusType>
};


/** Mutation root */
export type MutationUpdateSmartWarmupSetArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  smartWarmupSetId?: Maybe<Scalars['UUID']>,
  statusType?: Maybe<CalendarItemStatusType>
};


/** Mutation root */
export type MutationUpdateSupersetArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  restPeriod?: Maybe<Scalars['Int']>,
  supersetGymStepId?: Maybe<Scalars['UUID']>,
  targetSets?: Maybe<Scalars['Int']>,
  trainerId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationUpdateSupersetChildArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  hideWeightForExerciseSets?: Maybe<Scalars['Boolean']>,
  supersetChildGymStepId?: Maybe<Scalars['UUID']>,
  target?: Maybe<Scalars['String']>,
  targetRepsLowerBound?: Maybe<Scalars['Int']>,
  targetRepsUpperBound?: Maybe<Scalars['Int']>,
  targetTime?: Maybe<Scalars['Int']>,
  trainerId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationUpdateTextGoalArgs = {
  achieved: Scalars['Boolean'],
  clientId?: Maybe<Scalars['UUID']>,
  goalId?: Maybe<Scalars['UUID']>,
  targetDate?: Maybe<Scalars['String']>,
  text?: Maybe<Scalars['String']>
};


/** Mutation root */
export type MutationUpdateTrainerAvailabilityArgs = {
  isAvailable?: Maybe<Scalars['Boolean']>,
  trainerId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationUpdateTrainerCapacityArgs = {
  capacity?: Maybe<Scalars['Int']>,
  trainerId?: Maybe<Scalars['UUID']>
};


/** Mutation root */
export type MutationUpdateTrainerSettingsArgs = {
  trainerId?: Maybe<Scalars['UUID']>,
  trainerSettings?: Maybe<TrainerSettingsInput>
};


/** Mutation root */
export type MutationUpdateUserProfileArgs = {
  birthDate?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  dateMask?: Maybe<Scalars['String']>,
  deviceType?: Maybe<DeviceType>,
  email?: Maybe<Scalars['String']>,
  firstName?: Maybe<Scalars['String']>,
  gender?: Maybe<GenderType>,
  heightFloat?: Maybe<Scalars['Float']>,
  isAvailable?: Maybe<Scalars['Boolean']>,
  isConsultationCompleted?: Maybe<Scalars['Boolean']>,
  isProfileCompleted?: Maybe<Scalars['Boolean']>,
  lastName?: Maybe<Scalars['String']>,
  newAssignedManagerId?: Maybe<Scalars['UUID']>,
  phone?: Maybe<Scalars['String']>,
  state?: Maybe<Scalars['String']>,
  status?: Maybe<UserStatusType>,
  unitHeight: UnitType,
  userId: Scalars['UUID']
};


/** Mutation root */
export type MutationUpdateUserTimeZoneArgs = {
  timeZoneTypeId?: Maybe<Scalars['UUID']>,
  userId: Scalars['UUID']
};


/** Mutation root */
export type MutationUpdateWeightGoalArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  currentWeight: Scalars['Int'],
  goalId?: Maybe<Scalars['UUID']>,
  weightGoalValue: Scalars['Int']
};


/** Mutation root */
export type MutationUpdateWorkoutCalendarItemArgs = {
  calendarItemId: Scalars['UUID'],
  clientId?: Maybe<Scalars['UUID']>,
  notes?: Maybe<Scalars['String']>,
  rating?: Maybe<Scalars['Int']>
};


/** Mutation root */
export type MutationUpdateWorkoutDurationArgs = {
  calendarItemId: Scalars['UUID'],
  clientId: Scalars['UUID'],
  workoutDurationSeconds: Scalars['Int']
};

export type MuxVideoUpload = {
   __typename?: 'MuxVideoUpload',
  assetId?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['UUID']>,
  muxJson?: Maybe<Scalars['String']>,
  muxUploadId?: Maybe<Scalars['String']>,
  playbackId?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  uploadUrl?: Maybe<Scalars['String']>,
  videoProcessingStatus?: Maybe<VideoProcessingStatusType>,
};

export type MuxVideoUploadInput = {
  asset_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  mux_json?: Maybe<Scalars['String']>,
  playback_id?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
  video_processing_status?: Maybe<VideoProcessingStatusType>,
};

export enum NutritionSyncSourceType {
  Caliber = 'CALIBER',
  Cronometer = 'CRONOMETER',
  Fatsecret = 'FATSECRET',
  Fitbit = 'FITBIT',
  GoogleFit = 'GOOGLE_FIT',
  Healthkit = 'HEALTHKIT',
  Manual = 'MANUAL',
  Myfitnesspal = 'MYFITNESSPAL',
  Samsung = 'SAMSUNG'
}

export enum NutritionTargetPhaseType {
  Bulking = 'BULKING',
  Cutting = 'CUTTING',
  Maintenance = 'MAINTENANCE',
  ReverseDiet = 'REVERSE_DIET'
}

export enum NutritionTargetProtocolType {
  FlexibleDiet = 'FLEXIBLE_DIET',
  HighProtein = 'HIGH_PROTEIN',
  Iifym = 'IIFYM',
  Keto = 'KETO',
  LowCarb = 'LOW_CARB',
  MealPlan = 'MEAL_PLAN',
  Paleo = 'PALEO',
  Vegan = 'VEGAN',
  Vegetarian = 'VEGETARIAN',
  Whole_30 = 'WHOLE_30'
}


export type Pageable = {
   __typename?: 'Pageable',
  offset: Scalars['Long'],
  pageNumber: Scalars['Int'],
  pageSize: Scalars['Int'],
  paged: Scalars['Boolean'],
  sort?: Maybe<Sort>,
  unpaged: Scalars['Boolean'],
};

export type Paywall = {
   __typename?: 'Paywall',
  location?: Maybe<PaywallLocation>,
  type?: Maybe<PaywallType>,
};

export type PaywallConfig = {
   __typename?: 'PaywallConfig',
  enabled?: Maybe<Scalars['Boolean']>,
  id?: Maybe<Scalars['UUID']>,
  paywalls?: Maybe<Array<Maybe<Paywall>>>,
  trackingId?: Maybe<Scalars['String']>,
};

export type PaywallInput = {
  location?: Maybe<PaywallLocation>,
  type?: Maybe<PaywallType>,
};

export enum PaywallLocation {
  ActivityActionSheetBodyStat = 'ACTIVITY_ACTION_SHEET_BODY_STAT',
  ActivityActionSheetBodyStatBodyFat = 'ACTIVITY_ACTION_SHEET_BODY_STAT_BODY_FAT',
  ActivityActionSheetBodyStatWaistSize = 'ACTIVITY_ACTION_SHEET_BODY_STAT_WAIST_SIZE',
  ActivityActionSheetBodyStatWeight = 'ACTIVITY_ACTION_SHEET_BODY_STAT_WEIGHT',
  ActivityActionSheetCardio = 'ACTIVITY_ACTION_SHEET_CARDIO',
  ActivityActionSheetCardioActivity = 'ACTIVITY_ACTION_SHEET_CARDIO_ACTIVITY',
  ActivityActionSheetFreestyleWorkout = 'ACTIVITY_ACTION_SHEET_FREESTYLE_WORKOUT',
  ActivityActionSheetProgressPhoto = 'ACTIVITY_ACTION_SHEET_PROGRESS_PHOTO',
  ActivityActionSheetStrengthWorkout = 'ACTIVITY_ACTION_SHEET_STRENGTH_WORKOUT',
  ActivityActionSheetStrengthWorkoutWorkout = 'ACTIVITY_ACTION_SHEET_STRENGTH_WORKOUT_WORKOUT',
  AddWorkoutPlanActionSheetCaliberGenerated = 'ADD_WORKOUT_PLAN_ACTION_SHEET_CALIBER_GENERATED',
  AddWorkoutPlanActionSheetCoachDesigned = 'ADD_WORKOUT_PLAN_ACTION_SHEET_COACH_DESIGNED',
  AddWorkoutPlanActionSheetCreateFromScratch = 'ADD_WORKOUT_PLAN_ACTION_SHEET_CREATE_FROM_SCRATCH',
  Calendar = 'CALENDAR',
  CronometerSync = 'CRONOMETER_SYNC',
  ExerciseActionSheetCreateSuperset = 'EXERCISE_ACTION_SHEET_CREATE_SUPERSET',
  ExerciseActionSheetEdit = 'EXERCISE_ACTION_SHEET_EDIT',
  ExerciseActionSheetSubstitute = 'EXERCISE_ACTION_SHEET_SUBSTITUTE',
  ExerciseHomeHistoryTab = 'EXERCISE_HOME_HISTORY_TAB',
  ExerciseHomeNotesTab = 'EXERCISE_HOME_NOTES_TAB',
  ExerciseHomePlateMathIcon = 'EXERCISE_HOME_PLATE_MATH_ICON',
  ExerciseHomeStopwatchIcon = 'EXERCISE_HOME_STOPWATCH_ICON',
  ExerciseHomeTimerIcon = 'EXERCISE_HOME_TIMER_ICON',
  ExerciseListCustomTab = 'EXERCISE_LIST_CUSTOM_TAB',
  ExerciseListFilter = 'EXERCISE_LIST_FILTER',
  GroupsBrowsePublicGroups = 'GROUPS_BROWSE_PUBLIC_GROUPS',
  GroupsCreateGroup = 'GROUPS_CREATE_GROUP',
  Lessons = 'LESSONS',
  OfflineMode = 'OFFLINE_MODE',
  ProgressCharts = 'PROGRESS_CHARTS',
  ProgressPhotoGallery = 'PROGRESS_PHOTO_GALLERY',
  StrengthBalanceCard = 'STRENGTH_BALANCE_CARD',
  StrengthScoreCard = 'STRENGTH_SCORE_CARD',
  StrengthScoreChanges = 'STRENGTH_SCORE_CHANGES',
  TrainingPlanNutritionTab = 'TRAINING_PLAN_NUTRITION_TAB',
  TrainingPlanWorkout = 'TRAINING_PLAN_WORKOUT',
  UpgradeButton = 'UPGRADE_BUTTON',
  WorkoutActionSheetDuplicate = 'WORKOUT_ACTION_SHEET_DUPLICATE',
  WorkoutActionSheetMove = 'WORKOUT_ACTION_SHEET_MOVE',
  WorkoutActionSheetRename = 'WORKOUT_ACTION_SHEET_RENAME',
  WorkoutActionSheetReorder = 'WORKOUT_ACTION_SHEET_REORDER',
  WorkoutPlanActionSheetAddWorkout = 'WORKOUT_PLAN_ACTION_SHEET_ADD_WORKOUT',
  WorkoutPlanActionSheetChangeType = 'WORKOUT_PLAN_ACTION_SHEET_CHANGE_TYPE',
  WorkoutPlanActionSheetRename = 'WORKOUT_PLAN_ACTION_SHEET_RENAME',
  WorkoutPlanActionSheetReorder = 'WORKOUT_PLAN_ACTION_SHEET_REORDER',
  WorkoutSharingReceive = 'WORKOUT_SHARING_RECEIVE',
  WorkoutSharingShare = 'WORKOUT_SHARING_SHARE',
  WorkoutStartAddExercise = 'WORKOUT_START_ADD_EXERCISE',
  WorkoutStartExercise = 'WORKOUT_START_EXERCISE'
}

export enum PaywallType {
  Coaching = 'COACHING',
  Plus = 'PLUS'
}


export type PhotoUpload = {
   __typename?: 'PhotoUpload',
  id?: Maybe<Scalars['UUID']>,
  pose?: Maybe<PoseType>,
  poseMediaUrl?: Maybe<MediaUrl>,
};

export type PlateWeightQuantity = {
   __typename?: 'PlateWeightQuantity',
  clientEquipmentInventory?: Maybe<ClientEquipmentInventory>,
  id?: Maybe<Scalars['UUID']>,
  plateWeight?: Maybe<Scalars['Float']>,
  quantity?: Maybe<Scalars['Int']>,
};

export type PlateWeightQuantityInput = {
  clientEquipmentInventory?: Maybe<ClientEquipmentInventoryInput>,
  id?: Maybe<Scalars['UUID']>,
  plateWeight?: Maybe<Scalars['Float']>,
  quantity?: Maybe<Scalars['Int']>,
};

export enum PoseType {
  Back = 'BACK',
  Front = 'FRONT',
  Side = 'SIDE'
}

export type ProgressPhotoCalendarItem = CalendarItem & {
   __typename?: 'ProgressPhotoCalendarItem',
  client?: Maybe<Client>,
  date?: Maybe<Scalars['LocalDate']>,
  daySortOrder: Scalars['Int'],
  enumType?: Maybe<CalendarItemType>,
  id?: Maybe<Scalars['UUID']>,
  parent?: Maybe<HierarchicalDomainModelEntity>,
  photos?: Maybe<Array<Maybe<PhotoUpload>>>,
  recurring: Scalars['Boolean'],
  recurringEventDefinition?: Maybe<RecurringEventDefinition>,
  scheduledBy?: Maybe<CalendarItemScheduledByType>,
  sort: Scalars['Int'],
  status?: Maybe<CalendarItemStatusType>,
  title?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
};

export type ProgressPhotoRecurringEventDefinition = {
   __typename?: 'ProgressPhotoRecurringEventDefinition',
  RRule?: Maybe<Scalars['String']>,
  client?: Maybe<Client>,
  endDate?: Maybe<Scalars['LocalDate']>,
  id?: Maybe<Scalars['UUID']>,
  recurringEventExceptions?: Maybe<Array<Maybe<RecurringEventException>>>,
  startDate?: Maybe<Scalars['LocalDate']>,
  type?: Maybe<RecurringEventType>,
};

export type ProGroup = {
   __typename?: 'ProGroup',
  clients?: Maybe<Array<Maybe<Client>>>,
  id?: Maybe<Scalars['UUID']>,
  name?: Maybe<Scalars['String']>,
  proGroupDescription?: Maybe<ProGroupDescription>,
  proGroupTags?: Maybe<Array<Maybe<ProGroupTag>>>,
  sendbirdChannelUrl?: Maybe<Scalars['String']>,
  sortOrder: Scalars['Int'],
  trainerSortOrder?: Maybe<Scalars['Map_UUID_IntegerScalar']>,
  trainers?: Maybe<Array<Maybe<Trainer>>>,
  trainingPlans?: Maybe<Array<Maybe<CustomTrainingPlan>>>,
};

export type ProGroupDescription = {
   __typename?: 'ProGroupDescription',
  mainDescription?: Maybe<Scalars['String']>,
  workoutDuration?: Maybe<Scalars['String']>,
  workoutDurationDetail?: Maybe<Scalars['String']>,
  workoutEquipment?: Maybe<Scalars['String']>,
  workoutEquipmentDetail?: Maybe<Scalars['String']>,
  workoutExperienceLevel?: Maybe<Scalars['String']>,
  workoutExperienceLevelDetail?: Maybe<Scalars['String']>,
  workoutFrequency?: Maybe<Scalars['String']>,
  workoutFrequencyDetail?: Maybe<Scalars['String']>,
};

export type ProGroupDescriptionInput = {
  mainDescription?: Maybe<Scalars['String']>,
  workoutDuration?: Maybe<Scalars['String']>,
  workoutDurationDetail?: Maybe<Scalars['String']>,
  workoutEquipment?: Maybe<Scalars['String']>,
  workoutEquipmentDetail?: Maybe<Scalars['String']>,
  workoutExperienceLevel?: Maybe<Scalars['String']>,
  workoutExperienceLevelDetail?: Maybe<Scalars['String']>,
  workoutFrequency?: Maybe<Scalars['String']>,
  workoutFrequencyDetail?: Maybe<Scalars['String']>,
};

export type ProGroupInfoDto = {
   __typename?: 'ProGroupInfoDTO',
  coachProfiles?: Maybe<Array<Maybe<CoachProfile>>>,
  id?: Maybe<Scalars['UUID']>,
  name?: Maybe<Scalars['String']>,
  proGroupDescription?: Maybe<ProGroupDescription>,
  proGroupTags?: Maybe<Array<Maybe<ProGroupTag>>>,
};

export type ProGroupInput = {
  clients?: Maybe<Array<Maybe<ClientInput>>>,
  id?: Maybe<Scalars['UUID']>,
  name?: Maybe<Scalars['String']>,
  proGroupDescription?: Maybe<ProGroupDescriptionInput>,
  proGroupTags?: Maybe<Array<Maybe<ProGroupTagInput>>>,
  sendbirdChannelUrl?: Maybe<Scalars['String']>,
  sortOrder: Scalars['Int'],
  trainerSortOrder?: Maybe<Scalars['Map_UUID_IntegerScalar']>,
  trainers?: Maybe<Array<Maybe<TrainerInput>>>,
  trainingPlans?: Maybe<Array<Maybe<CustomTrainingPlanInput>>>,
};

export type ProGroupTag = {
   __typename?: 'ProGroupTag',
  color?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['UUID']>,
  name?: Maybe<Scalars['String']>,
  proGroups?: Maybe<Array<Maybe<ProGroup>>>,
  sortOrder: Scalars['Int'],
};

export type ProGroupTagInput = {
  color?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['UUID']>,
  name?: Maybe<Scalars['String']>,
  proGroups?: Maybe<Array<Maybe<ProGroupInput>>>,
  sortOrder: Scalars['Int'],
};

/** Query root */
export type Query = {
   __typename?: 'Query',
  acl?: Maybe<Array<Maybe<Scalars['String']>>>,
  aclCheck: Scalars['Boolean'],
  appRequirements?: Maybe<AppRequirements>,
  authenticatedUserCount: Scalars['Int'],
  authenticatedUsers?: Maybe<Array<Maybe<User>>>,
  branchLinkPreviewData?: Maybe<LinkPreviewData>,
  /** Returns a list of calendar days for a client based on the date range.  Default date mask is MM/dd/yyyy */
  calendarDateRange?: Maybe<Array<Maybe<CalendarDay>>>,
  calendarItem?: Maybe<CalendarItem>,
  caliberErrors?: Maybe<Array<Maybe<CaliberErrorObject>>>,
  caliberRules?: Maybe<Array<Maybe<CaliberRule>>>,
  /** Trainer-level display for most recently sent and received messages to and from a given client. */
  chatRoom?: Maybe<ChatRoom>,
  client?: Maybe<Client>,
  /** Get the client's active training plan. */
  clientActiveTrainingPlan?: Maybe<ClientTrainingPlan>,
  /** Get a user by email. Admin only. */
  clientByEmail?: Maybe<Client>,
  clientByReferralCode?: Maybe<ReferralResponse>,
  /** Retrieve the client consultation categories, questions and answers. */
  clientConsultation?: Maybe<ClientConsultation>,
  clientEquipmentInventory?: Maybe<ClientEquipmentInventory>,
  clientExerciseHistory?: Maybe<ClientExerciseHistory>,
  /** clientExerciseNote */
  clientExerciseNote?: Maybe<ClientExerciseNote>,
  /** Returns the habit target for a client */
  clientHabitTarget?: Maybe<ClientHabitTarget>,
  /** Returns the habit targets for a client */
  clientHabitTargets?: Maybe<Array<Maybe<ClientHabitTarget>>>,
  clientLoggedExercises?: Maybe<Array<Maybe<ExerciseLight>>>,
  /** Returns the nutrition target for a client */
  clientNutritionTarget?: Maybe<ClientNutritionTarget>,
  clientPaywallConfig?: Maybe<PaywallConfig>,
  clientProfile?: Maybe<ClientProfile>,
  clientSalesPackage?: Maybe<ClientSalesPackage>,
  clientTrainingPlanLastUpdatedDate?: Maybe<Scalars['OffsetDateTime']>,
  /** Get the client's training plans. */
  clientTrainingPlans?: Maybe<Array<Maybe<ClientTrainingPlan>>>,
  clientWorkoutHistory?: Maybe<Array<Maybe<ClientWorkout>>>,
  clientWorkoutTemplate?: Maybe<ClientWorkoutTemplate>,
  /** Retrieve a list of clients by their assigned trainer ID and sales package type. */
  clientsByTrainerAndSalesPackageType?: Maybe<Array<Maybe<Client>>>,
  /** Returns a Coach Profile based on the Trainer UUID. */
  coachProfile?: Maybe<CoachProfile>,
  coachTasks?: Maybe<Array<Maybe<CoachTask>>>,
  coachTasksByStatus?: Maybe<Array<Maybe<CoachTask>>>,
  coachTasksForAdminByStatus?: Maybe<Array<Maybe<CoachTask>>>,
  coachTasksForManager?: Maybe<Array<Maybe<CoachTask>>>,
  coachTasksForManagerByStatus?: Maybe<Array<Maybe<CoachTask>>>,
  /** Allows trainers, managers and admins to browse content weeks. */
  contentForWeekBrowsing?: Maybe<ContentWeek>,
  /** 
 * Returns the CMS content for the client based on their number of weeks as a
   * member.  If there is no content available, it defaults to the first week of
   * the 'General' member avatar type.
 */
  contentForWeekByClientId?: Maybe<ContentWeek>,
  /** Returns CMS content for a given week number based on the client's member avatar type stored in the DB. */
  contentForWeekById?: Maybe<ContentWeek>,
  /** Defaults to user's assigned memberAvatarType but allows an admin to view content for any memberAvatarType. */
  contentForWeekByWeekNumber?: Maybe<ContentWeek>,
  customExercises?: Maybe<CaliberPageImpl_ClientCustomExercise>,
  exercise?: Maybe<Exercise>,
  exercises?: Maybe<CaliberPageImpl_Exercise>,
  featureFlags?: Maybe<Array<Maybe<FeatureFlag>>>,
  featuredLibraryTrainingPlans?: Maybe<Array<Maybe<FeaturedLibraryTrainingPlanCategory>>>,
  /** Returns a form video). */
  formVideo?: Maybe<FormVideo>,
  /** Returns the form videos for a given client gym step (one exercise). */
  formVideosByClientGymStep?: Maybe<Array<Maybe<FormVideo>>>,
  /** Returns all form videos within a given date range. */
  formVideosByDateRange?: Maybe<Array<Maybe<FormVideo>>>,
  /** Get the list of trainers assigned to a Pro Group */
  getProGroupTrainers?: Maybe<Array<Maybe<Trainer>>>,
  /** Get the Pro Group training plans for a client. */
  getProGroupTrainingPlans?: Maybe<Array<Maybe<CustomTrainingPlan>>>,
  /** Get a single goal */
  goal?: Maybe<Goal>,
  /** Get goals for a client */
  goals?: Maybe<Array<Maybe<Goal>>>,
  gymStep?: Maybe<GymStep>,
  libraryTrainingPlan?: Maybe<LibraryTrainingPlan>,
  libraryTrainingPlanCategories?: Maybe<Array<Maybe<LibraryTrainingPlanCategory>>>,
  libraryTrainingPlans?: Maybe<Array<Maybe<LibraryTrainingPlan>>>,
  liveStatus?: Maybe<LiveStatus>,
  liveStatusesByGroupChannelUrl?: Maybe<Array<Maybe<LiveStatus>>>,
  /** Get a list of all master training plans. */
  masterTrainingPlans?: Maybe<Array<Maybe<MasterTrainingPlan>>>,
  masterWorkout?: Maybe<MasterWorkout>,
  /** Returns a MediaURL object. */
  mediaUrl?: Maybe<MediaUrl>,
  meetsMinimumAppBuildNumber: Scalars['Boolean'],
  mobileProgressViewer?: Maybe<Array<Maybe<ExerciseSetLight>>>,
  mostRecentBodyMeasurement?: Maybe<Scalars['Float']>,
  mostRecentBodyWeight?: Maybe<Scalars['Float']>,
  paywallConfigs?: Maybe<Array<Maybe<PaywallConfig>>>,
  paywalls?: Maybe<Array<Maybe<Paywall>>>,
  previewSharedWorkoutGroupTemplate?: Maybe<SharedClientWorkoutGroupTemplateDto>,
  previewSharedWorkoutTemplate?: Maybe<SharedClientWorkoutTemplateDto>,
  proGroupTags?: Maybe<Array<Maybe<ProGroupTag>>>,
  /** Get a list of all Pro Groups. */
  proGroups?: Maybe<Array<Maybe<ProGroup>>>,
  progressPhotos?: Maybe<CaliberPageImpl_ProgressPhotoCalendarItem>,
  /** Get a list of publicly available Pro Groups. */
  publicProGroups?: Maybe<Array<Maybe<ProGroupInfoDto>>>,
  /** Send a reactivation request email for a client. */
  reactivateSubscriptionRequest?: Maybe<Scalars['String']>,
  /** For testing purposes. */
  recentlyCompletedClientGymStep?: Maybe<ClientGymStep>,
  recurringEventDefinitions?: Maybe<Array<Maybe<RecurringEventDefinition>>>,
  referralOfferLink?: Maybe<ReferralOfferLink>,
  requestPasswordResetEmail: Scalars['Boolean'],
  runRulesEngineCron: Scalars['Boolean'],
  searchAdmins?: Maybe<CaliberPageImpl_Admin>,
  searchClients?: Maybe<CaliberPageImpl_Client>,
  searchClientsTab?: Maybe<CaliberPageImpl_ClientSearchResult>,
  searchCoachesTab?: Maybe<CaliberPageImpl_CoachSearchResult>,
  /** 
 * Search all custom training plans with optional search criteria and sort
   * fields. Default sort order is by 'gender, type, program, repRange, duration, frequency'.
 */
  searchCustomTrainingPlans?: Maybe<CaliberPageImpl_CustomTrainingPlan>,
  searchExercises?: Maybe<CaliberPageImpl_Exercise>,
  searchLibraryTrainingPlans?: Maybe<CaliberPageImpl_LibraryTrainingPlan>,
  searchLocations?: Maybe<Array<Maybe<LocationResult>>>,
  searchManagers?: Maybe<CaliberPageImpl_Manager>,
  /** 
 * Search all master training plans with optional search critera and sort fields.
   *  Leaving everything blank will return all master training plans.  Default sort
   * order is by 'gender, type, program, repRange, duration, frequency'.
 */
  searchMasterTrainingPlans?: Maybe<CaliberPageImpl_MasterTrainingPlan>,
  searchTimeZones?: Maybe<CaliberPageImpl_TimeZoneType>,
  searchTrainers?: Maybe<CaliberPageImpl_Trainer>,
  /** Returns a MediaURL object. */
  secureMediaUrl?: Maybe<MediaUrl>,
  sendBirdUserStatus?: Maybe<SendBirdUserStatusDto>,
  /** For testing purposes will send tomorrow's calendar to Segment */
  sendRemindersToSegment: Scalars['Boolean'],
  socialMediaBrands?: Maybe<Array<Maybe<SocialMediaBrand>>>,
  strengthBalancesByDateRange?: Maybe<Array<Maybe<StrengthScoreLight>>>,
  strengthScoreDashboardChanges?: Maybe<Array<Maybe<StrengthScoreDashboard>>>,
  strengthScores?: Maybe<StrengthScoreDashboard>,
  strengthScoresByDateRange?: Maybe<Array<Maybe<StrengthScoreLight>>>,
  strengthScoresInProgress?: Maybe<StrengthScoreDashboard>,
  stripeCustomerPortalUrl?: Maybe<Scalars['String']>,
  testPlusPackageTypeRequired: Scalars['Boolean'],
  /** Defaults to error code 0. */
  throwCaliberError: Scalars['Boolean'],
  /** Returns an HTTP 500 response in String (non-JSON) format */
  throwHttpError: Scalars['Boolean'],
  timeZone?: Maybe<TimeZoneType>,
  totalBodyWeightLogged?: Maybe<Scalars['Long']>,
  /** Trainer display of total unread message count. */
  trainerTotalUnreadMessageCount?: Maybe<Scalars['Int']>,
  /** Get a training plan. */
  trainingPlan?: Maybe<TrainingPlan>,
  /** Get the current authenticated user or any other user by UUID. */
  user?: Maybe<User>,
  userNotifications?: Maybe<CaliberPageImpl_UserNotification>,
  users?: Maybe<Array<Maybe<User>>>,
  widgets?: Maybe<Array<Maybe<ContentWidget>>>,
  workout?: Maybe<Workout>,
  zoomCalls?: Maybe<Array<Maybe<ZoomCall>>>,
};


/** Query root */
export type QueryAclArgs = {
  className: Scalars['String'],
  id: Scalars['UUID']
};


/** Query root */
export type QueryAclCheckArgs = {
  principal: Scalars['UUID'],
  targetClassName: Scalars['String'],
  targetId: Scalars['UUID'],
  targetPermission: Scalars['String']
};


/** Query root */
export type QueryAppRequirementsArgs = {
  deviceType?: Maybe<DeviceType>
};


/** Query root */
export type QueryBranchLinkPreviewDataArgs = {
  branchUrl?: Maybe<Scalars['String']>
};


/** Query root */
export type QueryCalendarDateRangeArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  end?: Maybe<Scalars['String']>,
  endDate?: Maybe<Scalars['LocalDate']>,
  start?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['LocalDate']>
};


/** Query root */
export type QueryCalendarItemArgs = {
  calendarItemId?: Maybe<Scalars['UUID']>,
  clientId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QueryChatRoomArgs = {
  clientId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QueryClientActiveTrainingPlanArgs = {
  clientId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QueryClientByEmailArgs = {
  email: Scalars['String']
};


/** Query root */
export type QueryClientByReferralCodeArgs = {
  referralCode?: Maybe<Scalars['String']>
};


/** Query root */
export type QueryClientConsultationArgs = {
  clientId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QueryClientEquipmentInventoryArgs = {
  clientId: Scalars['UUID'],
  weightUnitType: WeightUnitType
};


/** Query root */
export type QueryClientExerciseHistoryArgs = {
  clientId: Scalars['UUID'],
  exerciseId: Scalars['UUID'],
  page: Scalars['Int']
};


/** Query root */
export type QueryClientExerciseNoteArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  exerciseId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QueryClientHabitTargetArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  habitType?: Maybe<HabitType>
};


/** Query root */
export type QueryClientHabitTargetsArgs = {
  clientId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QueryClientLoggedExercisesArgs = {
  clientId: Scalars['UUID'],
  endDate: Scalars['LocalDate'],
  startDate: Scalars['LocalDate']
};


/** Query root */
export type QueryClientNutritionTargetArgs = {
  clientId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QueryClientPaywallConfigArgs = {
  clientId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QueryClientProfileArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  endDate?: Maybe<Scalars['LocalDate']>,
  startDate?: Maybe<Scalars['LocalDate']>
};


/** Query root */
export type QueryClientSalesPackageArgs = {
  clientId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QueryClientTrainingPlanLastUpdatedDateArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  clientTrainingPlanId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QueryClientTrainingPlansArgs = {
  clientId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QueryClientWorkoutHistoryArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  clientWorkoutTemplateId?: Maybe<Scalars['UUID']>,
  maxQueryResults: Scalars['Int']
};


/** Query root */
export type QueryClientWorkoutTemplateArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  clientWorkoutTemplateId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QueryClientsByTrainerAndSalesPackageTypeArgs = {
  packageType?: Maybe<ClientPackageType>,
  trainerId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QueryCoachProfileArgs = {
  trainerId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QueryCoachTasksArgs = {
  trainerId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QueryCoachTasksByStatusArgs = {
  statusCriteria?: Maybe<Array<Maybe<CoachTaskStatusType>>>,
  trainerId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QueryCoachTasksForAdminByStatusArgs = {
  statusCriteria?: Maybe<Array<Maybe<CoachTaskStatusType>>>
};


/** Query root */
export type QueryCoachTasksForManagerArgs = {
  managerId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QueryCoachTasksForManagerByStatusArgs = {
  managerId?: Maybe<Scalars['UUID']>,
  statusCriteria?: Maybe<Array<Maybe<CoachTaskStatusType>>>
};


/** Query root */
export type QueryContentForWeekBrowsingArgs = {
  memberAvatarType: MemberAvatarType,
  weekNumber: Scalars['Int']
};


/** Query root */
export type QueryContentForWeekByClientIdArgs = {
  clientId: Scalars['UUID']
};


/** Query root */
export type QueryContentForWeekByIdArgs = {
  clientId: Scalars['UUID'],
  contentWeekId: Scalars['String']
};


/** Query root */
export type QueryContentForWeekByWeekNumberArgs = {
  clientId: Scalars['UUID'],
  memberAvatarType?: Maybe<MemberAvatarType>,
  weekNumber: Scalars['Int']
};


/** Query root */
export type QueryCustomExercisesArgs = {
  clientId: Scalars['UUID'],
  page: Scalars['Int']
};


/** Query root */
export type QueryExerciseArgs = {
  exerciseId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QueryExercisesArgs = {
  page: Scalars['Int']
};


/** Query root */
export type QueryFeatureFlagsArgs = {
  appBuildNumber: Scalars['Int'],
  deviceType: DeviceType,
  userId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QueryFormVideoArgs = {
  clientId: Scalars['UUID'],
  formVideoId: Scalars['UUID']
};


/** Query root */
export type QueryFormVideosByClientGymStepArgs = {
  clientGymStepId: Scalars['UUID'],
  clientId: Scalars['UUID']
};


/** Query root */
export type QueryFormVideosByDateRangeArgs = {
  clientId: Scalars['UUID'],
  endDate?: Maybe<Scalars['LocalDate']>,
  startDate?: Maybe<Scalars['LocalDate']>
};


/** Query root */
export type QueryGetProGroupTrainersArgs = {
  sendbirdChannelUrl: Scalars['String']
};


/** Query root */
export type QueryGetProGroupTrainingPlansArgs = {
  clientId: Scalars['UUID']
};


/** Query root */
export type QueryGoalArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  goalId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QueryGoalsArgs = {
  clientId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QueryGymStepArgs = {
  gymStepId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QueryLibraryTrainingPlanArgs = {
  libraryTrainingPlanId: Scalars['UUID']
};


/** Query root */
export type QueryLiveStatusArgs = {
  userId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QueryLiveStatusesByGroupChannelUrlArgs = {
  groupChannelUrl?: Maybe<Scalars['String']>
};


/** Query root */
export type QueryMasterWorkoutArgs = {
  masterWorkoutId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QueryMediaUrlArgs = {
  mediaUrlId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QueryMeetsMinimumAppBuildNumberArgs = {
  buildNumber: Scalars['Int'],
  deviceType?: Maybe<DeviceType>
};


/** Query root */
export type QueryMobileProgressViewerArgs = {
  clientExerciseStatisticType: ClientExerciseStatisticType,
  clientId: Scalars['UUID'],
  endDate: Scalars['LocalDate'],
  exerciseId: Scalars['UUID'],
  startDate: Scalars['LocalDate']
};


/** Query root */
export type QueryMostRecentBodyMeasurementArgs = {
  bodyMeasurementType?: Maybe<BodyMeasurementType>,
  clientId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QueryMostRecentBodyWeightArgs = {
  clientId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QueryPaywallsArgs = {
  clientId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QueryPreviewSharedWorkoutGroupTemplateArgs = {
  clientWorkoutGroupTemplateId: Scalars['UUID']
};


/** Query root */
export type QueryPreviewSharedWorkoutTemplateArgs = {
  clientWorkoutTemplateId: Scalars['UUID']
};


/** Query root */
export type QueryProgressPhotosArgs = {
  clientId: Scalars['UUID'],
  page: Scalars['Int'],
  sortDirection?: Maybe<Direction>
};


/** Query root */
export type QueryReactivateSubscriptionRequestArgs = {
  clientId: Scalars['UUID']
};


/** Query root */
export type QueryRecentlyCompletedClientGymStepArgs = {
  clientId?: Maybe<Scalars['UUID']>,
  exerciseId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QueryRecurringEventDefinitionsArgs = {
  clientId: Scalars['UUID']
};


/** Query root */
export type QueryReferralOfferLinkArgs = {
  clientId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QueryRequestPasswordResetEmailArgs = {
  email?: Maybe<Scalars['String']>
};


/** Query root */
export type QuerySearchAdminsArgs = {
  page: Scalars['Int'],
  searchCriteria?: Maybe<Scalars['String']>
};


/** Query root */
export type QuerySearchClientsArgs = {
  managerIdFilter?: Maybe<Scalars['UUID']>,
  page: Scalars['Int'],
  searchCriteria?: Maybe<Scalars['String']>,
  sortDirection?: Maybe<Direction>,
  sortField?: Maybe<ClientSortCriteria>,
  status?: Maybe<UserStatusType>,
  trainerIdFilter?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QuerySearchClientsTabArgs = {
  clientPackageTypeCriteria?: Maybe<Array<Maybe<ClientPackageType>>>,
  managerIdFilter?: Maybe<Scalars['UUID']>,
  pageNumber: Scalars['Int'],
  pageSize: Scalars['Int'],
  searchCriteria?: Maybe<Scalars['String']>,
  sortField: ClientSearchResultSortCriteria,
  sortOrder: SortOrder,
  statusCriteria?: Maybe<Array<Maybe<UserStatusType>>>,
  trainerIdFilter?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QuerySearchCoachesTabArgs = {
  managerIdFilter?: Maybe<Scalars['UUID']>,
  pageNumber: Scalars['Int'],
  pageSize: Scalars['Int'],
  searchCriteria?: Maybe<Scalars['String']>,
  sortDirection?: Maybe<Direction>,
  sortField?: Maybe<TrainerSortCriteria>,
  statusCriteria?: Maybe<Array<Maybe<UserStatusType>>>
};


/** Query root */
export type QuerySearchCustomTrainingPlansArgs = {
  name?: Maybe<Scalars['String']>,
  page: Scalars['Int'],
  sortDirection?: Maybe<Direction>,
  sortFields?: Maybe<Array<Maybe<TrainingPlanSortCriteria>>>,
  trainerId: Scalars['UUID'],
  type?: Maybe<CustomTrainingPlanType>
};


/** Query root */
export type QuerySearchExercisesArgs = {
  customExercisesOnly?: Maybe<Scalars['Boolean']>,
  exerciseEquipmentCriteria?: Maybe<ExerciseEquipmentType>,
  exerciseEquipmentTypes?: Maybe<Array<Maybe<ExerciseEquipmentType>>>,
  exerciseLevelCriteria?: Maybe<ExerciseLevelType>,
  exerciseTypeCriteria?: Maybe<ExerciseType>,
  muscleGroupCriteria?: Maybe<Array<Maybe<MuscleGroupType>>>,
  page: Scalars['Int'],
  showWarmups?: Maybe<Scalars['Boolean']>,
  sortDirection?: Maybe<Direction>,
  sortField?: Maybe<ExerciseSortCriteria>,
  textCriteria: Scalars['String']
};


/** Query root */
export type QuerySearchLibraryTrainingPlansArgs = {
  page: Scalars['Int'],
  trainerId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QuerySearchLocationsArgs = {
  searchCriteria?: Maybe<Scalars['String']>
};


/** Query root */
export type QuerySearchManagersArgs = {
  page: Scalars['Int'],
  searchCriteria?: Maybe<Scalars['String']>,
  statusCriteria?: Maybe<Array<Maybe<UserStatusType>>>
};


/** Query root */
export type QuerySearchMasterTrainingPlansArgs = {
  durationType?: Maybe<Array<Maybe<TrainingPlanDurationType>>>,
  frequencyType?: Maybe<Array<Maybe<TrainingPlanFrequencyType>>>,
  genderType?: Maybe<Array<Maybe<GenderType>>>,
  page: Scalars['Int'],
  planType?: Maybe<Array<Maybe<TrainingPlanType>>>,
  programType?: Maybe<Array<Maybe<TrainingPlanProgramType>>>,
  repRangeType?: Maybe<Array<Maybe<TrainingPlanRepRangeType>>>,
  sortDirection?: Maybe<Direction>,
  sortFields?: Maybe<Array<Maybe<TrainingPlanSortCriteria>>>
};


/** Query root */
export type QuerySearchTimeZonesArgs = {
  page: Scalars['Int'],
  textCriteria?: Maybe<Scalars['String']>
};


/** Query root */
export type QuerySearchTrainersArgs = {
  page: Scalars['Int'],
  searchCriteria?: Maybe<Scalars['String']>,
  statusCriteria?: Maybe<Array<Maybe<UserStatusType>>>
};


/** Query root */
export type QuerySecureMediaUrlArgs = {
  mediaUrlId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QuerySendBirdUserStatusArgs = {
  clientId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QuerySendRemindersToSegmentArgs = {
  clientId: Scalars['UUID'],
  targetDate: Scalars['LocalDate']
};


/** Query root */
export type QueryStrengthBalancesByDateRangeArgs = {
  clientId: Scalars['UUID'],
  endDate: Scalars['LocalDate'],
  startDate: Scalars['LocalDate'],
  type: StrengthScoreMuscleGroupType
};


/** Query root */
export type QueryStrengthScoreDashboardChangesArgs = {
  clientId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QueryStrengthScoresArgs = {
  clientId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QueryStrengthScoresByDateRangeArgs = {
  clientId: Scalars['UUID'],
  endDate: Scalars['LocalDate'],
  startDate: Scalars['LocalDate'],
  type: StrengthScoreMuscleGroupType
};


/** Query root */
export type QueryStrengthScoresInProgressArgs = {
  clientId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QueryStripeCustomerPortalUrlArgs = {
  clientId: Scalars['UUID']
};


/** Query root */
export type QueryThrowCaliberErrorArgs = {
  caliberErrorNumber?: Maybe<Scalars['Int']>
};


/** Query root */
export type QueryTimeZoneArgs = {
  timeZoneTypeId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QueryTotalBodyWeightLoggedArgs = {
  clientId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QueryTrainerTotalUnreadMessageCountArgs = {
  trainerId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QueryTrainingPlanArgs = {
  trainingPlanId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QueryUserArgs = {
  id?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QueryUserNotificationsArgs = {
  pageNumber: Scalars['Int'],
  pageSize: Scalars['Int'],
  sortDirection?: Maybe<Direction>,
  userId: Scalars['UUID']
};


/** Query root */
export type QueryWidgetsArgs = {
  clientId: Scalars['UUID']
};


/** Query root */
export type QueryWorkoutArgs = {
  workoutId?: Maybe<Scalars['UUID']>
};


/** Query root */
export type QueryZoomCallsArgs = {
  clientId?: Maybe<Scalars['UUID']>
};

export type QuestionAndAnswerInput = {
  answer?: Maybe<Scalars['String']>,
  category?: Maybe<ConsultationQuestionCategoryType>,
  id?: Maybe<Scalars['UUID']>,
  question?: Maybe<Scalars['String']>,
  sortOrder?: Maybe<Scalars['Int']>,
};

export enum RecurrenceType {
  Add = 'ADD',
  Remove = 'REMOVE'
}

export type RecurringBodyStatCalendarItem = CalendarItem & {
   __typename?: 'RecurringBodyStatCalendarItem',
  bodyMeasurementType?: Maybe<BodyMeasurementType>,
  bodyMeasurementValue?: Maybe<Scalars['Float']>,
  client?: Maybe<Client>,
  date?: Maybe<Scalars['LocalDate']>,
  daySortOrder: Scalars['Int'],
  enumType?: Maybe<CalendarItemType>,
  id?: Maybe<Scalars['UUID']>,
  parent?: Maybe<HierarchicalDomainModelEntity>,
  recurring: Scalars['Boolean'],
  recurringEventDefinition?: Maybe<RecurringEventDefinition>,
  scheduledBy?: Maybe<CalendarItemScheduledByType>,
  sort: Scalars['Int'],
  status?: Maybe<CalendarItemStatusType>,
  title?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
};

export type RecurringCardioActivityCalendarItem = CalendarItem & {
   __typename?: 'RecurringCardioActivityCalendarItem',
  calories?: Maybe<Scalars['Int']>,
  cardioActivityType?: Maybe<CardioActivityType>,
  client?: Maybe<Client>,
  date?: Maybe<Scalars['LocalDate']>,
  daySortOrder: Scalars['Int'],
  distance?: Maybe<Scalars['Float']>,
  enumType?: Maybe<CalendarItemType>,
  id?: Maybe<Scalars['UUID']>,
  level?: Maybe<Scalars['Int']>,
  notes?: Maybe<Scalars['String']>,
  parent?: Maybe<HierarchicalDomainModelEntity>,
  recurring: Scalars['Boolean'],
  recurringEventDefinition?: Maybe<RecurringEventDefinition>,
  scheduledBy?: Maybe<CalendarItemScheduledByType>,
  sort: Scalars['Int'],
  speed?: Maybe<Scalars['Float']>,
  status?: Maybe<CalendarItemStatusType>,
  time?: Maybe<Scalars['Int']>,
  title?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
};

export type RecurringEventDefinition = {
   __typename?: 'RecurringEventDefinition',
  RRule?: Maybe<Scalars['String']>,
  client?: Maybe<Client>,
  endDate?: Maybe<Scalars['LocalDate']>,
  id?: Maybe<Scalars['UUID']>,
  recurringEventExceptions?: Maybe<Array<Maybe<RecurringEventException>>>,
  startDate?: Maybe<Scalars['LocalDate']>,
  type?: Maybe<RecurringEventType>,
};

export type RecurringEventDefinitionInput = {
  client?: Maybe<ClientInput>,
  endDate?: Maybe<Scalars['LocalDate']>,
  id?: Maybe<Scalars['UUID']>,
  recurringEventExceptions?: Maybe<Array<Maybe<RecurringEventExceptionInput>>>,
  rrule?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['LocalDate']>,
  type?: Maybe<RecurringEventType>,
};

export type RecurringEventException = {
   __typename?: 'RecurringEventException',
  date?: Maybe<Scalars['LocalDate']>,
  id?: Maybe<Scalars['UUID']>,
  recurrenceType?: Maybe<RecurrenceType>,
};

export type RecurringEventExceptionInput = {
  date?: Maybe<Scalars['LocalDate']>,
  id?: Maybe<Scalars['UUID']>,
  recurrenceType?: Maybe<RecurrenceType>,
};

export enum RecurringEventType {
  BodyStat = 'BODY_STAT',
  CardioActivity = 'CARDIO_ACTIVITY',
  Habit = 'HABIT',
  ProgressPic = 'PROGRESS_PIC',
  Workout = 'WORKOUT'
}

export type RecurringHabitCalendarItem = CalendarItem & {
   __typename?: 'RecurringHabitCalendarItem',
  client?: Maybe<Client>,
  date?: Maybe<Scalars['LocalDate']>,
  daySortOrder: Scalars['Int'],
  enumType?: Maybe<CalendarItemType>,
  habitType?: Maybe<HabitType>,
  id?: Maybe<Scalars['UUID']>,
  parent?: Maybe<HierarchicalDomainModelEntity>,
  quantity?: Maybe<Scalars['Int']>,
  recurring: Scalars['Boolean'],
  recurringEventDefinition?: Maybe<RecurringEventDefinition>,
  scheduledBy?: Maybe<CalendarItemScheduledByType>,
  sort: Scalars['Int'],
  status?: Maybe<CalendarItemStatusType>,
  target?: Maybe<Scalars['Int']>,
  title?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
};

export type RecurringProgressPhotoCalendarItem = CalendarItem & {
   __typename?: 'RecurringProgressPhotoCalendarItem',
  client?: Maybe<Client>,
  date?: Maybe<Scalars['LocalDate']>,
  daySortOrder: Scalars['Int'],
  enumType?: Maybe<CalendarItemType>,
  id?: Maybe<Scalars['UUID']>,
  parent?: Maybe<HierarchicalDomainModelEntity>,
  photos?: Maybe<Array<Maybe<PhotoUpload>>>,
  recurring: Scalars['Boolean'],
  recurringEventDefinition?: Maybe<RecurringEventDefinition>,
  scheduledBy?: Maybe<CalendarItemScheduledByType>,
  sort: Scalars['Int'],
  status?: Maybe<CalendarItemStatusType>,
  title?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
};

export type RecurringWorkoutCalendarItem = CalendarItem & {
   __typename?: 'RecurringWorkoutCalendarItem',
  client?: Maybe<Client>,
  clientWorkout?: Maybe<ClientWorkout>,
  clientWorkoutDate?: Maybe<Scalars['LocalDate']>,
  clientWorkoutTemplate?: Maybe<ClientWorkoutTemplate>,
  date?: Maybe<Scalars['LocalDate']>,
  daySortOrder: Scalars['Int'],
  endTime?: Maybe<Scalars['OffsetDateTime']>,
  enumType?: Maybe<CalendarItemType>,
  id?: Maybe<Scalars['UUID']>,
  includeInStrengthMetrics?: Maybe<Scalars['Boolean']>,
  notes?: Maybe<Scalars['String']>,
  parent?: Maybe<HierarchicalDomainModelEntity>,
  rating?: Maybe<Scalars['Int']>,
  recurring: Scalars['Boolean'],
  recurringEventDefinition?: Maybe<RecurringEventDefinition>,
  scheduledBy?: Maybe<CalendarItemScheduledByType>,
  sort: Scalars['Int'],
  startTime?: Maybe<Scalars['OffsetDateTime']>,
  status?: Maybe<CalendarItemStatusType>,
  title?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
  workoutDurationSeconds?: Maybe<Scalars['Long']>,
};

export type ReferralOffer = {
   __typename?: 'ReferralOffer',
  description?: Maybe<Scalars['String']>,
  getValue: Scalars['Int'],
  giveValue: Scalars['Int'],
  id?: Maybe<Scalars['UUID']>,
  packageType?: Maybe<ClientPackageType>,
  title?: Maybe<Scalars['String']>,
};

export type ReferralOfferInput = {
  description?: Maybe<Scalars['String']>,
  getValue: Scalars['Int'],
  giveValue: Scalars['Int'],
  id?: Maybe<Scalars['UUID']>,
  packageType?: Maybe<ClientPackageType>,
  title?: Maybe<Scalars['String']>,
};

export type ReferralOfferLink = {
   __typename?: 'ReferralOfferLink',
  referralOffer?: Maybe<ReferralOffer>,
  url?: Maybe<Scalars['String']>,
};

export type ReferralResponse = {
   __typename?: 'ReferralResponse',
  clientId?: Maybe<Scalars['UUID']>,
  clientPackageType?: Maybe<ClientPackageType>,
  referralOffer?: Maybe<ReferralOffer>,
};

export enum ReminderRuleType {
  CheckInWithClientAfterFirstWorkout = 'CHECK_IN_WITH_CLIENT_AFTER_FIRST_WORKOUT',
  CongratulateClientOnCompletedWorkouts = 'CONGRATULATE_CLIENT_ON_COMPLETED_WORKOUTS',
  CreateTrainingPlanForClient = 'CREATE_TRAINING_PLAN_FOR_CLIENT',
  EnterNotesFromZoomSession = 'ENTER_NOTES_FROM_ZOOM_SESSION',
  HaveIntroZoomCall = 'HAVE_INTRO_ZOOM_CALL',
  MessageClientEveryFewDays = 'MESSAGE_CLIENT_EVERY_FEW_DAYS',
  MessageLegacyClientEveryFewDays = 'MESSAGE_LEGACY_CLIENT_EVERY_FEW_DAYS',
  MessagePremiumClientEveryFewDays = 'MESSAGE_PREMIUM_CLIENT_EVERY_FEW_DAYS',
  NewClient = 'NEW_CLIENT',
  RenewalPeriod = 'RENEWAL_PERIOD',
  RespondToMessages = 'RESPOND_TO_MESSAGES',
  ReviewFormVideo = 'REVIEW_FORM_VIDEO',
  ScheduleFirstZoomStrategy = 'SCHEDULE_FIRST_ZOOM_STRATEGY',
  ScheduleIntroZoom = 'SCHEDULE_INTRO_ZOOM',
  SendClientBirthdayMessage = 'SEND_CLIENT_BIRTHDAY_MESSAGE',
  SendMessageToInactiveClient = 'SEND_MESSAGE_TO_INACTIVE_CLIENT',
  SendMessageTrainingPlanCreated = 'SEND_MESSAGE_TRAINING_PLAN_CREATED',
  SendMessageWhenClientMissesNutritionTarget = 'SEND_MESSAGE_WHEN_CLIENT_MISSES_NUTRITION_TARGET',
  SendMessageWhenClientNotLoggingFood = 'SEND_MESSAGE_WHEN_CLIENT_NOT_LOGGING_FOOD',
  SetGoalForClient = 'SET_GOAL_FOR_CLIENT',
  SetUpNutritionTargets = 'SET_UP_NUTRITION_TARGETS',
  UpdateClientNutritionTarget = 'UPDATE_CLIENT_NUTRITION_TARGET',
  UpdateClientTrainingPlan = 'UPDATE_CLIENT_TRAINING_PLAN'
}

export enum RuleType {
  CoachReminder = 'COACH_REMINDER',
  SnoozeReset = 'SNOOZE_RESET',
  TaskAutocomplete = 'TASK_AUTOCOMPLETE'
}

export type SalesRep = User & {
   __typename?: 'SalesRep',
  age?: Maybe<Scalars['Int']>,
  birthDate?: Maybe<Scalars['LocalDate']>,
  city?: Maybe<Scalars['String']>,
  clientAuthId?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  firstName?: Maybe<Scalars['String']>,
  gender?: Maybe<GenderType>,
  height?: Maybe<Scalars['Float']>,
  id?: Maybe<Scalars['UUID']>,
  isAvailable?: Maybe<Scalars['Boolean']>,
  isConsultationCompleted?: Maybe<Scalars['Boolean']>,
  isProfileCompleted?: Maybe<Scalars['Boolean']>,
  joinedDate?: Maybe<Scalars['OffsetDateTime']>,
  lastName?: Maybe<Scalars['String']>,
  notes?: Maybe<Scalars['String']>,
  parent?: Maybe<HierarchicalDomainModelEntity>,
  phone?: Maybe<Scalars['String']>,
  profileIconMediaUrl?: Maybe<MediaUrl>,
  profileIconUrl?: Maybe<Scalars['String']>,
  sendbirdAccessToken?: Maybe<Scalars['String']>,
  settings?: Maybe<Settings>,
  state?: Maybe<Scalars['String']>,
  status?: Maybe<UserStatusType>,
  timezone?: Maybe<TimeZoneType>,
  type?: Maybe<UserType>,
  userEnabled: Scalars['Boolean'],
};

export type SalesRepInput = {
  age: Scalars['Int'],
  birthDate?: Maybe<Scalars['LocalDate']>,
  city?: Maybe<Scalars['String']>,
  clientAuthId?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  firstName?: Maybe<Scalars['String']>,
  gender?: Maybe<GenderType>,
  height?: Maybe<Scalars['Float']>,
  id?: Maybe<Scalars['UUID']>,
  isAvailable?: Maybe<Scalars['Boolean']>,
  isConsultationCompleted?: Maybe<Scalars['Boolean']>,
  isProfileCompleted?: Maybe<Scalars['Boolean']>,
  joinedDate?: Maybe<Scalars['OffsetDateTime']>,
  lastName?: Maybe<Scalars['String']>,
  notes?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  profileIconMediaUrl?: Maybe<MediaUrlInput>,
  profileIconUrl?: Maybe<Scalars['String']>,
  sendbirdAccessToken?: Maybe<Scalars['String']>,
  settings?: Maybe<SettingsInput>,
  state?: Maybe<Scalars['String']>,
  status?: Maybe<UserStatusType>,
  timezone?: Maybe<TimeZoneTypeInput>,
  type?: Maybe<UserType>,
};

export type SendBirdMessage = {
   __typename?: 'SendBirdMessage',
  channel?: Maybe<GroupChannel>,
  createdAt: Scalars['Long'],
  customType?: Maybe<Scalars['String']>,
  file?: Maybe<SendBirdMessageFile>,
  message?: Maybe<Scalars['String']>,
  messageId: Scalars['Long'],
  reactions?: Maybe<Array<Maybe<SendBirdMessageReaction>>>,
  type?: Maybe<Scalars['String']>,
  user?: Maybe<SendBirdUser>,
};

export type SendBirdMessageFile = {
   __typename?: 'SendBirdMessageFile',
  name?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
};

export type SendBirdMessageReaction = {
   __typename?: 'SendBirdMessageReaction',
  key?: Maybe<Scalars['String']>,
  userIds?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export enum SendBirdMessageType {
  CardioFeedback = 'CARDIO_FEEDBACK',
  ChannelAction = 'CHANNEL_ACTION',
  EventMessage = 'EVENT_MESSAGE',
  FormVideo = 'FORM_VIDEO',
  Message = 'MESSAGE',
  Muxvideo = 'MUXVIDEO',
  Reaction = 'REACTION',
  WorkoutRating = 'WORKOUT_RATING'
}

export type SendBirdUser = {
   __typename?: 'SendBirdUser',
  metadata?: Maybe<Scalars['Map_String_StringScalar']>,
  nickname?: Maybe<Scalars['String']>,
  profileUrl?: Maybe<Scalars['String']>,
  userId?: Maybe<Scalars['String']>,
};

export type SendBirdUserStatusDto = {
   __typename?: 'SendBirdUserStatusDTO',
  connectOnLaunch?: Maybe<Scalars['Boolean']>,
  groupChannelCount?: Maybe<Scalars['Int']>,
};

export type Settings = {
  clientsTabColumnPreferences?: Maybe<Scalars['String']>,
  enableGeneralNotifications?: Maybe<Scalars['Boolean']>,
  enableMessageNotifications?: Maybe<Scalars['Boolean']>,
  hasNewNotification?: Maybe<Scalars['Boolean']>,
  id?: Maybe<Scalars['UUID']>,
  unitBodystat?: Maybe<UnitType>,
  unitDistance?: Maybe<UnitType>,
  unitWeight?: Maybe<UnitType>,
};

export type SettingsInput = {
  clientsTabColumnPreferences?: Maybe<Scalars['String']>,
  enableGeneralNotifications?: Maybe<Scalars['Boolean']>,
  enableMessageNotifications?: Maybe<Scalars['Boolean']>,
  hasNewNotification?: Maybe<Scalars['Boolean']>,
  id?: Maybe<Scalars['UUID']>,
  unitBodystat?: Maybe<UnitType>,
  unitDistance?: Maybe<UnitType>,
  unitWeight?: Maybe<UnitType>,
  user?: Maybe<UserInput>,
};

export type SharedClientWorkoutGroupTemplateDto = {
   __typename?: 'SharedClientWorkoutGroupTemplateDTO',
  clientDisplayName?: Maybe<Scalars['String']>,
  clientWorkoutGroupTemplate?: Maybe<ClientWorkoutGroupTemplate>,
};

export type SharedClientWorkoutTemplateDto = {
   __typename?: 'SharedClientWorkoutTemplateDTO',
  clientDisplayName?: Maybe<Scalars['String']>,
  clientWorkoutTemplate?: Maybe<ClientWorkoutTemplate>,
};

export type Slice_ClientGymStep = {
   __typename?: 'Slice_ClientGymStep',
  content?: Maybe<Array<Maybe<ClientGymStep>>>,
  first: Scalars['Boolean'],
  last: Scalars['Boolean'],
  number: Scalars['Int'],
  numberOfElements: Scalars['Int'],
  pageable?: Maybe<Pageable>,
  size: Scalars['Int'],
  sort?: Maybe<Sort>,
};

export type SmartWarmup = {
   __typename?: 'SmartWarmup',
  clientGymStep?: Maybe<ClientGymStep>,
  id?: Maybe<Scalars['UUID']>,
  smartWarmupSets?: Maybe<Array<Maybe<SmartWarmupSet>>>,
  status?: Maybe<CalendarItemStatusType>,
};

export type SmartWarmupInput = {
  clientGymStep?: Maybe<ClientGymStepInput>,
  id?: Maybe<Scalars['UUID']>,
  smartWarmupSets?: Maybe<Array<Maybe<SmartWarmupSetInput>>>,
  status?: Maybe<CalendarItemStatusType>,
};

export type SmartWarmupSet = {
   __typename?: 'SmartWarmupSet',
  id?: Maybe<Scalars['UUID']>,
  mostRecentMaxWeight: Scalars['Float'],
  percentage: Scalars['Float'],
  reps: Scalars['Int'],
  smartWarmup?: Maybe<SmartWarmup>,
  sortOrder: Scalars['Int'],
  status?: Maybe<CalendarItemStatusType>,
};

export type SmartWarmupSetInput = {
  id?: Maybe<Scalars['UUID']>,
  mostRecentMaxWeight: Scalars['Float'],
  percentage: Scalars['Float'],
  reps: Scalars['Int'],
  smartWarmup?: Maybe<SmartWarmupInput>,
  sortOrder: Scalars['Int'],
  status?: Maybe<CalendarItemStatusType>,
};

export type SnoozeResetRule = CaliberRule & {
   __typename?: 'SnoozeResetRule',
  caliberRuleType?: Maybe<RuleType>,
  condition?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['UUID']>,
  lowerLimitParam: Scalars['Int'],
  name?: Maybe<Scalars['String']>,
  objectClassSimpleName?: Maybe<Scalars['String']>,
  objectCrudOperation?: Maybe<CrudOperationType>,
  paramOperator?: Maybe<ComparisonOperatorType>,
  paramUnit?: Maybe<ChronoUnit>,
  ruleAutocompletable: Scalars['Boolean'],
  snoozedTaskSourceRuleName?: Maybe<ReminderRuleType>,
  type?: Maybe<RuleType>,
  upperLimitParam: Scalars['Int'],
};

export type SocialMediaBrand = {
   __typename?: 'SocialMediaBrand',
  active: Scalars['Boolean'],
  faviconUrl?: Maybe<Scalars['String']>,
  friendlyName?: Maybe<Scalars['String']>,
  socialMediaType?: Maybe<SocialMediaType>,
};

export type SocialMediaLink = {
   __typename?: 'SocialMediaLink',
  id?: Maybe<Scalars['UUID']>,
  socialMediaType?: Maybe<SocialMediaType>,
  url?: Maybe<Scalars['String']>,
  username?: Maybe<Scalars['String']>,
};

export enum SocialMediaType {
  Instagram = 'INSTAGRAM',
  Reddit = 'REDDIT',
  Tiktok = 'TIKTOK',
  Twitch = 'TWITCH',
  Twitter = 'TWITTER'
}

export type Sort = {
   __typename?: 'Sort',
  DEFAULT_DIRECTION?: Maybe<Direction>,
  sorted: Scalars['Boolean'],
  unsorted: Scalars['Boolean'],
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum SplitType {
  FullBody = 'FULL_BODY',
  PushPullLegs = 'PUSH_PULL_LEGS',
  UpperLower = 'UPPER_LOWER'
}

export type StrengthScore = {
   __typename?: 'StrengthScore',
  balance: Scalars['Float'],
  delta: Scalars['Int'],
  exerciseLight?: Maybe<ExerciseLight>,
  id?: Maybe<Scalars['UUID']>,
  keyDriverExplanationTextType?: Maybe<KeyDriverExplanationTextType>,
  orm: Scalars['Int'],
  ormDelta: Scalars['Int'],
  scaleWeightMultiplierLevel: Scalars['Int'],
  score: Scalars['Int'],
  strengthBalanceDelta: Scalars['Float'],
  strengthScoreDelta: Scalars['Int'],
  type?: Maybe<StrengthScoreMuscleGroupType>,
};

export type StrengthScoreDashboard = {
   __typename?: 'StrengthScoreDashboard',
  ageDiscount?: Maybe<Scalars['Float']>,
  ageDiscountChanged: Scalars['Boolean'],
  endDate?: Maybe<Scalars['OffsetDateTime']>,
  explanationText?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['UUID']>,
  level: Scalars['Int'],
  muscleGroupScores?: Maybe<Array<Maybe<StrengthScore>>>,
  overallStrengthScore?: Maybe<StrengthScore>,
  startDate?: Maybe<Scalars['OffsetDateTime']>,
  strengthBalanceScore: Scalars['Float'],
};

export type StrengthScoreLight = {
   __typename?: 'StrengthScoreLight',
  balance: Scalars['Float'],
  endDate?: Maybe<Scalars['OffsetDateTime']>,
  score: Scalars['Int'],
};

export enum StrengthScoreMuscleGroupType {
  Arms = 'ARMS',
  Back = 'BACK',
  Chest = 'CHEST',
  Legs = 'LEGS',
  Overall = 'OVERALL',
  Shoulders = 'SHOULDERS',
  Weight = 'WEIGHT'
}

export type Superset = {
   __typename?: 'Superset',
  gymSteps?: Maybe<Array<Maybe<GymStep>>>,
  id?: Maybe<Scalars['UUID']>,
  restPeriod?: Maybe<Scalars['Int']>,
  targetSets?: Maybe<Scalars['Int']>,
};

export type SupersetInput = {
  id?: Maybe<Scalars['UUID']>,
};

export enum SupersetTagType {
  SupersetFive = 'SUPERSET_FIVE',
  SupersetFour = 'SUPERSET_FOUR',
  SupersetOne = 'SUPERSET_ONE',
  SupersetThree = 'SUPERSET_THREE',
  SupersetTwo = 'SUPERSET_TWO'
}

export type SystemUser = User & {
   __typename?: 'SystemUser',
  age?: Maybe<Scalars['Int']>,
  birthDate?: Maybe<Scalars['LocalDate']>,
  city?: Maybe<Scalars['String']>,
  clientAuthId?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  firstName?: Maybe<Scalars['String']>,
  gender?: Maybe<GenderType>,
  height?: Maybe<Scalars['Float']>,
  id?: Maybe<Scalars['UUID']>,
  isConsultationCompleted?: Maybe<Scalars['Boolean']>,
  isProfileCompleted?: Maybe<Scalars['Boolean']>,
  joinedDate?: Maybe<Scalars['OffsetDateTime']>,
  lastName?: Maybe<Scalars['String']>,
  notes?: Maybe<Scalars['String']>,
  parent?: Maybe<HierarchicalDomainModelEntity>,
  phone?: Maybe<Scalars['String']>,
  profileIconMediaUrl?: Maybe<MediaUrl>,
  profileIconUrl?: Maybe<Scalars['String']>,
  sendbirdAccessToken?: Maybe<Scalars['String']>,
  settings?: Maybe<Settings>,
  state?: Maybe<Scalars['String']>,
  status?: Maybe<UserStatusType>,
  timezone?: Maybe<TimeZoneType>,
  type?: Maybe<UserType>,
  userEnabled: Scalars['Boolean'],
};

export type TaskAutocompletionRule = CaliberRule & {
   __typename?: 'TaskAutocompletionRule',
  condition?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['UUID']>,
  lowerLimitParam: Scalars['Int'],
  name?: Maybe<Scalars['String']>,
  objectClassSimpleName?: Maybe<Scalars['String']>,
  objectCrudOperation?: Maybe<CrudOperationType>,
  paramOperator?: Maybe<ComparisonOperatorType>,
  paramUnit?: Maybe<ChronoUnit>,
  ruleAutocompletable: Scalars['Boolean'],
  ruleType?: Maybe<RuleType>,
  taskAutocompletionSourceRuleName?: Maybe<ReminderRuleType>,
  type?: Maybe<RuleType>,
  upperLimitParam: Scalars['Int'],
};

export type TextGoal = Goal & {
   __typename?: 'TextGoal',
  achieved: Scalars['Boolean'],
  client?: Maybe<Client>,
  id?: Maybe<Scalars['UUID']>,
  targetDate?: Maybe<Scalars['LocalDate']>,
  text?: Maybe<Scalars['String']>,
};

export type TimeZoneType = {
   __typename?: 'TimeZoneType',
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['UUID']>,
  offset: Scalars['Int'],
};

export type TimeZoneTypeInput = {
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['UUID']>,
  offset: Scalars['Int'],
};

export type Trainer = User & {
   __typename?: 'Trainer',
  age?: Maybe<Scalars['Int']>,
  assignedClients?: Maybe<Array<Maybe<Client>>>,
  assignedManager?: Maybe<Manager>,
  birthDate?: Maybe<Scalars['LocalDate']>,
  capacity?: Maybe<Scalars['Int']>,
  city?: Maybe<Scalars['String']>,
  clientAuthId?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  enabledFeatures?: Maybe<Array<Maybe<TrainerFeature>>>,
  firstName?: Maybe<Scalars['String']>,
  gender?: Maybe<GenderType>,
  height?: Maybe<Scalars['Float']>,
  id?: Maybe<Scalars['UUID']>,
  isAvailable?: Maybe<Scalars['Boolean']>,
  isConsultationCompleted?: Maybe<Scalars['Boolean']>,
  isProfileCompleted?: Maybe<Scalars['Boolean']>,
  joinedDate?: Maybe<Scalars['OffsetDateTime']>,
  lastActiveDate?: Maybe<Scalars['OffsetDateTime']>,
  lastName?: Maybe<Scalars['String']>,
  notes?: Maybe<Scalars['String']>,
  parent?: Maybe<HierarchicalDomainModelEntity>,
  phone?: Maybe<Scalars['String']>,
  proGroupSortOrder?: Maybe<Scalars['Int']>,
  proGroups?: Maybe<Array<Maybe<ProGroup>>>,
  profileIconMediaUrl?: Maybe<MediaUrl>,
  profileIconUrl?: Maybe<Scalars['String']>,
  sendbirdAccessToken?: Maybe<Scalars['String']>,
  settings?: Maybe<Settings>,
  state?: Maybe<Scalars['String']>,
  status?: Maybe<UserStatusType>,
  timezone?: Maybe<TimeZoneType>,
  type?: Maybe<UserType>,
  userEnabled: Scalars['Boolean'],
};

export enum TrainerFeature {
  WorkoutLibrary = 'WORKOUT_LIBRARY'
}

export type TrainerInput = {
  age: Scalars['Int'],
  assignedClients?: Maybe<Array<Maybe<ClientInput>>>,
  assignedManager?: Maybe<ManagerInput>,
  birthDate?: Maybe<Scalars['LocalDate']>,
  capacity?: Maybe<Scalars['Int']>,
  city?: Maybe<Scalars['String']>,
  clientAuthId?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  enabledFeatures?: Maybe<Array<Maybe<TrainerFeature>>>,
  firstName?: Maybe<Scalars['String']>,
  gender?: Maybe<GenderType>,
  height?: Maybe<Scalars['Float']>,
  id?: Maybe<Scalars['UUID']>,
  isAvailable?: Maybe<Scalars['Boolean']>,
  isConsultationCompleted?: Maybe<Scalars['Boolean']>,
  isProfileCompleted?: Maybe<Scalars['Boolean']>,
  joinedDate?: Maybe<Scalars['OffsetDateTime']>,
  lastActiveDate?: Maybe<Scalars['OffsetDateTime']>,
  lastName?: Maybe<Scalars['String']>,
  notes?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  proGroupSortOrder?: Maybe<Scalars['Int']>,
  proGroups?: Maybe<Array<Maybe<ProGroupInput>>>,
  profileIconMediaUrl?: Maybe<MediaUrlInput>,
  profileIconUrl?: Maybe<Scalars['String']>,
  sendbirdAccessToken?: Maybe<Scalars['String']>,
  settings?: Maybe<SettingsInput>,
  state?: Maybe<Scalars['String']>,
  status?: Maybe<UserStatusType>,
  timezone?: Maybe<TimeZoneTypeInput>,
  type?: Maybe<UserType>,
};

export type TrainerSettings = Settings & {
   __typename?: 'TrainerSettings',
  clientsTabColumnPreferences?: Maybe<Scalars['String']>,
  enableGeneralNotifications?: Maybe<Scalars['Boolean']>,
  enableMessageNotifications?: Maybe<Scalars['Boolean']>,
  firstAutoMessage?: Maybe<Scalars['String']>,
  hasNewNotification?: Maybe<Scalars['Boolean']>,
  id?: Maybe<Scalars['UUID']>,
  secondAutoMessage?: Maybe<Scalars['String']>,
  thirdAutoMessage?: Maybe<Scalars['String']>,
  unitBodystat?: Maybe<UnitType>,
  unitDistance?: Maybe<UnitType>,
  unitWeight?: Maybe<UnitType>,
};

export type TrainerSettingsInput = {
  clientsTabColumnPreferences?: Maybe<Scalars['String']>,
  enableGeneralNotifications?: Maybe<Scalars['Boolean']>,
  enableMessageNotifications?: Maybe<Scalars['Boolean']>,
  firstAutoMessage?: Maybe<Scalars['String']>,
  hasNewNotification?: Maybe<Scalars['Boolean']>,
  id?: Maybe<Scalars['UUID']>,
  secondAutoMessage?: Maybe<Scalars['String']>,
  thirdAutoMessage?: Maybe<Scalars['String']>,
  unitBodystat?: Maybe<UnitType>,
  unitDistance?: Maybe<UnitType>,
  unitWeight?: Maybe<UnitType>,
  user?: Maybe<UserInput>,
};

export enum TrainerSortCriteria {
  Capacity = 'capacity',
  IsAvailable = 'isAvailable',
  LastActiveDate = 'lastActiveDate',
  LastName = 'lastName'
}

export type TrainingPlan = {
  id?: Maybe<Scalars['UUID']>,
  workoutGroups?: Maybe<Array<Maybe<WorkoutGroup>>>,
};

export enum TrainingPlanDurationType {
  Min_30 = 'MIN_30',
  Min_45 = 'MIN_45',
  Min_60 = 'MIN_60',
  Min_75 = 'MIN_75',
  Min_90 = 'MIN_90'
}

export enum TrainingPlanFrequencyType {
  FivePerWeek = 'FIVE_PER_WEEK',
  FourPerWeek = 'FOUR_PER_WEEK',
  ThreePerWeek = 'THREE_PER_WEEK',
  TwoPerWeek = 'TWO_PER_WEEK'
}

export type TrainingPlanInput = {
  id?: Maybe<Scalars['UUID']>,
};

export enum TrainingPlanProgramType {
  Cardio = 'CARDIO',
  Mobility = 'MOBILITY',
  Strength = 'STRENGTH'
}

export enum TrainingPlanRepRangeType {
  Periodized = 'PERIODIZED',
  Range_10To_12 = 'RANGE_10_TO_12',
  Range_4To_6 = 'RANGE_4_TO_6',
  Range_6To_8 = 'RANGE_6_TO_8',
  Range_8To_10 = 'RANGE_8_TO_10'
}

export enum TrainingPlanSortCriteria {
  Duration = 'duration',
  Frequency = 'frequency',
  Gender = 'gender',
  Program = 'program',
  RepRange = 'repRange',
  Type = 'type'
}

export enum TrainingPlanType {
  BarbellDumbbellBench = 'BARBELL_DUMBBELL_BENCH',
  BarbellDumbbellMachineCable = 'BARBELL_DUMBBELL_MACHINE_CABLE',
  BodyWeight = 'BODY_WEIGHT',
  Dumbbell = 'DUMBBELL',
  DumbbellBench = 'DUMBBELL_BENCH',
  DumbbellMachineCable = 'DUMBBELL_MACHINE_CABLE',
  Kettlebell = 'KETTLEBELL',
  MachineCable = 'MACHINE_CABLE',
  PowerRackBarbell = 'POWER_RACK_BARBELL',
  PowerRackBarbellDumbbell = 'POWER_RACK_BARBELL_DUMBBELL',
  ResistanceBand = 'RESISTANCE_BAND',
  SmithDumbbellMachineCable = 'SMITH_DUMBBELL_MACHINE_CABLE',
  Trx = 'TRX'
}

export enum TrainingPlanVersionType {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E'
}

export enum UnitType {
  Cal = 'CAL',
  Cm = 'CM',
  G = 'G',
  In = 'IN',
  Kg = 'KG',
  Km = 'KM',
  Lb = 'LB',
  M = 'M',
  Mi = 'MI',
  Yd = 'YD'
}


export type User = {
  age?: Maybe<Scalars['Int']>,
  birthDate?: Maybe<Scalars['LocalDate']>,
  city?: Maybe<Scalars['String']>,
  clientAuthId?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  firstName?: Maybe<Scalars['String']>,
  gender?: Maybe<GenderType>,
  height?: Maybe<Scalars['Float']>,
  id?: Maybe<Scalars['UUID']>,
  isConsultationCompleted?: Maybe<Scalars['Boolean']>,
  isProfileCompleted?: Maybe<Scalars['Boolean']>,
  joinedDate?: Maybe<Scalars['OffsetDateTime']>,
  lastName?: Maybe<Scalars['String']>,
  notes?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  profileIconMediaUrl?: Maybe<MediaUrl>,
  profileIconUrl?: Maybe<Scalars['String']>,
  sendbirdAccessToken?: Maybe<Scalars['String']>,
  settings?: Maybe<Settings>,
  state?: Maybe<Scalars['String']>,
  status?: Maybe<UserStatusType>,
  timezone?: Maybe<TimeZoneType>,
  type?: Maybe<UserType>,
  userEnabled: Scalars['Boolean'],
};

export type UserInput = {
  age: Scalars['Int'],
  birthDate?: Maybe<Scalars['LocalDate']>,
  city?: Maybe<Scalars['String']>,
  clientAuthId?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  firstName?: Maybe<Scalars['String']>,
  gender?: Maybe<GenderType>,
  height?: Maybe<Scalars['Float']>,
  id?: Maybe<Scalars['UUID']>,
  isConsultationCompleted?: Maybe<Scalars['Boolean']>,
  isProfileCompleted?: Maybe<Scalars['Boolean']>,
  joinedDate?: Maybe<Scalars['OffsetDateTime']>,
  lastName?: Maybe<Scalars['String']>,
  notes?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  profileIconMediaUrl?: Maybe<MediaUrlInput>,
  profileIconUrl?: Maybe<Scalars['String']>,
  sendbirdAccessToken?: Maybe<Scalars['String']>,
  settings?: Maybe<SettingsInput>,
  state?: Maybe<Scalars['String']>,
  status?: Maybe<UserStatusType>,
  timezone?: Maybe<TimeZoneTypeInput>,
  type?: Maybe<UserType>,
};

export type UserNotification = {
  actionText?: Maybe<Scalars['String']>,
  groupChannelUrl?: Maybe<Scalars['String']>,
  groupName?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['UUID']>,
  lastReactorReaction?: Maybe<Scalars['String']>,
  lastReactorsText?: Maybe<Scalars['String']>,
  lastUpdatedDate?: Maybe<Scalars['OffsetDateTime']>,
  messageId: Scalars['Long'],
  messageText?: Maybe<Scalars['String']>,
  user?: Maybe<User>,
  userNotificationReactors?: Maybe<Array<Maybe<UserNotificationReactor>>>,
  userNotificationStatusType?: Maybe<UserNotificationStatusType>,
  userNotificationType?: Maybe<UserNotificationType>,
};

export type UserNotificationReactor = {
   __typename?: 'UserNotificationReactor',
  id?: Maybe<Scalars['UUID']>,
  reaction?: Maybe<Scalars['String']>,
  user?: Maybe<User>,
  userNotification?: Maybe<UserNotification>,
};

export enum UserNotificationStatusType {
  Read = 'READ',
  Unread = 'UNREAD'
}

export enum UserNotificationType {
  Mention = 'MENTION',
  MessageReaction = 'MESSAGE_REACTION',
  PhotoReaction = 'PHOTO_REACTION',
  Reply = 'REPLY',
  VideoReaction = 'VIDEO_REACTION'
}

export enum UserStatusType {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Inactive = 'INACTIVE',
  OnHold = 'ON_HOLD',
  PausedSubscription = 'PAUSED_SUBSCRIPTION',
  PendingCancellation = 'PENDING_CANCELLATION',
  TrialUser = 'TRIAL_USER'
}

export enum UserType {
  Admin = 'ADMIN',
  Client = 'CLIENT',
  Manager = 'MANAGER',
  SalesRep = 'SALES_REP',
  System = 'SYSTEM',
  Trainer = 'TRAINER'
}


export type VideoLesson = {
   __typename?: 'VideoLesson',
  chapters?: Maybe<Array<Maybe<VideoLessonChapter>>>,
  id?: Maybe<Scalars['UUID']>,
  thumbnailUrl?: Maybe<MediaUrl>,
  videoLessonUrl?: Maybe<MediaUrl>,
};

export type VideoLessonChapter = {
   __typename?: 'VideoLessonChapter',
  id?: Maybe<Scalars['UUID']>,
  marker: Scalars['Int'],
  number: Scalars['Int'],
  title?: Maybe<Scalars['String']>,
};

export type VideoLessonChapterInput = {
  id?: Maybe<Scalars['UUID']>,
  marker: Scalars['Int'],
  number: Scalars['Int'],
  title?: Maybe<Scalars['String']>,
};

export type VideoLessonInput = {
  chapters?: Maybe<Array<Maybe<VideoLessonChapterInput>>>,
  id?: Maybe<Scalars['UUID']>,
  thumbnailUrl?: Maybe<MediaUrlInput>,
  videoLessonUrl?: Maybe<MediaUrlInput>,
};

export type VideoMessage = {
   __typename?: 'VideoMessage',
  chatRoom?: Maybe<ChatRoom>,
  client?: Maybe<Client>,
  id?: Maybe<Scalars['UUID']>,
  sendbirdMessageId?: Maybe<Scalars['Long']>,
  videoMessageMediaUrl?: Maybe<MediaUrl>,
};

export type VideoMessageInput = {
  chatRoom?: Maybe<ChatRoomInput>,
  client?: Maybe<ClientInput>,
  id?: Maybe<Scalars['UUID']>,
  sendbirdMessageId?: Maybe<Scalars['Long']>,
  videoMessageMediaUrl?: Maybe<MediaUrlInput>,
};

export enum VideoProcessingStatusType {
  Error = 'ERROR',
  New = 'NEW',
  Processing = 'PROCESSING',
  Ready = 'READY'
}

export enum WebDeepLinkScreenType {
  ClientDashboard = 'CLIENT_DASHBOARD',
  ClientProfile = 'CLIENT_PROFILE',
  ClientTrainingProgram = 'CLIENT_TRAINING_PROGRAM',
  Messages = 'MESSAGES',
  NutritionProgram = 'NUTRITION_PROGRAM',
  Progress = 'PROGRESS'
}

export type WeightGoal = Goal & {
   __typename?: 'WeightGoal',
  achieved: Scalars['Boolean'],
  client?: Maybe<Client>,
  currentWeight: Scalars['Float'],
  id?: Maybe<Scalars['UUID']>,
  mostRecentWeight: Scalars['Float'],
  progress: Scalars['Float'],
  progressCalculated: Scalars['Float'],
  targetDate?: Maybe<Scalars['LocalDate']>,
  weightGoal: Scalars['Float'],
};

export enum WeightUnitType {
  Kg = 'KG',
  Lb = 'LB'
}

export type WooCommerceDateInput = {
  date?: Maybe<Scalars['OffsetDateTime']>,
  type?: Maybe<WooCommerceDateType>,
};

export enum WooCommerceDateType {
  CancelledDate = 'CANCELLED_DATE',
  EndDate = 'END_DATE',
  NextPaymentDate = 'NEXT_PAYMENT_DATE',
  StartDate = 'START_DATE',
  TrialEndDate = 'TRIAL_END_DATE'
}

export type Workout = {
  gymSteps?: Maybe<Array<Maybe<GymStep>>>,
  id?: Maybe<Scalars['UUID']>,
  instructions?: Maybe<Scalars['String']>,
  sortOrder: Scalars['Int'],
  thumbnailImage?: Maybe<MediaUrl>,
  title?: Maybe<Scalars['String']>,
};

export type WorkoutCalendarItem = CalendarItem & {
   __typename?: 'WorkoutCalendarItem',
  client?: Maybe<Client>,
  clientWorkout?: Maybe<ClientWorkout>,
  clientWorkoutDate?: Maybe<Scalars['LocalDate']>,
  date?: Maybe<Scalars['LocalDate']>,
  daySortOrder: Scalars['Int'],
  endTime?: Maybe<Scalars['OffsetDateTime']>,
  enumType?: Maybe<CalendarItemType>,
  id?: Maybe<Scalars['UUID']>,
  includeInStrengthMetrics?: Maybe<Scalars['Boolean']>,
  notes?: Maybe<Scalars['String']>,
  parent?: Maybe<HierarchicalDomainModelEntity>,
  rating?: Maybe<Scalars['Int']>,
  recurring: Scalars['Boolean'],
  recurringEventDefinition?: Maybe<RecurringEventDefinition>,
  scheduledBy?: Maybe<CalendarItemScheduledByType>,
  sort: Scalars['Int'],
  startTime?: Maybe<Scalars['OffsetDateTime']>,
  status?: Maybe<CalendarItemStatusType>,
  title?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
  workoutDurationSeconds?: Maybe<Scalars['Long']>,
};

export type WorkoutGroup = {
  id?: Maybe<Scalars['UUID']>,
  sortOrder: Scalars['Int'],
  workouts?: Maybe<Array<Maybe<Workout>>>,
};

export type WorkoutGroupInput = {
  id?: Maybe<Scalars['UUID']>,
  sortOrder: Scalars['Int'],
};

export enum WorkoutGroupType {
  Freestyle = 'FREESTYLE',
  Gym = 'GYM',
  Home = 'HOME',
  Pro = 'PRO',
  Travel = 'TRAVEL'
}

export type WorkoutInput = {
  id?: Maybe<Scalars['UUID']>,
  instructions?: Maybe<Scalars['String']>,
  sortOrder: Scalars['Int'],
  title?: Maybe<Scalars['String']>,
};

export type WorkoutSharingNotification = UserNotification & {
   __typename?: 'WorkoutSharingNotification',
  actionText?: Maybe<Scalars['String']>,
  groupChannelUrl?: Maybe<Scalars['String']>,
  groupName?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['UUID']>,
  lastReactorReaction?: Maybe<Scalars['String']>,
  lastReactorsText?: Maybe<Scalars['String']>,
  lastUpdatedDate?: Maybe<Scalars['OffsetDateTime']>,
  messageId: Scalars['Long'],
  messageText?: Maybe<Scalars['String']>,
  templateId?: Maybe<Scalars['UUID']>,
  user?: Maybe<User>,
  userNotificationReactors?: Maybe<Array<Maybe<UserNotificationReactor>>>,
  userNotificationStatusType?: Maybe<UserNotificationStatusType>,
  userNotificationType?: Maybe<UserNotificationType>,
  workoutSharingNotificationType?: Maybe<WorkoutSharingNotificationType>,
};

export enum WorkoutSharingNotificationType {
  WorkoutGroupReceived = 'WORKOUT_GROUP_RECEIVED',
  WorkoutReceived = 'WORKOUT_RECEIVED'
}

export enum WorkoutSyncSourceType {
  Caliber = 'CALIBER',
  Cronometer = 'CRONOMETER',
  GoogleFit = 'GOOGLE_FIT',
  Healthkit = 'HEALTHKIT',
  SamsungHealth = 'SAMSUNG_HEALTH'
}

export type ZoomCall = {
   __typename?: 'ZoomCall',
  client?: Maybe<Client>,
  date?: Maybe<Scalars['OffsetDateTime']>,
  id?: Maybe<Scalars['UUID']>,
  status?: Maybe<CalendarItemStatusType>,
  trainer?: Maybe<Trainer>,
  type?: Maybe<ZoomCallType>,
};

export enum ZoomCallType {
  Intro = 'INTRO',
  Strategy = 'STRATEGY'
}
