import { format, getDate, isAfter, isSameDay } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { useDrop } from "react-dnd";
import colors from "../../assets/colors";
import PlusIcon from "../../assets/images/PlusIcon.svg";
import {
  CalendarDay,
  CalendarItem,
  CalendarItemType,
} from "../../types/gqlTypes";
import DraggableCalendarItemComponent from "./DraggableCalendarItem";

interface Props {
  isCurrentMonth: boolean;
  day: Date;
  calendarDay: CalendarDay;
  isToday: any;
  index: number;
  dateFormat: string;
  columns: number;
  maxItemsShown: number;
  onDropCalendarItem: (
    calendarItemId: string,
    oldCalendarDayId: string,
    date: Date
  ) => void;
  scheduleEvent: () => void;
  viewFullDay: () => void;
  viewEvent: (item: CalendarItem, dayId: string) => void;
  onDelete: (item: CalendarItem, date: Date) => void;
}

const CalendarDayComponent = (props: Props) => {
  const {
    isCurrentMonth,
    day,
    isToday,
    calendarDay,
    index,
    dateFormat,
    columns,
    maxItemsShown,
    onDropCalendarItem,
    scheduleEvent,
    viewEvent,
    viewFullDay,
    onDelete,
  } = props;
  const [hovered, setHovered] = useState(false);
  const [localItems, setLocalItems] = useState<CalendarItem[]>(null);

  const getShownItems = (calendarDay: CalendarDay): CalendarItem[] => {
    return calendarDay?.items
      .sort((item1, item2) => item1.daySortOrder - item2.daySortOrder)
      .slice(0, maxItemsShown);
  };

  const onDrop = (item, monitor) => {
    const { calendarItemId, oldCalendarDay } = item;
    const date = format(day, "yyyy-MM-dd");
    let moved = false;
    if (date !== oldCalendarDay.date) {
      onDropCalendarItem(calendarItemId, oldCalendarDay.id, day);
      moved = true;
    }
    return {
      moved,
      calendarItemId,
      oldCalendarDay,
      oldDate: oldCalendarDay.date,
    };
  };

  const [{ isOver, didDrop, res }, drop] = useDrop({
    accept: "calendar",
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      didDrop: monitor.didDrop(),
      res: monitor.getDropResult(),
    }),
  });

  useEffect(() => {
    setLocalItems(getShownItems(calendarDay));
  }, [calendarDay, didDrop]);

  if (res?.moved && res?.oldDate === format(day, "yyyy-MM-dd")) {
    const index = localItems.findIndex(
      (item) => item.id === res?.calendarItemId
    );
    if (index !== -1) {
      setLocalItems(localItems.filter((item, ind) => ind !== index));
    }
  }

  const isPast = useMemo(() => {
    return !(isAfter(day, new Date()) || isSameDay(new Date(), day));
  }, [day]);

  let backgroundColor = colors.caliber_white;
  if (isToday) {
    backgroundColor = colors.caliber_gray_bg;
  }

  return (
    <div
      onMouseEnter={() => {
        if (!isOver && !isPast) {
          setHovered(true);
        }
      }}
      onMouseLeave={() => {
        if (!isOver && !isPast) {
          setHovered(false);
        }
      }}
      ref={drop}
      className="d-flex flex-column"
      key={`div_${format(day, dateFormat)}`}
      style={{
        position: "relative",
        flex: `0 0 ${100 / columns}%`,
        height: `${100 / 5}%`,
        minWidth: 0,
        backgroundColor,

        /* Only First Row has Top Border */
        borderTopStyle: index < columns ? "solid" : "none",
        borderRightStyle: "solid",
        borderBottomStyle: "solid",
        /* Only First Column has Left Border */
        borderLeftStyle: index % columns === 0 ? "solid" : "none",
        boxSizing: "border-box",
        borderWidth: "2px",
        borderColor: colors.caliber_gray_bg,
      }}
    >
      <div
        className="flex-grow-0 heading-tiny align-self-end"
        style={{
          marginRight: 6,
          marginTop: 4,
          color: isCurrentMonth
            ? colors.caliber_gray_text
            : colors.caliber_gray_border,
        }}
      >
        {getDate(day)}
      </div>
      <div style={{ flex: "1", opacity: isOver ? 0 : 1 }}>
        {localItems?.map((item) => {
          const isDraggable =
            item?.enumType === CalendarItemType.Workout ||
            item?.enumType === CalendarItemType.CardioActivity ||
            item?.enumType === CalendarItemType.BodyStat ||
            item?.enumType === CalendarItemType.ProgressPic ||
            item?.enumType === CalendarItemType.Steps;
          return (
            <DraggableCalendarItemComponent
              isDraggable={isDraggable}
              calendarDay={calendarDay}
              onDelete={onDelete}
              key={item.id}
              viewEvent={() => viewEvent(item, calendarDay?.id)}
              date={day}
              item={item}
            />
          );
        })}
        {calendarDay?.items.length > maxItemsShown && (
          <div
            role="button"
            tabIndex={0}
            onClick={viewFullDay}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                viewFullDay();
              }
            }}
            className="pointer nofocus paragraph-small"
            style={{
              paddingLeft: 15,
              position: "absolute",
              bottom: 5,
            }}
          >
            {`${calendarDay?.items.length - maxItemsShown} more`}
          </div>
        )}
      </div>
      {hovered && !isPast && (
        <div
          role="button"
          tabIndex={0}
          onClick={scheduleEvent}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              scheduleEvent();
            }
          }}
          className="pointer nofocus"
          style={{
            position: "absolute",
            margin: 4,
            bottom: 0,
            right: 0,
          }}
        >
          <img src={PlusIcon} />
        </div>
      )}
    </div>
  );
};
export default CalendarDayComponent;
