import { MutationOptions, OperationVariables } from "apollo-client";
import { DocumentNode } from "graphql";
import apolloClient from "../../../api/apolloClient";
import {
  ADD_CHILD_TO_SUPERSET,
  CONVERT_GYM_STEP_TO_SUPERSET,
  DELETE_CHILD_FROM_SUPERSET,
  MOVE_SUPERSET_CHILD_TO_WORKOUT,
  MOVE_WORKOUT_CHILD_TO_SUPERSET,
  REORDER_CHILDREN_IN_SUPERSET,
  UPDATE_SUPERSET,
  UPDATE_SUPERSET_CHILD,
} from "../../../api/gql/trainingPrograms/supersets";
import {
  ClientGymStepTemplate,
  ClientSupersetGymStep,
  ClientWorkoutTemplate,
  GymStep,
  MasterGymStep,
  MasterSupersetGymStep,
  MasterWorkout,
  MutationAddChildToSupersetArgs,
  MutationConvertGymStepToSupersetArgs,
  MutationDeleteChildFromSupersetArgs,
  MutationMoveSupersetChildToWorkoutArgs,
  MutationMoveWorkoutChildToSupersetArgs,
  MutationReorderChildrenInSupersetArgs,
  MutationUpdateSupersetArgs,
  MutationUpdateSupersetChildArgs,
  Workout,
} from "../../../types/gqlTypes";
import { getTrainer } from "../../reducers";
import { ClientDetailState } from "../ClientDetail/types";
import {
  CREATE_SUPERSET_FAIL,
  CREATE_SUPERSET_LOADING,
  CREATE_SUPERSET_SUCCESS,
  TrainingProgramsAction,
  UPDATE_GYM_STEP_FAIL,
  UPDATE_GYM_STEP_LOADING,
  UPDATE_GYM_STEP_SUCCESS,
  UPDATE_WORKOUT_FAIL,
  UPDATE_WORKOUT_LOADING,
  UPDATE_WORKOUT_SUCCESS,
} from "./types";
import { ProgramType } from "../../../components/TrainingProgram";

export const updateWorkoutApi =
  (args: OperationVariables, gql: DocumentNode, objectName: string) =>
  async (dispatch, getState) => {
    if (getState().trainingProgramsState.isSaving) {
      return;
    }
    dispatch({ type: UPDATE_WORKOUT_LOADING });
    try {
      const apollo = await apolloClient(
        getState().authState.authToken,
        dispatch
      );

      const { user: selectedUser }: ClientDetailState =
        getState().clientDetailState;
      const result: MasterWorkout | ClientWorkoutTemplate = (
        await apollo.mutate({
          mutation: gql,
          variables: {
            ...args,
            clientId: selectedUser.id,
            trainerId: getTrainer(getState())?.id,
          },
        } as MutationOptions)
      ).data[objectName] as Workout;

      dispatch({
        type: UPDATE_WORKOUT_SUCCESS,
        workout: result,
      } as TrainingProgramsAction);
    } catch (error) {
      dispatch({
        type: UPDATE_WORKOUT_FAIL,
        data: error,
      });
    }
  };

export const createSupersetApi =
  (
    args: OperationVariables,
    index: number,
    gql: DocumentNode,
    objectName: string
  ) =>
  async (dispatch, getState) => {
    if (getState().trainingProgramsState.isSaving) {
      return;
    }
    dispatch({ type: CREATE_SUPERSET_LOADING });
    try {
      const apollo = await apolloClient(
        getState().authState.authToken,
        dispatch
      );

      const { user: selectedUser }: ClientDetailState =
        getState().clientDetailState;
      const result: MasterWorkout | ClientWorkoutTemplate = (
        await apollo.mutate({
          mutation: gql,
          variables: {
            ...args,
            clientId: selectedUser.id,
            trainerId: getTrainer(getState())?.id,
          },
        } as MutationOptions)
      ).data[objectName] as Workout;

      dispatch({
        type: CREATE_SUPERSET_SUCCESS,
        workout: result,
        convertedSupersetId: result.gymSteps[index].id,
      } as TrainingProgramsAction);
    } catch (error) {
      dispatch({
        type: CREATE_SUPERSET_FAIL,
        data: error,
      });
    }
  };

export const updateGymStepApi =
  (
    args: OperationVariables,
    gql: DocumentNode,
    objectName: string,
    secondLevelObjectName?: string
  ) =>
  async (dispatch, getState) => {
    if (getState().trainingProgramsState.isSaving) {
      return;
    }
    dispatch({ type: UPDATE_GYM_STEP_LOADING });
    try {
      const apollo = await apolloClient(
        getState().authState.authToken,
        dispatch
      );

      const { user: selectedUser }: ClientDetailState =
        getState().clientDetailState;
      let result:
        | MasterGymStep
        | ClientGymStepTemplate
        | MasterSupersetGymStep
        | ClientSupersetGymStep = (
        await apollo.mutate({
          mutation: gql,
          variables: {
            ...args,
            clientId: selectedUser.id,
            trainerId: getTrainer(getState())?.id,
          },
        } as MutationOptions)
      ).data[objectName] as GymStep;

      if (secondLevelObjectName) {
        result = result[secondLevelObjectName];
      }

      dispatch({
        type: UPDATE_GYM_STEP_SUCCESS,
        gymStep: result,
      } as TrainingProgramsAction);
    } catch (error) {
      dispatch({
        type: UPDATE_GYM_STEP_FAIL,
        data: error,
      });
    }
  };

/*
  Converts a superset AND opens the editor
*/
export const convertGymStepToSuperset =
  (args: MutationConvertGymStepToSupersetArgs, index: number) =>
  async (dispatch, getState) => {
    dispatch(
      createSupersetApi(
        args,
        index,
        CONVERT_GYM_STEP_TO_SUPERSET,
        "convertGymStepToSuperset"
      )
    );
  };

export const moveSupersetChildToWorkout =
  (args: MutationMoveSupersetChildToWorkoutArgs) =>
  async (dispatch, getState) => {
    dispatch(
      updateWorkoutApi(
        args,
        MOVE_SUPERSET_CHILD_TO_WORKOUT,
        "moveSupersetChildToWorkout"
      )
    );
  };

export const addChildToSuperset =
  (args: MutationAddChildToSupersetArgs, programType?: ProgramType) =>
  async (dispatch, getState) => {
    if (programType === ProgramType.Library) {
      args = { ...args, isLibraryTrainingPlan: true };
    }
    dispatch(
      updateWorkoutApi(args, ADD_CHILD_TO_SUPERSET, "addChildToSuperset")
    );
  };

export const reorderChildrenInSuperset =
  (args: MutationReorderChildrenInSupersetArgs) =>
  async (dispatch, getState) => {
    dispatch(
      updateWorkoutApi(
        args,
        REORDER_CHILDREN_IN_SUPERSET,
        "reorderChildrenInSuperset"
      )
    );
  };

export const deleteChildFromSuperset =
  (args: MutationDeleteChildFromSupersetArgs, programType?: ProgramType) =>
  async (dispatch, getState) => {
    if (programType === ProgramType.Library) {
      args = { ...args, isLibraryTrainingPlan: true };
    }
    dispatch(
      updateWorkoutApi(
        args,
        DELETE_CHILD_FROM_SUPERSET,
        "deleteChildFromSuperset"
      )
    );
  };

export const updateSuperset =
  (args: MutationUpdateSupersetArgs) => async (dispatch, getState) => {
    dispatch(updateGymStepApi(args, UPDATE_SUPERSET, "updateSuperset"));
  };

export const updateSupersetChild =
  (args: MutationUpdateSupersetChildArgs) => async (dispatch, getState) => {
    dispatch(
      updateGymStepApi(
        args,
        UPDATE_SUPERSET_CHILD,
        "updateSupersetChild",
        "supersetGymStep"
      )
    );
  };

export const moveWorkoutChildToSuperset =
  (args: MutationMoveWorkoutChildToSupersetArgs) =>
  async (dispatch, getState) => {
    dispatch(
      updateWorkoutApi(
        args,
        MOVE_WORKOUT_CHILD_TO_SUPERSET,
        "moveWorkoutChildToSuperset"
      )
    );
  };
