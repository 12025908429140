import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import { StoreState } from "../../redux/reducers";
import { ContentLessonSet, ContentWeek } from "../../types/gqlTypes";
import colors from "../../assets/colors";
import LessonItem from "./LessonItem";
import LessonContentModal from "./LessonContentModal";

interface Props {
  weeklyContent: ContentWeek;
  isCompleted: boolean;
}

const LessonList = (props: Props) => {
  const { weeklyContent, isCompleted } = props;

  const [showModal, toggleModal] = useState<ContentLessonSet>(null);

  const items = useMemo(() => {
    return (
      weeklyContent?.weekItems?.filter((item) => {
        if (isCompleted) {
          return item.read;
        }
        return !item.read;
      }) || []
    );
  }, [isCompleted, weeklyContent]);

  return (
    <div className="d-flex flex-column">
      {showModal && (
        <LessonContentModal
          weeklyItem={showModal}
          onClose={() => toggleModal(null)}
        />
      )}

      <div className="d-flex">
        <div
          className="d-flex justify-content-center align-items-center bold"
          style={{
            fontSize: "12px",
            lineHeight: "14px",
            letterSpacing: "-0.01em",
            color: colors.caliber_secondary,
            backgroundColor: colors.caliber_secondary_gray_5,
            borderRadius: 4,
            width: 115,
            height: 18,
            marginBottom: 12,
            marginLeft: 6,
          }}
        >
          {isCompleted ? "Completed lessons" : "Incomplete lessons"}
        </div>
      </div>

      <div
        className="d-flex flex-column"
        style={{
          height: 256,
          width: 368,
          overflowY: "auto",
        }}
      >
        {items.map((item: ContentLessonSet, index) => {
          return (
            <div
              key={item?.id}
              className="d-flex"
              style={{
                marginTop: index === 0 ? 0 : 4,
              }}
            >
              <LessonItem
                weeklyItem={item}
                onOpenModal={() => toggleModal(item)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({});
const mapStateToProps = (state: StoreState) => ({
  weeklyContent: state.weeklyContentState.data,
  isLoading: state.weeklyContentState.isLoading,
  client: state.clientDetailState.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(LessonList);
