import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { StoreState } from '../../../redux/reducers';
import { RecentEmojisState } from '../../../redux/actions/RecentEmojis/types';
import colors from '../../../assets/colors';
import SimpleButton from '../../SimpleButton';
import EmojiRecentSelected from '../../../assets/images/emoji_recent_selected.svg';
import EmojiRecentUnselected from '../../../assets/images/emoji_recent_unselected.svg';
import EmojiSmileySelected from '../../../assets/images/emoji_smileys_people_selected.svg';
import EmojiSmileyUnselected from '../../../assets/images/emoji_smileys_people_unselected.svg';
import EmojisNatureSelected from '../../../assets/images/emoji_nature_selected.svg';
import EmojisNatureUnselected from '../../../assets/images/emoji_nature_unselected.svg';
import EmojisFoodSelected from '../../../assets/images/emoji_food_selected.svg';
import EmojisFoodUnselected from '../../../assets/images/emoji_food_unselected.svg';
import EmojisActivitiesSelected from '../../../assets/images/emoji_activities_selected.svg';
import EmojisActivitiesUnselected from '../../../assets/images/emoji_activities_unselected.svg';
import EmojisTravelSelected from '../../../assets/images/emoji_travel_selected.svg';
import EmojisTravelUnselected from '../../../assets/images/emoji_travel_unselected.svg';
import EmojisObjectsSelected from '../../../assets/images/emoji_objects_selected.svg';
import EmojisObjectsUnselected from '../../../assets/images/emoji_objects_unselected.svg';
import EmojisSymbolsSelected from '../../../assets/images/emoji_symbols_selected.svg';
import EmojisSymbolsUnselected from '../../../assets/images/emoji_symbols_unselected.svg';
import EmojisFlagsSelected from '../../../assets/images/emoji_flags_selected.svg';
import EmojisFlagsUnselected from '../../../assets/images/emoji_flags_unselected.svg';
import {
  activityEmojis,
  animalsAndNatureEmojis,
  flagsEmojis,
  foodAndDrinkEmojis,
  objectsEmojis,
  smileyAndPeopleEmojis,
  symbolsEmojis,
  travelAndPlacesEmojis
} from './emojis';

enum EmojiRowType {
  HEADER,
  EMPTY_STATE,
  EMOJIS,
}

interface EmojiRow {
  type: EmojiRowType,
  header?: string,
  sectionIndex?: number,
  emojis?: string[],
}

// Split emojis into rows of 7 emojis
const splitEmojis = (emojis: string[]) => {
  if (emojis.length === 0) return [];

  const rows = [];
  for (let i = 0; i < emojis.length; i += 7) {
    rows.push(emojis.slice(i, i + 7));
  }
  const lastRow = rows[rows.length - 1];
  if (lastRow.length < 7) {
    const emptySpaces = 7 - lastRow.length;
    for (let i = 0; i < emptySpaces; i++) {
      lastRow.push('');
    }
  }
  return rows;
}

interface Props {
  onEmojiClick: (emojiKey: string) => void,
  recentEmojis: RecentEmojisState,
}

const EmojiPickerPopup = (props: Props) => {
  const {
    onEmojiClick,
    recentEmojis,
  } = props;

  const [activeTab, setActiveTab] = useState(0);
  // Populate sectionRefs with 9 useRef(null)

  const sectionHeaderRefs = {
    1: useRef(null),
    2: useRef(null),
    3: useRef(null),
    4: useRef(null),
    5: useRef(null),
    6: useRef(null),
    7: useRef(null),
    8: useRef(null),
    9: useRef(null),
  };

  const sectionRefs = useRef([]);

  const emojiViewData = useMemo(() => {
    const emojiViewData: EmojiRow[] = [];

    emojiViewData.push({
      type: EmojiRowType.HEADER,
      header: 'Recently Used',
      sectionIndex: 0,
    });
    splitEmojis(recentEmojis.recentEmojis).forEach((row) => {
      emojiViewData.push({
        type: EmojiRowType.EMOJIS,
        emojis: row,
        sectionIndex: 0,
      });
    });
    if (recentEmojis.recentEmojis.length === 0) {
      emojiViewData.push({
        type: EmojiRowType.EMPTY_STATE,
        sectionIndex: 0,
      });
    }

    emojiViewData.push({
      type: EmojiRowType.HEADER,
      header: 'Smileys & People',
      sectionIndex: 1,
    });
    splitEmojis(smileyAndPeopleEmojis()).forEach((row) => {
      emojiViewData.push({
        type: EmojiRowType.EMOJIS,
        emojis: row,
        sectionIndex: 1,
      });
    });

    emojiViewData.push({
      type: EmojiRowType.HEADER,
      header: 'Animals & Nature',
      sectionIndex: 2,
    });
    splitEmojis(animalsAndNatureEmojis()).forEach((row) => {
      emojiViewData.push({
        type: EmojiRowType.EMOJIS,
        emojis: row,
        sectionIndex: 2,
      });
    });

    emojiViewData.push({
      type: EmojiRowType.HEADER,
      header: 'Food & Drink',
      sectionIndex: 3,
    });
    splitEmojis(foodAndDrinkEmojis()).forEach((row) => {
      emojiViewData.push({
        type: EmojiRowType.EMOJIS,
        emojis: row,
        sectionIndex: 3,
      });
    });

    emojiViewData.push({
      type: EmojiRowType.HEADER,
      header: 'Activities',
      sectionIndex: 4,
    });
    splitEmojis(activityEmojis()).forEach((row) => {
      emojiViewData.push({
        type: EmojiRowType.EMOJIS,
        emojis: row,
        sectionIndex: 4,
      });
    });

    emojiViewData.push({
      type: EmojiRowType.HEADER,
      header: 'Travel & Places',
      sectionIndex: 5,
    });
    splitEmojis(travelAndPlacesEmojis()).forEach((row) => {
      emojiViewData.push({
        type: EmojiRowType.EMOJIS,
        emojis: row,
        sectionIndex: 5,
      });
    });

    emojiViewData.push({
      type: EmojiRowType.HEADER,
      header: 'Objects',
      sectionIndex: 6,
    });
    splitEmojis(objectsEmojis()).forEach((row) => {
      emojiViewData.push({
        type: EmojiRowType.EMOJIS,
        emojis: row,
        sectionIndex: 6,
      });
    });

    emojiViewData.push({
      type: EmojiRowType.HEADER,
      header: 'Symbols',
      sectionIndex: 7,
    });
    splitEmojis(symbolsEmojis()).forEach((row) => {
      emojiViewData.push({
        type: EmojiRowType.EMOJIS,
        emojis: row,
        sectionIndex: 7,
      });
    });

    emojiViewData.push({
      type: EmojiRowType.HEADER,
      header: 'Flags',
      sectionIndex: 8,
    });
    splitEmojis(flagsEmojis()).forEach((row) => {
      emojiViewData.push({
        type: EmojiRowType.EMOJIS,
        emojis: row,
        sectionIndex: 8,
      });
    });

    return emojiViewData;
  }, []);


  useLayoutEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries, observer) => {

      let foundSection = false;
      entries.forEach((entry) => {
        if (entry.isIntersecting && !foundSection) {
          foundSection = true;

          const classNames = entry.target.classList;
          const sectionClass = Array.from(classNames).find(className => className.startsWith('emoji-section-'));
          if (sectionClass) {
            const sectionKey = parseInt(sectionClass.replace('emoji-section-', ''));
            setActiveTab(sectionKey);
          }
        }
      });
    }, options);

    sectionRefs.current.forEach((element, index) => {
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      sectionRefs.current.forEach((element) => {
        if (element) {
          observer.unobserve(element);
        }
      });
    };

  }, []);

  const scrollToSection = (section: number) => {
    setActiveTab(section);
    sectionHeaderRefs[section + 1].current?.scrollIntoView();
  }

  const bottomNavImageSize = 17;

  return (
    <div
      className="d-flex flex-column"
      style={{
        width: 289,
        height: 377,
        borderRadius: 6,
        backgroundColor: colors.caliber_white,
        boxShadow: '0px 0px 10px 0px #B9B9B9',
        zIndex: 10,
      }}
    >
      {/* Top Header */}
      <div
        className={'d-flex flex-column flex-wrap justify-content-center align-content-center circular-bold-font'}
        style={{
          color: colors.caliber_blue,
          fontSize: '11px',
          height: '40px',
        }}
      >Emoji
      </div>
      <div
        style={{
          width: '100%',
          height: 1,
          backgroundColor: '#0000000D',
        }}
      />

      {/* Emojis */}
      <div
        className="d-flex flex-column"
        style={{
          flex: 1,
          overflowY: 'scroll',
          paddingTop: 8,
        }}
      >
        {emojiViewData.map((row, index) => {
          if (row.type === EmojiRowType.HEADER) {
            return (
              <div
                ref={sectionHeaderRefs[row.sectionIndex + 1]}
                key={row.header}
                className={'circular-bold-font'}
                style={{
                  color: colors.caliber_blue,
                  fontSize: '11px',
                  marginLeft: 18,
                  paddingTop: 7,
                  paddingBottom: 7,
                }}
              >
                {row.header}
              </div>
            );
          }

          if (row.type === EmojiRowType.EMPTY_STATE) {
            return (
              <div
                className={`d-flex circular-font justify-content-center emoji-section-${row.sectionIndex}`}
                ref={(ref) => {
                  sectionRefs.current[index] = ref;
                }}
                key={`empty-state_${row.sectionIndex}`}
                style={{
                  fontSize: '11px',
                  marginTop: 7,
                  marginBottom: 7,
                  color: '#00063266',
                }}
              >
                No recently used emojis to display.
              </div>
            );
          }

          if (row.type === EmojiRowType.EMOJIS) {
            return (
              <div
                className={`d-flex flex-row emoji-section-${row.sectionIndex}`}
                ref={(ref) => {
                  sectionRefs.current[index] = ref;
                }}
                key={`emojis_${row.sectionIndex}_row_${index}`}
                style={{
                  justifyContent: 'space-between',
                  marginLeft: 18,
                  marginRight: 18,
                }}
              >
                {row.emojis?.map((emoji, index) => {
                  return (
                    <SimpleButton
                      className={'emojiButton'}
                      key={`emoji_${emoji}_section_${row.sectionIndex}_row_${index}`}
                      style={{
                        width: '26px',
                        fontSize: '22px',
                        marginBottom: 8,
                      }}
                      onClick={() => onEmojiClick(emoji)}>
                      {emoji}
                    </SimpleButton>
                  )
                })}
              </div>
            )
          }

          return null;
        })}
      </div>

      {/* Bottom Emoji Tabs */}
      <div
        style={{
          width: '100%',
          height: 1,
          backgroundColor: '#0000000D',
        }}
      />
      <div
        className="d-flex"
        style={{
          marginLeft: 12,
          marginRight: 12,
        }}
      >
        <div
          className="d-flex flex-row"
          style={{
            flex: 1,
            marginTop: 11,
            marginBottom: 21,
            height: 12,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <SimpleButton
            className={'emojiButton'}
            onClick={() => {
              scrollToSection(0);
            }}>
            <img
              style={{
                width: bottomNavImageSize,
                height: bottomNavImageSize,
              }}
              src={activeTab === 0 ? EmojiRecentSelected : EmojiRecentUnselected}
            />
          </SimpleButton>
          <div
            style={{
              width: 1,
              backgroundColor: '#0000000D',
              height: 9,
            }}
          />

          <SimpleButton
            className={'emojiButton'}
            onClick={() => {
              scrollToSection(1);
            }}>
            <img
              style={{
                width: bottomNavImageSize,
                height: bottomNavImageSize,
              }}
              src={activeTab === 1 ? EmojiSmileySelected : EmojiSmileyUnselected}
            />
          </SimpleButton>
          <div
            style={{
              width: 1,
              backgroundColor: '#0000000D',
              height: 9,
            }}
          />

          <SimpleButton
            className={'emojiButton'}
            onClick={() => {
              scrollToSection(2);
            }}>
            <img
              style={{
                width: bottomNavImageSize,
                height: bottomNavImageSize,
              }}
              src={activeTab === 2 ? EmojisNatureSelected : EmojisNatureUnselected}
            />
          </SimpleButton>
          <div
            style={{
              width: 1,
              backgroundColor: '#0000000D',
              height: 9,
            }}
          />

          <SimpleButton
            className={'emojiButton'}
            onClick={() => {
              scrollToSection(3);
            }}>
            <img
              style={{
                width: bottomNavImageSize,
                height: bottomNavImageSize,
              }}
              src={activeTab === 3 ? EmojisFoodSelected : EmojisFoodUnselected}
            />
          </SimpleButton>
          <div
            style={{
              width: 1,
              backgroundColor: '#0000000D',
              height: 9,
            }}
          />

          <SimpleButton
            className={'emojiButton'}
            onClick={() => {
              scrollToSection(4);
            }}>
            <img
              style={{
                width: bottomNavImageSize,
                height: bottomNavImageSize,
              }}
              src={activeTab === 4 ? EmojisActivitiesSelected : EmojisActivitiesUnselected}
            />
          </SimpleButton>
          <div
            style={{
              width: 1,
              backgroundColor: '#0000000D',
              height: 9,
            }}
          />

          <SimpleButton
            className={'emojiButton'}
            onClick={() => {
              scrollToSection(5);
            }}>
            <img
              style={{
                width: bottomNavImageSize,
                height: bottomNavImageSize,
              }}
              src={activeTab === 5 ? EmojisTravelSelected : EmojisTravelUnselected}
            />
          </SimpleButton>
          <div
            style={{
              width: 1,
              backgroundColor: '#0000000D',
              height: 9,
            }}
          />

          <SimpleButton
            className={'emojiButton'}
            onClick={() => {
              scrollToSection(6);
            }}>
            <img
              style={{
                width: bottomNavImageSize,
                height: bottomNavImageSize,
              }}
              src={activeTab === 6 ? EmojisObjectsSelected : EmojisObjectsUnselected}
            />
          </SimpleButton>
          <div
            style={{
              width: 1,
              backgroundColor: '#0000000D',
              height: 9,
            }}
          />

          <SimpleButton
            className={'emojiButton'}
            onClick={() => {
              scrollToSection(7);
            }}>
            <img
              style={{
                width: bottomNavImageSize,
                height: bottomNavImageSize,
              }}
              src={activeTab === 7 ? EmojisSymbolsSelected : EmojisSymbolsUnselected}
            />
          </SimpleButton>
          <div
            style={{
              width: 1,
              backgroundColor: '#0000000D',
              height: 9,
            }}
          />

          <SimpleButton
            className={'emojiButton'}
            onClick={() => {
              scrollToSection(8);
            }}>
            <img
              style={{
                width: bottomNavImageSize,
                height: bottomNavImageSize,
              }}
              src={activeTab === 8 ? EmojisFlagsSelected : EmojisFlagsUnselected}
            />
          </SimpleButton>

        </div>

      </div>

    </div>
  );
}

const mapStateToProps = (state: StoreState) => ({
  recentEmojis: state.recentEmojis,
});

export default connect(mapStateToProps)(EmojiPickerPopup);