import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { ComponentLocationType, ProgramType } from ".";
import colors from "../../assets/colors";
import { MasqueradeState } from "../../redux/actions/Masquerade/types";
import {
  changeCustomTrainingPlanType,
  changeProgramTypeTrainingPlan,
  onClickNewOrEditMasterProgram,
  onCloseEditingMasterPlan,
  onStopCreateCustomPlanInProgramLibrary,
  toggleFilterTrainingPlan,
} from "../../redux/actions/TrainingPrograms";
import { toggleCreateCustomPlan } from "../../redux/actions/TrainingPrograms/copy";
import { getTrainingPlans } from "../../redux/actions/TrainingPrograms/get";
import {
  getCanAccessWorkoutLibrary,
  getTrainerId,
  getTrainerProGroups,
  StoreState,
} from "../../redux/reducers";
import {
  CustomTrainingPlanType,
  ProGroup,
  QueryClientTrainingPlansArgs,
  QuerySearchCustomTrainingPlansArgs,
  QuerySearchMasterTrainingPlansArgs,
  User,
  UserType,
} from "../../types/gqlTypes";
import FilterIcon from "../Icons/FilterIcon";
import SimpleButton from "../SimpleButton";
import TrainingProgramBreadcrumbs from "./TrainingProgramBreadcrumbs";

interface Props {
  componentLocation: ComponentLocationType;
  programType: ProgramType;
  showFilter?: boolean;
  masqueradeState: MasqueradeState;
  user: User;
  showCreateNewPlans: boolean;
  changeProgramType: (type: ProgramType) => void;
  changeCustomTrainingPlanType: (type: CustomTrainingPlanType) => void;
  toggleFilter?: () => void;
  toggleCreateCustomPlan: () => void;
  onClickCreateNewMasterProgram: () => void;
  getTrainingPlans: (
    args:
      | QuerySearchCustomTrainingPlansArgs
      | QuerySearchMasterTrainingPlansArgs
      | QueryClientTrainingPlansArgs,
    programType?: ProgramType
  ) => void;
  trainerId: string;
  trainerProGroups: ProGroup[];
  canAccessWorkoutLibrary: boolean;
  filterState?: QuerySearchMasterTrainingPlansArgs;
  onCancelCreatePlan: () => void;
}

const MenuBar = (props: Props) => {
  const {
    componentLocation,
    programType,
    showFilter,
    masqueradeState,
    user,
    showCreateNewPlans,
    changeProgramType,
    changeCustomTrainingPlanType,
    toggleFilter,
    toggleCreateCustomPlan,
    onClickCreateNewMasterProgram,
    getTrainingPlans,
    trainerId,
    trainerProGroups,
    canAccessWorkoutLibrary,
    filterState,
    onCancelCreatePlan,
  } = props;

  const history = useHistory();
  const isMasquerade = !!masqueradeState?.masqueradeTrainer;
  const isTrainer = user?.type === UserType.Trainer;
  const isAdmin = user?.type === UserType.Admin;
  const isProgramLibrary =
    componentLocation === ComponentLocationType.AdminLibrary;
  const canCreateNew =
    (isAdmin &&
      !isMasquerade &&
      programType === ProgramType.Master &&
      isProgramLibrary) ||
    ((isTrainer || isMasquerade) && programType === ProgramType.Custom) ||
    ((isTrainer || isMasquerade) && programType === ProgramType.Library);

  const programOptions = [
    {
      text: "Master Workout Groups",
      value: ProgramType.Master,
      deepLink: "/programLibrary",
    },
  ];

  if (isMasquerade || isTrainer) {
    programOptions.push({
      text: "My Workout Groups",
      value: ProgramType.Custom,
      deepLink: "/myworkoutgroups",
    });
    if (trainerProGroups.length) {
      programOptions.push({
        text: "Pro Workout Groups",
        value: ProgramType.Custom,
        deepLink: "/proworkoutgroups",
      });
    }
    if (canAccessWorkoutLibrary) {
      programOptions.push({
        text: "Workout Library",
        value: ProgramType.Custom,
        deepLink: "/workoutlibrary",
      });
    }
  }

  const getCustomTrainingPlans = (type: CustomTrainingPlanType) => {
    changeProgramType(ProgramType.Custom);
    changeCustomTrainingPlanType(type);
    getTrainingPlans({
      page: 0,
      trainerId,
      type,
    });
  };

  const getLibraryTrainingPlans = () => {
    changeProgramType(ProgramType.Library);
    getTrainingPlans(
      {
        page: 0,
        trainerId,
      },
      ProgramType.Library
    );
  };

  const [selectedProgramOption, setSelectedProgramOption] = useState(null);

  // Deep link entry point
  if (window.location.href.indexOf("/myworkoutgroups") !== -1) {
    getCustomTrainingPlans(CustomTrainingPlanType.Trainer);
  }

  if (window.location.href.indexOf("/proworkoutgroups") !== -1) {
    getCustomTrainingPlans(CustomTrainingPlanType.ProGroup);
  }

  if (window.location.href.indexOf("/workoutlibrary") !== -1) {
    getLibraryTrainingPlans();
  }

  useEffect(() => {
    onCancelCreatePlan();
    switch (selectedProgramOption) {
      case "/programLibrary":
        changeProgramType(ProgramType.Master);
        changeCustomTrainingPlanType(null);
        getTrainingPlans({
          page: 0,
          ...filterState,
        });
        break;
      case "/myworkoutgroups":
        getCustomTrainingPlans(CustomTrainingPlanType.Trainer);
        break;
      case "/proworkoutgroups":
        getCustomTrainingPlans(CustomTrainingPlanType.ProGroup);
        break;
      case "/workoutlibrary":
        getLibraryTrainingPlans();
        break;
      default:
      // Do nothing
    }
  }, [selectedProgramOption]);

  return (
    <div
      className="d-flex"
      style={{
        margin: "5px 24px 5px 0px",
      }}
    >
      <TrainingProgramBreadcrumbs />

      {programType !== ProgramType.Client && (
        <div
          className="d-flex"
          style={{
            flex: 1,
          }}
        >
          <div
            className="d-flex align-items-center justify-content-between"
            style={{
              flex: "1 1 50%",
            }}
          >
            <div
              className="d-flex align-items-center ms-2"
              style={{
                width: 214 * programOptions.length,
                height: 48,
              }}
            >
              {programOptions.map(({ text, value, deepLink }, index) => {
                return (
                  <Button
                    variant="outline-primary"
                    key={deepLink}
                    className="ms-2"
                    active={window.location.href.indexOf(deepLink) !== -1}
                    onClick={() => {
                      setSelectedProgramOption(deepLink);
                      // Change URL if we are viewing from the main NavBar and not from a client context
                      if (
                        componentLocation === ComponentLocationType.AdminLibrary
                      ) {
                        history?.push(deepLink);
                      }
                    }}
                  >
                    {text}
                  </Button>
                );
              })}
            </div>

            {programType === ProgramType.Master && (
              <SimpleButton nofocus onClick={() => toggleFilter?.()}>
                <FilterIcon isSelected={showFilter} />
              </SimpleButton>
            )}
          </div>
          <div
            className="d-flex align-items-center justify-content-end"
            style={{
              flex: "1 1 50%",
              marginRight: 14,
            }}
          >
            {canCreateNew && !showCreateNewPlans && (
              <SimpleButton
                onClick={() => {
                  if (
                    programType === ProgramType.Custom ||
                    programType === ProgramType.Library
                  ) {
                    toggleCreateCustomPlan?.();
                  } else if (programType === ProgramType.Master) {
                    onClickCreateNewMasterProgram?.();
                  }
                }}
                className="d-flex align-items-center justify-content-center medium-bold"
                style={{
                  fontSize: "14px",
                  color: colors.caliber_white,
                  backgroundColor: colors.caliber_green_200,
                  borderRadius: 4,
                  width: 100,
                  height: 32,
                }}
              >
                Create New
              </SimpleButton>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  changeProgramType: (type: ProgramType) => {
    dispatch(changeProgramTypeTrainingPlan(type));
  },
  changeCustomTrainingPlanType: (type: CustomTrainingPlanType) => {
    dispatch(changeCustomTrainingPlanType(type));
  },
  getTrainingPlans: (
    args:
      | QuerySearchCustomTrainingPlansArgs
      | QuerySearchMasterTrainingPlansArgs
      | QueryClientTrainingPlansArgs,
    programType?: ProgramType
  ) => {
    dispatch(getTrainingPlans(args, programType));
  },
  toggleFilter: () => {
    dispatch(toggleFilterTrainingPlan());
  },
  toggleCreateCustomPlan: () => {
    dispatch(toggleCreateCustomPlan());
  },
  onClickCreateNewMasterProgram: () => {
    dispatch(onClickNewOrEditMasterProgram());
  },
  onClose: () => {
    dispatch(onCloseEditingMasterPlan());
  },
  onCancelCreatePlan: () => {
    dispatch(onStopCreateCustomPlanInProgramLibrary());
  },
});

const mapStateToProps = (state: StoreState) => ({
  componentLocation: state.trainingProgramsState.componentLocation,
  showFilter: state.trainingProgramsState.showFilter,
  programType: state.trainingProgramsState.programType,
  customTrainingPlanType: state.trainingProgramsState.customTrainingPlanType,
  showCreateNewPlans: state.trainingProgramsState.showCreateNewPlans,
  masqueradeState: state.masqueradeState,
  user: state.authenticatedUserState?.user,
  filterState: state.trainingProgramsState.filterState,
  trainerId: getTrainerId(state),
  trainerProGroups: getTrainerProGroups(state),
  canAccessWorkoutLibrary: getCanAccessWorkoutLibrary(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuBar);
