import * as SockJS from "sockjs-client";
import Stomp from "stompjs";
import { getAppConfig } from "../../../config";

const UnreadMessageCountInstance = (() => {
  const state = { stompClient: null };
  return {
    subscribe: (
      token: string,
      userId: string,
      cb: (totalCount: number) => void
    ) => {
      if (!state.stompClient) {
        const socket = new SockJS(`${getAppConfig().apiUrl}/websocket`);
        state.stompClient = Stomp.over(socket);
      }
      state.stompClient.connect({ Authorization: `Bearer ${token}` }, () => {
        state.stompClient.subscribe(`/topic/count/${userId}`, (totalCount) => {
          cb(Number(totalCount.body));
        });
      });
    },

    unsubscribe: () => {
      if (state.stompClient && state.stompClient.connected) {
        state.stompClient.disconnect();
        delete state.stompClient;
      }
    },
  };
})();
Object.freeze(UnreadMessageCountInstance);
export default UnreadMessageCountInstance;
