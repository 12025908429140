import SendBird, {
  AdminMessage,
  FileMessage,
  GroupChannel,
  GroupChannelListQuery,
  PreviousMessageListQuery,
  ReactionEvent,
  UserMessage,
} from "sendbird";
import { getAppConfig } from "../../../config";
import { Client, MediaUrl, User, UserType } from "../../../types/gqlTypes";
import { deleteVideoMessage, videoMessageUpload } from "../Media";
import {
  CHANNEL_ACTIVE,
  CHANNEL_DEACTIVATE,
  FETCHING_MESSAGES,
  FETCHING_MESSAGES_DONE,
  MESSAGES_CHANNEL_LIST_RECEIVED,
  MESSAGES_CHANNEL_UNREGISTERED,
  MESSAGES_DELETE_MESSAGE_FAIL,
  MESSAGES_DELETE_MESSAGE_SUCCESS,
  MESSAGES_DISCONNECTED,
  MESSAGES_MESSAGE_LIST_FAIL,
  MESSAGES_MESSAGE_LIST_LOADING,
  MESSAGES_MESSAGE_LIST_SUCCESS,
  MESSAGES_MESSAGE_RECEIVED,
  MESSAGES_SEND_MESSAGE_FAIL,
  MESSAGES_SEND_MESSAGE_START,
  MESSAGES_SEND_MESSAGE_SUCCESS,
  MessagesAction,
  READ_RECEIPT_UPDATED,
  REMOVE_VIDEO_UPLOAD_FAIL,
  UPDATE_MESSAGE_REACTION_FAIL,
  UPDATE_MESSAGE_REACTION_SUCCESS,
} from "./types";
import { addRecentEmoji } from '../RecentEmojis';

/**
 * Sendbird async/await wrapper for groupChannelQuery()
 * @param groupChannelQuery
 */
export const sbGetGroupChannelList = async (
  groupChannelQuery: GroupChannelListQuery
): Promise<Array<GroupChannel>> => {
  return new Promise((resolve, reject) => {
    groupChannelQuery.next((channels, error) => {
      if (error) {
        reject(error);
      } else {
        resolve(channels);
      }
    });
  });
};

/**
 * Sendbird async/await wrapper for previousMessageListQuery.load()
 * @param previousMessageListQuery
 */
export const sbGetMessageList = (
  previousMessageListQuery: PreviousMessageListQuery
): Promise<(AdminMessage | UserMessage | FileMessage)[]> => {
  const limit = 20;
  const reverse = true;
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const messages = await previousMessageListQuery?.load?.(limit, reverse);
      resolve(messages);
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Sendbird async/await wrapper for channel.sendUserMessage()
 * @param channel
 * @param textMessage
 */
export const sbSendTextMessage = (channel, textMessage) => {
  return new Promise((resolve, reject) => {
    channel.sendUserMessage(textMessage, (message, error) => {
      if (error) {
        reject(error);
      } else {
        resolve(message);
      }
    });
  });
};

/**
 * Sendbird async/await wrapper for channel.sendUserMessage()
 * @param channel
 * @param fileUri
 * @param mediaId
 */
export const sbSendVideoMessage = (channel, fileUri, mediaId) => {
  return new Promise((resolve, reject) => {
    channel.sendUserMessage(fileUri, mediaId, "muxVideo", (message, error) => {
      if (error) {
        reject(error);
      } else {
        resolve(message);
      }
    });
  });
};

/**
 * Sendbird async/await wrapper for channel.deleteMessage()
 * @param channel
 * @param message
 */
export const sbDeleteMessage = (channel, message) => {
  return new Promise((resolve, reject) => {
    channel.deleteMessage(message, (response, error) => {
      if (error) {
        reject(error);
      } else {
        resolve(response);
      }
    });
  });
};

/**
 * Sendbird async/await wrapper for channel.addReaction()
 * @param channel
 * @param message
 * @param emojiKey
 */
export const sbAddReaction = (channel, message, emojiKey) => {
  return new Promise((resolve, reject) => {
    channel.addReaction(message, emojiKey, (response, error) => {
      if (error) {
        reject(error);
      } else {
        resolve(response);
      }
    });
  });
};

/**
 * Sendbird async/await wrapper for channel.deleteReaction()
 * @param channel
 * @param message
 * @param emojiKey
 */
export const sbDeleteReaction = (channel, message, emojiKey) => {
  return new Promise((resolve, reject) => {
    channel.deleteReaction(message, emojiKey, (response, error) => {
      if (error) {
        reject(error);
      } else {
        resolve(response);
      }
    });
  });
};

/**
 * Sendbird async/await wrapper for channel.sendFileMessage()
 * @param channel
 * @param fileParams
 */
export const sbSendFileMessage = (channel, fileParams) => {
  return new Promise((resolve, reject) => {
    channel.sendFileMessage(fileParams, (message, error) => {
      if (error) {
        reject(error);
      } else {
        resolve(message);
      }
    });
  });
};

const sbMarkAsRead = async (channel: GroupChannel) => {
  if (channel) {
    await channel.markAsRead();
  }
};

export const disconnectFromSendbird = () => async (dispatch) => {
  if (SendBird.getInstance()) {
    await SendBird.getInstance().disconnect();
  }
  dispatch({
    type: MESSAGES_DISCONNECTED,
  });
};

export const connectToSendbird = () => async (dispatch, getState) => {
  const { user } = getState().authenticatedUserState;
  if (
    SendBird.getInstance() &&
    SendBird.getInstance().getConnectionState() !== "CLOSED"
  ) {
    return;
  }
  const isMasquerading = !!getState().masqueradeState.masqueradeTrainer;
  const isAdminOrManager =
    user?.type === UserType.Admin || user?.type === UserType.Manager;

  let idToConnect;
  let accessToken;
  if (isAdminOrManager && !isMasquerading) {
    const client = getState().clientDetailState?.user;
    idToConnect = (client as Client)?.assignedTrainer?.id;
    accessToken = (client as Client)?.assignedTrainer?.sendbirdAccessToken;
  } else if (isAdminOrManager && isMasquerading) {
    const masqTrainer = getState().masqueradeState?.masqueradeTrainer;
    idToConnect = masqTrainer?.id;
    accessToken = masqTrainer?.sendbirdAccessToken;
  } else if (!isAdminOrManager) {
    idToConnect = user?.id;
    accessToken = user?.sendbirdAccessToken;
  }

  try {
    const sb = new SendBird({ appId: getAppConfig().sbAppId });
    sb.connect(idToConnect, accessToken);

    // Fetch Channels
    const groupChannelQuery =
      sb?.GroupChannel?.createMyGroupChannelListQuery?.();
    groupChannelQuery.includeEmpty = true;
    groupChannelQuery.limit = 100;
    groupChannelQuery.customTypesFilter = [""];

    groupChannelQuery.userIdsIncludeFilter = [idToConnect];
    const channels = [];
    do {
      // eslint-disable-next-line no-await-in-loop
      channels.push(...(await sbGetGroupChannelList(groupChannelQuery)));
    } while (groupChannelQuery.hasNext);
    dispatch({
      type: MESSAGES_CHANNEL_LIST_RECEIVED,
      channels,
    });
  } catch (error) {
    console.log("Failed to connect with SB: ", error);
  }
};

export const registerChannelHandler =
  (trainerId: string, clientId?: string) => async (dispatch, getState) => {
    dispatch({ type: FETCHING_MESSAGES });
    const sb = SendBird.getInstance();
    const channelHandler = new sb.ChannelHandler();
    try {
      // Fetch Channels
      const groupChannelQuery = sb.GroupChannel.createMyGroupChannelListQuery();
      groupChannelQuery.includeEmpty = true;
      groupChannelQuery.customTypesFilter = [""];

      groupChannelQuery.userIdsIncludeFilter = [trainerId];
      if (clientId) {
        groupChannelQuery.userIdsIncludeFilter.push(clientId);
      }
      const channels = await sbGetGroupChannelList(groupChannelQuery);

      const channel = channels[0];
      channelHandler.onMessageReceived = async (channel, message) => {
        const { activeChannel } = getState().messagesState;
        if (
          channel === activeChannel &&
          getState().authenticatedUserState?.user?.type === UserType.Trainer
        ) {
          await sbMarkAsRead(channel as GroupChannel);
        }
        dispatch({
          type: MESSAGES_MESSAGE_RECEIVED,
          message,
          channel,
          isMasquerading: getState().masqueradeState.masqueradeTrainer != null,
        });
      };

      channelHandler.onMessageDeleted = (channel, message) => {
        const { activeChannel } = getState().messagesState;
        if (channel === activeChannel) {
          dispatch({
            type: MESSAGES_DELETE_MESSAGE_SUCCESS,
            message: { messageId: message },
            channel,
          });
        }
      };

      channelHandler.onReactionUpdated = (
        channel,
        reactionEvent: ReactionEvent
      ) => {
        const { activeChannel } = getState().messagesState;
        if (channel === activeChannel) {
          dispatch({
            type: UPDATE_MESSAGE_REACTION_SUCCESS,
            messageId: reactionEvent.messageId,
            emojiKey: reactionEvent.key,
            reactionUserId: reactionEvent.userId,
            isAddingReaction: reactionEvent.operation === "add",
            channel,
          });
        }
      };
      dispatch({ type: FETCHING_MESSAGES_DONE });
      sb.addChannelHandler(channel.url, channelHandler);
    } catch (error) {
      console.log("failed to register channel", error);
    }
  };

export const unRegisterChannelHandler =
  (channel: GroupChannel) => (dispatch) => {
    const sb = SendBird.getInstance();

    dispatch({
      type: MESSAGES_CHANNEL_UNREGISTERED,
    });
    sb.removeChannelHandler(channel.url);
  };

export const setActiveChannel =
  (clientId: string, trainerId: string) => async (dispatch, getState) => {
    const sb = SendBird.getInstance();

    // Fetch Channels
    const groupChannelQuery = sb?.GroupChannel?.createMyGroupChannelListQuery();
    if (groupChannelQuery) {
      groupChannelQuery.includeEmpty = true;
      groupChannelQuery.customTypesFilter = [""];

      groupChannelQuery.userIdsIncludeFilter = [trainerId, clientId];

      const channels = await sbGetGroupChannelList(groupChannelQuery);

      dispatch({
        type: CHANNEL_ACTIVE,
        activeChannel: channels[0],
      });

      // Mark as read
      if (getState().authenticatedUserState?.user?.type === UserType.Trainer) {
        await sbMarkAsRead(channels[0] as GroupChannel);
      }
      dispatch({
        type: READ_RECEIPT_UPDATED,
      });
    }
  };

export const deactivateChannel = () => (dispatch) => {
  dispatch({
    type: CHANNEL_DEACTIVATE,
  });
};

export const getPrevMessageList = () => async (dispatch, getState) => {
  if (getState().messagesState.fetchingError) {
    return;
  }
  const channel = getState().messagesState.activeChannel;
  let messageListQuery =
    getState().messagesState?.messageListQueries?.[channel?.url];
  const firstFetch = !messageListQuery;
  if (firstFetch) {
    messageListQuery = channel?.createPreviousMessageListQuery?.() || {};
    messageListQuery.includeMetaArray = true;
    messageListQuery.includeReactions = true;
  }
  if (!messageListQuery?.hasMore) {
    return;
  }
  dispatch({ type: MESSAGES_MESSAGE_LIST_LOADING });
  try {
    const messages = await sbGetMessageList?.(messageListQuery);
    if (getState().authenticatedUserState?.user?.type === UserType.Trainer) {
      await sbMarkAsRead(channel);
    }
    const dispatchObject: MessagesAction = {
      type: MESSAGES_MESSAGE_LIST_SUCCESS,
      messageList: [...messages],
      shouldMarkAsRead:
        getState().authenticatedUserState?.user?.type === UserType.Trainer,
      channel,
      isMasquerading: getState().masqueradeState.masqueradeTrainer != null,
    };
    if (firstFetch) {
      dispatchObject.messageListQuery = messageListQuery;
    }
    dispatch(dispatchObject);
  } catch (error) {
    console.log("Failed to fetch Message List.", error);
    dispatch({
      type: MESSAGES_MESSAGE_LIST_FAIL,
    });
  }
};

export const sendMessage = (message: string) => async (dispatch, getState) => {
  if (getState().messagesState.messageSending) {
    return;
  }
  dispatch({
    type: MESSAGES_SEND_MESSAGE_START,
  });

  const { activeChannel } = getState().messagesState;

  try {
    const sentMessage = await sbSendTextMessage(activeChannel, message);

    dispatch({
      type: MESSAGES_SEND_MESSAGE_SUCCESS,
      message: sentMessage,
      channel: activeChannel,
    });
  } catch (error) {
    // TODO: Handle error / pass up error
    dispatch({
      type: MESSAGES_SEND_MESSAGE_FAIL,
      data: error,
    });
    console.log("Failed to send Message", error);
  }
};

export const getClientIdFromChannel = (
  user: User,
  activeChannel: GroupChannel
): string => {
  if (user.type === UserType.Client) {
    return user.id;
  }
  const client = activeChannel.members.find(
    (member) => member.userId != user.id
  );
  return client.userId;
};

export const sendVideoMessage = (file: any) => async (dispatch, getState) => {
  if (getState().messagesState.messageSending) {
    return;
  }
  dispatch({
    type: MESSAGES_SEND_MESSAGE_START,
  });

  const { activeChannel } = getState().messagesState;

  try {
    const media = await videoMessageUpload(
      file,
      getClientIdFromChannel(
        getState().authenticatedUserState.user,
        activeChannel
      ),
      dispatch,
      getState
    );
    dispatch(removeVideoUploadFail(activeChannel.url));
    const sentMessage = await sbSendVideoMessage(
      activeChannel,
      "Video",
      JSON.stringify(media)
    );

    dispatch({
      type: MESSAGES_SEND_MESSAGE_SUCCESS,
      message: sentMessage,
      channel: activeChannel,
    });
  } catch (error) {
    // TODO: Handle error / pass up error
    dispatch({
      type: MESSAGES_SEND_MESSAGE_FAIL,
      data: error,
    });
    console.log("Failed to send Message", error);
  }
};

export const deleteMessage =
  (message: UserMessage | FileMessage) => async (dispatch, getState) => {
    const { activeChannel } = getState().messagesState;

    try {
      await sbDeleteMessage(activeChannel, message);
      if (message.customType == "muxVideo") {
        const data = JSON.parse(message.data) as MediaUrl;
        await deleteVideoMessage(
          data.id,
          getClientIdFromChannel(
            getState().authenticatedUserState.user,
            activeChannel
          )
        )(dispatch, getState);
      }
      dispatch({
        type: MESSAGES_DELETE_MESSAGE_SUCCESS,
        message,
        channel: activeChannel,
      });
    } catch (error) {
      // TODO: Handle error / pass up error
      dispatch({
        type: MESSAGES_DELETE_MESSAGE_FAIL,
      });
      console.log("Failed to delete message", error);
    }
  };

export const addReaction =
  (message: AdminMessage | UserMessage | FileMessage, emojiKey: string) =>
  async (dispatch, getState) => {
    const { activeChannel } = getState().messagesState;
    const { user } = getState().authenticatedUserState;
    const existingReaction = message.reactions.find((reaction) => {
      return reaction.key === emojiKey;
    });

    const isDeleting =
      existingReaction != null && existingReaction.userIds.includes(user.id);
    const isAdding =
      existingReaction == null || !existingReaction.userIds.includes(user.id);

    try {
      // Real time UI Update
      dispatch({
        type: UPDATE_MESSAGE_REACTION_SUCCESS,
        messageId: message.messageId,
        emojiKey,
        reactionUserId: user.id,
        isAddingReaction: isAdding,
        channel: activeChannel,
      });
      if (isDeleting) {
        await sbDeleteReaction(activeChannel, message, emojiKey);
      } else if (isAdding) {
        await sbAddReaction(activeChannel, message, emojiKey);
        addRecentEmoji(emojiKey)(dispatch, getState);
      }
    } catch (error) {
      dispatch({
        type: UPDATE_MESSAGE_REACTION_FAIL,
      });
      console.log("Failed to update message's reaction", error);
    }
  };

export const sendFileMessage = (file: any) => async (dispatch, getState) => {
  if (getState().messagesState.messageSending) {
    return;
  }
  dispatch({
    type: MESSAGES_SEND_MESSAGE_START,
  });

  const { activeChannel } = getState().messagesState;

  try {
    const sb = SendBird.getInstance();
    const fileParams = new sb.FileMessageParams();
    fileParams.file = file;
    fileParams.fileName = file.name;
    fileParams.fileSize = file.size;
    fileParams.mimeType = file.type;
    fileParams.thumbnailSizes = [{ maxWidth: 400, maxHeight: 400 }];

    const sentMessage = await sbSendFileMessage(activeChannel, fileParams);

    dispatch({
      type: MESSAGES_SEND_MESSAGE_SUCCESS,
      message: sentMessage,
      channel: activeChannel,
    });
  } catch (error) {
    // TODO: Handle error / pass up error
    dispatch({
      type: MESSAGES_SEND_MESSAGE_FAIL,
      data: error,
    });
    console.log("Failed to send Message", error);
  }
};
export const getIsVideoUploadingByChannelId = (id: string): boolean => {
  return (
    window.localStorage?.getItem(`video_upload_channelId_${id}`) === "true"
  );
};
export const setIsVideoUploadingByChannelId = (id: string) => {
  window.localStorage?.setItem(`video_upload_channelId_${id}`, "true");
};
export const removeIsVideoUploadingByChannelId = (id: string) => {
  window.localStorage?.removeItem(`video_upload_channelId_${id}`);
};
export const removeVideoUploadFail =
  (channelId: string) => async (dispatch, getState) => {
    dispatch({ type: REMOVE_VIDEO_UPLOAD_FAIL, channelId });
  };
