import apolloClient from "../../../api/apolloClient";
import {
  GET_CLIENT_TRAINING_PLAN_LAST_UPDATED,
  GET_CLIENT_TRAINING_PLANS,
} from "../../../api/gql/trainingPrograms/clientTrainingPlan";
import {
  GET_LIBRARY_TRAINING_PLAN,
  GET_TRAINING_PLAN,
  SEARCH_CUSTOM_TRAINING_PLANS,
  SEARCH_LIBRARY_TRAINING_PLANS,
} from "../../../api/gql/trainingPrograms/customTrainingPlan";
import { SEARCH_MASTER_PLANS } from "../../../api/gql/trainingPrograms/masterTrainingPlan";
import { ProgramType } from "../../../components/TrainingProgram";
import {
  CaliberPageImpl_CustomTrainingPlan,
  CaliberPageImpl_LibraryTrainingPlan,
  CaliberPageImpl_MasterTrainingPlan,
  ClientTrainingPlan,
  QueryClientTrainingPlansArgs,
  QuerySearchCustomTrainingPlansArgs,
  QuerySearchLibraryTrainingPlansArgs,
  QuerySearchMasterTrainingPlansArgs,
  QueryTrainingPlanArgs,
  TrainingPlan,
} from "../../../types/gqlTypes";
import {
  GET_TRAINING_PLAN_LAST_UPDATED_SUCCESS,
  GET_TRAINING_PLAN_LOADING,
  GET_TRAINING_PLAN_SUCCESS,
  GET_TRAINING_PLANS_FAIL,
  GET_TRAINING_PLANS_LOADING,
  GET_TRAINING_PLANS_SUCCESS,
  LOAD_TRAINING_PLAN_FROM_ROUTE_SUCCESS,
  TrainingProgramsAction,
} from "./types";

export const getTrainingPlans =
  (
    args:
      | QuerySearchCustomTrainingPlansArgs
      | QuerySearchLibraryTrainingPlansArgs
      | QuerySearchMasterTrainingPlansArgs
      | QueryClientTrainingPlansArgs,
    programType?: ProgramType
  ) =>
  async (dispatch, getState) => {
    if (getState().trainingProgramsState.isLoading) {
      return;
    }
    dispatch({ type: GET_TRAINING_PLANS_LOADING });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );

      const actualProgramType =
        programType || getState().trainingProgramsState.programType;
      let result:
        | CaliberPageImpl_MasterTrainingPlan
        | CaliberPageImpl_CustomTrainingPlan
        | CaliberPageImpl_LibraryTrainingPlan
        | ClientTrainingPlan[];
      if (actualProgramType === ProgramType.Custom) {
        result = (
          await client.mutate({
            mutation: SEARCH_CUSTOM_TRAINING_PLANS,
            variables: args,
          })
        ).data.searchCustomTrainingPlans as CaliberPageImpl_CustomTrainingPlan;
      } else if (actualProgramType === ProgramType.Library) {
        result = (
          await client.mutate({
            mutation: SEARCH_LIBRARY_TRAINING_PLANS,
            variables: args,
          })
        ).data
          .searchLibraryTrainingPlans as CaliberPageImpl_LibraryTrainingPlan;
      } else if (actualProgramType === ProgramType.Master) {
        result = (
          await client.mutate({
            mutation: SEARCH_MASTER_PLANS,
            variables: args,
          })
        ).data.searchMasterTrainingPlans as CaliberPageImpl_MasterTrainingPlan;
      } else if (
        actualProgramType === ProgramType.Client &&
        (args as QueryClientTrainingPlansArgs).clientId
      ) {
        result = (
          await client.mutate({
            mutation: GET_CLIENT_TRAINING_PLANS,
            variables: args,
          })
        ).data.clientTrainingPlans as ClientTrainingPlan[];
      }
      dispatch({
        type: GET_TRAINING_PLANS_SUCCESS,
        filterState: args,
        programType: actualProgramType,
        getPlansResult: result,
      });
    } catch (error) {
      dispatch({
        type: GET_TRAINING_PLANS_FAIL,
        data: error,
      });
    }
  };

export const getTrainingPlanLastDateUpdated =
  (clientId: string, trainingPlanId: string) => async (dispatch, getState) => {
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );

      const data = (
        await client.query({
          query: GET_CLIENT_TRAINING_PLAN_LAST_UPDATED,
          variables: {
            clientId,
            clientTrainingPlanId: trainingPlanId,
          },
        })
      ).data.clientTrainingPlanLastUpdatedDate;

      dispatch({
        type: GET_TRAINING_PLAN_LAST_UPDATED_SUCCESS,
        id: trainingPlanId,
        timeUpdated: new Date(data),
      } as TrainingProgramsAction);
    } catch (error) {
      dispatch({
        type: GET_TRAINING_PLANS_FAIL,
        data: error,
      });
    }
  };

export const getTrainingPlan =
  (
    args: QueryTrainingPlanArgs,
    forceRefresh = false,
    programType?: ProgramType
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: GET_TRAINING_PLAN_LOADING });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      let cachedPlan: TrainingPlan =
        getState().trainingProgramsState.cachedPlans?.[args?.trainingPlanId];
      if (
        (!cachedPlan || forceRefresh === true) &&
        programType !== ProgramType.Library
      ) {
        cachedPlan = (
          await client.query({
            query: GET_TRAINING_PLAN,
            variables: args,
          })
        ).data.trainingPlan;
      } else if (!cachedPlan && programType === ProgramType.Library) {
        cachedPlan = (
          await client.query({
            query: GET_LIBRARY_TRAINING_PLAN,
            variables: args,
          })
        ).data.libraryTrainingPlan;
      }

      dispatch({
        type: GET_TRAINING_PLAN_SUCCESS,
        plan: cachedPlan,
      });
    } catch (error) {
      dispatch({
        type: GET_TRAINING_PLANS_FAIL,
        data: error,
      });
    }
  };

export const loadTrainingPlanFromRoute =
  (args: QueryTrainingPlanArgs) => async (dispatch, getState) => {
    dispatch({ type: GET_TRAINING_PLAN_LOADING });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );

      const planFromRoute = (
        await client.query({
          query: GET_TRAINING_PLAN,
          variables: args,
        })
      ).data.trainingPlan;

      dispatch({
        type: LOAD_TRAINING_PLAN_FROM_ROUTE_SUCCESS,
        plan: planFromRoute,
      });
    } catch (error) {
      dispatch({
        type: GET_TRAINING_PLANS_FAIL,
        data: error,
      });
    }
  };
