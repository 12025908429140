import "rc-slider/assets/index.css";
import Slider from "rc-slider/lib/Slider";
import React, { useState } from "react";
import colors from "../../assets/colors";
import SimpleButton from "../SimpleButton";
import "./index.css";

interface Props {
  instance: any;
}

const Tools = (props: Props) => {
  const { instance } = props;
  const [zoomValue, setZoomValue] = useState(0);

  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{
        marginTop: 32,
        marginBottom: 16,
      }}
    >
      <div
        style={{
          marginLeft: 8,
        }}
      >
        <MirrorIcon
          onClick={() => {
            if (instance?.imageData?.scaleX === 1) {
              instance?.scaleX(-1);
            } else {
              instance?.scaleX(1);
            }
          }}
        />
      </div>
      <div
        style={{
          marginRight: 19,
        }}
      >
        <RotateIcon onClick={() => instance?.rotate(90)} />
      </div>
      <div
        style={{
          marginBottom: 15,
        }}
      >
        <ZoomOutIcon />
      </div>
      <div
        style={{
          width: 80,
          marginLeft: 8,
          marginRight: 8,
          marginBottom: 16,
        }}
      >
        <Slider
          railStyle={{
            height: 2,
            marginTop: 2,
            backgroundColor: "#5B64A0",
          }}
          trackStyle={{
            height: 2,
            marginTop: 2,
            backgroundColor: "#5B64A0",
          }}
          handleStyle={{
            width: 16,
            height: 16,
            color: colors.caliber_white,
            backgroundColor: colors.caliber_white,
            borderColor: colors.caliber_white,
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          }}
          id="zoom_slider"
          draggableTrack
          step={0.1}
          min={-1}
          max={2}
          value={zoomValue}
          onChange={(value) => {
            if (value - zoomValue > 0) {
              instance?.zoom(0.1);
            } else {
              instance?.zoom(-0.1);
            }
            setZoomValue(value);
          }}
        />
      </div>
      <div
        style={{
          marginBottom: 15,
        }}
      >
        <ZoomInIcon />
      </div>
    </div>
  );
};

const MirrorIcon = ({ onClick }: { onClick: () => void }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <SimpleButton
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{
        position: "relative",
        width: 48,
        height: 48,
      }}
    >
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="24" cy="16" r="16" fill={hovered ? "#5B64A0" : "#989BAD"} />
        <path
          d="M21.7196 21.0059V11L16 21.0059H21.7196Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.2804 21.0059V11L32 21.0059H26.2804Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.8601 11V21.0093"
          stroke="white"
          strokeWidth="0.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      {hovered && (
        <div
          style={{
            fontSize: "10px",
            lineHeight: "12px",
            letterSpacing: "-0.01em",
            color: "#808297",
            position: "absolute",
            bottom: 0,
            left: -3,
            width: 54,
            textAlign: "center",
          }}
        >
          Flip image
        </div>
      )}
    </SimpleButton>
  );
};
const RotateIcon = ({ onClick }: { onClick: () => void }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <SimpleButton
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{
        position: "relative",
        width: 48,
        height: 48,
      }}
    >
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="24" cy="16" r="16" fill={hovered ? "#5B64A0" : "#989BAD"} />
        <rect
          x="-0.703581"
          width="6.09248"
          height="6.09248"
          transform="matrix(0.703581 -0.710615 0.703581 0.710615 19.7914 13.1187)"
          stroke="white"
          strokeLinejoin="round"
        />
        <path
          d="M25.5058 20.88C24.2597 21.3961 22.8877 21.3962 21.6416 20.88C20.8603 20.5563 20.1878 20.0543 19.6676 19.4179C19.5735 19.3029 19.5724 19.1699 19.6302 19.0633C19.6889 18.9551 19.8097 18.8728 19.9643 18.8728H20.8034C21.0932 18.8728 21.3281 18.6378 21.3281 18.348C21.3281 18.0582 21.0932 17.8232 20.8034 17.8232H18.5C18.1686 17.8232 17.9 18.0919 17.9 18.4232V20.7266C17.9 21.0164 18.1349 21.2514 18.4248 21.2514H18.5318C18.7625 21.2514 18.9495 21.0643 18.9495 20.8336C18.9495 20.7327 19.0155 20.6524 19.1133 20.6151C19.2119 20.5776 19.3253 20.5913 19.4031 20.664C21.0975 22.248 23.6281 22.7938 25.9075 21.8497C26.8921 21.4418 27.7546 20.7825 28.4017 19.9427C28.9412 19.2427 29.3235 18.4296 29.5202 17.5696C29.5866 17.2797 29.3827 17.0103 29.0983 16.9662C28.8101 16.9215 28.5494 17.1196 28.4841 17.3935C28.1087 18.9677 27.0194 20.2531 25.5058 20.88Z"
          fill="white"
          stroke="white"
          strokeWidth="0.2"
        />
      </svg>

      {hovered && (
        <div
          style={{
            fontSize: "10px",
            lineHeight: "12px",
            letterSpacing: "-0.01em",
            color: "#808297",
            position: "absolute",
            bottom: 0,
            left: 0,
            width: 48,
            textAlign: "center",
          }}
        >
          Rotate
        </div>
      )}
    </SimpleButton>
  );
};
const ZoomOutIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip123)">
        <path
          d="M11.4812 11.3299L8.04134 7.75256C8.89298 6.932 9.42436 5.78132 9.42436 4.50806C9.42436 2.02227 7.40204 0 4.91635 0C2.43056 0 0.408295 2.02227 0.408295 4.50806C0.408295 6.99379 2.43056 9.01611 4.91635 9.01611C5.84372 9.01611 6.70639 8.73438 7.42383 8.25239L10.9107 11.8786C10.9884 11.9594 11.0922 12 11.196 12C11.2947 12 11.3935 11.9634 11.4703 11.8895C11.6278 11.738 11.6327 11.4874 11.4812 11.3299ZM1.19983 4.50806C1.19983 2.45877 2.86707 0.791535 4.91635 0.791535C6.96564 0.791535 8.63282 2.45877 8.63282 4.50806C8.63282 6.55734 6.96564 8.22458 4.91635 8.22458C2.86707 8.22453 1.19983 6.55734 1.19983 4.50806Z"
          fill="#5B64A0"
        />
        <path
          d="M6.93473 4.1123H2.89816C2.67954 4.1123 2.5024 4.28945 2.5024 4.50807C2.5024 4.7267 2.67954 4.90384 2.89816 4.90384H6.93473C7.15335 4.90384 7.3305 4.7267 7.3305 4.50807C7.3305 4.28945 7.15335 4.1123 6.93473 4.1123Z"
          fill="#5B64A0"
        />
      </g>
      <defs>
        <clipPath id="clip123">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
const ZoomInIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip321)">
        <path
          d="M11.4812 11.3299L8.0413 7.75251C8.89294 6.93195 9.42432 5.78132 9.42432 4.50806C9.42432 2.02232 7.40206 0 4.91626 0C2.43047 0 0.408203 2.02227 0.408203 4.50806C0.408203 6.99379 2.43047 9.01611 4.91626 9.01611C5.84363 9.01611 6.7064 8.73438 7.42385 8.25233L10.9107 11.8786C10.9884 11.9594 11.0921 12 11.196 12C11.2947 12 11.3935 11.9634 11.4702 11.8895C11.6278 11.738 11.6327 11.4874 11.4812 11.3299ZM1.19974 4.50806C1.19974 2.45877 2.86698 0.791535 4.91626 0.791535C6.96555 0.791535 8.63279 2.45877 8.63279 4.50806C8.63279 6.55734 6.96555 8.22458 4.91626 8.22458C2.86698 8.22453 1.19974 6.55734 1.19974 4.50806Z"
          fill="#5B64A0"
        />
        <path
          d="M6.93464 4.11231H5.3121V2.48976C5.3121 2.27114 5.13495 2.09399 4.91633 2.09399C4.69771 2.09399 4.52056 2.27114 4.52056 2.48976V4.11231H2.89797C2.67934 4.11231 2.5022 4.28945 2.5022 4.50807C2.5022 4.7267 2.67934 4.90384 2.89797 4.90384H4.52051V6.52644C4.52051 6.74506 4.69765 6.92221 4.91628 6.92221C5.1349 6.92221 5.31204 6.74506 5.31204 6.52644V4.90384H6.93459C7.15321 4.90384 7.33036 4.7267 7.33036 4.50807C7.33036 4.28945 7.15321 4.11231 6.93464 4.11231Z"
          fill="#5B64A0"
        />
      </g>
      <defs>
        <clipPath id="clip321">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Tools;
