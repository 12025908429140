import React from "react";
import { connect } from "react-redux";
import colors from "../../../assets/colors";
import { StoreState } from "../../../redux/reducers";
import {
  GenderType,
  StrengthScore,
  StrengthScoreMuscleGroupType,
} from "../../../types/gqlTypes";
import ScaleIcon from "../../Icons/ScaleIcon";
import Skeleton from "../../Skeleton";

interface OwnProps {
  muscleGroup: StrengthScore;
}
interface Props extends OwnProps {
  gender: GenderType;
}

const MuscleDetailsSkeleton = (props: Props) => {
  const { gender, muscleGroup } = props;
  const isBack = muscleGroup?.type == StrengthScoreMuscleGroupType.Back;
  const isWeight = muscleGroup?.type === StrengthScoreMuscleGroupType.Weight;

  return (
    <div
      className={`d-flex justify-content-center align-items-${
        isWeight ? "start" : "center"
      }`}
      style={{
        width: 288,
        height: 603,
        backgroundColor: colors.caliber_white,
        borderRadius: 8,
        border: "1px solid #F2F3F5",
        margin: "44px 42px 0px 42px",
        paddingTop: isWeight && 60,
      }}
    >
      {isWeight ? (
        <ScaleIcon />
      ) : (
        <Skeleton
          gender={gender}
          muscleColor={[{ muscle: muscleGroup?.type, color: "#272E5F" }]}
          height={507}
          view={isBack ? "back" : "front"}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  gender: state.clientDetailState.user.gender,
  muscleGroup: ownProps.muscleGroup,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MuscleDetailsSkeleton);
