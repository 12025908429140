export const UPDATE_COACH_PROFILE_ICON_SUCCESS =
  "UPDATE_COACH_PROFILE_ICON_SUCCESS";
export const UPDATE_COACH_PROFILE_ICON_FAIL = "UPDATE_COACH_PROFILE_ICON_FAIL";
export const UPDATE_COACH_PROFILE_ICON_SAVING =
  "UPDATE_COACH_PROFILE_ICON_SAVING";

export interface UpdateCoachProfileIconState {
  isSaving: boolean;
}

export interface UpdateCoachProfileIconAction {
  type: string;
}
