import {
  CaliberPageImpl_Exercise,
  Exercise,
  QuerySearchExercisesArgs,
} from "../../../types/gqlTypes";

export const SEARCH_EXERCISES_LOADING = "SEARCH_EXERCISES_LOADING";
export const SEARCH_EXERCISES_LOAD_SUCCESS = "SEARCH_EXERCISES_LOAD_SUCCESS";
export const SEARCH_EXERCISES_LOAD_FAIL = "SEARCH_EXERCISES_LOAD_FAIL";

export interface SearchExercisesState {
  isLoading: boolean;
  currentArgs?: QuerySearchExercisesArgs;
  exerciseList: Exercise[];
  isLastPage: boolean;
  currentPage: number;
  totalElements: number;
}

export interface SearchExercisesAction {
  type: string;
  data: CaliberPageImpl_Exercise;
  args?: QuerySearchExercisesArgs;
  clientsExercises?: Exercise[];
  addedExercise?: Exercise;
}
