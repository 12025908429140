import React from "react";
import { connect } from "react-redux";
import { format } from "date-fns";
import { StoreState } from "../../redux/reducers";
import MessageSentIcon from "../../assets/images/MessageSentIcon.svg";
import MessageReceivedIcon from "../../assets/images/MessageReceivedIcon.svg";
import colors from "../../assets/colors";
import { ChatRoom, Client, User } from "../../types/gqlTypes";

interface Props {
  isLoading: boolean;
  lastMessage: ChatRoom;
  client: User;
}

const LastMessageModule = (props: Props) => {
  const { lastMessage, client } = props;

  const clientSentDate = lastMessage?.lastDateTrainerReceivedMessage
    ? Date.parse(
        `${lastMessage.lastDateTrainerReceivedMessage.split("T").join(" ")} GMT`
      )
    : null;
  const clientReceivedDate = lastMessage?.lastDateTrainerSentMessage
    ? Date.parse(
        `${lastMessage.lastDateTrainerSentMessage.split("T").join(" ")} GMT`
      )
    : null;
  const pattern = "EEE MMM d 'at' h:mmaaa";
  const timezone = new Date()
    .toLocaleTimeString("en-us", { timeZoneName: "short" })
    .split(" ")[2];
  const lastActive = (client as Client)?.lastActiveDate;
  const lastActiveDate = lastActive
    ? Date.parse(`${lastActive?.split("T")?.join(" ")} GMT`)
    : null;

  const dateList = [
    { title: "Last Active:", value: lastActiveDate, icon: null },
    {
      title: "Last Message Received:",
      value: clientReceivedDate,
      icon: MessageReceivedIcon,
    },
    {
      title: "Last Message Sent:",
      value: clientSentDate,
      icon: MessageSentIcon,
    },
  ];

  return (
    <div
      className="d-flex flex-column"
      style={{
        marginTop: 24,
        padding: 24,
        width: 272,
        height: 247,
        backgroundColor: colors.caliber_white,
        borderRadius: 8,
      }}
    >
      <div
        className="bold"
        style={{
          fontSize: "18px",
          lineHeight: "22px",
          letterSpacing: "-0.29px",
          color: "#333333",
        }}
      >
        Latest Activity
      </div>
      {dateList?.map(({ title, value, icon }) => {
        return (
          <div key={title} className="d-flex" style={{ marginTop: 24 }}>
            {icon ? (
              <img
                style={{
                  height: 32,
                  width: 32,
                  marginRight: 8,
                }}
                src={icon}
              />
            ) : (
              <div
                style={{
                  height: 32,
                  width: 32,
                  marginRight: 8,
                }}
              />
            )}
            <div className="d-flex flex-column">
              <div
                style={{
                  height: 16,
                  fontSize: "14px",
                  lineHeight: "16px",
                  color: colors.caliber_secondary,
                }}
              >
                {title}
              </div>
              <div
                style={{
                  marginTop: 3,
                  height: 16,
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: colors.caliber_gray_12,
                }}
              >
                {value ? `${format(value, pattern)} ${timezone}` : "--"}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isLoading: state.clientDetailState.isLoading,
  lastMessage: state.clientDetailState.lastMessage,
  client: state.clientDetailState.user,
});

export default connect(mapStateToProps)(LastMessageModule);
