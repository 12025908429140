import React from "react";
import colors from "../../../assets/colors";
import { CardioActivityType } from "../../../types/gqlTypes";
import CardioCard from "./CardioCard";

interface Props {
  onClick: (type: CardioActivityType) => void;
}

const CardioList = (props: Props) => {
  const { onClick } = props;

  const cardioEvents = [
    CardioActivityType.Running,
    CardioActivityType.Walking,
    CardioActivityType.Elliptical,
    CardioActivityType.Stairclimber,
    CardioActivityType.Cycling,
    CardioActivityType.Rower,
  ];

  return (
    <div className="d-flex flex-column">
      <div
        className="medium-bold"
        style={{
          color: colors.caliber_gray_text,
          fontSize: "14px",
          lineHeight: "24px",
          margin: "16px 0px",
        }}
      >
        Select workout
      </div>
      <div
        className="d-flex flex-column"
        style={{
          height: 440,
          width: 408,
          overflowY: "auto",
        }}
      >
        {cardioEvents.map((cardioEvent) => {
          return (
            <CardioCard
              type={cardioEvent}
              onClick={() => onClick(cardioEvent)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default CardioList;
