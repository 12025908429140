import { AppRequirements } from "../../../types/gqlTypes";

export const GET_MINIMUM_APP_BUILD_NUMBER_LOADING =
  "GET_MINIMUM_APP_BUILD_NUMBER_LOADING";
export const GET_MINIMUM_APP_BUILD_NUMBER_SUCCESS =
  "GET_MINIMUM_APP_BUILD_NUMBER_SUCCESS";
export const GET_MINIMUM_APP_BUILD_NUMBER_FAIL =
  "GET_MINIMUM_APP_BUILD_NUMBER_FAIL";

export const UPDATE_MINIMUM_APP_BUILD_NUMBER_LOADING =
  "UPDATE_MINIMUM_APP_BUILD_NUMBER_LOADING";
export const UPDATE_MINIMUM_APP_BUILD_NUMBER_SUCCESS =
  "UPDATE_MINIMUM_APP_BUILD_NUMBER_SUCCESS";
export const UPDATE_MINIMUM_APP_BUILD_NUMBER_FAIL =
  "UPDATE_MINIMUM_APP_BUILD_NUMBER_FAIL";

export interface AdminMobileAppBuildNumberState {
  isLoading: boolean;
  success: boolean;
  appRequirementsList: AppRequirements[];
}

export interface AdminMobileAppBuildNumberAction {
  type: string;
  data: AppRequirements;
}
