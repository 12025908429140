import { CoachTask } from "../../../types/gqlTypes";

export enum TaskActionType {
  GET_TASKS_SUCCESS,
  GET_TASKS_FAIL,
  GET_TASKS_LOADING,

  MARK_COACH_TASK_STATUS_SUCCESS,
  MARK_COACH_TASK_STATUS_FAIL,
  MARK_COACH_TASK_STATUS_LOADING,

  ADMIN_RUN_RULES_ENGINE_CRON_SUCCESS,
  ADMIN_RUN_RULES_ENGINE_CRON_FAIL,
  ADMIN_RUN_RULES_ENGINE_CRON_LOADING,

  DELETE_COACH_TASK_LOADING,
  DELETE_COACH_TASK_SUCCESS,
  DELETE_COACH_TASK_FAIL,
}

export interface TasksState {
  isLoading: boolean;
  isCronRunning: boolean;
  tasks: CoachTask[];
}

export interface TasksAction {
  type: string;
  taskList: CoachTask[];
  updatedTask: CoachTask;
  deletedTaskId: string;
}
