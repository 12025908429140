import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import colors from "../../assets/colors";
import ButtonTag from "../ButtonTag";

interface Props {
  show: boolean;
  text: string;
  disabled?: boolean;
  onConfirm: () => void;
}

const WindowLog = (props: Props) => {
  const { show, text, disabled, onConfirm } = props;

  const copyToClipboard = (str) => {
    const el = document.createElement("textarea");
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  const scroll = () => {
    const element = document.getElementById("log_div");
    element.scrollTop = element.scrollHeight;
  };

  useEffect(scroll, [text, disabled]);

  return (
    <Modal centered show={show} onHide={() => {}}>
      <div
        className="d-flex flex-column"
        style={{ backgroundColor: "#f3f3f3" }}
      >
        <div
          className="d-flex justify-content-center"
          style={{
            height: 62,
          }}
        >
          <ButtonTag
            disabled={disabled}
            margin={6}
            text="Copy All"
            onClick={disabled ? () => {} : () => copyToClipboard(text)}
            height={24}
            textColor={colors.caliber_white}
            bgColor={colors.caliber_blue_200}
            minWidth={91}
          />
        </div>
        <div
          id="log_div"
          className="d-flex flex-column paragraph-large"
          style={{
            flex: 1,
            padding: 15,
            whiteSpace: "pre",
            minHeight: 400,
            maxHeight: 400,
            color: colors.caliber_secondary,
            lineHeight: "24px",
            fontSize: "18px",
            border: "1px solid black",
            borderRadius: 4,
            margin: 10,
            overflowY: "auto",
            flexDirection: "column-reverse",
          }}
        >
          {show && text}
        </div>
        <div
          className="d-flex justify-content-center"
          style={{
            height: 62,
          }}
        >
          <ButtonTag
            disabled={disabled}
            margin={6}
            text="OK"
            onClick={disabled ? () => {} : onConfirm}
            height={24}
            textColor={colors.caliber_white}
            bgColor={colors.caliber_green_200}
            minWidth={91}
          />
        </div>
      </div>
    </Modal>
  );
};
export default WindowLog;
