import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import colors from "../../assets/colors";
import DumbbellWorkoutIcon from "../../assets/images/DumbbellWorkoutIcon.svg";
import { fileUpload, resetMediaState } from "../../redux/actions/Media";
import { stopEditWorkoutTrainingPlan } from "../../redux/actions/TrainingPrograms";
import { updateWorkoutToPlan } from "../../redux/actions/TrainingPrograms/update";
import { getTrainerId, StoreState } from "../../redux/reducers";
import {
  selectClientWorkoutGroup,
  selectCurrentGymSteps,
  selectCurrentWorkout,
  selectFullClientPlan,
  selectFullMasterOrCustomPlan,
} from "../../redux/reducers/TrainingPrograms/selectors";
import {
  Client,
  ClientGymStepTemplate,
  ClientTrainingPlan,
  ClientWorkoutGroupTemplate,
  ClientWorkoutTemplate,
  CustomTrainingPlan,
  FileUploadType,
  MasterGymStep,
  MasterTrainingPlan,
  MasterWorkout,
  MutationUpdateClientWorkoutTemplateArgs,
  MutationUpdateMasterWorkoutArgs,
  MutationUpdateMasterWorkoutInCustomTrainingPlanArgs,
  Trainer,
} from "../../types/gqlTypes";
import { useKeyPress } from "../../utils/customHooks";
import ButtonTag from "../ButtonTag";
import { ProgramType } from "../TrainingProgram";
import "./index.css";
import WorkoutItem from "./WorkoutItem";

interface OwnProps {}
interface Props extends OwnProps {
  plan: MasterTrainingPlan | CustomTrainingPlan;
  clientPlan: ClientTrainingPlan;
  gymSteps: (MasterGymStep | ClientGymStepTemplate)[];
  workout: MasterWorkout | ClientWorkoutTemplate;
  workoutGroup: ClientWorkoutGroupTemplate;
  isEditWorkout: boolean;
  programType: ProgramType;
  selectedUser: Client | Trainer;
  destinationTrainerId: string;
  uploadImage: (file: any, type: FileUploadType) => void;
  resetMediaState: () => void;
  stopEditWorkout: () => void;
  updateWorkout: (
    args:
      | MutationUpdateClientWorkoutTemplateArgs
      | MutationUpdateMasterWorkoutArgs
      | MutationUpdateMasterWorkoutInCustomTrainingPlanArgs
  ) => void;
}

const EditWorkoutHeader = (props: Props) => {
  const {
    plan,
    clientPlan,
    gymSteps,
    workout,
    workoutGroup,
    isEditWorkout,
    programType,
    selectedUser,
    destinationTrainerId,
    stopEditWorkout,
    resetMediaState,
    updateWorkout,
  } = props;

  const numberOfExercises = gymSteps?.length;
  const actualPlan = programType === ProgramType.Client ? clientPlan : plan;
  useKeyPress("Escape", () => {
    if (isEditWorkout) {
      stopEditWorkout?.();
    }
  });

  const instrRef = useRef(null);
  const highlightRef = useRef(null);
  const nameRef = useRef(null);

  const [localInstr, setLocalInstr] = useState("");
  const [localName, setLocalName] = useState("");

  useEffect(() => {
    setLocalInstr(workout?.instructions || "");
    setLocalName(workout?.title || "");
    if (nameRef?.current?.selectionStart >= 0) {
      nameRef.current?.focus();
      nameRef.current.selectionStart = localName.length;
      nameRef.current.selectionEnd = localName.length;
    }
  }, [workout]);

  useEffect(() => {
    return resetMediaState;
  }, []);

  const save = () => {
    if (workout?.id && localName?.length) {
      const obj: any = {
        instructions: localInstr,
        title: localName,
      };
      if (programType === ProgramType.Client) {
        obj.clientWorkoutTemplateId = workout?.id;
        obj.clientId = selectedUser.id;
      } else if (programType === ProgramType.Master) {
        obj.masterWorkoutId = workout?.id;
      } else if (programType === ProgramType.Custom) {
        obj.customTrainingPlanId = actualPlan.id;
        obj.masterWorkoutId = workout?.id;
        obj.trainerId = destinationTrainerId;
      } else if (programType === ProgramType.Library) {
        obj.masterWorkoutId = workout?.id;
        obj.trainerId = destinationTrainerId;
      }
      updateWorkout(obj);
      stopEditWorkout?.();
    } else if (localName.length === 0) {
      nameRef?.current?.focus();
    }
  };
  if (!isEditWorkout) {
    return (
      <WorkoutItem
        asHeader
        workout={workout}
        workoutGroupId={workoutGroup?.id}
      />
    );
  }

  const EditInstructions = (
    <div className="instructionsContainer d-flex">
      <div ref={highlightRef} className="backdrop">
        <div className="highlights">
          <span className="notTooLongPiece">{localInstr.slice(0, 2000)}</span>
          <span className="tooLongPiece">{localInstr.slice(2000)}</span>
        </div>
      </div>
      <textarea
        className="nofocus medium-bold"
        style={{
          height: 150,
          paddingLeft: "7px",
          borderRadius: "8px",
          lineHeight: "18px",
          fontSize: "16px",
          width: "100%",
          display: "block",
          position: "absolute",
          zIndex: 2,
          margin: "0px",
          border: "none",
          color: "#444",
          backgroundColor: colors.caliber_gray_bg,
          whiteSpace: "pre-wrap",
          wordWrap: "break-word",
          overflowY: "auto",
          resize: "none",
        }}
        value={localInstr}
        ref={instrRef}
        onKeyDown={(event) => {
          if (localInstr.length > 2000 && event?.key === "Enter") {
            event.preventDefault();
          }
        }}
        onChange={(event) => {
          setLocalInstr(event.target.value);
          highlightRef.current.scrollTop = instrRef?.current?.scrollTop;
        }}
        onScroll={() => {
          highlightRef.current.scrollTop = instrRef?.current?.scrollTop;
        }}
      />
    </div>
  );
  const editInput = (
    <input
      className="d-flex align-text-center bold nofocus"
      ref={nameRef}
      value={localName}
      onChange={(event) => setLocalName(event.target.value)}
      style={{
        width: "100%",
        border: "none",
        height: 31,
        borderRadius: 8,
        paddingLeft: 5,
        resize: "none",
        backgroundColor: colors.caliber_gray_bg,
        fontSize: "14px",
      }}
    />
  );

  const charNumberLeft = (
    <div
      className="d-flex align-items-center bold"
      style={{
        color:
          2000 - localInstr.length >= 0
            ? colors.caliber_green_200
            : colors.caliber_red_200,
        fontSize: "16px",
        lineHeight: "16px",
        height: 28,
      }}
    >
      {2000 - localInstr.length}
    </div>
  );

  return (
    <div className="d-flex flex-column">
      <div
        className="d-flex flex-column"
        style={{
          flex: 1,
          backgroundColor: colors.caliber_white,
          borderRadius: 12,
          minHeight: 96,
          padding: 12,
        }}
      >
        <div
          className="d-flex"
          style={{
            flex: 1,
            minHeight: 80,
            borderRadius: 8,
            paddingRight: 1,
            backgroundColor: colors.caliber_white,
          }}
        >
          <div
            style={{
              position: "relative",
              width: 72,
              height: 72,
            }}
          >
            <img
              style={{
                width: 72,
                height: 72,
                borderRadius: 8,
              }}
              src={DumbbellWorkoutIcon}
            />
          </div>
          <div
            className="d-flex flex-column"
            style={{
              marginLeft: 12,
              flex: "1 1 0",
            }}
          >
            <div
              className="d-flex align-items-center"
              style={{
                flex: "1 1 100%",
              }}
            >
              {editInput}
            </div>
            <div className="d-flex align-items-center">
              {typeof numberOfExercises === "number" && (
                <div className="d-flex align-items-center bold badgeStyleGray">
                  {`${numberOfExercises} Exercise${
                    numberOfExercises === 1 ? "" : "s"
                  }`}
                </div>
              )}
            </div>
          </div>
        </div>
        {EditInstructions}
        <div
          className="d-flex justify-content-between"
          style={{
            marginTop: 12,
          }}
        >
          <ButtonTag
            text="Cancel"
            padding="2px 5px"
            textColor={colors.caliber_white}
            bgColor={colors.caliber_error_color}
            onClick={stopEditWorkout}
            style={{
              borderRadius: 8,
            }}
          />
          <div className="d-flex">
            {charNumberLeft}
            <ButtonTag
              disabled={localInstr.length > 2000}
              text="Save Workout"
              padding="2px 10px"
              textColor={colors.caliber_white}
              bgColor={colors.caliber_calendar_item_blue}
              onClick={save}
              style={{
                borderRadius: 8,
                marginLeft: 20,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  uploadImage: (file: any, type: FileUploadType) => {
    dispatch(fileUpload(file, type));
  },
  resetMediaState: () => {
    dispatch(resetMediaState());
  },
  updateWorkout: (
    args:
      | MutationUpdateClientWorkoutTemplateArgs
      | MutationUpdateMasterWorkoutArgs
      | MutationUpdateMasterWorkoutInCustomTrainingPlanArgs
  ) => {
    dispatch(updateWorkoutToPlan(args));
  },
  stopEditWorkout: () => {
    dispatch(stopEditWorkoutTrainingPlan());
  },
});
const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  plan: selectFullMasterOrCustomPlan(state),
  clientPlan: selectFullClientPlan(state),
  workoutGroup: selectClientWorkoutGroup(state),
  workout: selectCurrentWorkout(state),
  gymSteps: selectCurrentGymSteps(state),
  isEditWorkout: state.trainingProgramsState.isEditWorkout,
  programType: state.trainingProgramsState.programType,
  selectedUser: state.clientDetailState.user,
  destinationTrainerId: getTrainerId(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditWorkoutHeader);
