import { format, subDays } from "date-fns";
import apolloClient from "../../../api/apolloClient";
import {
  FETCH_NUTRITION_CALENDAR_ITEMS_FOR_DEBUG,
  GET_ACL_DATA_FOR_CLIENT,
  GET_CLIENT_FOR_DEBUG_NUTRITION_COLUMNS,
  SEND_REMINDERS_TO_SEGMENT,
} from "../../../api/gql/masqueradeDebug";
import {
  Client,
  ClientSearchResultSortCriteria,
  QueryAclArgs,
  QueryCalendarDateRangeArgs,
  SortOrder,
} from "../../../types/gqlTypes";
import { ClientDetailState } from "../ClientDetail/types";
import {
  GET_CLIENT_NUTRITION_COLUMNS_FOR_DEBUG_FAIL,
  GET_CLIENT_NUTRITION_COLUMNS_FOR_DEBUG_LOADING,
  GET_CLIENT_NUTRITION_COLUMNS_FOR_DEBUG_SUCCESS,
  GET_CLIENT_WEEK_NUTRITION_DATA_FOR_DEBUG_FAIL,
  GET_CLIENT_WEEK_NUTRITION_DATA_FOR_DEBUG_LOADING,
  GET_CLIENT_WEEK_NUTRITION_DATA_FOR_DEBUG_SUCCESS,
  RESET_DEBUG_TOOLS_STATE,
  SEND_REMINDER_NOTIFICATION_FAIL,
  SEND_REMINDER_NOTIFICATION_LOADING,
  SEND_REMINDER_NOTIFICATION_SUCCESS,
  GET_ACL_DATA_FOR_CLIENT_FAIL,
  GET_ACL_DATA_FOR_CLIENT_LOADING,
  GET_ACL_DATA_FOR_CLIENT_SUCCESS,
} from "./types";

export const sendReminderNotification =
  (clientId: string, targetDate: string) => async (dispatch, getState) => {
    dispatch({
      type: SEND_REMINDER_NOTIFICATION_LOADING,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const result = (
        await client.query({
          query: SEND_REMINDERS_TO_SEGMENT,
          variables: { clientId, targetDate },
        })
      ).data.sendRemindersToSegment;

      dispatch({
        type: SEND_REMINDER_NOTIFICATION_SUCCESS,
        sentReminderResult: result ? "success" : "fail",
      });
    } catch (error) {
      console.error("Error sendReminderNotification:", error);
      dispatch({
        type: SEND_REMINDER_NOTIFICATION_FAIL,
      });
    }
  };

export const resetDebugToolsState = () => async (dispatch, getState) => {
  dispatch({
    type: RESET_DEBUG_TOOLS_STATE,
  });
};

export const getClientNutritionColumnsForDebug =
  () => async (dispatch, getState) => {
    dispatch({
      type: GET_CLIENT_NUTRITION_COLUMNS_FOR_DEBUG_LOADING,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const clientDataState = getState().clientDetailState as ClientDetailState;

      const result = await client.query({
        query: GET_CLIENT_FOR_DEBUG_NUTRITION_COLUMNS,
        variables: {
          pageSize: 100,
          pageNumber: 0,
          searchCriteria: `${clientDataState?.user?.firstName} ${clientDataState?.user?.lastName}`,
          sortOrder: SortOrder.Asc,
          sortField: ClientSearchResultSortCriteria.LastName,
          statusCritera: [clientDataState?.user?.status],
          trainerIdFilter: (clientDataState?.user as Client)?.assignedTrainer
            ?.id,
        },
      });

      dispatch({
        type: GET_CLIENT_NUTRITION_COLUMNS_FOR_DEBUG_SUCCESS,
        client: result.data.searchClientsTab?.content?.find(
          (client) => client.id === clientDataState?.user?.id
        ),
      });
    } catch (error) {
      console.error("Error getting nutrition columns for debug:", error);
      dispatch({
        type: GET_CLIENT_NUTRITION_COLUMNS_FOR_DEBUG_FAIL,
      });
    }
  };

export const getWeekOfNutritionCalendarItems =
  (clientId: string) => async (dispatch, getState) => {
    dispatch({ type: GET_CLIENT_WEEK_NUTRITION_DATA_FOR_DEBUG_LOADING });
    try {
      const endDate = new Date();
      const startDate = subDays(endDate, 7);
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const variables: QueryCalendarDateRangeArgs = {
        clientId,
        endDate: format(endDate, "yyyy-MM-dd"),
        startDate: format(startDate, "yyyy-MM-dd"),
      };

      const result = await client.query({
        query: FETCH_NUTRITION_CALENDAR_ITEMS_FOR_DEBUG,
        variables,
      });
      dispatch({
        type: GET_CLIENT_WEEK_NUTRITION_DATA_FOR_DEBUG_SUCCESS,
        calendarData: result.data.calendarDateRange,
      });
    } catch (error) {
      dispatch({
        type: GET_CLIENT_WEEK_NUTRITION_DATA_FOR_DEBUG_FAIL,
        data: error,
      });
    }
  };

export const getAclData = (clientId: string) => async (dispatch, getState) => {
  dispatch({ type: GET_ACL_DATA_FOR_CLIENT_LOADING });
  try {
    const client = await apolloClient(getState().authState.authToken, dispatch);
    const className = "Client";
    const variables: QueryAclArgs = {
      className,
      id: clientId,
    };

    const result = await client.query({
      query: GET_ACL_DATA_FOR_CLIENT,
      variables,
    });
    dispatch({
      type: GET_ACL_DATA_FOR_CLIENT_SUCCESS,
      aclData: result.data.acl,
    });
  } catch (error) {
    dispatch({ type: GET_ACL_DATA_FOR_CLIENT_FAIL, data: error });
  }
};
