import React, { useEffect, useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import colors from "../../assets/colors";
import { updateNotes } from "../../redux/actions/UpdateNotes";
import { StoreState } from "../../redux/reducers";
import { MutationUpdateNotesArgs } from "../../types/gqlTypes";
import { useDebounce } from "../../utils/customHooks";

interface Props {
  updateNotes: (args: MutationUpdateNotesArgs) => void;
  notes: string;
}

const ProfileNotesModule = (props: Props) => {
  const { notes, updateNotes } = props;
  const { id: userId } = useParams();

  const [firstRender, setFirstRender] = useState(true);
  const [localNotes, setLocalNotes] = useState(null);
  const debouncedLocalNotes = useDebounce(localNotes, 700);

  useEffect(() => {
    if (!localNotes) {
      setLocalNotes(notes);
      setFirstRender(false);
    }
  }, [notes]);

  useEffect(() => {
    if (!firstRender && debouncedLocalNotes !== notes) {
      updateNotes({ notes: debouncedLocalNotes, userId });
    }
  }, [debouncedLocalNotes]);

  return (
    <div
      className="d-flex flex-column bordered-item"
      style={{
        width: 272,
        padding: 16,
        backgroundColor: colors.caliber_white,
      }}
    >
      <div
        className="flex-grow-0 heading-large circular-bold-font"
        style={{
          margin: "16px 8px",
          lineHeight: "32px",
          fontSize: "20px",
        }}
      >
        Notes
      </div>
      <div className="flex-grow-1">
        <InputGroup className="max-height-100 circular-font">
          <FormControl
            className="circular-font"
            value={localNotes}
            onChange={(event) => setLocalNotes(event.currentTarget.value)}
            style={{
              border: "none",
              resize: "none",
              color: colors.caliber_secondary,
              lineHeight: "18px",
              fontSize: "14px",
            }}
            as="textarea"
            aria-label="Notes"
          />
        </InputGroup>
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  updateNotes: (args: MutationUpdateNotesArgs) => {
    dispatch(updateNotes(args));
  },
});

const mapStateToProps = (state: StoreState) => ({
  notes: state.clientDetailState.user.notes,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileNotesModule);
