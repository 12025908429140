import React from "react";

interface Props {
  defaultColor: string;
  legColor: string;
  backColor: string;
  armColor: string;
  shoulderColor: string;
  skeletonSize: {
    width: number;
    height: number;
  };
}

const BackFemaleSkeleton = (props: Props) => {
  const {
    defaultColor,
    legColor,
    backColor,
    armColor,
    shoulderColor,
    skeletonSize: { width, height },
  } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 218 431"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <FrontFemaleHead color={defaultColor} />
      <FrontFemaleNeck color={defaultColor} />
      <FrontFemaleTraps color={defaultColor} />

      <FrontFemaleTriceps color={armColor} />
      <FrontFemaleBrachialis color={armColor} />

      <FrontFemaleHands color={defaultColor} />

      <FrontFemaleRearShoulders color={shoulderColor} />
      <FrontFemaleMidShoulders color={shoulderColor} />

      <FrontFemaleRhomboids color={backColor} />
      <FrontFemaleLowerBack color={backColor} />
      <FrontFemaleErectorSpinae color={backColor} />
      <FrontFemaleTeresMajor color={backColor} />

      <FrontFemaleObliques color={defaultColor} />

      <FrontFemaleGlutes color={legColor} />
      <FrontFemaleAbductors color={legColor} />
      <FrontFemaleCalves color={legColor} />
      <FrontFemaleAdductors color={legColor} />
      <FrontFemaleHamstrings color={legColor} />
      <FrontFemaleCalcaneanTendon color={legColor} />

      <FrontFemaleFeet color={defaultColor} />
    </svg>
  );
};

const FrontFemaleObliques = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M64.3491 149.234L73.0008 161.826L70.2687 167.391L60.709 168.416L64.3491 149.234Z"
        fill={color}
      />
      <path
        d="M115.044 149.234L106.393 161.826L109.125 167.391L118.687 168.416L115.044 149.234Z"
        fill={color}
      />
    </>
  );
};
const FrontFemaleHands = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M19.692 199.018L22.3602 205.154L30.1544 206.34L30.7696 217.424L26.6687 233.255L24.0005 233.059L26.5915 222.519L23.8221 222.445L19.487 233.651L16.2063 233.255L20.2832 221.927L17.4365 221.407L11.8977 233.454L7.79425 233.059L13.4369 220.517L11.4877 219.402L4.51358 230.088L1.02521 229.695L11.4877 210.498L10.4625 208.913L0.615129 212.871L0 210.694L8.00195 203.768L19.692 199.018Z"
        fill={color}
      />
      <path
        d="M160.308 199.018L157.64 205.154L149.846 206.34L149.23 217.424L153.331 233.255L156 233.059L153.409 222.519L156.178 222.445L160.513 233.651L163.794 233.255L159.717 221.927L162.564 221.407L168.102 233.454L172.206 233.059L166.563 220.517L168.512 219.402L175.486 230.088L178.975 229.695L168.512 210.498L169.538 208.913L179.385 212.871L180 210.694L172.001 203.768L160.308 199.018Z"
        fill={color}
      />
    </>
  );
};
const FrontFemaleCalcaneanTendon = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M72.829 363.675L68.7441 367.208L74.9433 395.613L76.4931 421.434L78.6048 422.522L79.8723 395.883L82.8334 375.77L81.9866 371.151L72.829 363.675Z"
        fill={color}
      />
      <path
        d="M69.5918 383.517L73.3944 401.727L74.3797 421.163L72.5503 422.522L70.2948 420.889L71.1416 417.494L67.7598 398.331V381.343L69.5918 383.517Z"
        fill={color}
      />
      <path
        d="M108.465 363.675L112.55 367.208L106.353 395.613L104.803 421.434L102.689 422.522L101.421 395.883L98.4629 375.77L99.3097 371.151L108.465 363.675Z"
        fill={color}
      />
      <path
        d="M111.705 383.517L107.902 401.727L106.914 421.163L108.746 422.522L111.002 420.889L110.155 417.494L113.537 398.331V381.343L111.705 383.517Z"
        fill={color}
      />
    </>
  );
};
const FrontFemaleFeet = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M68.7933 421.027L72.5507 424.107L78.7472 423.563L81.5645 420.483L84.7573 424.832L81.0026 427.187L71.9861 429L64.8496 421.752L65.976 418.85L68.7933 421.027Z"
        fill={color}
      />
      <path
        d="M112.538 421.027L108.784 424.107L102.584 423.563L99.767 420.483L96.5742 424.832L100.329 427.187L109.345 429L116.485 421.752L115.358 418.85L112.538 421.027Z"
        fill={color}
      />
    </>
  );
};
const FrontFemaleGlutes = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M55.548 234.012L66.4738 228.301L83.3218 224.202L86.6611 214.977L86.3575 191.404L80.4379 183.644L69.6612 183.498L53.7266 196.382L57.8221 207.511L55.548 234.012Z"
        fill={color}
      />
      <path
        d="M123.848 234.012L112.92 228.301L96.0717 224.202L92.7324 214.977L93.036 191.404L98.9556 183.644L109.732 183.498L125.667 196.382L121.571 207.511L123.848 234.012Z"
        fill={color}
      />
      <path
        d="M72.6963 170.173L83.9284 181.887L80.1338 181.595L66.625 182.033L51.4492 193.016L59.342 171.49L72.6963 170.173Z"
        fill={color}
      />
      <path
        d="M106.697 170.173L95.4648 181.887L99.2595 181.595L112.768 182.033L127.947 193.016L120.054 171.49L106.697 170.173Z"
        fill={color}
      />
    </>
  );
};
const FrontFemaleAbductors = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M53.4229 204.583L51.2979 230.059L55.2443 236.21L52.8158 255.391L46.2891 234.161L48.1105 217.321L53.4229 204.583Z"
        fill={color}
      />
      <path
        d="M125.974 204.583L128.096 230.059L124.152 236.21L126.581 255.391L133.105 234.161L131.284 217.321L125.974 204.583Z"
        fill={color}
      />
    </>
  );
};
const FrontFemaleCalves = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M72.5488 309.449L73.5341 358.511L82.2683 368.023L86.4943 361.909L86.7766 348.725L82.9713 329.021L72.5488 309.449Z"
        fill={color}
      />
      <path
        d="M69.8708 306.595L69.309 346.686L71.7029 361.909L67.618 365.169L60.4336 351.035L62.2657 323.719L65.786 312.574L66.0682 304.557L69.8708 306.595Z"
        fill={color}
      />
      <path
        d="M108.745 309.449L107.76 358.511L99.0258 368.023L94.7998 361.909L94.5176 348.725L98.3202 329.021L108.745 309.449Z"
        fill={color}
      />
      <path
        d="M111.423 306.595L111.988 346.686L109.594 361.909L113.679 365.169L120.863 351.035L119.031 323.719L115.508 312.574L115.228 304.557L111.423 306.595Z"
        fill={color}
      />
    </>
  );
};
const FrontFemaleAdductors = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M75.4297 230.792L80.135 242.799L81.9564 274.427L87.1171 243.97L86.5099 224.789L75.4297 230.792Z"
        fill={color}
      />
      <path
        d="M103.965 230.792L99.2594 242.799L97.438 274.427L92.2773 243.97L92.8845 224.789L103.965 230.792Z"
        fill={color}
      />
    </>
  );
};
const FrontFemaleHamstrings = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M106.849 231.962L112.465 268.57L106.09 299.462L97.438 316.888L96.5273 279.843L102.599 264.763L102.902 241.772L106.849 231.962Z"
        fill={color}
      />
      <path
        d="M71.9363 231.962L66.3203 268.57L72.6952 299.462L81.3469 316.888L82.2576 279.843L76.1863 264.763L75.8827 241.772L71.9363 231.962Z"
        fill={color}
      />
      <path
        d="M112.464 232.841L114.74 269.008L111.098 285.407L118.232 305.175L125.818 270.474L122.026 235.477L112.464 232.841Z"
        fill={color}
      />
      <path
        d="M66.9286 232.841L64.6518 269.008L68.2946 285.407L61.1608 305.175L53.5742 270.474L57.3688 235.477L66.9286 232.841Z"
        fill={color}
      />
    </>
  );
};
const FrontFemaleTriceps = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M52.6645 106.04L56.7626 110.139L56.0037 137.375L46.5931 156.995L40.0664 154.212L37.9414 134.298L41.5842 113.359L52.6645 106.04Z"
        fill={color}
      />
      <path
        d="M126.73 106.04L122.635 110.139L123.394 137.375L132.802 156.995L139.328 154.212L141.453 134.298L137.811 113.359L126.73 106.04Z"
        fill={color}
      />
    </>
  );
};
const FrontFemaleBrachialis = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M36.4236 148.943L39.9146 163.438L26.6561 203.704L24.0918 201.761L25.8466 185.351L27.4657 174.809L36.4236 148.943Z"
        fill={color}
      />
      <path
        d="M143.579 148.943L140.088 163.438L153.346 203.704L155.911 201.761L154.156 185.351L152.537 174.809L143.579 148.943Z"
        fill={color}
      />
      <path
        d="M49.3257 156.995L49.1739 172.369L31.5137 197.457L40.2186 169.003L49.3257 156.995Z"
        fill={color}
      />
      <path
        d="M130.68 156.995L130.831 172.369L148.489 197.457L139.784 169.003L130.68 156.995Z"
        fill={color}
      />
    </>
  );
};
const FrontFemaleRearShoulders = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M66.5879 84.2198L65.3736 87.7322L59.7576 90.2224L56.8737 100.032L43.6712 110.574V102.767L54.0856 86.041L61.6748 79.9348L66.5879 84.2198Z"
        fill={color}
      />
      <path
        d="M112.641 84.2198L113.855 87.7322L119.471 90.2224L122.355 100.032L135.557 110.574V102.767L125.143 86.041L117.554 79.9348L112.641 84.2198Z"
        fill={color}
      />
    </>
  );
};
const FrontFemaleMidShoulders = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M60.7148 78.8491L53.3919 84.557L43.7016 99.8224V89.6357L51.5944 77.9225L58.1185 76.4597L60.7148 78.8491Z"
        fill={color}
      />
      <path
        d="M118.514 78.8491L125.837 84.557L135.527 99.8224V89.6357L127.634 77.9225L121.11 76.4597L118.514 78.8491Z"
        fill={color}
      />
    </>
  );
};
const FrontFemaleRhomboids = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M62.9863 78.0739L70.5729 84.3685L76.0371 114.971L87.2691 136.789V86.126L78.7692 79.9774L62.9863 78.0739Z"
        fill={color}
      />
      <path
        d="M116.412 78.0739L108.823 84.3685L103.359 114.971L92.127 136.789V86.126L100.627 79.9774L116.412 78.0739Z"
        fill={color}
      />
    </>
  );
};
const FrontFemaleLowerBack = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M94.1454 153.92L90.199 142.791L85.9491 153.822L79.2227 170.027L90.6065 183.047L101.383 170.027L94.1454 153.92Z"
        fill={color}
      />
    </>
  );
};
const FrontFemaleErectorSpinae = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M59.036 111.458L58.7324 120.389L64.8038 144.403L75.2769 156.556L73.7591 168.124L74.9733 168.854L82.7143 153.625L84.0804 137.226L74.2144 115.558L64.652 115.117L59.7949 111.31L59.036 111.458Z"
        fill={color}
      />
      <path
        d="M120.357 111.458L120.66 120.389L114.589 144.403L104.116 156.556L105.634 168.124L104.42 168.854L96.6786 153.625L95.3125 137.226L105.178 115.558L114.741 115.117L119.598 111.31L120.357 111.458Z"
        fill={color}
      />
    </>
  );
};
const FrontFemaleTeresMajor = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M69.0541 87.7374L74.063 112.775L62.9854 111.897L55.8516 103.696L60.2506 99.1586L61.3131 93.0073L69.0541 87.7374Z"
        fill={color}
      />
      <path
        d="M110.339 87.7374L105.33 112.775L116.41 111.897L123.544 103.696L119.142 99.1586L118.08 93.0073L110.339 87.7374Z"
        fill={color}
      />
    </>
  );
};
const FrontFemaleTraps = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M79.5272 66.7987L61.4648 74.9969L80.4379 77.1951L88.786 71.0438L79.5272 66.7987Z"
        fill={color}
      />
      <path
        d="M99.8662 66.7987L117.929 74.9969L98.9555 77.1951L90.6074 71.0438L99.8662 66.7987Z"
        fill={color}
      />
    </>
  );
};
const FrontFemaleNeck = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M80.8934 53.0334L79.8867 64.3802L88.8074 68.8934L87.1165 48.2015L80.8934 53.0334Z"
        fill={color}
      />
      <path
        d="M98.5006 53.0334L99.5925 64.3802L90.6719 68.8934L92.2775 48.2015L98.5006 53.0334Z"
        fill={color}
      />
    </>
  );
};
const FrontFemaleHead = ({ color }: { color: string }) => {
  return (
    <>
      <path
        d="M110.96 33.175L110.632 15.3769L102.116 5.25395L87.5182 0L77.2235 5.25395L68.7076 15.3769L68.3801 33.175L63.957 34.4812L65.5947 43.7865L69.1976 45.9103L73.6206 45.4192L77.551 54.2386L82.7916 47.3811L89.9973 45.2493L96.548 47.3811L101.789 54.2386L105.719 45.4192L110.142 45.9103L113.745 43.7865L115.383 34.4812L110.96 33.175Z"
        fill={color}
      />
    </>
  );
};

export default BackFemaleSkeleton;
