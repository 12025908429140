import React from "react";
import colors from "../../assets/colors";

interface Props {
  onCancel: () => void;
  onSave: () => void;
  saveLabel?: string;
}

const Footer = (props: Props) => {
  const { onCancel, onSave, saveLabel } = props;

  return (
    <div
      className="d-flex justify-content-between"
      style={{
        marginTop: 20,
      }}
    >
      <div
        role="button"
        tabIndex={0}
        className="pointer nofocus paragraph-normal"
        style={{
          backgroundColor: colors.caliber_red_200,
          color: colors.caliber_white,
          padding: "1px 5px",
          borderRadius: 4,
        }}
        onClick={onCancel}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            onCancel();
          }
        }}
      >
        Cancel
      </div>
      <div
        role="button"
        tabIndex={0}
        className="pointer nofocus paragraph-normal"
        style={{
          backgroundColor: colors.caliber_green_200,
          color: colors.caliber_white,
          padding: "1px 20px",
          borderRadius: 4,
        }}
        onClick={onSave}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            onSave();
          }
        }}
      >
        {saveLabel && saveLabel}
        {!saveLabel && "Save Exercise"}
      </div>
    </div>
  );
};

export default Footer;
