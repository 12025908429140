import React from "react";
import { UserStatusType } from "../../types/gqlTypes";

interface Props {
  status: UserStatusType;
  style?: React.CSSProperties;
}

const ClientStatusTag = (props: Props) => {
  const { status, style } = props;
  const statusImage = (status) => {
    switch (status) {
      case UserStatusType.Active:
        return { text: "Active", backgroundColor: "#1FC866", color: "#FFFFFF" };
      case UserStatusType.TrialUser:
        return { text: "Trial", backgroundColor: "#3446FB", color: "#FFFFFF" };
      case UserStatusType.PausedSubscription:
        return { text: "Paused", backgroundColor: "#82869B", color: "#FFFFFF" };
      case UserStatusType.PendingCancellation:
        return {
          text: "Pending Cancel",
          backgroundColor: "#FFCC32",
          color: "#000000",
        };
      case UserStatusType.OnHold:
        return {
          text: "Failed Billing",
          backgroundColor: "#FA8C84",
          color: "#FFFFFF",
        };
      case UserStatusType.Cancelled:
        return {
          text: "Cancelled",
          backgroundColor: "#F73843",
          color: "#FFFFFF",
        };
      case UserStatusType.Inactive:
        return {
          text: "Inactive",
          backgroundColor: "#F73843",
          color: "#FFFFFF",
        };
      default:
        return { text: "", backgroundColor: "#FFFFFF", color: "#FFFFFF" };
    }
  };

  const { text, color, backgroundColor } = statusImage(status);
  const propStyle = style || {};
  return (
    <div
      className="d-flex justify-content-center align-items-center medium-bold"
      style={{
        height: 16,
        backgroundColor,
        color,
        padding: "3px 8px",
        borderRadius: 4,
        fontSize: "11px",
        lineHeight: "14px",
        ...propStyle,
      }}
    >
      {text}
    </div>
  );
};

export default ClientStatusTag;
