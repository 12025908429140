import { addSeconds, format, isToday } from "date-fns";
import { padInt } from "./measurements";
import WorkoutGroupGymIcon from "../assets/images/WorkoutGroupGymIcon.svg";
import WorkoutGroupFreestyleIcon from "../assets/images/WorkoutGroupFreestyleIcon.svg";
import WorkoutGroupHomeIcon from "../assets/images/WorkoutGroupHomeIcon.svg";
import WorkoutGroupTravelIcon from "../assets/images/WorkoutGroupTravelIcon.svg";
import WorkoutGroupProIcon from "../assets/images/WorkoutGroupProIcon.svg";
import { Exercise, WorkoutGroupType } from "../types/gqlTypes";
import CustomExerciseImage from "../assets/images/CustomExerciseImage.svg";
import DefaultWorkoutImage from "../assets/images/DefaultWorkoutImage.svg";

/**
 * Gets common date header in form of Weekday, Month, Date,
 * @param date
 */
export const dateHeaderText = (date: Date) => {
  const weekDay = isToday(date) ? "Today" : format(date, "EEEE");
  return `${weekDay}, ${format(date, "MMMM do")}`;
};

/**
 * Format time from seconds to MM:SS or HH:MM:SS
 * @param timeInSeconds
 * @param includeHours
 */
export const formattedTime = (timeInSeconds?: number, includeHours = false) => {
  if (timeInSeconds === null) {
    return "00:00";
  }
  const pad = (num) => (num < 10 ? "0" : "") + num;

  let hours = Math.floor(timeInSeconds / 3600);
  let minutes = includeHours
    ? Math.floor((timeInSeconds % 3600) / 60)
    : Math.floor(timeInSeconds / 60);
  let secondsLeft = timeInSeconds % 60;

  if (includeHours && hours > 0) {
    return pad(hours) + ":" + pad(minutes) + ":" + pad(secondsLeft);
  } else {
    return pad(minutes) + ":" + pad(secondsLeft);
  }
};

export const timeFromString = (time: string): number => {
  const [minuteText, secondText] = time.split(":");
  const minutes = Number.parseInt(minuteText, 10);
  const seconds = Number.parseInt(secondText, 10);

  return minutes * 60 + seconds;
};

/**
 * Sleep on thread for time in millis
 * @param time
 */
export const sleep = (time: number): Promise<null> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(null);
    }, time);
  });
};

/**
 * onKeyDown restriction for numbers only inputs
 */

export const onKeyDownRestrict = (
  key: string,
  additionalKeysToAllow?: string[]
) => {
  const allowedKeys = {
    "1": true,
    "2": true,
    "3": true,
    "4": true,
    "5": true,
    "6": true,
    "7": true,
    "8": true,
    "9": true,
    "0": true,
    Backspace: true,
    ArrowRight: true,
    ArrowLeft: true,
    Tab: true,
  } as any;
  additionalKeysToAllow?.forEach((key) => {
    allowedKeys[key] = true;
  });
  return !allowedKeys[key];
};

/**
 * getter for names of the week days
 */
export enum WeekdayRecurrenceType {
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY",
}
const weekdayTitles = {
  [WeekdayRecurrenceType.MONDAY]: "Monday",
  [WeekdayRecurrenceType.TUESDAY]: "Tuesday",
  [WeekdayRecurrenceType.WEDNESDAY]: "Wednesday",
  [WeekdayRecurrenceType.THURSDAY]: "Thursday",
  [WeekdayRecurrenceType.FRIDAY]: "Friday",
  [WeekdayRecurrenceType.SATURDAY]: "Saturday",
  [WeekdayRecurrenceType.SUNDAY]: "Sunday",
};
export const getWeekdayName = (
  day: WeekdayRecurrenceType,
  numberOfLetters: number,
  allCaps?: boolean
) => {
  const weekday = allCaps
    ? weekdayTitles[day].toUpperCase()
    : weekdayTitles[day];
  if (numberOfLetters === 0) {
    return weekday;
  }
  return weekday.slice(0, numberOfLetters);
};

/**
 * to print an integer in JavaScript with commas as thousands separators
 */
export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 * to generate a thumbnail from mux video url
 */
export const getMuxThumbnailUrl = (url: string): string => {
  if (url != null) {
    const regex = /^https:\/\/stream.mux.com\/(.*).m3u8/;
    const match = url?.match(regex);
    if (match) {
      return `https://image.mux.com/${match[1]}/thumbnail.jpg?time=0`;
    }
  }
  return "";
};

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const renderWorkoutGroupThumbnailIcon = (type: WorkoutGroupType) => {
  let icon: string;
  switch (type) {
    case WorkoutGroupType.Gym:
      icon = WorkoutGroupGymIcon;
      break;
    case WorkoutGroupType.Home:
      icon = WorkoutGroupHomeIcon;
      break;
    case WorkoutGroupType.Freestyle:
      icon = WorkoutGroupFreestyleIcon;
      break;
    case WorkoutGroupType.Travel:
      icon = WorkoutGroupTravelIcon;
      break;
    case WorkoutGroupType.Pro:
      icon = WorkoutGroupProIcon;
      break;
  }
  return icon;
};

export const exerciseThumbnailImageUrl = (exercise: Exercise) => {
  return (
    exercise?.media?.thumbnailUrl?.url ||
    (exercise?.isCustomExercise ? CustomExerciseImage : DefaultWorkoutImage)
  );
};
