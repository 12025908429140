import React from "react";
import Dropdown from "../../Dropdown";
import RadioButton from "../../Icons/RadioButton";
import SimpleButton from "../../SimpleButton";
import { RecurrenceFrequencyType } from "./Frequency";

interface Props {
  onDaySelect: (value: number) => void;
  onCheck: () => void;
  isSelected: boolean;
  selectedDay: number;
  frequencyType: RecurrenceFrequencyType;
}

const OnDayFrequency = (props: Props) => {
  const { onDaySelect, onCheck, isSelected, selectedDay, frequencyType } =
    props;

  if (frequencyType === RecurrenceFrequencyType.WEEKLY) {
    return null;
  }

  let ordinal = "st";
  if (selectedDay === 2) {
    ordinal = "nd";
  } else if (selectedDay === 3) {
    ordinal = "rd";
  } else if (selectedDay > 3) {
    ordinal = "th";
  }

  const textStyle: React.CSSProperties = {
    fontSize: "16px",
    lineHeight: "16px",
    color: "#000000",
    height: 36,
    margin: "0px 12px",
  };
  return (
    <div
      className="d-flex align-items-center"
      style={{
        margin: "12px 12px",
        height: 36,
      }}
    >
      <RadioButton checked={isSelected} onClick={onCheck} />
      <SimpleButton
        nofocus
        onClick={onCheck}
        className="d-flex align-items-center medium-bold"
        style={textStyle}
      >
        On
      </SimpleButton>
      <div
        style={{
          width: 58,
        }}
      >
        <Dropdown
          height={36}
          customPadding="0px 24px 0px 10px"
          onSelect={onDaySelect}
          items={Array.from({ length: 31 }, (_, i) => ({
            value: i + 1,
            text: `${i + 1}`,
          }))}
          value={selectedDay}
        />
      </div>
      <div className="d-flex align-items-center medium-bold" style={textStyle}>
        {`${ordinal} of the month`}
      </div>
    </div>
  );
};

export default OnDayFrequency;
