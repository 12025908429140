import { gql } from "apollo-boost";

export const GET_ALL_PRO_GROUPS = gql`
  query GetAllProGroups {
    proGroups {
      id
      name
    }
  }
`;
