import {
  AdminMessage,
  FileMessage,
  GroupChannel,
  PreviousMessageListQuery,
  UserMessage,
} from "sendbird";

export const FETCHING_MESSAGES = "FETCHING_MESSAGES";
export const FETCHING_MESSAGES_DONE = "FETCHING_MESSAGES_DONE";
export const MESSAGES_CHANNEL_UNREGISTERED = "MESSAGES_CHANNEL_UNREGISTERED";
export const MESSAGES_DISCONNECTED = "MESSAGES_DISCONNECTED";
export const CHANNEL_ACTIVE = "CHANNEL_ACTIVE";
export const CHANNEL_DEACTIVATE = "CHANNEL_DEACTIVATE";
export const MESSAGES_CHANNEL_LIST_RECEIVED = "MESSAGES_CHANNEL_LIST_RECEIVED";
export const READ_RECEIPT_UPDATED = "READ_RECEIPT_UPDATED";
export const MESSAGES_MESSAGE_RECEIVED = "MESSAGES_MESSAGE_RECEIVED";
export const MESSAGES_MESSAGE_LIST_LOADING = "MESSAGES_MESSAGE_LIST_LOADING";
export const MESSAGES_MESSAGE_LIST_SUCCESS = "MESSAGES_MESSAGE_LIST_SUCCESS";
export const MESSAGES_MESSAGE_LIST_FAIL = "MESSAGES_MESSAGE_LIST_FAIL";
export const MESSAGES_SEND_MESSAGE_START = "MESSAGES_SEND_MESSAGE_START";
export const MESSAGES_SEND_MESSAGE_SUCCESS = "MESSAGES_SEND_MESSAGE_SUCCESS";
export const MESSAGES_SEND_MESSAGE_FAIL = "MESSAGES_SEND_MESSAGE_FAIL";
export const MESSAGES_DELETE_MESSAGE_SUCCESS =
  "MESSAGES_DELETE_MESSAGE_SUCCESS";
export const MESSAGES_DELETE_MESSAGE_FAIL = "MESSAGES_DELETE_MESSAGE_FAIL";
export const UPDATE_MESSAGE_REACTION_SUCCESS =
  "UPDATE_MESSAGE_REACTION_SUCCESS";
export const UPDATE_MESSAGE_REACTION_FAIL = "UPDATE_MESSAGE_REACTION_FAIL";

export const VIDEO_STARTS_UPLOADING = "VIDEO_STARTS_UPLOADING";
export const VIDEO_STARTS_UPLOADING_FAKE = "VIDEO_STARTS_UPLOADING_FAKE";
export const VIDEO_UPLOAD_PROGRESS = "VIDEO_UPLOAD_PROGRESS";
export const VIDEO_UPLOAD_SUCCESS = "VIDEO_UPLOAD_SUCCESS";
export const VIDEO_UPLOAD_FAIL = "VIDEO_UPLOAD_FAIL";
export const REMOVE_VIDEO_UPLOAD_FAIL = "REMOVE_VIDEO_UPLOAD_FAIL";

export interface MessagesState {
  messageListByClient: Map<
    string,
    (AdminMessage | UserMessage | FileMessage)[]
  >;
  sendingMessage: boolean;
  activeChannel?: GroupChannel;
  availableChannels: GroupChannel[];
  isLoading?: boolean;
  messageListQueries?: { [key: string]: PreviousMessageListQuery };
  fetchingError?: false;
  channelsVideoUploadProgress?: { [key: string]: number };
  isMediaUploading?: boolean;
  isMediaFakeUploading?: boolean;
}

export interface MessagesAction {
  type: string;
  clientId?: string;
  messageList?: (AdminMessage | UserMessage | FileMessage)[];
  message?: AdminMessage | UserMessage | FileMessage;
  messageId?: number;
  activeChannel?: GroupChannel;
  channel?: GroupChannel;
  channels?: GroupChannel[];
  isMasquerading?: boolean;
  emojiKey?: string;
  reactionUserId?: string;
  isAddingReaction?: boolean;
  messageListQuery?: PreviousMessageListQuery;
  shouldMarkAsRead?: boolean;
  progress?: number;
  channelId?: string;
}
