import { format, subMonths } from "date-fns";
import {
  QueryStrengthScoresByDateRangeArgs,
  StrengthScoreDashboard,
  StrengthScoreLight,
  StrengthScoreMuscleGroupType,
} from "../../../types/gqlTypes";
import {
  CLIENT_STRENGTH_DASHBOARD_FAIL,
  CLIENT_STRENGTH_DASHBOARD_LOADING,
  CLIENT_STRENGTH_DASHBOARD_SUCCESS,
  RESET_CLIENT_STRENGTH_DASHBOARD,
  REGENERATE_STRENGTH_DASHBOARD_FAIL,
  REGENERATE_STRENGTH_DASHBOARD_LOADING,
  ClientStrengthDashboardAction,
  CLIENT_STRENGTH_SCORE_MUSCLE_GROUP_FAIL,
  CLIENT_STRENGTH_SCORE_MUSCLE_GROUP_LOADING,
  CLIENT_STRENGTH_SCORE_MUSCLE_GROUP_SUCCESS,
} from "./types";
import apolloClient from "../../../api/apolloClient";
import {
  REGENERATE_STRENGTH_SCORE,
  GET_STRENGTH_SCORES,
  GET_STRENGTH_SCORES_MUSCLE_GROUP,
} from "../../../api/gql/strengthScore";
import { getAppConfig } from "../../../config";

export const getStrengthScores =
  (clientId: string) => async (dispatch, getState) => {
    if (getState().clientStrengthDashboardState.isLoading) {
      return;
    }
    dispatch({
      type: CLIENT_STRENGTH_DASHBOARD_LOADING,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const result = await client.query({
        query: GET_STRENGTH_SCORES,
        variables: {
          clientId,
          endDate: format(new Date(), "yyyy-MM-dd"),
          startDate: format(subMonths(new Date(), 6), "yyyy-MM-dd"),
          type: StrengthScoreMuscleGroupType.Overall,
        } as QueryStrengthScoresByDateRangeArgs,
      });
      const strengthScoreDashboardChangesResult = result.data
        .strengthScoreDashboardChanges as StrengthScoreDashboard[];
      const historicalStrengthScoreResult = result.data
        .strengthScoresByDateRange as StrengthScoreLight[];
      const historicalStrengthBalanceResult = result.data
        .strengthBalancesByDateRange as StrengthScoreLight[];
      dispatch({
        type: CLIENT_STRENGTH_DASHBOARD_SUCCESS,
        historicalStrengthScoreResult,
        historicalStrengthBalanceResult,
        strengthScoreDashboardChangesResult,
      } as ClientStrengthDashboardAction);
    } catch (error) {
      dispatch({
        type: CLIENT_STRENGTH_DASHBOARD_FAIL,
        data: error,
      });
    }
  };
export const getStrengthScoresMuscleGroup =
  (clientId: string, type: StrengthScoreMuscleGroupType) =>
  async (dispatch, getState) => {
    if (getState().clientStrengthDashboardState.isLoading) {
      return;
    }
    dispatch({
      type: CLIENT_STRENGTH_SCORE_MUSCLE_GROUP_LOADING,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const result = await client.query({
        query: GET_STRENGTH_SCORES_MUSCLE_GROUP,
        variables: {
          clientId,
          endDate: format(new Date(), "yyyy-MM-dd"),
          startDate: format(subMonths(new Date(), 6), "yyyy-MM-dd"),
          type,
        } as QueryStrengthScoresByDateRangeArgs,
      });

      const historicalStrengthScoreMuscleGroup = result.data
        .strengthScoresByDateRange as StrengthScoreLight[];

      dispatch({
        type: CLIENT_STRENGTH_SCORE_MUSCLE_GROUP_SUCCESS,
        historicalStrengthScoreMuscleGroup,
      } as ClientStrengthDashboardAction);
    } catch (error) {
      dispatch({
        type: CLIENT_STRENGTH_SCORE_MUSCLE_GROUP_FAIL,
        data: error,
      });
    }
  };

export const adminRegenerateStrengthScores =
  (clientId: string, weeksAgo?: number) => async (dispatch, getState) => {
    try {
      await fetch(
        `${
          getAppConfig().apiUrl
        }/regenerateStrengthScores?clientId=${clientId}&weeksAgo=${weeksAgo}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${getState().authState.authToken}`,
          },
        }
      );
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      const result = await client.query({
        query: GET_STRENGTH_SCORES,
        variables: {
          clientId,
          endDate: format(new Date(), "yyyy-MM-dd"),
          startDate: format(subMonths(new Date(), 6), "yyyy-MM-dd"),
          type: StrengthScoreMuscleGroupType.Overall,
        } as QueryStrengthScoresByDateRangeArgs,
      });
      const strengthScoreDashboardChangesResult = result.data
        .strengthScoreDashboardChanges as StrengthScoreDashboard[];
      const historicalStrengthScoreResult = result.data
        .strengthScoresByDateRange as StrengthScoreLight[];
      const historicalStrengthBalanceResult = result.data
        .strengthBalancesByDateRange as StrengthScoreLight[];
      dispatch({
        type: CLIENT_STRENGTH_DASHBOARD_SUCCESS,
        historicalStrengthScoreResult,
        historicalStrengthBalanceResult,
        strengthScoreDashboardChangesResult,
      } as ClientStrengthDashboardAction);
    } catch (error) {
      dispatch({
        type: REGENERATE_STRENGTH_DASHBOARD_FAIL,
        data: error,
      });
    }
  };

export const regenerateStrengthScore =
  (clientId: string) => async (dispatch, getState) => {
    if (getState().clientStrengthDashboardState.isLoading) {
      return;
    }
    dispatch({
      type: REGENERATE_STRENGTH_DASHBOARD_LOADING,
    });
    try {
      const client = await apolloClient(
        getState().authState.authToken,
        dispatch
      );
      await client.mutate({
        mutation: REGENERATE_STRENGTH_SCORE,
        variables: { clientId },
      });
      const result = await client.query({
        query: GET_STRENGTH_SCORES,
        variables: {
          clientId,
          endDate: format(new Date(), "yyyy-MM-dd"),
          startDate: format(subMonths(new Date(), 6), "yyyy-MM-dd"),
          type: StrengthScoreMuscleGroupType.Overall,
        } as QueryStrengthScoresByDateRangeArgs,
      });
      const strengthScoreDashboardChangesResult = result.data
        .strengthScoreDashboardChanges as StrengthScoreDashboard[];
      const historicalStrengthScoreResult = result.data
        .strengthScoresByDateRange as StrengthScoreLight[];
      const historicalStrengthBalanceResult = result.data
        .strengthBalancesByDateRange as StrengthScoreLight[];
      dispatch({
        type: CLIENT_STRENGTH_DASHBOARD_SUCCESS,
        historicalStrengthScoreResult,
        historicalStrengthBalanceResult,
        strengthScoreDashboardChangesResult,
      } as ClientStrengthDashboardAction);
    } catch (error) {
      dispatch({
        type: REGENERATE_STRENGTH_DASHBOARD_FAIL,
        data: error,
      });
    }
  };
export const resetStrengthDashboard = () => (dispatch) => {
  dispatch({
    type: RESET_CLIENT_STRENGTH_DASHBOARD,
  });
};
