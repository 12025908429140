import { MediaUrl } from "../../../types/gqlTypes";

export const MEDIA_IS_UPLOADING = "MEDIA_IS_UPLOADING";
export const MEDIA_UPLOAD_SUCCESS = "MEDIA_IS_UPLOAD_SUCCESS";
export const MEDIA_UPLOAD_FAIL = "MEDIA_IS_UPLOAD_FAIL";
export const RESET_MEDIA_STATE = "RESET_MEDIA_STATE";

export interface FileUploadResponse {
  fileName: string;
  fileDownloadUri: string;
  fileType: string;
  size: number;
  mediaUrl: {
    id: string;
    url: string;
  };
}

export interface MediaAction {
  type: string;
  response?: FileUploadResponse;
  mediaUrl?: MediaUrl;
}

export interface MediaState {
  isUploading: boolean;
  fileInfo?: FileUploadResponse;
  mediaUrl?: MediaUrl;
}
