import React from "react";
import colors from "../../assets/colors";

interface Props {
  onAddNewExercise: () => void;
}

const EmptyExerciseList = (props: Props) => {
  const {} = props;

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{
        marginTop: 100,
      }}
    >
      <div
        className="d-flex"
        style={{
          justifyContent: "center",
          marginBottom: 32,
        }}
      >
        <div
          className="d-flex"
          style={{
            width: 96,
            height: 96,
            backgroundColor: "#DADCE3",
            borderRadius: 65,
          }}
        />
      </div>
      <div
        className="d-flex justify-content-center medium-bold"
        style={{
          fontSize: "18px",
          lineHeight: "20px",
          color: colors.caliber_gray_text,
        }}
      >
        No exercises yet
      </div>
    </div>
  );
};

export default EmptyExerciseList;
