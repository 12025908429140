import _ from "lodash";
import {
  ClientSearchAction,
  ClientSearchState,
  CLIENTS_LOADING,
  CLIENTS_LOAD_FAIL,
  CLIENTS_LOAD_SUCCESS,
  RESET_CLIENT_SEARCH_LIST,
  RESET_CLIENT_SEARCH_LIST_FILTER,
  TOGGLE_CLIENT_SEARCH_LIST_FILTER,
} from "../../actions/ClientSearch/types";

const initialState: ClientSearchState = {
  isLoading: false,
  page: null,
  totalPages: null,
  totalElements: null,
  foundClients: null,
  isFilterOpen: false,
};

const clientSearchState = (
  state = initialState,
  action: ClientSearchAction
) => {
  switch (action.type) {
    case CLIENTS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case TOGGLE_CLIENT_SEARCH_LIST_FILTER:
      return {
        ...state,
        isFilterOpen: !state.isFilterOpen,
      };
    case RESET_CLIENT_SEARCH_LIST_FILTER:
      return {
        ...state,
        isFilterOpen: false,
        args: null,
      };
    case CLIENTS_LOAD_SUCCESS: {
      const isFirstPage = action.data.number === 0;
      let mergedData;
      if (!isFirstPage) {
        const collections = [...state.foundClients, ...action.data.content];
        mergedData = _.uniqBy(collections, (item) => item.id);
      }
      return {
        ...state,
        totalElements: action.data.totalElements,
        totalPages: action.data.totalPages,
        page: action.data.number,
        isLoading: false,
        foundClients: isFirstPage ? action.data.content : mergedData,
      };
    }
    case CLIENTS_LOAD_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case RESET_CLIENT_SEARCH_LIST:
      return {
        ...state,
        isLoading: false,
        foundClients: [],
        totalElements: null,
        totalPages: null,
        page: null,
      };
    default:
      return state;
  }
};

export default clientSearchState;
