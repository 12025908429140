import { gql } from "apollo-boost";
import { clientTrainingPlanFragment } from "../clientTrainingPlan";
import { masterPlanFragment } from "../masterTrainingPlan";
import {
  commonGymStepFields,
  masterGymStepsFields,
  masterGymStepsSelectors,
} from "../shared";

export const REORDER_WORKOUTS_IN_CUSTOM_TRAINING_PLAN = gql`
  mutation ReorderMasterWorkoutsInCustomTrainingPlan(
    $trainerId: UUID
    $customTrainingPlanId: UUID
    $masterWorkoutIds: [UUID]
  ) {
    reorderMasterWorkoutsInCustomTrainingPlan(
      trainerId: $trainerId
      customTrainingPlanId: $customTrainingPlanId
      masterWorkoutIds: $masterWorkoutIds
    ) {
      masterWorkouts {
        id
      }
    }
  }
`;

export const REORDER_WORKOUTS_IN_LIBRARY_TRAINING_PLAN = gql`
  mutation ReorderMasterWorkoutsInLibraryTrainingPlan(
    $trainerId: UUID
    $libraryTrainingPlanId: UUID
    $masterWorkoutIds: [UUID]
  ) {
    reorderMasterWorkoutsInLibraryTrainingPlan(
      trainerId: $trainerId
      libraryTrainingPlanId: $libraryTrainingPlanId
      masterWorkoutIds: $masterWorkoutIds
    ) {
      masterWorkouts {
        id
      }
    }
  }
`;

export const REORDER_GYMSTEPS_IN_CUSTOM_TRAINING_PLAN = gql`
  ${masterGymStepsFields}  
  mutation ReorderMasterGymStepsInCustomTrainingPlan(
    $trainerId: UUID
    $customTrainingPlanId: UUID
    $masterGymStepIds: [UUID]
    $masterWorkoutId: UUID
  ){
    reorderMasterGymStepsInCustomTrainingPlan(
      trainerId: $trainerId
      customTrainingPlanId: $customTrainingPlanId
      masterGymStepIds: $masterGymStepIds
      masterWorkoutId: $masterWorkoutId
    ){
      gymSteps {
        ${masterGymStepsSelectors}
      }
    }
  }
`;

export const ADD_GYM_STEP_TO_CUSTOM_TRAINING_PLAN = gql`
  ${masterGymStepsFields}
  mutation AddMasterGymStepToCustomTrainingPlan(
    $trainerId: UUID
    $customTrainingPlanId: UUID
    $exerciseId: UUID
    $masterWorkoutId: UUID
    $position: Int
  ){
    addMasterGymStepToCustomTrainingPlan(
      trainerId: $trainerId
      customTrainingPlanId: $customTrainingPlanId
      exerciseId: $exerciseId
      masterWorkoutId: $masterWorkoutId
      position: $position
    ){
      gymSteps {
        ${masterGymStepsSelectors}
      }
    }
  }
`;

export const ADD_GYM_STEP_TO_LIBRARY_TRAINING_PLAN = gql`
  ${masterGymStepsFields}
  mutation AddMasterGymStepToLibraryTrainingPlan(
    $trainerId: UUID
    $libraryTrainingPlanId: UUID
    $exerciseId: UUID
    $masterWorkoutId: UUID
    $position: Int
  ){
    addMasterGymStepToLibraryTrainingPlan(
      trainerId: $trainerId
      libraryTrainingPlanId: $libraryTrainingPlanId
      exerciseId: $exerciseId
      masterWorkoutId: $masterWorkoutId
      position: $position
    ){
      gymSteps {
        ${masterGymStepsSelectors}
      }
    }
  }
`;

export const ADD_WORKOUT_TO_CUSTOM_TRAINING_PLAN = gql`
  mutation AddNewMasterWorkoutToCustomTrainingPlan(
    $trainerId: UUID
    $customTrainingPlanId: UUID
  ) {
    addNewMasterWorkoutToCustomTrainingPlan(
      trainerId: $trainerId
      customTrainingPlanId: $customTrainingPlanId
    ) {
      masterWorkoutGroups {
        id
        masterWorkouts {
          id
        }
      }
    }
  }
`;

export const ADD_WORKOUT_TO_LIBRARY_TRAINING_PLAN = gql`
  mutation AddNewMasterWorkoutToLibraryTrainingPlan(
    $trainerId: UUID
    $libraryTrainingPlanId: UUID
  ) {
    addNewMasterWorkoutToLibraryTrainingPlan(
      trainerId: $trainerId
      libraryTrainingPlanId: $libraryTrainingPlanId
    ) {
      masterWorkoutGroups {
        id
        masterWorkouts {
          id
        }
      }
    }
  }
`;

export const DELETE_WORKOUT_IN_CUSTOM_TRAINING_PLAN = gql`
  mutation DeleteMasterWorkoutInCustomTrainingPlan(
    $trainerId: UUID
    $customTrainingPlanId: UUID
    $masterWorkoutId: UUID
  ) {
    deleteMasterWorkoutInCustomTrainingPlan(
      trainerId: $trainerId
      customTrainingPlanId: $customTrainingPlanId
      masterWorkoutId: $masterWorkoutId
    )
  }
`;

export const DELETE_WORKOUT_IN_LIBRARY_TRAINING_PLAN = gql`
  mutation DeleteMasterWorkoutInLibraryTrainingPlan(
    $trainerId: UUID
    $libraryTrainingPlanId: UUID
    $masterWorkoutId: UUID
  ) {
    deleteMasterWorkoutInLibraryTrainingPlan(
      trainerId: $trainerId
      libraryTrainingPlanId: $libraryTrainingPlanId
      masterWorkoutId: $masterWorkoutId
    )
  }
`;

export const DELETE_GYM_STEP_IN_CUSTOM_TRAINING_PLAN = gql`
  mutation DeleteMasterGymStepInCustomTrainingPlan(
    $trainerId: UUID
    $customTrainingPlanId: UUID
    $masterGymStepId: UUID
  ) {
    deleteMasterGymStepInCustomTrainingPlan(
      trainerId: $trainerId
      customTrainingPlanId: $customTrainingPlanId
      masterGymStepId: $masterGymStepId
    )
  }
`;

export const DELETE_GYM_STEP_IN_LIBRARY_TRAINING_PLAN = gql`
  mutation DeleteMasterGymStepInLibraryTrainingPlan(
    $trainerId: UUID
    $libraryTrainingPlanId: UUID
    $masterGymStepId: UUID
  ) {
    deleteMasterGymStepInLibraryTrainingPlan(
      trainerId: $trainerId
      libraryTrainingPlanId: $libraryTrainingPlanId
      masterGymStepId: $masterGymStepId
    )
  }
`;

export const UPDATE_WORKOUT_IN_CUSTOM_TRAINING_PLAN = gql`
  mutation UpdateMasterWorkoutInCustomTrainingPlan(
    $trainerId: UUID
    $customTrainingPlanId: UUID
    $masterWorkoutId: UUID
    $instructions: String
    $title: String
    $thumbnailImageMediaUrlId: UUID
  ) {
    updateMasterWorkoutInCustomTrainingPlan(
      trainerId: $trainerId
      customTrainingPlanId: $customTrainingPlanId
      masterWorkoutId: $masterWorkoutId
      instructions: $instructions
      title: $title
      thumbnailImageMediaUrlId: $thumbnailImageMediaUrlId
    ) {
      id
      title
      instructions
      thumbnailImage {
        id
        url
      }
    }
  }
`;

export const UPDATE_GYM_STEP_FOR_CUSTOM_TRAINING_PLAN = gql`
  mutation UpdateMasterGymStepForCustomTrainingPlan(
    $trainerId: UUID
    $customTrainingPlanId: UUID
    $hideWeightForExerciseSets: Boolean
    $masterGymStepId: UUID
    $supersetTag: SupersetTagType
    $target: String
    $targetRepsLowerBound: Int
    $targetRepsUpperBound: Int
    $targetSets: Int
    $targetTime: Int
    $targetWeight: Float
    $generateSmartWarmupSets: Boolean
    $smartWarmupSetsCount: Int
    $restPeriod: Int
  ){
    updateMasterGymStepForCustomTrainingPlan(
      trainerId: $trainerId
      customTrainingPlanId: $customTrainingPlanId
      hideWeightForExerciseSets: $hideWeightForExerciseSets
      masterGymStepId: $masterGymStepId
      supersetTag: $supersetTag
      target: $target
      targetRepsLowerBound: $targetRepsLowerBound
      targetRepsUpperBound: $targetRepsUpperBound
      targetSets: $targetSets
      targetTime: $targetTime
      targetWeight: $targetWeight
      generateSmartWarmupSets: $generateSmartWarmupSets
      smartWarmupSetsCount: $smartWarmupSetsCount
      restPeriod: $restPeriod
    ){
      ${commonGymStepFields}
    }
  }
`;

export const CREATE_CUSTOM_TRAINING_PLAN = gql`
  mutation CreateCustomTrainingPlan(
    $trainerId: UUID
    $proGroupId: UUID
    $title: String!
  ) {
    createCustomTrainingPlan(
      trainerId: $trainerId
      proGroupId: $proGroupId
      title: $title
    ) {
      id
      name
    }
  }
`;

export const CREATE_LIBRARY_TRAINING_PLAN = gql`
  mutation CreateLibraryTrainingPlan($name: String!, $trainerId: UUID!) {
    createLibraryTrainingPlan(name: $name, trainerId: $trainerId) {
      id
      name
    }
  }
`;

export const UPDATE_CUSTOM_TRAINING_PLAN = gql`
  mutation UpdateCustomTrainingPlan(
    $trainerId: UUID
    $customTrainingPlanId: UUID
    $name: String
  ) {
    updateCustomTrainingPlan(
      trainerId: $trainerId
      customTrainingPlanId: $customTrainingPlanId
      name: $name
    ) {
      id
      name
      active
      proGroup {
        id
        name
      }
    }
  }
`;

export const UPDATE_LIBRARY_TRAINING_PLAN = gql`
  mutation UpdateLibraryTrainingPlan(
    $trainerId: UUID
    $libraryTrainingPlanId: UUID!
    $name: String
  ) {
    updateLibraryTrainingPlan(
      trainerId: $trainerId
      libraryTrainingPlanId: $libraryTrainingPlanId
      name: $name
    ) {
      id
      name
      active
    }
  }
`;

export const DELETE_CUSTOM_TRAINING_PLAN = gql`
  mutation DeleteCustomTrainingPlan(
    $trainerId: UUID
    $customTrainingPlanId: UUID
  ) {
    deleteCustomTrainingPlan(
      trainerId: $trainerId
      customTrainingPlanId: $customTrainingPlanId
    )
  }
`;

export const DELETE_LIBRARY_TRAINING_PLAN = gql`
  mutation DeleteLibraryTrainingPlan(
    $trainerId: UUID!
    $libraryTrainingPlanId: UUID!
  ) {
    deleteLibraryTrainingPlan(
      trainerId: $trainerId
      libraryTrainingPlanId: $libraryTrainingPlanId
    )
  }
`;

export const DELETE_CLIENT_WORKOUT_GROUP_TEMPLATE = gql`
  mutation DeleteClientWorkoutGroupTemplate(
    $clientId: UUID
    $clientWorkoutGroupTemplateId: UUID
  ) {
    deleteClientWorkoutGroupTemplate(
      clientId: $clientId
      clientWorkoutGroupTemplateId: $clientWorkoutGroupTemplateId
    )
  }
`;
export const SEARCH_CUSTOM_TRAINING_PLANS = gql`
  query SearchCustomTrainingPlans(
    $trainerId: UUID!
    $type: CustomTrainingPlanType
    $page: Int!
    $name: String
  ) {
    searchCustomTrainingPlans(
      trainerId: $trainerId
      type: $type
      page: $page
      name: $name
    ) {
      last
      number
      numberOfElements
      totalElements
      totalPages
      content {
        id
        name
        active
        proGroup {
          id
          name
        }
      }
    }
  }
`;

export const SEARCH_LIBRARY_TRAINING_PLANS = gql`
  query SearchLibraryTrainingPlans($trainerId: UUID, $page: Int!) {
    searchLibraryTrainingPlans(trainerId: $trainerId, page: $page) {
      last
      number
      numberOfElements
      totalElements
      totalPages
      content {
        id
        name
        active
      }
    }
  }
`;

export const customPlanFragment = gql`
${masterGymStepsFields}
fragment CustomPlanProperty on CustomTrainingPlan {
  id
  active
  masterWorkoutGroups {
    id
    masterWorkouts {
      id
      sortOrder
      title
      instructions
      thumbnailImage {
        id
        url
      }
      gymSteps {
        ${masterGymStepsSelectors}
      }
    }
  }
}
`;

export const libraryPlanFragment = gql`
${masterGymStepsFields}
fragment LibraryPlanProperty on LibraryTrainingPlan {
  id
  active
  masterWorkoutGroups {
    id
    masterWorkouts {
      id
      sortOrder
      title
      instructions
      thumbnailImage {
        id
        url
      }
      gymSteps {
        ${masterGymStepsSelectors}
      }
    }
  }
}
`;

export const GET_TRAINING_PLAN = gql`
  ${clientTrainingPlanFragment}
  ${masterPlanFragment}
  ${customPlanFragment}
  query GetTrainingPlan($trainingPlanId: UUID) {
    trainingPlan(trainingPlanId: $trainingPlanId) {
      id
      ... on ClientTrainingPlan {
        ...ClientTrainingPlanProperty
      }
      ... on MasterTrainingPlan {
        ...MasterPlanProperty
      }
      ... on CustomTrainingPlan {
        name
        ...CustomPlanProperty
      }
    }
  }
`;

export const GET_LIBRARY_TRAINING_PLAN = gql`
  ${libraryPlanFragment}
  query GetLibraryTrainingPlan($trainingPlanId: UUID!) {
    libraryTrainingPlan(libraryTrainingPlanId: $trainingPlanId) {
      id
      ... on LibraryTrainingPlan {
        name
        ...LibraryPlanProperty
      }
    }
  }
`;

export const COPY_TRAINING_PLAN_TO_TRAINER = gql`
  mutation CopyTrainingPlanToTrainer(
    $clientId: UUID
    $trainerId: UUID
    $trainingPlanId: UUID
    $name: String!
  ) {
    copyTrainingPlanToTrainer(
      clientId: $clientId
      trainerId: $trainerId
      trainingPlanId: $trainingPlanId
      name: $name
    ) {
      id
      name
    }
  }
`;
export const TOGGLE_ACTIVATION_CUSTOM_TRAINING_PLAN = gql`
  mutation toggleCustomTrainingPlanState($customTrainingPlanId: UUID) {
    toggleCustomTrainingPlanState(customTrainingPlanId: $customTrainingPlanId) {
      id
      active
    }
  }
`;
export const CLONE_CUSTOM_TRAINING_PLAN = gql`
  mutation CloneCustomTrainingPlan($trainingPlanId: UUID) {
    cloneCustomTrainingPlan(trainingPlanId: $trainingPlanId) {
      id
      name
      active
      proGroup {
        id
        name
      }
    }
  }
`;
