import React, { useEffect, useRef, useState } from "react";
import { GroupChannel } from "sendbird";
import colors from "../../assets/colors";
import MessageAttachIcon from "../../assets/images/MessageAttachIcon.svg";
import MessageSendIcon from "../../assets/images/MessageSendIcon.svg";
import { useDebounce, useRerenderOnResize } from "../../utils/customHooks";
import SimpleButton from "../SimpleButton";

interface Props {
  sendTextMessage: (message: string) => void;
  sendFileMessage: (file: any) => void;
  sendVideoMessage: (file: any) => void;
  activeChannel?: GroupChannel;
  isChatWindow?: boolean;
  isMediaUploading?: boolean;
}

const MessagesInputField = (props: Props) => {
  const {
    sendTextMessage,
    sendFileMessage,
    sendVideoMessage,
    activeChannel,
    isChatWindow,
    isMediaUploading,
  } = props;
  useRerenderOnResize();

  const [bgColor, setBgColor] = useState(colors.caliber_gray_bg);
  const [enteredText, setEnteredText] = useState(null);
  const debouncedValue = useDebounce(enteredText, 500);
  const textArea = document.getElementById("message-textarea");

  const getSavedDraft = (channel: GroupChannel) => {
    if (channel?.url) {
      return window?.localStorage?.getItem(`draft_msg_${channel?.url}`);
    }
    return null;
  };

  useEffect(() => {
    if (activeChannel?.url) {
      const draft = getSavedDraft(activeChannel);
      setEnteredText(draft || "");
    }
    return () => {
      setEnteredText(null);
    };
  }, [activeChannel]);
  useEffect(() => {
    if (enteredText != null && activeChannel?.url) {
      window?.localStorage?.setItem(
        `draft_msg_${activeChannel?.url}`,
        debouncedValue
      );
    }
  }, [debouncedValue]);

  const fileInputRef = useRef(null);

  const lineHeight = 20;
  const textFieldStyle: React.CSSProperties = {
    lineHeight: `${lineHeight}px`,
    fontSize: "14px",
    minHeight: isChatWindow ? 3 * lineHeight + 24 : 8 * lineHeight + 24,
    maxHeight: isChatWindow ? 8 * lineHeight : window.innerHeight * 0.65,
    marginBottom: 10,
    borderRadius: 4,
    backgroundColor: bgColor,
    padding: 12,
  };

  return (
    <div
      className="d-flex flex-column flex-fill"
      style={{
        minHeight: 3 * lineHeight + 44,
        backgroundColor: colors.caliber_white,
      }}
    >
      <div
        style={{
          height: 1,
          backgroundColor: colors.caliber_gray_bg,
          width: "100%",
        }}
      />
      <div
        className="d-flex align-items-center flex-fill"
        style={{
          paddingLeft: 24,
          paddingRight: 8,
          paddingTop: 10,
        }}
      >
        <textarea
          id="message-textarea"
          className="flex-fill borderless"
          style={textFieldStyle}
          onChange={(event) => {
            setEnteredText(event.target.value);
            if (textArea && textArea.style) {
              textArea.style.height = `${textArea.scrollHeight}px`;
            }
          }}
          onFocus={(event) => {
            setBgColor(colors.caliber_white);
          }}
          onBlur={(event) => {
            if (enteredText.length === 0) {
              setBgColor(colors.caliber_gray_bg);
            }
          }}
          placeholder="Enter Message"
          value={activeChannel?.url ? enteredText || "" : ""}
        />
        <div>
          <input
            ref={fileInputRef}
            type="file"
            name="file"
            onChange={(event) => {
              const file = event.target?.files?.[0];
              if (
                file?.type?.startsWith("image") ||
                file?.type?.includes("pdf")
              ) {
                sendFileMessage(event.target.files[0]);
              } else if (file?.type?.startsWith("video")) {
                sendVideoMessage(event.target.files[0]);
              } else {
                // TODO: Display error
                console.log("Invalid filetype", file?.type);
              }
            }}
            style={{
              display: "none",
            }}
          />
          <SimpleButton
            style={{
              backgroundColor: "transparent",
              border: "none",
              color: "black",
              padding: "12px 16px",
            }}
            styleOnHover={
              !isMediaUploading && {
                backgroundColor: "#f2f3f5",
              }
            }
            disabled={isMediaUploading}
            nopointer={isMediaUploading}
            nofocus={isMediaUploading}
            onClick={() => {
              fileInputRef.current.click();
            }}
          >
            <img src={MessageAttachIcon} alt="attach" />
          </SimpleButton>
          <button
            className="general-btn"
            type="button"
            onClick={() => {
              if (enteredText?.length > 0) {
                sendTextMessage(enteredText);
                setBgColor(colors.caliber_gray_bg);
                setEnteredText("");
              }
            }}
          >
            <img src={MessageSendIcon} alt="send" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default MessagesInputField;
