import React from "react";
import { connect } from "react-redux";
import colors from "../../assets/colors";
import SimpleButton from "../SimpleButton";
import Badge, { BadgeType } from "../Badge";
import { toggleCustomTrainingPlanState } from "../../redux/actions/TrainingPrograms/update";
import { StoreState } from "../../redux/reducers";
import {
  ClientTrainingPlan,
  CustomTrainingPlan,
  MasterTrainingPlan,
} from "../../types/gqlTypes";

interface Props {
  id: string;
  title: string;
  index: number;
  isSelected?: boolean;
  onClick?: Function;
  proGroupName?: string;
  active?: boolean;
  toggleCustomTrainingPlanState: (customTrainingPlanId: string) => void;
  cachedPlans: {
    [key: string]: ClientTrainingPlan | MasterTrainingPlan | CustomTrainingPlan;
  };
}

const CustomPlanCard = (props: Props) => {
  const {
    id,
    title,
    isSelected,
    index,
    onClick,
    proGroupName,
    active,
    toggleCustomTrainingPlanState,
    cachedPlans,
  } = props;

  const actualActive =
    cachedPlans[id] && proGroupName
      ? (cachedPlans[id] as CustomTrainingPlan).active
      : active;

  return (
    <SimpleButton
      className="d-flex flex-column justify-content-center nofocus"
      style={{
        minHeight: 80,
        padding: "0px 20px",
        backgroundColor: isSelected
          ? colors.caliber_secondary_gray_11
          : colors.caliber_secondary_gray_5,
        borderRadius: 8,
        marginTop: index === 0 ? 0 : 8,
        marginRight: 4,
      }}
      onClick={() => onClick?.()}
    >
      <div
        className="bold"
        style={{
          fontSize: "18px",
          lineHeight: "20px",
          letterSpacing: "-0.022em",
          color: "#000000",
          paddingBottom: 7,
        }}
      >
        {title}
      </div>
      {proGroupName && (
        <div className="d-flex">
          <span
            aria-hidden="true"
            onClick={(e) => {
              e.stopPropagation();
              toggleCustomTrainingPlanState(id);
            }}
          >
            {actualActive ? (
              <Badge text="Active" type={BadgeType.ACTIVE} />
            ) : (
              <Badge text="Inactive" type={BadgeType.INACTIVE} />
            )}
          </span>
          <span>
            <Badge text={proGroupName} type={BadgeType.INFO} />
          </span>
        </div>
      )}
    </SimpleButton>
  );
};

const mapStateToProps = (state: StoreState) => ({
  cachedPlans: state.trainingProgramsState.cachedPlans,
});

const mapDispatchToProps = (dispatch) => ({
  toggleCustomTrainingPlanState: (customTrainingPlanId: string) => {
    dispatch(toggleCustomTrainingPlanState(customTrainingPlanId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomPlanCard);
