import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import ExerciseLibraryAddEditExercise from "../../components/ExerciseLibraryAddEditExercise";
import ExerciseLibraryDetailsView from "../../components/ExerciseLibraryDetailsView";
import ExerciseLibraryList from "../../components/ExerciseLibraryList";
import Loader from "../../components/Loader";
import { saveExercise } from "../../redux/actions/AdminExerciseLibrary";
import { AdminExerciseLibraryState } from "../../redux/actions/AdminExerciseLibrary/types";
import { StoreState } from "../../redux/reducers";
import {
  Exercise,
  ExerciseInput,
  ExerciseType,
  QuerySearchExercisesArgs,
} from "../../types/gqlTypes";
import ExerciseLibraryHeader, { ButtonState } from "./ExerciseLibraryHeader";

enum ExerciseLibraryState {
  Selecting,
  Viewing,
  Editing,
  Creating,
  Filtering,
}
export enum FilterTypes {
  Equipment,
  Type,
  MuscleGroup,
  Level,
  Warmups,
}

interface OwnProps {
  viewOnly?: boolean;
}
interface Props extends OwnProps {
  saveExercise: (exerciseInput: ExerciseInput) => void;
  adminExerciseLibraryState: AdminExerciseLibraryState;
}

const AdminExerciseLibraryScreen = (props: Props) => {
  const { saveExercise, viewOnly, adminExerciseLibraryState } = props;

  const [searchText, setSearchText] = useState("");
  const [selectedExercise, setSelectedExercise] = useState<Exercise>();
  const [exerciseLibraryState, setExerciseLibraryState] =
    useState<ExerciseLibraryState>(ExerciseLibraryState.Filtering);
  const [queryFilterArgs, setQueryFilterArgs] =
    useState<QuerySearchExercisesArgs>({
      page: 0,
      textCriteria: "",
      showWarmups: false,
    });

  const startedSaving = useRef(adminExerciseLibraryState.isSaving);
  useEffect(() => {
    if (startedSaving.current && !adminExerciseLibraryState.isSaving) {
      startedSaving.current = false;
      if (adminExerciseLibraryState.lastSavedExercise) {
        setSelectedExercise(adminExerciseLibraryState.lastSavedExercise);
      }
      setExerciseLibraryState(ExerciseLibraryState.Viewing);
      return;
    }
    if (adminExerciseLibraryState.isSaving) {
      startedSaving.current = true;
    }
    if (adminExerciseLibraryState.isLoading) {
      setSelectedExercise(null);
    }
  }, [adminExerciseLibraryState.isSaving, adminExerciseLibraryState.isLoading]);

  const editingExerciseInput = useRef<ExerciseInput>();

  const isEditing =
    exerciseLibraryState === ExerciseLibraryState.Creating ||
    exerciseLibraryState === ExerciseLibraryState.Editing;
  const isFiltering = exerciseLibraryState === ExerciseLibraryState.Filtering;

  const onSelectFilterTag = (tag: any, type: FilterTypes) => {
    if (type === FilterTypes.Equipment) {
      if (queryFilterArgs.exerciseEquipmentCriteria === tag) {
        const newQueries = { ...queryFilterArgs };
        delete newQueries.exerciseEquipmentCriteria;
        setQueryFilterArgs(newQueries);
      } else {
        setQueryFilterArgs({
          ...queryFilterArgs,
          exerciseEquipmentCriteria: tag,
        });
      }
    } else if (type === FilterTypes.Level) {
      if (queryFilterArgs.exerciseLevelCriteria === tag) {
        const newQueries = { ...queryFilterArgs };
        delete newQueries.exerciseLevelCriteria;
        setQueryFilterArgs(newQueries);
      } else {
        setQueryFilterArgs({
          ...queryFilterArgs,
          exerciseLevelCriteria: tag,
        });
      }
    } else if (type === FilterTypes.MuscleGroup) {
      const isEmpty = !queryFilterArgs.muscleGroupCriteria;
      const isSelected =
        !isEmpty && queryFilterArgs.muscleGroupCriteria.includes(tag);
      if (isEmpty) {
        setQueryFilterArgs({
          ...queryFilterArgs,
          muscleGroupCriteria: [tag],
        });
      } else if (
        isSelected &&
        queryFilterArgs.muscleGroupCriteria.length === 1
      ) {
        const newQueries = { ...queryFilterArgs };
        delete newQueries.muscleGroupCriteria;
        setQueryFilterArgs(newQueries);
      } else if (isSelected) {
        setQueryFilterArgs({
          ...queryFilterArgs,
          muscleGroupCriteria: queryFilterArgs.muscleGroupCriteria.filter(
            (muscle) => muscle !== tag
          ),
        });
      } else {
        setQueryFilterArgs({
          ...queryFilterArgs,
          muscleGroupCriteria: [...queryFilterArgs.muscleGroupCriteria, tag],
        });
      }
    } else if (type === FilterTypes.Type) {
      if (queryFilterArgs.exerciseTypeCriteria === tag) {
        const newQueries = { ...queryFilterArgs };
        delete newQueries.exerciseTypeCriteria;
        setQueryFilterArgs(newQueries);
      } else {
        setQueryFilterArgs({
          ...queryFilterArgs,
          exerciseTypeCriteria: tag,
        });
      }
    } else if (type === FilterTypes.Warmups) {
      setQueryFilterArgs({
        ...queryFilterArgs,
        showWarmups: !queryFilterArgs.showWarmups,
        exerciseTypeCriteria: !queryFilterArgs.showWarmups
          ? ExerciseType.Warmup
          : undefined,
      });
    }
  };

  let buttonState;
  switch (exerciseLibraryState) {
    case ExerciseLibraryState.Selecting:
    case ExerciseLibraryState.Filtering:
      buttonState = ButtonState.AddExercise;
      break;
    case ExerciseLibraryState.Creating:
      buttonState = ButtonState.SaveExercise;
      // TODO: Validate input / update button states
      // if (editingExerciseInput.current !== null) {
      //   buttonState = ButtonState.SaveExercise;
      // } else {
      //   buttonState = ButtonState.SaveExerciseDisabled;
      // }
      break;
    case ExerciseLibraryState.Viewing:
      buttonState = ButtonState.EditExercise;
      break;
    case ExerciseLibraryState.Editing:
      buttonState = ButtonState.SaveExercise;
      break;
  }

  const loader = (
    <div
      className="d-flex justify-content-center"
      style={{
        flex: 1,
      }}
    >
      <Loader />
    </div>
  );

  const exerciseView = (() => {
    if (startedSaving.current) {
      return loader;
    }
    if (exerciseLibraryState === ExerciseLibraryState.Filtering) {
      return (
        <ExerciseLibraryDetailsView
          asFilterComponent
          key="FilterComponentKey"
          onFilterTagClick={onSelectFilterTag}
          filterState={queryFilterArgs}
        />
      );
    }
    if (
      exerciseLibraryState === ExerciseLibraryState.Viewing &&
      selectedExercise
    ) {
      return (
        <ExerciseLibraryDetailsView
          viewExerciseMode
          key={selectedExercise.id}
          exercise={selectedExercise}
        />
      );
    }
    if (exerciseLibraryState === ExerciseLibraryState.Editing) {
      return (
        <ExerciseLibraryAddEditExercise
          key={selectedExercise.id}
          exercise={selectedExercise}
          onInputChanged={(exerciseInput) => {
            editingExerciseInput.current = exerciseInput;
          }}
        />
      );
    }
    return null;
  })();

  return (
    <div
      className="d-flex flex-column"
      style={{
        flex: 1,
      }}
    >
      <ExerciseLibraryHeader
        isFilterOn={isFiltering}
        viewOnly={viewOnly}
        buttonState={buttonState}
        onSearchTextEntered={(text) => {
          setSearchText(text);
        }}
        onAddExercise={() => {
          setExerciseLibraryState(ExerciseLibraryState.Creating);
        }}
        onEditExercise={() => {
          setExerciseLibraryState(ExerciseLibraryState.Editing);
        }}
        onSaveExercise={() => {
          if (adminExerciseLibraryState.isSaving) {
            return;
          }
          if (
            (editingExerciseInput.current as ExerciseInput)?.targetBase &&
            (editingExerciseInput.current as ExerciseInput)?.name
          ) {
            saveExercise(editingExerciseInput.current);
          }
        }}
        onCancelEditing={() => {
          if (exerciseLibraryState === ExerciseLibraryState.Creating) {
            setExerciseLibraryState(ExerciseLibraryState.Selecting);
          } else {
            setExerciseLibraryState(ExerciseLibraryState.Viewing);
          }
        }}
        onFilterToggleClicked={() => {
          if (exerciseLibraryState === ExerciseLibraryState.Filtering) {
            setExerciseLibraryState(null);
          } else {
            setExerciseLibraryState(ExerciseLibraryState.Filtering);
          }
          setSelectedExercise(null);
        }}
      />
      <div
        className="d-flex"
        style={{
          position: "relative",
          flex: 1,
        }}
      >
        <div
          className="d-flex"
          style={{
            height: "100%",
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <div
            className="d-flex"
            style={{
              flex: 1,
              marginLeft: 19,
              marginRight: 12,
            }}
          >
            {/* @ts-ignore */}
            <ExerciseLibraryList
              viewOnly={viewOnly}
              queryArgs={queryFilterArgs}
              searchText={searchText}
              selectedExercise={selectedExercise}
              onExerciseSelected={(exercise: Exercise) => {
                if (isEditing) {
                  return;
                }
                if (selectedExercise?.id === exercise.id) {
                  setSelectedExercise(null);
                  setExerciseLibraryState(ExerciseLibraryState.Selecting);
                } else {
                  setSelectedExercise(exercise);
                  setExerciseLibraryState(ExerciseLibraryState.Viewing);
                }
              }}
            />
          </div>
          {(selectedExercise ||
            exerciseLibraryState === ExerciseLibraryState.Filtering) && (
            <div
              className="d-flex overflow-auto"
              style={{
                flex: 1,
              }}
            >
              {exerciseView}
            </div>
          )}
          {exerciseLibraryState === ExerciseLibraryState.Creating &&
            (adminExerciseLibraryState.isSaving ? (
              <Loader />
            ) : (
              <div
                className="d-flex overflow-auto"
                style={{
                  flex: 1,
                }}
              >
                <ExerciseLibraryAddEditExercise
                  onInputChanged={(exerciseInput) => {
                    editingExerciseInput.current = exerciseInput;
                  }}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  saveExercise: (exerciseInput: ExerciseInput) => {
    dispatch(saveExercise(exerciseInput));
  },
});

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  adminExerciseLibraryState: state.adminExerciseLibraryState,
  viewOnly: ownProps.viewOnly,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminExerciseLibraryScreen);
