import { gql } from "apollo-boost";
import { medialUrlFragment } from "../../calendar";
import { userFragment } from "..";

export const managerFragment = gql`
  ${userFragment}
  ${medialUrlFragment}
  fragment ManagerProperty on Manager {
    ...UserData
    assignedTrainers {
      ...UserData
    }
  }
`;

export const SEARCH_MANAGERS = gql`
  ${managerFragment}
  query SearchManagers(
    $page: Int!
    $searchCriteria: String
    $statusCriteria: [UserStatusType]
  ) {
    searchManagers(
      page: $page
      searchCriteria: $searchCriteria
      statusCriteria: $statusCriteria
    ) {
      content {
        ...ManagerProperty
      }
      last
      number
      numberOfElements
      totalElements
      totalPages
    }
  }
`;

export const CREATE_MANAGER = gql`
  ${managerFragment}
  mutation CreateManager(
    $firstName: String
    $lastName: String
    $email: String!
    $defaultPassword: String
    $profileIconMediaUrlId: UUID
    $type: UserType!
  ) {
    createUserProfile(
      firstName: $firstName
      lastName: $lastName
      email: $email
      defaultPassword: $defaultPassword
      profileIconMediaUrlId: $profileIconMediaUrlId
      type: $type
    ) {
      ...ManagerProperty
    }
  }
`;

export const UPDATE_MANAGER = gql`
  ${managerFragment}
  mutation UpdateManager(
    $birthDate: String
    $city: String
    $country: String
    $dateMask: String
    $email: String
    $firstName: String
    $gender: GenderType
    $isConsultationCompleted: Boolean
    $isProfileCompleted: Boolean
    $lastName: String
    $phone: String
    $state: String
    $unitHeight: UnitType!
    $userId: UUID!
    $status: UserStatusType
  ) {
    updateUserProfile(
      birthDate: $birthDate
      city: $city
      country: $country
      dateMask: $dateMask
      email: $email
      firstName: $firstName
      gender: $gender
      isConsultationCompleted: $isConsultationCompleted
      isProfileCompleted: $isProfileCompleted
      lastName: $lastName
      phone: $phone
      state: $state
      unitHeight: $unitHeight
      userId: $userId
      status: $status
    ) {
      ...ManagerProperty
    }
  }
`;
