import { selectWorkoutGroups, selectWorkouts } from ".";
import {
  ClientWorkoutGroupTemplate,
  ClientWorkoutTemplate,
  MasterWorkout,
} from "../../../types/gqlTypes";
import {
  REORDER_GYM_STEPS_IN_TRAINING_PLAN_FAIL,
  REORDER_GYM_STEPS_IN_TRAINING_PLAN_SAVING,
  REORDER_GYM_STEPS_IN_TRAINING_PLAN_SUCCESS,
  REORDER_WORKOUT_ACROSS_GROUPS_FAIL,
  REORDER_WORKOUT_ACROSS_GROUPS_SAVING,
  REORDER_WORKOUT_ACROSS_GROUPS_SUCCESS,
  REORDER_WORKOUT_FAIL,
  REORDER_WORKOUT_GROUP_FAIL,
  REORDER_WORKOUT_GROUP_SAVING,
  REORDER_WORKOUT_GROUP_SUCCESS,
  REORDER_WORKOUT_SAVING,
  REORDER_WORKOUT_SUCCESS,
  TrainingProgramsAction,
  TrainingProgramsState,
} from "../../actions/TrainingPrograms/types";
import {
  replaceGymSteps,
  replaceWorkoutGroups,
  replaceWorkouts,
} from "./helperFunctions";

const reorderReducer = (
  state: TrainingProgramsState,
  action: TrainingProgramsAction
) => {
  switch (action.type) {
    case REORDER_WORKOUT_FAIL:
    case REORDER_WORKOUT_ACROSS_GROUPS_FAIL:
    case REORDER_WORKOUT_GROUP_FAIL:
      return {
        ...state,
        isLoading: false,
        message: (action as any).data.message,
      };
    case REORDER_WORKOUT_SAVING:
    case REORDER_WORKOUT_ACROSS_GROUPS_SAVING:
    case REORDER_WORKOUT_GROUP_SAVING:
      return {
        ...state,
        isLoading: true,
      };
    case REORDER_WORKOUT_SUCCESS: {
      const workouts = selectWorkouts(state);
      const newWorkouts: (MasterWorkout | ClientWorkoutTemplate)[] =
        action.ids.map((id) => {
          return workouts.find(({ id: foundId }) => id === foundId);
        });
      return replaceWorkouts({ newWorkouts, state });
    }
    case REORDER_WORKOUT_GROUP_SUCCESS: {
      const workoutGroups = selectWorkoutGroups(state);
      const newWorkoutGroups: ClientWorkoutGroupTemplate[] = action.ids.map(
        (id) => {
          return workoutGroups.find(({ id: foundId }) => id === foundId);
        }
      );
      return replaceWorkoutGroups({ newWorkoutGroups, state });
    }
    case REORDER_WORKOUT_ACROSS_GROUPS_SUCCESS: {
      const { cachedPlans } = state;
      return {
        ...state,
        isSaving: false,
        isLoading: false,
        cachedPlans: {
          ...cachedPlans,
          [action.plan.id]: action.plan,
        },
      };
    }
    case REORDER_GYM_STEPS_IN_TRAINING_PLAN_FAIL:
      return {
        ...state,
        isSaving: false,
        message: (action as any).data.message,
      };
    case REORDER_GYM_STEPS_IN_TRAINING_PLAN_SAVING:
      return {
        ...state,
        isSaving: true,
      };
    case REORDER_GYM_STEPS_IN_TRAINING_PLAN_SUCCESS: {
      const { workout } = action;
      const { gymSteps } = workout;
      return replaceGymSteps({ newGymSteps: gymSteps, state });
    }
    default:
      return state;
  }
};
export default reorderReducer;
