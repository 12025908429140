import React from "react";
import colors from "../../../assets/colors";
import {
  CalendarDay,
  ClientHabitTarget,
  HabitCalendarItem,
  HabitType,
} from "../../../types/gqlTypes";
import StepsHabitCard from "./StepsHabitCard";

interface Props {
  calendarDay: CalendarDay;
  habits: ClientHabitTarget[];
  onClick: (value: HabitType) => void;
}

const SelectHabit = (props: Props) => {
  const { onClick, calendarDay, habits } = props;

  const enabledHabits = {
    [HabitType.Steps]: habits?.find(
      ({ habitType }) => habitType === HabitType.Steps
    ),
  };
  const existingHabitCalendarItems = {
    [HabitType.Steps]: false,
  };
  Object.keys(existingHabitCalendarItems).forEach((value) => {
    const exist = calendarDay?.items?.some(
      (item: HabitCalendarItem) => item?.habitType === value
    );
    existingHabitCalendarItems[value] = exist;
  });

  return (
    <div>
      <div
        className="medium-bold"
        style={{
          color: colors.caliber_gray_text,
          fontSize: "14px",
          lineHeight: "24px",
          margin: "25px 0px 16px 0px",
        }}
      >
        Select habit
      </div>
      <div className="d-flex justify-content-between align-items-center">
        {enabledHabits[HabitType.Steps] && (
          <StepsHabitCard
            key="select-steps-card"
            habit={enabledHabits[HabitType.Steps] as ClientHabitTarget}
            disabled={existingHabitCalendarItems[HabitType.Steps]}
            isClickable
            onClick={() => onClick(HabitType.Steps)}
          />
        )}
      </div>
    </div>
  );
};

export default SelectHabit;
