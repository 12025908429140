import React, { useRef, useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { connect } from "react-redux";
import { StoreState } from "../../redux/reducers";
import { fileUpload, resetMediaState } from "../../redux/actions/Media";
import { FileUploadType } from "../../types/gqlTypes";
import { MediaContext } from "../ExerciseLibraryAddEditExercise/utils";
import { MediaState } from "../../redux/actions/Media/types";
import ErrorIcon from "../../assets/images/ErrorIcon.svg";
import colors from "../../assets/colors";
import Loader from "../Loader";
import { onSuccessDialogOpen } from "../../redux/actions/ErrorSuccessDialog";

interface OwnProps {
  onMediaUrlIdChange: (id: string) => void;
  error: boolean;
  ownImageUrl?: string;
}
interface Props extends OwnProps {
  uploadImage: (file: any, type: FileUploadType) => void;
  onSuccessDialogOpen: (message: string) => void;
  resetMediaState: () => void;
  mediaState: MediaState;
}

const ProfileImage = (props: Props) => {
  const {
    error,
    uploadImage,
    resetMediaState,
    onMediaUrlIdChange,
    onSuccessDialogOpen,
    mediaState,
    ownImageUrl,
  } = props;

  const nameCutter = (fileName) => {
    const splitted = fileName ? fileName.split(".") : [];
    const format = splitted.length > 1 ? splitted.pop() : "";
    return splitted.join("").length > 17
      ? `${fileName.slice(0, 17)}...${format}`
      : fileName;
  };

  const fileInputRef = useRef(null);
  const mediaContext = useRef<MediaContext>();
  const showLoader =
    mediaState.isUploading && mediaContext.current === MediaContext.Thumbnail;

  const [imageUrl, setImageUrl] = useState(
    ownImageUrl || mediaState.fileInfo?.mediaUrl?.url
  );
  const imageId = mediaState.fileInfo?.mediaUrl?.id;
  const imageName = mediaState.fileInfo?.fileName;
  const imageStyle = {
    backgroundColor: colors.caliber_dark_gray,
    borderRadius: 4,
    width: 48,
    height: 48,
    marginRight: 12,
  };

  useEffect(() => {
    setImageUrl(mediaState.fileInfo?.mediaUrl?.url || ownImageUrl);
  }, [ownImageUrl, mediaState.fileInfo?.mediaUrl?.url]);

  useEffect(() => {
    if (imageId) {
      onMediaUrlIdChange(imageId);
    }
  }, [imageId]);

  return (
    <div className="d-flex">
      {showLoader ? (
        <div
          style={{
            ...imageStyle,
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 8,
              left: 8,
            }}
          >
            <Loader />
          </div>
        </div>
      ) : (
        <div style={imageStyle}>
          {imageUrl ? (
            <Image style={imageStyle} src={imageUrl} />
          ) : (
            <div
              className="d-flex flex-column justify-content-center"
              style={{
                flex: 1,
                height: 48,
              }}
            >
              <div
                className="d-flex justify-content-center heading-xsmall"
                style={{
                  color: colors.caliber_gray_5,
                  lineHeight: "10px",
                }}
              >
                NO
              </div>
              <div
                className="d-flex justify-content-center heading-xsmall"
                style={{
                  marginTop: 2,
                  color: colors.caliber_gray_5,
                  lineHeight: "10px",
                }}
              >
                IMG
              </div>
            </div>
          )}
        </div>
      )}
      <div
        className="d-flex"
        style={{
          flex: 1,
          height: 48,
          backgroundColor: colors.caliber_gray_5,
          color: colors.caliber_dark_gray,
          borderRadius: 8,
          border: `2px solid ${
            error ? colors.caliber_error_color : colors.caliber_gray_5
          }`,
          position: "relative",
        }}
      >
        <input
          ref={fileInputRef}
          type="file"
          name="image"
          onChange={(event) => {
            if (event.target == null || event.target.files[0] == null) {
              return;
            }
            const file = event.target.files[0];
            if (file.type.startsWith("image")) {
              const imageFile = event.target.files[0];
              mediaContext.current = MediaContext.Thumbnail;
              if (imageFile?.size > 500 * 1024) {
                onSuccessDialogOpen(
                  "Maximum upload size exceeded.\nPlease reduce the file size and try again."
                );
              } else {
                uploadImage(imageFile, FileUploadType.ProfilePic);
              }
            } else {
              // TODO: Display error
              console.log("Invalid filetype", file.type);
            }
          }}
          style={{
            display: "none",
          }}
        />
        <div
          className="d-flex align-items-center paragraph-normal"
          style={{
            flex: 1,
            marginLeft: 16,
            color: colors.caliber_dark_gray,
          }}
        >
          {nameCutter(imageName) || "Upload profile image"}
        </div>

        <div
          className={`d-flex heading-xsmall justify-content-center align-items-center ${
            showLoader ? "" : "pointer"
          }`}
          role="button"
          tabIndex={0}
          onClick={() => {
            if (!mediaState.isUploading) {
              if (imageUrl) {
                resetMediaState();
                setImageUrl(null);
              } else {
                fileInputRef.current.click();
              }
            }
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter" && !mediaState.isUploading) {
              if (imageUrl) {
                resetMediaState();
              } else {
                fileInputRef.current.click();
              }
            }
          }}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
            width: 80,
            height: 32,
            backgroundColor: imageUrl
              ? colors.caliber_red_300
              : colors.caliber_green_200,
            color: colors.caliber_white,
            borderRadius: 6,
          }}
        >
          {imageUrl ? "Delete" : "Upload"}
        </div>
        <div
          className="paragraph-xsmall"
          style={{
            position: "absolute",
            top: 52,
            right: 0,
          }}
        >
          Minimum 48 x 48px ・ Maximum 500kb
        </div>
        {error && (
          <div
            className="paragraph-small"
            style={{
              position: "absolute",
              top: 65,
              left: "-65px",
              color: colors.caliber_error_color,
            }}
          >
            <img
              style={{
                width: 16,
                height: 16,
                marginRight: 5,
              }}
              alt="Error"
              src={ErrorIcon}
            />
            Upload profile image
          </div>
        )}
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  uploadImage: (file: any, type: FileUploadType) => {
    dispatch(fileUpload(file, type));
  },
  resetMediaState: () => {
    dispatch(resetMediaState());
  },
  onSuccessDialogOpen: (message: string) => {
    dispatch(onSuccessDialogOpen(message));
  },
});

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  mediaState: state.mediaState,
  onMediaUrlIdChange: ownProps.onMediaUrlIdChange,
  error: ownProps.error,
  ownImageUrl: ownProps.ownImageUrl,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileImage);
