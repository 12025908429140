import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import colors from "../../assets/colors";
import {
  changeFilterInClientsTab,
  closeColumnFilterInClientsTab,
} from "../../redux/actions/ClientsTab";
import {
  getIsAdminOrManager,
  getIsMasquerade,
  StoreState,
} from "../../redux/reducers";
import { ClientPackageType, UserStatusType } from "../../types/gqlTypes";
import SimpleButton from "../SimpleButton";

interface Props {
  whichColumnFilterOpen?: "status" | "packageType";
  isFilterOpen?: boolean;
  status?: UserStatusType[];
  packageType?: ClientPackageType[];
  isAdminOrManager: boolean;
  isMasquerade: boolean;
  onClose?: () => void;
  changeFilterInClientsTab: (args: {
    statusCriteria?: UserStatusType[];
    clientPackageTypeCriteria?: ClientPackageType[];
  }) => void;
}

const Filter = (props: Props) => {
  const {
    whichColumnFilterOpen,
    isFilterOpen,
    status,
    packageType,
    isAdminOrManager,
    isMasquerade,
    onClose,
    changeFilterInClientsTab,
  } = props;

  const [left, setLeft] = useState(null);
  const [localStatus, setLocalStatus] = useState<UserStatusType[]>([]);
  const [localPackageType, setLocalPackageType] = useState<ClientPackageType[]>(
    []
  );

  const onBlur = () => {
    setTimeout(() => {
      if (document.activeElement.id !== "filter_element") {
        onClose();
      }
    }, 0);
  };

  const onCheck = (value) => {
    if (whichColumnFilterOpen === "packageType") {
      const isExist = localPackageType?.includes(value as ClientPackageType);
      let newPackages;
      if (isExist) {
        newPackages = localPackageType?.filter(
          (packageType) => packageType !== value
        );
      } else {
        newPackages = [...localPackageType, value];
      }
      setLocalPackageType(newPackages);
      changeFilterInClientsTab({
        clientPackageTypeCriteria: newPackages,
      });
    } else if (whichColumnFilterOpen === "status") {
      const isExist = localStatus?.includes(value as UserStatusType);
      let newStatus;
      if (isExist) {
        newStatus = localStatus?.filter((status) => status !== value);
      } else {
        newStatus = [...localStatus, value];
      }
      setLocalStatus(newStatus);
      changeFilterInClientsTab({
        statusCriteria: newStatus,
      });
    }
  };

  const userStatusOptions = [
    { value: UserStatusType.Active, text: "Active" },
    { value: UserStatusType.TrialUser, text: "Trial" },
    { value: UserStatusType.PendingCancellation, text: "Pending" },
    { value: UserStatusType.PausedSubscription, text: "Paused" },
    { value: UserStatusType.OnHold, text: "Failed" },
  ];
  if (isAdminOrManager && !isMasquerade) {
    userStatusOptions.splice(1, 0, {
      value: UserStatusType.Cancelled,
      text: "Cancelled",
    });
  }

  const userPackageOptions = [
    { value: ClientPackageType.Premium, text: "Premium" },
    { value: ClientPackageType.Legacy, text: "Legacy" },
    { value: ClientPackageType.TestAccount, text: "Test Account" },
  ];

  if (isAdminOrManager && !isMasquerade) {
    userPackageOptions.push({
      value: ClientPackageType.Freemium,
      text: "Freemium",
    });
    userPackageOptions.push({
      value: ClientPackageType.Pro,
      text: "Pro",
    });
    userPackageOptions.push({
      value: ClientPackageType.Plus,
      text: "Plus",
    });
  }

  const items: any[] =
    whichColumnFilterOpen === "packageType"
      ? userPackageOptions
      : userStatusOptions;
  const isChecked = (value) => {
    if (whichColumnFilterOpen === "packageType") {
      return localPackageType?.includes(value as ClientPackageType);
    }
    if (whichColumnFilterOpen === "status") {
      return localStatus?.includes(value as UserStatusType);
    }
    return false;
  };

  useEffect(() => {
    setLocalStatus([...(status || [])]);
    setLocalPackageType([...(packageType || [])]);
  }, [status, packageType]);

  useEffect(() => {
    if (isFilterOpen) {
      const el = document
        ?.getElementById(`filter_cell_${whichColumnFilterOpen}`)
        ?.getBoundingClientRect();
      const table = document
        ?.getElementById("clients_tab_table")
        ?.getBoundingClientRect();
      setLeft(el.left - table.left);
    }
  }, [isFilterOpen, whichColumnFilterOpen]);

  if (!isFilterOpen) {
    return null;
  }

  return (
    <div
      id="filter_element"
      onBlur={onBlur}
      className="d-flex flex-column align-items-center justify-content-center"
      style={{
        width: 202,
        zIndex: 9999,
        backgroundColor: colors.caliber_white,
        borderRadius: 8,
        position: "absolute",
        top: 45,
        left,
        boxShadow: "0px 2px 16px #E7E7EB",
      }}
    >
      {items?.map((item, index) => {
        return (
          <SimpleButton
            nofocus
            nopointer
            key={`${item.text}-${item.value}`}
            id="filter_element"
            onBlur={onBlur}
            onClick={() => {}}
            className="d-flex align-items-center justify-content-between medium-bold"
            style={{
              width: 186,
              fontSize: 12,
              height: 40,
              padding: 12,
              margin: "0px 8px",
              marginTop: index === 0 && 8,
              marginBottom: index === items.length - 1 && 8,
              backgroundColor: colors.caliber_white,
              color: colors.caliber_secondary,
              borderRadius: 4,
              zIndex: 9999,
            }}
            styleOnHover={{
              backgroundColor: colors.caliber_gray_2,
            }}
          >
            {item.text}
            <SimpleButton
              nofocus
              id="filter_element"
              onClick={() => onCheck(item.value)}
            >
              <Checkbox checked={isChecked(item.value)} />
            </SimpleButton>
          </SimpleButton>
        );
      })}
    </div>
  );
};

const Checkbox = ({ checked }: { checked?: boolean }) => {
  return checked ? (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="15"
        height="15"
        rx="3.5"
        fill="white"
        stroke="#01052F"
      />
      <path
        d="M5.56737 11.9588L5.56735 11.9588L2.51411 8.90552L2.51408 8.90549C2.02864 8.42028 2.02864 7.63252 2.51408 7.1473L2.51414 7.14725C2.99933 6.66182 3.78712 6.66179 4.27261 7.14728L4.27262 7.14729L6.09175 8.96615L6.09184 8.96625C6.28778 9.16181 6.60545 9.16166 6.80152 8.96635L6.80172 8.96615L11.7274 4.04048L11.7274 4.04045C12.2126 3.55502 13.0004 3.55498 13.4859 4.04048C13.719 4.2736 13.85 4.58992 13.85 4.9196C13.85 5.24927 13.719 5.56559 13.4859 5.79872L7.32585 11.9588C6.84036 12.4442 6.05257 12.4442 5.56737 11.9588Z"
        fill="#01052F"
        stroke="white"
        strokeWidth="0.3"
      />
    </svg>
  ) : (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="15"
        height="15"
        rx="3.5"
        fill="white"
        stroke="#01052F"
      />
    </svg>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onClose: () => {
    dispatch(closeColumnFilterInClientsTab());
  },
  changeFilterInClientsTab: (args: {
    statusCriteria?: UserStatusType[];
    clientPackageTypeCriteria?: ClientPackageType[];
  }) => {
    dispatch(changeFilterInClientsTab(args));
  },
});

const mapStateToProps = (state: StoreState) => ({
  isFilterOpen: state.clientsTabState.isFilterOpen,
  whichColumnFilterOpen: state.clientsTabState.whichColumnFilterOpen,
  status: state.clientsTabState.statusCriteria,
  packageType: state.clientsTabState.clientPackageTypeCriteria,
  isAdminOrManager: getIsAdminOrManager(state),
  isMasquerade: getIsMasquerade(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
