import { ProGroup } from "../../../types/gqlTypes";

export const PRO_GROUPS_LOADING = "PRO_GROUPS_LOADING";
export const GET_PRO_GROUPS_SUCCESS = "GET_PRO_GROUPS_SUCCESS";
export const GET_PRO_GROUPS_FAIL = "GET_PRO_GROUPS_FAIL";

export interface ProGroupsState {
  isLoading: boolean;
  proGroups: ProGroup[];
  error?: string;
}

export interface ProGroupsActions {
  type: string;
  data?: any;
}
