import { format } from "date-fns";
import React from "react";
import colors from "../../assets/colors";
import { PoseType } from "../../types/gqlTypes";
import SimpleButton from "../SimpleButton";

interface Props {
  filteredPhotosByYear: {
    [key: string]: {
      date: Date;
      pose: PoseType;
      id: string;
      photo: string;
      isDefault?: boolean;
    }[];
  };
  year: string;
  onClick: (args: {
    date: Date;
    pose: PoseType;
    id: string;
    photo: string;
    isDefault?: boolean;
  }) => void;
}

const PhotoThrouple = (props: Props) => {
  const { filteredPhotosByYear, year, onClick } = props;

  return (
    <div
      key={`year-${year}`}
      className="d-flex flex-column"
      style={{
        marginBottom: 60,
      }}
    >
      <div
        className="medium-bold"
        style={{
          fontSize: "29px",
          lineHeight: "29px",
          color: colors.caliber_secondary,
        }}
      >
        {year}
      </div>
      <div className="d-flex flex-wrap">
        {filteredPhotosByYear[year].map((pictures) => {
          const front = pictures[0];
          const side = pictures?.[1];
          const back = pictures?.[2];
          return (
            <div key={front.id} id={front.id} className="d-flex flex-column">
              <div
                className="d-flex align-items-center justify-content-center medium-bold"
                style={{
                  width: 77,
                  height: 22,
                  backgroundColor: colors.caliber_secondary_gray_11,
                  color: colors.caliber_secondary,
                  borderRadius: 6,
                  fontSize: "12px",
                  lineHeight: "14px",
                  marginBottom: 12,
                  marginTop: 36,
                }}
              >
                {front?.date && format(front.date, "MM.dd.yyyy")}
              </div>
              <div className="d-flex flex-nowrap">
                <SimpleButton
                  nofocus
                  nopointer={front.isDefault}
                  onClick={() => onClick(front)}
                >
                  <img
                    src={front.photo}
                    style={{
                      minWidth: front.isDefault && 110,
                      height: 196,
                      marginRight: 12,
                    }}
                  />
                </SimpleButton>
                {side && (
                  <SimpleButton
                    nofocus
                    nopointer={side.isDefault}
                    onClick={() => onClick(side)}
                  >
                    <img
                      src={side.photo}
                      style={{
                        minWidth: side.isDefault && 110,
                        height: 196,
                        marginRight: 12,
                      }}
                    />
                  </SimpleButton>
                )}
                {back && (
                  <SimpleButton
                    nofocus
                    nopointer={back.isDefault}
                    onClick={() => onClick(back)}
                  >
                    <img
                      src={back.photo}
                      style={{
                        minWidth: back.isDefault && 110,
                        height: 196,
                        marginRight: 36,
                      }}
                    />
                  </SimpleButton>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PhotoThrouple;
