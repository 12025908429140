import React from "react";

interface Props {
  width?: number;
  height?: number;
  bgColor?: string;
}

const PremiumClientIcon = (props: Props) => {
  const { width, height, bgColor } = props;

  return (
    <svg
      style={{ display: "block" }}
      width={width || 22}
      height={height || 22}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="20"
        height="20"
        rx="7"
        fill="#FFCC32"
        stroke={bgColor || "white"}
        strokeWidth="2"
      />
      <path
        d="M10.7147 5.87812C10.8045 5.60172 11.1955 5.60172 11.2853 5.87812L12.2797 8.9386C12.3199 9.06221 12.4351 9.1459 12.565 9.1459H15.783C16.0737 9.1459 16.1945 9.51778 15.9594 9.6886L13.356 11.5801C13.2508 11.6565 13.2068 11.7919 13.247 11.9155L14.2414 14.976C14.3312 15.2524 14.0149 15.4822 13.7797 15.3114L11.1763 13.4199C11.0712 13.3435 10.9288 13.3435 10.8237 13.4199L8.22026 15.3114C7.98514 15.4822 7.6688 15.2524 7.75861 14.976L8.75302 11.9155C8.79318 11.7919 8.74918 11.6565 8.64404 11.5801L6.04063 9.6886C5.80552 9.51778 5.92635 9.1459 6.21697 9.1459H9.43495C9.56492 9.1459 9.68011 9.06221 9.72027 8.9386L10.7147 5.87812Z"
        fill="#A28429"
      />
    </svg>
  );
};

export default PremiumClientIcon;
