import React from "react";
import { connect } from "react-redux";
import colors from "../../assets/colors";
import { StoreState } from "../../redux/reducers";
import { Client, User } from "../../types/gqlTypes";
import Loader from "../Loader";
import Profile from "./Profile";

interface OwnProps {
  fullView: boolean;
  notShowLessInfo?: boolean;
}
interface Props extends OwnProps {
  user: Client & User;
  bodyWeight: number;
  isLoading: boolean;
  isSaving: boolean;
}

const ClientProfileInfoModule = (props: Props) => {
  const { user, bodyWeight, isLoading, isSaving, fullView, notShowLessInfo } =
    props;

  return (
    <div
      className="flex-grow-0 bordered-item"
      style={{
        position: "relative",
        minWidth: 272,
        minHeight: 368,
        backgroundColor: colors.caliber_white,
      }}
    >
      {isLoading ? (
        <div
          style={{
            position: "absolute",
            top: 90,
            left: 120,
          }}
        >
          <Loader />
        </div>
      ) : (
        <Profile
          isLoading={isSaving}
          clientInfo={user}
          fullView={fullView}
          bodyWeight={bodyWeight}
          notShowLessInfo={notShowLessInfo}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  user: state.clientDetailState.user,
  bodyWeight: state.clientDetailState.bodyWeight,
  isLoading: state.clientDetailState.isLoading,
  isSaving: state.clientDetailState.isSaving,
  fullView: ownProps.fullView,
});

export default connect(mapStateToProps)(ClientProfileInfoModule);
