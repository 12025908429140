import React, { useEffect } from "react";
import { connect } from "react-redux";
import colors from "../../assets/colors";
import {
  getCaliberErrors,
  throwCaliberError,
} from "../../redux/actions/ErrorSuccessDialog";
import { ErrorSuccessDialogState } from "../../redux/actions/ErrorSuccessDialog/types";
import { StoreState } from "../../redux/reducers";
import SimpleButton from "../SimpleButton";

interface Props {
  errorState: ErrorSuccessDialogState;
  throwCaliberError: (caliberErrorNumber: number) => void;
  getCaliberErrors: () => void;
}

const DebugErrorHandle = (props: Props) => {
  const { errorState, throwCaliberError, getCaliberErrors } = props;

  useEffect(() => {
    getCaliberErrors();
  }, []);

  const btnStyle: React.CSSProperties = {
    width: 150,
    height: 35,
    borderRadius: 6,
    backgroundColor: colors.caliber_green_200,
    color: colors.caliber_white,
    margin: "4px 10px",
  };

  return (
    <div>
      <table>
        <tbody>
          <tr>
            <td>500</td>
            <td>
              <SimpleButton
                className="d-flex align-items-center justify-content-center"
                style={btnStyle}
                onClick={() => throwCaliberError(500)}
              >
                throw an error
              </SimpleButton>
            </td>
            <td>
              An Unexpected Error has occurred, please notify your manager.
            </td>
          </tr>
          {errorState?.errors?.map((error) => {
            return (
              <tr>
                <td>{error?.code}</td>
                <td>
                  <SimpleButton
                    className="d-flex align-items-center justify-content-center"
                    style={btnStyle}
                    onClick={() => throwCaliberError(error?.code)}
                  >
                    throw an error
                  </SimpleButton>
                </td>
                <td>{error?.defaultMessage}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getCaliberErrors: () => {
    dispatch(getCaliberErrors());
  },
  throwCaliberError: (caliberErrorNumber: number) => {
    dispatch(throwCaliberError(caliberErrorNumber));
  },
});

const mapStateToProps = (state: StoreState) => ({
  errorState: state.errorSuccessDialogState,
});

export default connect(mapStateToProps, mapDispatchToProps)(DebugErrorHandle);
