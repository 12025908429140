import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  startOfWeek,
  addDays,
  getMonth,
  isToday,
  eachDayOfInterval,
  format,
} from "date-fns";
import { StoreState } from "../../redux/reducers";
import CalendarDayComponent from "./CalendarDayComponent";
import ControlPanel from "./ControlPanel";
import CalendarHeader from "./CalendarHeader";
import { useRerenderOnResize } from "../../utils/customHooks";
import {
  CalendarDay,
  CalendarItem,
  CalendarItemType,
  ClientHabitTarget,
  ClientNutritionTarget,
  MutationMoveRecurringEventArgs,
} from "../../types/gqlTypes";
import ScheduleEventModal from "../ScheduleEventModal";
import CalendarNutritionModal from "./CalendarNutritionModal";
import CalendarProgressModal from "./CalendarProgressModal";
import StrengthCalendarItem from "./StrengthCalendarItem";
import CardioCalendarItem from "./CardioCalendarItem";
import CalendarFullDayModal from "./CalendarFullDayModal";
import ConfirmationDialog from "../ConfirmationDialog";
import {
  deleteCalendarItem,
  getCalendarItem,
  moveCalendarItem,
  moveRecurringEvent,
} from "../../redux/actions/Calendar";
import DeleteRecurringEvent from "./DeleteRecurringEvent.tsx";
import "./index.css";

interface OwnProps {
  currentYear: any;
  currentMonth: any;
  currentDate: Date;
  goBack: () => void;
  goForth: () => void;
  goToday: () => void;
  maxMonthsToGo: number;
  isLoading: boolean;
  calendarItemsByDay: CalendarDay[];
}
interface Props extends OwnProps {
  isLoadingItem: boolean;
  calendarFetchItemFailed: boolean;
  isLoadingNutritionTargets: boolean;
  clientNutritionTarget: ClientNutritionTarget;
  habits: ClientHabitTarget[];
  moveCalendarItem: (
    calendarItemId: string,
    date: Date,
    oldCalendarDayId: string
  ) => void;
  moveRecurringEvent: (args: MutationMoveRecurringEventArgs) => void;
  deleteCalendarItem: (calendarItemId: string, date: Date) => void;
  getCalendarItem: (
    clientId: string,
    calendarItemId: string,
    calendarDayId: string
  ) => void;
}

const Calendar = (props: Props) => {
  const {
    currentMonth,
    currentYear,
    currentDate,
    goBack,
    goForth,
    goToday,
    deleteCalendarItem,
    moveCalendarItem,
    moveRecurringEvent,
    getCalendarItem,
    maxMonthsToGo,
    calendarItemsByDay,
    isLoading,
    isLoadingItem,
    calendarFetchItemFailed,
    isLoadingNutritionTargets,
    clientNutritionTarget,
    habits,
  } = props;
  useRerenderOnResize(); // to change maxItemsShown
  const { id: clientId } = useParams();

  const [newEventDay, setNewEventDay] = useState<Date>(null);

  const [confirmDelete, setConfirmDelete] = useState<{
    item: CalendarItem;
    date: Date;
  }>(null);
  const [confirmDeleteRecurring, setConfirmDeleteRecurring] = useState<{
    item: CalendarItem;
    date: Date;
  }>(null);
  const [viewRecurringEvent, setViewRecurringEvent] = useState(null);
  const [selectedDate, setSelectedDate] = useState<Date>(null);
  const [openFullDay, setOpenFullDay] = useState<CalendarDay>(null);
  const [openNutritionsItemId, setOpenNutritionsItemId] =
    useState<string>(null);
  const [openProgressItemId, setOpenProgressItemId] = useState<string>(null);
  const [strengthItemId, setStrengthItemId] = useState<string>(null);
  const [cardioItemId, setCardioItemId] = useState<string>(null);

  const dateFormat = "yyyy-MM-dd";
  const weekStartsOnSunday = true;
  const [rows, columns] = [5, 7]; // size of calendar

  const calendarDiv = document.getElementById("calendar");
  const minHeight = 520; // to initialize calendar
  const calendarHeight = calendarDiv ? calendarDiv.offsetHeight : minHeight;
  const dateNumberHeight = 20;
  const footerItemHeight = 20;
  const calendarItemHeight = 22;
  const maxItemsShown = Math.floor(
    (calendarHeight / rows - dateNumberHeight - footerItemHeight) /
      calendarItemHeight
  );

  const [firstDay, lastDay] = useMemo(() => {
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
    const first = startOfWeek(firstDayOfMonth, {
      weekStartsOn: weekStartsOnSunday ? 0 : 1,
    });
    const last = addDays(first, rows * columns - 1);
    return [first, last];
  }, [currentMonth, currentYear]);

  const daysObject = useMemo(() => {
    const obj: { [key: string]: CalendarDay } = {};
    if (calendarItemsByDay) {
      calendarItemsByDay?.forEach((item) => {
        obj[item.date] = item;
      });
    }
    return obj;
  }, [calendarItemsByDay, isLoading, isLoadingItem]);

  const isCalendarItemRecurring = (item: CalendarItem) => {
    return (
      item?.type === "RecurringWorkoutCalendarItem" ||
      item?.type === "RecurringBodyStatCalendarItem" ||
      item?.type === "RecurringCardioActivityCalendarItem" ||
      item?.type === "RecurringProgressPhotoCalendarItem" ||
      item?.type === "RecurringHabitCalendarItem"
    );
  };

  const viewEvent = (item: CalendarItem, dayId: string, day: Date) => {
    const formattedDate = format(day, dateFormat);
    const calendarDay = daysObject[formattedDate];
    if (isCalendarItemRecurring(item)) {
      setViewRecurringEvent({ calendarDay, item, day });
    } else {
      getCalendarItem(clientId, item?.id, dayId);
      if (item.enumType === CalendarItemType.ImportedNutrition) {
        setOpenNutritionsItemId(item?.id);
      } else if (item.enumType === CalendarItemType.ProgressPic) {
        setOpenProgressItemId(item?.id);
      } else if (item.enumType === CalendarItemType.Workout) {
        setStrengthItemId(item?.id);
      } else if (item.enumType === CalendarItemType.CardioActivity) {
        setCardioItemId(item?.id);
      } else if (item.enumType === CalendarItemType.ImportedCardioActivity) {
        setCardioItemId(item?.id);
      }
      setSelectedDate(day);
    }
  };
  const onDelete = (item: CalendarItem, date: Date) => {
    if (isCalendarItemRecurring(item)) {
      setConfirmDeleteRecurring({ item, date });
    } else {
      setConfirmDelete({ item, date });
    }
  };
  const onDeleteCalendarItem = () => {
    deleteCalendarItem(confirmDelete?.item?.id, confirmDelete?.date);
    setConfirmDelete(null);
    setOpenFullDay(null);
  };

  const onDropCalendarItem = (
    calendarItemId: string,
    oldCalendarDayId: string,
    date: Date
  ) => {
    const formattedDate = format(date, dateFormat);
    const calendarDay = calendarItemsByDay.find(
      (day) => day.id === oldCalendarDayId
    );
    const calendarItem = calendarDay?.items?.find(
      (item) => item?.id === calendarItemId
    );
    if (isCalendarItemRecurring(calendarItem)) {
      moveRecurringEvent({
        clientId,
        currentDate: calendarDay?.date,
        newDate: formattedDate,
        recurringEventDefinitionId: calendarItem?.recurringEventDefinition?.id,
      });
    } else {
      moveCalendarItem(calendarItemId, date, oldCalendarDayId);
    }
  };

  useEffect(() => {
    if (calendarFetchItemFailed) {
      setSelectedDate(null);
      setCardioItemId(null);
      setOpenNutritionsItemId(null);
      setStrengthItemId(null);
      setOpenProgressItemId(null);
      setOpenFullDay(null);
    }
  }, [calendarFetchItemFailed]);

  return (
    <div
      className="d-flex flex-column"
      style={{
        flex: 1,
      }}
    >
      {openFullDay && (
        <CalendarFullDayModal
          viewEvent={(item, dayId, day) => viewEvent(item, dayId, day)}
          onDelete={onDelete}
          onClose={() => setOpenFullDay(null)}
          day={openFullDay}
          date={selectedDate}
        />
      )}
      {openNutritionsItemId && (
        <CalendarNutritionModal
          clientNutritionTarget={clientNutritionTarget}
          onClose={() => setOpenNutritionsItemId(null)}
          isLoading={isLoadingItem || isLoadingNutritionTargets}
          calendarDay={daysObject?.[format(selectedDate, dateFormat)]}
          itemId={openNutritionsItemId}
          date={selectedDate}
        />
      )}
      {openProgressItemId && (
        <CalendarProgressModal
          onClose={() => setOpenProgressItemId(null)}
          isLoading={isLoadingItem}
          calendarDay={daysObject?.[format(selectedDate, dateFormat)]}
          itemId={openProgressItemId}
          date={selectedDate}
        />
      )}
      {strengthItemId && (
        <StrengthCalendarItem
          onClose={() => setStrengthItemId(null)}
          isLoading={isLoadingItem}
          calendarDay={daysObject?.[format(selectedDate, dateFormat)]}
          itemId={strengthItemId}
          day={selectedDate}
        />
      )}
      {cardioItemId && !calendarFetchItemFailed && (
        <CardioCalendarItem
          onClose={() => setCardioItemId(null)}
          isLoading={isLoadingItem}
          calendarDay={daysObject?.[format(selectedDate, dateFormat)]}
          itemId={cardioItemId}
          day={selectedDate}
        />
      )}
      {confirmDelete && (
        <ConfirmationDialog
          show
          cancelButtonText="Cancel"
          text={`Are you sure you want to delete ${confirmDelete?.item?.title}?`}
          onCancel={() => setConfirmDelete(null)}
          onConfirm={onDeleteCalendarItem}
        />
      )}
      {confirmDeleteRecurring && (
        <DeleteRecurringEvent
          onClose={() => setConfirmDeleteRecurring(null)}
          calendarItem={confirmDeleteRecurring.item}
          day={confirmDeleteRecurring.date}
        />
      )}
      {(newEventDay || viewRecurringEvent) && (
        <ScheduleEventModal
          habits={habits}
          calendarDay={
            daysObject?.[
              format(
                viewRecurringEvent ? viewRecurringEvent?.day : newEventDay,
                dateFormat
              )
            ]
          }
          calendarItem={viewRecurringEvent?.item}
          day={viewRecurringEvent ? viewRecurringEvent?.day : newEventDay}
          onClose={() => {
            setNewEventDay(null);
            setViewRecurringEvent(null);
          }}
        />
      )}
      <ControlPanel
        isLoading={isLoading || isLoadingItem || isLoadingNutritionTargets}
        currentDate={currentDate}
        maxMonthsToGo={maxMonthsToGo}
        goBack={goBack}
        goForth={goForth}
        goToday={goToday}
      />
      <CalendarHeader
        columns={columns}
        weekStartsOnSunday={weekStartsOnSunday}
      />
      <div id="calendar" className="flex-grow-1 d-flex flex-wrap">
        {eachDayOfInterval({ start: firstDay, end: lastDay }).map(
          (day, index) => {
            const formattedDate = format(day, dateFormat);
            return (
              <CalendarDayComponent
                viewFullDay={() => {
                  setOpenFullDay(daysObject[formattedDate] || {});
                  setSelectedDate(day);
                }}
                onDropCalendarItem={onDropCalendarItem}
                onDelete={onDelete}
                viewEvent={(item: CalendarItem, dayId: string) =>
                  viewEvent(item, dayId, day)
                }
                scheduleEvent={() => setNewEventDay(day)}
                key={formattedDate}
                index={index}
                columns={columns}
                maxItemsShown={maxItemsShown}
                dateFormat={dateFormat}
                calendarDay={daysObject?.[formattedDate]}
                day={day}
                isToday={isToday(day)}
                isCurrentMonth={currentMonth === getMonth(day)}
              />
            );
          }
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  deleteCalendarItem: (calendarItemId: string, date: Date) => {
    dispatch(deleteCalendarItem(calendarItemId, date));
  },
  moveCalendarItem: (
    calendarItemId: string,
    date: Date,
    oldCalendarDayId: string
  ) => {
    dispatch(moveCalendarItem(calendarItemId, date, oldCalendarDayId));
  },
  moveRecurringEvent: (args: MutationMoveRecurringEventArgs) => {
    dispatch(moveRecurringEvent(args));
  },
  getCalendarItem: (
    clientId: string,
    calendarItemId: string,
    calendarDayId: string
  ) => {
    dispatch(getCalendarItem(clientId, calendarItemId, calendarDayId));
  },
});

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => ({
  currentYear: ownProps.currentYear,
  currentMonth: ownProps.currentMonth,
  currentDate: ownProps.currentDate,
  goBack: ownProps.goBack,
  goForth: ownProps.goForth,
  goToday: ownProps.goToday,
  maxMonthsToGo: ownProps.maxMonthsToGo,
  isLoading: ownProps.isLoading,
  calendarItemsByDay: ownProps.calendarItemsByDay,

  clientNutritionTarget: state.clientNutritionTargetState.clientNutritionTarget,
  isLoadingItem: state.calendarDataState.isLoadingItem,
  calendarFetchItemFailed: state.calendarDataState.calendarFetchItemFailed,
  isLoadingNutritionTargets: state.clientNutritionTargetState.isLoading,
  habits: state.clientHabitsState.habits,
});

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
