import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { ClientConsultation } from "../../types/gqlTypes";
import colors from "../../assets/colors";

interface Props {
  clientConsultation: ClientConsultation;
}

const ProfileFitnessConsultations = (props: Props) => {
  const { clientConsultation } = props;

  const buttons = [
    { key: "TRAINING_HISTORY", title: "Training" },
    { key: "NUTRITION_HISTORY", title: "Nutrition" },
    { key: "HEALTH_HISTORY", title: "Health & Medical" },
  ];
  const [activeKey, setActiveKey] = useState("TRAINING_HISTORY");
  return (
    <div
      className="bordered-item"
      style={{
        flex: 1,
        backgroundColor: colors.caliber_white,
        padding: 16,
      }}
    >
      <div
        className="heading-large col-16 flex-grow-0"
        style={{
          fontSize: "20px",
          lineHeight: "32px",
        }}
      >
        Fitness Consultations
      </div>
      <div className="d-inline-flex col-16 flex-grow-0">
        {buttons.map(({ key, title }) => {
          return (
            <Button
              key={key}
              style={{
                marginRight: 8,
                paddingLeft: 20,
                paddingRight: 20,
                color:
                  activeKey === key
                    ? colors.caliber_white
                    : colors.caliber_secondary,
                backgroundColor:
                  activeKey === key
                    ? colors.caliber_secondary
                    : colors.caliber_gray_bg,
              }}
              className="heading-xsmall"
              variant="link"
              onClick={() => setActiveKey(key)}
            >
              {title}
            </Button>
          );
        })}
      </div>
      <div
        className="col-16 flex-grow-1"
        style={{
          whiteSpace: "pre-line",
        }}
      >
        {clientConsultation?.questionAndAnswersMap?.[activeKey]?.map(
          ({ id, question, answer }) => {
            return (
              <div key={id}>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: 14,
                  }}
                >
                  {question}
                </div>
                <div
                  style={{
                    marginTop: 15,
                    marginBottom: 15,
                    fontSize: 13,
                  }}
                >
                  {answer || ""}
                </div>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};

export default ProfileFitnessConsultations;
