import DataTable from "react-data-table-component";
import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import { Duration, Period } from "@js-joda/core";
import { StoreState } from "../../redux/reducers";
import colors from "../../assets/colors";
import {
  CaliberRule,
  CoachReminderRule,
  CoachReminderRuleFromCron,
  ComparisonOperatorType,
  CrudOperationType,
  RuleType,
  SnoozeResetRule,
  TaskAutocompletionRule,
  User,
} from "../../types/gqlTypes";
import { getRules } from "../../redux/actions/Rules";
import { toTitleCase } from "../../utils";
import CoachTaskAutoButton from "../../assets/images/CoachTaskAutoButton.svg";
import DarkArrowDownIcon from "../../assets/images/DarkArrowDownIcon.svg";
import ButtonTag from "../ButtonTag";

interface Props {
  rules: CaliberRule[];
  user: User;
  getRules: () => CaliberRule[];
  row: CaliberRule;
}

const RulesBuilder = (props: Props) => {
  const { rules, user, getRules } = props;

  const [resetPaginationToggle] = React.useState(false);
  const [filterText, setFilterText] = React.useState("");

  // On page load
  useEffect(() => {
    loadRules();
  }, [user]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [rules]);

  const loadRules = () => {
    getRules();
  };

  const HAS_OPEN_TASK_LABEL = "Has Open Task:";

  const filteredItems =
    rules &&
    rules.filter(
      (item) =>
        (item.description &&
          item.description.toLowerCase().includes(filterText.toLowerCase())) ||
        item.condition?.toLowerCase().includes(filterText.toLowerCase()) ||
        item.objectCrudOperation
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        item.objectClassSimpleName
          .toLowerCase()
          .includes(filterText.toLowerCase())
    );
  const inputFocus = useRef(null);

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div
        className="d-flex flex-column"
        style={{
          flex: 0,
        }}
      >
        {rules && (
          <div className="d-flex">
            <input
              className="bold"
              ref={inputFocus}
              style={{
                flex: 0.25,
                fontSize: "16px",
                lineHeight: "24px",
                padding: "13px 0px 11px 16px",
                backgroundColor: colors.caliber_secondary_gray_5,
                border: `1px solid ${colors.caliber_secondary_gray_5}`,
                borderRadius: 8,
                height: 48,
                marginRight: 24,
                marginLeft: 44,
              }}
              value={filterText}
              onChange={(event) => setFilterText(event.target.value)}
              placeholder="Filter Rules"
            />
          </div>
        )}
      </div>
    );
  }, [filterText, rules, resetPaginationToggle]);

  const toTitleCaseFromEnum = (string: string) => {
    return toTitleCase(string.replace(new RegExp("_", "g"), " "));
  };

  const generateRuleConditionText = (row: CaliberRule) => {
    const hasExtraAutocompletionConditions: string =
      row.type === RuleType.TaskAutocomplete && row.condition;
    let text: string;

    switch (row.type) {
      case RuleType.CoachReminder:
      case RuleType.SnoozeReset:
        if (row.condition) {
          text = `${row.condition}`;
        } else {
          text = "";
        }
        break;
      case RuleType.TaskAutocomplete:
        const autoCompletionConditionText = `${HAS_OPEN_TASK_LABEL} ${toTitleCaseFromEnum(
          (row as TaskAutocompletionRule).taskAutocompletionSourceRuleName
        )} `;
        if (hasExtraAutocompletionConditions) {
          text = `${autoCompletionConditionText} && ${row.condition}`;
          break;
        } else {
          text = `${autoCompletionConditionText}`;
          break;
        }
      default:
        text = "Not coded yet";
        break;
    }

    return text;
  };

  const configurableParamRowStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  };

  const configurableParamValueStyle: React.CSSProperties = {
    fontFamily: "monospace",
    fontSize: "12px",
    border: `1px solid ${colors.caliber_secondary_gray_11}`,
    borderRadius: "4px",
    padding: "5px",
    color: colors.caliber_red_300,
    backgroundColor: colors.caliber_gray_2,
    marginRight: "3px",
  };

  const formatPeriodString = (periodString: string) => {
    let duration: number = 0;
    let label: string = "";
    let isZero: boolean = false;
    let isNegative: boolean = false;

    try {
      const period: Period = Period.parse(periodString);
      isZero = period.isZero();
      isNegative = period.isNegative();
      if (period.days() != 0) {
        duration = period.days();
        label = "days";
      } else if (period.months() != 0) {
        duration = period.months();
        label = "months";
      } else if (period.years() != 0) {
        duration = period.years();
        label = "year";
      }
    } catch (error) {
      const javaDuration: Duration = Duration.parse(periodString);
      isZero = javaDuration.isZero();
      isNegative = javaDuration.isNegative();
      duration = javaDuration.toMinutes();
      label = "minutes";
    }

    if (isZero) {
      return (
        <div style={configurableParamRowStyle}>
          <div style={{ marginLeft: "3px", marginRight: "3px" }}>and</div>
          <div style={configurableParamValueStyle}>never snoozes</div>
        </div>
      );
    }

    if (isNegative) {
      return (
        <div style={configurableParamRowStyle}>
          <div style={{ marginLeft: "3px", marginRight: "3px" }}>
            and snoozes
          </div>
          <div style={configurableParamValueStyle}>infinitely</div>
        </div>
      );
    }

    return (
      <div style={configurableParamRowStyle}>
        <div style={{ marginLeft: "3px", marginRight: "3px" }}>
          and snoozes for
        </div>
        <div style={configurableParamValueStyle}>{duration}</div>
        <div style={configurableParamValueStyle}>{label}</div>
      </div>
    );
  };

  const generateOnSuccessText = (row: CaliberRule) => {
    switch (row.type) {
      case RuleType.SnoozeReset:
        return (
          <div>
            {formatToken(
              `Reset Snooze For Task: ${toTitleCaseFromEnum(
                (row as SnoozeResetRule).snoozedTaskSourceRuleName
              )}`
            )}
          </div>
        );
      case RuleType.TaskAutocomplete:
        return <div>Autocomplete Task</div>;
      default:
        switch (row.objectCrudOperation) {
          case CrudOperationType.Cron:
            return (
              <div style={configurableParamRowStyle}>
                <div style={{ marginRight: "3px" }}>
                  Create Task expiring in
                </div>
                <div style={configurableParamRowStyle}>
                  <div style={configurableParamValueStyle}>
                    {
                      (row as CoachReminderRule | CoachReminderRuleFromCron)
                        .taskExpirationDurationHours
                    }
                  </div>
                  <div>hours</div>
                  {formatPeriodString(
                    (row as CoachReminderRuleFromCron).snoozePeriod
                  )}
                </div>
              </div>
            );
          default:
            return (
              <div style={configurableParamRowStyle}>
                <div style={{ marginRight: "3px" }}>
                  Create Task expiring in
                </div>
                <div style={configurableParamValueStyle}>
                  {
                    (row as CoachReminderRule | CoachReminderRuleFromCron)
                      .taskExpirationDurationHours
                  }
                </div>
                hours
              </div>
            );
        }
    }
  };

  const generateParamText = (row: CaliberRule) => {
    if (row.lowerLimitParam) {
      let operator: string;
      switch (row.paramOperator) {
        case ComparisonOperatorType.Eq:
          operator = "=";
          break;
        case ComparisonOperatorType.Gt:
          operator = ">";
          break;
        case ComparisonOperatorType.Lt:
          operator = "<";
          break;
        default:
          operator = "not coded yet";
          break;
      }

      if (row.paramUnit) {
        return (
          <div style={configurableParamRowStyle}>
            <div style={configurableParamValueStyle}>{operator}</div>
            <div style={configurableParamValueStyle}>{row.lowerLimitParam}</div>
            <div style={configurableParamValueStyle}>
              {row.paramUnit.toLowerCase()}
            </div>
            ago
          </div>
        );
      }

      return (
        <div style={configurableParamRowStyle}>
          <div style={configurableParamValueStyle}>{operator}</div>
          <div style={configurableParamValueStyle}>{row.lowerLimitParam}</div>
        </div>
      );
    }

    return <div />;
  };

  const formatToken = (token: string) => {
    const splitToken: string[] = token.split(":");
    return (
      <div style={configurableParamRowStyle}>
        {splitToken[0]}
        {splitToken[1] && (
          <ButtonTag
            text={splitToken[1]}
            noPointer
            textColor={colors.caliber_calendar_item_blue}
            bgColor={colors.caliber_gray_4}
            style={{
              marginLeft: "3px",
            }}
          />
        )}
      </div>
    );
  };

  const formatConditionText = (text: string, row: CaliberRule) => {
    let result: string = text;

    result = result.replace(
      new RegExp("client.lastActiveDate", "g"),
      "Client Last Active Date"
    );
    result = result.replace(
      new RegExp("client.joinedDate", "g"),
      "Client Joined Date"
    );
    result = result.replace(
      new RegExp("client.birthDate", "g"),
      "Client Birthday"
    );
    result = result.replace(new RegExp("client.notes", "g"), "Client Notes");
    result = result.replace(
      new RegExp("clientTrainingPlan.active", "g"),
      "Training Plan is active"
    );
    result = result.replace(
      new RegExp("clientWorkoutGroupTemplate.", "g"),
      "Workout Group ➡️ "
    );
    result = result.replace(
      new RegExp("clientWorkoutTemplate.", "g"),
      "Workout ➡️ "
    );
    result = result.replace(
      new RegExp("clientGymStepTemplate.", "g"),
      "Exercise ➡️ "
    );
    result = result.replace(
      new RegExp("clientTrainingPlanLastUpdatedDate", "g"),
      "Active Training Plan hierarchy last updated"
    );
    result = result.replace(
      new RegExp("clientTrainingPlan.", "g"),
      "Training Plan"
    );
    result = result.replace(
      new RegExp(
        "abs\\(1 - \\(clientNutritionTarget.calories7DayAverage \\/ clientNutritionTarget.calories\\) \\* 100\\)",
        "g"
      ),
      "% of calorie target missed"
    );
    result = result.replace(
      new RegExp("clientNutritionTarget.", "g"),
      "Nutrition Target"
    );
    result = result.replace(
      new RegExp("lastChangedOn", "g"),
      " was last changed"
    );
    result = result.replace(
      new RegExp(
        "!isInZoomCallList\\(zoomCalls, ZoomCallType.INTRO, CalendarItemStatusType.SCHEDULED\\)",
        "g"
      ),
      "There is no scheduled intro call"
    );
    result = result.replace(
      new RegExp(
        "!isInZoomCallList\\(zoomCalls, ZoomCallType.INTRO, CalendarItemStatusType.COMPLETED\\)",
        "g"
      ),
      "There is no completed intro call"
    );
    result = result.replace(new RegExp("UserType.TRAINER", "g"), "Coach");
    result = result.replace(new RegExp("UserType.CLIENT", "g"), "Client");
    result = result.replace(
      new RegExp("chatRoom.lastMessageSentFrom", "g"),
      "Last sent message from"
    );
    result = result.replace(
      new RegExp("SendBirdMessageType.FORM_VIDEO", "g"),
      "Form Video"
    );
    result = result.replace(
      new RegExp("chatRoom.lastMessageSentType", "g"),
      "Last sent message"
    );
    result = result.replace(
      new RegExp("chatRoom.lastDateTrainerSentMessage", "g"),
      "Coach last sent a message"
    );
    result = result.replace(
      new RegExp("client.mostRecentlyCompletedWorkoutDate", "g"),
      "Client most recently completed workout was"
    );
    result = result.replace(
      new RegExp("clientSalesPackage.clientPackageType", "g"),
      "Package Type"
    );
    result = result.replace(
      new RegExp("clientSalesPackage.packageEndDate", "g"),
      "Sales Package End Date"
    );
    result = result.replace(
      new RegExp("ClientPackageType.LEGACY", "g"),
      "Legacy"
    );
    result = result.replace(
      new RegExp("ClientPackageType.PREMIUM", "g"),
      "Premium"
    );
    result = result.replace(
      new RegExp(
        "daysSinceLastZoomCall\\(clientSalesPackage, zoomCalls\\)",
        "g"
      ),
      "Days since last Zoom Call"
    );
    result = result.replace(
      new RegExp(
        "zoomCall.status == CalendarItemStatusType.SCHEDULED && zoomCall.type == ZoomCallType.INTRO",
        "g"
      ),
      "Zoom intro call is scheduled"
    );
    result = result.replace(
      new RegExp(
        "zoomCall.status == CalendarItemStatusType.COMPLETED && zoomCall.type == ZoomCallType.INTRO",
        "g"
      ),
      "Zoom intro call is completed"
    );
    result = result.replace(
      new RegExp("CalendarItemStatusType.COMPLETED", "g"),
      "completed"
    );
    result = result.replace(
      new RegExp("workoutCalendarItem.status", "g"),
      "Workout"
    );
    result = result.replace(
      new RegExp("isFirstWorkout", "g"),
      "Is First Workout"
    );
    result = result.replace(
      new RegExp("hasClientNutritionTarget", "g"),
      "Client has nutrition targets set"
    );
    result = result.replace(new RegExp(".contains", "g"), " contains ");
    result = result.replace(new RegExp("localDateFromNow\\(", "g"), "");
    result = result.replace(
      new RegExp(", ChronoUnit.DAYS\\)", "g"),
      " days away from now"
    );

    result = result.replace(new RegExp("!=", "g"), "is not");
    result = result.replace(new RegExp("==", "g"), "is");
    result = result.replace(new RegExp("=", "g"), "is");
    result = result.replace(new RegExp("null", "g"), "empty");

    result = result.replace(new RegExp("&&", "g"), "\nAND\n");

    const results: string[] = result.split("AND");
    const tokens: JSX.Element[] | JSX.Element =
      result.indexOf("AND") > 0 ? (
        results.map((token: string, i: number) => {
          return (
            <div style={configurableParamRowStyle}>
              {formatToken(token)}
              {i + 1 < results.length && (
                <ButtonTag
                  text="AND"
                  noPointer
                  bgColor={colors.caliber_gray_4}
                  style={{
                    marginLeft: "3px",
                  }}
                />
              )}
              {i == results.length - 1 && generateParamText(row)}
            </div>
          );
        })
      ) : (
        <div>
          {formatToken(result)} {generateParamText(row)}
        </div>
      );

    return <div style={{ marginRight: "3px" }}>{tokens}</div>;
  };

  const formatDescriptionCell = (row: CaliberRule) => {
    return (
      <div data-tip={row.name} style={configurableParamRowStyle}>
        {row.type === RuleType.CoachReminder && row.ruleAutocompletable && (
          <img src={DarkArrowDownIcon} alt="Autocompletes" />
        )}

        {row.type === RuleType.CoachReminder &&
          row.ruleAutocompletable &&
          row.description}

        {row.type === RuleType.CoachReminder && !row.ruleAutocompletable && (
          <div style={{ marginLeft: "24px" }}>{row.description}</div>
        )}

        {row.type === RuleType.TaskAutocomplete && (
          <div style={{ marginLeft: "40px" }}>{row.description}</div>
        )}

        {row.ruleAutocompletable && (
          <img
            src={CoachTaskAutoButton}
            style={{ marginLeft: "5px" }}
            alt="Auto"
          />
        )}

        {row.type === RuleType.SnoozeReset && (
          <div
            style={{
              marginLeft: "24px",
              paddingBottom: "10px",
              paddingTop: "10px",
            }}
          >
            {row.description}
          </div>
        )}
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        name: "Description",
        selector: (row: CaliberRule) => formatDescriptionCell(row),
        wrap: true,
      },
      {
        name: "On Success",
        cell: (row: CaliberRule) => generateOnSuccessText(row),
        grow: 1,
      },
      {
        name: "Trigger Action",
        cell: (row: CaliberRule) =>
          row.objectCrudOperation != CrudOperationType.Cron
            ? `${row.objectCrudOperation} ${row.objectClassSimpleName}`
            : `${row.objectCrudOperation}`,
        grow: 0.5,
      },
      {
        name: "Condition",
        cell: (row: CaliberRule) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {formatConditionText(generateRuleConditionText(row), row)}
          </div>
        ),
        grow: 1,
      },
    ],
    []
  );

  return (
    <div
      className="d-flex flex-column"
      style={{
        flex: 1,
      }}
    >
      {rules && (
        <div
          style={{
            height: 1,
            backgroundColor: colors.caliber_gray_border,
          }}
        >
          <DataTable
            title="Rules"
            columns={columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            highlightOnHover
            striped
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            customStyles={{
              cells: {
                style: {
                  fontFamily: "Circular, serif",
                  fontSize: "16px",
                },
              },
              headCells: {
                style: {
                  fontFamily: "Circular-Medium, serif",
                  fontSize: "14px",
                  border: "1px solid rgb(242, 242, 242)",
                  borderRadius: "8px",
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  marginLeft: "10px",
                  marginRight: "10px",
                },
              },
              header: {
                style: {
                  fontFamily: "Circular-Bold, serif",
                  fontWeight: "bold",
                },
              },
              headRow: {
                style: {
                  minHeight: "30px",
                  borderBottomStyle: "none",
                },
              },
            }}
          />
        </div>
      )}
      <ReactTooltip />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getRules: () => {
    dispatch(getRules());
  },
});

const mapStateToProps = (state: StoreState) => ({
  rules: state.rulesState.rules,
  user: state.authenticatedUserState.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(RulesBuilder);
